import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { alertBoxCall } from 'components';
import { dateFormatByRequestedFormat, getCurrentDate, defaultHeader, apiCall, HttpMethods } from 'utils/helpers';
import { ALERT_BOX_TYPES, EXPORT_XL_FILE_TYPE, EXPORT_DOWNLOAD_STARTED_MSG, YEAR_MONTH_DAY_FORMAT, API_TYPES, SOMETHING_WENT_WRONG, DONE_SUCCESS_MSG } from 'utils/constants';
import { downloadFormatHandlerCA } from '../content-analytics';
import { IBlobHeaders, IExportToCsvOptions } from 'analytics/types';
import { ExportFileExt } from 'analytics/utils/constants';

export const exportToExcel = <T>(csvData: T[], options: IExportToCsvOptions) => {
  const { fileName, fileExt, fileType } = options;
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: fileExt, type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}.${fileExt}`);
};

export const exportCsvhandler = async (downloadFormatHandler: any, data: any, message: string, fileName: string, currentName: string = '', id: number | null = 0, userOwnership: string = '') => {
  const exportOptions = {
    fileName: `${fileName}-${dateFormatByRequestedFormat(getCurrentDate(), YEAR_MONTH_DAY_FORMAT)}`,
    fileExt: ExportFileExt.CSV,
    fileType: EXPORT_XL_FILE_TYPE
  };
  exportToExcel(downloadFormatHandler ? downloadFormatHandler(data, id, userOwnership, currentName) : data, exportOptions);
  alertBoxCall(ALERT_BOX_TYPES.DISMISS, null);
  alertBoxCall(ALERT_BOX_TYPES.SUCCESS, message);
};

export const apiExportCsvhandler = (
  reportUrl: string,
  params = {},
  fileName: string,
  dataObj: string = '',
  currentName: string = '',
  id: number = 0,
  userOwnership: string = '',
  type: string = '',
  downloadFormatter = downloadFormatHandlerCA
) => {
  if (reportUrl) {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    const headers: IBlobHeaders = { ...defaultHeader(), responseType: 'blob' };
    apiCall({
      headers,
      params,
      apiPath: type === API_TYPES.NODE ? reportUrl : `${reportUrl}.csv`,
      type,
      action: HttpMethods.GET
    })
      .then((response) => {
        if (!dataObj) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.csv`);
          document.body.appendChild(link);
          link.click();
          alertBoxCall(ALERT_BOX_TYPES.DISMISS, null);
          alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
        } else {
          const data = type === API_TYPES.NODE ? response?.data?.data[dataObj] || response?.data?.data : response?.data[dataObj];
          if (response?.status === 200) {
            if (data) exportCsvhandler(downloadFormatter, data, DONE_SUCCESS_MSG, fileName, currentName, id, userOwnership);
          } else {
            alertBoxCall(ALERT_BOX_TYPES.ERROR, SOMETHING_WENT_WRONG);
          }
        }
      })
      .catch(() => {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, SOMETHING_WENT_WRONG);
      });
  }
};
