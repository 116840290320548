import { Tag } from 'react-tag-autocomplete';

import { postsReducerTypes, apAccountReducerTypes, mgApiTypes, calendarApiTypes, postsApiTypes } from 'types';
import { accountPostPermissionRestricted, atLeastApproved, getModifiedStatusForSavedPost } from 'utils/helpers';

export const confirmEditingAccountPost = (postStatusId: number, isAdmin: boolean, isFranchisor: boolean, isFranchisee: boolean, overriddenCustomPostsLocked: boolean, editingConfirmed: boolean) => {
  const customPostsRestricted = accountPostPermissionRestricted(isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked);
  const isApproved = atLeastApproved(postStatusId);

  // editingConfirmed - If the user is restricted from editing approved posts, the boolean value is used to confirm that he really wants to edit (and un-approve) the post.
  if (customPostsRestricted && isApproved && !editingConfirmed) {
    return true;
  }
  return false;
};

export const accountCanSchedule = (
  isFranchisee: boolean,
  overriddenCustomPostsLocked: boolean,
  savedPostDetails: null | apAccountReducerTypes.IAPSavedPostDetails,
  editSavedPost: postsReducerTypes.IPostDetailsData,
  selectedMediaList: mgApiTypes.IMGListResponseObj[],
  linkData: null | calendarApiTypes.ICouponLinks,
  selectedTags: Tag[],
  campaignData: postsApiTypes.IPostListDetailData[]
) => {
  const attributesMatchSavedPost = savedPostDetails ? !getModifiedStatusForSavedPost(savedPostDetails, editSavedPost, selectedMediaList, linkData, selectedTags, campaignData) : true;
  const savedPostApproved = editSavedPost ? atLeastApproved(editSavedPost.status_id) : false;

  if (isFranchisee && overriddenCustomPostsLocked && !savedPostApproved) {
    return false;
  } else if (isFranchisee && overriddenCustomPostsLocked && !attributesMatchSavedPost) {
    return false;
  } else {
    return true;
  }
};
