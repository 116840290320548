import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { overviewApiType } from 'types';

type IOverviewRequestParams = overviewApiType.IOverviewRequestParams;

// OVERVIEW - LOCATION
export const overviewLocationsRequest = (data: IOverviewRequestParams) => createAction(actionTypes.OVERVIEW_LOCATIONS_REQUEST, data);
export const overviewLocationsResponse = (data: overviewApiType.IOverviewLocationResponseData) => createAction(actionTypes.OVERVIEW_LOCATIONS_RESPONSE, data);
export const overviewLocationsRequestFail = (error: null | string) => createAction(actionTypes.OVERVIEW_LOCATIONS_REQUEST_FAIL, error);

// OVERVIEW - LOCATION DETAILS
export const overviewSetLocListArray = (data: overviewApiType.IOverviewAccountData[]) => createAction(actionTypes.OVERVIEW_SET_LOC_LIST_ARRAY, data);

// OVERVIEW - TOTAL METRICS
export const overviewTotalMetricsRequest = (data: IOverviewRequestParams) => createAction(actionTypes.OVERVIEW_TOTALMETRICS_REQUEST, data);
export const overviewTotalMetricsResponse = (data: overviewApiType.IOverviewTotalMetricsResponse) => createAction(actionTypes.OVERVIEW_TOTALMETRICS_RESPONSE, data);
export const overviewTotalMetricsRequestFail = (error: null | string) => createAction(actionTypes.OVERVIEW_TOTALMETRICS_REQUEST_FAIL, error);

// OVERVIEW - RECENT POSTS LIST
export const overviewRecentPostsRequest = (data: IOverviewRequestParams) => createAction(actionTypes.OVERVIEW_RECENTPOSTS_REQUEST, data);
export const overviewRecentPostsResponse = (data: overviewApiType.IOverviewRecentPostsResponse[]) => createAction(actionTypes.OVERVIEW_RECENTPOSTS_RESPONSE, data);
export const overviewRecentPostsRequestFail = (error: null | string) => createAction(actionTypes.OVERVIEW_RECENTPOSTS_REQUEST_FAIL, error);

// OVERVIEW - POST METRICS
export const overviewPostsMetricsRequest = (data: IOverviewRequestParams) => createAction(actionTypes.OVERVIEW_POSTSMETRICS_REQUEST, data);
export const overviewPostsMetricsResponse = (data: overviewApiType.IOverviewPostMetricsResponse) => createAction(actionTypes.OVERVIEW_POSTSMETRICS_RESPONSE, data);
export const overviewPostsMetricsRequestFail = (error: null | string) => createAction(actionTypes.OVERVIEW_POSTSMETRICS_REQUEST_FAIL, error);

// OVERVIEW - MONTHLY POSTS LIST
export const overviewMonthlyPostsRequest = (data: IOverviewRequestParams) => createAction(actionTypes.OVERVIEW_MONTHLY_POSTS_REQUEST, data);
export const overviewMonthlyPostsResponse = (data: overviewApiType.IOverviewMonthlyPostMetricsResponse) => createAction(actionTypes.OVERVIEW_MONTHLY_POSTS_RESPONSE, data);
export const overviewMonthlyPostsRequestFail = (error: null | string) => createAction(actionTypes.OVERVIEW_MONTHLY_POSTS_REQUEST_FAIL, error);

// OVERVIEW RESET
export const overviewReset = () => createAction(actionTypes.OVERVIEW_RESET);

const OVERVIEW_ACTIONS = {
  overviewLocationsRequest,
  overviewLocationsResponse,
  overviewLocationsRequestFail,
  overviewSetLocListArray,
  overviewTotalMetricsRequest,
  overviewTotalMetricsResponse,
  overviewTotalMetricsRequestFail,
  overviewRecentPostsRequest,
  overviewRecentPostsResponse,
  overviewRecentPostsRequestFail,
  overviewPostsMetricsRequest,
  overviewPostsMetricsResponse,
  overviewPostsMetricsRequestFail,
  overviewMonthlyPostsRequest,
  overviewMonthlyPostsResponse,
  overviewMonthlyPostsRequestFail,
  overviewReset
};

export type OverviewAction = ActionsUnion<typeof OVERVIEW_ACTIONS>;
