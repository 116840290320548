import { put, takeLatest } from 'redux-saga/effects';

import { downloadRequestFailure, downloadResponse, downloadSurveysFilteredRequestFailure, downloadSurveysFilteredResponse, pollExportRequestFailure } from 'revv/actions';
import { IDownloadParams, IDownloadSurveysFilteredParams, IExportRequest, IIdParam, ISagaPayload, SagaValue, SagaYield } from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';
import * as actionTypes from '../../actions/action-types';

const serialiseExportRequest = (response: any): IExportRequest => {
  return {
    id: response.data.data.id,
    completedAt: response.data.data.attributes.completed_at,
    dataType: response.data.data.attributes.data_type,
    exportedCount: response.data.data.attributes.exported_count,
    failedAt: response.data.data.attributes.failed_at,
    filename: response.data.data.attributes.filename,
    queuedAt: response.data.data.attributes.queued_at,
    startedAt: response.data.data.attributes.started_at
  };
};

function* sendDownloadSurveysFilteredRequest(action: ISagaPayload<IDownloadSurveysFilteredParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const defaultParams = {
      from_date: action.payload?.from_date,
      to_date: action.payload?.to_date,
      survey_status: action.payload?.survey_status,
      survey_rating: action.payload?.survey_rating,
      from_transaction_date: action.payload?.from_transaction_date,
      to_transaction_date: action.payload?.to_transaction_date,
      retail_type: action.payload?.retail_type,
      rallio_account_ids: action.payload?.locationIds
    };
    const response = yield apiCall({
      apiPath: API_ROUTES.downloadSurveysFiltered.apiPath,
      action: API_ROUTES.downloadSurveysFiltered.method,
      headers: defaultRevvHeader(),
      params: defaultParams,
      type: API_TYPES.REVV
    });
    if (response.status === 200) {
      const res: IExportRequest = yield serialiseExportRequest(response);
      yield put(downloadSurveysFilteredResponse(res));
    } else {
      yield put(downloadSurveysFilteredRequestFailure('Surveys Filtered download API error.'));
    }
  } catch (error: any) {
    yield put(downloadSurveysFilteredRequestFailure(error));
  }
}

function* sendPollExportRequest(action: ISagaPayload<IIdParam>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.pollExport.apiPath.replace(':id', action.payload.id),
        action: API_ROUTES.pollExport.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV
      });
      if (response.status === 200) {
        const res: IExportRequest = yield serialiseExportRequest(response);
        yield put(downloadResponse(res));
      } else {
        yield put(pollExportRequestFailure('Export polling failed.'));
      }
    }
  } catch (error: any) {
    yield put(pollExportRequestFailure(error));
  }
}

function* sendDownloadRequest(action: ISagaPayload<IDownloadParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const api =
      action.payload?.type === 'locations'
        ? API_ROUTES.downloadLocations
        : action.payload?.type === 'customers'
        ? API_ROUTES.downloadCustomers
        : action.payload?.type === 'surveys'
        ? API_ROUTES.downloadSurveys
        : action.payload?.type === 'users'
        ? API_ROUTES.downloadUsers
        : API_ROUTES.downloadCustomers;

    const apiParams = {
      type: action.payload?.type,
      date_range: `${action.payload?.from_date}-${action.payload?.to_date}`
    };
    const apiSurveyParams = {
      type: action.payload?.type,
      date_range: `${action.payload?.from_date}-${action.payload?.to_date}`,
      rallio_account_ids: action.payload?.locationIds
    };
    const response = yield apiCall({
      apiPath: api.apiPath,
      action: api.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: action.payload?.type === 'surveys' ? apiSurveyParams : apiParams,
      data: {
        rallio_franchisor_ids: action.payload?.rallioFranchisorIds,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_brand_id: action.payload?.rallioBrandId
      }
    });
    if (response.status === 200) {
      const res: IExportRequest = yield serialiseExportRequest(response);
      yield put(downloadResponse(res));
    } else {
      yield put(downloadRequestFailure('Report download API error.'));
    }
  } catch (error: any) {
    yield put(downloadRequestFailure(error));
  }
}

export function* takeDownloadRequest() {
  yield takeLatest(actionTypes.DOWNLOAD_SURVEYS_FILTERED_REQUEST, sendDownloadSurveysFilteredRequest);
  yield takeLatest(actionTypes.POLL_EXPORT_REQUEST, sendPollExportRequest);
  yield takeLatest(actionTypes.DOWNLOAD_REQUEST, sendDownloadRequest);
}
