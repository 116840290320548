import {
  IListRequestParams,
  IUploadParams,
  IUserDetailViewResponse,
  IUserListResponse,
  IUserRequestParams,
  IUsersMetricsResponse,
  ITeamLocation,
  IUpdateUserDetailsProps,
  IUserMetricsParams,
  IProfileSettingRequest,
  IProfileAttributes
} from 'revv/types';

import { ActionsUnion, createAction } from 'revv/utils';
import * as actionTypes from 'revv/actions/action-types';
import { COMMON_ACTIONS } from '../common';

export const userRequest = (payload: IUserRequestParams) => createAction(actionTypes.USER_REQUEST, payload);
export const userRequestFailure = (payload: string) => createAction(actionTypes.USER_FAILURE, payload);
export const userResponse = (payload: IUserDetailViewResponse) => createAction(actionTypes.USER_RESPONSE, payload);

export const userListRequest = (payload: IListRequestParams) => createAction(actionTypes.USER_LIST_REQUEST, payload);
export const userListRequestFailure = (payload: string) => createAction(actionTypes.USER_LIST_FAILURE, payload);
export const userListResponse = (payload: IUserListResponse[]) => createAction(actionTypes.USER_LIST_RESPONSE, payload);

export const userMetricsRequest = (payload?: IUserMetricsParams) => createAction(actionTypes.USER_METRICS_REQUEST, payload);
export const userMetricsRequestFailure = (payload: string) => createAction(actionTypes.USER_METRICS_FAILURE, payload);
export const userMetricsResponse = (payload: IUsersMetricsResponse) => createAction(actionTypes.USER_METRICS_RESPONSE, payload);

export const userCreateRequest = (payload: IUpdateUserDetailsProps) => createAction(actionTypes.USER_CREATE_REQUEST, payload);
export const userCreateFailure = (payload: string) => createAction(actionTypes.USER_CREATE_FAILURE, payload);
export const userCreateResponse = () => createAction(actionTypes.USER_CREATE_RESPONSE);

export const userDetailsUpdateRequest = (payload: IUpdateUserDetailsProps) => createAction(actionTypes.USER_DETAILS_UPDATE_REQUEST, payload);
export const userDetailsUpdateFailure = (payload: string) => createAction(actionTypes.USER_DETAILS_UPDATE_FAILURE, payload);
export const userDetailsUpdateResponse = (payload: IUserDetailViewResponse) => createAction(actionTypes.USER_DETAILS_UPDATE_RESPONSE, payload);

export const userDeleteRequest = (payload: IUserRequestParams) => createAction(actionTypes.USER_DELETE_REQUEST, payload);
export const userDeleteRequestFailure = (payload: string) => createAction(actionTypes.USER_DELETE_FAILURE, payload);
export const userDeleteResponse = () => createAction(actionTypes.USER_DELETE_RESPONSE);

export const userUploadRequest = (payload: IUploadParams) => createAction(actionTypes.USER_UPLOAD_REQUEST, payload);
export const userUploadRequestFailure = (payload: string) => createAction(actionTypes.USER_UPLOAD_FAILURE, payload);
export const userUploadResponse = (payload: string) => createAction(actionTypes.USER_UPLOAD_RESPONSE, payload);

export const userProfileSettingsRequest = (payload: IProfileSettingRequest) => createAction(actionTypes.PROFILE_SETTINGS_REQUEST, payload);
export const userProfileSettingsFailure = (payload: string) => createAction(actionTypes.PROFILE_SETTINGS_FAILURE, payload);
export const userProfileSettingsResponse = (payload: IProfileAttributes) => createAction(actionTypes.PROFILE_SETTINGS_RESPONSE, payload);

export const updateUsersLocation = (payload: ITeamLocation[]) => createAction(actionTypes.UPDATE_USERS_SELECTED_LOCATION, payload);

export const userResetUploadSuccessMessage = () => createAction(actionTypes.USER_RESET_UPLOAD_SUCCESS_MESSAGE);

const USER_ACTIONS = {
  ...COMMON_ACTIONS,
  userRequest,
  userRequestFailure,
  userResponse,
  userListRequest,
  userListRequestFailure,
  userListResponse,
  userMetricsRequest,
  userMetricsRequestFailure,
  userMetricsResponse,
  userCreateRequest,
  userCreateFailure,
  userCreateResponse,
  userDetailsUpdateRequest,
  userDetailsUpdateFailure,
  userDetailsUpdateResponse,
  userDeleteRequest,
  userDeleteRequestFailure,
  userDeleteResponse,
  userUploadRequest,
  userUploadRequestFailure,
  userUploadResponse,
  updateUsersLocation,
  userResetUploadSuccessMessage,
  userProfileSettingsRequest,
  userProfileSettingsFailure,
  userProfileSettingsResponse
};

export type UserActions = ActionsUnion<typeof USER_ACTIONS>;
