import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import * as types from 'actions/action-types';
import {
  cloudSignFailure,
  exceptionHandlerRequest,
  getCurrentUserDetailsFailure,
  getCurrentUserDetailsResponse,
  loadConfigurationResponse,
  logoutRequest,
  updateCurrentUserDetailsFailure,
  updateCurrentUserDetailsResponse,
  uploadCurrentUserCloudProfileImgResponse,
  getLoginUserDataResponse,
  getCurrentUserTabListFailure,
  getCurrentUserTabListResponse
} from 'actions';
import { apiCall, defaultHeader, CLOUDINARY_API, NODE_API, API, cloudinaryHeader } from 'utils/helpers';
import {
  CLOUD_SIGN_FAILED,
  AWS_REK_TAGGING_CATEGORY,
  API_TYPES,
  PAGINATION_PER_PAGE,
  GET_USERS_DETAIL_FAILED,
  USER_DETAIL_EXCEPTION_ERROR,
  UPDATE_USERS_DETAIL_FAILED,
  LOAD_ENV_VARS_FAILED,
  ALERT_BOX_TYPES,
  MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED,
  GET_CURRENT_USER_TAB_LIST_FETCHING_FAILED,
  USER_DETAIL_MODAL_TAB_KEY
} from 'utils/constants';
import { ISagaPayload } from 'types/common/api';
import { config } from 'config';
import { alertBoxCall } from 'components';
import { store } from 'store';

function* sendCloudSignRequest({ payload }: ISagaPayload): any {
  try {
    const params = {
      timestamp: payload.unixTimestamp,
      categorization: AWS_REK_TAGGING_CATEGORY
    };
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params, ...CLOUDINARY_API.getSignature });
    if (response.status === 200) {
      payload.onSignatureCallback({
        timestamp: response.data.timestamp,
        apikey: response.data.api_key,
        signature: response.data.signature,
        eager: response.data.eager
      });
    } else {
      yield put(cloudSignFailure(CLOUD_SIGN_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CLOUD_SIGN_FAILED));
  }
}

function* loadEnvVars(): any {
  try {
    const response = yield apiCall({ headers: defaultHeader(), type: API_TYPES.NODE, ...NODE_API.getEnv });
    if (response.status === 401 || response.status === 403) {
      indexedDB.deleteDatabase('localforage');
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const encryptedInfo = response.data.data;
      yield put(loadConfigurationResponse(encryptedInfo));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LOAD_ENV_VARS_FAILED));
  }
}

function* sendGetCurrentUserDetailsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, apiPath: API.getUserDetail.apiPath.replace(':id', payload), action: API.getUserDetail.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getCurrentUserDetailsResponse(response.data.user));
    } else {
      yield put(getCurrentUserDetailsFailure(GET_USERS_DETAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_DETAIL_EXCEPTION_ERROR));
  }
}

function* sendUpdateCurrentUserDetailsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { id, ...newPayload } = payload.reqPayload;
    const activeUserId = store.getState().login.activeUser?.id;
    const response = yield apiCall({
      headers,
      data: { user: newPayload },
      apiPath: API.saveNotificationSettings.apiPath.replace(':id', payload.id),
      action: API.saveNotificationSettings.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateCurrentUserDetailsResponse(response.data.user));
      if (id === activeUserId) {
        yield put(getLoginUserDataResponse(response.data.user));
      }
    } else {
      yield put(updateCurrentUserDetailsFailure(UPDATE_USERS_DETAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_DETAIL_EXCEPTION_ERROR));
  }
}

function* sendGetCurrentUserTabListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    const params = {
      page: payload.page,
      ...(payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.REWARDS ? { perPage: PAGINATION_PER_PAGE } : { per: PAGINATION_PER_PAGE }),
      ...(payload.franchisorId && (payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.REWARDS ? { franchisorId: payload.franchisorId } : { franchisor_id: payload.franchisorId })),
      ...(payload.ancestorFranchisorId && { ancestor_franchisor_id: payload.ancestorFranchisorId }),
      ...(payload.accountId && (payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.REWARDS ? { accountId: payload.accountId } : { account_id: payload.accountId })),
      ...(![USER_DETAIL_MODAL_TAB_KEY.REWARDS, USER_DETAIL_MODAL_TAB_KEY.POSTS].includes(payload.mediaType) && { user_id: payload.userId }),
      ...(payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.POSTS && payload?.mobileEmployee ? { pp_user_id: payload.userId } : { user_id: payload.userId })
    };

    const mediaAPIObj =
      payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.PHOTOS
        ? API.mgGetPhotosAssetsList
        : payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.VIDEOS
        ? API.mgGetVideosAssetsList
        : API.getAIPlaybookDocumentsList;
    if ([USER_DETAIL_MODAL_TAB_KEY.PHOTOS, USER_DETAIL_MODAL_TAB_KEY.VIDEOS, USER_DETAIL_MODAL_TAB_KEY.DOCUMENTS].includes(payload.mediaType)) {
      response = yield apiCall({ headers, params: { ...params, source: payload.source, search: payload.search }, ...mediaAPIObj });
    } else if (payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.POSTS) {
      response = yield apiCall({ headers, params: { ...params, text: payload.search }, ...API.getSavedPost });
    } else if (payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.REWARDS) {
      response = yield apiCall({
        headers,
        params: { ...params, searchText: payload.search },
        apiPath: NODE_API.getUserRewardsListData.apiPath.replace(':userId', payload.userId),
        action: NODE_API.getUserRewardsListData.action,
        type: API_TYPES.NODE
      });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        getCurrentUserTabListResponse({
          ...([USER_DETAIL_MODAL_TAB_KEY.PHOTOS, USER_DETAIL_MODAL_TAB_KEY.VIDEOS, USER_DETAIL_MODAL_TAB_KEY.DOCUMENTS].includes(payload.mediaType) && {
            assetsList: 'photos' in response.data ? response.data.photos || [] : 'videos' in response.data ? response.data.videos || [] : response.data.documents || []
          }),
          ...(payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.POSTS && { postsList: response.data.saved_posts || [] }),
          ...(payload.mediaType === USER_DETAIL_MODAL_TAB_KEY.REWARDS && { rewardsList: response.data.data.userRewardsList || [] })
        })
      );
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, GET_CURRENT_USER_TAB_LIST_FETCHING_FAILED);
      yield put(getCurrentUserTabListFailure(GET_CURRENT_USER_TAB_LIST_FETCHING_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_DETAIL_EXCEPTION_ERROR));
  }
}

function* sendUploadCurrentUserCloudProfileImgRequest({ payload }: ISagaPayload): any {
  const { file, signature, apiKey, timestamp, eager, uploadProgressCallback, successCallback } = payload;
  try {
    const resourceType = '/auto';
    const headers = cloudinaryHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('api_key', apiKey);
    bodyFormData.append('file', file);
    bodyFormData.append('signature', signature);
    bodyFormData.append('timestamp', timestamp);
    bodyFormData.append('eager', eager);

    const url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${CLOUDINARY_API.cloudinaryImageUpload.apiPath}`;

    const requestConfig = {
      url,
      method: CLOUDINARY_API.cloudinaryImageUpload.action,
      data: bodyFormData,
      headers,
      timeout: 0,
      validateStatus(status: number) {
        return status >= 200 && status <= 500;
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (uploadProgressCallback) {
          uploadProgressCallback(progressEvent);
        }
      }
    };
    const response = yield axios(requestConfig);
    if (successCallback) {
      successCallback(response.data);
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        uploadCurrentUserCloudProfileImgResponse({
          cloudinaryId: response.data.public_id,
          photoUrl: response.data.secure_url
        })
      );
    } else {
      if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
      else alertBoxCall(ALERT_BOX_TYPES.ERROR, MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED);
      yield put(
        uploadCurrentUserCloudProfileImgResponse({
          cloudinaryId: null,
          photoUrl: null
        })
      );
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MG_UPLOAD_MEDIA_IN_CLOUDINARY_FAILED));
  }
}

export function* takeCommonRequest() {
  yield takeEvery(types.CLOUD_SIGN_REQUEST, sendCloudSignRequest);
  yield takeEvery(types.LOAD_ENV_CONFIGURATION_REQUEST, loadEnvVars);
  yield takeLatest(types.GET_CURRENT_USER_DETAILS_REQUEST, sendGetCurrentUserDetailsRequest);
  yield takeLatest(types.UPDATE_CURRENT_USER_DETAILS_REQUEST, sendUpdateCurrentUserDetailsRequest);
  yield takeLatest(types.GET_CURRENT_USER_TAB_LIST_REQUEST, sendGetCurrentUserTabListRequest);
  yield takeLatest(types.UPLOAD_CURRENT_USER_CLOUD_PROFILE_IMG_REQUEST, sendUploadCurrentUserCloudProfileImgRequest);
}
