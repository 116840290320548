import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { MenuAccessiblityAction } from 'actions';
import { MEDIA_INITIAL_MENU_VALUE } from 'utils/constants';
import { menuAccessReducerTypes } from 'types';

type IMenuAccessibleReducer = menuAccessReducerTypes.IMenuAccessibleReducer;

const initialState: IMenuAccessibleReducer = {
  isDataFetching: false,
  error: null,
  menuAvailablity: MEDIA_INITIAL_MENU_VALUE,
  accessiblityResponse: null
};

const menuAccessibleReducer: Reducer<IMenuAccessibleReducer, MenuAccessiblityAction> = (state: IMenuAccessibleReducer = initialState, action: MenuAccessiblityAction): IMenuAccessibleReducer => {
  switch (action.type) {
    case types.MENU_ACCESSIBLE_REQUEST:
      return {
        ...state,
        isDataFetching: true,
        error: null
      };
    case types.MENU_ACCESSIBLE_RESPONSE:
      return {
        ...state,
        isDataFetching: false,
        accessiblityResponse: action.payload
      };
    case types.MENU_ACCESSIBLE_REQUEST_FAILURE:
      return {
        ...state,
        isDataFetching: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.SET_MENU_ACCESSIBILITY:
      return {
        ...state,
        menuAvailablity: action.payload
      };
    default:
      return state;
  }
};
export { menuAccessibleReducer };
