import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { rssFeedsApiTypes } from 'types';

// RSS FEEDS LIST REQUEST
export const rssFeedsListsRequest = (payload: rssFeedsApiTypes.IRssFeedsRequest) => createAction(actionTypes.RSS_FEEDS_LIST_REQUEST, payload);
export const rssFeedsListsResponse = (payload: rssFeedsApiTypes.IRssFeedsResponse[]) => createAction(actionTypes.RSS_FEEDS_LIST_RESPONSE, payload);
export const rssFeedsListsFailure = (error: null | string) => createAction(actionTypes.RSS_FEEDS_LIST_FAILURE, error);

// RSS FEEDS RESET ALL
export const rssFeedsResetAll = () => createAction(actionTypes.RSS_FEEDS_RESET_ALL);

const RSS_FEEDS_ACTIONS = {
  rssFeedsListsRequest,
  rssFeedsListsResponse,
  rssFeedsListsFailure,
  rssFeedsResetAll
};

export type RssFeedsAction = ActionsUnion<typeof RSS_FEEDS_ACTIONS>;
