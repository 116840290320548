import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { OVERVIEW_PLATFORM_FILTER, OVERVIEW_ANALYTICS_FILTER_INITIAL_VALUE, ExportCsvFileName, ValidQueryParams, OVERVIEW_STATS } from 'analytics/utils';
import { exportCsvhandler, downloadFormatHandler, handleFilterChange } from 'analytics/utils/helpers';
import { alertBoxCall, CommonFilter } from 'components';
import {
  BUTTON_PROPS,
  EXPORT_DOWNLOAD_STARTED_MSG,
  USER_OWNERSHIP,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  ALERT_BOX_TYPES,
  DONE_SUCCESS_MSG,
  MAIN_NAV_BAR_NAME
} from 'utils/constants';
import { useAvailablePlatforms, useAccountSwitcherData, useParamsDeconstructor, useParamsValidator, useNavBarData } from 'utils/hooks';
import { IFiterHandleChangeData } from 'types/common/filter';

export const OverviewFilter = () => {
  const navigate = useNavigate();
  const { queryParams, filter } = useParamsDeconstructor(OVERVIEW_ANALYTICS_FILTER_INITIAL_VALUE);
  const { id, userOwnership } = useAccountSwitcherData();
  const availablePlatform = useAvailablePlatforms(OVERVIEW_PLATFORM_FILTER, userOwnership);
  const { activeMainPages } = useNavBarData();

  const data = useSelector((state: IStore) => state.analyticsOverview.data);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const isOverviewAnalyticsFetching = useSelector((state: IStore) => state.analyticsOverview.isOverviewAnalyticsFetching);
  const isOverviewAnalyticsSummaryFetching = useSelector((state: IStore) => state.analyticsOverview.isOverviewAnalyticsSummaryFetching);

  const queryParamKeys = Object.keys(queryParams).filter((it) => it !== 'selected_tile');
  const isClearFilterDisabled = availablePlatform.length === 1 ? (queryParamKeys?.length === 1 && queryParams?.platform ? true : false) : !queryParamKeys?.length;
  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  // For current route validation with default params
  const isAnalyticsMenuActive = useMemo(() => {
    return activeMainPages.map((it) => it.name).includes(MAIN_NAV_BAR_NAME.ANALYTICS);
  }, [activeMainPages]);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams, availablePlatform);
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  useParamsValidator(
    { ...CommonValidQueryParams, ...ValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      selected_tile: OVERVIEW_STATS.map((it) => it.value),
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || '')
    }
  );

  useEffect(() => {
    if (availablePlatform.length === 1 && !queryParams?.platform && isAnalyticsMenuActive) {
      const params = { platform: availablePlatform[0].value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, queryParams?.platform, isAnalyticsMenuActive]); // eslint-disable-line

  const handleExportCsv = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    exportCsvhandler(downloadFormatHandler, data, DONE_SUCCESS_MSG, ExportCsvFileName.SA, currentName, id, userOwnership);
  };

  return (
    <CommonFilter
      filter={filter}
      handleChange={handleChange}
      isLocation={isHubUser}
      handleExportCsv={handleExportCsv}
      primaryButtons={[
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled || isOverviewAnalyticsFetching || isOverviewAnalyticsSummaryFetching
        }
      ]}
      analyticsPlatformFilter={availablePlatform}
    />
  );
};
