import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IStore } from 'types';
import { ALERT_BOX_TYPES, SIDE_NAV_NAME } from 'utils/constants';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { alertBoxCall } from 'components';

export const CampaignPostsDetailViewSection = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();
  const { mainNavPageName, subNavPageName } = useNavBarData();

  const isCampaignsTab = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity?.content?.campaigns || false);
  const campaigns = useSelector((state: IStore) => state.posts.postsDetails?.campaigns || []);

  const handleNavigateToCampaign = (campaignId: number) => {
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS) {
      if (isCampaignsTab) {
        navigate(`/${userOwnership}/${id}/${mainNavPageName}/${SIDE_NAV_NAME.CONTENT_CAMPAIGNS}/${campaignId}`);
      } else {
        alertBoxCall(ALERT_BOX_TYPES.WARN, "You don't have access to configure content campaigns, Please contact your admin.");
      }
    }
  };

  return campaigns?.length ? (
    <div className={`pd-field-group r-flx-ac${subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? ` cd-data` : ''}`}>
      <div className="wb-label">
        <span className="wbl-head">Campaign Details</span>
      </div>

      <div className="wb-f-main">
        <div className="wb-fields">
          <div className="wbf-item">
            <div className="wbf-label-main">
              <span className="wbf-label">Name</span>
            </div>
            <div className="wbf-inputs cdn-value">
              {campaigns.map((campItem, index) => {
                return (
                  <div key={`campaign-tags-${index}`} className="wbfi-items cur-pointer" onClick={() => handleNavigateToCampaign(campItem.id)}>
                    <span className="wbfi-txt blue-text">
                      <span>{campItem.name || ''}</span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
