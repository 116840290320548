import { campaignsReducerTypes } from 'types';

export const CAMPAIGN_POSTS_INIT_STATS_DATA: campaignsReducerTypes.ICampaignPostsStats = {
  total: 0,
  active: 0,
  inactive: 0,
  available: 0
};

export const CAMPAIGN_POSTS_INIT_RESPONSE_MSG: campaignsReducerTypes.ICampaignPostsResponseMsg = {
  successMsg: null,
  failureMsg: null
};
