import TextareaAutosize from 'react-textarea-autosize';

import { commonWidgetTypes } from 'types';

export const TextArea = ({
  id,
  ref,
  rows,
  minRows,
  maxRows,
  className,
  placeholder,
  defaultValue,
  defaultChecked,
  value,
  onFocus,
  onClick,
  autoFocus,
  onChange,
  maxLength,
  disabled,
  spellCheck = true,
  title
}: commonWidgetTypes.ITextAreaProps) => {
  return (
    <TextareaAutosize
      id={id}
      ref={ref}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      defaultChecked={defaultChecked}
      value={value}
      onFocus={onFocus}
      onClick={onClick}
      autoFocus={autoFocus}
      onChange={onChange}
      maxLength={maxLength}
      disabled={disabled}
      spellCheck={spellCheck}
      title={title}
    />
  );
};
