import { inboxReducerTypes } from 'types';

export const INBOX_COMMENTS_INIT_OBJ: inboxReducerTypes.IInboxCommentsObj = {
  socialContentId: 0,
  activeCommentsIds: [],
  modifiedComments: null,
  pageCount: {
    totalPage: 0,
    currentPage: 0
  }
};
