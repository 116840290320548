import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { loginReducerType, userLoginsApiTypes } from 'types';

// GET UL ACCOUNT OWNERS LIST
export const getULAccountOwnersRequest = (payload: userLoginsApiTypes.IULAccountOwnersRequest) => createAction(actionTypes.UL_GET_ACCOUNT_OWNERS_REQUEST, payload);
export const getULAccountOwnersResponse = (data: userLoginsApiTypes.IULAccountOwnersResponseObj[]) => createAction(actionTypes.UL_GET_ACCOUNT_OWNERS_RESPONSE, data);
export const getULAccountOwnersFailure = (error: null | string) => createAction(actionTypes.UL_GET_ACCOUNT_OWNERS_FAILURE, error);

// ADD UL ACCOUNT OWNERS
export const addULAccountOwnersResponse = (data: userLoginsApiTypes.IULAccountOwnersResponse) => createAction(actionTypes.UL_ADD_ACCOUNT_OWNERS_RESPONSE, data);
export const addULAccountOwnersFailure = (error: null | string) => createAction(actionTypes.UL_ADD_ACCOUNT_OWNERS_FAILURE, error);

// UPDATE UL ACCOUNT OWNERS
export const updateULAccountOwnersResponse = (data: loginReducerType.ILoginResponseUser) => createAction(actionTypes.UL_UPDATE_ACCOUNT_OWNERS_RESPONSE, data);
export const updateULAccountOwnersFailure = (error: null | string) => createAction(actionTypes.UL_UPDATE_ACCOUNT_OWNERS_FAILURE, error);

// CHECK EMAIL FOR ACCOUNT OWNERS
export const accountOwnersCheckEmailRequest = (payload: userLoginsApiTypes.IAccountOwnersCheckEmailRequest) => createAction(actionTypes.ACCOUNT_OWNERS_CHECK_EMAIL_REQUEST, payload);
export const accountOwnersCheckEmailResponse = (data: userLoginsApiTypes.IAccountOwnersCheckEmailResponse) => createAction(actionTypes.ACCOUNT_OWNERS_CHECK_EMAIL_RESPONSE, data);
export const accountOwnersCheckEmailFailure = (error: null | string) => createAction(actionTypes.ACCOUNT_OWNERS_CHECK_EMAIL_FAILURE, error);

// USER LOGINS - DELETE ACCOUNT OWNERS
export const deleteULAccountOwnersRequest = (payload: userLoginsApiTypes.IULDeleteAccountOwnerRequest) => createAction(actionTypes.UL_DELETE_ACCOUNT_OWNERS_REQUEST, payload);
export const deleteULAccountOwnersResponse = () => createAction(actionTypes.UL_DELETE_ACCOUNT_OWNERS_RESPONSE);
export const deleteULAccountOwnersFailure = (error: null | string) => createAction(actionTypes.UL_DELETE_ACCOUNT_OWNERS_FAILURE, error);

// USER LOGINS - RESEND INVITE FOR USERS
export const ulResendInviteForUserRequest = (payload: number) => createAction(actionTypes.UL_RESEND_INVITE_FOR_USER_REQUEST, payload);
export const ulResendInviteForUserResponse = (data: null) => createAction(actionTypes.UL_RESEND_INVITE_FOR_USER_RESPONSE, data);
export const ulResendInviteForUserFailure = (error: null | string) => createAction(actionTypes.UL_RESEND_INVITE_FOR_USER_FAILURE, error);

// CHECK EMAIL SET VALIDATION
export const checkEmailSetValidation = (payload: userLoginsApiTypes.IAccountOwnersCheckEmailResponse) => createAction(actionTypes.ACCOUNT_OWNERS_CHECK_EMAIL_SET_VALIDATION, payload);

// USER LOGINS - SET ADD NEW USER
export const ulSetIsAddNewUser = (data: boolean) => createAction(actionTypes.UL_SET_IS_ADD_NEW_USER, data);

// USER LOGINS - RESET ADD NEW USER
export const ulIsAddNewUserReset = () => createAction(actionTypes.UL_RESET_ADD_NEW_USER);

// UL RESET
export const ulDeleteDataReset = () => createAction(actionTypes.UL_DELETE_DATA_RESET);
export const ulAddNewUserDetailReset = () => createAction(actionTypes.UL_ADD_NEW_USER_DETAIL_RESET);
export const ulResendInviteDataReset = () => createAction(actionTypes.UL_RESEND_INVITE_DATA_RESET);
export const ulMessagesReset = () => createAction(actionTypes.UL_MESSAGES_RESET);
export const ulResetAll = () => createAction(actionTypes.UL_RESET_ALL);

const USER_LOGINS_ACTIONS = {
  getULAccountOwnersRequest,
  getULAccountOwnersResponse,
  getULAccountOwnersFailure,
  accountOwnersCheckEmailRequest,
  accountOwnersCheckEmailResponse,
  accountOwnersCheckEmailFailure,
  addULAccountOwnersResponse,
  addULAccountOwnersFailure,
  updateULAccountOwnersResponse,
  updateULAccountOwnersFailure,
  deleteULAccountOwnersRequest,
  deleteULAccountOwnersResponse,
  deleteULAccountOwnersFailure,
  ulResendInviteForUserRequest,
  ulResendInviteForUserResponse,
  ulResendInviteForUserFailure,
  checkEmailSetValidation,
  ulSetIsAddNewUser,
  ulIsAddNewUserReset,
  ulDeleteDataReset,
  ulAddNewUserDetailReset,
  ulResendInviteDataReset,
  ulMessagesReset,
  ulResetAll
};

export type UserLoginsAction = ActionsUnion<typeof USER_LOGINS_ACTIONS>;
