import { Card } from 'react-bootstrap';

import { addPostGetSelectedContentObj } from 'actions';
import { apFranContainerTypes } from 'types';
import { AP_DATE_SELECTION_TYPE, DATEPICKER_DATE_ONLY_FORMAT, FranCreatorScheduleDateType, ICommonDateTypes } from 'utils/constants';
import { CustomDatePicker } from 'widgets/DatePicker';
import { getCurrentDate } from 'utils/helpers';

export const FranSyndicateScheduleTiming = ({ contentObj, dispatch, handleDateChange }: apFranContainerTypes.IFranSyndicateScheduleLocProps) => {
  return (
    <Card className="glbl__modal--card">
      <Card.Header>When can this post be scheduled</Card.Header>
      <Card.Body>
        <div className="tip-section-wrp">
          {AP_DATE_SELECTION_TYPE.map((listItem, index) => {
            return (
              <div
                className={`form-check ${
                  listItem.name === FranCreatorScheduleDateType.ANYTIME && contentObj?.franSyndicationMinMaxDate?.minDate && contentObj?.franSyndicationMinMaxDate?.maxDate ? 'pointer-events-none' : ''
                }`}
                key={`creator-date-type-${index}`}
              >
                <label className={`r-flx r-flx-ac${listItem.name === contentObj.dateType ? ` active` : ``}`}>
                  <input
                    type="radio"
                    name="dateType"
                    className="option-input radio"
                    checked={listItem.name === contentObj.dateType}
                    onChange={(event) =>
                      dispatch(
                        addPostGetSelectedContentObj({
                          ...contentObj,
                          [event.target.name]: listItem.name
                        })
                      )
                    }
                  />

                  <div className="syn__tip--lbl">{listItem.label}</div>
                  <span className="checkmark" />
                </label>
              </div>
            );
          })}
        </div>
        {contentObj.dateType === FranCreatorScheduleDateType.CUSTOM ? (
          <>
            <div className="when-section-grid">
              <div className="section-01">
                <div className="form-group form-field-item">
                  <CustomDatePicker
                    selectedDate={contentObj?.customDate?.fromDate?.selected || null}
                    dateRangeType={ICommonDateTypes.FROM_DATE}
                    dateRangeValue={contentObj.customDate}
                    onChange={(date) => handleDateChange(date, ICommonDateTypes.FROM_DATE)}
                    dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                    customClassname="fltr-date-item fltr-from-date"
                    placeholderText="Start Date"
                    minDate={contentObj?.franSyndicationMinMaxDate?.minDate || getCurrentDate()}
                    maxDate={contentObj?.franSyndicationMinMaxDate?.maxDate || contentObj?.customDate?.toDate?.selected || null}
                  />
                </div>
              </div>
              <div className="section-02">
                <div className="form-group form-field-item">
                  <CustomDatePicker
                    selectedDate={contentObj?.customDate?.toDate?.selected || null}
                    dateRangeType={ICommonDateTypes.TO_DATE}
                    dateRangeValue={contentObj.customDate}
                    onChange={(date) => handleDateChange(date, ICommonDateTypes.TO_DATE)}
                    dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                    customClassname="fltr-date-item fltr-from-date fltr-pt-time"
                    placeholderText="End Date"
                    minDate={
                      !contentObj?.customDate?.fromDate?.selected && typeof contentObj?.customDate?.fromDate?.selected !== 'string'
                        ? getCurrentDate()
                        : contentObj?.customDate?.fromDate?.selected && typeof contentObj?.customDate?.fromDate?.selected !== 'string'
                        ? contentObj?.customDate?.fromDate?.selected
                        : contentObj?.franSyndicationMinMaxDate?.minDate
                        ? contentObj?.franSyndicationMinMaxDate?.minDate
                        : null
                    }
                    maxDate={contentObj?.franSyndicationMinMaxDate?.maxDate || null}
                  />
                </div>
              </div>
            </div>
            <div className="auto-delete-section">
              <div className="form-check">
                <label className="checkbox-item">
                  <input
                    type="checkbox"
                    className="option-input checkbox"
                    name="isDeleteAutomatically"
                    checked={contentObj.isDeleteAutomatically}
                    onChange={(event) =>
                      dispatch(
                        addPostGetSelectedContentObj({
                          ...contentObj,
                          [event.target.name]: event.target.checked
                        })
                      )
                    }
                  />
                  <div className="label-txt"> Automatically delete from Facebook and X (Formerly Twitter) when expired</div>
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
};
