import { combineReducers } from 'redux';

import {
  loginReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  errorBoundaryReducer,
  commonReducer,
  accountSwitcherReducer,
  menuAccessibleReducer,
  postsReducer,
  mediaGalleryReducer,
  settingsRallioProfileReducer,
  accountsReducer,
  addPostAccountReducer,
  inboxReducer,
  settingsSocialProfileReducer,
  calendarReducer,
  reviewsReducer,
  addPostFranReducer,
  addPostFranActionsReducer,
  franchisorsReducer,
  directoryListingsReducer,
  userLoginsReducer,
  connectionsReducer,
  profileImageryReducer,
  rewardProgramsReducer,
  advocacyOnboardReducer,
  fbAdsTargetingReducer,
  overviewReducer,
  advocacayLeaderboardReducer,
  stripeReducer,
  couponsReducer,
  changePasswordReducer,
  adminAreaReducer,
  rssFeedsReducer,
  operationsListsReducer,
  targetingReducer,
  salesReducer,
  campaignsReducer,
  aiContentCreatorReducer,
  socialContentsReducer,
  settingsAIPlayBookReducer,
  communicationsReducer,
  aiAssistantReducer,
  aiImageGenerationReducer,
  settingsPartnerBannerReducer,
  aiReviewResponderReducer
} from 'reducers';
import { reviewAnalyticsReducer } from 'analytics/reducers';
import { IStore } from 'types';
import { profileAnalyticsReducer } from 'analytics/reducers/modules/profile-analytics';
import { contenteAnalyticsReducer } from 'analytics/reducers/modules/content-analytics';
import { overviewReducer as overviewAnalytics } from 'analytics/reducers/modules/overview-analytics';
import { leaderBoardAnalyticsReducer } from 'analytics/reducers/modules/leader-board-analytics';
import { revvRootReducer } from 'revv/reducers';

const appReducer = combineReducers<IStore>({
  login: loginReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  errorBoundary: errorBoundaryReducer,
  common: commonReducer,
  accountSwitcher: accountSwitcherReducer,
  menuAccessiblity: menuAccessibleReducer,
  posts: postsReducer,
  campaigns: campaignsReducer,
  mediaGallery: mediaGalleryReducer,
  rallioProfile: settingsRallioProfileReducer,
  accounts: accountsReducer,
  addPostAccount: addPostAccountReducer,
  addPostFranchisor: addPostFranReducer,
  addPostFranchisorAction: addPostFranActionsReducer,
  inbox: inboxReducer,
  socialProfile: settingsSocialProfileReducer,
  calendar: calendarReducer,
  reviews: reviewsReducer,
  franchisors: franchisorsReducer,
  directoryListings: directoryListingsReducer,
  reviewAnalytics: reviewAnalyticsReducer,
  profileAnalytics: profileAnalyticsReducer,
  contentAnalytics: contenteAnalyticsReducer,
  leaderBoardAnalytics: leaderBoardAnalyticsReducer,
  userLogins: userLoginsReducer,
  connections: connectionsReducer,
  profileImagery: profileImageryReducer,
  rewardPrograms: rewardProgramsReducer,
  advocacyOnboard: advocacyOnboardReducer,
  fbAdsTargeting: fbAdsTargetingReducer,
  overview: overviewReducer,
  advLeaderboard: advocacayLeaderboardReducer,
  stripe: stripeReducer,
  analyticsOverview: overviewAnalytics,
  coupons: couponsReducer,
  changePassword: changePasswordReducer,
  adminArea: adminAreaReducer,
  rssFeeds: rssFeedsReducer,
  operationsList: operationsListsReducer,
  targeting: targetingReducer,
  sales: salesReducer,
  revv: revvRootReducer,
  aiContentCreator: aiContentCreatorReducer,
  socialContents: socialContentsReducer,
  aiPlayBook: settingsAIPlayBookReducer,
  aiImageGeneration: aiImageGenerationReducer,
  communications: communicationsReducer,
  aiAssistant: aiAssistantReducer,
  partnerBanners: settingsPartnerBannerReducer,
  aiReviewResponder: aiReviewResponderReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'users/LOGOUT_RESPONSE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export { rootReducer };
