import { useState, useEffect } from 'react';
import { Alert, Tabs, Tab, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe, ReactStripeElements, StripeProvider } from 'react-stripe-elements';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/swiper.min.css';

import { config } from 'config';
import { rpContainerTypes, IStore, rpApiTypes, stripeReducerTypes } from 'types';
import {
  RP_PAYMENT_VALIDATION,
  RP_PAYMENT_TABS,
  RP_PAYMENT_DEFAULT_CVC_PAYMENT,
  RpPaymentField,
  RP_ACTIONS_TYPE,
  RP_PAYMENT_CARD_DATA,
  RIPPLE_COLOR,
  RecommendedType,
  RpPaymentType,
  IReactResponsiveTypes
} from 'utils/constants';
import { getFormattedNumber, getEndDateAsUnix } from 'utils/helpers';
import { rpRewardsModified, rpSetCreateProgramDataObj } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useReactResponsive } from 'utils/hooks';

export const StripePayment = (props: ReactStripeElements.StripeProps | any) => {
  const dispatch = useDispatch();

  const responsiveType = useReactResponsive();

  SwiperCore.use([]);

  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const { cardsListArray } = useSelector((state: IStore) => state.stripe);
  const { createProgramDataObj, currentAction, isRewardsModified } = useSelector((state: IStore) => state.rewardPrograms);
  const { programDetails, modifiedStatus, selectedCard, paymentRequestObj } = createProgramDataObj;
  const { programBudget, recommendedType } = programDetails;

  const [fieldValid, setFieldValidation] = useState<rpContainerTypes.IRPPaymentValidation>({ ...RP_PAYMENT_VALIDATION });
  const [paymentTab, setPaymentTab] = useState(RP_PAYMENT_TABS.SAVED.value);
  const [saveDisable, setSaveDisable] = useState(false);
  const [newCardName, setNewCardName] = useState<null | string>(null);

  useEffect(() => {
    if (!cardsListArray.length) setPaymentTab(RP_PAYMENT_TABS.NEW.value);
    if (cardsListArray.length) {
      setPaymentTab(RP_PAYMENT_TABS.SAVED.value);
      dispatch(rpSetCreateProgramDataObj({ ...createProgramDataObj, selectedCard: cardsListArray[0].id }));
    }
  }, [cardsListArray]); // eslint-disable-line

  useEffect(() => {
    if (paymentTab === RP_PAYMENT_TABS.NEW.value) {
      dispatch(rpSetCreateProgramDataObj({ ...createProgramDataObj, selectedCard: null }));
    }
    if (paymentTab === RP_PAYMENT_TABS.SAVED.value && cardsListArray.length) {
      dispatch(rpSetCreateProgramDataObj({ ...createProgramDataObj, selectedCard: cardsListArray[0].id }));
    }
  }, [paymentTab, cardsListArray]); // eslint-disable-line

  useEffect(() => {
    dispatch(
      rpSetCreateProgramDataObj({
        ...createProgramDataObj,
        modifiedStatus: {
          ...modifiedStatus,
          payment: paymentRequestObj?.payment.token || paymentRequestObj?.payment.cardId ? true : false
        }
      })
    );
  }, [paymentRequestObj?.payment.token, paymentRequestObj?.payment.cardId]); // eslint-disable-line

  const handleValidation = (paymentEvt: React.ChangeEvent<HTMLInputElement> | any, fieldName: string) => {
    if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
      dispatch(rpRewardsModified(true));
    }
    setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isTouched: true
      }
    }));
    if (fieldName === RpPaymentField.USER_NAME) {
      if (paymentEvt.target) {
        const userVal = paymentEvt.target.value;
        setFieldValidation((prevState) => ({
          ...prevState,
          userName: {
            ...prevState.userName,
            isValid: userVal !== '' ? true : false
          }
        }));
      }
    } else {
      if (fieldName === RpPaymentField.CARD_NUMBER) {
        setNewCardName(paymentEvt.complete ? paymentEvt.brand : '');
      }
      setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          isValid: paymentEvt.complete
        }
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    setSaveDisable(true);
    Object.values(RpPaymentField).forEach((fieldName: string) => {
      setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          isTouched: true
        }
      }));
    });
    const paymentObject: rpApiTypes.IRPPaymentRequest = {
      email: activeUserEmail || '',
      amount: programBudget ? +programBudget * 100 : 0,
      description: event.target.cardname.value || '',
      isSaveCard: true
    };
    let payload: stripeReducerTypes.IStripeToken;
    if (props.stripe) {
      if (paymentTab === RP_PAYMENT_TABS.NEW.value) {
        payload = await props.stripe.createToken();
        paymentObject.token = payload.token.id || '';
      } else if (paymentTab === RP_PAYMENT_TABS.SAVED.value) {
        paymentObject.cardId = selectedCard || '';
      }
    }
    if (paymentObject.token || paymentObject.cardId) {
      const paymentRequestObject = {
        payment: paymentObject,
        details: {
          duration: programDetails?.duration ? +programDetails?.duration : 0,
          amount: programDetails?.programBudget ? `${getFormattedNumber(Number(programDetails.programBudget)) || ''}` : '',
          endDate: getEndDateAsUnix(programDetails?.endDate) || 0,
          programDuration: programDetails?.programDuration ? +programDetails?.programDuration : 0,
          occurrences: programDetails?.occurrences ? +programDetails?.occurrences : 0
        }
      };
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          paymentRequestObj: paymentRequestObject
        })
      );
      setSaveDisable(false);
    }
  };

  const disableEmailTemplateTab = () => {
    return recommendedType === RecommendedType.RECOMMENDED_TYPE
      ? true
      : !modifiedStatus.details || !modifiedStatus.actions || !modifiedStatus.locations || !modifiedStatus.emailTemplate
      ? true
      : false;
  };

  const renderSavedCardList = () => {
    return (
      <div className="saved-card-new-section">
        <Swiper slidesPerView={1} spaceBetween={60} freeMode centeredSlides draggable>
          {cardsListArray.length ? (
            cardsListArray.map((cardItem, idx) => {
              return (
                <SwiperSlide key={`saved-card-swiper-${idx}`} virtualIndex={idx}>
                  <div
                    className={`moneycard ${RP_PAYMENT_CARD_DATA.find((datum) => datum.name === cardItem.brand)?.classname}${selectedCard === cardItem.id ? ` active` : ``}`}
                    onClick={() => {
                      dispatch(rpSetCreateProgramDataObj({ ...createProgramDataObj, selectedCard: cardItem.id }));
                      if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                        dispatch(rpRewardsModified(true));
                      }
                    }}
                  >
                    <Card.Body>
                      <div className="card-number-wrp">
                        <span>{RP_PAYMENT_CARD_DATA.find((datum) => datum.name === cardItem.brand)?.first4}</span>
                        <span>****</span>
                        <span>****</span>
                        <span>{cardItem.last4}</span>
                      </div>

                      <div className="grid-sec">
                        <div className="expiry-date-wrp">
                          <span>
                            {String(cardItem.exp_month).length === 2 ? cardItem.exp_month : `0${cardItem.exp_month}`} / {String(cardItem.exp_year).substring(2, 4)}
                          </span>
                        </div>
                        <div className="cvv-wrp">
                          <span>{cardItem.cvc_check === RP_PAYMENT_DEFAULT_CVC_PAYMENT ? '***' : ''}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </div>
                </SwiperSlide>
              );
            })
          ) : (
            <div className="info-state">No Saved Cards Available</div>
          )}
        </Swiper>
      </div>
    );
  };

  return (
    <div className={`payment-section-wrp${disableEmailTemplateTab() ? ` opacity` : ``}`}>
      <Alert variant="primary">
        <Alert.Heading>Payment</Alert.Heading>
      </Alert>
      {!disableEmailTemplateTab() ? (
        <form className="mainContent animate__animated animate__fadeInUp" onSubmit={handleSubmit}>
          <div className="details-section-tab-wrp">
            <div className="details-section-scrol-wrp">
              <div className="payment-content-section-main">
                <div className="card-details-section-grid">
                  <div className="program-budget-sec-grid">
                    <div className="budget-left-column">
                      <span className="budget-text">Program Budget</span>
                    </div>
                    <div className="budget-right-column">
                      <span className="budget-price">{`$${programBudget ? getFormattedNumber(+programBudget) : 0}`}</span>
                    </div>
                  </div>
                  <h2>
                    Card details
                    <span className="error-txt-login"> (Your card will be charged each time an advocate achieves a reward. Processing fees may apply.) </span>
                  </h2>

                  <Tabs activeKey={paymentTab} onSelect={(selectedTab: null | string) => selectedTab && setPaymentTab(selectedTab)}>
                    <Tab eventKey={RP_PAYMENT_TABS.SAVED.value} title={RP_PAYMENT_TABS.SAVED.title}>
                      {renderSavedCardList()}
                    </Tab>
                    <Tab eventKey={RP_PAYMENT_TABS.NEW.value} title={RP_PAYMENT_TABS.NEW.title}>
                      <div className="pay-newcard-wrp">
                        <div className="left-section">
                          <div className={`form-group${fieldValid.userName.isTouched && !fieldValid.userName.isValid ? ` error-disp` : ``}`}>
                            <input
                              type="text"
                              name="cardname"
                              className={`form-control${fieldValid.userName ? ` used` : ``}`}
                              autoComplete="off"
                              onChange={(cardEvt: React.ChangeEvent<HTMLInputElement>) => handleValidation(cardEvt, 'userName')}
                            />
                            <span className="fltlabels">Name on Card</span>
                          </div>
                          <div className={`strip-section-align${fieldValid.cardNumber.isTouched && !fieldValid.cardNumber.isValid ? ` error-disp` : ``}`}>
                            <CardNumberElement
                              className="stripe-font"
                              onChange={(cardEvt: ReactStripeElements.ElementChangeResponse): void => handleValidation(cardEvt, 'cardNumber')}
                              style={{
                                base: {
                                  fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '6vw' : '16px'
                                }
                              }}
                            />
                            <span className="fltlabels-static">Card Number</span>
                          </div>
                          <div className="stripe-exp-cvc-grid">
                            <div className={`strip-section-align${fieldValid.cardValid.isTouched && !fieldValid.cardValid.isValid ? ` error-disp` : ``}`}>
                              <CardExpiryElement
                                onChange={(cardEvt: ReactStripeElements.ElementChangeResponse) => handleValidation(cardEvt, 'cardValid')}
                                style={{
                                  base: {
                                    fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '13vw' : '16px'
                                  }
                                }}
                              />
                              <span className="fltlabels-static">Expiry</span>
                            </div>
                            <div className={`strip-section-align${fieldValid.cardCvc.isTouched && !fieldValid.cardCvc.isValid ? ` error-disp` : ``}`}>
                              <CardCVCElement
                                onChange={(cardEvt: ReactStripeElements.ElementChangeResponse) => handleValidation(cardEvt, 'cardCvc')}
                                style={{
                                  base: {
                                    fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '13vw' : '16px'
                                  }
                                }}
                              />
                              <span className="fltlabels-static">CVC</span>
                            </div>
                          </div>
                        </div>

                        <div className="right-section">
                          {newCardName && RP_PAYMENT_CARD_DATA.find((datum) => datum.cardValue !== RpPaymentType.UNKNOWN) ? (
                            <div className={`rs-cards ${RP_PAYMENT_CARD_DATA.find((datum) => datum.cardValue === newCardName)?.classname}`}>
                              <Card.Body>
                                <div className="card-number-wrp">
                                  <span>{RP_PAYMENT_CARD_DATA.find((datum) => datum.cardValue === newCardName)?.first4}</span>
                                  <span>****</span>
                                  <span>****</span>
                                  <span>****</span>
                                </div>

                                <div className="grid-sec">
                                  <div className="expiry-date-wrp">
                                    <span>{fieldValid.cardValid.isTouched && fieldValid.cardValid.isValid ? '** / **' : '---'}</span>
                                  </div>
                                  <div className="cvv-wrp">
                                    <span>{fieldValid.cardCvc.isTouched && fieldValid.cardCvc.isValid ? '***' : '---'}</span>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          ) : (
                            <div className="rs-cards master-card-bg rsc-grey">
                              <Card.Body>
                                <div className="card-number-wrp">
                                  <span>2424</span>
                                  <span>****</span>
                                  <span>****</span>
                                  <span>****</span>
                                </div>

                                <div className="grid-sec">
                                  <div className="expiry-date-wrp">
                                    <span>03 / 23</span>
                                  </div>
                                  <div className="cvv-wrp">
                                    <span>***</span>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <CustomRippleButton
                  rippleClass={`ac-primary-box ${
                    saveDisable ||
                    (paymentTab === RP_PAYMENT_TABS.SAVED.value && !selectedCard) ||
                    (paymentTab === RP_PAYMENT_TABS.NEW.value && (!fieldValid.cardNumber.isValid || !fieldValid.cardValid.isValid || !fieldValid.cardCvc.isValid))
                      ? `pointer-events-none`
                      : ``
                  }`}
                  custColor={RIPPLE_COLOR.primary}
                >
                  <button
                    type="submit"
                    className={`ac-btn ac-primary ac-block ${
                      saveDisable ||
                      (paymentTab === RP_PAYMENT_TABS.SAVED.value && !selectedCard) ||
                      (paymentTab === RP_PAYMENT_TABS.NEW.value && (!fieldValid.cardNumber.isValid || !fieldValid.cardValid.isValid || !fieldValid.cardCvc.isValid))
                        ? 'disabled'
                        : ''
                    }`}
                    disabled={
                      saveDisable ||
                      (paymentTab === RP_PAYMENT_TABS.SAVED.value && !selectedCard) ||
                      (paymentTab === RP_PAYMENT_TABS.NEW.value && (!fieldValid.cardNumber.isValid || !fieldValid.cardValid.isValid || !fieldValid.cardCvc.isValid))
                        ? true
                        : false
                    }
                  >
                    Save a card
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
};

const CardForm = injectStripe(StripePayment);

export const ProgramPayment = () => {
  return (
    <StripeProvider apiKey={config.stripe.publishKey}>
      <Elements>
        <CardForm />
      </Elements>
    </StripeProvider>
  );
};
