import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import useOnClickOutside from 'use-onclickoutside';
import { useFormik } from 'formik';

import { commonModalPopup, IStore, userLoginsContainerTypes } from 'types';
import { USER_LOGINS_NEW_USER_FILEDS, USER_LOGINS_NEW_USER_INITIAL_VALUE, VALID_PHONE_NUMBER_REGEX, ALLOW_ONLY_NUMBERS_REGEX, AdvOnboardLoginTypeLabel, RIPPLE_COLOR } from 'utils/constants';
import { addNewUserValidation, formatPhoneNumber } from 'utils/helpers';
import { accountOwnersCheckEmailRequest, checkEmailSetValidation, getULAccountOwnersRequest, ulAddNewUserDetailReset, accountOwnersCheckEmailFailure } from 'actions';
import { ULAddNewUserAlreadyRallioUser } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const UserLoginsAddNewUser = ({ isModalShow, handleModalClose, containerClassName, editObjectValue }: commonModalPopup.IULAddNewUserModalPopup) => {
  const dispatch = useDispatch();

  const { optionalParams, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const { addNewUserDetails, checkEmailResponse, isAddNewUser } = useSelector((state: IStore) => state.userLogins);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const editObject = useSelector((state: IStore) => state.advocacyOnboard.editObject);

  const [isContinueAddUser, setIsContinueAddUser] = useState(false);
  const [showAlreadyRallioUser, setShowAlreadyRallioUser] = useState(false);

  const { values, touched, setFieldValue, errors, handleBlur, handleSubmit, isValid } = useFormik({
    initialValues: { ...addNewUserDetails },
    validationSchema: addNewUserValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values: userLoginsContainerTypes.IULAddNewUserFieldValue) => {
      if (accountDetails?.account?.id && values.isFirstButton) {
        dispatch(accountOwnersCheckEmailRequest({ id: accountDetails?.account?.id, email: values.email }));
      } else if (accountDetails?.account?.id) {
        delete values.isFirstButton;
        dispatch(
          getULAccountOwnersRequest({
            id: accountDetails?.account?.id,
            type: 'loading',
            accountOwnerId: editObject?.advocateId || editObjectValue?.id,
            accountOwner: { ...values, employee_login: isUser ? false : true }
          })
        );
        handleModalClose();
      } else if (!accountDetails) {
        delete values.isFirstButton;
        dispatch(
          getULAccountOwnersRequest({
            id: 0,
            type: 'loading',
            accountOwnerId: editObject?.advocateId || editObjectValue?.id,
            accountOwner: { ...values, employee_login: isUser ? false : true }
          })
        );
        handleModalClose();
      } else if (!accountDetails) {
        delete values.isFirstButton;
        dispatch(
          getULAccountOwnersRequest({
            id: 0,
            type: 'loading',
            accountOwnerId: editObject?.advocateId || editObjectValue?.id,
            accountOwner: { ...values, employee_login: isUser ? false : true }
          })
        );
        handleModalClose();
      }
    }
  });

  useEffect(() => {
    if (!isModalShow) {
      setIsContinueAddUser(false);
      dispatch(ulAddNewUserDetailReset());
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.EMAIL, USER_LOGINS_NEW_USER_INITIAL_VALUE.email || '');
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE, USER_LOGINS_NEW_USER_INITIAL_VALUE?.mobile_phone || '');
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME, USER_LOGINS_NEW_USER_INITIAL_VALUE.first_name || '');
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.LAST_NAME, USER_LOGINS_NEW_USER_INITIAL_VALUE.last_name || '');

      dispatch(checkEmailSetValidation({ email_valid: false, user_exists: false }));
    }
  }, [isModalShow]); // eslint-disable-line

  useEffect(() => {
    if (!addNewUserDetails.email) {
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.EMAIL, addNewUserDetails.email);
      setIsContinueAddUser(false);
    }
  }, [addNewUserDetails.email]); // eslint-disable-line

  useEffect(() => {
    if (checkEmailResponse.email_valid && checkEmailResponse.user_exists) {
      setShowAlreadyRallioUser(true);
    }
  }, [checkEmailResponse.email_valid, checkEmailResponse.user_exists]); // eslint-disable-line

  useEffect(() => {
    if (addNewUserDetails.email !== '' && addNewUserDetails.email !== values.email) {
      dispatch(
        checkEmailSetValidation({
          email_valid: false,
          user_exists: false
        })
      );
    }
  }, [values.email]); // eslint-disable-line

  useEffect(() => {
    if (editObject && isModalShow) {
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.EMAIL, editObject.email);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE, editObject.contact);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME, editObject.firstName);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.LAST_NAME, editObject.lastName);
      setIsContinueAddUser(false);
    }
  }, [editObject, isAddNewUser]); // eslint-disable-line

  useEffect(() => {
    if (editObjectValue) {
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.EMAIL, editObjectValue.email);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE, editObjectValue.mobile_phone);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME, editObjectValue.first_name);
      setFieldValue(USER_LOGINS_NEW_USER_FILEDS.LAST_NAME, editObjectValue.last_name);
      setIsContinueAddUser(false);
    }
  }, [editObjectValue, isAddNewUser]); // eslint-disable-line

  /** Handle and set Values when changes happen in forms */
  const handleChange = (field: string, value: string) => {
    const fieldValue = field === USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE ? value.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : value;
    setFieldValue(field, fieldValue);
  };

  const getClassNames = (valueItem: keyof userLoginsContainerTypes.IULAddNewUserFieldValue) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] && isContinueAddUser ? 'error-disp' : ''}`;
    return className;
  };

  const ref = useRef<any>(null);
  useOnClickOutside(ref, () => !showAlreadyRallioUser && handleModalClose());

  const isUser = currentRoute === 'team_management/logins' ? !queryParams?.login_type : optionalParams[1] === 'users';

  const disableConfirmAction = editObject?.advocateId ? !(isValid && touched) : !(isValid && touched && values.first_name && values.last_name);

  return (
    <>
      <Modal
        className={`prime-modal${containerClassName ? ` ${containerClassName}` : ``}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalShow}
        onHide={handleModalClose}
        animation={false}
      >
        <ImageValidation defaultImg="close-modal-icon" customClassname="close-icon" customName={'close'} onClick={handleModalClose} />
        <Modal.Body ref={ref}>
          <div className="userlogin-addnewuser-wrp userlogin-addnewuser__main">
            <div className="card-header--wrp">
              <h2 className="glbl__title--txt">{`${editObject ? 'Edit' : 'New'} ${isUser ? AdvOnboardLoginTypeLabel.ADMIN : AdvOnboardLoginTypeLabel.EMPLOYEE} User Account`}</h2>
            </div>
            <div className="addnewuser-modal-content">
              <div className={`form-group form-field-item email ${(editObject || editObjectValue) && (editObject?.email || editObjectValue?.email) ? 'events-none pointer-events-none' : ''}`}>
                <input
                  type="text"
                  name={USER_LOGINS_NEW_USER_FILEDS.EMAIL}
                  className={getClassNames(USER_LOGINS_NEW_USER_FILEDS.EMAIL)}
                  autoComplete="off"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(USER_LOGINS_NEW_USER_FILEDS.EMAIL, e.target.value);
                  }}
                />
                <span className="fltlabels">Enter Email</span>
                {(touched.email && errors.email && isContinueAddUser) || (errors.email && isContinueAddUser) ? <span className="errorText">{errors.email}</span> : null}
              </div>
              <div className={(!checkEmailResponse.email_valid && !checkEmailResponse.user_exists) || (checkEmailResponse.email_valid && checkEmailResponse.user_exists) ? 'd-none' : ''}>
                <div className={`form-group form-field-item mobile`}>
                  <input
                    type="text"
                    onKeyPress={(event) => {
                      const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX);
                      const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                      if (!regexData.test(enteredKey)) {
                        event.preventDefault();
                        return false;
                      }
                    }}
                    name={USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE}
                    className={getClassNames(USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE)}
                    autoComplete="off"
                    value={values.mobile_phone ? formatPhoneNumber(values.mobile_phone) : ''}
                    onBlur={handleBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(USER_LOGINS_NEW_USER_FILEDS.MOBILE_PHONE, e.target.value);
                    }}
                  />
                  <span className="fltlabels">Phone Number</span>
                </div>
                <div className={`form-group form-field-item firstname`}>
                  <input
                    type="text"
                    name={USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME}
                    className={getClassNames(USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME)}
                    autoComplete="off"
                    value={values.first_name}
                    onBlur={handleBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(USER_LOGINS_NEW_USER_FILEDS.FIRST_NAME, e.target.value);
                    }}
                  />
                  <span className="fltlabels">First Name</span>
                </div>
                <div className={`form-group form-field-item lastname`}>
                  <input
                    type="text"
                    name={USER_LOGINS_NEW_USER_FILEDS.LAST_NAME}
                    className={getClassNames(USER_LOGINS_NEW_USER_FILEDS.LAST_NAME)}
                    autoComplete="off"
                    value={values.last_name}
                    onBlur={handleBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(USER_LOGINS_NEW_USER_FILEDS.LAST_NAME, e.target.value);
                    }}
                  />
                  <span className="fltlabels">Last Name</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-btn-grp-wraps ap-actions">
            {/* <Button className={`btnOutline btnblueborder`}
              variant='outline-secondary' onClick={() => handleModalClose()}>Cancel</Button> */}
            {((!checkEmailResponse.email_valid && !checkEmailResponse.user_exists) || (checkEmailResponse.email_valid && checkEmailResponse.user_exists)) && (
              <CustomRippleButton rippleClass={`ac-primary-box ${!(isValid && touched) ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block ${!(isValid && touched) ? 'disabled' : ''}`}
                  type="submit"
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => {
                    setIsContinueAddUser(true);
                    setFieldValue('isFirstButton', true);
                    handleSubmit(event);
                  }}
                >
                  Continue
                </button>
              </CustomRippleButton>
            )}
            {checkEmailResponse.email_valid && !checkEmailResponse.user_exists && (
              <CustomRippleButton rippleClass={`ac-primary-box ${disableConfirmAction ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block ${disableConfirmAction ? 'disabled' : ''}`}
                  type="submit"
                  disabled={disableConfirmAction}
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => {
                    setFieldValue('isFirstButton', false);
                    handleSubmit(event);
                  }}
                >
                  Confirm
                </button>
              </CustomRippleButton>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <ULAddNewUserAlreadyRallioUser
        isModalShow={showAlreadyRallioUser}
        handleModalClose={() => {
          setShowAlreadyRallioUser(false);
          dispatch(accountOwnersCheckEmailFailure(null));
        }}
        handleMainModalClose={handleModalClose}
        containerClassName="modal-confirmation confirm-delete sdmp save-changes modal-post-now-wraps-section"
      />
    </>
  );
};
