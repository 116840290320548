import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { advocacyOnboardApiTypes } from 'types';

// ADVOCACY - GET ADVOCACY ONBOARD STATS REQUEST
export const getAdvocacyOnboardStatsRequest = (payload: advocacyOnboardApiTypes.IAdvocacyOnboardStatsRequest) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_STATS_REQUEST, payload);
export const getAdvocacyOnboardStatsResponse = (data: advocacyOnboardApiTypes.IAdvocacyOnboardStatsResponse) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_STATS_RESPONSE, data);
export const getAdvocacyOnboardStatsFailure = (error: null | string) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_STATS_FAILURE, error);

// ADVOCACY - GET ADVOCACY ONBOARD LIST REQUEST
export const getAdvocacyOnboardListRequest = (payload: advocacyOnboardApiTypes.IAdvocacyOnboardListRequest) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_LIST_REQUEST, payload);
export const getAdvocacyOnboardListResponse = (data: advocacyOnboardApiTypes.IAdvocacyOnboardList[]) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_LIST_RESPONSE, data);
export const getAdvocacyOnboardListFailure = (error: null | string) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_LIST_FAILURE, error);

// ADVOCACY - POST ADVOCACY ONBOARD SINGLE RE-INVITE REQUEST
export const getAdvocacyOnboardSingleReInviteResponse = (data: null | string) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_RESPONSE, data);
export const getAdvocacyOnboardSingleReInviteFailure = (error: null | string) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_FAILURE, error);

// ADVOCACY - POST ADVOCACY ONBOARD MULTIPLE RE-INVITE REQUEST
export const getAdvocacyOnboardMultipleReInviteRequest = (payload: advocacyOnboardApiTypes.IAdvocacyOnboardMultipleReInviteRequest) =>
  createAction(actionTypes.ADVOCACY_ONBOARD_GET_MULTIPLE_RE_INVITE_REQUEST, payload);

// ADVOCACY ONBOARD - GET ACTIVE ADVOCATES FOR REINVITATION
export const advocacyOnboardGetActiveAdvocatesIds = (payload: number[]) => createAction(actionTypes.ADVOCACY_ONBOARD_GET_ACTIVE_ADVOCATES_FOR_REINVITATION, payload);

// ADVOCACY ONBOARD - ENABLE CREATE INVITATION TO ALL USERS
export const advocacyOnboardEnableCreateInivation = (payload: boolean) => createAction(actionTypes.ADVOCACY_ONBOARD_ENABLE_CREATE_INVITATION_FOR_ALL_USERS, payload);

// ADVOCACY ONBOARD - SET EDIT OBJECT
export const setAdvocacyOnboardEditObject = (payload: null | advocacyOnboardApiTypes.IAdvocacyOnboardList) => createAction(actionTypes.ADVOCACY_ONBOARD_SET_EDIT_OBJECT, payload);

// ADVOCACY ONBOARD MESSAGE RESET
export const advocacyOnboardMessageReset = () => createAction(actionTypes.ADVOCACY_ONBOARD_MESSAGE_RESET);

// ADVOCACY ONBOARD RESET ALL
export const advocacyOnboardResetAll = () => createAction(actionTypes.ADVOCACY_ONBOARD_RESET_ALL);

// ADVOCACY ONBOARD SET EMAIL PREVIEW MODAL POPUP
export const advocacyOnboardSetEmailPreviewModalPopup = (payload: boolean) => createAction(actionTypes.ADVOCACY_ONBOARD_SET_EMAIL_PREVIEW_MODAL_POPUP, payload);

// ADVOCACY ONBOARD - EMAIL PREVIEW TEXT
export const advocacyOnboardSetPreviewText = (payload: { type: string; val: string }) => createAction(actionTypes.ADVOCACY_ONBOARD_SET_PREVIEW_TEXT, payload);

// ADVOCACY ONBOARD - INITIAL PREVIEW TEXT
export const advocacyOnboardSetInitialPreviewText = () => createAction(actionTypes.ADVOCACY_ONBOARD_SET_INITIAL_PREVIEW_TEXT);

// ADVOCACY ONBOARD - SET STATSCALL
export const setAdvocacyOnboardStatscall = (payload: boolean) => createAction(actionTypes.ADVOCACY_ONBOARD_SET_STATS_CALL, payload);

const ADVOCACY_ONBOARD_ACTIONS = {
  getAdvocacyOnboardStatsRequest,
  getAdvocacyOnboardStatsResponse,
  getAdvocacyOnboardStatsFailure,
  getAdvocacyOnboardListRequest,
  getAdvocacyOnboardListResponse,
  getAdvocacyOnboardListFailure,
  getAdvocacyOnboardSingleReInviteResponse,
  getAdvocacyOnboardSingleReInviteFailure,
  getAdvocacyOnboardMultipleReInviteRequest,
  advocacyOnboardMessageReset,
  advocacyOnboardResetAll,
  advocacyOnboardGetActiveAdvocatesIds,
  advocacyOnboardEnableCreateInivation,
  setAdvocacyOnboardEditObject,
  advocacyOnboardSetEmailPreviewModalPopup,
  advocacyOnboardSetPreviewText,
  advocacyOnboardSetInitialPreviewText,
  setAdvocacyOnboardStatscall
};

export type AdvocacyOnboardAction = ActionsUnion<typeof ADVOCACY_ONBOARD_ACTIONS>;
