import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { commonModalPopup, IStore } from 'types';
import { rpRewardsModified, rpSetResendEmailModified, rpCreateOrUpdateProgramReset, stripeCardsListReset } from 'actions';
import { RP_PROGRAM_RESET_TYPES } from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const ProgramSaveChangesModal = ({ isModalShow, handleModalClose }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ program: 'all' });

  const isRewardsModified = useSelector((state: IStore) => state.rewardPrograms.isRewardsModified);
  const isResendEmailModified = useSelector((state: IStore) => state.rewardPrograms.isResendEmailModified);

  const handleCancelProgramChanges = () => {
    if (isRewardsModified) dispatch(rpRewardsModified(false));
    if (isResendEmailModified) dispatch(rpSetResendEmailModified(false));
    dispatch(rpCreateOrUpdateProgramReset(RP_PROGRAM_RESET_TYPES.SUBMIT));
    dispatch(stripeCardsListReset());
    if (currentRoute === 'employee_advocacy/reward_programs') {
      navigate({ pathname: `/${userOwnership}/${id.toString()}/${currentRoute}`, search: `?${new URLSearchParams(queryParams).toString()}` });
    }
  };

  return (
    <Modal
      className="prime-modal modal-confirmation confirm-delete creator-modal-confirm-new"
      size={'sm'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Header>
        <div className="modal-icon-wraps">
          <span className="modal-itm-wraps modal-danger-wraps modal-info-wraps" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-message-wraps-text">Are you sure you want to cancel this rewards program?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-btn-grp-wraps">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={handleModalClose}>
            No
          </button>
          <button className="ok-btn-creator-model" onClick={handleCancelProgramChanges}>
            Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
