export const DATE_ONLY_FORMAT = 'D MMMM, YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A';
export const RP_DATEPICKER_DATE_TIME_FORMAT = 'MM/dd/yyyy h:mm aa';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_HYPEN = 'MM-DD-YYYY';
export const MONTH_DATE_FORMAT = 'MMM D, YYYY';
export const SHORT_MONTH_DATE_YEAR_FORMAT = 'MMM DD, YYYY';
export const DATE_ONLY = 'D';
export const EMAIL_DATE_FORMAT = 'MMMM DD, YYYY h:mm A';
export const EMAIL_SHORT_DATE_FORMAT = 'MMM DD, YYYY h:mm A';
export const YEAR_MONTH_DAY_WITH_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const YEAR_MONTH_DAY_WITH_TIME_FORMAT_HYPHEN = 'YYYY-MM-DD HH:mm:ss';
export const YEAR_MONTH_DAY_FORMAT = 'YYYY/MM/DD';
export const MOMENT_DATE_FORMAT = 'll';
export const MOMENT_TIME_FORMAT = 'LT';
export const MOMENT_DATE_FORMAT_TH = 'MMMM Do, YYYY';
export const UTC_TIMESTAMP_FORMAT = 'X';
export const MOMENT_LONG_DATE_FORMAT = 'LL';
export const TIME_ONLY = 'HH:mm A';
export const TIME_ONLY_FOR_EACH_12_HOURS = 'hh:mm A';
export const MOMENT_DATE_AND_TIME_FORMAT = 'lll';
export const SPECIAL_HOUR_FORMAT = 'yyyy-MM-DD';
export const MONTH_YEAR_FORMAT = 'MMM, YYYY';
export const OVERVIEW_RESQ_DATE_FORMAT = 'DD-MM-YYYY';
export const RP_DATE_FORMAT = 'MM/DD/YY';
export const CALENDAR_YR_MN_DATE_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_EVENT_DATE_FORMAT = 'MMM DD, YYYY @ h:mm A';

export const DATEPICKER_DATE_ONLY_FORMAT = 'MMM d, yyyy';
export const DATEPICKER_MONTH_ONLY_FORMAT = 'MMM, yyyy';
export const DATEPICKER_TIME_ONLY_FORMAT = 'hh:mm aa';
export const LOCATION_TIME_ZONE_DATE_TIME_FORMAT = 'h:mm A [on] MMM D, YYYY z';
export const CALENDAR_DATE_FORMAT = 'h:mm A [on] MMM D, YYYY';
export const CREATOR_SCH_DATE_AND_TIME = 'MMM DD, hh:mm A';
export const MERIDIAN_FORMAT = 'A';
export const HOUR_FORMAT = 'hh';
export const MINUTE_FORMAT = 'mm';
export const FULL_HOUR_FORMAT = 'HH';
export const FULL_YEAR_FORMAT = 'YYYY';
export const MONTH_DATE_YEAR_FORMAT = 'MMMM DD, YYYY';
export const DATEPICKER_VALID_DATE_ONLY_FORMAT = 'MMMM dd, yyyy';
export const DATE_PICKER_TIME_FORMAT = 'HH:mm';
export const OFFSET_TIMEZONE_FORMAT = 'Z';
export const DAY_FORMAT = 'ddd';
export const MONTH_FORMAT = 'MMM';
export const FULL_DATE_FORMAT = 'DD';
export const DAYS_ONLY = 'd';

export const DATE_AT_TIME_FORMAT = 'D-MMM-YYYY [at] h:mm a';
export const TIME_WITH_FULL_DAY_FORMAT = 'h:mm a dddd';
export const MONTH_NUMBER_FORMAT = 'M';
