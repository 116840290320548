import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { changePasswordResponse, changePasswordFailed, exceptionHandlerRequest, logoutRequest } from 'actions';
import { commonApiTypes } from 'types';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { CHANGE_PASSWORD_EXCEPTION_ERROR, INCORRECT_CHANGE_PASSWORD } from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendChangePasswordRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('user[current_password]', payload.inputValues.current_password);
    bodyFormData.append('user[password]', payload.inputValues.new_password);
    bodyFormData.append('user[password_confirmation]', payload.inputValues.confirm_password);
    const path = API.changePassword.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.changePassword.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(changePasswordResponse(response.data));
    } else {
      yield put(changePasswordFailed(response.data?.errors?.current_password[0] === 'is invalid' ? INCORRECT_CHANGE_PASSWORD : null));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CHANGE_PASSWORD_EXCEPTION_ERROR));
  }
}

export function* takeChangePasswordRequest() {
  yield takeEvery(types.CHANGE_PASSWORD_REQUEST, sendChangePasswordRequest);
}
