import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { apFranContainerTypes } from 'types';
import { commonSetContentModifiedStatus } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { RIPPLE_COLOR } from 'utils/constants';

export const CommonSaveChangesConfirmModal = ({ isModalShow, handleModalClose, handleNavigation }: apFranContainerTypes.IFranCreatorSaveChangesPopup) => {
  const dispatch = useDispatch();

  const handleUnsaveChanges = () => {
    dispatch(commonSetContentModifiedStatus(false));
    handleNavigation();
    handleModalClose();
  };

  return (
    // <Modal className="prime-modal modal-confirmation confirm-delete creator-modal-confirm-new" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
    <Modal className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
      <Modal.Header>
        <div className="modal-icon-wraps">
          <span className="modal-itm-wraps modal-danger-wraps modal-info-wraps" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="unsave__modal--txt">You have unsaved changes that will be lost. Continue anyway?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <div className="modal-btn-grp-wraps delete__btn--wrp"> */}
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className="ac-btn ac-primary ac-block" onClick={handleUnsaveChanges}>
              Ok
            </button>
          </CustomRippleButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
