import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { InboxAction } from 'actions';
import { inboxReducerTypes } from 'types';
import {
  getInboxCommentsUpdatedObj,
  inboxLikeStateUpdate,
  inboxReplyStateUpdate,
  getUniqueNumberValues,
  inboxCommentsEngagementStateUpdate,
  inboxRetweetStateUpdate,
  getInboxCommentsBasedOnReplies,
  getInboxCommentsDismissed,
  getUpdatedInboxList
} from 'utils/helpers';
import { INBOX_COMMENTS_INIT_OBJ, InboxReplyActionType } from 'utils/constants';

const initialState: inboxReducerTypes.IInboxReducer = {
  isInboxFetching: false,
  isInboxReplyFetching: false,
  isInboxCommentsFetching: false,
  isInboxCommentsLikeFetching: false,
  isInboxRetweetFetching: false,
  isInboxPieReloadFetching: false,
  isInboxStatsFetching: false,
  inboxStatsResponse: {},
  error: null,
  isDeleteInbox: false,
  currentPageCount: 1,
  inboxList: [],
  deleteInboxResponse: null,
  deleteInboxError: null,
  replyInboxSuccessMsg: null,
  commentsInboxResponse: INBOX_COMMENTS_INIT_OBJ,
  isStatsCall: true,
  inboxDMThreadMessageList: [],
  isInboxDMThreadMessageFetching: false,
  isNewDMThreadMessageFound: false
};

const inboxReducer: Reducer<inboxReducerTypes.IInboxReducer, InboxAction> = (state: inboxReducerTypes.IInboxReducer = initialState, action: InboxAction): inboxReducerTypes.IInboxReducer => {
  switch (action.type) {
    // INBOX List
    case types.INBOX_LIST_REQUEST:
      return {
        ...state,
        isInboxFetching: action.payload.page > 0 && !action.payload.countUpdate ? true : false,
        error: null,
        currentPageCount: action.payload.page
        // meta: action.payload.page === 1 && !action.payload.countUpdate ? POSTS_WITH_UNREAD_COMMENTS_BY_PLATFORM : state.meta
      };
    case types.INBOX_LIST_RESPONSE:
      return {
        ...state,
        isInboxFetching: false,
        error: null,
        inboxList: getUpdatedInboxList(state.currentPageCount, state.inboxList, action.payload.social_contents)
        /* meta: {
            postsWithUnreadCommentsCount: action.payload.meta.posts_with_unread_comments_count || 0,
            postsWithUnCommentsCountByPlatform: {
              facebook: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.facebook : 0,
              instagram: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.instagram : 0,
              twitter: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.twitter : 0,
              linkedin: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.linkedin : 0,
            }
          } */
      };
    case types.INBOX_LIST_REQUEST_FAILURE:
      return {
        ...state,
        isInboxFetching: false,
        error: action.payload || 'Unexpected error',
        inboxList: []
      };
    // Get Inbox stats count
    case types.INBOX_GET_STATS_REQUEST:
      return {
        ...state,
        isInboxStatsFetching: true
      };
    case types.INBOX_GET_STATS_RESPONSE:
      return {
        ...state,
        isInboxStatsFetching: false,
        inboxStatsResponse: action.payload
      };
    case types.INBOX_GET_STATS_FAILURE:
      return {
        ...state,
        isInboxStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // Delete Inbox post
    case types.INBOX_DELETE_RESPONSE:
      let inboxListArray = [...state.inboxList];
      if (action.payload.id) {
        inboxListArray = inboxListArray.filter((dataItem) => dataItem.id !== action.payload.id);
      }
      return {
        ...state,
        deleteInboxResponse: action.payload.message,
        inboxList: inboxListArray
      };
    case types.INBOX_DELETE_FAILURE:
      return {
        ...state,
        deleteInboxResponse: null,
        deleteInboxError: action.payload || 'Unexpected error'
      };
    case types.INBOX_DELETE_DATA_RESET:
      return {
        ...state,
        deleteInboxResponse: null,
        deleteInboxError: null
      };
    // Get Inbox Like
    case types.INBOX_LIKE_RESPONSE:
      const likeResponse = inboxLikeStateUpdate(state.inboxList, action.payload);
      return {
        ...state,
        inboxList: likeResponse
      };
    case types.INBOX_LIKE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Get Inbox Retweet
    case types.INBOX_RETWEET_REQUEST:
      return {
        ...state,
        isInboxRetweetFetching: true,
        error: null
      };
    case types.INBOX_RETWEET_RESPONSE:
      const retweetResponse = inboxRetweetStateUpdate(state.inboxList, action.payload);
      return {
        ...state,
        isInboxRetweetFetching: false,
        inboxList: retweetResponse
      };
    case types.INBOX_RETWEET_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Get Inbox Comments Like
    case types.INBOX_COMMENTS_ENGAGEMENT_REQUEST:
      return {
        ...state,
        isInboxCommentsLikeFetching: true,
        error: null
      };
    case types.INBOX_COMMENTS_ENGAGEMENT_RESPONSE:
      const commentsLikeResponse = state.commentsInboxResponse ? inboxCommentsEngagementStateUpdate(state.commentsInboxResponse, action.payload) : INBOX_COMMENTS_INIT_OBJ;
      return {
        ...state,
        commentsInboxResponse: commentsLikeResponse
      };
    case types.INBOX_COMMENTS_ENGAGEMENT_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Get Inbox Reply
    case types.INBOX_REPLY_REQUEST:
      return {
        ...state,
        isInboxReplyFetching: action.payload.actionType === InboxReplyActionType.LIST_REPLY ? true : false,
        replyInboxSuccessMsg: null,
        isNewDMThreadMessageFound: true,
        error: null
      };
    case types.INBOX_REPLY_RESPONSE:
      const replyResponse = action.payload.actionType === InboxReplyActionType.LIST_REPLY ? inboxReplyStateUpdate(state.inboxList, action.payload) : state.inboxList;
      const commentsData = action.payload.actionType === InboxReplyActionType.COMMENT_REPLY ? getInboxCommentsBasedOnReplies(state.commentsInboxResponse, action.payload) : state.commentsInboxResponse;
      return {
        ...state,
        isInboxReplyFetching: false,
        replyInboxSuccessMsg: action.payload.data,
        inboxList: replyResponse,
        commentsInboxResponse: commentsData
      };
    case types.INBOX_REPLY_FAILURE:
      return {
        ...state,
        isInboxReplyFetching: false,
        replyInboxSuccessMsg: null,
        error: action.payload || 'Unexpected error',
        isNewDMThreadMessageFound: false
      };
    // Get Inbox Comments
    case types.INBOX_COMMENTS_REQUEST:
      return {
        ...state,
        isInboxCommentsFetching: true
      };
    case types.INBOX_COMMENTS_RESPONSE:
      return {
        ...state,
        isInboxCommentsFetching: action.payload.meta.current_page !== action.payload.meta.total_pages ? true : false,
        commentsInboxResponse: {
          socialContentId: action.payload.socialContentId,
          activeCommentsIds: getUniqueNumberValues(state.commentsInboxResponse.activeCommentsIds),
          modifiedComments: getInboxCommentsUpdatedObj(state.commentsInboxResponse.modifiedComments, action.payload),
          pageCount: {
            totalPage: action.payload.meta.total_pages,
            currentPage: action.payload.meta.current_page
          }
        }
      };
    case types.UPDATE_POST_COMMENT_COUNT: {
      const postList = [...state.inboxList];
      const postObj = postList.find((it) => it.id === action.payload.contentId);
      if (postObj) {
        postObj.dismissed_comments_count = action.payload.data.meta.dismissed_comments_count;
      }
      return {
        ...state,
        inboxList: postList
      };
    }
    /* case types.UPDATE_INBOX_STATS:
        return {
          ...state,
          meta: {
            postsWithUnreadCommentsCount: action.payload.meta.posts_with_unread_comments_count || 0,
            postsWithUnCommentsCountByPlatform: {
              facebook: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.facebook : 0,
              instagram: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.instagram : 0,
              twitter: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.twitter : 0,
              linkedin: action.payload.meta.posts_with_unread_comments_count_by_platform ?
                action.payload.meta.posts_with_unread_comments_count_by_platform.linkedin : 0,
            }
          }
        }; */
    case types.INBOX_COMMENTS_FAILURE:
      return {
        ...state,
        isInboxCommentsFetching: state.commentsInboxResponse.pageCount.currentPage !== state.commentsInboxResponse.pageCount.totalPage ? true : false,
        error: action.payload || 'Unexpected error'
      };
    case types.INBOX_SET_ACTIVE_COMMENT_IDS:
      return {
        ...state,
        commentsInboxResponse: {
          ...state.commentsInboxResponse,
          socialContentId: 0,
          activeCommentsIds: action.payload
        }
      };
    // Get Inbox Dismiss
    case types.INBOX_DISMISS_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.INBOX_DISMISS_RESPONSE:
      return {
        ...state,
        commentsInboxResponse: getInboxCommentsDismissed(state.commentsInboxResponse, action.payload)
      };
    case types.INBOX_DISMISS_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Set Inbox statscall
    case types.INBOX_SET_STATS_CALL:
      return {
        ...state,
        isStatsCall: action.payload
      };
    case types.INBOX_VIEW_COMMENTS_DATA_RESET:
      return {
        ...state,
        commentsInboxResponse: initialState.commentsInboxResponse
      };
    // Inbox Pie Reload
    case types.INBOX_PIE_RELOAD_REQUEST:
      return {
        ...state,
        isInboxPieReloadFetching: true
      };
    // Inbox Pie Reload
    case types.INBOX_PIE_RELOAD_RESPONSE:
      return {
        ...state,
        isInboxPieReloadFetching: action.payload
      };
    case types.INBOX_PIE_RELOAD_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Get Inbox Direct Message Thread
    case types.INBOX_DIRECT_MESSAGE_THREAD_REQUEST:
      return {
        ...state,
        isInboxDMThreadMessageFetching: !action.payload?.isPolling ? true : false
      };
    case types.INBOX_DIRECT_MESSAGE_THREAD_RESPONSE:
      return {
        ...state,
        isInboxDMThreadMessageFetching: false,
        inboxDMThreadMessageList: action.payload.page > 1 ? state.inboxDMThreadMessageList.concat(action.payload.messageData) : action.payload.messageData
      };
    case types.INBOX_DIRECT_MESSAGE_THREAD_FAILURE:
      return {
        ...state,
        inboxDMThreadMessageList: [],
        isInboxDMThreadMessageFetching: false
      };
    case types.INBOX_REPLY_MSG_RESET:
      return {
        ...state,
        replyInboxSuccessMsg: initialState.replyInboxSuccessMsg,
        commentsInboxResponse: {
          ...state.commentsInboxResponse,
          socialContentId: initialState.commentsInboxResponse.socialContentId
        }
      };
    case types.INBOX_COMMENTS_PAGE_COUNT_RESET:
      return {
        ...state,
        commentsInboxResponse: {
          ...state.commentsInboxResponse,
          pageCount: initialState.commentsInboxResponse.pageCount
        }
      };
    case types.INBOX_SET_IS_NEW_THREAD_MSG_FOUND:
      return {
        ...state,
        isNewDMThreadMessageFound: action.payload
      };
    case types.INBOX_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
export { inboxReducer };
