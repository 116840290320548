import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { Loading } from 'components';
import { accountGetProfileRequest, rallioProfileResetAll, socialProfileResetAll, getTimeZoneRequest, getCountriesCodeRequest } from 'actions';
import { useAccountSwitcherData, useHideFilter } from 'utils/hooks';
import { USER_OWNERSHIP } from 'utils/constants';
import SettingsRallioProfileDetails from './SubPages/SettingsRallioProfileDetails';
import { scrollToTop } from 'utils/helpers';

export const SettingsRallioProfile = ({ socialProfileRef }: any) => {
  useHideFilter();

  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const { id, userOwnership } = useAccountSwitcherData();

  const isProfileFetching = useSelector((state: IStore) => state.accounts.isProfileFetching);

  useEffect(() => {
    scrollToTop();
    if (id && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(rallioProfileResetAll());
      dispatch(socialProfileResetAll());
      dispatch(accountGetProfileRequest({ id, isDataRetain: true }));
      dispatch(getTimeZoneRequest());
      dispatch(getCountriesCodeRequest());
    }
  }, [id, userOwnership, dispatch]); // eslint-disable-line

  return isProfileFetching ? <Loading /> : <SettingsRallioProfileDetails ref={socialProfileRef} />;
};
