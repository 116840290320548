import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { changePasswordApiTypes, loginReducerType } from 'types';

// CHANGE PASSWORD REQUEST
export const changePasswordRequest = (payload: changePasswordApiTypes.IChangePasswordReq) => createAction(actionTypes.CHANGE_PASSWORD_REQUEST, payload);
export const changePasswordResponse = (data: loginReducerType.ILoginResponseUser) => createAction(actionTypes.CHANGE_PASSWORD_RESPONSE, data);
export const changePasswordFailed = (error: null | string) => createAction(actionTypes.CHANGE_PASSWORD_FAILED, error);

export const changePasswordResetAll = () => createAction(actionTypes.CHANGE_PASSWORD_RESET_ALL);

const CHANGE_PASSWORD_ACTIONS = {
  changePasswordRequest,
  changePasswordResponse,
  changePasswordFailed,
  changePasswordResetAll
};

export type ChangePasswordActions = ActionsUnion<typeof CHANGE_PASSWORD_ACTIONS>;
