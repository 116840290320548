import { Point } from 'highcharts';

import { IReviewGroupedTableData, IReviewsGraphSeries, IReviewsKeyWordsChartData } from 'analytics/types';
import {
  ReviewsAnalyticsFBVariantLegendName,
  ReviewsAnalyticsStarLegendName,
  KEYWORDS_COLOR,
  REDUCE_OBJ,
  REVIEW_ANALYTICS_STATS,
  ReviewAnalyticsStatsValue,
  TAB_GRAPH_SERIES,
  REVIEW_ANALYTICS_PLATFORM_FILTER,
  REVIEW_ANALYTICS_PIE_LEGEND,
  OVERVIEW_TABLE_HEADER,
  PERCENT_REPLIED_IN_NUMBER,
  KeywordManagementTableValue
} from 'analytics/utils/constants';
import { getDiffDateHandler, subtractDayHandler, dateFormatter, dateEqualHandler, sumAverageObjectsInArray } from 'analytics/utils/helpers';
import { config } from 'config';
import { getMomentForDate } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';

// Function to handle the api data to required format for Word Cloud
export const getKeywordData = (data: { [key: string]: any }[]): IReviewsKeyWordsChartData[] => {
  const keyWordSplit = data.reduce(
    (acc, curr, index) => {
      const array = [+curr?.positive || 0, +curr?.negative || 0, +curr?.neutral || 0];
      const maxIndex = array.indexOf(Math.max(...array));
      acc[REDUCE_OBJ[maxIndex]].push({ name: curr?.keyword, color: KEYWORDS_COLOR[maxIndex], value: array[maxIndex] });
      if (curr.used > acc.usedMax) acc.usedMax = curr.used;
      if (array[maxIndex] > acc[`${REDUCE_OBJ[maxIndex]}Max`]) acc[`${REDUCE_OBJ[maxIndex]}Max`] = array[maxIndex];
      if (index === data.length - 1) {
        acc.pos = acc.pos.map((it: IReviewsKeyWordsChartData) => ({ ...it, weight: (it.value / acc.usedMax) * 5 }));
        acc.neg = acc.neg.map((it: IReviewsKeyWordsChartData) => ({ ...it, weight: (it.value / acc.usedMax) * 5 }));
        acc.neut = acc.neut.map((it: IReviewsKeyWordsChartData) => ({ ...it, weight: (it.value / acc.usedMax) * 5 }));
      }
      return acc;
    },
    { pos: [], neg: [], neut: [], posMax: 0, negMax: 0, neutMax: 0, usedMax: 0 }
  );
  const sliceIndex = 250;
  return [...keyWordSplit.pos.slice(0, sliceIndex), ...keyWordSplit.neg.slice(0, sliceIndex), ...keyWordSplit.neut.slice(0, sliceIndex)];
};

export const pieLegendFormatter = (it: Point) => {
  let img = '';
  switch (it.name) {
    case ReviewsAnalyticsFBVariantLegendName.RECOMMENDED:
      img = `<img src='${config.cdnImgUrl}recommend-g.svg'/>`;
      break;
    case ReviewsAnalyticsFBVariantLegendName.NOT_RECOMMENDED:
      img = `<img src='${config.cdnImgUrl}recommend-dn.svg'/>`;
      break;
    case ReviewsAnalyticsStarLegendName.ONE_STAR:
      img = `<img src='${config.cdnImgUrl}star-ratings-1.svg'/>`;
      break;
    case ReviewsAnalyticsStarLegendName.TWO_STAR:
      img = `<img src='${config.cdnImgUrl}star-ratings-2.svg'/>`;
      break;
    case ReviewsAnalyticsStarLegendName.THREE_STAR:
      img = `<img src='${config.cdnImgUrl}star-ratings-3.svg'/>`;
      break;
    case ReviewsAnalyticsStarLegendName.FOUR_STAR:
      img = `<img src='${config.cdnImgUrl}star-ratings-4.svg'/>`;
      break;
    case ReviewsAnalyticsStarLegendName.FIVE_STAR:
      img = `<img src='${config.cdnImgUrl}star-ratings-5.svg'/>`;
      break;
    default:
      img = '';
  }
  const percentage = !Number.isNaN(it?.percentage) && it?.percentage ? `${it?.percentage.toFixed(2)}%` : '';
  return `<div class="legend-wrp"> ${img} <span class="start-lbl">${it.name}</span><span class="percent-lbl">${percentage}</span> </div>`;
};

export const reviewSelectedTableData = (data: IReviewGroupedTableData, pieLegendList: typeof REVIEW_ANALYTICS_PIE_LEGEND, platform: string, tab: string, tabDelta: string) => {
  const delta = tabDelta === 'total_replied_percentage_delta' ? 'replied_delta_percentage' : tabDelta.replace(/total_/g, '');
  return [...pieLegendList]
    .filter((it) => (platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? it : it.value === platform))
    .map((it) => {
      const dataObj = data?.headers.reduce((acc, curr) => {
        const currentData = data.data.find((it) => it.title === curr.value);
        let overallData = currentData ? currentData[`${it.value}_${tab}`] : undefined;
        if (tab === 'replied_percentage') {
          overallData = currentData && !currentData[`${it.value}_reviews_count`] ? 100 : overallData;
        }
        acc = {
          ...acc,
          [`${curr.value}`]: overallData,
          [`${curr.value}_delta`]: currentData ? currentData[`${it.value}_${delta}`] : undefined
        };
        return acc;
      }, {});
      return {
        ...it,
        ...dataObj
      };
    });
};

export const reviewsTableDateGrouping = (
  dataByDay: { [key: string]: string | number | Date | null }[],
  tableHeader: typeof REVIEW_ANALYTICS_STATS,
  platformFilter: typeof REVIEW_ANALYTICS_PLATFORM_FILTER
) => {
  if (dataByDay?.length) {
    const startDate = getMomentForDate(dataByDay[0]?.date);
    const endDate = getMomentForDate(dataByDay[dataByDay.length - 1]?.date);
    const clonedStartDate = startDate.clone();
    const clonedEndDate = endDate.clone();

    const avgArray = [...platformFilter].reduce((acc: string[], curr) => {
      const avgRating = curr.value === 'all' ? 'avg_rating' : `${curr.value}_avg_rating`;
      const avgRatingDelta = curr.value === 'all' ? 'avg_rating_delta' : `${curr.value}_avg_rating_delta`;
      const brandAvgRating = curr.value === 'all' ? 'brand_avg_rating' : `${curr.value}_brand_avg_rating`;
      const hubAvgRating = curr.value === 'all' ? 'hub_avg_rating' : `${curr.value}_hub_avg_rating`;

      const avgResponse = curr.value === 'all' ? 'avg_response_time' : `${curr.value}_avg_response_time`;
      const avgResponseDelta = curr.value === 'all' ? 'avg_response_time_delta' : `${curr.value}_avg_response_time_delta`;
      const brandAvgResponse = curr.value === 'all' ? 'brand_avg_response_time' : `${curr.value}_brand_avg_response_time`;
      const hubAvgResponse = curr.value === 'all' ? 'hub_avg_response_time' : `${curr.value}_hub_avg_response_time`;

      const precReplied = curr.value === 'all' ? 'replied_percentage' : `${curr.value}_replied_percentage`;
      const precRepliedDelta = curr.value === 'all' ? 'replied_delta_percentage' : `${curr.value}_replied_delta_percentage`;
      const brandPrecReplied = curr.value === 'all' ? 'brand_avg_replied_percentage' : `${curr.value}_brand_avg_response_time`;
      const hubAvgPrecReplied = curr.value === 'all' ? 'hub_avg_replied_percentage' : `${curr.value}_hub_avg_response_time`;

      acc.push(
        precReplied,
        precRepliedDelta,
        brandPrecReplied,
        hubAvgPrecReplied,
        avgRating,
        avgRatingDelta,
        brandAvgRating,
        hubAvgRating,
        avgResponse,
        avgResponseDelta,
        brandAvgResponse,
        hubAvgResponse
      );
      return acc;
    }, []);

    const dayDifference = endDate.diff(startDate, 'd') + 1;
    const divident = dayDifference <= 31 ? 1 : dayDifference <= 90 ? 7 : dayDifference <= 365 * 3 ? 31 : 366;
    const factor = Math.floor(dayDifference / divident);
    const reminder = dayDifference % divident;
    const length =
      divident === 1
        ? dayDifference
        : divident === 7
        ? factor + (reminder ? 1 : 0)
        : divident === 31
        ? clonedEndDate.endOf('month').diff(clonedStartDate.startOf('month'), 'months') + 1
        : clonedEndDate.endOf('year').diff(clonedStartDate.startOf('year'), 'years') + 1;
    const dates: number[] = [];

    [...Array(length)].forEach((_1, index) => {
      if (index) {
        const addDates = index === factor + (reminder ? 1 : 0) ? reminder || divident : divident;
        const date = divident === 31 ? startDate.add(1, 'month').startOf('month') : divident === 366 ? startDate.add(1, 'year').startOf('year') : startDate.add(addDates, 'day');
        dates.push(date.valueOf());
      } else {
        dates.push(startDate.valueOf());
      }
    });

    const dateFormat = divident === 31 ? 'MMM YYYY' : divident === 366 ? 'YYYY' : 'MMM DD';
    const dateObj = dates.reduce(
      (acc: IReviewGroupedTableData, curr, index) => {
        const momentEnd = dates[index + 1] ? subtractDayHandler(dates[index + 1], 1) : endDate;
        const end =
          getDiffDateHandler(dates[index + 1] || endDate, curr) > 1 ? (dates[index + 1] ? `${subtractDayHandler(dates[index + 1], 1, dateFormat)}` : dateFormatter(`${endDate}`, dateFormat)) : null;

        const start = dateFormatter(curr, dateFormat);
        const header = `${start}${end ? ' - '.concat(end) : ''}`;
        acc.headers.push({
          title: header,
          value: header,
          totalValue: `total_${header}`,
          totalValueDelta: `total_${header}_delta`
        });
        if (end) {
          const dateRangeData = dataByDay.filter((it) => dateEqualHandler(it?.date, curr, 'YYYY-MM-DD', 'after') && dateEqualHandler(it.date, momentEnd, 'YYYY-MM-DD', 'before'));

          const result = sumAverageObjectsInArray(dateRangeData, avgArray);
          acc.data.push({
            ...result,
            title: header,
            totalAveragecount: dateRangeData.length
          });
        } else {
          const result = dataByDay.find((it) => dateEqualHandler(it?.date, curr, 'YYYY-MM-DD'));
          acc.data.push({
            ...result,
            title: header,
            totalAveragecount: 1
          });
        }

        if (index === dates.length - 1) {
          const obj = acc.headers.reduce((headerAcc: { [key: string]: number | string }, headerCurr, index: number) => {
            if (index) {
              const currentData = acc?.data?.find((it) => it.title === headerCurr.title);
              if (currentData) {
                [...platformFilter].forEach((platform) => {
                  [...tableHeader].forEach((header) => {
                    const delta = header.chartValue === 'replied_percentage' ? 'replied_delta_percentage' : header.delta.replace(/(total_)/g, '');
                    const platformHeader = platform.value === 'all' ? 'total' : platform.value;
                    const consolidatedAvg =
                      header.chartValue === 'replied_percentage' || header.chartValue === 'replied_count'
                        ? `brand_avg_${platform.value}_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '')
                        : `${platform.value}_brand_avg_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '');
                    const consolidatedHubAvg =
                      header.chartValue === 'replied_percentage' || header.chartValue === 'replied_count'
                        ? `hub_avg_${platform.value}_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '')
                        : `${platform.value}_hub_avg_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '');
                    const brandAvg = platform.value === 'all' ? `brand_avg_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '') : consolidatedAvg;
                    const hubAvg = platform.value === 'all' ? `hub_avg_${header.chartValue}`.replace(/(_avg)(?=.*\1)/g, '') : consolidatedHubAvg;
                    let data = +currentData[platform.value === 'all' ? `${header.chartValue}` : `${platformHeader}_${header.chartValue}`];
                    if (header.chartValue === 'replied_percentage') {
                      data = !+currentData[platform.value === 'all' ? 'reviews_count' : `${platformHeader}_reviews_count`] ? 100 : data;
                    }
                    headerAcc[`${platformHeader}_${headerCurr.value}_${header.value}`] = data;
                    headerAcc[`${platformHeader}_${headerCurr.value}_${header.value}_delta`] = +currentData[platform.value === 'all' ? `${delta}` : `${platformHeader}_${delta}`];
                    headerAcc[`brand_avg_${platformHeader}_${headerCurr.value}_${header.value}`] = +currentData[brandAvg] || 0;
                    headerAcc[`hub_avg_${platformHeader}_${headerCurr.value}_${header.value}`] = +currentData[hubAvg] || 0;
                  });
                });
              }
            }
            return headerAcc;
          }, {});
          acc.summary = obj;
          acc.pieChartData = {
            ...sumAverageObjectsInArray(acc?.data, avgArray)
          };
          acc.headers = acc.headers.map((it) => (divident === 31 || divident === 366 ? { ...it, title: it.title.split('-')[0].trim() } : it));
        }
        acc.tickInterval = [...dates, endDate.valueOf()];
        return acc;
      },
      { headers: [OVERVIEW_TABLE_HEADER[0]], data: [], summary: {}, pieChartData: {}, tickInterval: undefined }
    );
    return dateObj;
  } else {
    return { headers: [OVERVIEW_TABLE_HEADER[0]], data: [], summary: {}, pieChartData: {}, tickInterval: undefined };
  }
};

export const reviewAnalyticsTooltipText = (value: number | string, type: string) => {
  switch (type) {
    case ReviewAnalyticsStatsValue.REVIEWS: {
      return `You received ${value} New Reviews`;
    }
    case ReviewAnalyticsStatsValue.AVERAGE_RATING: {
      return `You received an average review rating of ${value}`;
    }
    case ReviewAnalyticsStatsValue.REPLY: {
      return `You replied to ${value}% of the new reviews`;
    }
    case PERCENT_REPLIED_IN_NUMBER.value: {
      return `You replied to ${value} new reviews`;
    }
    default: {
      return `Your average response time is ${value}`;
    }
  }
};

export const reviewAnalyticsTooltipDeltaText = (value: number | string, type: string, rawValue: number) => {
  switch (type) {
    case ReviewAnalyticsStatsValue.REVIEWS: {
      return `You had ${value} ${rawValue < 0 ? 'less' : 'more'} reviews from than you did during the same previous time
      period`;
    }
    case ReviewAnalyticsStatsValue.RESPONSE_TIME: {
      return `You ${rawValue < 0 ? 'decreased your time by' : 'increased your time by'} ${value} compared to the same previous time period`;
    }
    case ReviewAnalyticsStatsValue.REPLY: {
      return `You ${
        rawValue < 0
          ? 'decreased by'
          : // ? rawValue === 0 : 'remained same'
            'increased by'
      } ${value}% compared to the same
      previous time period`;
    }
    case PERCENT_REPLIED_IN_NUMBER.value: {
      return `You ${
        rawValue < 0
          ? 'decreased by'
          : // ? rawValue === 0 : 'remained same'
            'increased by'
      } ${value} compared to the same
      previous time period`;
    }
    default: {
      return `You ${
        rawValue < 0
          ? 'decreased by'
          : // ? rawValue === 0 : 'remained same'
            'increased by'
      } ${value} compared to the same
      previous time period`;
    }
  }
};

export const reviewAnalyticsTooltipAvgText = (value: number | string, type: string, isBrand: boolean = true) => {
  switch (type) {
    case ReviewAnalyticsStatsValue.REVIEWS: {
      return `${isBrand ? 'The' : ' Your'} ${isBrand ? 'brand' : 'hub'} received ${value} new reviews per location on average`;
    }
    case ReviewAnalyticsStatsValue.AVERAGE_RATING: {
      return `${isBrand ? 'The' : ' Your'} ${isBrand ? 'brand' : 'hub'} received ${value} on average per location`;
    }
    case ReviewAnalyticsStatsValue.REPLY: {
      return `${isBrand ? 'The' : ' Your'} ${isBrand ? 'brand' : 'hub'} average is ${value}% of the new reviews`;
    }
    case PERCENT_REPLIED_IN_NUMBER.value: {
      return `${isBrand ? 'The' : ' Your'} ${isBrand ? 'brand' : 'hub'} average is ${value}`;
    }
    default: {
      return `${isBrand ? 'The' : ' Your'} ${isBrand ? 'brand' : 'hub'} average is ${value}`;
    }
  }
};

export const statsChartDataHandler = (groupedTableData: IReviewGroupedTableData, platform: string) => {
  const headers = [...groupedTableData.headers].slice(1);
  return headers.length
    ? TAB_GRAPH_SERIES.reduce((acc: IReviewsGraphSeries, curr) => {
        acc[`${curr.key}`] = [...headers].map((datum) => {
          return {
            y: +groupedTableData.summary[platform ? `${platform}_${datum.value}_${curr.value}` : `total_${datum.value}_${curr.value}`] || 0
          };
        });
        return acc;
      }, {})
    : {};
};

export const handleKeywordsTableTotal = (data: { [key: string]: string | number }[]) => {
  return data.reduce(
    (acc: { positive: number; negative: number; used: number; neutral: number; used_percentage: string }, curr: { [key: string]: string | number }) => {
      acc.positive += Number(curr[KeywordManagementTableValue.POSITIVE]);
      acc.negative += Number(curr[KeywordManagementTableValue.NEGATIVE]);
      acc.used += Number(curr[KeywordManagementTableValue.USED]);
      acc.neutral += Number(curr[KeywordManagementTableValue.NEUTRAL]);
      return acc;
    },
    { positive: 0, negative: 0, used: 0, neutral: 0, used_percentage: '' }
  );
};
