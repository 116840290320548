import { useEffect } from 'react';
import { useMatch } from 'react-router';

import { SalesDirectoryListing, SalesRallioActivate, SalesRallioProfile, Community, Reputation } from './Subpages';
import { ScheduleDemoModalPopup } from 'components';
import { SalesRevvPlatform } from './Revv';
import { scrollToTop } from 'utils/helpers';

export const Sales = () => {
  const revvSSO = useMatch('/:type/:id/revv/login');

  useEffect(() => scrollToTop(), []);

  // const dotLoader = () => (
  //   <div className='dot-pulse r-ml2 r-mr2'/>
  // );

  return (
    //     <div className="mainContent animate__animated animate__fadeInUp revv-main">
    <div className="mainContent animate__animated animate__fadeInUp mbl-space">
      <div className="revvssomainsectionwrp lpx vpy-20">
        {false && <SalesRallioProfile />}

        {revvSSO?.pathname ? <SalesRevvPlatform /> : null}

        <SalesRallioActivate />

        <SalesDirectoryListing />

        <ScheduleDemoModalPopup />

        <Community />

        <Reputation />
      </div>
    </div>
  );
};
