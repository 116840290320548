export const CUSTOMER_METRICS_REQUEST = 'customers/CUSTOMER_METRICS_REQUEST';
export const CUSTOMER_METRICS_REQUEST_FAILURE = 'customers/CUSTOMER_METRICS_REQUEST_FAILURE';
export const CUSTOMER_METRICS_RESPONSE = 'customers/CUSTOMER_METRICS_RESPONSE';

export const CUSTOMER_LIST_REQUEST = 'customers/CUSTOMER_LIST_REQUEST';
export const CUSTOMER_LIST_REQUEST_FAILURE = 'customers/CUSTOMER_LIST_REQUEST_FAILURE';
export const CUSTOMER_LIST_RESPONSE = 'customers/CUSTOMER_LIST_RESPONSE';

export const CUSTOMER_DETAIL_REQUEST = 'customers/CUSTOMER_DETAIL_REQUEST';
export const CUSTOMER_DETAIL_RESPONSE = 'customers/CUSTOMER_DETAIL_RESPONSE';
export const CUSTOMER_DETAIL_FAILURE = 'customers/CUSTOMER_DETAIL_FAILURE';

export const CUSTOMER_DELETE_REQUEST = 'customers/CUSTOMER_DELETE_REQUEST';
export const CUSTOMER_DELETE_FAILURE = 'customers/CUSTOMER_DELETE_FAILURE';
export const CUSTOMER_DELETE_RESPONSE = 'customers/CUSTOMER_DELETE_RESPONSE';

export const CUSTOMER_DETAILS_UPDATE_REQUEST = 'customers/CUSTOMER_DETAILS_UPDATE_REQUEST';
export const CUSTOMER_DETAILS_UPDATE_FAILURE = 'customers/CUSTOMER_DETAILS_UPDATE_FAILURE';
export const CUSTOMER_DETAILS_UPDATE_RESPONSE = 'customers/CUSTOMER_DETAILS_UPDATE_RESPONSE';

export const CUSTOMER_CREATE_REQUEST = 'customers/CUSTOMER_CREATE_REQUEST';
export const CUSTOMER_CREATE_FAILURE = 'customers/CUSTOMER_CREATE_FAILURE';
export const CUSTOMER_CREATE_RESPONSE = 'customers/CUSTOMER_CREATE_RESPONSE';

export const CUSTOMER_UPLOAD_REQUEST = 'customers/CUSTOMER_UPLOAD_REQUEST';
export const CUSTOMER_UPLOAD_REQUEST_FAILURE = 'customers/CUSTOMER_UPLOAD_REQUEST_FAILURE';
export const CUSTOMER_UPLOAD_RESPONSE = 'customers/CUSTOMER_UPLOAD_RESPONSE';

export const CUSTOMER_RESET_UPLOAD_SUCCESS_MESSAGE = 'customers/CUSTOMER_RESET_UPLOAD_SUCCESS_MESSAGE';
