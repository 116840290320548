import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { connectionApiTypes } from 'types';

// CONNECTIONS - GET LOCATION LIST
export const getConnectionsLocationListRequest = (payload: connectionApiTypes.IConnectionsListReq) => createAction(actionTypes.GET_CONNECTIONS_LOCATION_LIST_REQUEST, payload);
export const getConnectionsLocationListResponse = (data: connectionApiTypes.IAccountConnectionListResponse) => createAction(actionTypes.GET_CONNECTIONS_LOCATION_LIST_RESPONSE, data);
export const getConnectionsLocationListFailure = (error: null | string) => createAction(actionTypes.GET_CONNECTIONS_LOCATION_LIST_FAILURE, error);

// CONNECTIONS RESET ALL
export const cnLocListAndStatsReset = () => createAction(actionTypes.CN_LOC_LIST_AND_STATS_RESET);
export const cnResetAll = () => createAction(actionTypes.CN_RESET_ALL);

const CONNECTIONS_ACTIONS = {
  getConnectionsLocationListRequest,
  getConnectionsLocationListResponse,
  getConnectionsLocationListFailure,
  cnLocListAndStatsReset,
  cnResetAll
};

export type ConnectionsAction = ActionsUnion<typeof CONNECTIONS_ACTIONS>;
