import { Reducer } from 'redux';

import produce from 'immer';
import * as actionTypes from 'revv/actions/action-types';
import { ISettings } from 'revv/types';
import { SettingsAction } from 'revv/actions';

const initialState: ISettings = {
  isFetching: false,
  error: '',
  settingsDetails: null,
  isSmsMsgFetching: false,
  smsMsgError: '',
  isEmailMsgFetching: false,
  emailMsgError: ''
};

export const settingsReducer: Reducer<ISettings, SettingsAction> = (prevState: ISettings = initialState, action: SettingsAction) => {
  switch (action.type) {
    case actionTypes.SETTINGS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.SETTINGS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.SETTINGS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
        nextState.settingsDetails = action.payload;
      });
    case actionTypes.SETTINGS_MESSAGE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isSmsMsgFetching = true;
        nextState.smsMsgError = '';
      });
    case actionTypes.SETTINGS_MESSAGE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isSmsMsgFetching = false;
        nextState.smsMsgError = action.payload;
      });
    case actionTypes.SETTINGS_MESSAGE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isSmsMsgFetching = false;
        nextState.smsMsgError = '';
      });
    case actionTypes.SETTINGS_EMAIL_MESSAGE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isEmailMsgFetching = true;
        nextState.emailMsgError = '';
      });
    case actionTypes.SETTINGS_EMAIL_MESSAGE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isEmailMsgFetching = false;
        nextState.emailMsgError = action.payload;
      });
    case actionTypes.SETTINGS_EMAIL_MESSAGE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isEmailMsgFetching = false;
        nextState.emailMsgError = '';
      });
    default:
      return prevState;
  }
};
