import { Reducer } from 'redux';

import produce from 'immer';
import * as types from 'revv/actions/action-types';
import { AnalyticsAction } from 'revv/actions';
import { IAnalytics } from 'revv/types/analytics';

const initialState: IAnalytics = {
  error: '',
  isSurveyResultFetching: false,
  isAvgRatingFetching: false,
  isAgreedToReviewFetching: false,
  isSentimentOverTimeFetching: false,
  surveyResultsResponse: null,
  sentimentTimeRespone: null,
  averageRatingResponse: null,
  agreedToReviewResponse: null
};

export const analyticsReducer: Reducer<IAnalytics, AnalyticsAction> = (prevState: IAnalytics = initialState, action: AnalyticsAction) => {
  switch (action.type) {
    case types.SURVEY_RESULTS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isSurveyResultFetching = true;
      });
    case types.SURVEY_RESULTS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isSurveyResultFetching = false;
      });
    case types.SURVEY_RESULTS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isSurveyResultFetching = false;
        nextState.surveyResultsResponse = action.payload;
      });
    case types.SENTIMENT_TIME_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isSentimentOverTimeFetching = true;
      });
    case types.SENTIMENT_TIME_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isSentimentOverTimeFetching = false;
      });
    case types.SENTIMENT_TIME_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isSentimentOverTimeFetching = false;
        nextState.sentimentTimeRespone = action.payload;
      });
    case types.AVERAGE_RATING_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isAvgRatingFetching = true;
      });
    case types.AVERAGE_RATING_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isAvgRatingFetching = false;
      });
    case types.AVERAGE_RATING_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isAvgRatingFetching = false;
        nextState.averageRatingResponse = action.payload;
      });
    case types.AGREED_TO_REVIEW_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isAgreedToReviewFetching = true;
      });
    case types.AGREED_TO_REVIEW_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isAgreedToReviewFetching = false;
      });
    case types.AGREED_TO_REVIEW_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.error = '';
        nextState.isAgreedToReviewFetching = false;
        nextState.agreedToReviewResponse = action.payload;
      });
    case types.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'analytics') {
          nextState.surveyResultsResponse = initialState.surveyResultsResponse;
          nextState.sentimentTimeRespone = initialState.sentimentTimeRespone;
          nextState.averageRatingResponse = initialState.averageRatingResponse;
          nextState.agreedToReviewResponse = initialState.agreedToReviewResponse;
        }
      });
    default:
      return prevState;
  }
};
