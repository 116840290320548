import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { aiContentCreatorReducerTypes } from 'types';
import { AiContentCreatorActions } from 'actions/modules/ai-content-creator';
import { splitTextFromHash } from 'utils/helpers/ai-content-creator';
import { getUniqueValues } from 'utils/helpers';
import { AI_SUBSCRIPTION_TAB, AI_TEXT_CONTENT_INITIAL_VALUE } from 'utils/constants';

type IAIContentCreatorReducer = aiContentCreatorReducerTypes.IAIContentCreatorReducer;

const initialState: IAIContentCreatorReducer = {
  error: null,
  isCreatePostSuggestionsFetching: false,
  aiInputContext: null,
  createPostSuggestions: [],
  createPostHashtagSuggestions: [],
  isAiCreateSimilarPostFetching: false,
  isAiContentOpen: false,
  isReplyResponseFetching: false,
  replyResponseSuggestion: [],
  aiTone: {
    isToneFetching: false,
    aiToneList: []
  },
  aiTags: {
    isAiTagsFetching: false,
    aiTagsList: []
  },
  isAiSubscriptionFetching: false,
  isAISubscribed: true,
  currentSubscription: [],
  aiSubscription: [],
  aiSubscriptionDetailsModal: { isOpen: false, type: 'trialPeriod' },
  aiSubscriptionMainPageType: 'selection',
  subscriptionType: AI_SUBSCRIPTION_TAB[0],
  selectedSubscription: [],
  subscriptionTableData: {},
  selectedSubscriptionTableData: { isActive: false, data: [] },
  isCoachMark: false,
  isAiPost: false,
  isAiPostUsed: false,
  captionsList: [],
  caption: { selectedHashtags: [], index: null },
  isHashtagsAndEmojis: { isHashtags: true, isEmojis: true },
  isPromptActive: false,
  aiGenerated: { caption: false, image: false, hashtag: false },
  aiTextContent: AI_TEXT_CONTENT_INITIAL_VALUE,
  contentSupplierSelectedPlan: '',
  complianceWarning: '',
  isCaptionValidating: false,
  isAICaptionValidationEnabled: true,
  aiLimitation: null,
  csLocationSubscriptionModalType: null
};

const aiContentCreatorReducer: Reducer<IAIContentCreatorReducer, AiContentCreatorActions> = (
  state: IAIContentCreatorReducer = initialState,
  action: AiContentCreatorActions
): IAIContentCreatorReducer => {
  switch (action.type) {
    case types.AI_CONTENT_CREATOR_REQUEST:
      return {
        ...state,
        isCreatePostSuggestionsFetching: true,
        aiInputContext: action.payload.text,
        createPostSuggestions: initialState.createPostSuggestions,
        createPostHashtagSuggestions: initialState.createPostHashtagSuggestions
      };
    case types.AI_CONTENT_CREATOR_RESPONSE:
      const data = splitTextFromHash(action.payload.choices);
      return {
        ...state,
        createPostSuggestions: data.text,
        createPostHashtagSuggestions: data?.hash?.length > 10 ? data?.hash?.slice(0, 10) : data.hash,
        isCreatePostSuggestionsFetching: false
      };
    case types.AI_CONTENT_CREATOR_FAILURE:
      return {
        ...state,
        isCreatePostSuggestionsFetching: false,
        aiInputContext: initialState.aiInputContext
      };
    case types.AI_REPLY_RESPONSE_REQUEST:
      return {
        ...state,
        isReplyResponseFetching: true
      };
    case types.AI_REPLY_RESPONSE_RESPONSE:
      const responseData = splitTextFromHash(action.payload.choices);
      return {
        ...state,
        isReplyResponseFetching: false,
        replyResponseSuggestion: responseData.text
      };
    case types.AI_REPLY_RESPONSE_FAILURE:
      return {
        ...state,
        isReplyResponseFetching: false
      };
    case types.AI_TONE_REQUEST:
      return {
        ...state,
        aiTone: {
          ...initialState.aiTone,
          isToneFetching: true
        }
      };
    case types.AI_TONE_RESPONSE:
      return {
        ...state,
        aiTone: {
          isToneFetching: false,
          aiToneList: action.payload
        }
      };
    case types.AI_TONE_FAILURE:
      return {
        ...state,
        aiTone: initialState.aiTone
      };
    case types.IS_AI_POST_CONTENT_OPEN:
      return {
        ...state,
        isAiContentOpen: action.payload
      };
    case types.IS_AI_POST:
      return {
        ...state,
        isAiPost: action.payload
      };
    case types.IS_AI_POST_USED:
      return {
        ...state,
        isAiPostUsed: action.payload
      };
    case types.AI_CREATE_SIMILAR_POST_REQUEST:
      return {
        ...state,
        isAiCreateSimilarPostFetching: true
      };
    case types.AI_CREATE_SIMILAR_POST_RESPONSE:
      return {
        ...state,
        isAiCreateSimilarPostFetching: false
      };
    case types.AI_CREATE_SIMILAR_AI_POST_FAILURE:
      return {
        ...state,
        isAiCreateSimilarPostFetching: false
      };

    case types.AI_CREATE_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        isAiSubscriptionFetching: true
      };
    case types.AI_CREATE_SUBSCRIPTION_PLAN_RESPONSE:
      return {
        ...state,
        isAiSubscriptionFetching: false
      };
    case types.AI_CREATE_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        isAiSubscriptionFetching: false
      };

    case types.AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        isAiSubscriptionFetching: true
      };
    case types.AI_UPDATE_SUBSCRIPTION_PLAN_RESPONSE:
      return {
        ...state,
        isAiSubscriptionFetching: false
      };
    case types.AI_UPDATE_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        isAiSubscriptionFetching: false
      };

    case types.AI_GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        isAiSubscriptionFetching: true,
        aiLimitation: null
      };
    case types.AI_GET_SUBSCRIPTION_PLAN_RESPONSE:
      return {
        ...state,
        isAiSubscriptionFetching: false,
        aiSubscription: action.payload.aiSubscription,
        isAISubscribed: action.payload?.isAISubscribed,
        currentSubscription: action.payload.currentSubscription,
        // subscriptionType: action.payload.currentSubscription?.length && action.payload.currentSubscription[0].subscription_period === 'year' ? AI_SUBSCRIPTION_TAB[1] : AI_SUBSCRIPTION_TAB[0],
        selectedSubscription: [],
        subscriptionTableData: action.payload.subscriptionTableData
      };
    case types.AI_GET_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        isAiSubscriptionFetching: false,
        isAISubscribed: false
      };
    case types.GET_AI_TAGS_LIST_REQUEST:
      return {
        ...state,
        aiTags: {
          ...initialState.aiTags,
          isAiTagsFetching: true
        }
      };
    case types.GET_AI_TAGS_LIST_RESPONSE:
      return {
        ...state,
        aiTags: {
          isAiTagsFetching: false,
          aiTagsList: getUniqueValues(state.aiTags.aiTagsList.concat(action.payload))
        }
      };
    case types.GET_AI_TAGS_LIST_FAILURE:
      return {
        ...state,
        aiTags: initialState.aiTags,
        error: action.payload
      };
    case types.AI_GET_CAPTIONS_LIST_REQUEST:
      return {
        ...state
      };
    case types.AI_GET_CAPTIONS_LIST_RESPONSE:
      return {
        ...state,
        captionsList: action.payload
      };
    case types.AI_GET_CAPTIONS_LIST_FAILURE:
      return {
        ...state,
        captionsList: []
      };
    case types.AI_CREATE_CAPTION_REQUEST:
      return {
        ...state
      };
    case types.AI_CREATE_CAPTION_RESPONSE:
      return {
        ...state,
        captionsList: [action.payload, ...state.captionsList].slice(0, 10)
      };
    case types.AI_CREATE_CAPTION_FAILURE:
      return {
        ...state,
        captionsList: []
      };
    case types.SET_AI_SUBSCRIPTION_DETAILS_MODAL:
      return {
        ...state,
        aiSubscriptionDetailsModal: action.payload
      };
    case types.SET_AI_SUBSCRIPTION_TYPE:
      return {
        ...state,
        subscriptionType: action.payload
      };
    case types.SET_AI_INPUT_CONTEXT:
      return {
        ...state,
        aiInputContext: action.payload
      };
    case types.SET_SELECTED_SUBSCRIPTION:
      return {
        ...state,
        selectedSubscription: action.payload
      };
    case types.SET_SELECTED_SUBSCRIPTION_TABLE_DATA:
      return {
        ...state,
        selectedSubscriptionTableData: action.payload
      };
    case types.SET_SUBSCRIPTION_MAIN_PAGE_TYPE:
      return {
        ...state,
        aiSubscriptionMainPageType: action.payload
      };
    case types.SET_COACHMARK:
      return {
        ...state,
        isCoachMark: action.payload
      };
    case types.SET_CREATOR_AI_CAPTIONS:
      return {
        ...state,
        caption: action.payload
      };
    case types.AI_SET_TEXT_CONTENT:
      return {
        ...state,
        aiTextContent: action.payload
      };
    case types.RESET_AI_CAPTION_HASHTAG_GENERATION:
      return {
        ...state,
        createPostSuggestions: initialState.createPostSuggestions,
        createPostHashtagSuggestions: initialState.createPostHashtagSuggestions
      };
    case types.RESET_AI_CONTENT_CREATOR:
      const newState = action.payload
        ? {
            ...state,
            ...action.payload,
            aiTone: state.aiTone,
            isAISubscribed: state.isAISubscribed,
            aiSubscription: state.aiSubscription,
            currentSubscription: state.currentSubscription
          }
        : {
            ...initialState,
            aiTone: state.aiTone,
            isAISubscribed: state.isAISubscribed,
            aiSubscription: state.aiSubscription,
            currentSubscription: state.currentSubscription,
            aiLimitation: state.aiLimitation
          };
      return {
        ...newState
      };
    case types.SET_AI_HASHTAGS_AND_EMOJIS:
      return {
        ...state,
        isHashtagsAndEmojis: {
          isEmojis: action.payload.isEmojis,
          isHashtags: action.payload.isHashtags
        }
      };
    case types.SET_AI_PROMPT_ACTIVE:
      return {
        ...state,
        isPromptActive: action.payload
      };
    case types.SET_AI_GENERATED_OPTION:
      return {
        ...state,
        aiGenerated: action.payload
      };
    case types.SET_CONTENT_SUPPLIER_SELECTED_PLAN:
      return {
        ...state,
        contentSupplierSelectedPlan: action.payload
      };
    case types.AI_CAPTION_VALIDATION_REQUEST:
      return {
        ...state,
        complianceWarning: '',
        error: null,
        isCaptionValidating: true
      };
    case types.AI_CAPTION_VALIDATION_RESPONSE:
      return {
        ...state,
        complianceWarning: action.payload,
        isCaptionValidating: false
      };
    case types.AI_CAPTION_VALIDATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isCaptionValidating: false
      };
    case types.ENABLE_AI_CAPTION_VALIDATION:
      return {
        ...state,
        isAICaptionValidationEnabled: action.payload
      };
    case types.AI_UPDATE_LIMITATION_REQUEST:
      return {
        ...state
      };
    case types.AI_UPDATE_LIMITATION_RESPONSE:
      return {
        ...state,
        aiLimitation: action.payload
      };
    case types.AI_UPDATE_LIMITATION_FAILURE:
      return {
        ...state,
        aiLimitation: null,
        error: action.payload
      };
    case types.SET_CS_LOCATION_SUBSCRIPTION_MODAL_TYPE:
      return {
        ...state,
        csLocationSubscriptionModalType: action.payload
      };
    default:
      return state;
  }
};

export { aiContentCreatorReducer };
