// ADD POST - POST ACTION
export const AP_SAVE_POST_ACTION_REQUEST = 'add_post/AP_SAVE_POST_ACTION_REQUEST';
export const AP_SAVE_POST_ACTION_RESPONSE = 'add_post/AP_SAVE_POST_ACTION_RESPONSE';
export const AP_SAVE_POST_ACTION_REQ_FAILURE = 'add_post/AP_SAVE_POST_ACTION_REQ_FAILURE';

// ADD POST - SAVE AS SCHEDULED POST
export const AP_SAVE_POST_AS_SCHEDULED_REQUEST = 'add_post/AP_SAVE_POST_AS_SCHEDULED_REQUEST';
export const AP_SAVE_POST_AS_SCHEDULED_RESPONSE = 'add_post/AP_SAVE_POST_AS_SCHEDULED_RESPONSE';
export const AP_SAVE_POST_AS_SCHEDULED_FAILURE = 'add_post/AP_SAVE_POST_AS_SCHEDULED_FAILURE';

// ADD POST - COUPON LIST
export const AP_COUPON_LIST_REQUEST = 'add_post/AP_COUPON_LIST_REQUEST';
export const AP_COUPON_LIST_RESPONSE = 'add_post/AP_COUPON_LIST_RESPONSE';
export const AP_COUPON_LIST_FAILURE = 'add_post/AP_COUPON_LIST_FAILURE';

// ADD POST - GET FACEBOOK TAGS
export const AP_GET_FB_TAG_LIST_REQUEST = 'add_post/AP_GET_FB_TAG_LIST_REQUEST';
export const AP_GET_FB_TAG_LIST_RESPONSE = 'add_post/AP_GET_FB_TAG_LIST_RESPONSE';
export const AP_GET_FB_TAG_LIST_FAILURE = 'add_post/AP_GET_FB_TAG_LIST_FAILURE';

// ADD POST - UPDATE SAVED POST
export const AP_UPDATE_SAVED_POST_REQUEST = 'add_post/AP_UPDATE_SAVED_POST_REQUEST';
export const AP_UPDATE_SAVED_POST_RESPONSE = 'add_post/AP_UPDATE_SAVED_POST_RESPONSE';
export const AP_UPDATE_SAVED_POST_REQ_FAILURE = 'add_post/AP_UPDATE_SAVED_POST_REQ_FAILURE';

// ADD POST - GET INSTAGRAM PHOTO PREVIEW
export const AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_REQUEST = 'add_post/AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_REQUEST';
export const AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_RESPONSE = 'add_post/AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_RESPONSE';
export const AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_FAILURE = 'add_post/AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_FAILURE';

// ADD POST - ADD BOOST FOR SCHEDULED POST
export const AP_ADD_BOOST_FOR_SCH_POST_REQUEST = 'add_post/AP_ADD_BOOST_FOR_SCH_POST_REQUEST';
export const AP_ADD_BOOST_FOR_SCH_POST_RESPONSE = 'add_post/AP_ADD_BOOST_FOR_SCH_POST_RESPONSE';
export const AP_ADD_BOOST_FOR_SCH_POST_FAILURE = 'add_post/AP_ADD_BOOST_FOR_SCH_POST_FAILURE';

// ADD POST - GET INTERNAL r_aNOTES
export const AP_GET_INTERNAL_NOTES_RESPONSE = 'add_post/AP_GET_INTERNAL_NOTES_RESPONSE';

// ADD POST -  ADD INTERNAL NOTES
export const AP_ADD_INTERNAL_NOTES_RESPONSE = 'add_post/AP_ADD_INTERNAL_NOTES_RESPONSE';
export const AP_ADD_INTERNAL_NOTES_FAILURE = 'add_post/AP_ADD_INTERNAL_NOTES_FAILURE';

// ADD POST - SET TAGS
export const AP_SET_TAGS = 'add_post/AP_SET_TAGS';

// ADD POST - GET ACTION TYPE FOR POST
export const AP_GET_ACTION_TYPE_FOR_POST = 'add_post/AP_GET_ACTION_TYPE_FOR_POST';

// ADD POST - UPDATE SAVED POST DETAILS
export const AP_UPDATE_SAVED_POST_DETAILS = 'add_post/AP_UPDATE_SAVED_POST_DETAILS';

// ADD POST - GET EDIT SAVED POST OBJ
export const AP_GET_EDIT_SAVED_POST_OBJ = 'add_post/AP_GET_EDIT_SAVED_POST_OBJ';

// ADD POST - SET INSTAGRAM PHOTO PREVIEW OBJ
export const AP_SET_INSTA_PHOTO_PREVIEW_OBJ = 'add_post/AP_SET_INSTA_PHOTO_PREVIEW_OBJ';

// ADD POST - SET EDIT CONFIRM FOR APPROVED POST
export const AP_SET_EDIT_CONFIRM_FOR_APPROVED_POST = 'add_post/AP_SET_EDIT_CONFIRM_FOR_APPROVED_POST';

// ADD POST RESET
export const AP_SAVE_POST_MESSAGE_RESET = 'add_post/AP_SAVE_POST_MESSAGE_RESET';
export const AP_SAVED_POST_RESET = 'add_post/AP_SAVED_POST_RESET';
export const AP_FB_TAGS_LIST_RESET = 'add_post/AP_FB_TAGS_LIST_RESET';
export const AP_RESET_ALL = 'add_post/AP_RESET_ALL';

// ADD POST - SET POST NOW STATUS
export const AP_ENABLE_POST_NOW = 'add_post/AP_ENABLE_POST_NOW';
