import { useDispatch, useSelector } from 'react-redux';

import { CommonFilter } from 'components';
import { activeFilterOptions } from 'revv/actions';
import { BUTTON_PROPS } from 'utils/constants';
import { IStore } from 'types';

export const ReportsFilter = () => {
  const isSendSurveyFetching = useSelector((state: IStore) => state.revv.surveys.sendSurveyMsg.isFetching);

  const dispatch = useDispatch();

  return (
    <CommonFilter
      filter={[]}
      sectionVariant="revv-report__filter"
      isDateRangeFilterEnable
      handleChange={() => false}
      filterActionButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_BUTTON,
          label: 'Send Survey',
          parentVariant: 'filter-item connection-report border-0',
          value: 'sendsurvey',
          onClick: () => {
            dispatch(activeFilterOptions('sendsurvey'));
          },
          isFetching: isSendSurveyFetching
        }
      ]}
    />
  );
};
