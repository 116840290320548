import { put, takeLatest, takeEvery } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { NODE_API, defaultHeader, apiCall, API } from 'utils/helpers';
import {
  logoutRequest,
  exceptionHandlerRequest,
  rpGetStatsResponse,
  rpGetListResponse,
  rpPromotionVaultListFailure,
  rpGetStatsFailure,
  rpGetListFailure,
  rpGetProgramDetailResponse,
  rpGetProgramDetailFailure,
  rpDeleteProgramResponse,
  rpDeleteProgramFailure,
  rpGetAchieversListResponse,
  rpGetAchieversListFailure,
  rpEditProgramResendEmailResponse,
  rpEditProgramResendEmailFailure,
  rpCreateOrUpdateProgramResponse,
  rpCreateOrUpdateProgramFailure,
  rpTreeDataResponse,
  rpTreeDataFailure,
  rpPromotionVaultListResponse
} from 'actions';
import { commonApiTypes } from 'types';
import {
  API_TYPES,
  PAGINATION_PER_PAGE,
  RP_STATS_FAILED,
  RP_ERROR,
  RP_LIST_FAILED,
  RP_GET_FAILED,
  RP_DELETE_FAILED,
  RP_DELETE_MESSAGE,
  RP_ACHIEVERS_LIST_FAILED,
  RP_END_DATE_VALIDATE_FAIL,
  RP_ACTIONS_TYPE,
  RP_CREATE_PROGRAM_SUCCESS,
  RP_CREATE_PROGRAM_FAIL,
  RP_UPDATE_PROGRAM_FAIL,
  RP_UPDATE_PROGRAM_SUCCESS,
  RP_PROMOTION_LIST_FAILED,
  USER_OWNERSHIP,
  RP_LOCATION_LIST_FAILED,
  RP_COPY_PROGRAM_SUCCESS_MSG,
  CDN_SOURCE,
  ALERT_BOX_TYPES
} from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendRewardProgramsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.accountId && { accountId: payload.accountId }),
      ...(payload.franchisorId && { franchisorId: payload.franchisorId }),
      page: payload.page,
      perPage: PAGINATION_PER_PAGE,
      ...(payload.searchText && { searchText: payload.searchText }),
      ...(payload.recommendedProgram && { recommendedProgram: payload.recommendedProgram }),
      ...(payload.rewardProgram && { rewardProgram: payload.rewardProgram })
    };
    const response = yield apiCall({ headers, params, ...NODE_API.getRpStatsData, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpGetStatsResponse(response.data.data));
    } else {
      yield put(rpGetStatsFailure(RP_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRewardProgramsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.accountId && { accountId: payload.accountId }),
      ...(payload.franchisorId && { franchisorId: payload.franchisorId }),
      page: payload.page,
      perPage: PAGINATION_PER_PAGE,
      ...(payload.searchText && { searchText: payload.searchText }),
      ...(payload.recommendedProgram && { recommendedProgram: payload.recommendedProgram }),
      ...(payload.rewardProgram && { rewardProgram: payload.rewardProgram })
    };
    const response = yield apiCall({ headers, params, ...NODE_API.getRpListData, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpGetListResponse({ ...response.data.data, page: payload.page || 0 }));
    } else {
      yield put(rpGetListFailure(RP_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendProgramsDataGetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = NODE_API.getRpProgramData.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: NODE_API.getRpProgramData.action, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpGetProgramDetailResponse(response.data.data));
    } else {
      yield put(rpGetProgramDetailFailure(RP_GET_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRpDeleteListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { deleteId, ...data } = payload;
    data.id = deleteId;
    const path = NODE_API.deleteRpList;
    const response = yield apiCall({ headers, data, apiPath: path.apiPath, action: NODE_API.deleteRpList.action, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpDeleteProgramResponse({ message: RP_DELETE_MESSAGE, ...payload }));
    } else {
      yield put(rpDeleteProgramFailure({ message: RP_DELETE_FAILED }));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRpGetAchieversListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = NODE_API.getRpAchieversListData.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: NODE_API.getRpAchieversListData.action, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpGetAchieversListResponse(response.data.data));
    } else {
      yield put(rpGetAchieversListFailure(RP_ACHIEVERS_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendEditProgramResendEmailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { ...payload.data, appCdnSource: CDN_SOURCE };
    const apiPathObj = NODE_API.rewardResendEmail;
    const response = yield apiCall({ headers, data, apiPath: apiPathObj.apiPath, action: apiPathObj.action, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, response.data.message);
      yield put(rpEditProgramResendEmailResponse());
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, RP_END_DATE_VALIDATE_FAIL);
      yield put(rpEditProgramResendEmailFailure());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRpCreateOrUpdateProgramDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { ...payload.data, appCdnSource: CDN_SOURCE };
    const apiPathObj = payload.action === RP_ACTIONS_TYPE.CREATE || !payload.data.programDetails.programId ? NODE_API.postRpProgram : NODE_API.updateRpPogram;

    const response = yield apiCall({ headers, data, apiPath: apiPathObj.apiPath, action: apiPathObj.action, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        rpCreateOrUpdateProgramResponse({
          message:
            payload.action === RP_ACTIONS_TYPE.COPY
              ? RP_COPY_PROGRAM_SUCCESS_MSG
              : payload.action === RP_ACTIONS_TYPE.CREATE || !payload.data.programDetails.programId
              ? RP_CREATE_PROGRAM_SUCCESS
              : RP_UPDATE_PROGRAM_SUCCESS,
          programId: payload
        })
      );
    } else {
      yield put(
        rpCreateOrUpdateProgramFailure({
          message: payload.action === RP_ACTIONS_TYPE.CREATE ? RP_CREATE_PROGRAM_FAIL : RP_UPDATE_PROGRAM_FAIL
        })
      );
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRpTreeDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && { franchisor_id: payload.id }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && { account_id: payload.id })
    };
    const response = yield apiCall({ headers, params, ...API.getTreeData });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(rpTreeDataResponse(response.data));
    } else {
      yield put(rpTreeDataFailure(RP_LOCATION_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

function* sendRpPromotionVaultListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      location_id: payload || 1520880774
    };
    const response = yield apiCall({ headers, params, ...NODE_API.getPromotionsList, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (response.data.status) {
        yield put(rpPromotionVaultListResponse(response?.data?.data));
      } else {
        yield put(rpPromotionVaultListFailure(RP_PROMOTION_LIST_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RP_ERROR));
  }
}

export function* takeRewardProgramsRequest() {
  yield takeLatest(types.RP_GET_STATS_REQUEST, sendRewardProgramsStatsRequest);
  yield takeLatest(types.RP_GET_LIST_REQUEST, sendRewardProgramsListRequest);
  yield takeLatest(types.RP_GET_PROGRAM_DETAIL_REQUEST, sendProgramsDataGetRequest);
  yield takeLatest(types.RP_DELETE_PROGRAM_DATA_REQUEST, sendRpDeleteListRequest);
  yield takeEvery(types.RP_ACHIEVERS_LIST_REQUEST, sendRpGetAchieversListRequest);
  yield takeEvery(types.RP_EDIT_PROGRAM_RESEND_EMAIL_REQUEST, sendEditProgramResendEmailRequest);
  yield takeLatest(types.RP_CREATE_OR_UPDATE_PROGRAM_DATA_REQUEST, sendRpCreateOrUpdateProgramDataRequest);
  yield takeEvery(types.RP_LOCATION_TREE_DATA_REQUEST, sendRpTreeDataRequest);
  yield takeEvery(types.RP_PROMOTION_VAULT_LIST_REQUEST, sendRpPromotionVaultListRequest);
}
