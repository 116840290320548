import parse from 'html-react-parser';

import { ImageValidation } from 'widgets/Media';
import { commonWidgetTypes } from 'types';
import { convertURLAsLink, convertValidUrlFromLinkText, handleLinkNavigation, removeHttpWebFromUrl } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, FBCallToActionOptionsName, FB_CALL_TO_ACTION_SELECT_OPTIONS, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const SocialMediaLinkPreview = (props: commonWidgetTypes.ISocialMediaLinkPreview) => {
  const { imageUrl, isShowThumbnail, linkUrl, linkPreviewTitle, linkPreviewDescription, socialMedia, isLinkCustomized, actionType } = props;

  return socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
    <div className="new-preview csm-wrap" onClick={() => (linkUrl ? handleLinkNavigation(convertValidUrlFromLinkText(linkUrl)) : false)}>
      <div className="np-img">
        <ImageValidation imgUrl={imageUrl || null} defaultImg={null} customClassname={'img-thumb'} customName={'social-media-link-preview'} />
      </div>
      <div className="np-content">
        <div className="np-left">
          <h3>
            <span className="np-form-txt">{isLinkCustomized ? 'links.rall.io' : removeHttpWebFromUrl(linkUrl || '')}</span>
            {/* <span className="np-form-value">Facebook</span> */} 
          </h3>
          <h2>{linkPreviewTitle || ''}</h2>
          <p>{linkPreviewDescription || ''}</p>
        </div>
        {actionType && ![`${FBCallToActionOptionsName.NO_BUTTON}`, `${FBCallToActionOptionsName.WHATSAPP_MESSAGE}`].includes(actionType) ? (
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp border-0 np-right" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0">
              <span>{parse(convertURLAsLink(linkUrl, FB_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === actionType)?.title || '', 'add'))}</span>
            </button>
          </CustomRippleButton>
        ) : null}
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && !actionType ? (
    <div className="linkWrap">
      {/* eslint-disable-next-line */}
      <a href={linkUrl} target="_blank">
        Learn More
      </a>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? (
    <div className="new-preview" onClick={() => (linkUrl ? handleLinkNavigation(convertValidUrlFromLinkText(linkUrl)) : false)}>
      <div className="np-img">
        <ImageValidation imgUrl={imageUrl || null} defaultImg={null} customClassname={'img-thumb'} customName={'social-media-link-preview'} />
      </div>
      <div className="np-content">
        <div className="np-left">
          <h2>{linkPreviewTitle || ''}</h2>
          <h3>
            <span className="np-form-txt">{removeHttpWebFromUrl(linkUrl || '')}</span>
          </h3>
          {/* {socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && <p>{linkPreviewDescription || ''}</p>} */}
        </div>
      </div>
    </div>
  ) : ![COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(socialMedia) ? (
    <div className="coupon-link-preview-wrp" onClick={() => (linkUrl ? handleLinkNavigation(convertValidUrlFromLinkText(linkUrl)) : false)}>
      <div className={isShowThumbnail && imageUrl ? 'grid-section' : 'gs-no-img'}>
        {isShowThumbnail && imageUrl && (
          <div className="left-section">
            <ImageValidation imgUrl={imageUrl} defaultImg={null} customClassname={'img-thumb'} customName={'social-media-link-preview'} />
          </div>
        )}
        <div className="right-section">
          <div className="learn-more-section">
            {linkUrl ? (
              <>
                <div className="url-head">{linkUrl}</div>
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  <div className="url-subhead-txt">{linkPreviewTitle || ''}</div>
                </a>
              </>
            ) : null}
            {linkPreviewDescription && (
              <div className="url-head" title={linkPreviewDescription}>
                {linkPreviewDescription}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
