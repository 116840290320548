import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { SIDE_NAV_NAME, CREATOR_SAVE_CHANGES_IN_HEADER_OBJ, USER_OWNERSHIP, RALLIO_PROFILE_TEXT } from 'utils/constants';
import { CommonSaveChangesConfirmModal } from 'components';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { TrackGoogleAnalyticsEvent } from 'utils/helpers';

export const HeaderTabs = ({ hideLogo, isTabProfileNamechange }: { hideLogo?: boolean; isTabProfileNamechange?: boolean }) => {
  const { mainNavPageName, selectedSubNavData, subNavPageName } = useNavBarData();
  const { id, userOwnership, isAdminScreenEnabled, currentRoute } = useAccountSwitcherData();

  const location = `/${currentRoute}`;

  const contentModified = useSelector((state: IStore) => state.common.contentModified);
  const salesPages = useSelector((state: IStore) => state.common.salesPages);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const activeRevvUser = useSelector((state: IStore) => state.revv.login.activeUser?.role);
  const activeUser = useSelector((state: IStore) => state.login.activeUser);
  const accountSwitcherData = useSelector((state: IStore) => state.accountSwitcher);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);

  const navigate = useNavigate();

  const hideTabs = useMemo(() => {
    const page = salesPages.find((it) => location.substring(1, location.length).startsWith(it.replace('-sales', '')));
    if (page) {
      return page?.split('/')?.length === 1;
    }
    return false;
  }, [salesPages, location]);

  const [saveChangesObj, setSaveChangesObj] = useState(CREATOR_SAVE_CHANGES_IN_HEADER_OBJ);

  const activeSideNav = useMemo(() => {
    return location.split('/').filter((it) => it)[0];
  }, [location]);

  const handleSubTabNav = (subTabName: null | string) => {
    if (subTabName) {
      const route = isAdmin && isAdminScreenEnabled ? `/${mainNavPageName}/${subTabName}` : `/${userOwnership}/${id}/${mainNavPageName}/${subTabName}`;
      navigate(route);
    }
  };

  const handleSelectedNavTab = (selectedKey: null | string) => {
    if (selectedKey === SIDE_NAV_NAME.CONTENT_MEDIA) {
      handleSubTabNav('media/photos');
    } else if (selectedKey === SIDE_NAV_NAME.CONTENT_CALENDAR) {
      handleSubTabNav(userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'calendar/scheduled' : 'calendar');
    } else if (selectedKey === SIDE_NAV_NAME.DL_PROFILE) {
      handleSubTabNav('profile/store_details');
    } else if (selectedKey === SIDE_NAV_NAME.REVV_SETTINGS) {
      if (activeRevvUser === 'employee') {
        handleSubTabNav('revv_settings/connection');
      } else {
        handleSubTabNav('revv_settings/global');
      }
    } else if (selectedKey === SIDE_NAV_NAME.REVV_LOCATION) {
      handleSubTabNav('locations');
    } else if (selectedKey === SIDE_NAV_NAME.REVV_EMPLOYEES) {
      handleSubTabNav('employees');
    } else if (selectedKey === SIDE_NAV_NAME.COMMUNITY_REVV) {
      if (activeSideNav === 'community') {
        handleSubTabNav('revv/locations');
      } else {
        handleSubTabNav(selectedKey);
      }
    } else if (selectedKey === SIDE_NAV_NAME.ANALYTICS_LEADERBOARD) {
      if (isRevvAuthenticated && isRevvConnectedToCurrentFranchisor && userOwnership === USER_OWNERSHIP.ACCOUNT) {
        handleSubTabNav('analytics_leaderboard/revv/locations');
      } else {
        handleSubTabNav(selectedKey);
      }
    } else {
      handleSubTabNav(selectedKey);
    }
  };
  return (
    <>
      {hideTabs ? null : (
        <ul>
          {selectedSubNavData?.length > 0
            ? selectedSubNavData.map((subNavDatum: any, index: number) => {
                return subNavDatum.isShow ? (
                  <li
                    className={`ripple${subNavPageName === subNavDatum.name ? ` active` : ``}`}
                    onClick={() => {
                      TrackGoogleAnalyticsEvent('sub_menu', subNavDatum.name, `${window.location.pathname}${window.location.search}`, {
                        menu_name: subNavDatum.name,
                        account_id: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0,
                        franchisor_id: accountSwitcherData.topLevelFranchisorObj?.id,
                        user_id: activeUser?.id,
                        user_ids: activeUser?.id,
                        user_name: `${activeUser?.first_name} ${activeUser?.last_name}`,
                        franchisor_name: accountSwitcherData.currentName,
                        account_name: accountSwitcherData.currentName,
                        platform: 'Web'
                      });
                      // Check whether the content changes to be discarded
                      if (contentModified && subNavPageName !== subNavDatum.name) {
                        setSaveChangesObj({ isShow: true, subPageName: subNavDatum.name });
                      } else {
                        handleSelectedNavTab(subNavDatum.name);
                      }
                    }}
                    key={`sub-nav-${index}`}
                  >
                    <span className="sub-nav-item-txt">
                      {hideLogo ? subNavDatum.label.replace('Rallio', '') : subNavDatum.label === RALLIO_PROFILE_TEXT ? (!isTabProfileNamechange ? subNavDatum.label : 'Profile') : subNavDatum.label}
                    </span>
                  </li>
                ) : null;
              })
            : null}
        </ul>
      )}
      <CommonSaveChangesConfirmModal
        isModalShow={saveChangesObj.isShow}
        handleModalClose={() => setSaveChangesObj(CREATOR_SAVE_CHANGES_IN_HEADER_OBJ)}
        handleNavigation={() => {
          handleSelectedNavTab(saveChangesObj.subPageName);
        }}
      />
    </>
  );
};
