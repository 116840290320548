import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SettingsSocialProfileActions } from 'actions';
import { settingsSocialProfileReducerTypes } from 'types';
import { FACEBOOK_PAGES_INITIAL_VALUE, LINKEDIN_PAGES_INITIAL_VALUE, YELP_DETAILS_INITIAL_VALUES } from 'utils';

const initialState: settingsSocialProfileReducerTypes.ISocialProfileReducer = {
  yelpDetails: YELP_DETAILS_INITIAL_VALUES,
  linkedInPages: LINKEDIN_PAGES_INITIAL_VALUE,
  facebookPages: FACEBOOK_PAGES_INITIAL_VALUE,
  mediaRequest: {
    twitter: true,
    linkedIn: true,
    facebook: true,
    instagram: true,
    instagram_business_page: true,
    google_oauth2: true
  },
  mediaSettingPayload: null,
  fbSettingPayload: null,
  fbUserCredentialId: null,
  googleCredentialId: null,
  googleBusinessAccountList: [],
  isGoogleAccountFetching: false,
  googleLocationsList: [],
  providingFbPageUrl: FACEBOOK_PAGES_INITIAL_VALUE
};

export const settingsSocialProfileReducer: Reducer<settingsSocialProfileReducerTypes.ISocialProfileReducer, SettingsSocialProfileActions> = (
  state = initialState,
  action: SettingsSocialProfileActions
) => {
  switch (action.type) {
    case types.SETTINGS_UPDATE_YELP_DETAILS:
      return {
        ...state,
        yelpDetails: action.payload
      };
    case types.DELETE_SOCIAL_MEDIA_SETTING: {
      const mediaRequest = state.mediaRequest;
      mediaRequest[action.payload.type] = true;
      return {
        ...state,
        mediaRequest
      };
    }
    case types.UPDATE_SOCIAL_MEDIA_SETTING: {
      const mediaRequest = state.mediaRequest;
      mediaRequest[action.payload.type] = true;
      return {
        ...state,
        mediaSettingPayload: action.payload.type === 'linkedIn' ? action.payload : state.mediaSettingPayload,
        mediaRequest
      };
    }
    case types.UPDATE_SOCIAL_MEDIA_SETTING_SUCCESS_FAILURE: {
      const mediaRequest = state.mediaRequest;
      Object.keys(mediaRequest).forEach((it) => {
        mediaRequest[it] = mediaRequest[it] ? false : mediaRequest[it];
      });
      return {
        ...state,
        mediaRequest
      };
    }
    case types.LINKEDIN_PAGES_REQUEST:
      return {
        ...state,
        linkedInPages: { ...state.linkedInPages, isFetching: true }
      };
    case types.LINKEDIN_PAGES_REQUEST_FAIL:
      return {
        ...state,
        linkedInPages: { ...state.linkedInPages, isFetching: false, error: action.payload.error }
      };
    case types.LINKEDIN_PAGES_RESPONSE:
      return {
        ...state,
        linkedInPages: { ...state.linkedInPages, isFetching: false, data: action.payload.data, error: '' }
      };
    case types.SAVE_FACEBOOK_USER_CREDENTIAL: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.facebook = true;
      mediaRequest.instagram_business_page = true;
      return {
        ...state,
        fbSettingPayload: action.payload,
        mediaRequest
      };
    }
    case types.SAVE_FACEBOOK_USER_CREDENTIAL_RESPONSE:
      return {
        ...state,
        fbUserCredentialId: action.payload.id
      };
    case types.FACEBOOK_PAGES_REQUEST:
      return {
        ...state,
        facebookPages: { ...initialState.facebookPages, isFetching: true }
      };
    case types.FB_PAGE_PROVIDING_URL_REQUEST:
      return {
        ...state,
        providingFbPageUrl: { ...initialState.providingFbPageUrl, isFetching: true }
      };
    case types.FB_PAGE_PROVIDING_URL_RESPONSE:
      return {
        ...state,
        providingFbPageUrl: { ...state.providingFbPageUrl, isFetching: false, data: action.payload.data, error: '' }
      };
    case types.FB_PAGE_PROVIDING_URL_FAILURE:
      return {
        ...state,
        providingFbPageUrl: { ...state.providingFbPageUrl, isFetching: false, error: action.payload.error }
      };
    case types.FACEBOOK_PAGES_REQUEST_FAIL:
      return {
        ...state,
        facebookPages: { ...state.facebookPages, isFetching: false, error: action.payload.error }
      };
    case types.FACEBOOK_PAGES_RESPONSE:
      return {
        ...state,
        facebookPages: { ...state.facebookPages, isFetching: false, data: state.facebookPages.data.concat(action.payload.data), error: '' }
      };
    case types.CONNECT_INSTAGRAM: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.instagram = true;
      return {
        ...state,
        mediaRequest
      };
    }
    case types.CONNECT_INSTAGRAM_SUCCESS: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.instagram = false;
      return {
        ...state,
        mediaRequest
      };
    }
    case types.DISCONNECT_INSTAGRAM: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.instagram = true;
      return {
        ...state,
        mediaRequest
      };
    }
    case types.DISCONNECT_INSTAGRAM_SUCCESS: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.instagram = false;
      return {
        ...state,
        mediaRequest
      };
    }
    case types.SAVE_GOOGLE_OAUTH_CREDENTIAL_RESPONSE:
      return { ...state, googleCredentialId: action.payload.credentialId };
    case types.LOAD_GOOGLE_BUSINESS_ACCOUNT_REQUEST: {
      const mediaRequest = state.mediaRequest;
      mediaRequest.google_oauth2 = true;
      return { ...state, isGoogleAccountFetching: true };
    }
    case types.LOAD_GOOGLE_BUSINESS_ACCOUNT_RESPONSE:
      return { ...state, googleBusinessAccountList: action.payload.data, isGoogleAccountFetching: false };
    case types.LOAD_GOOGLE_LOCATIONS_REQUEST:
      return { ...state, isGoogleAccountFetching: true };
    case types.LOAD_GOOGLE_LOCATIONS_RESPONSE: {
      let googleLocations = state.googleLocationsList;
      if (action.payload.dataPacketNo === 1) {
        googleLocations = action.payload.data;
      } else {
        googleLocations = [...googleLocations].concat(action.payload.data);
      }
      return { ...state, googleLocationsList: googleLocations, isGoogleAccountFetching: false };
    }
    case types.SAVE_FACEBOOK_PAGE_CREDENTIAL:
      return { ...state, mediaRequest: { ...state.mediaRequest, instagram_business_page: action.payload.isInstagram ? true : false } };
    case types.SOCIAL_PROFILE_RESET_ALL:
      return { ...initialState, mediaRequest: state.mediaRequest };
    default:
      return state;
  }
};
