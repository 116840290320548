// REVIEWS LIST
export const REVIEWS_GET_LIST_REQUEST = 'reviews/REVIEWS_GET_LIST_REQUEST';
export const REVIEWS_GET_LIST_RESPONSE = 'reviews/REVIEWS_GET_LIST_RESPONSE';
export const REVIEWS_GET_LIST_FAILURE = 'reviews/REVIEWS_GET_LIST_FAILURE';

// REVIEWS STATS
export const REVIEWS_GET_STATS_REQUEST = 'reviews/REVIEWS_GET_STATS_REQUEST';
export const REVIEWS_GET_STATS_RESPONSE = 'reviews/REVIEWS_GET_STATS_RESPONSE';
export const REVIEWS_GET_STATS_FAILURE = 'reviews/REVIEWS_GET_STATS_FAILURE';

// REVIEWS - ADD REPLY TO A POST
export const REVIEWS_ADD_REPLY_TO_A_POST_REQUEST = 'reviews/REVIEWS_ADD_REPLY_TO_A_POST_REQUEST';
export const REVIEWS_ADD_REPLY_TO_A_POST_RESPONSE = 'reviews/REVIEWS_ADD_REPLY_TO_A_POST_RESPONSE';
export const REVIEWS_ADD_REPLY_TO_A_POST_FAILURE = 'reviews/REVIEWS_ADD_REPLY_TO_A_POST_FAILURE';

// REVIEWS - COMPLETE POST
export const REVIEWS_COMPLETE_POST_REQUEST = 'reviews/REVIEWS_COMPLETE_POST_REQUEST';
export const REVIEWS_COMPLETE_POST_RESPONSE = 'reviews/REVIEWS_COMPLETE_POST_RESPONSE';
export const REVIEWS_COMPLETE_POST_FAILURE = 'reviews/REVIEWS_COMPLETE_POST_FAILURE';

// REVIEWS - GET INTERNAL NOTES
export const REVIEWS_GET_INTERNAL_NOTES_REQUEST = 'reviews/REVIEWS_GET_INTERNAL_NOTES_REQUEST';
export const REVIEWS_GET_INTERNAL_NOTES_RESPONSE = 'reviews/REVIEWS_GET_INTERNAL_NOTES_RESPONSE';
export const REVIEWS_GET_INTERNAL_NOTES_FAILURE = 'reviews/REVIEWS_GET_INTERNAL_NOTES_FAILURE';

// REVIEWS - ADD INTERNAL NOTE
export const REVIEWS_ADD_INTERNAL_NOTE_REQUEST = 'reviews/REVIEWS_ADD_INTERNAL_NOTE_REQUEST';
export const REVIEWS_ADD_INTERNAL_NOTE_RESPONSE = 'reviews/REVIEWS_ADD_INTERNAL_NOTE_RESPONSE';
export const REVIEWS_ADD_INTERNAL_NOTE_FAILURE = 'reviews/REVIEWS_ADD_INTERNAL_NOTE_FAILURE';

// REVIEWS - HANDLE POST
export const REVIEWS_HANDLE_POST_REQUEST = 'reviews/REVIEWS_HANDLE_POST_REQUEST';
export const REVIEWS_HANDLE_POST_RESPONSE = 'reviews/REVIEWS_HANDLE_POST_RESPONSE';
export const REVIEWS_HANDLE_POST_FAILURE = 'reviews/REVIEWS_HANDLE_POST_FAILURE';

// REVIEWS - SEND FOR REPLY APPROVAL
export const REVIEWS_SEND_FOR_REPLY_APPROVAL_REQUEST = 'reviews/REVIEWS_SEND_FOR_REPLY_APPROVAL_REQUEST';
export const REVIEWS_SEND_FOR_REPLY_APPROVAL_RESPONSE = 'reviews/REVIEWS_SEND_FOR_REPLY_APPROVAL_RESPONSE';
export const REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILURE = 'reviews/REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILURE';

// REVIEWS - UPDATE SUBMITTED REPLY
export const REVIEWS_UPDATE_SUBMITTED_REPLY_REQUEST = 'reviews/REVIEWS_UPDATE_SUBMITTED_REPLY_REQUEST';
export const REVIEWS_UPDATE_SUBMITTED_REPLY_RESPONSE = 'reviews/REVIEWS_UPDATE_SUBMITTED_REPLY_RESPONSE';
export const REVIEWS_UPDATE_SUBMITTED_REPLY_FAILURE = 'reviews/REVIEWS_UPDATE_SUBMITTED_REPLY_FAILURE';

// REVIEWS - APPROVE REPLY
export const REVIEWS_APPROVE_REPLY_REQUEST = 'reviews/REVIEWS_APPROVE_REPLY_REQUEST';
export const REVIEWS_APPROVE_REPLY_RESPONSE = 'reviews/REVIEWS_APPROVE_REPLY_RESPONSE';
export const REVIEWS_APPROVE_REPLY_FAILURE = 'reviews/REVIEWS_APPROVE_REPLY_FAILURE';

// REVIEWS - REJECT REPLY
export const REVIEWS_REJECT_REPLY_REQUEST = 'reviews/REVIEWS_REJECT_REPLY_REQUEST';
export const REVIEWS_REJECT_REPLY_RESPONSE = 'reviews/REVIEWS_REJECT_REPLY_RESPONSE';
export const REVIEWS_REJECT_REPLY_FAILURE = 'reviews/REVIEWS_REJECT_REPLY_FAILURE';

// REVIEWS - DELETE REPLY FOR GOOGLE POST
export const REVIEWS_DELETE_REPLY_FOR_GOOGLE_REQUEST = 'reviews/REVIEWS_DELETE_REPLY_FOR_GOOGLE_REQUEST';
export const REVIEWS_DELETE_REPLY_FOR_GOOGLE_RESPONSE = 'reviews/REVIEWS_DELETE_REPLY_FOR_GOOGLE_RESPONSE';
export const REVIEWS_DELETE_REPLY_FOR_GOOGLE_FAILURE = 'reviews/REVIEWS_DELETE_REPLY_FOR_GOOGLE_FAILURE';

// SET NOTES
export const REVIEWS_SET_INTERNAL_NOTES_LIST = 'reviews/REVIEWS_SET_INTERNAL_NOTES_LIST';

// REVIEWS RESET
export const REVIEWS_LIST_DATA_RESET = 'reviews/REVIEWS_LIST_DATA_RESET';
export const REVIEWS_UPDATED_MSG_RESET = 'reviews/REVIEWS_UPDATED_MSG_RESET';
export const REVIEWS_RESET_ALL = 'reviews/REVIEWS_RESET_ALL';
