import { IAISugesstionTabs, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';

export const AIPrompt = ({ handlePromptClose, activeTab }: { handlePromptClose: () => void; activeTab: string }) => {
  return activeTab === IAISugesstionTabs.CAPTIONS_AND_HASHTAGS ? (
    <div className="ai-prompt-text">
      <div className="aip-head-wrap br-ai">
        <h3 className="aip__wrap--txt">How to get the best results from your AI Assistant:</h3>
        <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
          <button onClick={handlePromptClose}>
            <span>X</span>
          </button>
        </CustomRippleButton>
      </div>
      <div className="ai-ideas">
        <h3>What makes a good prompt?</h3>
        <p>
          <strong>Be specific:</strong>
          <span>
            If you're creating a social media post about a new product launch, a specific prompt could be: "Please generate a caption that highlights the key features of our new product, the XYZ
            widget."
          </span>
        </p>
        <p>
          <strong>Use relevant keywords:</strong>
          <span>
            For a post about a new restaurant opening, a relevant keyword prompt could be: "Please generate a caption that includes the words 'foodie hotspot' and 'culinary adventure' to describe our
            new restaurant."
          </span>
        </p>
        <p>
          <strong>Be creative:</strong>
          <span>
            If you're creating a post about a new fashion line, a creative prompt could be: "Please generate a caption that compares our new line to a bold and daring adventure, highlighting the
            unique and adventurous nature of our designs."
          </span>
        </p>
        <p>
          <strong>Test different prompts:</strong>
          <span>
            If you're creating a post about a travel destination, try different prompts to see what works best. For example, you could try "Please generate a caption that captures the beauty and
            serenity of this location" or "Please generate a caption that emphasizes the exciting and adventurous activities available in this destination."
          </span>
        </p>
        <p>
          <strong>Impersonate a celebrity voice:</strong>
          <span>
            If you're looking to create a post in the voice of a specific celebrity or influencer, you could try “Please generate a caption in the voice of Dwayne "The Rock" Johnson, about … and
            include a quote.” Alternatively, for an impersonation of Taylor Swift, a prompt could be: "Please generate a caption that uses clever wordplay and metaphors to describe our new album, in a
            style reminiscent of Taylor Swift's songwriting." By using prompts that impersonate a specific celebrity voice, you can add a fun and unique twist to your social media posts.
          </span>
        </p>
        <p>
          <strong>Use product page URLs:</strong>
          <span>
            If you're looking to create a post about a specific product or service, you could try "Write a social media caption for a post about this product [product page URL]" to generate amazing
            captions.
          </span>
        </p>
      </div>
    </div>
  ) : (
    <div className="ai-prompt-text">
      <div className="aip-head-wrap br-ai">
        <h3 className="aip__wrap--txt">How to get the best results from your AI Assistant:</h3>
        <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
          <button onClick={handlePromptClose}>
            <span>X</span>
          </button>
        </CustomRippleButton>
      </div>
      <div className="ai-ideas aim-info">
        <h3>What makes a good prompt?</h3>
        <p>Don’t worry: it’s easier than it seems. Follow this short guide and you’ll become a prompting expert in no time. A good prompt has three components, each more important than the next:</p>
        <ol>
          <li>
            <span>1.</span> Subject and action
          </li>
          <li>
            <span>2.</span> Setting
          </li>
          <li>
            <span>3.</span> Look and feel
          </li>
        </ol>
        <p>In the examples below, we’ll start with a simple prompt and iterate on it until we get a final result we love.</p>
        <h3>First, start with the subject and what they are doing</h3>
        <p>The subject of the image should answer:</p>
        <ul>
          <li>Who or what is the subject of the image?</li>
          <li>What do they look like?</li>
          <li>What are they doing?</li>
        </ul>
        <p>Let’s look at some examples from bad to better.</p>
        <h4>
          Prompt 1: <span>“Handsome man”</span>
        </h4>
        <ImageValidation isImgValid defaultImg="handsomeMan.png" isNotSvgFormat customName="Handsome man" customClassname="aim-imgs" />
        <h4>
          Prompt 2: “Handsome man <span>in a three-piece suit</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="inAThreePieceSuit.png" isNotSvgFormat customName="in a three-piece suit" customClassname="aim-imgs" />
        <h4>
          Prompt 3: “Handsome man in a three-piece suit <span>running</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="running.png" isNotSvgFormat customName="running" customClassname="aim-imgs" />
        <h3>Next, add the setting</h3>
        <p>Where is the subject and what does the setting look like? We’ll answer these questions by iterating on our prompt above.</p>
        <h4>
          Prompt 4: “Handsome man in a three-piece suit running <span>in a forest</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="inAForest.png" isNotSvgFormat customName="in a forest" customClassname="aim-imgs" />
        <h4>
          Prompt 5: “Handsome man in a three-piece suit running <span>in a lush tropical forest full of overgrown vegetation</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="overgrownVegetation.png" isNotSvgFormat customName="overgrown vegetation" customClassname="aim-imgs" />
        <h3>Finally, you can change the look and feel of the image</h3>
        <p>Let’s see three possible variations in our image above.</p>
        <h4>
          Prompt 6: “Handsome man in a three-piece suit running in a lush tropical forest full of overgrown vegetation <span>at golden hour, golden rays of light through the leaves</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="lightThroughLeaves.png" isNotSvgFormat customName="light through the leaves" customClassname="aim-imgs" />
        <h4>
          Prompt 7: “Handsome man in a three-piece suit running in a lush tropical forest full of overgrown vegetation <span>at night, moon in the sky, low-key, dimly lit</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="dimlyLit.png" isNotSvgFormat customName="dimly lit" customClassname="aim-imgs" />
        <h4>
          Prompt 8: “Handsome man in a three-piece suit running in a lush tropical forest full of overgrown vegetation <span>in heavy rainfall during a monsoon</span>”
        </h4>
        <ImageValidation isImgValid defaultImg="monsoon.png" isNotSvgFormat customName="monsoon" customClassname="aim-imgs" />
      </div>
    </div>
  );
};
