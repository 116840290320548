import { settingsRallioProfileContainerTypes } from 'types';
import { compareProfileObjects } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RALLIO_PROFILE_TAB_FILEDS, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const URLsSection = ({
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames,
  handleIndividualCancel,
  handleIndividualSave
}: settingsRallioProfileContainerTypes.ISettingsCommonCompProps) => {
  const { optionalParams } = useAccountSwitcherData();

  return accountId ? (
    <div className="settings-rallio-profile-new-section wn-urls wnh">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">URLs</h3>
        </div>
        <div className="content-right-section form-elements-new">
          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}
              value={String(values[RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL] || '')}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL)}`}>Job Page URL</span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL] ? String(values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>Website URL</span>
          </div>
        </div>
      </div>

      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.URL, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.URL)}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.URL)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
