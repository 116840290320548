import { Suspense } from 'react';

import { Loading } from 'components';
import { commonRouterTypes } from 'types';

export const PublicRoute: React.FunctionComponent<commonRouterTypes.IPublicRoute> = ({ routeObj }: any) => {
  const Component = routeObj.component;
  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};
