import Highcharts from 'highcharts';

import { ToolTipLabel, TOOLT_TIP_COLOR } from 'analytics/utils/constants';
import { config } from 'config';
import { convertMsTimeFormat } from './dateHelper';
import { getFormattedNumber, getRoundNumberTo2Decimal } from 'utils/helpers';

export const multiSeriesToolTipHandler = (
  x: string | number = 0,
  value: number[],
  highChartPoints: any,
  toolTipColor: { [key: string]: string }[],
  resultObj: { [key: string]: any[] },
  isContentSupplier: boolean,
  isAverage: boolean,
  isTimeFormat: boolean,
  isDelta: boolean,
  isPercentage: boolean,
  isToFixed: boolean
  // for tooltip avg to check for ALL or color card selected, and for dotted line tooltip label,
) => {
  let tooltip: string = '';
  const date: string = x.toString();
  tooltip = `<span class='ra-tooltip-date'>
        <span>${date}</span></span>
    ${highChartPoints?.points.reduce((acc: string, it: any, index: any) => {
      let deltaIcon = 'an-rating-grey';
      const delta = isTimeFormat ? -1 * value[index] : value[index];
      if (delta > 0) {
        deltaIcon = 'an-ratings-up-green';
      } else if (delta < 0) {
        deltaIcon = 'rating-down-red';
      }
      const data = toolTipColor.find((datum) => datum.color === it.color);
      const label = data?.label || data?.title || it?.series?.name || ToolTipLabel.BRAND_AVERAGE;
      const classVarient = data?.class;
      // const statObject = Object.keys(resultObj);
      const toolTiplabel = label ? (label === 'All' ? (isAverage ? 'Average' : isContentSupplier ? 'Average total per day' : 'Total') : label) : ToolTipLabel.BRAND_AVERAGE;
      const isTooltTipValue = toolTipColor.filter((it) => (it?.label || it?.title) !== ToolTipLabel.BRAND_AVERAGE && (it?.label || it?.title) !== ToolTipLabel.HUB_AVERAGE).length > 0;
      // statObject.length > 1 ? (toolTiplabel !== ToolTipLabel.BRAND_AVERAGE && toolTiplabel !== ToolTipLabel.HUB_AVERAGE) : true;
      const tolltipYValue: number = getRoundNumberTo2Decimal(it.point?.options.toolTipY) || (it.y as number);
      const timeFormatYValue = tolltipYValue && isTimeFormat ? convertMsTimeFormat(tolltipYValue, true) : getFormattedNumber(+tolltipYValue);
      const toolTipDelta = isTimeFormat ? convertMsTimeFormat(Math.abs(delta), true) : delta;
      const brandAverage = it.point.avg !== null && it.point.avg !== undefined ? (isTimeFormat ? convertMsTimeFormat(Math.abs(it.point.avg), true) : getRoundNumberTo2Decimal(+it?.point?.avg)) : null;
      const hubAverage =
        it.point.hubAvg !== null && it.point.hubAvg !== undefined ? (isTimeFormat ? convertMsTimeFormat(Math.abs(it.point.hubAvg), true) : getRoundNumberTo2Decimal(+it?.point?.hubAvg)) : null;

      if (isTooltTipValue && toolTiplabel !== ToolTipLabel.BRAND_AVERAGE && toolTiplabel !== ToolTipLabel.HUB_AVERAGE) {
        acc = acc.concat(`<div class="tooltip-tile-wrp">
      <div class="follwers-wrp">
        <div class='chart-tlp-lbl'>
          <span class='${classVarient}'>${toolTiplabel}</span>
        </div>
        <span class='y-value fontincrese-txt'>${isToFixed ? Number(timeFormatYValue).toFixed(1) : timeFormatYValue}${isPercentage ? '%' : ''}</span>
        ${
          label && isDelta
            ? `<div class="ov-tooltip-ratings-wrp">
          <img class="delta-icon ${isTimeFormat ? 'sort-icon__rotate' : ''}" src=${config.cdnImgUrl}${deltaIcon}.svg />
            <span class='percent-value'>${getFormattedNumber(+toolTipDelta || 0)}</span>
        </div>`
            : ''
        }
      </div>
       ${it.point.avg !== null && it.point.avg !== undefined ? `<div class='avg'>${`${ToolTipLabel.BRAND_AVERAGE} ${getFormattedNumber(brandAverage ? +brandAverage : 0)}`}</div>` : ''}
       ${it.point.hubAvg !== null && it.point.hubAvg !== undefined ? `<div class='avg'>${`${ToolTipLabel.HUB_AVERAGE} ${getFormattedNumber(hubAverage ? +hubAverage : 0)}`}</div>` : ''}
       </div>`);
      }
      return acc;
    }, '')}`;
  return tooltip;
};

// function to handle the tooltip data
export const multiSeriesToolTipFormatter = (
  highChartPoints: Highcharts.TooltipFormatterContextObject,
  toolTipColor: { [key: string]: string }[] = TOOLT_TIP_COLOR,
  resultObj: { [key: string]: any[] },
  isContentSupplier: boolean = false,
  isAverage: boolean = false,
  isTimeFormat: boolean = false,
  isDelta: boolean = true,
  isPercentage: boolean = false,
  isToFixed: boolean = false
) => {
  // const resultingObj = Object.keys(resultObj);
  if (highChartPoints && highChartPoints?.points?.length) {
    const pointY: number[] = [];
    const toolTipDeltas: number[] = [];
    const toolTipDeltaPercs: number[] = [];
    const pointColor: string[] = [];
    highChartPoints?.points?.forEach((it: any) => {
      toolTipDeltas.push(getRoundNumberTo2Decimal(it.point?.options?.toolTipDelta));
      pointY.push(getRoundNumberTo2Decimal(it.point?.options?.toolTipY));
      toolTipDeltaPercs.push(getRoundNumberTo2Decimal(it.point?.options?.toolTipDeltaPerc));
      pointColor.push(it.color);
    });
    return multiSeriesToolTipHandler(
      highChartPoints?.x || 0,
      toolTipDeltaPercs,
      highChartPoints,
      toolTipColor,
      resultObj,
      isContentSupplier,
      isAverage,
      isTimeFormat,
      isDelta,
      isPercentage,
      isToFixed
    );
  }
  return '';
};
export const pieToolTipFormatter = (it: any, colorObjArray: any[]) => {
  const data = colorObjArray.find((datum: any) => (datum?.title || datum?.label) === it?.point?.name);
  const classVarient = data?.class;
  return `<div class="tooltip-tile-wrp">
      <div class="follwers-wrp">
        <div class='chart-tlp-lbl'>
          <span class=${classVarient || ''}>${it?.point?.name || ''}:</span>
          <span class='y-value fontincrese-txt'>${getRoundNumberTo2Decimal(it?.percentage)}%</span>
        </div>
      </div>
    </div>`;
};
