import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { RIPPLE_COLOR, CONNECTION_TABS, IConUpgradePackageOption, APP_TITLE } from 'utils/constants';
import { couponIsExpired, getHumanReadableTime, getParsedDate, getCurrentDate } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { config } from 'config';
import { IStore } from 'types';

export const UpgradePackageSection = () => {
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, isAdminScreenEnabled } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });

  const signupCouponDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account?.signup_coupon);
  const isAdminLogin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);

  const [activeOption, setActiveOption] = useState('');

  const handleURLPathname = (route: string) => {
    if (isAdminLogin && isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  return (
    <div className="mainContent animate__animated animate__fadeIn connec-allrallio-profile-wrp selectOption">
      <div className="settings-main-wrp">
        <div className="left-section-wrp">
          <div className="right-section-wrp">
            <div className="selct-header">
              <div className="selectCbox">
                <ImageValidation isImgValid defaultImg="checkbox-c" customName="Please select an option" />
              </div>
              <span>Please select an option</span>
            </div>
          </div>

          <div className={`frameModule loc-settings-wrp stg-rp`}>
            <div
              className={`settings-rallio-profile-new-section${activeOption === IConUpgradePackageOption.RALLIO_PACKAGE ? ` active` : ''}`}
              onClick={() => setActiveOption(IConUpgradePackageOption.RALLIO_PACKAGE)}
            >
              <a href="https://www.rallio.com/monthly-register/#registerModalComplete" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="content-full-wrp settins-content--wrp">
                  <div className="content-left-section">
                    <h3>Option 1:</h3>
                  </div>
                  <div className="oprMain">
                    <h3>Personalize and Engage</h3>
                    <p>Build your Following AND encourage engagement with your brand.</p>
                    <div className="optionView">
                      <div className="op-item op-left">
                        <div className="op-Charts">
                          {' '}
                          <img src={`${config.cdnImgUrl}franchiseData.svg`} alt="Franchisee Data" />
                        </div>
                      </div>
                      <div className="op-item op-right special-setup">
                        {signupCouponDetails?.expires_at && !couponIsExpired(signupCouponDetails?.expires_at) ? (
                          <>
                            <h2>
                              <span>Special set-up pricing</span>
                            </h2>
                            <div className="cpc-item cpc-top">
                              <h3>
                                $325<span>/month</span>
                              </h3>
                              <span className="pr-sub">with coupon code</span>
                            </div>

                            <div className="coupon-code">
                              <h3>[{signupCouponDetails?.coupon_code}]</h3>
                              <span className="pr-sub">Coupon expires in {getHumanReadableTime(getCurrentDate(), getParsedDate(signupCouponDetails?.expires_at))}</span>
                            </div>

                            <div className="cpc-item cpc-base">
                              <h3>
                                $347<span>/month</span>
                              </h3>
                              <span className="pr-sub">Standard pricing</span>
                            </div>
                          </>
                        ) : (
                          <div className="cpc-item">
                            <h3>
                              $347<span>/month</span>
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="oprm-Base">
                      <div className="specs">
                        <h3>Let the experts at {APP_TITLE} handle it. We will:</h3>
                        <ul>
                          <li>
                            <span>Includes everything in the Manage and Grow option</span>
                          </li>
                          <li>
                            <span>Create 3 custom, local social media posts per week</span>
                          </li>
                          <li>
                            <span>Assign you a dedicated account strategist</span>
                          </li>
                          <li>
                            <span>Perform quaterly strategy calls</span>
                          </li>
                        </ul>
                      </div>
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                        <button className="ac-btn ac-primary size-sm" onClick={() => setActiveOption(IConUpgradePackageOption.RALLIO_PACKAGE)}>
                          Select this option
                        </button>
                      </CustomRippleButton>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div
              className={`settings-rallio-profile-new-section doitWrap${activeOption === IConUpgradePackageOption.DO_IT_YOURSELF ? ` active` : ''}`}
              onClick={() => setActiveOption(IConUpgradePackageOption.DO_IT_YOURSELF)}
            >
              <div className="content-full-wrp settins-content--wrp">
                <div className="content-left-section">
                  <h3>Option 2:</h3>
                </div>
                <div className="oprMain">
                  <h3>Do-It-Yourself</h3>
                  <p>Option 1 looks great but I’ve got the time and expertise to run my social media, reply to reviews and put together reports myself!</p>
                  <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                    <button className="ac-btn ac-primary size-sm" onClick={() => setActiveOption(IConUpgradePackageOption.DO_IT_YOURSELF)}>
                      Select this option
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            </div>
          </div>

          <div className="stg-item form-configure">
            <div className="modal-btn-grp-wraps csp-action ap-actions cn-social-pr">
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className="ac-btn ac-primary-white ac-outline size-sm"
                  onClick={() => {
                    if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.RALLIO_PROFILE}`);
                  }}
                >
                  Back
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className="ac-btn ac-primary size-sm"
                  onClick={() => {
                    if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.SOCIAL_PROFILES}`);
                  }}
                >
                  Next
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
