import { config } from 'config';
import { commonWidgetTypes } from 'types';
import { TOOLTIP_PLACEMENT } from 'utils/constants';
import { CustomTooltip } from 'widgets/Tooltip';

export const ImageLocationDetail = (props: commonWidgetTypes.IImageLocationProps) => (
  <div className={props.customClassname}>
    <span className="lvt-txt">{props.locationName}</span>
    {props.isTooltip ? (
      <CustomTooltip
        customPlacement={TOOLTIP_PLACEMENT.TOP}
        customTooltipText={props.customTooltipText ? props.customTooltipText : ''}
        customInput={() => (
          <img
            src={!props.isImageExists ? `${config.cdnImgUrl}${props.customImgName}` : props.customImgName ? props.customImgName : `${config.cdnImgUrl}${props.customImgName}`}
            className={props.className ? props.className : ''}
            alt={props.customName}
          />
        )}
      />
    ) : (
      <img
        src={!props.isImageExists ? `${config.cdnImgUrl}${props.customImgName}` : props.customImgName ? props.customImgName : `${config.cdnImgUrl}${props.customImgName}`}
        className={props.className ? props.className : ''}
        alt={props.customName}
      />
    )}
    <span className="lvt-txt">@ {props.createdAt}</span>
  </div>
);
