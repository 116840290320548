import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Spinner } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { IStore, /* settingsRallioProfileReducerTypes, */ settingsRallioProfileContainerTypes, directoryListingsContainerTypes } from 'types';
import {
  RALLIO_PROFILE_TAB_FILEDS,
  CONNECTION_TABS,
  US_COUNTRY_NAME,
  US_TIME_ZONE,
  TOOLTIP_PLACEMENT,
  USER_OWNERSHIP,
  RALLIO_PROFILE_INITIAL_VALUE,
  ALERT_BOX_TYPES,
  RALLIO_PROFILE_HEADER_TOOLTIP,
  RALLIO_PROFILE_OBJ_TYPE,
  COUNTRIES_CODE_DROPDOWN_OBJ,
  RIPPLE_COLOR,
  APP_TITLE,
  VALID_APP_URL_TITLES,
  SETTINGS_RALLIO_PROFILE_CHECKFILEDS,
  MODAL_BOX_TYPES,
  RALLIO_PROFILE_PERSONALIZED_FIELD_LABELS,
  RALLIO_PROFIILE_CUSTOM_FIELDS
} from 'utils/constants';
import {
  settingsUpdateRallioProfile,
  accountUpdateProfileRequest,
  settingsResetprofileStatus,
  accountAddProfileRequest,
  accountProfileReset,
  rallioProfileResetAll,
  socialProfileResetAll,
  setFranchisorIds,
  settingsValidatePostPersonalizationResponse,
  settingsValidatePostPersonalizationRequest
} from 'actions';
import {
  formatRallioProfileRequestPayload,
  rallioProfileValidation,
  addAccountProfileReqPayload,
  compareTwoObjects,
  getIndividualSaveStatus,
  getUpdatedValues,
  getCountriesCodeForDropdown,
  getTimeZonesForDropdown,
  /* formatPhoneNumber, */
  touchFieldObjectCreator,
  updateAccountSwitcherName
} from 'utils/helpers';
import { alertBoxCall, ModalPopup } from 'components';
import { CustomTooltip } from 'widgets/Tooltip';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ProfileInfoSection } from './ProfileInfoSection';
import { AddressSection } from './AddressSection';
import { URLsSection } from './URLsSection';
import { CustomFieldsSection } from './CustomFieldsSection';
import { useAccountSwitcherData, useParamsDeconstructor, useSiteRestriction, useSSOIdentifiersForAccount } from 'utils/hooks';
import { CTAFieldSection } from './CTAFieldSection';
import { AgencyInfoSection } from './AgencyInfoSection';
import { TimezoneInfoSection } from './TimezoneInfoSection';
import { BusinessInfoSection } from './BusinessInfoSection';
import { PreferredLanguageSection } from './PreferredLanguageSection';

const SettingsRallioProfileDetails = (_1?: any, ref?: any) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams, isAdminScreenEnabled } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });
  const { aiRestriction } = useSiteRestriction();

  const rallioProfile = useSelector((state: IStore) => state.rallioProfile.rallioProfile);
  const isRallioProfileUpdated = useSelector((state: IStore) => state.rallioProfile.isRallioProfileUpdated);
  const responseMsg = useSelector((state: IStore) => state.rallioProfile.responseMsg);
  const countriesCode = useSelector((state: IStore) => state.rallioProfile.countriesCode);
  const timeZones = useSelector((state: IStore) => state.rallioProfile.timeZones);
  const updateProfileRespData = useSelector((state: IStore) => state.rallioProfile.updateProfileRespData);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const accountData = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const isProfileFetching = useSelector((state: IStore) => state.accounts.isProfileFetching);
  const isNewProfileFetching = useSelector((state: IStore) => state.accounts.isNewProfileFetching);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const selectedFranchisorId = useSelector((state: IStore) => state.adminArea.selectedFranchisorId);
  const activateAccount = useSelector((state: IStore) => state.accounts.activateAccount);
  const isAccountUpdating = useSelector((state: IStore) => state.accounts.isAccountUpdating); // used to update rss feed
  const overriddenProfileEditLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_profile_edit_locked || false);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const topLevelFranchisorObj = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorObj);
  const franchisorGroups = useSelector((state: IStore) => state.accountSwitcher.franchisorGroups);
  const accounts = useSelector((state: IStore) => state.accountSwitcher.accounts);
  const franchisors = useSelector((state: IStore) => state.accountSwitcher.franchisors);
  const isSSO = useSelector((state: IStore) => state.login.isSSO);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed || false);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const postsNeedingUpdate = useSelector((state: IStore) => state.rallioProfile.postsNeedingUpdate);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);

  const [countriesData, setCountriesData] = useState<directoryListingsContainerTypes.ICountriesCodeObj>(COUNTRIES_CODE_DROPDOWN_OBJ);
  const [timeZonesData, setTimeZonesData] = useState<directoryListingsContainerTypes.ICountriesCodeObj>(COUNTRIES_CODE_DROPDOWN_OBJ);
  const [overwriteConfirmationModalOpen, setOverwriteConfirmationModalOpen] = useState(false);

  const countriesCodeList = getCountriesCodeForDropdown(countriesCode);
  const timeZonesList = getTimeZonesForDropdown(timeZones);

  const isAccountWizardScreen = optionalParams[0] === 'account_wizard';
  const isAgencyProfileSection = isSSO && isCSLocation && isAccountWizardScreen;
  const isPostsNeedingUpdate = Boolean(postsNeedingUpdate?.saved_posts_needing_update || postsNeedingUpdate?.scheduled_posts_needing_update);

  const { values, touched, setFieldValue, errors, handleBlur, handleSubmit, isValid, setTouched, validateField } = useFormik({
    initialValues: { ...rallioProfile },
    validationSchema: rallioProfileValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (val: settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
      dispatch(settingsUpdateRallioProfile({ ...rallioProfile, ...val }));
      const formattedProfileData = formatRallioProfileRequestPayload(val);
      const profileData = { ...accountData, ...formattedProfileData };
      dispatch(accountUpdateProfileRequest({ profileData, id, isUpdatePersonalizedFields: Boolean(isPostsNeedingUpdate && modifiedPersonalizedFields) }));
    }
  });

  const modifiedPersonalizedFields = useMemo(() => {
    const personalizedFields = [
      RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME,
      RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE,
      RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING,
      RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL,
      RALLIO_PROFILE_TAB_FILEDS.MANAGER,
      RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL,
      RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL,
      RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS
    ];
    return personalizedFields.reduce((acc: string[], curr: keyof settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
      if (curr === RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS) {
        Object.keys(rallioProfile.custom_fields).forEach((field) => {
          if (RALLIO_PROFIILE_CUSTOM_FIELDS.includes(field) && rallioProfile.custom_fields?.[field] !== values.custom_fields?.[field]) {
            acc.push(field);
          }
        });
      } else {
        if (rallioProfile?.[curr] !== values?.[curr]) {
          acc.push(curr);
        }
      }
      return acc;
    }, []);
  }, [values, rallioProfile]);

  useEffect(() => {
    validateField('name');
  }, [values.name]); // eslint-disable-line

  const showTranslateOption = useMemo(() => {
    if (isRallioai && isAISubscribed && !aiRestriction.button) {
      if (!isContentSupplierFranchisor) {
        return true;
      } else if (+accSubscriptionDetails?.franchisor_subscription_type?.id === 7) {
        return aiLimitation && +aiLimitation?.ai_caption_limit > 0 ? true : false;
      }
      return [3, 8].includes(+accSubscriptionDetails?.franchisor_subscription_type?.id) ? true : false;
    }
    return false;
  }, [isRallioai, isAISubscribed, aiRestriction.button, accSubscriptionDetails?.franchisor_subscription_type?.id, aiLimitation, isContentSupplierFranchisor]);

  const handleURLPathname = (route: string) => {
    if (isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const handleNext = () => {
    if (isValid) {
      handleAddAccountProfile();
    } else {
      const errorFields = Object.keys(errors).reduce((acc, curr) => {
        acc = {
          ...acc,
          [curr]: true
        };
        return acc;
      }, {});
      setTouched(errorFields, true);
    }
  };

  useImperativeHandle(ref, () => ({
    onSelectNextTab: () => {
      handleNext();
    }
  }));

  const hideLogo = useMemo(() => {
    if (id && userOwnership && menuAccess) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && restrictions?.hub_user) {
        return restrictions.hub_user.includes('logo') || restrictions.hub_user.includes('profile');
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('logo') || restrictions.location_user.includes('profile');
      }
    }
    return false;
  }, [id, userOwnership, menuAccess]);

  const resetFormValues = () => {
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.NAME, rallioProfile.name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ADDRESS, rallioProfile.street || '');
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STORE_CODE, rallioProfile.short_name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CITY, rallioProfile.city);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER, rallioProfile?.phone || ``);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE, rallioProfile.zip || '');
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME, rallioProfile.business_name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE, rallioProfile.phone_number);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING, rallioProfile.mailing_address);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL, rallioProfile.business_email);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, rallioProfile.url);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE, rallioProfile.state || '');
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL, rallioProfile.job_page_url);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.MANAGER, rallioProfile.person_to_reach);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, rallioProfile.country_code);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, rallioProfile.time_zone);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE, rallioProfile.preferred_language);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS, rallioProfile.custom_fields);

    resetFieldTouched();

    setCountriesData(
      countriesCodeList.find((item) => item.id === rallioProfile.country_code) ||
        countriesCodeList.find((item) => item.label === US_COUNTRY_NAME) || {
          id: countriesCode[0]?.code || '',
          value: countriesCode[0]?.name || '',
          label: countriesCode[0]?.name || ''
        }
    );
    setTimeZonesData(
      timeZonesList.find((item) => item.value === rallioProfile.time_zone) ||
        timeZonesList.find((it) => it.value === US_TIME_ZONE) || {
          id: timeZones[0]?.value || '',
          value: timeZones[0]?.value || '',
          label: timeZones[0]?.label || ''
        }
    );
  };

  /** Reinitialize Rallio Profile Form */
  useEffect(() => {
    resetFormValues();
  }, [rallioProfile]); // eslint-disable-line

  /** Set country and time zone for adding new profile */
  useEffect(() => {
    if (optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, countriesCode?.find((datum) => datum.code === rallioProfile.country_code)?.code || '');
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, timeZones?.find((datum) => datum.value === rallioProfile.time_zone)?.value || '');
    }
    setCountriesData(
      countriesCodeList.find((item) => item.id === rallioProfile.country_code) ||
        countriesCodeList.find((item) => item.label === US_COUNTRY_NAME) || {
          id: countriesCode[0]?.code || '',
          value: countriesCode[0]?.name || '',
          label: countriesCode[0]?.name || ''
        }
    );
    setTimeZonesData(
      timeZonesList.find((item) => item.value === rallioProfile.time_zone) ||
        timeZonesList.find((it) => it.value === US_TIME_ZONE) || {
          id: timeZones[0]?.value || '',
          value: timeZones[0]?.value || '',
          label: timeZones[0]?.label || ''
        }
    );
  }, [optionalParams.length, countriesCode.length, timeZones.length, rallioProfile.country_code, rallioProfile.time_zone]); // eslint-disable-line

  useEffect(() => {
    if (optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, countriesData.id);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, timeZonesData.value);
    }
  }, [countriesData, timeZonesData]); // eslint-disable-line

  /** Show Alertbox after Saving Updated profile details */
  useEffect(() => {
    if (currentRoute === 'settings/rallio_profile') {
      if (!isRallioProfileUpdated && responseMsg) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, responseMsg);
        dispatch(settingsResetprofileStatus());
      } else if (isRallioProfileUpdated && responseMsg) {
        dispatch(settingsResetprofileStatus());
        if (updateProfileRespData && updateProfileRespData.account) {
          updateAccountSwitcherName({ updatedName: values.name, id, userOwnership, currentAccountOrFranchisor, topLevelFranchisorObj, franchisorGroups, franchisors, accounts, dispatch });
        }
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, responseMsg);
      }
    } else {
      dispatch(settingsResetprofileStatus());
    }
  }, [currentRoute, isRallioProfileUpdated, dispatch]); // eslint-disable-line

  /** Handle and set Values when changes happen in forms */
  const handleChange = (field: string, value: string | { [key: string]: string }) => {
    const fieldValue = /* field === RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER ? formatPhoneNumber(value,) : */ value;
    setFieldValue(field, fieldValue);
  };

  /** Set classnames for form field */
  const getClassNames = (valueItem: keyof settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] && values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const getInputBoxClassName = (valueItem: keyof settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const getInputTitleClassName = (valueItem: keyof settingsRallioProfileContainerTypes.IRPTabFeildValue) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'fltlabels-error-text' : ''}`;
    return className;
  };

  const handleNavigateToAccountPostsPage = (accountId: number, signupCouponCode?: null | string) => {
    if (accountId) {
      if (signupCouponCode) {
        handleURLPathname(`/${currentRoute}/account_wizard/${accountId.toString()}/${CONNECTION_TABS.UPGRADE_PACKAGE}`);
      } else {
        handleURLPathname(`/${currentRoute}/account_wizard/${accountId.toString()}/${CONNECTION_TABS.SOCIAL_PROFILES}`);
      }
    }
  };

  /** Add new account profile */
  const handleAddAccountProfile = () => {
    if (id && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      if (rallioProfile.name && accountData?.id) {
        delete values[RALLIO_PROFILE_TAB_FILEDS.REQUIRED_FIELD];
        /* const profileData = {
          ...accountData,
          ...values,
          custom_fields: {
            custom_field_1: values.custom_fields?.custom_field_1 || '',
            custom_field_2: values.custom_fields?.custom_field_2 || '',
            custom_field_3: values.custom_fields?.custom_field_3 || '',
            custom_field_4: values.custom_fields?.custom_field_4 || '',
            custom_field_5: values.custom_fields?.custom_field_5 || '',
            custom_field_6: values.custom_fields?.custom_field_6 || '',
            custom_field_7: values.custom_fields?.custom_field_7 || ''
          }
        }; */
        const formattedProfileData = formatRallioProfileRequestPayload({ ...accountData, ...values });
        const profileData = { ...accountData, ...formattedProfileData };
        dispatch(
          accountUpdateProfileRequest({
            profileData,
            id: accountData.id,
            type: 'edit_location_rallio',
            onNavigate: (accountId, signupCouponCode) => handleNavigateToAccountPostsPage(accountId, signupCouponCode)
          })
        );
      } else {
        dispatch(settingsUpdateRallioProfile({ ...values }));
        dispatch(
          accountAddProfileRequest({ reqPayload: addAccountProfileReqPayload(values, id), onNavigate: (accountId, signupCouponCode) => handleNavigateToAccountPostsPage(accountId, signupCouponCode) })
        );
      }
    } else {
      if (rallioProfile.name && accountData?.id) {
        delete values[RALLIO_PROFILE_TAB_FILEDS.REQUIRED_FIELD];
        /* const profileData = {
          ...accountData,
          ...values,
          custom_fields: {
            custom_field_1: values.custom_fields?.custom_field_1 || '',
            custom_field_2: values.custom_fields?.custom_field_2 || '',
            custom_field_3: values.custom_fields?.custom_field_3 || '',
            custom_field_4: values.custom_fields?.custom_field_4 || '',
            custom_field_5: values.custom_fields?.custom_field_5 || '',
            custom_field_6: values.custom_fields?.custom_field_6 || '',
            custom_field_7: values.custom_fields?.custom_field_7 || ''
          }
        }; */
        const formattedProfileData = formatRallioProfileRequestPayload({ ...accountData, ...values });
        const profileData = { ...accountData, ...formattedProfileData };
        dispatch(
          accountUpdateProfileRequest({
            profileData,
            id: accountData.id,
            type: 'edit_location_rallio',
            onNavigate: (accountId, signupCouponCode) => handleNavigateToAccountPostsPage(accountId, signupCouponCode)
          })
        );
      } else if (selectedFranchisorId) {
        dispatch(
          accountAddProfileRequest({
            reqPayload: addAccountProfileReqPayload(values, selectedFranchisorId),
            onNavigate: (accountId, signupCouponCode) => handleNavigateToAccountPostsPage(accountId, signupCouponCode)
          })
        );
      }
      dispatch(settingsUpdateRallioProfile({ ...values }));
    }
  };

  /** Reset the rallio profile redux state */
  const handlCancelAccountProfile = () => {
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.NAME, RALLIO_PROFILE_INITIAL_VALUE.name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ADDRESS, RALLIO_PROFILE_INITIAL_VALUE.street);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STORE_CODE, RALLIO_PROFILE_INITIAL_VALUE.short_name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CITY, RALLIO_PROFILE_INITIAL_VALUE.city);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER, RALLIO_PROFILE_INITIAL_VALUE.phone);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE, RALLIO_PROFILE_INITIAL_VALUE.zip);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME, RALLIO_PROFILE_INITIAL_VALUE.business_name);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE, RALLIO_PROFILE_INITIAL_VALUE.phone_number);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING, RALLIO_PROFILE_INITIAL_VALUE.mailing_address);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL, RALLIO_PROFILE_INITIAL_VALUE.business_email);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, RALLIO_PROFILE_INITIAL_VALUE.url);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE, RALLIO_PROFILE_INITIAL_VALUE.state);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS, RALLIO_PROFILE_INITIAL_VALUE.custom_fields);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL, RALLIO_PROFILE_INITIAL_VALUE.job_page_url);
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, countriesCode?.find((datum) => datum.name === US_COUNTRY_NAME)?.code || '');
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, timeZones?.find((datum) => datum.value === US_TIME_ZONE)?.value || '');
    setFieldValue(RALLIO_PROFILE_TAB_FILEDS.MANAGER, RALLIO_PROFILE_INITIAL_VALUE.person_to_reach);

    resetFieldTouched();

    dispatch(rallioProfileResetAll());
    if (isAdminScreenEnabled) {
      if (selectedFranchisorId) dispatch(setFranchisorIds(null));
      dispatch(accountProfileReset());
      dispatch(socialProfileResetAll());
    }
    handleURLPathname(`/${currentRoute}`);
  };

  const resetFieldTouched = () => {
    setTouched({
      // ...SETTINGS_RALLIO_PROFILE_CHECKFILEDS[RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO],
      ...SETTINGS_RALLIO_PROFILE_CHECKFILEDS[RALLIO_PROFILE_OBJ_TYPE.ADDRESS],
      // ...SETTINGS_RALLIO_PROFILE_CHECKFILEDS[RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO],
      ...SETTINGS_RALLIO_PROFILE_CHECKFILEDS[RALLIO_PROFILE_OBJ_TYPE.URL],
      ...SETTINGS_RALLIO_PROFILE_CHECKFILEDS[RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS]
    });
  };

  const handleIndividualSave = (objType: string) => {
    handleFieldTouched(objType);
    if (!getIndividualSaveStatus(objType, errors, rallioProfile.required_field)) {
      const val = getUpdatedValues(objType, values);
      if (!([RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS, RALLIO_PROFILE_OBJ_TYPE.URL].includes(objType) && isPostsNeedingUpdate && modifiedPersonalizedFields.length)) {
        dispatch(settingsUpdateRallioProfile({ ...rallioProfile, ...val }));
      }
      const formattedProfileData = formatRallioProfileRequestPayload({ ...rallioProfile, ...val });
      const profileData = { ...accountData, ...formattedProfileData };
      if ([RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS, RALLIO_PROFILE_OBJ_TYPE.URL].includes(objType) && isPostsNeedingUpdate && modifiedPersonalizedFields.length) {
        setOverwriteConfirmationModalOpen(true);
      } else {
        dispatch(accountUpdateProfileRequest({ profileData, id }));
      }
    }
  };

  const handleFieldTouched = (objType: string) => {
    const rpCheckedFields = touchFieldObjectCreator(SETTINGS_RALLIO_PROFILE_CHECKFILEDS, true);
    /*  if (objType === RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO]);
    } else */ if (objType === RALLIO_PROFILE_OBJ_TYPE.ADDRESS) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.ADDRESS]);
    } /* else if (objType === RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO]);
    } */ else if (objType === RALLIO_PROFILE_OBJ_TYPE.URL) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.URL]);
    } else if (objType === RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS]);
    } else if (objType === RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES) {
      setTouched(rpCheckedFields[RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES]);
    }
  };

  const handleIndividualCancel = (objType: string) => {
    setTouched({
      ...touched,
      ...(SETTINGS_RALLIO_PROFILE_CHECKFILEDS[objType] || {})
    });

    /* if (objType === RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.NAME, rallioProfile.name);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STORE_CODE, rallioProfile.short_name);
    } else */ if (objType === RALLIO_PROFILE_OBJ_TYPE.ADDRESS) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.NAME, rallioProfile.name);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STORE_CODE, rallioProfile.short_name);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ADDRESS, rallioProfile.street || '');
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CITY, rallioProfile.city);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE, rallioProfile.state || '');
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE, rallioProfile.zip || '');
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER, rallioProfile.phone);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, countriesCode?.find((datum) => datum.code === rallioProfile.country_code)?.code || '');
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, timeZones?.find((datum) => datum.value === rallioProfile.time_zone)?.value || '');

      setCountriesData(
        countriesCodeList.find((item) => item.id === rallioProfile.country_code) ||
          countriesCodeList.find((item) => item.label === US_COUNTRY_NAME) || {
            id: countriesCode[0]?.code || '',
            value: countriesCode[0]?.name || '',
            label: countriesCode[0]?.name || ''
          }
      );
      setTimeZonesData(
        timeZonesList.find((item) => item.value === rallioProfile.time_zone) ||
          timeZonesList.find((it) => it.value === US_TIME_ZONE) || {
            id: timeZones[0]?.value || '',
            value: timeZones[0]?.value || '',
            label: timeZones[0]?.label || ''
          }
      );
    } /* else if (objType === RALLIO_PROFILE_OBJ_TYPE.BUSINESS_INFO) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME, rallioProfile.business_name);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE, rallioProfile.phone_number);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING, rallioProfile.mailing_address);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL, rallioProfile.business_email);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.MANAGER, rallioProfile.person_to_reach);
    } */ else if (objType === RALLIO_PROFILE_OBJ_TYPE.URL) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_NAME, rallioProfile.business_name);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_PHONE, rallioProfile.phone_number);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_MAILING, rallioProfile.mailing_address);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.BUSINESS_EMAIL, rallioProfile.business_email);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.MANAGER, rallioProfile.person_to_reach);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.JOB_PAGE_URL, rallioProfile.job_page_url);
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, rallioProfile.url);
    } else if (objType === RALLIO_PROFILE_OBJ_TYPE.PREFERRED_LANGUAGES) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.PREFERRED_LANGUAGE, rallioProfile.preferred_language);
    } else if (objType === RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS) {
      setFieldValue(RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS, rallioProfile.custom_fields);
    }
  };

  useEffect(() => {
    if (modifiedPersonalizedFields.length) {
      dispatch(settingsValidatePostPersonalizationRequest(id));
    } else {
      dispatch(settingsValidatePostPersonalizationResponse({ saved_posts_needing_update: 0, scheduled_posts_needing_update: 0 }));
    }
  }, [id, modifiedPersonalizedFields?.length, dispatch]);

  return (
    <div
      className={
        optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE
          ? `mainContent animate__animated animate__fadeIn  ralprofile-hl settings-profile__main connec-allrallio-profile-wrp lpx vpy-20`
          : `mainContent animate__animated animate__fadeIn ralprofile-hl settings-profile__main lpx vpy-20`
      }
    >
      <div className={`settings-main-wrp`}>
        <div className="left-section-wrp">
          {optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE && !isCSLocation ? (
            <div className="right-section-wrp">
              <div className="r-profile-details-notify">
                <div className="required-content-first notify-top">
                  <div className="required">
                    <ImageValidation defaultImg="required" customName="required" />
                  </div>
                  <span>These fields are required</span>
                </div>
                {/* {accountData?.id && (
                  <div className="required-content-first">
                    <div className="localized">
                      <ImageValidation defaultImg="localized" customName="localized" />
                    </div>
                    <span>
                      Do you want your brand’s HQ to include your location’s information, like your phone number or your hashtag, in your social media posts? Fields marked with this symbol allows your
                      global content to be localized. Be sure it’s accurate!
                    </span>
                  </div>
                )} */}
              </div>
            </div>
          ) : (
            !isCSLocation && (
              <div className="location-peer-wrp">
                <div className="location-img g-15">
                  <div className="trans-icon">
                    <ImageValidation defaultImg="rallio-profile" customName="trans-icon" />
                  </div>
                  <CustomTooltip
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={RALLIO_PROFILE_HEADER_TOOLTIP}
                    customClassname={'custom-tooltip-long-text'}
                    customInput={() => <div className="cursor-help">{`${hideLogo || VALID_APP_URL_TITLES.includes(APP_TITLE) ? '' : 'Rallio '}Profile`}</div>}
                  />
                </div>
              </div>
            )
          )}
          <div
            className={`frameModule loc-settings-wrp stg-rp
          ${!isAdmin && !isFranchisor && overriddenProfileEditLocked ? 'events-none pointer-events-none' : ''}`}
          >
            {isAgencyProfileSection ? (
              //  Agency Info section
              <AgencyInfoSection
                hideLogo={hideLogo}
                selectedFranchisorId={selectedFranchisorId}
                accountId={accountData?.id}
                values={values}
                actualValues={rallioProfile}
                handleBlur={handleBlur}
                handleChange={handleChange}
                getInputTitleClassName={getInputTitleClassName}
                getInputBoxClassName={getInputBoxClassName}
                getClassNames={getClassNames}
                handleIndividualCancel={handleIndividualCancel}
                handleIndividualSave={handleIndividualSave}
              />
            ) : (
              //  Profile Info section
              <ProfileInfoSection
                hideLogo={hideLogo}
                selectedFranchisorId={selectedFranchisorId}
                accountId={accountData?.id}
                values={values}
                actualValues={rallioProfile}
                handleBlur={handleBlur}
                handleChange={handleChange}
                getInputTitleClassName={getInputTitleClassName}
                getInputBoxClassName={getInputBoxClassName}
                getClassNames={getClassNames}
                handleIndividualCancel={handleIndividualCancel}
                handleIndividualSave={handleIndividualSave}
              />
            )}

            {/* Address section */}
            <AddressSection
              countriesCodeList={countriesCodeList}
              countriesData={countriesData}
              setCountriesData={setCountriesData}
              timeZonesList={timeZonesList}
              timeZonesData={timeZonesData}
              setTimeZonesData={setTimeZonesData}
              accountId={accountData?.id}
              values={values}
              actualValues={rallioProfile}
              handleBlur={handleBlur}
              handleChange={handleChange}
              getInputTitleClassName={getInputTitleClassName}
              getInputBoxClassName={getInputBoxClassName}
              getClassNames={getClassNames}
              handleIndividualCancel={handleIndividualCancel}
              handleIndividualSave={handleIndividualSave}
              isAgencyProfileSection={isAgencyProfileSection}
            />

            {isAgencyProfileSection && (
              <TimezoneInfoSection
                timeZonesList={timeZonesList}
                timeZonesData={timeZonesData}
                setTimeZonesData={setTimeZonesData}
                accountId={accountData?.id}
                values={values}
                actualValues={rallioProfile}
                handleBlur={handleBlur}
                handleChange={handleChange}
                getInputTitleClassName={getInputTitleClassName}
                getInputBoxClassName={getInputBoxClassName}
                getClassNames={getClassNames}
                handleIndividualCancel={handleIndividualCancel}
                handleIndividualSave={handleIndividualSave}
              />
            )}

            {showTranslateOption && (
              <PreferredLanguageSection
                values={values}
                actualValues={rallioProfile}
                handleChange={handleChange}
                handleIndividualCancel={handleIndividualCancel}
                handleIndividualSave={handleIndividualSave}
              />
            )}

            {/* Business Info section */}
            <BusinessInfoSection
              accountId={accountData?.id}
              values={values}
              actualValues={rallioProfile}
              handleBlur={handleBlur}
              handleChange={handleChange}
              getInputTitleClassName={getInputTitleClassName}
              getInputBoxClassName={getInputBoxClassName}
              getClassNames={getClassNames}
              handleIndividualCancel={handleIndividualCancel}
              handleIndividualSave={handleIndividualSave}
            />

            {/* URLs section */}
            <URLsSection
              accountId={accountData?.id}
              values={values}
              actualValues={rallioProfile}
              handleBlur={handleBlur}
              handleChange={handleChange}
              getInputTitleClassName={getInputTitleClassName}
              getInputBoxClassName={getInputBoxClassName}
              getClassNames={getClassNames}
              handleIndividualCancel={handleIndividualCancel}
              handleIndividualSave={handleIndividualSave}
            />

            {isAgencyProfileSection ? null : (
              <>
                {/* Custom Fields section */}
                <CustomFieldsSection
                  accountId={accountData?.id}
                  values={values}
                  actualValues={rallioProfile}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  getInputTitleClassName={getInputTitleClassName}
                  getInputBoxClassName={getInputBoxClassName}
                  getClassNames={getClassNames}
                  handleIndividualCancel={handleIndividualCancel}
                  handleIndividualSave={handleIndividualSave}
                />

                <CTAFieldSection
                  getInputBoxClassName={getInputBoxClassName}
                  getClassNames={getClassNames}
                  handleIndividualCancel={handleIndividualCancel}
                  handleIndividualSave={handleIndividualSave}
                  handleChange={handleChange}
                  values={values}
                  actualValues={rallioProfile}
                />
              </>
            )}

            {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && (
              <div className="stg-item form-configure r-mt4">
                <div className="fc-actions">
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button
                      className={`ac-btn ac-secondary-white border-0 ac-outline size-sm ${isProfileFetching || compareTwoObjects(rallioProfile, values) ? 'disabled' : ''}`}
                      onClick={() => resetFormValues()}
                    >
                      Cancel
                    </button>
                  </CustomRippleButton>
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                    <button
                      className={`ac-btn ac-primary size-sm ${isProfileFetching || compareTwoObjects(rallioProfile, values) ? 'disabled' : ''}`}
                      type="submit"
                      onClick={(event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | any) => {
                        if (isPostsNeedingUpdate && modifiedPersonalizedFields.length) {
                          setOverwriteConfirmationModalOpen(true);
                        } else {
                          handleSubmit(event);
                        }
                      }}
                    >
                      Save All
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            )}
          </div>
          {optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE && (
            <div className="stg-item form-configure">
              <div className="modal-btn-grp-wraps csp-action ap-actions">
                {activateAccount ? null : (
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" disabled={isNewProfileFetching || isAccountUpdating} onClick={() => handlCancelAccountProfile()}>
                      Cancel
                    </button>
                  </CustomRippleButton>
                )}
                {(isSSO && isCSLocation) ||
                  (isContentSupplierFranchisor && (
                    <CustomRippleButton rippleClass="ripple-unset ac-primary-box r-ml2" custColor={RIPPLE_COLOR.whiteGrey}>
                      <button
                        className="ac-btn ac-primary-white ac-outline size-sm"
                        onClick={() => handleURLPathname(`/${currentRoute}/account_wizard/${accountData?.id}/${CONNECTION_TABS.VIDEO_INTRODUCTION}`)}
                      >
                        Back
                      </button>
                    </CustomRippleButton>
                  ))}
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary size-xs`}
                    disabled={isNewProfileFetching || isAccountUpdating}
                    type="submit"
                    onClick={() => {
                      if (isPostsNeedingUpdate && modifiedPersonalizedFields.length) {
                        setOverwriteConfirmationModalOpen(true);
                      } else {
                        handleNext();
                      }
                    }}
                  >
                    {isNewProfileFetching || isAccountUpdating ? <Spinner animation="border" variant="light" /> : 'Next'}
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          )}

          <ModalPopup
            isModalShow={overwriteConfirmationModalOpen}
            modalHeaderType={MODAL_BOX_TYPES.INFO}
            containerClassName={'modal-confirmation confirm-delete overwritePosts'}
            modalBody={() => (
              // <div>
              <div className="modal-message-wraps">
                <h2 className="modHead">Overwrite your Posts?</h2>
                <div>
                  <p>You've updated your personalization fields, which are used to customize syndicated posts.</p>
                  <div className="modified-fields-sec">
                    {modifiedPersonalizedFields.map((field, index) => {
                      return field.includes('custom_field') ? (
                        <p key={`personalized-fields-${index}`}>
                          <strong>{RALLIO_PROFILE_PERSONALIZED_FIELD_LABELS[field.toUpperCase()]}:</strong> &nbsp;
                          {`"${(rallioProfile?.custom_fields?.[field] as any) || ' '}"` || `" "`} &nbsp; to &nbsp;
                          {`"${values?.custom_fields?.[field as keyof settingsRallioProfileContainerTypes.IRPTabFeildValue] as any}"` || `" "`}
                        </p>
                      ) : (
                        <p key={`personalized-fields-${index}`}>
                          <strong>{RALLIO_PROFILE_PERSONALIZED_FIELD_LABELS[field.toUpperCase()]}:</strong> &nbsp;
                          {`"${(rallioProfile?.[field] as any) || ' '}"` || `" "`} &nbsp; to &nbsp;
                          {`"${values?.[field as keyof settingsRallioProfileContainerTypes.IRPTabFeildValue] as any}"` || `" "`}
                        </p>
                      );
                    })}
                  </div>
                  <p>
                    Saving these changes will update both scheduled posts and those in your post library that use the updated field. However, this action will overwrite any previous edits you've made
                    to these posts. Do you still want to proceed with saving these changes?
                  </p>
                </div>
              </div>
              // </div>
            )}
            modalCloseButton
            handleModalClose={() => {
              setOverwriteConfirmationModalOpen(false);
            }}
            isModalFooterShow
            modalFooter={() => (
              <div className="modal-btn-grp-wraps">
                <button
                  className="modal-btn-action-itm modal-cancel-btn"
                  onClick={() => {
                    resetFormValues();
                    setOverwriteConfirmationModalOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="modal-btn-action-itm modal-ok-btn"
                  onClick={() => {
                    if (optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE) {
                      handleNext();
                    } else {
                      handleSubmit();
                    }
                    setOverwriteConfirmationModalOpen(false);
                  }}
                >
                  Save
                </button>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(SettingsRallioProfileDetails);
