import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { campaignsApiTypes, commonWidgetTypes, postsReducerTypes } from 'types';

// CAMPAIGNS LIST
export const campaignsListRequest = (payload: campaignsApiTypes.ICampaignsDataReqParams) => createAction(actionTypes.CAMPAIGNS_LIST_REQUEST, payload);
export const campaignsListResponse = (data: campaignsApiTypes.ICampaignListData[]) => createAction(actionTypes.CAMPAIGNS_LIST_RESPONSE, data);
export const campaignsListFailure = (error: null | string) => createAction(actionTypes.CAMPAIGNS_LIST_FAILURE, error);

// CAMPAIGNS STATS DATA
export const campaignsStatsRequest = (payload: campaignsApiTypes.ICampaignsDataReqParams) => createAction(actionTypes.CAMPAIGNS_STATS_REQUEST, payload);
export const campaignsStatsResponse = (data: campaignsApiTypes.ICampaignStatsObj) => createAction(actionTypes.CAMPAIGNS_STATS_RESPONSE, data);
export const campaignsStatsFailure = (error: null | string) => createAction(actionTypes.CAMPAIGNS_STATS_FAILURE, error);

// CAMPAIGNS EVENT LIST
export const getCampaignsEventListRequest = (payload: campaignsApiTypes.ICampaignEventListReq) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_LIST_REQUEST, payload);
export const getCampaignsEventListResponse = (data: campaignsApiTypes.ICampaignEvent[]) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_LIST_RESPONSE, data);
export const getCampaignsEventListFailure = (error: null | string) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_LIST_FAILURE, error);

// GET CAMPAIGNS EVENT
export const getCampaignsEventRequest = (payload: campaignsApiTypes.ICampaignEventReq) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_REQUEST, payload);
export const getCampaignsEventResponse = (data: campaignsApiTypes.ICampaignEvent) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_RESPONSE, data);
export const getCampaignsEventFailure = (error: null | string) => createAction(actionTypes.GET_CAMPAIGNS_EVENT_FAILURE, error);

// CAMPAIGN CREATE OR UPDATE EVENT
export const createOrUpdateCampaignEventRequest = (payload: campaignsApiTypes.ICampaignEventReqPayload) => createAction(actionTypes.CREATE_OR_UPDATE_CAMPAIGN_EVENT_REQUEST, payload);
export const createOrUpdateCampaignEventResponse = (data: campaignsApiTypes.ICampaignEvent) => createAction(actionTypes.CREATE_OR_UPDATE_CAMPAIGN_EVENT_RESPONSE, data);
export const createOrUpdateCampaignEventFailure = (error: null | string) => createAction(actionTypes.CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAILURE, error);

// CAMPAIGN DELETE EVENT
export const campaignPostsDeleteEventRequest = (payload: campaignsApiTypes.ICampaignDeleteEventReq) => createAction(actionTypes.CAMPAIGN_POSTS_DELETE_EVENT_REQUEST, payload);
export const campaignPostsDeleteEventResponse = (isSuccessMsgHide?: boolean) => createAction(actionTypes.CAMPAIGN_POSTS_DELETE_EVENT_RESPONSE, isSuccessMsgHide);
export const campaignPostsDeleteEventFailure = (error: null | string) => createAction(actionTypes.CAMPAIGN_POSTS_DELETE_EVENT_FAILURE, error);

// STOP CAMPAIGN EVENT
export const stopCampaignEventRequest = (payload: campaignsApiTypes.ICampaignEventReq) => createAction(actionTypes.STOP_CAMPAIGN_EVENT_REQUEST, payload);
export const stopCampaignEventResponse = () => createAction(actionTypes.STOP_CAMPAIGN_EVENT_RESPONSE);
export const stopCampaignEventFailure = (error: null | string) => createAction(actionTypes.STOP_CAMPAIGN_EVENT_FAILURE, error);

// CREATE OR EDIT CAMPAIGN
export const createOrEditCampaignRequest = (payload: campaignsApiTypes.ICreateOrEditCampaignReqPayload) => createAction(actionTypes.CREATE_OR_EDIT_CAMPAIGN_REQUEST, payload);
export const createOrEditCampaignResponse = (data: null | string) => createAction(actionTypes.CREATE_OR_EDIT_CAMPAIGN_RESPONSE, data);
export const createOrEditCampaignFailure = (error: null | string) => createAction(actionTypes.CREATE_OR_EDIT_CAMPAIGN_FAILURE, error);

// GET CAMPAIGN DETAIL
export const getCampaignDetailRequest = (payload: campaignsApiTypes.ICampaignDetailReqPayload) => createAction(actionTypes.GET_CAMPAIGN_DETAIL_REQUEST, payload);
export const getCampaignDetailResponse = (data: campaignsApiTypes.ICampaignListData) => createAction(actionTypes.GET_CAMPAIGN_DETAIL_RESPONSE, data);
export const getCampaignDetailFailure = (error: null | string) => createAction(actionTypes.GET_CAMPAIGN_DETAIL_FAILURE, error);

// DELETE CAMPAIGN
export const deleteCampaignRequest = (payload: campaignsApiTypes.IDeleteCampaignReqPayload) => createAction(actionTypes.DELETE_CAMPAIGN_REQUEST, payload);
export const deleteCampaignResponse = () => createAction(actionTypes.DELETE_CAMPAIGN_RESPONSE);
export const deleteCampaignFailure = (error: null | string) => createAction(actionTypes.DELETE_CAMPAIGN_FAILURE, error);

// DEACTIVATE CAMPAIGN EVENT
export const deactivateCampaignEventRequest = (payload: number) => createAction(actionTypes.DEACTIVATE_CAMPAIGN_EVENT_REQUEST, payload);
export const deactivateCampaignEventResponse = () => createAction(actionTypes.DEACTIVATE_CAMPAIGN_EVENT_RESPONSE);
export const deactivateCampaignEventFailure = (error: null | string) => createAction(actionTypes.DEACTIVATE_CAMPAIGN_EVENT_FAILURE, error);

// CAMPAIGN SORT POSTS LIST
export const campaignSortPostsListRequest = (payload: campaignsApiTypes.ICampaignSortPostsReqPayload) => createAction(actionTypes.CAMPAIGN_SORT_POSTS_LIST_REQUEST, payload);
export const campaignSortPostsListResponse = (data: string) => createAction(actionTypes.CAMPAIGN_SORT_POSTS_LIST_RESPONSE, data);
export const campaignSortPostsListFailure = (error: null | string) => createAction(actionTypes.CAMPAIGN_SORT_POSTS_LIST_FAILURE, error);

// ADD POSTS TO CAMPAIGN
export const addPostsToCampaignRequest = (payload: campaignsApiTypes.IAddPostsToCampaignReq) => createAction(actionTypes.ADD_POSTS_TO_CAMPAIGN_REQUEST, payload);
export const addPostsToCampaignResponse = (data: null | string) => createAction(actionTypes.ADD_POSTS_TO_CAMPAIGN_RESPONSE, data);
export const addPostsToCampaignFailure = (error: null | string) => createAction(actionTypes.ADD_POSTS_TO_CAMPAIGN_FAILURE, error);

// CAMPAIGNS DELETE POSTS
export const campaignsDeletePostsRequest = (payload: campaignsApiTypes.ICampaignsDeletePostsReq) => createAction(actionTypes.CAMPAIGNS_DELETE_POSTS_REQUEST, payload);
export const campaignsDeletePostsResponse = (data: string) => createAction(actionTypes.CAMPAIGNS_DELETE_POSTS_RESPONSE, data);
export const campaignsDeletePostsFailure = (error: null | string) => createAction(actionTypes.CAMPAIGNS_DELETE_POSTS_FAILURE, error);

// CAMPAIGNS POST LIST
export const campaignsPostListRequest = (payload: campaignsApiTypes.ICampaignsPostListReq) => createAction(actionTypes.CAMPAIGNS_POST_LIST_REQUEST, payload);
export const campaignsPostListResponse = (data: postsReducerTypes.IPostListData) => createAction(actionTypes.CAMPAIGNS_POST_LIST_RESPONSE, data);
export const campaignsPostListFailure = (error: null | string) => createAction(actionTypes.CAMPAIGNS_POST_LIST_FAILURE, error);

// CAMPAIGNS SORT POST LIST
export const campaignsSortPostList = (data: commonWidgetTypes.IReactDNDSortData) => createAction(actionTypes.CAMPAIGNS_SORT_POST_LIST, data);

export const setMultiplePostsToCampaign = (data: number) => createAction(actionTypes.SET_MULTIPLE_POSTS_TO_CAMPAIGN, data);

export const campaignResponseMsgReset = () => createAction(actionTypes.CAMPAIGN_RESPONSE_MSG_RESET);
export const campaignDetailReset = () => createAction(actionTypes.CAMPAIGN_DETAIL_RESET);
export const campaignSelectedPostsReset = () => createAction(actionTypes.CAMPAIGN_SELECTED_POSTS_RESET);
export const campaignEventDetailReset = () => createAction(actionTypes.CAMPAIGN_EVENT_DETAIL_RESET);
export const campaignsResetAll = () => createAction(actionTypes.CAMPAIGNS_RESET_ALL);

const CAMPAIGNS_ACTIONS = {
  campaignsListRequest,
  campaignsListResponse,
  campaignsListFailure,
  campaignsStatsRequest,
  campaignsStatsResponse,
  campaignsStatsFailure,
  getCampaignsEventListRequest,
  getCampaignsEventListResponse,
  getCampaignsEventListFailure,
  getCampaignsEventRequest,
  getCampaignsEventResponse,
  getCampaignsEventFailure,
  createOrUpdateCampaignEventRequest,
  createOrUpdateCampaignEventResponse,
  createOrUpdateCampaignEventFailure,
  stopCampaignEventRequest,
  stopCampaignEventResponse,
  stopCampaignEventFailure,
  campaignPostsDeleteEventRequest,
  campaignPostsDeleteEventResponse,
  campaignPostsDeleteEventFailure,
  createOrEditCampaignRequest,
  createOrEditCampaignResponse,
  createOrEditCampaignFailure,
  getCampaignDetailRequest,
  getCampaignDetailResponse,
  getCampaignDetailFailure,
  deleteCampaignRequest,
  deleteCampaignResponse,
  deleteCampaignFailure,
  deactivateCampaignEventRequest,
  deactivateCampaignEventResponse,
  deactivateCampaignEventFailure,
  campaignSortPostsListRequest,
  campaignSortPostsListResponse,
  campaignSortPostsListFailure,
  addPostsToCampaignRequest,
  addPostsToCampaignResponse,
  addPostsToCampaignFailure,
  campaignsDeletePostsRequest,
  campaignsDeletePostsResponse,
  campaignsDeletePostsFailure,
  campaignsPostListRequest,
  campaignsPostListResponse,
  campaignsPostListFailure,
  campaignsSortPostList,
  setMultiplePostsToCampaign,
  campaignResponseMsgReset,
  campaignDetailReset,
  campaignSelectedPostsReset,
  campaignEventDetailReset,
  campaignsResetAll
};

export type CampaignsAction = ActionsUnion<typeof CAMPAIGNS_ACTIONS>;
