import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { filterObjConversion } from 'analytics/utils';
import { IStore } from 'types';
import { COMMON_PAGE_TYPE, SIDE_NAV_NAME, USER_OWNERSHIP } from 'utils/constants';
import { useNavBarData, useAccountSwitcherData, useShowDefaultNotificationSettings } from 'utils/hooks';

export const useParamsDeconstructor = (initialValue?: any) => {
  const location = useLocation();
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  const showDefaultNotificationSettings = useShowDefaultNotificationSettings();

  const accountSelectionList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  const envEncryptedData = useSelector((state: IStore) => state.common.envEncryptedData);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const isAchieversFetching = useSelector((state: IStore) => state.rewardPrograms.isAchieversFetching);
  const isAdvLeaderboardFetching = useSelector((state: IStore) => state.advLeaderboard.isFetching);
  const postsDetailId = useSelector((state: IStore) => state.posts.postsDetails?.id || null);
  const mgDetailsId = useSelector((state: IStore) => state.mediaGallery.mgDetails?.id || null);
  const contentSupplierHubs = useSelector((state: IStore) => state.operationsList.cs_franchisors);

  const optionalParamsValue = optionalParams[1] || '';

  const queryParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return Object.fromEntries(urlParams);
  }, [location.search]);

  const startDate = useMemo(() => {
    return queryParams?.start_date || '';
  }, [queryParams]);

  const endDate = useMemo(() => {
    return queryParams.end_date || '';
  }, [queryParams]);

  const selectedHubId = useMemo(() => {
    const value = queryParams?.hub_id || queryParams?.location_id || queryParams?.location_list_id || id;
    return value;
  }, [queryParams, id]);

  const selectedHubKey = useMemo(() => {
    const key = queryParams?.hub_id
      ? 'ancestor_franchisor_id'
      : queryParams?.location_list_id
      ? 'account_list_id'
      : queryParams?.location_id || userOwnership === USER_OWNERSHIP.ACCOUNT
      ? 'account_id'
      : 'ancestor_franchisor_id';
    return key;
  }, [queryParams, userOwnership]);

  const filter = useMemo(() => {
    if (initialValue) {
      const value = filterObjConversion(queryParams, initialValue, accountSelectionList, id, userOwnership, contentSupplierHubs);
      return value;
    }
  }, [queryParams, id, userOwnership, accountSelectionList, initialValue, contentSupplierHubs]);

  const showNotificationSettings = useMemo(() => {
    return showDefaultNotificationSettings || queryParams?.notification_settings === '1';
  }, [showDefaultNotificationSettings, queryParams?.notification_settings]);

  const showUserDetail = useMemo(() => {
    const isLoadUserData = queryParams?.active_user_id
      ? Boolean(queryParams?.active_user_id)
      : subNavPageName === SIDE_NAV_NAME.EMP_ADV_REWARD_PROGRAMS && optionalParamsValue === COMMON_PAGE_TYPE.ACHIEVERS
      ? Boolean(!isAchieversFetching && queryParams?.user_id)
      : subNavPageName === SIDE_NAV_NAME.EMP_ADV_LEADERBOARD
      ? Boolean(!isAdvLeaderboardFetching && queryParams?.user_id)
      : subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS
      ? Boolean(postsDetailId && queryParams?.user_id)
      : subNavPageName === SIDE_NAV_NAME.CONTENT_MEDIA
      ? Boolean(mgDetailsId && queryParams?.user_id)
      : false;
    return Boolean(envEncryptedData && !isDataLoading && isLoadUserData);
  }, [
    envEncryptedData,
    isDataLoading,
    queryParams?.user_id,
    queryParams?.active_user_id,
    optionalParamsValue,
    subNavPageName,
    isAchieversFetching,
    isAdvLeaderboardFetching,
    postsDetailId,
    mgDetailsId
  ]);

  return {
    queryParams,
    startDate,
    endDate,
    selectedHubId,
    selectedHubKey,
    filter,
    showNotificationSettings,
    showUserDetail
  };
};
