import { CREATOR_PAGE_TAG_INFO_TOOLTIP, TOOLTIP_PLACEMENT } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';

export const PageTagInfoTooltip = ({ icon = 'info-tooltip', tooltipText = CREATOR_PAGE_TAG_INFO_TOOLTIP }) => {
  return (
    <CustomTooltip
      customPlacement={TOOLTIP_PLACEMENT.TOP}
      customClassname={'custom-tooltip-long-text'}
      customTooltipText={tooltipText}
      customInput={() => (
        <div className="mic info-icon info-ins-twt">
          <ImageValidation defaultImg={icon} customName={'Info'} />
        </div>
      )}
    />
  );
};
