import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Scrollbar, Virtual } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

import { commonWidgetTypes } from 'types';
import { ImageValidation } from './ImageValidation';

export const CustomSwiper = (props: commonWidgetTypes.ICustomSwiper) => {
  SwiperCore.use(props.isBulletSlider ? [Pagination] : [Pagination, Navigation, Scrollbar, Virtual]);

  return (
    <Swiper
      observer
      observeParents
      // loop={true}
      spaceBetween={20}
      slidesPerView={1}
      watchSlidesVisibility
      watchSlidesProgress
      // freeMode={true}
      navigation={props.isVirtualSlider || false}
      pagination={{
        clickable: true,
        type: props.isBulletSlider ? 'bullets' : 'fraction',
        dynamicBullets: props.isBulletSlider || false
      }}
      scrollbar={{ el: props.isBulletSlider ? null : undefined, draggable: props.isVirtualSlider }}
      virtual={props.isVirtualSlider || false}
      centeredSlides
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      {props.imagesList.map((mediaDatum, index) => {
        return mediaDatum ? (
          <SwiperSlide key={`social-media-images-${index}`} virtualIndex={index}>
            <div>
              <ImageValidation imgUrl={mediaDatum} customName="swiper-image" />
            </div>
          </SwiperSlide>
        ) : null;
      })}
    </Swiper>
  );
};
