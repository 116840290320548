import { Fragment, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';

import { PostStats } from './PostStats';
import { IStore, postsContainerTypes } from 'types';
import { AILoading, Loading, NoDataFound } from 'components';
import { NoDataFoundProps, SCROLL_THRESHOLD, POSTS_INIT_FILTER_DATA, PAGINATION_PER_PAGE, USER_OWNERSHIP, POSTS_STATUS_TYPE, AI_ICON_BG_IMG, AI_LOADING_CONTEXT_MSG } from 'utils/constants';
import { PostsList } from './PostsList';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { mgGetFavTagsRequest } from 'actions';

export const PostsInfinityScrollList = ({ isCallApi, startDateTimeStamp, endDateTimeStamp, type, height, handlePostsStats, handlePostListBasedOnFilters }: postsContainerTypes.IPostListProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const isFetching = useSelector((state: IStore) => state.posts.isFetching);
  const isAiCreateSimilarPostFetching = useSelector((state: IStore) => state.aiContentCreator.isAiCreateSimilarPostFetching);
  const isPostsStatsFetching = useSelector((state: IStore) => state.posts.isPostsStatsFetching);
  const postsList = useSelector((state: IStore) => state.posts.postsList);
  const postsStats = useSelector((state: IStore) => state.posts.postsStats);
  const currentPage = useSelector((state: IStore) => state.posts.currentPage);

  const [postStats, setPostStats] = useState({ totalLength: 0, totalPagecount: 0 });

  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  // Get favourite tags
  useEffect(() => {
    if (id && userOwnership && isValidId) {
      dispatch(mgGetFavTagsRequest({ id, userOwnership }));
    }
  }, [id, userOwnership, isValidId]); // eslint-disable-line

  // Get post stats
  useEffect(() => {
    if (id && userOwnership && isValidId && isCallApi && typeof handlePostsStats === 'function') {
      handlePostsStats();
    }
  }, [id, userOwnership, isCallApi, isValidId, queryParams?.tags_list, queryParams?.text, startDateTimeStamp, endDateTimeStamp]); // eslint-disable-line

  // Get post list based on filter changes
  useEffect(() => {
    if (id && userOwnership && isValidId && isCallApi) {
      handlePostListBasedOnFilters();
    }
    // eslint-disable-next-line
  }, [
    id,
    userOwnership,
    isCallApi,
    isValidId,
    queryParams?.text,
    queryParams?.tags_list,
    queryParams?.platform,
    queryParams?.status, // eslint-disable-line
    queryParams?.ai_post,
    queryParams?.usage,
    queryParams?.has_photo,
    queryParams?.has_link,
    queryParams?.has_copy,
    queryParams?.content_supplier_id,
    queryParams?.source,
    startDateTimeStamp,
    endDateTimeStamp
  ]);

  useEffect(() => {
    if (postsStats && Object.keys(postsStats).length) {
      setPostStats({ ...postStats, totalLength: postsStats.totalPosts });
    }
  }, [postsStats]); // eslint-disable-line

  useEffect(() => {
    const count = Math.ceil(postStats.totalLength / PAGINATION_PER_PAGE);
    setPostStats({ ...postStats, totalPagecount: count });
  }, [postStats.totalLength]); // eslint-disable-line

  const handleLoadMoreItems = () => {
    if (id && userOwnership && isValidId && isCallApi) {
      const lastPostId =
        postsList.length && userOwnership === USER_OWNERSHIP.ACCOUNT
          ? postsList[postsList.length - 1]?.saved_post_id
          : postsList.length && userOwnership === USER_OWNERSHIP.FRANCHISOR && queryParams.status === POSTS_STATUS_TYPE.AA_LOCATION_CONTENT
          ? postsList[postsList.length - 1]?.saved_post_id
          : postsList[postsList.length - 1]?.content_id;
      handlePostListBasedOnFilters(lastPostId);
    }
  };

  return (
    <Fragment>
      <PostStats type={type} />
      {isAiCreateSimilarPostFetching && <AILoading aiIconBgColorType={AI_ICON_BG_IMG.DARK_GREY} context={AI_LOADING_CONTEXT_MSG} />}
      {isFetching && currentPage === 1 ? (
        <Loading />
      ) : postsList.length ? (
        <div className={`infinite-list post-grid-wrp${isAiCreateSimilarPostFetching ? ` pointer-events-none` : ''}`} id="post-list-ifs">
          <InfiniteScroll
            scrollThreshold={SCROLL_THRESHOLD}
            next={handleLoadMoreItems}
            hasMore={isPostsStatsFetching ? true : postStats.totalPagecount >= currentPage}
            dataLength={postsList.length}
            loader={isFetching ? <Loading /> : null}
            className="local-ini"
            scrollableTarget={'scrollTarget'}
            height={height}
          >
            <PostsList type={type} />
          </InfiniteScroll>
        </div>
      ) : !isFetching && !postsList.length ? (
        <NoDataFound size={NoDataFoundProps.FULL} />
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};
