import { Reducer } from 'redux';
import moment from 'moment-timezone';

import produce from 'immer';
import { FilterAction } from '../../actions/filter';
import { IFilter } from '../../types/filter';
import * as actionTypes from '../../actions/action-types';
import { groupHubIdByTypeId } from 'revv/utils';
import { dateHelperForWeek } from 'analytics/utils';
import { CommonActions } from 'actions';

const initialState: IFilter = {
  fiscalStartDate: dateHelperForWeek(true).fromDate.selected || new Date(),
  fiscalEndDate: dateHelperForWeek(true).toDate.selected || new Date(),
  prevFiscalStartDate: new Date(),
  prevFiscalEndDate: new Date(),
  selectedYear: moment().year(),
  selectedMonth: moment().month(),
  rangeType: 'Monthly',
  minDate: moment().startOf('year').toDate(),
  maxDate: moment().endOf('year').toDate(),
  selectedQuarter: moment().quarter(),
  disableFilter: false,
  locationIds: [],
  isHubListFetching: true,
  isHubTypeFetching: true,
  isHubLocationsFetching: true,
  hubListError: '',
  hubTypeError: '',
  hubLocationError: '',
  hubListResponse: null,
  hubTypes: null,
  hubLocation: null,
  selectedLocation: [],
  defaultSelectedLocation: [],
  selectedHubGroup: {},
  highlightHubIdsGroup: {},
  selectedFilterMode: [],
  availableHubs: {},
  highlightedLocations: [],
  activeFilterOptions: '',
  isHubLocationListEmpty: false,
  isRevvAccountSwitcherFetching: false,
  isRevvConnectedToCurrentFranchisor: false,
  revvAccountSwitcherErrorLog: ''
};

export const filterReducer: Reducer<IFilter, FilterAction | CommonActions> = (prevState: IFilter = initialState, action: FilterAction | CommonActions) => {
  switch (action.type) {
    case actionTypes.ON_CHANGE_DATE_RANGE:
      return produce(prevState, (nextState) => {
        if (action.payload.fiscalStartDate) {
          nextState.fiscalStartDate = action.payload.fiscalStartDate;
        }

        if (action.payload.fiscalEndDate) {
          nextState.fiscalEndDate = action.payload.fiscalEndDate;
        }

        if (action.payload.prevFiscalStartDate) {
          nextState.prevFiscalStartDate = action.payload.prevFiscalStartDate;
        }

        if (action.payload.prevFiscalEndDate) {
          nextState.prevFiscalEndDate = action.payload.prevFiscalEndDate;
        }

        if (action.payload.selectedMonth !== undefined) {
          nextState.selectedMonth = action.payload.selectedMonth;
        }

        if (action.payload.selectedYear !== undefined) {
          nextState.selectedYear = action.payload.selectedYear;
        }

        if (action.payload.rangeType) {
          nextState.rangeType = action.payload.rangeType;
        }

        if (action.payload.minDate) {
          nextState.minDate = action.payload.minDate;
        }

        if (action.payload.maxDate) {
          nextState.maxDate = action.payload.maxDate;
        }

        if (action.payload.selectedQuarter) {
          nextState.selectedQuarter = action.payload.selectedQuarter;
        }
      });
    case actionTypes.ENABLE_OR_DISABLE_FILTER:
      return produce(prevState, (nextState) => {
        nextState.disableFilter = action.payload;
      });
    case actionTypes.LOAD_HUBS_LIST_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isHubListFetching = true;
        nextState.hubListError = '';
      });
    case actionTypes.LOAD_HUBS_LIST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isHubListFetching = false;
        nextState.hubListError = action.payload;
      });
    case actionTypes.LOAD_HUBS_LIST_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isHubListFetching = false;
        nextState.hubListResponse = action.payload;
        nextState.availableHubs = groupHubIdByTypeId(action.payload.hubs);
        nextState.hubListError = '';
      });
    case actionTypes.LOAD_HUB_TYPE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isHubTypeFetching = true;
        nextState.hubTypeError = '';
      });
    case actionTypes.LOAD_HUB_TYPE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isHubTypeFetching = false;
        nextState.hubTypeError = action.payload;
      });
    case actionTypes.LOAD_HUB_TYPE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isHubTypeFetching = false;
        nextState.hubTypes = action.payload;
        nextState.hubTypeError = '';
      });
    case actionTypes.LOAD_HUB_LOCATION_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isHubLocationsFetching = true;
        nextState.hubLocationError = '';
      });
    case actionTypes.LOAD_HUB_LOCATION_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isHubLocationsFetching = false;
        nextState.hubLocationError = action.payload;
      });
    case actionTypes.LOAD_HUB_LOCATION_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isHubLocationsFetching = false;
        nextState.hubLocationError = '';
        nextState.hubLocation = action.payload;
        nextState.defaultSelectedLocation = action.payload?.map((it) => it.id) || [];
      });
    case actionTypes.ON_CHANGE_LOCATION:
      return produce(prevState, (nextState) => {
        nextState.selectedLocation = action.payload.selectedLocations;
        nextState.selectedHubGroup = action.payload.selectedHubGroup;
        nextState.highlightHubIdsGroup = action.payload.highlightHubIdsGroup;
        nextState.selectedFilterMode = action.payload.selectedFilterMode;
        nextState.highlightedLocations = action.payload.highlightedLocations;
        nextState.defaultSelectedLocation = action.payload.validLocations;
        nextState.availableHubs = action.payload.validHubs;
      });
    case actionTypes.UPDATE_HUB_LOCATION_FLAG:
      return produce(prevState, (nextState) => {
        nextState.isHubLocationListEmpty = action.payload;
      });
    case actionTypes.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'revv_dashboard' || action.payload === 'revv_surveys' || action.payload === 'leaderboard') {
          nextState.selectedLocation = initialState.selectedLocation;
          nextState.fiscalEndDate = initialState.fiscalEndDate;
          nextState.fiscalEndDate = initialState.fiscalEndDate;
        }
      });
    case actionTypes.ACTIVE_FILTER_OPTIONS:
      return produce(prevState, (nextState) => {
        nextState.activeFilterOptions = action.payload;
      });
    case actionTypes.REVV_ACCOUNT_SWITCHER_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isRevvAccountSwitcherFetching = true;
        nextState.isRevvConnectedToCurrentFranchisor = prevState.isRevvConnectedToCurrentFranchisor;
        nextState.revvAccountSwitcherErrorLog = '';
      });
    case actionTypes.REVV_ACCOUNT_SWITCHER_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isRevvAccountSwitcherFetching = false;
        nextState.isRevvConnectedToCurrentFranchisor = action.payload;
        nextState.revvAccountSwitcherErrorLog = '';
      });
    case actionTypes.REVV_ACCOUNT_SWITCHER_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isRevvAccountSwitcherFetching = false;
        nextState.isRevvConnectedToCurrentFranchisor = false;
        nextState.revvAccountSwitcherErrorLog = action.payload;
      });

    default:
      return prevState;
  }
};
