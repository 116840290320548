import { useCallback } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'config';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { FacebookConnectButton } from './FacebookConnectButton';
import { useSocialMedia } from './useSocialMedia';
import { COMMON_SOCIAL_PROVIDER_TYPE, RIPPLE_COLOR } from 'utils/constants/common';
import { IStore, settingsSocialProfilContainerTypes } from 'types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { APP_TITLE } from 'utils/constants';

export const InstagramConnectModal = ({ isShowModal, onModalClose, disconnectSocialMedia, getFbViewPages }: settingsSocialProfilContainerTypes.IInstagramConnectModal) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  const instagramMedia = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE);

  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id || 0);
  const extraFacebookScopes = useSelector((state: IStore) => state.accounts.accountDetails?.account.custom_fields.extra_facebook_scopes);
  const mediaRequest = useSelector((state: IStore) => state.socialProfile.mediaRequest);

  return (
    <Modal className="insta-connect instaCWrap" show={isShowModal} centered size="lg">
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg={'xmark'} customClassname={'mod__close--img'} customName={'Close Modal'} onClick={onModalClose} />
      </div>
      <Modal.Body>
        <h2>Link Your Instagram to {APP_TITLE}</h2>
        <div className="instaCnt">
          <h3>To integrate your Instagram with {APP_TITLE}, you'll need to use Facebook for the connection. Ensure you have:</h3>
          <ol>
            <li>
              <strong>Facebook Business Page:</strong> Don't have one yet?{' '}
              <a target="_blank" href="https://www.facebook.com/business/help/1199464373557428?id=418112142508425" rel="noreferrer">
                Follow these instructions to create it.
              </a>
            </li>
            <li>
              <strong>Facebook-Instagram Page Link:</strong> Haven't connected your pages?{' '}
              <a target="_blank" href="https://www.facebook.com/business/help/connect-instagram-to-page" rel="noreferrer">
                Here's how to do it.
              </a>
            </li>
            {/* <li>
              <strong>Instagram Business Profile:</strong> Got a personal account? You'll be guided to switch to a business profile during the linking process.
            </li> */}
          </ol>
          <div className="csp">
            <div className="video-outer-element">
              <ReactVideoPlayer
                isPlayControl
                light={`${config.cdnImgUrl}connectInstagramBg.png`}
                muted
                videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/cgfcrtabpthylrdagkui.mp4'}
                customClassname="m-ast-itm m-ast-video"
              />
            </div>
          </div>

          <div className="ica-actions">
            {instagramMedia?.needs_reconnection || instagramMedia?.needs_updated_credentials ? (
              <FacebookConnectButton
                callback={(fbResponse: any) => {
                  getFbViewPages(fbResponse, 'instagram');
                }}
                renderButton={(renderProps: any) => (
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                    <button onClick={renderProps.onClick} className={`gnrc-btn ac-btn ${instagramMedia ? 'l-red' : 'l-blue'}`}>
                      {mediaRequest?.instagram_business_page ? (
                        <Spinner animation="border" variant={'light'} />
                      ) : (
                        <span>{instagramMedia?.needs_reconnection ? 'Reconnect' : instagramMedia?.needs_updated_credentials ? 'Update Credentials' : 'Connect'}</span>
                      )}
                    </button>
                  </CustomRippleButton>
                )}
                extraScopes={extraFacebookScopes}
              />
            ) : !instagramMedia ? (
              <FacebookConnectButton
                callback={(fbResponse: any) => {
                  getFbViewPages(fbResponse, 'instagram');
                }}
                renderButton={(renderProps: any) => (
                  <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                    <button onClick={renderProps.onClick} className={`gnrc-btn ac-btn ${instagramMedia ? 'l-red' : 'l-blue'}`}>
                      {mediaRequest?.instagram_business_page ? <Spinner animation="border" variant={'light'} /> : <span>Connect</span>}
                    </button>
                  </CustomRippleButton>
                )}
                extraScopes={extraFacebookScopes}
              />
            ) : null}
            {instagramMedia ? (
              <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                <button
                  className={`ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac`}
                  onClick={() => {
                    if (instagramMedia?.id) {
                      disconnectSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, instagramMedia.id, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, accountId, dispatch);
                    }
                  }}
                >
                  {mediaRequest?.instagram_business_page ? <Spinner animation="border" variant={'primary'} /> : <span>Disconnect</span>}
                </button>
              </CustomRippleButton>
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
