import { MODAL_BOX_TYPES } from 'utils/constants';
import { ModalPopup } from './Modal';
import { commonModalPopup } from 'types';

export const DeleteConfirmationModal = ({ isModalShow, deleteMsg, onClose, onDelete }: commonModalPopup.IDeleteConfirmationModalProps) => {
  return (
    <ModalPopup
      isModalShow={isModalShow}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{deleteMsg}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={onClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button className="modal-btn-action-itm modal-delete-btn" onClick={onDelete}>
            Delete
          </button>
        </div>
      )}
    />
  );
};
