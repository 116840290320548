import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData, useSiteRestriction } from 'utils/hooks';
import { dateFormatByRequestedFormat } from 'utils/helpers';
import { MOMENT_TIME_FORMAT, MOMENT_LONG_DATE_FORMAT, AP_ACTION_TYPES, AP_INTERNAL_NOTES_TYPES, USER_OWNERSHIP } from 'utils/constants';
import { IStore } from 'types';
import { TextArea } from 'widgets/Text';
import { postsDetailAddInternalNotesRequest, addPostSetEditConfirmForApprovedPost } from 'actions';

export const InternalNotesSection = () => {
  const dispatch = useDispatch();
  const { userOwnership } = useAccountSwitcherData();
  const { postsRestriction } = useSiteRestriction();

  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const addPostinternalNotesList = useSelector((state: IStore) => state.addPostAccount.addPostinternalNotesList);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);

  const [internalNoteText, setInternalNoteText] = useState<null | string>(null);

  const handleAddInternalNotes = () => {
    if (editConfirmObj.isPostEditable) {
      handleEditConfirmation();
    } else if (editSavedPost?.id && userOwnership) {
      dispatch(
        postsDetailAddInternalNotesRequest({
          postDataId: editSavedPost?.id,
          customText: internalNoteText,
          userOwnership: USER_OWNERSHIP.ACCOUNT,
          type: AP_INTERNAL_NOTES_TYPES.SAVED_POST
        })
      );
      setInternalNoteText(null);
    }
  };

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  return (
    <Form>
      {addPostAction === AP_ACTION_TYPES.ADD ? (
        <div>Save this post to add internal notes.</div>
      ) : (
        <div className="st-tc-item">
          <div className="wb-top">
            <div className="chat-main">
              <div className="cm-group">
                {!postsRestriction.internal_note && (
                  <div className={`cm-input-area ${editSavedPost?.locked ? 'pointer-events-none' : ''}`}>
                    <ImageValidation
                      imgUrl={editSavedPost?.created_user?.profile_photo_url || null}
                      defaultImg={'user-avatar-common.png'}
                      isNotSvgFormat
                      customClassname={'cm-asset'}
                      customName={'Profile Pic'}
                    />
                    <div className="cmi">
                      <Button className="plain-btn arw-cmi " variant="link" onClick={() => handleAddInternalNotes()} />
                      <TextArea
                        id="internalnote"
                        name="internalnote"
                        minRows={1}
                        className="form-control post-notes"
                        placeholder="Add Internal Note"
                        onClick={() => {
                          if (editConfirmObj.isPostEditable) {
                            handleEditConfirmation();
                          }
                        }}
                        onChange={(event) => setInternalNoteText(event.target.value)}
                        value={internalNoteText || ''}
                      />
                    </div>
                  </div>
                )}
                <div className="cma-child-holder">
                  {addPostinternalNotesList.length ? (
                    addPostinternalNotesList.map((noteItem, index) => {
                      return (
                        <div className="cm-input-area cma-child" key={`creator-acc-internal-note-${index}`}>
                          <ImageValidation
                            imgUrl={editSavedPost?.created_user?.profile_photo_url || null}
                            defaultImg={'user-avatar-common.png'}
                            isNotSvgFormat
                            customClassname={'cm-asset'}
                            customName={'Profile Pic'}
                          />
                          <div className="cmi">
                            <div className="cm-prime-dtls">
                              <h3>{noteItem.user_name}</h3>
                              <div className="cmp-brief">
                                <span className="cmp-txt">{`@ ${dateFormatByRequestedFormat(noteItem.created_at, MOMENT_TIME_FORMAT)} |`}</span>
                                <span className="cmp-txt cmpt-blue"> {dateFormatByRequestedFormat(noteItem.created_at, MOMENT_LONG_DATE_FORMAT)}</span>
                              </div>
                            </div>
                            <div className="cmi-txt-wrap">
                              <TextArea
                                id="internalnote"
                                name="internalnote"
                                readOnly
                                disabled
                                defaultValue={noteItem.body}
                                className="form-control post-notes"
                                placeholder="Internal Notes"
                                maxRows={2}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="notetext">There are no internal notes to display.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
