import { useDispatch, useSelector } from 'react-redux';

import { calendarAutomatedScheduledPostDeleteModalOption } from 'actions';
import { ModalPopup } from 'components';
import { IStore, calendarApiTypes, calendarContainerTypes, commonModalPopup } from 'types';
import { calendarConvertDateBasedOnTimeZone } from 'utils/helpers';
import {
  AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS,
  AUTOMATED_SCHEDULED_POSTS_DELETE_OPTIONS,
  AUTOMATED_POSTS_DELETE_TIME_SLOT_OPTIONS,
  DATE_AT_TIME_FORMAT,
  MODAL_BOX_TYPES,
  RIPPLE_COLOR,
  TIME_WITH_FULL_DAY_FORMAT
} from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const CalendarAutomatedScheduledPostDeleteModal = ({
  isModalShow,
  handleModalCancel,
  handleModalClose,
  postDetail
}: commonModalPopup.IDeleteScheduleSlotProps & { postDetail: calendarContainerTypes.ICalendarDataType | calendarApiTypes.ICalendarItemsResponse }) => {
  const dispatch = useDispatch();

  const deleteAutomatedScheduledPostOptions = useSelector((state: IStore) => state.calendar.deleteAutomatedScheduledPostOptions);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');

  const updatedAutomatedPostTimeSlotOptions = postDetail?.account_list_schedule_details?.can_delete
    ? AUTOMATED_POSTS_DELETE_TIME_SLOT_OPTIONS
    : AUTOMATED_POSTS_DELETE_TIME_SLOT_OPTIONS.filter((option) => option.key !== AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.REMOVE_ALL_POST);

  const getTimeSlotLabel = (label: string, key: string) => {
    switch (key) {
      case AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.EMPTY_SLOT:
        return label.replace(
          ':time-slot',
          calendarConvertDateBasedOnTimeZone(timeZone, postDetail?.account_list_schedule_details?.original_scheduled_for, DATE_AT_TIME_FORMAT, true)?.toString() || ''
        );
      case AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.REMOVE_ALL_POST:
        return label.replace(
          ':time-slot',
          calendarConvertDateBasedOnTimeZone(timeZone, postDetail?.account_list_schedule_details?.original_scheduled_for, TIME_WITH_FULL_DAY_FORMAT, true)?.toString() || ''
        );
      default:
        return label;
    }
  };

  // calendarConvertDateBasedOnTimeZone(timeZone, calenderViewData?.scheduledTime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT, true);
  return (
    <ModalPopup
      isModalShow={isModalShow}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={`cnfrm__delete--modal glbl__delete--modal package__delete--modal ap-remove`}
      modalBody={() => (
        <div className="createpost-wrp-new-section">
          <h3 className="modal-sub-head">What do you want to do with this post?</h3>

          {AUTOMATED_SCHEDULED_POSTS_DELETE_OPTIONS.map((option) => (
            <div className="form-check" key={`post-option-${option.value}`}>
              <label className={deleteAutomatedScheduledPostOptions.post === option.value ? 'active' : ''}>
                <span>
                  <input
                    type="radio"
                    name={option.name}
                    checked={deleteAutomatedScheduledPostOptions.post === option.value}
                    value={option.value}
                    onChange={() => dispatch(calendarAutomatedScheduledPostDeleteModalOption({ ...deleteAutomatedScheduledPostOptions, post: option.value }))}
                  />
                </span>
                <div className="label-txt">{option.label}</div>
                <span className={'checkmark'} />
              </label>
            </div>
          ))}
          <h3 className="modal-sub-head pt-20">What do you want to do with this time slot?</h3>
          {updatedAutomatedPostTimeSlotOptions.map((option) => (
            <div className="form-check" key={`post-option-${option.value}`}>
              <label className={deleteAutomatedScheduledPostOptions.timeSlot === option.value ? 'active' : ''}>
                <span>
                  <input
                    type="radio"
                    name={option.name}
                    checked={deleteAutomatedScheduledPostOptions.timeSlot === option.value}
                    value={option.value}
                    onChange={() => dispatch(calendarAutomatedScheduledPostDeleteModalOption({ ...deleteAutomatedScheduledPostOptions, timeSlot: option.value }))}
                  />
                </span>
                <div className="label-txt">{getTimeSlotLabel(option.label, option.key)}</div>
                <span className={'checkmark'} />
              </label>
            </div>
          ))}
        </div>
      )}
      modalCloseButton
      handleModalClose={() => handleModalCancel()}
      isModalFooterShow
      modalFooter={() => (
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalCancel}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
            <button className={`ac-btn ac-danger ac-block`} onClick={handleModalClose}>
              Ok
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
