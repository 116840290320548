import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
// import { config } from 'config';
import { commonBoost } from 'types';
import { FB_ADS_GOAL_LIST, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const EditBoostGoal = ({ isModalShow, handleModalClose, goalObjective, handleUpdateFBBoostConfig }: commonBoost.IEditBoostGoalProps) => {
  const [boostGoalObjective, setBoostGoalObjective] = useState('');

  useEffect(() => {
    if (isModalShow && goalObjective) {
      setBoostGoalObjective(goalObjective);
    }
  }, [isModalShow, goalObjective]); // eslint-disable-line

  const handleUpdateDefaultConfig = () => {
    handleUpdateFBBoostConfig('objective', boostGoalObjective);
    handleModalClose();
  };

  return (
    <Modal show={isModalShow} onHide={() => false} centered size="lg" className="boost__goal--modal z-index__type--two" backdropClassName="z-index__type--two">
      <div className="mod__close--icon">
        {/* <img className="mod__close--img" src={`${config.cdnImgUrl}modal-close2.svg`} alt="cancel" onClick={handleModalClose} /> */}
        <ImageValidation isImgValid defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} onClick={handleModalClose} />
      </div>
      <Modal.Body>
        <div>
          <div>
            <span className="boost__box--title">Goal</span>
            <div className="boost__box--qn">What results would you like from this ad?</div>
          </div>
          <div className="goal-list__wrp">
            {FB_ADS_GOAL_LIST.map((goalDatum, index) => {
              return (
                <div
                  className={`r-flx r-flx-ac goal-list__item g-20${goalDatum.value === boostGoalObjective ? ` active` : ``}${[0, 2].includes(index) ? ` pointer-events-none` : ''}`}
                  key={`boost-goal-${index}`}
                  onClick={() => setBoostGoalObjective(goalDatum.value)}
                >
                  <div className="boost__media--icon">
                    <ImageValidation
                      isImgValid
                      defaultImg={`${goalDatum.imgSrc}${goalDatum.value === boostGoalObjective ? `-active` : ``}`}
                      customName={goalDatum.value}
                      customClassname={goalDatum.className}
                    />
                  </div>
                  <div>
                    <span className="goal__list--title">{goalDatum.label}</span>
                    <div className="goal__list--desc">{goalDatum.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="r-flx goal__btn--wrp g-20">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box ${goalObjective === boostGoalObjective ? ` pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block ${goalObjective === boostGoalObjective ? `disabled` : ``}`}
                disabled={goalObjective === boostGoalObjective}
                onClick={() => handleUpdateDefaultConfig()}
              >
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
