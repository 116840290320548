import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  franchisorsPermissionListResponse,
  franchisorsPermissionListFailure,
  getDirectLocsForHubOrLocListResponse,
  getDirectLocsForHubOrLocListFailure,
  franchisorGetOrUpdateProfileResponse,
  franchisorGetOrUpdateProfileFailure,
  contentSupplierGetCorpFranchisorsListResponse,
  contentSupplierGetCorpFranchisorsListFailure,
  getPartnerBannersResponse,
  getPartnerBannersFailure
} from 'actions';
import { apiCall, defaultHeader, API, HttpMethods, updateFranchisorSyndicateWithLabel } from 'utils/helpers';
import { commonApiTypes, commonProps, franchisorApiTypes, postsApiTypes } from 'types';
import {
  FRANCHISORS_EXCEPTION_ERROR_MSG,
  GET_LOC_BASED_ON_HUB_OR_LOC_LIST_ERROR,
  FRANCHISORS_SELECTIONS,
  ALERT_BOX_TYPES,
  SETTINGS_SUCCESS_MSG,
  SETTINGS_FAILURE_MSG,
  CONTENT_SUPPLIER_EXCEPTION_ERROR
} from 'utils/constants';
import { store } from 'store';
import { alertBoxCall } from 'components';
type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendFranchisorPermissionListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { ...(payload.editedContent && { franchisor_permissions_list: payload.editedContent }) };
    const urlPathObj: { apiPath: string; action: HttpMethods } = payload.editedContent ? { ...API.updateFranchisorsPermissionList } : { ...API.getFranchisorsPermissionList };
    const replacedPath = urlPathObj.apiPath.replace(':id', payload.id);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, data, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(franchisorsPermissionListResponse(response.data.franchisor_permissions_list));
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
      }
    } else {
      yield put(franchisorsPermissionListFailure(response.data.error));
      if (urlPathObj.action === HttpMethods.PUT) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, SETTINGS_FAILURE_MSG);
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

function* sendGetDirectLocsForHubOrLocListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response: commonProps.ICommonObj<any>;
    if (payload.type === FRANCHISORS_SELECTIONS) {
      const params = {
        franchisor_id: payload.id
      };
      response = yield apiCall({ headers, params, ...API.getLocIdsBasedOnHub });
    } else {
      response = yield apiCall({ headers, apiPath: API.getLocIdsBasedOnLocList.apiPath.replace(':id', payload.id), action: API.getLocIdsBasedOnLocList.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload.type === FRANCHISORS_SELECTIONS) {
        const locationList = store.getState().accountSwitcher.locationSelectorResponse.account_selections || [];
        const currentHubDetail = (store.getState().accountSwitcher.locationSelectorResponse.franchisor_selections || []).find((it) => it.id === payload.id);
        if (currentHubDetail) {
          yield put(
            getDirectLocsForHubOrLocListResponse({
              hubOrListData: {
                id: currentHubDetail.id,
                name: currentHubDetail.name,
                type: payload.type,
                open: true,
                locations: locationList
                  .sort((a, b) => a?.name.localeCompare(b?.name))
                  .reduce((acc: franchisorApiTypes.IDirectLocsObj[], curr: postsApiTypes.IPostListDetailData) => {
                    if ((response.data || []).includes(curr.id)) {
                      acc.push({
                        id: curr.id,
                        name: curr.name,
                        active: !payload.scheduledLocationIds?.includes(curr.id)
                      });
                    }
                    return acc;
                  }, [])
              },
              ...(payload.includedAccounts?.length && { includedAccounts: payload.includedAccounts })
            })
          );
        }
      } else {
        yield put(
          getDirectLocsForHubOrLocListResponse({
            hubOrListData: {
              id: response.data.account_list_detail.id,
              name: response.data.account_list_detail.name,
              type: payload.type,
              open: true,
              locations: (response.data.account_list_detail?.included_accounts || [])
                .sort((a: postsApiTypes.IPostListDetailData, b: postsApiTypes.IPostListDetailData) => a?.name.localeCompare(b?.name))
                .reduce((acc: franchisorApiTypes.IDirectLocsObj[], curr: postsApiTypes.IPostListDetailData) => {
                  acc.push({
                    id: curr.id,
                    name: curr.name,
                    active: !payload.scheduledLocationIds?.includes(curr.id)
                  });
                  return acc;
                }, [])
            },
            ...(payload.includedAccounts?.length && { includedAccounts: payload.includedAccounts })
          })
        );
      }
    } else yield put(getDirectLocsForHubOrLocListFailure(GET_LOC_BASED_ON_HUB_OR_LOC_LIST_ERROR));
  } catch (error) {
    yield put(exceptionHandlerRequest(GET_LOC_BASED_ON_HUB_OR_LOC_LIST_ERROR));
  }
}

function* sendFranchisorGetOrUpdateProfileRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj = payload.editedContent
      ? { apiPath: API.updateFranchisor.apiPath.replace(':id', `${payload.id}`), action: API.updateFranchisor.action }
      : { apiPath: API.getFranchisor.apiPath.replace(':id', `${payload.id}`), action: API.getFranchisor.action };
    const response = yield apiCall({ headers, ...{ ...(payload.editedContent && { data: { franchisor: payload.editedContent } }) }, ...urlPathObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(franchisorGetOrUpdateProfileResponse(response.data));
    } else {
      yield put(franchisorGetOrUpdateProfileFailure(response.data.error));
    }
    if (payload?.callback) {
      payload?.callback(response.status);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISORS_EXCEPTION_ERROR_MSG));
  }
}

function* sendContentSupplierGetCorpFranchisorsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: any } = { ...API.getContentSupplierCorpFranchisor };
    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 200) {
      yield put(contentSupplierGetCorpFranchisorsListResponse(updateFranchisorSyndicateWithLabel(response.data.franchisors)));
    } else {
      yield put(contentSupplierGetCorpFranchisorsListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_SUPPLIER_EXCEPTION_ERROR));
  }
}
function* sendgetPartnerBannersRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj = { ...API.getPartnerBanners };
    const replacedPath = urlPathObj.apiPath.replace(':id', payload?.id);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, params: payload?.params, ...urlPathObj });
    if (response.status === 200) {
      yield put(getPartnerBannersResponse({ data: response.data.partner_banners, isRandom: Boolean(payload?.params) }));
    } else {
      yield put(getPartnerBannersFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_SUPPLIER_EXCEPTION_ERROR));
  }
}

export function* takeFranchisorsProfileRequest() {
  yield takeEvery(types.FRANCHISOR_PERMISSION_LIST_REQUEST, sendFranchisorPermissionListRequest);
  yield takeEvery(types.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_REQUEST, sendGetDirectLocsForHubOrLocListRequest);
  yield takeLatest(types.FRANCHISOR_GET_OR_UPDATE_PROFILE_REQUEST, sendFranchisorGetOrUpdateProfileRequest);
  yield takeLatest(types.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_REQUEST, sendContentSupplierGetCorpFranchisorsListRequest);
  yield takeLatest(types.GET_PARTNER_BANNERS_REQUEST, sendgetPartnerBannersRequest);
}
