import { salesReducerTypes } from 'types';

export const SALES_INIT_QUERY_OBJ: salesReducerTypes.ISalesQueryObj = {
  name: '',
  company: '',
  email: '',
  phone_number: '',
  location_count: '',
  regarding: [],
  comment: ''
};

export const REVV_INIT_LOGIN_OBJ: salesReducerTypes.IRevvLoginObj = {
  isFetching: false,
  errorMsg: null
};
