// GET FB ADS TARGETING CHOICES
export const GET_FB_ADS_TARGETING_CHOICES_REQUEST = 'fb-ads-targeting/GET_FB_ADS_TARGETING_CHOICES_REQUEST';
export const GET_FB_ADS_TARGETING_CHOICES_RESPONSE = 'fb-ads-targeting/GET_FB_ADS_TARGETING_CHOICES_RESPONSE';
export const GET_FB_ADS_TARGETING_CHOICES_FAILURE = 'fb-ads-targeting/GET_FB_ADS_TARGETING_CHOICES_FAILURE';

// DISCONNECT FB ADS TARGETING
export const DISCONNECT_FB_ADS_TARGETING_CHOICES_REQUEST = 'fb-ads-targeting/DISCONNECT_FB_ADS_TARGETING_CHOICES_REQUEST';
export const DISCONNECT_FB_ADS_TARGETING_CHOICES_RESPONSE = 'fb-ads-targeting/DISCONNECT_FB_ADS_TARGETING_CHOICES_RESPONSE';
export const DISCONNECT_FB_ADS_TARGETING_CHOICES_FAILURE = 'fb-ads-targeting/DISCONNECT_FB_ADS_TARGETING_CHOICES_FAILURE';

// UPDATE ACC FB ADS TARGETING DEFAULT CONFIG
export const UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_REQUEST = 'fb-ads-targeting/UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_REQUEST';
export const UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_RESPONSE = 'fb-ads-targeting/UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_RESPONSE';
export const UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_FAILURE = 'fb-ads-targeting/UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_FAILURE';

// ACCOUNTS - TARGETING INTERESTS
export const GET_ACC_TARGETING_INTERESTS_LIST_REQUEST = 'fb-ads-targeting/GET_ACC_TARGETING_INTERESTS_LIST_REQUEST';
export const GET_ACC_TARGETING_INTERESTS_LIST_RESPONSE = 'fb-ads-targeting/GET_ACC_TARGETING_INTERESTS_LIST_RESPONSE';
export const GET_ACC_TARGETING_INTERESTS_LIST_FAILURE = 'fb-ads-targeting/GET_ACC_TARGETING_INTERESTS_LIST_FAILURE';

// ACCOUNTS - GET FACEBOOK AD ACCOUNT DATA
export const GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_REQUEST = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_REQUEST';
export const GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_RESPONSE = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_RESPONSE';
export const GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_FAILURE = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_FAILURE';

// ACCOUNTS - GET FACEBOOK AD ACCOUNT ID
export const GET_ACC_FACEBOOK_AD_ACCOUNT_ID_REQUEST = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_ID_REQUEST';
export const GET_ACC_FACEBOOK_AD_ACCOUNT_ID_RESPONSE = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_ID_RESPONSE';
export const GET_ACC_FACEBOOK_AD_ACCOUNT_ID_FAILURE = 'fb-ads-targeting/GET_ACC_FACEBOOK_AD_ACCOUNT_ID_FAILURE';

// ACCOUNTS - UPDATE FACEBOOK AD ACCOUNT ID
export const UPDATE_FACEBOOK_AD_ACCOUNT_REQUEST = 'fb-ads-targeting/UPDATE_FACEBOOK_AD_ACCOUNT_REQUEST';
export const UPDATE_FACEBOOK_AD_ACCOUNT_RESPONSE = 'fb-ads-targeting/UPDATE_FACEBOOK_AD_ACCOUNT_RESPONSE';
export const UPDATE_FACEBOOK_AD_ACCOUNT_FAILURE = 'fb-ads-targeting/UPDATE_FACEBOOK_AD_ACCOUNT_FAILURE';

// ACCOUNTS - ADD FACEBOOK AD PREVIEW
export const ADD_FACEBOOK_AD_PREVIEW_REQUEST = 'fb-ads-targeting/ADD_FACEBOOK_AD_PREVIEW_REQUEST';
export const ADD_FACEBOOK_AD_PREVIEW_RESPONSE = 'fb-ads-targeting/ADD_FACEBOOK_AD_PREVIEW_RESPONSE';
export const ADD_FACEBOOK_AD_PREVIEW_FAILURE = 'fb-ads-targeting/ADD_FACEBOOK_AD_PREVIEW_FAILURE';

// FRANCHISOR - GET AVERAGE LOCATIONS SPEND
export const GET_AVERAGE_LOCATIONS_SPEND_REQUEST = 'fb-ads-targeting/GET_AVERAGE_LOCATIONS_SPEND_REQUEST';
export const GET_AVERAGE_LOCATIONS_SPEND_RESPONSE = 'fb-ads-targeting/GET_AVERAGE_LOCATIONS_SPEND_RESPONSE';
export const GET_AVERAGE_LOCATIONS_SPEND_FAILURE = 'fb-ads-targeting/GET_AVERAGE_LOCATIONS_SPEND_FAILURE';

// FRANCHISOR - SET CORPORATE POST BOOST DATA
export const SET_CORPORATE_POST_BOOST_DATA = 'fb-ads-targeting/SET_CORPORATE_POST_BOOST_DATA';

// ACCOUNTS - UPDATE FB BOOST CONFIG DATA
export const UPDATE_FB_BOOST_CONFIG_DATA = 'fb-ads-targeting/UPDATE_FB_BOOST_CONFIG_DATA';

// ACCOUNTS - SET FB POST DETAILS
export const SET_FB_POST_DETAILS = 'fb-ads-targeting/SET_FB_POST_DETAILS';

// FB ADS TARGETING RESET ALL
export const FB_ADS_INTEREST_LIST_FAIL_MSG_RESET = 'fb-ads-targeting/FB_ADS_INTEREST_LIST_FAIL_MSG_RESET';
export const FB_ADS_CREATOR_BOOST_DATA_RESET = 'fb-ads-targeting/FB_ADS_CREATOR_BOOST_DATA_RESET';
export const FB_ADS_BOOST_DATA_RESET_ALL = 'fb-ads-targeting/FB_ADS_BOOST_DATA_RESET_ALL';
