import { commonWidgetTypes } from 'types';
import { getPercentage } from 'utils/helpers';

export const CalendarTotalStatsCount = (props: commonWidgetTypes.ICalendarTotalStatCountProps) => {
  const { totalStatCount, schedule, approval, events } = props;

  let scheduleVal = getPercentage(totalStatCount, schedule) || 0;
  let approvalVal = getPercentage(totalStatCount, approval) || 0;
  let eventsVal = getPercentage(totalStatCount, events) || 0;

  /** store converted stats value to local state */
  if (totalStatCount) {
    const totalPercent = scheduleVal + approvalVal + eventsVal;
    if (totalPercent > 100) {
      const diff = totalPercent - 100;
      const maxStatValue = Math.max(scheduleVal, approvalVal, eventsVal);
      if (scheduleVal === maxStatValue) scheduleVal = scheduleVal - diff;
      else if (approvalVal === maxStatValue) approvalVal = approvalVal - diff;
      else if (eventsVal === maxStatValue) eventsVal = eventsVal - diff;
    } else if (totalPercent < 100) {
      const diff = 100 - totalPercent;
      const minStatValue = Math.min.apply(null, [scheduleVal, approvalVal, eventsVal].filter(Boolean));
      if (scheduleVal === minStatValue) scheduleVal = scheduleVal + diff;
      else if (approvalVal === minStatValue) approvalVal = approvalVal + diff;
      else if (eventsVal === minStatValue) eventsVal = eventsVal + diff;
    }
  }

  return (
    <div className={`chart-details cf-facebook`}>
      <div className="progress-vertical">
        <div className="bar bar-facebook" style={{ width: '100%', background: '#3870FF', height: `${scheduleVal}%` }} />
        <div className="bar bar-twitter" style={{ width: '100%', background: '#FFC259', height: `${approvalVal}%` }} />
        <div className="bar bar-insta" style={{ width: '100%', background: '#A535B5', height: `${eventsVal}%` }} />
      </div>
    </div>
  );
};
