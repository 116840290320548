import { takeEvery, put } from 'redux-saga/effects';

import { apiCall, defaultHeader, API } from 'utils/helpers';
import * as types from 'actions/action-types';
import { forgotPasswordResponse, forgotPasswordFailure, exceptionHandlerRequest } from 'actions';
import { FGP_EXCEPTION_ERROR_MSG } from 'utils/constants';
import { commonApiTypes } from 'types';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendForgotPasswordRequest(authDetails: ISagaPayload): any {
  const headers = defaultHeader();
  const data = authDetails.payload;
  const response = yield apiCall({ headers, data, ...API.forgotPassword });
  try {
    if (response.status === 200) {
      const message: string = response.data.message;
      yield put(forgotPasswordResponse(message));
    } else {
      const error: string = response.data.errors;
      yield put(forgotPasswordFailure(error));
    }
  } catch (error) {
    const errorMsg = response.data && response.data.errors ? response.data.errors : FGP_EXCEPTION_ERROR_MSG;
    yield put(exceptionHandlerRequest(errorMsg));
  }
}

export function* takeforgotPasswordRequest() {
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, sendForgotPasswordRequest);
}
