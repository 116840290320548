// SOCIAL CONTENTS STATS
export const SOCIAL_CONTENTS_STATS_REQUEST = 'socialContents/SOCIAL_CONTENTS_STATS_REQUEST';
export const SOCIAL_CONTENTS_STATS_RESPONSE = 'socialContents/SOCIAL_CONTENTS_STATS_RESPONSE';
export const SOCIAL_CONTENTS_STATS_FAILURE = 'socialContents/SOCIAL_CONTENTS_STATS_FAILURE';

// SOCIAL_CONTENTS PIE RELOAD
export const SOCIAL_CONTENTS_PIE_RELOAD_REQUEST = 'socialContents/SOCIAL_CONTENTS_PIE_RELOAD_REQUEST';
export const SOCIAL_CONTENTS_PIE_RELOAD_RESPONSE = 'socialContents/SOCIAL_CONTENTS_PIE_RELOAD_RESPONSE';
export const SOCIAL_CONTENTS_PIE_RELOAD_FAILURE = 'socialContents/SOCIAL_CONTENTS_PIE_RELOAD_FAILURE';

// SOCIAL CONTENTS LIST
export const SOCIAL_CONTENTS_LIST_REQUEST = 'socialContents/SOCIAL_CONTENTS_LIST_REQUEST';
export const SOCIAL_CONTENTS_LIST_RESPONSE = 'socialContents/SOCIAL_CONTENTS_LIST_RESPONSE';
export const SOCIAL_CONTENTS_LIST_FAILURE = 'socialContents/SOCIAL_CONTENTS_LIST_FAILURE';

// SOCIAL CONTENTS LIKE
export const SOCIAL_CONTENTS_LIKE_REQUEST = 'socialContents/SOCIAL_CONTENTS_LIKE_REQUEST';
export const SOCIAL_CONTENTS_LIKE_RESPONSE = 'socialContents/SOCIAL_CONTENTS_LIKE_RESPONSE';
export const SOCIAL_CONTENTS_LIKE_FAILURE = 'socialContents/SOCIAL_CONTENTS_LIKE_FAILURE';

// SOCIAL CONTENTS REUSE
export const SOCIAL_CONTENTS_REUSE_REQUEST = 'socialContents/SOCIAL_CONTENTS_REUSE_REQUEST';
export const SOCIAL_CONTENTS_REUSE_RESPONSE = 'socialContents/SOCIAL_CONTENTS_REUSE_RESPONSE';
export const SOCIAL_CONTENTS_REUSE_FAILURE = 'socialContents/SOCIAL_CONTENTS_REUSE_FAILURE';

// SOCIAL CONTENTS REPLY
export const SOCIAL_CONTENTS_REPLY_REQUEST = 'socialContents/SOCIAL_CONTENTS_REPLY_REQUEST';
export const SOCIAL_CONTENTS_REPLY_RESPONSE = 'socialContents/SOCIAL_CONTENTS_REPLY_RESPONSE';
export const SOCIAL_CONTENTS_REPLY_FAILURE = 'socialContents/SOCIAL_CONTENTS_REPLY_FAILURE';

// SOCIAL CONTENTS DELETE POST
export const SOCIAL_CONTENTS_DELETE_REQUEST = 'socialContents/SOCIAL_CONTENTS_DELETE_REQUEST';
export const SOCIAL_CONTENTS_DELETE_RESPONSE = 'socialContents/SOCIAL_CONTENTS_DELETE_RESPONSE';
export const SOCIAL_CONTENTS_DELETE_FAILURE = 'socialContents/SOCIAL_CONTENTS_DELETE_FAILURE';

// SOCIAL CONTENTS BRAND ACTIVE PLATFORMS
export const SOCIAL_CONTENTS_BRAND_ACTIVE_PLATFORMS = 'socialContents/SOCIAL_CONTENTS_BRAND_ACTIVE_PLATFORMS';

// SOCIAL CONTENTS STATS CALL
export const SOCIAL_CONTENTS_IS_STATS_CALL = 'socialContents/SOCIAL_CONTENTS_IS_STATS_CALL';

// SOCIAL CONTENTS RESET ALL
export const SOCIAL_CONTENTS_RESET_ALL = 'socialContents/SOCIAL_CONTENTS_RESET_ALL';
