import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AccountsProfileActions } from 'actions';
import { accountReducerTypes } from 'types';
import { ADD_POST_SAVE_CONTENT_DRAFT_SUCCESS_MSG } from 'utils/constants';

const initialState: accountReducerTypes.IAccountsReducer = {
  isProfileFetching: false,
  isNewProfileFetching: false,
  isAccountUpdating: false,
  isAccountActivateFetching: false,
  isScheduleSlotListFetching: false,
  isError: false,
  error: null,
  accountDetails: null,
  accSuccessMsg: null,
  permissions: {
    isPermissionFetching: false,
    franchiseePermissions: null,
    error: null
  },
  activateAccount: false,
  accScheduleSlotList: [],
  scheduledPostsStats: 0
};

const accountsReducer: Reducer<accountReducerTypes.IAccountsReducer, AccountsProfileActions> = (state: accountReducerTypes.IAccountsReducer = initialState, action: AccountsProfileActions) => {
  switch (action.type) {
    // UPDATE ACCOUNT PROFILE - TO DB
    case types.ACCOUNTS_UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isAccountUpdating: true,
        isError: false,
        error: null
      };
    case types.ACCOUNTS_UPDATE_PROFILE_RESPONSE:
      return {
        ...state,
        isAccountUpdating: false,
        accountDetails: state.accountDetails ? { ...state.accountDetails, account: action.payload } : null,
        isError: false,
        error: null
      };
    case types.ACCOUNTS_UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isAccountUpdating: false,
        isError: true,
        error: action.payload
      };

    // GET ACCOUNT PROFILE FROM DB
    case types.ACCOUNTS_GET_PROFILE_REQUEST:
      return {
        ...state,
        isProfileFetching: action.payload.isDataRetain ? false : true,
        isError: false,
        error: null,
        accountDetails: action.payload.isDataRetain ? state.accountDetails : null
        // socialMedia: null,
        // accountProfiles: null
      };
    case types.ACCOUNTS_GET_PROFILE_RESPONSE:
      return {
        ...state,
        isProfileFetching: false,
        isError: false,
        error: null,
        accountDetails: action.payload
        // socialMedia: action.payload.social_media,
        // accountProfiles: action.payload.account_profiles
      };
    case types.ACCOUNTS_GET_PROFILE_FAILURE:
      return {
        ...state,
        isProfileFetching: false,
        isError: true,
        error: action.payload,
        accountDetails: null
        // socialMedia: null,
        // accountProfiles: null
      };

    // ADD ACCOUNT PROFILE
    case types.ACCOUNTS_ADD_PROFILE_REQUEST:
      return {
        ...state,
        isNewProfileFetching: true
      };
    case types.ACCOUNTS_ADD_PROFILE_RESPONSE:
      return {
        ...state,
        isNewProfileFetching: false,
        accountDetails: state.accountDetails
          ? {
              ...state.accountDetails,
              account: action.payload.account
            }
          : null,
        accSuccessMsg: ADD_POST_SAVE_CONTENT_DRAFT_SUCCESS_MSG
      };
    case types.ACCOUNTS_ADD_PROFILE_FAILURE:
      return {
        ...state,
        isNewProfileFetching: false,
        accountDetails: null,
        accSuccessMsg: null,
        error: action.payload || 'Unexpected error'
      };
    // GET FRANCHISEE PERMISSION
    case types.GET_FRANCHISEE_PERMISSION_REQUEST:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isPermissionFetching: true
        }
      };
    case types.GET_FRANCHISEE_PERMISSION_RESPONSE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isPermissionFetching: false,
          franchiseePermissions: action.payload
        }
      };
    case types.GET_FRANCHISEE_PERMISSION_FAILURE:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isPermissionFetching: false,
          error: action.payload || 'Unexpected error'
        }
      };
    // ACCOUNT ACTIVATE
    case types.ACCOUNT_ACTIVATE_REQUEST:
      return {
        ...state,
        isAccountActivateFetching: true
      };
    case types.ACCOUNT_ACTIVATE_RESPONSE:
      return {
        ...state,
        isAccountActivateFetching: false,
        accountDetails: action.payload // initialState.accountDetails
      };
    case types.ACCOUNT_ACTIVATE_FAILURE:
      return {
        ...state,
        isAccountActivateFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.SET_ACCOUNT_ACTIVATE:
      return {
        ...state,
        activateAccount: action.payload
      };
    case types.ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case types.ACCOUNT_PROFILE_MESSAGES_RESET:
      return {
        ...state,
        accSuccessMsg: initialState.accSuccessMsg
      };
    case types.ACCOUNTS_PROFILE_RESET:
      return {
        ...initialState
      };
    case types.ACCOUNTS_GET_SCHEDULE_LIST_REQUEST: {
      return {
        ...state,
        accScheduleSlotList: [],
        isScheduleSlotListFetching: true
      };
    }
    case types.ACCOUNTS_GET_SCHEDULE_LIST_RESPONSE: {
      return {
        ...state,
        accScheduleSlotList: action.payload.sort((a, b) => a.id - b.id),
        isScheduleSlotListFetching: false
      };
    }
    case types.ACCOUNTS_GET_SCHEDULE_LIST_FAILURE: {
      return {
        ...state,
        accScheduleSlotList: [],
        isScheduleSlotListFetching: false,
        error: action.payload
      };
    }
    case types.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_RESPONSE: {
      const updatedSchedulerSlotList = [...state.accScheduleSlotList];
      const findPayloadListIndex = state.accScheduleSlotList.findIndex((list: any) => list.id === action.payload.id);
      if (findPayloadListIndex >= 0) {
        updatedSchedulerSlotList[findPayloadListIndex] = action.payload;
      } else {
        updatedSchedulerSlotList.push(action.payload);
      }
      return {
        ...state,
        accScheduleSlotList: updatedSchedulerSlotList
      };
    }
    case types.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    case types.ACCOUNTS_DELETE_SCHEDULE_LIST_REQUEST: {
      return {
        ...state
      };
    }
    case types.ACCOUNTS_DELETE_SCHEDULE_LIST_RESPONSE: {
      let updatedSchedulerSlotList = [...state.accScheduleSlotList];
      if (action.payload.list_id) {
        updatedSchedulerSlotList = updatedSchedulerSlotList.filter((dataItem) => dataItem.id !== action.payload.list_id);
      }
      return {
        ...state,
        accScheduleSlotList: updatedSchedulerSlotList
      };
    }
    case types.ACCOUNTS_DELETE_SCHEDULE_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    case types.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_REQUEST: {
      return {
        ...state,
        scheduledPostsStats: initialState.scheduledPostsStats
      };
    }
    case types.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_RESPONSE: {
      return {
        ...state,
        scheduledPostsStats: action.payload
      };
    }
    case types.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        scheduledPostsStats: initialState.scheduledPostsStats
      };
    }
    default:
      return state;
  }
};

export { accountsReducer };
