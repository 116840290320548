import { useEffect, useState } from 'react';
import { Alert, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { IStore, rpReducerTypes } from 'types';
import {
  cpEmailValidation,
  dateFormatByRequestedFormat,
  handleLabelDisplay,
  getDatePickerDateToLocal,
  compareCurrentDate,
  getCurrentDate,
  getMomentForDate,
  dateTimeObjectFormatter,
  getSaveAsDraftProgramDetails,
  convertProgramActionsArray,
  endDateLessThanStartDate,
  getNearest30thMinute,
  setHourMinuteSecondBasedOnType
} from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { rpEditProgramResendEmailRequest, rpRewardsModified, rpSetResendEmailModified, rpSetCreateProgramDataObj, rpCreateOrUpdateProgramRequest } from 'actions';
import {
  RP_EMAIL_TAB_FIELDS,
  DATE_TIME_FORMAT,
  Duration,
  RewardType,
  RP_PRGRM_ACTION_PRGRM,
  Stage,
  RP_SEND_NOTIFICATION_ARRAY,
  PRIMARY_LOGO_URL,
  EmailNotificationStatus,
  ICommonDateTypes,
  RP_DATEPICKER_DATE_TIME_FORMAT,
  RP_ACTIONS_TYPE,
  RP_TIME_PICKER_MERIDIAN,
  RP_USER_ACTION_TYPE,
  RP_PROGRAM_TYPE,
  COMMON_PAGE_TYPE,
  MERIDIAN_FORMAT,
  HOUR_FORMAT,
  MINUTE_FORMAT,
  FULL_HOUR_FORMAT,
  APP_TITLE
} from 'utils/constants';
import { CustomDatePicker } from 'widgets/DatePicker';
import { TextArea } from 'widgets/Text';

interface IProgramEmailTemplateProps {
  modalType: string;
}

export const EmailTemplate = ({ modalType }: IProgramEmailTemplateProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const { brandEmail, topLevelFranchisorId, topLevelFranchisor, brandImageUrl } = useSelector((state: IStore) => state.accountSwitcher);
  const { createProgramDataObj, promotionVaultList, currentAction, isRewardsModified, isResendEmailModified } = useSelector((state: IStore) => state.rewardPrograms);
  const { programDetails, programAction, emailObj, modifiedStatus } = createProgramDataObj;
  const { name, startDate, endDate, duration, rewardsType, recommendedType, emailNotificationLastModified } = programDetails;
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);

  const [currentButtonAction, setCurrentButtonAction] = useState('');
  const [isDateInvalid, setIsDateInvalid] = useState(false);

  const emailInvitationValue = `Congrats! You have the opportunity to participate in a ${topLevelFranchisor} employee rewards program `;

  const { setFieldValue, values, handleSubmit } = useFormik({
    initialValues: {
      ...emailObj,
      emailInvitationMessage: !emailObj?.emailInvitationMessage ? emailInvitationValue : emailObj?.emailInvitationMessage,
      rewardEmailLogoUrl: brandImageUrl || ''
    },
    validationSchema: cpEmailValidation,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (actionValues: rpReducerTypes.ICPEmailTemplateObj) => {
      if (Object.keys(actionValues).length) {
        submitEmailTemplate(currentButtonAction);
      }
    }
  });

  useEffect(() => {
    if (getCurrentDate() && typeof values.emailScheduleDate !== 'string' && values.emailScheduleDate) {
      setIsDateInvalid(!endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate));
    }
  }, [getCurrentDate(), values.emailScheduleDate]); // eslint-disable-line

  useEffect(() => {
    if (isRewardsModified && !isResendEmailModified) {
      dispatch(rpSetResendEmailModified(true));
    }
  }, [isRewardsModified]); // eslint-disable-line

  useEffect(() => {
    if (isDateInvalid) {
      setFieldValue(RP_EMAIL_TAB_FIELDS.EMAIL_SCHELUDE_DATE, getNearest30thMinute());
      setIsDateInvalid(false);
    }
  }, [isDateInvalid]); // eslint-disable-line

  useEffect(() => {
    if (currentButtonAction === RP_USER_ACTION_TYPE.RESEND) handleSubmit();
  }, [currentButtonAction]); // eslint-disable-line

  useEffect(() => {
    if (values && !disableEmailTemplateTab()) {
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          emailObj: { ...values },
          modifiedStatus: {
            ...modifiedStatus,
            emailTemplate: getProgramEmailTemplateValidation(values)
          }
        })
      );
    }
  }, [JSON.stringify(values), modifiedStatus.details, modifiedStatus.actions, modifiedStatus.locations]); // eslint-disable-line

  /** Get program email template validation */
  const getProgramEmailTemplateValidation = (data: rpReducerTypes.ICPEmailTemplateObj) => {
    if (!data.emailInvitationMessage) return false;
    else if (data.emailNotificationStatus === EmailNotificationStatus.SEND_LATER && !data.emailScheduleDate) return false;
    else return true;
  };

  const submitEmailTemplate = (action: string) => {
    if ([RP_USER_ACTION_TYPE.RESEND].includes(action) && id && userOwnership && activeUserId) {
      const type =
        programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM
          ? RP_PROGRAM_TYPE.RECCURING_PROGRAM
          : programDetails?.rewardsType === RewardType.NON_CASH_PRIZE
          ? RP_PROGRAM_TYPE.NON_CASH_PROGRAM
          : RP_PROGRAM_TYPE.DRAFT_PROGRAM;
      const programDetailData = getSaveAsDraftProgramDetails(
        currentAction,
        programDetails,
        id,
        userOwnership,
        topLevelFranchisorId,
        brandEmail,
        activeUserId,
        activeUserEmail || null,
        type,
        action,
        values
      );
      const programActionData = convertProgramActionsArray(programAction, promotionVaultList);
      const postProgramObj = {
        data: {
          programDetails: programDetailData,
          programAction: programActionData,
          programHuLocations: createProgramDataObj.programHuLocations
        },
        action: currentAction === RP_ACTIONS_TYPE.CREATE ? RP_ACTIONS_TYPE.CREATE : RP_ACTIONS_TYPE.EDIT
      };
      if (action === RP_USER_ACTION_TYPE.RESEND) {
        const resendInviteObj = {
          data: {
            programDetails: programDetailData,
            programAction: programActionData,
            programHuLocations: {
              franchisors: createProgramDataObj.programHuLocations.franchisors.map((datum) => `${datum.id}`) || [],
              accounts: createProgramDataObj.programHuLocations.accounts.map((datum) => `${datum.id}`) || [],
              accountLists: createProgramDataObj.programHuLocations.accountLists.map((datum) => `${datum.id}`) || [],
              selectedLocationCount: createProgramDataObj.programHuLocations.selectedLocationCount,
              isAllLocation: createProgramDataObj.programHuLocations.isAllLocation
            }
          },
          action: currentAction === RP_ACTIONS_TYPE.CREATE ? RP_ACTIONS_TYPE.CREATE : RP_ACTIONS_TYPE.EDIT
        };
        dispatch(rpEditProgramResendEmailRequest(resendInviteObj));
      } else {
        dispatch(rpCreateOrUpdateProgramRequest(postProgramObj));
      }
    }
  };

  const handleChange = (field: string, value: string | number | Date | null) => {
    if (field === RP_EMAIL_TAB_FIELDS.EMAIL_NOTIFICATION_STATUS && value && (value === EmailNotificationStatus.NEVER_SEND || value === EmailNotificationStatus.SEND_NOW)) {
      setFieldValue(RP_EMAIL_TAB_FIELDS.EMAIL_SCHELUDE_DATE, null);
    }
    setFieldValue(field, value);
    if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
      dispatch(rpRewardsModified(true));
    }
  };

  const disableEmailTemplateTab = () => {
    return !modifiedStatus.details || !modifiedStatus.actions || !modifiedStatus.locations ? true : false;
  };
  const handleTimePicker = (type: string, value: number) => {
    if (typeof values.emailScheduleDate !== 'string') {
      const updateTime = setHourMinuteSecondBasedOnType(values.emailScheduleDate, type, value || 0);
      handleChange(RP_EMAIL_TAB_FIELDS.EMAIL_SCHELUDE_DATE, updateTime);
    }
  };

  return (
    <>
      <div className={`email-template-wrp${disableEmailTemplateTab() ? ` opacity` : ``}`}>
        <Alert variant="primary">
          <Alert.Heading>Email Template</Alert.Heading>
        </Alert>
        {!disableEmailTemplateTab() ? (
          <div className="email-left-side-content">
            <div className="email-left-inner-content-wrp">
              <div className="email-left-grid">
                <div className="email-l-gr-se">
                  <div className="info-icon">
                    <ImageValidation imgUrl={values.rewardEmailLogoUrl || PRIMARY_LOGO_URL} customName="email-template-pic" />
                  </div>
                </div>
                <div className="email-r-gr-se">
                  <div className="info-icon">
                    <ImageValidation isImgValid defaultImg={`img2.png`} isNotSvgFormat customName="email-template-pic" />
                  </div>
                </div>
              </div>

              <div className="email-without-grid">
                <div className={`form-group`}>
                  <TextArea
                    name={'emailInvitationMessage'}
                    rows={2}
                    placeholder="No text entered"
                    id="exampleForm.ControlTextarea1"
                    className="form-control used"
                    autoComplete="off"
                    maxLength={300}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      handleChange(RP_EMAIL_TAB_FIELDS.EMAIL_INVITATION_MESSAGE, e.target.value);
                    }}
                    value={values.emailInvitationMessage}
                  />
                  <span className="fltlabels blue-text">Email Invitation</span>
                  <span className="des-count-text">
                    <span>{values.emailInvitationMessage.length}</span>/300
                  </span>
                </div>

                {/* reward program section */}
                <div className="rpw-rvp-item">
                  <span className="rpw-rvp-item-heads">Reward Program</span>
                  <div className="rp-sdc-value-item">
                    <span className="rpw-rvp-cnt">{name}</span>
                  </div>
                </div>

                {/* Valid Through */}
                <div className="rpw-rvp-item">
                  <span className="rpw-rvp-item-heads">Valid Through</span>
                  <div className="rp-sdc-value-item">
                    <div className="rp-dc-sedates">
                      <div className="rp-dc-startdatetime">
                        <span className="rp-dc-stdt-item">{dateFormatByRequestedFormat(startDate, DATE_TIME_FORMAT)}</span>
                      </div>
                      <span className="rp-sdc-sedates-arw" />
                      <div className="rp-dc-enddatetime">
                        <span className="rp-dc-stdt-item">{duration === Duration.RECURRING ? `Ongoing` : dateFormatByRequestedFormat(endDate, DATE_TIME_FORMAT)}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Program details  */}
                <div className="rpw-rvp-item">
                  <span className="rpw-rvp-item-heads">Program Details</span>
                  <div className="rp-sdc-value-item">
                    {programAction.map((item, idx: number) => {
                      return (
                        <div key={`email-temp-program-actions-${idx}`}>
                          <div className="rpw-rvp-cnt-gwraps rpw-pd-details">
                            <div className="summary-action-sequence">
                              <div className="rpw-rvp-cnt rpw-pd-sections">
                                {rewardsType === RewardType.CASH_PRIZE ? (
                                  <div>
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{`$${handleLabelDisplay(promotionVaultList, item.rewards, 'value', 'giftCost')}`}</span> gift card by submitting
                                        at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span> {item.actionItem.value === 1 ? 'picture' : 'pictures'}
                                        </span>{' '}
                                        through the {APP_TITLE} app
                                      </div>
                                    )}
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{`$${handleLabelDisplay(promotionVaultList, item.rewards, 'value', 'giftCost')}`}</span> gift card by having at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span>
                                        </span>{' '}
                                        engagements (likes, shares and comments) for the posts made through the {APP_TITLE} app
                                      </div>
                                    )}
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{`$${handleLabelDisplay(promotionVaultList, item.rewards, 'value', 'giftCost')}`}</span> gift card by performing
                                        at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span>
                                        </span>{' '}
                                        advocacy posts through the {APP_TITLE} app
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{item.nonCashRewards}</span> by submitting at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span> pictures
                                        </span>{' '}
                                        through the {APP_TITLE} app
                                      </div>
                                    )}
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{item.nonCashRewards}</span> by having at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span>
                                        </span>{' '}
                                        engagements (likes, shares and comments) for the posts made through the {APP_TITLE} app
                                      </div>
                                    )}
                                    {item.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS && (
                                      <div className="pd-consol-txt">
                                        Earn a <span className="rp-cv-star rsc-in-star">{item.nonCashRewards}</span> by performing at
                                        <span className="blue-text">
                                          {item.actionItem.operator === '>=' ? ' least ' : ''}
                                          <span className="pdc-counts">{item.actionItem.value}</span>
                                        </span>{' '}
                                        advocacy posts through the {APP_TITLE} app
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {programAction.length - 1 !== idx && <div className="separator">OR</div>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!disableEmailTemplateTab() ? (
        <div className="send-emailnotify-grid">
          <div className="email-notify-grid-section">
            <div className="left-section">Send Email Notification</div>

            {(currentAction === RP_ACTIONS_TYPE.EDIT || modalType === COMMON_PAGE_TYPE.DETAIL_VIEW) && emailNotificationLastModified ? (
              <div className="after-email-notify-update">
                <span className="ln-item ln-pre-txt">Last Notified @</span>
                <span className="ln-item ln-date">{emailNotificationLastModified ? dateTimeObjectFormatter(emailNotificationLastModified).dateFormat : null}</span>
                <span className="ln-item ln-time">{emailNotificationLastModified ? dateTimeObjectFormatter(emailNotificationLastModified).timeFormat : null}</span>
                <span
                  className={`ln-item ln-resendEmail${
                    programDetails?.typeCode === 5 ||
                    ((currentAction === RP_ACTIONS_TYPE.EDIT || modalType === COMMON_PAGE_TYPE.DETAIL_VIEW) && !isResendEmailModified && programDetails?.typeCode === 1)
                      ? ` events-none pointer-events-none`
                      : ``
                  }`}
                  onClick={() => setCurrentButtonAction(RP_USER_ACTION_TYPE.RESEND)}
                >
                  Re-Send
                </span>
              </div>
            ) : (
              <div className="right-section">
                {RP_SEND_NOTIFICATION_ARRAY.map((statusItem, idx: number) => {
                  return (
                    <label key={`reward-program-send-notify-${idx}`} className={values.emailNotificationStatus === statusItem.value ? 'active' : ''}>
                      <input
                        type="radio"
                        className="option-input radio"
                        value={statusItem.value}
                        checked={values.emailNotificationStatus === statusItem.value ? true : false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(RP_EMAIL_TAB_FIELDS.EMAIL_NOTIFICATION_STATUS, +event.target.value)}
                      />
                      <span className="labelText">{statusItem.label}</span>
                    </label>
                  );
                })}
              </div>
            )}
          </div>

          {(currentAction === RP_ACTIONS_TYPE.EDIT || modalType === COMMON_PAGE_TYPE.DETAIL_VIEW) && emailNotificationLastModified
            ? null
            : recommendedType === Stage.RECOMMENDED_PROGRAM && (
                <p className="label-txt">
                  <span className="mandatory">*</span> Emails will not be triggered for recommended programs
                </p>
              )}

          {values.emailNotificationStatus === EmailNotificationStatus.SEND_LATER && (
            <div className="email-notify-calendar-wrp react-datepicker-popper dtPicker">
              <CustomDatePicker
                name={RP_EMAIL_TAB_FIELDS.EMAIL_SCHELUDE_DATE}
                dateRangeType={ICommonDateTypes.FROM_DATE}
                selectedDate={values.emailScheduleDate ? getDatePickerDateToLocal(values.emailScheduleDate) : null}
                onChange={(date: Date | null) => {
                  handleChange(RP_EMAIL_TAB_FIELDS.EMAIL_SCHELUDE_DATE, date);
                }}
                inline
                dateFormat={RP_DATEPICKER_DATE_TIME_FORMAT || ''}
                minDate={getCurrentDate()}
                maxDate={null}
                minTime={compareCurrentDate(values.emailScheduleDate) ? getCurrentDate() : getMomentForDate(getCurrentDate()).hours(0).minutes(0).toDate()}
                maxTime={getMomentForDate(getCurrentDate()).hours(23).minutes(59).toDate()}
                customClassname={''}
                autoComplete="off"
              />
              <div className="cr-timePicker">
                <ButtonGroup className="crt-item crt-top btn-group-toggle">
                  <div className="tgb-item">
                    <ToggleButton id={`ToggleButton1`} type="radio" variant="secondary" name="radio" value={''} checked>
                      <input
                        type="text"
                        className="iText"
                        pattern="[0-9]+"
                        min={1}
                        max={12}
                        readOnly
                        value={dateFormatByRequestedFormat(values.emailScheduleDate, HOUR_FORMAT) || '--'}
                        autoComplete="off"
                      />
                      <div className="id-values">
                        <button
                          className={`idv-arws idva-inc${
                            typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate) ? ` pointer-events-none` : ``
                          }`}
                          onClick={() => handleTimePicker('hour', values?.emailScheduleDate ? parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, FULL_HOUR_FORMAT) || `${0}`) + 1 : 0)}
                        >
                          <ImageValidation isImgValid defaultImg={`arw-incrmnt-blue`} customName="arw-incrmnt-blue" />
                        </button>
                        <button
                          className={`idv-arws idva-dec${
                            typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate) ? ` pointer-events-none` : ``
                          }`}
                          onClick={() => handleTimePicker('hour', values?.emailScheduleDate ? parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, FULL_HOUR_FORMAT) || `${0}`) - 1 : 0)}
                        >
                          <ImageValidation isImgValid defaultImg={`arw-decrmnt-blue`} customName="arw-decrmnt-blue" />
                        </button>
                      </div>
                    </ToggleButton>
                  </div>

                  <div className="ctSep">
                    <ImageValidation isImgValid defaultImg={`ctSep`} customName="ctSep" />
                  </div>

                  <div className="tgb-item">
                    <ToggleButton id={`ToggleButton2`} type="radio" variant="secondary" name="radio" value={''}>
                      <input
                        type="text"
                        className="iText"
                        pattern="[0-9]+"
                        min={0}
                        max={59}
                        readOnly
                        value={dateFormatByRequestedFormat(values.emailScheduleDate, MINUTE_FORMAT) || '--'}
                        autoComplete="off"
                      />
                      <div className="id-values">
                        <button
                          className={`idv-arws idva-inc${
                            typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate) ? ` pointer-events-none` : ``
                          }`}
                          onClick={() => handleTimePicker('minute', values?.emailScheduleDate ? parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, MINUTE_FORMAT) || `${0}`) + 1 : 0)}
                        >
                          <ImageValidation isImgValid defaultImg={`arw-incrmnt-blue`} customName="arw-incrmnt-blue" />
                        </button>
                        <button
                          className={`idv-arws idva-dec${
                            typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate) ? ` pointer-events-none` : ``
                          }`}
                          onClick={() => handleTimePicker('minute', values?.emailScheduleDate ? parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, MINUTE_FORMAT) || `${0}`) - 1 : 0)}
                        >
                          <ImageValidation isImgValid defaultImg={`arw-decrmnt-blue`} customName="arw-decrmnt-blue" />
                        </button>
                      </div>
                    </ToggleButton>
                  </div>
                </ButtonGroup>
                <div className="crt-item crt-base">
                  <div className={`switch6 ${typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate) ? 'pointer-events-none' : ''}`}>
                    <label className="switch6-light">
                      <input type="checkbox" defaultChecked={RP_TIME_PICKER_MERIDIAN[1] === dateFormatByRequestedFormat(values.emailScheduleDate, MERIDIAN_FORMAT)} />
                      <span>
                        {RP_TIME_PICKER_MERIDIAN.map((datum, index) => {
                          return (
                            <span
                              key={`rp-time-meridian-${index}`}
                              className={
                                typeof values.emailScheduleDate !== 'string' && !endDateLessThanStartDate(getCurrentDate(), values.emailScheduleDate)
                                  ? 'pointer-events-none'
                                  : datum === dateFormatByRequestedFormat(values.emailScheduleDate, MERIDIAN_FORMAT)
                                  ? 'active'
                                  : 'ss'
                              }
                              onClick={() => {
                                if (datum !== dateFormatByRequestedFormat(values.emailScheduleDate, MERIDIAN_FORMAT)) {
                                  const updatedHour =
                                    index === 0
                                      ? parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, FULL_HOUR_FORMAT) || `${0}`) - 12
                                      : parseInt(dateFormatByRequestedFormat(values?.emailScheduleDate, FULL_HOUR_FORMAT) || `${0}`) + 12;
                                  handleTimePicker('hour', values?.emailScheduleDate ? updatedHour : 0);
                                }
                              }}
                            >
                              {datum}
                            </span>
                          );
                        })}
                      </span>
                      <button className="btn btn-primary" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
