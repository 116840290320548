import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { commonApiTypes } from 'types';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import { accountUpdateProfileRequest, exceptionHandlerRequest, franchisorGetOrUpdateProfileRequest, rssFeedsListsFailure, rssFeedsListsResponse } from 'actions';
import { ALERT_BOX_TYPES, RSS_FEEDS_EXCEPTION_ERROR, USER_OWNERSHIP } from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendRssFeedsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload?.url ? { url: payload?.url } : payload.userOwnership === USER_OWNERSHIP.ACCOUNT ? { account_id: payload.id } : { franchisor_id: payload.id };
    const response = yield apiCall({ headers, data, ...API.rssFeedsList });
    if (response.status === 200) {
      yield put(rssFeedsListsResponse(response.data));
      if (payload?.url && payload?.updatedData) {
        if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
          yield put(accountUpdateProfileRequest({ id: payload.id, profileData: payload.updatedData }));
        } else {
          yield put(franchisorGetOrUpdateProfileRequest({ id: payload.id, userOwnership: payload.userOwnership, editedContent: payload.updatedData }));
        }
      }
    } else {
      yield put(rssFeedsListsFailure(response.data.message));
      if (payload?.url) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.message);
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(RSS_FEEDS_EXCEPTION_ERROR));
  }
}

export function* takeRssFeedsRequest() {
  yield takeLatest(types.RSS_FEEDS_LIST_REQUEST, sendRssFeedsRequest);
}
