import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { MODAL_BOX_TYPES, RIPPLE_COLOR, CALENDAR_FILTER_INITIAL_VALUE, CALENDER_STATS } from 'utils/constants';
import { ModalPopup } from 'components/Common';
import { ICalendarDeleteModal } from 'types/calendar/calendar-container';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { calendarDeleteConfirmMsg } from 'utils/helpers';
import { calendarDeleteModalOption } from 'actions';

export const CalendarDeleteAlertModal = ({ isLocationUser = false, states, dispatchFunction }: ICalendarDeleteModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor({ ...CALENDAR_FILTER_INITIAL_VALUE });

  const onClose = () => {
    navigate({
      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${CALENDER_STATS.map((it) => it.value).includes(optionalParams[0]) ? `/${optionalParams[0]}` : ''}`,
      search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
    });
  };

  const handleModalClose = () => {
    dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: false }));
  };

  return (
    <ModalPopup
      isModalShow
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      // containerClassName={'modal-confirmation confirm-delete'}
      containerClassName={'cnfrm__delete--modal glbl__delete--modal'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{calendarDeleteConfirmMsg(states.data?.campaign ? 'campaign' : '')}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={handleModalClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass="ac-danger-box" custColor={RIPPLE_COLOR.danger}>
            <button
              className="ac-btn ac-danger ac-block"
              onClick={() => {
                if (isLocationUser) {
                  dispatchFunction(states.type, states.bool, states.data);
                } else {
                  dispatchFunction(states.data);
                }
                dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: true }));
                onClose();
              }}
            >
              Ok
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
