import { useSelector } from 'react-redux';

import { addPostSetEditConfirmForApprovedPost, addPostUpdateSavedPostDetails, resetAIContentCreator } from 'actions';
import { IStore, apAccountContainerTypes } from 'types';
import {
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  COMMON_SOCIAL_MEDIA_NAME,
  COMMON_SOCIAL_PROVIDER_TYPE,
  OVERVIEW_MON_CHRT_LABLES,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  STRING_WITH_PHONE_NUMBER
} from 'utils/constants';
import { capitalizeName, convertStringToEditorState, getContentListForDropdown } from 'utils/helpers';
import { CustomDropdown } from 'widgets/CustomDropdown';

export const RenderAddContentList = ({
  socialMedia,
  addContentList,
  savedPostDetails,
  editableSocialMedia,
  dispatch,
  setEditorState,
  textEditorState,
  setEditableSocialMedia
}: apAccountContainerTypes.IRenderAddContentProps) => {
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const createPostSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostSuggestions);

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const handleCopyFromMediaTextArea = (textKey: string, socialMediaKey: string) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      const limitedTextValue = savedPostDetails && savedPostDetails[socialMediaKey] ? savedPostDetails[socialMediaKey] : '';
      // old - const limitedTextValue = getContentTextWithMaximumLimit(savedPostDetails && savedPostDetails[socialMediaKey] ? savedPostDetails[socialMediaKey] : '', textKey);
      setEditorState({ ...textEditorState, [textKey]: convertStringToEditorState(limitedTextValue || '') });
      const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
      const editorGoogleText = regexData.test(savedPostDetails?.[`${textKey}_text`]);
      // dispatch(addPostUpdateCustomTextEditor({ key: textKey, value: convertStringToEditorState(limitedTextValue || '') }));
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                [`${textKey}_text`]: limitedTextValue,
                [`use_${textKey}`]:
                  savedPostDetails && !limitedTextValue && !selectedMediaList.length && !linkData
                    ? false
                    : textKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && !selectedMediaList.length
                    ? false
                    : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(textKey) && limitedTextValue.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[textKey.toUpperCase()]
                    ? false
                    : textKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                      (editorGoogleText || savedPostDetails?.[`${textKey}_text`].includes('[phone_number]') || savedPostDetails?.[`${textKey}_text`].includes('[phone]'))
                    ? false
                    : limitedTextValue
                    ? true
                    : false
              }
            : null
        )
      );
      setEditableSocialMedia({ name: '', isEdit: false });
    }
  };

  const handleAddNewTextArea = () => {
    if (createPostSuggestions?.length) {
      const data = { createPostSuggestions: [], createPostHashtagSuggestions: [] };
      dispatch(resetAIContentCreator(data));
    }
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      setEditableSocialMedia({ name: '', isEdit: true });
    }
  };

  return (
    <>
      {addContentList.length && !addContentList.includes(socialMedia) && editableSocialMedia.name === socialMedia && !editableSocialMedia.isEdit ? (
        <div className="social-section-copy-wrp">
          <div className={`at-textarea-overlay-updated${savedPostDetails?.locked ? ` pointer-events-none` : ``}`}>
            {addContentList.length === 1 ? (
              <div className="h-btn-o-updated copy-fb-btn-updated" onClick={() => handleCopyFromMediaTextArea(editableSocialMedia.name, `${addContentList[0]}_text`)}>
                <span>{`Add Content From ${
                  addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                    ? OVERVIEW_MON_CHRT_LABLES.LINKEDIN
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
                    ? COMMON_SOCIAL_MEDIA_NAME.GOOGLE_MY_BUSINESS
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                    ? COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM_BUSINESS
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                    ? COMMON_SOCIAL_MEDIA_NAME.X
                    : capitalizeName(addContentList[0])
                }`}</span>
              </div>
            ) : (
              <div className="desc-wrp flex-item-xl">
                <div className="flex-item-sm">
                  <div className="form-group form-field-item fg-dropdown m-align-items cs-item">
                    <CustomDropdown
                      classNamePrefix="cs-select"
                      id="dl-store-details-category-select"
                      placeholder={'Add Content From'}
                      options={getContentListForDropdown(addContentList)}
                      isSearchable={false}
                      onChange={(event: any) => handleCopyFromMediaTextArea(editableSocialMedia.name, `${event.value}_text`)}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="h-btn-o-updated" onClick={() => handleAddNewTextArea()}>
              <span>Add New Content</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
