import { ICAModalIDResponse, ICAMultipleLocationRequest, ICAMultipleLocationResponse, ICARequest, IContentAnalyticsFilter } from 'analytics/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

// CONTENT ANALYTICS - GET CONTENT ANALYTICS
export const getContentAnalyticsRequest = (payload: ICARequest) => createAction(actionTypes.GET_CONTENT_ANALYTICS_REQUEST, payload);
export const getContentAnalyticsResponse = (response: any) => createAction(actionTypes.GET_CONTENT_ANALYTICS_RESPONSE, response);
export const getContentAnalyticsFailure = (error: null | string) => createAction(actionTypes.GET_CONTENT_ANALYTICS_FAILURE, error);
export const setContentAnalyticsFilter = (payload: Partial<IContentAnalyticsFilter>) => createAction(actionTypes.SET_CONTENT_ANALYTICS_FILTER, payload);
export const getContentAnalyticsMultipleLocationsRequest = (payload: ICAMultipleLocationRequest) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_REQUEST, payload);
export const getContentAnalyticsMultipleLocationsResponse = (payload: ICAMultipleLocationResponse) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_RESPONSE, payload);
export const getContentAnalyticsMultipleLocationsFailure = (payload: string | null) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_FAILURE, payload);
export const getContentAnalyticsModelIdReq = (payload: { id: number }) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MODAL_ID_REQUEST, payload);
export const getContentAnalyticsModelIdResponse = (response: ICAModalIDResponse) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MODAL_ID_RESPONSE, response);
export const getContentAnalyticsModelIdFailure = (error: null | string) => createAction(actionTypes.GET_CONTENT_ANALYTICS_MODAL_ID_FAILURE, error);
export const contentAnalyticsDetailViewError = (error: null | string) => createAction(actionTypes.CONTENT_ANALYTICS_DETAIL_VIEW_ERROR, error);
export const contentAnalyticsIndividualImageResponse = (payload: any) => createAction(actionTypes.CONTENT_ANALYTICS_INDIVIDUAL_IMAGE_RESPONSE, payload);

export const resetContentState = () => createAction(actionTypes.RESET_CONTENT_STATE);

const CONTENT_ANALYTICS = {
  getContentAnalyticsRequest,
  getContentAnalyticsResponse,
  getContentAnalyticsFailure,
  setContentAnalyticsFilter,
  getContentAnalyticsMultipleLocationsRequest,
  getContentAnalyticsMultipleLocationsResponse,
  getContentAnalyticsMultipleLocationsFailure,
  getContentAnalyticsModelIdReq,
  getContentAnalyticsModelIdResponse,
  getContentAnalyticsModelIdFailure,
  contentAnalyticsDetailViewError,
  resetContentState,
  contentAnalyticsIndividualImageResponse
};

export type ContentAnalyticsActions = ActionsUnion<typeof CONTENT_ANALYTICS>;
