// SETTINGS PARTNER BANNER - GET PARTNER BANNERS CS LIST
export const GET_PARTNER_BANNERS_CS_LIST_REQUEST = 'partnerBanners/GET_PARTNER_BANNERS_CS_LIST_REQUEST';
export const GET_PARTNER_BANNERS_CS_LIST_RESPONSE = 'partnerBanners/GET_PARTNER_BANNERS_CS_LIST_RESPONSE';
export const GET_PARTNER_BANNERS_CS_LIST_FAILURE = 'partnerBanners/GET_PARTNER_BANNERS_CS_LIST_FAILURE';

// SETTINGS PARTNER BANNER - ENROLL PARTNER BANNERS
export const ENROLL_PARTNER_BANNERS_REQUEST = 'partnerBanners/ENROLL_PARTNER_BANNERS_REQUEST';
export const ENROLL_PARTNER_BANNERS_RESPONSE = 'partnerBanners/ENROLL_PARTNER_BANNERS_RESPONSE';
export const ENROLL_PARTNER_BANNERS_FAILURE = 'partnerBanners/ENROLL_PARTNER_BANNERS_FAILURE';

// SETTINGS PARTNER BANNER - GET PARTNER BANNER FRANCHISORS
export const GET_PARTNER_BANNER_FRANCHISORS_REQUEST = 'partnerBanners/GET_PARTNER_BANNER_FRANCHISORS_REQUEST';
export const GET_PARTNER_BANNER_FRANCHISORS_RESPONSE = 'partnerBanners/GET_PARTNER_BANNER_FRANCHISORS_RESPONSE';
export const GET_PARTNER_BANNER_FRANCHISORS_FAILURE = 'partnerBanners/GET_PARTNER_BANNER_FRANCHISORS_FAILURE';

// SETTINGS PARTNER BANNER - CREATE OR UPDATE PARTNER BANNER
export const CREATE_OR_UPDATE_PARTNER_BANNER_REQUEST = 'partnerBanners/CREATE_OR_UPDATE_PARTNER_BANNER_REQUEST';
export const CREATE_OR_UPDATE_PARTNER_BANNER_RESPONSE = 'partnerBanners/CREATE_OR_UPDATE_PARTNER_BANNER_RESPONSE';
export const CREATE_OR_UPDATE_PARTNER_BANNER_FAILURE = 'partnerBanners/CREATE_OR_UPDATE_PARTNER_BANNER_FAILURE';

// RESET PARTNER BANNER
export const PARTNER_BANNERS_RESET_ALL = 'partnerBanners/PARTNER_BANNERS_RESET_ALL';
