import { put, takeLatest } from 'redux-saga/effects';

import {
  agreedToReviewFailure,
  agreedToReviewResponse,
  averageRatingFailure,
  averageRatingResponse,
  sentimentTimeFailure,
  sentimentTimeResponse,
  surveyResultFailure,
  surveyResultResponse
} from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { ISagaPayload, SagaValue, SagaYield } from 'revv/types';
import { IAnalyticsRequestPayload, ISentimentTimeResponseIteration } from 'revv/types/analytics';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* surveyResultsRequestSaga(action: ISagaPayload<IAnalyticsRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.analyticsSurveyResult.apiPath,
      action: action.payload?.locationIds ? API_ROUTES.analyticsSurveyResultPost.method : API_ROUTES.analyticsSurveyResult.method,
      params: {
        'filter[from_date]': action.payload?.fromDate,
        'filter[to_date]': action.payload?.toDate
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 200) {
      yield put(surveyResultResponse(response.data.data));
    } else {
      yield put(surveyResultFailure('Error on Survey Results survey.'));
    }
  } catch (error: any) {
    yield put(surveyResultFailure(error));
  }
}

function* sentimentTimeRequestSaga(action: ISagaPayload<IAnalyticsRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.analyticsSentimentTime.apiPath,
      action: action.payload?.locationIds ? API_ROUTES.analyticsSentimentTimePost.method : API_ROUTES.analyticsSentimentTime.method,
      params: {
        'filter[from_date]': action.payload?.fromDate,
        'filter[to_date]': action.payload?.toDate
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_franchisior_ids: action.payload?.rallioFranchisorId
      },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 200) {
      const dataObj =
        response.data.data && response.data?.data?.length > 0
          ? response.data.data.map((it: ISentimentTimeResponseIteration) => ({
              label: it.label,
              negative: it.negative_rating,
              neutral: it.neutral_rating,
              positive: it.positive_rating
            }))
          : [];
      yield put(sentimentTimeResponse(dataObj));
    } else {
      yield put(sentimentTimeFailure('Error Occured on Sentiment Time Request.'));
    }
  } catch (error: any) {
    yield put(sentimentTimeFailure(error));
  }
}

function* averageRatingRequestSaga(action: ISagaPayload<IAnalyticsRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.analyticsSAvgRating.apiPath,
      action: action.payload?.locationIds ? API_ROUTES.analyticsSAvgRatingPost.method : API_ROUTES.analyticsSAvgRating.method,
      params: {
        'filter[from_date]': action.payload?.fromDate,
        'filter[to_date]': action.payload?.toDate
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_franchisior_ids: action.payload?.rallioFranchisorId
      },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 200) {
      yield put(averageRatingResponse(response.data.data));
    } else {
      yield put(averageRatingFailure('Error Occured on Average Rating Request.'));
    }
  } catch (error: any) {
    yield put(averageRatingFailure(error));
  }
}

function* agreedToReviewRequestSaga(action: ISagaPayload<IAnalyticsRequestPayload>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.agreedToReview.apiPath,
      action: action.payload?.locationIds ? API_ROUTES.agreedToReviewPost.method : API_ROUTES.agreedToReview.method,
      params: {
        'filter[from_date]': action.payload?.fromDate,
        'filter[to_date]': action.payload?.toDate
      },
      data: {
        location_ids: action.payload?.locationIds,
        rallio_franchisior_ids: action.payload?.rallioFranchisorId
      },
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status === 200) {
      yield put(agreedToReviewResponse(response.data.data));
    } else {
      yield put(agreedToReviewFailure('Error Occured on Average Rating Request.'));
    }
  } catch (error: any) {
    yield put(agreedToReviewFailure(error));
  }
}
export function* takeAnalyticsRequest() {
  yield takeLatest(actionTypes.SURVEY_RESULTS_REQUEST, surveyResultsRequestSaga);
  yield takeLatest(actionTypes.SENTIMENT_TIME_REQUEST, sentimentTimeRequestSaga);
  yield takeLatest(actionTypes.AVERAGE_RATING_REQUEST, averageRatingRequestSaga);
  yield takeLatest(actionTypes.AGREED_TO_REVIEW_REQUEST, agreedToReviewRequestSaga);
}
