import { combineReducers } from 'redux';

import { loginReducer } from './login';
import { IStoreRevv } from 'revv/types/store';
import { dashboardReducer } from './dashboard';
import { surveysReducer } from './surveys';
import { userReducer } from './user';
import { clientsReducer } from './clients';
import { settingsReducer } from './settings';
import { filterReducer } from './filter';
import { customerReducer } from './customers';
import { downloadReducer } from './download';
import { locationsReducer } from './locations';
import { analyticsReducer } from './analytics';

export const revvAppReducer = combineReducers<IStoreRevv>({
  login: loginReducer,
  user: userReducer,
  clients: clientsReducer,
  dashboard: dashboardReducer,
  surveys: surveysReducer,
  settings: settingsReducer,
  filter: filterReducer,
  customers: customerReducer,
  download: downloadReducer,
  locations: locationsReducer,
  analytics: analyticsReducer
});

const revvRootReducer = (state: IStoreRevv | undefined, action: any) => {
  if (action.type === 'users/SIGN_OUT') {
    state = undefined;
  }
  return revvAppReducer(state, action);
};

export { revvRootReducer };
