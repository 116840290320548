import { Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { useMemo, useState } from 'react';
import Close from '@mui/icons-material/Close';

import { OutsideClickDetector } from 'revv/components';

interface IOption {
  value: string | number | boolean;
  label: string;
  renderOptions?: (obj: IOption) => React.ReactNode;
}

interface IDropdownMenuProps {
  isActive: boolean;
  setActive: (flag: boolean) => void;
  options: IOption[];
  isFetching?: boolean;
  loadingMsg?: string;
  noDataMsg?: string;
  hideOnOutsideClick?: boolean;
  hideOnSelect?: boolean;
  excludeTargets?: Array<any>;
  searchable?: boolean;
  onSelect: (option: IOption) => void;
  selectedClass?: (option: IOption) => string;
  clearSearchOnSelect?: boolean;
}

export const DropdownMenu = ({
  isActive,
  setActive,
  options,
  isFetching,
  loadingMsg,
  noDataMsg,
  hideOnOutsideClick,
  hideOnSelect,
  excludeTargets,
  searchable,
  onSelect,
  selectedClass,
  clearSearchOnSelect
}: IDropdownMenuProps) => {
  const [search, setSearch] = useState<string>('');

  const filteredOptions = useMemo(() => {
    if (search) {
      return options.filter((it) => it.label.toLowerCase().includes(search.toLowerCase()));
    }
    return options;
  }, [options, search]);

  const renderDropdownMenu = (
    isActive: boolean,
    options: IOption[],
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    isFetching?: boolean,
    loadingMsg?: string,
    noDataMsg?: string,
    selectedClass?: (option: IOption) => string,
    clearSearchOnSelect?: boolean
  ) => (
    <Dropdown.Menu show={isActive} className="drp-shadow drp-rounded">
      {searchable && !isFetching ? (
        <InputGroup className="search-group">
          <FormControl className="dropdown-seach-control" type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
          {search ? <Close className="cur-pointer" onClick={() => setSearch('')} /> : null}
        </InputGroup>
      ) : null}
      <Scrollbars autoHeight autoHide>
        {isFetching ? (
          <Dropdown.Item>{loadingMsg || 'Loading...'}</Dropdown.Item>
        ) : options.length > 0 ? (
          options.map((it, index) => (
            <Dropdown.Item
              key={`option${it.value}${index}`}
              className={selectedClass ? selectedClass(it) : ''}
              onClick={() => {
                if (clearSearchOnSelect) {
                  setSearch('');
                }
                if (hideOnSelect) {
                  setActive(false);
                }
                onSelect(it);
              }}
            >
              {it.label}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item>{noDataMsg || 'No Options Found.'}</Dropdown.Item>
        )}
      </Scrollbars>
    </Dropdown.Menu>
  );

  return hideOnOutsideClick ? (
    <OutsideClickDetector
      active={isActive}
      handler={() => setActive(false)}
      renderWrapper={(ref: any, children: any) => (
        <div ref={ref} className="position-relative">
          {children}
        </div>
      )}
      excludeTargets={excludeTargets}
    >
      {renderDropdownMenu(isActive, filteredOptions, search, setSearch, isFetching, loadingMsg, noDataMsg, selectedClass, clearSearchOnSelect)}
    </OutsideClickDetector>
  ) : (
    <div className="position-relative">{renderDropdownMenu(isActive, filteredOptions, search, setSearch, isFetching, loadingMsg, noDataMsg, selectedClass, clearSearchOnSelect)}</div>
  );
};
