import { commonProps } from 'types';
import { INITIAL_LOCATION_FILTER_DATA } from 'utils/constants';

export enum FbAdsConnectionsType {
  LIKE_YOUR_PAGE = 'only_connected_to_page',
  DO_NOT_LIKE_YOUR_PAGE = 'only_not_connected_to_page',
  LIKE_YOUR_PAGE_AND_FRIENDS = 'only_connected_to_page_and_friends',
  ALL_CONNECTIONS = 'all_connections'
}

export const FB_ADS_TARGET_CONNECTIONS_LIST: commonProps.IMainPageRouteData[] = [
  { name: FbAdsConnectionsType.LIKE_YOUR_PAGE, label: 'People who like your Page' },
  { name: FbAdsConnectionsType.DO_NOT_LIKE_YOUR_PAGE, label: 'People who do not like your Page' },
  { name: FbAdsConnectionsType.LIKE_YOUR_PAGE_AND_FRIENDS, label: 'People who like your Page and their friends' },
  { name: FbAdsConnectionsType.ALL_CONNECTIONS, label: 'All of the above' }
];

export enum FbAdsGenderType {
  ALL = 'all',
  MALE = 'male',
  FEMALE = 'female'
}

export const FB_ADS_TARGET_GENDER_LIST: commonProps.IMainPageRouteData[] = [
  { name: FbAdsGenderType.ALL, label: 'All' },
  { name: FbAdsGenderType.MALE, label: 'Male' },
  { name: FbAdsGenderType.FEMALE, label: 'Female' }
];

export const FB_ADS_GOAL_LIST = [
  { value: 'messages', label: 'Get more messages', desc: 'Show your ad to people who are likely to send you a message on Facebook', className: 'messages', imgSrc: 'chat-msg' },
  { value: 'post_engagement', label: 'Get more engagement', desc: 'Show your ad to people who are likely to react, comment and share.', className: 'engagement', imgSrc: 'like-rd' },
  { value: 'link_clicks', label: 'Get more website visitors', desc: 'Show your ad to people who are likely to click on a URL in it.', className: 'visitors', imgSrc: 'visitors-r' }
];

export const INITIAL_TARGETING_LOCATION_FILTER_DATA = {
  ...INITIAL_LOCATION_FILTER_DATA,
  label: 'All Locations with facebook pages'
};

export const TARGETING_HEADERS = [
  {
    name: 'Location',
    key: 'location'
  },
  {
    name: 'Target Audience',
    key: 'targetAudience',
    colSpan: 4
  },
  {
    name: 'Totals',
    key: 'totals'
  }
];

export const SECONDARY_TARGETING_HEADERS = [
  {
    name: 'Location Name/ID',
    key: 'locationName'
  },
  {
    name: 'Location',
    key: 'location'
  },
  {
    name: 'Age',
    key: 'age'
  },
  {
    name: 'Gender',
    key: 'gender'
  },
  {
    name: 'Radius',
    key: 'radius'
  },
  {
    name: '',
    key: 'icon'
  }
];

export const CORPORATE_BOOST_POST_TYPE = [
  {
    id: 1,
    value: 'reach',
    label: 'Weight',
    tooltip: "When set to Weighted, each location will receive a spend proportional to the Daily Spend you chose for this account multiplied by the location's potential reach."
  },
  { id: 2, value: 'flat', label: 'Flat', tooltip: 'When set to Flat, your Lifetime Spend will be divided equally between locations.' }
];

export const AVERAGE_LOCATIONS_SPEND_NAMES = {
  LOCATION_NAME: 'account_name',
  DAILY_SPEND: 'daily_spend',
  LIFETIME_SPEND: 'lifetime_spend'
};

export const FB_ADS_AVG_LOC_SPEND_TABLE_HEADER: commonProps.ITableHeader[] = [
  {
    name: 'Location Name',
    key: 'account_name'
  },
  {
    name: 'Average Daily Spend',
    key: 'daily_spend'
  },
  {
    name: 'Average Total Spend',
    key: 'lifetime_spend'
  }
];

export enum ITargettingValidQueryParams {
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id',
  SEARCH = 'text'
}

export const TARGETING_TABLE_HEADER = [
  {
    value: 'name',
    label: 'Location Name/ID'
  },
  {
    label: 'Location',
    value: 'location'
  },
  {
    label: 'Age',
    value: 'age'
  },
  {
    label: 'Gender',
    value: 'gender'
  },
  {
    label: 'Radius',
    value: 'radius'
  },
  {
    label: 'Estimated Reach',
    value: 'estimated'
  },
  {
    label: '',
    value: 'icon'
  }
];

export const INVALID_STORE_LOCATION_CONTEXT = `Your Facebook page does not have a geographical location configured,
so we can't target ads around it. Please use the "US Zip Codes" feature instead to target specific zip codes.`;
