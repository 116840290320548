import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { logoutRequest, exceptionHandlerRequest, scheduleDemoContactEmailResponse, scheduleDemoContactEmailFailure, revvLoginResponse, revvLoginFailure, updateUserDetailRequest } from 'actions';
import { apiCall, defaultHeader, API, REVV_API } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { SCHEDULE_DEMO_CONTACT_EMAIL_FAILED_MESSAGE, SALES_EXCEPTION_ERROR, REVV_LOGIN_FAILED, API_TYPES } from 'utils/constants';
import { store } from 'store';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendScheduleDemoContactEmailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload;
    const response = yield apiCall({ headers, data, ...API.scheduleDemoContact });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(scheduleDemoContactEmailResponse(response?.data));
    } else {
      yield put(scheduleDemoContactEmailFailure(SCHEDULE_DEMO_CONTACT_EMAIL_FAILED_MESSAGE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SALES_EXCEPTION_ERROR));
  }
}

function* sendRevvLoginRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      user: {
        ...payload,
        connect_revv_sso: 1
      }
    };
    const response = yield apiCall({ headers, data, ...REVV_API.revvLogin, type: API_TYPES.REVV_SSO });
    if (response.status === 201) {
      yield put(revvLoginResponse(response.data));
      const activeUserId = store.getState().login.activeUser?.id;
      if (activeUserId) {
        yield put(
          updateUserDetailRequest({
            userId: activeUserId,
            reqPayload: {
              revv_reference: response.data
            }
          })
        );
      }
      if (payload.callback) payload.callback();
    } else {
      yield put(revvLoginFailure(response?.data?.error || REVV_LOGIN_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SALES_EXCEPTION_ERROR));
  }
}

export function* takeSalesRequest() {
  yield takeEvery(types.SCHEDULE_DEMO_CONTACT_EMAIL_REQUEST, sendScheduleDemoContactEmailRequest);
  yield takeEvery(types.REVV_LOGIN_REQUEST, sendRevvLoginRequest);
}
