import { socialMediaTotalTextCount } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM } from 'utils/constants';
import { apFranContainerTypes } from 'types';

export const CreatorTextCount = ({ socialMedia, currentTextCount }: apFranContainerTypes.ICreatorTextCountProps) => {
  return IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(socialMedia) || COMMON_SOCIAL_PROVIDER_TYPE.TWITTER === socialMedia ? (
    <p className="letter-count-text">
      <span className="l-count">{currentTextCount}</span>
      <span className="l-total">{`/${socialMediaTotalTextCount(socialMedia)}`}</span>
    </p>
  ) : null;
};
