import { useState, useEffect, useRef, Fragment } from 'react';
import { MapBoxProvider } from 'leaflet-geosearch';
import { Marker, Popup, TileLayer, useMap, ZoomControl, MapContainer, Circle } from 'react-leaflet';
import { Form } from 'react-bootstrap';
import Slider from 'rc-slider';
import { LatLngExpression, Map } from 'leaflet';

import { CustomDropdownMenu } from 'widgets/CustomDropdownMenu';
import { ImageValidation } from 'widgets/Media';
import { config } from 'config';
import { OutsideClickDetector } from 'widgets/CustomDropdownMenu/OutsideClickDetector';

interface IGeoLocation {
  x: number;
  y: number;
  label: string;
  bounds: number[][];
  raw: {
    id: number;
    place_id: number;
    licence: string;
    osm_type: string;
    osm_id: number;
    boundingbox: string[];
    lat: string;
    lon: string;
    display_name: string;
    class: string;
    type: string;
    importance: number;
    icon: string;
  };
}

interface ISelectedLocation {
  lat: number;
  lng: number;
  address: string;
  radiusInMiles: number;
}

interface ILeafletMapProps {
  enableSearch?: boolean;
  onChangeRadius?: (radiusInMiles: number) => void;
  onLocationSelection?: (selectedLocation: IGeoLocation) => void;
  onRemoveLocation?: (removedLocation: ISelectedLocation) => void;
  onLocationClick?: (locationObj: ISelectedLocation | null) => void;
  selectedLocation: ISelectedLocation | null;
  selectedLocationList: ISelectedLocation[];
  placeholder: string;
}

export const LeafletMap = ({ enableSearch, selectedLocation, placeholder, selectedLocationList, onChangeRadius, onLocationSelection, onRemoveLocation, onLocationClick }: ILeafletMapProps) => {
  const defaultZoomLevel = 10;

  const [search, setSearch] = useState<string>('');
  const [geoLocations, setGeoLocations] = useState<IGeoLocation[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>();
  const [active, setActive] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  const provider = useRef(new MapBoxProvider());
  const targetRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const debounceTimeout = useRef<any>(null);
  const mapInstance = useRef<Map | null>(null);
  const mapKey = useRef<number | null>(null);
  const defaultLocation = [33.653191, -117.745903];

  const getInitialCenterCoordinates: any = (selectedLocation: ISelectedLocation | null, selectedLocationList: ISelectedLocation[]) => {
    if (selectedLocation) {
      return [selectedLocation?.lat, selectedLocation?.lng];
    } else if (selectedLocationList?.length > 0) {
      return [selectedLocationList[0]?.lat, selectedLocationList[0]?.lng];
    }
    return defaultLocation;
  };

  const getSliderMarks = (selectedLocation: ISelectedLocation | null, selectedLocationList: ISelectedLocation[]) => {
    const rangeObj = (min: number, max: number) => {
      return {
        [max]: { label: <span className="rsmark-txt">{max}</span> }
      };
    };

    const activeLocation = selectedLocationList?.find((it) => it.lat === selectedLocation?.lat && it.lng === selectedLocation?.lng);
    if (activeLocation) {
      return rangeObj(10, activeLocation?.radiusInMiles);
    }
  };

  const ChangeMapView = ({ coords }: { coords: LatLngExpression }) => {
    const map = useMap();
    map.flyTo(coords, map.getZoom());
    return null;
  };

  useEffect(() => {
    const leafletAttribution = document.getElementsByClassName('leaflet-control-attribution');
    if (leafletAttribution?.length > 0) {
      leafletAttribution[0].setAttribute('style', 'display: none');
    }
    mapKey.current = new Date().getTime();
    // return () => {
    //   if (mapInstance?.current) {
    //     mapInstance?.current?.off();
    //     mapInstance?.current?.remove();
    //   }
    // };
  }, []); // eslint-disable-line

  useEffect(() => {
    const loadGeoLocations = async (search: string) => {
      if (search && search.length > 0) {
        setLocationLoading(true);
        provider.current.endpoint = ({ query }) => {
          return `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${config.mapBoxToken}`;
        };
        const results = await provider.current.search({ query: search });
        return results;
      }
      return [];
    };
    debounceTimeout.current = setTimeout(() => {
      loadGeoLocations(search).then((resultLocations: any) => {
        setGeoLocations(resultLocations);
        setLocationLoading(false);
      });
    }, 300);
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [search]);

  const renderMarkerWithCircle = (location: ISelectedLocation) => (
    <Fragment key={location.address}>
      <Marker position={[location.lat, location.lng]}>
        <Popup>{location.address}</Popup>
      </Marker>
      <Circle center={[location.lat, location.lng]} radius={location.radiusInMiles / 0.00062} pathOptions={{ color: 'purple' }} />
    </Fragment>
  );

  const renderLocationRadius = (
    location: ISelectedLocation,
    selectedLocation: ISelectedLocation | null,
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    onChangeRadius?: (radius: number) => void
  ) => (
    <div
      className="cust-tag cu-pointer"
      key={location.address}
      onClick={(e) => {
        e.preventDefault();
        if (onLocationClick) {
          onLocationClick(location);
        }
      }}
    >
      <div className="r-flx r-flx-ac">
        <div className="loc__info--icon">
          <ImageValidation isImgValid defaultImg="location-pr" customName="location" />
        </div>
        <span className="loc" title={location.address}>
          {location.address}{' '}
        </span>
        <span className="plus">+</span>
        <div
          className={`miles-dropdown ${false ? 'actvie' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            if (onLocationClick) {
              if (location.lat === selectedLocation?.lat && location.lng === selectedLocation?.lng) {
                setOpen(!isOpen);
              } else {
                onLocationClick(location);
                setOpen(true);
              }
            }
          }}
          ref={(ele) => {
            dropdownRef.current[location?.address] = ele;
          }}
        >
          <span className="lbl">
            {location.radiusInMiles} mile{location.radiusInMiles > 1 ? 's' : ''}
          </span>
        </div>
      </div>
      <button>
        <ImageValidation
          isImgValid
          defaultImg="remove-red"
          customName="remove"
          customClassname="remove"
          onClick={(e) => {
            e.stopPropagation();
            if (onRemoveLocation) {
              const listWithRemovedLocation = selectedLocationList.filter((it) => it.lat !== location.lat && it.lng !== location.lng);
              if (onLocationClick) {
                if (listWithRemovedLocation?.length > 0) {
                  onLocationClick(listWithRemovedLocation[listWithRemovedLocation?.length - 1]);
                } else {
                  onLocationClick(null);
                }
              }
              onRemoveLocation(location);
            }
          }}
        />
      </button>
      {location?.lat === selectedLocation?.lat && location?.lng === selectedLocation?.lng && isOpen ? (
        <OutsideClickDetector
          active={isOpen}
          handler={() => setOpen(false)}
          renderWrapper={(ref: any, children: any) => (
            <div className="miles-drpdown--list" style={{ zIndex: 9999 }} ref={ref}>
              {children}
            </div>
          )}
          excludeTargets={[dropdownRef.current[location?.address]]}
        >
          <>
            <span className="l-lbl">Cities within radius</span>
            <div className="wbf-inputs r-mt2">
              <Slider
                min={1}
                max={50}
                value={location.radiusInMiles}
                marks={getSliderMarks(selectedLocation, selectedLocationList)}
                onChange={(value: any) => {
                  if (onChangeRadius) {
                    onChangeRadius(value);
                  }
                }}
              />
            </div>
          </>
        </OutsideClickDetector>
      ) : null}
    </div>
  );

  return (
    <>
      {enableSearch ? (
        <div ref={targetRef} className="loc-selector--wrp">
          <Form.Control
            type="text"
            placeholder={placeholder || 'Search'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onFocus={() => {
              setActive(true);
              setOpen(false);
            }}
          />
          <CustomDropdownMenu
            targetRef={targetRef}
            showMenu={active}
            setActive={setActive}
            options={geoLocations.map((it) => ({ value: it.raw.place_id || it.raw.id, name: it.label }))}
            isLoading={locationLoading}
            onSelect={(value: number) => {
              setActive(false);
              const locationObj = geoLocations.find((it) => it.raw.place_id === value || it.raw.id === value);
              if (locationObj) {
                if (onLocationSelection) {
                  onLocationSelection(locationObj);
                }
              }
            }}
            placeholder="Search Locations"
          />
          {selectedLocationList.map((it) => renderLocationRadius(it, selectedLocation, isOpen, setOpen, onChangeRadius))}
        </div>
      ) : null}
      <div className="map--wrp">
        {mapKey?.current && (
          <MapContainer
            center={getInitialCenterCoordinates(selectedLocation, selectedLocationList)}
            zoom={defaultZoomLevel}
            scrollWheelZoom={false}
            style={{ height: '100%' }}
            zoomControl={false}
            id="map1"
            key={new Date().getTime()}
            whenCreated={(map: Map) => {
              mapInstance.current = map;
            }}
          >
            <ZoomControl position="bottomright" />
            <TileLayer attribution="" url={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}?access_token=${config.mapBoxToken}`} />
            {selectedLocationList.map((it) => renderMarkerWithCircle(it))}
            {selectedLocation ? (
              <ChangeMapView coords={[selectedLocation.lat, selectedLocation.lng]} key={selectedLocation.address} />
            ) : selectedLocationList?.length > 0 ? (
              <ChangeMapView coords={[selectedLocationList[0].lat, selectedLocationList[0].lng]} key={selectedLocationList[0].address} />
            ) : (
              <ChangeMapView coords={defaultLocation as any} key="Rallio" />
            )}
          </MapContainer>
        )}
      </div>
    </>
  );
};
