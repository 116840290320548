import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { loginContainerType, salesReducerTypes } from 'types';

export const salesUpdateQueryField = (payload: salesReducerTypes.ISalesQueryObj) => createAction(actionTypes.SALES_UPDATE_QUERY_FIELDS, payload);

// SALES SCHEDULE DEMO CONTACT EMAIL
export const scheduleDemoContactEmailRequest = (payload: salesReducerTypes.ISalesQueryObj) => createAction(actionTypes.SCHEDULE_DEMO_CONTACT_EMAIL_REQUEST, payload);
export const scheduleDemoContactEmailResponse = (data: null | string) => createAction(actionTypes.SCHEDULE_DEMO_CONTACT_EMAIL_RESPONSE, data);
export const scheduleDemoContactEmailFailure = (error: null | string) => createAction(actionTypes.SCHEDULE_DEMO_CONTACT_EMAIL_FAILURE, error);

// REVV LOGIN REQUEST
export const revvLoginRequest = (payload: loginContainerType.ILoginUserInfo) => createAction(actionTypes.REVV_LOGIN_REQUEST, payload);
export const revvLoginResponse = (data: salesReducerTypes.IRevvLoginResponse) => createAction(actionTypes.REVV_LOGIN_RESPONSE, data);
export const revvLoginFailure = (error: null | string) => createAction(actionTypes.REVV_LOGIN_FAILURE, error);

// SCHEDULE_DEMO_CONTACT_EMAIL RESET MESSAGE
export const scheduleDemoContactEmailMessageReset = () => createAction(actionTypes.SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE_RESET);

export const salesIsDemoModalOpen = (payload: boolean) => createAction(actionTypes.SALES_IS_DEMO_MODAL_OPEN, payload);

const SALES_ACTIONS = {
  salesUpdateQueryField,
  salesIsDemoModalOpen,
  scheduleDemoContactEmailRequest,
  scheduleDemoContactEmailResponse,
  scheduleDemoContactEmailFailure,
  scheduleDemoContactEmailMessageReset,
  revvLoginRequest,
  revvLoginResponse,
  revvLoginFailure
};

export type SalesAction = ActionsUnion<typeof SALES_ACTIONS>;
