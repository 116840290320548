import { CustomTextEditor } from 'widgets/Text';
import { apFranContainerTypes } from 'types';

export const CreatorTextEditor = ({
  currentSocialMediaTab,
  emojiKey,
  onEditorClick,
  isEmojiListOpen,
  onEmojiClick,
  editorState,
  onEditorChange,
  isMentionEnabled,
  suggestionList,
  onAddSuggestionTag,
  onSuggestionSearchChange,
  className,
  emojiClassName,
  disableEditor,
  disableEmoji,
  isShowAiButton = true,
  renderExtra,
  handleAiClick,
  isAiButtonDisable,
  isCoachMark
}: apFranContainerTypes.ICreatorTextEditorProps) => {
  return (
    <div className="at-textarea-holder">
      <CustomTextEditor
        className={className}
        emojiClassName={emojiClassName}
        emojiKey={emojiKey}
        onEditorClick={onEditorClick}
        isEmojiListOpen={isEmojiListOpen}
        onEmojiClick={onEmojiClick}
        editorState={editorState}
        onEditorChange={onEditorChange}
        isMentionEnabled={isMentionEnabled}
        suggestionList={suggestionList}
        onAddSuggestionTag={onAddSuggestionTag}
        onSuggestionSearchChange={onSuggestionSearchChange}
        disableEditor={disableEditor}
        disableEmoji={disableEmoji}
        placeholder={isShowAiButton ? 'If you are creating an AI post, click the AI assistant button below. Otherwise, type your caption here...' : 'Type your post caption here...'}
        // handleBeforeInput={() => handleBeforeInput(editorState, currentSocialMediaTab || '')}
        // handlePastedText={(pastedText) => handlePastedText(pastedText, editorState, currentSocialMediaTab || '')}
        renderExtra={renderExtra}
        isShowAiButton={isShowAiButton}
        handleAiClick={handleAiClick}
        isAiButtonDisable={isAiButtonDisable}
        isSecondaryAiButton={false}
        isCoachMark={isCoachMark}
        coachMarkType="caption"
        isAddToYourPostEmoji // RAS-4013 - For Emoji button changes in creator page
      />
    </div>
  );
};
