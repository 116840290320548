import { IOverviewAnalyticsFilter } from 'analytics/types';
import { COMMON_SOCIAL_PROVIDER_TYPE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';
import { dateHelperForMonth } from '../../helpers';
import { ANALYTICS_PLATFORM_CHART_OPTIONS, PLATFORM_FILTER } from '../common';
import { ContentEngagement } from '../content-analytics';
import { OverviewAnalyticsGraphFillColors, OverviewAnalyticsGraphFadeColors, OverviewAnalyticsGraphColors } from '../../theme/rallio';

export enum OverviewStatsValue {
  FOLLOWERS = 'followers',
  POST_ENGAGEMENT = 'engagement',
  PUBLISHED_POSTS = 'posts'
}

export enum OverviewStatsTitle {
  FOLLOWERS = 'Followers',
  POST_ENGAGEMENT = 'Post Engagement',
  PUBLISHED_POSTS = 'Published Posts',
  POTENTIAL_REACH = 'Potential Reach'
}

export const OVERVIEW_STATS = [
  {
    title: OverviewStatsTitle.FOLLOWERS,
    value: OverviewStatsValue.FOLLOWERS,
    header: 'Following',
    class: 'follower-txt',
    colorKey: 'FOLLOWERS',
    color: OverviewAnalyticsGraphColors.FOLLOWERS,
    fillColor: OverviewAnalyticsGraphFillColors.FOLLOWERS,
    fadeColor: OverviewAnalyticsGraphFadeColors.FOLLOWERS
  },
  {
    title: OverviewStatsTitle.POST_ENGAGEMENT,
    value: OverviewStatsValue.POST_ENGAGEMENT,
    header: 'Avg Daily Post Engagement',
    class: 'post-e-txt',
    colorKey: 'POST_ENGAGEMENT',
    color: OverviewAnalyticsGraphColors.POST_ENGAGEMENT,
    fillColor: OverviewAnalyticsGraphFillColors.POST_ENGAGEMENT,
    fadeColor: OverviewAnalyticsGraphFadeColors.POST_ENGAGEMENT
  },
  {
    title: OverviewStatsTitle.PUBLISHED_POSTS,
    value: OverviewStatsValue.PUBLISHED_POSTS,
    header: 'Published Posts',
    class: 'published-p-txt',
    colorKey: 'PUBLISHED_POSTS',
    color: OverviewAnalyticsGraphColors.PUBLISHED_POSTS,
    fillColor: OverviewAnalyticsGraphFillColors.PUBLISHED_POSTS,
    fadeColor: OverviewAnalyticsGraphFadeColors.PUBLISHED_POSTS
  }
];

export const OVERVIEW_PIE_LEGEND = [
  { image: 'fb-lv.svg', class: 'txt-fb', name: 'Facebook', value: 'facebook' },
  { image: 'insta-t.svg', class: 'txt-ig', name: 'Instagram', value: 'instagram' },
  { image: 'twtr-t.svg', class: 'txt-tw', name: 'X (Formerly Twitter)', value: 'twitter' },
  { image: 'in-t.svg', class: 'txt-li', name: 'LinkedIn', value: 'linkedin' }
];

export enum OverviewTableValue {
  TOTAL_PLATFORM = 'platform',
  FOLLOWERS = 'followers',
  POST_ENGAGEMENT = 'engagement',
  PUBLISHED_POSTS = 'posts'
}

export enum OverviewTableTotalValueDelta {
  TOTAL_PLATFORM = 'platform_delta',
  FOLLOWERS = 'followers_delta',
  POST_ENGAGEMENT = 'engagement_delta',
  PUBLISHED_POSTS = 'posts_delta'
}

export const OVERVIEW_TABLE_HEADER = [
  {
    title: 'Platform',
    value: OverviewTableValue.TOTAL_PLATFORM,
    totalValue: 'total_platform',
    totalValueDelta: OverviewTableTotalValueDelta.TOTAL_PLATFORM
  },
  {
    title: 'Followers',
    value: OverviewTableValue.FOLLOWERS,
    totalValue: 'total_followers',
    totalValueDelta: OverviewTableTotalValueDelta.FOLLOWERS
  },
  {
    title: 'Avg Daily Post Engagement',
    value: OverviewTableValue.POST_ENGAGEMENT,
    totalValue: 'total_engagement',
    totalValueDelta: OverviewTableTotalValueDelta.POST_ENGAGEMENT
  },
  {
    title: 'Published Posts',
    value: OverviewTableValue.PUBLISHED_POSTS,
    totalValue: 'total_posts',
    totalValueDelta: OverviewTableTotalValueDelta.PUBLISHED_POSTS
  }
];

export const OVERVIEW_PLATFORM_FILTER = PLATFORM_FILTER.slice(0, 5);

export const OVERVIEW_ANALYTICS_FILTER_INITIAL_VALUE: IOverviewAnalyticsFilter = {
  dateRange: dateHelperForMonth(true),
  platform: COMMON_SOCIAL_PROVIDER_TYPE.ALL,
  engagement: ContentEngagement.ALL,
  locationId: null,
  locationListId: null,
  ancestorFranchisorId: null,
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ
};

export const OVERVIEW_PLATFORM_CHART_OPTIONS = ANALYTICS_PLATFORM_CHART_OPTIONS.slice(0, 5);
