import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AiImageGenerationActions } from 'actions/modules/ai-image-generation';
import { aiImageGenertaionReducerTypes } from 'types';

type IAIImageGenerationReducer = aiImageGenertaionReducerTypes.IAIImageGenerationReducerType;

const initialState: aiImageGenertaionReducerTypes.IAIImageGenerationReducerType = {
  error: null,
  isAIImageFetching: false,
  aiImageGenerationReqPayload: null,
  aiGeneratedImages: [],
  aiStableDiffusionImage: { aiGeneratedImages: [], automatic_task: null }
};

const aiImageGenerationReducer: Reducer<IAIImageGenerationReducer, AiImageGenerationActions> = (
  state: IAIImageGenerationReducer = initialState,
  action: AiImageGenerationActions
): IAIImageGenerationReducer => {
  switch (action.type) {
    case types.AI_IMAGE_GENERATION_FIELD_UPDATE_RESPONSE:
      return {
        ...state,
        aiImageGenerationReqPayload: action.payload
      };

    case types.AI_IMAGE_GENERATION_REQUEST:
      return {
        ...state,
        isAIImageFetching: true,
        aiGeneratedImages: []
      };

    case types.AI_IMAGE_GENERATION_RESPONSE:
      return {
        ...state,
        isAIImageFetching: false,
        aiGeneratedImages: action.payload,
        error: null
      };

    case types.AI_IMAGE_GENERATION_FAILURE:
      return {
        ...state,
        isAIImageFetching: false,
        aiGeneratedImages: [],
        error: action.payload
      };

    case types.AI_IMAGE_GENERATION_RESET:
      return {
        ...state,
        error: null,
        isAIImageFetching: false,
        aiImageGenerationReqPayload: null,
        aiGeneratedImages: []
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST:
      return {
        ...state,
        isAIImageFetching: true,
        aiGeneratedImages: [],
        aiStableDiffusionImage: {
          aiGeneratedImages: [],
          automatic_task: state.aiStableDiffusionImage?.automatic_task || null
        }
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE:
      return {
        ...state,
        isAIImageFetching: true,
        aiStableDiffusionImage: action.payload,
        error: null
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE:
      return {
        ...state,
        isAIImageFetching: false,
        aiGeneratedImages: [],
        error: action.payload
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID:
      return {
        ...state,
        isAIImageFetching: true,
        aiGeneratedImages: []
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE_BY_ID:
      const stableDiffusionImage = [
        {
          id: action.payload?.automatic_task?.id!,
          b64_json: action.payload?.automatic_task?.photo_url || ''
        }
      ];
      return {
        ...state,
        isAIImageFetching: true,
        aiStableDiffusionImage: action.payload,
        aiGeneratedImages: stableDiffusionImage,
        error: null
      };

    case types.AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID_RESET:
      return {
        ...state,
        isAIImageFetching: false
      };

    default:
      return state;
  }
};

export { aiImageGenerationReducer };
