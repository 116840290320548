import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { scApiTypes } from 'types';

// SOCIAL CONTENTS STATS
export const socialContentsStatsRequest = (payload: scApiTypes.ISCStatsReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_STATS_REQUEST, payload);
export const socialContentsStatsResponse = (data: scApiTypes.ISCStatsResponse) => createAction(actionTypes.SOCIAL_CONTENTS_STATS_RESPONSE, data);
export const socialContentsStatsFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_STATS_FAILURE, error);

// SOCIAL CONTENTS PIE RELOAD
export const socialContentsPieReloadRequest = (payload: scApiTypes.ISCPieReloadReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_PIE_RELOAD_REQUEST, payload);
export const socialContentsPieReloadResponse = (payload: boolean) => createAction(actionTypes.SOCIAL_CONTENTS_PIE_RELOAD_RESPONSE, payload);
export const socialContentsPieReloadFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_PIE_RELOAD_FAILURE, error);

// SOCIAL CONTENTS LIST
export const socialContentsListRequest = (payload: scApiTypes.ISCListReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_LIST_REQUEST, payload);
export const socialContentsListResponse = (data: scApiTypes.ISCListResponse) => createAction(actionTypes.SOCIAL_CONTENTS_LIST_RESPONSE, data);
export const socialContentsListFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_LIST_FAILURE, error);

// SOCIAL CONTENTS LIKE
export const socialContentsLikeRequest = (payload: scApiTypes.ISCLikeReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_LIKE_REQUEST, payload);
export const socialContentsLikeResponse = (data: scApiTypes.ISCLikeResponse) => createAction(actionTypes.SOCIAL_CONTENTS_LIKE_RESPONSE, data);
export const socialContentsLikeFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_LIKE_FAILURE, error);

// SOCIAL CONTENTS REUSE
export const socialContentsReuseRequest = (payload: scApiTypes.ISCReuseReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_REUSE_REQUEST, payload);
export const socialContentsReuseResponse = (data: number) => createAction(actionTypes.SOCIAL_CONTENTS_REUSE_RESPONSE, data);
export const socialContentsReuseFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_REUSE_FAILURE, error);

// SOCIAL CONTENTS REPLY
export const socialContentsReplyRequest = (payload: scApiTypes.ISCReplyReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_REPLY_REQUEST, payload);
export const socialContentsReplyResponse = (data: scApiTypes.ISCReplyResponse) => createAction(actionTypes.SOCIAL_CONTENTS_REPLY_RESPONSE, data);
export const socialContentsReplyFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_REPLY_FAILURE, error);

// SOCIAL CONTENTS DELETE POST
export const socialContentsDeleteRequest = (payload: scApiTypes.ISCDeleteReqPayload) => createAction(actionTypes.SOCIAL_CONTENTS_DELETE_REQUEST, payload);
export const socialContentsDeleteResponse = (data: scApiTypes.ISCDeleteResponse) => createAction(actionTypes.SOCIAL_CONTENTS_DELETE_RESPONSE, data);
export const socialContentsDeleteFailure = (error: null | string) => createAction(actionTypes.SOCIAL_CONTENTS_DELETE_FAILURE, error);

// SOCIAL CONTENTS BRAND ACTIVE PLATFORMS
export const socialContentsBrandActivePlatforms = (payload: string[]) => createAction(actionTypes.SOCIAL_CONTENTS_BRAND_ACTIVE_PLATFORMS, payload);

// SOCIAL CONTENTS STATS CALL
export const socialContentsIsStatsCall = (payload: boolean) => createAction(actionTypes.SOCIAL_CONTENTS_IS_STATS_CALL, payload);

// SOCIAL CONTENTS RESET ALL
export const socialContentsResetAll = () => createAction(actionTypes.SOCIAL_CONTENTS_RESET_ALL);

const SOCIAL_CONTENT_ACTIONS = {
  socialContentsStatsRequest,
  socialContentsStatsResponse,
  socialContentsStatsFailure,
  socialContentsPieReloadRequest,
  socialContentsPieReloadResponse,
  socialContentsPieReloadFailure,
  socialContentsListRequest,
  socialContentsListResponse,
  socialContentsListFailure,
  socialContentsLikeRequest,
  socialContentsLikeResponse,
  socialContentsLikeFailure,
  socialContentsReuseRequest,
  socialContentsReuseResponse,
  socialContentsReuseFailure,
  socialContentsReplyFailure,
  socialContentsReplyRequest,
  socialContentsReplyResponse,
  socialContentsDeleteRequest,
  socialContentsDeleteResponse,
  socialContentsDeleteFailure,
  socialContentsBrandActivePlatforms,
  socialContentsIsStatsCall,
  socialContentsResetAll
};

export type SocialContentsAction = ActionsUnion<typeof SOCIAL_CONTENT_ACTIONS>;
