import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AdminAreaActions } from 'actions';
import { adminAreaReducerTypes, loginReducerType, franchisorApiTypes } from 'types';
import { DONE_SUCCESS_MSG } from 'utils/constants';

const initialState: adminAreaReducerTypes.IAdminAreaReducer = {
  currentPage: 1,
  error: null,
  accountsSummaries: [],
  franchisorList: [],
  usersList: [],
  franchisorStats: [],
  isAccountListFetching: true,
  isFranshisorListFetching: true,
  isUserListFetching: true,
  isFranshisorStatsFetching: false,
  isUpdatingFranchisor: false,
  lastVisitedScreen: '',
  selectedFranchisorId: null,
  userDetailObj: {
    isUserDetailFetching: false,
    isUpdateUserDetails: false,
    userDetails: null,
    isSendInviteEmail: false,
    updateUserSuccessMsg: null,
    updateUserFailMsg: null
  },
  detailViewError: null
};

const adminAreaReducer: Reducer<adminAreaReducerTypes.IAdminAreaReducer, AdminAreaActions> = (state: adminAreaReducerTypes.IAdminAreaReducer = initialState, action: AdminAreaActions) => {
  switch (action.type) {
    // GET ADMIN AREA ACCOUNT LIST REQUEST
    case types.GET_AA_ACCOUNTS_LIST_REQUEST:
      return {
        ...state,
        isAccountListFetching: true
      };
    case types.GET_AA_ACCOUNTS_LIST_RESPONSE:
      return {
        ...state,
        isAccountListFetching: false,
        accountsSummaries: state.currentPage > 1 ? [...state.accountsSummaries, ...action.payload] : action.payload
      };
    case types.GET_AA_ACCOUNTS_LIST_FAILURE:
      return {
        ...state,
        isAccountListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // GET ADMIN AREA FRANCHIORS LIST REQUEST
    case types.GET_AA_FRANCHISORS_LIST_REQUEST:
      return {
        ...state,
        isFranshisorListFetching: true
      };
    case types.GET_AA_FRANCHISORS_LIST_RESPONSE:
      return {
        ...state,
        isFranshisorListFetching: false,
        franchisorList: state.currentPage > 1 ? [...state.franchisorList, ...action.payload] : action.payload
      };
    case types.GET_AA_FRANCHISORS_LIST_FAILURE:
      return {
        ...state,
        isFranshisorListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // GET ADMIN AREA USERS LIST REQUEST
    case types.GET_AA_USERS_LIST_REQUEST:
      return {
        ...state,
        isUserListFetching: true
      };
    case types.GET_AA_USERS_LIST_RESPONSE:
      return {
        ...state,
        isUserListFetching: false,
        usersList: state.currentPage > 1 ? [...state.usersList, ...action.payload] : action.payload
      };
    case types.GET_AA_USERS_LIST_FAILURE:
      return {
        ...state,
        isUserListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.GET_AA_FRANCHISOR_STATS_REQUEST:
      return {
        ...state,
        isFranshisorStatsFetching: true
      };
    case types.GET_AA_FRANCHISOR_STATS_RESPONSE:
      return {
        ...state,
        isFranshisorStatsFetching: false,
        franchisorStats: action.payload || []
      };
    case types.GET_AA_FRANCHISOR_STATS_FAILURE:
      return {
        ...state,
        isFranshisorStatsFetching: false,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    case types.UPDATE_AA_FRANCHISOR_REQUEST:
      return {
        ...state,
        isUpdatingFranchisor: true
      };
    case types.UPDATE_AA_FRANCHISOR_RESPONSE:
      const updatedList = [...state.franchisorList].reduce((acc: franchisorApiTypes.IFranchisorData[], curr) => {
        if (curr.id !== action.payload.id) {
          acc = [...acc, curr];
        } else {
          acc = [...acc, action.payload];
        }
        return acc;
      }, []);
      return {
        ...state,
        isUpdatingFranchisor: false,
        franchisorList: updatedList
      };
    case types.UPDATE_AA_FRANCHISOR_FAILURE:
      return {
        ...state,
        isUpdatingFranchisor: false,
        error: action.payload || 'Unexpected error'
      };
    case types.ADMIN_AREA_RESET:
      if (action.payload && action.payload.length) {
        const resetObj = action.payload.reduce((acc, curr) => {
          acc = { ...acc, [curr]: initialState[curr] };
          return acc;
        }, {});
        return {
          ...state,
          ...resetObj
        };
      }
      return {
        ...initialState,
        lastVisitedScreen: state.lastVisitedScreen
      };
    case types.SET_ADMIN_AREA_SCREEN:
      return {
        ...state,
        lastVisitedScreen: action.payload
      };
    case types.UPDATE_AA_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUpdateUserDetails: true
        }
      };
    case types.UPDATE_AA_USER_DETAILS_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUpdateUserDetails: false,
          userDetails: action.payload,
          updateUserSuccessMsg: DONE_SUCCESS_MSG
        },
        usersList: state.usersList.reduce((acc: loginReducerType.ILoginResponseUser[], curr) => {
          if (curr.id === action.payload.id) acc = [...acc, action.payload];
          else acc = [...acc, curr];
          return acc;
        }, [])
      };
    case types.UPDATE_AA_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUpdateUserDetails: false,
          updateUserSuccessMsg: null,
          updateUserFailMsg: action.payload || 'Unexpected error'
        }
      };
    case types.ADD_AA_USER_INVITE_EMAIL_REQUEST:
      return {
        ...state
      };
    case types.ADD_AA_USER_INVITE_EMAIL_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isSendInviteEmail: true,
          updateUserSuccessMsg: DONE_SUCCESS_MSG
        }
      };
    case types.ADD_AA_USER_INVITE_EMAIL_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isSendInviteEmail: false,
          updateUserSuccessMsg: null
        },
        error: action.payload || 'Unexpected error'
      };
    case types.UPDATE_AA_USER_DETAILS_MSG_RESET:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUpdateUserDetails: initialState.userDetailObj.isUpdateUserDetails,
          updateUserSuccessMsg: initialState.userDetailObj.updateUserSuccessMsg,
          updateUserFailMsg: initialState.userDetailObj.updateUserFailMsg
        }
      };
    case types.AA_USER_DETAILS_DATA_RESET:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          userDetails: initialState.userDetailObj.userDetails,
          isSendInviteEmail: initialState.userDetailObj.isSendInviteEmail
        }
      };
    case types.SET_FRANCHISOR_ID:
      return {
        ...state,
        selectedFranchisorId: action.payload
      };
    case types.GET_AA_USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUserDetailFetching: true
        }
      };
    case types.GET_AA_USER_DETAILS_RESPONSE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUserDetailFetching: false,
          userDetails: action.payload
        }
      };
    case types.GET_AA_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetailObj: {
          ...state.userDetailObj,
          isUserDetailFetching: false,
          userDetails: null
        },
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    case types.SET_AA_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    case types.ADMIN_AREA_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    default:
      return state;
  }
};

export { adminAreaReducer };
