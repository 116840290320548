import { Button } from 'react-bootstrap';
import { /* useSelector , */ useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { config } from 'config';
import { ReactVideoPlayer } from 'widgets/Media';
import { setAISubscriptionDetailsModal, setCsLocationSubscriptionModalType } from 'actions';
import { ROUTES } from 'utils/constants';
import { useSSOIdentifiersForAccount } from 'utils/hooks';

export const Community = () => {
  const dispatch = useDispatch();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const communityRoute = useMatch(`${ROUTES.COMMUNITY}/inbox`);

  const handleUpgrade = () => {
    dispatch(setCsLocationSubscriptionModalType('ai_community'));
    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
  };

  return (
    //  Rallio platform start here
    communityRoute?.pathname ? (
      <div className="ralSalesMain">
        <div className="rallioplatform rallioactivate rallio__multi--location eAgencyWrap eA-Community">
          <div className="eaTop-cnt">
            <h3>
              <span>Like Rallio so far? Add more features with an upgraded subscription!</span>
            </h3>
            <div className="content-main-section">
              <div className="csp">
                <div className="video-outer-element">
                  <ReactVideoPlayer
                    isPlayControl
                    light={`${config.cdnImgUrl}rallioVideoBg.jpg`}
                    muted
                    videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/clfiatynmckstpopoget.mp4'}
                    customClassname="m-ast-itm m-ast-video"
                  />
                </div>
              </div>
              <div className="left-section-main">
                <div className="sales-cnt">
                  <p className="main-para-text main-para-title">
                    <span className="eaCount">1. </span> Unlimited AI Content: Create unlimited social media posts, stunning AI images, and professional review responses with ease.
                  </p>
                  <p className="main-para-text main-para-title">
                    <span className="eaCount">2. </span> Community Management: Take control of your engagements across all platforms. Respond to comments and direct messages seamlessly.
                  </p>
                  <p className="main-para-text main-para-title">
                    <span className="eaCount">3. </span> AI-Assisted Review Responses: Enhance your reputation by responding quickly to reviews on Google, Yelp, and Facebook with AI support.
                  </p>

                  <p className="main-para-text main__para--subtxt">Upgrade to the full power of Rallio and manage your social media like a pro!</p>
                </div>
              </div>
              {/* <div className="right-section-main" /> */}
            </div>
          </div>
        </div>
        {isCSLocation && (
          <div className="quotation-text">
            <div className="schedule-section">
              <Button className="csv-btn" onClick={handleUpgrade}>
                <span>Upgrade</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    ) : null
    //  Rallio platform end here
  );
};
