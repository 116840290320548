import { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

interface IChart {
  identifier: string;
  options: any;
  classStr?: string;
}

export const ChartComponent = ({ identifier, options, classStr }: IChart) => {
  const chartRef = useRef<Highcharts.Chart | null>(null);

  useEffect(() => {
    chartRef.current = Highcharts.chart(identifier, options);
    return () => {
      chartRef.current = null;
    };
  }, [options, identifier]);

  return <div id={identifier} />;
};
