// GET AI REVIEW RESPONDER
export const GET_AI_REVIEW_RESPONDER_REQUEST = 'ai_review_responder/GET_AI_REVIEW_RESPONDER_REQUEST';
export const GET_AI_REVIEW_RESPONDER_RESPONSE = 'ai_review_responder/GET_AI_REVIEW_RESPONDER_RESPONSE';
export const GET_AI_REVIEW_RESPONDER_FAILURE = 'ai_review_responder/GET_AI_REVIEW_RESPONDER_FAILURE';

// UPDATE AI REVIEW RESPONDER
export const UPDATE_AI_REVIEW_RESPONDER_REQUEST = 'ai_review_responder/UPDATE_AI_REVIEW_RESPONDER_REQUEST';
export const UPDATE_AI_REVIEW_RESPONDER_RESPONSE = 'ai_review_responder/UPDATE_AI_REVIEW_RESPONDER_RESPONSE';
export const UPDATE_AI_REVIEW_RESPONDER_FAILURE = 'ai_review_responder/UPDATE_AI_REVIEW_RESPONDER_FAILURE';

// GET RESPONSE PREVIEW
export const GET_REVIEW_RES_PREVIEW_REQUEST = 'ai_review_responder/GET_REVIEW_RES_PREVIEW_REQUEST';
export const GET_REVIEW_RES_PREVIEW_RESPONSE = 'ai_review_responder/GET_REVIEW_RES_PREVIEW_RESPONSE';
export const GET_REVIEW_RES_PREVIEW_FAILURE = 'ai_review_responder/GET_REVIEW_RES_PREVIEW_FAILURE';
export const RESET_REVIEW_RESPONSE_PREVIEW = 'ai_review_responder/RESET_REVIEW_RESPONSE_PREVIEW';

// GENERATE AI AUTO REVIEW
export const GENERATE_AI_AUTO_REVIEWS_REQUEST = 'ai_review_responder/GENERATE_AI_AUTO_REVIEWS_REQUEST';
export const GENERATE_AI_AUTO_REVIEWS_RESPONSE = 'ai_review_responder/GENERATE_AI_AUTO_REVIEWS_RESPONSE';
export const GENERATE_AI_AUTO_REVIEWS_FAILURE = 'ai_review_responder/GENERATE_AI_AUTO_REVIEWS_FAILURE';
