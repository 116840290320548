import { Reducer } from 'redux';

import produce from 'immer';
import { UserActions } from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { IUser } from 'revv/types';

const initialState: IUser = {
  isFetching: false,
  error: '',
  isCurrentUserFetching: false,
  userDetail: null,
  editDetail: null,
  userDetailViewData: null,
  selectedLocationList: null,
  userMetrics: null,
  usersListResponse: null,
  currentPage: 1,
  sorting: '',
  isMetricsFetching: true,
  successMsg: '',
  profileSettingsResponse: null,
  isProfileSettingReqFetching: false
};

export const userReducer: Reducer<IUser, UserActions> = (prevState: IUser = initialState, action: UserActions) => {
  switch (action.type) {
    case actionTypes.USER_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
        if (action.payload.isLogin) {
          nextState.isCurrentUserFetching = true;
        }
      });
    case actionTypes.USER_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
        if (action.payload.isLogin) {
          nextState.userDetail = action.payload.data;
          nextState.isCurrentUserFetching = false;
        } else if (action.payload.isEdit) {
          nextState.editDetail = action.payload.data;
          nextState.selectedLocationList = action.payload.data?.locationList || null;
        } else {
          nextState.userDetailViewData = action.payload.data;
        }
      });
    case actionTypes.USER_METRICS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = true;
        nextState.error = '';
      });
    case actionTypes.USER_METRICS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_METRICS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.error = '';
        nextState.userMetrics = action.payload;
      });

    case actionTypes.USER_LIST_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.currentPage = action.payload.pageNumber || prevState.currentPage;
        nextState.error = '';
        nextState.sorting = action.payload?.sorting || prevState.sorting;
      });
    case actionTypes.USER_LIST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_LIST_RESPONSE:
      return produce(prevState, (nextState) => {
        if (prevState.currentPage > 1) {
          if (prevState.usersListResponse && prevState.usersListResponse?.length > 0) {
            nextState.usersListResponse = prevState.usersListResponse.concat(action.payload);
          }
        } else {
          nextState.usersListResponse = action.payload;
        }
        nextState.isFetching = false;
      });
    case actionTypes.USER_DELETE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.USER_DELETE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_DELETE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
      });
    case actionTypes.USER_UPLOAD_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.USER_UPLOAD_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_UPLOAD_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.successMsg = action.payload;
      });
    case actionTypes.USER_CREATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.USER_CREATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_CREATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
      });
    case actionTypes.USER_DETAILS_UPDATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.USER_DETAILS_UPDATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.USER_DETAILS_UPDATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
        if (action.payload.data) {
          nextState.userDetailViewData = action.payload.data;
          nextState.editDetail = action.payload.data;
          nextState.selectedLocationList = action.payload.data.locationList || null;
        }
      });
    case actionTypes.UPDATE_USERS_SELECTED_LOCATION:
      return produce(prevState, (nextState) => {
        nextState.selectedLocationList = action.payload;
      });
    case actionTypes.USER_RESET_UPLOAD_SUCCESS_MESSAGE:
      return produce(prevState, (nextState) => {
        nextState.successMsg = initialState.successMsg;
      });

    case actionTypes.PROFILE_SETTINGS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isProfileSettingReqFetching = true;
        nextState.error = '';
      });
    case actionTypes.PROFILE_SETTINGS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isProfileSettingReqFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.PROFILE_SETTINGS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isProfileSettingReqFetching = false;
        nextState.error = '';
        nextState.profileSettingsResponse = action.payload;
      });
    case actionTypes.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'user') {
          nextState.isFetching = initialState.isFetching;
          nextState.error = initialState.error;
          nextState.usersListResponse = initialState.usersListResponse;
          nextState.currentPage = initialState.currentPage;
          nextState.sorting = initialState.sorting;
          nextState.userDetailViewData = initialState.userDetailViewData;
          nextState.userMetrics = initialState.userMetrics;
          nextState.isMetricsFetching = initialState.isMetricsFetching;
          nextState.successMsg = initialState.successMsg;
          nextState.editDetail = initialState.editDetail;
          nextState.selectedLocationList = initialState.selectedLocationList;
          nextState.profileSettingsResponse = initialState.profileSettingsResponse;
        }
      });

    default:
      return prevState;
  }
};
