import { Reducer } from 'redux';

import produce from 'immer';
import * as types from 'revv/actions/action-types';
import { ILogin } from 'revv/types';
import { LoginAction } from 'revv/actions';

const initialState: ILogin = {
  isAuthenticated: false,
  errorMessage: '',
  isFetching: false,
  response: null,
  activeUser: null,
  activeClientId: '',
  activeClientName: '',
  activeClientDetail: null,
  isActiveClientRequest: false
};

export const loginReducer: Reducer<ILogin, LoginAction> = (prevState: ILogin = initialState, action: LoginAction) => {
  switch (action.type) {
    case types.SIGN_IN:
      return produce(prevState, (nextState) => {
        nextState.isAuthenticated = false;
        nextState.errorMessage = '';
        nextState.isFetching = true;
      });
    case types.SIGN_IN_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.errorMessage = action.payload;
        nextState.isFetching = false;
      });
    case types.SIGN_IN_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isAuthenticated = true;
        nextState.response = action.payload;
        nextState.activeUser = action.payload;
        nextState.isFetching = false;
      });
    case types.ACTIVE_CLIENT_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.activeClientId = action.payload?.id || '';
        nextState.activeClientName = action.payload?.name || '';
        nextState.activeClientDetail = action.payload;
        nextState.isActiveClientRequest = false;
      });
    case types.LOGOUT:
      return produce(prevState, (nextState) => {
        nextState.isAuthenticated = initialState.isAuthenticated;
        nextState.response = initialState.response;
        nextState.errorMessage = initialState.errorMessage;
        nextState.isFetching = initialState.isFetching;
        nextState.activeUser = initialState.activeUser;
        nextState.activeClientId = initialState.activeClientId;
        nextState.activeClientDetail = initialState.activeClientDetail;
      });
    default:
      return prevState;
  }
};
