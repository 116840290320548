import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';

import { cloudSignRequest, setCurrentUserCloudProfileImg, getAAUsersListRequest, uploadCurrentUserCloudProfileImgRequest, setIsEditCurrentUser, currentUserDetailStatusReset } from 'actions';
import { commonModalPopup, IStore, loginContainerType } from 'types';
import { dateFormatByRequestedFormat, getCurrentDate, profileInfoValidation } from 'utils/helpers';
import { useParamsDeconstructor } from 'utils/hooks';
import {
  UTC_TIMESTAMP_FORMAT,
  PROFILE_INFO_DATA_FIELDS,
  ALERT_BOX_TYPES,
  PROFILE_INFO_TOOLTIP,
  RIPPLE_COLOR,
  DL_UPDATED_SUCCESSFULLY,
  UPDATE_USER_DETAIL_FAILED,
  TOOLTIP_PLACEMENT,
  IMAGE_SUPPORTED_FORMAT,
  ADV_LB_EMPLOYEE_SOCIAL_MEDIA_OBJ,
  INPUT_TYPE
} from 'utils/constants';
import { alertBoxCall, Loading } from 'components';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const EditUserDetail = ({ updateUserDetails }: commonModalPopup.IUserDetailModalProps) => {
  const dispatch = useDispatch();
  const userRoute = useMatch('/admin_area/users');
  const { queryParams } = useParamsDeconstructor();

  const userDetails = useSelector((state: IStore) => state.common.userDetailObj?.currentUserDetails);
  const isUpdated = useSelector((state: IStore) => state.common.userDetailObj.updateUserDetailStatus.isUpdated);
  const isFailed = useSelector((state: IStore) => state.common.userDetailObj.updateUserDetailStatus.isFailed);
  const isProfileImgUpdate = useSelector((state: IStore) => state.common.userDetailObj.isProfileImgFetching);
  const photoUrl = useSelector((state: IStore) => state.common.userDetailObj.userProfileObj.photoUrl);
  const photoCloudinaryId = useSelector((state: IStore) => state.common.userDetailObj.userProfileObj.cloudinaryId);
  const isEditUser = useSelector((state: IStore) => state.common.userDetailObj.isEditUser);
  const isUpdateUserDetails = useSelector((state: IStore) => state.common.userDetailObj.isUserDataUpdate);
  const isFetching = useSelector((state: IStore) => state.common.userDetailObj.isFetching);

  const isProfileInfo = Boolean(queryParams?.active_user_id);

  const { values, handleBlur, setFieldValue, touched, errors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      firstName: userDetails?.first_name || '',
      lastName: userDetails?.last_name || '',
      email: userDetails?.email || '',
      facebookProfileUrl: userDetails?.facebook_profile_url || '',
      instagramProfileUrl: userDetails?.instagram_profile_url || '',
      twitterProfileUrl: userDetails?.twitter_profile_url || '',
      linkedinProfileUrl: userDetails?.linkedin_profile_url || ''
    },
    validationSchema: profileInfoValidation,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (data: loginContainerType.IProfileInfoData) => {
      if (userDetails && data) {
        const updatedValues = {
          ...userDetails,
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          facebook_profile_url: data.facebookProfileUrl || null,
          instagram_profile_url: data.instagramProfileUrl || null,
          twitter_profile_url: data.twitterProfileUrl || null,
          linkedin_profile_url: data.linkedinProfileUrl || null,
          detail_id: String(userDetails?.detail_id),
          profile_photo_cloudinary_id: photoCloudinaryId,
          skip_reconfirmation: false
        };
        if (typeof updateUserDetails === 'function') {
          updateUserDetails(updatedValues);
        }
        dispatch(setIsEditCurrentUser(false));
        if (userRoute?.pathname) {
          dispatch(getAAUsersListRequest({ searchString: '', page: 1 }));
        }
      }
    }
  });

  const profileInfoSocialMediaObj = [
    { name: 'FACEBOOK_PROFILE_URL', value: userDetails?.facebook_profile_url || '', label: 'Facebook Profile Url' },
    { name: 'INSTAGRAM_PROFILE_URL', value: userDetails?.instagram_profile_url || '', label: 'Instagram Profile Url' },
    { name: 'TWITTER_PROFILE_URL', value: userDetails?.twitter_profile_url || '', label: 'X (Formerly Twitter) Profile Url' },
    { name: 'LINKEDIN_PROFILE_URL', value: userDetails?.linkedin_profile_url || '', label: 'Linkedin Profile Url' }
  ];

  ADV_LB_EMPLOYEE_SOCIAL_MEDIA_OBJ.map((it) => {
    return (it.url = typeof userDetails?.[`${it.value}_profile_url`] === 'string' ? String(userDetails?.[`${it.value}_profile_url`]) : null);
  });

  useEffect(() => {
    if (isUpdated) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DL_UPDATED_SUCCESSFULLY);
      dispatch(currentUserDetailStatusReset());
    }
    if (isFailed) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, UPDATE_USER_DETAIL_FAILED);
      dispatch(currentUserDetailStatusReset());
    }
  }, [isUpdated, isFailed, dispatch]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
      if (currentUnixTimestamp) {
        dispatch(
          cloudSignRequest({
            unixTimestamp: +currentUnixTimestamp,
            onSignatureCallback: (cloudSign) => {
              if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length) {
                acceptedFiles.forEach(async (selectedFile) => {
                  dispatch(
                    uploadCurrentUserCloudProfileImgRequest({
                      timestamp: cloudSign.timestamp,
                      apiKey: cloudSign.apikey,
                      signature: cloudSign.signature,
                      eager: cloudSign.eager.join(),
                      file: selectedFile,
                      fileName: selectedFile.name
                    })
                  );
                });
              }
            }
          })
        );
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: IMAGE_SUPPORTED_FORMAT,
    noClick: false,
    onDrop,
    maxFiles: 1
  });

  const getUserProfileImgData = () => {
    dispatch(
      setCurrentUserCloudProfileImg({
        cloudinaryId: userDetails?.profile_photo_cloudinary_id || null,
        photoUrl: userDetails?.profile_photo_url || null
      })
    );
  };

  const handleRemovePhoto = () => {
    dispatch(setCurrentUserCloudProfileImg({ cloudinaryId: null, photoUrl: null }));
  };

  /** Set values when changes happen in forms */
  const handleChange = (field: string, value: null | string) => {
    setFieldValue(field, value);
  };

  const getInputBoxClassName = (valueItem: keyof loginContainerType.IProfileInfoModalFieldValue) => {
    return touched[valueItem] && errors[valueItem] ? 'erroritem' : '';
  };

  const disableProfileInfoAction = () => {
    if (isUpdateUserDetails) return true;
    else if (Object.keys(errors).length) return true;
    else if (
      (userDetails?.first_name || '') === values.firstName &&
      (userDetails?.last_name || '') === values.lastName &&
      (userDetails?.email || '') === values.email &&
      (userDetails?.profile_photo_cloudinary_id || null) === photoCloudinaryId &&
      (userDetails?.facebook_profile_url || '') === values.facebookProfileUrl &&
      (userDetails?.instagram_profile_url || '') === values.instagramProfileUrl &&
      (userDetails?.twitter_profile_url || '') === values.twitterProfileUrl &&
      (userDetails?.linkedin_profile_url || '') === values.linkedinProfileUrl
    ) {
      return true;
    } else return false;
  };

  return isFetching ? (
    <Loading />
  ) : !isEditUser ? (
    <div className={`up-header`}>
      <div className="up-head-left">
        <div className="profileinfo-user-section">
          <div className="image">
            <ImageValidation isImgValid imgUrl={photoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Profile Info Image'} />
          </div>
          {isProfileInfo && (
            <div className="overlay">
              <div className="text">
                <div className="edit-icon" onClick={() => dispatch(setIsEditCurrentUser(true))}>
                  <ImageValidation isImgValid defaultImg="edit-w" customName="close" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="up-short-dtls">
          <h3>
            <span>{userDetails?.first_name}</span> <span>{userDetails?.last_name}</span>
          </h3>
          <span className="upd-email">{userDetails?.email}</span>
        </div>
      </div>
      <div className="up-head-right">
        <div className="fltr-imc selectsocial platformAnchors">
          {ADV_LB_EMPLOYEE_SOCIAL_MEDIA_OBJ.map((it, index) => {
            return (
              <a key={index} href={it.url || ''} target="_blank" rel="noopener noreferrer" className={it.url ? '' : 'custom-disabled'}>
                <ImageValidation isImgValid defaultImg={it.imgSrc} customName={it.label} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className={`up-header`}>
      <div className="up-head-left">
        <input {...getInputProps()} />
        <div className="profileinfo-user-section">
          <div className="image">
            <ImageValidation isImgValid imgUrl={photoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName={'Profile Info Image'} />
          </div>
          {isProfileImgUpdate ? (
            <Loading />
          ) : (
            isProfileInfo && (
              <div className="overlay">
                <div className="text">
                  <OverlayTrigger key={`${TOOLTIP_PLACEMENT.TOP}-edit`} overlay={<Tooltip id={`${TOOLTIP_PLACEMENT.TOP}-edit`}>{PROFILE_INFO_TOOLTIP.EDIT_PHOTO}</Tooltip>}>
                    <Button variant="secondary">
                      <div
                        className="edit-icon"
                        {...getRootProps({
                          onClick: (event) => event
                        })}
                      >
                        <ImageValidation isImgValid defaultImg="edit-action" customName="close" />
                      </div>
                    </Button>
                  </OverlayTrigger>
                  {photoUrl && (
                    <OverlayTrigger key={`${TOOLTIP_PLACEMENT.TOP}-remove`} overlay={<Tooltip id={`${TOOLTIP_PLACEMENT.TOP}-remove`}>{PROFILE_INFO_TOOLTIP.REMOVE_PHOTO}</Tooltip>}>
                      <Button variant="secondary">
                        <div className="delete-icon" onClick={handleRemovePhoto}>
                          <ImageValidation isImgValid defaultImg="delete-red" customName="close" />
                        </div>
                      </Button>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="up-head-right">
        <div className="up-fields">
          <div className={`form-group form-field-item ${getInputBoxClassName(PROFILE_INFO_DATA_FIELDS.FIRST_NAME)}`}>
            <input
              type={INPUT_TYPE.TEXT}
              name="firstName"
              className="form-control"
              autoComplete="off"
              value={values.firstName}
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(PROFILE_INFO_DATA_FIELDS.FIRST_NAME, e.target.value);
              }}
            />
            <span className="fltlabels"> First Name </span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(PROFILE_INFO_DATA_FIELDS.LAST_NAME)}`}>
            <input
              type={INPUT_TYPE.TEXT}
              name="lastName"
              className="form-control"
              autoComplete="off"
              value={values.lastName}
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(PROFILE_INFO_DATA_FIELDS.LAST_NAME, e.target.value);
              }}
            />
            <span className="fltlabels">Last Name</span>
          </div>

          <div className={`form-group form-field-item ${getInputBoxClassName(PROFILE_INFO_DATA_FIELDS.EMAIL)}`}>
            <input type={INPUT_TYPE.EMAIL} name="email" className="form-control" autoComplete="off" value={values.email || ''} onBlur={handleBlur} readOnly />
            <span className="fltlabels">Email</span>
          </div>
          {profileInfoSocialMediaObj.map((it, index) => {
            return (
              <div key={`profile-sm-${index}`} className={`form-group form-field-item ${getInputBoxClassName(PROFILE_INFO_DATA_FIELDS[it.name])}`}>
                <input
                  type={INPUT_TYPE.TEXT}
                  name={PROFILE_INFO_DATA_FIELDS[it.name]}
                  className="form-control"
                  autoComplete="off"
                  value={values[PROFILE_INFO_DATA_FIELDS[it.name]] || ''}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(PROFILE_INFO_DATA_FIELDS[it.name], e.target.value);
                  }}
                />
                <span className="fltlabels">{it.label}</span>
              </div>
            );
          })}
          <div className="form-group form-field-item upf-action flex-action-btns">
            <div className="easyflex-btns">
              <CustomRippleButton rippleClass="ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                <button
                  className="gnrc-btn lined-gnr"
                  type="reset"
                  onClick={() => {
                    dispatch(setIsEditCurrentUser(false));
                    getUserProfileImgData();
                    resetForm();
                  }}
                >
                  <span>Back</span>
                </button>
              </CustomRippleButton>
            </div>
            <div className={`easyflex-btns${disableProfileInfoAction() ? ` events-none pointer-events-none` : ``}`}>
              <CustomRippleButton rippleClass={`ac-primary-box  ${disableProfileInfoAction() ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`gnrc-btn blue-gnr  ${disableProfileInfoAction() ? 'disabled' : ''}`}
                  type="submit"
                  onClick={(event: React.MouseEvent<HTMLElement, MouseEvent> | any) => handleSubmit(event)}
                >
                  <span>Update</span>
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
