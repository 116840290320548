// SETTINGS SOCIAL PROFILE - STATE UPDATES
export const SETTINGS_UPDATE_YELP_DETAILS = 'socialProfile/SETTINGS_UPDATE_YELP_DETAILS';

export const UPDATE_SOCIAL_MEDIA_SETTING = 'socialProfile/UPDATE_SOCIAL_MEDIA_SETTING';

export const UPDATE_SOCIAL_MEDIA_SETTING_SUCCESS_FAILURE = 'socialProfile/UPDATE_SOCIAL_MEDIA_SETTING_SUCCESS_FAILURE';

export const DELETE_SOCIAL_MEDIA_SETTING = 'socialProfile/DELETE_SOCIAL_MEDIA_SETTING';

export const LINKEDIN_PAGES_REQUEST = 'socialProfile/LINKEDIN_PAGES_REQUEST';

export const LINKEDIN_PAGES_REQUEST_FAIL = 'socialProfile/LINKEDIN_PAGES_REQUEST_FAIL';

export const LINKEDIN_PAGES_RESPONSE = 'socialProfile/LINKEDIN_PAGES_RESPONSE';

export const SAVE_FACEBOOK_USER_CREDENTIAL = 'socialProfile/SAVE_FACEBOOK_USER_CREDENTIAL';

export const SAVE_FACEBOOK_USER_CREDENTIAL_RESPONSE = 'socialProfile/SAVE_FACEBOOK_USER_CREDENTIAL_RESPONSE';

export const SAVE_FACEBOOK_PAGE_CREDENTIAL = 'socialProfile/SAVE_FACEBOOK_PAGE_CREDENTIAL';

export const FACEBOOK_PAGES_REQUEST = 'socialProfile/FACEBOOK_PAGES_REQUEST';

export const FACEBOOK_PAGES_REQUEST_FAIL = 'socialProfile/FACEBOOK_PAGES_REQUEST_FAIL';

export const FACEBOOK_PAGES_RESPONSE = 'socialProfile/FACEBOOK_PAGES_RESPONSE';

export const CONNECT_INSTAGRAM = 'socialProfile/CONNECT_INSTAGRAM';

export const CONNECT_INSTAGRAM_SUCCESS = 'socialProfile/CONNECT_INSTAGRAM_SUCCESS';

export const DISCONNECT_INSTAGRAM = 'socialProfile/DISCONNECT_INSTAGRAM';

export const DISCONNECT_INSTAGRAM_SUCCESS = 'socialProfile/DISCONNECT_INSTAGRAM_SUCCESS';

export const SAVE_GOOGLE_OAUTH_CREDENTIAL_RESPONSE = 'socialProfile/SAVE_GOOGLE_OAUTH_CREDENTIAL_RESPONSE';

export const LOAD_GOOGLE_BUSINESS_ACCOUNT_REQUEST = 'socialProfile/LOAD_GOOGLE_BUSINESS_ACCOUNT_REQUEST';

export const LOAD_GOOGLE_BUSINESS_ACCOUNT_RESPONSE = 'socialProfile/LOAD_GOOGLE_BUSINESS_ACCOUNT_RESPONSE';

export const LOAD_GOOGLE_LOCATIONS_REQUEST = 'socialProfile/LOAD_GOOGLE_LOCATIONS_REQUEST';

export const LOAD_GOOGLE_LOCATIONS_RESPONSE = 'socialProfile/LOAD_GOOGLE_LOCATIONS_RESPONSE';

export const CONNECT_GOOGLE_REQUEST = 'socialProfile/CONNECT_GOOGLE_REQUEST';

export const SOCIAL_PROFILE_RESET_ALL = 'socialProfile/SOCIAL_PROFILE_RESET_ALL';

export const GOOGLE_LOCATION_PHONE_FOR_CALL_TO_ACTION = 'socialProfile/GOOGLE_LOCATION_PHONE_FOR_CALL_TO_ACTION';

// FB PAGE PROVIDING URL - STATE UPDATES
export const FB_PAGE_PROVIDING_URL_REQUEST = 'socialProfile/FB_PAGE_PROVIDING_URL_REQUEST';
export const FB_PAGE_PROVIDING_URL_RESPONSE = 'socialProfile/FB_PAGE_PROVIDING_URL_RESPONSE';
export const FB_PAGE_PROVIDING_URL_FAILURE = 'socialProfile/FB_PAGE_PROVIDING_URL_FAILURE';
