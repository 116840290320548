import { Reducer } from 'redux';

import * as types from 'analytics/actions/action-types';
import { LeaderBoardAnalyticsActions } from 'analytics/actions';
import { ILeaderBoardAnalyticsReducer } from 'analytics/types';

const initialState: ILeaderBoardAnalyticsReducer = {
  isLeaderBoardFetching: true,
  isError: false,
  error: null,
  actualData: null,
  reviewCategoryData: [],
  reviewCategoryId: []
};

export const leaderBoardAnalyticsReducer: Reducer<ILeaderBoardAnalyticsReducer, LeaderBoardAnalyticsActions> = (
  state: ILeaderBoardAnalyticsReducer = initialState,
  action: LeaderBoardAnalyticsActions
) => {
  switch (action.type) {
    case types.GET_LEADER_BOARD_ANALYTICS_REQUEST:
      return {
        ...state,
        isLeaderBoardFetching: true,
        isError: false
      };
    case types.GET_LEADER_BOARD_ANALYTICS_RESPONSE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: false,
        error: null,
        actualData: action.payload?.analytics_table
      };
    case types.GET_LEADER_BOARD_ANALYTICS_FAILURE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: true,
        tableData: null,
        actualData: null
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_REQUEST:
      return {
        ...state,
        isLeaderBoardFetching: true,
        isError: false
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_RESPONSE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: false,
        error: null,
        reviewCategoryData: action.payload
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_FAILURE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: true,
        reviewCategoryData: []
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_REQUEST:
      return {
        ...state,
        isLeaderBoardFetching: true,
        isError: false
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_RESPONSE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: false,
        error: null,
        reviewCategoryId: action.payload
      };
    case types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_FAILURE:
      return {
        ...state,
        isLeaderBoardFetching: false,
        isError: true,
        reviewCategoryId: []
      };
    case types.RESET_LEADER_BOARD_ANALYTICS_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
