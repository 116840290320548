import { useMediaQuery } from 'react-responsive';

import { IReactResponsiveTypes } from 'utils/constants';

export const useReactResponsive = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1280 });
  const isTabletOrMobile = useMediaQuery({ minWidth: 1224 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const isRetina = useMediaQuery({ minResolution: 2 });

  return isDesktopOrLaptop
    ? IReactResponsiveTypes.DESKTOP_OR_LAPTOP
    : isTabletOrMobile
    ? IReactResponsiveTypes.TABLET_OR_MOBILE
    : isBigScreen
    ? IReactResponsiveTypes.BIG_SCREEN
    : isPortrait
    ? IReactResponsiveTypes.PORTRAIT
    : isLandscape
    ? IReactResponsiveTypes.LANDSCAPE
    : isRetina
    ? IReactResponsiveTypes.RETINA
    : '';
};
