import { AP_FRAN_SHOW_LINE_BREAK_TYPES } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';

export const TiktokPreview = ({ userName, videoUrl, description, thumbnailUrl }: { userName: string; videoUrl: string; description: string; thumbnailUrl: string }) => {
  return (
    <div className="tt-wraps">
      <div className="tt-asset-wrap">
        <ReactVideoPlayer
          videoUrl={videoUrl || ''}
          customClassname={'video full-width'}
          isPlayControl={false} // playVideo.includes(socialMediaName)
          light={thumbnailUrl}
        />
      </div>
      <div className="tiktok-Overlay">
        <div className="play-btn">
          <ImageValidation isImgValid defaultImg={'play-tiktok'} customName={'tiktokPlay'} />
        </div>
        <div className="tt-topContent">
          <h3>
            <span className="ttcLabel">Following | </span>
            <span className="ttcValue">For you</span>
          </h3>
        </div>

        <div className="tt-BaseContent">
          <div className="ttb-top">
            <div className="ttb-cnt-left">
              <h3>
                <span className="ttcValue">@{userName}</span>
                <div className="ttcTime">
                  <Paragraph customText={'Just now'} />
                </div>
              </h3>
              <Paragraph customClassname="gm-text" customText={description || ''} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />

              <div className="tt-translation">
                <h3>See translation</h3>
                <div className="tt-st-wrap">
                  <div className="tt-st-item">
                    <ImageValidation isImgValid defaultImg={'music-tiktok'} customName={'Original Sound'} />
                    <span className="tt-st-head">Original Sound</span>
                  </div>
                  <div className="tt-st-item">
                    <ImageValidation isImgValid defaultImg={'mute-tiktok'} customName={'Mute'} />
                    <span className="tt-st-head">Mute</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ttb-icons-right">
              <ul>
                <li>
                  <ImageValidation isImgValid defaultImg={'follow-tiktok'} customName={'Follow'} />
                </li>
                <li>
                  <ImageValidation isImgValid defaultImg={'like-tiktok'} customName={'Likes'} />
                  <span className="tt-nav-txt">0</span>
                </li>
                <li>
                  <ImageValidation isImgValid defaultImg={'chat-tiktok'} customName={'Chat Message'} />
                  <span className="tt-nav-txt">0</span>
                </li>
                <li>
                  <ImageValidation isImgValid defaultImg={'share-tiktok'} customName={'Share'} />
                  <span className="tt-nav-txt">share</span>
                </li>
                <li>
                  <ImageValidation isImgValid defaultImg={'music-r-tiktok'} customName={'Music'} />
                </li>
              </ul>
            </div>
          </div>
          <div className="ttb-base">
            <ul>
              <li>
                <ImageValidation isImgValid defaultImg={'home-tiktok'} customName={'Home'} />
                <span className="tt-nav-txt">home</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'search-tiktok'} customName={'Search'} />
                <span className="tt-nav-txt">search</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'add-tiktok'} customName={'Add'} />
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'inbox-tiktok'} customName={'Inbox'} />
                <span className="tt-nav-txt">inbox</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'profile-tiktok'} customName={'Profile'} />
                <span className="tt-nav-txt">profile</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
