import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { advocacyLeaderboardApiTypes } from 'types';

// GET ADV LEADERBOARD LIST
export const getAdvLeaderboardListRequest = (payload: advocacyLeaderboardApiTypes.IAdvLeaderboardRequest) => createAction(actionTypes.GET_ADV_LEADERBOARD_LIST_REQUEST, payload);
export const getAdvLeaderboardListResponse = (data: advocacyLeaderboardApiTypes.IAdvLeaderboardResponse) => createAction(actionTypes.GET_ADV_LEADERBOARD_LIST_RESPONSE, data);
export const getAdvLeaderboardListFailure = (error: null | string) => createAction(actionTypes.GET_ADV_LEADERBOARD_LIST_FAILURE, error);

// GET ADV LEADERBOARD FILTER STATS
export const getAdvLeaderboardStatsRequest = (payload: advocacyLeaderboardApiTypes.IAdvLeaderboardStatsReq) => createAction(actionTypes.GET_ADV_LEADERBOARD_STATS_REQUEST, payload);
export const getAdvLeaderboardStatsResponse = (data: advocacyLeaderboardApiTypes.IAdvLeaderboardStatsResponse) => createAction(actionTypes.GET_ADV_LEADERBOARD_STATS_RESPONSE, data);
export const getAdvLeaderboardStatsFailure = (error: null | string) => createAction(actionTypes.GET_ADV_LEADERBOARD_STATS_FAILURE, error);

const ADV_LEADERBOARD_ACTIONS = {
  getAdvLeaderboardListRequest,
  getAdvLeaderboardListResponse,
  getAdvLeaderboardListFailure,
  getAdvLeaderboardStatsRequest,
  getAdvLeaderboardStatsResponse,
  getAdvLeaderboardStatsFailure
};

export type AdvocacyLeaderboardActions = ActionsUnion<typeof ADV_LEADERBOARD_ACTIONS>;
