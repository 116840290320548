import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { getPartnerBannersRequest } from 'actions';
import { ADMIN_AREA_ROUTES } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { /* CarousalImages, */ ImageValidation } from 'widgets/Media';
// import { handleLinkNavigation } from 'utils/helpers';

export const PartnerBanners = () => {
  const dispatch = useDispatch();
  const { userOwnership, currentRoute } = useAccountSwitcherData();

  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const randomPartnerBanners = useSelector((state: IStore) => state.franchisors.partnerBanners.randomPartnerBanners);
  const isBannerPartner = useSelector((state: IStore) => state.franchisors.franchisorDetails?.banner_partner);

  useEffect(() => {
    if (userOwnership && topLevelFranchisorId && !isBannerPartner) {
      dispatch(getPartnerBannersRequest({ id: topLevelFranchisorId, params: { visible: true, random: 1 } })); // random param gives number of images as response
    }
  }, [userOwnership, topLevelFranchisorId, isBannerPartner, currentRoute, dispatch]);

  // const handleUrlNavigation = (imgUrl: string) => {
  //   const selectedImgRedirectUrl = randomPartnerBanners.find((banner) => banner.photo_url === imgUrl)?.redirect_url ?? null;
  //   if (selectedImgRedirectUrl) handleLinkNavigation(selectedImgRedirectUrl);
  // };
  return (
    <>
      {/* {randomPartnerBanners?.length && !ADMIN_AREA_ROUTES.includes(currentRoute) ? (
        <div className="pbWrap">
          <CarousalImages
            key={`calendar-detail`}
            imagesList={randomPartnerBanners.map((banner) => banner.photo_url)}
            showThumbs={false}
            showArrows
            handleImageUrl={(url) => handleUrlNavigation(url)}
            carouselWrapperClassName="pbAsset"
          />
        </div>
      ) : null} */}

      {randomPartnerBanners?.length && !ADMIN_AREA_ROUTES.includes(currentRoute) ? (
        <div className="pbWrap">
          <div className="pbAsset">
            <a href={randomPartnerBanners?.[0]?.redirect_url} target="blank">
              <ImageValidation isImgValid isNotSvgFormat imgUrl={randomPartnerBanners?.[0]?.photo_url} customName={'Partner Banner'} customClassname={'pbBanner'} />
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
};
