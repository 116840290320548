import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup, IStore } from 'types';
import { ADD_POST_ACTION_TYPE, AP_ACTION_TYPES } from 'utils/constants';
import { addPostSavePostActionRequest, addPostUpdateSavedPostRequest } from 'actions';
import { getApPostActionReqPayload, getAddContentList, getApUpdatePostActionReqPayload } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';

export const PostNowModal = (props: commonModalPopup.IAddPostModalPopup) => {
  const dispatch = useDispatch();

  const { isModalShow, handleModalClose, containerClassName } = props;

  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);

  const handleAddSavedPostData = (actionType: string) => {
    if (addPostAction === AP_ACTION_TYPES.ADD) {
      const apReqPayload = getApPostActionReqPayload(id, userOwnership, savedPostDetails, selectedFBTagList, selectedMediaList, selectedTags, linkObj.linkData, actionType, instaType, [], isAiPost);
      dispatch(addPostSavePostActionRequest({ actionType, reqBody: apReqPayload }));
      handleModalClose();
    } else if (addPostAction === AP_ACTION_TYPES.EDIT) {
      handleUpdateSavedPostData(actionType);
    }
  };

  const handleUpdateSavedPostData = (actionType: string) => {
    if (id && userOwnership && savedPostDetails && editSavedPost?.id) {
      const apReqPayload = getApUpdatePostActionReqPayload(
        editSavedPost,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkObj.linkData,
        actionType,
        instaType,
        reducerTextEditorState?.facebook,
        [],
        false,
        editSavedPost?.ai_post || isAiPost
      );
      dispatch(addPostUpdateSavedPostRequest({ actionType, savedPostId: editSavedPost?.id, reqBody: apReqPayload }));
      handleModalClose();
    }
  };

  const disableSavedPostAction = () => {
    if (savedPostDetails && !getAddContentList(savedPostDetails, activePlatforms).length && !selectedMediaList.length && !linkObj.linkData) return true;
    else return false;
  };

  return (
    <Modal className={containerClassName} size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} onHide={handleModalClose} animation={false}>
      <Modal.Body>
        <div className="cretor-postnow-modal-wrp">
          <div className="m-b-2re">
            <ImageValidation isImgValid defaultImg="post-now-icon-creator" customName="Post now" />
          </div>
          <div className="modal-message-wraps">Are you sure you want to post now?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cretor-postnow-modal-wrp">
          <div className="modal-btn-grp-wraps">
            {/* <button className="modal-btn-action-itm modal-cancel-btn" onClick={handleModalClose}> */}
            <button className="ac-btn ac-secondary-white ac-outline" onClick={handleModalClose}>
              Cancel
            </button>
            <button
              // className={`modal-btn-action-itm modal-post-now-btn${disableSavedPostAction() ? ` pointer-events-none` : ``}`}
              className={`ac-btn ac-primary${disableSavedPostAction() ? ` pointer-events-none` : ``}`}
              disabled={disableSavedPostAction()}
              onClick={() => handleAddSavedPostData(ADD_POST_ACTION_TYPE.POST_NOW)}
            >
              Post Now
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
