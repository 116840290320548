import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export const useSafeSelector = (stateName: string, values: Array<string>) => {
  const obtainValue = (state: any, key: string[], index = 0): any => {
    if (index === key.length - 1) {
      return state[key[index]];
    }
    return obtainValue(state[key[index]], key, index + 1);
  };

  const safeSelector = createSelector(
    (state: any) => state[stateName],
    (selectedState: any) => {
      const stateObj: any = {};
      values.forEach((key) => {
        const nestedKeys = key.split('.');
        stateObj[nestedKeys[nestedKeys.length - 1]] = obtainValue(selectedState, nestedKeys);
      });
      return stateObj;
    }
  );

  return useSelector(safeSelector);
};
