import { Reducer } from 'redux';

import { settingsPartnerBannersReducerTypes } from 'types';
import * as types from 'actions/action-types';
import { SettingsPartnerBannersAction } from 'actions';

const initialState: settingsPartnerBannersReducerTypes.ISettingsPartnerBannersReducer = {
  isPartnersFetching: false,
  isPartnerBannerFranchisorFetching: false,
  isFetching: false,
  partnerBannerFranchisors: [],
  partners: [],
  error: null,
  message: '',
  hasMorePartnerOrFran: false,
  currentPage: 1
};

export const settingsPartnerBannerReducer: Reducer<settingsPartnerBannersReducerTypes.ISettingsPartnerBannersReducer, SettingsPartnerBannersAction> = (
  state: settingsPartnerBannersReducerTypes.ISettingsPartnerBannersReducer = initialState,
  action: SettingsPartnerBannersAction
) => {
  switch (action.type) {
    case types.GET_PARTNER_BANNERS_CS_LIST_REQUEST:
      return {
        ...state,
        error: null,
        isPartnersFetching: true
      };
    case types.GET_PARTNER_BANNERS_CS_LIST_RESPONSE:
      return {
        ...state,
        isPartnersFetching: false,
        partners: action.payload.page === 1 ? action.payload.response : state.partners.concat(action.payload.response),
        hasMorePartnerOrFran: Boolean(action.payload.response.length),
        currentPage: action.payload.page
      };
    case types.GET_PARTNER_BANNERS_CS_LIST_FAILURE:
      return {
        ...state,
        isPartnersFetching: false,
        partners: [],
        error: action.payload,
        hasMorePartnerOrFran: false
      };
    case types.GET_PARTNER_BANNER_FRANCHISORS_REQUEST:
      return {
        ...state,
        error: null,
        isPartnerBannerFranchisorFetching: true
      };
    case types.GET_PARTNER_BANNER_FRANCHISORS_RESPONSE:
      return {
        ...state,
        isPartnerBannerFranchisorFetching: false,
        partnerBannerFranchisors: action.payload.page === 1 ? action.payload.response : state.partnerBannerFranchisors.concat(action.payload.response),
        hasMorePartnerOrFran: Boolean(action.payload.response.length),
        currentPage: action.payload.page
      };
    case types.GET_PARTNER_BANNER_FRANCHISORS_FAILURE:
      return {
        ...state,
        isPartnerBannerFranchisorFetching: false,
        partnerBannerFranchisors: [],
        error: action.payload,
        hasMorePartnerOrFran: false
      };
    case types.ENROLL_PARTNER_BANNERS_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.ENROLL_PARTNER_BANNERS_RESPONSE:
      const partnersCopy = [...state.partners];
      const selectedBrandIndex = state.partners.findIndex((partner) => partner.id === action.payload.partner_franchisor_id);
      partnersCopy[selectedBrandIndex][`${action.payload.syndication_type}_syndication`] = action.payload.join ? true : false;
      return {
        ...state,
        partners: partnersCopy
      };
    case types.ENROLL_PARTNER_BANNERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.CREATE_OR_UPDATE_PARTNER_BANNER_RESPONSE:
      const partnersBannerFranCopy = [...state.partnerBannerFranchisors];
      const selectedBannerIndex = partnersBannerFranCopy.findIndex((franchisor) => franchisor.id === action.payload.corporate_partner_franchisor_id);
      partnersBannerFranCopy[selectedBannerIndex].partner_banner = action.payload;
      return {
        ...state,
        partnerBannerFranchisors: partnersBannerFranCopy
      };
    case types.CREATE_OR_UPDATE_PARTNER_BANNER_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case types.PARTNER_BANNERS_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
