import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { IStore, commonModalPopup } from 'types';
import { ImageValidation } from 'widgets/Media';
import { SocialProfileSection } from './SocialProfileSection';
import { RallioProfileSection } from './RallioProfileSection';
import { UpgradePackageSection } from './UpgradePackageSection';
import { CONNECTION_TABS, USER_OWNERSHIP, RALLIO_PROFILE_TEXT, APP_TITLE, COMMON_PAGE_TYPE, ACCOUNT_ERROR_MSG, VALID_APP_URL_TITLES } from 'utils/constants';
import {
  accountSwitcherDataReset,
  accountProfileReset,
  rallioProfileResetAll,
  socialProfileResetAll,
  setFranchisorIds,
  accountGetProfileRequest,
  getTimeZoneRequest,
  getCountriesCodeRequest,
  accountError
} from 'actions';
import { Loading, /* SchedulerSection, */ TeamManagementListSection } from 'components';
import { useAccountSwitcherData, useParamsDeconstructor, useSSOIdentifiersForAccount } from 'utils/hooks';
import { config } from 'config';
import { VideoIntroduction } from './VideoIntroduction';
import { VideoConclusion } from './VideoConclusion';

export const AddOrEditLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams, isAdminScreenEnabled } = useAccountSwitcherData();

  const { isCSLocation } = useSSOIdentifiersForAccount();

  const props = useOutletContext<commonModalPopup.IAddOrEditLocation>();
  const { account_wizard_id: accountWizardId } = useParams();

  const type = props?.type;
  const tabName = optionalParams[2];

  const socialProfileRef = useRef<any>(null);
  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });

  const isProfileFetching = useSelector((state: IStore) => state.accounts.isProfileFetching);
  const signupCouponDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account?.signup_coupon);
  const activateAccount = useSelector((state: IStore) => state.accounts.activateAccount);
  const selectedFranchisorId = useSelector((state: IStore) => state.adminArea.selectedFranchisorId);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const accountId = accountDetails?.id || null;

  const hideLogo = () => {
    if (id && userOwnership && menuAccess) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && restrictions?.hub_user) {
        return restrictions.hub_user.includes('logo') || restrictions.hub_user.includes('profile');
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('logo') || restrictions.location_user.includes('profile');
      }
    }
    return false;
  };

  const isHideLogo = hideLogo();

  useEffect(() => {
    if (accountWizardId && isNaN(+accountWizardId)) {
      dispatch(accountError(ACCOUNT_ERROR_MSG));
    }
    if (type === COMMON_PAGE_TYPE.NEW && accountWizardId && +accountWizardId) {
      if (isAdminScreenEnabled) dispatch(setFranchisorIds(+accountWizardId));
      getRallioProfileDropdownData();
    }
    if (type === COMMON_PAGE_TYPE.EDIT && accountWizardId && +accountWizardId) {
      dispatch(accountGetProfileRequest({ id: +accountWizardId }));
      getRallioProfileDropdownData();
    }
  }, [type, accountWizardId]); // eslint-disable-line

  const getRallioProfileDropdownData = () => {
    if (tabName === CONNECTION_TABS.RALLIO_PROFILE) {
      dispatch(getTimeZoneRequest());
      dispatch(getCountriesCodeRequest());
    }
  };

  const handleURLPathname = (route: string) => {
    if (isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const handleCloseLocationDetails = () => {
    if (selectedFranchisorId) dispatch(setFranchisorIds(null));
    if (!id && !userOwnership) dispatch(accountSwitcherDataReset());
    dispatch(accountProfileReset());
    dispatch(rallioProfileResetAll());
    dispatch(socialProfileResetAll());
    handleURLPathname(`/${currentRoute}`);
  };

  return (
    <Modal show centered size="lg" className="connection-secondary add-location ralprofile-hl admin-modal-settings rsfsWizard">
      <Modal.Body>
        <div className="sec-conn__wrp" id="scrollTargetAccountWizard">
          <div className="sec-header multi-headItems">
            <div className="header-item__wrp">
              {!isCSLocation && <span className="title">{`${isProfileFetching ? '' : !accountId ? `Add` : `Edit`} Location`}</span>}
              <div className="stepper-holder">
                <div
                  className={tabName === CONNECTION_TABS.RALLIO_PROFILE ? 'step active' : 'step'}
                  onClick={() => {
                    if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.RALLIO_PROFILE}`);
                  }}
                >
                  <img src={`${config.cdnImgUrl}${optionalParams[2] === CONNECTION_TABS.RALLIO_PROFILE ? 'step1-active' : 'step1'}.svg`} alt={RALLIO_PROFILE_TEXT} className="step1" />
                  <h5>{`${isHideLogo || VALID_APP_URL_TITLES.includes(APP_TITLE) || isCSLocation ? '' : 'Rallio '} Profile`}</h5>
                </div>
                <div className="hz-line" />
                {signupCouponDetails?.coupon_code ? (
                  <>
                    <div
                      className={tabName === CONNECTION_TABS.UPGRADE_PACKAGE ? 'step active' : 'step'}
                      onClick={() => {
                        if (tabName === CONNECTION_TABS.SOCIAL_PROFILES) {
                          if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.UPGRADE_PACKAGE}`);
                        } else if (socialProfileRef?.current?.onSelectNextTab) {
                          socialProfileRef.current.onSelectNextTab();
                        }
                      }}
                    >
                      <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.UPGRADE_PACKAGE ? 'step2-active' : 'step2'}.svg`} alt="Upgrade package" className="step2" />
                      <h5>Choose an option</h5>
                    </div>
                    <div className="hz-line" />
                  </>
                ) : null}
                <div
                  className={tabName === CONNECTION_TABS.SOCIAL_PROFILES ? 'step active' : 'step'}
                  onClick={() => {
                    if (tabName === CONNECTION_TABS.UPGRADE_PACKAGE || !signupCouponDetails?.coupon_code) {
                      if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.SOCIAL_PROFILES}`);
                    } else if (!signupCouponDetails?.coupon_code && socialProfileRef?.current?.onSelectNextTab) {
                      socialProfileRef.current.onSelectNextTab();
                    }
                  }}
                >
                  {signupCouponDetails?.coupon_code ? (
                    <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.SOCIAL_PROFILES ? 'step3-active' : 'step3'}.svg`} alt="Social Profile" className="step3" />
                  ) : (
                    <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.SOCIAL_PROFILES ? 'step2-active' : 'step2'}.svg`} alt="Social Profile" className="step2" />
                  )}
                  <h5>Social Profiles</h5>
                </div>
                {(isContentSupplierFranchisor || isCSLocation) && currentRoute !== 'team_management/connections' ? (
                  <>
                    <div className="hz-line" />
                    <div
                      className={tabName === CONNECTION_TABS.FEED ? 'step active' : 'step'}
                      onClick={() => {
                        if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.FEED}`);
                      }}
                    >
                      {signupCouponDetails?.coupon_code ? (
                        <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.FEED ? 'step4-active' : 'step4'}.svg`} alt="feed" className="step4" />
                      ) : (
                        <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.FEED ? 'step3-active' : 'step3'}.svg`} alt="feed" className="step3" />
                      )}
                      <h5>Feed</h5>
                    </div>
                    {/* {isContentSupplierFranchisor && !isCSLocation && currentRoute !== 'team_management/connections' && (
                      <>
                        <div className="hz-line" />
                        <div
                          className={tabName === CONNECTION_TABS.SCHEDULER ? 'step active' : 'step'}
                          onClick={() => {
                            if (accountId) handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.SCHEDULER}`);
                          }}
                        >
                          {signupCouponDetails?.coupon_code ? (
                            <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.SCHEDULER ? 'step5-active' : 'step5'}.svg`} alt="scheduler" className="step5" />
                          ) : (
                            <img src={`${config.cdnImgUrl}${tabName === CONNECTION_TABS.SCHEDULER ? 'step4-active' : 'step4'}.svg`} alt="scheduler" className="step4" />
                          )}
                          <h5>Scheduler</h5>
                        </div>
                      </>
                    )} */}
                  </>
                ) : null}
              </div>
              {activateAccount ? null : <ImageValidation isImgValid defaultImg="close" customName="close" customClassname="close" onClick={handleCloseLocationDetails} />}
            </div>
          </div>
          {isProfileFetching ? (
            <Loading />
          ) : (
            <div className="sec-main__content">
              {tabName === CONNECTION_TABS.VIDEO_INTRODUCTION ? (
                <VideoIntroduction />
              ) : tabName === CONNECTION_TABS.RALLIO_PROFILE ? (
                <RallioProfileSection socialProfileRef={socialProfileRef} />
              ) : tabName === CONNECTION_TABS.UPGRADE_PACKAGE ? (
                <UpgradePackageSection />
              ) : tabName === CONNECTION_TABS.SOCIAL_PROFILES ? (
                <SocialProfileSection />
              ) : (isContentSupplierFranchisor || isCSLocation) && currentRoute !== 'team_management/connections' && tabName === CONNECTION_TABS.FEED ? (
                <TeamManagementListSection /> /* : isContentSupplierFranchisor && currentRoute !== 'team_management/connections' && tabName === CONNECTION_TABS.SCHEDULER ? (
                <SchedulerSection />
              ) */
              ) : tabName === CONNECTION_TABS.VIDEO_CONCLUSION ? (
                <VideoConclusion />
              ) : null}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
