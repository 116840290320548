import { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { apFranContainerTypes, IStore } from 'types';
import { getContentPostReqPayload } from 'utils/helpers';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import {
  addPostContentPostDataRequest,
  addPostPreviousPackageDataReset,
  addPostResetAll,
  addPostActionsResetAll,
  franchisorScheduleTreeReset,
  setAIPostContentOpen,
  resetAIContentCreator,
  resetAIImageGenerationImages,
  franchisorSyndicationResetAll,
  postSyndicateSuccessResponse,
  getPostsRequest,
  mgDetailDataRequest,
  getFranchisorSyndicationRequest,
  setCreatorTextAlertMsg
} from 'actions';
import { AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT, FranchisorSyndicationMediaType, INBOX_COMMENTS_DELAY_SECONDS, SIDE_NAV_NAME } from 'utils/constants';
import { EditorStateContext } from '../..';

export const PostSyndicatedModal = (props: apFranContainerTypes.ICreatorSyndicatedModalPopup) => {
  const dispatch = useDispatch();

  const { isModalShow, handleModalClose, handleMainModalClose } = props;

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();

  const contentSuccessMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentSuccessMsg);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const postSyndicationSuccessMsg = useSelector((state: IStore) => state.posts.postSyndicationSuccessMsg);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];

  useEffect(() => {
    if (isModalShow) {
      setTimeout(() => {
        handleModalClose();
        handleMainModalClose();
        dispatch(franchisorSyndicationResetAll());
        dispatch(postSyndicateSuccessResponse(null));
        if (props.postOrMediaId && props.mediaType) {
          dispatch(getFranchisorSyndicationRequest({ id: props.postOrMediaId, mediaType: props.mediaType }));
          if (props.mediaType === FranchisorSyndicationMediaType.CONTENT) {
            dispatch(getPostsRequest({ postsDataId: props.postOrMediaId, id, userOwnership }));
          } else {
            dispatch(
              mgDetailDataRequest({
                mediaType: props.mediaType === FranchisorSyndicationMediaType.PHOTO || props.mediaType === FranchisorSyndicationMediaType.VIDEO ? optionalParams[0] : '',
                assetId: props.postOrMediaId,
                id,
                userOwnership
              })
            );
          }
        }
        if (subNavPageName === SIDE_NAV_NAME.CONTENT_CREATOR) handleCreatorResetAll();
      }, INBOX_COMMENTS_DELAY_SECONDS);
    }
  }, [isModalShow]); // eslint-disable-line

  const handleCreatorResetAll = () => {
    setEditorState(AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT);
    if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(resetAIContentCreator());
    dispatch(resetAIImageGenerationImages());
    if (id && userOwnership) {
      const reqPayload = getContentPostReqPayload(id, userOwnership);
      dispatch(addPostContentPostDataRequest(reqPayload));
      dispatch(addPostPreviousPackageDataReset());
      dispatch(franchisorScheduleTreeReset());
      dispatch(addPostResetAll());
      dispatch(addPostActionsResetAll());
    }
  };

  return (
    <Modal className="prime-modal modal-confirmation confirm-delete" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} onHide={handleModalClose} animation={false}>
      <Modal.Body>
        <div className="cretor-postnow-modal-wrp hubuser-creator-postnow-modal-wrp">
          <div className="m-b-2re">
            <ImageValidation isImgValid defaultImg={'post-now-icon-creator'} customName={'syndicate'} />
          </div>
          <div className="message-sub-text">{contentSuccessMsg || postSyndicationSuccessMsg}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
