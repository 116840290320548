import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch } from 'react-router';

import { ROUTES } from 'utils/constants';
import { salesIsDemoModalOpen } from 'actions';
import { IStore } from 'types';

export const SalesRallioActivate = () => {
  const dispatch = useDispatch();
  const employeeAdvocacyRoute = useMatch(ROUTES.ADVOCACY_OVERVIEW);

  const brandName = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);

  return (
    // Rallio Activate  start here
    employeeAdvocacyRoute?.pathname ? (
      <div className="rallioplatform rallioactivate">
        <div className="content-main-section">
          <div className="left-section-main">
            <h3>
              [{brandName}] + <span>Rallio Activate</span>
            </h3>
            <h4>
              Massively increase your performance by activating <br /> your employees.
            </h4>
            <p className="main-para-text main-para-title">
              Because <b className="main__para--bold">real</b> images create social media engagement.
            </p>
            <p className="main-para-text main__para--subtxt">
              You have <b className="main__para--bold">”zero”</b> local images to use on social media and <b className="main__para--bold">zero</b> employees reaching their network with your content.
              Massively increase these numbers by <b className="main__para--bold">activating</b> your employees
            </p>
            <div className="quotation-text">
              <span className="quoto-icon">&#8220;</span>
              Employee recruitment is a constant need for us. Using Rallio Activate has helped immensely because now we are able to incentivize our best employees to share our hiring content. It’s a
              win, win strategy.
              <p className="para__author--txt">
                <span> Lizz Rook </span> Market Team Leader for Pet Supplies Plus #4099
              </p>
              <div className="schedule-section">
                <Button onClick={() => dispatch(salesIsDemoModalOpen(true))} className="csv-btn">
                  <span>Schedule a demo</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
    //  Rallio Activate  end here
  );
};
