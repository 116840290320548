import { useDispatch } from 'react-redux';

import { ModalPopup } from 'components';
import { commonModalPopup } from 'types';
import { STOP_CAMPAIGN_SCHEDULE_CONFIRM_MSG, MODAL_BOX_TYPES } from 'utils/constants';
import { stopCampaignEventRequest } from 'actions';

export const StopCampaignScheduleConfirmModal = ({ campaignId, eventId, isShowModal, onModalClose }: commonModalPopup.IStopCampaignConfirmModalProps) => {
  const dispatch = useDispatch();

  const handleStopCampaignSchedule = () => {
    if (campaignId && eventId) {
      dispatch(stopCampaignEventRequest({ campaignId, eventId }));
      onModalClose();
    }
  };

  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{STOP_CAMPAIGN_SCHEDULE_CONFIRM_MSG}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={onModalClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={onModalClose}>
            No
          </button>
          <button className="modal-btn-action-itm modal-delete-btn" onClick={handleStopCampaignSchedule}>
            Yes
          </button>
        </div>
      )}
    />
  );
};
