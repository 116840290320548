import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import { TOOLTIP_MOUSE_LEAVE_DELAY, TOOLTIP_TRIGGER } from 'utils/constants';
import { commonWidgetTypes } from 'types';

export const CustomTooltip = (props: commonWidgetTypes.ICustomTooltipProps) => (
  <Tooltip
    placement={props.customPlacement}
    overlayClassName={props.customClassname}
    destroyTooltipOnHide
    mouseEnterDelay={0}
    mouseLeaveDelay={TOOLTIP_MOUSE_LEAVE_DELAY}
    trigger={TOOLTIP_TRIGGER}
    overlay={props?.overlay ? props.overlay : props?.customTooltipText ? <div className="common-tooltip--wrp">{props.customTooltipText}</div> : null}
    align={{
      offset: [0, 0]
    }}
  >
    {props.customInput()}
  </Tooltip>
);
