import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { IStore, postsContainerTypes, settingsSocialProfileReducerTypes } from 'types';
import { FB_ADS_DISCONNECT_DELETE_CONFIRM_MSG, MODAL_BOX_TYPES, RIPPLE_COLOR } from 'utils/constants';
import { Loading, ModalPopup, SettingsSocialModalPopup } from 'components';
import { disconnectFBAdsTargetingChoicesRequest, getAccFacebookAdAccountDataRequest, getAccFacebookAdAccountIdRequest, saveFbUserCredentials, updateAccFacebookAdAccountIdRequest } from 'actions';
import { FacebookButton, FBAdsFacebookViewPagesModalBody } from 'containers/Settings/FBAds/SubPages';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData } from 'utils/hooks';

export const BoostConnection = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);
  // const { id, userOwnership } = useSelector((state: IStore) => ({ id: state.accountSwitcher.id, userOwnership: state.accountSwitcher.userOwnership }));
  const { fbTargetingChoice, accountUserCredential, adAccountFetching } = useSelector((state: IStore) => state.fbAdsTargeting);
  const { facebookAccountDetail, isUserDataFetching, isAdAccountFetching } = accountUserCredential;
  const userId = useSelector((state: IStore) => state.login.activeUser?.id);
  const fbUserCredentialId = useSelector((state: IStore) => state.socialProfile).fbUserCredentialId;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<postsContainerTypes.IPostDeleteItem>({ deleteId: null, deleteMsg: null });
  const [stopSpinnerOnCancel, setstopSpinnerOnCancel] = useState(false);
  const [showFBPageModal, setShowFBPageModal] = useState(false);
  const [selectedFBPageId, setSelectedFBPageId] = useState<string | null>(null);

  useEffect(() => {
    if (fbUserCredentialId) {
      dispatch(getAccFacebookAdAccountIdRequest(fbUserCredentialId));
      dispatch(getAccFacebookAdAccountDataRequest(fbUserCredentialId));
    }
  }, [fbUserCredentialId]); // eslint-disable-line

  const handleDisconnectFBAdsTarget = () => {
    setShowDeleteModal(true);
    if (id) {
      setDeleteItem({ deleteId: id, deleteMsg: FB_ADS_DISCONNECT_DELETE_CONFIRM_MSG });
    }
  };

  const handleDisconnectFBAdsTargetConfirm = () => {
    if (deleteItem.deleteId && userOwnership) {
      dispatch(disconnectFBAdsTargetingChoicesRequest({ id: deleteItem.deleteId, userOwnership }));
      setShowDeleteModal(false);
    }
  };

  const getFacebookDetails = (fbResponse: any | null) => {
    if (fbResponse && fbResponse.accessToken) {
      const payload: settingsSocialProfileReducerTypes.IFacebookCredential = {
        ...fbResponse,
        rallioUserId: userId || 0,
        app_id: fbApplicationId,
        callback: () => {
          setShowFBPageModal(true);
        }
      };
      dispatch(saveFbUserCredentials(payload));
    }
  };

  return (
    <>
      <div className="boost__white--box fb-acc--wrp r-mt2">
        <div>
          <div className="r-flx r-flx-ac boost__fb--title r-mb2">
            <div className="fb__title--icon">
              <ImageValidation isImgValid defaultImg="fb-d" customName="fb" customClassname="fb" />
            </div>
            <span className="boost__box--title">Facebook Ad Account</span>
          </div>
          <div className="boost__box--parg">
            Boost your posts and run ads through Facebook by connecting your Facebook ad account below. Before connecting and running ads, you must already have your billing information set up in your
            ad account.
          </div>
        </div>
        {fbTargetingChoice && Object.keys(fbTargetingChoice).length ? (
          <div className="fb-acc--option">
            <div className="r-flx r-flx-ac fbad__acc--details">
              <label className="f-item1">Account Name</label>
              <div className="f-item2">{fbTargetingChoice.facebook_ad_account.name}</div>
            </div>
            <div className="r-flx r-flx-ac fbad__acc--details r-mb-0">
              <label className="f-item1">Account ID</label>
              <div className="f-item2">{fbTargetingChoice.facebook_ad_account.id}</div>
            </div>
            <hr className="hr" />
            <div className="r-flx vb-detailed-abtns g-20">
              <CustomRippleButton rippleClass="ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-grey ac-outline ac-block" onClick={() => handleDisconnectFBAdsTarget()}>
                  Disconnect
                </button>
              </CustomRippleButton>
              <FacebookButton label="Change Account" stop={stopSpinnerOnCancel} isFetching={isAdAccountFetching && showFBPageModal} handler={getFacebookDetails} />
            </div>
          </div>
        ) : (
          <div className="fb-acc--option">
            <div className="r-flx">
              <FacebookButton label="Connect Now" stop={stopSpinnerOnCancel} isFetching={isAdAccountFetching && showFBPageModal} handler={getFacebookDetails} />
            </div>
          </div>
        )}
      </div>
      <ModalPopup
        isModalShow={showDeleteModal}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <div>
            <div className="modal-message-wraps">{deleteItem.deleteMsg}</div>
          </div>
        )}
        modalCloseButton
        handleModalClose={() => {
          setShowDeleteModal(false);
          setDeleteItem({ deleteId: null, deleteMsg: null });
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <CustomRippleButton rippleClass="ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline ac-block"
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteItem({ deleteId: null, deleteMsg: null });
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ac-danger-box" custColor={RIPPLE_COLOR.danger}>
              <button className="ac-btn ac-danger ac-block" onClick={() => handleDisconnectFBAdsTargetConfirm()}>
                Ok
              </button>
            </CustomRippleButton>
          </div>
        )}
      />
      {showFBPageModal ? (
        <div className="rlc-pt-modal-main-wraps">
          <SettingsSocialModalPopup
            isModalShow={showFBPageModal}
            modalCloseButton
            handleModalClose={() => setShowFBPageModal(false)}
            modalHeader={() => (
              <>
                <h3 className={`aps-head`}>Please select an Ad Account</h3>
              </>
            )}
            modalHeaderType=""
            modalBody={() =>
              !facebookAccountDetail && isUserDataFetching && adAccountFetching ? (
                <Loading />
              ) : (
                (setstopSpinnerOnCancel(false), (<FBAdsFacebookViewPagesModalBody selectedFBPageId={selectedFBPageId} handleFbPageSelect={(id: string) => setSelectedFBPageId(id)} />))
              )
            }
            modalFooter={() => (
              <div className="apmodal modal-btn-grp-wraps ap-actions">
                <Button
                  className="btnOutline modal-cancel-btn"
                  variant="outline-secondary"
                  onClick={() => {
                    setstopSpinnerOnCancel(true);
                    setShowFBPageModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={`primeblueaction events-none-opacity-${selectedFBPageId ? '' : 'pointfive'}`}
                  variant="primary"
                  onClick={() => {
                    dispatch(
                      updateAccFacebookAdAccountIdRequest({
                        accountId: id || 0,
                        userOwnership: userOwnership || '',
                        credentialId: facebookAccountDetail?.id || '',
                        adAccountId: selectedFBPageId || '',
                        callback: () => setShowFBPageModal(false)
                      })
                    );
                    setSelectedFBPageId(null);
                  }}
                >
                  Connect Now
                </Button>
              </div>
            )}
          />
        </div>
      ) : null}
    </>
  );
};
