import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem } from 'react-bootstrap';

import { NavSlider } from 'revv/components';

interface INavBarProps {
  subTabs: {
    name: string;
    label: string;
    path: string;
  }[];
  activeTab: string;
}

export const RevvNavBar = ({ subTabs, activeTab }: INavBarProps) => {
  const navigate = useNavigate();

  const sectionRef = useRef<HTMLTableSectionElement>(null);
  const navElement = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  // For Direct PermaLink changes
  useEffect(() => {
    if (activeTab) {
      const tabIndx = subTabs.findIndex((it) => it.name === activeTab);
      setActiveIndex(tabIndx || 0);
      if (subTabs[tabIndx]) {
        navigate({ pathname: subTabs[tabIndx].path });
      }
    }
  }, [activeTab, navigate, subTabs]);

  return (
    <div className="nop-psp-revv-settings-wrp lpx pt-20">
      <section className="revv-sub__tab" id="nav-section" ref={sectionRef}>
        <Nav id="settings-inner-nav" ref={navElement}>
          {subTabs.map((it, idx) => (
            <NavItem
              key={`navItem${idx}`}
              onClick={() => {
                setActiveIndex(idx);
                navigate({ pathname: it.path });
              }}
              className={`${activeTab === it.name ? 'mobile-active active' : ''}`}
            >
              {it.label}
            </NavItem>
          ))}
        </Nav>
        <NavSlider sectionRef={sectionRef} navRef={navElement} activeIndex={activeIndex} noOfItems={subTabs.length} />
      </section>
    </div>
  );
};
