import { useState, useEffect } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { IStore } from 'types';
import { RP_PRGRM_ACTION_INITIAL_OBJECT, RP_PRGRM_ACTION_PRGRM, RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE } from 'utils/constants';
import { AddProgramActions } from 'components';
import { checkExpireProgram, rpActionDataGetFormFields } from 'utils/helpers';
import { rpSetCreateProgramDataObj } from 'actions';
import { CustomDropdown } from 'widgets/CustomDropdown';

export const ProgramActions = () => {
  const dispatch = useDispatch();

  const { createProgramDataObj, promotionVaultList } = useSelector((state: IStore) => state.rewardPrograms);
  const { programDetails, programAction, modifiedStatus } = createProgramDataObj;

  const [openActionsModal, setOpenActionsModal] = useState(false);

  useEffect(() => {
    dispatch(
      rpSetCreateProgramDataObj({
        ...createProgramDataObj,
        modifiedStatus: {
          ...modifiedStatus,
          actions: programAction.length ? true : false
        }
      })
    );
  }, [programAction.length]); // eslint-disable-line

  const handleAddProgramAction = (isAddNewAction: boolean) => {
    if (isAddNewAction) {
      const newObj = JSON.parse(JSON.stringify({ ...RP_PRGRM_ACTION_INITIAL_OBJECT.actions[0] }));
      newObj.actionItem.action = programAction.length + 1;
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          programAction: [...programAction, rpActionDataGetFormFields(newObj, programDetails, promotionVaultList)]
        })
      );
    }
    setOpenActionsModal(true);
  };

  const disableActionTab = () => {
    return !modifiedStatus.details ? true : false;
  };

  return (
    <>
      <div className={`action-rewards-wrp${disableActionTab() ? ` opacity` : ` enabled-ac-rewards`}`}>
        <Alert variant="primary">
          <Alert.Heading>
            <div className="grid-sec-item1">
              <div className="left-section">Program Action and Rewards</div>
              {!programAction.length ? (
                <div className="right-section" onClick={() => handleAddProgramAction(true)}>
                  <div className="cursor-pointer">
                    <div className="info-icon">
                      <ImageValidation isImgValid defaultImg={'add-item'} customName="add" />
                    </div>
                    Add Action
                  </div>
                </div>
              ) : (
                <div className={`right-section${checkExpireProgram(programDetails?.endDate) ? ` pointer-events-none` : ``}`} onClick={() => handleAddProgramAction(false)}>
                  <div className="cursor-pointer">
                    <div className="info-icon">
                      <ImageValidation isImgValid defaultImg={'edit-action'} customName="edit" />
                    </div>
                    Edit Action
                  </div>
                </div>
              )}
            </div>
          </Alert.Heading>
        </Alert>
      </div>
      <div className={`action-rewards-wrp-edit-section${disableActionTab() ? ` pointer-events-none` : ``} ${programAction.length === 0 ? 'empty-edit' : ''}`}>
        {programAction?.length
          ? programAction.map((actionDatum, index) => {
              return (
                <div className="left-section-wrp actions-section-main" key={`rp-edited-actions-${index}`}>
                  <Card>
                    <Card.Header>
                      <div className="action-top-section">
                        <div>
                          <span className="rp-action-counts">
                            <span className="rp-ac-item">{actionDatum.actionItem.action}</span>
                          </span>
                          <span className="rp-ac-item-head">Action</span>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {actionDatum.actionItem.program === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED && (
                        <div className="description-text">
                          I want my employee advocates to submit at least
                          <span>
                            {' '}
                            {actionDatum.actionItem.value}
                            {actionDatum.actionItem.value === 1 ? ' image/video.' : ' images/videos.'}
                          </span>{' '}
                          The reward will be earned by the number of {actionDatum.actionItem.value === 1 ? ' image/video ' : ' images/videos '} submitted{' '}
                          {actionDatum.actionItem.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL ? '(Requires "like" rating)' : '(Does not require "like" rating)'}.
                        </div>
                      )}
                      {actionDatum.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS && (
                        <div className="description-text">
                          I want my employee advocates to do at least<span> {actionDatum.actionItem.value}</span> engagements (like/comment/share) of my brand content. The reward will be earned by the
                          amount of engagements generated against the {actionDatum.actionItem.value === 1 ? ' image/video' : ' images/videos'}.
                        </div>
                      )}
                      {actionDatum.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS && (
                        <div className="description-text removed-arrow-unwanted">
                          I want my employee advocates to publish at least<span> {actionDatum.actionItem.value}</span> of my branded posts. The reward will be earned by the number of posts they share.
                        </div>
                      )}
                      <div className="rp-rewards-item-highlights">
                        <span className="rewards-ribbon" />
                        <div className="rp-pa-item rp-drp-btns-wraps rp-drp-rewards-slct">
                          <div className="form-group fg-dropdown">
                            <span className="fltlabels">Rewards</span>
                            {actionDatum.rewards ? (
                              <CustomDropdown
                                classNamePrefix="rp-dats dropdown-action-items"
                                id="program-actions-reward-dropdown"
                                placeholder={''}
                                menuIsOpen={false}
                                isSearchable={false}
                                value={promotionVaultList.find((datum) => datum.value === actionDatum.rewards)}
                              />
                            ) : (
                              <span className="description-text">{actionDatum.nonCashRewards}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })
          : null}
      </div>
      {openActionsModal && <AddProgramActions isModalShow={openActionsModal} handleModalClose={() => setOpenActionsModal(false)} />}
    </>
  );
};
