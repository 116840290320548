import { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { commonModalPopup, IStore, rpApiTypes } from 'types';
import { FRAN_SCHEDULE_POST_TYPE, NoDataFoundProps, RP_ACTIONS_TYPE, RP_USER_ACTION_TYPE, Stage } from 'utils/constants';
import { franchisorScheduleTreeReset, rpRewardsModified, rpSetCreateProgramDataObj, setFranchisorScheduleLocType } from 'actions';
import { NoDataFound, ScheduleLocationSection } from 'components';
import { compareTwoObjects } from 'utils/helpers';

export const ChooseHubsAndLocations = ({ isModalShow, handleModalClose }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { createProgramDataObj, currentAction, isRewardsModified } = useSelector((state: IStore) => state.rewardPrograms);
  const locationType = useSelector((state: IStore) => state.franchisors.franScheduleTree.locationType);
  const dropdownList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);

  const { programDetails, locationsObj, programHuLocations } = createProgramDataObj;

  const [brandHubLocData, setBrandHubLocData] = useState<rpApiTypes.IRPProgramLocationData>(programHuLocations);

  const isSaveDisabled = locationType !== FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS && !brandHubLocData?.selectedLocationCount;
  const isLocationModified = !compareTwoObjects(programHuLocations, brandHubLocData);
  const isAllLocationsIncluded = programHuLocations.franchisors.find((franchisor: any) => franchisor.id === currentAccountOrFranchisor?.id);

  useEffect(() => {
    if (brandHubLocData) {
      if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified && isLocationModified) {
        dispatch(rpRewardsModified(true));
      }
    }
  }, [brandHubLocData]); // eslint-disable-line

  const handleLocationProgram = (action: string) => {
    if (action === RP_USER_ACTION_TYPE.NEXT) {
      const modifiedBrandHubLocData: rpApiTypes.IRPProgramLocationData =
        locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
          ? {
              franchisors: [{ id: currentAccountOrFranchisor?.id, name: currentAccountOrFranchisor?.name }],
              accounts: [],
              accountLists: [],
              selectedLocationCount: dropdownList?.onlyLocationList?.length || 0,
              isAllLocation: true
            }
          : brandHubLocData;
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          programHuLocations: modifiedBrandHubLocData,
          locationsObj: {
            ...locationsObj
          }
        })
      );
    } else {
      setBrandHubLocData(programHuLocations);
      dispatch(franchisorScheduleTreeReset());
      dispatch(
        setFranchisorScheduleLocType(
          programHuLocations?.isAllLocation || (programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM && isAllLocationsIncluded)
            ? FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
            : programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM
            ? FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS
            : Object.values(brandHubLocData).some((item) => Boolean(Array.isArray(item) ? item.length : item))
            ? FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS
            : ''
        )
      );
    }
    handleModalClose();
  };

  return (
    <Modal className="rewards-program-location-wrap-treeview-wrp glbl__modal--wrp rpcl" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
      <Modal.Body>
        <h3>Choose Program Hubs and Locations</h3>

        {Object.values(dropdownList).length ? (
          <div className="treeview-section-main">
            <div className="glbl__modal--wrp">
              <div className="modal-dialog modal-content modal-body" />
              <Card className="glbl__modal--card">
                <Card.Header>Where</Card.Header>
                <Card.Body>
                  <ScheduleLocationSection selectedLocationsCount={() => brandHubLocData.selectedLocationCount} setBrandHubLocData={setBrandHubLocData} isRewardProgram />
                </Card.Body>
              </Card>
            </div>
          </div>
        ) : (
          <NoDataFound size={NoDataFoundProps.SMALL} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="cretor-postnow-modal-wrp">
          <div className="modal-btn-grp-wraps">
            <div className="left-section">
              <button className="modal-btn-action-itm modal-cancel-btn" onClick={() => handleLocationProgram(RP_USER_ACTION_TYPE.PREV)}>
                Cancel
              </button>
            </div>
            <div className="right-section">
              <button
                className={`modal-btn-action-itm modal-post-now-btn${isSaveDisabled ? ` events-none pointer-events-none` : ``}`}
                disabled={isSaveDisabled}
                onClick={() => handleLocationProgram(RP_USER_ACTION_TYPE.NEXT)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
