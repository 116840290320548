import { Reducer } from 'redux';

import produce from 'immer';
import { LocationActions } from 'revv/actions';
import { ILocations } from 'revv/types';
import * as actionTypes from 'revv/actions/action-types';

const initialState: ILocations = {
  isFetching: true,
  error: '',
  locationsMetrics: null,
  locationDetailError: '',
  isCreateUpdateFetching: false,
  locationCreateUpdateError: '',
  locationsListResponse: null,
  locationDetailResponse: null,
  isDetailedViewFetching: false,
  locationMetricsError: '',
  currentPage: 1,
  sorting: '',
  isMetricsFetching: true,
  successMsg: '',
  selectedTags: null
};

export const locationsReducer: Reducer<ILocations, LocationActions> = (prevState: ILocations = initialState, action: LocationActions) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_METRICS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = true;
        nextState.locationMetricsError = '';
      });
    case actionTypes.LOCATIONS_METRICS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.locationMetricsError = action.payload;
      });
    case actionTypes.LOCATIONS_METRICS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.locationMetricsError = '';
        nextState.locationsMetrics = action.payload;
      });
    case actionTypes.LOCATIONS_LIST_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.currentPage = action.payload.pageNumber || prevState.currentPage;
        nextState.error = '';
        nextState.sorting = action.payload?.sorting || prevState.sorting;
      });
    case actionTypes.LOCATIONS_LIST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.LOCATIONS_LIST_RESPONSE:
      return produce(prevState, (nextState) => {
        if (prevState.currentPage > 1) {
          if (prevState.locationsListResponse && prevState.locationsListResponse?.length > 0) {
            nextState.locationsListResponse = prevState.locationsListResponse.concat(action.payload);
          }
        } else {
          nextState.locationsListResponse = action.payload;
        }
        nextState.isFetching = false;
      });

    case actionTypes.LOCATION_CREATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isCreateUpdateFetching = true;
        nextState.locationCreateUpdateError = '';
      });
    case actionTypes.LOCATION_CREATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.locationCreateUpdateError = action.payload;
        nextState.isCreateUpdateFetching = false;
      });
    case actionTypes.LOCATION_CREATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isCreateUpdateFetching = false;
        nextState.locationCreateUpdateError = '';
      });

    case actionTypes.LOCATION_UDPATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isCreateUpdateFetching = true;
        nextState.locationCreateUpdateError = '';
      });
    case actionTypes.LOCATION_UPDATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.locationCreateUpdateError = action.payload;
        nextState.isCreateUpdateFetching = false;
      });
    case actionTypes.LOCATION_UPDATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isCreateUpdateFetching = false;
        nextState.locationCreateUpdateError = '';
      });

    case actionTypes.LOCATION_DETAIL_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isDetailedViewFetching = true;
        nextState.locationDetailError = '';
      });
    case actionTypes.LOCATION_DETAIL_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.locationDetailError = action.payload;
        nextState.isDetailedViewFetching = false;
      });
    case actionTypes.LOCATION_DETAIL_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.locationDetailResponse = action.payload;
        nextState.isDetailedViewFetching = false;
        nextState.locationDetailError = '';
        nextState.selectedTags = action?.payload?.tags ? action.payload.tags.split(',') : null;
      });
    case actionTypes.RESET_PAGE_NUMBER:
      return produce(prevState, (nextState) => {
        if (action.payload === 'locations') {
          nextState.currentPage = initialState.currentPage;
        }
      });
    case actionTypes.UPDATE_LOCATION_TAGS:
      return produce(prevState, (nextState) => {
        nextState.selectedTags = action.payload;
      });

    case actionTypes.LOCATION_UPLOAD_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.LOCATION_UPLOAD_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.LOCATION_UPLOAD_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.successMsg = action.payload;
      });
    case actionTypes.LOCATION_RESET_UPLOAD_SUCCESS_MESSAGE:
      return produce(prevState, (nextState) => {
        nextState.successMsg = initialState.successMsg;
      });

    case actionTypes.LOCATION_DELETE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.LOCATION_DELETE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
      });
    case actionTypes.LOCATION_DELETE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
      });

    case actionTypes.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'locations' || action.payload === 'leaderboard') {
          nextState.isFetching = initialState.isFetching;
          nextState.error = initialState.error;
          nextState.locationsMetrics = initialState.locationsMetrics;
          nextState.locationDetailError = initialState.locationDetailError;
          nextState.isCreateUpdateFetching = initialState.isCreateUpdateFetching;
          nextState.locationCreateUpdateError = initialState.locationCreateUpdateError;
          nextState.locationsListResponse = initialState.locationsListResponse;
          nextState.locationDetailResponse = initialState.locationDetailResponse;
          nextState.isDetailedViewFetching = initialState.isDetailedViewFetching;
          nextState.locationMetricsError = initialState.locationMetricsError;
          nextState.currentPage = initialState.currentPage;
          nextState.sorting = initialState.sorting;
          nextState.isMetricsFetching = initialState.isMetricsFetching;
          nextState.successMsg = initialState.successMsg;
          nextState.selectedTags = initialState.selectedTags;
        }
      });
    default:
      return prevState;
  }
};
