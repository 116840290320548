import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { AISubscriptionPayment } from './AISubscriptionPayment';
import { aiStripeCustomerRequest, aiUpdateSubscriptionPlanRequest, setAISubscriptionDetailsModal, setAISubscriptionType, setSubscription, setSubscriptionMainPageType } from 'actions';
import { IStore, aiContentCreatorReducerTypes } from 'types';
import {
  AI_SUBSCRIPTION_TAB,
  DATE_FORMAT,
  ALERT_BOX_TYPES,
  AI_SUBSCRIPTION_CANCEL_SUCCESS,
  AI_SUBSCRIPTION_RENEW_FAIL,
  MONTH_DATE_YEAR_FORMAT,
  AI_SUBSCRIPTION_RENEW_SUCCESS,
  USER_OWNERSHIP,
  AI_SUBSCRIPTION_UPGRADE_YEARLY_SUCCESS
} from 'utils/constants';
import { getDateTimeAfterAWeek, getFormattedNumber, isDateInBetweenTwoDates } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { AISubscriptionSelection } from './AISubscritpionSelection';
import { alertBoxCall } from 'components/Common/AlertBox';
import { utcToLocalHandler } from 'analytics/utils';
import { AICancelSusbcription } from './AICancelSusbcription';

export const AISubscriptionMainPage = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const selectedSubscription = useSelector((state: IStore) => state.aiContentCreator.selectedSubscription);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const aiSubscriptionMainPageType = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionMainPageType);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );
  const selectedSusbcripitonActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);

  const validYearlySubscription = useSelector((state: IStore) =>
    state.aiContentCreator.selectedSubscriptionTableData?.data?.filter((it) => {
      return it.user_id === activeUserId && it.subscription_period !== 'year';
    })
  );

  const [isCancelSubscription, setCancelSusbcription] = useState<boolean>(false);

  const setView = (type: 'selection' | 'payment') => {
    dispatch(setSubscriptionMainPageType(type));
  };

  useEffect(() => {
    if (activeUserEmail && aiSubscriptionDetailsModal.type !== 'modifySubscription') {
      dispatch(aiStripeCustomerRequest(activeUserEmail || null));
    }
  }, [activeUserEmail, aiSubscriptionDetailsModal, dispatch]);

  const handleCancelSusbcription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId && !it?.subscription_device_type;
    });
    if (validSusbcription?.length && activeUserId) {
      const data = validSusbcription.map((it) => ({ ...it, auto_renewal: false }));
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_CANCEL_SUCCESS);
          }
        })
      );
    }
  };

  const handleRenewSubscription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId;
    });
    const isUpdateAutoRenewal = isDateInBetweenTwoDates(moment(validSusbcription[0]?.start_date), moment(validSusbcription[0]?.end_date), moment());
    if (validSusbcription?.length && isUpdateAutoRenewal && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: validSusbcription.map((it) => ({ ...it, auto_renewal: true }))
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_RENEW_SUCCESS);
          }
        })
      );
    } else {
      handleExpiredRenewSusbcription();
    }
  };

  const handleUpgradetoYearly = () => {
    if (validYearlySubscription.length && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: validYearlySubscription.map((it) => ({ ...it, isUpgradeSusbcription: true, subscription_period: 'year' }))
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_YEARLY_SUCCESS);
          }
        })
      );
    }
  };

  const handleExpiredRenewSusbcription = () => {
    const validSubscription = selectedSubscriptionTableData.filter(
      (it) => it.user_id === activeUserId && !currentSubscription.some((datum) => datum.franchisor_id === it.franchisor_id && datum.account_id === it.account_id)
    );
    if (validSubscription?.length && activeUserId) {
      dispatch(
        setSubscription(
          validSubscription.map(
            (it) =>
              ({
                accountId: it.account_id,
                franchisorId: it.franchisor_id,
                isChecked: true,
                userId: activeUserId
              } as aiContentCreatorReducerTypes.IAISetSusbscripiton)
          )
        )
      );
      setView('payment');
    } else {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_RENEW_FAIL);
    }
  };

  const isRenewSubscription = !selectedSusbcripitonActive
    ? selectedSubscriptionTableData
        ?.filter((it) => !currentSubscription.some((datum) => datum.franchisor_id === it.franchisor_id && datum.account_id === it.account_id))
        .filter((it) => !it.auto_renewal)?.length > 0
    : selectedSubscriptionTableData.filter((it) => it.auto_renewal)?.length === 0;

  return (
    <>
      <Modal.Header>
        <div className="rai-sub-top">
          <h2>Rallio AI Subscription</h2>
          {(aiSubscriptionDetailsModal.type !== 'modifySubscription' || aiSubscriptionMainPageType === 'payment') && (
            <p className="ai__sub--txt">
              Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio AI! Try it for free for a week and get ready to fall in love! Want to save 17%? Click the
              yearly option.
            </p>
          )}
          {aiSubscriptionDetailsModal.type === 'modifySubscription' &&
            selectedSusbcripitonActive &&
            aiSubscriptionMainPageType !== 'payment' &&
            !isRenewSubscription &&
            validYearlySubscription?.length > 0 && (
              <div className="ai-stripe-btns csuy-btns ans-btn asb-wm r-flx r-flx-ac upgrade__btn--wrp">
                <>
                  <div>
                    <button
                      // className="gnrc-btn blue-gnr ac-btn ai__sub--btn"
                      className="ac-btn ai__sub--btn upg__yr--btn"
                      onClick={handleUpgradetoYearly}
                      disabled={!selectedSubscriptionTableData?.length || selectedSubscriptionTableData[0]?.subscription_period !== 'month'}
                    >
                      <span>Upgrade to yearly</span>
                    </button>
                  </div>
                  <div className="rai-sub-top--text">
                    <p>Enjoying Rallio AI? Why not get two months FREE? Upgrade to Yearly, and get two months free each year (dropping your effective monthly payment down from $29/mo to $24/mo)!</p>
                  </div>
                </>
              </div>
            )}

          {aiSubscriptionDetailsModal.type === 'modifySubscription' && aiSubscriptionMainPageType !== 'payment' && isRenewSubscription && (
            <div className="ai-stripe-btns csuy-btns ans-btn ansb-rs">
              {selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? null : (
                // <button className="gnrc-btn blue-gnr" onClick={handleRenewSubscription}>
                <button className="ac-btn ai__sub--btn renew__sub--btn" onClick={handleRenewSubscription}>
                  <span>Renew Subscription</span>
                </button>
              )}
            </div>
          )}

          {(aiSubscriptionDetailsModal.type === 'newSubscription' || aiSubscriptionMainPageType === 'payment') && (
            <div className="rai-switch">
              {AI_SUBSCRIPTION_TAB.map((it) => (
                <div
                  key={it.value}
                  className={`raist-item ${it.value === subscriptionType.value ? 'active' : ''}`}
                  onClick={() => {
                    if (aiSubscriptionDetailsModal.type !== 'modifySubscription') dispatch(setAISubscriptionType(it));
                  }}
                >
                  <span className="raist-txt">{it.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="rai-sub-base">
          <div className="raisb-item raisb-left ai-lt">
            <div className="raisb-cnt">
              <div className="fabs">
                <div className="raisbc-field-group">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big">{selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? 'Free Trial' : subscriptionType.label}</span>
                      <span className="rais-lbl-small">(Per location per {subscriptionType.label === 'Monthly' ? 'month' : 'year'})</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value rais__value--right">{`$ ${selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? 0 : subscriptionType.price}`}</span>
                    </div>
                  </div>
                </div>

                <div className="raisbc-field-group raisbc__field--selected">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big">Locations/Hubs Selected</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value rais__value--right">
                        {aiSubscriptionDetailsModal.type === 'newSubscription' ? selectedSubscription.length : selectedSubscriptionTableData?.length}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="raisbc-field-group rais-total rais__tot--val">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big rais__lbl-tot">Total</span>
                      {aiSubscriptionDetailsModal.type === 'modifySubscription' && (
                        <div className="raisb_cnt--text">
                          Your{' '}
                          {selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length
                            ? 'plan renews'
                            : selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length
                            ? 'free trial ends'
                            : 'plan ends'}{' '}
                          on {utcToLocalHandler(selectedSubscriptionTableData[0]?.end_date || '', MONTH_DATE_YEAR_FORMAT)}
                        </div>
                      )}
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value ravs-xbold">{`$ ${
                        selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length
                          ? 0
                          : getFormattedNumber(subscriptionType.price * (aiSubscriptionDetailsModal.type === 'newSubscription' ? selectedSubscription.length : selectedSubscriptionTableData?.length))
                      }`}</span>
                    </div>
                  </div>
                  {selectedSubscription?.length ? <span className="rais-lbl-small">{`Your card will be charged post free trail on ${getDateTimeAfterAWeek(DATE_FORMAT)}`}</span> : null}
                </div>
              </div>
              {/* {aiSubscriptionDetailsModal.type === 'modifySubscription' && (
                <div className="raisb_cnt--text">
                  Your plan {selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length > 0 ? 'renews' : 'ends'} on{' '}
                  {utcToLocalHandler(selectedSubscriptionTableData[0]?.end_date || '', MONTH_DATE_YEAR_FORMAT)}
                </div>
              )} */}
              {aiSubscriptionDetailsModal.type === 'modifySubscription' &&
                selectedSusbcripitonActive &&
                aiSubscriptionMainPageType !== 'payment' &&
                selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length > 0 && (
                  <button onClick={() => setCancelSusbcription(true)} className="raisb_cnt--cancel">
                    Cancel Subscription
                  </button>
                )}
            </div>
            <div className="ai-stripe-btns r-flx r-flx-ac g-20">
              {aiSubscriptionDetailsModal.type === 'newSubscription' && aiSubscription.length > 0 && (
                <button
                  // className="gnrc-btn lined-gnr"
                  className="ac-btn ac-secondary-white ac-outline"
                  onClick={() => {
                    setView('selection');
                    const type = aiSubscriptionDetailsModal?.isTrialPreiod ? 'trialPeriod' : 'susbcriptionTable';
                    dispatch(setAISubscriptionDetailsModal({ type, isOpen: true }));
                  }}
                >
                  <span>Back</span>
                </button>
              )}
              {aiSubscriptionDetailsModal.type !== 'modifySubscription' && (
                <button
                  // className={`gnrc-btn blue-gnr ${!selectedSubscription?.length ? 'pointer-events-none' : ''}`}
                  className={`ac-btn ac-primary ${!selectedSubscription?.length ? 'pointer-events-none' : ''}`}
                  disabled={!selectedSubscription?.length}
                  onClick={() => {
                    setView('payment');
                  }}
                >
                  <span>Make Payment</span>
                </button>
              )}
            </div>
          </div>
          <div className={`raisb-item raisb-right ai-payment aips`}>
            <div className="raisb-cnt">
              {aiSubscriptionMainPageType === 'selection' ? (
                <AISubscriptionSelection />
              ) : (
                <div className="ai__payment--card">
                  <AISubscriptionPayment />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      {isCancelSubscription && <AICancelSusbcription showDeleteModal={isCancelSubscription} handleModalClose={() => setCancelSusbcription(false)} handleCancel={handleCancelSusbcription} />}
    </>
  );
};
