import { inboxApiTypes, inboxReducerTypes, scApiTypes } from 'types';
import { getUTCForCurrentTime } from 'utils/helpers';

/** Inbox - commenst like state update */
export const inboxCommentsEngagementStateUpdate = (commentsInboxResponse: inboxReducerTypes.IInboxCommentsObj, likeResponse: inboxApiTypes.IInboxEngagement) => {
  return {
    socialContentId: commentsInboxResponse.socialContentId,
    activeCommentsIds: commentsInboxResponse.activeCommentsIds,
    pageCount: {
      totalPage: 0,
      currentPage: 0
    },
    modifiedComments:
      commentsInboxResponse.modifiedComments && Object.keys(commentsInboxResponse.modifiedComments).length
        ? Object.entries(commentsInboxResponse.modifiedComments).reduce((acc, curr) => {
            if (+curr[0] === likeResponse.contentId) {
              const socialContentArray: inboxApiTypes.IInboxComments[] = curr[1].map((datum: inboxApiTypes.IInboxComments) => {
                return {
                  ...(datum.id === likeResponse.id ? { ...datum, [likeResponse.type]: likeResponse.liked } : { ...datum })
                };
              });
              acc = { ...acc, [+curr[0]]: socialContentArray };
            } else {
              acc = { ...acc, [+curr[0]]: curr[1] };
            }
            return acc;
          }, {})
        : null
  };
};

/** Inbox - Retweet state update */
export const inboxRetweetStateUpdate = (dataArray: inboxApiTypes.IInboxListSocialContents[], retweetResponse: inboxApiTypes.IInboxRetweetResponse) => {
  return dataArray.reduce((acc: inboxApiTypes.IInboxListSocialContents[], curr) => {
    if (curr.id === retweetResponse.id) {
      acc = [...acc, { ...curr, retweeted: retweetResponse.retweeted }];
    } else {
      acc = [...acc, curr];
    }
    return acc;
  }, []);
};

/** Get the inbox comments updated data */
export const getInboxCommentsUpdatedObj = (previousCommentsObj: null | inboxReducerTypes.IInboxCommentsData, contentObj: inboxApiTypes.IInboxCommentsResponse) => {
  const currentCommentsList = contentObj.comments;
  if (previousCommentsObj && previousCommentsObj[contentObj.socialContentId]) {
    previousCommentsObj[contentObj.socialContentId] = previousCommentsObj[contentObj.socialContentId].concat(currentCommentsList);
    previousCommentsObj[contentObj.socialContentId] = previousCommentsObj[contentObj.socialContentId].filter((it, index) => {
      if (previousCommentsObj) {
        return previousCommentsObj[contentObj.socialContentId].findIndex((i) => i.id === it.id) === index;
      }
      return false;
    });
  } else {
    previousCommentsObj = {};
    previousCommentsObj[contentObj.socialContentId] = currentCommentsList;
  }
  return { ...previousCommentsObj };
};

/** Insert a particular object inside an array based on index */
export const insertBasedOnIndex = <T>(arr: T[], index: number, newItem: T) => [
  // Part of the array before the specified index
  ...arr.slice(0, index),
  // Inserted item
  newItem,
  // Part of the array after the specified index
  ...arr.slice(index)
];

/** Get the inbox comments updated data based on adding reply */
export const getInboxCommentsBasedOnReplies = (commentsInboxResponse: inboxReducerTypes.IInboxCommentsObj, replyResponse: inboxApiTypes.IInboxReplyResponse) => {
  return {
    socialContentId: replyResponse.contentId || commentsInboxResponse.socialContentId,
    activeCommentsIds: commentsInboxResponse.activeCommentsIds,
    pageCount: {
      totalPage: 0,
      currentPage: 0
    },
    modifiedComments:
      commentsInboxResponse.modifiedComments && Object.keys(commentsInboxResponse.modifiedComments).length
        ? Object.entries(commentsInboxResponse.modifiedComments).reduce((acc, curr: [string, inboxApiTypes.IInboxComments[]]) => {
            if (+curr[0] === replyResponse.contentId) {
              const getIndex = curr[1].findIndex((datum) => datum.id === replyResponse?.parentCommentObj?.id);
              const addedReplyObj = replyResponse.parentCommentObj
                ? {
                    ...replyResponse.parentCommentObj,
                    id: Math.floor(Math.random() * 100),
                    message: replyResponse.message,
                    parent_social_media_id: replyResponse?.parentCommentObj?.parent_social_media_id || replyResponse?.parentCommentObj?.social_media_id,
                    social_content_feed_type: null,
                    liked: false,
                    social_content_id: replyResponse?.parentCommentObj?.social_content_id,
                    social_media_id: replyResponse?.parentCommentObj?.social_media_id || null,
                    created_time: getUTCForCurrentTime(),
                    dismissed: true,
                    can_reply: null
                  }
                : null;
              if (addedReplyObj) {
                acc = { ...acc, [+curr[0]]: insertBasedOnIndex(curr[1], getIndex + 1, addedReplyObj) };
              }
            } else {
              acc = { ...acc, [+curr[0]]: curr[1] };
            }
            return acc;
          }, {})
        : null
  };
};

/** Get the inbox comments dismissed */
export const getInboxCommentsDismissed = (commentsInboxResponse: inboxReducerTypes.IInboxCommentsObj, dismissedResponse: inboxApiTypes.IInboxDismissReqParams) => {
  return {
    socialContentId: 0,
    activeCommentsIds: commentsInboxResponse.activeCommentsIds,
    pageCount: {
      totalPage: 0,
      currentPage: 0
    },
    modifiedComments:
      commentsInboxResponse.modifiedComments && Object.keys(commentsInboxResponse.modifiedComments).length
        ? Object.entries(commentsInboxResponse.modifiedComments).reduce((acc, curr: [string, inboxApiTypes.IInboxComments[]], index) => {
            if (+curr[0] === dismissedResponse.socialContentId) {
              acc = {
                ...acc,
                [+curr[0]]: curr[1].reduce((acc: inboxApiTypes.IInboxComments[], curr) => {
                  if (dismissedResponse.commentIds.includes(curr.id)) acc = [...acc, { ...curr, dismissed: true }];
                  else acc = [...acc, curr];
                  return acc;
                }, [])
              };
            } else {
              acc = { ...acc, [+curr[0]]: curr[1] };
            }
            return acc;
          }, {})
        : null
  };
};

export const getUpdatedInboxList = (currentPageCount: number, previousList: inboxApiTypes.IInboxListSocialContents[], currentList: inboxApiTypes.IInboxListSocialContents[]) => {
  return currentPageCount > 1 ? previousList.concat(currentList) : currentList;
};

/** Inbox - like state update */
export const inboxLikeStateUpdate = (dataArray: inboxApiTypes.IInboxListSocialContents[], likeResponse: scApiTypes.ISCLikeResponse) => {
  return dataArray.reduce((acc: inboxApiTypes.IInboxListSocialContents[], curr) => {
    if (curr.id === likeResponse.id) {
      acc = [...acc, { ...curr, liked: likeResponse.liked }];
    } else {
      acc = [...acc, curr];
    }
    return acc;
  }, []);
};

/** Inbox - reply state update */
export const inboxReplyStateUpdate = (dataArray: inboxApiTypes.IInboxListSocialContents[], replyResponse: inboxApiTypes.IInboxReplyResponse) => {
  return dataArray.reduce((acc: inboxApiTypes.IInboxListSocialContents[], curr) => {
    if (curr.id === replyResponse.socialContentId) {
      acc = [...acc, { ...curr, replied: true }];
    } else {
      acc = [...acc, curr];
    }
    return acc;
  }, []);
};
