import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, settingsSocialProfilContainerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { handleLinkNavigation, disconnectSocialMedia } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { GooglePopupModal } from './GooglePopupModal';
import { useSocialMedia } from './useSocialMedia';

export const SocialProfileGoogle = ({ showPageModal, setShowPageModal, renderLabel, openPopup }: settingsSocialProfilContainerTypes.ISocialProfileGoogleProps) => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();
  const googleMedia = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT);

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const [showModal, setShowModal] = useState(false);

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  return (
    <>
      {
        <div className="csp-item d-flex justify-content-between cspGoogle">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="google-Iv" customName="social logo" />
              </div>
              <span className="sp-txt">Google</span>
            </div>
            <div className="sm-added-details">
              <span className="smadtxt">My Business Reviews</span>
            </div>
          </aside>
          {googleMedia ? (
            showModal ? (
              <div className="disconnect-notification-wrp">
                <div className="left-item">
                  <div>
                    <ImageValidation isImgValid defaultImg="connection-social-profile" customName="more" />
                  </div>
                  <span className="are-u-sure-text">Are you sure?</span>
                </div>
                <div className="right-item">
                  <div className="csp-action">
                    <CustomRippleButton rippleClass="ripple-unset ac-secondary-box clr" custColor={RIPPLE_COLOR.whiteGrey}>
                      <button className="ac-btn ac-secondary-white size-xs cl" onClick={() => setShowModal(false)}>
                        Cancel
                      </button>
                    </CustomRippleButton>
                    <CustomRippleButton rippleClass={`ripple-unset ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                      <button
                        className={`ac-btn ac-danger`}
                        onClick={() => {
                          setShowModal(false);
                          disconnectSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, googleMedia?.id, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, accountId, dispatch);
                        }}
                      >
                        {googleMedia?.needs_reconnection ? 'Remove' : 'Disconnect Now'}
                      </button>
                    </CustomRippleButton>
                  </div>
                </div>
              </div>
            ) : (
              <div className="csp-action g-15">
                <Button
                  className="ac-btn ac-link-btn primeblueaction"
                  variant="link"
                  onClick={() => {
                    handleLinkNavigation(googleMedia.profile_url);
                  }}
                >
                  View page{' '}
                </Button>
                {googleMedia?.needs_reconnection ? (
                  <CustomRippleButton rippleClass={`ripple-unset ac-danger-box r-mr2`} custColor={RIPPLE_COLOR.danger}>
                    <button className={`ac-btn ac-icon-btn ac-danger h-default r-flx r-flx-jc r-flx-ac`} onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)}>
                      <ImageValidation isImgValid defaultImg="reconnect" customClassname="reconnect" customName="Reconnect" />
                      <span>{renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT, 'light', 'Reconnect')}</span>
                    </button>
                  </CustomRippleButton>
                ) : googleMedia?.needs_updated_credentials ? (
                  <CustomRippleButton rippleClass={`ripple-unset ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
                    <button className={`ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac`} onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)}>
                      {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT, 'light', 'Update Credentials')}
                    </button>
                  </CustomRippleButton>
                ) : null}
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className="ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac" onClick={() => setShowModal(true)}>
                    {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT, 'primary', googleMedia?.needs_reconnection ? 'Remove' : 'Disconnect')}
                  </button>
                </CustomRippleButton>
              </div>
            )
          ) : (
            <div className="csp-action">
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac`} onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)}>
                  {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT, 'light', 'Connect Now')}
                </button>
              </CustomRippleButton>
            </div>
          )}
        </div>
      }
      <GooglePopupModal showGoogleAccountModal={showPageModal} setShowGoogleAccountModal={setShowPageModal} accountId={accountId} />
    </>
  );
};
