import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CommonFilter } from 'components';
import { COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useAvailablePlatforms, useParamsDeconstructor } from 'utils/hooks';
import { handleFilterChange, REVIEW_ANALYTICS_FILTER_INITIAL_VALUE, REVIEW_ANALYTICS_PLATFORM_FILTER, RATING_STATUS, RatingStatusValue } from 'analytics/utils';
import { IStore } from 'types';
import { IFiterHandleChangeData } from 'types/common/filter';

export const KeywordsModalFilter = () => {
  const navigate = useNavigate();

  const { userOwnership, id } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor(REVIEW_ANALYTICS_FILTER_INITIAL_VALUE);
  const yelpBusinessId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.yelp_business_id);

  const keywordsFilter = {
    ...filter,
    ratings: queryParams?.ratings?.split(',') || []
  };

  const availablePlatform = useAvailablePlatforms(REVIEW_ANALYTICS_PLATFORM_FILTER, userOwnership, yelpBusinessId || null);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams, availablePlatform);
    if (data.ratings && Array.isArray(data.ratings)) {
      if (data.ratings.length === 0) {
        delete queryParamsClone.ratings;
      } else {
        queryParamsClone.ratings = data.ratings.join(',');
      }
    }
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  const ratingStatus =
    queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      ? RATING_STATUS.filter((it) => it.value === RatingStatusValue.ALL || it.value === RatingStatusValue.RECOMMENDED || it.value === RatingStatusValue.NOT_RECOMMENDED)
      : !queryParams.platform
      ? RATING_STATUS
      : [...RATING_STATUS].slice(0, 6);

  return (
    <CommonFilter
      isDateRangeFilterEnable={false}
      handleChange={handleChange}
      filter={keywordsFilter}
      analyticsPlatformFilter={availablePlatform}
      isLocation={isHubUser}
      multiSelectCheckBoxFilter={[
        {
          title: 'Rating',
          filter: ratingStatus,
          value: 'ratings'
        }
      ]}
    />
  );
};
