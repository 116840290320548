import { Dispatch } from 'react';

import { capitalizeName } from '../common';
import { alertBoxCall } from 'components';
import { mgApiTypes, mgReducerTypes, aiImageGenertaionReducerTypes } from 'types';
import { addAssetReqParams, calcProgressInfo } from '../media-gallery-helpers';
import { dateFormatByRequestedFormat, getCurrentDate } from '../date-helper';
import { ALERT_BOX_TYPES, CreatorMediaTypeForUser, CREATOR_USER_OWNERSHIP, MgMediaType, MG_SOURCE_TYPE, MEDIA_PAGINATION_PER_PAGE, UTC_TIMESTAMP_FORMAT } from 'utils/constants';
import { addPostSetSelectedMediaList, cloudSignRequest, mgAddMediaReleaseResponse, mgSaveAssetRequest, mgSetAddedAssetsCount, mgSetUploadProgressInfo, mgUploadCloudMediaRequest } from 'actions';

export const addAIGeneratedImagesToCloudinary = (
  acceptedFiles: aiImageGenertaionReducerTypes.IAIGeneratedImages[],
  response: mgReducerTypes.IMGCloudinaryDataObj[],
  addedAssetsCount: number,
  aiImage: boolean,
  imgNamePrefix: string,
  dispatch: Dispatch<any>,
  mediaReleaseObj?: mgReducerTypes.IMediaReleaseSignatures,
  onComplete?: () => void
) => {
  const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
  if (currentUnixTimestamp) {
    dispatch(
      cloudSignRequest({
        unixTimestamp: +currentUnixTimestamp,
        onSignatureCallback: (cloudSign) => {
          if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length) {
            const fileUploadErrors: string[] = [];
            acceptedFiles.forEach(async (selectedFile, index) => {
              const imgFileName = `${imgNamePrefix}-${selectedFile.id}`;
              if (!mediaReleaseObj) {
                dispatch(mgSetAddedAssetsCount(addedAssetsCount + acceptedFiles.length));
              }
              const startTime = getCurrentDate().getTime();
              if (!!response.find((it) => it.fileName === imgFileName)) {
                fileUploadErrors.push(imgFileName);
              } else {
                await dispatch(
                  mgUploadCloudMediaRequest({
                    timestamp: cloudSign.timestamp,
                    apiKey: cloudSign.apikey,
                    signature: cloudSign.signature,
                    eager: cloudSign.eager.join(),
                    file: selectedFile.b64_json,
                    fileName: imgFileName || '',
                    uploadProgressCallback: (progressEvent: ProgressEvent, index: number, noOfChunks: number) => {
                      dispatch(mgSetUploadProgressInfo(calcProgressInfo(progressEvent, startTime, noOfChunks, index)));
                    },
                    isAIImage: aiImage ? true : false,
                    isMediaRelease: mediaReleaseObj ? true : false,
                    ...(mediaReleaseObj && {
                      successCallback: (response) => {
                        dispatch(mgAddMediaReleaseResponse({ ...mediaReleaseObj, signature_url: response.secure_url, id: response.public_id }));
                        if (index === acceptedFiles?.length - 1 && onComplete) {
                          onComplete();
                        }
                      }
                    })
                  })
                );
              }
            });
            if (fileUploadErrors?.length > 0) {
              alertBoxCall(
                ALERT_BOX_TYPES.ERROR,
                `${fileUploadErrors?.length > 1 ? `The Following Files ${fileUploadErrors.join(',')} are already uploaded.` : `The Following ${fileUploadErrors[0]} File is already uploaded.`}`
              );
            }
          }
        }
      })
    );
  }
};

export const handleAddAssetsUpload = (
  response: mgReducerTypes.IMGCloudinaryDataObj[],
  activeUserId: number | undefined,
  id: number,
  userOwnership: string,
  isValidId: boolean,
  activeUserFirstName: string,
  activeUserLastName: string,
  currentName: string,
  topLevelFranchisorId: number,
  topLevelFranchisor: string,
  cloudinaryDataResponse: mgApiTypes.IMGUploadCloudMediaRespData | null,
  aiPhoto: boolean,
  dispatch: Dispatch<any>,
  creatorCompName?: string | null,
  accountId?: number | null,
  isFromCreator?: boolean | null,
  onNavigate?: () => void
) => {
  if (response.length && activeUserId && id && userOwnership) {
    response.forEach(async (cloudinaryData) => {
      const creatorMgRequestPayload = getMgListReqPayloadForCreator(creatorCompName, id, accountId);
      if (cloudinaryData.mediaType && isValidId) {
        const userName = capitalizeName(activeUserFirstName, activeUserLastName);
        const reqParams = addAssetReqParams(
          cloudinaryData,
          activeUserId,
          userName,
          id,
          userOwnership,
          CreatorMediaTypeForUser.PERSONAL,
          false,
          currentName,
          topLevelFranchisorId,
          topLevelFranchisor,
          cloudinaryDataResponse,
          aiPhoto ? { ai_photo: true } : {}
        );
        await dispatch(
          mgSaveAssetRequest({
            mediaType: cloudinaryData.mediaType,
            dataParams: reqParams,
            subPageName: isFromCreator ? 'creator' : '',
            ...(onNavigate ? { onNavigate } : {}),
            isFromCreator,
            ...(isFromCreator ? { mgListReqPayload: creatorMgRequestPayload } : ''),
            isAIImage: aiPhoto
          })
        );
      }
    });
  }
};

export const getMgListReqPayloadForCreator = (creatorCompName: string | null | undefined, id: number, accountId: number | null | undefined) => {
  return {
    page: 1,
    per: MEDIA_PAGINATION_PER_PAGE,
    media_type: MgMediaType.PHOTOS,
    source: MG_SOURCE_TYPE.ALL,
    ...(creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: id } : { account_id: accountId || id })
  };
};

export const disableSaveButton = (response: mgReducerTypes.IMGCloudinaryDataObj[], addedAssetsCount: number) => {
  const disableMgUploadAction = addedAssetsCount !== response.filter((datum) => datum.cloudinaryId).length;
  return !response.length || disableMgUploadAction;
};

export const handleAddOrDeleteMedia = (isSelected: boolean, activeMedia: mgApiTypes.IMGListResponseObj, selectedMediaList: any, dispatch: any) => {
  dispatch(addPostSetSelectedMediaList(isSelected ? [...selectedMediaList].filter((it) => it.url !== activeMedia.url) : [...selectedMediaList, activeMedia]));
};

export const generateRandomNumber = () => {
  return Math.floor(Math.random() * 899999 + 100000);
};
