import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { calendarContainerTypes, commonModalPopup, IStore } from 'types';
import { CalendarView } from 'components';
import { ViewCalendarSelectedEventModal } from './ViewCalendarSelectedEvent';
import { CALENDAR_VIEW_DATE_RANGE, USER_OWNERSHIP, ICommonDateTypes, SCHEDULE_PARAM_TYPE, CALENDAR_EXCEPTION_ERROR, SIDE_NAV_NAME } from 'utils/constants';
import { utcTimeStampConverter, getStartAndEndOfUpcomingWeek, getStartAndEndOfCurrentMonth, getCurrentDate, apiEventSourcePolyfillHandler, API } from 'utils/helpers';
import { calendarViewDateRange, calendarResetAll, getScheduledPostResponse, getScheduledPostRequest } from 'actions';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';

export const ViewCalendarModal = ({ isModalShow, handleModalClose, startDate }: commonModalPopup.IViewCalendarModalProps) => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();

  const isFetching = useSelector((state: IStore) => state.calendar.isFetching);
  const hubActualData = useSelector((state: IStore) => state.calendar.hubActualData);
  const locationActualData = useSelector((state: IStore) => state.calendar.locationActualData);
  const endDate = useSelector((state: IStore) => state.calendar.calendarDateRange.endDate);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');

  const [selectedEvent, setSelectedEvent] = useState<null | calendarContainerTypes.ICalendarEventList>(null);
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const initialDate = startDate ? startDate : getCurrentDate();

  useEffect(() => {
    if (isModalShow && initialDate && !endDate) {
      const initialStartDate = getStartAndEndOfCurrentMonth(initialDate, ICommonDateTypes.START_TIME);
      const initialEndDate = getStartAndEndOfCurrentMonth(initialDate, ICommonDateTypes.END_TIME);
      dispatch(
        calendarViewDateRange({
          ...CALENDAR_VIEW_DATE_RANGE,
          startDate: initialStartDate,
          endDate: initialEndDate
        })
      );
    }
  }, [isModalShow, initialDate]); // eslint-disable-line

  const modalCloseHandler = () => {
    handleModalClose();
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_CREATOR) {
      const initialStartDate = getStartAndEndOfUpcomingWeek(initialDate, ICommonDateTypes.START_TIME);
      const initialEndDate = getStartAndEndOfUpcomingWeek(initialDate, ICommonDateTypes.END_TIME);
      dispatch(
        calendarViewDateRange({
          ...CALENDAR_VIEW_DATE_RANGE,
          startDate: initialStartDate,
          endDate: initialEndDate
        })
      );
      if (id && userOwnership && initialStartDate && initialEndDate) {
        apiEventSourcePolyfillHandler(
          API.getScheduledPost.apiPath,
          {
            hidden: 0,
            order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
            account_id: id,
            date_range: [utcTimeStampConverter(initialStartDate), utcTimeStampConverter(initialEndDate)]
          },
          'scheduled_posts',
          () => dispatch(getScheduledPostRequest()),
          (data) => {
            dispatch(getScheduledPostResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
      }
    } else {
      dispatch(calendarResetAll());
    }
  };

  return (
    <>
      <div className="creator-view-calendar-wrp-modal">
        <Modal className="add-assets creator-view-calendar-wrp-modal filter__view--calendar" show={isModalShow}>
          <div className="mod__close--icon">
            <ImageValidation isImgValid defaultImg="close-creator" customClassname="mod__close--img" onClick={() => modalCloseHandler()} customName={'close'} />
          </div>
          <Modal.Body>
            <div className="view-fullcalendarnew-wrp-section">
              <CalendarView
                actualResponse={isHubUser ? hubActualData : locationActualData}
                defaultDate={startDate}
                isHubUser={isHubUser}
                timeZone={timeZone}
                isEventsEnabled={false}
                isFetching={isFetching}
                setShowSelectedEvent={setShowSelectedEvent}
                setSelectedEvent={setSelectedEvent}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {selectedEvent?.id && (
        <ViewCalendarSelectedEventModal isModalShow={showSelectedEvent} selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} handleCloseModal={() => setShowSelectedEvent(false)} />
      )}
    </>
  );
};
