import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { AISubscriptionTrialPage } from './AISubscriptionTrialPage';
import { AISubscriptionMainPage } from './AISubscriptionMainPage';
import { AISubscriptionTable } from './AISubscriptionTable';
import { setAISubscriptionDetailsModal, setSubscriptionMainPageType } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { Loading } from 'components/Common/Loading';
import { useAccountSwitcherData } from 'utils/hooks';
import { USER_OWNERSHIP } from 'utils/constants';
import { AICoach } from './AICoach';
import { ContentSuppliersSubscriptionModal } from '../ContentSuppliersSubscriptionModal';
import { CSLocationSubscriptionModal } from '../CSLocationSubscriptionModal';

export const AISubscriptionModal = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const isFetching = useSelector((state: IStore) => state.stripe.isFetching);
  const isAiSubscriptionFetching = useSelector((state: IStore) => state.aiContentCreator.isAiSubscriptionFetching);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);

  const handleCancelSubscription = () => {
    if (!(isFetching || isAiSubscriptionFetching)) dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: false }));
  };

  useEffect(() => {
    return () => {
      dispatch(setSubscriptionMainPageType('selection'));
    };
  }, [dispatch]);

  return (
    <Modal
      className={`prime-modal lb-filter__modal ai-sub-modal ${
        aiSubscriptionDetailsModal.type === 'susbcriptionTable'
          ? 'ais-cu-wrap ai__table--wrp'
          : aiSubscriptionDetailsModal.type === 'AICoach'
          ? 'activated-rai-main'
          : aiSubscriptionDetailsModal.type === 'contentSupplierSubscription'
          ? 'tierModalMainWrap'
          : ''
      }`}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show
      animation={false}
    >
      <>
        <div className="mod__close--icon" onClick={() => handleCancelSubscription()}>
          <ImageValidation defaultImg="modal-close2" customName="cancel" customClassname="mod__close--img" />
        </div>
        {(isFetching || isAiSubscriptionFetching) && <Loading />}
        {aiSubscriptionDetailsModal.type === 'modifySubscription' || aiSubscriptionDetailsModal.type === 'newSubscription' ? (
          <AISubscriptionMainPage />
        ) : aiSubscriptionDetailsModal.type === 'susbcriptionTable' ? (
          <AISubscriptionTable />
        ) : aiSubscriptionDetailsModal.type === 'AICoach' ? (
          <AICoach />
        ) : aiSubscriptionDetailsModal.type === 'contentSupplierSubscription' ? (
          <ContentSuppliersSubscriptionModal />
        ) : aiSubscriptionDetailsModal.type === 'CSLocationAISubscription' ? (
          <CSLocationSubscriptionModal />
        ) : (
          <AISubscriptionTrialPage isAlreadySubscribed={Boolean(aiSubscription.find((it) => it[userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'franchisor_id' : 'account_id'] === id))} />
        )}
      </>
    </Modal>
  );
};
