import { IOverviewAnalyticsRequest, IOverviewAnalyticsResponse, IOverviewAnalyticsSummaryResponse } from 'analytics/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

export const getOverviewAnalyticsRequest = (payload: IOverviewAnalyticsRequest) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_REQUEST, payload);
export const getOverviewAnalyticsResponse = (payload: IOverviewAnalyticsResponse) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_RESPONSE, payload);
export const getOverviewAnalyticsFailure = (error: string) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_FAILURE, error);

export const getOverviewAnalyticsSummaryRequest = (payload: IOverviewAnalyticsRequest) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_SUMMARY_REQUEST, payload);
export const getOverviewAnalyticsSummaryResponse = (payload: IOverviewAnalyticsSummaryResponse) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_SUMMARY_RESPONSE, payload);
export const getOverviewAnalyticsSummaryFailure = (error: string) => createAction(actionTypes.GET_OVERVIEW_ANALYTICS_SUMMARY_FAILURE, error);

export const resetOverviewState = () => createAction(actionTypes.RESET_OVERVIEW_STATE);

const OVERVIEW_ANALYTICS = {
  getOverviewAnalyticsRequest,
  getOverviewAnalyticsResponse,
  getOverviewAnalyticsFailure,
  getOverviewAnalyticsSummaryRequest,
  getOverviewAnalyticsSummaryResponse,
  getOverviewAnalyticsSummaryFailure,
  resetOverviewState
};

export type OverviewAnalyticsActions = ActionsUnion<typeof OVERVIEW_ANALYTICS>;
