import { useDispatch } from 'react-redux';

import { ModalPopup } from 'components';
import { commonModalPopup } from 'types';
import { MODAL_BOX_TYPES, STOP_CAMPAIGN_CONFIRM_MSG, STOP_CAMPAIGN_CONFIRM_SEC_MSG } from 'utils/constants';
import { deactivateCampaignEventRequest } from 'actions';

export const DeactivateCampaignEventConfirmModal = ({ campaignId, isShowModal, onModalClose }: commonModalPopup.IDeleteCampaignConfirmModalProps) => {
  const dispatch = useDispatch();

  const handleDeactivateCampaignEvent = () => {
    if (campaignId) {
      dispatch(deactivateCampaignEventRequest(campaignId));
      onModalClose();
    }
  };

  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={'modal-confirmation confirm-delete pureDelete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{STOP_CAMPAIGN_CONFIRM_MSG}</div>
          <div className="modal-message-wraps">{STOP_CAMPAIGN_CONFIRM_SEC_MSG}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={onModalClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={onModalClose}>
            Cancel
          </button>
          <button className="modal-btn-action-itm modal-delete-btn" onClick={handleDeactivateCampaignEvent}>
            Stop
          </button>
        </div>
      )}
    />
  );
};
