import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CommonFilter } from 'components';
import { useAccountSwitcherData } from 'utils/hooks';
import { BUTTON_PROPS, USER_OWNERSHIP } from 'utils/constants';
import { activeFilterOptions, downloadRequest } from 'revv/actions';
import { IDownloadParams } from 'revv/types';
import { IStore } from 'types';

export const CustomersFilter = () => {
  const { userOwnership, id } = useAccountSwitcherData();
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);

  const dispatch = useDispatch();

  const isFranchisor = useMemo(() => {
    return userOwnership === USER_OWNERSHIP.FRANCHISOR;
  }, [userOwnership]);

  return (
    <CommonFilter
      filter={[]}
      sectionVariant=" revv-customer__filter"
      isDateRangeFilterEnable={false}
      handleChange={() => false}
      filterActionButtons={[
        // {
        //   ...BUTTON_PROPS.PRIMARY_BUTTON,
        //   label: 'Send Survey',
        //   parentVariant: 'filter-item connection-report border-0',
        //   value: 'sendsurvey',
        //   onClick: () => {
        //     dispatch(activeFilterOptions('sendsurvey'));
        //   },
        //   isFetching: isSendSurveyFetching
        // },
        {
          ...BUTTON_PROPS.PRIMARY_BUTTON,
          label: 'Download',
          customImage: 'csv-export.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-primary ac-block csv',
          onClick: () => {
            const payload: IDownloadParams = {
              type: 'customers',
              rallioFranchisorIds: isFranchisor && id !== topLevelFranchisorId ? [String(id)] : undefined,
              rallioLocationId: !isFranchisor ? [id] : undefined,
              rallioBrandId: isFranchisor && id === topLevelFranchisorId ? id : undefined
            };
            dispatch(downloadRequest(payload));
          }
        },
        {
          ...BUTTON_PROPS.SECONDARY_BUTTON,
          label: 'Upload',
          customImage: 'upload-white.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
          onClick: () => {
            dispatch(activeFilterOptions('customerUpload'));
          }
        }
      ]}
    />
  );
};
