export const TARGETING_LIST_REQUEST = 'targeting/TARGETING_LIST_REQUEST';
export const TARGETING_LIST_REQUEST_FAIL = 'targeting/TARGETING_LIST_REQUEST_FAIL';
export const TARGETING_LIST_RESPONSE = 'targeting/TARGETING_LIST_RESPONSE';

export const GET_ACCOUNT_CONFIG_DATA_REQUEST = 'targeting/GET_ACCOUNT_CONFIG_DATA_REQUEST';
export const GET_ACCOUNT_CONFIG_DATA_RESPONSE = 'targeting/GET_ACCOUNT_CONFIG_DATA_RESPONSE';
export const GET_ACCOUNT_CONFIG_DATA_FAILURE = 'targeting/GET_ACCOUNT_CONFIG_DATA_FAILURE';

export const ON_CHANGE_CONFIGURATION = 'targeting/ON_CHANGE_CONFIGURATION';
export const UPDATE_ACCOUNT_CONFIGURATION = 'targeting/UPDATE_ACCOUNT_CONFIGURATION';

export const MARKETING_TARGETING_RESET_ALL = 'targeting/MARKETING_TARGETING_RESET_ALL';
