import { useState, useMemo } from 'react';

import { commonWidgetTypes } from 'types';
import { ALPHABET } from 'utils/constants';
import { getSortedAlphabetList, scrollToView } from 'utils/helpers';

export const AlphabetList = ({ data, selectedData, onSelect, inputType, className }: commonWidgetTypes.IAlphabetListProps) => {
  const [activeAlphabet, setActiveAlphabet] = useState(ALPHABET[0]);

  const sortedData = useMemo(() => getSortedAlphabetList(data), [data]);

  const handleAlphabetSelect = (alpha: string) => {
    setActiveAlphabet(alpha);
    scrollToView(`alpha-data-${alpha}`);
  };

  return (
    <div className={className}>
      {/* Left section */}
      <div className="asc-list-value">
        {sortedData.map((listItem, index) => {
          const isChecked = selectedData.map((it) => it.id).includes(listItem.id);
          const capitializeFirstLetter = listItem.name.charAt(0).toUpperCase();
          return !inputType ? (
            <span id={`alpha-data-${capitializeFirstLetter}`} key={`alpha-item-${index}`} className={isChecked ? `ascl-items active` : 'ascl-items'} onClick={() => onSelect(listItem)}>
              {listItem.name}
            </span>
          ) : (
            <label id={`alpha-data-${capitializeFirstLetter}`} key={`alpha-item-${inputType}-${index}`} className={isChecked ? `${inputType}-item active` : `${inputType}-item`}>
              <span className={`${inputType}-hover`}>
                <input value={listItem.name} type={inputType} className={`option-input ${inputType}`} name={listItem.name} checked={isChecked} onChange={() => onSelect(listItem)} />
              </span>
              <span className="labelText">{listItem.name}</span>
            </label>
          );
        })}
      </div>
      {/* Right section */}
      <div className="asc-list-sort">
        {ALPHABET.map((it, index) => {
          return (
            <span key={`alpha-item-${index}`} className={`${it === activeAlphabet ? ` active` : ''}`} onClick={() => handleAlphabetSelect(it)}>
              {it}
            </span>
          );
        })}
      </div>
    </div>
  );
};
