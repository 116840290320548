import { OVERVIEW_MONTHLY_POSTS_YAXIS_SERIES, OVERVIEW_MON_CHRT_METRIC_OBJ, OVERVIEW_MON_CHRT_LABLES, OVERVIEW_MON_CHRT_GRADINET_COLOR } from 'utils/constants';
import { overviewContainerType } from 'types';

/** Overview - get valid monthly post array */
export const validatePostArray = (postArray: any[]) => {
  const validPost = {
    lastIdx: postArray.length - 1,
    dataArray: postArray
  };
  postArray.forEach(() => {
    if (postArray[validPost.lastIdx] === 0) {
      postArray[validPost.lastIdx] = null;
      if (validPost.lastIdx > 0) validPost.lastIdx = validPost.lastIdx - 1;
      validPost.dataArray = postArray;
      validatePostArray(postArray);
    }
  });
  return validPost;
};

/** Get series name based on current Social media platform */
export const handleMonthlyPostsChartSerisName = (seriesDataType: string) => {
  switch (seriesDataType) {
    case OVERVIEW_MON_CHRT_METRIC_OBJ.FB_POST_COUNT:
      return OVERVIEW_MON_CHRT_LABLES.FACEBOOK;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.TWIT_POST_COUNT:
      return OVERVIEW_MON_CHRT_LABLES.TWITTER;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.INSTA_POST_COUNT:
      return OVERVIEW_MON_CHRT_LABLES.INSTAGRAM;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.LINKIN_POST_COUNT:
      return OVERVIEW_MON_CHRT_LABLES.LINKEDIN;
    default:
      return '';
  }
};

/** Get Chart color based on current Social media platform */
export const handleMonthlyChartFillColor = (seriesDataType: string) => {
  switch (seriesDataType) {
    case OVERVIEW_MON_CHRT_METRIC_OBJ.FB_POST_COUNT:
      return OVERVIEW_MON_CHRT_GRADINET_COLOR.FB;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.TWIT_POST_COUNT:
      return OVERVIEW_MON_CHRT_GRADINET_COLOR.TWITTER;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.INSTA_POST_COUNT:
      return OVERVIEW_MON_CHRT_GRADINET_COLOR.INSTA;
    case OVERVIEW_MON_CHRT_METRIC_OBJ.LINKIN_POST_COUNT:
      return OVERVIEW_MON_CHRT_GRADINET_COLOR.LINKIN;
    default:
      return '';
  }
};

/** Get maximum y axis value to plot chart */
export const getMaxYAxisValue = (tempMaxYAxisValue: overviewContainerType.IOverviewMontlyPosts) => {
  let maxValueFromPosts: number = OVERVIEW_MONTHLY_POSTS_YAXIS_SERIES;
  if (tempMaxYAxisValue.facebookPostsCount && tempMaxYAxisValue.facebookPostsCount.length > 0) {
    const fbDatas = [...tempMaxYAxisValue.facebookPostsCount].reduce((acc: any, curr: any) => {
      acc = [...acc, curr[1]];
      return acc;
    }, []);
    const tempFBMaxValue = Math.round(Math.max(...fbDatas) + 5);
    if (tempFBMaxValue > maxValueFromPosts) maxValueFromPosts = tempFBMaxValue;
  }
  if (tempMaxYAxisValue.twitterPostsCount && tempMaxYAxisValue.twitterPostsCount.length > 0) {
    const twitDatas = [...tempMaxYAxisValue.twitterPostsCount].reduce((acc: any, curr: any) => {
      acc = [...acc, curr[1]];
      return acc;
    }, []);
    const tempTwitMaxValue = Math.round(Math.max(...twitDatas) + 5);
    if (tempTwitMaxValue > maxValueFromPosts) maxValueFromPosts = tempTwitMaxValue;
  }
  if (tempMaxYAxisValue.instagramPostsCount && tempMaxYAxisValue.instagramPostsCount.length > 0) {
    const instaDatas = [...tempMaxYAxisValue.instagramPostsCount].reduce((acc: any, curr: any) => {
      acc = [...acc, curr[1]];
      return acc;
    }, []);
    const tempInstMaxValue = Math.round(Math.max(...instaDatas) + 5);
    if (tempInstMaxValue > maxValueFromPosts) maxValueFromPosts = tempInstMaxValue;
  }
  if (tempMaxYAxisValue.linkedinPostsCount && tempMaxYAxisValue.linkedinPostsCount.length > 0) {
    const linkedInDatas = [...tempMaxYAxisValue.instagramPostsCount].reduce((acc: any, curr: any) => {
      acc = [...acc, curr[1]];
      return acc;
    }, []);
    const tempLinkInMaxValue = Math.round(Math.max(...linkedInDatas) + 5);
    if (tempLinkInMaxValue > maxValueFromPosts) maxValueFromPosts = tempLinkInMaxValue;
  }
  return maxValueFromPosts;
};
