import { Reducer } from 'redux';

import * as actionTypes from 'actions/action-types';
import { resetPasswordReducerType } from 'types/auth/reset-password';
import { ResetPasswordActions } from 'actions';
import { capitalizeName } from 'utils/helpers';

const initialState: resetPasswordReducerType.IResetPassword = {
  isFetching: false,
  error: null,
  message: null,
  userEmail: null
};

export const resetPasswordReducer: Reducer<resetPasswordReducerType.IResetPassword, ResetPasswordActions> = (
  state: resetPasswordReducerType.IResetPassword = initialState,
  action: ResetPasswordActions
) => {
  switch (action.type) {
    case actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_RESPONSE:
      return {
        ...state,
        isFetching: false,
        error: null,
        userEmail: action.payload
      };
    case actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionTypes.RESET_PASSWORD_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        isFetching: false,
        message: capitalizeName(action.payload)
      };
    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: capitalizeName(action.payload)
      };
    case actionTypes.RESET_PASSWORD_ERROR_MSG_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
