import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { apAccountReducerTypes } from 'types';
import {
  ADD_POST_SAVE_POST_NOW_SUCCESS_MSG,
  AP_ACTION_TYPES,
  ADD_POST_ACTION_TYPE,
  ADD_POST_SCHEDULE_POST_SUCCESS_MSG,
  ADD_POST_UPDATE_SAVED_POST_SUCCESS_MSG,
  ADD_POST_SAVE_TO_DATABASE_SUCCESS_MSG,
  ADD_POST_SEND_TO_APPROVAL_SUCCESS_MSG,
  AP_EDIT_CONFIRM_INIT_OBJ,
  ADD_POST_SAVE_AS_DRAFT_SUCCESS_MSG,
  AP_INSTA_PHOTO_PREVIEW_INIT_OBJ,
  AP_ADD_BOOST_FOR_SCH_POST_INIT_OBJ,
  POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG,
  COMMON_SOCIAL_PROVIDER_TYPE
} from 'utils/constants';
import { getDataForFBTagList } from 'utils/helpers';
import { AddPostAccountActions } from 'actions';

type IAddPostAccountReducer = apAccountReducerTypes.IAddPostAccountReducer;

const initialState: IAddPostAccountReducer = {
  isPostUpdate: false,
  isPostFetching: false,
  isInstaPhotosFetching: false,
  error: null,
  apActionType: null,
  addPostAction: AP_ACTION_TYPES.ADD,
  savedPostDetails: null,
  selectedTags: [],
  savedPostId: null,
  addPostMessage: null,
  couponData: {
    isListFetching: false,
    couponList: []
  },
  fbTagsData: {
    isListFetching: false,
    fbTagsList: [],
    tagErrorMessage: null
  },
  addPostinternalNotesList: [],
  editSavedPost: null,
  instaPhotoPreviewObj: AP_INSTA_PHOTO_PREVIEW_INIT_OBJ,
  fbPhotoPreviewObj: AP_INSTA_PHOTO_PREVIEW_INIT_OBJ,
  addedBoostObj: AP_ADD_BOOST_FOR_SCH_POST_INIT_OBJ,
  editConfirmObj: AP_EDIT_CONFIRM_INIT_OBJ,
  isPostNowEnabled: true
};

export const addPostAccountReducer: Reducer<IAddPostAccountReducer, AddPostAccountActions> = (state: IAddPostAccountReducer = initialState, action: AddPostAccountActions) => {
  switch (action.type) {
    case types.AP_SET_TAGS:
      return {
        ...state,
        selectedTags: Array.from(new Map(action.payload.map((dataItem) => [dataItem.name, dataItem])).values())
      };
    // Add post - Post action
    case types.AP_SAVE_POST_ACTION_REQUEST:
      return {
        ...state,
        apActionType: action.payload.actionType,
        isPostFetching: true
      };
    case types.AP_SAVE_POST_ACTION_RESPONSE:
      return {
        ...state,
        savedPostId: action.payload[0].saved_post_id,
        addPostMessage:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? `${POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG}${state.apActionType === ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS ? 's' : ''}`
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? ADD_POST_SAVE_TO_DATABASE_SUCCESS_MSG
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? ADD_POST_SEND_TO_APPROVAL_SUCCESS_MSG
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? ADD_POST_SAVE_AS_DRAFT_SUCCESS_MSG
            : null,
        isPostFetching:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? false
            : state.isPostFetching
      };
    case types.AP_SAVE_POST_ACTION_REQ_FAILURE:
      return {
        ...state,
        savedPostId: null,
        isPostFetching:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? false
            : state.isPostFetching,
        error: action.payload || 'Unexpected error'
      };
    // Add post - Save post as scheduled
    case types.AP_SAVE_POST_AS_SCHEDULED_REQUEST:
      return {
        ...state
      };
    case types.AP_SAVE_POST_AS_SCHEDULED_RESPONSE:
      return {
        ...state,
        savedPostId: null,
        addPostMessage:
          action.payload && state.apActionType === ADD_POST_ACTION_TYPE.POST_NOW
            ? ADD_POST_SAVE_POST_NOW_SUCCESS_MSG
            : action.payload && state.apActionType === ADD_POST_ACTION_TYPE.SCHEDULE_POST
            ? ADD_POST_SCHEDULE_POST_SUCCESS_MSG
            : null,
        addedBoostObj: {
          ...state.addedBoostObj,
          scheduledPostId: action.payload
        },
        isPostFetching: false
      };
    case types.AP_SAVE_POST_AS_SCHEDULED_FAILURE:
      return {
        ...state,
        savedPostId: null,
        addPostMessage: null,
        isPostFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // Add post - Coupon list
    case types.AP_COUPON_LIST_REQUEST:
      return {
        ...state,
        couponData: {
          ...state.couponData,
          isListFetching: true
        }
      };
    case types.AP_COUPON_LIST_RESPONSE:
      return {
        ...state,
        couponData: {
          isListFetching: false,
          couponList: action.payload
        }
      };
    case types.AP_COUPON_LIST_FAILURE:
      return {
        ...state,
        couponData: {
          isListFetching: false,
          couponList: []
        },
        error: action.payload || 'Unexpected error'
      };
    // Add post - Update saved post
    case types.AP_UPDATE_SAVED_POST_REQUEST:
      return {
        ...state,
        isPostUpdate: action.payload.actionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST ? true : false,
        apActionType: action.payload.actionType,
        isPostFetching: true
      };
    case types.AP_UPDATE_SAVED_POST_RESPONSE:
      return {
        ...state,
        savedPostId: action.payload,
        isPostUpdate: false,
        addPostMessage:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? `${POST_ASSIGNED_TO_CAMPAIGN_SUCCESS_MSG}${state.apActionType === ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS ? 's' : ''}`
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? ADD_POST_SAVE_AS_DRAFT_SUCCESS_MSG
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? ADD_POST_SAVE_TO_DATABASE_SUCCESS_MSG
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? ADD_POST_SEND_TO_APPROVAL_SUCCESS_MSG
            : state.apActionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST
            ? ADD_POST_UPDATE_SAVED_POST_SUCCESS_MSG
            : null,
        isPostFetching:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST
            ? false
            : state.isPostFetching
      };
    case types.AP_UPDATE_SAVED_POST_REQ_FAILURE:
      return {
        ...state,
        isPostUpdate: false,
        addPostMessage: null,
        isPostFetching:
          state.apActionType && [ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(state.apActionType)
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
            ? false
            : state.apActionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST
            ? false
            : state.isPostFetching,
        error: action.payload || 'Unexpected error'
      };
    // Add post - Get FB Tag list
    case types.AP_GET_FB_TAG_LIST_REQUEST:
      return {
        ...state,
        fbTagsData: {
          ...state.fbTagsData,
          isListFetching: true
        }
      };
    case types.AP_GET_FB_TAG_LIST_RESPONSE:
      return {
        ...state,
        fbTagsData: {
          ...state.fbTagsData,
          isListFetching: false,
          fbTagsList: getDataForFBTagList(action.payload)
        }
      };
    case types.AP_GET_FB_TAG_LIST_FAILURE:
      return {
        ...state,
        fbTagsData: {
          ...state.fbTagsData,
          isListFetching: false,
          tagErrorMessage: action.payload || 'Unexpected error'
        }
      };
    // Get Internal Notes
    case types.AP_GET_INTERNAL_NOTES_RESPONSE:
      return {
        ...state,
        addPostinternalNotesList: action.payload
      };
    case types.AP_ADD_INTERNAL_NOTES_RESPONSE:
      return {
        ...state
      };
    case types.AP_ADD_INTERNAL_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Add post - Get instagram photo preview
    case types.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_REQUEST:
      return {
        ...state,
        isInstaPhotosFetching: true,
        ...(action.payload.socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
          ? {
              fbPhotoPreviewObj: {
                ...state.fbPhotoPreviewObj,
                actionType: action.payload?.payload?.mode || ''
              }
            }
          : {
              instaPhotoPreviewObj: {
                ...state.instaPhotoPreviewObj,
                actionType: action.payload?.payload?.mode || ''
              }
            })
      };
    case types.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_RESPONSE:
      return {
        ...state,
        isInstaPhotosFetching: false,
        ...(action.payload.socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
          ? {
              fbPhotoPreviewObj: {
                ...state.fbPhotoPreviewObj,
                photoUrl: {
                  originalUrls: action.payload.response.originalUrls,
                  storyUrls: action.payload.response.storyUrls
                }
              }
            }
          : {
              instaPhotoPreviewObj: {
                ...state.instaPhotoPreviewObj,
                photoUrl: {
                  originalUrls: action.payload.response.originalUrls,
                  previewUrls: action.payload.response.previewUrls,
                  storyUrls: action.payload.response.storyUrls
                }
              }
            })
      };
    case types.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_FAILURE:
      return {
        ...state,
        isInstaPhotosFetching: false,
        ...(action.payload.socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
          ? {
              fbPhotoPreviewObj: AP_INSTA_PHOTO_PREVIEW_INIT_OBJ
            }
          : {
              instaPhotoPreviewObj: AP_INSTA_PHOTO_PREVIEW_INIT_OBJ
            }),
        error: action.payload.msg
      };
    // ADD POST - ADD BOOST FOR SCHEDULED POST
    case types.AP_ADD_BOOST_FOR_SCH_POST_REQUEST:
      return {
        ...state
      };
    case types.AP_ADD_BOOST_FOR_SCH_POST_RESPONSE:
      return {
        ...state,
        addedBoostObj: {
          ...state.addedBoostObj,
          scheduledPostId: 0,
          boostSuccessMsg: action.payload
        }
      };
    case types.AP_ADD_BOOST_FOR_SCH_POST_FAILURE:
      return {
        ...state,
        addedBoostObj: {
          ...state.addedBoostObj,
          scheduledPostId: 0,
          boostFailureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    // Get action type for post
    case types.AP_GET_ACTION_TYPE_FOR_POST:
      return {
        ...state,
        addPostAction: action.payload
      };
    case types.AP_SAVED_POST_RESET:
      return {
        ...initialState,
        addPostAction: state.addPostAction,
        apActionType: state.apActionType,
        savedPostDetails: state.savedPostDetails
      };
    case types.AP_UPDATE_SAVED_POST_DETAILS:
      return {
        ...state,
        savedPostDetails: action.payload
      };
    case types.AP_GET_EDIT_SAVED_POST_OBJ:
      return {
        ...state,
        editSavedPost: action.payload
      };
    case types.AP_SET_INSTA_PHOTO_PREVIEW_OBJ:
      return {
        ...state,
        instaPhotoPreviewObj: action.payload
      };
    case types.AP_SET_EDIT_CONFIRM_FOR_APPROVED_POST:
      return {
        ...state,
        editConfirmObj: action.payload
      };
    // Add post reset
    case types.AP_SAVE_POST_MESSAGE_RESET:
      return {
        ...state,
        addPostMessage: initialState.addPostMessage,
        savedPostId: initialState.savedPostId,
        apActionType: initialState.apActionType,
        addedBoostObj: initialState.addedBoostObj
      };
    case types.AP_FB_TAGS_LIST_RESET:
      return {
        ...state,
        fbTagsData: initialState.fbTagsData
      };
    case types.AP_ENABLE_POST_NOW:
      return {
        ...state,
        isPostNowEnabled: action.payload
      };
    case types.AP_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
