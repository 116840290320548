import parse from 'html-react-parser';

import { commonWidgetTypes } from 'types';
import { convertFBIdsToAnchorTag, convertURLAsLink, getPostedTextWithFBTagging } from 'utils/helpers';
import { /* AP_FRAN_SHOW_LINE_BREAK_TYPES, */ GREATERTHAN_REGEX, LESSTHAN_REGEX, /* LINE_BREAK_REGEX, */ SEPERATE_TEXT_TO_HASH_REGEX } from 'utils/constants';

export const Paragraph = (props: commonWidgetTypes.IParagraphProps) => {
  // const customTextWithLineBreak = props.actionType === AP_FRAN_SHOW_LINE_BREAK_TYPES.LIST_OR_DETAIL ? props.customText?.replace(LINE_BREAK_REGEX, '<br>') || '' : props.customText || '';
  const customTextWithLineBreak = props.customText || '';
  const isFbTags = customTextWithLineBreak && props.facebookTagList && props.facebookTagList.length;
  const fbTagsList = isFbTags ? props.facebookTagList || [] : undefined;
  const title = fbTagsList ? getPostedTextWithFBTagging(convertURLAsLink(customTextWithLineBreak), fbTagsList) : convertURLAsLink(customTextWithLineBreak);
  let parsedText = isFbTags ? convertFBIdsToAnchorTag(props.facebookTagList || [], convertURLAsLink(customTextWithLineBreak)) : convertURLAsLink(customTextWithLineBreak);

  parsedText = parsedText.replaceAll(SEPERATE_TEXT_TO_HASH_REGEX, (val: string) => {
    return `<span class="hngfxw3"><span data-offset-key="cgnr4-0-0"><span data-text="true">${val}</span></span></span>`;
  });

  const fbParsedText = parsedText
    .replace(LESSTHAN_REGEX, '&lt')
    .replace(GREATERTHAN_REGEX, '&gt')
    .replaceAll(`&lta id='fb_link'`, '<a')
    .replaceAll(`&gt&ltspan id='fb_link'&gt`, '><span>')
    .replaceAll('&lt/span&gt&lt/a&gt', '</span></a>')
    .replaceAll(`&ltspan class="hngfxw3"&gt&ltspan data-offset-key="cgnr4-0-0"&gt&ltspan data-text="true"&gt`, '<span class="hngfxw3"><span data-offset-key="cgnr4-0-0"><span data-text="true">')
    .replaceAll(`&lt/span&gt&lt/span&gt&lt/span&gt`, '</span></span></span>');

  return (
    <p className={props.customClassname} title={title}>
      {parse(fbParsedText)}
    </p>
  );
};
