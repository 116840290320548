import { useState, useEffect, useContext } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';

import { apAccountContainerTypes, IStore, mgApiTypes } from 'types';
import {
  MgMediaType,
  ADD_POST_IMAGES_ACTIVE_TAB,
  ADD_POST_VIDEO_ACTIVE_TAB,
  ADD_POST_IMAGES_ACTIVE_TAB_NAME,
  ADD_POST_VIDEO_ACTIVE_TAB_NAME,
  MEDIA_PAGINATION_PER_PAGE,
  MG_SOURCE_TYPE,
  AP_MEDIA_USED,
  CREATOR_USER_OWNERSHIP,
  RIPPLE_COLOR,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  MediaDropPages,
  AP_ADD_TO_POST_TAB_NAMES,
  MG_MEDIA_TYPE,
  USER_OWNERSHIP,
  AddMediaExpirationValues,
  COMMON_SOCIAL_PROVIDER_TYPE
} from 'utils/constants';
import {
  getMGListRequest,
  mgResetAll,
  mgTagsRequest,
  addPostSetSelectedMediaList,
  addPostGetSelectedContentObj,
  addPostUpdateSavedPostDetails,
  postsTagsResponse,
  mgUploadCloudMediaReqFailure,
  mgSaveAssetRequest,
  getAITagsListRequest,
  setCreatorVideoThumbnailModalName,
  mgResetCustomVideoThumbnailResponse,
  addCustomizeVideoThumbnailRequest
} from 'actions';
import { AddMediaList } from './AddMediaList';
import { AddMediaDragAndDrop } from './AddMediaDragAndDrop';
import { useAccountSwitcherData } from 'utils/hooks';
import {
  capitalizeName,
  addAssetReqParams,
  getBrandMediaCloudinaryIdsForAI,
  getBrandMediaTypeForAI,
  convertStringToEditorState,
  getContentTextWithMaximumLimit,
  getFormatBasedOnDateString,
  utcFormatForDateValue
} from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { EditorStateContext } from '..';
import { AddVideoThumbnails } from './AddVideoThumbnails';

export const AddMediaModal = (props: apAccountContainerTypes.IAddPostMediaListProps) => {
  const dispatch = useDispatch();

  const { mediaName, handleClose } = props;

  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const mediaList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const postsTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const isCloudinaryUpload = useSelector((state: IStore) => state.mediaGallery.isCloudinaryUpload);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const cloudinaryDataResponse = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.cloudinaryData);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name) || '';
  const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name) || '';
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const videoThumbnail = useSelector((state: IStore) => state.mediaGallery.videoThumbnailRes || null);
  const creatorVideoThumbnailModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorVideoThumbnailModalName);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const [activeMediaLeftTab, setActiveMediaLeftTab] = useState(mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE : ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO);
  const [searchValue, setSearchValue] = useState('');
  const [selectedMediaArr, setSelectedMediaArr] = useState<mgApiTypes.IMGListResponseObj[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [tagSearchValue, setTagSearchValue] = useState('');
  const [textEditorState, setEditorState] = useContext(EditorStateContext);

  const showCustomThumbnailModal = creatorVideoThumbnailModalName === AP_ADD_TO_POST_TAB_NAMES.THUMBNAIL;
  const isFranchisor = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  useEffect(() => {
    if ([ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES].includes(activeMediaLeftTab) && id && creatorCompName) {
      const tagsParams = { userOwnership: creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? CREATOR_USER_OWNERSHIP.ACCOUNT : userOwnership, id, searchValue: null };
      dispatch(mgTagsRequest(tagsParams));
    }
  }, [activeMediaLeftTab]); // eslint-disable-line

  useEffect(() => {
    setSelectedMediaArr([]);
    handleMediaListRequest(1);
  }, [selectedTags?.length]); // eslint-disable-line

  useEffect(() => {
    if (activeMediaLeftTab) {
      setSearchValue('');
      setSelectedTags([]);
      setSelectedMediaArr([]);
      handleMediaListRequest(1);
    }
  }, [id, creatorCompName, activeMediaLeftTab]); // eslint-disable-line

  const handleMediaListRequest = (activePage?: number) => {
    if (id && creatorCompName === CREATOR_USER_OWNERSHIP.ACCOUNT && mediaName && [ADD_POST_IMAGES_ACTIVE_TAB.MY_IMAGES, ADD_POST_VIDEO_ACTIVE_TAB.MY_VIDEOS].includes(activeMediaLeftTab)) {
      handleLocalMediaList(activePage);
    } else if (
      id &&
      creatorCompName === CREATOR_USER_OWNERSHIP.ACCOUNT &&
      mediaName &&
      [ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES, ADD_POST_VIDEO_ACTIVE_TAB.BRAND_VIDEOS].includes(activeMediaLeftTab)
    ) {
      handleBrandMediaList(activePage);
    } else if (id && creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && mediaName && [ADD_POST_IMAGES_ACTIVE_TAB.MY_IMAGES, ADD_POST_VIDEO_ACTIVE_TAB.MY_VIDEOS].includes(activeMediaLeftTab)) {
      dispatch(
        getMGListRequest({
          page: activePage || 1,
          per: MEDIA_PAGINATION_PER_PAGE,
          media_type: mediaName,
          source: MG_SOURCE_TYPE.ALL,
          franchisor_id: id,
          active: 'true',
          ...(searchValue && { search: searchValue }),
          ...(selectedTags.length && { tags: selectedTags.map((datum) => datum.name) })
        })
      );
    } else if (
      id &&
      creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT &&
      mediaName &&
      [ADD_POST_IMAGES_ACTIVE_TAB.MY_IMAGES, ADD_POST_VIDEO_ACTIVE_TAB.MY_VIDEOS].includes(activeMediaLeftTab)
    ) {
      handleLocalMediaList(activePage);
    } else if (
      id &&
      creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT &&
      mediaName &&
      [ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES, ADD_POST_VIDEO_ACTIVE_TAB.BRAND_VIDEOS].includes(activeMediaLeftTab)
    ) {
      handleBrandMediaList(activePage);
    }
  };

  const handleLocalMediaList = (activePage?: number) => {
    if (mediaName && id && creatorCompName) {
      dispatch(
        getMGListRequest({
          page: activePage || 1,
          per: MEDIA_PAGINATION_PER_PAGE,
          media_type: mediaName,
          ...(searchValue && { search: searchValue }),
          account_id: editSavedPost?.account?.id || id,
          trashed: 0,
          active: 'true'
        })
      );
    }
  };

  const handleBrandMediaList = (activePage?: number) => {
    if (mediaName && id && creatorCompName) {
      dispatch(
        getMGListRequest({
          page: activePage || 1,
          per: MEDIA_PAGINATION_PER_PAGE,
          media_type: mediaName,
          ...(searchValue && { search: searchValue }),
          source: MG_SOURCE_TYPE.ALL,
          account_id: editSavedPost?.account?.id || id,
          ...(selectedTags.length && { tags: selectedTags.map((datum) => datum.name) }),
          active: 'true'
        })
      );
    }
  };

  const handleActiveTabSelection = (activeTab: null | string) => {
    if (!activeTab) {
      setActiveMediaLeftTab(mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE : ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO);
    } else setActiveMediaLeftTab(activeTab);
    setSearchValue('');
  };

  const disableForUpdateClearMedia = () => {
    if ([ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab) && (isCloudinaryUpload || !response.length)) return true;
    else if (
      creatorCompName &&
      [CREATOR_USER_OWNERSHIP.LOCATION_CONTENT, CREATOR_USER_OWNERSHIP.ACCOUNT].includes(creatorCompName) &&
      ![ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab) &&
      !selectedMediaArr?.length
    ) {
      return true;
    } else if (
      creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR &&
      ![ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab) &&
      !selectedMediaArr?.length
    ) {
      return true;
    }
    if (
      [ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab) &&
      contentObj?.expiration?.type === AddMediaExpirationValues.EXPIRE &&
      !contentObj?.expiration?.date
    ) {
      return true;
    } else return false;
  };

  const handleClearMediaData = () => {
    if (response.length) dispatch(mgUploadCloudMediaReqFailure(null));
    else if (selectedMediaArr?.length) setSelectedMediaArr([]);
    dispatch(mgResetCustomVideoThumbnailResponse());
  };

  const handleSelectedMedia = () => {
    addSelectedMediaList();
    if (mediaList.length) dispatch(mgResetAll());
    if (postsTagsList.length) dispatch(postsTagsResponse([]));
    handleClose();
  };

  const addSelectedMediaList = () => {
    const usedMediaList = mediaName === MgMediaType.PHOTOS ? [...selectedMediaList, ...selectedMediaArr] : selectedMediaArr;
    const imgDescription = getContentTextWithMaximumLimit(
      usedMediaList.find((it) => it.comments)?.comments || '',
      creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR ? contentObj.currentTab : savedPostDetails?.current_tab || ''
    );
    if (selectedMediaArr?.length && ![ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab)) {
      dispatch(addPostSetSelectedMediaList(usedMediaList));
      if (mediaName === MgMediaType.PHOTOS && isShowAiImageTab && usedMediaList?.length) {
        dispatch(getAITagsListRequest({ cloudinaryId: getBrandMediaCloudinaryIdsForAI(usedMediaList), type: getBrandMediaTypeForAI(usedMediaList) }));
      }
    }
    if (
      response.length &&
      response.length === addedAssetsCount &&
      activeUserId &&
      id &&
      userOwnership &&
      creatorCompName &&
      [ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE, ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO].includes(activeMediaLeftTab)
    ) {
      response.forEach((cloudinaryData) => {
        if (cloudinaryData.mediaType && isValidId) {
          const userName = capitalizeName(activeUserFirstName, activeUserLastName);
          const reqParams = addAssetReqParams(
            cloudinaryData,
            activeUserId || 0,
            userName,
            id,
            creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? CREATOR_USER_OWNERSHIP.ACCOUNT : userOwnership,
            contentObj.mediaAvailForUser,
            false,
            currentName,
            topLevelFranchisorId,
            topLevelFranchisor,
            cloudinaryDataResponse,
            contentObj?.expiration.date
              ? {
                  expires_at:
                    userOwnership === USER_OWNERSHIP.FRANCHISOR
                      ? getFormatBasedOnDateString(contentObj?.expiration.date)
                      : utcFormatForDateValue(accountDetails?.account?.tzinfo_name, contentObj?.expiration.date)
                }
              : null,
            videoThumbnail
          );
          dispatch(mgSaveAssetRequest({ mediaType: cloudinaryData.mediaType, dataParams: reqParams, subPageName: 'creator' }));
        }
      });
      dispatch(mgResetAll());
    }
    if (creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && contentObj) {
      setEditorState({ ...textEditorState, ...(imgDescription && !contentObj.customText[contentObj.currentTab] && { [contentObj.currentTab]: convertStringToEditorState(imgDescription) }) });
      //  The post can be posted in instagram only when medias are available
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          ...(imgDescription &&
            !contentObj.customText[contentObj.currentTab] && {
              customText: {
                ...contentObj.customText,
                [contentObj.currentTab]: imgDescription
              }
            }),
          postStatus: {
            ...contentObj.postStatus,
            [contentObj.currentTab]:
              contentObj.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                ? contentObj?.facebook_story
                  ? true
                  : contentObj?.facebook_reel
                  ? mediaName === MgMediaType.VIDEOS
                    ? true
                    : false
                  : true
                : contentObj.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                ? true
                : contentObj.postStatus?.[contentObj.currentTab] || Boolean(imgDescription)
          },
          ...(contentObj?.customText?.instagram &&
            contentObj?.customText?.instagram.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM && {
              postStatus: {
                ...contentObj.postStatus,
                instagram: true
              }
            })
        })
      );
    } else if (savedPostDetails) {
      setEditorState({
        ...textEditorState,
        ...(imgDescription && !savedPostDetails[`${savedPostDetails.current_tab}_text`] && { [savedPostDetails.current_tab]: convertStringToEditorState(imgDescription) })
      });
      dispatch(
        addPostUpdateSavedPostDetails({
          ...savedPostDetails,
          ...(imgDescription &&
            !savedPostDetails[`${savedPostDetails.current_tab}_text`] && {
              [`${savedPostDetails.current_tab}_text`]: imgDescription
            }),
          [`use_${savedPostDetails.current_tab}`]:
            savedPostDetails.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? savedPostDetails?.facebook_story
                ? true
                : savedPostDetails?.facebook_reel
                ? mediaName === MgMediaType.VIDEOS
                  ? true
                  : false
                : true
              : savedPostDetails.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
              ? true
              : savedPostDetails?.[`use_${savedPostDetails.current_tab}`] || Boolean(imgDescription),
          ...(savedPostDetails?.instagram_text && savedPostDetails?.instagram_text.length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM && { use_instagram: true })
        })
      );
    }
  };

  const handleTabAction = (data: string | null) => {
    dispatch(setCreatorVideoThumbnailModalName(data));
    // if (response.length) dispatch(mgResetAll());
  };

  const handleThumbanil = () => {
    dispatch(setCreatorVideoThumbnailModalName(AP_ADD_TO_POST_TAB_NAMES.THUMBNAIL));
    if (activeMediaLeftTab !== ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO) addSelectedMediaList();
  };

  const handleThumbanilModalClose = () => {
    dispatch(
      addCustomizeVideoThumbnailRequest({
        id: selectedMediaList[0]?.id,
        cover_cloudinary_id: videoThumbnail[0]?.cloudinaryId || '',
        thumbnail_url: videoThumbnail[0]?.secureUrl || selectedMediaList[0]?.thumbnail_url || ''
      })
    );
    handleTabAction(null);
    if (activeMediaLeftTab !== ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO) handleClose();
  };

  const cancelCutomThumbnail = () => {
    dispatch(mgResetCustomVideoThumbnailResponse());
    handleTabAction(null);
  };
  return (
    <Modal className="creator-img-section-wrp creator-img-section__main" show={Boolean(mediaName && Object.values(MgMediaType).filter((it) => it === mediaName))} centered>
      <div className="mod__close--icon">
        <ImageValidation
          defaultImg="close-modal-icon"
          customClassname="mod__close--img"
          customName={'close'}
          onClick={() => {
            if (mediaList.length || response.length) dispatch(mgResetAll());
            if (postsTagsList.length) dispatch(postsTagsResponse([]));
            if (response.length) dispatch(mgUploadCloudMediaReqFailure(null));
            handleTabAction(null);
            handleClose();
          }}
        />
      </div>
      {/* <Modal.Header /> */}
      {!creatorVideoThumbnailModalName && (
        <Modal.Body>
          <div className="addTabs">
            <Tabs activeKey={activeMediaLeftTab} id="add-tabs" onSelect={(key: null | string) => handleActiveTabSelection(key)}>
              <Tab
                eventKey={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE : ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO}
                title={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB_NAME.ADD_NEW_IMAGE : ADD_POST_VIDEO_ACTIVE_TAB_NAME.ADD_NEW_VIDEO}
              >
                <AddMediaDragAndDrop
                  mediaName={mediaName}
                  from={MediaDropPages.MEDIA_GALLERY}
                  title={`Drag & Drop your ${mediaName === AP_ADD_TO_POST_TAB_NAMES.FILES ? 'pdf' : ''} files here to upload${
                    mediaName === AP_ADD_TO_POST_TAB_NAMES.VIDEOS ? ` (Select video up to 500 MB)` : ''
                  }`}
                />
              </Tab>
              <Tab
                eventKey={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB.MY_IMAGES : ADD_POST_VIDEO_ACTIVE_TAB.MY_VIDEOS}
                title={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB_NAME.MY_IMAGES : ADD_POST_VIDEO_ACTIVE_TAB_NAME.MY_VIDEOS}
              >
                <AddMediaList
                  mediaTabName={activeMediaLeftTab}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  mediaListRequest={handleMediaListRequest}
                  selectedMediaArr={selectedMediaArr}
                  setSelectedMediaArr={setSelectedMediaArr}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  tagSearchValue={tagSearchValue}
                  setTagSearchValue={setTagSearchValue}
                />
              </Tab>
              {creatorCompName && [CREATOR_USER_OWNERSHIP.LOCATION_CONTENT, CREATOR_USER_OWNERSHIP.ACCOUNT].includes(creatorCompName) && (
                <Tab
                  eventKey={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB.BRAND_IMAGES : ADD_POST_VIDEO_ACTIVE_TAB.BRAND_VIDEOS}
                  title={mediaName === MgMediaType.PHOTOS ? ADD_POST_IMAGES_ACTIVE_TAB_NAME.BRAND_IMAGES : ADD_POST_VIDEO_ACTIVE_TAB_NAME.BRAND_VIDEOS}
                >
                  <AddMediaList
                    mediaTabName={activeMediaLeftTab}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    mediaListRequest={handleMediaListRequest}
                    selectedMediaArr={selectedMediaArr}
                    setSelectedMediaArr={setSelectedMediaArr}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tagSearchValue={tagSearchValue}
                    setTagSearchValue={setTagSearchValue}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        </Modal.Body>
      )}
      {/* show video thumnail modal */}
      {showCustomThumbnailModal ? <AddVideoThumbnails isShowModalVideoThumbnail /> : null}
      {showCustomThumbnailModal ? (
        <div className="add__post--btn">
          <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteGrey}>
            <button className={`ac-btn ac-secondary-white ac-outline size-sm post__clear--btn border-0`} onClick={cancelCutomThumbnail}>
              Back
            </button>
          </CustomRippleButton>
          {!(videoThumbnail?.[0]?.mediaType !== MG_MEDIA_TYPE.PHOTO || !videoThumbnail?.[0]?.secureUrl) ? (
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary size-md post__image--btn`} onClick={handleThumbanilModalClose}>
                {activeMediaLeftTab === ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO ? 'Save' : 'Use Video'}
              </button>
            </CustomRippleButton>
          ) : null}
        </div>
      ) : (
        <div className="add__post--btn">
          <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp ${disableForUpdateClearMedia() ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
            <button
              className={`ac-btn ac-secondary-white ac-outline size-sm post__clear--btn border-0 ${disableForUpdateClearMedia() ? 'disabled' : ''}`}
              disabled={disableForUpdateClearMedia()}
              onClick={handleClearMediaData}
            >
              Clear
            </button>
          </CustomRippleButton>
          {!creatorVideoThumbnailModalName && mediaName === MgMediaType.VIDEOS && ((isFranchisor && activeMediaLeftTab === ADD_POST_VIDEO_ACTIVE_TAB.ADD_NEW_VIDEO) || !isFranchisor) && (
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2 ${disableForUpdateClearMedia() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary size-md post__image--btn ${disableForUpdateClearMedia() ? `disabled` : ``}`} disabled={disableForUpdateClearMedia()} onClick={handleThumbanil}>
                Custom Thumbnail
              </button>
            </CustomRippleButton>
          )}
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2 ${disableForUpdateClearMedia() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary size-md post__image--btn ${disableForUpdateClearMedia() ? `disabled` : ``}`} disabled={disableForUpdateClearMedia()} onClick={handleSelectedMedia}>
              {mediaName === MgMediaType.PHOTOS ? (selectedMediaArr?.length > 1 ? AP_MEDIA_USED.USE_IMAGES : AP_MEDIA_USED.USE_IMAGE) : AP_MEDIA_USED.USE_VIDEO}
            </button>
          </CustomRippleButton>
        </div>
      )}
    </Modal>
  );
};
