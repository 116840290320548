export const REVIEWS_REPLIED_PERCENTAGE_GAUGE: Highcharts.Options = {
  chart: {
    renderTo: 'chart-details',
    height: 100,
    // width: '130%',
    margin: 0,
    type: 'line'
  },
  title: {
    text: ''
  },
  xAxis: {
    title: {
      text: ''
    },
    labels: {
      enabled: false
    }
  },
  yAxis: {
    tickAmount: 0,
    min: 0,
    max: 100,
    title: {
      text: ''
    },
    labels: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      color: '#27AE60'
    }
  },
  tooltip: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  accessibility: {
    enabled: false
  }
};

export const REVIEWS_REPLIED_PERCENTAGE_CHART_SERIES = [
  {
    name: '% Replied',
    data: [0],
    lineWidth: 1.5,
    type: 'area',
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false
        }
      }
    },
    fillColor: {
      linearGradient: {
        x1: 0,
        x2: 0,
        y1: 1,
        y2: 0
      },
      stops: [
        [0, '#27AE60'],
        [1, 'rgba(255,255,255,0)']
      ]
    }
  }
];

export const REVIEWS_AVG_RATING_GAUGE: Highcharts.Options = {
  chart: {
    renderTo: 'chart-details',
    height: 90,
    margin: 0,
    type: 'column'
  },
  title: {
    text: ''
  },
  xAxis: {
    title: {
      text: ''
    },
    labels: {
      enabled: false
    }
  },
  yAxis: {
    tickAmount: 0,
    min: 0,
    max: 100,
    title: {
      text: ''
    },
    labels: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      stacking: 'normal'
    }
  },
  tooltip: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  accessibility: {
    enabled: false
  }
};

export const REVIEWS_AVG_RATING_CHART_SERIES = [
  {
    name: 'Avg Rating',
    data: [
      {
        color: '#FFD600',
        y: 0
      }
    ]
  }
];
