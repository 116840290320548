import { validNumericalData } from 'analytics/utils';
import { PROFILE_ANALYTICS_PIE_LEGEND, PROFILE_ANALYTICS_PLATFORM_FILTER, PROFILE_STATS } from 'analytics/utils/constants';

export const getProfileAnalyticsData = (data: { [key: string]: string | number }[], summary: { [key: string]: number }) => {
  const initialValue = {
    facebook: { profile_views: 0, button_clicks: 0, direction_requests: 0, phone_calls: 0, website_visits: 0 },
    google: { button_clicks: 0, direction_requests: 0, phone_calls: 0, profile_views: 0, website_visits: 0 }
  };
  const dataObj = data?.length
    ? data.reduce((acc, curr) => {
        acc = {
          facebook: {
            button_clicks: validNumericalData(acc.facebook.button_clicks) + validNumericalData(curr.facebook_button_clicks),
            profile_views: validNumericalData(acc.facebook.profile_views) + validNumericalData(curr.facebook_profile_views),
            direction_requests: validNumericalData(acc.facebook.direction_requests) + validNumericalData(curr.facebook_direction_requests),
            phone_calls: validNumericalData(acc.facebook.phone_calls) + validNumericalData(curr.facebook_phone_calls),
            website_visits: validNumericalData(acc.facebook.website_visits) + validNumericalData(curr.facebook_website_visits)
          },
          google: {
            button_clicks: validNumericalData(acc.google.button_clicks) + validNumericalData(curr.google_button_clicks),
            direction_requests: validNumericalData(acc.google.direction_requests) + validNumericalData(curr.google_direction_requests),
            phone_calls: validNumericalData(acc.google.phone_calls) + validNumericalData(curr.google_phone_calls),
            profile_views: validNumericalData(acc.google.profile_views) + validNumericalData(curr.google_profile_views),
            website_visits: validNumericalData(acc.google.website_visits) + validNumericalData(curr.google_website_visits)
          }
        };
        return acc;
      }, initialValue)
    : initialValue;

  const tableSummary = [...PROFILE_ANALYTICS_PIE_LEGEND].reduce((acc: { [key: string]: number }, curr) => {
    PROFILE_STATS.forEach((it) => {
      const value = it.value;
      acc[value] = (acc[value] || 0) + summary[`${curr.value}_${value}`];
      acc[`${value}_delta`] = (acc[`${value}_delta`] || 0) + summary[`${curr.value}_${value}_delta`];
    });
    return acc;
  }, {});

  return {
    tableData: [
      {
        ...dataObj.google,
        type: 'google',
        image: `${PROFILE_ANALYTICS_PLATFORM_FILTER[2].statImg}.svg`,
        button_clicks_delta: summary?.google_button_clicks_delta,
        phone_calls_delta: summary?.google_phone_calls_delta,
        profile_views_delta: summary?.google_profile_views_delta,
        direction_requests_delta: summary?.google_direction_requests_delta,
        website_visits_delta: summary?.google_website_visits_delta
      },
      {
        ...dataObj.facebook,
        type: 'facebook',
        image: `${PROFILE_ANALYTICS_PLATFORM_FILTER[1].statImg}.svg`,
        button_clicks_delta: summary?.facebook_button_clicks_delta,
        phone_calls_delta: summary?.facebook_phone_calls_delta,
        profile_views_delta: summary?.facebook_profile_views_delta,
        direction_requests_delta: summary?.facebook_direction_requests_delta,
        website_visits_delta: summary?.facebook_website_visits_delta
      }
    ],
    tableTotal: tableSummary
  };
};
