import { Button, Card } from 'react-bootstrap';
import Ripples from 'react-ripples';

import { ModalPopup } from 'revv/components';
import { config } from 'config';

interface IAlertModal {
  show: boolean;
  closeModal: () => void;
  onDelete: () => void;
  modalName: string;
}
export const AlertModal = ({ closeModal, onDelete, show, modalName }: IAlertModal) => {
  return (
    <ModalPopup
      show={show}
      centered
      className="npsp-modal__main alert-modal__container non-psp__modal"
      onHide={closeModal}
      renderBody={() => (
        <Card className="alert-modal__card">
          <aside className="alert__img">
            <img src={`${config.cdnRevvImgUrl}/alert-icon.svg`} alt="alert" className="alert__icon msg" />
          </aside>
          <section className="alert-message text-center">Are you sure you want to delete this {modalName}?</section>
          <aside className="alert__btn">
            <Ripples>
              <Button onClick={closeModal} className="cancel-btn">
                Cancel
              </Button>
            </Ripples>
            <Ripples>
              <Button onClick={onDelete} className="delete-btn">
                Delete
              </Button>
            </Ripples>
          </aside>
        </Card>
      )}
    />
  );
};
