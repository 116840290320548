// AI ASSISTANT - GET CHAT HISTORY
export const AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST = 'ai_assistant/AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST';
export const AI_ASSISTANT_GET_CHAT_HISTORY_RESPONSE = 'ai_assistant/AI_ASSISTANT_GET_CHAT_HISTORY_RESPONSE';
export const AI_ASSISTANT_GET_CHAT_HISTORY_FAILURE = 'ai_assistant/AI_ASSISTANT_GET_CHAT_HISTORY_FAILURE';

// AI ASSISTANT - CREATE CHAT HISTORY
export const AI_ASSISTANT_SEND_NEW_CHAT_REQUEST = 'ai_assistant/AI_ASSISTANT_SEND_NEW_CHAT_REQUEST';
export const AI_ASSISTANT_SEND_NEW_CHAT_RESPONSE = 'ai_assistant/AI_ASSISTANT_SEND_NEW_CHAT_RESPONSE';
export const AI_ASSISTANT_SEND_NEW_CHAT_FAILURE = 'ai_assistant/AI_ASSISTANT_SEND_NEW_CHAT_FAILURE';

// AI ASSISTANT - DELETE CHAT HISTORY
export const AI_ASSISTANT_DELETE_REQUEST = 'ai_assistant/AI_ASSISTANT_DELETE_REQUEST';
export const AI_ASSISTANT_DELETE_RESPONSE = 'ai_assistant/AI_ASSISTANT_DELETE_RESPONSE';
export const AI_ASSISTANT_DELETE_FAILURE = 'ai_assistant/AI_ASSISTANT_DELETE_FAILURE';

// RESET AI ASSISTANT
export const AI_ASSISTANT_RESET = 'ai_assistant/AI_ASSISTANT_RESET';

// AI ASSISTANT GENERATING
export const AI_ASSISTANT_GENERATING = 'ai_assistant/AI_ASSISTANT_GENERATING';
