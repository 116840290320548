import { calendarApiTypes, postsApiTypes, postsReducerTypes } from 'types';

/** Posts - Get posts list based on the account switcher */
export const getPostsListBasedOnAccountSwitcher = (payload: postsApiTypes.IPostListResponse, previousPostList: postsReducerTypes.IPostListData) => {
  let postList: postsReducerTypes.IPostListData = [];
  if (payload.content_summaries && payload.content_summaries.length) {
    postList = payload.content_summaries;
  }
  if (payload.saved_post_summaries && payload.saved_post_summaries.length) {
    postList = payload.saved_post_summaries;
  }
  postList = payload.page === 1 ? postList : previousPostList.concat(postList);
  return postList;
};

/** Posts - Get posts details data based on the account switcher */
export const getPostDetailDataBasedOnAccSwitcher = (payload: postsApiTypes.IPostDetailResponse) => {
  let postsData: postsReducerTypes.IPostDetailsData = null;
  if (payload.content && payload.content) {
    postsData = payload.content;
  } else if (payload.contents && payload.contents.length) {
    postsData = payload.contents[0];
  }
  if (payload.saved_post && payload.saved_post) {
    postsData = payload.saved_post;
  }
  return postsData;
};

/** Get the unique posts internal notes */
export const getUniquePostsInternalNotes = (internalNotes: (postsApiTypes.IPostSavedPostCommentsDetails | postsApiTypes.IPostContentInternalNotes)[]) => {
  return Array.from(new Map(internalNotes.map((dataItem) => [dataItem.id, dataItem])).values());
};

/** Get link details from saved post */
export const getLinkDetails = (savedPostDetail: postsApiTypes.IPostDetailSavedPost): calendarApiTypes.ICouponLinks[] => {
  return [
    {
      id: 1,
      url: savedPostDetail.url,
      short_url: '',
      verticals_list: '',
      tags_list: '',
      link_preview_title: savedPostDetail.link_preview_title || '',
      link_preview_description: savedPostDetail.link_preview_description || '',
      link_preview_image_url: savedPostDetail.link_preview_image_url || '',
      link_preview_image_url_choices: savedPostDetail.link_preview_image_url_choices || [],
      tracpoint_coupon_id: ''
    }
  ];
};
