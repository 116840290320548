// import { Counter } from 'components/counter';
import { ReactSpringCountUp } from 'widgets/ReactSpring';

interface IStateTiles {
  className?: string;
  title?: string;
  subTitle?: string;
  value?: number;
  isImageBox?: boolean;
  onClick?: () => void;
  isDisableFormatter?: boolean;
  isFetching?: boolean;
  imageBoxElement?: JSX.Element;
}

export const StatsCard = ({ className, title, subTitle, value, isImageBox, onClick, isDisableFormatter, imageBoxElement, isFetching }: IStateTiles) => {
  return (
    <>
      <div onClick={onClick} className={`stat-tile ${className} d-flex flex-column `}>
        <aside>
          <h3 className="title">{title}</h3>
          <h4 className="sub-title">{subTitle}</h4>
          <div className="value">{value !== undefined ? <ReactSpringCountUp end={Number(!isDisableFormatter ? value : value > 0 ? Number(value).toFixed(1) : value)} /> : ''}</div>
        </aside>
        {isImageBox ? <section className="img-box"> {imageBoxElement} </section> : null}
      </div>
    </>
  );
};
