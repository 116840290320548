import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SettingsRallioProfileActions } from 'actions';
import { settingsRallioProfileReducerTypes } from 'types/settings-rallio-profile';
import { RALLIO_PROFILE_INITIAL_VALUE } from 'utils/constants/settings-rallio-profile/settings-rallio-profile-reducer';

const initialState: settingsRallioProfileReducerTypes.ISettingsRallioProfileReducer = {
  rallioProfile: RALLIO_PROFILE_INITIAL_VALUE,
  isRallioProfileUpdated: false,
  updateProfileRespData: null,
  responseMsg: null,
  timeZones: [],
  countriesCode: [],
  postsNeedingUpdate: {
    saved_posts_needing_update: 0,
    scheduled_posts_needing_update: 0
  }
};

export const settingsRallioProfileReducer: Reducer<settingsRallioProfileReducerTypes.ISettingsRallioProfileReducer, SettingsRallioProfileActions> = (
  state: settingsRallioProfileReducerTypes.ISettingsRallioProfileReducer = initialState,
  action: SettingsRallioProfileActions
) => {
  switch (action.type) {
    // UPDATE RALLIO PROFILE STATES
    case types.SETTINGS_UPDATE_RALLIO_PROFILE:
      return {
        ...state,
        rallioProfile: action.payload,
        updateProfileRespData: null
      };
    case types.SETTINGS_UPDATE_PROFILE_STATUS:
      return {
        ...state,
        responseMsg: action.payload.msg,
        isRallioProfileUpdated: action.payload.isUpdated,
        updateProfileRespData: action.payload.resp
      };
    case types.SETTINGS_RESET_PROFILE_STATUS:
      return {
        ...state,
        responseMsg: null,
        isRallioProfileUpdated: false,
        updateProfileRespData: null
      };
    // GET TIME ZONES
    case types.GET_TIME_ZONES_REQUEST:
      return {
        ...state,
        timeZones: []
      };
    case types.GET_TIME_ZONES_RESPONSE:
      return {
        ...state,
        timeZones: action.payload
      };
    case types.GET_TIME_ZONES_FAILURE:
      return {
        ...state,
        timeZones: []
      };

    // GET COUNTRIES CODE
    case types.GET_COUNTRIES_CODE_REQUEST:
      return {
        ...state,
        countriesCode: []
      };
    case types.GET_COUNTRIES_CODE_RESPONSE:
      return {
        ...state,
        countriesCode: action.payload
      };
    case types.GET_COUNTRIES_CODE_FAILURE:
      return {
        ...state,
        countriesCode: []
      };
    case types.SETTINGS_VALIDATE_POST_PERSONALIZATION_REQUEST:
      return {
        ...state,
        postsNeedingUpdate: initialState.postsNeedingUpdate
      };
    case types.SETTINGS_VALIDATE_POST_PERSONALIZATION_RESPONSE:
      return {
        ...state,
        postsNeedingUpdate: action.payload
      };
    case types.SETTINGS_VALIDATE_POST_PERSONALIZATION_FAILURE:
      return {
        ...state,
        postsNeedingUpdate: initialState.postsNeedingUpdate
      };
    case types.RALLIO_PROFILE_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
