import { scContainerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, CREATE_POST_TYPE, SC_ENGAGEMENT_VALUE, SC_POSTS_STATUS_FILTER, SIDE_NAV_NAME } from 'utils/constants';

export const scEngagementFilterData = (platform: string) => {
  switch (platform) {
    case COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK:
      return SC_ENGAGEMENT_VALUE;
    case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM:
    case COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN:
      return SC_ENGAGEMENT_VALUE.filter((it) => it.value !== 'Message');
    case COMMON_SOCIAL_PROVIDER_TYPE.TWITTER:
      return SC_ENGAGEMENT_VALUE;
    default:
      return SC_ENGAGEMENT_VALUE.map((it) => (it.value === 'Like' ? { ...it, label: 'Like / Favorite' } : it.value === 'Comment' ? { ...it, label: 'Comment / Reply' } : { ...it }));
  }
};

export const scLikeImageUrl = (platform: string) => {
  return platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
    ? 'fb-like'
    : platform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
    ? 'twitter-like'
    : platform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
    ? 'linkedin-like'
    : platform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
    ? 'tiktok-like'
    : '';
};

export const scPlatformImagesAndTooltipText = ({ type, isPostedUsingRallio, platform, scImagesAndTooltipTextObj }: scContainerTypes.ISCImagesAndTooltipArgs) => {
  return isPostedUsingRallio &&
    type === CREATE_POST_TYPE.POST &&
    [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK].includes(platform)
    ? scImagesAndTooltipTextObj.rallio_platform
    : [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(platform)
    ? scImagesAndTooltipTextObj.instagram
    : scImagesAndTooltipTextObj[platform];
};

export const scCheckBoxFilter = (subNavPageName: string, platform: string) => {
  switch (subNavPageName) {
    case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
      return [
        {
          title: 'Posts',
          filter: SC_POSTS_STATUS_FILTER,
          value: 'posts'
        }
      ];
    case SIDE_NAV_NAME.REPUTATION_OUTBOX:
      return [
        {
          title: 'Engagement Type',
          filter: scEngagementFilterData(platform),
          value: 'engagement'
        }
      ];
    default:
      return [];
  }
};
