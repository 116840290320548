import { Loading } from './Loading';

export const LoadingWithCustomText = ({ loadingText }: { loadingText?: string }) => {
  return (
    <div className="spin-txt-wrap">
      <Loading />
      <span className="spin-txt">{loadingText || `We're crunching a lot of data for you. This may take a minute!`}</span>
    </div>
  );
};
