import { useState, useEffect } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { Formik, FormikErrors, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { CustomTooltip } from 'widgets/Tooltip';
import { commonModalPopup, IStore, rpContainerTypes, rpApiTypes, commonApiTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import {
  RP_PRGRM_ACTION_INITIAL_OBJECT,
  RP_PRGRM_ACTION_ADVCATES_TO_DO,
  RP_PRGRM_ACTION_PRGRM,
  RewardType,
  VALID_ONLY_NUMBERS_REGEX,
  RP_USER_ACTION_TYPE,
  RP_REPLACE_SPECIAL_CHAR,
  ALERT_BOX_TYPES,
  RP_BUDGET_VALIDATE_FAIL_MSG,
  RP_ACTION_VALIDATE_FAIL_MSG,
  TOOLTIP_PLACEMENT,
  RP_ACTIONS_TYPE,
  RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE
} from 'utils/constants';
import { rpActionDataGetFormFields, cpActionValidataionSchema, validateBudget, validateAction } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { rpSetCreateProgramDataObj, rpRewardsModified } from 'actions';
import { alertBoxCall } from 'components';

export const AddProgramActions = ({ isModalShow, handleModalClose }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const { promotionVaultList, createProgramDataObj, currentAction, isRewardsModified } = useSelector((state: IStore) => state.rewardPrograms);
  const { programDetails, programAction } = createProgramDataObj;
  const { rewardsType, programBudget } = programDetails;

  const [actionDataArray, setActionDataArray] = useState<any>({ actions: [] });
  const [currentButtonAction, setCurrentButtonAction] = useState('');
  const [save, setSave] = useState<rpApiTypes.IRPProgramActionData[]>([]);
  const [validFields, setValidFields] = useState(false);

  const { values, touched, errors, handleChange, setFieldValue } = useFormik({
    initialValues: actionDataArray,
    validationSchema: cpActionValidataionSchema,
    enableReinitialize: true,
    onSubmit: (values: rpContainerTypes.ICpProgramActionObject) => {
      submitActionsProgram(currentButtonAction, values.actions);
    }
  });

  useEffect(() => {
    setSave(values.actions);
  }, [values.actions]); // eslint-disable-line

  useEffect(() => {
    if (isModalShow) setActionDataArray({ actions: programAction });
  }, [isModalShow]); // eslint-disable-line

  useEffect(() => {
    if ([RP_USER_ACTION_TYPE.PREV, RP_USER_ACTION_TYPE.NEXT].includes(currentButtonAction)) {
      submitActionsProgram(currentButtonAction, save);
    }
  }, [currentButtonAction, save]); // eslint-disable-line

  const submitActionsProgram = (action: string, actionFormValues: rpApiTypes.IRPProgramActionData[]) => {
    if (id && userOwnership) {
      let budget = programBudget;
      budget = budget ? budget?.replace(RP_REPLACE_SPECIAL_CHAR.SPECIAL_CHAR, '') : `${0}`;
      const isBudgetValid = validateBudget(actionFormValues, promotionVaultList, budget, rewardsType);
      const isActionValid = validateAction(actionFormValues);
      if (action === RP_USER_ACTION_TYPE.NEXT) {
        const validateBoth = isBudgetValid && isActionValid ? true : false;
        if (validateBoth) {
          dispatch(
            rpSetCreateProgramDataObj({
              ...createProgramDataObj,
              programAction: actionFormValues || []
            })
          );
          handleModalClose();
          setCurrentButtonAction('');
        }
        if (!isBudgetValid) {
          alertBoxCall(ALERT_BOX_TYPES.ERROR, RP_BUDGET_VALIDATE_FAIL_MSG);
          setCurrentButtonAction('');
        }
        if (!isActionValid) {
          alertBoxCall(ALERT_BOX_TYPES.ERROR, RP_ACTION_VALIDATE_FAIL_MSG);
          setCurrentButtonAction('');
        }
      } else if (action === RP_USER_ACTION_TYPE.PREV) {
        dispatch(
          rpSetCreateProgramDataObj({
            ...createProgramDataObj,
            programAction: createProgramDataObj.programAction.filter((datum) => datum.actionItem.value)
          })
        );
        handleModalClose();
        setCurrentButtonAction('');
      }
    }
  };

  const handleActionIdx = (updatedAction: rpApiTypes.IRPProgramActionData[]) => {
    const newObj = JSON.parse(JSON.stringify({ ...RP_PRGRM_ACTION_INITIAL_OBJECT.actions[0] }));
    newObj.actionItem.action = updatedAction.length + 1;
    updatedAction.push(rpActionDataGetFormFields(newObj, programDetails, promotionVaultList));
    setSave(updatedAction);
    setActionDataArray({ actions: updatedAction });
    if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
      dispatch(rpRewardsModified(true));
    }
  };

  const disableNextAction = (actionsArray: rpApiTypes.IRPProgramActionData[], errors: FormikErrors<{ actions: commonApiTypes.ICommonResponseData[] }>) => {
    let disableAction = false;
    if (actionsArray && actionsArray.length) {
      const filteredActionsArray = actionsArray.map((datum) => datum.actionItem.program);
      const validActionsProgram = filteredActionsArray.filter((datum) => datum);
      const filteredNonRewardsArray = actionsArray.map((datum) => datum.nonCashRewards);
      const validNonCashReward = filteredNonRewardsArray.filter((datum) => datum);
      const filteredRewardsArray = actionsArray.map((datum) => datum.rewards);
      const validCashReward = filteredRewardsArray.filter((datum) => datum);
      if (rewardsType === RewardType.NON_CASH_PRIZE) {
        if (actionsArray.length !== validNonCashReward.length) disableAction = true;
        else if (actionsArray.length !== validActionsProgram.length) disableAction = true;
        else if (actionsArray.length === validActionsProgram.length && Object.keys(errors).length) disableAction = true;
        else disableAction = false;
      } else {
        if (actionsArray.length !== validCashReward.length) disableAction = true;
        else if (actionsArray.length !== validActionsProgram.length) disableAction = true;
        else if (actionsArray.length === validActionsProgram.length && Object.keys(errors).length) disableAction = true;
        else disableAction = false;
      }
    }
    return disableAction;
  };

  const validateIsError = (errors: any, touched: any, idx: number, isActionItem: boolean, option: string) => {
    let isError: boolean = false;
    const errorsAction = errors.actions;
    const touchedAction = touched.actions;
    if (errorsAction && touchedAction && errorsAction.length && touchedAction.length) {
      if (touchedAction[idx]) {
        if (isActionItem && touchedAction[idx] && touchedAction[idx].actionItem && touchedAction[idx].actionItem[option]) {
          isError = errorsAction[idx] && errorsAction[idx].actionItem && errorsAction[idx].actionItem[option] ? true : false;
        } else if (!isActionItem && touchedAction[idx] && touchedAction[idx][option]) {
          isError = errorsAction[idx] && errorsAction[idx][option] ? true : false;
        }
      }
    }
    return isError;
  };

  const handleInputActiveClassname = (isActive: boolean) => {
    return `checkbox-item radio-item${isActive ? ` active` : ''}`;
  };

  const handleInputCheckedClassaname = (isChecked: boolean) => {
    return `checkmark${isChecked ? ` checked` : ``}`;
  };

  const handleMediaLabel = (isActive: boolean, value: number) => {
    return isActive && value > 1 ? 'images/videos' : 'image/video';
  };

  return (
    <Modal
      className="prime-modal modal-confirmation confirm-delete add-program-acctions-modal-wrp-sec"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation={false}
    >
      <Modal.Body>
        <Formik
          initialValues={actionDataArray}
          validationSchema={cpActionValidataionSchema}
          enableReinitialize
          onSubmit={(values: rpContainerTypes.ICpProgramActionObject) => {
            submitActionsProgram(currentButtonAction, values.actions);
          }}
        >
          <div className="mainContent">
            <div className="details-section-tab-wrp">
              <div className="details-section-scrol-wrp">
                <div className="header-section-main">
                  <div className="header-left">
                    <h3>Program Action and Rewards</h3>
                  </div>
                  <div className="right-section" onClick={() => handleActionIdx(values.actions)}>
                    <div className="info-icon">
                      <ImageValidation isImgValid defaultImg="add-item" customName="add" />
                    </div>
                    <h3>Add Action</h3>
                  </div>
                </div>
                <div className="details-content-section-main dcs-rewards">
                  {values.actions &&
                    values.actions.map((dataItem, idx: number) => {
                      return (
                        <div className="left-section-wrp actions-section-main" key={`reward-program-actions-${idx}`}>
                          <Card>
                            <Card.Header>
                              <div className="action-top-section">
                                <div>
                                  <span className="rp-action-counts">
                                    <span className="rp-ac-item">{idx + 1}</span>
                                  </span>
                                  <span className="rp-ac-item-head">Action</span>
                                </div>
                                {dataItem.actionItem.action > 1 && (
                                  <div className="del-icon">
                                    <span
                                      onClick={() => {
                                        if (values.actions.length > 1) {
                                          setFieldValue(
                                            'actions',
                                            values.actions.filter((_, actionIndex) => actionIndex !== idx)
                                          );
                                        }
                                        if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                          dispatch(rpRewardsModified(true));
                                        }
                                        if (validFields) setValidFields(false);
                                      }}
                                      className="rp-ac-delete"
                                    />
                                  </div>
                                )}
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <div className="actions-section-questions">
                                <h4 className="title-text">What do you want your employee advocates to do?</h4>
                                <div className={`gender-controls${validFields && !dataItem.actionItem.employeesTodo && disableNextAction(save, errors) ? ` erroritem error-disp` : ``}`}>
                                  <label className={handleInputActiveClassname(dataItem?.actionItem?.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.imgsVideo)}>
                                    <input
                                      name={`actions.${idx}.actionItem.employeesTodo`}
                                      className="rpdatsn form-control"
                                      type="radio"
                                      autoComplete="off"
                                      value={dataItem?.actionItem?.employeesTodo}
                                      onClick={() => {
                                        setFieldValue(`actions.${idx}.actionItem.program`, '');
                                        setFieldValue(`actions.${idx}.actionItem.programMeasure`, '');
                                        setFieldValue(`actions.${idx}.actionItem.value`, '');
                                        setFieldValue(`actions.${idx}.actionItem.employeesTodo`, RP_PRGRM_ACTION_ADVCATES_TO_DO.imgsVideo);
                                        if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                          dispatch(rpRewardsModified(true));
                                        }
                                      }}
                                    />
                                    <span className="label-txt">Submit Images/Videos</span>
                                    <span className={handleInputCheckedClassaname(dataItem?.actionItem?.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.imgsVideo)} />
                                  </label>

                                  <label className={handleInputActiveClassname(dataItem?.actionItem?.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.brandApprCnt)}>
                                    <input
                                      name={`actions.${idx}.actionItem.employeesTodo`}
                                      className="rpdatsn form-control"
                                      type="radio"
                                      autoComplete="off"
                                      value={RP_PRGRM_ACTION_ADVCATES_TO_DO.brandApprCnt}
                                      onClick={(event) => {
                                        setFieldValue(`actions.${idx}.actionItem.program`, '');
                                        setFieldValue(`actions.${idx}.actionItem.programMeasure`, '');
                                        setFieldValue(`actions.${idx}.actionItem.value`, '');
                                        setFieldValue(`actions.${idx}.actionItem.employeesTodo`, RP_PRGRM_ACTION_ADVCATES_TO_DO.brandApprCnt);
                                        if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                          dispatch(rpRewardsModified(true));
                                        }
                                      }}
                                    />
                                    <span className="label-txt">Publish Brand Approved Content</span>
                                    <span className={handleInputCheckedClassaname(dataItem?.actionItem?.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.brandApprCnt)} />
                                  </label>
                                </div>
                              </div>

                              <div className="actions-section-questions">
                                <h4 className="title-text">How will you measure success?</h4>
                                {/* Submit Images/Videos */}
                                {dataItem.actionItem.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.imgsVideo && (
                                  <div
                                    className={`gender-controls hwms${
                                      validFields && (!dataItem.actionItem.program || !dataItem.actionItem.value) && disableNextAction(save, errors) ? ` erroritem error-disp` : ``
                                    }`}
                                  >
                                    <label className={handleInputActiveClassname(dataItem?.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL)}>
                                      <input
                                        name={`actions.${idx}.actionItem.programMeasure`}
                                        className="rpdatsn form-control"
                                        type="radio"
                                        autoComplete="off"
                                        value={RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL}
                                        onClick={() => {
                                          setFieldValue(`actions.${idx}.actionItem.program`, RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED);
                                          setFieldValue(`actions.${idx}.actionItem.value`, '');
                                          setFieldValue(`actions.${idx}.actionItem.programMeasure`, RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL);
                                          if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                            dispatch(rpRewardsModified(true));
                                          }
                                        }}
                                      />
                                      <span className="label-txt">
                                        By the number of {handleMediaLabel(dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL, dataItem.actionItem.value)} submitted
                                        (Requires "like" rating)
                                      </span>
                                      <span className={handleInputCheckedClassaname(dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL)} />
                                      <CustomTooltip
                                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                                        customClassname={'custom-tooltip-long-text otmWrap'}
                                        overlay={
                                          <div className="overlay-tooltip-main">
                                            <div className="otm">
                                              <p>To like images, click the thumbs up on the image in the Media tab</p>
                                              <ImageValidation isImgValid defaultImg="approveimgThumbsUp.png" isNotSvgFormat customName="approveImg" customClassname="thumbsUpImgg" />
                                            </div>
                                          </div>
                                        }
                                        customInput={() => (
                                          <div className="info-icon">
                                            <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                                          </div>
                                        )}
                                      />
                                    </label>
                                    {dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL && (
                                      <div className="msdb-brand-email-wraps">
                                        <div className={`form-group`}>
                                          <input
                                            name={`actions.${idx}.actionItem.value`}
                                            className={`form-control msdb-input-text${validateIsError(errors, touched, idx, true, 'value') ? ` erroritem error-disp` : ``}${
                                              dataItem.actionItem.value ? ` used` : ``
                                            }`}
                                            autoComplete="off"
                                            onChange={(event) => {
                                              setFieldValue(`actions.${idx}.actionItem.value`, +event.target.value || undefined);
                                              if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                dispatch(rpRewardsModified(true));
                                              }
                                            }}
                                            value={dataItem.actionItem.value ? dataItem.actionItem.value : ``}
                                            onKeyPress={(event) => {
                                              const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                              const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                              if (!regexData.test(enteredKey)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />

                                          <span className="fltlabels">At least</span>
                                        </div>
                                      </div>
                                    )}
                                    <label className={handleInputActiveClassname(dataItem?.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL)}>
                                      <input
                                        name={`actions.${idx}.actionItem.programMeasure`}
                                        className="rpdatsn form-control"
                                        type="radio"
                                        autoComplete="off"
                                        value={RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL}
                                        onClick={(event) => {
                                          setFieldValue(`actions.${idx}.actionItem.program`, RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED);
                                          setFieldValue(`actions.${idx}.actionItem.value`, '');
                                          setFieldValue(`actions.${idx}.actionItem.programMeasure`, RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL);
                                          if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                            dispatch(rpRewardsModified(true));
                                          }
                                        }}
                                      />

                                      <span className="label-txt">
                                        By the number of {handleMediaLabel(dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL, dataItem.actionItem.value)}{' '}
                                        submitted (Does not require "like" rating)
                                      </span>
                                      <span className={handleInputCheckedClassaname(dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL)} />
                                    </label>
                                    {dataItem.actionItem?.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL && (
                                      <div className="msdb-brand-email-wraps">
                                        <div className={`form-group`}>
                                          <input
                                            name={`actions.${idx}.actionItem.value`}
                                            className={`form-control msdb-input-text${validateIsError(errors, touched, idx, true, 'value') ? ` erroritem error-disp` : ``}${
                                              dataItem.actionItem.value ? ` used` : ``
                                            }`}
                                            autoComplete="off"
                                            onChange={(event) => {
                                              setFieldValue(`actions.${idx}.actionItem.value`, +event.target.value || undefined);
                                              if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                dispatch(rpRewardsModified(true));
                                              }
                                            }}
                                            value={dataItem.actionItem.value ? dataItem.actionItem.value : ``}
                                            onKeyPress={(event) => {
                                              const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                              const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                              if (!regexData.test(enteredKey)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />

                                          <span className="fltlabels">At least</span>
                                        </div>
                                      </div>
                                    )}
                                    {/* https://rallio.atlassian.net/browse/RAS-3444
                                        <label className={handleInputActiveClassname(dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS)}>
                                          <Field>
                                            {({ field }: FieldProps) => (
                                              <input
                                                name={`actions.${idx}.actionItem.program`}
                                                className="rpdatsn form-control"
                                                type="radio"
                                                autoComplete="off"
                                                value={RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS}
                                                onClick={(event) => {
                                                  setFieldValue(`actions.${idx}.actionItem.programMeasure`, '');
                                                  setFieldValue(`actions.${idx}.actionItem.value`, '');
                                                  field.onChange(event);
                                                  if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                    dispatch(rpRewardsModified(true));
                                                  }
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <span className="label-txt">
                                            By the amount of engagement generated by{' '}
                                            {handleMediaLabel(dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS, dataItem.actionItem.value)} submitted that are used in brand
                                            created content
                                          </span>
                                          <span className={handleInputCheckedClassaname(dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS)} />
                                        </label>
                                        {dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS && (
                                          <div className="msdb-brand-email-wraps">
                                            <div className={`form-group`}>
                                              <Field>
                                                {({ field }: FieldProps) => (
                                                  <input
                                                    name={`actions.${idx}.actionItem.value`}
                                                    className={`form-control msdb-input-text${validateIsError(errors, touched, idx, true, 'value') ? ` erroritem error-disp` : ``}${
                                                      dataItem.actionItem.value ? ` used` : ``
                                                    }`}
                                                    autoComplete="off"
                                                    onChange={(event) => {
                                                      field.onChange(event);
                                                      setFieldValue(`actions.${idx}.actionItem.value`, +event.target.value || undefined);
                                                      if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                        dispatch(rpRewardsModified(true));
                                                      }
                                                    }}
                                                    value={dataItem.actionItem.value ? dataItem.actionItem.value : ``}
                                                    onKeyPress={(event) => {
                                                      const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                                      const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                                      if (!regexData.test(enteredKey)) {
                                                        event.preventDefault();
                                                        return false;
                                                      }
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <span className="fltlabels">At least</span>
                                            </div>
                                          </div>
                                        )} */}
                                  </div>
                                )}
                                {/* Publish Brand Approved Content */}
                                {dataItem.actionItem.employeesTodo === RP_PRGRM_ACTION_ADVCATES_TO_DO.brandApprCnt && (
                                  <div
                                    className={`gender-controls${
                                      validFields && (!dataItem.actionItem.program || !dataItem.actionItem.value) && disableNextAction(save, errors) ? ` erroritem error-disp` : ``
                                    }`}
                                  >
                                    <label className={handleInputActiveClassname(dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS)}>
                                      <input
                                        name={`actions.${idx}.actionItem.program`}
                                        className="rpdatsn form-control"
                                        type="radio"
                                        autoComplete="off"
                                        value={RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS}
                                        onClick={(event) => {
                                          setFieldValue(`actions.${idx}.actionItem.programMeasure`, '');
                                          setFieldValue(`actions.${idx}.actionItem.value`, '');
                                          setFieldValue(`actions.${idx}.actionItem.program`, RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS);
                                          if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                            dispatch(rpRewardsModified(true));
                                          }
                                        }}
                                      />

                                      <span className="label-txt">By the number of advocacy posts they share</span>
                                      <span className={handleInputCheckedClassaname(dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS)} />
                                    </label>
                                    {dataItem.actionItem.program === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS && (
                                      <div className="msdb-brand-email-wraps">
                                        <div className={`form-group`}>
                                          <input
                                            name={`actions.${idx}.actionItem.value`}
                                            className={`form-control msdb-input-text${validateIsError(errors, touched, idx, true, 'value') ? ` erroritem error-disp` : ``}${
                                              dataItem.actionItem.value ? ` used` : ``
                                            }`}
                                            autoComplete="off"
                                            onChange={(event) => {
                                              setFieldValue(`actions.${idx}.actionItem.value`, +event.target.value || undefined);
                                              if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                dispatch(rpRewardsModified(true));
                                              }
                                            }}
                                            value={dataItem.actionItem.value ? dataItem.actionItem.value : ``}
                                            onKeyPress={(event) => {
                                              const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                              const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                              if (!regexData.test(enteredKey)) {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />

                                          <span className="fltlabels">At least</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                              <div className="rp-rewards-item-highlights">
                                <span className="rewards-ribbon" />
                                {rewardsType === RewardType.CASH_PRIZE && (
                                  <div className={`rp-pa-item rp-drp-btns-wraps rp-drp-rewards-slct`}>
                                    <div className={`form-group fg-dropdown${validFields && !dataItem.rewards && disableNextAction(save, errors) ? ` erroritem error-disp` : ``}`}>
                                      <span className="fltlabels">
                                        Select Your Rewards<span className="mandatory">*</span>
                                      </span>
                                      <select
                                        name={`actions.${idx}.rewards`}
                                        className={`rp-dats dropdown-action-items`}
                                        onClick={() => {
                                          if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                            dispatch(rpRewardsModified(true));
                                          }
                                        }}
                                        onChange={handleChange}
                                        value={dataItem.rewards}
                                      >
                                        {promotionVaultList.map((rewardsItem, rewardsIdx) => (
                                          <option key={rewardsIdx} value={rewardsItem.value}>
                                            {rewardsItem.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                )}
                                {rewardsType === RewardType.NON_CASH_PRIZE && (
                                  <div className={`rp-pa-item rp-drp-btns-wraps rp-drp-rewards-slct`}>
                                    <div className={`form-group fg-dropdown${validFields && !dataItem.nonCashRewards && disableNextAction(save, errors) ? ` erroritem error-disp` : ``}`}>
                                      <div className="msdb-brand-email-wraps">
                                        <div className={`form-group`}>
                                          <input
                                            name={`actions.${idx}.nonCashRewards`}
                                            className={`form-control msdb-input-text${dataItem.nonCashRewards ? ` used` : ``}`}
                                            type="text"
                                            autoComplete="off"
                                            maxLength={100}
                                            value={dataItem.nonCashRewards}
                                            onChange={(event) => {
                                              setFieldValue(`actions.${idx}.nonCashRewards`, event.target.value || '');
                                              if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
                                                dispatch(rpRewardsModified(true));
                                              }
                                            }}
                                          />

                                          <span className="fltlabels">
                                            Select Your Rewards<span className="mandatory">*</span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="rp-email-txt-count-wraps">
                                        <span className="rpe-items rp-email-entered">{dataItem?.nonCashRewards?.length}</span>
                                        <span className="rpe-items rp-email-of">/</span>
                                        <span className="rpe-items rp-email-total">100</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="stg-item form-configure">
                <div className="left-section">
                  <Button className="btn-cancel" variant="outline-secondary" type="submit" onClick={() => setCurrentButtonAction(RP_USER_ACTION_TYPE.PREV)}>
                    Cancel
                  </Button>
                </div>

                <div className="right-section">
                  <Button
                    className={`primeblueaction${disableNextAction(save, errors) ? ` events-none pointer-events-none` : ``}`}
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      if (disableNextAction(save, errors)) {
                        setValidFields(true);
                      } else {
                        setCurrentButtonAction(RP_USER_ACTION_TYPE.NEXT);
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
