import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { ContentCreator } from 'containers/Content/Creator';
import { commonModalPopup, IStore } from 'types';
import { CommonAction } from 'utils/constants';
import { addPostSetCampaignData } from 'actions';

export const CreateOrEditPostModal = ({ isShowModal, onModalClose, type, headerActionSection, rightSideActionSection }: commonModalPopup.ICreateOrEditPostProps) => {
  const dispatch = useDispatch();

  const campaignDetail = useSelector((state: IStore) => state.campaigns.campaignDetail);

  useEffect(() => {
    if (isShowModal && campaignDetail?.id) dispatch(addPostSetCampaignData([{ id: campaignDetail.id, name: campaignDetail.name, active: campaignDetail.active }]));
  }, [isShowModal, campaignDetail, dispatch]);

  return (
    <Modal show={isShowModal} className="connection-secondary campaign__post--wrp add-location ralprofile-hl add-booster cal-soicalpreview-secion-wrp-modal" centered size="lg" animation={false}>
      <Modal.Body>
        <div className="sec-conn__wrp">
          <div className="sec-header done__sec--header">
            <div className="header-item__wrp">
              <span className="title">{type === CommonAction.EDIT ? 'Edit' : 'Create'} Post</span>
              <div className="apch-right">
                {headerActionSection ? headerActionSection : null}
                <div className="mod__close--icon header__close--icon">
                  <ImageValidation isImgValid defaultImg="close-modal-icon" customName="close" customClassname="mod__close--img" onClick={onModalClose} />
                </div>
              </div>
            </div>
          </div>
          <div className="sec-main__content hubuser-createpost-new-section calendar-edit__modal">
            <div className="addpost-section-main-wrp">
              <ContentCreator />
            </div>
            {rightSideActionSection ? rightSideActionSection : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
