import InfiniteScroll from 'react-infinite-scroll-component';

import { ICarouselTableProps, IIndexable } from 'types';
import { Loading } from 'components';
import { CarouselTable } from '../Table';

export const ScrollableCarouselTable = <T extends IIndexable, E extends {} = {}>({
  scrollHolderClassName,
  scrollThreshold,
  scrollDivHeight,
  data,
  headers,
  tableClassName,
  onScroll,
  isFetching,
  noDataMesg,
  ipadBoundary,
  ipadColCount,
  mobileBoundary,
  mobileColCount,
  mdDeviceBoundary,
  mdDeviceColCount,
  pinnedColumns,
  pinColumnsRight,
  responsiveNavigation,
  onHeaderClick,
  onRowClick,
  onColumnClick,
  onCustomHeaderDropDownClick,
  responsiveNavigationNextIcon,
  responsiveNavigationPreviousIcon,
  renderResponsiveNavNextIcon,
  renderResponsiveNavPrevIcon,
  scrollableTargetString, // For modal based infinite scroll
  colState,
  hasMore
}: ICarouselTableProps<T, E>) => {
  return (
    <InfiniteScroll
      scrollableTarget={scrollableTargetString || 'scrollTarget'}
      scrollThreshold={scrollThreshold}
      className={scrollHolderClassName}
      hasMore={hasMore === undefined ? true : hasMore}
      dataLength={data[data.length - 1].length}
      height={scrollDivHeight}
      next={() => {
        if (onScroll) {
          onScroll();
        }
      }}
      loader={isFetching ? <Loading /> : null}
    >
      <CarouselTable<T, E>
        data={data}
        tableClassName={tableClassName}
        headers={headers}
        isFetching={isFetching}
        noDataMesg={noDataMesg}
        ipadBoundary={ipadBoundary}
        ipadColCount={ipadColCount}
        mobileBoundary={mobileBoundary}
        mobileColCount={mobileColCount}
        mdDeviceBoundary={mdDeviceBoundary}
        mdDeviceColCount={mdDeviceColCount}
        pinnedColumns={pinnedColumns}
        pinColumnsRight={pinColumnsRight}
        responsiveNavigation={responsiveNavigation}
        onHeaderClick={onHeaderClick}
        onRowClick={onRowClick}
        onColumnClick={onColumnClick}
        onCustomHeaderDropDownClick={onCustomHeaderDropDownClick}
        responsiveNavigationNextIcon={responsiveNavigationNextIcon}
        responsiveNavigationPreviousIcon={responsiveNavigationPreviousIcon}
        renderResponsiveNavNextIcon={renderResponsiveNavNextIcon}
        renderResponsiveNavPrevIcon={renderResponsiveNavPrevIcon}
        colState={colState}
      />
    </InfiniteScroll>
  );
};
