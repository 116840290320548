import React from 'react';
import { connect } from 'react-redux';

import { exceptionHandlerRequest } from 'actions';
class ErrorBoundaryComponent extends React.Component<any, { capturedError: any }> {
  constructor(props: any) {
    super(props);
    this.state = { capturedError: null };
  }
  public componentDidCatch(error: any, info: any) {
    this.setState({ capturedError: true });
    this.props.dispatchExceptionHandlerRequest(true);
  }
  public render() {
    return <>{!this.state.capturedError && this.props.children}</>;
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  dispatchExceptionHandlerRequest: (payload: any) => dispatch(exceptionHandlerRequest(payload))
});

export const ErrorBoundary = connect(null, mapDispatchToProps)(ErrorBoundaryComponent);
