import { useState, useEffect, Fragment } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';

import { MapBoxMarkerPin } from './MapBoxMarkerPin';
import { commonMapboxTypes } from 'types';
import { config } from 'config';
import { MAP_INIT_VIEW_PORT, MAP_DEFAULT_PROPS } from 'utils/constants';

// const MAP_TRANSIST_DURATION = 1000;

export const MapBox: React.FC<commonMapboxTypes.IMapBoxComponent> = ({ coordsList, navigationStyle, centerCoords, height, width, zoom } = MAP_DEFAULT_PROPS) => {
  const [viewPort, setViewPort] = useState({ ...MAP_INIT_VIEW_PORT });

  const updateViewport = (viewport: any) => {
    setViewPort({ ...viewport });
  };

  const handleCoordValid = (coords: commonMapboxTypes.IMapMarker) => {
    if (coords.lat !== null && !isNaN(coords.lat) && coords.lng !== null && !isNaN(coords.lng)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setViewPort((state) => ({
      ...state,
      height: height ? height : MAP_INIT_VIEW_PORT.height,
      width: width ? width : MAP_INIT_VIEW_PORT.width,
      zoom: zoom ? zoom : MAP_INIT_VIEW_PORT.zoom
    }));
    if (centerCoords) {
      if (handleCoordValid(centerCoords)) {
        setViewPort((state) => ({
          ...state,
          latitude: centerCoords.lat,
          longitude: centerCoords.lng
          // transitionDuration: MAP_TRANSIST_DURATION,
        }));
      }
    }
  }, [centerCoords, zoom, width, height]);

  return (
    <ReactMapGL style={{ textAlign: 'left' }} mapStyle="mapbox://styles/mapbox/outdoors-v11" mapboxApiAccessToken={config.mapBoxToken} {...viewPort} onViewportChange={updateViewport}>
      <div style={navigationStyle ? { ...navigationStyle } : { position: 'absolute', right: 20, bottom: 20 }}>
        <NavigationControl onViewportChange={updateViewport} showCompass={false} />
      </div>
      {coordsList.length &&
        coordsList.map((coords: commonMapboxTypes.IMapMarker, idx: number) => {
          return (
            <Fragment key={idx}>
              {handleCoordValid(coords) && (
                <Marker key={idx} latitude={coords.lat} longitude={coords.lng}>
                  <MapBoxMarkerPin selected={coords.selected} />
                </Marker>
              )}
            </Fragment>
          );
        })}
    </ReactMapGL>
  );
};
