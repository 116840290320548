import { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IStore, settingsSocialProfilContainerTypes, settingsSocialProfileReducerTypes } from 'types';
import { facebookPageRequestByUrl, facebookPagesRequest, saveFbPageCredentials, socialProfileResetAll, updateSocialMediaSettingSuccessOrFailure } from 'actions';
import { CONNECTION_TABS, RIPPLE_COLOR, COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP, APP_TITLE, MediaDropPages } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { handleLinkNavigation, disconnectSocialMedia } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { FacebookConnectButton } from './FacebookConnectButton';
import { useSocialMedia } from './useSocialMedia';
import { Loading, SettingsSocialModalPopup } from 'components';
import { FacebookViewPagesModalBody } from './FacebookPageDetailPopup';

export const SocialProfileFacebook = ({ showPageModal, setShowPageModal, setStopSpinnerOnCancel, renderLabel, getFbViewPages }: settingsSocialProfilContainerTypes.ISPFbProps) => {
  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const extraFacebookScopes = accountDetails?.account.custom_fields.extra_facebook_scopes;

  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);
  const { facebookPages, fbSettingPayload, fbUserCredentialId, providingFbPageUrl } = useSelector((state: IStore) => state.socialProfile);
  const facebookMediaData = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);

  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');
  const [isConnectByUrlModalOpen, setIsConnectByUrlModalOpen] = useState(false);

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  const handleShowPageModal = () => {
    if (currentRoute.includes(MediaDropPages.SETTINGS)) {
      return [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(optionalParams[0]) && optionalParams[1] ? true : false;
    }
    return showPageModal || false;
  };

  useEffect(() => {
    if (handleShowPageModal() && !facebookPages.isFetching) {
      setStopSpinnerOnCancel(false);
    }
  }, [handleShowPageModal(), facebookPages.isFetching]); // eslint-disable-line

  const reloadFbPages = () => {
    dispatch(
      facebookPagesRequest({
        id: fbUserCredentialId || '',
        access_token: fbSettingPayload?.accessToken || '',
        type: optionalParams[0] === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? 'instagram' : 'facebook',
        refresh: 1
      })
    );
  };

  const handleModalClose = () => {
    if (currentRoute.includes(MediaDropPages.SETTINGS)) {
      navigate(`/${userOwnership}/${id}/${currentRoute}`);
    } else {
      setStopSpinnerOnCancel(true);
      setShowPageModal(false);
    }
    setUrl('');
    setIsConnectByUrlModalOpen(false);
    dispatch(socialProfileResetAll());
  };

  const validateUrl = () => {
    dispatch(facebookPageRequestByUrl({ id: optionalParams[1], url }));
  };

  const handleCancelConnectModalOpen = () => {
    setUrl('');
    setIsConnectByUrlModalOpen(false);
  };

  const FbPageModalBody = useCallback(({ data, isShowSearch, isShowError }: settingsSocialProfilContainerTypes.IFbPageModalBody) => {
    return (
      <FacebookViewPagesModalBody
        pagesDetailData={data}
        handleFbPageSelect={(pageObj: settingsSocialProfileReducerTypes.IFacebookPages, isInstaModal) => {
          if (pageObj) {
            const pageRequestPayload = {
              user_credential_id: fbUserCredentialId,
              facebook_id: isInstaModal ? pageObj.id : pageObj.facebook_id,
              name: pageObj.name,
              url: pageObj.url,
              image_url: isInstaModal ? pageObj.profile_picture_url || '' : pageObj.image_url || '',
              app_id: fbApplicationId,
              account_id: accountId,
              instagram_page_id: pageObj?.id || '',
              isInstagram: isInstaModal,
              credential_id: isInstaModal ? pageObj.facebook_page_credential_id : pageObj.id,
              instagramAccess: accountDetails?.account_profiles[0]?.access.platform_instagram_access || false
            };
            dispatch(saveFbPageCredentials(pageRequestPayload));
            handleModalClose();
          }
        }}
        isShowSearch={isShowSearch}
        isShowError={isShowError}
      />
    );
  }, []); // eslint-disable-line

  return (
    <>
      {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || accountDetails?.account_profiles[0]?.access.platform_facebook_access ? (
        <div className="csp-item ss-metafb d-flex justify-content-between cspFacebookMeta">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="fb-lv" customName="social logo" />
              </div>
              <span className="sp-txt">Facebook</span>
            </div>
            <div className="sm-added-details">
              <span className="smadtxt">Business Account</span>
            </div>
          </aside>
          {
            <>
              {facebookMediaData ? (
                <>
                  {showModal ? (
                    <div className="disconnect-notification-wrp">
                      <div className="left-item">
                        <div>
                          <ImageValidation isImgValid defaultImg="connection-social-profile" customName="more" />
                        </div>
                        <span className="are-u-sure-text">Are you sure?</span>
                      </div>
                      <div className="right-item">
                        <div className="csp-action">
                          <CustomRippleButton rippleClass="ripple-unset ac-secondary-box clr" custColor={RIPPLE_COLOR.whiteGrey}>
                            <button className="ac-btn ac-secondary-white size-xs cl" onClick={() => setShowModal(false)}>
                              Cancel
                            </button>
                          </CustomRippleButton>
                          <CustomRippleButton rippleClass={`ripple-unset ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                            <button
                              className="ac-btn ac-danger"
                              onClick={() => {
                                setShowModal(false);
                                disconnectSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, facebookMediaData.id, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, accountId, dispatch);
                              }}
                            >
                              {facebookMediaData?.needs_reconnection ? 'Remove' : 'Disconnect Now'}
                            </button>
                          </CustomRippleButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="csp-action g-15">
                      <Button
                        className="ac-btn ac-link-btn primeblueaction"
                        variant="link"
                        onClick={() => {
                          handleLinkNavigation(facebookMediaData.profile_url);
                        }}
                      >
                        View page
                      </Button>
                      {facebookMediaData?.needs_reconnection ? (
                        <FacebookConnectButton
                          callback={getFbViewPages}
                          renderButton={(renderProps: any) => (
                            <CustomRippleButton rippleClass="ripple-unset ac-danger-box" custColor={RIPPLE_COLOR.danger}>
                              <button className="ac-btn ac-icon-btn ac-danger h-default r-flx r-flx-jc r-flx-ac" onClick={renderProps.onClick}>
                                <ImageValidation isImgValid defaultImg="reconnect" customClassname="reconnect" customName="Reconnect" />
                                <span>{renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, 'light', 'Reconnect')}</span>
                              </button>
                            </CustomRippleButton>
                          )}
                          extraScopes={extraFacebookScopes}
                        />
                      ) : facebookMediaData?.needs_updated_credentials ? (
                        <FacebookConnectButton
                          callback={getFbViewPages}
                          renderButton={(renderProps: any) => (
                            <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                              <button className="ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac" onClick={renderProps.onClick}>
                                {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, 'light', 'Update Credentials')}
                              </button>
                            </CustomRippleButton>
                          )}
                          extraScopes={extraFacebookScopes}
                        />
                      ) : null}
                      <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                        <button className={`ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac`} onClick={() => setShowModal(true)}>
                          {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, 'primary', facebookMediaData?.needs_reconnection ? 'Remove' : 'Disconnect')}
                        </button>
                      </CustomRippleButton>
                    </div>
                  )}
                </>
              ) : (
                <div className="csp-action">
                  <FacebookConnectButton
                    callback={getFbViewPages}
                    renderButton={(renderProps: any) => (
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac`} onClick={renderProps.onClick}>
                          {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, 'light', 'Connect Now')}
                        </button>
                      </CustomRippleButton>
                    )}
                    extraScopes={extraFacebookScopes}
                  />
                </div>
              )}
            </>
          }
        </div>
      ) : null}

      {handleShowPageModal() ? (
        <div className="rlc-pt-modal-main-wraps">
          <SettingsSocialModalPopup
            isModalShow={handleShowPageModal()}
            modalCloseButton
            handleModalClose={() => {
              dispatch(updateSocialMediaSettingSuccessOrFailure({ type: '' }));
              handleModalClose();
            }}
            modalHeader={() => (
              <>
                <div className="stngs-badge">
                  <ImageValidation isImgValid defaultImg="stats/fb-stats" customName="settings social" />
                </div>
                <h3 className={`aps-head`}>Select the Facebook Business Page you'd like to connect to {APP_TITLE}</h3>
                <h4 className={`aps-reset`}>
                  Don’t see your page here?
                  <span className="nac-reset" onClick={reloadFbPages}>
                    Click here to refresh this list.
                  </span>
                </h4>
                <h4 className={`aps-reset fb-cp-url`}>
                  Alternatively,
                  <span className="nac-reset" onClick={() => setIsConnectByUrlModalOpen(true)}>
                    Click here to connect your page by providing its URL.
                  </span>
                </h4>
              </>
            )}
            modalHeaderType=""
            modalBody={() =>
              facebookPages.isFetching ? (
                <Loading />
              ) : isConnectByUrlModalOpen ? (
                <div className="afp-connect">
                  <p className="afp-label">Copy and paste a link to your Facebook page in the field below.</p>
                  <div className="afp-fields">
                    <div className={`form-group form-field-item`}>
                      <input
                        type="text"
                        name={'url'}
                        value={url}
                        autoComplete="off"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setUrl(e.target.value);
                        }}
                        placeholder="URL"
                        className="form-control"
                      />
                    </div>
                    <div className="afp-actionItems">
                      <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-sm ${!url.trim() || providingFbPageUrl.isFetching ? 'disabled' : ''}`} type="submit" onClick={validateUrl}>
                          Validate
                        </button>
                      </CustomRippleButton>
                      <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteGrey}>
                        <button className="ac-btn ac-secondary-white ac-outline size-sm border-0" onClick={handleCancelConnectModalOpen}>
                          Cancel
                        </button>
                      </CustomRippleButton>
                    </div>
                  </div>
                  {providingFbPageUrl.isFetching ? <Loading /> : <FbPageModalBody data={providingFbPageUrl.data} isShowSearch={false} isShowError={Boolean(providingFbPageUrl.error)} />}
                </div>
              ) : (
                <FbPageModalBody data={facebookPages.data} isShowSearch isShowError />
              )
            }
            modalFooter={() => (
              <div className="apmodal modal-btn-grp-wraps ap-actions">
                <Button
                  // className="btnOutline modal-cancel-btn"
                  className="ac-btn ac-secondary-white ac-outline"
                  variant="outline-secondary"
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
              </div>
            )}
          />
        </div>
      ) : null}
    </>
  );
};
