import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { SettingsSocialProfileDetails } from 'containers/Settings/SettingsSocialProfile/SubPages';
import { COMMON_SOCIAL_PROVIDER_TYPE, MediaDropPages, USER_OWNERSHIP } from 'utils/constants';
import { useHideFilter, useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { scrollToTop } from 'utils/helpers';
import { IStore } from 'types';
import { Loading } from 'components';
import {
  rallioProfileResetAll,
  accountProfileReset,
  socialProfileResetAll,
  accountGetProfileRequest,
  setFranchisorIds,
  facebookPagesRequest,
  loadGoogleBusinessAccountRequest,
  saveGoogleOauthCredentialResponse
} from 'actions';

export const SettingsSocialProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, isAdminScreenEnabled, optionalParams } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const isProfileFetching = useSelector((state: IStore) => state.accounts.isProfileFetching);
  const selectedFranchisorId = useSelector((state: IStore) => state.adminArea.selectedFranchisorId);
  const googleCredentialId = useSelector((state: IStore) => state.socialProfile.googleCredentialId);
  const { facebookPages, fbSettingPayload } = useSelector((state: IStore) => state.socialProfile);

  useHideFilter();

  useEffect(() => {
    scrollToTop();
    if (id && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(rallioProfileResetAll());
      dispatch(socialProfileResetAll());
      dispatch(accountGetProfileRequest({ id, isDataRetain: true }));
    }
  }, [id, userOwnership, dispatch]);

  const handleCancelLocationDetails = () => {
    dispatch(rallioProfileResetAll());
    if (isAdminScreenEnabled) {
      if (selectedFranchisorId) dispatch(setFranchisorIds(null));
      dispatch(accountProfileReset());
      dispatch(socialProfileResetAll());
    }
    const queryParamsObj = { ...queryParams };
    if (isAdmin && isAdminScreenEnabled) navigate({ pathname: `/${currentRoute}`, search: `?${new URLSearchParams(queryParamsObj).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}/${currentRoute}`, search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  useEffect(() => {
    if (currentRoute.includes(MediaDropPages.SETTINGS)) {
      if (optionalParams[optionalParams.length - 2] === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && !googleCredentialId) {
        dispatch(saveGoogleOauthCredentialResponse({ credentialId: optionalParams[optionalParams.length - 1] }));
        dispatch(loadGoogleBusinessAccountRequest({ credentialId: optionalParams[optionalParams.length - 1] }));
      } else if (
        !facebookPages.data.length &&
        !facebookPages.isFetching &&
        [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(optionalParams[optionalParams.length - 2]) &&
        optionalParams[optionalParams.length - 1]
      ) {
        dispatch(
          facebookPagesRequest({ id: optionalParams[optionalParams.length - 1] || '', access_token: fbSettingPayload?.accessToken || '', type: optionalParams[optionalParams.length - 2], refresh: 1 })
        );
      }
    }
  }, [optionalParams[optionalParams.length - 1], googleCredentialId, fbSettingPayload?.accessToken, dispatch]); // eslint-disable-line

  return isProfileFetching ? <Loading /> : <SettingsSocialProfileDetails onCancel={handleCancelLocationDetails} />;
};
