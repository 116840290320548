import parse from 'html-react-parser';

import { COMMON_SOCIAL_PROVIDER_TYPE, FBCallToActionOptionsName, FB_CALL_TO_ACTION_SELECT_OPTIONS, RIPPLE_COLOR } from 'utils/constants';

import { convertURLAsLink, convertValidUrlFromLinkText, handleLinkNavigation, removeHttpWebFromUrl } from 'utils/helpers';

import { CustomRippleButton } from 'widgets/CustomRippleButton';

import { ImageValidation } from 'widgets/Media';

export const FBCallToActionPreview = ({
  title,
  description,
  linkUrl,
  actionType,
  imageUrl,
  socialMedia,
  isLinkCustomized
}: {
  title: string;
  description: string;
  linkUrl: string;
  imageUrl: string | null;
  socialMedia: string;
  actionType?: string;
  isLinkCustomized?: boolean;
}) => {
  return (
    <div className="new-preview" onClick={() => (linkUrl ? handleLinkNavigation(convertValidUrlFromLinkText(linkUrl)) : false)}>
      <div className="np-img">
        <ImageValidation imgUrl={imageUrl || null} defaultImg={null} customClassname={'img-thumb'} customName={'social-media-link-preview'} />
      </div>
      <div className="np-content">
        <div className="np-left">
          <h3>
            <span className="np-form-txt">{isLinkCustomized && socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'links.rall.io' : removeHttpWebFromUrl(linkUrl || '')}</span>
            {/* <span className="np-form-value">Facebook</span> */} 
          </h3>
          <h2>{title || ''}</h2>
          {socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && <p>{description || ''}</p>}
        </div>
        {socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && actionType && ![`${FBCallToActionOptionsName.NO_BUTTON}`, `${FBCallToActionOptionsName.WHATSAPP_MESSAGE}`].includes(actionType) ? (
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp border-0 np-right" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0">
              <span>{parse(convertURLAsLink(linkUrl, FB_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === actionType)?.title || '', 'add'))}</span>
            </button>
          </CustomRippleButton>
        ) : null}
      </div>
    </div>
  );
};
