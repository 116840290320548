import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { StripeAction } from 'actions';
import { stripeReducerTypes } from 'types';

type IStripeReducer = stripeReducerTypes.IStripeReducer;

const initialState: IStripeReducer = {
  error: null,
  isFetching: false,
  customerObj: null,
  cardsListArray: []
};

export const stripeReducer: Reducer<IStripeReducer, StripeAction> = (state = initialState, action: StripeAction): IStripeReducer => {
  switch (action.type) {
    case types.STRIPE_GET_CUSTOMER_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        customerObj: null
      };
    case types.STRIPE_GET_CUSTOMER_RESPONSE:
      return {
        ...state,
        isFetching: false,
        customerObj: action.payload
      };
    case types.STRIPE_GET_CUSTOMER_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        customerObj: null
      };

    case types.STRIPE_GET_CARDS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        cardsListArray: []
      };
    case types.STRIPE_GET_CARDS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        cardsListArray: action.payload
      };
    case types.STRIPE_GET_CARDS_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        cardsListArray: []
      };
    case types.STRIPE_CARDS_RESET:
      return {
        ...state,
        isFetching: initialState.isFetching,
        error: initialState.error,
        cardsListArray: initialState.cardsListArray
      };
    default:
      return state;
  }
};
