import { apFranReducerTypes } from 'types';
import { COMMON_DATE_AND_UTC_FORMAT_RANGE_INIT_VALUE } from 'utils/constants';

export const AP_CONTENT_INIT_OBJ: apFranReducerTypes.IAddPostFranContentObj = {
  currentTab: 'facebook',
  mediaAvailForUser: 'personal',
  scheduledBy: 'manuallySchedule',
  dateType: 'anytime',
  customDate: COMMON_DATE_AND_UTC_FORMAT_RANGE_INIT_VALUE,
  defaultDate: COMMON_DATE_AND_UTC_FORMAT_RANGE_INIT_VALUE,
  isDeleteAutomatically: false,
  isRepeatAnnually: true,
  isPostAvailToEmployee: false,
  isPostLock: false,
  isPermissionOverride: false,
  personalizedText: {
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
    google: '',
    tiktok: ''
  },
  customText: {
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
    google: '',
    tiktok: ''
  },
  postStatus: {
    facebook: false,
    twitter: false,
    linkedin: false,
    instagram: false,
    google: false,
    tiktok: false
  },
  dateAndTime: { selected: null, utcFormat: null },
  linkIndex: 0,
  isThumbnailShow: true,
  isNotShowPost: false,
  instagram_reel: false,
  facebook_reel: false,
  google_call_to_action_type: null,
  google_call_to_action_url: null,
  facebook_call_to_action_type: null,
  facebook_call_to_action_link: null,
  personalized_facebook_call_to_action_link: '',
  expiration: { type: 'never', date: null },
  facebook_story: false,
  instagram_story: false,
  franSyndicationMinMaxDate: { minDate: null, maxDate: null }
};

export const AP_FRAN_INIT_CONTENT_SAVED_DATE = {
  currentDate: new Date(),
  savedDate: null
};

export const AP_FRAN_INIT_VIDEO_COMPATIBILITY = {
  warnings: {
    facebook_video: [],
    twitter_video: [],
    instagram_video: [],
    linkedin_video: [],
    facebook_reel: [],
    instagram_reel: []
  },
  compatibility: {
    facebook_video: false,
    twitter_video: false,
    instagram_video: false,
    linkedin_video: false,
    facebook_reel: false,
    instagram_reel: false
  }
};

export const AP_MEDIA_EXPIRATION_INITIAL_OBJ = { type: 'never', date: null };
