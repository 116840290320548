import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { adminAreaApiTypes, franchisorApiTypes, loginReducerType } from 'types';
import { IAdminAreaFilter, IAdminAreaReducer } from 'types/admin-area/admin-area-reducer';
import { IFranchisorStatsObj } from 'types/admin-area/admin-area-api';
import { IFranchisorData } from 'types/franchisors/franchisors-api';

// GET ADMIN AREA ACCOUNT LIST REQUEST
export const getAAAccountsListRequest = (payload: IAdminAreaFilter) => createAction(actionTypes.GET_AA_ACCOUNTS_LIST_REQUEST, payload);
export const getAAAccountsListResponse = (data: adminAreaApiTypes.IAccountListResponseObj[]) => createAction(actionTypes.GET_AA_ACCOUNTS_LIST_RESPONSE, data);
export const getAAAccountsListFailed = (error: null | string) => createAction(actionTypes.GET_AA_ACCOUNTS_LIST_FAILURE, error);

// GET ADMIN AREA FRANCHIORS LIST REQUEST
export const getAAFranchisorsListRequest = (payload: Partial<IAdminAreaFilter>) => createAction(actionTypes.GET_AA_FRANCHISORS_LIST_REQUEST, payload);
export const getAAFranchisorsListResponse = (data: franchisorApiTypes.IFranchisorData[]) => createAction(actionTypes.GET_AA_FRANCHISORS_LIST_RESPONSE, data);
export const getAAFranchisorsListFailed = (error: null | string) => createAction(actionTypes.GET_AA_FRANCHISORS_LIST_FAILURE, error);

// GET ADMIN AREA USERS LIST REQUEST
export const getAAUsersListRequest = (payload: Partial<IAdminAreaFilter>) => createAction(actionTypes.GET_AA_USERS_LIST_REQUEST, payload);
export const getAAUsersListResponse = (data: loginReducerType.ILoginResponseUser[]) => createAction(actionTypes.GET_AA_USERS_LIST_RESPONSE, data);
export const getAAUsersListFailed = (error: null | string) => createAction(actionTypes.GET_AA_USERS_LIST_FAILURE, error);

// GET ADMIN AREA EXPIRY ACTIONS REQUEST
export const getAAFranchisorStatsRequest = (id: number) => createAction(actionTypes.GET_AA_FRANCHISOR_STATS_REQUEST, id);
export const getAAFranchisorStatsResponse = (data: IFranchisorStatsObj[]) => createAction(actionTypes.GET_AA_FRANCHISOR_STATS_RESPONSE, data);
export const getAAFranchisorStatsFailure = (error: null | string) => createAction(actionTypes.GET_AA_FRANCHISOR_STATS_FAILURE, error);

// RESET ADMIN AREA
export const resetAdminArea = (payload?: (keyof IAdminAreaReducer)[]) => createAction(actionTypes.ADMIN_AREA_RESET, payload);

// UPDATE FRANCHISOR
export const updateAAFranchisorRequest = (payload: franchisorApiTypes.IFranchisorData) => createAction(actionTypes.UPDATE_AA_FRANCHISOR_REQUEST, payload);
export const updateAAFranchisorResponse = (payload: IFranchisorData) => createAction(actionTypes.UPDATE_AA_FRANCHISOR_RESPONSE, payload);
export const updateAAFranchisorFailure = (payload: null | string) => createAction(actionTypes.UPDATE_AA_FRANCHISOR_FAILURE, payload);

export const updateAALastViewedScreen = (payload: string) => createAction(actionTypes.SET_ADMIN_AREA_SCREEN, payload);

// UPDATE ADMIN AREA USER DETAILS
export const updateAAUserDetailsRequest = (payload: Partial<loginReducerType.ILoginResponseUser>) => createAction(actionTypes.UPDATE_AA_USER_DETAILS_REQUEST, payload);
export const updateAAUserDetailsResponse = (payload: loginReducerType.ILoginResponseUser) => createAction(actionTypes.UPDATE_AA_USER_DETAILS_RESPONSE, payload);
export const updateAAUserDetailsFailure = (payload: null | string) => createAction(actionTypes.UPDATE_AA_USER_DETAILS_FAILURE, payload);

// ADMIN AREA USER SEND WIZARD
export const addAAUserInviteMailRequest = (payload: number) => createAction(actionTypes.ADD_AA_USER_INVITE_EMAIL_REQUEST, payload);
export const addAAUserInviteMailResponse = (data: null | string) => createAction(actionTypes.ADD_AA_USER_INVITE_EMAIL_RESPONSE, data);
export const addAAUserInviteMailFailure = (error: null | string) => createAction(actionTypes.ADD_AA_USER_INVITE_EMAIL_FAILURE, error);

// SET FRANCHISOR ID
export const setFranchisorIds = (payload: number | null) => createAction(actionTypes.SET_FRANCHISOR_ID, payload);

// SET CURRENT PAGE
export const setAACurentPage = (payload: number) => createAction(actionTypes.SET_AA_CURRENT_PAGE, payload);

export const updateAAUserDetailsMsgReset = () => createAction(actionTypes.UPDATE_AA_USER_DETAILS_MSG_RESET);
export const aaUserDetailsDataReset = () => createAction(actionTypes.AA_USER_DETAILS_DATA_RESET);

export const getAAUserDetailsRequest = (payload: number) => createAction(actionTypes.GET_AA_USER_DETAILS_REQUEST, payload);
export const getAAUserDetailsResponse = (payload: null | loginReducerType.ILoginResponseUser) => createAction(actionTypes.GET_AA_USER_DETAILS_RESPONSE, payload);
export const getAAUserDetailsFailure = (payload: null | string) => createAction(actionTypes.GET_AA_USER_DETAILS_FAILURE, payload);

export const adminAreaDetailViewError = (error: null | string) => createAction(actionTypes.ADMIN_AREA_DETAIL_VIEW_ERROR, error);

const ADMIN_AREA_ACTIONS = {
  getAAAccountsListRequest,
  getAAAccountsListResponse,
  getAAAccountsListFailed,
  getAAFranchisorsListRequest,
  getAAFranchisorsListResponse,
  getAAFranchisorsListFailed,
  getAAUsersListRequest,
  getAAUsersListResponse,
  getAAUsersListFailed,
  getAAFranchisorStatsRequest,
  getAAFranchisorStatsResponse,
  getAAFranchisorStatsFailure,
  updateAAFranchisorRequest,
  updateAAFranchisorResponse,
  updateAAFranchisorFailure,
  updateAALastViewedScreen,
  updateAAUserDetailsRequest,
  updateAAUserDetailsResponse,
  updateAAUserDetailsFailure,
  addAAUserInviteMailRequest,
  addAAUserInviteMailResponse,
  addAAUserInviteMailFailure,
  setFranchisorIds,
  setAACurentPage,
  updateAAUserDetailsMsgReset,
  aaUserDetailsDataReset,
  getAAUserDetailsRequest,
  getAAUserDetailsResponse,
  getAAUserDetailsFailure,
  adminAreaDetailViewError,
  resetAdminArea
};

export type AdminAreaActions = ActionsUnion<typeof ADMIN_AREA_ACTIONS>;
