import { ILeaderBoardAnalyticsPayload, ILeaderBoardAnalyticsResponse, ILeaderBoardAnalyticsReviewCategoryPayload, ILeaderBoardAnalyticsReviewCategoryResponse } from 'analytics/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

export const getLeaderBoardAnalyticsRequest = (payload: Partial<ILeaderBoardAnalyticsPayload>) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REQUEST, payload);
export const getLeaderBoardAnalyticsResponse = (response: { analytics_table: ILeaderBoardAnalyticsResponse }) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_RESPONSE, response);
export const getLeaderBoardAnalyticsFailure = (error: null | string) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_FAILURE, error);
export const resetLeaderBoardAnalyticsState = () => createAction(actionTypes.RESET_LEADER_BOARD_ANALYTICS_STATE);

export const getLeaderBoardAnalyticsReviewCategoryRequest = (payload: ILeaderBoardAnalyticsReviewCategoryPayload) =>
  createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_REQUEST, payload);
export const getLeaderBoardAnalyticsReviewCategoryResponse = (response: ILeaderBoardAnalyticsReviewCategoryResponse[]) =>
  createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_RESPONSE, response);
export const getLeaderBoardAnalyticsReviewCategoryFailure = (error: null | string) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_FAILURE, error);

export const getLeaderBoardAnalyticsReviewCategoryIdRequest = (payload: number) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_REQUEST, payload); // Omit<ILeaderBoardAnalyticsReviewCategoryPayload, 'review_keyword_category_ids'>
export const getLeaderBoardAnalyticsReviewCategoryIdResponse = (response: number[]) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_RESPONSE, response);
export const getLeaderBoardAnalyticsReviewCategoryIdFailure = (error: null | string) => createAction(actionTypes.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_FAILURE, error);

const LEADER_BOARD_ANALYTICS = {
  getLeaderBoardAnalyticsRequest,
  getLeaderBoardAnalyticsResponse,
  getLeaderBoardAnalyticsFailure,
  getLeaderBoardAnalyticsReviewCategoryRequest,
  getLeaderBoardAnalyticsReviewCategoryResponse,
  getLeaderBoardAnalyticsReviewCategoryFailure,
  getLeaderBoardAnalyticsReviewCategoryIdRequest,
  getLeaderBoardAnalyticsReviewCategoryIdResponse,
  getLeaderBoardAnalyticsReviewCategoryIdFailure,
  resetLeaderBoardAnalyticsState
};

export type LeaderBoardAnalyticsActions = ActionsUnion<typeof LEADER_BOARD_ANALYTICS>;
