import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore, aiContentCreatorContainerTypes } from 'types';

export const useAIFeatureAccess = () => {
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const aiFeature = useSelector((state: IStore) => state.accountSwitcher?.ai_features);

  const aiAccess = useMemo(() => {
    const intialAccess: aiContentCreatorContainerTypes.IAIFeautures = { aiCaptionAccess: false, aiImageAccess: false, aiReviewReplyAccess: false };
    if (isRallioai) {
      if (aiFeature === 0) {
        Object.keys(intialAccess).forEach((key) => {
          intialAccess[key as keyof aiContentCreatorContainerTypes.IAIFeautures] = true;
        });
      } else if (Array.isArray(aiFeature)) {
        intialAccess.aiCaptionAccess = Boolean(aiFeature?.find((it) => [1].includes(it)));
        intialAccess.aiImageAccess = Boolean(aiFeature?.find((it) => [2].includes(it)));
        intialAccess.aiReviewReplyAccess = Boolean(aiFeature?.find((it) => [3].includes(it)));
      }
    }
    return intialAccess;
  }, [isRallioai, aiFeature]);

  return aiAccess;
};
