import { reviewsApiTypes } from 'types';
import { getReviewRepliedPercentage } from 'utils/helpers';

/** Update the reviews list based on the data changes */
export const getUpdatedReviewsList = (reviewsList: reviewsApiTypes.IReviewsListObj[], updatedReviewObj: reviewsApiTypes.IReviewsListObj) => {
  return updatedReviewObj.id
    ? reviewsList.reduce((acc: reviewsApiTypes.IReviewsListObj[], curr) => {
        acc = [...acc, updatedReviewObj.id === curr.id ? updatedReviewObj : curr];
        return acc;
      }, [])
    : reviewsList;
};

/** Get the unique reviews internal notes */
export const getUniqueReviewsInternalNotes = (internalNotes: reviewsApiTypes.IReviewsInternalNotesResponse[]) => {
  return Array.from(new Map(internalNotes.map((dataItem) => [dataItem.id, dataItem])).values());
};

/** Get the reviews stats by date list for charts */
export const getReviewStatsByDate = (analyticStats: { [key: string]: string | number }[], platform?: string) => {
  return analyticStats.reduce((acc: { [key: string]: string | number }[], curr) => {
    const analyticObj = {
      date: curr.title,
      avgRatingCount: platform ? +curr[`${platform}_avg_rating`] || 0 : +curr.avg_rating || 0,
      repliedPercentCount: getReviewRepliedPercentage(+curr.replied_percentage || 0)
    };
    acc = [...acc, analyticObj];
    return acc;
  }, []);
};

/** Get the reviews that are replied */
export const getReviewsThatAreReplied = (reviewsList: reviewsApiTypes.IReviewsListObj[], updatedObj: reviewsApiTypes.IReviewsListObj) => {
  return reviewsList.reduce((acc: reviewsApiTypes.IReviewsListObj[], curr) => {
    if (curr.id === updatedObj.id) acc = [...acc, updatedObj];
    else acc = [...acc, curr];
    return acc;
  }, []);
};

/** Get updated reviews internal notes count */
export const getReviewsIntNotesCount = (reviewsList: reviewsApiTypes.IReviewsListObj[], response: reviewsApiTypes.IReviewsIntNotesResp) => {
  return reviewsList.reduce((acc: reviewsApiTypes.IReviewsListObj[], curr) => {
    if (curr.id === response.id) acc = [...acc, { ...curr, internal_comments_count: response.notes.length }];
    else acc = [...acc, curr];
    return acc;
  }, []);
};
