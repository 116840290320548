import { APP_TITLE, MODAL_BOX_TYPES, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ModalPopup } from '../ModalPopup';

export const AICancelSusbcription = ({ showDeleteModal, handleModalClose, handleCancel }: { showDeleteModal: boolean; handleModalClose: () => void; handleCancel: () => void }) => {
  return (
    <ModalPopup
      isModalShow={showDeleteModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={'modal-confirmation confirm-delete ai-relationship'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">
            {`${APP_TITLE} AI is just getting to know you! The more you work with it, the more it learns what you like and the better the results will be. Give the relationship a little longer?`}
          </div>
        </div>
      )}
      modalCloseButton
      handleModalClose={handleModalClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button
              className="ac-btn ac-secondary-white ac-outline ac-block edit-ripple__wrp"
              onClick={() => {
                handleCancel();
                handleModalClose();
              }}
            >
              No, please cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass="ac-danger-box" custColor={RIPPLE_COLOR.danger}>
            <button className="ac-btn ac-danger ac-block" onClick={handleModalClose}>
              Yes, I'll give it a little more time
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
