import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { config } from 'config';
import { accountApiTypes, commonProps, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { getActiveHeaderToastData } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { ROUTES, ALERT_BOX_TYPES, HEADER_TOAST_ALERT_TYPES, HEADER_TOAST_RECONNECT_TYPES } from 'utils/constants';
import { alertBoxCall } from 'components';

interface IHeaderToastProps {
  accountProfilesAlerts: accountApiTypes.IAlerts[];
}

export const HeaderToast = React.memo(({ accountProfilesAlerts }: IHeaderToastProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id, userOwnership } = useAccountSwitcherData();

  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);

  const [toastData, setToastData] = useState<commonProps.IHeaderToastData[]>(getActiveHeaderToastData(accountProfilesAlerts || []));

  const handleNavigate = () => {
    if (menuAvailablity.settings?.social_profile) {
      navigate(ROUTES.SETTINGS_SOCIAL.replace(':type', userOwnership).replace(':id', id.toString()));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.WARN, "You don't have access to configure social settings, Please contact your admin.");
    }
  };

  return toastData.length ? (
    <div className="mbl-head--margin">
      <div className="ap-alert-main m-0">
        {toastData.map((item, index) => {
          return (
            <Fragment key={`headerToast${item.identifier}${index}`}>
              <div className={`api-alert-box ${item.className || ''}`}>
                <div className="ab-cnt">
                  {Object.values(HEADER_TOAST_ALERT_TYPES).includes(item.alertType) && (
                    <div className="alert-icon">
                      <img
                        src={`${config.cdnImgUrl}${
                          item.alertType === HEADER_TOAST_ALERT_TYPES.RED_ALERT ? 'alert-red.svg' : item.alertType === HEADER_TOAST_ALERT_TYPES.YELLOW_ALERT ? 'alert-yellow.svg' : 'alert-green.svg'
                        }`}
                        alt="alert"
                      />
                    </div>
                  )}
                  <p>{item.context}</p>
                  {Object.values(HEADER_TOAST_RECONNECT_TYPES).includes(item.alertType) && (
                    <button className={location.pathname.split('/')[4] === 'social_profile' ? 'pointer-events-none' : ''} onClick={handleNavigate}>
                      <span>Fix This</span>
                    </button>
                  )}
                </div>
                {Object.values(HEADER_TOAST_RECONNECT_TYPES).includes(item.alertType) && (
                  <div className="alert-icon ai-x">
                    <ImageValidation defaultImg="alert-xmark" customClassname="close" customName="close" onClick={() => setToastData(toastData.filter((it) => it.identifier !== item.identifier))} />
                  </div>
                )}
              </div>
              {Object.values(HEADER_TOAST_RECONNECT_TYPES).includes(item.alertType) && (
                <div className="alert-icon ai-x">
                  <ImageValidation
                    isImgValid
                    defaultImg="alert-xmark"
                    customClassname="close"
                    customName="close"
                    onClick={() => setToastData(toastData.filter((it) => it.identifier !== item.identifier))}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  ) : null;
});
