import { rpContainerTypes } from 'types';

export const RP_ACTIONS_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
  COPY: 'copy'
};

export enum RewardType {
  CASH_PRIZE = 1,
  NON_CASH_PRIZE = 2
}

export enum RecommendedType {
  DEFAULT = 1,
  RECOMMENDED_TYPE = 2
}

export enum Duration {
  ONETIME = 0,
  RECURRING = 1
}

export enum Stage {
  DRAFT = 1,
  RECOMMENDED_PROGRAM = 2,
  PAYMENT_COMPLETED = 5, // non-cash-prize = 5
  PROGRAM_COMPLETED = 10,
  PROGRAM_NOTIFICATION_SENT = 60
}

export const RP_REPLACE_SPECIAL_CHAR = {
  SPECIAL_CHAR: /[&\\#, +()$~%'":*?<>{}]/g
};

export enum RpProgram {
  ALL = 'all',
  RECOMMENDED_PROGRAM = 'recommended_program',
  REWARD_PROGRAM = 'reward_program'
}

export const RP_FILTERS_ARRAY = [
  { value: RpProgram.ALL, label: 'All' },
  { value: RpProgram.RECOMMENDED_PROGRAM, label: 'Recommended Program' },
  { value: RpProgram.REWARD_PROGRAM, label: 'Reward Program' }
];

export const RP_PRGRM_ACTION_PRGRM = {
  ASSETS_SUBMITTED: 'assets_submitted',
  ENGAGEMENT_ASSETS: 'engagement_from_assets',
  ADVOCACY_POSTS: 'advocacy_posts'
};

export const RP_USER_ACTION_TYPE = {
  SAVE: 'save',
  SAVE_AS_DRAFT: 'saveAsDraft',
  NEXT: 'next',
  RESEND: 'resend',
  PREV: 'prev',
  PAYMENT: 'modifySubscription',
  MAKE_PAYMENT: 'MakePayment'
};

export enum EmailNotificationStatus {
  SEND_NOW = 1,
  SEND_LATER = 0,
  NEVER_SEND = 2
}

export const RP_EMAIL_TAB_FIELDS = {
  EMAIL_INVITATION_MESSAGE: 'emailInvitationMessage',
  EMAIL_NOTIFICATION_STATUS: 'emailNotificationStatus',
  EMAIL_SCHELUDE_DATE: 'emailScheduleDate'
};

export const RP_PROGRAM_RESET_TYPES = {
  SUBMIT: 'submit',
  BACK: 'back'
};

export const RP_PAYMENT_VALIDATION: rpContainerTypes.IRPPaymentValidation = {
  userName: { isTouched: false, isValid: false },
  cardNumber: { isTouched: false, isValid: false },
  cardValid: { isTouched: false, isValid: false },
  cardCvc: { isTouched: false, isValid: false }
};

export const RP_PAYMENT_TABS = {
  SAVED: { value: 'savedCards', title: 'Saved Cards' },
  NEW: { value: 'newCard', title: 'New Card' }
};

export const RP_PAYMENT_DEFAULT_CVC_PAYMENT = 'pass';

export enum RpPaymentField {
  USER_NAME = 'userName',
  CARD_NUMBER = 'cardNumber',
  CARD_VALID = 'cardValid',
  CARD_CVC = 'cardCvc'
}

export const RP_PROGRAM_TYPE = {
  NON_CASH_PROGRAM: 'nonCashProgram',
  DRAFT_PROGRAM: 'draftProgram',
  RECCURING_PROGRAM: 'recProgram'
};

export const RP_RECOMMENDED_PRG_TOOLTIP = `This is a suggested program for your locations to run based on your guidance.
                    Locations will set the time frame, the budget and the reward independently if they choose to run this program`;

export const RP_SEND_NOTIFICATION_ARRAY = [
  { value: EmailNotificationStatus.NEVER_SEND, label: 'Never Send' },
  { value: EmailNotificationStatus.SEND_NOW, label: 'Send Now' },
  { value: EmailNotificationStatus.SEND_LATER, label: 'Send Later' }
];

export const RP_TABLE_CLASSNAME = {
  GREY: 'si-grey',
  GREEN: 'si-green',
  BLUE: 'si-blue',
  PURPLE: 'si-purple',
  ORANGE: 'si-orange'
};

export const RP_MODAL_TITLE = {
  DETAIL_VIEW: 'Reward Program',
  CREATE_PROGRAM: 'Create Program',
  EDIT_PROGRAM: 'Edit Program'
};

export const RP_DELETE_ACTION_OBJ: rpContainerTypes.IRPDeleteAction = {
  showModal: false,
  deleteId: 0,
  recurringPaymentSubscriptionId: null
};

export enum RpPaymentType {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  AMERICAN_EXPRESS = 'amex',
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  UNIONPAY = 'unionpay',
  UNKNOWN = 'unknown'
}

export const RP_PAYMENT_CARD_DATA = [
  { name: 'MasterCard', classname: 'master-card-bg', first4: '5***', cardValue: RpPaymentType.MASTERCARD },
  { name: 'Visa', classname: 'visa-card-bg', first4: '4***', cardValue: RpPaymentType.VISA },
  { name: 'American Express', classname: 'amercianexpress-card-bg', first4: '3***', cardValue: RpPaymentType.AMERICAN_EXPRESS },
  { name: 'Diners Club', classname: 'diners-club-card-bg', first4: '3***', cardValue: RpPaymentType.DINERS_CLUB },
  { name: 'Discover', classname: 'discover-card-bg', first4: '6***', cardValue: RpPaymentType.DISCOVER },
  { name: 'JCB', classname: 'jcb-card-bg', first4: '3***', cardValue: RpPaymentType.JCB },
  { name: 'UnionPay', classname: 'unionpay-card-bg', first4: '62**', cardValue: RpPaymentType.UNIONPAY },
  { name: 'Unknown', classname: 'visa-card-bg', first4: '4***', cardValue: RpPaymentType.UNKNOWN }
];

export const RP_DISABLE_TYPE = {
  DRAFT: 'draft',
  SAVE: 'save'
};

export const RP_TIME_PICKER_MERIDIAN = ['AM', 'PM'];
