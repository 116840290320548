import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { config } from 'config';
import { ReactVideoPlayer } from 'widgets/Media';
import { IStore } from 'types';
import { accountActivateRequest, accountCreateScheduledPostRequest /* , aiCreateSubscriptionPlanRequest */ } from 'actions';
import { RIPPLE_COLOR, USER_OWNERSHIP, contentSchedulerScheduleType } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const VideoConclusion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();
  // const { isCSLocation } = useSSOIdentifiersForAccount();
  const { queryParams } = useParamsDeconstructor();

  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account.id || null);
  const isAccountActivateFetching = useSelector((state: IStore) => state.accounts.isAccountActivateFetching);

  // const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  // const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);

  const handleNavigateToCalendarPage = () => {
    if (accountId) {
      dispatch(
        accountActivateRequest({
          id: accountId,
          onNavigate: (currentAccountId) =>
            navigate({
              pathname: `/${USER_OWNERSHIP.ACCOUNT}/${currentAccountId.toString()}/content/calendar`,
              search: `?${new URLSearchParams({ ...queryParams, ...{ view: 'calendar' } }).toString()}`
            })
        })
      );
      dispatch(accountCreateScheduledPostRequest({ userOwnership, id, type: contentSchedulerScheduleType.DEFAULT }));
      // if (activeUserId) {
      //   dispatch(
      //     aiCreateSubscriptionPlanRequest({
      //       createParams: {
      //         data: [
      //           {
      //             account_id: accountId,
      //             subscriptionType: 'CS_FREE',
      //             user_id: activeUserId,
      //             franchisor_subscription_type_id: 7
      //           }
      //         ]
      //       },
      //       getParams: {
      //         id,
      //         topLevelFranchisorId: topLevelFranchisorId.toString(),
      //         userOwnership,
      //         userId: activeUserId
      //       }
      //     })
      //   );
      // }
    }
  };

  return (
    <div className="mainContent animate__animated animate__fadeIn social-profile-details-wrp settings__main lpx vpy-20 vicWrap vConclsn">
      <div className="settings-main-wrp">
        <div className="frameModule settings">
          <div className="csp">
            <div className="video-outer-element">
              <ReactVideoPlayer
                isPlayControl
                light={`${config.cdnImgUrl}congratulations.png`}
                muted
                videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/vnebhhs2xfbor664p4am.mp4'}
                customClassname="m-ast-itm m-ast-video"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="stg-item form-configure">
        <div className="modal-btn-grp-wraps csp-action ap-actions">
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary size-xs`} onClick={() => handleNavigateToCalendarPage()}>
              {isAccountActivateFetching ? <Spinner animation="border" variant="light" /> : 'Finish'}
            </button>
          </CustomRippleButton>
        </div>
      </div>
    </div>
  );
};
