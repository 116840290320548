import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { API, apiCall, defaultHeader, HttpMethods } from 'utils/helpers';
import {
  exceptionHandlerRequest,
  getULAccountOwnersFailure,
  getULAccountOwnersResponse,
  logoutRequest,
  accountOwnersCheckEmailResponse,
  accountOwnersCheckEmailFailure,
  addULAccountOwnersResponse,
  addULAccountOwnersFailure,
  updateULAccountOwnersResponse,
  deleteULAccountOwnersResponse,
  deleteULAccountOwnersFailure,
  ulResendInviteForUserResponse,
  updateULAccountOwnersFailure,
  ulResendInviteForUserFailure
} from 'actions';
import { commonApiTypes } from 'types';
import {
  USER_LOGINS_ERROR,
  UL_ACCOUNT_OWNERS_FAILED,
  UL_ACCOUNT_OWNERS_CHECK_EMAIL_FAILED,
  UL_DELETE_ACCOUNT_OWNERS_FAILED,
  UL_UPDATE_ACCOUNT_OWNERS_FAILED,
  UL_RESEND_INIVITE_FOR_USER_FAILED,
  DONE_SUCCESS_MSG,
  ALERT_BOX_TYPES,
  UL_ACTIVE_USER_DELETED_MSG
} from 'utils/constants';
import { store } from 'store';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendULAccountOwnersRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let data;
    let response;
    let path;
    let params;
    if (payload.accountOwner) {
      if (payload.accountOwnerId) {
        data = { ...(payload.accountOwner && { user: payload.accountOwner }) };
        const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.saveNotificationSettings };
        path = urlPathObj;
        path.apiPath = path.apiPath.replace(':id', payload.accountOwnerId);
        response = yield apiCall({ headers, data, ...path, action: API.saveNotificationSettings.action });
      } else {
        data = { ...(payload.accountOwner && { account_owner: payload.accountOwner }) };
        const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.addULAccountOwners };
        path = urlPathObj;
        path.apiPath = path.apiPath.replace(':id', payload.id);
        response = yield apiCall({ headers, data, ...path, action: API.addULAccountOwners.action });
      }
    } else if (payload.accountOwnerFeatures && payload.accountOwnerId) {
      data = { ...(payload.accountOwnerFeatures && { features: payload.accountOwnerFeatures.features }) };
      const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.updateULAccountOwnersFeatures };
      path = urlPathObj;
      path.apiPath = path.apiPath.replace(':id', payload.id).replace(':accountOwnerId', payload.accountOwnerId);
      response = yield apiCall({ headers, data, ...path, action: API.updateULAccountOwnersFeatures.action });
    } else {
      const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.getULAccountOwners };
      path = urlPathObj;
      path.apiPath = path.apiPath.replace(':id', payload.id);
      params = { employee: payload.employee, ...(payload.search && { search: payload.search }) };
      response = yield apiCall({ headers, params, ...path, action: API.getULAccountOwners.action });
    }

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (path.action === HttpMethods.POST) {
        yield put(addULAccountOwnersResponse({ data: response.data.account_owner, message: DONE_SUCCESS_MSG }));
      } else if (path.action === HttpMethods.PUT) {
        yield put(updateULAccountOwnersResponse(response.data.user));
      } else {
        yield put(getULAccountOwnersResponse(response.data.account_owners));
      }
    } else {
      if (path.action === HttpMethods.POST) {
        yield put(addULAccountOwnersFailure(response.data.message || null));
      } else if (path.action === HttpMethods.PUT) {
        yield put(updateULAccountOwnersFailure(UL_UPDATE_ACCOUNT_OWNERS_FAILED));
      } else {
        yield put(getULAccountOwnersFailure(UL_ACCOUNT_OWNERS_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_LOGINS_ERROR));
  }
}

function* sendAccountOwnersCheckEmailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      email: payload.email
    };
    const path = API.accountOwnersCheckEmail.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, params, apiPath: path, action: API.accountOwnersCheckEmail.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountOwnersCheckEmailResponse(response.data));
    } else {
      yield put(accountOwnersCheckEmailFailure(UL_ACCOUNT_OWNERS_CHECK_EMAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_LOGINS_ERROR));
  }
}

function* sendULDeleteAccOwnersRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      data: {},
      apiPath: API.deleteAccountOwner.apiPath.replace(':accountId', payload.accountId).replace(':ownerId', payload.ownerId),
      action: API.deleteAccountOwner.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const activeUserId = store.getState().login.activeUser?.id || 0;
      if (payload?.isLogins && activeUserId === payload.ownerId) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, UL_ACTIVE_USER_DELETED_MSG);
        yield put(logoutRequest());
      } else yield put(deleteULAccountOwnersResponse());
    } else {
      yield put(deleteULAccountOwnersFailure(UL_DELETE_ACCOUNT_OWNERS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_LOGINS_ERROR));
  }
}

function* sendULResendInviteForUserRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {};
    const path = API.resendIniviteToUser.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.resendIniviteToUser.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(ulResendInviteForUserResponse(null));
    } else {
      yield put(ulResendInviteForUserFailure(UL_RESEND_INIVITE_FOR_USER_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(USER_LOGINS_ERROR));
  }
}

export function* takeUserLoginsRequest() {
  yield takeEvery(types.UL_GET_ACCOUNT_OWNERS_REQUEST, sendULAccountOwnersRequest);
  yield takeEvery(types.ACCOUNT_OWNERS_CHECK_EMAIL_REQUEST, sendAccountOwnersCheckEmailRequest);
  yield takeEvery(types.UL_DELETE_ACCOUNT_OWNERS_REQUEST, sendULDeleteAccOwnersRequest);
  yield takeEvery(types.UL_RESEND_INVITE_FOR_USER_REQUEST, sendULResendInviteForUserRequest);
}
