import { Modal } from 'react-bootstrap';

import { NoDataFound } from '../NoDataFound';
import { NoDataFoundProps } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { commonModalPopup } from 'types';

export const TMLoginsLocations = ({ showLocationsModal, locationslist, handleModalClose }: commonModalPopup.ITMLoginsLocationsModalPopup) => {
  return (
    <Modal className={`prime-modal eft-modal edit__fav--modal tmLogin-MultiLocationsModal`} aria-labelledby="contained-modal-title-vcenter" centered show={showLocationsModal} animation={false}>
      <div className="mod__close--icon" onClick={handleModalClose}>
        <ImageValidation isImgValid defaultImg="xmark" customClassname="mod__close--img" customName={'close'} />
      </div>
      <Modal.Body>
        <div className="eftMain">
          <div className="eft-top r-flx r-flx-ac r-flx-jb">
            <div className="glbl__title--txt">Locations</div>
            {/* <div className="eft__close--icon" onClick={handleModalClose}>
              <ImageValidation isImgValid defaultImg="xmark" customName="close" />
            </div> */}
          </div>
          <div className="eft-listings">
            {locationslist.length ? (
              locationslist.map((locItem, locIndex) => {
                return (
                  <div className="eft-items" key={`mg-fav-tags${locIndex}`}>
                    <div className="fltr-control">
                      <span className="label-txt" onClick={(event) => event.preventDefault()} style={{ cursor: 'default' }}>
                        {locItem}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoDataFound size={NoDataFoundProps.FULL} />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
