import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { ProfileImageryAction } from 'actions';
import { profileImageryReducerTypes } from 'types';
import {
  SELECTED_ASSET_INITIAL_VAL,
  UPDATE_PI_INITAIL_DATA,
  PI_PICTURE_TYPE,
  UPDATE_PI_PROFILE_PHOTO_SUCCESS,
  UPDATE_PI_COVER_PHOTO_FAILED,
  UPDATE_PI_COVER_PHOTO_SUCCESS,
  ADD_POST_IMAGES_ACTIVE_TAB
} from 'utils/constants';

const initialState: profileImageryReducerTypes.IProfileImageryReducer = {
  isFetching: true,
  isPhotoFetching: false,
  error: null,
  profileImageryDetails: null,
  photosCurrentPage: 1,
  photos: [],
  selectedAsset: SELECTED_ASSET_INITIAL_VAL,
  updatePIDetails: UPDATE_PI_INITAIL_DATA,
  activeMediaLeftTab: ADD_POST_IMAGES_ACTIVE_TAB.ADD_NEW_IMAGE,
  selectSingleMedia: null,
  showProfileConfirmPopup: false,
  showCoverConfirmPopup: false
};

const profileImageryReducer: Reducer<profileImageryReducerTypes.IProfileImageryReducer, ProfileImageryAction> = (
  state: profileImageryReducerTypes.IProfileImageryReducer = initialState,
  action: ProfileImageryAction
): profileImageryReducerTypes.IProfileImageryReducer => {
  switch (action.type) {
    // PI - GET PROFILE IMAGERY LIST
    case types.GET_PROFILE_IMAGERY_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_PROFILE_IMAGERY_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        profileImageryDetails: action.payload
      };
    case types.GET_PROFILE_IMAGERY_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // PI - GET FILTER PROFILE IMAGERY LIST
    case types.GET_FILTER_PROFILE_IMAGERY_LIST_REQUEST:
      return {
        ...state,
        isPhotoFetching: true,
        photosCurrentPage: action.payload.page || 1
      };
    case types.GET_FILTER_PROFILE_IMAGERY_RESPONSE:
      return {
        ...state,
        isPhotoFetching: false,
        photos: state.photosCurrentPage === 1 ? action.payload.photos : [...state.photos, ...action.payload.photos]
      };
    case types.GET_FILTER_PROFILE_IMAGERY_FAILURE:
      return {
        ...state,
        isPhotoFetching: false,
        photosCurrentPage: 1,
        photos: [],
        error: action.payload || 'Unexpected error'
      };
    // PI - CHANGE ACCOUNT PICTURES
    case types.UPDATE_PI_ACCOUNT_PICTURE_REQUEST:
      return {
        ...state,
        isFetching: true,
        updatePIDetails: UPDATE_PI_INITAIL_DATA
      };
    case types.UPDATE_PI_ACCOUNT_PICTURE_RESPONSE:
      return {
        ...state,
        isFetching: false,
        // cloudinaryData: COULDINARY_INITIAL_DATA,
        updatePIDetails: {
          ...state.updatePIDetails,
          isUpdated: true,
          updateSuccessMsg: action.payload === PI_PICTURE_TYPE.PROFILE ? UPDATE_PI_PROFILE_PHOTO_SUCCESS : UPDATE_PI_COVER_PHOTO_SUCCESS
        }
      };
    case types.UPDATE_PI_ACCOUNT_PICTURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        updatePIDetails: {
          ...state.updatePIDetails,
          isUpdated: false,
          updateFailureMsg: action.payload === PI_PICTURE_TYPE.PROFILE ? UPDATE_PI_PROFILE_PHOTO_SUCCESS : UPDATE_PI_COVER_PHOTO_FAILED
        },
        error: action.payload || 'Unexpected error'
      };
    // PI - SET SELECTED ASSETS
    case types.SET_PI_SELECTED_ASSETS:
      return {
        ...state,
        selectedAsset: action.payload
      };
    // PI - SET UPDATE DETAILS
    case types.SET_PI_UPDATE_DETAILS_STAT:
      return {
        ...state,
        updatePIDetails: action.payload
      };
    // PI - PROFILE IMAGERY SET SELECTED SINGLE MEDIA
    case types.PROFILE_IMAGERY_SET_SELECTED_MEDIA:
      return {
        ...state,
        selectSingleMedia: action.payload
      };
    // PI - PROFILE IMAGERY SET ACTIVE MEDIA TAB
    case types.PROFILE_IMAGERY_SET_ACTIVE_TAB:
      return {
        ...state,
        activeMediaLeftTab: action.payload
      };
    // PI - PROFILE IMAGERY SET SHOW PROFILE CONFIRM POP-UP
    case types.PROFILE_IMAGERY_SET_SHOW_PROFILE_CONFIRM_POPUP:
      return {
        ...state,
        showProfileConfirmPopup: action.payload
      };
    // PI - PROFILE IMAGERY SET SHOW COVER CONFIRM POP-UP
    case types.PROFILE_IMAGERY_SET_SHOW_COVER_CONFIRM_POPUP:
      return {
        ...state,
        showCoverConfirmPopup: action.payload
      };
    // PI - PROFILE IMAGERY RESET ALL
    case types.PROFILE_IMAGERY_RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};

export { profileImageryReducer };
