import Select, { CSSObjectWithLabel } from 'react-select';

import { commonWidgetTypes } from 'types';

export const CustomDropdown = ({
  classNamePrefix,
  id,
  placeholder,
  onChange,
  value,
  options,
  isSearchable,
  menuIsOpen,
  components,
  styles,
  onMenuOpen,
  onMenuClose,
  autoFocus,
  name,
  formatOptionLabel
}: commonWidgetTypes.ICustomDropdownProps) => {
  /** CUSTOM STYLE PROVIDED TO DROPDOWN */
  const selectStyles = {
    control: (provided: CSSObjectWithLabel) => ({ ...provided, minWidth: 240, margin: 8, boxShadow: 'none' }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
  };

  return (
    <Select
      classNamePrefix={classNamePrefix}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={options}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      components={components}
      styles={styles || selectStyles}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      autoFocus={autoFocus}
      isOptionDisabled={(option) => option.disabled || false}
      name=""
      formatOptionLabel={formatOptionLabel}
    />
  );
};
