import { useNavigate } from 'react-router';

import { useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { CONNECTION_STATUS_ARRAY, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ } from 'utils/constants';
import { CommonFilter } from 'components';
import { IFiterHandleChangeData } from 'types/common/filter';

export const DLListingsFilter = () => {
  const navigate = useNavigate();

  const { queryParams } = useParamsDeconstructor();

  useParamsValidator(
    { ...CommonValidQueryParams, CONNECTION_STATUS: 'connection_status' },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      connection_status: CONNECTION_STATUS_ARRAY.filter((it) => it.value !== 'all').map((it) => it.value)
    }
  );

  const handleChange = (data: IFiterHandleChangeData) => {
    const queryParamsClone = { ...queryParams };
    if (data.connectionStatus) {
      if (data.connectionStatus === 'all') {
        delete queryParamsClone.connection_status;
      } else {
        queryParamsClone.connection_status = data.connectionStatus.toString();
      }
    }

    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  return (
    <CommonFilter
      filter={{ connectionStatus: queryParams?.connection_status || 'all' }}
      handleChange={handleChange}
      isDateRangeFilterEnable={false}
      checkBoxFilter={[
        {
          title: '',
          filter: CONNECTION_STATUS_ARRAY,
          value: 'connectionStatus'
        }
      ]}
    />
  );
};
