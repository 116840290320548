import Cryptr from 'cryptr';

import { config } from 'config';

export const cryptrEncrypt = (text: string) => {
  if (config.cryptoJs) {
    const cryptr = new Cryptr(config.cryptoJs);
    const ciphertext = cryptr.encrypt(text);
    return ciphertext;
  }
  return text;
};

export const cryptrDecrypt = (text: string) => {
  if (config.cryptoJs) {
    const cryptr = new Cryptr(config.cryptoJs);
    const plaintext = cryptr.decrypt(text);
    return plaintext;
  }
  return text;
};

export const cryptrVerify = (text: any): boolean => {
  if (config.cryptoJs) {
    const cryptr = new Cryptr(config.cryptoJs);
    try {
      cryptr.decrypt(text);
      return true;
    } catch (ex) {
      return false;
    }
  }
  return false;
};
