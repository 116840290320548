// SETTINGS RALLIO PROFILE - STATE UPDATES
export const SETTINGS_UPDATE_RALLIO_PROFILE = 'rallioProfile/SETTINGS_UPDATE_RALLIO_PROFILE';

// UPDATE AND RESET PROFILE STATUS
export const SETTINGS_UPDATE_PROFILE_STATUS = 'rallioProfile/SETTINGS_UPDATE_PROFILE_STATUS';
export const SETTINGS_RESET_PROFILE_STATUS = 'rallioProfile/SETTINGS_RESET_PROFILE_STATUS';

// GET TIME ZONES
export const GET_TIME_ZONES_REQUEST = 'rallioProfile/GET_TIME_ZONES_REQUEST';
export const GET_TIME_ZONES_RESPONSE = 'rallioProfile/GET_TIME_ZONES_RESPONSE';
export const GET_TIME_ZONES_FAILURE = 'rallioProfile/GET_TIME_ZONES_FAILURE';

// GET COUNTRIES CODE
export const GET_COUNTRIES_CODE_REQUEST = 'rallioProfile/GET_COUNTRIES_CODE_REQUEST';
export const GET_COUNTRIES_CODE_RESPONSE = 'rallioProfile/GET_COUNTRIES_CODE_RESPONSE';
export const GET_COUNTRIES_CODE_FAILURE = 'rallioProfile/GET_COUNTRIES_CODE_FAILURE';

// ACCOUNTS - POST PERSONALIZATION VALIDATION
export const SETTINGS_VALIDATE_POST_PERSONALIZATION_REQUEST = 'accounts/SETTINGS_VALIDATE_POST_PERSONALIZATION_REQUEST';
export const SETTINGS_VALIDATE_POST_PERSONALIZATION_RESPONSE = 'accounts/SETTINGS_VALIDATE_POST_PERSONALIZATION_RESPONSE';
export const SETTINGS_VALIDATE_POST_PERSONALIZATION_FAILURE = 'accounts/SETTINGS_VALIDATE_POST_PERSONALIZATION_FAILURE';

// RESET
export const RALLIO_PROFILE_RESET_ALL = 'rallioProfile/RALLIO_PROFILE_RESET_ALL';
