import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AccountSwitcherActions } from 'actions';
import { commonAccountSwitcher } from 'types';
import { FRANCHISORS_SELECTIONS, LOCATION, LOCATION_LISTS } from 'utils/constants';
import { addPostLocationListDropdown, getHubLocListForDropdown } from 'utils/helpers';

const initialState: commonAccountSwitcher.IAccountSwitcherReducer = {
  isAccountFetching: false,
  error: null,
  currentName: '',
  bannerList: [],
  franchisors: [],
  accounts: [],
  franchisorGroups: [],
  userOwnership: null,
  id: null,
  locationsCount: 0,
  topLevelFranchisor: '',
  topLevelFranchisorId: 0,
  childCount: 0,
  latitue: null,
  longitude: null,
  childPresent: false,
  isLocationSelectorFetching: false,
  locationSelectorError: null,
  locationSelectorResponse: {},
  accountSelectionList: {},
  brandImageUrl: '',
  brandEmail: '',
  logoCloudinaryId: '',
  parentFranchisorId: 0,
  pvLocationId: null,
  currentAccountOrFranchisor: null,
  menu_access: null,
  brand_config: null,
  showRevv: false,
  rallio_ai: false,
  ai_review_responder: false,
  ai_features: [],
  content_customize_link: false,
  rallio_profile_disabled: false,
  content_supplier_franchisor: false,
  topLevelFranchisorObj: null,
  isBrand: false
};

const accountSwitcherReducer: Reducer<commonAccountSwitcher.IAccountSwitcherReducer, AccountSwitcherActions> = (
  state: commonAccountSwitcher.IAccountSwitcherReducer = initialState,
  action: AccountSwitcherActions
) => {
  switch (action.type) {
    case types.ACCOUNT_SWITCHER_REQUEST:
      return {
        ...state,
        isAccountFetching: true,
        error: null,
        currentName: action.payload.label,
        franchisors: [],
        accounts: [],
        franchisorGroups: [],
        currentAccountOrFranchisor: null,
        topLevelFranchisorObj: null
      };
    case types.ACCOUNT_SWITCHER_REQUEST_FAIL:
      return { ...state, isAccountFetching: false, error: action.payload };
    case types.ACCOUNT_SWITCHER_RESPONSE: {
      return {
        ...state,
        isAccountFetching: false,
        error: null,
        currentName: action.payload.current_name,
        bannerList: action.payload.banners || [],
        franchisors: action.payload.franchisors,
        accounts: action.payload.accounts,
        franchisorGroups: action.payload.franchisor_groups,
        topLevelFranchisor: action.payload.top_level_franchisor ? action.payload.top_level_franchisor.name : '',
        topLevelFranchisorId: action.payload.top_level_franchisor ? action.payload.top_level_franchisor.id : 0,
        childCount: action.payload.current_account_or_franchisor.child_count,
        latitue: action.payload.current_account_or_franchisor.latitude,
        longitude: action.payload.current_account_or_franchisor.longitude,
        childPresent: action.payload.current_account_or_franchisor.child_present,
        logoCloudinaryId: action.payload.top_level_franchisor ? action.payload.top_level_franchisor.logo_cloudinary_id : '',
        brandImageUrl: action.payload.top_level_franchisor ? action.payload.top_level_franchisor.logo_url : '',
        brandEmail: action.payload.top_level_franchisor ? action.payload.top_level_franchisor.email : '',
        parentFranchisorId: action.payload?.current_account_or_franchisor?.parent_franchisor_id || 0,
        pvLocationId: action.payload?.top_level_franchisor?.pv_location_id || null,
        topLevelFranchisorObj: action.payload.top_level_franchisor || null,
        currentAccountOrFranchisor: action.payload.current_account_or_franchisor,
        locationsCount: action.payload?.top_level_franchisor?.accounts_count || 0,
        ...(action.payload?.current_account_or_franchisor?.menu_access?.page_exclusions?.hub_user?.length ||
        action.payload?.current_account_or_franchisor?.menu_access?.page_exclusions?.location_user?.length
          ? {
              menu_access: action.payload?.current_account_or_franchisor.menu_access
            }
          : {
              menu_access: action.payload?.top_level_franchisor?.menu_access || null
            }),
        ...(action.payload?.current_account_or_franchisor?.brand_config
          ? {
              brand_config: action.payload?.current_account_or_franchisor?.brand_config
            }
          : {
              brand_config: action.payload?.top_level_franchisor?.brand_config || null
            }),
        userOwnership: action.payload.userOwnership || null,
        id: action.payload.id || null,
        rallio_ai: action.payload.current_account_or_franchisor?.rallio_ai || false,
        ai_review_responder: action.payload.current_account_or_franchisor?.ai_review_responder || false,
        ai_features: action.payload.current_account_or_franchisor?.ai_features ?? null,
        content_customize_link: action.payload.current_account_or_franchisor?.content_customize_link || false,
        rallio_profile_disabled: action.payload.current_account_or_franchisor?.rallio_profile_disabled || false,
        content_supplier_franchisor: action.payload.top_level_franchisor?.content_supplier_franchisor || false,
        isBrand: action.payload.current_account_or_franchisor?.is_brand || false
      };
    }
    case types.USER_OWNERSHIP:
      return {
        ...state,
        isAccountFetching: false,
        error: null,
        userOwnership: action.payload.userOwnership,
        id: action.payload.id
      };
    case types.LOCATION_SELECTOR_LIST_REQUEST:
      return {
        ...state,
        isLocationSelectorFetching: true,
        locationSelectorError: null
      };
    case types.LOCATION_SELECTOR_LIST_RESPONSE:
      const accountSelectionList = addPostLocationListDropdown(action.payload);
      const hubList = getHubLocListForDropdown(accountSelectionList, FRANCHISORS_SELECTIONS);
      const locationList = getHubLocListForDropdown(accountSelectionList, LOCATION_LISTS);
      const onlyLocationList = getHubLocListForDropdown(accountSelectionList, LOCATION);
      return {
        ...state,
        isLocationSelectorFetching: false,
        locationSelectorError: null,
        locationSelectorResponse: action.payload,
        accountSelectionList: {
          hubList,
          locationList,
          onlyLocationList
        }
      };
    case types.LOCATION_SELECTOR_LIST_FAILURE:
      return {
        ...state,
        isLocationSelectorFetching: false,
        locationSelectorError: action.payload
      };
    case types.SET_MENU_ACCESS:
      return {
        ...state,
        menu_access: action.payload
      };
    case types.UPDATE_REVV_STATE:
      return {
        ...state,
        showRevv: action.payload
      };
    case types.ACCOUNT_SWITCHER_DATA_RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export { accountSwitcherReducer };
