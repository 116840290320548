import { useMemo, useState } from 'react';

import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData } from 'utils/hooks';
import { COMMON_SOCIAL_PROVIDER_TYPE, CONNECTION_TABS, FB_CALL_TO_ACTION_SELECT_OPTIONS, RALLIO_PROFILE_OBJ_TYPE, RALLIO_PROFILE_TAB_FILEDS, RIPPLE_COLOR } from 'utils/constants';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { compareProfileObjects, isValidUrl } from 'utils/helpers';
import { AddSocialMediaCallToAction } from 'containers/Content/Creator/CommonModal';
import { settingsRallioProfileContainerTypes } from 'types';

export const CTAFieldSection = ({
  getClassNames,
  getInputBoxClassName,
  handleIndividualCancel,
  handleIndividualSave,
  values,
  actualValues,
  handleChange
}: Omit<settingsRallioProfileContainerTypes.ISettingsCommonCompProps, 'handleBlur' | 'getInputTitleClassName'>) => {
  const { id, optionalParams, userOwnership } = useAccountSwitcherData();

  const [customFieldAction, setCustomFieldAction] = useState({ actionType: `cta_${FB_CALL_TO_ACTION_SELECT_OPTIONS[0].value.toLowerCase()}`, url: '' });
  const [selectedCTA, setSelectedCTA] = useState<{ action: string; url: string } | null>(null);
  const [openCTAModal, setOpenCTAModal] = useState(false);
  const isCTADisabled = customFieldAction.actionType === `cta_${FB_CALL_TO_ACTION_SELECT_OPTIONS[0].value.toLowerCase()}`;

  const customFieldsArr: string[] = useMemo(() => {
    return Object.keys(values.custom_fields).filter((field) => {
      return !field.includes('custom_field');
    });
  }, [values.custom_fields, actualValues.custom_fields]); // eslint-disable-line

  const handleAddCTAField = () => {
    const sortCTAFields = [customFieldAction.actionType, ...Object.keys(values.custom_fields)].sort().reduce((acc: any, curr: any) => {
      if (curr === customFieldAction.actionType) {
        acc[customFieldAction.actionType] = customFieldAction.url;
      } else {
        acc[curr] = values.custom_fields[curr];
      }
      return acc;
    }, {});
    handleChange(RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS, sortCTAFields);
    resetCustomField();
  };

  const resetCustomField = () => {
    setCustomFieldAction({ actionType: `cta_${FB_CALL_TO_ACTION_SELECT_OPTIONS[0].value.toLowerCase()}`, url: '' });
  };

  const deleteCTAField = (field: string) => {
    const obj = { ...values.custom_fields };
    delete obj[field];
    handleChange(RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS, obj);
  };

  const handleModalClose = () => {
    setSelectedCTA(null);
    setOpenCTAModal(false);
  };

  const handleCancel = () => {
    delete values.custom_fields[customFieldAction.actionType as string];
    handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS);
    resetCustomField();
  };

  return (
    <div className="settings-rallio-profile-new-section caf-wrap wn-cta wnh">
      <div className="wn-top-cnt">
        <h3>
          <span>Fields used for posts with Call-To-Action buttons</span>
        </h3>
        <p>
          Select the Action, enter the URL, and click <ImageValidation isImgValid defaultImg="add-circle-green" customName="Add URL" customClassname="wn-add" /> to add.
        </p>
      </div>
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Call To Action Fields</h3>
        </div>
        <div className="content-right-section-second-item form-elements-new">
          <div className="caf-cr-top">
            <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
              <CustomDropdown
                classNamePrefix="cs-select"
                id="dl-store-details-country-dropdown"
                value={FB_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => `cta_${selectOption.value.toLowerCase()}` === customFieldAction.actionType)}
                placeholder={''}
                onChange={(eventObj: any) => {
                  setCustomFieldAction((prev) => ({ ...prev, actionType: `cta_${eventObj.value.toLowerCase()}` }));
                }}
                options={FB_CALL_TO_ACTION_SELECT_OPTIONS}
              />
              <span className={`fltlabels`}>Select Action</span>
            </div>
            <div className={`form-group form-field-item m-align-items ${getInputBoxClassName('url')}`}>
              <input
                type="text"
                name="url"
                value={customFieldAction.url}
                className={getClassNames('url')}
                onChange={(e) => setCustomFieldAction((prev) => ({ ...prev, url: e.target.value }))}
                autoComplete="off"
                // onBlur={handleBlur}
                disabled={isCTADisabled}
              />
              <span className={`fltlabels`}>URL</span>
            </div>
            {isValidUrl(customFieldAction.url) && !isCTADisabled ? (
              <div className="indivual-button-section">
                <div className="stg-item form-configure">
                  <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.aiprompt}>
                    <button className={`ac-btn ac-primary size-xs`} onClick={handleAddCTAField}>
                      Add
                    </button>
                  </CustomRippleButton>
                </div>
              </div>
            ) : null}
          </div>
          <div className="caf-cr-base">
            {customFieldsArr.length ? (
              <div className="caf-added">
                {customFieldsArr.map((ctaField, index) => (
                  <div className="cafa-item" key={`cta-field-${index}`}>
                    <div
                      className="cafai-left"
                      onClick={() => {
                        setSelectedCTA({ action: ctaField, url: values.custom_fields[ctaField] as string });
                        setOpenCTAModal(true);
                      }}
                    >
                      <span className="caf-actions">{ctaField}</span>
                      <span className="caf-arrow" />
                      <span className="caf-url">{values.custom_fields[ctaField] as string}</span>
                    </div>
                    <span className="list-expanded-tag-remove" onClick={() => deleteCTAField(ctaField)} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.CTA_FIELDS, values, actualValues) && (
            <div className="indivual-button-section">
              <div className="stg-item form-configure">
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={handleCancel}>
                    Cancel
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button className="ac-btn ac-primary size-xs" onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS)}>
                    Save
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          )}

          {openCTAModal ? (
            <AddSocialMediaCallToAction
              id={id}
              userOwnership={userOwnership}
              isShowModal={openCTAModal}
              onModalClose={handleModalClose}
              actionTypeOptions={FB_CALL_TO_ACTION_SELECT_OPTIONS}
              currentTab={COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK}
              ctaAction={selectedCTA}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
