// DL - GET CATEGORIES
export const GET_DL_CATEGORIES_REQUEST = 'directory_listings/GET_DL_CATEGORIES_REQUEST';
export const GET_DL_CATEGORIES_RESPONSE = 'directory_listings/GET_DL_CATEGORIES_RESPONSE';
export const GET_DL_CATEGORIES_FAILURE = 'directory_listings/GET_DL_CATEGORIES_FAILURE';

// DL - GET SYNUP LISTINGS
export const DL_SYNUP_LISTING_REQUEST = 'directory_listings/DL_SYNUP_LISTING_REQUEST';
export const DL_SYNUP_LISTING_RESPONSE = 'directory_listings/DL_SYNUP_LISTING_RESPONSE';
export const DL_SYNUP_LISTING_FAILURE = 'directory_listings/DL_SYNUP_LISTING_FAILURE';

// DL - UPDATE DIRECTORY LISTING
export const DL_UPDATE_REQUEST = 'directory_listings/DL_UPDATE_REQUEST';
export const DL_UPDATE_RESPONSE = 'directory_listings/DL_UPDATE_RESPONSE';
export const DL_UPDATE_FAILURE = 'directory_listings/DL_UPDATE_FAILURE';

// DL - ADD BUSINESS PHOTO IN CLOUDINARY
export const DL_ADD_BUSINESS_PHOTO_IN_CLOUD_REQUEST = 'directory_listings/DL_ADD_BUSINESS_PHOTO_IN_CLOUD_REQUEST';
export const DL_ADD_BUSINESS_PHOTO_IN_CLOUD_RESPONSE = 'directory_listings/DL_ADD_BUSINESS_PHOTO_IN_CLOUD_RESPONSE';
export const DL_ADD_BUSINESS_PHOTO_IN_CLOUD_FAILURE = 'directory_listings/DL_ADD_BUSINESS_PHOTO_IN_CLOUD_FAILURE';

// DL - SET STORES DETAIL STATES
export const DL_SET_STORE_DETAILS = 'directory_listings/DL_SET_STORE_DETAILS';

// DL - SET DL-UPDATE STATES
export const DL_SET_UPDATE_STATUS = 'directory_listings/DL_SET_UPDATE_STATUS';

// DL - SET DL-BUSINESS HOURS
export const DL_SET_BUSINESS_HOURS = 'directory_listings/DL_SET_BUSINESS_HOURS';

// DL - SET DL-BUSINESS SPECIAL HOURS
export const DL_SET_BUSINESS_SPECIAL_HOURS = 'directory_listings/DL_SET_BUSINESS_SPECIAL_HOURS';

// DL - SET DL-SPECIAL HOURS WARNINGS
export const DL_SET_SPECIAL_HOURS_WARNINGS = 'directory_listings/DL_SET_SPECIAL_HOURS_WARNINGS';

// DL - SET DL-BUSINESS HOURS TIME SLOT
export const DL_SET_BUSINESS_HOURS_TIME_SLOT = 'directory_listings/DL_SET_BUSINESS_HOURS_TIME_SLOT';

// DL - SET DL-BUSINESS HOURS WARNINGS
export const DL_SET_BUSINESS_HOURS_WARNINGS = 'directory_listings/DL_SET_BUSINESS_HOURS_WARNINGS';

// DL - SET DL-BUSINESS PHOTO LIST
export const DL_SET_BUSINESS_PHOTO = 'directory_listings/DL_SET_BUSINESS_PHOTO';

// DL RESET ALL
export const DL_RESET_ALL = 'directory_listings/DL_RESET_ALL';
