import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { alertBoxCall, CreateCouponsModalPopup } from 'components/Common';
import {
  COUPONS_STATE_DATA,
  RIPPLE_COLOR,
  ROUTES,
  COUPON_FILTERS_INITIAL_OBJECT,
  COMMON_PAGE_TYPE,
  CouponsStateName,
  CouponsStateLabel,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  ALERT_BOX_TYPES,
  EXPORT_DOWNLOAD_STARTED_MSG
} from 'utils/constants';
import { getExportCouponCodes } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { IFiterHandleChangeData } from 'types/common/filter';

export const CouponsFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(COUPON_FILTERS_INITIAL_OBJECT);

  useParamsValidator(
    { ...CommonValidQueryParams, COUPON_STATE: 'state' },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      state: ['0', '1']
    }
  );

  const couponStatus = queryParams?.state || 'all';

  const couponDetail = useSelector((state: IStore) => state.coupons.couponDetail);
  const couponsStats = useSelector((state: IStore) => state.coupons.couponsStats);

  const [exportCodesFetching, setExportCodesFetching] = useState(false);

  const handleExportCodes = () => {
    if (!exportCodesFetching && couponDetail?.id) {
      alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
      getExportCouponCodes(couponDetail.id, setExportCodesFetching);
    }
  };

  const handleChange = (data: IFiterHandleChangeData) => {
    if (data.state === CouponsStateName.ALL) delete queryParams.state;
    else queryParams.state = data.state === CouponsStateName.ACTIVE ? '1' : '0';
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  return (
    <>
      <section className={`item-g filter coupon-filter ${optionalParams.length === 2 && optionalParams[1] === COMMON_PAGE_TYPE.DETAILS ? 'events-none pointer-events-none' : ''}`}>
        {(!optionalParams.length ||
          optionalParams[0] === COMMON_PAGE_TYPE.NEW ||
          optionalParams[1] === COMMON_PAGE_TYPE.EDIT ||
          (optionalParams.length === 2 && optionalParams[1] === COMMON_PAGE_TYPE.DETAILS)) && (
          <div className="filter-item">
            <CustomRippleButton rippleClass={`ac-primary-box ${!optionalParams.length ? '' : 'events-none pointer-events-none'}`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block ${!optionalParams.length ? '' : 'disabled'}`}
                onClick={() => navigate(ROUTES.CONTENT_COUPONS.replace(':type', userOwnership).replace(':id', id.toString()).replace('*', COMMON_PAGE_TYPE.NEW))}
              >
                New Coupon
              </button>
            </CustomRippleButton>
          </div>
        )}

        {(!optionalParams.length ||
          optionalParams[0] === COMMON_PAGE_TYPE.NEW ||
          optionalParams[1] === COMMON_PAGE_TYPE.EDIT ||
          (optionalParams.length === 2 && optionalParams[1] === COMMON_PAGE_TYPE.DETAILS)) && (
          <div className="coupons-fillter-section-wrp filter-item">
            <h3>State</h3>
            <div className={`form-group`}>
              {COUPONS_STATE_DATA.map((item, index) => {
                return (
                  <label key={index} className={couponStatus === item.name ? 'active' : ''}>
                    <input
                      type="radio"
                      className="option-input radio"
                      value={item.name}
                      checked={couponStatus === item.name}
                      onChange={(event) => {
                        handleChange({ state: event.target.value });
                        // dispatch(setCouponsFilters({ ...filters, state: event.target.name }));
                      }}
                    />
                    <span className="labelText">
                      {item.label} (
                      {item.label === CouponsStateLabel.ALL ? couponsStats.active + couponsStats.inactive : item.label === CouponsStateLabel.ACTIVE ? couponsStats.active : couponsStats.inactive})
                    </span>
                  </label>
                );
              })}
            </div>
          </div>
        )}
        {optionalParams.length === 1 && optionalParams[0] !== COMMON_PAGE_TYPE.NEW && (
          <>
            {couponDetail && (
              <CustomRippleButton rippleClass={`ac-primary-box r-mt4`}>
                <a href={couponDetail.coupon_url} target="blank" className={`ac-btn ac-primary ac-block`}>
                  <span>View Coupon</span>
                </a>
              </CustomRippleButton>
            )}
            <hr className="coupon-ruler" />
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary ac-block ${!exportCodesFetching ? `` : `disabled`}`} onClick={() => handleExportCodes()}>
                Export Codes
              </button>
            </CustomRippleButton>
          </>
        )}
        <CreateCouponsModalPopup />
      </section>
    </>
  );
};
