import { ModalPopup } from 'components';
import { postsContainerTypes } from 'types';
import { MODAL_BOX_TYPES } from 'utils/constants';
import { getRemovePostFromCampaignsConfirmMsg } from 'utils/helpers';

export const PostDeleteConfirmationModal = ({ isShowModal, onModalClose, campaigns }: postsContainerTypes.IPostDeleteConfirmationModalProps) => {
  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{getRemovePostFromCampaignsConfirmMsg(campaigns.length)}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={onModalClose}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={onModalClose}>
            No
          </button>
          <button className="modal-btn-action-itm modal-delete-btn">Yes</button>
        </div>
      )}
    />
  );
};
