import { takeLatest, put, delay, takeEvery } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  exceptionHandlerRequest,
  logoutRequest,
  getFBAdsTargetingChoicesResponse,
  getFBAdsTargetingChoicesFailure,
  disconnectFBAdsTargetingChoicesResponse,
  disconnectFBAdsTargetingChoicesFailure,
  getAccTargetingInterestsListResponse,
  getAccTargetingInterestsListFailure,
  updateAccFBDefaultConfigResponse,
  updateAccFBDefaultConfigFailure,
  getFBAdsTargetingChoicesRequest,
  getAccFacebookAdAccountDataResponse,
  getAccFacebookAdAccountDataFailure,
  getAccFacebookAdAccountIdResponse,
  getAccFacebookAdAccountIdFailure,
  updateAccFacebookAdAccountIdFailure,
  updateAccFacebookAdAccountIdResponse,
  addFacebookAdPreviewResponse,
  addFacebookAdPreviewFailure,
  getAverageLocationsSpendResponse,
  getAverageLocationsSpendFailure
} from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import {
  FB_ADS_TARGETING_CHOICES_FAILURE,
  FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_FAILURE,
  USER_OWNERSHIP,
  FB_ADS_DISCONNECT_TARGETING_CHOICES_FAILURE,
  FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_SUCCESS_MSG,
  SAGAS_DELAY_SECONDS,
  FB_ADS_GET_AD_ACCOUNT_UUID_FAILURE,
  FB_ADS_GET_AD_ACCOUNT_ID_FAILURE,
  FB_ADS_INTEREST_LIST_FAILURE,
  FB_ADS_ADD_AD_PREVIEW_FAILURE,
  FB_ADS_GET_AVERAGE_LOCATIONS_SPEND_ERROR_MSG
} from 'utils/constants';
import { ISagaPayload } from 'types/common/api';

function* sendGetFBAdsTargetingChoicesRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      path = API.getFBAdsFranChoices.apiPath.replace(':id', payload.id);
      response = yield apiCall({ headers, apiPath: path, action: API.getFBAdsFranChoices.action });
    } else {
      path = API.getFBAdsAccChoices.apiPath.replace(':id', payload.id);
      response = yield apiCall({ headers, apiPath: path, action: API.getFBAdsAccChoices.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        getFBAdsTargetingChoicesResponse(
          payload.userOwnership === USER_OWNERSHIP.FRANCHISOR
            ? response.data?.franchisor_facebook_ad_account_choice
            : response.data?.facebook_ad_account_choice
            ? {
                ...response.data?.facebook_ad_account_choice,
                default_configuration: {
                  ...response.data?.facebook_ad_account_choice.default_configuration,
                  objective: 'post_engagement' // The goal should be hard-coded to Engagement
                }
              }
            : null
        )
      );
    } else {
      yield put(getFBAdsTargetingChoicesFailure(FB_ADS_TARGETING_CHOICES_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendDisconnectFBAdsTargetingChoicesRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      path = API.disconnectFBAdsFranChoices.apiPath.replace(':id', payload.id);
      response = yield apiCall({ headers, apiPath: path, action: API.disconnectFBAdsFranChoices.action });
    } else {
      path = API.disconnectFBAdsAccChoices.apiPath.replace(':id', payload.id);
      response = yield apiCall({ headers, apiPath: path, action: API.disconnectFBAdsAccChoices.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(disconnectFBAdsTargetingChoicesResponse(response.data));
    } else {
      yield put(disconnectFBAdsTargetingChoicesFailure(FB_ADS_DISCONNECT_TARGETING_CHOICES_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendGetAccTargetingInterestsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      query: payload.search
    };
    const apiObj = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? API.getFranTargetingInterests : API.getAccTargetingInterests;
    const path = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, params, apiPath: path, action: apiObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAccTargetingInterestsListResponse(response.data.interests));
    } else {
      yield put(getAccTargetingInterestsListFailure(FB_ADS_INTEREST_LIST_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendUpdateAccFBTargetDefaultConfigRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    const data = {
      default_configuration: payload.reqPayload
    };
    path = API.updateAccDefaultConfig.apiPath.replace(':id', payload.accountId);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.updateAccDefaultConfig.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateAccFBDefaultConfigResponse(FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_SUCCESS_MSG));
      yield delay(SAGAS_DELAY_SECONDS);
      yield put(updateAccFBDefaultConfigResponse(null));
      yield put(getFBAdsTargetingChoicesRequest({ id: payload.accountId, userOwnership: USER_OWNERSHIP.ACCOUNT, initialLoad: false }));
    } else {
      yield put(updateAccFBDefaultConfigFailure(FB_ADS_UPDATE_ACC_DEFAULT_CONFIG_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendAccFacebookAdAccountDataRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getFBAdSingleAccount.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getFBAdSingleAccount.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAccFacebookAdAccountDataResponse(response.data.facebook_user_credential));
    } else {
      yield put(getAccFacebookAdAccountDataFailure(FB_ADS_GET_AD_ACCOUNT_UUID_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendAccFacebookAdAccountIdRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getFBAdSingleAccountId.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getFBAdSingleAccountId.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAccFacebookAdAccountIdResponse(response.data?.facebook_ad_accounts?.length ? response.data?.facebook_ad_accounts : []));
    } else {
      yield put(getAccFacebookAdAccountIdFailure(FB_ADS_GET_AD_ACCOUNT_ID_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* updateAccFacebookAdAccount(params: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      facebook_user_credential_id: params.payload.credentialId,
      facebook_ad_account_id: params.payload.adAccountId
    };
    let apiPath = '';
    if (params.payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      apiPath = API.updateFBAdsFranChoices.apiPath.replace(':id', params.payload.accountId);
    } else {
      apiPath = API.updateFBAdsAccChoices.apiPath.replace(':id', params.payload.accountId);
    }
    const response = yield apiCall({ headers, apiPath, data, action: API.updateFBAdsAccChoices.action });
    if (response.status === 200) {
      yield put(updateAccFacebookAdAccountIdResponse());
      if (params.payload.callback) {
        yield params.payload.callback();
      }
      yield put(getFBAdsTargetingChoicesRequest({ id: params.payload.accountId, userOwnership: params.payload.userOwnership, initialLoad: false }));
    }
  } catch (error) {
    yield put(updateAccFacebookAdAccountIdFailure(`${error}`));
  }
}

function* sendAddFacebookAdPreviewRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { account_id: payload.accountId };
    const data = payload.reqPayload;
    const response = yield apiCall({ headers, params, data, ...API.addFBAdPreview });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addFacebookAdPreviewResponse(response.data.facebook_ad_preview));
    } else {
      yield put(addFacebookAdPreviewFailure(FB_ADS_ADD_AD_PREVIEW_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

function* sendGetAverageLocationsSpendRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiPath = API.getAverageLocationsSpend.apiPath.replace(':id', payload.franchisorId);
    const params = payload.params;
    const response = yield apiCall({ headers, params, apiPath, action: API.getAverageLocationsSpend.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAverageLocationsSpendResponse({ data: response.data.spends || [], actionType: payload.actionType || null }));
    } else {
      yield put(getAverageLocationsSpendFailure(response.data.message ? FB_ADS_GET_AVERAGE_LOCATIONS_SPEND_ERROR_MSG : null));
      // delay(SAGAS_DELAY_SECONDS);
      // yield put(getAverageLocationsSpendFailure(null));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${error}`));
  }
}

export function* takeFBAdsTargetingRequest() {
  yield takeLatest(types.GET_FB_ADS_TARGETING_CHOICES_REQUEST, sendGetFBAdsTargetingChoicesRequest);
  yield takeLatest(types.DISCONNECT_FB_ADS_TARGETING_CHOICES_REQUEST, sendDisconnectFBAdsTargetingChoicesRequest);
  yield takeLatest(types.GET_ACC_TARGETING_INTERESTS_LIST_REQUEST, sendGetAccTargetingInterestsListRequest);
  yield takeLatest(types.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_REQUEST, sendUpdateAccFBTargetDefaultConfigRequest);
  yield takeEvery(types.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_REQUEST, sendAccFacebookAdAccountDataRequest);
  yield takeEvery(types.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_REQUEST, sendAccFacebookAdAccountIdRequest);
  yield takeLatest(types.UPDATE_FACEBOOK_AD_ACCOUNT_REQUEST, updateAccFacebookAdAccount);
  yield takeLatest(types.ADD_FACEBOOK_AD_PREVIEW_REQUEST, sendAddFacebookAdPreviewRequest);
  yield takeLatest(types.GET_AVERAGE_LOCATIONS_SPEND_REQUEST, sendGetAverageLocationsSpendRequest);
}
