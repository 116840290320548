import { config } from 'config';
import { commonWidgetTypes } from 'types';

export const Image = (props: commonWidgetTypes.IImageProps) => (
  // <div className={props.customClassname} {...(props.onClick && { onClick: props.onClick })}>
  <img
    src={!props.isImageExists ? `${config.cdnImgUrl}${props.customImgName}` : props.customImgName ? props.customImgName : `${config.cdnImgUrl}${props.customImgName}`}
    className={props.className ? props.className : ''}
    alt={props.customName}
    loading="lazy"
  />
  // </div>
);
