import { inboxReducerTypes, inboxApiTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, FeedValue, INBOX_ENGAGEMENT_FILTER_LIST } from 'utils/constants';

/** Show or hide delete button for inbox comments  */
export const canDeleteInboxComments = (feedType: null | string, socialMediaType: string) => {
  if (feedType === FeedValue.OUTBOX && [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(socialMediaType)) return true;
  else return false;
};

/** Get dismissed Ids after replying to inbox comments  */
export const getDismissedIdsAfterReply = (inboxResponse: inboxReducerTypes.IInboxCommentsObj, previousDismissedId: number[]) => {
  const filteredDismissIds = inboxResponse.modifiedComments
    ? Object.entries(inboxResponse.modifiedComments).reduce((acc: number[], curr) => {
        const atLeastOnePreviousDismissedId = previousDismissedId.some((value) => {
          return curr[1].map((item: inboxApiTypes.IInboxComments) => item.id).includes(value);
        });
        if (+curr[0] === inboxResponse.socialContentId && atLeastOnePreviousDismissedId) {
          const dismissedIds = curr[1].filter((datum: inboxApiTypes.IInboxComments) => datum.parent_social_media_id).map((item: inboxApiTypes.IInboxComments) => item.id);
          acc = [...acc, ...dismissedIds];
        }
        return acc;
      }, [])
    : [];
  return Array.from(new Map([...previousDismissedId, ...filteredDismissIds].map((dataItem) => [dataItem, dataItem])).values());
};

export const getInboxEngagementTypeData = (platform: string) => {
  switch (platform) {
    case COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK:
      return INBOX_ENGAGEMENT_FILTER_LIST;
    case COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM:
      return INBOX_ENGAGEMENT_FILTER_LIST.filter((it) => ['Post Comments', 'Direct Messages'].includes(it.value));
    case COMMON_SOCIAL_PROVIDER_TYPE.TWITTER:
      return INBOX_ENGAGEMENT_FILTER_LIST.filter((it) => ['Post Comments'].includes(it.value));
    case COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN:
      return INBOX_ENGAGEMENT_FILTER_LIST.filter((it) => ['Post Comments'].includes(it.value));
    case COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE:
      return INBOX_ENGAGEMENT_FILTER_LIST.filter((it) => ['Post Comments'].includes(it.value));
    default:
      return INBOX_ENGAGEMENT_FILTER_LIST;
  }
};
