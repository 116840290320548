import { rpReducerTypes, rpApiTypes } from 'types';

export const RP_MAIN_PAGES = {
  LANDING: 'landing',
  DETAIL_VIEW: 'detail_view',
  CREATE_PROGRAM: 'create_program',
  EDIT_PROGRAM: 'edit_program',
  CP_DETAILS: 'details',
  CP_ACTIONS: 'actions',
  CP_LOCATIONS: 'locations',
  CP_SUMMARY: 'summary',
  CP_EMAIL: 'email',
  CP_PAYMENT: 'modifySubscription'
};

export const CREATE_PROGRAM_INIT_DETAILS_DATA: rpApiTypes.IRPProgramDetailData = {
  name: '',
  rewardsType: 1,
  recommendedType: 1,
  duration: 0,
  programDuration: 0,
  startDate: null,
  endDate: null,
  userId: '',
  programId: 0,
  accountId: 0,
  franchisorId: 0,
  programBudget: '',
  endafter: 0,
  occurrences: 0,
  stage: 0,
  emailNotificationStatus: 2,
  emailInvitationMessage: null,
  emailNotificationLastModified: null,
  typeCode: 0,
  rewardTimezone: '',
  adminEmail: '',
  rewardEmailScheduleDate: null
};

export const CREATE_PROGRAM_EMAIL_INITIAL_OBJ: rpReducerTypes.ICPEmailTemplateObj = {
  emailNotificationStatus: 2,
  emailScheduleDate: null,
  emailInvitationMessage: '',
  rewardEmailLogoUrl: ''
};

export const RP_TREE_INIT_OBJ: rpReducerTypes.ICreateProgramLocationsObj = {
  rpTreeList: [],
  searchTreeList: [],
  topLevelFranchisor: [],
  expandedKeysList: [],
  checkedKeysList: [],
  searchValue: null,
  mapLocationList: [],
  brandHubLocResponse: null
};

export const RP_LOCATION_INIT_OBJ: rpApiTypes.IRPProgramLocationData = {
  franchisors: [],
  accounts: [],
  accountLists: [],
  selectedLocationCount: 0,
  isAllLocation: false
};

export const RP_MODIFIED_STATUS = {
  details: false,
  actions: false,
  locations: false,
  emailTemplate: false,
  payment: false
};

export const CREATE_PROGRAM_INITIAL_DATA_OBJ: rpReducerTypes.ICreateProgramDataObj = {
  programDetails: CREATE_PROGRAM_INIT_DETAILS_DATA,
  programAction: [],
  locationsObj: RP_TREE_INIT_OBJ,
  programHuLocations: RP_LOCATION_INIT_OBJ,
  emailObj: CREATE_PROGRAM_EMAIL_INITIAL_OBJ,
  selectedCard: null,
  modifiedStatus: RP_MODIFIED_STATUS
};

export const ON_GOING_ACHIEVERS_LIST_INITIAL_DATA_OBJ: rpApiTypes.IRPOngoingAchieversList = {
  recent: [],
  lastWeekOrMonth: [],
  history: []
};

export const CP_DETAILS_PAGE_FIELDS = {
  NAME: 'name',
  REWARDS_TYPE: 'rewardsType',
  RECOMMENDED_TYPE: 'recommendedType',
  DURATION: 'duration',
  PROGRAM_DURATION: 'programDuration',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  USER_ID: 'userId',
  PROGRAM_ID: 'programId',
  ACCOUNT_ID: 'accountId',
  FRANCHISOR_ID: 'franchisorId',
  PROGRAM_BUDGET: 'programBudget',
  END_AFTER: 'endafter',
  OCCURRENCES: 'occurrences',
  STAGE: 'stage',
  EMAIL_INVITATION_MESSAGE: 'emailInvitationMessage',
  EMAIL_NOTIFICATION_STATUS: 'emailNotificationStatus',
  ADMIN_EMAIL: 'adminEmail'
};

export const RP_PRGRM_ACTION_ADVCATES_TO_DO = {
  imgsVideo: 'imgsVideos',
  brandApprCnt: 'brandApprCnt'
};

export const RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE = {
  APPROVAL: 'approval',
  NOT_APPROVAL: 'notApproval'
};

export const RP_PRGRM_ACTION_ITEM_OBJECT = {
  action: 1,
  program: '',
  operator: '>=',
  value: '',
  condition: 'or',
  employeesTodo: RP_PRGRM_ACTION_ADVCATES_TO_DO.imgsVideo,
  programMeasure: ''
};

export const RP_PRGRM_ACTION_INITIAL_OBJECT = {
  actions: [
    {
      rewards: '',
      nonCashRewards: '',
      rewardsType: 0,
      actionItem: RP_PRGRM_ACTION_ITEM_OBJECT
    }
  ]
};

export const RP_ADD_PROGRAM_INIT_OBJ: rpReducerTypes.IAddProgramObj = {
  message: null,
  error: null
};
