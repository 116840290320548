import moment from 'moment-timezone';

import { IHubTypeResponse, IIdGroup, ISurveyAnswerFormated, ISurveyAnswerOnlyResponse } from 'revv/types';

interface IDDLOptions {
  value: string;
  label: string;
  id: number;
}

export const computeMyScore = (surveyQuestionStatistics: ISurveyAnswerOnlyResponse | null) => {
  const overallStats =
    (surveyQuestionStatistics && surveyQuestionStatistics.currentSurveyAns && surveyQuestionStatistics.currentSurveyAns.filter((stats) => stats.id.toString().match(/^0-\d+$/))) || [];

  const excellentCountSum = overallStats.map((stats) => stats.totalExcellentCount || 0).reduce((a, b) => a + b, 0);
  // Sum all survey counts
  const totalCountSum = overallStats.map((stats) => stats.totalCount || 0).reduce((a, b) => a + b, 0);

  // If there is data, compute the percentage.
  if (totalCountSum > 0) {
    return Math.round(Number(((excellentCountSum / totalCountSum) * 100).toFixed(1)));
  } else {
    // Otherwise, -1 is a special value indicating N/A
    return -1;
  }
};

export const computeHubScoreOptions = (ddlOptions: IDDLOptions[], selectedHubGroup: IIdGroup, selectedFilterMode: string[], hubTypeOptions: IHubTypeResponse[]) => {
  const options = Object.keys(selectedHubGroup).reduce((acc: IDDLOptions[], curr: string) => {
    const optionObj = hubTypeOptions.find((it) => it.id === Number(curr));
    if (optionObj) {
      acc.push({
        label: optionObj.name,
        value: optionObj.name,
        id: optionObj.id
      });
    }
    return acc;
  }, ddlOptions);
  const ownerGroup = hubTypeOptions.find((it) => it.id === 5);
  if (selectedFilterMode.includes('franchise') && selectedFilterMode?.length === 1 && options.findIndex((it) => it.id === 5) === -1 && ownerGroup) {
    const typesWithoutOG = hubTypeOptions.filter((it) => it.id !== 5);
    const franchiseOptions = [...ddlOptions];
    typesWithoutOG.forEach((it) => {
      if (franchiseOptions.findIndex((co) => co.id === it.id) === -1) {
        franchiseOptions.push({
          label: it.name,
          value: it.name,
          id: it.id
        });
      }
    });
    franchiseOptions.push({
      label: ownerGroup.name,
      value: ownerGroup.name,
      id: ownerGroup.id
    });
    return franchiseOptions;
  }
  if (selectedFilterMode.includes('corporate') && selectedFilterMode?.length === 1) {
    const typesWithoutOG = hubTypeOptions.filter((it) => it.id !== 5);
    const corporateOptions = [...ddlOptions];
    typesWithoutOG.forEach((it) => {
      if (corporateOptions.findIndex((co) => co.id === it.id) === -1) {
        corporateOptions.push({
          label: it.name,
          value: it.name,
          id: it.id
        });
      }
    });
    return corporateOptions;
  }
  return options;
};

export const getGraphDates = (startDate: string, stopDate: string, selectedYear: string) => {
  const dates = [];
  let currentDate = moment(startDate, 'MMM D YYYY').year(moment(startDate, 'MMM D YYYY').year()).hours(23).minutes(59).seconds(0);
  const endDate = moment(stopDate, 'MMM D YYYY').year(moment(stopDate, 'MMM D YYYY').year()).hours(23).minutes(59).seconds(0);
  while (endDate.isSameOrAfter(currentDate)) {
    // dateArray.push({ x: new Date(currentDate.format()).getTime(), y: 0, z: 0 });
    dates.push(new Date(currentDate.format()).getTime());
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dates;
};

interface IMyScoreArray {
  x: number;
  y: number;
  z: number;
  customdate?: number;
}

export const surveyTableGraphData = (
  activeGraphIndex: number,
  surveyCurrent: ISurveyAnswerFormated[],
  hubSurveyCurrent: ISurveyAnswerFormated[],
  prevQuaterData: ISurveyAnswerFormated[],
  suffixText: string,
  selectedScore: IDDLOptions,
  datesArr: number[]
) => {
  const currentTimeValue: IMyScoreArray[] = [];
  const previousTimeValue: IMyScoreArray[] = [];
  const myScoreValue: IMyScoreArray[] = [];
  const companyScoreValue: IMyScoreArray[] = [];

  datesArr.forEach((it) => {
    currentTimeValue.push({ x: it, y: 0, z: 0 });
    previousTimeValue.push({ x: it, y: 0, z: 0 });
    myScoreValue.push({ x: it, y: 0, z: 0 });
    companyScoreValue.push({ x: it, y: 0, z: 0 });
  });

  const myScoreSeries = [];
  const hubScoreSeries = [];
  let myScore: number[][] = [];

  if (activeGraphIndex >= 0) {
    // Current Time Period
    if (surveyCurrent && surveyCurrent.length > 0) {
      myScore = [...(surveyCurrent[activeGraphIndex]?.scoreSeries || [])].sort((a, b) => a[0] - b[0]);
      if (myScore.length === currentTimeValue.length) {
        myScore.forEach((_1, ind) => {
          currentTimeValue[ind].x = myScore[ind][0];
        });
      }

      [...Array(currentTimeValue.length)].forEach((_1, k) => {
        [...Array(myScore.length)].forEach((_1, m) => {
          if (currentTimeValue[k] && myScore[m] && currentTimeValue[k].x === myScore[m][0]) {
            currentTimeValue[k].y = myScore[m][1];
            currentTimeValue[k].x = myScore[m][0];
            currentTimeValue[k].customdate = new Date(moment(myScore[m][0]).local().valueOf()).getTime();
          }
        });
      });
    }
    myScoreSeries.push({
      name: 'Current Time Period',
      color: '#29AB87',
      data: currentTimeValue
    });

    // Previous Period
    let myPrevPeriodNewArrUpdated;
    if (prevQuaterData && prevQuaterData.length > 0) {
      const previousQuestionObject = prevQuaterData[activeGraphIndex] ? prevQuaterData[activeGraphIndex] : null;
      let prevObjValidate = previousQuestionObject ? [...(previousQuestionObject?.scoreSeries || [])].sort((a, b) => a[0] - b[0]) : [];
      if (prevObjValidate.length > previousTimeValue.length) {
        prevObjValidate = prevObjValidate.splice(prevObjValidate.length - previousTimeValue.length - 1, prevObjValidate.length - 1);
      }
      [...Array(prevObjValidate.length)].forEach((_1, p) => {
        if (prevObjValidate[p] && previousTimeValue[p] && prevObjValidate[p][0]) {
          previousTimeValue[p].y = prevObjValidate[p][1];
          previousTimeValue[p].customdate = new Date(moment(prevObjValidate[p][0]).local().valueOf()).getTime();
        }
      });

      myPrevPeriodNewArrUpdated = previousTimeValue.filter((i) => {
        return i.x !== 0 && i.customdate !== 0;
      });
    }
    myScoreSeries.push({
      name: `Previous Period`,
      color: '#0d0d0d',
      data: myPrevPeriodNewArrUpdated
    });

    if (hubSurveyCurrent?.length > 0) {
      const hubObj = hubSurveyCurrent.find((it) => it.id === surveyCurrent[activeGraphIndex]?.id);

      if (myScore.length === myScoreValue.length) {
        myScore.forEach((_1, ind) => {
          myScoreValue[ind].x = myScore[ind][0];
        });
      }

      [...Array(myScore.length)].forEach((_1, d) => {
        if (myScore[d]) {
          const retData = myScoreValue.find((element) => {
            return myScore && element.x === myScore[d][0];
          });
          if (retData && retData.x) {
            myScoreValue.forEach((element) => {
              if (element.x === retData.x) {
                element.y = myScore[d][1];
              }
            });
          }
        }
      });

      const companyValues = hubObj ? [...(hubObj?.scoreSeries || [])].sort((a, b) => a[0] - b[0]) : [];
      if (companyValues.length === companyScoreValue.length) {
        companyValues.forEach((_1, ind) => {
          companyScoreValue[ind].x = companyValues[ind][0];
        });
      }
      [...Array(companyValues.length)].forEach((_1, w) => {
        if (companyValues[w]) {
          const retData = companyScoreValue.find((element) => {
            return companyValues && element.x === companyValues[w][0];
          });
          if (retData && retData.x) {
            companyScoreValue.forEach((element) => {
              if (element.x === retData.x) {
                element.y = companyValues[w][1];
              }
            });
          }
        }
      });
    }
    hubScoreSeries.push({
      name: `My ${suffixText}`,
      data: myScoreValue
    });
    hubScoreSeries.push({
      name: selectedScore.label,
      data: companyScoreValue
    });
  }

  return { myScoreSeries, hubScoreSeries };
};

export const surveyMultipleQuestionTableGraphData = (
  surveyCurrent: number[][],
  prevQuaterData: number[][],
  hubSurveyCurrent: number[][],
  suffixText: string,
  selectedScore: IDDLOptions,
  datesArr: number[]
) => {
  const currentTimeValue: IMyScoreArray[] = [];
  const previousTimeValue: IMyScoreArray[] = [];
  const myScoreValue: IMyScoreArray[] = [];
  const companyScoreValue: IMyScoreArray[] = [];

  datesArr.forEach((it) => {
    currentTimeValue.push({ x: it, y: 0, z: 0 });
    previousTimeValue.push({ x: it, y: 0, z: 0 });
    myScoreValue.push({ x: it, y: 0, z: 0 });
    companyScoreValue.push({ x: it, y: 0, z: 0 });
  });

  const myScoreSeries = [];
  const hubScoreSeries = [];
  let myScore: number[][] = [];

  // Current Time Period
  if (surveyCurrent?.length > 0) {
    myScore = [...surveyCurrent].sort((a, b) => a[0] - b[0]);
    if (myScore.length === currentTimeValue.length) {
      myScore.forEach((_1, ind) => {
        currentTimeValue[ind].x = myScore[ind][0];
      });
    }

    [...Array(currentTimeValue.length)].forEach((_1, k) => {
      [...Array(myScore.length)].forEach((_1, m) => {
        if (currentTimeValue[k] && myScore[m] && currentTimeValue[k].x === myScore[m][0]) {
          currentTimeValue[k].y = myScore[m][1];
          currentTimeValue[k].x = myScore[m][0];
          currentTimeValue[k].customdate = new Date(moment(myScore[m][0]).local().valueOf()).getTime();
        }
      });
    });
  }
  myScoreSeries.push({
    name: 'Current Time Period',
    color: '#29AB87',
    data: currentTimeValue
  });

  // Previous Time Period
  let myPrevPeriodNewArrUpdated: IMyScoreArray[] = [];
  if (prevQuaterData?.length > 0) {
    const prevObjValidate = [...prevQuaterData].sort((a, b) => a[0] - b[0]);
    [...Array(prevObjValidate.length)].forEach((_1, p) => {
      if (prevObjValidate[p] && previousTimeValue[p]) {
        previousTimeValue[p].y = prevObjValidate[p][1];
        previousTimeValue[p].customdate = new Date(moment(prevObjValidate[p][0]).local().valueOf()).getTime();
      }
    });

    myPrevPeriodNewArrUpdated = previousTimeValue.filter((i) => {
      return i.x !== 0 && i.customdate !== 0;
    });
  }
  myScoreSeries.push({
    name: `Previous Period`,
    color: '#0d0d0d',
    data: myPrevPeriodNewArrUpdated
  });

  hubScoreSeries.push({
    name: `My ${suffixText}`,
    data: myScore
  });
  hubScoreSeries.push({
    name: selectedScore?.label,
    data: [...hubSurveyCurrent].sort((a, b) => a[0] - b[0])
  });
  return { myScoreSeries, hubScoreSeries };
};
