import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { accountApiTypes, commonModalPopup, IStore } from 'types';
import { accountUpdateProfileRequest, rssFeedsListsRequest, franchisorGetOrUpdateProfileRequest } from 'actions';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';

export const RssFeedURLModalPopup = ({ isModalShow, handleModalClose, containerClassName }: commonModalPopup.IULAddNewUserModalPopup) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership } = useAccountSwitcherData();
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);
  const isRssFeedUpdating = useSelector((state: IStore) => (userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.isAccountUpdating : state.franchisors.isFranchisorUpdating));
  const franchisorDetails = useSelector((state: IStore) => state.franchisors.franchisorDetails);
  const configRssFeedUrls = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.ACCOUNT ? state.accounts.accountDetails?.account.news_rss_feed_urls || [] : state.franchisors.franchisorDetails?.news_rss_feed_urls || []
  );

  const [addConfigRssFeedUrls, setAddConfigRssFeedUrls] = useState<accountApiTypes.INewsRssFeedUrls>({ name: '', url: '' });

  useEffect(() => {
    if (id && userOwnership && isModalShow && !isRssFeedUpdating) {
      dispatch(rssFeedsListsRequest({ userOwnership, id }));
    }
  }, [isRssFeedUpdating]); // eslint-disable-line

  const handleDelete = (deleteIdx: number) => {
    if (id && userOwnership) {
      const filteredRssFeedUrls = configRssFeedUrls.filter((_1, index) => index !== deleteIdx);
      if (accountDetails?.id) {
        dispatch(accountUpdateProfileRequest({ profileData: { ...accountDetails, news_rss_feed_urls: filteredRssFeedUrls }, id }));
      } else if (franchisorDetails?.id) {
        dispatch(franchisorGetOrUpdateProfileRequest({ userOwnership, id, editedContent: { ...franchisorDetails, news_rss_feed_urls: filteredRssFeedUrls } }));
      }
    }
  };

  const handleAddConfigRssFeedUrls = (addConfigRssFeedUrls: accountApiTypes.INewsRssFeedUrls) => {
    if (id && userOwnership) {
      const updateNewsRssFeedUrl = [...configRssFeedUrls, addConfigRssFeedUrls];
      dispatch(
        rssFeedsListsRequest({
          id,
          userOwnership,
          url: addConfigRssFeedUrls.url,
          updatedData:
            userOwnership === USER_OWNERSHIP.ACCOUNT && accountDetails
              ? { ...accountDetails, news_rss_feed_urls: updateNewsRssFeedUrl }
              : franchisorDetails
              ? { ...franchisorDetails, news_rss_feed_urls: updateNewsRssFeedUrl }
              : null
        })
      );
      setAddConfigRssFeedUrls({ name: '', url: '' });
    }
  };

  return (
    <Modal
      className={`prime-modal rssfeedurlmodalpopup-wrp${containerClassName ? ` ${containerClassName}` : ``}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Body>
        <div className="userlogin-addnewuser-wrp rssfeedurlmodalpopup-wrp">
          <div className="location-peer-wrp rssfeedlist-wrp d-flex align-item-center justify-content-between">
            <div className="location-img g-15">
              <ImageValidation isImgValid defaultImg="rssfeed" customName="rss-feed" />
              <span>RSS Feed URLs</span>
            </div>
            <ImageValidation isImgValid defaultImg="rss-feed-close" customName="close" customClassname="close-ico pointer" onClick={handleModalClose} />
          </div>

          <div className="rssfeed-form-section-wrp">
            {configRssFeedUrls?.map((rssFeedItem, index: number) => {
              return (
                <div key={`rss-feed-item-${index}`} className="rssfeed-form-innter-sec">
                  <div className="left-section">
                    <div className="addnewuser-modal-content">
                      <div className={`form-group`}>
                        <input type="text" name={'name'} defaultValue={rssFeedItem.name} className={`form-control events-none pointer-events-none`} />
                      </div>
                    </div>
                  </div>
                  <div className="center-section">
                    <div className="addnewuser-modal-content">
                      <div className={`form-group`}>
                        <input type="text" name={'url'} defaultValue={rssFeedItem.url} className={`form-control events-none pointer-events-none`} />
                      </div>
                    </div>
                  </div>
                  <div className="last-section">
                    <div className="red-delete-icon" onClick={() => handleDelete(index)}>
                      <ImageValidation isImgValid defaultImg="delete-red" customName="delete-red" />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="rssfeed-form-innter-sec">
              <div className="left-section">
                <div className="addnewuser-modal-content">
                  <div className={`form-group`}>
                    <input
                      type="text"
                      name={'name'}
                      value={addConfigRssFeedUrls.name}
                      className={`form-control ${addConfigRssFeedUrls.name ? 'used' : ''}`}
                      onChange={(event) => {
                        setAddConfigRssFeedUrls((prev) => ({ ...prev, name: event.target.value }));
                      }}
                    />
                    <span className="fltlabels">Name (Optional)</span>
                  </div>
                </div>
              </div>
              <div className="center-section">
                <div className="addnewuser-modal-content">
                  <div className={`form-group`}>
                    <input
                      type="text"
                      name={'url'}
                      value={addConfigRssFeedUrls.url}
                      className={`form-control ${addConfigRssFeedUrls.url ? 'used' : ''}`}
                      onChange={(event) => {
                        setAddConfigRssFeedUrls((prev) => ({ ...prev, url: event.target.value }));
                      }}
                    />
                    <span className="fltlabels">www.RSSfeedurl.xml</span>
                  </div>
                </div>
              </div>
              <div className={`last-section ${addConfigRssFeedUrls.url ? '' : 'events-none pointer-events-none'}`}>
                <ImageValidation isImgValid defaultImg="add-blue-ico" customName="rss-add-images" onClick={() => handleAddConfigRssFeedUrls(addConfigRssFeedUrls)} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
