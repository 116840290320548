import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { useAccountSwitcherData, useActivePlatform, useNavBarData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { getInboxEngagementTypeData, weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import {
  INBOX_FILTER_INITIAL_VALUE,
  USER_OWNERSHIP,
  FeedValue,
  COMMON_SOCIAL_PROVIDER_TYPE,
  INBOX_FILTER_POSTS,
  REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  CALENDAR_YR_MN_DATE_FORMAT,
  InboxValidQueryParams,
  RIPPLE_COLOR,
  BUTTON_PROPS,
  InboxPostType,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  InboxEngagementType,
  MAIN_NAV_BAR_NAME
} from 'utils/constants';
import { CommonFilter } from 'components';
import { PLATFORM_FILTER, dateFormatter, handleFilterChange } from 'analytics/utils';
import { inboxPieReloadRequest, setInboxStatscall } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';

export const InboxFilter = () => {
  const navigate = useNavigate();
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();
  const { activeMainPages } = useNavBarData();
  const selectedPlatforms = activePlatforms.filter((it) => it !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && it !== COMMON_SOCIAL_PROVIDER_TYPE.YELP);

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isStatsCall = useSelector((state: IStore) => state.inbox.isStatsCall);

  const inboxDaterange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);
  const initialFilterValue = {
    ...INBOX_FILTER_INITIAL_VALUE,
    locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ
  };
  const { queryParams, filter } = useParamsDeconstructor(initialFilterValue);

  const [isDateRangeEnabled, setIsDateRangeEnabled] = useState(Boolean(queryParams.start_date && queryParams.end_date));

  const engagementTypeList = getInboxEngagementTypeData(queryParams?.platform || 'all');

  // For current route validation with default query params
  const isCommunityMenuActive = useMemo(() => {
    return activeMainPages.map((it) => it.name).includes(MAIN_NAV_BAR_NAME.COMMUNINTY);
  }, [activeMainPages]);

  useParamsValidator(
    { ...CommonValidQueryParams, ...InboxValidQueryParams, ENGAGEMENT: 'engagement' },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      posts: INBOX_FILTER_POSTS.map((it) => it.value),
      engagement: engagementTypeList.map((it) => it.value),
      platform: selectedPlatforms
    }
  );

  const inboxFilter = {
    ...filter,
    posts: queryParams?.posts === InboxPostType.SAVED ? InboxPostType.SAVED : queryParams.start_date && queryParams.end_date ? InboxPostType.ALL : InboxPostType.UNREAD,
    engagement:
      queryParams.engagement ||
      (!queryParams.platform ? InboxEngagementType.ALL : queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? InboxEngagementType.ALL : InboxEngagementType.POST_COMMENTS)
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (id && data.reload) {
      dispatch(inboxPieReloadRequest({ id, force: true }));
    } else {
      const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
      if (isClear) {
        setIsDateRangeEnabled(false);
      }
      /* if (( data.engagement || data.platform) && isStatsCall) {
        dispatch(setInboxStatscall(false));
      } */
      if (!isStatsCall && (isClear || data.platform || data.engagement || data.dateRange || data.locationId || data.locationListId || data.ancestorFranchisorId)) {
        dispatch(setInboxStatscall(true));
      }
      if (data.platform) {
        if ([COMMON_SOCIAL_PROVIDER_TYPE.ALL, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK].includes(data.platform)) delete queryParamsClone.engagement;
        else queryParamsClone.engagement = InboxEngagementType.POST_COMMENTS;
      }
      if (data.posts === InboxPostType.UNREAD) {
        setIsDateRangeEnabled(false);
        delete queryParamsClone.start_date;
        delete queryParamsClone.end_date;
        delete queryParamsClone?.posts;
      } else if (data.posts === InboxPostType.ALL) {
        setIsDateRangeEnabled(true);
        queryParamsClone.start_date = dateFormatter(inboxDaterange.fromDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
        queryParamsClone.end_date = dateFormatter(inboxDaterange.toDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
        delete queryParamsClone?.posts;
      } else if (data.posts === InboxPostType.SAVED) {
        setIsDateRangeEnabled(false);
        delete queryParamsClone.start_date;
        delete queryParamsClone.end_date;
        queryParamsClone.posts = data.posts;
      }
      if (data.engagement) {
        if (data.engagement === InboxEngagementType.ALL) delete queryParamsClone.engagement;
        else if (data.engagement === InboxEngagementType.POSTS) {
          setIsDateRangeEnabled(true);
          queryParamsClone.engagement = data.engagement;
          queryParamsClone.start_date = queryParams.start_date || dateFormatter(inboxDaterange.fromDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
          queryParamsClone.end_date = queryParams.end_date || dateFormatter(inboxDaterange.toDate.selected, CALENDAR_YR_MN_DATE_FORMAT) || '';
        } else queryParamsClone.engagement = data.engagement;
      }
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    }
  };

  const platformFilter = PLATFORM_FILTER.filter((it) => selectedPlatforms.includes(it.value) || (selectedPlatforms.length > 1 ? it.value === 'all' : false));

  useEffect(() => {
    if (selectedPlatforms.length === 1 && !queryParams?.platform && isCommunityMenuActive) {
      const params = { platform: selectedPlatforms[0] || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, selectedPlatforms, queryParams?.platform, isCommunityMenuActive]); // eslint-disable-line

  const isClearFilterDisabled = useMemo(() => {
    return selectedPlatforms.length === 1 ? (Object.keys(queryParams)?.length === 1 && queryParams?.platform ? true : false) : !Object.keys(queryParams)?.length;
  }, [selectedPlatforms, queryParams]);

  return (
    <CommonFilter
      filter={inboxFilter}
      handleChange={handleChange}
      isDateRangeFilterEnable={isDateRangeEnabled}
      analyticsPlatformFilter={platformFilter}
      secondaryCheckBoxFilter={[
        {
          title: 'Engagement Status',
          filter: INBOX_FILTER_POSTS,
          value: 'posts'
        }
      ]}
      checkBoxFilter={[
        {
          title: 'Engagement Type',
          filter: engagementTypeList,
          value: 'engagement'
        }
      ]}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled
        },
        ...(userOwnership === USER_OWNERSHIP.ACCOUNT
          ? [
              {
                rippleColor: RIPPLE_COLOR.whiteBlue,
                value: 'reload',
                label: 'Reload',
                customImage: 'pie-reload',
                buttonVariant: `ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv pie-reload--btn ${isAdmin ? '' : 'd-none'}`,
                isDisable: false,
                parentVariant: 'r-mt2',
                isBorder: false
              }
            ]
          : [])
      ]}
      sectionVariant="inbox-filter"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
};
