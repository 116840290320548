import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import { getConnectionsLocationListResponse, getConnectionsLocationListFailure, exceptionHandlerRequest, logoutRequest } from 'actions';
import { commonApiTypes } from 'types';
import { CONNECTIONS_LOC_LIST_FAILED, CONNECTIONS_ERROR } from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendConnectionsLocationListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      account_list_id: payload.accountListId
    };
    let path;
    let response;
    path = API.getConnectionsList.apiPath.replace(':id', payload.franchisorId);
    response = yield apiCall({ headers, params, apiPath: path, action: API.getConnectionsList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getConnectionsLocationListResponse(response.data));
    } else {
      yield put(getConnectionsLocationListFailure(CONNECTIONS_LOC_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONNECTIONS_ERROR));
  }
}

export function* takeConnectionRequest() {
  yield takeLatest(types.GET_CONNECTIONS_LOCATION_LIST_REQUEST, sendConnectionsLocationListRequest);
}
