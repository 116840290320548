import { useMemo } from 'react';

import { commonWidgetTypes } from 'types';
import { getPercentage } from 'utils/helpers';

export const CommonTotalStatsCount = ({ isStatsFetching, totalCount, statsData }: commonWidgetTypes.ICommonTotalStatsCountProps) => {
  const splittedHeights = useMemo(() => {
    if (!isStatsFetching && statsData.length) {
      return statsData.reduce((acc: commonWidgetTypes.ICommonStatsSplittedHeight[], curr) => {
        if (curr.value) {
          acc = [
            ...acc,
            {
              label: curr.label,
              height: `${getPercentage(totalCount, curr.value) || 0}%`,
              colorCode: curr.colorCode
            }
          ];
        }
        return acc;
      }, []);
    }
    return [];
  }, [statsData, totalCount]); // eslint-disable-line

  return isStatsFetching ? null : (
    <div className="chart-details">
      <div className="progress-vertical">
        {splittedHeights.map((it, index) => {
          return <div key={`total-stats-${it.label}-${index}`} className="bar" style={{ width: '100%', background: it.colorCode, height: it.height }} />;
        })}
      </div>
    </div>
  );
};
