export const DOWNLOAD_SURVEYS_FILTERED_REQUEST = 'download/DOWNLOAD_SURVEYS_FILTERED_REQUEST';
export const DOWNLOAD_SURVEYS_FILTERED_RESPONSE = 'download/DOWNLOAD_SURVEYS_FILTERED_RESPONSE';
export const DOWNLOAD_SURVEYS_FILTERED_REQUEST_FAILURE = 'download/DOWNLOAD_SURVEYS_FILTERED_REQUEST_FAILURE';

export const DOWNLOAD_REQUEST = 'download/DOWNLOAD_REQUEST';
export const DOWNLOAD_RESPONSE = 'download/DOWNLOAD_RESPONSE';
export const DOWNLOAD_REQUEST_FAILURE = 'download/DOWNLOAD_REQUEST_FAILURE';

export const POLL_EXPORT_REQUEST = 'download/POLL_EXPORT_REQUEST';
export const POLL_EXPORT_REQUEST_FAILURE = 'download/POLL_EXPORT_REQUEST_FAILURE';

export const DASHBOARD_REPORT_DOWNLOAD_REQUEST = 'download/DASHBOARD_REPORT_DOWNLOAD_REQUEST';
export const DASHBOARD_REPORT_DOWNLOAD_RESPONSE = 'download/DASHBOARD_REPORT_DOWNLOAD_RESPONSE';
export const DASHBOARD_REPORT_DOWNLOAD_REQUEST_FAILURE = 'download/DASHBOARD_REPORT_DOWNLOAD_REQUEST_FAILURE';
