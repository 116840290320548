import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { hubPlatformHandler, initialPlatformHandler, locationPlatformHandler } from 'analytics/utils';
import { IStore } from 'types';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from '../use-account-switcher-data';

export const useAvailablePlatforms = (initialFilterValue: any, userOwnership: string, yelpBusinessId?: string | null | undefined, isSocialProfile = true, isProfileAccess?: boolean) => {
  const isGoogleEnabled = useSelector((state: IStore) =>
    isSocialProfile ? state.franchisors.franchisorDetails?.social_profile?.some((it) => it.includes('google')) : state.franchisors.franchisorDetails?.gmb_feature
  );
  const currentFranDetails = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access);
  const currentAccountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.access);
  const locationSocialMedia = useSelector((state: IStore) => state.accounts.accountDetails?.social_media);

  const { isValidId } = useAccountSwitcherData();

  const availablePlatform = useMemo(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && currentFranDetails && isValidId) {
      const platform = hubPlatformHandler(initialFilterValue, isGoogleEnabled, currentFranDetails);
      return initialPlatformHandler(platform);
    } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && isValidId) {
      const platform = locationPlatformHandler(initialFilterValue, locationSocialMedia || [], currentAccountDetails, isProfileAccess, yelpBusinessId || null);
      return initialPlatformHandler(platform);
    } else {
      return initialPlatformHandler(initialFilterValue);
    }
  }, [isValidId, initialFilterValue, isGoogleEnabled, yelpBusinessId, currentFranDetails, currentAccountDetails, userOwnership, locationSocialMedia, isProfileAccess]);

  return availablePlatform;
};
