import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import React, { Fragment } from 'react';

import { IDashboardQuestListResponse, ITableHeader } from 'revv/types';
import { Skeleton } from '../skeleton';
export interface ITableData {
  header: ITableHeader[];
  data: IDashboardQuestListResponse[];
  renderTableColumn: (header: ITableHeader[], data: IDashboardQuestListResponse, index: number) => React.ReactNode;
  isDataFetching: boolean;
  headerRowClass?: string;
}

export const NonPspSurveyTable = ({ header, data, renderTableColumn, isDataFetching, headerRowClass }: ITableData) => {
  const SurveyTablePlaceholder = () => {
    return (
      <>
        {[...Array(2)].map((_1, index) => (
          <Tr key={index} className="loading-row">
            <Td colSpan={5}>
              <Skeleton classVariant="survey-tbl__placehldr questions-list__palcehldr" gridVariant={12} />
            </Td>
          </Tr>
        ))}
      </>
    );
  };

  return (
    <Table className="non-psp__questions-list">
      <Thead>
        {/* className="multiple-questions__row   */}
        <Tr className={headerRowClass}>
          {header.map((it, index1) => (
            <Th key={index1}>{it.value}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {isDataFetching ? (
          <SurveyTablePlaceholder />
        ) : (
          data.map((it, index) => {
            return <Fragment key={index}>{renderTableColumn(header, it, index)}</Fragment>;
          })
        )}
      </Tbody>
    </Table>
  );
};
