import { useCallback, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ImageValidation } from 'widgets/Media';
import { Loading, VirtualTable } from 'components';
import { IStore, ITableHeader, rpApiTypes } from 'types';
import { RewardType, TOOLTIP_PLACEMENT, RP_PRGRM_ACTION_PRGRM, RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE } from 'utils/constants';
import { getInitials, handleLabelDisplay, dateFormatterMmDDYYToLocal } from 'utils/helpers';
import { CustomTooltip } from 'widgets/Tooltip';
import { useParamsDeconstructor } from 'utils/hooks';

export const AchieversDetailView = () => {
  const navigate = useNavigate();
  const { queryParams } = useParamsDeconstructor();

  const isAchieversFetching = useSelector((state: IStore) => state.rewardPrograms.isAchieversFetching);
  const achieversList = useSelector((state: IStore) => state.rewardPrograms.achieversList);
  const programDetailObj = useSelector((state: IStore) => state.rewardPrograms.programDetailObj);
  const promotionVaultList = useSelector((state: IStore) => state.rewardPrograms.promotionVaultList);
  const programAction = useSelector((state: IStore) => state.rewardPrograms.createProgramDataObj.programAction);

  const renderColumn = useCallback(
    (data: rpApiTypes.IRPAchieversDetails, header: ITableHeader<rpApiTypes.IRPAchieversDetails, {}>, _rowIndex: number, _colState?: {}) => {
      switch (header.identifier) {
        case 'actions':
          const achieversProgramAction = data.programAction.program.split(' ');
          return (
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customTooltipText=""
              overlay={
                <div>
                  {achieversProgramAction[0] === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED && (
                    <div className="description-text">
                      I want my employee advocates to submit at least
                      <span className="blue-text">
                        {' '}
                        {parseInt(achieversProgramAction[2])} {parseInt(achieversProgramAction[2]) === 1 ? ' image/video' : ' images/videos'}
                      </span>
                      . The reward will be earned by the number of {parseInt(achieversProgramAction[2]) === 1 ? ' image/video ' : ' images/videos '} submitted{' '}
                      {data.programAction.programMeasure === RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.APPROVAL ? '(Requires "like" rating)' : '(Does not require "like" rating)'}.
                    </div>
                  )}
                  {achieversProgramAction[0] === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS && (
                    <div className="description-text">
                      I want my employee advocates to do at least
                      <span className="blue-text"> {parseInt(achieversProgramAction[2])} engagements</span> (like/comment/share) of my brand content. The reward will be earned by the amount of
                      engagements generated against the {parseInt(achieversProgramAction[2]) === 1 ? ' image/video' : ' images/videos'}.
                    </div>
                  )}
                  {achieversProgramAction[0] === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS && (
                    <div className="description-text">
                      I want my employee advocates to publish at least
                      <span className="blue-text"> {parseInt(achieversProgramAction[2])} of my branded posts.</span> The reward will be earned by the number of posts they share.
                    </div>
                  )}
                </div>
              }
              customClassname={'custom-tooltip-long-text otmWrap earpa'}
              customInput={() => (
                <span className="rp-ac-item">
                  {
                    programAction.find(
                      (action) =>
                        action.actionItem.program === achieversProgramAction[0] &&
                        action.actionItem.operator === achieversProgramAction[1] &&
                        action.actionItem.value === parseInt(achieversProgramAction[2])
                    )?.actionItem?.action
                  }
                </span>
              )}
            />
          );
        case 'achievers':
          return (
            <div className="title-name-secion">
              <div className="rel-icons rel-initials">
                <span className="rInitials">{data && getInitials(data.userName ? data.userName : data.users)}</span>
              </div>
              <span
                className="padding-right-align cur-pointer"
                onClick={() => {
                  navigate({ search: `?${new URLSearchParams({ ...queryParams, user_id: data.userId.toString() }).toString()}` });
                }}
              >
                {data.userName ? data.userName : data.users}
              </span>
            </div>
          );
        case 'last-updated':
          return (
            <div className="last-update-txt">
              <div className="info-icon">
                <ImageValidation isImgValid defaultImg="startBucks" customName="star" />
              </div>
              <span>
                {programDetailObj?.programDetails?.rewardsType === RewardType.CASH_PRIZE && data.rewards
                  ? `$${handleLabelDisplay(promotionVaultList, data.rewards, 'value', 'giftCost')} Gift Card`
                  : data.nonCashRewards
                  ? data.nonCashRewards
                  : ''}
              </span>
            </div>
          );
        default:
          return <></>;
      }
    },
    [navigate, programAction, programDetailObj?.programDetails?.rewardsType, promotionVaultList, queryParams]
  );

  const renderHeader = useCallback(
    (headerObj: ITableHeader<rpApiTypes.IRPAchieversDetails, {}>, isResponsiveMode?: boolean, setShowDropdown?: React.Dispatch<React.SetStateAction<string>>, pinned?: boolean) => {
      return (
        <div
          onClick={() => {
            if (isResponsiveMode && !pinned && setShowDropdown) {
              setShowDropdown((prevState) => (headerObj.identifier === prevState ? '' : headerObj.identifier));
            }
          }}
        >
          {headerObj.label}
        </div>
      );
    },
    []
  );

  const tableHeader: ITableHeader<rpApiTypes.IRPAchieversDetails>[] = useMemo(() => {
    const achieversTableHeader = [
      { name: 'Actions', key: 'actions' },
      { name: 'Achievers', key: 'achievers' },
      {
        name: `Last Updated: ${
          achieversList && achieversList.length
            ? achieversList[achieversList.length - 1].updatedAt === null
              ? dateFormatterMmDDYYToLocal(achieversList[achieversList.length - 1].createdAt)
              : dateFormatterMmDDYYToLocal(achieversList[achieversList.length - 1].updatedAt)
            : '---'
        }`,
        key: 'last-updated'
      }
    ];
    return achieversTableHeader.map(
      (it): ITableHeader<rpApiTypes.IRPAchieversDetails> => ({
        identifier: it.key,
        label: it.name,
        labelString: it.name,
        colClassName: '',
        headerClassName: '',
        renderHeader,
        renderColumn
      })
    );
  }, [achieversList, renderColumn, renderHeader]);

  return (
    <div className="achivers-list-main-wrp-section resuseTable">
      <Card>
        <Card.Body>
          {isAchieversFetching ? (
            <Loading />
          ) : (
            <VirtualTable<rpApiTypes.IRPAchieversDetails> headers={tableHeader} data={[achieversList]} ipadBoundary={768} mobileColCount={2} isFetching={false} tableClassName="responsiveTable" />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
