import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import {
  getProfileImageryListResponse,
  getProfileImageryListFailure,
  updatePIAccountPictureResponse,
  exceptionHandlerRequest,
  getFilterProfileImageryListResponse,
  getFilterProfileImageryListFailure,
  updatePIAccountPictureFailure
} from 'actions';
import { commonApiTypes } from 'types';
import { PROFILE_IMAGERY_LIST_FAILED, PROFILE_IMAGERY_ERROR, FILTER_PROFILE_IMAGERY_LIST_FAILED, FILTER_PROFILE_IMAGERY_ERROR, PROFILE_IMAGERY_ACCOUNT_PICTURE_UPDATE_FAIL } from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendProfileImageryListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    const params = {
      franchisor_id: payload.id
    };
    response = yield apiCall({ headers, params, ...API.getProfileImageryList });
    if (response.status === 200) {
      yield put(getProfileImageryListResponse(response.data));
    } else {
      yield put(getProfileImageryListFailure(PROFILE_IMAGERY_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PROFILE_IMAGERY_ERROR));
  }
}

function* sendFilterProfileImageryListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    const params = {
      franchisor_id: payload.id,
      page: payload.page,
      ...(payload.search && { search: payload.search })
    };
    response = yield apiCall({ headers, params, ...API.getFilterProfileImageryList });
    if (response.status === 200) {
      yield put(getFilterProfileImageryListResponse(response.data));
    } else {
      yield put(getFilterProfileImageryListFailure(FILTER_PROFILE_IMAGERY_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FILTER_PROFILE_IMAGERY_ERROR));
  }
}

function* sendUpdatePIAccountPictureRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    const data = { ...payload };
    response = yield apiCall({ headers, data, ...API.updateProfileImageryPicture });
    if (response.status === 200) {
      yield put(updatePIAccountPictureResponse(payload.type));
    } else {
      yield put(updatePIAccountPictureFailure(PROFILE_IMAGERY_ACCOUNT_PICTURE_UPDATE_FAIL));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PROFILE_IMAGERY_ERROR));
  }
}

export function* takeProfileImageryRequest() {
  yield takeEvery(types.GET_PROFILE_IMAGERY_LIST_REQUEST, sendProfileImageryListRequest);
  yield takeEvery(types.GET_FILTER_PROFILE_IMAGERY_LIST_REQUEST, sendFilterProfileImageryListRequest);
  yield takeEvery(types.UPDATE_PI_ACCOUNT_PICTURE_REQUEST, sendUpdatePIAccountPictureRequest);
}
