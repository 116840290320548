import { ImageValidation } from 'widgets/Media';

interface ICalendarIconHandler {
  isBoosted: boolean;
  isAwaiting: boolean;
  isCampaign?: boolean;
}

export const CalendarIconHandler = ({ isBoosted, isAwaiting, isCampaign }: ICalendarIconHandler) => {
  return (
    <>
      {
        /* NOT APPROVED POST MSG */ isAwaiting && (
          <div className="img-reduce">
            <ImageValidation isImgValid defaultImg="warning" customName={'Approval awaiting'} />
          </div>
        )
      }
      {
        /* BOOSTED POST IMG */ isBoosted && (
          <div className="img-reduce">
            <ImageValidation isImgValid defaultImg="dollar-boost" customName={'Boost'} />
          </div>
        )
      }
      {
        /* CAMPAIGN POST IMG */ isCampaign && (
          <div className="img-reduce cmp-icon">
            <ImageValidation isImgValid defaultImg="campaign-active-green" customName={'Active Campaign'} />
          </div>
        )
      }
    </>
  );
};
