import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, settingsSocialProfileApiTypes, settingsSocialProfilContainerTypes } from 'types';
import { updateSocialMediaSetting, updateSocialMediaSettingSuccessOrFailure } from 'actions';
import { ALERT_BOX_TYPES, APP_TITLE, COMMON_SOCIAL_PROVIDER_TYPE, CONNECTION_TABS, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { handleLinkNavigation, disconnectSocialMedia } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { useSocialMedia } from './useSocialMedia';
import { Loading, SettingsSocialModalPopup, alertBoxCall } from 'components';
import { LinkedInViewPagesModalBody } from './LinkedInPageDetailPopup';

export const SocialProfileLinkedin = ({
  showPageModal,
  setShowPageModal,
  mediaSettingPayload,
  renderLabel,
  setStopSpinnerOnCancel,
  openPopup
}: settingsSocialProfilContainerTypes.ISettingsSocialProfileLinkedIn & Omit<settingsSocialProfilContainerTypes.ISPFbProps, 'getFbViewPages'>) => {
  const dispatch = useDispatch();

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const linkedInFilterData = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);

  const { linkedInPages } = useSelector((state: IStore) => state.socialProfile);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const [showModal, setShowModal] = useState(false);
  const [reconnectLinkedIn, setReconnectLinkedIn] = useState(false);

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  useEffect(() => {
    if (showPageModal && !linkedInPages.isFetching) {
      setStopSpinnerOnCancel(false);
    } else if (linkedInPages.error && !linkedInPages.isFetching) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, linkedInPages.error);
      setStopSpinnerOnCancel(true);
    }
  }, [linkedInPages.error, linkedInPages.isFetching]); // eslint-disable-line

  return (
    <>
      {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || accountDetails?.account_profiles[0]?.access.platform_linkedin_access ? (
        <div className="csp-item d-flex justify-content-between cspLinkedin">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="linkedin" customName="social logo" />
              </div>
              <span className="sp-txt">Linkedin</span>
            </div>
            <div className="sm-added-details" />
          </aside>
          {linkedInFilterData ? (
            <>
              {showModal ? (
                <div className="disconnect-notification-wrp">
                  <div className="left-item">
                    <div>
                      <ImageValidation isImgValid defaultImg="connection-social-profile" customName="more" />
                    </div>
                    <span className="are-u-sure-text">Are you sure?</span>
                  </div>
                  <div className="right-item">
                    <div className="csp-action">
                      <CustomRippleButton rippleClass="ripple-unset ac-secondary-box clr" custColor={RIPPLE_COLOR.whiteGrey}>
                        <button className="ac-btn ac-secondary-white size-xs cl" onClick={() => setShowModal(false)}>
                          Cancel
                        </button>
                      </CustomRippleButton>
                      <CustomRippleButton rippleClass={`ripple-unset ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                        <button
                          className={`ac-btn ac-danger`}
                          onClick={() => {
                            setShowModal(false);
                            disconnectSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, linkedInFilterData?.id, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, accountId, dispatch);
                          }}
                        >
                          {linkedInFilterData?.needs_reconnection ? 'Remove' : 'Disconnect Now'}
                        </button>
                      </CustomRippleButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="csp-action g-15">
                  <Button
                    className="ac-btn ac-link-btn primeblueaction"
                    variant="link"
                    onClick={() => {
                      handleLinkNavigation(`${linkedInFilterData.profile_url}#t=1`);
                    }}
                  >
                    View page
                  </Button>
                  {linkedInFilterData?.needs_reconnection ? (
                    <CustomRippleButton rippleClass={`ripple-unset ac-danger-box r-mr2`} custColor={RIPPLE_COLOR.danger}>
                      <button
                        className={`ac-btn ac-icon-btn ac-danger h-default r-flx r-flx-jc r-flx-ac`}
                        onClick={() => {
                          setReconnectLinkedIn(true);
                          openPopup(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
                        }}
                      >
                        <ImageValidation isImgValid defaultImg="reconnect" customClassname="reconnect" customName="Reconnect" />
                        <span> {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, 'light', 'Reconnect')}</span>
                      </button>
                    </CustomRippleButton>
                  ) : linkedInFilterData?.needs_updated_credentials ? (
                    <CustomRippleButton rippleClass={`ripple-unset ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
                      <button className={`ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac`} onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)}>
                        {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, 'light', 'Update Credentials')}
                      </button>
                    </CustomRippleButton>
                  ) : null}
                  <CustomRippleButton rippleClass="ripple-unset ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className="ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac" onClick={() => setShowModal(true)}>
                      {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, 'primary', linkedInFilterData?.needs_reconnection ? 'Remove' : 'Disconnect')}
                    </button>
                  </CustomRippleButton>
                </div>
              )}
            </>
          ) : (
            <div className="csp-action">
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac`} onClick={() => openPopup(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)}>
                  {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, 'light', 'Connect Now')}
                </button>
              </CustomRippleButton>
            </div>
          )}
        </div>
      ) : null}

      {showPageModal ? (
        <div className="rlc-pt-modal-main-wraps">
          <SettingsSocialModalPopup
            isModalShow={showPageModal}
            modalCloseButton
            handleModalClose={() => {
              dispatch(updateSocialMediaSettingSuccessOrFailure({ type: '' }));
              setShowPageModal(false);
            }}
            modalHeader={() => (
              <>
                <div className="stngs-badge">
                  <ImageValidation isImgValid defaultImg="stats/in-stats" customName="settings social" />
                </div>
                <h3 className={`aps-head`}>Select the Linkedin Business Page you'd like to connect to {APP_TITLE}</h3>
              </>
            )}
            modalHeaderType=""
            modalBody={() =>
              linkedInPages.isFetching ? (
                <Loading />
              ) : (
                <LinkedInViewPagesModalBody
                  pagesDetailData={linkedInPages.data.map((it) => ({ id: String(it.id), name: it.name, type: it.dataType }))}
                  handlePageSelect={(value: { obj: settingsSocialProfileApiTypes.ILinkedInViewPageDataItem | undefined; type: string }) => {
                    if (value.obj) {
                      const newPayload: any = mediaSettingPayload || {};
                      newPayload.social_medium.social_media_id = value.obj?.id;
                      newPayload.social_medium.social_media_type = value.obj?.type || '';
                      newPayload.social_medium.linkedin_id = value.obj?.id;
                      newPayload.social_medium.data.linkedin_profile_name = value.obj?.name;
                      newPayload.social_medium.profile_url = linkedInPages.data.find((it: any) => String(it.id) === String(value.obj?.id))?.pageUrl || '';
                      newPayload.callback = null;
                      if (reconnectLinkedIn) newPayload.id = accountDetails?.social_media.find((item) => item.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)?.id;
                      if (accountId) {
                        dispatch(updateSocialMediaSetting(newPayload));
                        setShowPageModal(false);
                      }
                    }
                  }}
                />
              )
            }
            modalFooter={() => (
              <div className="apmodal modal-btn-grp-wraps ap-actions">
                <Button
                  className="btnOutline modal-cancel-btn"
                  variant="outline-secondary"
                  onClick={() => {
                    setStopSpinnerOnCancel(true);
                    setShowPageModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          />
        </div>
      ) : null}
    </>
  );
};
