import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { PROFILE_ANALYTICS_PLATFORM_FILTER, PROFILE_ANALYTICS_FILTER_INITIAL_VALUE, ExportCsvFileName, ValidQueryParams, PROFILE_STATS } from 'analytics/utils';
import { downloadFormatHandler, exportCsvhandler, handleFilterChange } from 'analytics/utils/helpers';
import { alertBoxCall, CommonFilter } from 'components';
import { USER_OWNERSHIP, EXPORT_DOWNLOAD_STARTED_MSG, BUTTON_PROPS, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, ALERT_BOX_TYPES, DONE_SUCCESS_MSG } from 'utils/constants';
import { useAvailablePlatforms, useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { IFiterHandleChangeData } from 'types/common/filter';

export const ProfileAnalyticsFilter = () => {
  const navigate = useNavigate();
  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor(PROFILE_ANALYTICS_FILTER_INITIAL_VALUE);
  const availablePlatform = useAvailablePlatforms(PROFILE_ANALYTICS_PLATFORM_FILTER, userOwnership);

  const data = useSelector((state: IStore) => state.profileAnalytics.data);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const isProfileAnalyticsFetching = useSelector((state: IStore) => state.profileAnalytics.isProfileAnalyticsFetching);

  const queryParamKeys = Object.keys(queryParams).filter((it) => it !== 'selected_tile');
  const isClearFilterDisabled = availablePlatform.length === 1 ? (queryParamKeys?.length === 1 && queryParams?.platform ? true : false) : !queryParamKeys?.length;

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams, availablePlatform);
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  useEffect(() => {
    if (availablePlatform.length === 1 && !queryParams?.platform) {
      const params = { platform: availablePlatform[0].value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, queryParams?.platform]); // eslint-disable-line

  useParamsValidator(
    { ...CommonValidQueryParams, ...ValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      selected_tile: PROFILE_STATS.map((it) => it.value),
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || '')
    }
  );

  const handleExportCsv = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    exportCsvhandler(downloadFormatHandler, data?.profile_analytics, DONE_SUCCESS_MSG, ExportCsvFileName.PA, currentName, id, userOwnership);
  };

  return (
    <CommonFilter
      filter={filter}
      handleChange={handleChange}
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
      handleExportCsv={handleExportCsv}
      primaryButtons={[
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled || isProfileAnalyticsFetching
        }
      ]}
      analyticsPlatformFilter={availablePlatform}
    />
  );
};
