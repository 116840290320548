import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  updateScheduledPostResponse,
  updateScheduledPostFailure,
  updateCalendarItemResponse,
  updateCalendarItemFailure,
  updateScheduledMultiPostsFailure,
  updateScheduledMultiPostsResponse,
  deleteScheduledMultiPostResponse,
  deleteScheduledMultiPostFailure,
  getScheduledMultiPostsResponse,
  getScheduledPostResponse,
  getScheduledPostRequest,
  getScheduledMultiPostsRequest
} from 'actions';
import { apiCall, defaultHeader, API, apiEventSourcePolyfillHandler, HttpMethods } from 'utils/helpers';
import { UPDATE_REQUESTED_POST_FAILED, CALENDAR_EXCEPTION_ERROR, DELETE_SCH_POST_CONTENT_FAILED, SCHEDULE_PARAM_TYPE } from 'utils/constants';
import { ISagaPayload } from 'types/common/api';
import { store } from 'store';

function* sendScheduledPostUpdateRequest(req: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.updateScheduledPost.apiPath.replace(':id', req.payload.payload.id);
    const data = req.payload.payload;
    const { id, account_id, ...updatedSchData } = data;
    const isCalendarView = req.payload?.isCalendarView;
    // key name changes for link
    updatedSchData.link_url = updatedSchData.url;
    updatedSchData.link_title = updatedSchData.link_preview_title;
    updatedSchData.link_description = updatedSchData.link_preview_description;
    const { url, link_preview_title, link_preview_description, ...newUpdatedSchData } = updatedSchData;

    const response = yield apiCall({
      headers,
      ...(!req.payload?.isDeletePost && { data: { scheduled_post: { ...newUpdatedSchData, account_id: String(data.account_id) } } }),
      apiPath: path,
      action: req.payload?.isDeletePost ? HttpMethods.DELETE : API.updateScheduledPost.action
    });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (isCalendarView) {
        yield put(
          updateScheduledPostResponse({
            data: response?.data?.scheduled_post || null,
            postId: req.payload.isDeletePost ? req.payload.payload.id : null,
            accountSlotDetails: req.payload?.isRemoveAllScheduledPostOnThisTimeSlot ? req.payload?.payload?.account_list_schedule_details : null
          })
        );
      }
      if (req.payload?.onSuccess) {
        req.payload.onSuccess();
      }
      if (req.payload?.refetch) {
        // old cond - response.data.scheduled_post?.draft
        apiEventSourcePolyfillHandler(
          API.getScheduledPost.apiPath,
          {
            hidden: 0,
            order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
            account_id: req.payload.payload.account_id,
            ...(req.payload?.dateRange?.length ? { date_range: req.payload.dateRange } : { future: 1 })
          },
          'scheduled_posts',
          () => store.dispatch(getScheduledPostRequest()),
          (data) => {
            store.dispatch(getScheduledPostResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
      }
    } else {
      yield put(updateScheduledPostFailure(response.data?.error || ''));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CALENDAR_EXCEPTION_ERROR));
  }
}

function* sendUpdateReqCalendarItemRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.updateRequestedCalendarItem.apiPath.replace(':id', payload.id);
    const data = {
      calendar_item: payload
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.updateRequestedCalendarItem.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateCalendarItemResponse([response.data.calendar_item]));
    } else {
      yield put(updateCalendarItemFailure(UPDATE_REQUESTED_POST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CALENDAR_EXCEPTION_ERROR));
  }
}

function* sendScheduledMultiPostUpdateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { scheduled_multipost: { ...payload.schedulePayload } };
    const path = API.updateSchduledMultiPost.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.updateSchduledMultiPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateScheduledMultiPostsResponse(response.data?.scheduled_multipost));
      if (payload.franchisorId && payload?.refetch) {
        apiEventSourcePolyfillHandler(
          API.getScheduledMultiPost.apiPath,
          {
            franchisor_id: payload.franchisorId,
            ...(payload.dateRange && payload.dateRange?.startDate && payload.dateRange?.endDate ? { brand_date_range: [payload.dateRange.startDate, payload.dateRange.endDate] } : { future: 1 }),
            trashed: 0
          },
          'scheduled_multiposts',
          () => store.dispatch(getScheduledMultiPostsRequest()),
          (data) => {
            store.dispatch(getScheduledMultiPostsResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
      }
    } else {
      yield put(updateScheduledMultiPostsFailure(response.data?.error || ''));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CALENDAR_EXCEPTION_ERROR));
  }
}

function* sendDeleteScheduledMultiPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteSchduledMultiPost.apiPath.replace(':id', payload.postId);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteSchduledMultiPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deleteScheduledMultiPostResponse({ postId: payload.postId, contentId: payload.contentId }));
    } else {
      yield put(deleteScheduledMultiPostFailure(DELETE_SCH_POST_CONTENT_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CALENDAR_EXCEPTION_ERROR));
  }
}

export function* takeContentCalendarRequest() {
  yield takeEvery(types.UPDATE_SCHEDULED_POST_REQUEST, sendScheduledPostUpdateRequest);
  yield takeEvery(types.UPDATE_REQUESTED_CALENDAR_ITEM_REQUEST, sendUpdateReqCalendarItemRequest);
  yield takeEvery(types.UPDATE_SCHEDULED_MULTI_POST_REQUEST, sendScheduledMultiPostUpdateRequest);
  yield takeEvery(types.DELETE_SCHEDULED_MULTI_POST_REQUEST, sendDeleteScheduledMultiPostRequest);
}
