import { EditorState } from 'draft-js';

import { inboxReducerTypes } from 'types';
import { COMMON_DATE_RANGE_INIT_VALUE } from 'utils/constants';

export enum InboxReplyActionType {
  LIST_REPLY = 'list_reply',
  COMMENT_REPLY = 'comment_reply'
}

export const INBOX_COMMENTS_REPLY_INIT_OBJ = { id: 0, socialContentId: 0, replyText: EditorState.createEmpty() };

export const INBOX_FB_HELP_TOOLTIP_MSG = `Your response window has expired. Respond directly through Facebook. Click to learn more.`;

export const INBOX_INSTAGRAM_HELP_TOOLTIP_MSG = `Your response window has expired. Respond directly through Instagram. Click to learn more.`;

export enum InboxPostType {
  ALL = 'all',
  UNREAD = 'unread',
  SAVED = 'saved'
}

export const INBOX_FILTER_POSTS = [
  { label: 'All', value: InboxPostType.ALL },
  { label: 'Unread', value: InboxPostType.UNREAD, title: 'Posts' },
  { label: 'Saved', value: InboxPostType.SAVED, title: 'Posts' }
];

export enum InboxEngagementType {
  ALL = 'all',
  POST_COMMENTS = 'Post Comments',
  AD_COMMENTS = 'Ad Comments',
  DIRECT_MESSAGES = 'Direct Messages',
  POSTS = 'Posts'
}

export const INBOX_ENGAGEMENT_FILTER_LIST = [
  { value: 'all', label: 'All' },
  { value: 'Post Comments', label: 'Post Comments' },
  { value: 'Ad Comments', label: 'Ad Comments' },
  { value: 'Direct Messages', label: 'Direct Messages' },
  { value: 'Posts', label: 'Mentions' }
];

export const INBOX_FILTER_INITIAL_VALUE: inboxReducerTypes.IInboxFiltersVal = {
  dateRange: COMMON_DATE_RANGE_INIT_VALUE,
  engagement: 'All',
  platform: null,
  onlyLocationId: 0,
  locationId: 0,
  ancestorFranchisorId: 0
};

export enum InboxValidQueryParams {
  PLATFORM = 'platform',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id',
  POSTS = 'posts'
}

export enum IPostEngagementType {
  LIKED = 'liked',
  FAVORITE = 'favorite'
}
