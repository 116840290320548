// POSTS STATS
export const POSTS_STATS_REQUEST = 'posts/POSTS_STATS_REQUEST';
export const POSTS_STATS_RESPONSE = 'posts/POSTS_STATS_RESPONSE';
export const POSTS_STATS_REQUEST_FAILURE = 'posts/POSTS_STATS_REQUEST_FAILURE';

// POSTS LIST
export const POSTS_LIST_REQUEST = 'posts/POSTS_LIST_REQUEST';
export const POSTS_LIST_RESPONSE = 'posts/POSTS_LIST_RESPONSE';
export const POSTS_LIST_REQUEST_FAILURE = 'posts/POSTS_LIST_REQUEST_FAILURE';

// POSTS BRAND VIEW LOCATION CONTENT
export const POSTS_LOCATION_CONTENT_REQUEST = 'posts/POSTS_LOCATION_CONTENT_REQUEST';
export const POSTS_LOCATION_CONTENT_RESPONSE = 'posts/POSTS_LOCATION_CONTENT_RESPONSE';
export const POSTS_LOCATION_CONTENT_FAILURE = 'posts/POSTS_LOCATION_CONTENT_FAILURE';

// POSTS DETAIL DATA
export const POSTS_DETAIL_RESPONSE = 'posts/POSTS_DETAIL_RESPONSE';
export const POSTS_DETAIL_REQUEST_FAILURE = 'posts/POSTS_DETAIL_REQUEST_FAILURE';

// POSTS DETAIL INTERNAL NOTES LIST
export const POSTS_DETAIL_INT_NOTES_LIST_REQUEST = 'posts/POSTS_DETAIL_INT_NOTES_LIST_REQUEST';
export const POSTS_DETAIL_INT_NOTES_LIST_RESPONSE = 'posts/POSTS_DETAIL_INT_NOTES_LIST_RESPONSE';
export const POSTS_DETAIL_INT_NOTES_LIST_FAILURE = 'posts/POSTS_DETAIL_INT_NOTES_LIST_FAILURE';

// POSTS - DELETE POST
export const POSTS_DELETE_REQUEST = 'posts/POSTS_DELETE_REQUEST';
export const POSTS_DELETE_RESPONSE = 'posts/POSTS_DELETE_RESPONSE';
export const POSTS_DELETE_FAILURE = 'posts/POSTS_DELETE_FAILURE';

// POSTS TAGS
export const POSTS_TAGS_REQUEST = 'posts/POSTS_TAGS_REQUEST';
export const POSTS_TAGS_RESPONSE = 'posts/POSTS_TAGS_RESPONSE';
export const POSTS_TAGS_FAILURE = 'posts/POSTS_TAGS_FAILURE';

// POSTS DETAIL ADD INTERNAL NOTE
export const POSTS_DETAIL_ADD_INTERNAL_NOTE_REQUEST = 'posts/POSTS_DETAIL_ADD_INTERNAL_NOTE_REQUEST';
export const POSTS_DETAIL_ADD_INTERNAL_NOTE_RESPONSE = 'posts/POSTS_DETAIL_ADD_INTERNAL_NOTE_RESPONSE';
export const POSTS_DETAIL_ADD_INTERNAL_NOTE_FAILURE = 'posts/POSTS_DETAIL_ADD_INTERNAL_NOTE_FAILURE';

// POSTS EDIT CONTENT DRAFT
export const POSTS_EDIT_CONTENT_DRAFT_REQUEST = 'posts/POSTS_EDIT_CONTENT_DRAFT_REQUEST';
export const POSTS_EDIT_CONTENT_DRAFT_RESPONSE = 'posts/POSTS_EDIT_CONTENT_DRAFT_RESPONSE';
export const POSTS_EDIT_CONTENT_DRAFT_FAILURE = 'posts/POSTS_EDIT_CONTENT_DRAFT_FAILURE';

// POSTS UPDATE SAVED POST LIST
export const POSTS_UPDATE_SAVED_POST_REQUEST = 'posts/POSTS_UPDATE_SAVED_POST_REQUEST';
export const POSTS_UPDATE_SAVED_POST_RESPONSE = 'posts/POSTS_UPDATE_SAVED_POST_RESPONSE';
export const POSTS_UPDATE_SAVED_POST_FAILURE = 'posts/POSTS_UPDATE_SAVED_POST_FAILURE';

// POSTS SYNDICATE EXPIRE DATE UPDATE
export const POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_REQUEST = 'posts/POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_REQUEST';
export const POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_RESPONSE = 'posts/POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_RESPONSE';
export const POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_FAILURE = 'posts/POSTS_SYNDICATE_EXPIRE_DATE_UPDATE_FAILURE';

// SET POSTS SYNDICATE EXPIRE DATE UPDATE
export const POSTS_SYNDICATE_EXPIRE_DATE = 'posts/POSTS_SYNDICATE_EXPIRE_DATE';

// POSTS CANCEL PENDING APPROVAL IN POSTS DETAIL
export const POSTS_CANCEL_PENDING_APPROVAL_RESPONSE = 'posts/POSTS_CANCEL_PENDING_APPROVAL_RESPONSE';

// POSTS COPY
export const POSTS_COPY_REQUEST = 'posts/POSTS_COPY_REQUEST';
export const POSTS_COPY_RESPONSE = 'posts/POSTS_COPY_RESPONSE';
export const POSTS_COPY_FAILURE = 'posts/POSTS_COPY_FAILURE';

// POSTS - REJECT SAVED POST FROM HUB
export const POSTS_REJECT_SAVED_POST_REQUEST = 'posts/POSTS_REJECT_SAVED_POST_REQUEST';
export const POSTS_REJECT_SAVED_POST_RESPONSE = 'posts/POSTS_REJECT_SAVED_POST_RESPONSE';
export const POSTS_REJECT_SAVED_POST_FAILURE = 'posts/POSTS_REJECT_SAVED_POST_FAILURE';

export const POSTS_DETAIL_VIEW_ERROR = 'posts/POSTS_DETAIL_VIEW_ERROR';

// POSTS - SYNDICATE SUCCESS
export const POSTS_SYNDICATE_SUCCESS_RESPONSE = 'posts/POSTS_SYNDICATE_SUCCESS_RESPONSE';

// POSTS - POST PUBLISHED STATUS
export const POSTS_PUBLISHED_STATUS_REQUEST = 'posts/POSTS_PUBLISHED_STATUS_REQUEST';
export const POSTS_PUBLISHED_STATUS_RESPONSE = 'posts/POSTS_PUBLISHED_STATUS_RESPONSE';
export const POSTS_PUBLISHED_STATUS_FAILURE = 'posts/POSTS_PUBLISHED_STATUS_FAILURE';

// POSTS DELETE RESET
export const POSTS_DELETE_DATA_RESET = 'posts/POSTS_DELETE_DATA_RESET';
export const POSTS_DETAIL_DATA_RESET = 'posts/POSTS_DETAIL_DATA_RESET';
export const POSTS_COPY_DATA_RESET = 'posts/POSTS_COPY_DATA_RESET';
export const POSTS_LISTS_DATA_RESET = 'posts/POSTS_LISTS_DATA_RESET';
export const POSTS_RESET_ALL = 'posts/POSTS_RESET_ALL';
