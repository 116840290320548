import { ISurveyListRequestPayload, ISurveyListResponse, ISurveyMetricRequestPayload, ISurveyMetricsResponse, ISendSurveyRequest, INewSurveyRequestParams, ICustomerBasedSurveyRes } from 'revv/types';
import { ActionsUnion, createAction } from 'revv/utils';
import * as actionTypes from '../action-types';

export const surveyMetricsRequest = (payload: ISurveyMetricRequestPayload) => createAction(actionTypes.SURVEY_METRICS_REQUEST, payload);
export const surveyMetricsRequestFailure = (payload: string) => createAction(actionTypes.SURVEY_METRICS_REQUEST_FAILURE, payload);
export const surveyMetricsResponse = (payload: ISurveyMetricsResponse) => createAction(actionTypes.SURVEY_METRICS_RESPONSE, payload);

export const surveyListRequest = (payload: ISurveyListRequestPayload) => createAction(actionTypes.SURVEY_LIST_REQUEST, payload);
export const surveyListRequestFailure = (payload: string) => createAction(actionTypes.SURVEY_LIST_REQUEST_FAILURE, payload);
export const surveyListResponse = (payload: ISurveyListResponse[]) => createAction(actionTypes.SURVEY_LIST_RESPONSE, payload);

export const sendSurveyRequest = (payload: INewSurveyRequestParams) => createAction(actionTypes.SEND_SURVEY_REQUEST, payload);
export const sendSurveyRequestFailure = (payload: string) => createAction(actionTypes.SEND_SURVEY_FAILURE, payload);
export const sendSurveyResponse = (payload: string) => createAction(actionTypes.SEND_SURVEY_RESPONSE, payload);

export const sendQueuedSurveysRequest = () => createAction(actionTypes.SEND_QUEUED_SURVEYS_REQUEST);
export const sendQueuedSurveysFailure = (payload: string) => createAction(actionTypes.SEND_QUEUED_SURVEYS_FAILURE, payload);
export const sendQueuedSurveysResponse = (payload: string) => createAction(actionTypes.SEND_QUEUED_SURVEYS_RESPONSE, payload);

export const customerSendSurveyRequest = (payload: ISendSurveyRequest) => createAction(actionTypes.CUSTOMER_SEND_SURVEY_REQUEST, payload);
export const customerSendSurveyRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_SEND_SURVEY_FAILURE, payload);
export const customerSendSurveyResponses = (payload: string) => createAction(actionTypes.CUSTOMER_SEND_SURVEY_RESPONSE, payload);

export const customerBasedSurveysRequest = (payload: ISurveyListRequestPayload) => createAction(actionTypes.CUSTOMER_BASED_SURVEYS_REQUEST, payload);
export const customerBasedSurveysRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_BASED_SURVEYS_FAILURE, payload);
export const customerBasedSurveysResponses = (payload: ICustomerBasedSurveyRes) => createAction(actionTypes.CUSTOMER_BASED_SURVEYS_RESPONSE, payload);

export const SURVEY_ACTIONS = {
  surveyMetricsRequest,
  surveyMetricsRequestFailure,
  surveyMetricsResponse,
  surveyListRequest,
  surveyListRequestFailure,
  surveyListResponse,
  customerSendSurveyRequest,
  customerSendSurveyRequestFailure,
  customerSendSurveyResponses,
  sendSurveyRequest,
  sendSurveyRequestFailure,
  sendSurveyResponse,
  sendQueuedSurveysRequest,
  sendQueuedSurveysFailure,
  sendQueuedSurveysResponse,
  customerBasedSurveysRequest,
  customerBasedSurveysRequestFailure,
  customerBasedSurveysResponses
};

export type SurveyActions = ActionsUnion<typeof SURVEY_ACTIONS>;
