/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import io, { Socket } from 'socket.io-client';
import { Spinner } from 'react-bootstrap';

import { commonProps, IStore, settingsSocialProfileReducerTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import { config } from 'config';
import {
  accountUpdateProfileRequest,
  updateSocialMediaSetting,
  fetchLinkedinPages,
  saveFbUserCredentials,
  facebookPagesRequest,
  loadGoogleBusinessAccountRequest,
  saveGoogleOauthCredentialResponse,
  accountActivateRequest
} from 'actions';
import { COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP, CONNECTION_TABS, RIPPLE_COLOR, MediaDropPages } from 'utils/constants';
import { useParamsDeconstructor, useAccountSwitcherData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { SocialProfileFacebook } from './SocialProfileFacebook';
import { SocialProfileInstagram } from './SocialProfileInstagram';
import { SocialProfileTwitter } from './SocialProfileTwitter';
import { SocialProfileLinkedin } from './SocialProfileLinkedin';
import { SocialProfileGoogle } from './SocialProfileGoogle';
import { SocialProfileYelp } from './SocialProfileYelp';
import { SocialProfileTiktok } from './SocialProfileTiktok';

export const SettingsSocialProfileDetails = (props: { onCancel: () => void }) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();

  const socketConnection = useRef<Socket | null>();
  const authWindow = useRef<Window | null>();
  const twitterReconnect = useRef<boolean | null>(false);
  const tiktokReconnect = useRef<boolean | null>(false);

  const { id, userOwnership, currentRoute, optionalParams, isAdminScreenEnabled } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });

  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const userId = useSelector((state: IStore) => state.login.activeUser?.id);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const activateAccount = useSelector((state: IStore) => state.accounts.activateAccount);
  const isAccountActivateFetching = useSelector((state: IStore) => state.accounts.isAccountActivateFetching);
  const { mediaRequest } = useSelector((state: IStore) => state.socialProfile);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  const [showGoogleAccountModal, setShowGoogleAccountModal] = useState(false);
  const [showLinkedInPageModal, setShowLinkedInPageModal] = useState(false);
  const [showFBPageModal, setShowFBPageModal] = useState(false);
  const [stopSpinnerOnCancel, setStopSpinnerOnCancel] = useState(false);
  const [mediaSettingPayload, setMediaSettingPayload] = useState<settingsSocialProfileReducerTypes.ISocialMediaDetails>();

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  const handleSocialMediaConnectModal = (socialMedia: string, credentialId: string) => {
    navigate({
      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/${socialMedia}/${credentialId}`
    });
  };

  const updateTwitterSocialMedia = useCallback((twitterdata: settingsSocialProfileReducerTypes.IConnectSocialMediaTwitterPayload) => {
    const payload: any = {
      social_medium: {
        provider_name: COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
        profile_url: `https://twitter.com/${twitterdata?.profile?.username}`,
        oauth_token: twitterdata?.accessToken || twitterdata?.accessTokenV1,
        twitter_user_credential_id: twitterdata?.twitter_uuid,
        data: twitterdata?.data,
        social_media_id: twitterdata?.profile.id,
        social_media_type: 'User',
        oauth_secret: twitterdata?.refreshToken,
        profile_picture_url: twitterdata.profile?.profile_image_url || twitterdata?.profile?._json.profile_image_url,
        cover_picture_url: twitterdata?.rawdata && JSON.parse(twitterdata?.rawdata)?.profile_banner_url ? JSON.parse(twitterdata?.rawdata)?.profile_banner_url : ''
      },
      type: COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
      account_id: accountId
    };
    if (twitterReconnect.current) payload.id = accountDetails?.social_media.find((item) => item.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)?.id;
    dispatch(updateSocialMediaSetting(payload));
  }, []);

  const updateLinkedInSocialMedia = useCallback((linkedindata: settingsSocialProfileReducerTypes.IConnectSocialMediaLinkedInPayload) => {
    const payload = {
      social_medium: {
        provider_name: COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
        profile_url: `https://linkedin.com/${linkedindata.profile._json.vanityName}`,
        oauth_token: linkedindata.accessToken,
        data: linkedindata.profile,
        social_media_id: linkedindata.profile.id,
        linkedin_id: linkedindata.profile.id,
        social_media_type: 'User',
        linkedin_user_credential_id: linkedindata.linkedin_uuid,
        profile_picture_url: linkedindata.profile.photos ? linkedindata.profile.photos[linkedindata.profile.photos.length - 1]?.value : ''
      },
      type: COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
      account_id: accountId
    };
    setShowLinkedInPageModal(true);
    dispatch(fetchLinkedinPages({ access_token: linkedindata.accessToken }));
    setMediaSettingPayload(payload);
  }, []);

  useEffect(() => {
    if (!socketConnection.current) {
      socketConnection.current = io(config.apiNodeBaseURL, { transports: ['websocket', 'pooling'] });
      socketConnection.current.on('connect', () => {
        console.info('Socket Connected !!!');
      });
      socketConnection.current.on('twitter', (data: settingsSocialProfileReducerTypes.IConnectSocialMediaTwitterPayload) => {
        updateTwitterSocialMedia(data);
        if (authWindow.current) {
          authWindow.current.close();
        }
      });
      socketConnection.current.on('linkedin', (data: settingsSocialProfileReducerTypes.IConnectSocialMediaLinkedInPayload) => {
        updateLinkedInSocialMedia(data);
        if (authWindow.current) {
          authWindow.current.close();
        }
      });
      socketConnection.current.on('google', (data: any) => {
        if (authWindow.current) {
          authWindow.current.close();
        }
        if (currentRoute.includes(MediaDropPages.SETTINGS)) {
          handleSocialMediaConnectModal(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, data.credentialId);
        } else {
          setShowGoogleAccountModal(true);
        }
        dispatch(saveGoogleOauthCredentialResponse({ credentialId: data.credentialId }));
        dispatch(loadGoogleBusinessAccountRequest({ credentialId: data.credentialId }));
      });
    }
    return () => {
      if (socketConnection.current) {
        socketConnection.current?.removeAllListeners();
        socketConnection.current = null;
      }
    };
  }, [updateTwitterSocialMedia, updateLinkedInSocialMedia, setShowGoogleAccountModal, dispatch]);

  const getFbViewPages = (fbResponse: any | null, type: string = COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) => {
    if (fbResponse && fbResponse.accessToken) {
      const payload: settingsSocialProfileReducerTypes.IFacebookCredential = {
        ...fbResponse,
        rallioUserId: userId || 0,
        app_id: fbApplicationId,
        callback: (id: string, accessToken: string, type: string) => {
          if (currentRoute.includes(MediaDropPages.SETTINGS)) {
            handleSocialMediaConnectModal(type, id);
          } else {
            dispatch(facebookPagesRequest({ id, access_token: accessToken, type, refresh: 1 }));
            setShowFBPageModal(true);
          }
        },
        mediaName: type
      };
      dispatch(saveFbUserCredentials(payload));
    }
  };

  const openPopup = (platform: string) => {
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${config.apiNodeBaseURL}social_connections/${platform}?socketId=${socketConnection.current?.id}&userId=${userId}`;
    authWindow.current = window.open(
      url,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`
    );
  };
  const renderLabel = (name: string, spinnerVariant: string, label: string) =>
    optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || stopSpinnerOnCancel ? label : mediaRequest[name] ? <Spinner animation="border" variant={spinnerVariant} /> : label;

  /** Add new account profile */
  const handleNextAccountProfile = () => {
    if (accountDetails?.account?.id) {
      dispatch(
        accountUpdateProfileRequest({
          id: accountDetails?.account?.id,
          profileData: accountDetails?.account,
          type: 'add_location_social',
          isOnlyUpdateAccount: true,
          ...((isContentSupplierFranchisor || isCSLocation) && { onNavigate: (accountId) => handleNavigateToFeedPage(accountId) })
        })
      );
      if (!isContentSupplierFranchisor && !isCSLocation) {
        dispatch(
          accountActivateRequest({
            id: accountDetails?.account?.id,
            onNavigate: (currentAccountId) => navigate({ pathname: `/${USER_OWNERSHIP.ACCOUNT}/${currentAccountId.toString()}/content/posts`, search: '' })
          })
        );
      }
    }
  };

  const handleURLPathname = (route: string, search?: commonProps.ICommonObj<string>) => {
    if (isAdmin && isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
  };

  const handleNavigateToFeedPage = (accountId: number) => {
    if (['admin_area/accounts'].includes(currentRoute)) {
      navigate({ pathname: `/${currentRoute}/account_wizard/${accountId}/feed`, search: '' }, { state: {}, replace: true });
    } else if (!['team_management/connections'].includes(currentRoute)) {
      navigate({ pathname: `/${USER_OWNERSHIP.ACCOUNT}/${accountId.toString()}/${currentRoute}/account_wizard/${accountId}/feed`, search: '' });
    } else {
      navigate({ pathname: `/${USER_OWNERSHIP.ACCOUNT}/${accountId.toString()}/content/posts`, search: '' });
    }
  };

  return (
    <>
      <div className="mainContent animate__animated animate__fadeIn social-profile-details-wrp settings__main lpx vpy-20">
        <div className="settings-main-wrp">
          <div className="frameModule settings stg-sp left-section-wrp">
            {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES && !isCSLocation ? null : (
              <div className="location-peer-wrp sandboxlist-wrp mb-20">
                <div className="location-img g-15">
                  <div className="trans-icon">
                    <ImageValidation isImgValid defaultImg="connectins-profile" customName="trans-icon" />
                  </div>
                  {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES
                    ? 'Connect at least one social profile'
                    : userOwnership === USER_OWNERSHIP.ACCOUNT
                    ? 'My Connected Profiles'
                    : 'What are your locations up to?'}
                </div>
              </div>
            )}
            <div className="csp mx-0">
              <SocialProfileFacebook
                showPageModal={showFBPageModal}
                setShowPageModal={setShowFBPageModal}
                renderLabel={renderLabel}
                setStopSpinnerOnCancel={setStopSpinnerOnCancel}
                getFbViewPages={getFbViewPages}
              />

              <SocialProfileInstagram getFbViewPages={getFbViewPages} renderLabel={renderLabel} />

              <SocialProfileTwitter renderLabel={renderLabel} openPopup={openPopup} twitterReconnect={twitterReconnect} />

              <SocialProfileLinkedin
                showPageModal={showLinkedInPageModal}
                setShowPageModal={setShowLinkedInPageModal}
                mediaSettingPayload={mediaSettingPayload}
                renderLabel={renderLabel}
                setStopSpinnerOnCancel={setStopSpinnerOnCancel}
                openPopup={openPopup}
              />

              <SocialProfileTiktok renderLabel={renderLabel} openPopup={openPopup} tiktokReconnect={tiktokReconnect} />

              <SocialProfileGoogle showPageModal={showGoogleAccountModal} setShowPageModal={setShowGoogleAccountModal} renderLabel={renderLabel} openPopup={openPopup} />

              <SocialProfileYelp />
            </div>
          </div>
          {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES && (
            <div className="stg-item form-configure r-mr0">
              <div className="modal-btn-grp-wraps csp-action ap-actions cn-social-pr">
                {activateAccount ? null : (
                  <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className="ac-btn ac-secondary-white ac-outline size-sm border-0" disabled={isAccountActivateFetching} onClick={props.onCancel}>
                      Cancel
                    </button>
                  </CustomRippleButton>
                )}
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className="ac-btn ac-primary-white ac-outline size-sm"
                    disabled={isAccountActivateFetching}
                    onClick={() => {
                      if (accountDetails?.account?.signup_coupon?.coupon_code) {
                        handleURLPathname(`/${currentRoute}/account_wizard/${accountDetails?.account?.id?.toString()}/${CONNECTION_TABS.UPGRADE_PACKAGE}`);
                      } else {
                        handleURLPathname(`/${currentRoute}/account_wizard/${accountDetails?.account?.id?.toString()}/${CONNECTION_TABS.RALLIO_PROFILE}`);
                      }
                    }}
                  >
                    Back
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button className="ac-btn ac-primary size-sm" disabled={isAccountActivateFetching} onClick={() => handleNextAccountProfile()}>
                    {isAccountActivateFetching ? (
                      <Spinner animation="border" variant="light" />
                    ) : (isContentSupplierFranchisor || isCSLocation) && currentRoute !== 'team_management/connections' ? (
                      'Next'
                    ) : (
                      'Finish'
                    )}
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
