import { useState } from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import {
  FRANCHISORS_SELECTIONS,
  LOCATIONS,
  ALL_LOCATIONS,
  LOCATION,
  LOCATION_LISTS,
  ALL_HUBS,
  REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  NoDataFoundProps,
  RIPPLE_COLOR,
  ALL_HUB_OPTIONS,
  SIDE_NAV_NAME
} from 'utils/constants';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { Loading, NoDataFound } from 'components';
import { ILocationOptions } from 'analytics/types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

interface ILocationFilterModal {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: any;
  initialObj: ILocationOptions;
}

export const LocationFilterModal = ({ setModalState, handleChange, initialObj }: ILocationFilterModal) => {
  const { subNavPageName } = useNavBarData();
  const { id, optionalParams } = useAccountSwitcherData();
  const isMultipleLocationEnabled = (optionalParams[0] === 'facebook_post_stats' || optionalParams[0] === 'linkedin_post_stats') && +optionalParams[1] > 0;
  const multipleLocationId = isMultipleLocationEnabled ? +optionalParams[1] : 0;

  const accountSelectionList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  const isLocationSelectorFetching = useSelector((state: IStore) => state.accountSwitcher.isLocationSelectorFetching);

  const isHubListCall = subNavPageName === SIDE_NAV_NAME.EMP_ADV_OVERVIEW ? false : true;
  const isLocationListCall = subNavPageName === SIDE_NAV_NAME.EMP_ADV_OVERVIEW ? false : true;
  const isOnlyLocationListCall = subNavPageName === SIDE_NAV_NAME.TM_CONNECTIONS || subNavPageName === SIDE_NAV_NAME.ANALYTICS_CONTENT ? false : true;

  const locationDropdownOptions: ILocationOptions[] = [
    REVIEWS_LOCATIONS_DROPDOWN_OBJ,
    ...(isHubListCall
      ? [
          {
            label: FRANCHISORS_SELECTIONS,
            options: subNavPageName === SIDE_NAV_NAME.ANALYTICS_LEADERBOARD ? [ALL_HUB_OPTIONS, ...(accountSelectionList?.hubList || [])] : accountSelectionList?.hubList || []
          }
        ]
      : []),
    ...(multipleLocationId === 0 && isLocationListCall
      ? [
          {
            label: LOCATION_LISTS,
            options: accountSelectionList?.locationList || []
          }
        ]
      : []),
    ...(multipleLocationId === 0 && isOnlyLocationListCall
      ? [
          {
            label: LOCATIONS,
            options: accountSelectionList?.onlyLocationList || []
          }
        ]
      : [])
  ];

  const [options, setOptions] = useState(initialObj);
  const [currentAccord, setCurrentAccord] = useState('');
  const [searchString, setsearchString] = useState('');

  const LocationModalImg = [
    { label: FRANCHISORS_SELECTIONS, image: 'hubs-active-new' },
    { label: LOCATIONS, image: 'location-blue-active' },
    { label: LOCATION_LISTS, image: 'locationLists-blue' }
  ];

  const locationFilterHandler = () => {
    setModalState(false);
    const optionId = options?.id ? +options?.id : 0;
    if (initialObj !== options && id) {
      handleChange(
        options?.label === ALL_HUBS
          ? {
              isLocationAllHubSelected: true,
              locationFilterObj: options,
              ...(options?.type === FRANCHISORS_SELECTIONS
                ? { ancestorFranchisorId: id, locationId: 0, locationListId: 0 }
                : options?.type === LOCATION_LISTS
                ? { ancestorFranchisorId: 0, locationId: 0, locationListId: id }
                : options?.type === LOCATION
                ? { ancestorFranchisorId: 0, locationId: id, locationListId: 0 }
                : { ancestorFranchisorId: id, locationId: 0, locationListId: 0 })
            }
          : {
              locationFilterObj: options,
              ...(SIDE_NAV_NAME.ANALYTICS_LEADERBOARD ? { isLocationAllHubSelected: false } : {}),
              ...(options?.type === FRANCHISORS_SELECTIONS
                ? { ancestorFranchisorId: optionId, locationId: 0, locationListId: 0 }
                : options?.type === LOCATION_LISTS
                ? { ancestorFranchisorId: 0, locationId: 0, locationListId: optionId }
                : options?.type === LOCATION
                ? { ancestorFranchisorId: 0, locationId: optionId, locationListId: 0 }
                : { ancestorFranchisorId: id, locationId: 0, locationListId: 0 })
            }
      );
    }
  };

  return (
    <Modal show className="lb-filter__modal accountSwitchModal global-modal-secondary" centered size="lg" onHide={() => setModalState}>
      {isLocationSelectorFetching ? (
        <Loading />
      ) : (
        <>
          <div className="mod__close--icon">
            <ImageValidation isImgValid defaultImg="close-modal-icon" customClassname="close-icon" customName={'close'} onClick={() => setModalState(false)} />
          </div>
          <Modal.Body>
            <div className="asm-top-head">
              <h3 className="title">Location Selector</h3>
              <div className="asm-lf">
                <input type="text" name="Locations" onChange={(event) => setsearchString(event.target.value.trim())} placeholder="Search" />
              </div>
            </div>
            <div className="asm-accord">
              <div className="all-locs">
                <label onClick={() => setOptions(locationDropdownOptions[0])} className="cur-pointer r-flx r-flx-ac">
                  <input type="radio" defaultChecked={options?.id === REVIEWS_LOCATIONS_DROPDOWN_OBJ?.id} className="option-input radio" name="selectedLocation" />
                  <span>{ALL_LOCATIONS}</span>
                </label>
              </div>
              {locationDropdownOptions.slice(1).map((datum, index: number) => {
                const imgObj = LocationModalImg.find((it) => it.label === datum?.label);
                return (
                  <Accordion activeKey={currentAccord} key={index}>
                    <Card>
                      <Accordion.Item eventKey={`${index}`}>
                        <Card.Header className={currentAccord === `${index}` ? 'open' : ''} onClick={() => setCurrentAccord(currentAccord === `${index}` ? `` : `${index}`)}>
                          <span className="lca-head">{datum?.label} </span>
                          {(options?.type === LOCATION ? (datum?.label === LOCATIONS ? true : false) : options?.type === datum?.label) ? (
                            options?.locationsCount !== undefined ? (
                              <div className="lsl-counts-wrap">
                                <span className="lcs-name" title={options?.label}>
                                  ({options?.label}
                                </span>{' '}
                                (<span className="lca-sel-label">{options?.locationsCount}</span>))
                              </div>
                            ) : (
                              <div className="lsl-counts-wrap">
                                <span className="lcs-name" title={options?.label}>
                                  ({options?.label})
                                </span>
                              </div>
                            )
                          ) : null}
                        </Card.Header>
                      </Accordion.Item>
                      <Accordion.Collapse eventKey={`${index}`}>
                        <Card.Body>
                          <ul className="hub-list">
                            {datum.options && datum.options?.length && imgObj ? (
                              datum.options
                                .filter((iterated) => iterated.label.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
                                .map((it, itIndex: number) => (
                                  <li key={itIndex}>
                                    <label className={`r-flx r-flx-ac lc-acc-cnt${options?.id === it.id ? ` active` : ''}`} onClick={() => setOptions(it)}>
                                      <input type="radio" defaultChecked={options?.id === it.id} className="option-input radio" name="selectedLocation" />
                                      <ImageValidation isImgValid defaultImg={imgObj.image} customName="location selector option" customClassname="hub" />
                                      <div className={`list-lbl cur-pointer`}>
                                        {it.locationsCount !== undefined ? (
                                          <div className="lsl-counts-wrap">
                                            <span className="lcs-name" title={it.label}>
                                              {it.label}
                                            </span>{' '}
                                            (<span className="lca-sel-label">{it.locationsCount}</span>)
                                          </div>
                                        ) : (
                                          <span className="lcs-name" title={it.label}>
                                            {it.label}
                                          </span>
                                        )}
                                      </div>
                                    </label>
                                  </li>
                                ))
                            ) : (
                              <NoDataFound size={NoDataFoundProps.SMALL} />
                            )}
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })}
            </div>
            <Modal.Footer>
              <div className="asm-btn">
                <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={() => setModalState(false)}>
                    Cancel
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass={`ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                  <button className={`ac-btn ac-primary ac-block`} onClick={locationFilterHandler}>
                    Ok
                  </button>
                </CustomRippleButton>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
