import { reviewsReducerTypes } from 'types';

export const REVIEWS_INITIAL_STATS_VALUE: reviewsReducerTypes.IReviewStats = {
  averageRating: 0,
  reviewsCount: 0,
  repliedPercentage: '',
  statsByDate: [],
  platformStats: {
    facebook: 0,
    yelp: 0,
    google: 0
  }
};

export const REVIEWS_INT_NOTES_OBJ: reviewsReducerTypes.IReviewsInternalNotesObj = {
  isNotesFetching: false,
  currentReviewId: 0,
  reviewsInternalNotesList: []
};
