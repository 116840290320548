// INBOX LIST
export const INBOX_LIST_REQUEST = 'inbox/INBOX_LIST_REQUEST';
export const INBOX_LIST_RESPONSE = 'inbox/INBOX_LIST_RESPONSE';
export const INBOX_LIST_REQUEST_FAILURE = 'inbox/INBOX_LIST_REQUEST_FAILURE';

// INBOX - DELETE INBOX
export const INBOX_DELETE_REQUEST = 'inbox/INBOX_DELETE_REQUEST';
export const INBOX_DELETE_RESPONSE = 'inbox/INBOX_DELETE_RESPONSE';
export const INBOX_DELETE_FAILURE = 'inbox/INBOX_DELETE_FAILURE';

// INBOX RESET
export const INBOX_DELETE_DATA_RESET = 'inbox/INBOX_DELETE_DATA_RESET';

// INBOX LIKE
export const INBOX_LIKE_RESPONSE = 'inbox/INBOX_LIKE_RESPONSE';
export const INBOX_LIKE_FAILURE = 'inbox/INBOX_LIKE_FAILURE';

// INBOX COMMENTS LIKE
export const INBOX_COMMENTS_ENGAGEMENT_REQUEST = 'inbox/INBOX_COMMENTS_ENGAGEMENT_REQUEST';
export const INBOX_COMMENTS_ENGAGEMENT_RESPONSE = 'inbox/INBOX_COMMENTS_ENGAGEMENT_RESPONSE';
export const INBOX_COMMENTS_ENGAGEMENT_FAILURE = 'inbox/INBOX_COMMENTS_ENGAGEMENT_FAILURE';

// INBOX REPLY
export const INBOX_REPLY_REQUEST = 'inbox/INBOX_REPLY_REQUEST';
export const INBOX_REPLY_RESPONSE = 'inbox/INBOX_REPLY_RESPONSE';
export const INBOX_REPLY_FAILURE = 'inbox/INBOX_REPLY_FAILURE';

// INBOX RETWEET
export const INBOX_RETWEET_REQUEST = 'inbox/INBOX_RETWEET_REQUEST';
export const INBOX_RETWEET_RESPONSE = 'inbox/INBOX_RETWEET_RESPONSE';
export const INBOX_RETWEET_FAILURE = 'inbox/INBOX_RETWEET_FAILURE';

// INBOX COMMENTS
export const INBOX_COMMENTS_REQUEST = 'inbox/INBOX_COMMENTS_REQUEST';
export const INBOX_COMMENTS_RESPONSE = 'inbox/INBOX_COMMENTS_RESPONSE';
export const INBOX_COMMENTS_FAILURE = 'inbox/INBOX_COMMENTS_FAILURE';

// INBOX DISMISS
export const INBOX_DISMISS_REQUEST = 'inbox/INBOX_DISMISS_REQUEST';
export const INBOX_DISMISS_RESPONSE = 'inbox/INBOX_DISMISS_RESPONSE';
export const INBOX_DISMISS_FAILURE = 'inbox/INBOX_DISMISS_FAILURE';

// INBOX PIE RELOAD
export const INBOX_PIE_RELOAD_REQUEST = 'inbox/INBOX_PIE_RELOAD_REQUEST';
export const INBOX_PIE_RELOAD_RESPONSE = 'inbox/INBOX_PIE_RELOAD_RESPONSE';
export const INBOX_PIE_RELOAD_FAILURE = 'inbox/INBOX_PIE_RELOAD_FAILURE';

// INBOX - SET FILTER
export const INBOX_SET_STATS_CALL = 'inbox/INBOX_SET_STATS_CALL';

// INBOX - SET ACTIVE COMMENT IDS
export const INBOX_SET_ACTIVE_COMMENT_IDS = 'inbox/INBOX_SET_ACTIVE_COMMENT_IDS';

// INBOX STATS
export const INBOX_GET_STATS_REQUEST = 'reviews/INBOX_GET_STATS_REQUEST';
export const INBOX_GET_STATS_RESPONSE = 'reviews/INBOX_GET_STATS_RESPONSE';
export const INBOX_GET_STATS_FAILURE = 'reviews/INBOX_GET_STATS_FAILURE';

// UPDATE POST COUNT AND STATS
export const UPDATE_POST_COMMENT_COUNT_REQUEST = 'inbox/UPDATE_POST_COMMENT_COUNT_REQUEST';
export const UPDATE_POST_COMMENT_COUNT = 'inbox/UPDATE_POST_COMMENT_COUNT';
export const UPDATE_INBOX_STATS = 'inbox/UPDATE_INBOX_STATS';

// INBOX DIRECT MESSAGE THREAD
export const INBOX_DIRECT_MESSAGE_THREAD_REQUEST = 'inbox/INBOX_DIRECT_MESSAGE_THREAD_REQUEST';
export const INBOX_DIRECT_MESSAGE_THREAD_RESPONSE = 'inbox/INBOX_DIRECT_MESSAGE_THREAD_RESPONSE';
export const INBOX_DIRECT_MESSAGE_THREAD_FAILURE = 'inbox/INBOX_DIRECT_MESSAGE_THREAD_FAILURE';

// INBOX - RESET ALL
export const INBOX_REPLY_MSG_RESET = 'inbox/INBOX_REPLY_MSG_RESET';
export const INBOX_VIEW_COMMENTS_DATA_RESET = 'inbox/INBOX_VIEW_COMMENTS_DATA_RESET';
export const INBOX_COMMENTS_PAGE_COUNT_RESET = 'inbox/INBOX_COMMENTS_PAGE_COUNT_RESET';
export const INBOX_RESET_ALL = 'inbox/INBOX_RESET_ALL';

// INBOX SET THREAD MESSAGE STATUS
export const INBOX_SET_IS_NEW_THREAD_MSG_FOUND = 'inbox/INBOX_SET_IS_NEW_THREAD_MSG_FOUND';
