import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { dateFormatByRequestedFormat } from 'utils/helpers';
import { MOMENT_TIME_FORMAT, MOMENT_LONG_DATE_FORMAT } from 'utils/constants';
import { IStore } from 'types';
import { TextArea } from 'widgets/Text';
import { addPostAddInternalNotesListRequest } from 'actions';
import { useSiteRestriction } from 'utils/hooks';

export const InternalNotesSection = () => {
  const dispatch = useDispatch();

  const { postsRestriction } = useSiteRestriction();

  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const internalNotesList = useSelector((state: IStore) => state.addPostFranchisorAction.internalNotesList);

  const [internalNoteText, setInternalNoteText] = useState('');

  const handleAddInternalNotes = () => {
    if (contentData) {
      dispatch(addPostAddInternalNotesListRequest({ contentId: contentData.id, message: internalNoteText }));
      setInternalNoteText('');
    }
  };

  return (
    <Form>
      <div className="st-tc-item">
        <div className="wb-top">
          <div className="chat-main">
            <div className="cm-group">
              {!postsRestriction.internal_note && (
                <div className={`cm-input-area ${contentData?.locked ? 'pointer-events-none' : ''}`}>
                  <div className="cm-asset">
                    <ImageValidation isImgValid defaultImg={'user-avatar-common.png'} isNotSvgFormat customName={'Profile Pic'} />
                  </div>
                  <div className="cmi">
                    <Button className="plain-btn arw-cmi" variant="link" onClick={() => handleAddInternalNotes()} />
                    <TextArea
                      id="internalnote"
                      name="internalnote"
                      minRows={1}
                      className="form-control post-notes"
                      placeholder="Add Internal Note"
                      onChange={(event) => setInternalNoteText(event.target.value)}
                      value={internalNoteText || ''}
                    />
                  </div>
                </div>
              )}
              <div className="cma-child-holder">
                {internalNotesList.length ? (
                  internalNotesList.map((noteItem, index) => {
                    return (
                      <div className="cm-input-area cma-child" key={`creator-fran-internal-note-${index}`}>
                        <div className="cm-asset">
                          <ImageValidation imgUrl={noteItem.user_photo_url || ''} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Profile Pic'} />
                        </div>
                        <div className="cmi">
                          <div className="cm-prime-dtls">
                            <h3>{noteItem.user_name}</h3>
                            <div className="cmp-brief">
                              <span className="cmp-txt">{`@ ${dateFormatByRequestedFormat(noteItem.created_at, MOMENT_TIME_FORMAT)} |`}</span>
                              <span className="cmp-txt cmpt-blue"> {dateFormatByRequestedFormat(noteItem.created_at, MOMENT_LONG_DATE_FORMAT)}</span>
                            </div>
                          </div>
                          <div className="cmi-txt-wrap">
                            <TextArea
                              id="internalnote"
                              name="internalnote"
                              readOnly
                              disabled
                              defaultValue={noteItem.body}
                              className="form-control post-notes"
                              placeholder="Internal Notes"
                              maxRows={2}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="notetext">There are no internal notes to display.</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
