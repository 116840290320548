import { ModalPopup } from 'components';
import { commonModalPopup } from 'types';
import { MG_DELETE_ASSET_CONFIRM_MSG, MODAL_BOX_TYPES } from 'utils/constants';

export const DeleteAssetModalPopup = ({ showModal, handleModalClose, handleDelete, message, secondaryMessage, okButtonText, cancelButtonText }: commonModalPopup.IMGDeleteAssetModalPopup) => {
  return (
    <ModalPopup
      isModalShow={showModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      // containerClassName={'modal-confirmation confirm-delete pureDelete'}
      containerClassName={'cnfrm__delete--modal glbl__delete--modal'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{message || MG_DELETE_ASSET_CONFIRM_MSG}</div>
          {secondaryMessage && <div className="modal-message-wraps">{secondaryMessage}</div>}
        </div>
      )}
      modalCloseButton
      handleModalClose={() => handleModalClose()}
      isModalFooterShow
      modalFooter={() => (
        // <div className="modal-btn-grp-wraps delete__btn--wrp">
        <div className="delete__btn--wrp">
          {/* <button className="modal-btn-action-itm modal-cancel-btn" onClick={() => handleModalClose()}> */}
          <button className="ac-btn ac-secondary-white ac-outline" onClick={() => handleModalClose()}>
            {cancelButtonText || 'Cancel'}
          </button>
          {/* <button className="modal-btn-action-itm modal-delete-btn" onClick={() => handleDelete()}> */}
          <button className="ac-danger ac-btn" onClick={() => handleDelete()}>
            {okButtonText || 'Delete'}
          </button>
        </div>
      )}
    />
  );
};
