import { useState, useEffect, useCallback } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router';

import { IStore, rpApiTypes } from 'types';
import { ImageValidation } from 'widgets/Media';
import {
  HEADER_TITLE_SPLITTER,
  RP_DATE_FORMAT,
  TIME_ONLY_FOR_EACH_12_HOURS,
  USER_OWNERSHIP,
  RP_PROGRAM_RESET_TYPES,
  RewardType,
  RP_PROGRAM_TYPE,
  RP_MODIFIED_STATUS,
  RP_USER_ACTION_TYPE,
  RP_ACTIONS_TYPE,
  RP_MODAL_TITLE,
  RP_DELETE_ACTION_OBJ,
  MODAL_BOX_TYPES,
  RP_PROGRAM_DELETE_CONFIRM,
  Stage,
  RIPPLE_COLOR,
  RP_DISABLE_TYPE,
  RecommendedType,
  COMMON_PAGE_TYPE,
  NoDataFoundProps
} from 'utils/constants';
import {
  rpCreateOrUpdateProgramReset,
  rpSetCreateProgramDataObj,
  stripeCardsListReset,
  stripeCardsListRequest,
  rpCreateOrUpdateProgramRequest,
  rpDeleteProgramRequest,
  rpRewardsModified,
  rpSetResendEmailModified
} from 'actions';
import { ProgramDetails, ProgramActions, ProgramLocations, EmailTemplate, ProgramPayment, AchieversDetailView, ProgramSaveChangesModal } from 'components/Common';
import { capitalizeName, dateFormatByRequestedFormat, getCurrentDate, getModifiedProgramSummary, getSaveAsDraftProgramDetails, rpSaveChangesStatus } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { Loading, ModalPopup, NoDataFound } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

interface ICreateProgramModalProps {
  modalType: string;
  programId?: string;
}

export const CreateProgramModal = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const { modalType, programId } = useOutletContext<ICreateProgramModalProps>();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ program: 'all' });

  const { customerObj, cardsListArray } = useSelector((state: IStore) => state.stripe);
  const { createProgramDataObj, currentAction, isRewardsModified, isResendEmailModified, promotionVaultList, isDetailFetching, programDetailObj } = useSelector(
    (state: IStore) => state.rewardPrograms
  );
  const currentAccountName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const brandEmail = useSelector((state: IStore) => state.accountSwitcher.brandEmail);
  const { programDetails, locationsObj, emailObj, modifiedStatus, paymentRequestObj, programAction, programHuLocations } = createProgramDataObj;
  const { rewardsType, recommendedType } = programDetails;
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name);
  const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name);
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url);

  const [deleteAction, setDeleteAction] = useState(RP_DELETE_ACTION_OBJ);
  const [isShowChangesModal, setIsShowChangesModal] = useState(false);

  useEffect(() => {
    if (!isDetailFetching && userOwnership === USER_OWNERSHIP.ACCOUNT && recommendedType !== RecommendedType.RECOMMENDED_TYPE) {
      // let brandHubLocData: rpApiTypes.IRPProgramLocationData = RP_LOCATION_INIT_OBJ;
      // brandHubLocData = getSavedLocationObj(createProgramDataObj);
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          programHuLocations: {
            franchisors: [],
            accountLists: [],
            accounts: [{ id: currentAccountOrFranchisor?.id, name: currentAccountOrFranchisor?.name }],
            selectedLocationCount: 1,
            isAllLocation: false
          },
          locationsObj: {
            ...locationsObj
            // checkedKeysList: [rpTreeList[0].key]
          },
          modifiedStatus: {
            ...modifiedStatus,
            locations: true
          }
        })
      );
    }
  }, [isDetailFetching, userOwnership]); // eslint-disable-line

  useEffect(() => {
    if (isDetailFetching) {
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          modifiedStatus: RP_MODIFIED_STATUS
        })
      );
    }
    if (!isDetailFetching && programDetailObj?.programDetails.programId) {
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          modifiedStatus: {
            details: true,
            actions: programAction?.length ? true : false,
            locations: programHuLocations.franchisors.length || programHuLocations.accountLists.length || programHuLocations.accounts.length ? true : false,
            emailTemplate: true,
            payment: false
          }
        })
      );
    }
  }, [isDetailFetching, programDetailObj?.programDetails.programId]); // eslint-disable-line

  useEffect(() => {
    if (customerObj?.id && !cardsListArray.length) {
      dispatch(stripeCardsListRequest(customerObj.id));
    }
  }, [customerObj?.id && cardsListArray.length]); // eslint-disable-line

  const disableActionTab = useCallback(
    (actionType: string) => {
      const isDisableAction =
        actionType === RP_DISABLE_TYPE.DRAFT
          ? !modifiedStatus.details
            ? true
            : false
          : recommendedType === RecommendedType.RECOMMENDED_TYPE
          ? !modifiedStatus.details || !modifiedStatus.actions || !modifiedStatus.locations || !modifiedStatus.emailTemplate
            ? true
            : false
          : rewardsType === RewardType.CASH_PRIZE
          ? !modifiedStatus.details || !modifiedStatus.actions || !modifiedStatus.locations || !modifiedStatus.emailTemplate || !modifiedStatus.payment
            ? true
            : false
          : !modifiedStatus.details || !modifiedStatus.actions || !modifiedStatus.locations || !modifiedStatus.emailTemplate
          ? true
          : false;
      if (modalType === COMMON_PAGE_TYPE.NEW && isDisableAction) return true;
      else if (modalType === COMMON_PAGE_TYPE.EDIT && (!isRewardsModified || isDisableAction)) return true;
      else return false;
    },
    [recommendedType, rewardsType, modalType, modifiedStatus, isRewardsModified]
  ); // eslint-disable-line

  const handleSaveAsDraft = () => {
    if (id && userOwnership && activeUserId) {
      const postProgramObj = getModifiedProgramSummary(createProgramDataObj, promotionVaultList);
      postProgramObj.programDetails = getSaveAsDraftProgramDetails(
        currentAction,
        programDetails,
        id,
        userOwnership,
        topLevelFranchisorId,
        brandEmail,
        activeUserId,
        activeUserEmail || null,
        RP_PROGRAM_TYPE.DRAFT_PROGRAM,
        RP_USER_ACTION_TYPE.SAVE_AS_DRAFT,
        emailObj
      );
      const reqPayload = {
        data: {
          programDetails: postProgramObj.programDetails,
          programAction: postProgramObj.programAction,
          programHuLocations: postProgramObj.programHuLocations
        },
        action: currentAction === RP_ACTIONS_TYPE.CREATE ? RP_ACTIONS_TYPE.CREATE : RP_ACTIONS_TYPE.EDIT
      };
      dispatch(rpCreateOrUpdateProgramRequest(reqPayload));
      routePush();
    }
  };

  const handleSaveProgram = () => {
    if (id && userOwnership && activeUserId) {
      const type =
        paymentRequestObj?.payment.cardId || paymentRequestObj?.payment.token
          ? RP_USER_ACTION_TYPE.PAYMENT
          : programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM
          ? RP_PROGRAM_TYPE.RECCURING_PROGRAM
          : programDetails?.rewardsType === RewardType.NON_CASH_PRIZE
          ? RP_PROGRAM_TYPE.NON_CASH_PROGRAM
          : RP_PROGRAM_TYPE.DRAFT_PROGRAM;
      const actionType = paymentRequestObj?.payment.cardId || paymentRequestObj?.payment.token ? RP_USER_ACTION_TYPE.MAKE_PAYMENT : RP_USER_ACTION_TYPE.SAVE;
      const programDetailData = getSaveAsDraftProgramDetails(
        currentAction,
        programDetails,
        id,
        userOwnership,
        topLevelFranchisorId,
        brandEmail,
        activeUserId,
        activeUserEmail || null,
        type,
        actionType,
        emailObj
      );
      const programSummary = getModifiedProgramSummary(createProgramDataObj, promotionVaultList, paymentRequestObj);
      const postProgramObj = {
        data: {
          programDetails: programDetailData,
          programAction: programSummary.programAction,
          programHuLocations: programSummary.programHuLocations,
          ...(programSummary.paymentRequestObj && { paymentRequestObj: programSummary.paymentRequestObj })
        },
        action: currentAction === RP_ACTIONS_TYPE.CREATE ? RP_ACTIONS_TYPE.CREATE : RP_ACTIONS_TYPE.EDIT
      };
      dispatch(rpCreateOrUpdateProgramRequest(postProgramObj));
      routePush();
    }
  };

  const handleDeleteProgram = (program: null | rpApiTypes.IRPProgramDetails) => {
    if (program?.programDetails?.programId) {
      setDeleteAction({
        showModal: true,
        deleteId: program.programDetails.programId,
        recurringPaymentSubscriptionId: program?.programDetails?.recurringPaymentSubscriptionId || null
      });
    }
  };

  const handleCloseProgramModal = () => {
    if (isRewardsModified) dispatch(rpRewardsModified(false));
    if (isResendEmailModified) dispatch(rpSetResendEmailModified(false));
    dispatch(rpCreateOrUpdateProgramReset(RP_PROGRAM_RESET_TYPES.SUBMIT));
    dispatch(stripeCardsListReset());
    routePush();
  };

  const routePush = (path?: string) => {
    if (currentRoute === 'employee_advocacy/reward_programs') {
      navigate({
        pathname: !path ? `/${userOwnership}/${id.toString()}/${currentRoute}` : `/${userOwnership}/${id.toString()}/${currentRoute}/${path}`,
        search: `?${new URLSearchParams(queryParams).toString()}`
      });
    }
  };

  const handleProgramSaveChanges = () => {
    const programStatus = rpSaveChangesStatus(isRewardsModified, createProgramDataObj);
    if (programDetails?.programId && programStatus) {
      setIsShowChangesModal(programStatus);
    } else {
      handleCloseProgramModal();
    }
  };

  return (
    <>
      <Modal
        show={(currentAction && modalType && [COMMON_PAGE_TYPE.NEW, COMMON_PAGE_TYPE.EDIT, COMMON_PAGE_TYPE.DETAIL_VIEW, COMMON_PAGE_TYPE.ACHIEVERS].includes(modalType)) || false}
        className="connection-secondary add-location ralprofile-hl add-booster cal-soicalpreview-secion-wrp-modal rewards-create-program-main-section daModal"
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="sec-conn__wrp">
            <div className="sec-header">
              <div className="rewards-details-tab-section-wrp">
                <div className="header-item__wrp">
                  <div className="item-01">
                    <span className="title">{currentAction === RP_ACTIONS_TYPE.CREATE ? RP_MODAL_TITLE.CREATE_PROGRAM : RP_MODAL_TITLE.EDIT_PROGRAM}</span>
                  </div>

                  {modalType && [COMMON_PAGE_TYPE.DETAIL_VIEW, COMMON_PAGE_TYPE.ACHIEVERS].includes(modalType) && (
                    <div className="item-02">
                      <ul>
                        <li
                          className={`ripple${modalType === COMMON_PAGE_TYPE.DETAIL_VIEW ? ` active` : ``}`}
                          onClick={() => {
                            routePush(`${programId}/${COMMON_PAGE_TYPE.DETAIL_VIEW}`);
                          }}
                        >
                          <span className="sub-nav-item-txt">Details</span>
                        </li>

                        <li
                          className={`ripple${modalType === COMMON_PAGE_TYPE.ACHIEVERS ? ` active` : ``}`}
                          onClick={() => {
                            routePush(`${programId}/${COMMON_PAGE_TYPE.ACHIEVERS}`);
                          }}
                        >
                          <span className="sub-nav-item-txt">Achievers</span>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div className="item-03">
                    <ImageValidation isImgValid defaultImg="close" customName="close" customClassname="close" onClick={() => handleProgramSaveChanges()} />
                  </div>
                </div>
              </div>
            </div>
            {!isDetailFetching ? (
              <div className="sec-main__content hubuser-createpost-new-section">
                {[COMMON_PAGE_TYPE.EDIT, COMMON_PAGE_TYPE.DETAIL_VIEW].includes(modalType) && !programDetails?.programId ? (
                  <NoDataFound size={NoDataFoundProps.FULL} />
                ) : (
                  <div className="rewards-programdetails-modal-wrp">
                    {modalType && [COMMON_PAGE_TYPE.NEW, COMMON_PAGE_TYPE.EDIT, COMMON_PAGE_TYPE.DETAIL_VIEW].includes(modalType) ? (
                      <Card>
                        <Card.Header className="header-section-main-wrp" />
                        <Card.Body className={modalType === COMMON_PAGE_TYPE.DETAIL_VIEW ? `cursor-none` : ``}>
                          <div className="list-expanded-asset-and-author-details">
                            <div className="list-expanded-asset-submitted-details">
                              <div className="list-user-pic list-expanded-author-pic">
                                <div className="fu-icon">
                                  <div className="avathar-icon">
                                    <ImageValidation imgUrl={activeUserProfilePhotoUrl || ''} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'program-avatar'} />
                                  </div>
                                </div>
                              </div>
                              <div className="list-expanded-asset-text-details">
                                <div className="list-expanded-asset-text-top">
                                  <span className="list-expanded-asset-author-name">
                                    {currentAction === RP_ACTIONS_TYPE.CREATE
                                      ? `${capitalizeName(activeUserFirstName || '', activeUserLastName || '')}${HEADER_TITLE_SPLITTER}${currentAccountName}`
                                      : `${capitalizeName(programDetailObj?.programDetails?.userName || '')}${HEADER_TITLE_SPLITTER}${
                                          programDetailObj?.programDetails?.franchisorName || programDetailObj?.programDetails?.accountName
                                        }`}
                                  </span>
                                </div>
                                <div className="list-expanded-asset-text-base">
                                  {programDetailObj?.programDetails?.address && <span className="list-expanded-asset-prod-id">{programDetailObj?.programDetails?.address}</span>}
                                  <span className="list-expanded-asset-date-time">
                                    {currentAction === RP_ACTIONS_TYPE.CREATE
                                      ? `${dateFormatByRequestedFormat(getCurrentDate(), RP_DATE_FORMAT)} - ${dateFormatByRequestedFormat(getCurrentDate(), TIME_ONLY_FOR_EACH_12_HOURS)}`
                                      : `${dateFormatByRequestedFormat(programDetailObj?.programDetails.createdAt, RP_DATE_FORMAT)} - ${dateFormatByRequestedFormat(
                                          programDetailObj?.programDetails.createdAt,
                                          TIME_ONLY_FOR_EACH_12_HOURS
                                        )}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ProgramDetails modalType={modalType} />

                          <ProgramActions />

                          <ProgramLocations />

                          <EmailTemplate modalType={modalType} />

                          {rewardsType === RewardType.CASH_PRIZE && <ProgramPayment />}
                        </Card.Body>
                      </Card>
                    ) : (
                      <AchieversDetailView />
                    )}
                  </div>
                )}

                <div className="right-button-section">
                  {modalType && [COMMON_PAGE_TYPE.DETAIL_VIEW, COMMON_PAGE_TYPE.ACHIEVERS].includes(modalType) ? (
                    <CustomRippleButton rippleClass={`ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                      <button
                        className={`ac-btn ac-danger ac-block${programDetails?.programId ? '' : ` pointer-events-none`}`}
                        disabled={!programDetails?.programId}
                        onClick={() => handleDeleteProgram(programDetailObj)}
                      >
                        Delete
                      </button>
                    </CustomRippleButton>
                  ) : (
                    <>
                      {(currentAction === RP_ACTIONS_TYPE.CREATE || (currentAction === RP_ACTIONS_TYPE.EDIT && programDetails?.stage === Stage.DRAFT)) && (
                        <CustomRippleButton
                          rippleClass={`ac-secondary-box edit-ripple__wrp ${disableActionTab(RP_DISABLE_TYPE.DRAFT) ? ` events-none pointer-events-none` : ``}`}
                          custColor={RIPPLE_COLOR.whiteGrey}
                        >
                          <button className={`ac-btn ac-secondary-white ac-outline ac-block border-0 ${disableActionTab(RP_DISABLE_TYPE.DRAFT) ? `disabled` : ``}`} onClick={() => handleSaveAsDraft()}>
                            {currentAction === RP_ACTIONS_TYPE.CREATE ? 'Save as Draft' : 'Update Draft'}
                          </button>
                        </CustomRippleButton>
                      )}
                      <CustomRippleButton rippleClass={`ac-primary-box r-mt2 ${disableActionTab(RP_DISABLE_TYPE.SAVE) ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary ac-block ${disableActionTab(RP_DISABLE_TYPE.SAVE) ? `disabled` : ``}`} type="submit" onClick={() => handleSaveProgram()}>
                          {currentAction === RP_ACTIONS_TYPE.CREATE ? 'Save Program' : 'Update Program'}
                        </button>
                      </CustomRippleButton>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <ModalPopup
        isModalShow={deleteAction.showModal}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <div>
            <div className="modal-message-wraps">{RP_PROGRAM_DELETE_CONFIRM}</div>
          </div>
        )}
        modalCloseButton
        handleModalClose={() => {
          setDeleteAction({
            showModal: false,
            deleteId: 0,
            recurringPaymentSubscriptionId: null
          });
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button
              className="modal-btn-action-itm modal-cancel-btn"
              onClick={() => {
                setDeleteAction(RP_DELETE_ACTION_OBJ);
              }}
            >
              Cancel
            </button>
            <button
              className="modal-btn-action-itm modal-delete-btn"
              onClick={() => {
                if (deleteAction.deleteId) {
                  dispatch(rpDeleteProgramRequest(deleteAction));
                  setDeleteAction({
                    showModal: false,
                    deleteId: 0,
                    recurringPaymentSubscriptionId: null
                  });
                  handleCloseProgramModal();
                }
              }}
            >
              Delete
            </button>
          </div>
        )}
      />
      <ProgramSaveChangesModal isModalShow={isShowChangesModal} handleModalClose={() => setIsShowChangesModal(false)} />
    </>
  );
};
