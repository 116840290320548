import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { dateFormatByRequestedFormat, downloadFormatHandlerRP, getCurrentDate } from 'utils/helpers';
import { useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import {
  ALERT_BOX_TYPES,
  API_TYPES,
  BUTTON_PROPS,
  EXPORT_DOWNLOAD_STARTED_MSG,
  RP_FILTERS_ARRAY,
  USER_OWNERSHIP,
  UTC_TIMESTAMP_FORMAT,
  RpProgram,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ
} from 'utils/constants';
import { CommonFilter, alertBoxCall } from 'components';
import { apiExportCsvhandler, handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const RewardsProgramsFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useSelector((state: IStore) => state.accountSwitcher);
  const initialFilterValue = { program: 'all', text: '' };
  const { queryParams } = useParamsDeconstructor(initialFilterValue);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const rpListData = useSelector((state: IStore) => state.rewardPrograms.rpListData);

  useParamsValidator(
    { ...CommonValidQueryParams, PROGRAM: 'program', SEARCH: 'text' },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      program: RP_FILTERS_ARRAY.map((it) => it.value)
    }
  );

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (isClear) {
      if (queryParams?.text) queryParamsObj.text = queryParams?.text || '';
    }
    if (data.program === RpProgram.ALL) {
      delete queryParamsObj.program;
    } else if (data.program) {
      queryParamsObj.program = data.program;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleExportRPListFile = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    if (id && userOwnership) {
      const params = {
        ...(userOwnership === USER_OWNERSHIP.FRANCHISOR && { franchisorId: id }),
        ...(userOwnership === USER_OWNERSHIP.ACCOUNT && { accountId: id }),
        ...(queryParams?.text && { searchText: queryParams?.text }),
        ...(queryParams.program === RpProgram.RECOMMENDED_PROGRAM && { recommendedProgram: 1 }),
        ...(queryParams.program === RpProgram.REWARD_PROGRAM && { rewardProgram: 1 }),
        isDownlaodCSV: true
      };
      const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
      apiExportCsvhandler('reward_programs', params, `Rewards_program_${currentUnixTimestamp}`, 'programDetails', currentName, id, userOwnership, API_TYPES.NODE, downloadFormatHandlerRP);
    }
  };

  return (
    <CommonFilter
      filter={{ program: queryParams?.program || 'all', text: queryParams?.text || '' }}
      handleChange={handleChange}
      handleExportCsv={handleExportRPListFile}
      isDateRangeFilterEnable={false}
      checkBoxFilter={[
        {
          title: 'Program',
          filter: RP_FILTERS_ARRAY,
          value: 'program'
        }
      ]}
      sectionVariant="rewards-filter"
      primaryButtons={[
        {
          ...BUTTON_PROPS.SECONDARY_EXPORT_CSV,
          isDisable: !rpListData.length
        },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: !queryParams?.program
        }
      ]}
    />
  );
};
