import { Reducer } from 'redux';

import produce from 'immer';
import { CustomerActions } from 'revv/actions';
import { ICustomers } from 'revv/types';
import * as actionTypes from 'revv/actions/action-types';

const initialState: ICustomers = {
  isFetching: true,
  isMetricsFetching: true,
  metricsResponse: null,
  customersList: null,
  currentPage: 1,
  error: '',
  successMsg: '',
  customersUpdateResponse: '',
  customerDetailResponse: null
};

export const customerReducer: Reducer<ICustomers, CustomerActions> = (prevState: ICustomers = initialState, action: CustomerActions) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_METRICS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = true;
      });
    case actionTypes.CUSTOMER_METRICS_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.CUSTOMER_METRICS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.metricsResponse = action.payload;
      });
    case actionTypes.CUSTOMER_LIST_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.currentPage = action.payload.pageNumber || prevState.currentPage;
      });
    case actionTypes.CUSTOMER_LIST_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.CUSTOMER_LIST_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        if (prevState.currentPage > 1) {
          if (prevState.customersList && prevState.customersList?.length > 0) {
            nextState.customersList = prevState.customersList.concat(action.payload || []);
          }
        } else {
          nextState.customersList = action.payload;
        }
      });
    case actionTypes.CUSTOMER_CREATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
      });
    case actionTypes.CUSTOMER_CREATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_CREATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
      });
    case actionTypes.CUSTOMER_DETAIL_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
      });
    case actionTypes.CUSTOMER_DETAIL_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_DETAIL_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.customerDetailResponse = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_DELETE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
      });
    case actionTypes.CUSTOMER_DELETE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_DELETE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_DETAILS_UPDATE_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
      });
    case actionTypes.CUSTOMER_DETAILS_UPDATE_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_DETAILS_UPDATE_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = '';
        nextState.customersUpdateResponse = action.payload;
      });
    case actionTypes.CUSTOMER_UPLOAD_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.CUSTOMER_UPLOAD_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.CUSTOMER_UPLOAD_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.successMsg = action.payload;
      });
    case actionTypes.CUSTOMER_RESET_UPLOAD_SUCCESS_MESSAGE:
      return produce(prevState, (nextState) => {
        nextState.successMsg = initialState.successMsg;
      });
    case actionTypes.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'customers') {
          nextState.isFetching = initialState.isFetching;
          nextState.isMetricsFetching = initialState.isMetricsFetching;
          nextState.metricsResponse = initialState.metricsResponse;
          nextState.error = initialState.error;
          nextState.customersList = initialState.customersList;
          nextState.customerDetailResponse = initialState.customerDetailResponse;
          nextState.currentPage = initialState.currentPage;
          nextState.successMsg = initialState.successMsg;
        }
      });
    default:
      return prevState;
  }
};
