// GET COUPONS LIST REQUEST
export const GET_COUPONS_LIST_REQUEST = 'coupons/GET_COUPONS_LIST_REQUEST';
export const GET_COUPONS_LIST_RESPONSE = 'coupons/GET_COUPONS_LIST_RESPONSE';
export const GET_COUPONS_LIST_FAILURE = 'coupons/GET_COUPONS_LIST_FAILURE';

// ADD OR EDIT COUPON REQUEST
export const ADD_OR_EDIT_COUPON_REQUEST = 'coupons/ADD_OR_EDIT_COUPON_REQUEST';
export const ADD_OR_EDIT_COUPON_RESPONSE = 'coupons/ADD_OR_EDIT_COUPON_RESPONSE';
export const ADD_OR_EDIT_COUPON_FAILURE = 'coupons/ADD_OR_EDIT_COUPON_FAILURE';

// GET COUPONS DETAIL VIEW REQUEST
export const GET_COUPON_DETAIL_VIEW_REQUEST = 'coupons/GET_COUPON_DETAIL_VIEW_REQUEST';
export const GET_COUPON_DETAIL_VIEW_RESPONSE = 'coupons/GET_COUPON_DETAIL_VIEW_RESPONSE';
export const GET_COUPON_DETAIL_VIEW_FAILURE = 'coupons/GET_COUPON_DETAIL_VIEW_FAILURE';

// DELETE COUPONS
export const DELETE_COUPON_REQUEST = 'coupons/DELETE_COUPON_REQUEST';
export const DELETE_COUPON_RESPONSE = 'coupons/DELETE_COUPON_RESPONSE';
export const DELETE_COUPON_FAILURE = 'coupons/DELETE_COUPON_FAILURE';

// COUPON - UPLOAD CLOUDINARY MEDIA
export const CP_CLOUD_MEDIA_UPLOAD_REQUEST = 'coupons/CP_CLOUD_MEDIA_UPLOAD_REQUEST';
export const CP_CLOUD_MEDIA_UPLOAD_RESPONSE = 'coupons/CP_CLOUD_MEDIA_UPLOAD_RESPONSE';
export const CP_CLOUD_MEDIA_UPLOAD_FAILURE = 'coupons/CP_CLOUD_MEDIA_UPLOAD_FAILURE';

// COUPONS SET NEW COUPON OBJ
export const COUPONS_SET_NEW_COUPON_OBJ = 'coupons/COUPONS_SET_NEW_COUPON_OBJ';

// SET COUPONS UPDATE OBJECT
export const COUPONS_SET_UPDATE_OBJ = 'coupons/COUPONS_SET_UPDATE_OBJ';

// COUPONS RESET
export const COUPONS_DETAIL_DATA_RESET = 'coupons/COUPONS_DETAIL_DATA_RESET';
export const COUPONS_MESSAGES_RESET = 'coupons/COUPONS_MESSAGES_RESET';
export const COUPONS_RESET_ALL = 'coupons/COUPONS_RESET_ALL';
