import { useMemo, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';

import { settingsSocialProfilContainerTypes, settingsSocialProfileReducerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const FacebookViewPagesModalBody = ({ pagesDetailData, handleFbPageSelect, isShowSearch = true, isShowError = true }: settingsSocialProfilContainerTypes.IFBPageDetailPopupProps) => {
  const { optionalParams } = useAccountSwitcherData();
  const [search, setSearch] = useState('');

  const isInstaModal = optionalParams[0] === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM;

  const filteredPageDetails = useMemo(() => {
    if (search) {
      return pagesDetailData.filter((it) => (isInstaModal ? it?.username || '' : it.name).toLowerCase().indexOf(search.toLowerCase()) > -1);
    }
    return pagesDetailData;
  }, [isInstaModal, pagesDetailData, search]);

  return (
    <>
      {isShowSearch && (
        <div className="search-tk">
          {/* <FormControl placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} className="s-fb-search" /> */}
          <FormControl placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      )}
      {filteredPageDetails && filteredPageDetails?.length > 0
        ? filteredPageDetails?.map((dataItem: settingsSocialProfileReducerTypes.IFacebookPages, idx: number) => {
            const fullName = isInstaModal ? dataItem?.username : dataItem.name;
            const imageTag =
              isInstaModal && dataItem?.profile_picture_url ? (
                <ImageValidation imgUrl={dataItem?.profile_picture_url} customName={fullName} customClassname={'page-thumbnail'} />
              ) : dataItem?.image_url ? (
                <ImageValidation imgUrl={dataItem.image_url} customName={fullName} customClassname={'page-thumbnail'} />
              ) : (
                ''
              );
            return (
              <div key={`settings-fb-page-${idx}`} className={`aps-item`}>
                <div className="aps-content content-wrp">
                  <div className="r-flx r-flx-ac">
                    <div className="aps-left">{imageTag}</div>
                    <div className="aps-right r-ml2">
                      <div className="apsr-sn-details">
                        <label>
                          <input type="radio" className="radio-checkbox" value={dataItem.id} id={dataItem.id} name="social_medium[facebook_page_credential_id]" defaultChecked={idx === 0} />
                          <label htmlFor={dataItem.id} />
                        </label>
                        <div className="nac-cnt">
                          <div className="nac-txt">{fullName}</div>
                          {/* <a href={pageURL} rel='noopener noreferrer' target='_blank' className='nac-link'>{fullName}</a> */}
                          {/* {(dataItem.location !== null && dataItem.location !== undefined) ?
                      <div className='scz-item'>
                        <span className='asc-item'>{dataItem.location.street} {dataItem.location.city}</span>
                        <span className='acp-item'>{dataItem.location.country} {dataItem.location.zip}</span>
                      </div>
                      : null
                    } */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button className="primeblueaction ac-btn" onClick={() => handleFbPageSelect(dataItem, isInstaModal)}>
                    Connect Now
                  </Button>
                </div>
              </div>
            );
          })
        : isShowError && <span className="not__found--txt">{isShowSearch ? 'Pages not found' : 'Page not found'}</span>}
    </>
  );
};
