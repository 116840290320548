import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { API, apiCall, cloudinaryHeader, CLOUDINARY_API, defaultHeader } from 'utils/helpers';
import {
  exceptionHandlerRequest,
  getDLCategoriesFailure,
  getDLCategoriesResponse,
  logoutRequest,
  getDLSynupListingResponse,
  getDLSynupListingFailure,
  updateDLResponse,
  updateDLFailure,
  dlAddBusinessPhotoInCloudResponse,
  dlAddBusinessPhotoInCloudFailure
} from 'actions';
import { commonApiTypes } from 'types';
import { DIRECTORY_LISTINGS_ERROR, DL_CATEGORIES_FAILED, DL_SYNUP_LISTING_FAILED, DL_UPDATE_FAILED, API_TYPES, CLOUD_IMG_UPLOAD_FAILED, DlmCategoryType, ALERT_BOX_TYPES } from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendDLSynupSubCategoriesRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let response;
    if (payload === DlmCategoryType.BUSINESS_PROFILE_CATEGORY) {
      response = yield apiCall({ headers, ...API.getDLBusinessProfileCategories });
    } else {
      response = yield apiCall({ headers, ...API.getDLSynupSubCategories });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getDLCategoriesResponse(payload === DlmCategoryType.BUSINESS_PROFILE_CATEGORY ? response?.data?.business_profile_categories || [] : response?.data?.data?.subcategories || []));
    } else {
      yield put(getDLCategoriesFailure(DL_CATEGORIES_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(DIRECTORY_LISTINGS_ERROR));
  }
}

function* sendDLSynupListingRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      account: payload.id
    };
    const response = yield apiCall({ headers, params, ...API.getDLSynupListing });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getDLSynupListingResponse(response.data.accounts));
    } else {
      yield put(getDLSynupListingFailure(DL_SYNUP_LISTING_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(DIRECTORY_LISTINGS_ERROR));
  }
}

function* sendDLUpdateRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      account: payload.editedDL
    };
    const path = API.updateDL.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.updateDL.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateDLResponse(response.data.account));
    } else {
      yield put(updateDLFailure(DL_UPDATE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(DIRECTORY_LISTINGS_ERROR));
  }
}

function* sendDLAddBusinessPhotoInCloudRequest({ payload }: ISagaPayload): any {
  const { file, signature, apiKey, timestamp, eager, imageKey } = payload;

  try {
    const headers = cloudinaryHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('api_key', apiKey);
    bodyFormData.append('file', file);
    bodyFormData.append('signature', signature);
    bodyFormData.append('timestamp', timestamp);
    bodyFormData.append('eager', eager);

    const response = yield apiCall({
      headers,
      data: bodyFormData,
      ...CLOUDINARY_API.cloudinaryImageUpload,
      type: API_TYPES.CLOUDINARY
    });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        dlAddBusinessPhotoInCloudResponse({
          imageKey,
          secureUrl: response.data.secure_url,
          cloudinaryId: response.data.public_id
        })
      );
    } else {
      if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
      else alertBoxCall(ALERT_BOX_TYPES.ERROR, CLOUD_IMG_UPLOAD_FAILED);
      yield put(dlAddBusinessPhotoInCloudFailure(null));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(DIRECTORY_LISTINGS_ERROR));
  }
}

export function* takeDirectoryListingsRequest() {
  yield takeEvery(types.GET_DL_CATEGORIES_REQUEST, sendDLSynupSubCategoriesRequest);
  yield takeEvery(types.DL_SYNUP_LISTING_REQUEST, sendDLSynupListingRequest);
  yield takeEvery(types.DL_UPDATE_REQUEST, sendDLUpdateRequest);
  yield takeEvery(types.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_REQUEST, sendDLAddBusinessPhotoInCloudRequest);
}
