import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { socialContentsPieReloadRequest, socialContentsIsStatsCall } from 'actions';
import { IStore } from 'types';
import { IFiterHandleChangeData } from 'types/common/filter';
import { useActivePlatform, useNavBarData, useParamsDeconstructor, useAccountSwitcherData, useParamsValidator } from 'utils/hooks';
import { scCheckBoxFilter, scEngagementFilterData, weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import {
  BUTTON_PROPS,
  COMMON_SOCIAL_PROVIDER_TYPE,
  SIDE_NAV_NAME,
  USER_OWNERSHIP,
  SC_POSTS_STATUS_FILTER,
  SC_FILTER_INIT_VALUE,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  CommonValidQueryParams,
  SCValidQueryParams,
  SCLocationValidQueryParams,
  SCOutboxValidQueryParams,
  SCSandboxValidQueryParams,
  FeedValue
} from 'utils/constants';
import { handleFilterChange } from 'analytics/utils/helpers';
import { PLATFORM_FILTER } from 'analytics/utils/constants';
import { CommonFilter } from 'components';

export const SCFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subNavPageName } = useNavBarData();
  const activePlatforms = useActivePlatform();
  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const brandActivePlatforms = useSelector((state: IStore) => state.socialContents.brandActivePlatforms);

  const dateRange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);
  const initialFilterValue = { ...SC_FILTER_INIT_VALUE, dateRange };

  const { filter, queryParams } = useParamsDeconstructor(initialFilterValue);

  const scFilter = {
    ...filter,
    ...(subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX && { posts: queryParams?.posts || COMMON_SOCIAL_PROVIDER_TYPE.ALL }),
    ...(subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX && { engagement: queryParams?.engagement || COMMON_SOCIAL_PROVIDER_TYPE.ALL })
  };

  const selectedPlatforms = useMemo(() => {
    switch (subNavPageName) {
      case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
        return brandActivePlatforms.filter((it) => it !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && it !== COMMON_SOCIAL_PROVIDER_TYPE.YELP);
      case SIDE_NAV_NAME.REPUTATION_OUTBOX:
        return activePlatforms.filter((it) => it !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && it !== COMMON_SOCIAL_PROVIDER_TYPE.YELP);
      default:
        return [];
    }
  }, [activePlatforms, brandActivePlatforms, subNavPageName]);

  const engagementOptions = scEngagementFilterData(queryParams.platform);

  const platformFilter = PLATFORM_FILTER.filter((it) => (selectedPlatforms.length > 1 && it.value === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? true : selectedPlatforms.includes(it.value)));

  const isClearFilterDisabled = selectedPlatforms.length === 1 ? Boolean(Object.keys(queryParams)?.length === 1 && queryParams?.platform) : !Object.keys(queryParams)?.length;

  const isReloadEnabled = userOwnership === USER_OWNERSHIP.ACCOUNT && subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX && isAdmin;

  useParamsValidator(
    {
      ...CommonValidQueryParams,
      ...SCValidQueryParams,
      ...(userOwnership === USER_OWNERSHIP.FRANCHISOR && SCLocationValidQueryParams),
      ...(subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX && SCSandboxValidQueryParams),
      ...(subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX && SCOutboxValidQueryParams)
    },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      platform: selectedPlatforms,
      ...(subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX && { posts: SC_POSTS_STATUS_FILTER.map((it) => it.value) }),
      ...(subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX && { engagement: engagementOptions.map((it) => it.value) })
    }
  );

  useEffect(() => {
    if (id && userOwnership && isValidId && selectedPlatforms.length === 1 && !queryParams?.platform) {
      const params = { platform: selectedPlatforms[0] || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, selectedPlatforms, queryParams?.platform, id, userOwnership, isValidId]); // eslint-disable-line

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (id && data.reload) {
      dispatch(socialContentsPieReloadRequest({ id, force: true }));
    } else {
      const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
      const isLocationFilter = ['locationId', 'locationListId', 'ancestorFranchisorId'].some((it) => Object.keys(data).includes(it));
      let isLocationStatsCall: boolean = false;
      if (subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX) {
        if (data.posts === COMMON_SOCIAL_PROVIDER_TYPE.ALL) {
          delete queryParamsObj.posts;
        } else if (data.posts) {
          queryParamsObj.posts = data.posts;
        }
      } else if (subNavPageName === SIDE_NAV_NAME.REPUTATION_OUTBOX) {
        isLocationStatsCall = isLocationFilter;
        if ((data.engagement && data.engagement === COMMON_SOCIAL_PROVIDER_TYPE.ALL) || data.platform) {
          delete queryParamsObj.engagement;
        } else if (data.engagement) {
          queryParamsObj.engagement = data.engagement;
        }
      }
      if (isClear || data.dateRange?.fromDate.selected || data.dateRange?.toDate.selected || isLocationStatsCall) {
        dispatch(socialContentsIsStatsCall(true));
      }
      navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
    }
  };

  return (
    <CommonFilter
      filter={scFilter}
      handleChange={handleChange}
      analyticsPlatformFilter={platformFilter}
      checkBoxFilter={scCheckBoxFilter(subNavPageName, queryParams.platform)}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled
        },
        ...(isReloadEnabled ? [{ ...BUTTON_PROPS.RELOAD_BUTTON }] : [])
      ]}
      sectionVariant={`${subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX ? 'sandbox' : 'inbox'}-filter`}
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
};
