import { profileImageryReducerTypes } from 'types';

export const PI_FILTER_INITIAL_VALUE: profileImageryReducerTypes.IProfileImgFiltersVal = {
  platform: 'facebook',
  locationFilterObj: {
    id: 0,
    label: 'All Locations',
    type: 'All',
    value: 'All Locations'
  },
  ancestorFranchisorId: 0,
  locationId: 0,
  locationListId: 0
};

export const SELECTED_ASSET_INITIAL_VAL: profileImageryReducerTypes.ISelectedAsset = {
  social_medium_ids: [],
  type: null
};

export const UPDATE_PI_INITAIL_DATA = {
  isUpdated: false,
  updateSuccessMsg: null,
  updateFailureMsg: null
};
