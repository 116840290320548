import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { config } from 'config';
import { IStore } from 'types';
import {
  ALERT_BOX_TYPES,
  EXPORT_DOWNLOAD_STARTED_MSG,
  USER_OWNERSHIP,
  RIPPLE_COLOR,
  CONNECTION_FILTER_INIT_OBJ,
  ConnExportTabName,
  ConnExportTabLabel,
  CommonValidQueryParams,
  ConnectionsValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  CONNECTION_SORT_TYPES,
  DONE_SUCCESS_MSG
} from 'utils/constants';
import { connectionsLocListDownloadFormatter, defaultHeader } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { alertBoxCall } from 'components/Common';
import { CommonFilter } from 'components';
import { handleFilterChange, exportCsvhandler } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const ConnectionsFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  const { queryParams, filter } = useParamsDeconstructor(CONNECTION_FILTER_INIT_OBJ);

  // const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin) || false;
  const connectionAccountList = useSelector((state: IStore) => state.connections.connectionAccountList);

  useParamsValidator(
    { ...CommonValidQueryParams, ...ConnectionsValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      sort_by: Object.values(CONNECTION_SORT_TYPES).slice(1),
      ascending: ['0']
    }
  );

  const handleChange = (data: IFiterHandleChangeData) => {
    if (data.connections_report) {
      handleExportReport(ConnExportTabName.CONNECTIONS_REPORT);
    } else if (data.users_report) {
      handleExportReport(ConnExportTabName.USERS_REPORT);
    } else if (data.location_activity_report) {
      handleExportReport(ConnExportTabName.LOCATION_ACTIVITY_REPORT);
    } else {
      const queryParamsClone = handleFilterChange(data, false, id, queryParams);
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    }
  };

  const handleExportReport = (exportType: string) => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    if (exportType === ConnExportTabName.CONNECTIONS_REPORT) {
      exportCsvhandler(connectionsLocListDownloadFormatter, connectionAccountList, DONE_SUCCESS_MSG, `rallio-hub-${id}-platform-connections-report`);
    } else {
      const reportName = exportType === ConnExportTabName.USERS_REPORT ? 'users_report.csv' : 'location_activity_report.csv';
      const reportDownloadName = exportType === ConnExportTabName.USERS_REPORT ? 'users-report.csv' : 'location-activity-report.csv';
      const reportUrl = `${config.apiBaseURL}${config.apiVersionPath}franchisors/${id}/${reportName}`;
      fetch(reportUrl, {
        headers: {
          Accept: defaultHeader().Accept,
          'Content-Type': defaultHeader()['Content-Type'],
          'X-Rallio-API-Key': defaultHeader()['X-Rallio-API-Key']
        }
      }).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const createdDoc = document.createElement('a');
          createdDoc.href = url;
          createdDoc.download = `rallio-hub-${id}-${reportDownloadName}`;
          createdDoc.click();
          createdDoc.remove();
          alertBoxCall(ALERT_BOX_TYPES.DISMISS, null);
          alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
        });
      });
    }
  };

  return (
    <CommonFilter
      filter={{ ...filter }}
      handleChange={handleChange}
      isDateRangeFilterEnable={false}
      primaryButtons={[
        // ...(isAdmin ? [
        {
          rippleColor: RIPPLE_COLOR.whiteBlue,
          value: ConnExportTabName.CONNECTIONS_REPORT,
          label: ConnExportTabLabel.CONNECTIONS_REPORT,
          customImage: 'export-blue',
          buttonVariant: 'ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv',
          isDisable: false,
          parentVariant: 'filter-item connection-report',
          isBorder: false
        },
        // ] : []),
        {
          rippleColor: RIPPLE_COLOR.whiteBlue,
          value: ConnExportTabName.USERS_REPORT,
          label: ConnExportTabLabel.USERS_REPORT,
          customImage: 'export-blue',
          buttonVariant: 'ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv',
          isDisable: false,
          parentVariant: 'filter-item',
          isBorder: false
        },
        {
          rippleColor: RIPPLE_COLOR.whiteBlue,
          value: ConnExportTabName.LOCATION_ACTIVITY_REPORT,
          label: ConnExportTabLabel.LOCATION_ACTIVITY_REPORT,
          customImage: 'export-blue',
          buttonVariant: 'ac-btn ac-primary-white ac-outline ac-icon-btn ac-block csv',
          isDisable: false,
          parentVariant: 'filter-item',
          isBorder: false
        }
      ]}
      sectionVariant="connection"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
};
