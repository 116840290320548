import { Reducer } from 'redux';

import { AiAssistantActions } from 'actions/modules/ai-assistant';
import { aiAssistantReducerTypes } from 'types/ai-assistant';
import * as types from 'actions/action-types';
import { AI_ASSISTANT_ROLE } from 'utils/constants';

type IAIAssistantReducer = aiAssistantReducerTypes.IAIAssistantReducerType;

const initialState: IAIAssistantReducer = {
  error: null,
  isChatHistoryFetching: false,
  chatHistory: [],
  isAIAssistantGenerating: false,
  message: '',
  deleteMessage: ''
};

const aiAssistantReducer: Reducer<IAIAssistantReducer, AiAssistantActions> = (state: IAIAssistantReducer = initialState, action: AiAssistantActions): IAIAssistantReducer => {
  switch (action.type) {
    case types.AI_ASSISTANT_SEND_NEW_CHAT_REQUEST:
      return {
        ...state,
        message: '',
        chatHistory: [...state.chatHistory, { name: AI_ASSISTANT_ROLE.USER, message: action.payload.userContent }]
      };
    case types.AI_ASSISTANT_SEND_NEW_CHAT_RESPONSE:
      return {
        ...state,
        chatHistory: [...state.chatHistory, action.payload],
        isAIAssistantGenerating: false,
        message: 'success'
      };
    case types.AI_ASSISTANT_SEND_NEW_CHAT_FAILURE:
      return {
        ...state,
        isAIAssistantGenerating: false,
        error: action.payload
      };
    case types.AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: true
      };
    case types.AI_ASSISTANT_GET_CHAT_HISTORY_RESPONSE:
      return {
        ...state,
        chatHistory: action.payload,
        isChatHistoryFetching: false
      };
    case types.AI_ASSISTANT_GET_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        chatHistory: [],
        isChatHistoryFetching: false,
        error: action.payload
      };
    case types.AI_ASSISTANT_DELETE_REQUEST:
      return {
        ...state
      };
    case types.AI_ASSISTANT_DELETE_RESPONSE:
      return {
        ...state,
        deleteMessage: action.payload.message
      };
    case types.AI_ASSISTANT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case types.AI_ASSISTANT_RESET:
      return initialState;
    case types.AI_ASSISTANT_GENERATING:
      return {
        ...state,
        isAIAssistantGenerating: true
      };
    default:
      return state;
  }
};

export { aiAssistantReducer };
