import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { config } from 'config';

export const useSSOIdentifiersForAccount = () => {
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails?.account);

  const ssoIdentifiersForAccount = config.ssoIdentifiersForAccount?.split(',');
  const referenceIdsHasOwnProp = ssoIdentifiersForAccount?.some((it: any) => accountDetails?.reference_ids.hasOwnProperty(it));
  const isCSLocation = Boolean(accountDetails?.reference_ids && referenceIdsHasOwnProp) || false;

  return { isCSLocation };
};
