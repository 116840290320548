import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { settingsSocialProfileReducerTypes } from 'types';

// UPDATE SOCIAL PROFILE STATES
export const settingsUpdateYelpDetails = (payload: settingsSocialProfileReducerTypes.IYelpDetailsInitialValue) => createAction(actionTypes.SETTINGS_UPDATE_YELP_DETAILS, payload);

export const updateSocialMediaSetting = (payload: settingsSocialProfileReducerTypes.ISocialMediaDetails) => createAction(actionTypes.UPDATE_SOCIAL_MEDIA_SETTING, payload);

export const updateSocialMediaSettingSuccessOrFailure = (payload: { type: string }) => createAction(actionTypes.UPDATE_SOCIAL_MEDIA_SETTING_SUCCESS_FAILURE, payload);

export const deleteSocialMediaSetting = (payload: settingsSocialProfileReducerTypes.IDeleteParams) => createAction(actionTypes.DELETE_SOCIAL_MEDIA_SETTING, payload);

export const fetchLinkedinPages = (payload: { access_token: string }) => createAction(actionTypes.LINKEDIN_PAGES_REQUEST, payload);

export const linkedInRequestFail = (payload: { error: any }) => createAction(actionTypes.LINKEDIN_PAGES_REQUEST_FAIL, payload);

export const linkedInPagesResponse = (payload: { data: settingsSocialProfileReducerTypes.ILinkedInPages[] }) => createAction(actionTypes.LINKEDIN_PAGES_RESPONSE, payload);

export const saveFbUserCredentials = (payload: settingsSocialProfileReducerTypes.IFacebookCredential) => createAction(actionTypes.SAVE_FACEBOOK_USER_CREDENTIAL, payload);

export const saveFbUserCredentialsResponse = (payload: { id: string }) => createAction(actionTypes.SAVE_FACEBOOK_USER_CREDENTIAL_RESPONSE, payload);

export const saveFbPageCredentials = (payload: settingsSocialProfileReducerTypes.ISaveFbPageCredentials) => createAction(actionTypes.SAVE_FACEBOOK_PAGE_CREDENTIAL, payload);

export const facebookPagesRequest = (payload: { id: string; access_token: string; type: string; refresh?: number }) => createAction(actionTypes.FACEBOOK_PAGES_REQUEST, payload);

export const facebookPagesRequestFail = (payload: { error: any }) => createAction(actionTypes.FACEBOOK_PAGES_REQUEST_FAIL, payload);

export const facebookPagesResponse = (payload: { data: settingsSocialProfileReducerTypes.IFacebookPages[] }) => createAction(actionTypes.FACEBOOK_PAGES_RESPONSE, payload);

export const connectInstagram = (payload: { id: number }) => createAction(actionTypes.CONNECT_INSTAGRAM, payload);

export const connectInstagramSuccess = () => createAction(actionTypes.CONNECT_INSTAGRAM_SUCCESS);

export const disconnectInstagram = (payload: { id: number }) => createAction(actionTypes.DISCONNECT_INSTAGRAM, payload);

export const disconnectInstagramSuccess = () => createAction(actionTypes.DISCONNECT_INSTAGRAM_SUCCESS);

export const saveGoogleOauthCredentialResponse = (payload: { credentialId: string }) => createAction(actionTypes.SAVE_GOOGLE_OAUTH_CREDENTIAL_RESPONSE, payload);

export const loadGoogleBusinessAccountRequest = (payload: { credentialId: string }) => createAction(actionTypes.LOAD_GOOGLE_BUSINESS_ACCOUNT_REQUEST, payload);

export const loadGoogleBusinessAccountResponse = (payload: any) => createAction(actionTypes.LOAD_GOOGLE_BUSINESS_ACCOUNT_RESPONSE, payload);

export const loadGoogleLocationRequest = (payload: settingsSocialProfileReducerTypes.IGoogleLocationRequestPayload) => createAction(actionTypes.LOAD_GOOGLE_LOCATIONS_REQUEST, payload);

export const loadGoogleLocationResponse = (payload: any) => createAction(actionTypes.LOAD_GOOGLE_LOCATIONS_RESPONSE, payload);

export const connectGoogleRequest = (payload: { googlePlacesId: number; googleCredentialId: string; accountId: number }) => createAction(actionTypes.CONNECT_GOOGLE_REQUEST, payload);

export const facebookPageRequestByUrl = (payload: { id: string | null; url: string }) => createAction(actionTypes.FB_PAGE_PROVIDING_URL_REQUEST, payload);

export const fbPageProvidingUrlRequest = () => createAction(actionTypes.FB_PAGE_PROVIDING_URL_REQUEST);
export const fbPageProvidingUrlResponse = (payload: { data: settingsSocialProfileReducerTypes.IFacebookPages[] }) => createAction(actionTypes.FB_PAGE_PROVIDING_URL_RESPONSE, payload);
export const fbPageProvidingUrlFailure = (payload: { error: any }) => createAction(actionTypes.FB_PAGE_PROVIDING_URL_FAILURE, payload);

export const socialProfileResetAll = () => createAction(actionTypes.SOCIAL_PROFILE_RESET_ALL);

const SETTINGS_SOCIAL_PROFILE = {
  settingsUpdateYelpDetails,
  updateSocialMediaSetting,
  deleteSocialMediaSetting,
  fetchLinkedinPages,
  linkedInRequestFail,
  linkedInPagesResponse,
  updateSocialMediaSettingSuccessOrFailure,
  saveFbUserCredentials,
  facebookPagesRequest,
  facebookPagesRequestFail,
  facebookPagesResponse,
  saveFbUserCredentialsResponse,
  saveFbPageCredentials,
  connectInstagram,
  connectInstagramSuccess,
  disconnectInstagram,
  disconnectInstagramSuccess,
  saveGoogleOauthCredentialResponse,
  loadGoogleBusinessAccountRequest,
  loadGoogleBusinessAccountResponse,
  loadGoogleLocationRequest,
  loadGoogleLocationResponse,
  facebookPageRequestByUrl,
  fbPageProvidingUrlRequest,
  fbPageProvidingUrlResponse,
  fbPageProvidingUrlFailure,
  socialProfileResetAll
};

export type SettingsSocialProfileActions = ActionsUnion<typeof SETTINGS_SOCIAL_PROFILE>;
