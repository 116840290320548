import { EventSourcePolyfill } from 'event-source-polyfill';

import { commonProps } from 'types';
import { eventSourcePolyfillHeader } from 'utils/helpers';
import { store } from 'store';
import { config } from 'config';
import { exceptionHandlerRequest } from 'actions';

/** Geocoder - Get query params */
export const createQueryParams = (params: string) =>
  Object.keys(params)
    .map((k: any) => `${encodeURI(params[k])}`)
    .join('&');

export const createQueryParamsUsingKeyValuePair = (params: commonProps.ICommonObj<any>) =>
  Object.entries(params)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((it) => `${key}[]=${it}`).join('&')
        : typeof value === 'object'
        ? Object.entries(value)
            .map(([subKey, subValue]) => `${subKey}=${subValue}`)
            .join('&')
        : `${key}=${value}`
    )
    .join('&');

// Event source polyfill
export const apiEventSourcePolyfillHandler = (
  apiPath: string,
  payload: commonProps.ICommonObj<any>,
  type: string,
  onRequestCallback: () => void,
  onResponseCallback: (data: any) => void,
  exceptionHandlerMsg: string
) => {
  try {
    if (typeof onRequestCallback === 'function') onRequestCallback();

    const headers = eventSourcePolyfillHeader();
    const eventSource = new EventSourcePolyfill(`${config.apiBaseURL}${config.apiVersionPath}${apiPath}?${createQueryParamsUsingKeyValuePair(payload)}`, {
      headers: {
        'X-Rallio-API-Key': headers['X-Rallio-API-Key']
      }
    });

    eventSource.addEventListener(type, (event: any) => {
      onResponseCallback(type === 'scheduled_posts' ? [...JSON.parse(event.data || '').scheduled_posts] : [...JSON.parse(event.data || '')]);
    });

    const closeEvents = () => {
      eventSource.close();
    };

    eventSource.addEventListener('error', () => {
      closeEvents();
      onResponseCallback([]);
    });

    return closeEvents;
  } catch (error) {
    store.dispatch(exceptionHandlerRequest(exceptionHandlerMsg));
    return null;
  }
};
