import { ILeaderBoardAnalyticsValue } from 'analytics/types';
import { TABLE_FORMAT_OBJECT } from 'analytics/utils/constants';

const brandAverageHandler = (numerator: number, denominator: number) => {
  return denominator ? numerator / denominator : numerator;
};

const brandAverageHandlerOne = (valueArr: number[], countArr: number[]) => {
  let numerator = 0;
  let denomenator = 0;
  valueArr.forEach((_1, index: number) => {
    if (valueArr[index] && countArr[index]) {
      numerator += valueArr[index] * countArr[index];
      denomenator += countArr[index];
    }
  });
  return denomenator ? numerator / denomenator : numerator;
};

const valueHandler = (data: { [key: string]: string | number }, platform: string, prefString: string = '', key: string = '') => {
  const platformArr = platform === 'all' ? TABLE_FORMAT_OBJECT.platform : platform === 'google' ? [TABLE_FORMAT_OBJECT.platform[1]] : [platform];
  const obj: any = {};
  if (data) {
    TABLE_FORMAT_OBJECT.labels.forEach((label) => {
      let totalSum = 0;
      let totalSumDelta = 0;
      let isTotalAvailable = false;
      let isTotalDeltaAvailable = false;
      const totalArr: (string | number | null | undefined)[] = [];
      const totalDeltaArr: (string | number | null | undefined)[] = [];

      platformArr.forEach((iterator: string) => {
        totalArr.push(data[`${prefString}${iterator}_${label}`]);
        totalDeltaArr.push(data[`${prefString}${iterator}_${label}_delta`]);
      });

      isTotalAvailable = totalArr.some((it) => it !== null && it !== undefined);
      isTotalDeltaAvailable = totalDeltaArr.some((it) => it !== null && it !== undefined);

      platformArr.forEach((iterator: string) => {
        totalSum += label === 'reputation' && prefString === 'sum_' ? +data[`avg_${iterator}_${label}`] : +data[`${prefString}${iterator}_${label}`] || 0;
        totalSumDelta += label === 'reputation' ? +data[`avg_${label}_delta`] : +data[`${prefString}${iterator}_${label}_delta`] || 0;

        obj[`${label}Total${key}`] = isTotalAvailable ? (label === 'reputation' && platform === 'all' ? data[`avg_${label}`] : totalSum) : null;
        obj[`${label}TotalDelta`] = isTotalDeltaAvailable ? totalSumDelta : null;
      }, {});
    });
  }
  return obj;
};

export const leaderBoardResponseHandler = (data: any, platform: string | undefined, sortLabel: string, sortOrder: string, isAllHub: boolean, isContentSupplier: boolean) => {
  // followers
  const contentData = valueHandler(data?.totals, platform || '', 'sum_');
  const totals = isContentSupplier ? data?.totals : data?.global_totals;

  let postsCountAvgTotal = 0;
  let followerAvgTotal = 0;
  let engagementAvgTotal = 0;
  let profileViewTotal = 0;
  let reviewAvgTotal = 0;
  let reputationrAvgTotal = 0;

  // Follower Avg
  if (platform === 'all') {
    postsCountAvgTotal = brandAverageHandler(
      Number(totals?.sum_facebook_posts_count) + Number(totals?.sum_instagram_posts_count) + Number(totals?.sum_linkedin_posts_count) + Number(totals?.sum_twitter_posts_count),
      +totals?.posts_count_present_count || 0
    );

    followerAvgTotal = brandAverageHandler(
      Number(totals?.sum_facebook_followers) + Number(totals?.sum_instagram_followers) + Number(totals?.sum_linkedin_followers) + Number(totals?.sum_twitter_followers),
      +totals?.followers_present_count || 0
    );
    // Profile view Avg
    profileViewTotal = brandAverageHandler(Number(totals?.sum_facebook_profile_views) + Number(totals?.sum_google_plus_profile_views || 0), +totals?.profile_views_present_count || 0);
    // Engagement Avg
    engagementAvgTotal = brandAverageHandler(
      +totals?.sum_facebook_daily_engagement + +totals?.sum_instagram_daily_engagement + +totals?.sum_linkedin_daily_engagement + +totals?.sum_twitter_daily_engagement,
      +totals?.daily_engagement_present_count || 0
    );
    // Reputation Avg
    // reputationrAvgTotal = brandAverageHandler(
    //   +data?.global_totals.sum_facebook_reputation + +data?.global_totals.sum_google_plus_reputation + +data?.global_totals.sum_yelp_reputation,
    //   +data?.global_totals.reputation_present_count
    // );
    reputationrAvgTotal = brandAverageHandlerOne(
      [+totals?.avg_facebook_reputation || 0, +totals?.avg_google_plus_reputation || 0, +totals?.avg_yelp_reputation || 0],
      [+totals?.facebook_count || 0, +totals?.google_plus_count || 0, +totals?.yelp_count || 0]
    );
    // Review Avg
    reviewAvgTotal = brandAverageHandler(+totals?.sum_facebook_reviews + +totals?.sum_google_plus_reviews || 0 + +totals?.sum_yelp_reviews || 0, +totals?.reviews_present_count);
  } else {
    const avgValue = valueHandler(data?.global_totals, platform || '', 'avg_', 'Avg');
    postsCountAvgTotal = avgValue.posts_countTotalAvg;
    followerAvgTotal = avgValue.followersTotalAvg;
    engagementAvgTotal = avgValue.daily_engagementTotalAvg;
    // profileViewTotal = avgValue.profile_viewsTotalAvg;
    profileViewTotal = contentData?.profile_viewsTotal / +data?.rows.length;
    reviewAvgTotal = avgValue.reviewsTotalAvg;
    reputationrAvgTotal = avgValue.reputationTotalAvg;
  }

  const tableTotal = [
    { label: 'posts_count', value: contentData?.posts_countTotal, delta: contentData?.posts_countTotalDelta, avg: postsCountAvgTotal },
    { label: 'follower', value: contentData?.followersTotal, delta: contentData?.followersTotalDelta, avg: followerAvgTotal },
    { label: 'profile_view', value: contentData?.profile_viewsTotal, delta: contentData?.profile_viewsTotalDelta, avg: profileViewTotal },
    { label: 'engagement', value: contentData?.daily_engagementTotal, delta: contentData?.daily_engagementTotalDelta, avg: engagementAvgTotal },
    { label: 'review', value: contentData?.reviewsTotal, delta: contentData?.reviewsTotalDelta, avg: reviewAvgTotal },
    { label: 'reputation', value: contentData?.reputationTotal, delta: contentData?.reputationTotalDelta, avg: reputationrAvgTotal }
  ];

  const mappingData = isAllHub ? data?.by_hub : data?.rows || data || [];
  const tableData = mappingData?.map((it: { [x: string]: string | number; account_name?: any; franchisor_name?: any; account_id?: any; franchisor_id?: any }) => {
    const tableDataObj = valueHandler(it, platform || '');

    return {
      title: it.account_name && it.franchisor_name ? `${it.franchisor_name} - ${it.account_name}` : it.account_name ? it.account_name : it.franchisor_name,
      accountName: it.account_name,
      franchisorName: it.franchisor_name,
      accountId: it.account_id,
      franchisorId: it.franchisor_id,
      follower: tableDataObj.followersTotal,
      followerDelta: tableDataObj.followersTotalDelta,
      engagement: tableDataObj.daily_engagementTotal,
      engagementDelta: tableDataObj.daily_engagementTotalDelta,
      profile_view: tableDataObj.profile_viewsTotal,
      profileViewDelta: tableDataObj.profile_viewsTotalDelta,
      review: tableDataObj.reviewsTotal,
      reviewDelta: tableDataObj.reviewsTotalDelta,
      reputation: tableDataObj.reputationTotal,
      reputationDelta: tableDataObj.reputationTotalDelta,
      posts_count: tableDataObj.posts_countTotal,
      posts_countDelta: tableDataObj.posts_countTotalDelta
    };
  });

  const label = sortOrder === 'H2L' || sortOrder === 'L2H' ? sortLabel : `${sortLabel === 'profile_view' ? 'profileView' : sortLabel}Delta`;
  const isHighToLow = sortOrder === 'H2L' || sortOrder === 'DH2L';
  const validTableData = tableData.filter((data: any) => data[label] !== null);

  const notapplicableTableData = tableData.filter((data: any) => data[label] === null);
  const sortedTableData: any[] = validTableData.sort((a: { [key: string]: string | number }, b: { [key: string]: string | number }) => {
    if (a[label] === b[label] || b[label] === null) {
      return isHighToLow ? -1 : 1;
    } else if (b[label] === null) {
      return isHighToLow ? -1 : 1;
    } else if (a[label] === null) {
      return isHighToLow ? 1 : -1;
    } else if (sortOrder === 'H2L' || sortOrder === 'DH2L') {
      return a[label] > b[label] ? -1 : 1;
    } else {
      if (label === 'title') {
        return a[label].toString().toLowerCase() > b[label].toString().toLowerCase() ? 1 : -1;
      }
      return a[label] > b[label] ? 1 : -1;
    }
  });
  return { hubs: data?.by_hub, total: tableTotal, values: sortedTableData.concat(notapplicableTableData) };
};

export const leaderBoardReviewCategoryResponseHandler = (data: any, platform: string | undefined, sortLabel: string, sortOrder: string, isAllHub: boolean, reviewCategoryId: number[]) => {
  const mappingData = data;
  const totalTable = reviewCategoryId.map((_, index) => {
    const reviewKeywordCategory = `review_keyword_category_${index + 1}`;
    return mappingData.reduce(
      (acc: { label: string; value: number; delta: string }, curr: any) => {
        if (!acc.label) {
          acc.label = curr[`${reviewKeywordCategory}_name`];
        }
        acc.value += curr[`${reviewKeywordCategory}_score`];
        acc.delta += curr[`${reviewKeywordCategory}_score_delta`];
        return acc;
      },
      { label: '', value: 0, delta: 0 }
    );
  });

  const tableData = mappingData?.map((it: any, index: number) => {
    const updatedData: any = {
      accountName: it.account_name,
      accountId: it.account_id
    };
    reviewCategoryId.forEach((_, index) => {
      const reviewKeywordCategory = `review_keyword_category_${index + 1}`;
      updatedData[`${reviewKeywordCategory}_name`] = it[`${reviewKeywordCategory}_name`];
      updatedData[`${reviewKeywordCategory}_score`] = it[`${reviewKeywordCategory}_score`];
      updatedData[`${reviewKeywordCategory}_score_delta`] = it[`${reviewKeywordCategory}_score_delta`];
      updatedData[`${reviewKeywordCategory}_rank`] = it[`${reviewKeywordCategory}_rank`];
    });
    return updatedData;
  });

  const label = sortOrder === 'H2L' || sortOrder === 'L2H' ? `${sortLabel.replace('name', 'score')}` : `${sortLabel.replace('name', 'score_delta')}`;
  const isHighToLow = sortOrder === 'H2L' || sortOrder === 'DH2L';
  const sortedTableData = tableData.sort((a: { [key: string]: string | number }, b: { [key: string]: string | number }) => {
    if (label === 'Keyword Category') {
      if (isHighToLow) {
        return a.accountName.toString().toLowerCase() > b.accountName.toString().toLowerCase() ? -1 : 1;
      } else {
        return a.accountName.toString().toLowerCase() > b.accountName.toString().toLowerCase() ? 1 : -1;
      }
    } else if (a[label] === b[label] || b[label] === null) {
      return isHighToLow ? -1 : 1;
    } else if (b[label] === null) {
      return isHighToLow ? -1 : 1;
    } else if (a[label] === null) {
      return isHighToLow ? 1 : -1;
    } else if (sortOrder === 'H2L' || sortOrder === 'DH2L') {
      return a[label] > b[label] ? -1 : 1;
    } else {
      return a[label] > b[label] ? 1 : -1;
    }
  });
  return { total: totalTable, values: sortedTableData };
};

export const tableValueHandler = (platform: string, value: ILeaderBoardAnalyticsValue[], isSorting: boolean = false) => {
  const objKey = isSorting ? 'value' : 'label';
  if (platform === 'yelp') {
    return value.filter((it) => it[objKey] === 'review' || it[objKey] === 'reputation' || it[objKey] === 'title');
  } else if (platform === 'google') {
    return value.filter((it) => it[objKey] === 'review' || it[objKey] === 'reputation' || it[objKey] === 'profile_view' || it[objKey] === 'title');
  } else if (platform === 'linkedin' || platform === 'twitter' || platform === 'instagram') {
    return value.filter((it) => it[objKey] === 'follower' || it[objKey] === 'engagement' || it[objKey] === 'posts_count' || it[objKey] === 'title');
  } else {
    return value;
  }
};

export const rankHandler = (arrValue: number[], medals: string[], isKeyword?: boolean, sortOrder?: string) => {
  const sortArray = arrValue?.slice();
  sortArray?.sort((a, b) => (isKeyword ? a - b : b - a));
  return arrValue?.map((_1, index) => {
    if (arrValue[index] < 1) {
      return medals[3];
    } else {
      const medalPosition = sortArray.indexOf(arrValue[index]);
      const lastThreePosition = [arrValue.length, arrValue.length - 3, arrValue.length - 2, arrValue.length - 1];
      const isHighToLow = sortOrder === 'H2L' || sortOrder === 'DH2L';
      const isShowMedals = isHighToLow ? index < 3 : lastThreePosition.includes(index); // Medal handling for only three position
      return medalPosition <= 2 && isShowMedals ? medals[medalPosition] : medals[3];
    }
  });
};
