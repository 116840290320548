import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Masonry from 'react-masonry-css';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getCurrentUserTabListRequest } from 'actions';
import { IStore, mgApiTypes } from 'types';
import { Loading, NoDataFound } from 'components';
import { useAccountSwitcherData } from 'utils/hooks';
import { BREAK_POINT_COLUMNS_OBJ, SCROLL_THRESHOLD, MG_MEDIA_TYPE, MG_SOURCE_TYPE, USER_OWNERSHIP, NoDataFoundProps, MgMediaType, MG_CLOUD_MEDIA_TYPE } from 'utils/constants';
import { Video, ImageValidation } from 'widgets/Media';
import { UserDetailSearch } from './UserDetailSearch';

export const UserMediaList = () => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const assetsList = useSelector((state: IStore) => state.common.userDetailObj.assetsList);
  const currentPageCount = useSelector((state: IStore) => state.common.userDetailObj.currentPage);
  const isListFetching = useSelector((state: IStore) => state.common.userDetailObj.isListFetching);
  const userId = useSelector((state: IStore) => state.common.userDetailObj.currentUserDetails?.id);
  const search = useSelector((state: IStore) => state.common.userDetailObj.search);
  const activeTab = useSelector((state: IStore) => state.common.userDetailObj.activeTab);

  useEffect(() => {
    if (id && userOwnership && isValidId && userId) {
      handleMediaGalleryDataRequest(1);
    }
  }, [id, userOwnership, isValidId, userId]); // eslint-disable-line

  const handleMediaGalleryDataRequest = (page: number) => {
    if (userId) {
      dispatch(
        getCurrentUserTabListRequest({
          page,
          mediaType: activeTab,
          source: MG_SOURCE_TYPE.ALL,
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisorId: id } : { accountId: id }),
          userId,
          ...(search && { search })
        })
      );
    }
  };

  return (
    <>
      <UserDetailSearch searchValue={search} handleInputSearch={() => handleMediaGalleryDataRequest(1)} />
      {isListFetching && currentPageCount === 1 ? (
        <Loading />
      ) : assetsList?.length ? (
        <div className="grid-infinity mg-list-view">
          <InfiniteScroll
            scrollThreshold={SCROLL_THRESHOLD}
            next={() => {
              handleMediaGalleryDataRequest(currentPageCount + 1);
            }}
            hasMore
            dataLength={assetsList?.length}
            loader={isListFetching ? <Loading /> : null}
            className="local-ini"
            scrollableTarget="userDetailScrollTarget"
            height={500}
          >
            <div className={`masonry-list-view-group`}>
              <Masonry breakpointCols={BREAK_POINT_COLUMNS_OBJ} className="masonry-grid" columnClassName="masonry-grid_column">
                {assetsList?.map((assetsItem: mgApiTypes.IMGListResponseObj, index: number) => {
                  return (
                    <div key={`user-${activeTab}-item-${index}`} className="m-item">
                      <div className="m-ast">
                        {[MG_MEDIA_TYPE.PHOTO, MG_MEDIA_TYPE.RAW, MG_CLOUD_MEDIA_TYPE.IMAGE].includes(assetsItem?.media_type) ? (
                          <ImageValidation
                            defaultImg={assetsItem?.media_type !== MG_MEDIA_TYPE.PHOTO ? 'list_document_icon' : ''}
                            imgUrl={assetsItem?.medium_size_url || ''}
                            customClassname={'m-ast-itm m-ast-img'}
                            customName={'Media Gallery Asset'}
                          />
                        ) : assetsItem?.media_type === MG_MEDIA_TYPE.VIDEO ? (
                          <div className="video-outer-element">
                            <Video videoUrl={assetsItem?.medium_size_url || ''} light={assetsItem?.thumbnail_url} customClassname="m-ast-itm m-ast-video" />
                          </div>
                        ) : null}
                      </div>
                      <div className="m-ast-dtls">
                        <div className="mastg-main">
                          <div className="mast-prime-dtls">
                            <div className="mast-prof-pic">
                              <ImageValidation
                                isImgValid
                                imgUrl={assetsItem?.created_user_profile_photo_url}
                                defaultImg={'user-avatar-common.png'}
                                isNotSvgFormat
                                customClassname={''}
                                customName={'MG Profile Picture'}
                              />
                            </div>
                            <div className="mast-prof-txt">
                              <span className="sm-txt-top"> {assetsItem?.name}</span>
                              {activeTab !== MgMediaType.DOCUMENTS ? (
                                <span className="lg-txt-base">
                                  <span className="mlg-label">Uses</span>
                                  <span className="mlg-count"> {assetsItem?.used_count || 0}</span>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {/* removed for no action in expand icon */}
                        {/*  <div className="mast-controls">
                          <div className="mc-expand">
                            <ImageValidation isImgValid defaultImg={'expand.svg'} isNotSvgFormat customName="Open Detailed View" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        !isListFetching && !assetsList?.length && <NoDataFound size={NoDataFoundProps.FULL} />
      )}
    </>
  );
};
