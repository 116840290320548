import { postsReducerTypes } from 'types';

export const POSTS_INIT_STATS_DATA: postsReducerTypes.IPostStatsData = {
  approvedPosts: 0,
  boostedPosts: 0,
  draftPosts: 0,
  notPublishedPosts: 0,
  pendingPosts: 0,
  postedPosts: 0,
  publishedPosts: 0,
  totalPosts: 0,
  pendingPostsLocationContent: 0,
  aiDraftPosts: 0,
  brandPosts: 0,
  syndicatedPosts: 0,
  locationPosts: 0
};
