// GET ADMIN AREA ACCOUNT LIST REQUEST
export const GET_AA_ACCOUNTS_LIST_REQUEST = 'admin_area/GET_AA_ACCOUNTS_LIST_REQUEST';
export const GET_AA_ACCOUNTS_LIST_RESPONSE = 'admin_area/GET_AA_ACCOUNTS_LIST_RESPONSE';
export const GET_AA_ACCOUNTS_LIST_FAILURE = 'admin_area/GET_AA_ACCOUNTS_LIST_FAILURE';

// GET ADMIN AREA FRANCHIORS LIST REQUEST
export const GET_AA_FRANCHISORS_LIST_REQUEST = 'admin_area/GET_AA_FRANCHISORS_LIST_REQUEST';
export const GET_AA_FRANCHISORS_LIST_RESPONSE = 'admin_area/GET_AA_FRANCHISORS_LIST_RESPONSE';
export const GET_AA_FRANCHISORS_LIST_FAILURE = 'admin_area/GET_AA_FRANCHISORS_LIST_FAILURE';

// GET ADMIN AREA USERS LIST REQUEST
export const GET_AA_USERS_LIST_REQUEST = 'admin_area/GET_AA_USERS_LIST_REQUEST';
export const GET_AA_USERS_LIST_RESPONSE = 'admin_area/GET_AA_USERS_LIST_RESPONSE';
export const GET_AA_USERS_LIST_FAILURE = 'admin_area/GET_AA_USERS_LIST_FAILURE';

export const ADMIN_AREA_DETAIL_VIEW_ERROR = 'admin_area/ADMIN_AREA_DETAIL_VIEW_ERROR';

// RESET ADMIN AREA
export const ADMIN_AREA_RESET = 'admin_area/ADMIN_AREA_RESET';

// GET ADMIN AREA FRANCHISOR_STATS REQUEST
export const GET_AA_FRANCHISOR_STATS_REQUEST = 'admin_area/GET_AA_FRANCHISOR_STATS_REQUEST';
export const GET_AA_FRANCHISOR_STATS_RESPONSE = 'admin_area/GET_AA_FRANCHISOR_STATS_RESPONSE';
export const GET_AA_FRANCHISOR_STATS_FAILURE = 'admin_area/GET_AA_FRANCHISOR_STATS_FAILURE';

// UPDATE FRANCHISOR
export const UPDATE_AA_FRANCHISOR_REQUEST = 'admin_area/UPDATE_AA_FRANCHISOR_REQUEST';
export const UPDATE_AA_FRANCHISOR_RESPONSE = 'admin_area/UPDATE_AA_FRANCHISOR_RESPONSE';
export const UPDATE_AA_FRANCHISOR_FAILURE = 'admin_area/UPDATE_AA_FRANCHISOR_FAILURE';

// SET ADMIN AREA FILTER
export const SET_ADMIN_AREA_SCREEN = 'admin_area/SET_ADMIN_AREA_SCREEN';

// UPDATE ADMIN AREA USER DETAILS
export const UPDATE_AA_USER_DETAILS_REQUEST = 'admin_area/UPDATE_AA_USER_DETAILS_REQUEST';
export const UPDATE_AA_USER_DETAILS_RESPONSE = 'admin_area/UPDATE_AA_USER_DETAILS_RESPONSE';
export const UPDATE_AA_USER_DETAILS_FAILURE = 'admin_area/UPDATE_AA_USER_DETAILS_FAILURE';

// ADMIN AREA USER INVITE EMAIL
export const ADD_AA_USER_INVITE_EMAIL_REQUEST = 'admin_area/ADD_AA_USER_INVITE_EMAIL_REQUEST';
export const ADD_AA_USER_INVITE_EMAIL_RESPONSE = 'admin_area/ADD_AA_USER_INVITE_EMAIL_RESPONSE';
export const ADD_AA_USER_INVITE_EMAIL_FAILURE = 'admin_area/ADD_AA_USER_INVITE_EMAIL_FAILURE';

// SET FRANCHISOR ID
export const SET_FRANCHISOR_ID = 'admin_area/SET_FRANCHISOR_ID';

// SET CURRENT PAGE
export const SET_AA_CURRENT_PAGE = 'admin_area/SET_AA_CURRENT_PAGE';

export const UPDATE_AA_USER_DETAILS_MSG_RESET = 'admin_area/UPDATE_AA_USER_DETAILS_MSG_RESET';
export const AA_USER_DETAILS_DATA_RESET = 'admin_area/AA_USER_DETAILS_DATA_RESET';

// GET ADMIN AREA USER DETAILS
export const GET_AA_USER_DETAILS_REQUEST = 'admin_area/GET_AA_USER_DETAILS_REQUEST';
export const GET_AA_USER_DETAILS_RESPONSE = 'admin_area/GET_AA_USER_DETAILS_RESPONSE';
export const GET_AA_USER_DETAILS_FAILURE = 'admin_area/GET_AA_USER_DETAILS_FAILURE';
