// STRIPE - GET CUSTOMER DATA
export const STRIPE_GET_CUSTOMER_REQUEST = 'stripe/STRIPE_GET_CUSTOMER_REQUEST';
export const STRIPE_GET_CUSTOMER_RESPONSE = 'stripe/STRIPE_GET_CUSTOMER_RESPONSE';
export const STRIPE_GET_CUSTOMER_REQUEST_FAIL = 'stripe/STRIPE_GET_CUSTOMER_REQUEST_FAIL';

// STRIPE - GET CARD DATA
export const STRIPE_GET_CARDS_REQUEST = 'stripe/STRIPE_GET_CARDS_REQUEST';
export const STRIPE_GET_CARDS_RESPONSE = 'stripe/STRIPE_GET_CARDS_RESPONSE';
export const STRIPE_GET_CARDS_REQUEST_FAIL = 'stripe/STRIPE_GET_CARDS_REQUEST_FAIL';

// STRIPE CARDS RESET
export const STRIPE_CARDS_RESET = 'stripe/STRIPE_CARDS_RESET';

// AI STRIPE - GET CUSTOMER DATA
export const AI_STRIPE_GET_CUSTOMER_REQUEST = 'stripe/AI_STRIPE_GET_CUSTOMER_REQUEST';
