import { config } from 'config';
import { couponsApiTypes, couponsReducerTypes, couponsContainerTypes } from 'types';
import { dateFormatByRequestedFormat, defaultHeader, getCurrentDate } from 'utils/helpers';
import { ALERT_BOX_TYPES, DONE_SUCCESS_MSG, UTC_TIMESTAMP_FORMAT } from 'utils/constants';
import { alertBoxCall } from 'components';

/** Get new coupon request payload */
export const getNewCouponReqPayload = (franchisorId: number, couponData: couponsReducerTypes.INewCouponObj) => {
  return {
    claimed_count: null,
    company_id: null,
    coupon_image_id: -1,
    coupon_image_url: null,
    coupon_name: couponData.couponName || '',
    coupon_type: null,
    coupon_url: null,
    description: couponData.offerCopy ? `<p>${couponData.offerCopy}</p>` : '',
    disclaimer: couponData.disclaimerCopy ? `<p>${couponData.disclaimerCopy}</p>` : '',
    expiration: couponData.couponDate.expired.date || '',
    external_image_url: couponData.imageUrl,
    franchisor_id: `${franchisorId}`,
    pending_count: null,
    quantity: couponData.quantityOfCoupons,
    redeemed_count: null,
    start_date: couponData.couponDate.start.date || '',
    total_revenue: null,
    views: null
  };
};

/** Update existing coupon request payload */
export const getUpdateCouponReqPayload = (franchisorId: number, couponData: couponsReducerTypes.INewCouponObj, couponDetail: couponsApiTypes.IAddCouponReq) => {
  return {
    id: couponData.id,
    claimed_count: couponDetail.claimed_count,
    company_id: couponDetail.company_id,
    coupon_image_id: couponDetail.coupon_image_id,
    coupon_image_url: couponDetail.coupon_image_url,
    coupon_locations: couponDetail.coupon_locations,
    coupon_name: couponData.couponName || '',
    coupon_type: couponDetail.coupon_type,
    coupon_url: couponDetail.coupon_url,
    description: couponData.offerCopy ? `<p>${couponData.offerCopy}</p>` : '',
    disclaimer: couponData.disclaimerCopy ? `<p>${couponData.disclaimerCopy}</p>` : '',
    expiration: couponData.couponDate.expired.date || '',
    external_image_url: couponData.imageUrl,
    franchisor_id: `${franchisorId}`,
    pending_count: couponDetail.pending_count,
    quantity: couponData.quantityOfCoupons,
    redeemed_count: couponDetail.redeemed_count,
    roi: couponDetail.roi,
    start_date: couponData.couponDate.start.date || '',
    total_revenue: couponDetail.total_revenue,
    total_spend: couponDetail.total_spend,
    views: couponDetail.views
  };
};

export const getExportCouponCodes = (couponId: string, setExportCodesFetching: React.Dispatch<React.SetStateAction<boolean>>) => {
  setExportCodesFetching(true);
  const reportUrl = `${config.apiBaseURL}${config.apiVersionPath}rallio_coupons/${couponId}/coupon_codes.csv`;
  fetch(reportUrl, {
    headers: {
      Accept: defaultHeader().Accept,
      'Content-Type': defaultHeader()['Content-Type'],
      'X-Rallio-API-Key': defaultHeader()['X-Rallio-API-Key']
    }
  }).then((response) => {
    const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const createdDoc = document.createElement('a');
      createdDoc.href = url;
      createdDoc.download = `coupon_codes_${currentUnixTimestamp}.csv`;
      createdDoc.click();
      createdDoc.remove();
      alertBoxCall(ALERT_BOX_TYPES.DISMISS, null);
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
    });
    setExportCodesFetching(false);
  });
};

export const totalLocationData = (couponDetail: couponsApiTypes.ICouponsListResponse): couponsContainerTypes.ICouponLocationTableObj => {
  return {
    couponLocationsCount: couponDetail.coupon_locations.length || 0,
    startDate: couponDetail.start_date,
    expiration: couponDetail.expiration,
    spend: couponDetail.total_spend,
    views: couponDetail.views,
    claimed: couponDetail.claimed_count,
    redeemed: couponDetail.redeemed_count,
    revenue: couponDetail.total_revenue,
    roi: couponDetail.roi
  };
};

export const locationData = (locationDetail: couponsApiTypes.ICouponsLocations): couponsContainerTypes.ICouponLocationTableObj => {
  return {
    name: locationDetail.name,
    spend: locationDetail.spend,
    views: locationDetail.visits_count,
    claimed: locationDetail.claimed_count,
    redeemed: locationDetail.redeemed_count,
    revenue: locationDetail.total_revenue,
    roi: locationDetail.roi
  };
};

export const noLocationViewsCount = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const locationsViewsCount = couponDetail.coupon_locations.reduce((acc, curr) => acc + curr.visits_count, 0);

  return couponDetail.views - locationsViewsCount;
};

export const noLocationSpend = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const totalSpendAmount = couponDetail.total_spend || 0;
  const locationsSpendAmount = couponDetail.coupon_locations.reduce((acc, curr) => acc + curr.spend, 0);
  return totalSpendAmount - locationsSpendAmount;
};

export const noLocationClaimedCount = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const totalClaimedCount = couponDetail.claimed_count || 0;
  const locationsClaimedCount = couponDetail.coupon_locations.reduce((acc, curr) => acc + curr.claimed_count, 0);

  return totalClaimedCount - locationsClaimedCount;
};

export const noLocationRedeemedCount = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const totalRedeemedCount = couponDetail.redeemed_count || 0;
  const locationsRedeemedCount = couponDetail.coupon_locations.reduce((acc, curr) => acc + curr.redeemed_count, 0);

  return totalRedeemedCount - locationsRedeemedCount;
};

export const noLocationRevenueAmount = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const totalRevenueAmount = parseFloat(couponDetail.total_revenue) || 0;
  const locationsRevenueAmount = couponDetail.coupon_locations.reduce((acc, curr) => acc + +curr.total_revenue, 0);

  return totalRevenueAmount - locationsRevenueAmount;
};

export const noLocationRoi = (couponDetail: couponsApiTypes.ICouponsListResponse) => {
  const totalSpendAmount = couponDetail.total_spend || 0;
  const totalRevenueAmount = parseFloat(couponDetail.total_revenue) || 0;

  if (totalSpendAmount === 0 || totalRevenueAmount === 0) {
    return null;
  }

  return Math.round(((totalRevenueAmount - totalSpendAmount) / totalSpendAmount) * 100) / 100;
};

export const noLocationData = (couponDetail: couponsApiTypes.ICouponsListResponse): couponsContainerTypes.ICouponLocationTableObj => {
  return {
    spend: noLocationSpend(couponDetail),
    views: noLocationViewsCount(couponDetail),
    claimed: noLocationClaimedCount(couponDetail),
    redeemed: noLocationRedeemedCount(couponDetail),
    revenue: noLocationRevenueAmount(couponDetail),
    roi: noLocationRoi(couponDetail)
  };
};
