import { scContainerTypes } from 'types';

export const SCEngagementStats = ({ stats }: scContainerTypes.ISCEngagementStatsProps) => {
  return stats.length ? (
    <div className="lvc">
      {stats.map((statsItem, index) => {
        return (
          <div className="aib-item" key={`engagement-stats-${index}`}>
            <div className="lvc-item">
              <div className="lvci-cnt">
                <span className="lvc-count">{statsItem.number}</span>
                <span className="lvc-txt">{statsItem.label}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};
