import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { dateFormatByRequestedFormat } from 'utils/helpers';
import { MOMENT_TIME_FORMAT, MOMENT_LONG_DATE_FORMAT, USER_OWNERSHIP } from 'utils/constants';
import { IStore } from 'types';
import { TextArea } from 'widgets/Text';
import { Loading } from 'components';
import { useAccountSwitcherData, useSiteRestriction } from 'utils/hooks';
import { postsDetailAddInternalNotesRequest } from 'actions';

export const PostsDetailViewInternalNotes = () => {
  const dispatch = useDispatch();
  const { userOwnership } = useAccountSwitcherData();
  const { postsRestriction } = useSiteRestriction();

  const isIntNotesFetching = useSelector((state: IStore) => state.posts.isIntNotesFetching);
  const internalNotesList = useSelector((state: IStore) => state.posts.internalNotesList);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);

  const [internalNoteText, setInternalNoteText] = useState('');

  const handleAddInternalNote = () => {
    const postUserOwnership = userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id ? USER_OWNERSHIP.ACCOUNT : userOwnership;
    if (postsDetails?.id && internalNoteText && postUserOwnership) {
      dispatch(
        postsDetailAddInternalNotesRequest({
          postDataId: postsDetails.id,
          customText: internalNoteText,
          userOwnership: postUserOwnership
        })
      );
      setInternalNoteText('');
    }
  };

  return postsDetails ? (
    <div className="st-tc-item">
      <div className="wb-top">
        <div className="chat-main">
          <div className="cm-group">
            {!postsRestriction.internal_note && (
              <div className="cm-input-area">
                <ImageValidation
                  imgUrl={postsDetails?.created_user?.profile_photo_url || ''}
                  defaultImg={'user-avatar-common.png'}
                  isNotSvgFormat
                  customClassname={'cm-asset'}
                  customName={'user-profile-img'}
                />
                <div className="cmi">
                  <Button className="plain-btn arw-cmi send-post" disabled={!internalNoteText} variant="link" onClick={() => handleAddInternalNote()} />
                  <TextArea
                    id="internalnote"
                    name="internalnote"
                    minRows={1}
                    className="form-control post-notes"
                    placeholder="Add Internal Note"
                    value={internalNoteText}
                    onChange={(event) => setInternalNoteText(event.target.value)}
                  />
                </div>
              </div>
            )}
            {isIntNotesFetching ? <Loading isSmall /> : null}
            <div className="cma-child-holder">
              {internalNotesList.length
                ? internalNotesList.map((noteItem, index) => {
                    return (`saved_post_id` in noteItem && noteItem.saved_post_id === postsDetails.id) || (`content_id` in noteItem && noteItem.content_id === postsDetails.id) ? (
                      <div className="cm-input-area cma-child" key={`post-internal-note-${index}`}>
                        <ImageValidation
                          imgUrl={postsDetails?.created_user?.profile_photo_url || ''}
                          defaultImg={'user-avatar-common.png'}
                          isNotSvgFormat
                          customClassname={'cm-asset'}
                          customName={'user-profile-img'}
                        />
                        <div className="cmi">
                          <div className="cm-prime-dtls">
                            <h3>{noteItem.user_name}</h3>
                            <div className="cmp-brief">
                              <span className="cmp-txt">{`@ ${dateFormatByRequestedFormat(noteItem.created_at, MOMENT_TIME_FORMAT)} |`}</span>
                              <span className="cmp-txt cmpt-blue"> {dateFormatByRequestedFormat(noteItem.created_at, MOMENT_LONG_DATE_FORMAT)}</span>
                            </div>
                          </div>
                          <div className="cmi-txt-wrap">
                            <TextArea name="internalnote" readOnly disabled defaultValue={noteItem.body} className="form-control post-notes" maxRows={2} placeholder="Internal Notes" />
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })
                : !isIntNotesFetching && <span className="notetext">There are no internal notes to display.</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
