// SAVE OR UPDATE ACCOUNT PROFILE AFTER UPDATING DETAILS
export const ACCOUNTS_UPDATE_PROFILE_REQUEST = 'accounts/ACCOUNTS_UPDATE_PROFILE_REQUEST';
export const ACCOUNTS_UPDATE_PROFILE_RESPONSE = 'accounts/ACCOUNTS_UPDATE_PROFILE_RESPONSE';
export const ACCOUNTS_UPDATE_PROFILE_FAILURE = 'accounts/ACCOUNTS_UPDATE_PROFILE_FAILURE';

// GET ACCOUNT PROFILE DETAILS
export const ACCOUNTS_GET_PROFILE_REQUEST = 'accounts/ACCOUNTS_GET_PROFILE_REQUEST';
export const ACCOUNTS_GET_PROFILE_RESPONSE = 'accounts/ACCOUNTS_GET_PROFILE_RESPONSE';
export const ACCOUNTS_GET_PROFILE_FAILURE = 'accounts/ACCOUNTS_GET_PROFILE_FAILURE';

// ADD ACCOUNT PROFILE
export const ACCOUNTS_ADD_PROFILE_REQUEST = 'accounts/ACCOUNTS_ADD_PROFILE_REQUEST';
export const ACCOUNTS_ADD_PROFILE_RESPONSE = 'accounts/ACCOUNTS_ADD_PROFILE_RESPONSE';
export const ACCOUNTS_ADD_PROFILE_FAILURE = 'accounts/ACCOUNTS_ADD_PROFILE_FAILURE';

// GET FRANCHISEE PERMISSION
export const GET_FRANCHISEE_PERMISSION_REQUEST = 'accounts/GET_FRANCHISEE_PERMISSION_REQUEST';
export const GET_FRANCHISEE_PERMISSION_RESPONSE = 'accounts/GET_FRANCHISEE_PERMISSION_RESPONSE';
export const GET_FRANCHISEE_PERMISSION_FAILURE = 'accounts/GET_FRANCHISEE_PERMISSION_FAILURE';

// ACCOUNT ACTIVATE
export const ACCOUNT_ACTIVATE_REQUEST = 'accounts/ACCOUNT_ACTIVATE_REQUEST';
export const ACCOUNT_ACTIVATE_RESPONSE = 'accounts/ACCOUNT_ACTIVATE_RESPONSE';
export const ACCOUNT_ACTIVATE_FAILURE = 'accounts/ACCOUNT_ACTIVATE_FAILURE';

// ACCOUNT - SET ACCOUNT ACTIVATE
export const SET_ACCOUNT_ACTIVATE = 'accounts/SET_ACCOUNT_ACTIVATE';

export const ACCOUNT_ERROR = 'accounts/ACCOUNT_ERROR';

// RESET ACCOUNT PROFILE DETAILS
export const ACCOUNT_PROFILE_MESSAGES_RESET = 'accounts/ACCOUNT_PROFILE_MESSAGES_RESET';
export const ACCOUNTS_PROFILE_RESET = 'accounts/ACCOUNTS_PROFILE_RESET';

// ACCOUNTS - GET SCHEDULE LIST
export const ACCOUNTS_GET_SCHEDULE_LIST_REQUEST = 'accounts/ACCOUNTS_GET_SCHEDULE_LIST_REQUEST';
export const ACCOUNTS_GET_SCHEDULE_LIST_RESPONSE = 'accounts/ACCOUNTS_GET_SCHEDULE_LIST_RESPONSE';
export const ACCOUNTS_GET_SCHEDULE_LIST_FAILURE = 'accounts/ACCOUNTS_GET_SCHEDULE_LIST_FAILURE';

// ACCOUNTS - CREATE OR UPDATE SCHEDULE LIST
export const ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_REQUEST = 'accounts/ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_REQUEST';
export const ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_RESPONSE = 'accounts/ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_RESPONSE';
export const ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_FAILURE = 'accounts/ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST__FAILURE';

// ACCOUNTS - DELETE SCHEDULE LIST
export const ACCOUNTS_DELETE_SCHEDULE_LIST_REQUEST = 'accounts/ACCOUNTS_DELETE_SCHEDULE_LIST_REQUEST';
export const ACCOUNTS_DELETE_SCHEDULE_LIST_RESPONSE = 'accounts/ACCOUNTS_DELETE_SCHEDULE_LIST_RESPONSE';
export const ACCOUNTS_DELETE_SCHEDULE_LIST_FAILURE = 'accounts/ACCOUNTS_DELETE_SCHEDULE_LIST_FAILURE';

// ACCOUNTS - CREATE SCHEDULED POSTS
export const ACCOUNTS_CREATE_SCHEDULED_POSTS_REQUEST = 'accounts/ACCOUNTS_CREATE_SCHEDULED_POSTS_REQUEST';

// ACCOUNTS - GET SCHEDULED POSTS STATS
export const ACCOUNTS_GET_SCHEDULED_POSTS_STATS_REQUEST = 'accounts/ACCOUNTS_GET_SCHEDULED_POSTS_STATS_REQUEST';
export const ACCOUNTS_GET_SCHEDULED_POSTS_STATS_RESPONSE = 'accounts/ACCOUNTS_GET_SCHEDULED_POSTS_STATS_RESPONSE';
export const ACCOUNTS_GET_SCHEDULED_POSTS_STATS_FAILURE = 'accounts/ACCOUNTS_GET_SCHEDULED_POSTS_STATS_FAILURE';

// ACCOUNTS - BLOCK SCHEDULED LIST
export const ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_REQUEST = 'accounts/ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_REQUEST';
export const ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_RESPONSE = 'accounts/ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_RESPONSE';
export const ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_FAILURE = 'accounts/ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_FAILURE';
