// User Request action types
export const USER_REQUEST = 'users/USER_REQUEST';
export const USER_FAILURE = 'users/USER_FAILURE';
export const USER_RESPONSE = 'users/USER_RESPONSE';

// User List Request action types
export const USER_LIST_REQUEST = 'users/USER_LIST_REQUEST';
export const USER_LIST_FAILURE = 'users/USER_LIST_FAILURE';
export const USER_LIST_RESPONSE = 'users/USER_LIST_RESPONSE';

export const USER_METRICS_REQUEST = 'users/USER_METRICS_REQUEST';
export const USER_METRICS_FAILURE = 'users/USER_METRICS_FAILURE';
export const USER_METRICS_RESPONSE = 'users/USER_METRICS_RESPONSE';

export const USER_CREATE_REQUEST = 'users/USER_CREATE_REQUEST';
export const USER_CREATE_FAILURE = 'users/USER_CREATE_FAILURE';
export const USER_CREATE_RESPONSE = 'users/USER_CREATE_RESPONSE';

export const USER_DETAILS_UPDATE_REQUEST = 'users/USER_DETAILS_UPDATE_REQUEST';
export const USER_DETAILS_UPDATE_FAILURE = 'users/USER_DETAILS_UPDATE_FAILURE';
export const USER_DETAILS_UPDATE_RESPONSE = 'users/USER_DETAILS_UPDATE_RESPONSE';

export const USER_DELETE_REQUEST = 'users/USER_DELETE_REQUEST';
export const USER_DELETE_FAILURE = 'users/USER_DELETE_FAILURE';
export const USER_DELETE_RESPONSE = 'users/USER_DELETE_RESPONSE';

export const USER_UPLOAD_REQUEST = 'users/USER_UPLOAD_REQUEST';
export const USER_UPLOAD_FAILURE = 'users/USER_UPLOAD_FAILURE';
export const USER_UPLOAD_RESPONSE = 'users/USER_UPLOAD_RESPONSE';

export const PROFILE_SETTINGS_REQUEST = 'users/PROFILE_SETTINGS_REQUEST';
export const PROFILE_SETTINGS_FAILURE = 'users/ PROFILE_SETTINGS_FAILURE';
export const PROFILE_SETTINGS_RESPONSE = 'users/PROFILE_SETTINGS_RESPONSE';

export const UPDATE_USERS_SELECTED_LOCATION = 'users/UPDATE_USERS_SELECTED_LOCATION';

export const USER_RESET_UPLOAD_SUCCESS_MESSAGE = 'users/USER_RESET_UPLOAD_SUCCESS_MESSAGE';
