import { ImageValidation } from 'widgets/Media';
import { commonWidgetTypes } from 'types';
import { Paragraph } from 'widgets/Text';

export const CouponOrLinkPreview = (props: commonWidgetTypes.ICouponOrLinkPreview) => {
  const { type, previewTitle, linkUrl, description, thumbnailShow, handleDeletePreview, handleUpdateThumbnail, activeImgUrl } = props;

  return (
    <div className="left-side-coupon-wrp-new">
      <div className="card-bg coupon-card">
        <span className="apui-close-creator-cou" onClick={handleDeletePreview} />
        <div className="coupan-card-left">
          <div className="coupon-status">
            <span>{type}</span>
          </div>
          <div className="coupon-content">
            <div className="coupon-thumb-wrp">
              {thumbnailShow && activeImgUrl ? (
                <div className="coupon-slider-wrp">
                  <div className="coupon-thumb">
                    <ImageValidation imgUrl={activeImgUrl} customName={'Coupon or link preview image'} />
                  </div>
                  {/* {imagesList.length > 1 && index !== 0 && (
                    <div className="coupon-add-link-img-arrow" onClick={() => handleUpdateIndex(index - 1)}>
                      <ImageValidation defaultImg={'addlink-rightarrow'} customName={'Left link arrow'} />
                    </div>
                  )}
                  {imagesList.map((image, imageIndex) => (
                    <div key={`link-preview-img-${imageIndex}`} className={`coupon-thumb ${index !== imageIndex ? 'd-none' : ''}`} onClick={() => handleLinkNavigation(imagesList[index])}>
                      <ImageValidation imgUrl={image} customName={'Coupon or link preview image'} />
                    </div>
                  ))}
                  {imagesList.length > 1 && index !== imagesList.length - 1 && (
                    <div className="coupon-link-img-arrow-right" onClick={() => handleUpdateIndex(index + 1)}>
                      <ImageValidation defaultImg={'addlink-rightarrow'} customName={'Right link arrow'} />
                    </div>
                  )} */}
                </div>
              ) : null}
              <label className={`checkbox-item`}>
                <span className="label-txt">Thumbnail</span>
                <input type="checkbox" name="isNoThumbnail" checked={thumbnailShow} onChange={() => handleUpdateThumbnail()} />
                <span className={`checkmark${thumbnailShow ? ` fullSelectCheck` : ``}`} />
              </label>
            </div>
            <div className="coupon-details">
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                <h5>{previewTitle}</h5>
              </a>
              <span className="url-txt-lnk">{linkUrl}</span>
              {description && <Paragraph customText={description} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
