import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Image, Table } from 'react-bootstrap';

// import { ImageValidation } from 'widgets/Media';
import { ICustomHeaderDropDownOption, IIndexable, IRenderNavigationIcon, ITableHeader, ITableProps } from 'types';

interface IResponsiveTableModeProps<T, E = object> extends ITableProps<T, E> {
  pinnedColumns?: Array<number>;
  pinColumnsRight?: Array<number>;
  noDataMesg?: string;
  ipadBoundary?: number;
  mobileBoundary: number;
  ipadColCount?: number;
  mobileColCount: number;
  mdDeviceBoundary?: number;
  mdDeviceColCount?: number;
  responsiveNavigation: 'arrow' | 'dropdown';
  responsiveNavigationNextIcon?: string;
  responsiveNavigationPreviousIcon?: string;
  renderResponsiveNavNextIcon?: ({ className, onIconClick }: IRenderNavigationIcon) => JSX.Element;
  renderResponsiveNavPrevIcon?: ({ className, onIconClick }: IRenderNavigationIcon) => JSX.Element;
}

interface ICustomDropdownProps<T, E = object> {
  headerObj: ITableHeader<T, E>;
  index: number;
  options?: ICustomHeaderDropDownOption[];
  showDropdown: string;
  onCustomHeaderDropDownClick?: (headerObj: ITableHeader<T, E>, dropdownItem: ICustomHeaderDropDownOption) => void;
  unvisibleHeaders: ITableHeader<T, E>[];
  visibleHeaders: ITableHeader<T, E>[];
}

interface ISelectedOption {
  optionIdentifier: string;
  headerIdentifier: string;
  optionType: 'sort' | 'filter';
}

export const ResponsiveTableMode = <T extends IIndexable, E extends {}>({
  data,
  onRowClick,
  onColumnClick,
  tableClassName,
  headers,
  noDataMesg,
  pinnedColumns,
  pinColumnsRight,
  mdDeviceBoundary,
  mdDeviceColCount,
  onHeaderClick,
  mobileColCount,
  ipadColCount,
  ipadBoundary,
  mobileBoundary,
  responsiveNavigation,
  onCustomHeaderDropDownClick,
  responsiveNavigationNextIcon,
  responsiveNavigationPreviousIcon,
  renderResponsiveNavNextIcon,
  renderResponsiveNavPrevIcon,
  colState
}: IResponsiveTableModeProps<T, E>) => {
  const initUnpinnedState = () => {
    let count = mdDeviceBoundary;
    if (window.innerWidth <= mobileBoundary) {
      count = mobileColCount;
    } else if (ipadBoundary && window.innerWidth <= ipadBoundary && ipadColCount) {
      count = ipadColCount;
    } else if (mdDeviceBoundary && window.innerWidth <= mdDeviceBoundary && mdDeviceColCount) {
      count = mdDeviceColCount;
    } else {
      count = mobileColCount;
    }
    return count;
  };

  const [unpinnedCount, setUnpinnedCount] = useState(initUnpinnedState());
  const [slideNo, setSlideNo] = useState(0);
  const [visibleHeaders, setVisibleHeaders] = useState<ITableHeader<T, E>[]>([]);
  const [showDropdown, setShowDropdown] = useState('');
  const [mobileMode, setMobileMode] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ISelectedOption[]>([]);

  const headersRef = useRef<Array<string>>([]);

  useEffect(() => {
    headersRef.current = visibleHeaders.map((it) => it?.identifier);
  }, [visibleHeaders]);

  const headerObj = useMemo(() => {
    const obtainPinnedColumns = (pinIndexes: number[]) =>
      pinIndexes.reduce((prevVal: ITableHeader<T, E>[], pIndex: number) => {
        prevVal.push(headers[pIndex]);
        return prevVal;
      }, []);

    const obtainUnpinnedColumns = (pinIndexes: number[]) => headers.filter((_1, index) => !pinIndexes.includes(index));

    const pinColsRight = pinColumnsRight || [];
    if (pinnedColumns && pinnedColumns?.length > 0) {
      return { pinHeaders: obtainPinnedColumns(pinnedColumns), unpinnedHeaders: obtainUnpinnedColumns([...pinnedColumns, ...pinColsRight]), pinRightHeaders: obtainPinnedColumns(pinColsRight) };
    } else {
      return { pinHeaders: obtainPinnedColumns([0]), unpinnedHeaders: obtainUnpinnedColumns([0, ...pinColsRight]), pinRightHeaders: obtainPinnedColumns(pinColsRight) };
    }
  }, [headers, pinnedColumns, pinColumnsRight]);

  useEffect(() => {
    setShowDropdown('');
  }, [data]);

  useEffect(() => {
    const findBoundaryCols = () => {
      headersRef.current = [];
      const isMdDevice = mdDeviceBoundary && window.innerWidth <= mdDeviceBoundary;
      const isIpad = ipadBoundary && window.innerWidth <= ipadBoundary;
      const isMobile = window.innerWidth <= mobileBoundary;
      if (isMobile) {
        setMobileMode(true);
        setUnpinnedCount(mobileColCount);
      } else if (isIpad && ipadColCount) {
        setMobileMode(false);
        setUnpinnedCount(ipadColCount);
      } else if (isMdDevice && mdDeviceColCount) {
        setMobileMode(false);
        setUnpinnedCount(mdDeviceColCount);
      }
      setSlideNo(0);
    };
    findBoundaryCols();
    window.addEventListener('resize', findBoundaryCols);
    return () => {
      window.removeEventListener('resize', findBoundaryCols);
    };
  }, [mdDeviceBoundary, mdDeviceColCount, mobileColCount, ipadColCount, ipadBoundary, mobileBoundary, setUnpinnedCount, setSlideNo, setMobileMode]);

  useEffect(() => {
    if (headersRef.current.length > 0 && responsiveNavigation !== 'arrow') {
      const updatedHeaders = headersRef.current.reduce<ITableHeader<T, E>[]>((prevValue, currValue) => {
        const headObj = headerObj.unpinnedHeaders.find((it) => currValue === it?.identifier);
        if (headObj) {
          prevValue.push(headObj);
        }
        return prevValue;
      }, []);
      setVisibleHeaders(updatedHeaders);
    } else {
      setVisibleHeaders(headerObj.unpinnedHeaders.slice(slideNo, slideNo + unpinnedCount));
    }
  }, [headerObj, slideNo, unpinnedCount, responsiveNavigation]);

  const unvisibleHeaders = useMemo(() => {
    return headerObj.unpinnedHeaders.filter((it) => visibleHeaders.findIndex((vh) => vh.identifier === it?.identifier) === -1);
  }, [headerObj.unpinnedHeaders, visibleHeaders]);

  const threshold = useMemo(() => {
    return unpinnedCount !== 1
      ? headerObj.unpinnedHeaders.length % 2 === 0
        ? Math.floor(headerObj.unpinnedHeaders.length / unpinnedCount)
        : Math.ceil(headerObj.unpinnedHeaders.length / unpinnedCount)
      : headerObj.unpinnedHeaders.length - 1;
  }, [unpinnedCount, headerObj]);

  const handleNavClicks = useCallback(
    (val: number) => {
      const currentSlide = slideNo + val;
      setSlideNo(currentSlide);
    },
    [slideNo]
  );

  const isOptionSelected = useCallback(
    (option: ICustomHeaderDropDownOption, headerObj: ITableHeader<T, E>) => {
      return selectedOptions.findIndex((it) => it?.optionIdentifier === option.identifier && it?.headerIdentifier === headerObj.identifier) > -1;
    },
    [selectedOptions]
  );

  const CustomDropdown = ({ headerObj, index, options }: ICustomDropdownProps<T, E>) => {
    return unvisibleHeaders?.length > 0 || (options && options?.length > 0) ? (
      <Dropdown.Menu className="header-switching-dropdown" show={headerObj.identifier === showDropdown} align="start">
        {options && options.length
          ? options.map((ch, chIndex) => (
              <Dropdown.Item
                key={`${headerObj.identifier}customHeaderdropdown${chIndex}`}
                className={`${isOptionSelected(ch, headerObj) ? 'active selected__option' : ''}`}
                onClick={() => {
                  let newSelectedOption = [...selectedOptions];
                  if (ch.type === 'sort') {
                    if (!isOptionSelected(ch, headerObj)) {
                      newSelectedOption = newSelectedOption.filter((it) => it?.optionType !== 'sort');
                      newSelectedOption.push({
                        headerIdentifier: headerObj.identifier,
                        optionIdentifier: ch.identifier,
                        optionType: ch.type
                      });
                    }
                  } else {
                    if (isOptionSelected(ch, headerObj)) {
                      newSelectedOption = newSelectedOption.filter((it) => it?.optionIdentifier !== ch.identifier && it?.headerIdentifier !== headerObj.identifier);
                    } else {
                      newSelectedOption.push({
                        headerIdentifier: headerObj.identifier,
                        optionIdentifier: ch.identifier,
                        optionType: ch.type
                      });
                    }
                  }
                  setSelectedOptions(newSelectedOption);
                  if (!(ch.identifier === selectedOptions[0]?.optionIdentifier) || !(headerObj.identifier === selectedOptions[0]?.headerIdentifier)) {
                    if (onCustomHeaderDropDownClick) {
                      onCustomHeaderDropDownClick(headerObj, ch);
                    }
                  }
                }}
              >
                {ch.text}
              </Dropdown.Item>
            ))
          : null}
        {options && options.length && unvisibleHeaders.length ? <Dropdown.Divider /> : null}
        {options && options.length && unvisibleHeaders.length ? <Dropdown.Header>Columns</Dropdown.Header> : null}
        {unvisibleHeaders.map((uh, uhIndex) => (
          <Dropdown.Item
            key={`${headerObj.identifier}headerdropdown${uhIndex}`}
            onClick={() => {
              const updatedVisibleHeaders = [...visibleHeaders];
              updatedVisibleHeaders.splice(index, 1);
              updatedVisibleHeaders.splice(index, 0, uh);
              setVisibleHeaders(updatedVisibleHeaders);
            }}
          >
            {uh.labelString}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    ) : null;
  };

  const ArrowOptions = useCallback(
    ({ index, pinned, headers }: { index: number; pinned: boolean; headers: ITableHeader<T, E>[] }) =>
      index === headers.length - 1 && !pinned ? (
        <div className="d-flex ms-auto">
          <span>
            {responsiveNavigationPreviousIcon ? (
              <Image src={responsiveNavigationPreviousIcon} onClick={() => handleNavClicks(-1)} className={`${slideNo > 0 ? '' : 'opacity-50 pe-none'}`} />
            ) : renderResponsiveNavPrevIcon ? (
              renderResponsiveNavPrevIcon({
                className: `${slideNo > 0 ? '' : 'opacity-50 pe-none'}`,
                onIconClick: () => handleNavClicks(-1)
              })
            ) : null}
          </span>
          <span className={`${slideNo < threshold ? '' : 'opacity-50 pe-none'}`}>
            {responsiveNavigationNextIcon ? (
              <Image src={responsiveNavigationNextIcon} onClick={() => handleNavClicks(1)} />
            ) : renderResponsiveNavNextIcon ? (
              renderResponsiveNavNextIcon({
                className: `${slideNo < threshold ? '' : 'opacity-50 pe-none'}`,
                onIconClick: () => handleNavClicks(1)
              })
            ) : null}
          </span>
        </div>
      ) : null,
    [handleNavClicks, renderResponsiveNavNextIcon, renderResponsiveNavPrevIcon, responsiveNavigationNextIcon, responsiveNavigationPreviousIcon, slideNo, threshold]
  );

  const renderHeaders = (headers: ITableHeader<T, E>[], pinned = false) =>
    headers.map((it, index) => (
      <th
        key={`responsiveTableMode${pinned ? 'pinned' : 'unpinned'}Header${index}`}
        onClick={() => {
          if (onHeaderClick && pinned) {
            onHeaderClick(it);
          }
        }}
        className={it?.headerClassName}
      >
        {it?.renderHeader ? (
          <div className="position-relative">
            {it?.renderHeader(it, true, setShowDropdown, pinned)}
            {responsiveNavigation === 'arrow' ? (
              <ArrowOptions index={index} pinned={pinned} headers={headers} />
            ) : !pinned ? (
              <div>
                {/* <ImageValidation isImgValid defaultImg="sort" customName="sort" /> */}
                <CustomDropdown headerObj={it} index={index} options={it?.headerDropdownOptions} showDropdown={showDropdown} unvisibleHeaders={unvisibleHeaders} visibleHeaders={visibleHeaders} />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="position-relative">
            {responsiveNavigation === 'arrow' ? (
              <>
                <span>{it?.labelString}</span>
                <ArrowOptions index={index} pinned={pinned} headers={headers} />
              </>
            ) : !pinned ? (
              <div
                onClick={() => {
                  setShowDropdown(showDropdown === it?.identifier ? '' : it?.identifier);
                }}
              >
                {it?.labelString}
                {/* <ImageValidation isImgValid defaultImg="sort" customName="sort" /> */}
                <CustomDropdown headerObj={it} index={index} options={it?.headerDropdownOptions} showDropdown={showDropdown} unvisibleHeaders={unvisibleHeaders} visibleHeaders={visibleHeaders} />
              </div>
            ) : (
              <>{it?.label}</>
            )}
          </div>
        )}
      </th>
    ));

  const renderCols = (it: T, colHeaders: ITableHeader<T, E>[], rowIndex: number, bodyIndex: number, internalRowTable?: boolean) =>
    colHeaders.map((col, colIndex) => (
      <td
        className={col?.addColClass ? col?.addColClass(it) : col?.colClassName || ''}
        key={`responsiveTableColumn${bodyIndex}${rowIndex}${colIndex}${internalRowTable ? `internalMobileRow${colIndex}` : ''}`}
        onClick={(event) => {
          if (onColumnClick) {
            event.stopPropagation();
            onColumnClick(it, col.identifier, rowIndex);
          }
        }}
        title={typeof it[col?.identifier] === 'string' ? it[col?.identifier] : undefined}
      >
        {col?.renderColumn ? col?.renderColumn(it, col, rowIndex, colState) : it[col?.identifier]}
      </td>
    ));

  return (
    <Table className={tableClassName}>
      <thead>
        <tr>
          {mobileMode ? null : renderHeaders(headerObj.pinHeaders, true)}
          {renderHeaders(visibleHeaders, false)}
          {mobileMode ? null : renderHeaders(headerObj.pinRightHeaders, true)}
        </tr>
      </thead>
      {data.map((body, bodyIndex) => (
        <tbody key={`tableBody${bodyIndex}`}>
          {body.length > 0 ? (
            body.map((it, rowIndex) => (
              <Fragment key={`fragment${rowIndex}`}>
                {mobileMode ? (
                  <tr key={`responsiveTableRow${bodyIndex}${rowIndex}pinnedRow`}>
                    <td colSpan={visibleHeaders.length}>
                      <table>
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>{renderCols(it, [...headerObj.pinHeaders, ...headerObj.pinRightHeaders], rowIndex, bodyIndex, true)}</tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ) : null}
                <tr
                  className={it?.rowClassName || ''}
                  key={`responsiveTableRow${bodyIndex}${rowIndex}`}
                  onClick={(e) => {
                    if (onRowClick) {
                      e.preventDefault();
                      onRowClick(it, rowIndex);
                    }
                  }}
                >
                  {mobileMode ? null : renderCols(it, headerObj.pinHeaders, rowIndex, bodyIndex)}
                  {renderCols(it, visibleHeaders, rowIndex, bodyIndex)}
                  {mobileMode ? null : renderCols(it, headerObj.pinRightHeaders, rowIndex, bodyIndex)}
                </tr>
              </Fragment>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={visibleHeaders.length + headerObj.pinHeaders.length + headerObj.pinRightHeaders.length}>
                {' '}
                {noDataMesg || 'No Data to show'}
              </td>
            </tr>
          )}
        </tbody>
      ))}
    </Table>
  );
};
