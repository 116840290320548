import { IProfileAnalyticsFilter } from 'analytics/types';
import { dateHelperForMonth } from '../../helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';
import { ANALYTICS_PLATFORM_CHART_OPTIONS, PLATFORM_FILTER } from '../common';
import { CombinedGraphFillColors, FadeCombinedGraphFillColors, GraphColors } from '../../theme/rallio';

export const PROFILE_ANALYTICS_TABLE_HEADERS = [
  { id: 1, title: 'Profiles', value: 'profile' },
  { id: 2, title: 'Profile Views', value: 'profile_views', totalValue: 'profile_views', totalValueDelta: 'profile_views_delta' },
  { id: 3, title: 'Website Visits', value: 'website_visits', totalValue: 'website_visits', totalValueDelta: 'website_visits_delta' },
  { id: 4, title: 'Phone Calls', value: 'phone_calls', totalValue: 'phone_calls', totalValueDelta: 'phone_calls_delta' },
  { id: 5, title: 'Direction Requests', value: 'direction_requests', totalValue: 'direction_requests', totalValueDelta: 'direction_requests_delta' },
  { id: 6, title: 'Button Clicks', value: 'button_clicks', totalValue: 'button_clicks', totalValueDelta: 'button_clicks_delta' }
];

export const PROFILE_ANALYTICS_PLATFORM_FILTER = [PLATFORM_FILTER[0], PLATFORM_FILTER[1], PLATFORM_FILTER[6]];

export const PROFILE_STATS = [
  {
    id: 0,
    title: 'Profile Views',
    color: GraphColors.PROFILE_VIEW,
    fillColor: CombinedGraphFillColors.PROFILE_VIEW,
    fadeColor: FadeCombinedGraphFillColors.PROFILE_VIEW,
    value: 'profile_views',
    colorKey: 'PROFILE_VIEW',
    class: 'pa-pv-text'
  },
  {
    id: 1,
    title: 'Website Clicks',
    color: GraphColors.WEBSITE_CLICKS,
    fillColor: CombinedGraphFillColors.WEBSITE_CLICKS,
    fadeColor: FadeCombinedGraphFillColors.WEBSITE_CLICKS,
    value: 'website_visits',
    colorKey: 'WEBSITE_CLICKS',
    class: 'pa-wc-text'
  },
  {
    id: 2,
    title: 'Phone Calls',
    color: GraphColors.PHONE_CALLS,
    fillColor: CombinedGraphFillColors.PHONE_CALLS,
    fadeColor: FadeCombinedGraphFillColors.PHONE_CALLS,
    value: 'phone_calls',
    colorKey: 'PHONE_CALLS',
    class: 'pa-pc-text'
  },
  {
    id: 3,
    title: 'Direction Requests',
    color: GraphColors.DIRECTION_REQUEST,
    fillColor: CombinedGraphFillColors.DIRECTION_REQUEST,
    fadeColor: FadeCombinedGraphFillColors.DIRECTION_REQUEST,
    value: 'direction_requests',
    colorKey: 'DIRECTION_REQUEST',
    class: 'pa-dr-text'
  },
  {
    id: 4,
    title: 'Button Clicks',
    color: GraphColors.BUTTON_CLICKS,
    fillColor: CombinedGraphFillColors.BUTTON_CLICKS,
    fadeColor: FadeCombinedGraphFillColors.BUTTON_CLICKS,
    value: 'button_clicks',
    colorKey: 'BUTTON_CLICKS',
    class: 'pa-bc-text'
  }
];

// export const TOOLT_TIP_COLOR_PA = [
//   {
//     label: 'Profile Views',
//     value: 'profile_views',
//     class: 'pa-pv-text',
//     color: GraphColors.PROFILE_VIEW,
//     fillColor: CombinedGraphFillColors.PROFILE_VIEW,
//     fadeColor: FadeCombinedGraphFillColors.PROFILE_VIEW
//   },
//   {
//     label: 'Website Clicks',
//     class: 'pa-wc-text',
//     value: 'website_visits',
//     color: GraphColors.WEBSITE_CLICKS,
//     fillColor: CombinedGraphFillColors.WEBSITE_CLICKS,
//     fadeColor: FadeCombinedGraphFillColors.WEBSITE_CLICKS
//   },
//   {
//     label: 'Phone Calls',
//     class: 'pa-pc-text',
//     value: 'phone_calls',
//     color: GraphColors.PHONE_CALLS,
//     fillColor: CombinedGraphFillColors.PHONE_CALLS,
//     fadeColor: FadeCombinedGraphFillColors.PHONE_CALLS
//   },
//   {
//     label: 'Direction Requests',
//     class: 'pa-dr-text ',
//     value: 'direction_requests',
//     color: GraphColors.DIRECTION_REQUEST,
//     fillColor: CombinedGraphFillColors.DIRECTION_REQUEST,
//     fadeColor: FadeCombinedGraphFillColors.DIRECTION_REQUEST
//   },
//   {
//     label: 'Button Clicks',
//     value: 'button_clicks',
//     class: 'pa-bc-text',
//     color: GraphColors.BUTTON_CLICKS,
//     fillColor: CombinedGraphFillColors.BUTTON_CLICKS,
//     fadeColor: FadeCombinedGraphFillColors.BUTTON_CLICKS
//   }
// ];

export const PROFILE_ANALYTICS_FILTER_INITIAL_VALUE: IProfileAnalyticsFilter = {
  dateRange: dateHelperForMonth(true),
  platform: COMMON_SOCIAL_PROVIDER_TYPE.ALL,
  locationId: null,
  ancestorFranchisorId: null,
  locationListId: null,
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ
};

export const PROFILE_ANALYTICS_PIE_LEGEND = [
  { image: 'google-lv.svg', class: 'txt-li', name: 'Google', value: 'google' },
  { image: 'fb-lv.svg', class: 'txt-fb', name: 'Facebook', value: 'facebook' }
];

export const PROFILE_PLATFORM_CHART_OPTIONS = [ANALYTICS_PLATFORM_CHART_OPTIONS[0], ANALYTICS_PLATFORM_CHART_OPTIONS[1], ANALYTICS_PLATFORM_CHART_OPTIONS[5]];
