import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  campaignsListResponse,
  campaignsListFailure,
  campaignsStatsResponse,
  campaignsStatsFailure,
  campaignPostsDeleteEventResponse,
  campaignPostsDeleteEventFailure,
  createOrEditCampaignResponse,
  createOrEditCampaignFailure,
  addPostSetCampaignData,
  getCampaignDetailResponse,
  getCampaignDetailFailure,
  campaignSortPostsListResponse,
  campaignSortPostsListFailure,
  campaignsDeletePostsResponse,
  campaignsDeletePostsFailure,
  getCampaignsEventListResponse,
  getCampaignsEventListFailure,
  getCampaignsEventResponse,
  getCampaignsEventFailure,
  stopCampaignEventResponse,
  stopCampaignEventFailure,
  createOrUpdateCampaignEventResponse,
  createOrUpdateCampaignEventFailure,
  addPostsToCampaignFailure,
  addPostsToCampaignResponse,
  campaignsPostListResponse,
  campaignsPostListFailure,
  deleteCampaignFailure,
  deactivateCampaignEventResponse,
  deactivateCampaignEventFailure
} from 'actions';
import {
  USER_OWNERSHIP,
  CAMPAIGNS_EXCEPTION_ERROR,
  CAMPAIGNS_LIST_FAIL_MSG,
  CAMPAIGNS_STATS_FAIL_MSG,
  CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAIL_MSG,
  CAMPAIGN_POST_DELETE_EVENT_FAIL_MSG,
  CREATE_OR_UPDATE_CAMPAIGN_FAIL_MSG,
  GET_CAMPAIGN_DETAIL_FAIL_MSG,
  CAMPAIGN_SORT_POSTS_LIST_FAIL_MSG,
  CAMPAIGN_POST_ADD_CAMPAIGN_SUCCESS_MSG,
  CAMPAIGNS_DELETE_POSTS_FAIL_MSG,
  CAMPAIGNS_EVENT_LIST_FAIL_MSG,
  CAMPAIGN_POST_UPDATE_CAMPAIGN_SUCCESS_MSG,
  SIDE_NAV_NAME,
  PAGINATION_PER_PAGE,
  CAMPAIGNS_EVENT_FAIL_MSG,
  STOP_CAMPAIGN_EVENT_FAIL_MSG,
  ADD_POSTS_TO_CAMPAIGN_FAIL_MSG,
  CAMPAIGNS_POST_LIST_FAIL_MSG,
  INVALID_INPUT_ERROR_MSG,
  CAMPAIGN_ALREADY_SCHEDULED_LOC_ERROR_MSG,
  DELETE_CAMPAIGN_FAIL_MSG,
  ALERT_BOX_TYPES,
  DELETE_CAMPAIGN_SUCCESS_MSG,
  DEACTIVATE_CAMPAIGN_EVENT_FAIL_MSG
} from 'utils/constants';
import { apiCall, defaultHeader, API, getAddPostToCampaignSuccessMsg } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { store } from 'store';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendCampaignsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: payload.id } : { account_id: payload.id }),
      search: payload.text,
      date_range: payload.dateRange,
      source: payload.source,
      status: payload.status,
      ...(payload.page && { page: payload.page, per: PAGINATION_PER_PAGE })
    };
    const response = yield apiCall({ headers, params, apiPath: API.campaignsList.apiPath, action: API.campaignsList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignsListResponse(response.data.campaigns));
    } else {
      yield put(campaignsListFailure(CAMPAIGNS_LIST_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCampaignsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: payload.id } : { account_id: payload.id }),
      search: payload.text,
      source: payload.source,
      date_range: payload.dateRange
    };
    const response = yield apiCall({ headers, params, apiPath: API.campaignStats.apiPath, action: API.campaignStats.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignsStatsResponse(response.data));
    } else {
      yield put(campaignsStatsFailure(CAMPAIGNS_STATS_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendGetCampaignsEventListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: payload.id } : { account_id: payload.id }),
      ...(payload.page && { page: payload.page, per: PAGINATION_PER_PAGE }),
      status: payload.status
    };
    const response = yield apiCall({ headers, params, apiPath: API.getCampaignEventList.apiPath.replace(':campaignId', payload.campaignId), action: API.getCampaignEventList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getCampaignsEventListResponse(response.data.campaign_events));
    } else {
      yield put(getCampaignsEventListFailure(CAMPAIGNS_EVENT_LIST_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendGetCampaignsEventRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      apiPath: API.getCampaignEvent.apiPath.replace(':campaignId', payload.campaignId).replace(':eventId', payload.eventId),
      action: API.getCampaignEvent.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getCampaignsEventResponse(response.data.campaign_event));
    } else {
      yield put(getCampaignsEventFailure(CAMPAIGNS_EVENT_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCreateOrUpdateCampaignEventRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      campaign_event: payload.campaignEvent
    };
    let response;
    if (payload.campaignEventId) {
      response = yield apiCall({
        headers,
        data,
        apiPath: API.updateCampaignEvent.apiPath.replace(':campaignId', payload.campaignId).replace(':campaignEventId', payload.campaignEventId),
        action: API.updateCampaignEvent.action
      });
    } else {
      response = yield apiCall({ headers, data, apiPath: API.createCampaignEvent.apiPath.replace(':campaignId', payload.campaignId), action: API.createCampaignEvent.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(createOrUpdateCampaignEventResponse(response.data));
    } else {
      yield put(
        createOrUpdateCampaignEventFailure(
          response.data?.errors === INVALID_INPUT_ERROR_MSG ? CAMPAIGN_ALREADY_SCHEDULED_LOC_ERROR_MSG : response.data?.errors || CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAIL_MSG
        )
      );
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendStopCampaignEventRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      apiPath: API.stopCampaignEvent.apiPath.replace(':campaignId', payload.campaignId).replace(':eventId', payload.eventId),
      action: API.stopCampaignEvent.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(stopCampaignEventResponse());
    } else {
      yield put(stopCampaignEventFailure(STOP_CAMPAIGN_EVENT_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCampaignPostsDeleteEventRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      apiPath: API.deleteCampaignEvent.apiPath.replace(':campaignId', payload.campaignId).replace(':eventId', payload.eventId),
      action: API.deleteCampaignEvent.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignPostsDeleteEventResponse(payload.isSuccessMsgHide));
    } else {
      yield put(campaignPostsDeleteEventFailure(CAMPAIGN_POST_DELETE_EVENT_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCreateOrEditCampaignRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      campaign: payload.data
    };
    const response = yield apiCall({
      headers,
      data,
      apiPath: !payload.campaignId ? API.createCampaign.apiPath : API.updateCampaign.apiPath.replace(':campaignId', payload.campaignId),
      action: !payload.campaignId ? API.createCampaign.action : API.updateCampaign.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const campaignData = response.data.campaign;
      yield put(createOrEditCampaignResponse(campaignData.id ? (!payload.campaignId ? CAMPAIGN_POST_ADD_CAMPAIGN_SUCCESS_MSG : CAMPAIGN_POST_UPDATE_CAMPAIGN_SUCCESS_MSG) : null));
      if (payload.pageName === SIDE_NAV_NAME.CONTENT_CREATOR) {
        const retainedCampaign = store.getState().addPostFranchisorAction.campaignData;
        yield put(addPostSetCampaignData([...retainedCampaign, { id: campaignData.id, name: campaignData.name, active: campaignData.active || false }]));
      }
    } else {
      yield put(createOrEditCampaignFailure(response.data?.errors?.name[0] ? `Campaign ${response.data.errors.name[0]}` : CREATE_OR_UPDATE_CAMPAIGN_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendGetCampaignDetailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.franchisorId ? { franchisor_id: payload.franchisorId } : { account_id: payload.accountId }),
      include_all_account_ids: 1
    };
    const response = yield apiCall({ headers, params, apiPath: API.getCampaignDetail.apiPath.replace(':campaignId', payload.campaignId), action: API.getCampaignDetail.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getCampaignDetailResponse(response.data.campaign));
    } else {
      yield put(getCampaignDetailFailure(response.data?.message || GET_CAMPAIGN_DETAIL_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendDeleteCampaignRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, apiPath: API.deleteCampaign.apiPath.replace(':campaignId', payload.campaignId), action: API.deleteCampaign.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DELETE_CAMPAIGN_SUCCESS_MSG);
      payload.onNavigate();
    } else {
      const errorMsg: string = response.data?.message || DELETE_CAMPAIGN_FAIL_MSG;
      alertBoxCall(ALERT_BOX_TYPES.ERROR, errorMsg);
      yield put(deleteCampaignFailure(errorMsg));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendDeactivateCampaignEventRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, apiPath: API.deactivateCampaignEvent.apiPath.replace(':campaignId', payload), action: API.deactivateCampaignEvent.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deactivateCampaignEventResponse());
    } else {
      yield put(deactivateCampaignEventFailure(response.data?.message || DEACTIVATE_CAMPAIGN_EVENT_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCampaignSortPostsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { content_ids: payload.sortedIds } : { saved_post_ids: payload.sortedIds })
    };
    const response = yield apiCall({ headers, data, apiPath: API.sortCampaignPosts.apiPath.replace(':campaignId', payload.campaignId), action: API.sortCampaignPosts.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignSortPostsListResponse(response.data.message));
    } else {
      yield put(campaignSortPostsListFailure(response.data?.message || CAMPAIGN_SORT_POSTS_LIST_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendAddPostsToCampaignRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { content_ids: payload.selectedPostIds } : { saved_post_ids: payload.selectedPostIds })
    };
    const response = yield apiCall({ headers, data, apiPath: API.addPostsToCampaign.apiPath.replace(':campaignId', payload.campaignId), action: API.addPostsToCampaign.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostsToCampaignResponse(response.data.message ? getAddPostToCampaignSuccessMsg(payload.selectedPostIds.length) : null));
    } else {
      yield put(addPostsToCampaignFailure(ADD_POSTS_TO_CAMPAIGN_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCampaignsDeletePostsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { content_ids: payload.postIds } : { saved_post_ids: payload.postIds })
    };
    const response = yield apiCall({ headers, params, apiPath: API.deleteCampaignPosts.apiPath.replace(':campaignId', payload.campaignId), action: API.deleteCampaignPosts.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignsDeletePostsResponse(response.data.message));
    } else {
      yield put(campaignsDeletePostsFailure(response.data?.message || CAMPAIGNS_DELETE_POSTS_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

function* sendCampaignsPostListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      campaign_id: payload.campaignId
    };
    let response;
    if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      response = yield apiCall({ headers, params, ...API.getContentPosts });
    } else if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
      response = yield apiCall({ headers, params, ...API.getSavedPosts });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(campaignsPostListResponse('content_summaries' in response.data ? response.data.content_summaries : 'saved_post_summaries' in response.data ? response.data.saved_post_summaries : []));
    } else {
      yield put(campaignsPostListFailure(response.data?.message || CAMPAIGNS_POST_LIST_FAIL_MSG));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CAMPAIGNS_EXCEPTION_ERROR));
  }
}

export function* takeCampaignsRequest() {
  yield takeLatest(types.CAMPAIGNS_LIST_REQUEST, sendCampaignsListRequest);
  yield takeLatest(types.CAMPAIGNS_STATS_REQUEST, sendCampaignsStatsRequest);
  yield takeLatest(types.GET_CAMPAIGNS_EVENT_LIST_REQUEST, sendGetCampaignsEventListRequest);
  yield takeLatest(types.GET_CAMPAIGNS_EVENT_REQUEST, sendGetCampaignsEventRequest);
  yield takeLatest(types.CREATE_OR_UPDATE_CAMPAIGN_EVENT_REQUEST, sendCreateOrUpdateCampaignEventRequest);
  yield takeLatest(types.STOP_CAMPAIGN_EVENT_REQUEST, sendStopCampaignEventRequest);
  yield takeLatest(types.CAMPAIGN_POSTS_DELETE_EVENT_REQUEST, sendCampaignPostsDeleteEventRequest);
  yield takeLatest(types.CREATE_OR_EDIT_CAMPAIGN_REQUEST, sendCreateOrEditCampaignRequest);
  yield takeLatest(types.GET_CAMPAIGN_DETAIL_REQUEST, sendGetCampaignDetailRequest);
  yield takeLatest(types.DELETE_CAMPAIGN_REQUEST, sendDeleteCampaignRequest);
  yield takeLatest(types.DEACTIVATE_CAMPAIGN_EVENT_REQUEST, sendDeactivateCampaignEventRequest);
  yield takeLatest(types.CAMPAIGN_SORT_POSTS_LIST_REQUEST, sendCampaignSortPostsListRequest);
  yield takeLatest(types.ADD_POSTS_TO_CAMPAIGN_REQUEST, sendAddPostsToCampaignRequest);
  yield takeLatest(types.CAMPAIGNS_DELETE_POSTS_REQUEST, sendCampaignsDeletePostsRequest);
  yield takeLatest(types.CAMPAIGNS_POST_LIST_REQUEST, sendCampaignsPostListRequest);
}
