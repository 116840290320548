import { overviewReducerType } from 'types';

export const OVERVIEW_POST_API_PROPS = {
  FB: 'facebook_posts_count',
  TWIT: 'twitter_posts_count',
  INSTA: 'instagram_posts_count',
  LINKIN: 'linkedin_posts_count'
};

export const OVERVIEW_ALL_VALUE = 'All';

export const OVERVIEW_INIT_LOCATION_DATA: overviewReducerType.IOverviewLocationData = {
  accounts_count: 0,
  accounts: []
};

export const OVERVIEW_INIT_ASSETS_STATS: overviewReducerType.IOverviewAssetsStats = {
  isAssetsStatsFetching: false,
  totalAssetsCount: 0,
  usedAssetsCount: 0,
  recentMediaAssetsCount: 0,
  recentMediaAssetDate: null,
  usersCount: 0,
  totalPhotosCount: 0,
  totalVideosCount: 0,
  unusedPhotosCount: 0,
  unusedVideosCount: 0,
  usedPhotosCount: 0,
  usedVideosCount: 0,
  activeUsersCount: 0,
  activeUsersDelta: 0,
  activeUsersDeltaPercentage: 0,
  inactiveUsersCount: 0
};

export const OVERVIEW_INIT_POST_METRICS: overviewReducerType.IOverviewPostMetrics = {
  isPostMetricsFetching: false,
  postsCount: 0,
  publishedPosts: 0,
  usedPosts: 0
};

export const OVERVIEW_INITIAL_POST_RESULTS = {
  facebookPostsCount: [],
  twitterPostsCount: [],
  instagramPostsCount: [],
  linkedinPostsCount: []
};

export const OVERVIEW_INIT_MONTHLY_POST_METRICS: overviewReducerType.IOverviewMonthlyPostMetrics = {
  isMonthlyPostMetricsFetching: false,
  totalPostsResult: { ...OVERVIEW_INITIAL_POST_RESULTS },
  publishedPostsResult: { ...OVERVIEW_INITIAL_POST_RESULTS },
  usedPostsResult: { ...OVERVIEW_INITIAL_POST_RESULTS }
};
