import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SalesAction } from 'actions';
import { salesReducerTypes } from 'types';
import { SALES_INIT_QUERY_OBJ, SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE, REVV_INIT_LOGIN_OBJ } from 'utils/constants';

type ISalesReducer = salesReducerTypes.ISalesReducer;

const initialState: ISalesReducer = {
  isFetching: false,
  message: null,
  error: null,
  isDemoModalOpen: false,
  salesQueryObj: SALES_INIT_QUERY_OBJ,
  revvLoginObj: REVV_INIT_LOGIN_OBJ
};

export const salesReducer: Reducer<ISalesReducer, SalesAction> = (state = initialState, action: SalesAction): ISalesReducer => {
  switch (action.type) {
    case types.SALES_UPDATE_QUERY_FIELDS:
      return {
        ...state,
        salesQueryObj: action.payload
      };
    // SALES SCHEDULE DEMO CONTACT EMAIL
    case types.SCHEDULE_DEMO_CONTACT_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SCHEDULE_DEMO_CONTACT_EMAIL_RESPONSE:
      return {
        ...state,
        isFetching: false,
        message: SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE
      };
    case types.SCHEDULE_DEMO_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.SALES_IS_DEMO_MODAL_OPEN:
      return {
        ...state,
        isDemoModalOpen: action.payload
      };
    // SCHEDULE_DEMO_CONTACT_EMAIL RESET MESSAGE
    case types.SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE_RESET:
      return {
        ...state,
        message: null,
        error: null
      };
    // REVV LOGIN
    case types.REVV_LOGIN_REQUEST:
      return {
        ...state,
        revvLoginObj: {
          ...initialState.revvLoginObj,
          isFetching: true
        }
      };
    case types.REVV_LOGIN_RESPONSE:
      return {
        ...state,
        revvLoginObj: {
          ...state.revvLoginObj,
          isFetching: false
        }
      };
    case types.REVV_LOGIN_FAILURE:
      return {
        ...state,
        revvLoginObj: {
          ...state.revvLoginObj,
          isFetching: false,
          errorMsg: action.payload
        }
      };
    default:
      return state;
  }
};
