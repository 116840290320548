import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router';

import { IStore } from 'types/reducers/store';
import {
  logoutRequest,
  toggleFilter,
  toggleNav,
  loginUpdateEncodedUserInfo,
  changePasswordResetAll,
  creatorSetComponentName,
  restoreSession,
  setIsDataLoading,
  setAISubscriptionDetailsModal,
  aiGetSubscriptionPlanRequest,
  updateCurrentUserDetailsRequest,
  communicationsListGetRequest,
  communicationDeleteRequest
} from 'actions';
import { DropDownComponent } from 'layouts';
import { HeaderTabs } from './HeaderTabs';
import { ActivateAccount, ChangePasswordModalPopup, NotificationSettingsModalPopup, UserDetailModal, ListCommunications, CreateCommunications } from 'components/Common/Modal';
import {
  LOGIN_USER_INFO_KEY,
  USER_OWNERSHIP,
  IMPERSONATE_RESPONSE_USER_KEY,
  ROUTES,
  APP_TITLE,
  HELP_URL,
  VALID_APP_URL_TITLES,
  CHILD_ROUTES,
  VALID_APP_URL,
  SIDE_NAV_NAME,
  COMMUNICATION_DELETE_CONFIRM_MSG,
  contentSupplierHelpUrl,
  csLocationHelpUrl
} from 'utils/constants';
import { handleLinkNavigation, navigateToValidRoute } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useNavBarData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { shutdownIntercomBot } from 'utils/helpers/external-script';
import { AISubscriptionModal } from 'components/Common/Modal/AISubscriptionModal';
import { communicationReducerTypes, loginReducerType } from 'types';
import { DeleteConfirmationModal } from 'components';

export const Header: React.FC<any> = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const location = useLocation();

  const communicationRef = useRef<any>(null);
  const bellRef = useRef<any>(null);

  const { subNavPageName } = useNavBarData();
  const { id, userOwnership, isValidId, isAdminScreenEnabled } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { queryParams, showNotificationSettings, showUserDetail } = useParamsDeconstructor();

  // const franchisorLogoUrl = useSelector((state: IStore) => state.franchisors?.franchisorDetails?.logo_url || '');
  // const topLevelBrandLogoUrl = useSelector((state: IStore) => state.accountSwitcher?.brandImageUrl || '');
  const mobileFilter = useSelector((state: IStore) => state.common.mobileFilter);
  const showFilterBtn = useSelector((state: IStore) => state.common.showFilterBtn);
  const leftNav = useSelector((state: IStore) => state.common.leftNav);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);
  const isAccountActivated = useSelector((state: IStore) => state.accounts.accountDetails?.account?.activated);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url) || '';
  // const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name) || '';
  // const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name) || '';
  const impersonatedUserId = useSelector((state: IStore) => state.login.activeUser?.impersonated_user_id);
  const impersonatedUserName = useSelector((state: IStore) => state.login.activeUser?.impersonated_user_name);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const error = useSelector((state: IStore) => state.errorBoundary.error);
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  // const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const isAiSubscriptionFetching = useSelector((state: IStore) => state.aiContentCreator.isAiSubscriptionFetching);
  const message = useSelector((state: IStore) => state.communications.message);
  const remainingSeenCount = useSelector((state: IStore) => state.communications.remainingSeenCount);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showListCommunications, setShowListCommunications] = useState(false);
  const [showCreateCommunicationModal, setShowCreateCommunicationModal] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState<communicationReducerTypes.ICommunicationList | null>(null);

  const franchisorSubscriptionTypeId = accSubscriptionDetails?.franchisor_subscription_type?.plan_name;

  const isOpenAccountActivate = isDataLoading || userOwnership === USER_OWNERSHIP.FRANCHISOR ? false : !isAdminScreenEnabled && accountId ? !isAccountActivated : false;

  const headerRestrictions = useMemo(() => {
    const restrictions = {
      showLogo: error ? false : true,
      notificationSettings: false,
      changePassword: false,
      hideDropdown: false,
      isShowNeedHelp: false,
      isShowTabProfileName: false
    };

    const siteRestrictions = menuAccess?.site_restrictions;
    if (id && userOwnership && isValidId) {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        restrictions.showLogo = !Boolean(siteRestrictions?.hub_user?.includes('logo'));
        restrictions.notificationSettings = Boolean(siteRestrictions?.hub_user?.includes('profile/notification_settings'));
        restrictions.changePassword = Boolean(siteRestrictions?.hub_user?.includes('profile/change_password'));
        restrictions.hideDropdown = Boolean(siteRestrictions?.hub_user?.includes('profile/dropdown'));
        restrictions.isShowNeedHelp = Boolean(siteRestrictions?.hub_user?.includes('need_help'));
        restrictions.isShowTabProfileName = Boolean(siteRestrictions?.hub_user?.includes('profile'));
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
        restrictions.showLogo = !Boolean(siteRestrictions?.location_user?.includes('logo'));
        restrictions.notificationSettings = Boolean(siteRestrictions?.location_user?.includes('profile/notification_settings'));
        restrictions.changePassword = Boolean(siteRestrictions?.location_user?.includes('profile/change_password'));
        restrictions.hideDropdown = Boolean(siteRestrictions?.location_user?.includes('profile/dropdown'));
        restrictions.isShowNeedHelp = Boolean(siteRestrictions?.location_user?.includes('need_help'));
        restrictions.isShowTabProfileName = Boolean(siteRestrictions?.location_user?.includes('profile'));
      }
    }
    return restrictions;
  }, [menuAccess, id, userOwnership, isValidId, error]);

  // Render CDN changes while loading in admin area pages
  useEffect(() => {
    if (isAdminScreenEnabled && isDataLoading) dispatch(setIsDataLoading(false));
  }, [isAdminScreenEnabled, isDataLoading, dispatch]);

  // If the subpage is invalid for particular franchisor/account, then navigate to valid subpage
  useEffect(() => {
    if (!isDataLoading && !VALID_APP_URL.includes(location.pathname.split('/')[1]) && Object.values(SIDE_NAV_NAME).includes(subNavPageName)) {
      navigateToValidRoute(id, userOwnership, isAdmin, subNavPageName, menuAvailablity, navigate);
    }
  }, [isDataLoading, menuAvailablity]); // eslint-disable-line

  useEffect(() => {
    if (id && userOwnership && isValidId) {
      dispatch(creatorSetComponentName(userOwnership));
    } else {
      dispatch(creatorSetComponentName(null));
    }
  }, [id, userOwnership, isValidId, dispatch]);

  useEffect(() => {
    if (userOwnership && id) {
      dispatch(communicationsListGetRequest({ userOwnership, id }));
    }
  }, [userOwnership, id, dispatch, message]); // eslint-disable-line

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (communicationRef?.current && !communicationRef?.current?.contains(event.target) && !showCreateCommunicationModal && !deleteConfirmationModalOpen) {
        setShowListCommunications(false);
      } else if (bellRef?.current && bellRef?.current?.contains(event.target)) {
        setShowListCommunications((prev) => !prev);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [communicationRef, bellRef, showCreateCommunicationModal, deleteConfirmationModalOpen]);

  const handleRestoreSession = () => {
    if (activeUserId) dispatch(restoreSession(activeUserId));
    navigate(`/admin_area/accounts`);
  };

  const updateUserDetails = (values: loginReducerType.ILoginResponseUser) => {
    dispatch(updateCurrentUserDetailsRequest({ id: values.id, reqPayload: values }));
  };

  const handlecommunicationDelete = () => {
    if (selectedCommunication?.id) {
      dispatch(communicationDeleteRequest(selectedCommunication?.id));
      setSelectedCommunication(null);
    }
  };

  const isValidRoute = useMemo(() => {
    const routeMatch = (path: string) => matchPath(path, location.pathname)?.pathname;
    const routesArray = Object.values(ROUTES).slice(7);
    const childRouteKeys = Object.keys(CHILD_ROUTES);
    const routes: any = ROUTES;
    const childRoute: any = CHILD_ROUTES;

    const childRoutes = childRouteKeys.reduce((acc: string[], curr) => {
      const parentRoute: any = routes[curr];
      const childRouteObj = childRoute[curr];
      const routesArr = Object.values(childRouteObj).map((it) => `${parentRoute}/${it}`);
      acc = acc.concat(routesArr);
      return acc;
    }, []);
    return routesArray.concat(childRoutes).filter((it) => routeMatch(it))?.length > 0;
  }, [location.pathname]);

  return (
    <div className="head-menu">
      {/* { (isDesktop || isBrowser || isIPad13 || isTablet || isIOS13) && */}
      <header className={`header ${leftNav ? 'nav-open' : ''}`}>
        {/* <header className='header'> */}
        <>
          {/* <button className='mob-sidenav--btn' onClick={() => dispatch(toggleNav())} /> */}
          <div className="brand">
            <div className="mobile ham-icon">
              <div className="ham__icon--wrp" onClick={() => dispatch(toggleNav())}>
                <input type="checkbox" id="check" />
                <span />
                <span />
                <span />
              </div>
            </div>
            {headerRestrictions.showLogo && !isDataLoading && isValidRoute ? (
              <div className="logo">
                <ImageValidation
                  // imgUrl={!id && !userOwnership ? null : userOwnership === USER_OWNERSHIP.FRANCHISOR ? franchisorLogoUrl : topLevelBrandLogoUrl}
                  defaultImg={'logo'}
                  customName={'Logo'}
                />
              </div>
            ) : null}
          </div>

          {!error && !isDataLoading && isValidRoute && (
            <div className="sub-nav-tabs animate__animated animate__fadeIn">
              <HeaderTabs hideLogo={!headerRestrictions.showLogo} isTabProfileNamechange={headerRestrictions.isShowTabProfileName} />
            </div>
          )}

          <div className="brand-profile h-assorted">
            {!isAdminScreenEnabled && !error && isValidRoute && <DropDownComponent />}

            <div className="btn-icon ha-item bc-wrap">
              {remainingSeenCount ? (
                <div className="nmc-count">
                  <span>{remainingSeenCount}</span>
                </div>
              ) : null}
              <div className={`bl-wrap ${remainingSeenCount ? 'blc-on' : 'blc-off'}`} ref={bellRef}>
                <ImageValidation customClassname="bell-icon" defaultImg="notifyMessage" customName={'Communications'} />
              </div>
            </div>

            {!headerRestrictions.isShowNeedHelp && !VALID_APP_URL_TITLES.includes(APP_TITLE) ? (
              <Link
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  const helpUrl = `${isContentSupplier ? contentSupplierHelpUrl : isCSLocation ? csLocationHelpUrl : HELP_URL}/`;
                  handleLinkNavigation(helpUrl);
                }}
                className="btn-icon ha-item help-rallio"
                target="_blank"
              >
                <ImageValidation customClassname="help__rallio--icon" defaultImg="help-blue" customName={'Knowledge Base'} />
              </Link>
            ) : null}

            {headerRestrictions.hideDropdown ? (
              <div className="drop-items ha-item profile-items">
                <div className="btn-icon ripple">
                  <div className="btc-img">
                    <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={'Profile Img Item'} />
                  </div>
                </div>
              </div>
            ) : (
              <Dropdown className="drop-items ha-item profile-items">
                <Dropdown.Toggle id="dropitems-profile-items" className="btn-icon ripple">
                  <ImageValidation imgUrl={activeUserProfilePhotoUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={'head__profile--avatar'} customName={'Profile Img Item'} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    // title={`${activeUserFirstName} ${activeUserLastName}`}
                    title={`My Profile`}
                    onClick={() => {
                      if (activeUserId) {
                        navigate({ search: `?${new URLSearchParams({ ...queryParams, active_user_id: activeUserId.toString() }).toString()}` });
                      }
                    }}
                    className="top-usr-name d-flex justify-content-between align-items-center"
                  >
                    {/* https://rallio.atlassian.net/browse/RAS-3443 <span>{`${activeUserFirstName} ${activeUserLastName}`}</span> */}
                    <span>My Profile</span>
                    <ImageValidation defaultImg={'edit-s-grey'} customClassname={'edit-icon'} customName={'Edit profile details'} />
                  </Dropdown.Item>
                  {headerRestrictions.changePassword ? null : <Dropdown.Item onClick={() => setShowChangePassword(true)}>Change Password</Dropdown.Item>}
                  {!isAdminScreenEnabled && isRallioai && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          if (!isAiSubscriptionFetching && activeUserId) {
                            dispatch(
                              aiGetSubscriptionPlanRequest({
                                id,
                                userOwnership,
                                userId: activeUserId,
                                ...(franchisorSubscriptionTypeId && { type: 'contentSupplierSubscription' })
                              })
                            );
                            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: !aiSubscriptionDetailsModal.isOpen }));
                          }
                        }}
                      >
                        Subscriptions
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => {
                          dispatch(setAISubscriptionDetailsModal({ type: 'trialPeriod', isOpen: !aiSubscriptionDetailsModal.isOpen }));
                        }}
                      >
                        Payments
                      </Dropdown.Item> */}
                    </>
                  )}
                  {headerRestrictions.notificationSettings ? null : (
                    <Dropdown.Item onClick={() => navigate({ search: `?${new URLSearchParams({ ...queryParams, notification_settings: '1' }).toString()}` })}>Notification Settings</Dropdown.Item>
                  )}

                  <Dropdown.Item
                    onClick={() => {
                      localStorage?.removeItem(LOGIN_USER_INFO_KEY);
                      localStorage?.removeItem(IMPERSONATE_RESPONSE_USER_KEY);
                      dispatch(loginUpdateEncodedUserInfo(null));
                      dispatch(logoutRequest());
                      if (!isAdminScreenEnabled) shutdownIntercomBot();
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                  {impersonatedUserId ? (
                    <Dropdown.Item
                      onClick={() => {
                        handleRestoreSession();
                      }}
                    >{`Restore Session as ${impersonatedUserName}`}</Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {showFilterBtn && !isAdminScreenEnabled ? <button className={`mobile-filter ${mobileFilter ? 'open' : ''}`} onClick={() => dispatch(toggleFilter())} /> : null}
          </div>
        </>
      </header>
      {showUserDetail && (
        <UserDetailModal
          isShowModal={showUserDetail}
          onModalClose={() => {
            const { user_id, active_user_id, ...otherParams } = queryParams;
            navigate({ search: `?${new URLSearchParams(otherParams).toString()}` });
          }}
          updateUserDetails={updateUserDetails}
        />
      )}
      {showChangePassword && (
        <ChangePasswordModalPopup
          isModalShow={showChangePassword}
          handleModalClose={() => {
            dispatch(changePasswordResetAll());
            setShowChangePassword(false);
          }}
        />
      )}
      {aiSubscriptionDetailsModal.isOpen && <AISubscriptionModal />}
      {showNotificationSettings && (
        <NotificationSettingsModalPopup
          isModalShow={showNotificationSettings}
          handleModalClose={() => {
            delete queryParams?.notification_settings;
            navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
          }}
          isNeedHelpShow={!headerRestrictions.isShowNeedHelp}
        />
      )}
      {showListCommunications && (
        <ListCommunications
          setShowListCommunications={setShowListCommunications}
          showCreateCommunicationModal={showCreateCommunicationModal}
          setShowCreateCommunicationModal={setShowCreateCommunicationModal}
          setSelectedCommunication={setSelectedCommunication}
          setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
          communicationRef={communicationRef}
        />
      )}

      {isOpenAccountActivate ? <ActivateAccount /> : null}
      {showCreateCommunicationModal && userOwnership === USER_OWNERSHIP.FRANCHISOR && (
        <CreateCommunications
          isShowModal={showCreateCommunicationModal}
          onModalClose={() => {
            setShowCreateCommunicationModal(false);
            setSelectedCommunication(null);
          }}
          communicationData={selectedCommunication}
        />
      )}
      {deleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isModalShow={deleteConfirmationModalOpen}
          onClose={() => {
            setDeleteConfirmationModalOpen(false);
            setSelectedCommunication(null);
          }}
          onDelete={() => {
            if (deleteConfirmationModalOpen && selectedCommunication) {
              setDeleteConfirmationModalOpen(false);
              handlecommunicationDelete();
            }
          }}
          deleteMsg={COMMUNICATION_DELETE_CONFIRM_MSG}
        />
      )}
    </div>
  );
};
