import { IClientDetailedViewResponse, ILoginResponse, ILoginUserInfo } from 'revv/types';
import { ActionsUnion, createAction } from 'revv/utils';
import * as actionTypes from 'revv/actions/action-types';

export const loginRequest = (payload: ILoginUserInfo) => createAction(actionTypes.SIGN_IN, payload);
export const loginFailure = (payload: string) => createAction(actionTypes.SIGN_IN_FAILURE, payload);
export const loginResponse = (payload: ILoginResponse) => createAction(actionTypes.SIGN_IN_RESPONSE, payload);

export const activeClientResponse = (payload: IClientDetailedViewResponse | null) => createAction(actionTypes.ACTIVE_CLIENT_RESPONSE, payload);

export const logout = () => createAction(actionTypes.LOGOUT);

export const LOGIN_ACTIONS = {
  loginRequest,
  loginFailure,
  loginResponse,
  activeClientResponse,
  logout
};

export type LoginAction = ActionsUnion<typeof LOGIN_ACTIONS>;
