import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { getMomentForDate } from 'utils/helpers';
import { OVERVIEW_PLATFORM_FILTER, OVERVIEW_TABLE_HEADER } from 'analytics/utils/constants';
import { dateEqualHandler, dateFormatter, getDiffDateHandler, subtractDayHandler, sumAverageObjectsInArray } from 'analytics/utils';
import { IKCATimeSeries, IKeywordsCAChartData } from 'analytics/types';

export const secondaryTableHeader = (headers: any, platform: string, tab: string) => {
  return headers.map((it: any) => {
    const totalValue = `${platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : platform}_${it.value}_${tab}`;
    return {
      ...it,
      totalValue,
      totalValueDelta: `${totalValue}_delta`
    };
  });
};

export const selectedTableData = (data: any, pieLegendList: any[], platform: string, tab: string) => {
  return [...pieLegendList]
    .filter((it) => (platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? it : it.value === platform))
    .map((it) => {
      const dataObj = data?.headers.reduce((acc: any, curr: any) => {
        const currentData = data.data.find((it: any) => it.title === curr.value);
        acc = {
          ...acc,
          [`${curr.value}`]: currentData ? currentData[`${it.value}_${tab}`] : undefined,
          [`${curr.value}_delta`]: currentData ? currentData[`${it.value}_${tab}_delta`] : undefined
        };
        return acc;
      }, {});
      return {
        ...it,
        ...dataObj
      };
    });
};

export const tableDateGrouping = (dataByDay: any[], tableHeader: any[] = OVERVIEW_TABLE_HEADER, platformFilter: any[] = OVERVIEW_PLATFORM_FILTER, isSum: boolean = true) => {
  if (dataByDay?.length) {
    // const startDate = moment(dataByDay[0].date);
    // const endDate = moment(dataByDay[dataByDay.length - 1].date);
    const startDate = getMomentForDate(dataByDay[0].date);
    const endDate = getMomentForDate(dataByDay[dataByDay.length - 1].date);
    const clonedStartDate = startDate.clone();
    const clonedEndDate = endDate.clone();
    const dayDifference = endDate.diff(startDate, 'd') + 1;
    const divident = dayDifference <= 31 ? 1 : dayDifference <= 90 ? 7 : dayDifference <= 365 * 3 ? 31 : 366;
    const factor = Math.floor(dayDifference / divident);
    const reminder = dayDifference % divident;
    const length =
      divident === 1
        ? dayDifference
        : divident === 7
        ? factor + (reminder ? 1 : 0)
        : divident === 31
        ? clonedEndDate.endOf('month').diff(clonedStartDate.startOf('month'), 'months') + 1
        : clonedEndDate.endOf('year').diff(clonedStartDate.startOf('year'), 'years') + 1;
    const dates: any[] = [];

    [...Array(length)].forEach((_1, index) => {
      if (index) {
        const addDates = index === factor + (reminder ? 1 : 0) ? reminder || divident : divident;
        const date = divident === 31 ? startDate.add(1, 'month').startOf('month') : divident === 366 ? startDate.add(1, 'year').startOf('year') : startDate.add(addDates, 'day');
        dates.push(date.valueOf());
      } else {
        dates.push(startDate.valueOf());
      }
    });

    const dateFormat = divident === 31 ? 'MMM YYYY' : divident === 366 ? 'YYYY' : 'MMM DD';

    const dateObj = dates.reduce(
      (acc, curr, index) => {
        const momentEnd = dates[index + 1] ? subtractDayHandler(dates[index + 1], 1) : endDate;
        const end =
          getDiffDateHandler(dates[index + 1] || endDate, curr) > 1 ? (dates[index + 1] ? `${subtractDayHandler(dates[index + 1], 1, dateFormat)}` : dateFormatter(`${endDate}`, dateFormat)) : null;

        const start = dateFormatter(curr, dateFormat);
        const header = `${start}${end ? ' - '.concat(end) : ''}`;
        acc.headers.push({
          title: header,
          value: header,
          totalValue: `total_${header}`,
          totalValueDelta: `total_${header}_delta`
        });
        const sumOfPublishedPostsBasedOnMonth = (dateRangeData: any[]) => {
          const finalData = dateRangeData[dateRangeData.length - 1];
          dateRangeData.forEach((data, index) => {
            [...platformFilter].slice(1).forEach((platform) => {
              if (index !== dateRangeData.length - 1) {
                finalData[`${platform.value}_posts`] += +data[`${platform.value}_posts`];
                finalData[`${platform.value}_posts_delta`] += +data[`${platform.value}_posts_delta`];
              }
            });
          });
          return finalData;
        };
        if (end) {
          const dateRangeData = dataByDay.filter((it: any) => dateEqualHandler(it.date, curr, 'YYYY-MM-DD', 'after') && dateEqualHandler(it.date, momentEnd, 'YYYY-MM-DD', 'before'));
          // Sum of all platform published posts count in to last date of month. Remaining follwers and engagement counts are same
          const result = isSum ? sumAverageObjectsInArray(dateRangeData, [], acc.data[acc.data.length - 1]) : sumOfPublishedPostsBasedOnMonth(dateRangeData); // only Sum of all platform published posts count for every month. Remaing follwers and engagement counts are same

          acc.data.push({
            ...result,
            title: header
          });
        } else {
          const result = dataByDay.find((it: any) => dateEqualHandler(it.date, curr, 'YYYY-MM-DD'));
          acc.data.push({
            ...result,
            title: header
          });
        }
        if (index === dates.length - 1) {
          const obj = acc.headers.reduce((headerAcc: any, headerCurr: any, index: number) => {
            if (index) {
              const currentData = acc?.data?.find((it: any) => it.title === headerCurr.title);
              [...platformFilter].slice(1).forEach((platform) => {
                [...tableHeader].slice(1).forEach((header: any) => {
                  const avgKey = platform.value === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? `avg_${headerCurr.value}` : `avg_${platform.value}_${header.value}`;
                  if (!headerAcc[`total_${headerCurr.value}_${header.value}`]) {
                    headerAcc[`total_${headerCurr.value}_${header.value}`] = 0;
                  }
                  if (!headerAcc[`total_${headerCurr.value}_${header.value}_delta`]) {
                    headerAcc[`total_${headerCurr.value}_${header.value}_delta`] = 0;
                  }
                  if (!headerAcc[`brand_avg_total_${headerCurr.value}_${header.value}`]) {
                    headerAcc[`brand_avg_total_${headerCurr.value}_${header.value}`] = 0;
                  }
                  headerAcc[`total_${headerCurr.value}_${header.value}`] += +currentData[`${platform.value}_${header.value}`] || 0;
                  headerAcc[`total_${headerCurr.value}_${header.value}_delta`] += +currentData[`${platform.value}_${header.value}_delta`] || 0;
                  headerAcc[`brand_avg_total_${headerCurr.value}_${header.value}`] +=
                    currentData[`${avgKey}`] === undefined ? +currentData[`avg_total_${header.value}`] || 0 : +currentData[`${avgKey}`] || 0;
                  headerAcc[`${platform.value}_${headerCurr.value}_${header.value}`] = +currentData[`${platform.value}_${header.value}`] || 0;
                  headerAcc[`${platform.value}_${headerCurr.value}_${header.value}_delta`] = +currentData[`${platform.value}_${header.value}_delta`] || 0;
                  headerAcc[`brand_avg_${platform.value}_${headerCurr.value}_${header.value}`] =
                    currentData[`${avgKey}`] === undefined ? +currentData[`avg_total_${header.value}`] || 0 : +currentData[`${avgKey}`] || 0;
                });
              });
            }
            return headerAcc;
          }, {});
          acc.pieChartData = sumAverageObjectsInArray(acc?.data);
          acc.summary = obj;
          acc.headers = acc.headers.map((it: any) => (divident === 31 || divident === 366 ? { ...it, title: it.title.split('-')[0].trim() } : it));
        }
        return acc;
      },
      { headers: [tableHeader[0]], data: [], summary: {}, pieChartData: {} }
    );
    return dateObj;
  } else {
    return { headers: [tableHeader[0]], data: [], summary: {}, pieChartData: {} };
  }
};

export const keywordsCategoryChartData = (keywordsCAChartData: IKCATimeSeries[], activeCategoryIndex?: number | null) => {
  const data = keywordsCAChartData.reduce((acc: IKeywordsCAChartData[], curr, index) => {
    const obj: IKeywordsCAChartData = { date: '', review_keyword_category_score: 0, review_keyword_category_cumulative_score: 0, title: '' };
    const reviewCategory = `review_keyword_category_${activeCategoryIndex}`;
    const positiveCount = activeCategoryIndex ? `${reviewCategory}_positive_count` : `total_positive_count`;
    const negativeCount = activeCategoryIndex ? `${reviewCategory}_negative_count` : `total_negative_count`;
    const score = Number(curr[positiveCount]) - Number(curr[negativeCount]);
    obj[`review_keyword_category_score`] = score;
    obj[`date`] = curr.date.toString();
    if (index === 0) {
      obj[`review_keyword_category_cumulative_score`] = score;
    } else {
      obj[`review_keyword_category_cumulative_score`] = Number(acc[index - 1][`review_keyword_category_cumulative_score`]) + score;
    }
    acc.push(obj);
    return acc;
  }, []);

  return data;
};

export const keywordCAChartDateGrouping = (dataByDay: IKCATimeSeries[], activeCategoryIndex?: number | null) => {
  if (dataByDay?.length) {
    const startDate = getMomentForDate(dataByDay[0].date);
    const endDate = getMomentForDate(dataByDay[dataByDay.length - 1].date);
    const clonedStartDate = startDate.clone();
    const clonedEndDate = endDate.clone();
    const dayDifference = endDate.diff(startDate, 'd') + 1;
    const divident = dayDifference <= 31 ? 1 : dayDifference <= 90 ? 7 : dayDifference <= 365 * 3 ? 31 : 366;
    const factor = Math.floor(dayDifference / divident);
    const reminder = dayDifference % divident;
    const length =
      divident === 1
        ? dayDifference
        : divident === 7
        ? factor + (reminder ? 1 : 0)
        : divident === 31
        ? clonedEndDate.endOf('month').diff(clonedStartDate.startOf('month'), 'months') + 1
        : clonedEndDate.endOf('year').diff(clonedStartDate.startOf('year'), 'years') + 1;
    const dates: any[] = [];

    [...Array(length)].forEach((_1, index) => {
      if (index) {
        const addDates = index === factor + (reminder ? 1 : 0) ? reminder || divident : divident;
        const date = divident === 31 ? startDate.add(1, 'month').startOf('month') : divident === 366 ? startDate.add(1, 'year').startOf('year') : startDate.add(addDates, 'day');
        dates.push(date.valueOf());
      } else {
        dates.push(startDate.valueOf());
      }
    });

    const dateFormat = divident === 31 ? 'MMM YYYY' : divident === 366 ? 'YYYY' : 'MMM DD';

    const chartDataWithCumulativeScore = keywordsCategoryChartData(dataByDay, activeCategoryIndex);

    const dateObj = dates.reduce((acc, curr, index) => {
      const momentEnd = dates[index + 1] ? subtractDayHandler(dates[index + 1], 1) : endDate;
      const end =
        getDiffDateHandler(dates[index + 1] || endDate, curr) > 1 ? (dates[index + 1] ? `${subtractDayHandler(dates[index + 1], 1, dateFormat)}` : dateFormatter(`${endDate}`, dateFormat)) : null;

      const start = dateFormatter(curr, dateFormat);
      const header = start === end ? start : `${start}${end ? ' - '.concat(end) : ''}`;
      if (end) {
        const dateRangeData = chartDataWithCumulativeScore.filter((it: any) => dateEqualHandler(it.date, curr, 'YYYY-MM-DD', 'after') && dateEqualHandler(it.date, momentEnd, 'YYYY-MM-DD', 'before'));
        const result = dateRangeData.find((it: any) => dateEqualHandler(it.date, curr, 'YYYY-MM-DD'));
        acc.push({
          ...result,
          title: header
        });
      } else {
        const result = chartDataWithCumulativeScore.find((it: any) => dateEqualHandler(it.date, curr, 'YYYY-MM-DD'));
        acc.push({
          ...result,
          title: header
        });
      }
      return acc;
    }, []);
    return dateObj;
  }
};
