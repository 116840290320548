import { Form, Button } from 'react-bootstrap';
import Ripples from 'react-ripples';
import { useFormik, FormikProps, FormikTouched, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ModalPopup, DropdownMenu, Toastifier } from 'revv/components';
import { config } from 'config';
import { IStore } from 'types';
import { ISendSurveyModalNonPsp } from 'revv/types';
import { customerSendSurveyRequest, sendSurveyRequest, customerUpdateRequest, activeFilterOptions, customerSendSurveyResponses, customerUploadRequestFailure } from 'revv/actions';
import { settingsCheckOptions } from 'revv/utils';

interface ISendSurveyModal {
  open: boolean;
  closeModal: () => void;
}

export const SendSurveyModal = ({ open, closeModal }: ISendSurveyModal) => {
  const dispatch = useDispatch();
  const [isLocation, setLocationOpen] = useState(false);

  const targetRef = useRef<{ [key: string]: HTMLDivElement }>({});
  const activeClientId = useSelector((state: IStore) => state.revv.login.activeClientId);
  const settingDetails = useSelector((state: IStore) => state.revv.settings.settingsDetails?.attributes);
  const locations = useSelector((state: IStore) => state.revv.filter.hubLocation);
  const isSurevyFetching = useSelector((state: IStore) => state.revv.surveys.isFetching);
  // const isCustomerFetching = useSelector((state: IStore) => state.revv.customers.isFetching);
  const isSendSurveyFetching = useSelector((state: IStore) => state.revv.surveys.sendSurveyMsg.isFetching);
  const successMsg = useSelector((state: IStore) => state.revv.surveys.successMsg);
  const errorMsg = useSelector((state: IStore) => state.revv.customers.error);

  const [toasterMsg, setToasterMsg] = useState('');

  const validationSchema = Yup.object().shape(
    {
      phoneNumber: Yup.string().when('email', {
        is: (val: any) =>
          settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[1].value ||
          settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[0].value ||
          (settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[4].value ? !val : false),
        then: Yup.string().required('Phone Number can\t  be Blank'),
        otherwise: Yup.string()
      }),
      email: Yup.string().when('phoneNumber', {
        is: (val: any) =>
          settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[3].value ||
          settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[2].value ||
          (settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[4].value ? !val : false),
        then: Yup.string().email('Email must be a valid email').required("Email can't be blank"),
        otherwise: Yup.string().email()
      }),
      userName: Yup.string().required("User name can't be blank"),
      locations: Yup.string().notOneOf(['Select Location'], "Locations can't be blank").required("Locations can't be blank"),
      delay: Yup.boolean()
    },
    [['email', 'phoneNumber']]
  );

  useEffect(() => {
    return () => {
      dispatch(activeFilterOptions(''));
    };
  }, [dispatch]);

  const isFetching = useMemo(() => {
    // return isCustomerFetching || isSurevyFetching || isSendSurveyFetching;
    return isSurevyFetching || isSendSurveyFetching;
  }, [isSurevyFetching, isSendSurveyFetching]);

  const handlePhoneNumberEmail = useMemo(() => {
    const phoneNumberEmail = ['phoneNumber', 'email'];
    if (
      settingDetails &&
      (settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[0].value || settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[3].value)
    ) {
      phoneNumberEmail.splice(1, 2);
    } else if (settingDetails && settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[2].value) {
      phoneNumberEmail.splice(0, 1);
    }
    return phoneNumberEmail;
  }, [settingDetails]);

  const formEmail = () => {
    return (
      <Form.Group className="send-survey-modal__grp">
        <Form.Control
          type="email"
          placeholder=""
          name="email"
          className={`form__input ${settingDetails?.survey_delivery_method !== 'sms_preferred' ? handleInvalidField('email') : null}`}
          onChange={handleChange}
          value={values.email}
          onBlur={handleBlur}
          onFocus={() => {
            setFieldTouched('email', false);
          }}
        />
        <Form.Label className="filt_label">
          Email
          {settingDetails?.survey_delivery_method !== 'sms_preferred' && !values.phoneNumber ? <span className="filt_star req__star">*</span> : null}
        </Form.Label>
      </Form.Group>
    );
  };

  const nameSplitter = (value: string) => {
    const nameArr = value.split(' ');
    return {
      lastName: nameArr.length > 1 ? nameArr.pop() : '',
      firstName: nameArr.join(' ')
    };
  };

  const { handleChange, handleSubmit, setFieldValue, values, errors, touched, resetForm, handleBlur, setFieldTouched, isValid, dirty }: FormikProps<ISendSurveyModalNonPsp> =
    useFormik<ISendSurveyModalNonPsp>({
      enableReinitialize: true,
      initialValues: {
        phoneNumber: '',
        email: '',
        userName: '',
        locations: '',
        delay: true
      },
      onSubmit: (value) => {
        const params = { email: value.email || undefined, phoneNumber: value.phoneNumber || undefined };
        dispatch(
          sendSurveyRequest({
            ...params,
            callback: (data) => {
              if (data?.isValid && data?.id && !isFetching && settingDetails?.survey_type !== 'nps') {
                dispatch(
                  customerSendSurveyRequest({
                    attributes: {
                      client_id: Number(activeClientId),
                      location_id: value.locations ? Number(value.locations) : 0,
                      send_survey: value.delay ? 'now' : 'later',
                      customer_id: Number(data.id) || 0
                    },
                    type: 'surveys'
                  })
                );
                // } else if (!isCustomerFetching && (!data?.isValid || settingDetails?.survey_type === 'nps')) {
              } else if (!data?.isValid || settingDetails?.survey_type === 'nps') {
                const name = nameSplitter(value.userName);
                dispatch(
                  customerUpdateRequest({
                    firstName: name.firstName,
                    lastName: name.lastName || '',
                    email: value.email,
                    phoneNumber: value.phoneNumber,
                    receiveSMS: true,
                    sendSurveyNow: value.delay ? 'now' : 'later',
                    isRelationShipDisabled: true,
                    locationId: value.locations ? value.locations : '',
                    callback: () => {
                      setToasterMsg('Survey sent successfully');
                    }
                  })
                );
              }
            }
          })
        );
        resetForm();
        dispatch(activeFilterOptions(''));
      },
      validationSchema
    });

  useEffect(() => {
    setToasterMsg(errorMsg || successMsg);
  }, [errorMsg, successMsg]);

  const handleInvalidField = (label: string) => {
    const touchedField: FormikTouched<{ [key: string]: string }> = touched;
    const errorField: FormikErrors<{ [key: string]: string }> = errors;
    return touchedField[label] && errorField[label] ? 'form-error' : '';
  };

  return (
    <div>
      <ModalPopup
        show={open}
        centered
        fullView
        className="non-psp-send-survey__modal npsp-modal__main non-psp__modal"
        renderHeader={() => (
          <div>
            <div className="send-survey-modal__title">Send Survey</div>
            <div
              className="mod-close__icon cur-pointer"
              onClick={() => {
                closeModal();
                resetForm();
              }}
            >
              <img src={`${config.cdnRevvImgUrl}/modal-close.svg`} className="non-psp-mod-close" alt="close-icon" />
            </div>
          </div>
        )}
        renderBody={() => (
          <div className="send-survey-modal__form">
            <Form onSubmit={handleSubmit}>
              {settingDetails?.survey_delivery_method === settingsCheckOptions.surveyDelivery[3].value ? formEmail() : null}
              {handlePhoneNumberEmail.includes('phoneNumber') ? (
                <Form.Group className="send-survey-modal__grp">
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="phoneNumber"
                    className={`form__input ${settingDetails?.survey_delivery_method !== 'email_preferred' ? handleInvalidField('phoneNumber') : null}`}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    onFocus={() => {
                      setFieldTouched('phoneNumber', false);
                    }}
                  />
                  <Form.Label className="filt_label">
                    Phone Number
                    {settingDetails?.survey_delivery_method !== 'email_preferred' && !values.email ? <span className="filt_star req__star">*</span> : null}
                  </Form.Label>
                </Form.Group>
              ) : null}
              {handlePhoneNumberEmail.includes('email') ? formEmail() : null}
              <Form.Group className="send-survey-modal__grp">
                <Form.Control
                  type="text"
                  placeholder=""
                  name="userName"
                  onChange={handleChange}
                  value={values.userName}
                  className={`form__input ${handleInvalidField('userName')}`}
                  onBlur={handleBlur}
                  onFocus={() => {
                    setFieldTouched('userName', false);
                  }}
                />
                <Form.Label className="filt_label">
                  Name
                  <span className="filt_star req__star">*</span>
                </Form.Label>
              </Form.Group>
              <Form.Group className="send-survey-modal__grp custom-dropdown--menu">
                <Form.Control
                  onClick={() => setLocationOpen(!isLocation)}
                  name="locations"
                  className={`dropdown-trigger dropdown__select form__input ${isLocation ? 'active' : ''}  ${handleInvalidField('locations')}`}
                  value={locations && values.locations ? locations.filter((it) => String(it.id) === String(values.locations))[0]?.name : 'Select Location'}
                  ref={(ip: any) => (targetRef.current[`locations`] = ip)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={() => {
                    setFieldTouched('locations', false);
                  }}
                  readOnly
                />
                <DropdownMenu
                  isActive={isLocation}
                  setActive={setLocationOpen}
                  options={
                    locations
                      ? locations.map((it): any => ({
                          value: it.id,
                          label: it.name
                        }))
                      : []
                  }
                  hideOnOutsideClick
                  hideOnSelect
                  excludeTargets={[targetRef.current[`locations`]]}
                  onSelect={(option) => {
                    setFieldValue('locations', option.value);
                  }}
                  selectedClass={(option) => {
                    return option.value === values.locations ? 'active' : '';
                  }}
                  searchable
                />
                <Form.Label className="filt_label">
                  Locations
                  <span className="filt_star req__star">*</span>
                </Form.Label>
              </Form.Group>
              <Form.Group className="send-survey-modal__grp" controlId="send-survey">
                <Form.Check
                  className="send-survey-modal__check d-flex align-items-center"
                  type="checkbox"
                  label="Send Later"
                  name="delay"
                  checked={!values.delay}
                  onChange={() => {
                    setFieldValue('delay', !values.delay);
                  }}
                />
                <p className="add-check__text">
                  (Use the <strong>“Send Queued Surveys”</strong> button on the <strong>Survey</strong> page)
                </p>
              </Form.Group>
              <Ripples className={`${isFetching || !isValid || !dirty ? 'custom-disabled' : ''} survey-btn__ripple`}>
                <Button size="lg" type="submit" className="send-survey-modal__btn cust-survey__btn">
                  Send Survey
                </Button>
              </Ripples>
            </Form>
          </div>
        )}
      />
      <Toastifier
        notificationMsg={toasterMsg}
        setToastifier={() => {
          setToasterMsg('');
          if (toasterMsg === errorMsg) {
            dispatch(customerUploadRequestFailure(''));
          } else {
            dispatch(customerSendSurveyResponses(''));
          }
        }}
        isEnabled={!!toasterMsg}
        toastClr="dark"
      />
    </div>
  );
};
