// APP LOGIN
export const LOGIN_REQUEST = 'users/LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'users/LOGIN_RESPONSE';
export const LOGIN_REQUEST_FAILURE = 'users/LOGIN_REQUEST_FAILURE';

// APP OAUTH USER VALIDATE
export const OAUTH_USER_VALIDATE_REQUEST = 'users/OAUTH_USER_VALIDATE_REQUEST';
export const OAUTH_USER_VALIDATE_RESPONSE = 'users/OAUTH_USER_VALIDATE_RESPONSE';
export const OAUTH_USER_VALIDATE_FAILURE = 'users/OAUTH_USER_VALIDATE_FAILURE';

// GET OAUTH SIGN ON TOKENS
export const GET_OAUTH_SIGN_ON_TOKENS_REQUEST = 'users/GET_OAUTH_SIGN_ON_TOKENS_REQUEST';
export const GET_OAUTH_SIGN_ON_TOKENS_RESPONSE = 'users/GET_OAUTH_SIGN_ON_TOKENS_RESPONSE';
export const GET_OAUTH_SIGN_ON_TOKENS_FAILURE = 'users/GET_OAUTH_SIGN_ON_TOKENS_FAILURE';

// APP LOGIN ENCODED USER INFO FOR REMEMBER ME SELECTION
export const LOGIN_UPDATED_ENCODED_USER_INFO = 'users/LOGIN_UPDATED_ENCODED_USER_INFO';

// APP LOGIN ERROR RESET
export const LOGIN_ERROR_RESET = 'users/LOGIN_ERROR_RESET';

// APP LOGOUT
export const LOGOUT_REQUEST = 'users/LOGOUT_REQUEST';
export const LOGOUT_RESPONSE = 'users/LOGOUT_RESPONSE';

// SET ACCOUNT NAME
export const SET_ACCOUNT_NAME = 'users/SET_ACCOUNT_NAME';

// SET ACCOUNT USER DETAILS FROM USER LOGINS
export const SET_ACCOUNT_USER_LOGINS_DETAILS = 'users/SET_ACCOUNT_USER_LOGINS_DETAILS';

// USER - UPLOAD CLOUDINARY PROFILE IMAGE
export const USER_CLOUD_PROFILE_IMG_UPLOAD_REQUEST = 'users/USER_CLOUD_PROFILE_IMG_UPLOAD_REQUEST';
export const USER_CLOUD_PROFILE_IMG_UPLOAD_RESPONSE = 'users/USER_CLOUD_PROFILE_IMG_UPLOAD_RESPONSE';
export const USER_CLOUD_PROFILE_IMG_UPLOAD_FAILURE = 'users/USER_CLOUD_PROFILE_IMG_UPLOAD_FAILURE';

// SET USER PROFILE IMAGE
export const USER_SET_CLOUD_PROFILE_IMG = 'users/USER_SET_CLOUD_PROFILE_IMG';

// USER - UPDATE USER DETAIL
export const UPDATE_USER_DETAIL_REQUEST = 'users/UPDATE_USER_DETAIL_REQUEST';
export const UPDATE_USER_DETAIL_RESPONSE = 'users/UPDATE_USER_DETAIL_RESPONSE';
export const UPDATE_USER_DETAIL_FAILURE = 'users/UPDATE_USER_DETAIL_FAILURE';

// GET LOGIN USER DATA
export const GET_LOGIN_USER_DATA_REQUEST = 'users/GET_LOGIN_USER_DATA_REQUEST';
export const GET_LOGIN_USER_DATA_RESPONSE = 'users/GET_LOGIN_USER_DATA_RESPONSE';
export const GET_LOGIN_USER_DATA_FAILURE = 'users/GET_LOGIN_USER_DATA_FAILURE';

// IMPERSONATE USER
export const IMPERSONATE_USER_REQUEST = 'users/IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_RESPONSE = 'users/IMPERSONATE_USER_RESPONSE';
export const IMPERSONATE_USER_FAILURE = 'users/IMPERSONATE_USER_FAILURE';

export const RESTORE_SESSION = 'users/RESTORE_SESSION';

export const SET_AUTHENTICATE = 'users/SET_AUTHENTICATE';

// UPDATE USER DATA
export const IS_USER_DATA_UPDATED = 'users/IS_USER_DATA_UPDATED';
