import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import { ImageValidation } from 'widgets/Media';
import { config } from 'config';
import { revvLoginValidation } from 'utils/helpers';
import { revvLoginRequest, isUserDataUpdated, revvLoginFailure } from 'actions';
import { loginContainerType, IStore } from 'types';
import { useAccountSwitcherData } from 'utils/hooks';

export const SalesRevvLoginModal = ({ handleModalClose }: { handleModalClose: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const revvErrorMsg = useSelector((state: IStore) => state.sales.revvLoginObj.errorMsg);
  const isUserDataUpdate = useSelector((state: IStore) => state.login.isUserDataUpdate);
  const { id, userOwnership } = useAccountSwitcherData();
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);

  const { values, touched, errors, isValid, setFieldValue, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
      rallio_access: 0,
      rallio_user_id: 0
    },
    validationSchema: revvLoginValidation,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (data) => {
      dispatch(isUserDataUpdated(true));
      dispatch(
        revvLoginRequest({
          ...data,
          callback: () => {
            if (menuAvailablity.dashboard) {
              navigate(`/${userOwnership}/${id}/revv/dashboard`);
            }
          }
        })
      );
    }
  });

  const handleChange = (field: string, value: null | string) => {
    setFieldValue(field, value);
    if (revvErrorMsg) dispatch(revvLoginFailure(null));
  };

  /** Set classnames for form field */
  const getClassNames = (valueItem: keyof Omit<loginContainerType.ILoginUserInfo, 'callback'>) => {
    let className = 'rlf-item';
    className = `${className} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  const getInputBoxClassName = (valueItem: keyof Omit<loginContainerType.ILoginUserInfo, 'callback'>) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const getInputTitleClassName = (valueItem: keyof Omit<loginContainerType.ILoginUserInfo, 'callback'>) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'fltlabels-error-text' : ''}`;
    return className;
  };

  return (
    <Modal className="rvLoginMain" centered show animation={false}>
      <div className="mod__close--icon">
        <ImageValidation defaultImg="close-modal-icon" customName={'Close'} customClassname="mod__close--img" onClick={handleModalClose} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="revv-login">
          <div className="img-holder">
            <img src={`${config.cdnImgUrl}revv-logo.svg`} alt="REVV Logo" />
          </div>
          <div className="rl-body">
            <div className="rl-fields">
              <div className={`form-group email ${getInputBoxClassName('email')}`}>
                <input
                  type="text"
                  name="email"
                  className={getClassNames('email')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <span className={`fltlabels ${getInputTitleClassName('email')}`}>Email Address</span>
              </div>
              <div className={`form-group password ${getInputBoxClassName('password')}`}>
                <input
                  type="password"
                  name="password"
                  className={getClassNames('password')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.name, e.target.value)}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <span className={`fltlabels ${getInputTitleClassName('password')}`}>Password</span>
              </div>
            </div>
            <div className="rl-ftr">
              <div className="rlTop">
                {revvErrorMsg ? <span className="errorText">{revvErrorMsg}</span> : null}
                <button type="submit" className={isValid || isUserDataUpdate ? '' : 'pointer-events-none'}>
                  <span>{isUserDataUpdate ? <Spinner animation="border" variant="primary" /> : 'Login'}</span>
                </button>
              </div>
              {/*  <a className='text-link' href={`${config.revvSiteUrl}login/forgot-password`}
                target='_blank' rel='noopener noreferrer'>Forgot your password?</a> */}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
