import { put, takeLatest } from 'redux-saga/effects';

import { settingsEmailMessageFailure, settingsEmailMessageResponse, settingsFailure, settingsMessageFailure, settingsMessageResponse, settingsResponse } from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { ISagaPayload, ISettingMessageRequest, ISettingRequestParams, ISettingsEmailMessageRequest, SagaValue, SagaYield } from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* settingsDetailRequest(action: ISagaPayload<ISettingRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: action.payload ? API_ROUTES.updateSetting.apiPath : API_ROUTES.setting.apiPath,
      action: action.payload ? API_ROUTES.updateSetting.method : API_ROUTES.setting.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      data: action.payload ? { data: { attributes: action.payload.data, id: action.payload.id } } : undefined
    });
    if (response.status === 200) {
      yield put(settingsResponse({ id: response.data.data.id, attributes: response.data.data?.attributes }));
      if (action.payload?.callback) {
        action.payload?.callback();
      }
    } else {
      yield put(settingsFailure('There was a problem saving your settings'));
    }
  } catch (error: any) {
    yield put(settingsFailure(error));
  }
}

function* settingsDetailMessageRequest(action: ISagaPayload<ISettingMessageRequest>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath:
        action.payload?.smsMessage !== undefined
          ? API_ROUTES.settingSurveyMessage.apiPath
          : action.payload?.optInMsg !== undefined
          ? API_ROUTES.settingOptInMessage.apiPath
          : API_ROUTES.settingCouponMessage.apiPath,
      action: API_ROUTES.settingSurveyMessage.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        sms_message: action.payload?.smsMessage,
        opt_in_message: action.payload?.optInMsg,
        coupon_message: action.payload?.couponMsg,
        coupon_url: action.payload?.couponUrl,
        location_id: action.payload?.loationId
      }
    });
    if (response.status === 200) {
      yield put(settingsMessageResponse());
      if (action.payload?.callback) {
        action.payload?.callback(response.data.data || '');
      }
    } else {
      yield put(settingsMessageFailure('OOPS! Something went wrong.' || response.data.error));
    }
  } catch (error: any) {
    yield put(settingsMessageFailure(error));
  }
}

function* settingsDetailEmailMessageRequest(action: ISagaPayload<ISettingsEmailMessageRequest>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.settingsEmailMessage.apiPath,
      action: API_ROUTES.settingsEmailMessage.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        survey_email_message: action.payload?.emailMessage || '',
        survey_email_subject: action.payload?.emailSubject || '',
        location_id: action.payload?.loationId
      }
    });
    if (response.status === 200) {
      yield put(settingsEmailMessageResponse());
      if (action.payload?.callback) {
        action.payload?.callback(response.data.data || '');
      }
    } else {
      yield put(settingsEmailMessageFailure('OOPS! Something went wrong.' || response.data.error));
    }
  } catch (error: any) {
    yield put(settingsEmailMessageFailure(error));
  }
}

export function* takeSettingsRequest() {
  yield takeLatest(actionTypes.SETTINGS_REQUEST, settingsDetailRequest);
  yield takeLatest(actionTypes.SETTINGS_MESSAGE_REQUEST, settingsDetailMessageRequest);
  yield takeLatest(actionTypes.SETTINGS_EMAIL_MESSAGE_REQUEST, settingsDetailEmailMessageRequest);
}
