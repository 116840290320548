import moment from 'moment-timezone';

import { chartProps, numberFormatter } from 'revv/utils';
import { IReputationSeriesData, ISeriesData } from 'revv/types';

export const nonPspSurveyGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat: string[], isDetailedView?: boolean) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: null
    },
    chart: {
      type: chartType,
      backgroundColor: 'white',
      borderRadius: 0,
      width: isDetailedView ? null : 336,
      height: 273
    },
    xAxis: {
      gridLineDashStyle: 'dash',
      gridLineWidth: 1,
      tickmarkPlacement: 'on',
      categories: xAxisCat,
      plotLines: [
        {
          value: xAxisCat?.length ? xAxisCat.length - 0.5 : -0.5,
          width: 0.4
        }
      ],
      labels: {
        useHTML: false,
        formatter: (data: any) => {
          return `<div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('ddd')}</div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('DD')}</div>
            </div>`;
        },
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: false
      }
    },
    yAxis: {
      lineWidth: 1,
      gridLineDashStyle: 'dash',
      labels: {
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      },
      title: {
        enabled: false
      },
      min: 0
    },
    series: seriesData
  };
};

export const nonPspSurveyResultGraphOption = (seriesData: ISeriesData[], chartType: string, tooltipValue?: number) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: chartType,
      backgroundColor: '#FFF',
      borderRadius: 0,
      width: 336,
      height: 273
    },
    tooltip: {
      formatter: () => {
        return tooltipValue;
      }
    },
    title: {
      text: numberFormatter(Number(tooltipValue || 0), 0),
      style: {
        color: '#000',
        fontSize: '2.4rem',
        fontFamily: 'Mulish'
      },
      verticalAlign: 'middle'
    },
    plotOptions: {
      pie: {
        innerHeight: 470,
        marker: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        slicedOffset: 5,
        borderWidth: 1,
        fillColor: '#EEF0F4'
      },
      series: {
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: seriesData
  };
};

export const nonPspSentimentGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat: any, isDetailedView?: boolean) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: null
    },
    chart: {
      type: chartType,
      backgroundColor: 'white',
      width: isDetailedView ? null : 336,
      height: 273,
      borderRadius: 0
    },
    xAxis: {
      gridLineDashStyle: 'dash',
      gridLineWidth: 1,
      tickmarkPlacement: 'on',
      categories: xAxisCat,
      plotLines: [
        {
          value: xAxisCat?.length ? xAxisCat.length - 0.5 : -0.5,
          width: 0.4
        }
      ],
      labels: {
        useHTML: false,
        formatter: (data: any) => {
          return `<div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('ddd')}</div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('DD')}</div>
            </div>`;
        },
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        pointWidth: 30
      },
      column: {
        stacking: 'normal',
        borderWidth: 0
      }
    },
    yAxis: {
      gridLineDashStyle: 'dash',
      lineWidth: 1,
      labels: {
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      },
      title: {
        enabled: false
      },
      min: 0
    },
    series: seriesData
  };
};

export const nonPspAverageGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], labelFormat?: any, isDetailedView?: boolean) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: null
    },
    chart: {
      type: chartType,
      backgroundColor: 'white',
      width: isDetailedView ? null : 336,
      height: 273,
      borderRadius: 0
    },
    xAxis: {
      gridLineDashStyle: 'dash',
      gridLineWidth: 1,
      tickmarkPlacement: 'on',
      categories: xAxisCat,
      plotLines: [
        {
          value: xAxisCat?.length ? xAxisCat.length - 0.5 : -0.5,
          width: 0.4
        }
      ],
      labels: {
        useHTML: false,
        formatter: (data: any) => {
          return `<div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('ddd')}</div>
              <div>${moment(data.value, 'YYYY-MM-DD').format('DD')}</div>
            </div>`;
        },
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        borderRadiusTop: 10
      }
    },
    yAxis: {
      gridLineDashStyle: 'dash',
      lineWidth: 1,
      labels: {
        useHTML: true,
        formatter: labelFormat,
        style: {
          color: '#5B6A78',
          fontSize: '1.4rem',
          fontFamily: 'Mulish',
          fontWeight: 'normal'
        }
      },
      title: {
        enabled: false
      },
      tickInterval: 1,
      min: 0,
      max: 5
    },
    series: seriesData
  };
};
export const surveyGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean) => {
  return {
    ...chartProps.props,
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        lineWidth: '.25rem'
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const sentimentGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean) => {
  return {
    ...chartProps.props,
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        pointWidth: 20
      },
      column: {
        stacking: 'normal'
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const agreedGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean, tooltipValue?: number) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    title: {
      text: numberFormatter(Number(tooltipValue || 0), 1),
      style: {
        color: '#ffffff',
        fontSize: '2.4rem'
      },
      verticalAlign: 'middle'
    },
    tooltip: {
      formatter: () => {
        return tooltipValue;
      }
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false
        }
      },
      series: {
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    yAxis: {
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const surveyResultGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean, tooltipValue?: number) => {
  return {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    title: {
      text: numberFormatter(Number(tooltipValue || 0), 1),
      style: {
        color: '#ffffff',
        fontSize: '2.4rem'
      },
      verticalAlign: 'middle'
    },
    tooltip: {
      formatter: () => {
        return tooltipValue;
      }
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      pie: {
        marker: {
          enabled: false
        },
        borderWidth: 0,
        dataLabels: {
          enabled: false
        }
      },
      series: {
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    yAxis: {
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const reviewGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean) => {
  return {
    ...chartProps.props,
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        lineWidth: '.25rem'
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const averageGraphOption = (seriesData: ISeriesData[], chartType: string, xAxisCat?: string[], isDetailedView?: boolean) => {
  return {
    ...chartProps.props,
    chart: {
      type: chartType,
      backgroundColor: '#002635',
      width: isDetailedView ? null : 256
    },
    xAxis: {
      categories: xAxisCat,
      labels: chartProps.chartStyle
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        lineWidth: '.25rem'
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      labels: chartProps.chartStyle,
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const reputationChartOptions = (seriesData: IReputationSeriesData[], chartType: string, noDataStr?: string, highchartTooltipFormatter?: (value: any) => string) => {
  return {
    ...chartProps.props,
    chart: {
      type: chartType,
      height: 161,
      width: null
    },
    series: seriesData,
    tooltip: {
      useHTML: true,
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      formatter() {
        if (highchartTooltipFormatter && this) {
          return highchartTooltipFormatter(this);
        }
      }
    },
    xAxis: {
      angle: 90,
      type: 'category',
      labels: {
        formatter: (data: any) => {
          return moment(data.value).format('MMM DD');
        },
        rotation: -45,
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto',
          cursor: 'pointer'
        }
      }
    },
    yAxis: {
      min: 0,
      max: 5,
      gridLineDashStyle: 'shortdash',
      labels: {
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto',
          cursor: 'pointer'
        }
      },
      title: {
        enabled: false
      }
    },

    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        lineWidth: '.25rem'
      }
    },
    lang: {
      noData: noDataStr
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    }
  };
};

export const surveyTableGraphDataOption = (
  seriesData: any,
  chartType: string,
  noDataStr?: string,
  highchartTooltipFormatter?: (value: any) => string,
  yTickInterval?: number,
  yMax?: number,
  chartWidth?: number
) => {
  return {
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    chart: {
      type: chartType,
      width: chartWidth || 560,
      height: 200
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      formatter() {
        if (highchartTooltipFormatter && this) {
          return highchartTooltipFormatter(this);
        }
      }
    },
    legend: {
      align: 'right',
      verticalAlign: 'top'
    },
    xAxis: {
      type: 'datetime',
      labels: {
        rotation: 320,
        formatter: (data: any) => {
          return moment(data.value).format('MMM DD');
        },
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto'
        }
      }
    },
    lang: {
      noData: noDataStr
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        lineWidth: '.25rem'
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      tickInterval: yTickInterval,
      min: 0,
      max: yMax || null,
      labels: {
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto',
          cursor: 'pointer'
        }
      },
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};

export const comparisonReportGraphDataOption = (seriesData: any, xAxisCat: Array<string | number>, chartType: string, noDataStr?: string, yTickInterval?: number, yMax?: number) => {
  return {
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: chartType,
      height: 300,
      width: 450
    },
    xAxis: {
      categories: xAxisCat,
      gridLineWidth: 1,
      labels: {
        rotation: 320,
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto'
        }
      }
    },
    lang: {
      noData: noDataStr
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        animation: false,
        lineWidth: '.25rem'
      }
    },
    yAxis: {
      gridLineColor: '#3f4040',
      tickInterval: yTickInterval,
      min: 0,
      max: yMax,
      labels: {
        style: {
          color: '#000000',
          fontSize: '1.2rem',
          fontFamily: 'Roboto'
        }
      },
      title: {
        enabled: false
      }
    },
    series: seriesData
  };
};
