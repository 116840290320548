import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { BUTTON_PROPS, PI_FILTER_INITIAL_VALUE, ProfileImgValidQueryParams, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useActivePlatform, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { CommonFilter } from 'components';
import { PLATFORM_FILTER, handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const ProfileImageryFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams, filter } = useParamsDeconstructor({ ...PI_FILTER_INITIAL_VALUE });
  const activePlatforms = useActivePlatform();

  const platformFilter = [PLATFORM_FILTER[1], PLATFORM_FILTER[3]].filter((it) => activePlatforms.includes(it.value));

  const profileImgFilter = {
    ...filter,
    platform: queryParams?.platform || platformFilter[0].value
  };

  useParamsValidator(
    { ...CommonValidQueryParams, ...ProfileImgValidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      platform: platformFilter.map((it) => it.value)
    }
  );

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && !queryParams?.platform) {
      navigate({ search: `?${new URLSearchParams({ platform: platformFilter[0].value }).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, userOwnership, queryParams?.platform]); // eslint-disable-line

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (isClear) queryParamsObj.platform = platformFilter[0].value;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  return (
    <CommonFilter
      filter={{ ...profileImgFilter }}
      handleChange={handleChange}
      isDateRangeFilterEnable={false}
      analyticsPlatformFilter={platformFilter}
      sectionVariant={'profile-imagery-filter'}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: Boolean(queryParams?.platform === platformFilter[0].value && !queryParams?.hub_id && !queryParams?.location_list_id && !queryParams?.location_id)
        }
      ]}
      isLocation
    />
  );
};
