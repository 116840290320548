import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { accountApiTypes, directoryListingsApiTypes, directoryListingsContainerTypes, directoryListingsReducerTypes } from 'types';

// GET DL CATEGORIES LIST
export const getDLCategoriesRequest = (type: string) => createAction(actionTypes.GET_DL_CATEGORIES_REQUEST, type);
export const getDLCategoriesResponse = (data: directoryListingsApiTypes.IDLCategoriesData[]) => createAction(actionTypes.GET_DL_CATEGORIES_RESPONSE, data);
export const getDLCategoriesFailure = (error: null | string) => createAction(actionTypes.GET_DL_CATEGORIES_FAILURE, error);

// GET DL SYNUP SUB CATEGORIES LIST
export const getDLSynupListingRequest = (payload: directoryListingsApiTypes.IDLSynupListingRequest) => createAction(actionTypes.DL_SYNUP_LISTING_REQUEST, payload);
export const getDLSynupListingResponse = (data: directoryListingsApiTypes.IDLSynupListingResponse[]) => createAction(actionTypes.DL_SYNUP_LISTING_RESPONSE, data);
export const getDLSynupListingFailure = (error: null | string) => createAction(actionTypes.DL_SYNUP_LISTING_FAILURE, error);

// DL - UPDATE DIRECTORY LISTING
export const updateDLRequest = (payload: directoryListingsApiTypes.IUpdateDlRequest) => createAction(actionTypes.DL_UPDATE_REQUEST, payload);
export const updateDLResponse = (data: accountApiTypes.IAccount) => createAction(actionTypes.DL_UPDATE_RESPONSE, data);
export const updateDLFailure = (error: null | string) => createAction(actionTypes.DL_UPDATE_FAILURE, error);

// DL - ADD PHOTO IN CLOUDINARY
export const dlAddBusinessPhotoInCloudRequest = (payload: directoryListingsApiTypes.IDLAddPhotoInCloudRequest) => createAction(actionTypes.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_REQUEST, payload);
export const dlAddBusinessPhotoInCloudResponse = (data: directoryListingsApiTypes.IDLAddPhotoInCloudResponse) => createAction(actionTypes.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_RESPONSE, data);
export const dlAddBusinessPhotoInCloudFailure = (error: null | string) => createAction(actionTypes.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_FAILURE, error);

// DL - SET STORES DETAIL STATES
export const setDLStoreDetails = (payload: directoryListingsContainerTypes.IStoreDetailsTabFieldValue) => createAction(actionTypes.DL_SET_STORE_DETAILS, payload);

// DL - SET DL-UPDATE STATES
export const dlSetUpdateStatus = (payload: directoryListingsReducerTypes.IUpdateDLDetails) => createAction(actionTypes.DL_SET_UPDATE_STATUS, payload);

// DL - SET DL-BUSINESS PHOTO LIST
export const dlSetBusinessPhotoList = (payload: accountApiTypes.IBusinessPhoto[]) => createAction(actionTypes.DL_SET_BUSINESS_PHOTO, payload);

// DL - SET DL-BUSINESS HOURS
export const dlSetBusinessHoursList = (payload: accountApiTypes.IBusinessHour[]) => createAction(actionTypes.DL_SET_BUSINESS_HOURS, payload);

// DL - SET DL-BUSINESS SPECIAL HOURS
export const dlSetBusinessSpecialHoursList = (payload: accountApiTypes.IBusinessHour[]) => createAction(actionTypes.DL_SET_BUSINESS_SPECIAL_HOURS, payload);

// DL - SET DL-SPECIAL HOURS WARNINGS
export const dlSetSpecialHoursWarning = (payload: directoryListingsReducerTypes.IDLSpecialHoursWarnings) => createAction(actionTypes.DL_SET_SPECIAL_HOURS_WARNINGS, payload);

// DL - SET DL-BUSINESS HOURS TIME SLOT
export const dlSetBusinessHoursTimeSlot = (payload: directoryListingsContainerTypes.IDLBusinessHoursTimeSlot) => createAction(actionTypes.DL_SET_BUSINESS_HOURS_TIME_SLOT, payload);

// DL - SET DL-BUSINESS HOURS WARNINGS
export const dlSetBusinessHoursWarning = (payload: directoryListingsReducerTypes.IDLBusinessHoursWarnings) => createAction(actionTypes.DL_SET_BUSINESS_HOURS_WARNINGS, payload);

// DL RESET ALL
export const dlResetAll = () => createAction(actionTypes.DL_RESET_ALL);

const DIRECTORY_LISTINGS_ACTIONS = {
  getDLCategoriesRequest,
  getDLCategoriesResponse,
  getDLCategoriesFailure,
  getDLSynupListingRequest,
  getDLSynupListingResponse,
  getDLSynupListingFailure,
  updateDLRequest,
  updateDLResponse,
  updateDLFailure,
  dlAddBusinessPhotoInCloudRequest,
  dlAddBusinessPhotoInCloudResponse,
  dlAddBusinessPhotoInCloudFailure,
  setDLStoreDetails,
  dlSetUpdateStatus,
  dlSetBusinessPhotoList,
  dlSetBusinessHoursList,
  dlSetBusinessHoursTimeSlot,
  dlSetBusinessHoursWarning,
  dlSetBusinessSpecialHoursList,
  dlSetSpecialHoursWarning,
  dlResetAll
};

export type DirectoryListingsAction = ActionsUnion<typeof DIRECTORY_LISTINGS_ACTIONS>;
