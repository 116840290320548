import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// import { ImageValidation } from 'widgets/Media';
import { IStore } from 'types';
import { salesIsDemoModalOpen, isUserDataUpdated, revvLoginFailure, scheduleDemoContactEmailMessageReset, setMenuAccessibility } from 'actions';
import { SalesRevvLoginModal } from './RevvLoginModal';
import { ALERT_BOX_TYPES } from 'utils/constants';
import { getReviewAnalyticsSummaryRequest } from 'analytics/actions';
import { Loading, alertBoxCall } from 'components';
import { ImageValidation } from 'widgets/Media';
import { getRevvReviewAnalyticsDateFormat, showSalesPage } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { Toastifier } from 'revv/components';

export const SalesRevvPlatform = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const brandName = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const data = useSelector((state: IStore) => state.reviewAnalytics.data);
  const revvErrorMsg = useSelector((state: IStore) => state.sales.revvLoginObj.errorMsg);
  const isUserDataUpdate = useSelector((state: IStore) => state.login.isUserDataUpdate);
  const isUserDataFetching = useSelector((state: IStore) => state.login.isUserDataFetching);
  const message = useSelector((state: IStore) => state.sales.message);
  const error = useSelector((state: IStore) => state.sales.error);
  const revvReference = useSelector((state: IStore) => state.login.activeUser?.revv_reference);
  const isRevvLoginRequest = useSelector((state: IStore) => state.revv.login.isFetching);
  const isAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const salesPages = useSelector((state: IStore) => state.common.salesPages);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);
  const activeClientName = useSelector((state: IStore) => state.revv.login.activeClientName);

  const { userOwnership, id, currentRoute } = useAccountSwitcherData();

  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [toasterMsg, setToasterMSg] = useState('');

  const handleLoginModal = () => setIsOpenLoginModal(!isOpenLoginModal);

  const showSales = useMemo(() => {
    return showSalesPage(salesPages, `/${currentRoute}`);
  }, [salesPages, currentRoute]);

  useEffect(() => {
    if (revvReference?.id && menuAvailablity.revv.dashboard && isAuthenticated && !showSales && (isRevvConnectedToCurrentFranchisor || showRevv)) {
      navigate({ pathname: `/${userOwnership}/${id}/revv/dashboard` });
    }
    return () => {
      if (revvReference?.id && isAuthenticated && menuAvailablity.revv.dashboard && (isRevvConnectedToCurrentFranchisor || showRevv)) {
        dispatch(
          setMenuAccessibility({
            ...menuAvailablity,
            revv: {
              ...menuAvailablity.revv,
              login: false
            }
          })
        );
      }
    };
  }, [revvReference, menuAvailablity, id, userOwnership, isAuthenticated, isRevvConnectedToCurrentFranchisor, showRevv, navigate, showSales, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      const data = {
        ancestor_franchisor_id: topLevelFranchisorId,
        date_range: [getRevvReviewAnalyticsDateFormat().fromDate || '', getRevvReviewAnalyticsDateFormat().toDate || '']
      };
      dispatch(getReviewAnalyticsSummaryRequest(data));
    }
  }, [isAuthenticated]); // eslint-disable-line

  useEffect(() => {
    if (message) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, message);
    } else if (error) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
    }
    return () => {
      dispatch(scheduleDemoContactEmailMessageReset());
    };
  }, [message, error]); // eslint-disable-line

  useEffect(() => {
    if (revvErrorMsg) {
      dispatch(isUserDataUpdated(false));
    }
  }, [revvErrorMsg]); // eslint-disable-line

  useEffect(() => {
    if (!isUserDataUpdate && revvReference?.id && !showSales) {
      setIsOpenLoginModal(false);
    }
  }, [isUserDataUpdate, revvReference?.id, showSales]); // eslint-disable-line

  return (
    <>
      {isUserDataFetching || isRevvLoginRequest ? <Loading /> : null}
      {!isAuthenticated || !showRevv || !isRevvConnectedToCurrentFranchisor ? (
        <>
          <div className={isUserDataFetching ? ` pointer-events-none` : ''}>
            {showSales ? null : (
              <div className="small-trans-box">
                <h3>
                  Already a <span>Revv</span> member?
                </h3>
                <button
                  onClick={() => {
                    if (!showRevv && isAuthenticated && !isRevvConnectedToCurrentFranchisor) {
                      setToasterMSg(`Already connected to ${activeClientName}`);
                    } else {
                      handleLoginModal();
                    }
                  }}
                >
                  <span>Connect now</span>
                </button>
              </div>
            )}
            <div className="rallioplatform revv">
              <div className="content-main-section">
                <div className="left-section-main">
                  <h3>
                    {brandName} + <span>Revv</span>
                  </h3>
                  <h4>Get more positive reviews.</h4>
                  <p className="main-para-text">
                    In the past 30 days, you have received {`${data?.reviews_analytics?.total_reviews_count || 0} reviews.`} Your average all-time review rating is{' '}
                    {`${data?.reviews_analytics?.avg_rating || 0}.`} It’s time to rev these numbers up.
                  </p>
                  <div className="quotation-text">
                    <span className="quoto-icon">&#8220;</span>
                    Our biggest challenge was how we didn’t have any sort of plan in place to generate more reviews. The most we could do was to offer some sort of discount to the customer to leave a
                    review, but nothing was automated like what REVV and Rallio brought to the table. Before this platform we didn’t have the tools nor the expertise to generate more reviews.”
                    <p>
                      <span> Dennis Ferguson </span> Operator of a 10 Location ZAGG Location
                    </p>
                    <div className="schedule-section">
                      <Button onClick={() => dispatch(salesIsDemoModalOpen(true))} className="csv-btn">
                        <span>Schedule a demo</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="right-section-main">
                  <h3>Increase in Monthly Review per Location</h3>
                  <div className="slider-img-bg">
                    <ImageValidation isImgValid defaultImg="sso-bg-2-inner" customName="Location" />
                  </div>
                </div>
              </div>
            </div>
            {isOpenLoginModal ? (
              <SalesRevvLoginModal
                handleModalClose={() => {
                  handleLoginModal();
                  if (revvErrorMsg) dispatch(revvLoginFailure(null));
                }}
              />
            ) : null}
          </div>
        </>
      ) : null}
      <Toastifier
        notificationMsg={toasterMsg}
        setToastifier={() => {
          setToasterMSg('');
        }}
        isEnabled={!!toasterMsg}
        toastClr="dark"
      />
    </>
  );
};
