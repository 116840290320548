import { all, fork } from 'redux-saga/effects';

import { takeClientsRequests } from './clients';
import { takeDashboardRequest } from './dashboard';
import { takeAllFilterRequests } from './filter';
import { takeLoginRequests } from './login';
import { takeSettingsRequest } from './settings';
import { takeSurveyRequest } from './surveys';
import { takeUserRequest } from './user';
import { takeCustomersRequest } from './customers';
import { takeDownloadRequest } from './download';
import { takeLocationsRequest } from './locations';
import { takeAnalyticsRequest } from './analytics';

export function* revvRootSaga() {
  yield all([
    fork(takeLoginRequests),
    fork(takeDashboardRequest),
    fork(takeSurveyRequest),
    fork(takeUserRequest),
    fork(takeClientsRequests),
    fork(takeSettingsRequest),
    fork(takeAllFilterRequests),
    fork(takeCustomersRequest),
    fork(takeDownloadRequest),
    fork(takeLocationsRequest),
    fork(takeAnalyticsRequest)
  ]);
}
