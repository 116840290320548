import { useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { FB_REQUIRED_FIELDS, FB_SCOPE } from 'utils/constants';
import { IStore } from 'types';

interface IFacebookConnectProps {
  callback: any;
  renderButton: (renderProps: any) => JSX.Element;
  extraScopes?: string;
}

export const FacebookConnectButton = ({ callback, renderButton, extraScopes }: IFacebookConnectProps) => {
  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);
  const defaultScopes = FB_SCOPE.split(',').map((scope) => scope.trim());
  const extraScopesArray = extraScopes ? extraScopes.split(',').map((scope) => scope.trim()) : [];
  const scope = Array.from(new Set([...defaultScopes, ...extraScopesArray])).join(',');
  return (
    <FacebookLogin
      authType="reauthenticate"
      appId={fbApplicationId}
      autoLoad={false}
      autoLogAppEvents={false}
      cookie={false}
      fields={FB_REQUIRED_FIELDS}
      scope={scope}
      callback={callback}
      render={renderButton}
    />
  );
};
