import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { useParamsDeconstructor, useParamsValidator, useAccountSwitcherData } from 'utils/hooks';
import { NODE_API, downloadFormatHandlerLogins } from 'utils/helpers';
import {
  USER_OWNERSHIP,
  REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  BUTTON_PROPS,
  ADVOCACY_ONBOARD_FILTERS_ARRAY,
  UserLoginsValidParams,
  API_TYPES,
  AdvOnboardLoginTypeValue,
  AdvOnboardEmailStatus,
  AdvOnboardAppStatus,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ
} from 'utils/constants';
import { CommonFilter } from 'components';
import { apiExportCsvhandler, handleFilterChange } from 'analytics/utils';
import { setAdvocacyOnboardStatscall } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';

export const AdvocacyOnboardingFilter = () => {
  const navigate = useNavigate();
  const { id, userOwnership } = useAccountSwitcherData();
  const dispatch = useDispatch();

  const advocacyOnboardList = useSelector((state: IStore) => state.advocacyOnboard.advocacyOnboardList);
  const advocacyOnboardStats = useSelector((state: IStore) => state.advocacyOnboard.advocacyOnboardStats);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv

  useParamsValidator(
    { ...CommonValidQueryParams, ...UserLoginsValidParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      login_type: [AdvOnboardLoginTypeValue.EMPLOYEE],
      email_status: ADVOCACY_ONBOARD_FILTERS_ARRAY.emailStatus.map((it) => it.value),
      app_status: ADVOCACY_ONBOARD_FILTERS_ARRAY.appStatus.map((it) => it.value)
    }
  );

  const initialFilterValue = {
    locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
    loginType: AdvOnboardLoginTypeValue.ADMIN,
    emailStatus: AdvOnboardEmailStatus.ALL,
    appStatus: AdvOnboardAppStatus.ALL,
    text: ''
  };
  const { queryParams, filter, selectedHubId, selectedHubKey } = useParamsDeconstructor(initialFilterValue);

  const reviewsFilter = {
    ...filter,
    loginType: queryParams?.login_type || AdvOnboardLoginTypeValue.ADMIN,
    emailStatus: queryParams?.email_status || AdvOnboardEmailStatus.ALL,
    appStatus: queryParams?.app_status || AdvOnboardAppStatus.ALL
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
    if (isClear) {
      if (queryParams?.text) queryParamsClone.text = queryParams?.text || '';
    }
    if (data.loginType) {
      if (data.loginType === AdvOnboardLoginTypeValue.ADMIN) {
        delete queryParamsClone.login_type;
      } else {
        queryParamsClone.login_type = data.loginType;
      }
    }
    if (data.emailStatus) {
      if (data.emailStatus === AdvOnboardEmailStatus.ALL) {
        delete queryParamsClone.email_status;
      } else {
        queryParamsClone.email_status = data.emailStatus;
      }
    }
    if (data.appStatus) {
      if (data.appStatus === AdvOnboardAppStatus.ALL) {
        delete queryParamsClone.app_status;
      } else {
        queryParamsClone.app_status = data.appStatus;
      }
    }
    if (data.locationListId || data.ancestorFranchisorId || data.locationId || data.loginType || isClear) {
      dispatch(setAdvocacyOnboardStatscall(true));
    }
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  const handleExportData = () => {
    const filteredParams = {
      employee: queryParams?.login_type === AdvOnboardLoginTypeValue.EMPLOYEE ? 1 : 0,
      ...(queryParams?.app_status ? (queryParams?.app_status === AdvOnboardAppStatus.INSTALLED ? { installed: 1 } : { notInstalled: 1 }) : {}),
      ...(queryParams?.email_status ? (queryParams?.email_status === AdvOnboardEmailStatus.OPENED ? { opened: 1 } : { unopened: 1 }) : {})
    };
    if (id && userOwnership) {
      const hubKey = selectedHubKey === 'account_list_id' ? 'accountListId' : selectedHubKey === 'account_id' ? 'accountId' : 'franchisorId';
      const reqPayload = {
        [hubKey]: selectedHubId,
        ...(queryParams?.text && { searchText: queryParams?.text }),
        ...(filteredParams && { ...filteredParams })
      };
      apiExportCsvhandler(
        NODE_API.tmAdvocatesList.apiPath,
        reqPayload,
        !queryParams?.login_type ? `Admin` : `Advocates`,
        'data',
        currentName,
        id,
        userOwnership,
        API_TYPES.NODE,
        downloadFormatHandlerLogins
      );
    }
  };

  return (
    <CommonFilter
      filter={reviewsFilter}
      handleChange={handleChange}
      handleExportCsv={handleExportData}
      isDateRangeFilterEnable={false}
      checkBoxFilter={[
        {
          title: 'Login Type',
          filter: ADVOCACY_ONBOARD_FILTERS_ARRAY.loginType.map((it, index) => ({
            ...it,
            label: `${it.label} (${index === 0 ? advocacyOnboardStats.totalAdminsCount : advocacyOnboardStats.totalEmployeesCount})`
          })),
          value: 'loginType'
        },
        {
          title: 'Email Status',
          filter: ADVOCACY_ONBOARD_FILTERS_ARRAY.emailStatus.map((it, index) => ({
            ...it,
            label: `${it.label}  (${index === 0 ? advocacyOnboardStats.opened + advocacyOnboardStats.unOpened : index === 1 ? advocacyOnboardStats.opened : advocacyOnboardStats.unOpened})`
          })),
          value: 'emailStatus'
        },
        {
          title: 'App Status',
          filter: ADVOCACY_ONBOARD_FILTERS_ARRAY.appStatus.map((it, index) => ({
            ...it,
            label: `${it.label}  (${
              index === 0 ? advocacyOnboardStats.installed + advocacyOnboardStats.notInstalled : index === 1 ? advocacyOnboardStats.installed : advocacyOnboardStats.notInstalled
            })`
          })),
          value: 'appStatus'
        }
      ]}
      primaryButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
          isDisable: !advocacyOnboardList.length
        },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: !queryParams?.location_id && !queryParams?.location_list_id && !queryParams?.hub_id && !queryParams?.login_type && !queryParams?.email_status && !queryParams?.app_status
        }
      ]}
      sectionVariant="tm-logins tm-logins__main"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
};
