import { useEffect, useRef, useState } from 'react';
import { Spinner, ToastContainer, Toast, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Download } from '@mui/icons-material';

import { config } from 'config';
import { IStore } from 'types';
import { dashboardReportDownloadResponse, downloadResponse, pollExportRequest } from 'revv/actions';

export const ExportHandler = () => {
  const dispatch = useDispatch();

  const exportRequest = useSelector((state: IStore) => state.revv.download.exportRequest);
  const reportName = useSelector((state: IStore) => state.revv.download.reportName);
  const downloadFilePath = useSelector((state: IStore) => state.revv.download.dashboardReportResponse?.filePath);
  const downloadReportType = useSelector((state: IStore) => state.revv.download.dashboardReportResponse?.reportType);
  const isInProgress = useSelector((state: IStore) => state.revv.download.isExportInProgress);
  const isPollRequest = useSelector((state: IStore) => state.revv.download.isPollRequest);
  const [filePath, setfilePath] = useState<string | null>(null);

  const interval = useRef<NodeJS.Timer | null>(null);
  const isFetchRequest = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (exportRequest && !exportRequest.filename && !interval.current) {
      interval.current = setInterval(() => {
        if (!isPollRequest) {
          dispatch(
            pollExportRequest({
              id: exportRequest.id
            })
          );
        }
      }, 2000);
    } else if (exportRequest?.filename) {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    }
  }, [exportRequest, isPollRequest, dispatch]);

  useEffect(() => {
    if (downloadFilePath) {
      interval.current = setInterval(() => {
        if (!isFetchRequest.current) {
          isFetchRequest.current = true;
          fetch(String(downloadFilePath)).then((response) => {
            isFetchRequest.current = false;
            if (response.status === 200) {
              setfilePath(String(downloadFilePath));
              clearInterval(Number(interval.current));
              return;
            }
          });
        }
      }, 2000);
    }
  }, [downloadFilePath]);

  return (
    <ToastContainer position="bottom-end" className="revv-npsp-toast">
      {isInProgress && (exportRequest || downloadFilePath) && reportName !== 'comparison' ? (
        <Toast className="p-2" animation bg="dark">
          <Toast.Body className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              {(exportRequest ? !exportRequest?.filename : !filePath) ? <Spinner variant="light" animation="border" /> : null}
              <span className="text-white fs-primary ps-2 message">
                {(exportRequest ? !exportRequest?.filename : !filePath)
                  ? `Loading ${exportRequest ? exportRequest.dataType : downloadReportType} report...`
                  : `Please click to download the ${exportRequest ? exportRequest.dataType : downloadReportType} report.`}
              </span>
            </div>
            <Button
              className="ms-2 text-decoration-none text-success"
              onClick={() => {
                if ((exportRequest && exportRequest.filename) || (filePath && downloadFilePath)) {
                  const downloadFile = exportRequest ? exportRequest?.filename : filePath;
                  if (downloadFile) {
                    window.open(String(downloadFile), '_blank');
                  }
                  if (exportRequest && exportRequest?.filename) {
                    dispatch(downloadResponse(null));
                  } else {
                    dispatch(dashboardReportDownloadResponse(null));
                  }
                }
              }}
              size="sm"
              variant="link"
              disabled={exportRequest ? !exportRequest?.filename : !filePath}
              type="submit"
            >
              <Download />
              <span className="download-text">DOWNLOAD</span>
            </Button>
            {(exportRequest ? !exportRequest?.filename : !filePath) ? null : (
              <img
                onClick={() => {
                  dispatch(downloadResponse(null));
                }}
                src={`${config.cdnRevvImgUrl}/modal-close.svg`}
                alt="close"
                className="cur-pointer close"
              />
            )}
          </Toast.Body>
        </Toast>
      ) : isInProgress && reportName ? (
        <Toast className="p-2" animation bg="dark">
          <Toast.Body className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner variant="light" animation="border" />
              <span className="text-white fs-primary ps-2">Loading {reportName} report.</span>
            </div>
          </Toast.Body>
        </Toast>
      ) : null}
    </ToastContainer>
  );
};
