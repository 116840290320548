export enum OverviewAnalyticsGraphColors {
  POST_ENGAGEMENT = '#1F527C',
  FOLLOWERS = '#3870FF',
  PUBLISHED_POSTS = '#21D184'
}

export enum OverviewAnalyticsGraphFillColors {
  POST_ENGAGEMENT = 'rgba(31, 82, 124, 0.1)',
  FOLLOWERS = 'rgba(56, 112, 255, 0.15)',
  PUBLISHED_POSTS = 'rgba(33, 209, 132, 0.15)'
}

export enum OverviewAnalyticsGraphFadeColors {
  POST_ENGAGEMENT = 'rgba(31, 82, 124, 0)',
  FOLLOWERS = 'rgba(56, 112, 255, 0)',
  PUBLISHED_POSTS = 'rgba(33, 209, 132, 0)'
}

export enum GraphColors {
  PROFILE_VIEW = '#3870FF',
  WEBSITE_CLICKS = '#1F527C',
  PHONE_CALLS = '#21D184',
  DIRECTION_REQUEST = '#FFC259',
  BUTTON_CLICKS = '#2CCBA7'
}

export enum CombinedGraphFillColors {
  PROFILE_VIEW = 'rgba(56, 112, 255, 0.14)',
  WEBSITE_CLICKS = 'rgba(56, 112, 255, 0.14)',
  PHONE_CALLS = 'rgba(33, 209, 132, 0.06)',
  DIRECTION_REQUEST = 'rgba(255, 194, 89, 0.04)',
  BUTTON_CLICKS = 'rgba(44, 203, 167, 0.03)'
}

export enum FadeCombinedGraphFillColors {
  PROFILE_VIEW = 'rgba(31, 82, 124, 0)',
  WEBSITE_CLICKS = 'rgba(31, 82, 124, 0)',
  PHONE_CALLS = 'rgba(33, 209, 132, 0)',
  DIRECTION_REQUEST = 'rgba(255, 194, 89, 0)',
  BUTTON_CLICKS = 'rgba(44, 203, 167, 0)'
}

export enum KeywordsCAGraphColors {
  LOCATION_SCORE = '#21D184'
}

export enum KeywordCAGraphFillColors {
  LOCATION_SCORE = 'rgba(33, 209, 132, 0.15)'
}

export enum KeywordCAGraphFadeColors {
  LOCATION_SCORE = 'rgba(33, 209, 132, 0)'
}
