import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { NODE_API, apiCall, defaultHeader, API } from 'utils/helpers';
import {
  getAdvocacyOnboardStatsResponse,
  getAdvocacyOnboardStatsFailure,
  logoutRequest,
  exceptionHandlerRequest,
  getAdvocacyOnboardListResponse,
  getAdvocacyOnboardListFailure,
  getAdvocacyOnboardSingleReInviteResponse,
  getAdvocacyOnboardSingleReInviteFailure
} from 'actions';
import { commonApiTypes } from 'types';
import { ADVOCACY_ONBOARD_STATS_FAILED, ADVOCACY_ONBOARD_ERROR, API_TYPES, ADVOCACY_ONBOARD_LIST_FAILED, PAGINATION_PER_PAGE, ADVOCACY_ONBOARD_MULTIPLE_RE_INVITE_FAILED } from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendAdvocacyOnboardStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      ...(payload.franchisorId && { franchisorId: payload.franchisorId }),
      ...(payload.accountId && { accountId: payload.accountId }),
      ...(payload.accountListId && { accountListId: payload.accountListId }),
      ...(payload.searchText && { searchText: payload.searchText }),
      ...(payload.opened && { opened: payload.opened }),
      ...(payload.unopened && { unopened: payload.unopened }),
      ...(payload.installed && { installed: payload.installed }),
      ...(payload.notInstalled && { notInstalled: payload.notInstalled }),
      ...(payload.inviteEmailFromTime && { inviteEmailFromTime: payload.inviteEmailFromTime }),
      ...(payload.inviteEmailToTime && { inviteEmailToTime: payload.inviteEmailToTime }),
      ...(payload.appActivityFromTime && { appActivityFromTime: payload.appActivityFromTime }),
      ...(payload.appActivityToTime && { appActivityToTime: payload.appActivityToTime }),
      employee: payload.employee
    };
    const response = yield apiCall({ headers, params, ...NODE_API.tmAdvocatesStats, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAdvocacyOnboardStatsResponse(response.data.data));
    } else {
      yield put(getAdvocacyOnboardStatsFailure(ADVOCACY_ONBOARD_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADVOCACY_ONBOARD_ERROR));
  }
}

function* sendAdvocacyOnboardListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      page: payload.page,
      perPage: PAGINATION_PER_PAGE,
      ...(payload.franchisorId && { franchisorId: payload.franchisorId }),
      ...(payload.accountId && { accountId: payload.accountId }),
      ...(payload.accountListId && { accountListId: payload.accountListId }),
      ...(payload.searchText && { searchText: payload.searchText }),
      ...(payload.opened && { opened: payload.opened }),
      ...(payload.unopened && { unopened: payload.unopened }),
      ...(payload.installed && { installed: payload.installed }),
      ...(payload.notInstalled && { notInstalled: payload.notInstalled }),
      ...(payload.inviteEmailFromTime && { inviteEmailFromTime: payload.inviteEmailFromTime }),
      ...(payload.inviteEmailToTime && { inviteEmailToTime: payload.inviteEmailToTime }),
      ...(payload.appActivityFromTime && { appActivityFromTime: payload.appActivityFromTime }),
      ...(payload.appActivityToTime && { appActivityToTime: payload.appActivityToTime }),
      employee: payload.employee
    };
    const response = yield apiCall({ headers, params, ...NODE_API.tmAdvocatesList, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAdvocacyOnboardListResponse(response.data?.data || []));
    } else {
      yield put(getAdvocacyOnboardListFailure(ADVOCACY_ONBOARD_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADVOCACY_ONBOARD_ERROR));
  }
}

function* sendAdvocacyOnboardMultipleReInviteRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload;
    const response = yield apiCall({ headers, data, ...API.tmResendInviteForMultipleUsers });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAdvocacyOnboardSingleReInviteResponse(response.data));
    } else {
      yield put(getAdvocacyOnboardSingleReInviteFailure(ADVOCACY_ONBOARD_MULTIPLE_RE_INVITE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADVOCACY_ONBOARD_ERROR));
  }
}

export function* takeAdvocacyOnboardRequest() {
  yield takeLatest(types.ADVOCACY_ONBOARD_GET_STATS_REQUEST, sendAdvocacyOnboardStatsRequest);
  yield takeEvery(types.ADVOCACY_ONBOARD_GET_LIST_REQUEST, sendAdvocacyOnboardListRequest);
  yield takeLatest(types.ADVOCACY_ONBOARD_GET_MULTIPLE_RE_INVITE_REQUEST, sendAdvocacyOnboardMultipleReInviteRequest);
}
