// Setting Request action types
export const SETTINGS_REQUEST = 'setings/SETTINGS_REQUEST';
export const SETTINGS_FAILURE = 'setings/SETTINGS_FAILURE';
export const SETTINGS_RESPONSE = 'setings/SETTINGS_RESPONSE';

export const SETTINGS_MESSAGE_REQUEST = 'setings/SETTINGS_MESSAGE_REQUEST';
export const SETTINGS_MESSAGE_FAILURE = 'setings/SETTINGS_MESSAGE_FAILURE';
export const SETTINGS_MESSAGE_RESPONSE = 'setings/SETTINGS_MESSAGE_RESPONSE';

export const SETTINGS_EMAIL_MESSAGE_REQUEST = 'settings/SETTINGS_EMAIL_MESSAGE_REQUEST';
export const SETTINGS_EMAIL_MESSAGE_FAILURE = 'settings/SETTINGS_EMAIL_MESSAGE_FAILURE';
export const SETTINGS_EMAIL_MESSAGE_RESPONSE = 'settings/SETTINGS_EMAIL_MESSAGE_RESPONSE';
