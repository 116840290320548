export interface ILocationTreeResponse {
  id: number;
  name: string;
  child_present: boolean;
  child_count: number;
  descendant_accounts_count: number;
  latitude: null | string;
  longitude: null | string;
  address: null | string;
  street: null | string;
  city: null | string;
  state: null | string;
  zip: null | string;
  country_code: null | string;
  franchisor_id: number | null;
  parent_franchisor_id: number | null;
  top_level_franchisor_id: number;
  rallio_local_assets_notification: boolean;
  class: ILocationTreeClass;
  accounts?: ILocationTreeResponse[];
  franchisors?: ILocationTreeResponse[];
  [key: string]: any;
}

export enum ILocationTreeClass {
  ACCOUNTS = 'accounts',
  FRANCHISORS = 'franchisors'
}

export interface ITreeObj {
  id: number;
  key: string;
  title: string;
  isLeaf: boolean;
  parentId: number;
  children: ITreeObj[];
  type: string;
  className: string;
}

export interface ITreeSelection {
  selected: ITreeObj[];
  checked: string[];
  partiallyChecked: string[];
}

export const getLocationTreeInAscendingOrder = (data: ILocationTreeResponse[]) => {
  return data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

export const formLocationTreeData = (data: ILocationTreeResponse[]): ITreeObj[] => {
  const resultArr: ITreeObj[] = [];
  data.forEach((locationTreeResponse: ILocationTreeResponse) => {
    const isAccount = locationTreeResponse.class === ILocationTreeClass.ACCOUNTS;
    const obj: ITreeObj = {
      id: locationTreeResponse.id,
      key: String(locationTreeResponse.id),
      title: locationTreeResponse.name,
      isLeaf: isAccount,
      parentId: isAccount ? locationTreeResponse.franchisor_id || 0 : locationTreeResponse.parent_franchisor_id || 0,
      children: [],
      type: isAccount ? 'location' : 'hub',
      className: isAccount ? 'tree-location' : 'tree-franchisor'
    };
    let childrenArr: ITreeObj[] = [];
    const franchisors = locationTreeResponse.franchisors || [];
    const accounts = locationTreeResponse.accounts || [];
    if (franchisors?.length > 0) {
      childrenArr = childrenArr.concat(formLocationTreeData(getLocationTreeInAscendingOrder(franchisors)));
    }
    if (accounts?.length > 0) {
      childrenArr = childrenArr.concat(formLocationTreeData(getLocationTreeInAscendingOrder(accounts)));
    }
    obj.children = childrenArr;
    resultArr.push(obj);
  });
  return resultArr;
};

export const buildSelectedTree = (data: ITreeObj[], selectedIds: string[], checkedKeys: string[] = [], partiallyCheckedKeys: string[] = []): ITreeSelection => {
  const resultArr: ITreeObj[] = [];
  data.forEach((selectedTreeResponse: ITreeObj) => {
    if (selectedTreeResponse.isLeaf) {
      if (selectedIds.findIndex((it: string) => Number(it) === Number(selectedTreeResponse.key)) > -1) {
        checkedKeys.push(selectedTreeResponse.key);
        resultArr.push({ ...selectedTreeResponse });
      }
    } else {
      const selectedObj = buildSelectedTree(selectedTreeResponse.children, selectedIds, [], []);
      if (selectedObj.selected.length > 0) {
        checkedKeys = checkedKeys.concat(selectedObj.checked);
        partiallyCheckedKeys = partiallyCheckedKeys.concat(selectedObj.partiallyChecked);
        if (selectedObj.selected.length === selectedTreeResponse.children.length && selectedObj.selected.filter((it) => partiallyCheckedKeys.includes(it.key))?.length === 0) {
          checkedKeys.push(selectedTreeResponse.key);
        } else {
          partiallyCheckedKeys.push(selectedTreeResponse.key);
        }
        resultArr.push({
          ...selectedTreeResponse,
          children: selectedObj.selected
        });
      }
    }
  });
  return { selected: resultArr, checked: checkedKeys, partiallyChecked: partiallyCheckedKeys };
};

export const findValidChildren = (data: ITreeObj[], selectedId: string, selectedIsNotLeaf = false): string[] => {
  const resultIdArr: string[] = [];
  data.forEach((treeDataResponse: ITreeObj) => {
    let validChildren: string[] = [];
    if (treeDataResponse.key === selectedId || selectedIsNotLeaf) {
      if (treeDataResponse.isLeaf) {
        resultIdArr.push(treeDataResponse.key);
      } else {
        validChildren = findValidChildren(treeDataResponse.children, selectedId, true);
      }
    } else if (!treeDataResponse.isLeaf) {
      validChildren = findValidChildren(treeDataResponse.children, selectedId, selectedIsNotLeaf);
    }
    if (validChildren.length > 0) {
      resultIdArr.push(...validChildren);
    }
  });
  return resultIdArr;
};
