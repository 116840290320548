import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { connectGoogleRequest, loadGoogleLocationRequest, loadGoogleLocationResponse, socialProfileResetAll, updateSocialMediaSettingSuccessOrFailure } from 'actions';
import { Loading, SettingsSocialModalPopup } from 'components';
import { IStore, settingsSocialProfilContainerTypes, settingsSocialProfileReducerTypes } from 'types';
import { GoogleAccountPopup } from './GoogleAccountPopup';
import { ImageValidation } from 'widgets/Media';
import { APP_TITLE, COMMON_SOCIAL_PROVIDER_TYPE, MediaDropPages } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';

export const GooglePopupModal = ({ showGoogleAccountModal, setShowGoogleAccountModal, accountId }: settingsSocialProfilContainerTypes.IGoogleModalProps) => {
  const { userOwnership, id, currentRoute, optionalParams } = useAccountSwitcherData();

  const isGoogleAccountFetching = useSelector((state: IStore) => state.socialProfile.isGoogleAccountFetching);
  const googleAccountBusinessList = useSelector((state: IStore) => state.socialProfile.googleBusinessAccountList);
  const googleCredentialId = useSelector((state: IStore) => state.socialProfile.googleCredentialId);
  const locationsList = useSelector((state: IStore) => state.socialProfile.googleLocationsList);
  const [selectedObj, setSelectedObj] = useState<settingsSocialProfileReducerTypes.IGoogleBusinessAccount | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowGoogleModal = () => {
    if (currentRoute.includes(MediaDropPages.SETTINGS)) {
      return optionalParams[0] === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && optionalParams[1] ? true : false;
    }
    return showGoogleAccountModal || false;
  };

  const handleModalClose = () => {
    if (currentRoute.includes(MediaDropPages.SETTINGS)) {
      navigate(`/${userOwnership}/${id}/${currentRoute}`);
    } else {
      setShowGoogleAccountModal(false);
    }
    dispatch(socialProfileResetAll());
  };

  return handleShowGoogleModal() ? (
    <div className="rlc-pt-modal-main-wraps">
      <SettingsSocialModalPopup
        isModalShow={handleShowGoogleModal()}
        modalCloseButton
        handleModalClose={() => {
          setSelectedObj(null);
          dispatch(updateSocialMediaSettingSuccessOrFailure({ type: '' }));
          handleModalClose();
        }}
        modalHeader={() => (
          <>
            <div className="stngs-badge">
              <ImageValidation isImgValid defaultImg="stats/google-stats" customName="settings social" />
            </div>
            <h3 className={`aps-head`}>Select the Google My Business account you'd like to connect to {APP_TITLE}</h3>
          </>
        )}
        modalHeaderType=""
        modalBody={() =>
          isGoogleAccountFetching ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Loading />
              <div style={{ color: '#013B71' }}>Hang tight... This may take a minute!</div>
            </div>
          ) : (
            <GoogleAccountPopup
              accountDetailsData={googleAccountBusinessList}
              handleGoogleAccountSelect={(id) => {
                if (googleCredentialId) {
                  dispatch(
                    loadGoogleLocationRequest({
                      credentialId: googleCredentialId,
                      accountId: id,
                      eventCallback: (event, dataPacketNo) => {
                        const messageEvent = event as MessageEvent;
                        dispatch(loadGoogleLocationResponse({ data: JSON.parse(messageEvent.data), dataPacketNo }));
                      }
                    })
                  );
                }
              }}
              locationsList={locationsList}
              handleConnect={(locationObject: any) => {
                if (googleCredentialId && accountId) {
                  handleModalClose();
                  dispatch(connectGoogleRequest({ googleCredentialId, googlePlacesId: locationObject.id, accountId }));
                }
              }}
              selectedObj={selectedObj}
              setSelectedObj={setSelectedObj}
            />
          )
        }
        modalFooter={() => null}
      />
    </div>
  ) : null;
};
