import { settingsRallioProfileContainerTypes } from 'types';
import { RALLIO_PROFILE_TAB_FILEDS, CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RIPPLE_COLOR } from 'utils/constants';
import { compareProfileObjects } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const TimezoneInfoSection = ({
  values,
  actualValues,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  handleIndividualCancel,
  handleIndividualSave,
  timeZonesList,
  timeZonesData,
  setTimeZonesData
}: settingsRallioProfileContainerTypes.ISettingsTimezone) => {
  const { optionalParams } = useAccountSwitcherData();

  return (
    <div className="settings-rallio-profile-new-section wn-timezone wnh">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Timezone</h3>
        </div>
        <div className="content-right-section form-elements-new">
          {/* ${isProfileNameEditDisabled ? 'events-none pointer-events-none' : ''} */}
          <div className={`form-group form-field-item s fg-dropdown m-align-items cs-item cs-clean s-mb2 ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE)}`}>
            <CustomDropdown
              classNamePrefix="cs-select"
              id="dl-store-details-time-zone"
              // menuIsOpen={true}  // need to remove
              placeholder={''}
              onChange={(e: any) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, e.value);
                setTimeZonesData({ id: e.id, value: e.value, label: e.label });
              }}
              value={timeZonesData}
              options={timeZonesList}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE)}`}>
              Time Zone
              <span className="mandatory cursor-help">*</span>
            </span>
          </div>
        </div>
      </div>

      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.ADDRESS, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <div className="fc-actions g-20">
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.ADDRESS)}>
                  Cancel
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary size-xs`} onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.ADDRESS)}>
                  Save
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
