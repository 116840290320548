import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUserTabListRequest, rpPromotionVaultListRequest } from 'actions';
import { IStore, ITableHeader } from 'types';
import { Loading, ScrollableCarouselTable } from 'components';
import { handleLabelDisplay } from 'utils/helpers';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { RP_PRGRM_ACTION_PRGRM, SCROLL_THRESHOLD, SIDE_NAV_NAME, USER_DETAIL_MODAL_TAB_KEY, USER_OWNERSHIP, USER_REWARD_LIST_TABLE_HEADER, USER_REWARD_LIST_TABLE_HEADER_KEY } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { UserDetailSearch } from './UserDetailSearch';
import { IUserRewardsListDetail } from 'types/reward-programs/reward-programs-api';

export const UserRewardsList = () => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();

  const userId = useSelector((state: IStore) => state.common.userDetailObj.currentUserDetails?.id);
  const isListFetching = useSelector((state: IStore) => state.common.userDetailObj.isListFetching);
  const search = useSelector((state: IStore) => state.common.userDetailObj.search);
  const rewardsList = useSelector((state: IStore) => state.common.userDetailObj.rewardsList);
  const activeTab = useSelector((state: IStore) => state.common.userDetailObj.activeTab);
  const currentPageCount = useSelector((state: IStore) => state.common.userDetailObj.currentPage);
  const promotionVaultList = useSelector((state: IStore) => state.rewardPrograms.promotionVaultList);
  const pvLocationId = useSelector((state: IStore) => state.accountSwitcher.pvLocationId);

  useEffect(() => {
    if (id && userOwnership && isValidId && userId) {
      handleRewardsListRequest(0);
    }
  }, [id, userOwnership, isValidId, userId]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === USER_DETAIL_MODAL_TAB_KEY.REWARDS && subNavPageName !== SIDE_NAV_NAME.EMP_ADV_REWARD_PROGRAMS) {
      dispatch(rpPromotionVaultListRequest(pvLocationId));
    }
  }, [activeTab, pvLocationId, subNavPageName, dispatch]);

  const handleRewardsListRequest = (page: number) => {
    if (userId) {
      dispatch(
        getCurrentUserTabListRequest({
          page,
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisorId: id } : { accountId: id }),
          userId,
          mediaType: activeTab,
          ...(search && { search })
        })
      );
    }
  };

  const renderColumn = useCallback(
    (rowObj: IUserRewardsListDetail, header: ITableHeader<IUserRewardsListDetail>) => {
      switch (header.identifier) {
        case USER_REWARD_LIST_TABLE_HEADER_KEY.PROGRAM:
          return (
            <div className="cmfrt-tv-item" title={rowObj.programName}>
              <span>{rowObj.programName}</span>
            </div>
          );
        case USER_REWARD_LIST_TABLE_HEADER_KEY.ACTIONS:
          const actionsCountObj = rowObj?.programAction?.program.split(' ');
          const actionsCount =
            actionsCountObj[0] === RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS
              ? rowObj?.engagementFromAssets || 0
              : actionsCountObj[0] === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED
              ? rowObj?.assetsSubmitted || 0
              : actionsCountObj[0] === RP_PRGRM_ACTION_PRGRM.ADVOCACY_POSTS
              ? rowObj?.advocacyPosts || 0
              : 0;
          return (
            <div className="cmfrt-tv-item">
              <span className="ctv-state">{[RP_PRGRM_ACTION_PRGRM.ENGAGEMENT_ASSETS, RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED].includes(actionsCountObj[0]) ? 'Submitted' : 'Published'}</span>
              <div className="ctv-counts-wrap">
                <span className="ctv-counts">{actionsCount}</span>
                <span className="ctv-type">{actionsCount > 1 ? 'Images/Videos' : 'Image/Video'}</span>
              </div>
            </div>
          );
        case USER_REWARD_LIST_TABLE_HEADER_KEY.REWARDS:
          return (
            <div className="cmfrt-rwrds-value">
              <ImageValidation isImgValid defaultImg="rewards" customClassname="rewards" customName="rewards-image" />
              <span>{rowObj?.giftCost ? `$${handleLabelDisplay(promotionVaultList, rowObj.rewards, 'value', 'giftCost')} Gift Card` : rowObj?.nonCashRewards}</span>
            </div>
          );
        default:
          return '';
      }
    },
    [promotionVaultList]
  );

  const userRewardsTableHeader = useMemo(() => {
    return USER_REWARD_LIST_TABLE_HEADER.map(
      (it): ITableHeader<IUserRewardsListDetail> => ({
        label: it.label,
        labelString: it.label,
        identifier: it.key,
        renderColumn
      })
    );
  }, [renderColumn]);

  return (
    <>
      <UserDetailSearch searchValue={search} handleInputSearch={() => handleRewardsListRequest(0)} />
      {isListFetching && !currentPageCount ? (
        <Loading />
      ) : (
        <div className="resuseTable adptTable cmfrt-td">
          <div className={`adpt-height${!rewardsList.length ? 'no-data-found' : ''}`} id="rewardsUserScrollTarget">
            <ScrollableCarouselTable<IUserRewardsListDetail>
              data={[rewardsList]}
              headers={userRewardsTableHeader}
              tableClassName="responsiveTable"
              isFetching={isListFetching}
              scrollableTargetString="rewardsUserScrollTarget"
              scrollDivHeight={500}
              ipadBoundary={768}
              ipadColCount={1}
              mobileColCount={2}
              mobileBoundary={575}
              scrollThreshold={SCROLL_THRESHOLD}
              onScroll={() => {
                handleRewardsListRequest(currentPageCount + 1);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
