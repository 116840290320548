import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { CAMPAIGN_INIT_FILTER_DATA, NoDataFoundProps, SCROLL_THRESHOLD, PAGINATION_PER_PAGE, ALERT_BOX_TYPES } from 'utils/constants';
import { useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';
import { getCampaignsReqParams } from 'utils/helpers';
import { campaignsApiTypes, IStore } from 'types';
import { alertBoxCall, Loading, NoDataFound } from 'components';
import { campaignsListRequest, campaignsStatsRequest, campaignResponseMsgReset } from 'actions';
import { CampaignCard } from './CampaignCard';

export const CampaignList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);

  const { id, userOwnership, isValidId, currentRoute } = useAccountSwitcherData();

  const isListFetching = useSelector((state: IStore) => state.campaigns.isListFetching);
  const campaignsList = useSelector((state: IStore) => state.campaigns.campaignsList);
  const isStatsFetching = useSelector((state: IStore) => state.campaigns.isStatsFetching);
  const total = useSelector((state: IStore) => state.campaigns.campaignStats.total);
  const successMsg = useSelector((state: IStore) => state.campaigns.responseMsg.successMsg);
  const failureMsg = useSelector((state: IStore) => state.campaigns.responseMsg.failureMsg);
  const currentPage = useSelector((state: IStore) => state.campaigns.currentPage);
  const isCreateOrEditCampaign = useSelector((state: IStore) => state.campaigns.isCreateOrEditCampaign);

  const [totalPagecount, setTotalPageCount] = useState(0);

  useEffect(() => {
    setTotalPageCount(Math.ceil(total / PAGINATION_PER_PAGE));
  }, [total]); // eslint-disable-line

  // Get campaigns list
  useEffect(() => {
    if (id && userOwnership && isValidId) {
      handleCampaignsList(1);
    }
  }, [id, userOwnership, isValidId, queryParams?.text, queryParams?.source, queryParams?.status]); // eslint-disable-line

  // If the campaign has been added, updated, activated or deactivated, get updated campaign stats and list
  useEffect(() => {
    if (successMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, successMsg);
      handleCampaignStats();
      handleCampaignsList(1);
      dispatch(campaignResponseMsgReset());
    }
    if (failureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, failureMsg);
      dispatch(campaignResponseMsgReset());
    }
  }, [successMsg, failureMsg]); // eslint-disable-line

  const handleCampaignStats = () => {
    const reqParams = getCampaignsReqParams(id, userOwnership, successMsg, queryParams);
    dispatch(campaignsStatsRequest(reqParams));
  };

  const handleCampaignsList = (page: number) => {
    const reqParams = getCampaignsReqParams(id, userOwnership, successMsg, queryParams, page);
    dispatch(campaignsListRequest(reqParams));
  };

  const handleLoadMoreItems = () => {
    handleCampaignsList(currentPage + 1);
  };

  const handleSwitchToCampaignDetail = (campaignId: number) => {
    navigate({
      pathname: `/${userOwnership}/${id}/${currentRoute}/${campaignId}`,
      search: `?${new URLSearchParams({
        ...(queryParams?.text && { text: queryParams.text }),
        ...(queryParams?.source && { source: queryParams.source }),
        ...(queryParams?.status && { status: queryParams.status })
      }).toString()}`
    });
  };

  return (
    <Fragment>
      <div className={`${isCreateOrEditCampaign ? ` pointer-events-none` : ''}`}>
        {isCreateOrEditCampaign ? <Loading /> : null}
        {isListFetching && currentPage === 1 ? (
          <Loading />
        ) : campaignsList.length ? (
          <div className="infinite-list post-grid-wrp indvdl-campaigns cl-postlist campaigns-res" id="campaigns-ifs">
            <InfiniteScroll
              scrollThreshold={SCROLL_THRESHOLD}
              next={handleLoadMoreItems}
              hasMore={isStatsFetching ? true : totalPagecount >= currentPage}
              dataLength={campaignsList.length}
              loader={isListFetching ? <Loading /> : null}
              className="local-ini"
              scrollableTarget="scrollTarget"
            >
              <div className="mainContent animate__animated animate__fadeInUp hub-coupons-wrp-section lpx vpy-20">
                {campaignsList.map((campaignItem: campaignsApiTypes.ICampaignListData, campaignIndex: number) => {
                  return (
                    <CampaignCard key={`campaign-item-${campaignIndex}`} campaignItem={campaignItem} onDetailView={() => handleSwitchToCampaignDetail(campaignItem.id)} className={'coupon-card p-0'} />
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        ) : !isListFetching && !campaignsList.length ? (
          <NoDataFound size={NoDataFoundProps.FULL} />
        ) : (
          <Loading />
        )}
      </div>
    </Fragment>
  );
};
