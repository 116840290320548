// import ShowMoreText from 'react-show-more-text';

import { commonWidgetTypes } from 'types';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES } from 'utils/constants';
import { Paragraph } from 'widgets/Text';

export const CustomShowMoreText = (props: commonWidgetTypes.ICustomShowMoreText) => (
  // <ShowMoreText lines={props.isMoreLine ? 8 : 3} more="Show more" less="Show less" className="sm-content" anchorClass="sm-anchor" onClick={() => props.onClick()} expanded={props.expanded}>
  <Paragraph
    customClassname={props.customPClassname}
    customText={props.customText}
    actionType={!props.expanded ? AP_FRAN_SHOW_LINE_BREAK_TYPES.LIST_OR_DETAIL : AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
  />
  // </ShowMoreText>
);
