import { aiImageGenertaionContainerTypes } from 'types/ai-image-generation';

export enum AIImageResolutionTypes {
  SMALL = '256x256',
  MEDIUM = '512x512',
  LARGE = '1024x1024'
}

export const AI_IMAGE_GENERATION_FIELDS: aiImageGenertaionContainerTypes.IAIImageGenerationField = {
  IMAGE_DESCRIPTION: 'image_description',
  IMAGE_RESOLUTION: 'image_resolution',
  IMAGE_STYLE: 'image_style'
};

export const AI_IMAGE_GENERATION_TAB_NAMES = {
  CREATE_AI_IMAGE: 'create_ai_image',
  AI_IMAGE_RESULTS: 'ai_image_results'
};

export const AI_IMAGE_GENERATION_FIELD_LABELS = {
  IMAGE_DESCRIPTION: 'Enter description',
  IMAGE_STYLE: 'Select style',
  IMAGE_SIZE: 'Choose the image resolution'
};

export const AI_IMAGE_GENERATION_FIELD_PLACEHOLDER = {
  IMAGE_DESCRIPTION: 'Enter the description to generate ai image'
};

// Realism, Abstract, Fantasy, Cartoonish, Painting, Surrealism, Photographic
export const AI_IMAGE_GENERATION_STYLES = [
  { id: 0, label: 'Surrealism', value: 'surrealism' },
  { id: 1, label: 'Realism', value: 'realism' },
  { id: 2, label: 'Abstract', value: 'abstract' },
  { id: 3, label: 'Fantasy', value: 'fantasy' },
  { id: 4, label: 'Cartoonish', value: 'cartoonish' },
  { id: 5, label: 'Painting', value: 'painting' },
  { id: 6, label: 'Photographic', value: 'photographic' }
];

export const AI_IMAGE_GENERATION_RESOLUTIONS = [
  { id: 0, label: AIImageResolutionTypes.SMALL, value: AIImageResolutionTypes.SMALL },
  { id: 1, label: AIImageResolutionTypes.MEDIUM, value: AIImageResolutionTypes.MEDIUM },
  { id: 2, label: AIImageResolutionTypes.LARGE, value: AIImageResolutionTypes.LARGE }
];

export const AI_IMAGE_GENERATION_RESOLUTION = [
  { id: 1, label: 'Square (1:1)', value: '1024x1024' },
  { id: 2, label: 'Portrait (9:16)', value: '1024x1792' },
  { id: 3, label: 'Landscape (16:9)', value: '1792x1024' }
  // { id: 4, label: 'Facebook Cover (2.77:1)', value: 'facebook_cover' },
  // { id: 5, label: 'X (Formerly Twitter) Cover (3:1)', value: 'twitter_cover' }
];

export const PERSONA_FIELDS_FOR_CAPTION_GENERATION = [
  { key: 'name', label: 'Name' },
  { key: 'occupation', label: 'Occupation' }
];

export const AI_IMAGE_MODIFY_DETAIL_LEVEL = [
  { id: 1, label: 'Very Low Detail (Smooth)', value: -1 },
  { id: 2, label: 'Low Detail (Airbrushed look)', value: 0 },
  { id: 3, label: 'Detailed (Realistic)', value: 1 },
  { id: 4, label: 'Very Detailed (Gritty)', value: 2 }
];

export const AI_IMAGE_MODIFY_CREATIVITY_LEVEL = [
  { id: 1, label: 'Very Creative', value: 9.0 },
  { id: 2, label: 'Balanced', value: 7.0 },
  { id: 3, label: 'Less Creative', value: 5.0 },
  { id: 4, label: 'Not Creative', value: 3.0 }
];

export const AI_IMAGE_DETAIL_OPTIONS = [
  { label: 'Default', value: 'default' },
  { label: 'Low Detail', value: 'low_detail' },
  { label: 'Detailed', value: 'detailed' }
];

export const AI_IMAGE_STYLE_OPTIONS = [
  { label: 'Default', value: 'default' },
  { label: 'Photorealistic', value: 'photorealistic' },
  { label: 'Illustration', value: 'illustration' }
];

export const AI_IMAGE_RESOLUTION_OPTIONS = [
  { label: 'Default', value: 'default' },
  { label: 'Photorealistic', value: 'photorealistic' },
  { label: 'Illustration', value: 'illustration' }
];

export const THINK_LITTLE_HARDER_TOOLTIP_TEXT = `If you like the image you see but it isn't quite right, use this option to generate a small variation that may look better.`;

export const AI_TEXT_CONTENT_INITIAL_VALUE = { content: '', image: '' };
