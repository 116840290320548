import { ILeaderBoardAnalyticsFilter } from 'analytics/types';
import { COMMON_SOCIAL_PROVIDER_TYPE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';
import { dateHelperForMonth } from '../../helpers';

export const LEADER_BOARD_ANALYTICS_FILTER_INITIAL_VALUE: ILeaderBoardAnalyticsFilter = {
  dateRange: dateHelperForMonth(true),
  platform: COMMON_SOCIAL_PROVIDER_TYPE.ALL,
  locationId: null,
  ancestorFranchisorId: null,
  locationListId: null,
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  isLocationAllHubSelected: false,
  search: ''
};

export const TABLE_HEADERS = [
  { label: 'title', value: 'Ranking', class: 'sorted active' },
  { label: 'posts_count', value: 'Published Posts' },
  { label: 'follower', value: 'Followers', changes: 'Likes' },
  { label: 'profile_view', value: 'Page Views', tooltip: 'The number of times your social media pages were visited.' },
  { label: 'engagement', value: 'Daily Post Engagement', changes: 'Engagement', tooltip: 'Total likes, comments and shares your content receives on average each day during the date range selected.' },
  { label: 'review', value: 'Reviews', tooltip: 'The number of online reviews people have left for your business.' },
  { label: 'reputation', value: 'Reputation', tooltip: 'Your average reputation score for your reviews on five-star scale.' }
];

export const SORTING_OPTIONS = {
  header: [
    { value: 'title', label: 'Alphabetical' },
    { value: 'posts_count', label: 'Published Posts' },
    { value: 'follower', label: 'Followers' },
    { value: 'profile_view', label: 'Page Views' },
    { value: 'engagement', label: 'Daily Post Engagement' },
    { value: 'review', label: 'Reviews' },
    { value: 'reputation', label: 'Reputation' }
  ],
  sortOrder: [
    { value: 'H2L', label: 'High to Low' },
    { value: 'L2H', label: 'Low to High' },
    { value: 'DH2L', label: 'Delta High to Low' },
    { value: 'DL2H', label: 'Delta Low to High' }
  ]
};

export const TABLE_FORMAT_OPTIONS = [
  { label: 'posts_count', value: 'posts_count', delta: 'postsCountDelta' },
  { label: 'follower', value: 'follower', delta: 'followerDelta' },
  { label: 'profile_view', value: 'profile_view', delta: 'profileViewDelta' },
  { label: 'engagement', value: 'engagement', delta: 'engagementDelta' },
  { label: 'review', value: 'review', delta: 'reviewDelta' },
  { label: 'reputation', value: 'reputation', delta: 'reputationDelta' }
];

export const TABLE_FORMAT_OBJECT = {
  platform: ['facebook', 'google_plus', 'instagram', 'twitter', 'yelp', 'linkedin'],
  labels: ['followers', 'profile_views', 'posts_count', 'daily_engagement', 'reputation', 'reviews']
};

export const TABLE_MEDALS = ['award-gold', 'award-silver', 'award-bronze', 'no-award'];

export enum LeaderboardvalidQueryParams {
  PLATFORM = 'platform',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id',
  ALL_HUBS = 'all_hubs',
  ASCENDING = 'ascending',
  ASCENDING_DELTA = 'ascending_delta',
  SORT_BY = 'sort_by',
  SEARCH = 'search'
}

export const KEYWORD_CATEGORY_LB_TABLE_HEADER = { value: 'Keyword Category', label: 'Keyword Category' };
