import { EditorState } from 'draft-js';

import { apFranContainerTypes, apFranReducerTypes, commonProps } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, FRANCHISORS_SELECTIONS } from 'utils/constants';

export const ALL_LOCATIONS = 'All Locations';
export const ALL_BRANDS = 'All Content Suppliers';
export const LOCATION = 'Location';
export const LOCATION_LISTS = 'Location Lists';
export const ALL_HUBS = 'All Hubs';
export const ALL_LOCATION_LISTS = 'All Location Lists';
export const HUBS = 'Hubs';
export const CORP_HUBS = 'Corporate Hubs';

export const AP_SELECTED_LOC_INIT_DATA = {
  id: 0,
  name: ALL_LOCATIONS
};

export const ALL_HUB_OPTIONS = { id: 0.5, value: ALL_HUBS, label: ALL_HUBS, type: FRANCHISORS_SELECTIONS };

export enum CreatorMediaTypeForUser {
  PERSONAL = 'personal',
  EVERYONE = 'everyone'
}

export enum AddMediaExpirationValues {
  NEVER = 'never',
  EXPIRE = 'expire'
}

export const AP_AVAIL_MEDIA_FOR_USER: commonProps.IMainPageRouteData[] = [
  { name: CreatorMediaTypeForUser.PERSONAL, label: 'Available for Personal Use Only' },
  { name: CreatorMediaTypeForUser.EVERYONE, label: 'Available for Everyone Else' }
];

export const AP_MEDIA_EXPIRATION: commonProps.IMainPageRouteData[] = [
  { name: AddMediaExpirationValues.NEVER, label: 'Always Available' },
  { name: AddMediaExpirationValues.EXPIRE, label: 'Custom' }
];

export enum FranCreatorScheduleType {
  MANUAL_SCHEDULE = 'manuallySchedule',
  AUTO_SCHEDULE = 'autoSchedule',
  SYNDICATE_TO_ACCOUNT_DB = 'syndicateAccDB'
}

export enum FranCreatorScheduleDateType {
  ANYTIME = 'anytime',
  CUSTOM = 'custom'
}

export const AP_DATE_SELECTION_TYPE: commonProps.IMainPageRouteData[] = [
  { name: FranCreatorScheduleDateType.ANYTIME, label: 'Anytime' },
  { name: FranCreatorScheduleDateType.CUSTOM, label: 'Custom' }
];

export const AP_DELETE_CONTENT_ITEM: commonProps.IMainPageRouteData[] = [
  { name: 'removeFromDB', label: 'Remove this post from my database' },
  { name: 'removeFromHub&Loc', label: 'Remove this post from my Hubs and any scheduled locations' },
  { name: 'removeFromMedia', label: 'Remove this post from Facebook, X (Formerly Twitter), Linkedin and Google if it has already posted' }
];

export enum FranCreatorDeleteType {
  REMOVE_FROM_DB = 'removeFromDB',
  REMOVE_FROM_HUB_AND_LOC = 'removeFromHub&Loc',
  REMOVE_FROM_SOCIAL_MEDIA = 'removeFromMedia'
}

export const AP_UPDATED_MSG_INIT_OBJ: apFranReducerTypes.IAddPostFranUpdatedMessage = {
  contentSuccessMsg: null,
  contentFailureMsg: null
};

export const AP_CONTENT_ACTION_TYPE = {
  SAVE_DRAFT: 'save_as_draft',
  APPROVE_AND_SAVE_TO_DB: 'approve_and_save_to_database',
  APPROVE_AND_SCHEDULE: 'approve_and_schedule',
  APPROVE_AND_INIT_AUTO_SCHEDULE: 'approve_and_initiate_auto_schedule',
  APPROVE_AND_SYNDICATE: 'approve_and_syndicate',
  SCHEDULE: 'schedule',
  POST_NOW: 'post',
  SINGLE_CAMPAIGN_POST: 'campaign_post',
  MULTIPLE_CAMPAIGN_POSTS: 'multiple_campaign_posts'
};

export const AP_FRAN_ADD_LINK_INIT_DATA = {
  isLinkFetching: false,
  linkData: null
};

export enum FranScheduledLocName {
  ALL_LOCATIONS = 'all_locations',
  CORPORATE_ONLY = 'corporate_only',
  SPECIFIC_LOCATIONS = 'specific_locations',
  SPECIFIC_LOCATION_LIST = 'specific_location_list',
  FRANCHISE_CONSULTANT = 'franchise_consultants',
  ALL_FRANCHISE_CONSULTANT = 'all_franchise_consultants'
}

export enum FranScheduledLocLabel {
  ALL_LOCATIONS = 'All Locations',
  CORPORATE_ONLY = 'Corporate Only',
  SPECIFIC_LOCATIONS = 'Specific Locations/Lists',
  SPECIFIC_LOCATION_LIST = 'Specific Location List',
  FRANCHISE_CONSULTANT = 'Select Franchise Consultants',
  ALL_FRANCHISE_CONSULTANT = 'All Franchise Consultants',
  ALL_BRANDS = 'All Brands',
  SPECIFIC_BRANDS = 'Specific Brands'
}

export enum FranchisorSyndicationMediaType {
  CONTENT = 'content',
  PHOTO = 'photo',
  VIDEO = 'video',
  DOCUMENT = 'document'
}

export enum GoogleCallToActionOptionsName {
  BOOK = 'BOOK',
  ORDER = 'ORDER',
  SHOP = 'SHOP',
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  GET_OFFER = 'GET_OFFER',
  CALL = 'CALL',
  NONE = 'NONE'
}
export enum GoogleCallToActionOptionsLabel {
  BOOK = 'Book',
  ORDER = 'Order',
  SHOP = 'Shop',
  LEARN_MORE = 'Learn More',
  SIGN_UP = 'Sign Up',
  GET_OFFER = 'Get Offer',
  CALL = 'Call',
  NONE = 'None'
}

export enum FBCallToActionOptionsName {
  ADD_TO_CART = 'ADD_TO_CART',
  APPLY_NOW = 'APPLY_NOW',
  BET_NOW = 'BET_NOW',
  BOOK_NOW = 'BOOK_NOW',
  BOOK_TRAVEL = 'BOOK_TRAVEL',
  BUY = 'BUY',
  BUY_NOW = 'BUY_NOW',
  BUY_TICKETS = 'BUY_TICKETS',
  CALL = 'CALL',
  CALL_ME = 'CALL_ME',
  CALL_NOW = 'CALL_NOW',
  CHECK_AVAILABILITY = 'CHECK_AVAILABILITY',
  CIVIC_ACTION = 'CIVIC_ACTION',
  CONTACT_US = 'CONTACT_US',
  DIAL_CODE = 'DIAL_CODE',
  DONATE = 'DONATE',
  DONATE_NOW = 'DONATE_NOW',
  DOWNLOAD = 'DOWNLOAD',
  EVENT_RSVP = 'EVENT_RSVP',
  FIND_YOUR_GROUPS = 'FIND_YOUR_GROUPS',
  GET_DIRECTIONS = 'GET_DIRECTIONS',
  GET_EVENT_TICKETS = 'GET_EVENT_TICKETS',
  GET_MOBILE_APP = 'GET_MOBILE_APP',
  GET_OFFER = 'GET_OFFER',
  GET_OFFER_VIEW = 'GET_OFFER_VIEW',
  GET_PROMOTIONS = 'GET_PROMOTIONS',
  GET_QUOTE = 'GET_QUOTE',
  GET_SHOWTIMES = 'GET_SHOWTIMES',
  GIVE_FREE_RIDES = 'GIVE_FREE_RIDES',
  GO_LIVE = 'GO_LIVE',
  INQUIRE_NOW = 'INQUIRE_NOW',
  INSTAGRAM_MESSAGE = 'INSTAGRAM_MESSAGE',
  INSTALL_APP = 'INSTALL_APP',
  INSTALL_MOBILE_APP = 'INSTALL_MOBILE_APP',
  INTERESTED = 'INTERESTED',
  JOIN_GROUP = 'JOIN_GROUP',
  LEARN_MORE = 'LEARN_MORE',
  LIKE_PAGE = 'LIKE_PAGE',
  LINK_CARD = 'LINK_CARD',
  LISTEN_MUSIC = 'LISTEN_MUSIC',
  LISTEN_NOW = 'LISTEN_NOW',
  LOYALTY_LEARN_MORE = 'LOYALTY_LEARN_MORE',
  MESSAGE_PAGE = 'MESSAGE_PAGE',
  MISSED_CALL = 'MISSED_CALL',
  MOBILE_DOWNLOAD = 'MOBILE_DOWNLOAD',
  NO_BUTTON = 'NO_BUTTON',
  OPEN_INSTANT_APP = 'OPEN_INSTANT_APP',
  OPEN_LINK = 'OPEN_LINK',
  OPEN_MESSENGER_EXT = 'OPEN_MESSENGER_EXT',
  ORDER_NOW = 'ORDER_NOW',
  PAY_TO_ACCESS = 'PAY_TO_ACCESS',
  PLAY_GAME = 'PLAY_GAME',
  PLAY_GAME_ON_FACEBOOK = 'PLAY_GAME_ON_FACEBOOK',
  PRE_REGISTER = 'PRE_REGISTER',
  PURCHASE_GIFT_CARDS = 'PURCHASE_GIFT_CARDS',
  RAISE_MONEY = 'RAISE_MONEY',
  RECORD_NOW = 'RECORD_NOW',
  REFER_FRIENDS = 'REFER_FRIENDS',
  REGISTER_NOW = 'REGISTER_NOW',
  REMIND_ME = 'REMIND_ME',
  REQUEST_TIME = 'REQUEST_TIME',
  SAVE = 'SAVE',
  SEARCH = 'SEARCH',
  SEARCH_MORE = 'SEARCH_MORE',
  SEE_MENU = 'SEE_MENU',
  SEE_MORE = 'SEE_MORE',
  SELL_NOW = 'SELL_NOW',
  SEND_INVITES = 'SEND_INVITES',
  SEND_TIP = 'SEND_TIP',
  SEND_UPDATES = 'SEND_UPDATES',
  SHOP_NOW = 'SHOP_NOW',
  SIGN_UP = 'SIGN_UP',
  START_ORDER = 'START_ORDER',
  SUBSCRIBE = 'SUBSCRIBE',
  SWIPE_UP_PRODUCT = 'SWIPE_UP_PRODUCT',
  SWIPE_UP_SHOP = 'SWIPE_UP_SHOP',
  TRY_IN_CAMERA = 'TRY_IN_CAMERA',
  TRY_IT = 'TRY_IT',
  TRY_ON = 'TRY_ON',
  UPDATE_APP = 'UPDATE_APP',
  USE_APP = 'USE_APP',
  USE_MOBILE_APP = 'USE_MOBILE_APP',
  VIEW_INSTAGRAM_PROFILE = 'VIEW_INSTAGRAM_PROFILE',
  VISIT_WORLD = 'VISIT_WORLD',
  VOTE_NOW = 'VOTE_NOW',
  WATCH_MORE = 'WATCH_MORE',
  WATCH_VIDEO = 'WATCH_VIDEO',
  WHATSAPP_LINK = 'WHATSAPP_LINK',
  WHATSAPP_MESSAGE = 'WHATSAPP_MESSAGE'
}
export enum FBCallToActionOptionsLabel {
  ADD_TO_CART = 'Add To Cart',
  APPLY_NOW = 'Apply Now',
  BET_NOW = 'Bet Now',
  BOOK_NOW = 'Book Now',
  BOOK_TRAVEL = 'Book Travel',
  BUY = 'Buy',
  BUY_NOW = 'Buy Now',
  BUY_TICKETS = 'Buy Tickets',
  BUY_GIFT_CARD = 'Buy Gift Card',
  CALL = 'Call',
  CALL_ME = 'Call Me',
  CALL_NOW = 'Call Now',
  CHECK_AVAILABILITY = 'Check Availability',
  CIVIC_ACTION = 'Civic Action',
  CONTACT_US = 'Contact Us',
  DIAL_CODE = 'Dial Code',
  DONATE = 'Donate',
  DONATE_NOW = 'Donate Now',
  DOWNLOAD = 'Download',
  EVENT_RSVP = 'Event Rsvp',
  EMPTY = '',
  FIND_YOUR_GROUPS = 'Find Your Groups',
  GET_ACCESS = 'Get Access',
  GET_DIRECTIONS = 'Get Directions',
  GET_EVENT_TICKETS = 'Get Event Tickets',
  GET_TICKETS = 'Get tickets',
  GET_MOBILE_APP = 'Get Mobile App',
  GET_APP = 'Get App',
  GET_OFFER = 'Get Offer',
  GET_OFFER_VIEW = 'Get Offer view',
  GET_PROMOTIONS = 'Get Promotions',
  GET_QUOTE = 'Get Quote',
  GET_SHOWTIMES = 'Get Showtimes',
  GIVE_FREE_RIDES = 'Give Free Rides',
  GO_LIVE = 'Go Live',
  INQUIRE_NOW = 'Inquire Now',
  INSTAGRAM_MESSAGE = 'Instagram Message',
  INSTALL_APP = 'Install App',
  INSTALL_MOBILE_APP = 'Install Mobile App',
  INSTALL_NOW = 'Install Now',
  INTERESTED = 'INTERESTED',
  INVITE_FRIENDS = 'Invite Friends',
  JOIN_GROUP = 'Join Group',
  LEARN_MORE = 'Learn More',
  LEARN_MORE_MESSENGER = 'Learn More in Messenger',
  LIKE_PAGE = 'Like Page',
  LINK_CARD = 'Link Card',
  LISTEN_MUSIC = 'Listen Music',
  LISTEN_NOW = 'Listen Now',
  LOYALTY_LEARN_MORE = 'Loyalty Learn More',
  MESSAGE_PAGE = 'Message Page',
  MISSED_CALL = 'Missed Call',
  MOBILE_DOWNLOAD = 'Mobile Download',
  NO_BUTTON = 'No Button',
  OPEN_INSTANT_APP = 'Open Instant App',
  OPEN_LINK = 'Open Link',
  OPEN_MESSENGER_EXT = 'Open Messenger Ext',
  ORDER_NOW = 'Order Now',
  PAY_TO_ACCESS = 'Pay To Access',
  PLAY_GAME = 'Play Game',
  PLAY_GAME_ON_FACEBOOK = 'Play Game on Facebook',
  PRE_REGISTER = 'Pre-register',
  PURCHASE_GIFT_CARDS = 'Purchase Gift Cards',
  RAISE_MONEY = 'Raise Money',
  RECORD_NOW = 'Record Now',
  REFER_FRIENDS = 'Refer Friends',
  REGISTER_NOW = 'Register Now',
  REMIND_ME = 'Remind Me',
  REQUEST_TIME = 'Request Time',
  SAVE = 'Save',
  SEARCH = 'Search',
  SEARCH_MORE = 'Search More',
  SEE_MENU = 'See Menu',
  SEE_MORE = 'See More',
  SELL_NOW = 'Sell Now',
  SEND_INVITES = 'Send Invites',
  SEND_TIP = 'Send Tip',
  SEND_UPDATES = 'Send Updates',
  SEND_MESSAGE = 'Send Message',
  SHOP_NOW = 'Shop Now',
  SIGN_UP = 'Sign Up',
  START_ORDER = 'Start Order',
  SUBSCRIBE = 'Subscribe',
  SWIPE_UP_PRODUCT = 'Swipe Up Product',
  SWIPE_UP_SHOP = 'Swipe Up Shop',
  SEE_DISCUSSION = 'See discussion',
  TRY_IN_CAMERA = 'Try In Camera',
  TRY_IT = 'Try It',
  TRY_ON = 'Try On',
  UPDATE_APP = 'Update App',
  USE_APP = 'Use App',
  USE_MOBILE_APP = 'Use Mobile App',
  VIEW_INSTAGRAM_PROFILE = 'Visit Instagram Profile',
  VISIT_WORLD = 'Visit World',
  VOTE_NOW = 'Vote Now',
  WATCH_MORE = 'Watch More',
  WATCH_VIDEO = 'Watch Video',
  WHATSAPP_LINK = 'Whatsapp Link',
  WHATSAPP_MESSAGE = 'Whatsapp Message'
}

export const OVERRIDE_POSTING_PERMISSIONS = `Checking this box will override the posting permissions of each location
and automatically approve this content for publishing.`;

export const AP_FRAN_SHOW_LINE_BREAK_TYPES = {
  LIST_OR_DETAIL: 'list-or-detail',
  PREVIEW: 'media-preview'
};

export const AUTO_SCHEDULE_DISABLE_PACKAGE = `Auto-scheduled packages cannot be manually scheduled. Click 'Edit' and change this package to 'Manually Schedule Only' to post it at a specific time.`;

export const MANUALLY_SCHEDULE_DISABLE_PACKAGE = `You must approve this package to schedule it.`;

export const AP_FRAN_PERSONALIZED_OPTION_DATA: apFranContainerTypes.ICreatorPersonalizedOption[] = [
  { id: 1, value: '[business_name]', label: '[business_name]', disabled: false },
  { id: 2, value: '[phone_number]', label: '[phone_number]', disabled: false },
  { id: 3, value: '[mailing_address]', label: '[mailing_address]', disabled: false },
  { id: 4, value: '[email_address]', label: '[email_address]', disabled: false },
  { id: 5, value: '[website_url]', label: '[website_url]', disabled: false },
  { id: 6, value: '[job_opportunities_url]', label: '[job_opportunities_url]', disabled: false },
  { id: 7, value: '[manager_name]', label: '[manager_name]', disabled: false },
  { id: 8, value: '[custom_field_1]', label: '[custom_field_1]', disabled: false },
  { id: 9, value: '[custom_field_2]', label: '[custom_field_2]', disabled: false },
  { id: 10, value: '[custom_field_3]', label: '[custom_field_3]', disabled: false },
  { id: 11, value: '[custom_field_4]', label: '[custom_field_4]', disabled: false },
  { id: 12, value: '[custom_field_5]', label: '[custom_field_5]', disabled: false },
  { id: 13, value: '[custom_field_6]', label: '[custom_field_6]', disabled: false },
  { id: 14, value: '[custom_field_7]', label: '[custom_field_7]', disabled: false }
];

export const AP_FRAN_PERSONALIZED_CALL_TO_ACTION_TYPE: apFranContainerTypes.ICreatorPersonalizedOption[] = [{ id: 4, value: '[url]', label: 'URL', disabled: false }];

export const AP_FRAN_MODAL_NAMES = {
  LOCATIONS: 'locations',
  SCHEDULE: 'schedule',
  POST_NOW: 'post_now',
  SYNDICATE: 'syndicate',
  POST_SYNDICATED: 'post_syndicated',
  CAMPAIGN: 'campaign'
};

export const AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT: apFranContainerTypes.ICreatorEditorStateTextObj = {
  facebook: EditorState.createEmpty(),
  twitter: EditorState.createEmpty(),
  instagram: EditorState.createEmpty(),
  linkedin: EditorState.createEmpty(),
  google: EditorState.createEmpty(),
  tiktok: EditorState.createEmpty()
};

export const CREATOR_FB_PREVIEW_FOOTER_ICONS: apFranContainerTypes.ICreatorSocialMediaFooterIconObj[] = [
  { defaultImg: 'fb-like', name: 'like', label: 'Like', divClassname: 'fb-like-icon fb-lcs-icons' },
  { defaultImg: 'comment', name: 'comment', label: 'Comment', divClassname: 'fb-command-icon fb-lcs-icons' },
  { defaultImg: 'share', name: 'share', label: 'Share', divClassname: 'fb-share-icon fb-lcs-icons' }
];

export const CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS: apFranContainerTypes.ICreatorSocialMediaFooterIconObj[] = [
  { defaultImg: 'insta-like-icon', name: 'like', divClassname: 'social-icons' },
  { defaultImg: 'insta-comments-icon', name: 'comment', divClassname: 'social-icons' },
  { defaultImg: 'insta-send-icon', name: 'share', divClassname: 'social-icons' }
];

export const CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS: apFranContainerTypes.ICreatorSocialMediaFooterIconObj[] = [{ defaultImg: 'insta-norify-icon', name: 'notify', divClassname: 'social-icons' }];

export const CREATOR_TWITTER_PREVIEW_FOOTER_ICONS: apFranContainerTypes.ICreatorSocialMediaFooterIconObj[] = [
  { defaultImg: 'twitter-comments-icon', name: 'comment', divClassname: 'social-icons' },
  { defaultImg: 'twitter-tweets-icon', name: 'tweet', divClassname: 'social-icons' },
  { defaultImg: 'twitter-like-icon', name: 'like', divClassname: 'social-icons' },
  { defaultImg: 'twitter-upload-icon', name: 'upload', divClassname: 'social-icons' }
];

export const CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS: apFranContainerTypes.ICreatorSocialMediaFooterIconObj[] = [
  { defaultImg: 'ap-prev-in-like', name: 'comment', label: 'Like' },
  { defaultImg: 'ap-prev-in-comments', name: 'tweet', label: 'Comment' },
  // { defaultImg: 'ap-prev-in-share', name: 'like', label: 'Share' },
  { defaultImg: 'ap-prev-in-repost', name: 'repost', label: 'Repost' },
  { defaultImg: 'ap-prev-in-send', name: 'upload', label: 'Send' }
];

export const LOCALIZE_POST_INFO_TOOLTIP = 'Checking this option allows you to create unique local versions of this post by automatically inserting a variable field, such as a phone number.';

export const CREATOR_CAMPAIGN_CONFIRM_MSG = 'You must select at least one campaign to include this post into';

export const CREATOR_TEXT_EDITOR_ALERT_MSG = {
  FACEBOOK_STORY: 'You must include an image or video in order to post a story to Facebook.',
  FACEBOOK_REEL: 'You must include a video in order to post a reel to Facebook.',
  INSTAGRAM: 'You must include an image or video in order to post to Instagram.',
  TAGS_ALERT_MSG: 'Please start typing first.',
  LINK_ALERT_MSG: `It is not possible to include both an image or video alongside a link, call-to-action or coupon within a single post.`,
  TAG_ALERT_STORY_MSG: `You must include an image or video in order to post a story. It is not possible to include a tag, emoji or a hashtag within a single story.`,
  TIKTOK: 'You must include a video in order to post to TikTok.',
  GOOGLE: `It looks like you're trying to include a phone number. However, Google's terms prohibit the inclusion of phone numbers in posts on their platform.`,
  LINK_ALERT_MSG_WITH_STORY_OR_REEL:
    'You must include an image or video in order to add a /post-type/ to Facebook. It is not possible to include both an image or video alongside a link or call-to-action within a /post-type/.'
};

export const CREATOR_SOCIAL_MEDIAS = [
  COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
  COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM,
  COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
  COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
  COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
  COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
];
export const IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM = [
  COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM,
  // COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
  COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
  COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
];

export const GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS = [
  { label: GoogleCallToActionOptionsLabel.NONE, value: GoogleCallToActionOptionsName.NONE },
  { label: GoogleCallToActionOptionsLabel.BOOK, value: GoogleCallToActionOptionsName.BOOK },
  { label: GoogleCallToActionOptionsLabel.ORDER, value: GoogleCallToActionOptionsName.ORDER },
  { label: GoogleCallToActionOptionsLabel.SHOP, value: GoogleCallToActionOptionsName.SHOP },
  { label: GoogleCallToActionOptionsLabel.LEARN_MORE, value: GoogleCallToActionOptionsName.LEARN_MORE },
  { label: GoogleCallToActionOptionsLabel.SIGN_UP, value: GoogleCallToActionOptionsName.SIGN_UP },
  // { label: GoogleCallToActionOptionsLabel.GET_OFFER, value: GoogleCallToActionOptionsName.GET_OFFER },
  { label: GoogleCallToActionOptionsLabel.CALL, value: GoogleCallToActionOptionsName.CALL }
];

export const FB_CALL_TO_ACTION_SELECT_OPTIONS = [
  { label: GoogleCallToActionOptionsLabel.NONE, value: GoogleCallToActionOptionsName.NONE, title: GoogleCallToActionOptionsLabel.NONE },
  { label: FBCallToActionOptionsLabel.ADD_TO_CART, value: FBCallToActionOptionsName.ADD_TO_CART, title: FBCallToActionOptionsLabel.ADD_TO_CART },
  { label: FBCallToActionOptionsLabel.APPLY_NOW, value: FBCallToActionOptionsName.APPLY_NOW, title: FBCallToActionOptionsLabel.APPLY_NOW },
  { label: FBCallToActionOptionsLabel.BET_NOW, value: FBCallToActionOptionsName.BET_NOW, title: FBCallToActionOptionsLabel.BET_NOW },
  { label: FBCallToActionOptionsLabel.BOOK_NOW, value: FBCallToActionOptionsName.BOOK_NOW, title: FBCallToActionOptionsLabel.BOOK_NOW },
  { label: FBCallToActionOptionsLabel.BOOK_TRAVEL, value: FBCallToActionOptionsName.BOOK_TRAVEL, title: FBCallToActionOptionsLabel.BOOK_NOW },
  { label: FBCallToActionOptionsLabel.BUY, value: FBCallToActionOptionsName.BUY, title: FBCallToActionOptionsLabel.BUY },
  { label: FBCallToActionOptionsLabel.BUY_NOW, value: FBCallToActionOptionsName.BUY_NOW, title: FBCallToActionOptionsLabel.BUY_NOW },
  { label: FBCallToActionOptionsLabel.BUY_TICKETS, value: FBCallToActionOptionsName.BUY_TICKETS, title: FBCallToActionOptionsLabel.BUY_TICKETS },
  // { label: FBCallToActionOptionsLabel.CALL, value: FBCallToActionOptionsName.CALL , title:FBCallToActionOptionsLabel.CALL },
  // { label: FBCallToActionOptionsLabel.CALL_ME, value: FBCallToActionOptionsName.CALL_ME , title:FBCallToActionOptionsLabel.CALL_ME },
  // { label: FBCallToActionOptionsLabel.CALL_NOW, value: FBCallToActionOptionsName.CALL_NOW , title:FBCallToActionOptionsLabel.CALL_ME },
  { label: FBCallToActionOptionsLabel.CHECK_AVAILABILITY, value: FBCallToActionOptionsName.CHECK_AVAILABILITY, title: FBCallToActionOptionsLabel.CHECK_AVAILABILITY },
  { label: FBCallToActionOptionsLabel.CIVIC_ACTION, value: FBCallToActionOptionsName.CIVIC_ACTION, title: FBCallToActionOptionsLabel.SEE_DISCUSSION },
  { label: FBCallToActionOptionsLabel.CONTACT_US, value: FBCallToActionOptionsName.CONTACT_US, title: FBCallToActionOptionsLabel.CONTACT_US },
  // { label: FBCallToActionOptionsLabel.DIAL_CODE, value: FBCallToActionOptionsName.DIAL_CODE , title:FBCallToActionOptionsLabel.DIAL_CODE },
  { label: FBCallToActionOptionsLabel.DONATE, value: FBCallToActionOptionsName.DONATE, title: FBCallToActionOptionsLabel.DONATE },
  { label: FBCallToActionOptionsLabel.DONATE_NOW, value: FBCallToActionOptionsName.DONATE_NOW, title: FBCallToActionOptionsLabel.DONATE_NOW },
  { label: FBCallToActionOptionsLabel.DOWNLOAD, value: FBCallToActionOptionsName.DOWNLOAD, title: FBCallToActionOptionsLabel.DOWNLOAD },
  { label: FBCallToActionOptionsLabel.EVENT_RSVP, value: FBCallToActionOptionsName.EVENT_RSVP, title: FBCallToActionOptionsLabel.INTERESTED },
  { label: FBCallToActionOptionsLabel.FIND_YOUR_GROUPS, value: FBCallToActionOptionsName.FIND_YOUR_GROUPS, title: FBCallToActionOptionsLabel.FIND_YOUR_GROUPS },
  // { label: FBCallToActionOptionsLabel.GET_DIRECTIONS, value: FBCallToActionOptionsName.GET_DIRECTIONS , title:FBCallToActionOptionsLabel.GET_DIRECTIONS },
  { label: FBCallToActionOptionsLabel.GET_EVENT_TICKETS, value: FBCallToActionOptionsName.GET_EVENT_TICKETS, title: FBCallToActionOptionsLabel.GET_TICKETS },
  { label: FBCallToActionOptionsLabel.GET_MOBILE_APP, value: FBCallToActionOptionsName.GET_MOBILE_APP, title: FBCallToActionOptionsLabel.GET_APP },
  { label: FBCallToActionOptionsLabel.GET_OFFER, value: FBCallToActionOptionsName.GET_OFFER, title: FBCallToActionOptionsLabel.GET_OFFER },
  { label: FBCallToActionOptionsLabel.GET_OFFER_VIEW, value: FBCallToActionOptionsName.GET_OFFER_VIEW, title: FBCallToActionOptionsLabel.GET_OFFER_VIEW },
  { label: FBCallToActionOptionsLabel.GET_PROMOTIONS, value: FBCallToActionOptionsName.GET_PROMOTIONS, title: FBCallToActionOptionsLabel.GET_PROMOTIONS },
  { label: FBCallToActionOptionsLabel.GET_QUOTE, value: FBCallToActionOptionsName.GET_QUOTE, title: FBCallToActionOptionsLabel.GET_QUOTE },
  { label: FBCallToActionOptionsLabel.GET_SHOWTIMES, value: FBCallToActionOptionsName.GET_SHOWTIMES, title: FBCallToActionOptionsLabel.GET_SHOWTIMES },
  { label: FBCallToActionOptionsLabel.GIVE_FREE_RIDES, value: FBCallToActionOptionsName.GIVE_FREE_RIDES, title: FBCallToActionOptionsLabel.GIVE_FREE_RIDES },
  { label: FBCallToActionOptionsLabel.GO_LIVE, value: FBCallToActionOptionsName.GO_LIVE, title: FBCallToActionOptionsLabel.GO_LIVE },
  { label: FBCallToActionOptionsLabel.INQUIRE_NOW, value: FBCallToActionOptionsName.INQUIRE_NOW, title: FBCallToActionOptionsLabel.INQUIRE_NOW },
  { label: FBCallToActionOptionsLabel.INSTAGRAM_MESSAGE, value: FBCallToActionOptionsName.INSTAGRAM_MESSAGE, title: FBCallToActionOptionsLabel.SEND_MESSAGE },
  { label: FBCallToActionOptionsLabel.INSTALL_APP, value: FBCallToActionOptionsName.INSTALL_APP, title: FBCallToActionOptionsLabel.INSTALL_APP },
  { label: FBCallToActionOptionsLabel.INSTALL_MOBILE_APP, value: FBCallToActionOptionsName.INSTALL_MOBILE_APP, title: FBCallToActionOptionsLabel.INSTALL_NOW },
  { label: FBCallToActionOptionsLabel.INTERESTED, value: FBCallToActionOptionsName.INTERESTED, title: FBCallToActionOptionsLabel.INTERESTED },
  { label: FBCallToActionOptionsLabel.JOIN_GROUP, value: FBCallToActionOptionsName.JOIN_GROUP, title: FBCallToActionOptionsLabel.JOIN_GROUP },
  { label: FBCallToActionOptionsLabel.LEARN_MORE, value: FBCallToActionOptionsName.LEARN_MORE, title: FBCallToActionOptionsLabel.LEARN_MORE },
  { label: FBCallToActionOptionsLabel.LIKE_PAGE, value: FBCallToActionOptionsName.LIKE_PAGE, title: FBCallToActionOptionsLabel.LIKE_PAGE },
  // { label: FBCallToActionOptionsLabel.LINK_CARD, value: FBCallToActionOptionsName.LINK_CARD , title:FBCallToActionOptionsLabel.LINK_CARD },
  { label: FBCallToActionOptionsLabel.LISTEN_MUSIC, value: FBCallToActionOptionsName.LISTEN_MUSIC, title: FBCallToActionOptionsLabel.LISTEN_MUSIC },
  { label: FBCallToActionOptionsLabel.LISTEN_NOW, value: FBCallToActionOptionsName.LISTEN_NOW, title: FBCallToActionOptionsLabel.LISTEN_NOW },
  { label: FBCallToActionOptionsLabel.LOYALTY_LEARN_MORE, value: FBCallToActionOptionsName.LOYALTY_LEARN_MORE, title: FBCallToActionOptionsLabel.LOYALTY_LEARN_MORE },
  { label: FBCallToActionOptionsLabel.MESSAGE_PAGE, value: FBCallToActionOptionsName.MESSAGE_PAGE, title: FBCallToActionOptionsLabel.MESSAGE_PAGE },
  // { label: FBCallToActionOptionsLabel.MISSED_CALL, value: FBCallToActionOptionsName.MISSED_CALL , title:FBCallToActionOptionsLabel.MISSED_CALL },
  { label: FBCallToActionOptionsLabel.MOBILE_DOWNLOAD, value: FBCallToActionOptionsName.MOBILE_DOWNLOAD, title: FBCallToActionOptionsLabel.DOWNLOAD },
  { label: FBCallToActionOptionsLabel.NO_BUTTON, value: FBCallToActionOptionsName.NO_BUTTON, title: FBCallToActionOptionsLabel.EMPTY },
  { label: FBCallToActionOptionsLabel.OPEN_INSTANT_APP, value: FBCallToActionOptionsName.OPEN_INSTANT_APP, title: FBCallToActionOptionsLabel.OPEN_INSTANT_APP },
  { label: FBCallToActionOptionsLabel.OPEN_LINK, value: FBCallToActionOptionsName.OPEN_LINK, title: FBCallToActionOptionsLabel.OPEN_LINK },
  { label: FBCallToActionOptionsLabel.OPEN_MESSENGER_EXT, value: FBCallToActionOptionsName.OPEN_MESSENGER_EXT, title: FBCallToActionOptionsLabel.LEARN_MORE_MESSENGER },
  { label: FBCallToActionOptionsLabel.ORDER_NOW, value: FBCallToActionOptionsName.ORDER_NOW, title: FBCallToActionOptionsLabel.ORDER_NOW },
  { label: FBCallToActionOptionsLabel.PAY_TO_ACCESS, value: FBCallToActionOptionsName.PAY_TO_ACCESS, title: FBCallToActionOptionsLabel.GET_ACCESS },
  { label: FBCallToActionOptionsLabel.PLAY_GAME, value: FBCallToActionOptionsName.PLAY_GAME, title: FBCallToActionOptionsLabel.PLAY_GAME },
  { label: FBCallToActionOptionsLabel.PLAY_GAME_ON_FACEBOOK, value: FBCallToActionOptionsName.PLAY_GAME_ON_FACEBOOK, title: FBCallToActionOptionsLabel.PLAY_GAME_ON_FACEBOOK },
  { label: FBCallToActionOptionsLabel.PRE_REGISTER, value: FBCallToActionOptionsName.PRE_REGISTER, title: FBCallToActionOptionsLabel.PRE_REGISTER },
  { label: FBCallToActionOptionsLabel.PURCHASE_GIFT_CARDS, value: FBCallToActionOptionsName.PURCHASE_GIFT_CARDS, title: FBCallToActionOptionsLabel.BUY_GIFT_CARD },
  { label: FBCallToActionOptionsLabel.RAISE_MONEY, value: FBCallToActionOptionsName.RAISE_MONEY, title: FBCallToActionOptionsLabel.RAISE_MONEY },
  { label: FBCallToActionOptionsLabel.RECORD_NOW, value: FBCallToActionOptionsName.RECORD_NOW, title: FBCallToActionOptionsLabel.RECORD_NOW },
  { label: FBCallToActionOptionsLabel.REFER_FRIENDS, value: FBCallToActionOptionsName.REFER_FRIENDS, title: FBCallToActionOptionsLabel.REFER_FRIENDS },
  { label: FBCallToActionOptionsLabel.REGISTER_NOW, value: FBCallToActionOptionsName.REGISTER_NOW, title: FBCallToActionOptionsLabel.REGISTER_NOW },
  { label: FBCallToActionOptionsLabel.REMIND_ME, value: FBCallToActionOptionsName.REMIND_ME, title: FBCallToActionOptionsLabel.REMIND_ME },
  { label: FBCallToActionOptionsLabel.REQUEST_TIME, value: FBCallToActionOptionsName.REQUEST_TIME, title: FBCallToActionOptionsLabel.REQUEST_TIME },
  { label: FBCallToActionOptionsLabel.SAVE, value: FBCallToActionOptionsName.SAVE, title: FBCallToActionOptionsLabel.SAVE },
  { label: FBCallToActionOptionsLabel.SEARCH, value: FBCallToActionOptionsName.SEARCH, title: FBCallToActionOptionsLabel.SEARCH },
  { label: FBCallToActionOptionsLabel.SEARCH_MORE, value: FBCallToActionOptionsName.SEARCH_MORE, title: FBCallToActionOptionsLabel.SEARCH },
  { label: FBCallToActionOptionsLabel.SEE_MENU, value: FBCallToActionOptionsName.SEE_MENU, title: FBCallToActionOptionsLabel.SEE_MENU },
  { label: FBCallToActionOptionsLabel.SEE_MORE, value: FBCallToActionOptionsName.SEE_MORE, title: FBCallToActionOptionsLabel.SEE_MORE },
  { label: FBCallToActionOptionsLabel.SELL_NOW, value: FBCallToActionOptionsName.SELL_NOW, title: FBCallToActionOptionsLabel.SELL_NOW },
  { label: FBCallToActionOptionsLabel.SEND_INVITES, value: FBCallToActionOptionsName.SEND_INVITES, title: FBCallToActionOptionsLabel.INVITE_FRIENDS },
  { label: FBCallToActionOptionsLabel.SEND_TIP, value: FBCallToActionOptionsName.SEND_TIP, title: FBCallToActionOptionsLabel.SEND_TIP },
  { label: FBCallToActionOptionsLabel.SEND_UPDATES, value: FBCallToActionOptionsName.SEND_UPDATES, title: FBCallToActionOptionsLabel.SEND_UPDATES },
  { label: FBCallToActionOptionsLabel.SHOP_NOW, value: FBCallToActionOptionsName.SHOP_NOW, title: FBCallToActionOptionsLabel.SHOP_NOW },
  { label: FBCallToActionOptionsLabel.SIGN_UP, value: FBCallToActionOptionsName.SIGN_UP, title: FBCallToActionOptionsLabel.SIGN_UP },
  { label: FBCallToActionOptionsLabel.START_ORDER, value: FBCallToActionOptionsName.START_ORDER, title: FBCallToActionOptionsLabel.START_ORDER },
  { label: FBCallToActionOptionsLabel.SUBSCRIBE, value: FBCallToActionOptionsName.SUBSCRIBE, title: FBCallToActionOptionsLabel.SUBSCRIBE },
  { label: FBCallToActionOptionsLabel.SWIPE_UP_PRODUCT, value: FBCallToActionOptionsName.SWIPE_UP_PRODUCT, title: FBCallToActionOptionsLabel.SWIPE_UP_PRODUCT },
  { label: FBCallToActionOptionsLabel.SWIPE_UP_SHOP, value: FBCallToActionOptionsName.SWIPE_UP_SHOP, title: FBCallToActionOptionsLabel.SWIPE_UP_SHOP },
  { label: FBCallToActionOptionsLabel.TRY_IN_CAMERA, value: FBCallToActionOptionsName.TRY_IN_CAMERA, title: FBCallToActionOptionsLabel.TRY_IN_CAMERA },
  { label: FBCallToActionOptionsLabel.TRY_IT, value: FBCallToActionOptionsName.TRY_IT, title: FBCallToActionOptionsLabel.TRY_IT },
  { label: FBCallToActionOptionsLabel.TRY_ON, value: FBCallToActionOptionsName.TRY_ON, title: FBCallToActionOptionsLabel.TRY_ON },
  { label: FBCallToActionOptionsLabel.UPDATE_APP, value: FBCallToActionOptionsName.UPDATE_APP, title: FBCallToActionOptionsLabel.UPDATE_APP },
  { label: FBCallToActionOptionsLabel.USE_APP, value: FBCallToActionOptionsName.USE_APP, title: FBCallToActionOptionsLabel.USE_APP },
  { label: FBCallToActionOptionsLabel.USE_MOBILE_APP, value: FBCallToActionOptionsName.USE_MOBILE_APP, title: FBCallToActionOptionsLabel.USE_APP },
  { label: FBCallToActionOptionsLabel.VIEW_INSTAGRAM_PROFILE, value: FBCallToActionOptionsName.VIEW_INSTAGRAM_PROFILE, title: FBCallToActionOptionsLabel.VIEW_INSTAGRAM_PROFILE },
  { label: FBCallToActionOptionsLabel.VISIT_WORLD, value: FBCallToActionOptionsName.VISIT_WORLD, title: FBCallToActionOptionsLabel.VISIT_WORLD },
  { label: FBCallToActionOptionsLabel.VOTE_NOW, value: FBCallToActionOptionsName.VOTE_NOW, title: FBCallToActionOptionsLabel.VOTE_NOW },
  { label: FBCallToActionOptionsLabel.WATCH_MORE, value: FBCallToActionOptionsName.WATCH_MORE, title: FBCallToActionOptionsLabel.WATCH_MORE },
  { label: FBCallToActionOptionsLabel.WATCH_VIDEO, value: FBCallToActionOptionsName.WATCH_VIDEO, title: FBCallToActionOptionsLabel.WATCH_VIDEO },
  { label: FBCallToActionOptionsLabel.WHATSAPP_LINK, value: FBCallToActionOptionsName.WHATSAPP_LINK, title: FBCallToActionOptionsLabel.WHATSAPP_LINK }
  // { label: FBCallToActionOptionsLabel.WHATSAPP_MESSAGE, value: FBCallToActionOptionsName.WHATSAPP_MESSAGE, title: FBCallToActionOptionsLabel.EMPTY }
];

export const socialMediaCallToActionCallOptions: string[] = [
  GoogleCallToActionOptionsName.CALL,
  FBCallToActionOptionsName.CALL_ME,
  FBCallToActionOptionsName.CALL_NOW,
  FBCallToActionOptionsName.MISSED_CALL,
  FBCallToActionOptionsName.CALL,
  FBCallToActionOptionsName.DIAL_CODE,
  FBCallToActionOptionsName.WHATSAPP_MESSAGE
];

export const socialMediaCallToActionCallLabels: string[] = [
  GoogleCallToActionOptionsLabel.CALL,
  FBCallToActionOptionsLabel.CALL_ME,
  FBCallToActionOptionsLabel.CALL_NOW,
  FBCallToActionOptionsLabel.MISSED_CALL,
  FBCallToActionOptionsLabel.CALL,
  FBCallToActionOptionsLabel.DIAL_CODE,
  FBCallToActionOptionsLabel.WHATSAPP_MESSAGE
];

export const AP_HASHTAGS_INITIAL_VALUE = {
  isFetching: false,
  hashtagsList: [],
  selectedHashtagsList: []
};

export const SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO = [
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, label: 'Facebook Page', logo: 'flag.svg' },
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, label: 'X (Formerly Twitter)', logo: 'twitter-lv.svg' },
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, label: 'Instagram Business', logo: 'insta-t.svg' },
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, label: 'LinkedIn', logo: 'in-t.svg' },
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK, label: 'TikTok', logo: 'tiktok.svg' },
  { medium: COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, label: 'Google My Business', logo: 'google-t.svg' }
];

export const SOCIAL_MEDIA_POST_TYPE_VALUES = {
  POST: 'post',
  STORY: 'story',
  REELS: 'reel'
};
export const SOCIAL_MEDIA_POST_TYPE_LABELS = {
  POST: 'Post',
  STORY: 'Story',
  REELS: 'Reel'
};
export const SOCIAL_MEDIA_POST_TYPE = [
  { id: 1, label: SOCIAL_MEDIA_POST_TYPE_LABELS.POST, value: SOCIAL_MEDIA_POST_TYPE_VALUES.POST },
  { id: 1, label: SOCIAL_MEDIA_POST_TYPE_LABELS.REELS, value: SOCIAL_MEDIA_POST_TYPE_VALUES.REELS },
  { id: 1, label: SOCIAL_MEDIA_POST_TYPE_LABELS.STORY, value: SOCIAL_MEDIA_POST_TYPE_VALUES.STORY }
];

export const POST_VALIDITY_INITIAL_VALUE = { start_date: null, end_date: null };
