import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { ITableData, ITableHeader } from 'types/common/common-props';
import { CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL, CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY, SHORT_MONTH_DATE_YEAR_FORMAT, CAMPAIGN_INIT_FILTER_DATA, SCROLL_THRESHOLD, USER_OWNERSHIP } from 'utils/constants';
import { campaignsApiTypes, campaignsContainerTypes, IStore } from 'types';
import { dateFormatByRequestedFormat } from 'utils/helpers';
import { Loading, StopCampaignScheduleConfirmModal, TableComponent } from 'components';

export const CampaignSchedulesTable = ({ handleCampaignEventsList }: campaignsContainerTypes.ICampaignEventsTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);
  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();

  const validCampaignId = +optionalParams[0];

  const isEventListFetching = useSelector((state: IStore) => state.campaigns.isEventListFetching);
  const eventsCurrentPage = useSelector((state: IStore) => state.campaigns.eventsCurrentPage);
  const campaignEventsList = useSelector((state: IStore) => state.campaigns.campaignEventsList);

  const [stopCampaignEventId, setStopCampaignEventId] = useState(0);

  useEffect(() => {
    if (id && userOwnership && isValidId && validCampaignId) {
      handleCampaignEventsList(1);
    }
  }, [id, userOwnership, isValidId, validCampaignId, queryParams?.schedule_status, dispatch]); // eslint-disable-line

  const handleLoadMoreItems = () => {
    handleCampaignEventsList(eventsCurrentPage + 1);
  };

  const disableStopCampaignEvent = useCallback(
    (franchisorId: null | number) => {
      if (userOwnership === USER_OWNERSHIP.ACCOUNT && franchisorId) return true;
      else return false;
    },
    [userOwnership]
  );

  const handleEditCampaignEvent = useCallback(
    (eventId: number) => {
      navigate({ search: `?${new URLSearchParams({ ...queryParams, edit_event: `${eventId}` }).toString()}` });
    },
    [queryParams, navigate]
  );

  const headers: ITableHeader[] = useMemo(
    () =>
      [
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.STATUS,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.STATUS,
          className: 'ad-status',
          renderJSX: () => <span />
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.STARTED_BY,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.STARTED_BY,
          renderJSX: () => <span>Started By</span>
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.ENDS_ON,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.ENDS_ON,
          renderJSX: () => <span>Ends On</span>
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.NUMBER_OF_POST_PER_WEEK,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.NUMBER_OF_POST_PER_WEEK,
          renderJSX: () => <span>Number of post per week</span>
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.NUMBER_OF_LOCATION,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.NUMBER_OF_LOCATION,
          renderJSX: () => <span>Number of Location</span>
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.ACTION,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.ACTION,
          renderJSX: () => <span>Action</span>
        },
        {
          name: CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL.EXTRA_ACTION,
          key: CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.EXTRA_ACTION,
          renderJSX: () => <span />
        }
      ].filter((it) => (userOwnership === USER_OWNERSHIP.ACCOUNT ? ![CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.NUMBER_OF_LOCATION].includes(it.key) : it.key)),
    [userOwnership]
  );

  const tableData: ITableData[] = useMemo(() => {
    const getValue = (key: string, campaignEvent: campaignsApiTypes.ICampaignEvent) => {
      switch (key) {
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.STATUS:
          return <span className={`adsi-sign ${campaignEvent.status === 'COMPLETED' ? 'inactive' : 'active'}`} />;
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.STARTED_BY:
          return (
            <div className="ad-label">
              <span className="adl-top" title={campaignEvent.created_user?.name || undefined}>
                {campaignEvent.created_user?.name || '-'}
              </span>
              <span className="adl-base">{dateFormatByRequestedFormat(campaignEvent.created_at, SHORT_MONTH_DATE_YEAR_FORMAT)}</span>
            </div>
          );
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.ENDS_ON:
          return <span className="ad-cnt">{campaignEvent.end_date ? dateFormatByRequestedFormat(campaignEvent.end_date, SHORT_MONTH_DATE_YEAR_FORMAT) : 'Ongoing'}</span>;
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.NUMBER_OF_POST_PER_WEEK:
          return <span className="ad-cnt">{campaignEvent.campaign_post_per_week}</span>;
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.NUMBER_OF_LOCATION:
          return (
            <span className="ad-cnt">
              {Boolean('location' in campaignEvent?.config && campaignEvent.config?.location && campaignEvent?.account_id) ? 1 : campaignEvent.config.included_accounts?.length || 0}
            </span>
          );
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.ACTION:
          return campaignEvent.status === 'COMPLETED' ? (
            <div className="cpnItem">Completed</div>
          ) : (
            <div className={`cpnItem${disableStopCampaignEvent(campaignEvent.franchisor_id) ? ` pointer-events-none` : ''}`}>
              <button className="gnrc-btn red-gnr ac-btn" onClick={() => setStopCampaignEventId(campaignEvent.id)}>
                <span>Stop</span>
              </button>
            </div>
          );
        case CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.EXTRA_ACTION:
          return campaignEvent.status === 'COMPLETED' ? null : (
            <div className="cpnItem">
              <button
                className={userOwnership === USER_OWNERSHIP.ACCOUNT ? 'btn btn-primary ac-btn camp__table--btn' : 'gnrc-btn blue-lined-gnr camp__table--btn ac-btn'}
                onClick={() => handleEditCampaignEvent(campaignEvent.id)}
              >
                <span>{userOwnership === USER_OWNERSHIP.ACCOUNT ? 'View' : 'Edit'}</span>
              </button>
            </div>
          );
        default:
          return '';
      }
    };
    if (campaignEventsList.length) {
      return campaignEventsList.reduce((acc: ITableData[], curr, index) => {
        let row: ITableData = {};
        headers.forEach((it) => {
          row = {
            ...row,
            [it.key]: {
              value: getValue(it.key, curr),
              identifier: index,
              dataObj: curr,
              ...(it.key === CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY.STATUS && { columnClass: 'ads-item' })
            }
          };
        });
        acc.push(row);
        return acc;
      }, []);
    }
    return [];
  }, [campaignEventsList, headers, userOwnership, handleEditCampaignEvent, disableStopCampaignEvent]);

  return (
    <div className="campaign-schedule-table">
      <div className="resuseTable adptTable">
        <div className="adpt-height">
          {isEventListFetching && eventsCurrentPage === 1 ? (
            <Loading />
          ) : (
            <InfiniteScroll
              scrollThreshold={SCROLL_THRESHOLD}
              next={handleLoadMoreItems}
              hasMore
              dataLength={campaignEventsList.length}
              loader={isEventListFetching ? <Loading /> : null}
              className="local-ini lpx vpy-20"
              scrollableTarget="scrollTarget"
            >
              <TableComponent tableHeaders={headers} tableData={tableData} />
            </InfiniteScroll>
          )}
        </div>
      </div>
      <StopCampaignScheduleConfirmModal campaignId={validCampaignId} eventId={stopCampaignEventId} isShowModal={Boolean(stopCampaignEventId)} onModalClose={() => setStopCampaignEventId(0)} />
    </div>
  );
};
