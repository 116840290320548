import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { UserLoginsAction } from 'actions';
import { userLoginsReducerTypes } from 'types';
import { DONE_SUCCESS_MSG, USER_LOGINS_NEW_USER_INITIAL_VALUE } from 'utils/constants';

const initialState: userLoginsReducerTypes.IUserLoginsReducer = {
  isFetching: false,
  error: null,
  accountOwners: [],
  accountOwner: null,
  accountUser: null,
  searchValue: null,
  isAddNewUser: false,
  addNewUserDetails: USER_LOGINS_NEW_USER_INITIAL_VALUE,
  checkEmailResponse: { email_valid: false, user_exists: false },
  isDeleteOwner: false,
  isResendEmail: false,
  messages: {
    ulSuccessMsg: null,
    ulFailureMsg: null
  }
};

const userLoginsReducer: Reducer<userLoginsReducerTypes.IUserLoginsReducer, UserLoginsAction> = (
  state: userLoginsReducerTypes.IUserLoginsReducer = initialState,
  action: UserLoginsAction
): userLoginsReducerTypes.IUserLoginsReducer => {
  switch (action.type) {
    // GET UL ACCOUNT OWNERS
    case types.UL_GET_ACCOUNT_OWNERS_REQUEST:
      return {
        ...state,
        isFetching: action.payload.type === 'loading' ? true : false,
        error: null
      };
    case types.UL_GET_ACCOUNT_OWNERS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        accountOwners: action.payload,
        error: null
      };
    case types.UL_GET_ACCOUNT_OWNERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        accountOwners: []
      };
    // CHECK EMAIL FOR ACCOUNT OWNERS
    case types.ACCOUNT_OWNERS_CHECK_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
        addNewUserDetails: { ...state.addNewUserDetails, email: action.payload.email }
      };
    case types.ACCOUNT_OWNERS_CHECK_EMAIL_RESPONSE:
      return {
        ...state,
        error: null,
        checkEmailResponse: action.payload
      };
    case types.ACCOUNT_OWNERS_CHECK_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        checkEmailResponse: initialState.checkEmailResponse
      };
    // ADD UL ACCOUNT OWNERS
    case types.UL_ADD_ACCOUNT_OWNERS_RESPONSE:
      return {
        ...state,
        error: null,
        accountOwner: action.payload.data,
        messages: {
          ...state.messages,
          ulSuccessMsg: action.payload.message || null
        }
      };
    case types.UL_ADD_ACCOUNT_OWNERS_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          ulFailureMsg: action.payload || null
        },
        accountOwner: initialState.accountOwner
      };
    // UPDATE UL ACCOUNT OWNERS
    case types.UL_UPDATE_ACCOUNT_OWNERS_RESPONSE:
      return {
        ...state,
        error: null,
        messages: {
          ...state.messages,
          ulSuccessMsg: DONE_SUCCESS_MSG || null
        },
        accountUser: action.payload
      };
    case types.UL_UPDATE_ACCOUNT_OWNERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        accountUser: initialState.accountUser
      };
    // UL DELETE ACCOUNT OWNER
    case types.UL_DELETE_ACCOUNT_OWNERS_REQUEST:
      return {
        ...state
      };
    case types.UL_DELETE_ACCOUNT_OWNERS_RESPONSE:
      return {
        ...state,
        isDeleteOwner: true
      };
    case types.UL_DELETE_ACCOUNT_OWNERS_FAILURE:
      return {
        ...state,
        isDeleteOwner: false,
        error: action.payload || 'Unexpected error'
      };
    // UL RESEND INVITE ACCOUNT OWNER
    case types.UL_RESEND_INVITE_FOR_USER_REQUEST:
      return {
        ...state
      };
    case types.UL_RESEND_INVITE_FOR_USER_RESPONSE:
      return {
        ...state,
        isResendEmail: true
      };
    case types.UL_RESEND_INVITE_FOR_USER_FAILURE:
      return {
        ...state,
        isResendEmail: false,
        error: action.payload || 'Unexpected error'
      };
    // CHECK EMAIL SET VALIDATION
    case types.ACCOUNT_OWNERS_CHECK_EMAIL_SET_VALIDATION:
      return {
        ...state,
        checkEmailResponse: action.payload
      };
    // Reset user login add new user
    case types.UL_RESET_ADD_NEW_USER:
      return {
        ...state,
        accountOwner: initialState.accountOwner
      };
    // Set user login add new user  boolean
    case types.UL_SET_IS_ADD_NEW_USER:
      return {
        ...state,
        isAddNewUser: action.payload
      };
    // UL Reset
    case types.UL_DELETE_DATA_RESET:
      return {
        ...state,
        isDeleteOwner: initialState.isDeleteOwner
      };
    case types.UL_RESEND_INVITE_DATA_RESET:
      return {
        ...state,
        isResendEmail: initialState.isResendEmail
      };
    case types.UL_ADD_NEW_USER_DETAIL_RESET:
      return {
        ...state,
        addNewUserDetails: initialState.addNewUserDetails
      };
    case types.UL_MESSAGES_RESET:
      return {
        ...state,
        messages: initialState.messages
      };
    case types.UL_RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};

export { userLoginsReducer };
