import { advocacyLeaderboardReducerTypes, advocacyLeaderboardApiTypes } from 'types';

export const ADV_LB_DATE_RANGE_FILTER_TYPE = {
  MONTH_TO_DATE: 'month_to_date',
  LAST_MONTH: 'last_month',
  YEAR_TO_DATE: 'year_to_date',
  ALL_TIME: 'all_time'
};

export const ADV_LB_FILTER_STATS_INITIAL_OBJ: advocacyLeaderboardReducerTypes.IAdvLeaderboardFilterStats = {
  employeesCount: 0,
  locationsCount: 0
};

export const ADV_LB_RANKING_TARGET_VALUE = {
  EMPLOYEE: 'employee',
  LOCATION: 'location'
};

export const ADV_LB_SORT_NAMES = {
  ASSETS_SUBMITTED: 'assets_submitted_count',
  ADVOCACY_POSTS_COUNT: 'advocacy_posts_count',
  TOTAL_SCORE: 'total_score',
  BRAND_RANKING: 'brand_ranking',
  LOCATION_RANKING: 'location_ranking',
  EMPLOYEE_NAME: 'employee_name',
  LOCATION_NAME: 'location_name'
};

export const ADV_LB_LOC_CONSOL_LIST_EXPORT_HEADER = {
  LOCATIONS: 'Locations',
  ASSETS_SUBMITTED: 'Assets Submitted',
  ADVOCACY_POSTS: 'Advocacy Posts',
  SCORE: 'Score',
  BRAND_RANK: 'Brand Rank'
};

export const ADV_LB_EMP_CONSOL_LIST_EXPORT_HEADER = {
  EMPLOYEES: 'Employees',
  ASSETS_SUBMITTED: 'Assets Submitted',
  ADVOCACY_POSTS: 'Advocacy Posts',
  SCORE: 'Score',
  BRAND_RANK: 'Brand Rank'
};

export const ADV_LB_CONSOL_LIST_EXPORT_COLS = {
  LOCATIONS: 'locations',
  EMPLOYEES: 'employees',
  ASSETS_SUBMITTED: 'assetsSubmitted',
  ADVOCACY_POSTS: 'advocacyPosts',
  SCORE: 'score',
  BRAND_RANK: 'brandRank'
};

export const RANKING_FILTER = [
  { value: 'employee', label: 'Employees', title: 'Ranking' },
  { value: 'location', label: 'Locations' }
];

export enum EmpAdvLeaderbordRankType {
  EMPLOYEE = 'employee',
  LOCATION = 'location'
}

export const DATE_FILTER = [
  { value: 'month_to_date', label: 'Month-To-Date', title: '' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'year_to_date', label: 'Year-To-Date' },
  { value: 'all_time', label: 'All-Time' },
  { value: 'custom', label: 'Specific Date Range' }
];

export enum EmpAdvLeaderbordDateType {
  MONTH_TO_DATE = 'month_to_date',
  LAST_MONTH = 'last_month',
  YEAR_TO_DATE = 'year_to_date',
  ALL_TIME = 'all_time',
  CUSTOM = 'custom'
}

export enum AdvocacyLeaderboardvalidQueryParams {
  DATE = 'date_type',
  RANKING = 'ranking',
  SORT_BY = 'sort_by',
  ASCENDING = 'ascending'
}

export const ADV_LB_EMPLOYEE_SOCIAL_MEDIA_OBJ: advocacyLeaderboardApiTypes.IAdvLBEmpDetailsSocialMediaObj[] = [
  { imgSrc: 'filter/fb-platform', value: 'facebook', label: 'Facebook', url: null },
  { imgSrc: 'filter/instagram-platform', value: 'instagram', label: 'Instagram', url: null },
  { imgSrc: 'filter/twitter-platform', value: 'twitter', label: 'X (Formerly Twitter)', url: null },
  { imgSrc: 'filter/linkedin-platform', value: 'linkedin', label: 'Linkedin', url: null }
];
