import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import { CommonFilter } from 'components';
import { onChangeLocation, resetPageState } from 'revv/actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { BUTTON_PROPS, USER_OWNERSHIP } from 'utils/constants';
import { weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { handleFilterChange } from 'analytics/utils';
import { IStore } from 'types';

export const AnalyticsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const locations = useSelector((state: IStore) => state.revv.filter.hubLocation);
  const selectedLocation = useSelector((state: IStore) => state.revv.filter.selectedLocation);
  const defaultSelectedLocation = useSelector((state: IStore) => state.revv.filter.defaultSelectedLocation);

  const initialDateRange = weekOrMonthBeforeTodayFilter();

  const { queryParams, filter: basicQueryFilter } = useParamsDeconstructor({
    dateRange: initialDateRange
  });

  const isFilterDisabled = useMemo(() => {
    if (Object.keys(queryParams).length || selectedLocation.length) {
      return false;
    } else {
      return true;
    }
  }, [queryParams, selectedLocation]);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (data.multipleLocationList) {
      dispatch(
        onChangeLocation({
          selectedLocations: data.multipleLocationList,
          highlightHubIdsGroup: {},
          highlightedLocations: [],
          selectedFilterMode: [],
          selectedHubGroup: {},
          validHubs: {},
          validLocations: defaultSelectedLocation
        })
      );
    }
    if (isClear) {
      if (selectedLocation.length) {
        dispatch(resetPageState('revv_dashboard'));
      }
    }
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };
  return (
    <CommonFilter
      filter={{ ...basicQueryFilter, locations, selectedLocation }}
      handleChange={handleChange}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isFilterDisabled
        }
      ]}
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
      isRevvLocation
      sectionVariant="revv-analytics-filter"
    />
  );
};
