import { commonLocationTree, rpApiTypes, rpContainerTypes, rpReducerTypes } from 'types';
import { getAllFilterTreeKeys, getFormatBasedOnDateString, getLocationDetails, getTimezone } from 'utils/helpers';
import {
  Stage,
  TREE_NODE_TYPE,
  USER_OWNERSHIP,
  RP_PROGRAM_TYPE,
  RP_USER_ACTION_TYPE,
  RP_ACTIONS_TYPE,
  RP_REPLACE_SPECIAL_CHAR,
  RewardType,
  EmailNotificationStatus,
  RecommendedType
} from 'utils/constants';

export const budgetCalculation = (programDetails: any) => {
  let newValue;
  if (programDetails != null && programDetails.endafter && programDetails.occurrences && programDetails.programBudget !== null) {
    newValue = programDetails.programBudget !== null ? programDetails?.programBudget * programDetails.occurrences : 0;
  } else if (programDetails != null) {
    newValue = programDetails.programBudget !== null ? programDetails.programBudget : 0;
  }
  return newValue;
};

export const handleLabelDisplay = (labelArray: rpReducerTypes.ICPActionPromotionObj[], value: null | number | string | undefined, matchOption: string, returnOption: string): any => {
  const returnArray: any = labelArray.filter((item: any) => item[matchOption] === value);
  return returnArray.length > 0 ? returnArray[0][returnOption] : '';
};

/** Create program > Actions > Get form fields */
export const rpActionDataGetFormFields = (
  initialFormFields: rpContainerTypes.ICpActionsInitialVal | any,
  programDetails: rpApiTypes.IRPProgramDetailData,
  promotionVaultList: rpReducerTypes.ICPActionPromotionObj[]
) => {
  if (initialFormFields.actions) {
    initialFormFields.actions[0].rewardsType = programDetails?.rewardsType;
  } else {
    initialFormFields.rewardsType = programDetails?.rewardsType;
  }
  const initialvalue = initialFormFields;
  if (promotionVaultList.length === 1) {
    if (initialvalue.actions) initialvalue.actions[0].rewards = promotionVaultList[0].value;
    else initialvalue.rewards = promotionVaultList[0].value;
  }
  return initialvalue;
};

/** Create program > Get save as draft program details */
export const getSaveAsDraftProgramDetails = (
  currentAction: null | string,
  programDetails: rpApiTypes.IRPProgramDetailData,
  id: number,
  userOwnership: string,
  topFranchisorId: number,
  brandEmail: string,
  userIdValue: number,
  userEmail: null | string,
  type: string,
  action: string,
  emailDetails?: rpReducerTypes.ICPEmailTemplateObj
): rpApiTypes.IRPProgramDetailData => {
  return {
    accountId: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0,
    adminEmail: userEmail || '',
    duration: programDetails.duration,
    emailInvitationMessage:
      programDetails.recommendedType === RecommendedType.RECOMMENDED_TYPE
        ? ''
        : action === RP_USER_ACTION_TYPE.RESEND ||
          emailDetails?.emailNotificationStatus === EmailNotificationStatus.SEND_LATER ||
          emailDetails?.emailNotificationStatus === EmailNotificationStatus.SEND_NOW
        ? emailDetails?.emailInvitationMessage
        : emailDetails && emailDetails?.emailNotificationStatus !== EmailNotificationStatus.SEND_LATER
        ? ''
        : null,
    emailNotificationLastModified: programDetails.recommendedType === RecommendedType.RECOMMENDED_TYPE ? null : programDetails?.emailNotificationLastModified || null,
    emailNotificationStatus:
      programDetails.recommendedType === RecommendedType.RECOMMENDED_TYPE ? 0 : emailDetails ? emailDetails.emailNotificationStatus : action === RP_USER_ACTION_TYPE.RESEND ? 1 : null,
    endDate: getFormatBasedOnDateString(programDetails.endDate),
    endafter: programDetails.endafter || 1,
    franchisorId: userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : 0,
    name: programDetails.name,
    occurrences: programDetails.occurrences || 1,
    programBudget: programDetails.programBudget?.replace('$', '') || null,
    programDuration: programDetails.programDuration,
    programId: currentAction === RP_ACTIONS_TYPE.EDIT ? programDetails.programId : 0,
    recommendedType: programDetails.recommendedType,
    rewardBrandEmail: brandEmail || null,
    rewardEmailLogoUrl: emailDetails?.rewardEmailLogoUrl ? emailDetails.rewardEmailLogoUrl : null,
    rewardEmailScheduleDate:
      emailDetails?.emailNotificationStatus === EmailNotificationStatus.SEND_LATER && emailDetails?.emailScheduleDate ? getFormatBasedOnDateString(emailDetails.emailScheduleDate) : null,
    rewardTimezone: getTimezone(),
    rewardsType: programDetails.rewardsType,
    stage: getStageValueBasedOnType(programDetails.stage || 0, type, action),
    startDate: getFormatBasedOnDateString(programDetails.startDate),
    topLevelFranchisorId: topFranchisorId,
    typeCode: programDetails.typeCode || 0,
    userId: userIdValue
  };
};

/** Create program > Convert program actions into req payload array */
export const convertProgramActionsArray = (currentProgramAction: rpApiTypes.IRPProgramActionData[], promotionList: rpReducerTypes.ICPActionPromotionObj[]) => {
  const tempArray: any[] = [];
  if (currentProgramAction.length) {
    currentProgramAction.forEach((val) => {
      if (val.actionItem) {
        tempArray.push({
          ...val.actionItem,
          rewards: val.rewards || '',
          nonCashRewards: val.nonCashRewards || ''
        });
      }
    });
    [...tempArray].forEach((actionItem: any) => {
      promotionList.forEach((rewardItem: rpReducerTypes.ICPActionPromotionObj) => {
        if (actionItem.rewards === rewardItem.value) actionItem.giftCost = parseInt(rewardItem.giftCost ? rewardItem.giftCost : '0');
      });
    });
  }
  currentProgramAction = tempArray;
  return currentProgramAction;
};

/** Create program > Location tree > Get retained previous checked keys */
export const retainPreviousCheckedKeys = (prevCheckedKeys: string[], filteredArray: commonLocationTree.ILocationTree[]) => {
  const filteredKeys = getAllFilterTreeKeys(filteredArray);
  const retainedKeys = prevCheckedKeys.filter((key: string) => !filteredKeys.includes(key));
  return retainedKeys;
};

/** Create program > Location tree > Remove unmatched keys */
export const removeUnmatchedKeys = (unmatchedCheckedKeys: string[], checkedKey: string[]) => {
  const combinedCheckedKeys = [...unmatchedCheckedKeys, ...checkedKey];
  // eslint-disable-next-line
  const newArray = combinedCheckedKeys.filter((elem, i, arr) => {
    if (arr.indexOf(elem) === i) {
      return elem;
    }
  });
  return newArray;
};

/** Create program > Location tree > Remove top level node from checked keys */
export const removeTopLevelNodeFromCheckedKeys = (
  rpTreeData: commonLocationTree.ILocationTree[],
  filterTreeArray: commonLocationTree.ILocationTree[],
  currentCheckedKey: string[],
  topLevelFranchisor: string[],
  unmatchedCheckedKeys: string[]
) => {
  if (rpTreeData.length === filterTreeArray.length && rpTreeData.length && filterTreeArray.length) {
    if (!rpTreeData[0].isLeaf && !filterTreeArray[0].isLeaf) {
      if (rpTreeData[0].children?.length !== filterTreeArray[0].children?.length) {
        if (currentCheckedKey.length && topLevelFranchisor.length === 1 && currentCheckedKey.includes(topLevelFranchisor[0]) && unmatchedCheckedKeys.length) {
          const index = unmatchedCheckedKeys.indexOf(topLevelFranchisor[0], 0);
          if (index > -1) {
            unmatchedCheckedKeys.splice(index, 1);
          }
        }
      }
    }
  }
  return unmatchedCheckedKeys;
};

/** Create program > Location tree > Remove half checked franchisors from checked keys */
export const removeHalfCheckedFranchisorsFromCheckedKeys = (currentCheckedKey: string[], rpTreeData: commonLocationTree.ILocationTree[]) => {
  const allFranKeys: string[] = currentCheckedKey.filter((itm) => getLocationDetails(itm).userType === TREE_NODE_TYPE.FRANCHISORS);
  const flatTreeArray: string[] = getAllFilterTreeKeys(rpTreeData);
  allFranKeys.forEach((item: string) => {
    const franId: number | string = getLocationDetails(item).id;
    const allChildrenOfFran: string[] = flatTreeArray.filter((child: string) => getLocationDetails(child).parentId === franId);
    if (allChildrenOfFran && allChildrenOfFran.length) {
      allChildrenOfFran.forEach((datum: string) => {
        const childIndex = currentCheckedKey.indexOf(datum, 0);
        if (childIndex === -1) {
          const index = currentCheckedKey.indexOf(item, 0);
          if (index > -1) {
            currentCheckedKey.splice(index, 1);
          }
        }
      });
    }
  });
  return currentCheckedKey;
};

/** Create program > Location tree > Get top level franchisor keys */
export const getTopLevelFranchisorKeys = (filterTreeArray: commonLocationTree.ILocationTree[]) => {
  let franchisorKeys: string[] = [];
  if (filterTreeArray.length) {
    franchisorKeys = filterTreeArray.reduce((acc: string[], curr) => {
      if (!curr.isLeaf) {
        acc = [...acc, curr.key];
      }
      return acc;
    }, []);
  } else {
    franchisorKeys = [];
  }
  return franchisorKeys;
};

/** Get optimized hub location object */
export const getMinimizedHubLocation = (overallCheckedKeys: string[], locationObj: rpApiTypes.IRPProgramLocationData) => {
  const franchisorKeys = overallCheckedKeys.reduce((acc: string[], curr) => {
    if (getLocationDetails(curr).userType === TREE_NODE_TYPE.FRANCHISORS) {
      acc = [...acc, getLocationDetails(curr).id];
    }
    return acc;
  }, []);
  const sortedTempFranchisors = [...franchisorKeys.sort()];
  if (sortedTempFranchisors.length) {
    sortedTempFranchisors.forEach((franSortId: string) => {
      overallCheckedKeys.forEach((item: string, idx: number) => {
        if (getLocationDetails(item).parentId === franSortId && getLocationDetails(item).userType === TREE_NODE_TYPE.ACCOUNTS) {
          overallCheckedKeys[idx] = '';
        }
      });
    });
  }
  overallCheckedKeys.forEach((item: string) => {
    if (getLocationDetails(item).userType === TREE_NODE_TYPE.ACCOUNTS && !locationObj.accounts.includes(getLocationDetails(item).id)) {
      locationObj.accounts.push(getLocationDetails(item).id);
    }
  });
  sortedTempFranchisors.forEach((franSortId: string) => {
    overallCheckedKeys.forEach((item: string, idx: number) => {
      if (getLocationDetails(item).parentId === franSortId && getLocationDetails(item).userType === TREE_NODE_TYPE.FRANCHISORS) {
        overallCheckedKeys[idx] = '';
      }
    });
  });
  overallCheckedKeys.forEach((item: string) => {
    if (getLocationDetails(item).userType === TREE_NODE_TYPE.FRANCHISORS && !locationObj.franchisors.includes(getLocationDetails(item).id)) {
      locationObj.franchisors.push(getLocationDetails(item).id);
    }
  });
  return locationObj;
};

/** Get location object for handling request payload */
export const getSavedLocationObj = (programDataObj: any) => {
  const locationObj: rpApiTypes.IRPProgramLocationData = { franchisors: [], accounts: [], accountLists: [], selectedLocationCount: 0, isAllLocation: false };
  // if (!checkedKeys.length) {
  if (programDataObj?.programHuLocations?.franchisors?.length) {
    programDataObj?.programHuLocations?.franchisors?.forEach((franItem: { id: string; name: string }) => locationObj.franchisors.push(`${franItem.id}`));
  }
  if (programDataObj?.programHuLocations?.accounts?.length) {
    programDataObj?.programHuLocations?.accounts?.forEach((accItem: { id: string; name: string }) => locationObj.accounts.push(`${accItem.id}`));
  }
  if (programDataObj?.programHuLocations?.accountLists?.length) {
    programDataObj?.programHuLocations?.accountLists?.forEach((accItem: { id: string; name: string }) => locationObj.accountLists.push(`${accItem.id}`));
  }
  locationObj.selectedLocationCount = programDataObj?.programHuLocations?.selectedLocationCount;
  locationObj.isAllLocation = programDataObj?.programHuLocations?.isAllLocation;
  // }

  // console.log(checkedKeys, 'checked keys');
  // const overallCheckedKeys = [...checkedKeys];
  // if (overallCheckedKeys.length) {
  //   getMinimizedHubLocation(overallCheckedKeys, locationObj);
  //   locationObj.selectedLocationCount = getSelectedLocationCount(checkedKeys);
  // }
  return locationObj;
};

/** Get selected location count */
export const getSelectedLocationCount = (checkedKeys: string[]) => {
  if (checkedKeys.length) {
    return checkedKeys.reduce((acc: number, curr: string) => {
      if (getLocationDetails(curr).userType === TREE_NODE_TYPE.ACCOUNTS) {
        acc += 1;
      }
      return acc;
    }, 0);
  } else return 0;
};

export const getStageValueBasedOnType = (stage: number, type: string, action: string) => {
  let stageValueBasedOnType = stage;
  if (stageValueBasedOnType !== Stage.PAYMENT_COMPLETED) {
    const stageValue: null | number = stage;
    if (stageValue === Stage.PROGRAM_COMPLETED || stageValue === Stage.PROGRAM_NOTIFICATION_SENT) {
      stageValueBasedOnType = stageValue;
    } else {
      stageValueBasedOnType =
        type === RP_PROGRAM_TYPE.DRAFT_PROGRAM || (type === RP_PROGRAM_TYPE.NON_CASH_PROGRAM && action === RP_USER_ACTION_TYPE.SAVE_AS_DRAFT)
          ? Stage.DRAFT
          : type === RP_PROGRAM_TYPE.RECCURING_PROGRAM
          ? Stage.RECOMMENDED_PROGRAM
          : type === RP_PROGRAM_TYPE.NON_CASH_PROGRAM && action === RP_USER_ACTION_TYPE.SAVE
          ? Stage.PAYMENT_COMPLETED
          : Stage.PAYMENT_COMPLETED;
    }
  }
  return stageValueBasedOnType;
};

export const getModifiedProgramSummary = (
  currentProgramSummary: rpReducerTypes.IRpAddProgramDataObj,
  promotionList: rpReducerTypes.ICPActionPromotionObj[],
  paymentRequestObject?: rpApiTypes.IRPPaymemntReqObj
): any => {
  const modifiedProgramSummay = currentProgramSummary;
  modifiedProgramSummay.programAction = convertProgramActionsArray(currentProgramSummary.programAction, promotionList);
  if (
    typeof currentProgramSummary.programHuLocations.franchisors[0] === 'object' ||
    typeof currentProgramSummary.programHuLocations.accountLists[0] === 'object' ||
    typeof currentProgramSummary.programHuLocations.accounts[0] === 'object'
  ) {
    const locationObj = getSavedLocationObj(currentProgramSummary);
    modifiedProgramSummay.programHuLocations = locationObj;
  }
  if (paymentRequestObject) modifiedProgramSummay.paymentRequestObj = paymentRequestObject;
  return modifiedProgramSummay;
};

/** Get selected checked keys for Create program > Summary */
export const getActiveCheckedKeys = (locationTreeList: commonLocationTree.ILocationTree[], locationProp: rpApiTypes.IRPProgramLocationData, checkedKeys: string[]) => {
  let tempCheckedArr: string[] = checkedKeys;
  locationTreeList.forEach((item) => {
    if (locationProp.franchisors.length) {
      tempCheckedArr = locationProp.franchisors.reduce((acc: string[], curr) => {
        if (getLocationDetails(item.key).id === curr && getLocationDetails(item.key).userType === TREE_NODE_TYPE.FRANCHISORS && !tempCheckedArr.includes(item.key)) {
          acc.push(item.key);
        }
        return acc;
      }, checkedKeys);
    }
    if (locationProp.accounts.length) {
      tempCheckedArr = locationProp.accounts.reduce((acc: string[], curr) => {
        if (getLocationDetails(item.key).id === curr && getLocationDetails(item.key).userType === TREE_NODE_TYPE.ACCOUNTS && !tempCheckedArr.includes(item.key)) {
          acc.push(item.key);
        }
        return acc;
      }, checkedKeys);
    }
    if (item.children && item.children.length) getActiveCheckedKeys(item.children, locationProp, checkedKeys);
  });
  return tempCheckedArr;
};

export const getActiveCheckedKeysFromList = (locationProp: rpApiTypes.IRPProgramLocationData) => {
  let tempCheckedArr: string[] = [];
  // if (locationProp.franchisors.length) {
  //   locationTreeList?.hubList?.forEach((item) => {
  if (locationProp.franchisors.length) {
    tempCheckedArr = locationProp.franchisors.reduce((acc: string[], curr) => {
      // if (item.id === +curr || item.id === +curr.id) {
      acc.push(`All ${curr.name}`);
      // }
      return acc;
    }, tempCheckedArr);
  }
  //   });
  // }
  // if (locationProp?.accountLists?.length) {
  //   locationTreeList?.locationList?.forEach((item) => {
  if (locationProp?.accountLists?.length) {
    tempCheckedArr = locationProp.accountLists.reduce((acc: string[], curr) => {
      // if (item.id === +curr || item.id === +curr.id) {
      acc.push(`All ${curr.name}`);
      // }
      return acc;
    }, tempCheckedArr);
  }
  //   });
  // }
  // if (locationProp.accounts.length) {
  //   locationTreeList?.onlyLocationList?.forEach((item) => {
  if (locationProp.accounts.length) {
    tempCheckedArr = locationProp.accounts.reduce((acc: string[], curr) => {
      // if (item.id === +curr || item.id === +curr.id) {
      acc.push(curr.name);
      // }
      return acc;
    }, tempCheckedArr);
  }
  //   });
  // }

  return tempCheckedArr;
};
/** Validate the budget for rewards program */
export const validateBudget = (
  formValue: rpContainerTypes.ICpActionObject[] | rpApiTypes.IRPProgramActionData[],
  promotionVaultList: rpReducerTypes.ICPActionPromotionObj[],
  programBudget?: null | string | number,
  rewardsType?: number | null | string
) => {
  let isBudgetValid = false;
  let actionBudget = 0;
  if (formValue.length) {
    formValue.forEach((item: rpContainerTypes.ICpActionObject | rpApiTypes.IRPProgramActionData) => {
      let giftCost = 0;
      promotionVaultList.forEach((rewardItem) => {
        if (item.rewards === rewardItem.value) giftCost = parseInt(rewardItem.giftCost ? rewardItem.giftCost : '0');
      });
      actionBudget = actionBudget + giftCost;
    });
    if (programBudget && parseInt(`${programBudget}`)) {
      isBudgetValid = actionBudget <= parseInt(`${programBudget}`?.replace(RP_REPLACE_SPECIAL_CHAR.SPECIAL_CHAR, ''));
    }
    if (rewardsType === RewardType.NON_CASH_PRIZE || rewardsType === Stage.RECOMMENDED_PROGRAM) isBudgetValid = true;
  }
  return isBudgetValid;
};

/** Validate the action for rewards program */
export const validateAction = (actionFormValues: rpContainerTypes.ICpActions[] | rpContainerTypes.ICpActionObject[] | rpApiTypes.IRPProgramActionData[]) => {
  let isActionValid = false;
  const strActionFormValues = [...JSON.parse(JSON.stringify(actionFormValues))];
  const newActionsArray = strActionFormValues.reduce((acc: string[], curr: rpContainerTypes.ICpActions | rpContainerTypes.ICpActionObject | rpApiTypes.IRPProgramActionData) => {
    acc = [
      ...acc,
      JSON.stringify({
        rewards: curr.rewards,
        nonCashRewards: curr.nonCashRewards,
        actionItem: {
          program: curr.actionItem.program,
          operator: curr.actionItem.operator,
          value: curr.actionItem.value,
          condition: curr.actionItem.condition,
          employeesTodo: curr.actionItem.employeesTodo,
          programMeasure: curr.actionItem.programMeasure
        }
      })
    ];
    return acc;
  }, []);
  const uniqueAction = [...new Set(newActionsArray)];
  if (newActionsArray.length === uniqueAction.length) isActionValid = true;
  return isActionValid;
};

/** Get copied request payload of a program to get new program */
export const getCopiedReqPayloadOfProgram = (programDataObj: rpReducerTypes.ICreateProgramDataObj, promotionList: rpReducerTypes.ICPActionPromotionObj[]): rpApiTypes.IRpAddProgramRequest => {
  return {
    data: {
      programDetails: {
        ...programDataObj.programDetails,
        programId: 0
      },
      programAction: convertProgramActionsArray(programDataObj.programAction, promotionList),
      programHuLocations: {
        franchisors: programDataObj.programHuLocations.franchisors.map((datum) => `${datum.id}`) || [],
        accounts: programDataObj.programHuLocations.accounts.map((datum) => `${datum.id}`) || [],
        accountLists: programDataObj.programHuLocations.accountLists.map((datum) => `${datum.id}`) || [],
        selectedLocationCount: programDataObj.programHuLocations.selectedLocationCount,
        isAllLocation: programDataObj.programHuLocations.isAllLocation
      }
    },
    action: RP_ACTIONS_TYPE.COPY
  };
};

/** Get program save changes status */
export const rpSaveChangesStatus = (isRewardsModified: boolean, createProgramObj: rpReducerTypes.ICreateProgramDataObj) => {
  if (!createProgramObj?.programDetails?.programId) {
    if (createProgramObj?.programDetails?.name) return true;
    else if (createProgramObj?.programDetails?.recommendedType === 2) return true;
    else if (createProgramObj?.programDetails?.rewardsType === 2) return true;
    else if (!createProgramObj?.programDetails?.duration && (createProgramObj?.programDetails?.startDate || createProgramObj?.programDetails?.endDate)) return true;
    else if (createProgramObj?.programDetails?.duration === 1 && createProgramObj?.programDetails?.startDate) return true;
    else if (createProgramObj?.programDetails?.rewardsType === 1 && createProgramObj?.programDetails?.programBudget) return true;
    else return false;
  } else if (createProgramObj?.programDetails?.programId) return isRewardsModified;
  else return false;
};

export const downloadFormatHandlerRP = (data: any) => {
  return data?.map((it: any) => ({
    Program: it.name,
    Recommended: it.recommendedType === 2 ? true : false,
    Locations: it?.selectedLocationCount,
    'Start Date': it?.startDate,
    'End Date': it?.endDate,
    'Total Budget': it?.programBudget,
    'Amount Spent': it?.programBudgetSpent,
    Achievers: it?.achieversCount
  }));
};
