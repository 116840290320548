import { EditorState } from 'draft-js';

import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { postsApiTypes, apFranActionApiTypes, apFranApiTypes, calendarApiTypes, apFranContainerTypes, apFranActionReducerTypes, advocacyLeaderboardApiTypes } from 'types';

// ADD POST - DELETE CONTENT
export const addPostDeleteContentRequest = (payload: number) => createAction(actionTypes.AP_DELETE_CONTENT_REQUEST, payload);
export const addPostDeleteContentResponse = (data: postsApiTypes.IPostDetailContentPost) => createAction(actionTypes.AP_DELETE_CONTENT_RESPONSE, data);
export const addPostDeleteContentFailure = (error: null | string) => createAction(actionTypes.AP_DELETE_CONTENT_FAILURE, error);

// ADD POST - MASS DELETE CONTENT
export const addPostMassDeleteContentRequest = (payload: apFranActionApiTypes.IAPMassDeleteRequestPayload) => createAction(actionTypes.AP_MASS_DELETE_CONTENT_REQUEST, payload);
export const addPostMassDeleteContentResponse = (data: postsApiTypes.IPostDetailContentPost) => createAction(actionTypes.AP_MASS_DELETE_CONTENT_RESPONSE, data);
export const addPostMassDeleteContentFailure = (error: null | string) => createAction(actionTypes.AP_MASS_DELETE_CONTENT_FAILURE, error);

// ADD POST - GET INTERNAL NOTES LIST
export const addPostGetInternalNotesListRequest = (payload: number) => createAction(actionTypes.AP_GET_INTERNAL_NOTES_LIST_REQUEST, payload);
export const addPostGetInternalNotesListResponse = (data: postsApiTypes.IPostContentInternalNotes[]) => createAction(actionTypes.AP_GET_INTERNAL_NOTES_LIST_RESPONSE, data);
export const addPostGetInternalNotesListFailure = (error: null | string) => createAction(actionTypes.AP_GET_INTERNAL_NOTES_LIST_FAILURE, error);

// ADD POST - ADD INTERNAL NOTES LIST
export const addPostAddInternalNotesListRequest = (payload: apFranApiTypes.IAPFranAddInternalNotesReq) => createAction(actionTypes.AP_ADD_INTERNAL_NOTES_LIST_REQUEST, payload);
export const addPostAddInternalNotesListResponse = (data: postsApiTypes.IPostContentInternalNotes) => createAction(actionTypes.AP_ADD_INTERNAL_NOTES_LIST_RESPONSE, data);
export const addPostAddInternalNotesListFailure = (error: null | string) => createAction(actionTypes.AP_ADD_INTERNAL_NOTES_LIST_FAILURE, error);

// ADD POST - ADD LINK
export const addPostAddLinkRequest = (payload: string | apFranApiTypes.IAPUpdateLinkReqPayload) => createAction(actionTypes.AP_ADD_LINK_REQUEST, payload);
export const addPostAddLinkResponse = (data: calendarApiTypes.ICouponLinks) => createAction(actionTypes.AP_ADD_LINK_RESPONSE, data);
export const addPostAddLinkFailure = (error: null | string) => createAction(actionTypes.AP_ADD_LINK_FAILURE, error);

// ADD POST - DELETE LINK
export const addPostDeleteLinkRequest = (payload: number) => createAction(actionTypes.AP_DELETE_LINK_REQUEST, payload);
export const addPostDeleteLinkResponse = (data: null) => createAction(actionTypes.AP_DELETE_LINK_RESPONSE, data);
export const addPostDeleteLinkFailure = (error: null | string) => createAction(actionTypes.AP_DELETE_LINK_FAILURE, error);

// ADD POST - UPDATE LINK
export const addPostUpdateLinkRequest = (payload: apFranApiTypes.IAPUpdateLinkRequest) => createAction(actionTypes.AP_UPDATE_LINK_REQUEST, payload);
export const addPostUpdateLinkResponse = (data: calendarApiTypes.ICouponLinks) => createAction(actionTypes.AP_UPDATE_LINK_RESPONSE, data);
export const addPostUpdateLinkFailure = (error: null | string) => createAction(actionTypes.AP_UPDATE_LINK_FAILURE, error);

// ADD POST - CREATE LINK FOR SELECTED COUPON
export const addPostCreateLinkForCouponRequest = (payload: string) => createAction(actionTypes.AP_CREATE_LINK_FOR_COUPON_REQUEST, payload);
export const addPostCreateLinkForCouponResponse = (data: calendarApiTypes.ICouponLinks) => createAction(actionTypes.AP_CREATE_LINK_FOR_COUPON_RESPONSE, data);
export const addPostCreateLinkForCouponFailure = (error: null | string) => createAction(actionTypes.AP_CREATE_LINK_FOR_COUPON_FAILURE, error);

// ADD POST - CREATE LINK FOR ACCOUNT COUPON
export const addPostCreateLinkForAccCouponRequest = (payload: apFranActionApiTypes.ICreateLinkForAccCouponReq) => createAction(actionTypes.AP_CREATE_LINK_FOR_ACC_COUNPON_REQUEST, payload);
export const addPostCreateLinkForAccCouponResponse = (data: apFranActionApiTypes.ICreateLinkForAccCouponResponse) => createAction(actionTypes.AP_CREATE_LINK_FOR_ACC_COUNPON_RESPONSE, data);
export const addPostCreateLinkForAccCouponFailure = (error: null | string) => createAction(actionTypes.AP_CREATE_LINK_FOR_ACC_COUNPON_FAILURE, error);

// ADD POST  SET DELETE CONTENT OBJ
export const addPostDeleteContentObj = (payload: string[]) => createAction(actionTypes.AP_SET_DELETE_CONTENT_OBJ, payload);

// ADD POST - SET SELECTED LOC DATA
export const addPostSetSelectedLocData = (data: postsApiTypes.IPostListDetailData) => createAction(actionTypes.AP_SET_SELECTED_LOC_DATA, data);

// ADD POST - SET LINK DATA
export const addPostSetLinkData = (data: null | calendarApiTypes.ICouponLinks) => createAction(actionTypes.AP_SET_LINK_DATA, data);

// ADD POST - UPDATE CUSTOM TEXT EDITOR
export const addPostUpdateCustomTextEditor = (data: { key: string; value: EditorState }) => createAction(actionTypes.AP_UPDATE_CUSTOM_TEXT_EDITOR, data);
export const addPostUpdatePersonalizedTextEditor = (data: { key: string; value: EditorState }) => createAction(actionTypes.AP_UPDATE_PERSONALIZED_TEXT_EDITOR, data);

// ADD POST - SET CUSTOM TEXT EDITOR
export const addPostSetCustomTextEditorData = (data: apFranContainerTypes.ICreatorEditorStateTextObj) => createAction(actionTypes.AP_SET_CUSTOM_TEXT_EDITOR_DATA, data);
export const addPostSetPersonalizedTextEditorData = (data: apFranContainerTypes.ICreatorEditorStateTextObj) => createAction(actionTypes.AP_SET_PERSONALIZED_TEXT_EDITOR_DATA, data);

// ADD POST - SET CAMPAIGN DATA
export const addPostSetCampaignData = (data: postsApiTypes.IPostListDetailData[]) => createAction(actionTypes.AP_SET_CAMPAIGN_DATA, data);

// ADD POST - SET ACTIVE TAB
export const addPostSetActiveTab = (data: string) => createAction(actionTypes.AP_SET_ACTIVE_TAB, data);

// ADD POST - IS EMOJI OPEN
export const addPostIsEmojiOpen = (data: boolean) => createAction(actionTypes.AP_IS_EMOJI_OPEN, data);

// ADD POST - PERSONALIZED IS EMOJI OPEN
export const addPostPersonalizedIsEmojiOpen = (data: boolean) => createAction(actionTypes.AP_PERSONALIZED_IS_EMOJI_OPEN, data);

// ADD POST IS CONFIRM ACTIVE CAMPAIGN UPDATE
export const addPostIsConfirmActiveCampaignUpdate = (data: boolean) => createAction(actionTypes.AP_IS_CONFIRM_ACTIVE_CAMPAIGN_UPDATE, data);

export const addPostActionsResetAll = () => createAction(actionTypes.AP_ACTION_RESET_ALL);

export const setCreatorModalName = (data: string | null) => createAction(actionTypes.SET_CREATOR_MODAL_NAME, data);

export const setCreatorAITabName = (data: apFranActionReducerTypes.IAITabName) => createAction(actionTypes.SET_CREATOR_AI_TAB_NAME, data);

export const aiShowImageTab = (data: boolean) => createAction(actionTypes.AI_SHOW_IMAGE_TAB, data);

// ADD POST GET HASHTAGS LIST
export const addPostGetHashtagsListRequest = (payload: advocacyLeaderboardApiTypes.IAdvLeaderboardStatsReq) => createAction(actionTypes.AP_GET_HASHTAGS_LIST_REQUEST, payload);
export const addPostGetHashtagsListResponse = (data: string[]) => createAction(actionTypes.AP_GET_HASHTAGS_LIST_RESPONSE, data);
export const addPostGetHashtagsListFailure = (error: null | string) => createAction(actionTypes.AP_GET_HASHTAGS_LIST_FAILURE, error);

// ADD POST CREATE HASHTAGS LIST
export const addPostCreateHashtagRequest = (payload: apFranActionReducerTypes.ICreateHashtagReqPayload) => createAction(actionTypes.AP_CREATE_HASHTAG_REQUEST, payload);
export const addPostCreateHashtagResponse = (data: string[]) => createAction(actionTypes.AP_CREATE_HASHTAG_RESPONSE, data);
export const addPostCreateHashtagFailure = (error: null | string) => createAction(actionTypes.AP_CREATE_HASHTAG_FAILURE, error);

// ADD POST CREATE HASHTAGS LIST
export const addPostDeleteHashtagRequest = (payload: apFranActionReducerTypes.IDeleteHashtagReqPayload) => createAction(actionTypes.AP_DELETE_HASHTAG_REQUEST, payload);
export const addPostDeleteHashtagResponse = (data: string[]) => createAction(actionTypes.AP_DELETE_HASHTAG_RESPONSE, data);
export const addPostDeleteHashtagFailure = (error: null | string) => createAction(actionTypes.AP_DELETE_HASHTAG_FAILURE, error);

// ADD POST SELECT HASHTAGS
export const addPostSelectHashtagResponse = (data: string[]) => createAction(actionTypes.AP_SELECT_HASHTAG_RESPONSE, data);

// SET CUSTOMIZE LINK ENABLE
export const setCustomizeLinkEnable = (data: boolean) => createAction(actionTypes.SET_CUSTOMIZE_LINK_ENABLE, data);

// ADD CUSTOMIZE LINK
export const addCustomizeLink = (data: boolean) => createAction(actionTypes.ADD_CUSTOMIZE_LINK, data);

// SET CREATOR VIDEO THUMNAIL MODAL NAME
export const setCreatorVideoThumbnailModalName = (data: string | null) => createAction(actionTypes.SET_CREATOR_VIDEO_THUMBNAIL_MODAL_NAME, data);

// ADD POST TRANSLATE CAPTION
export const addPostCaptionTranslateRequest = (payload: { payload: apFranActionReducerTypes.ITranslateTextReq; onSuccess: (text: string) => void }) =>
  createAction(actionTypes.AP_CAPTION_TRANSLATE_REQUEST, payload);
export const addPostCaptionTranslateResponse = (payload: apFranActionReducerTypes.ISetCreatorOriginalTextReq) => createAction(actionTypes.AP_CAPTION_TRANSLATE_RESPONSE, payload);
export const addPostCaptionTranslateFailure = (error: null | string) => createAction(actionTypes.AP_CAPTION_TRANSLATE_FAILURE, error);

// SET CREATOR ORIGINAL TEXT
export const addPostSetCreatorOriginalText = (payload: apFranActionReducerTypes.ISetCreatorOriginalTextReq) => createAction(actionTypes.AP_SET_CREATOR_ORIGINAL_TEXT, payload);

const ADD_POST_FRANCHISOR_ACTION = {
  addPostDeleteContentRequest,
  addPostDeleteContentResponse,
  addPostDeleteContentFailure,
  addPostMassDeleteContentRequest,
  addPostMassDeleteContentResponse,
  addPostMassDeleteContentFailure,
  addPostGetInternalNotesListRequest,
  addPostGetInternalNotesListResponse,
  addPostGetInternalNotesListFailure,
  addPostAddInternalNotesListRequest,
  addPostAddInternalNotesListResponse,
  addPostAddInternalNotesListFailure,
  addPostAddLinkRequest,
  addPostAddLinkResponse,
  addPostAddLinkFailure,
  addPostDeleteLinkRequest,
  addPostDeleteLinkResponse,
  addPostDeleteLinkFailure,
  addPostUpdateLinkRequest,
  addPostUpdateLinkResponse,
  addPostUpdateLinkFailure,
  addPostCreateLinkForCouponRequest,
  addPostCreateLinkForCouponResponse,
  addPostCreateLinkForCouponFailure,
  addPostCreateLinkForAccCouponRequest,
  addPostCreateLinkForAccCouponResponse,
  addPostCreateLinkForAccCouponFailure,
  addPostGetHashtagsListRequest,
  addPostGetHashtagsListResponse,
  addPostGetHashtagsListFailure,
  addPostCreateHashtagRequest,
  addPostCreateHashtagResponse,
  addPostCreateHashtagFailure,
  addPostDeleteHashtagRequest,
  addPostDeleteHashtagResponse,
  addPostDeleteHashtagFailure,
  addPostDeleteContentObj,
  addPostSetLinkData,
  addPostUpdateCustomTextEditor,
  addPostUpdatePersonalizedTextEditor,
  addPostSetCustomTextEditorData,
  addPostSetPersonalizedTextEditorData,
  addPostSetCampaignData,
  addPostSetActiveTab,
  addPostIsEmojiOpen,
  addPostPersonalizedIsEmojiOpen,
  addPostIsConfirmActiveCampaignUpdate,
  addPostActionsResetAll,
  setCreatorModalName,
  setCreatorAITabName,
  aiShowImageTab,
  addPostSetSelectedLocData,
  addPostSelectHashtagResponse,
  setCustomizeLinkEnable,
  addCustomizeLink,
  setCreatorVideoThumbnailModalName,
  addPostCaptionTranslateResponse,
  addPostCaptionTranslateRequest,
  addPostCaptionTranslateFailure,
  addPostSetCreatorOriginalText
};

export type AddPostFranMainActions = ActionsUnion<typeof ADD_POST_FRANCHISOR_ACTION>;
