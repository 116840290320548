import { takeLatest, put } from '@redux-saga/core/effects';
import * as actionTypes from 'revv/actions/action-types';
import {
  userRequestFailure,
  userResponse,
  userMetricsRequestFailure,
  userMetricsResponse,
  userListRequestFailure,
  userListResponse,
  clientsDetailedViewRequest,
  userDeleteRequestFailure,
  userDeleteResponse,
  userUploadResponse,
  userUploadRequestFailure,
  userCreateResponse,
  userCreateFailure,
  userDetailsUpdateResponse,
  userDetailsUpdateFailure,
  settingsRequest,
  userProfileSettingsResponse,
  userProfileSettingsFailure
} from 'revv/actions';
import {
  IListRequestParams,
  IUserListResponse,
  IUserListResponseData,
  IUserRequestParams,
  IUserResponse,
  IUsersMetricsResponse,
  ISagaPayload,
  IUploadParams,
  IUpdateUserDetailsProps,
  SagaYield,
  SagaValue,
  IUserMetricsParams,
  IProfileSettingRequest,
  IProfileAttributes
} from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES, SAVE_PREFERENCES_SUCCESS } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* loadUserDetail(action: ISagaPayload<IUserRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.userDetail.apiPath.replace(':id', `${action.payload?.id}`),
      action: API_ROUTES.userDetail.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV
    });
    if (response.status !== 200) {
      yield put(userRequestFailure(response.data.error));
    } else {
      const client = response.data.included.find((it: any) => it.type === 'clients');
      const userLocations = response.data.included.filter((it: any) => it.type === 'locations');
      const userDetail: IUserResponse = {
        id: response.data.data.id,
        canManageClientConfig: response.data.data.attributes.can_manage_client_config,
        canManageCustomers: response.data.data.attributes.can_manage_customers,
        canManageSurveys: response.data.data.attributes.can_manage_surveys,
        canManageUsers: response.data.data.attributes.can_manage_users,
        canSendSurvey: response.data.data.attributes.can_send_survey,
        customersCount: response.data.data.attributes.customers_count,
        email: response.data.data.attributes.email,
        externalId: response.data.data.attributes.external_id,
        firstName: response.data.data.attributes.first_name,
        lastName: response.data.data.attributes.last_name,
        locationsCount: response.data.data.attributes.locations_count,
        role: response.data.data.attributes.role,
        surveysCount: response.data.data.attributes.surveys_count,
        authenticationToken: response.data.data.attributes.authentication_token,
        averageRating: response.data.data.attributes.average_rating,
        nps: response.data.data.attributes.nps,
        surveysForUserAgreedToReviewCount: response.data.data.attributes.surveys_for_user_agreed_to_review_count,
        surveysForUserCreatedCount: response.data.data.attributes.surveys_for_user_created_count,
        surveysForUserSentCount: response.data.data.attributes.surveys_for_user_sent_count,
        surveysForUserCompletedCount: response.data.data.attributes.surveys_for_user_completed_count,
        activeClientId: client.id,
        locationList: userLocations.map((it: any) => ({
          id: it.id,
          name: it.attributes.name
        }))
      };
      if (action.payload?.isLogin && !action.payload.isLocationImpersonate) {
        yield put(
          clientsDetailedViewRequest({
            id: userDetail.activeClientId,
            isLogin: true
          })
        );
        yield put(settingsRequest());
      }
      yield put(
        userResponse({
          data: userDetail,
          isLogin: action.payload?.isLogin,
          isEdit: action?.payload?.isEdit
        })
      );
      if (action.payload?.callback) {
        yield action.payload.callback();
      }
    }
  } catch (error: any) {
    yield put(userRequestFailure(error));
  }
}

function* getUserMetrics(action: ISagaPayload<IUserMetricsParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: action.payload ? API_ROUTES.usersFilteredMetrics.apiPath : API_ROUTES.usersMetrics.apiPath,
      action: action.payload ? API_ROUTES.usersFilteredMetrics.method : API_ROUTES.usersMetrics.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        'filter[visit_from_date]': action.payload?.fromDate,
        'filter[visit_to_date]': action.payload?.toDate
      },
      data: {
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        location_ids: action.payload?.locationIds
      }
    });
    if (response.status !== 200) {
      yield put(userMetricsRequestFailure(response.data.error));
    } else {
      const userMetricsResponseData: IUsersMetricsResponse = {
        standardSurveysSentCount: response.data.data.standard_surveys_sent_count,
        surveysAverageRating: response.data.data.average_rating_count,
        surveysNps: response.data.data.surveys_nps,
        usersCount: response.data.data.users_count,
        customerCount: response.data.data.customers_count,
        surveySent: response.data.data.surveys_sent_count,
        surveyCancelled: response.data.data.surveys_cancelled_count,
        surveyCompleted: response.data.data.surveys_completed_count,
        surveyAgreedToReview: response.data.data.surveys_agreed_to_review_count,
        surveyAvgRating: response.data.data.surveys_average_rating,
        totalUser: response.data.data.total_users_count
      };
      yield put(userMetricsResponse(userMetricsResponseData));
    }
  } catch (error: any) {
    yield put(userMetricsRequestFailure(error));
  }
}

function* getUserList(action: ISagaPayload<IListRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const isFilteredApiCall = action.payload?.rallioFranchisorId || action.payload?.rallioLocationId;
    const response = yield apiCall({
      apiPath: isFilteredApiCall ? API_ROUTES.usersFilteredList.apiPath : API_ROUTES.usersList.apiPath,
      action: isFilteredApiCall ? API_ROUTES.usersFilteredList.method : API_ROUTES.usersList.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        'filter[from_date]': action.payload?.isLeaderBoard ? undefined : action.payload?.fromDate,
        'filter[to_date]': action.payload?.isLeaderBoard ? undefined : action.payload?.toDate,
        'filter[visit_from_date]': action.payload?.isLeaderBoard ? action.payload?.fromDate : undefined,
        'filter[visit_to_date]': action.payload?.isLeaderBoard ? action.payload?.toDate : undefined,
        'filter[client_id]': action.payload?.clientId || '',
        'filter[keyword]': action.payload?.searchKeyword || '',
        'page[size]': action.payload?.size,
        'page[number]': action.payload?.pageNumber,
        sort: action.payload?.sorting || ''
      },
      data: {
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId,
        location_ids: action.payload?.locationIds
      }
    });
    if (response.status !== 200) {
      yield put(userListRequestFailure(response.data.error));
    } else {
      if (response.data) {
        const responseData: IUserListResponse[] =
          response.data?.data && response.data?.data?.length > 0
            ? response.data.data.map((it: IUserListResponseData) => {
                return {
                  id: it.id,
                  name: `${it?.attributes?.first_name || ''} ${it?.attributes?.last_name || ''}`,
                  locationCount: it?.attributes?.locations_count,
                  customerCount: it?.attributes?.customers_count,
                  role: it?.attributes?.role,
                  averageRating: it?.attributes?.average_rating,
                  surveySentCount: it?.attributes?.surveys_sent_count,
                  surveyCancelledCount: it?.attributes?.surveys_cancelled_count,
                  surveyCompletedCount: it?.attributes?.surveys_completed_count,
                  surveyAgreedToReviewCount: it?.attributes?.surveys_agreed_to_review_count
                };
              })
            : [];
        yield put(userListResponse(responseData));
      }
    }
  } catch (error: any) {
    yield put(userMetricsRequestFailure(error));
  }
}

function* userUpdateRequest(action: ISagaPayload<IUpdateUserDetailsProps>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const dataObj = {
        id: action.payload?.userId || undefined,
        attributes: {
          email: action.payload.email,
          first_name: action.payload.firstName,
          last_name: action.payload.lastName,
          role: action.payload.role,
          password: action.payload.password || undefined,
          location_ids: action.payload.isLocationsEditable && action.payload.locationList ? action.payload.locationList.map((it) => it.id).toString() : undefined
        },
        type: 'users'
      };
      const response = yield apiCall({
        apiPath: action.payload.userId ? API_ROUTES.userUpdate.apiPath.replace(':id', action.payload?.userId) : API_ROUTES.userCreate.apiPath,
        action: action.payload.userId ? API_ROUTES.userUpdate.method : API_ROUTES.userCreate.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV,
        data: { data: dataObj }
      });
      if (response.status === 201 || response.status === 200) {
        if (action.payload.userId) {
          const responseObj: IUserResponse = {
            id: response.data.data.id,
            canManageClientConfig: response.data.data.attributes.can_manage_client_config,
            canManageCustomers: response.data.data.attributes.can_manage_customers,
            canManageSurveys: response.data.data.attributes.can_manage_surveys,
            canManageUsers: response.data.data.attributes.can_manage_users,
            canSendSurvey: response.data.data.attributes.can_send_survey,
            customersCount: response.data.data.attributes.customers_count,
            email: response.data.data.attributes.email,
            externalId: response.data.data.attributes.external_id,
            firstName: response.data.data.attributes.first_name,
            lastName: response.data.data.attributes.last_name,
            locationsCount: response.data.data.attributes.locations_count,
            role: response.data.data.attributes.role,
            surveysCount: response.data.data.attributes.surveys_count,
            authenticationToken: response.data.data.attributes.authentication_token,
            averageRating: response.data.data.attributes.average_rating,
            nps: response.data.data.attributes.nps,
            surveysForUserAgreedToReviewCount: response.data.data.attributes.surveys_for_user_agreed_to_review_count,
            surveysForUserCreatedCount: response.data.data.attributes.surveys_for_user_created_count,
            surveysForUserSentCount: response.data.data.attributes.surveys_for_user_sent_count,
            surveysForUserCompletedCount: response.data.data.attributes.surveys_for_user_completed_count,
            activeClientId: action.payload.clientId || '',
            locationList: action.payload.locationList
          };
          yield put(userDetailsUpdateResponse({ data: responseObj }));
        } else {
          yield put(userCreateResponse());
        }
        if (action.payload.callback) {
          action.payload.callback({ id: response?.data?.data?.id });
        }
      } else {
        if (action.payload?.userId) {
          yield put(userDetailsUpdateFailure(`${response.data.errors[0].title} ${response.data.errors[0].detail}`));
        } else {
          yield put(userCreateFailure(response.data.errors[0].detail));
        }
      }
    }
  } catch (error: any) {
    if (action.payload?.userId) {
      yield put(userDetailsUpdateFailure('Error Occured'));
    } else {
      yield put(userCreateFailure('Error Occured'));
    }
  }
}

function* deleteUser(action: ISagaPayload<IUserRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload?.id) {
      const response = yield apiCall({
        apiPath: API_ROUTES.userDelete.apiPath.replace(':id', action.payload?.id),
        action: API_ROUTES.userDelete.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV,
        data: action.payload
      });
      if (response.status === 204) {
        yield put(userDeleteResponse());
        if (action.payload.callback) {
          action.payload.callback();
        }
      } else {
        yield put(userDeleteRequestFailure(response.data.error));
      }
    }
  } catch (error: any) {
    yield put(userDeleteRequestFailure(error));
  }
}

function* uploadUsers(action: ISagaPayload<IUploadParams>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const fileData = new FormData();
      fileData.append('file', action.payload?.data);
      if (action.payload?.data) {
        const response = yield apiCall({
          apiPath: API_ROUTES.uploadUsers.apiPath,
          action: API_ROUTES.uploadUsers.method,
          headers: defaultRevvHeader(),
          type: API_TYPES.REVV,
          data: fileData,
          fileUpload: true
        });
        if (response.status === 204) {
          yield put(userUploadResponse('You will receive an email once your request has been processed.'));
        } else {
          yield put(userUploadRequestFailure('There was a problem uploading your file'));
        }
      }
    } else {
      yield put(userUploadRequestFailure('File not selected.'));
    }
  } catch (error: any) {
    yield put(userUploadRequestFailure(error));
  }
}

function* getProfileSettingsRequest(action: ISagaPayload<IProfileSettingRequest>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload?.id) {
      const response = yield apiCall({
        apiPath: API_ROUTES.profileSettings.apiPath.replace(':id', `${action.payload?.id}`),
        action: action.payload.attributes ? API_ROUTES.profileSettingsUpdate.method : API_ROUTES.profileSettings.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV,
        params: action.payload.attributes
          ? undefined
          : {
              user_id: action.payload?.id || ''
            },
        data: action.payload.attributes
          ? {
              data: {
                id: action.payload.id,
                attributes: {
                  completed_negative_surveys: action.payload.attributes.completedNegativeSurveys,
                  completed_positive_surveys: action.payload.attributes.completedPositiveSurveys,
                  completed_surveys_with_feedback: action.payload.attributes.completedSurveysWithFeedback,
                  contacted_request_only: action.payload.attributes.contactedRequestOnly,
                  daily_survey_summaries: action.payload.attributes.dailySurveySummaries
                }
              }
            }
          : undefined
      });

      if (response.status !== 200) {
        if (action.payload.callBack) {
          action.payload.callBack({ isSuccess: false, msg: 'OOPS! Something went wrong.' });
        }
        yield put(userProfileSettingsFailure(response.data.error));
      } else {
        if (action.payload.callBack) {
          action.payload.callBack({ isSuccess: true, msg: SAVE_PREFERENCES_SUCCESS });
        }
        const data: IProfileAttributes = {
          survey: {
            completedNegativeSurveys: response.data.data.attributes.completed_negative_surveys,
            completedPositiveSurveys: response.data.data.attributes.completed_positive_surveys,
            completedSurveysWithFeedback: response.data.data.attributes.completed_surveys_with_feedback,
            contactedRequestOnly: response.data.data.attributes.contacted_request_only
          },
          dailySurveySummaries: response.data.data.attributes.daily_survey_summaries
        };
        yield put(userProfileSettingsResponse(data));
      }
    }
  } catch (error: any) {
    yield put(userProfileSettingsFailure(error));
  }
}

export function* takeUserRequest() {
  yield takeLatest(actionTypes.USER_REQUEST, loadUserDetail);
  yield takeLatest(actionTypes.USER_METRICS_REQUEST, getUserMetrics);
  yield takeLatest(actionTypes.USER_LIST_REQUEST, getUserList);
  yield takeLatest(actionTypes.USER_CREATE_REQUEST, userUpdateRequest);
  yield takeLatest(actionTypes.USER_DETAILS_UPDATE_REQUEST, userUpdateRequest);
  yield takeLatest(actionTypes.USER_DELETE_REQUEST, deleteUser);
  yield takeLatest(actionTypes.USER_UPLOAD_REQUEST, uploadUsers);
  yield takeLatest(actionTypes.PROFILE_SETTINGS_REQUEST, getProfileSettingsRequest);
}
