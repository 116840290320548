import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { Loading } from 'components';
import { APP_TITLE, COMMON_SOCIAL_PROVIDER_TYPE, MG_MEDIA_TYPE, FACEBOOK_BOOST_AD_PREVIEW_ACTIONS } from 'utils/constants';
import { CustomSwiper, ReactVideoPlayer, ImageValidation } from 'widgets/Media';

export const BoostAdPreview = () => {
  const { accountDetails } = useSelector((state: IStore) => state.accounts);
  const { facebookAdPreviewObj } = useSelector((state: IStore) => state.fbAdsTargeting);
  const { isPreviewFetching, adPreview } = facebookAdPreviewObj;

  return (
    <div className="boost__white--box add-preview-wrp r-mt0">
      {/* <div className="layer"> */}
      <div>
        {' '}
        <span className="boost__box--title">Ad Preview</span>
      </div>
      {isPreviewFetching ? (
        <Loading isSmall />
      ) : (
        adPreview?.previewId && (
          <div className="post-card">
            <div className="r-pd2">
              {accountDetails?.social_media?.map((socialMediaDatum, index) => {
                return (
                  COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK === socialMediaDatum.provider_name && (
                    <div className="r-flx g-20" key={`boost-ad-preview-${index}`}>
                      <div className="boost__preview--icon">
                        <ImageValidation imgUrl={socialMediaDatum?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat customName="posted" customClassname="posted" />
                      </div>
                      <div>
                        <span className="loc-name">{socialMediaDatum.profile_name || APP_TITLE}</span>
                        <div className="r-flx lbl">
                          <span>Sponsored</span>
                          <ImageValidation isImgValid defaultImg="globe" customName="globe" customClassname="globe" />
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
              <div className="desc r-mt2">{adPreview?.facebookText || ''}</div>
            </div>
            {adPreview?.photoUrls.length ? (
              <div className="post-img--wrp">
                {adPreview?.photoUrls.length >= 1 && adPreview?.cloudinaryResourceType === MG_MEDIA_TYPE.PHOTO ? (
                  adPreview?.photoUrls.length > 1 ? (
                    <CustomSwiper imagesList={adPreview?.photoUrls.map((datum) => datum)} isVirtualSlider />
                  ) : (
                    <ImageValidation imgUrl={adPreview?.photoUrls[0] || ''} customName={'boost-preview-image'} />
                  )
                ) : null}
                {adPreview?.cloudinaryResourceType === MG_MEDIA_TYPE.VIDEO && <ReactVideoPlayer videoUrl={adPreview?.photoUrls[0]} />}
              </div>
            ) : null}
            {adPreview?.linkTitle && (
              <div className="r-flx r-flx-ac r-flx-jb card-ft">
                <div>
                  <span className="email">{adPreview.linkTitle}</span>
                  <div className="post-txt">{adPreview.linkDescription}</div>
                </div>
                <button className="learn-btn">Learn More</button>
              </div>
            )}
            <div className="r-flx r-flx-ac r-flx-jb card-ft-btn__wrp">
              {FACEBOOK_BOOST_AD_PREVIEW_ACTIONS.map((it, index) => {
                return (
                  <button key={`boost-ad-preview-action-${index}`}>
                    <div className="r-flx r-flx-ac">
                      <div className="soc__media--btn">
                        <ImageValidation isImgValid defaultImg={it.defaultImg} customName={it.label} customClassname={it.className} />
                      </div>
                      <span className="btn-lbl">{it.label}</span>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )
      )}
    </div>
    // </div>
  );
};
