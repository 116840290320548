import { useSelector } from 'react-redux';

import { addPostSetEditConfirmForApprovedPost, addPostSetSelectedMediaList, getAITagsListRequest, selectedMediaListSort, videoCompatibilityReset } from 'actions';
import { IStore, apAccountContainerTypes, commonWidgetTypes } from 'types';
import { AP_ADD_TO_POST_TAB_NAMES, COMMON_SOCIAL_PROVIDER_TYPE, IReactDNDItemTypes, MG_MEDIA_TYPE, POST_IMAGES_LIMITATION, TOOLTIP_PLACEMENT } from 'utils/constants';
import { creatorPhotosCount, getBrandMediaCloudinaryIdsForAI, getBrandMediaTypeForAI, handleLinkNavigation } from 'utils/helpers';
import { ImageValidation, Video } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { ReactDND } from 'widgets/DragAndDrop';

export const LeftCreatorMediaList = ({
  socialMedia,
  setModalName,
  dispatch,
  selectedMediaList,
  savedPostDetails,
  editData,
  editConfirmObj,
  isPostEditable
}: apAccountContainerTypes.ILeftMediaListProps) => {
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const splitSelectedMediaList = selectedMediaList.slice(
    0,
    socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? POST_IMAGES_LIMITATION.TWITTER
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? POST_IMAGES_LIMITATION.LINKEDIN
      : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? POST_IMAGES_LIMITATION.GOOGLE
      : selectedMediaList.length
  );
  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const renderImage = (mediumSizeUrl: null | string, url: null | string) => {
    return (
      <div className="img-thumb">
        <ImageValidation imgUrl={mediumSizeUrl} customName={'creator-media'} onClick={() => handleLinkNavigation(url)} />
      </div>
    );
  };

  const renderVideo = (socialMedia: string, thumbnailUrl: string, mediumSizeUrl: string) => {
    return (
      <div className={`video-outer-element-left-side${savedPostDetails && !savedPostDetails[`use_${socialMedia}`] ? ` button-opacity` : ``}`}>
        <Video videoUrl={mediumSizeUrl} customClassname={'video'} light={thumbnailUrl} />
      </div>
    );
  };

  const handleDeleteUploadedMedia = (mediaUrl: string, mediaType: string) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else if (mediaUrl) {
      const totalMediaUrls = selectedMediaList.filter((it) => it.url !== mediaUrl);
      dispatch(addPostSetSelectedMediaList(totalMediaUrls));
      if (isShowAiImageTab) {
        if (totalMediaUrls?.length) dispatch(getAITagsListRequest({ cloudinaryId: getBrandMediaCloudinaryIdsForAI(totalMediaUrls), type: getBrandMediaTypeForAI(totalMediaUrls) }));
      }
      if (mediaType === MG_MEDIA_TYPE.VIDEO) {
        dispatch(videoCompatibilityReset());
      }
    }
  };

  const handleSelectedMedialistPosition = (data: commonWidgetTypes.IReactDNDSortData) => {
    dispatch(selectedMediaListSort(data));
  };

  return selectedMediaList.length && (socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM || selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO) ? (
    <>
      {splitSelectedMediaList?.length > 1 && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && <p className="d-block mb-3">Drag and drop to rearrange the order</p>}

      <div className={`image-roll-holder ctml ct-main`}>
        {splitSelectedMediaList.map((mediaDatum, index) => {
          return mediaDatum.media_type === MG_MEDIA_TYPE.PHOTO && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
            <ReactDND
              key={`campaign-posts-list-dnd-${index}`}
              index={index}
              type={IReactDNDItemTypes.CARD}
              data={mediaDatum}
              onSortData={(data) => handleSelectedMedialistPosition(data)}
              // disabled={editData?.isCorporatePost}
            >
              <div className={`ctvm ct-img${savedPostDetails && !savedPostDetails[`use_${socialMedia}`] ? ` button-opacity` : ``}`} key={`creator-img-media-${index}`}>
                {mediaDatum.comments ? (
                  <CustomTooltip
                    customPlacement={TOOLTIP_PLACEMENT.TOP}
                    customTooltipText={mediaDatum.comments}
                    customClassname={'custom-tooltip-long-text'}
                    customInput={() => renderImage(mediaDatum.medium_size_url, mediaDatum.url)}
                  />
                ) : (
                  renderImage(mediaDatum.medium_size_url, mediaDatum.url)
                )}
                {!savedPostDetails?.locked ? (
                  <div className="ctm-controls">
                    <div className="ctmc-item ctmc-delete" onClick={() => handleDeleteUploadedMedia(mediaDatum.url, MG_MEDIA_TYPE.PHOTO)}>
                      <ImageValidation isImgValid defaultImg="delete-bright-red" customName="Delete" />
                    </div>
                  </div>
                ) : null}
              </div>
            </ReactDND>
          ) : (
            <div className={`ctvm ct-img ct-video`} key={`creator-acc-video-media-${index}`}>
              {mediaDatum.comments && mediaDatum.media_type === MG_MEDIA_TYPE.VIDEO ? (
                <CustomTooltip
                  customPlacement={TOOLTIP_PLACEMENT.TOP}
                  customTooltipText={mediaDatum.comments}
                  customClassname={'custom-tooltip-long-text'}
                  customInput={() => renderVideo(socialMedia, mediaDatum.thumbnail_url || '', mediaDatum.medium_size_url || '')}
                />
              ) : mediaDatum.media_type === MG_MEDIA_TYPE.VIDEO ? (
                renderVideo(socialMedia, mediaDatum.thumbnail_url || '', mediaDatum.medium_size_url || '')
              ) : null}
              {!savedPostDetails?.locked ? (
                <div className="ctm-controls">
                  <div className="ctmc-item ctmc-delete" onClick={() => handleDeleteUploadedMedia(mediaDatum.url, MG_MEDIA_TYPE.VIDEO)}>
                    <ImageValidation isImgValid defaultImg="delete-bright-red" customName="Delete" />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
        {selectedMediaList[selectedMediaList.length - 1].media_type === MG_MEDIA_TYPE.PHOTO && socialMedia !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && !savedPostDetails?.locked && (
          <div
            className={`add-img-btn${creatorPhotosCount(selectedMediaList) >= 500 ? ` pointer-events-none` : ''}`}
            onClick={() => {
              if (isPostEditable) {
                handleEditConfirmation();
              } else if (!savedPostDetails?.locked) {
                setModalName(AP_ADD_TO_POST_TAB_NAMES.PHOTOS);
              }
            }}
          >
            <div>
              <ImageValidation isImgValid defaultImg="add-images" customName="add-img-btn" />
            </div>
            <span>Add Images</span>
          </div>
        )}
      </div>
    </>
  ) : null;
};
