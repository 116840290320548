import { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { socialContentsDeleteRequest } from 'actions';
import { scContainerTypes } from 'types';
import { useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { handleLinkNavigation } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, CREATE_POST_TYPE, MODAL_BOX_TYPES, SC_DELETE_CONFIRM_MSG, SIDE_NAV_NAME, USER_OWNERSHIP } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { ModalPopup } from 'components';

export const SCSettings = ({ id: socialContentId, type, platform, actionUrl }: scContainerTypes.ISCSettingsProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { subNavPageName } = useNavBarData();
  const { userOwnership } = useAccountSwitcherData();

  const [isShowModal, setShowModal] = useState(false);

  const handleSCDelete = () => {
    if (socialContentId) {
      dispatch(socialContentsDeleteRequest({ id: socialContentId, platform, pageType: subNavPageName }));
      setShowModal(false);
    }
  };

  const isDeleteButton = useMemo(() => {
    const isDeleteCommon = [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(platform);
    switch (subNavPageName) {
      case SIDE_NAV_NAME.COMMUNITY_SANDBOX:
        return userOwnership === USER_OWNERSHIP.FRANCHISOR && isDeleteCommon;
      case SIDE_NAV_NAME.REPUTATION_OUTBOX:
        return type !== CREATE_POST_TYPE.MESSAGE && isDeleteCommon;
      default:
        return false;
    }
  }, [userOwnership, subNavPageName, type, platform]);

  return (
    <>
      <Dropdown className="drop-items">
        <Dropdown.Toggle id="dropitems-more-options" className="btn-icon ripple">
          <div className="more-dots-horizontal">
            <ImageValidation isImgValid defaultImg="more-dots-h" customName="More" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleLinkNavigation(actionUrl)}>
            <div className="md-item">
              <ImageValidation isImgValid defaultImg="link-black" customName="Link" />
            </div>
            <span>Link to Post</span>
          </Dropdown.Item>
          {isDeleteButton && (
            <Dropdown.Item onClick={() => setShowModal(true)}>
              <div className="md-item">
                <ImageValidation isImgValid defaultImg="delete-red" customName="Delete" />
              </div>
              <span>Delete</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <ModalPopup
        isModalShow={isShowModal}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        // containerClassName={'modal-confirmation confirm-delete'}
        containerClassName={'cnfrm__delete--modal glbl__delete--modal'}
        modalBody={() => <div className="modal-message-wraps">{SC_DELETE_CONFIRM_MSG}</div>}
        modalCloseButton
        handleModalClose={() => setShowModal(false)}
        isModalFooterShow
        modalFooter={() => (
          <div className="delete__btn--wrp">
            <button className="ac-btn ac-secondary-white ac-outline ac-block" onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <button className="ac-btn ac-danger ac-block" onClick={handleSCDelete}>
              Delete
            </button>
          </div>
        )}
      />
    </>
  );
};
