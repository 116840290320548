import { getInitialName } from 'revv/utils';

interface INameCircle {
  name: string;
  onClick?: () => void;
  identifier?: string;
  disableAvatar?: boolean;
  isNameDisabled?: boolean;
}

export const NameCircle = ({ name, identifier, disableAvatar, onClick, isNameDisabled }: INameCircle) => {
  return (
    <div className={`user-name__hldr d-flex align-items-center gap-20 ${!name ? 'd-none' : ''} ${onClick ? 'cur-pointer' : ''}`} onClick={onClick}>
      {disableAvatar ? null : <div className={`avatar text-uppercase`}>{getInitialName(name)}</div>}
      {!isNameDisabled ? (
        <span className="customer-name text-dotted" title={name}>
          {identifier ? `${identifier} - ` : ''}
          {name}
        </span>
      ) : null}
    </div>
  );
};
