import { config } from 'config';
import { commonWidgetTypes } from 'types';

export const InteractiveIcons = (props: commonWidgetTypes.IIntIcons) => (
  <div className={props.customClassname}>
    <div className="grid">
      <div className="grid__item pos-1">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-1.svg)` }} />
      </div>
      <div className="grid__item pos-2">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-1.svg)` }} />
      </div>
      <div className="grid__item pos-3">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-1.svg)` }} />
      </div>
      <div className="grid__item pos-4">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-2.svg)` }} />
      </div>
      <div className="grid__item pos-5">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-1.svg)` }} />
      </div>
      <div className="grid__item pos-6">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-12.svg)` }} />
      </div>
      <div className="grid__item pos-7">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-2.svg)` }} />
      </div>
      <div className="grid__item pos-8">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-2.svg)` }} />
      </div>
      <div className="grid__item pos-9">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-5.svg)` }} />
      </div>
      <div className="grid__item pos-10">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-3.svg)` }} />
      </div>
      <div className="grid__item pos-11">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-2.svg)` }} />
      </div>
      <div className="grid__item pos-12">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-1.svg)` }} />
      </div>
      <div className="grid__item pos-13">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-4.svg)` }} />
      </div>
      <div className="grid__item pos-14">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-2.svg)` }} />
      </div>
      <div className="grid__item pos-15">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-4.svg)` }} />
      </div>
      <div className="grid__item pos-16">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-3.svg)` }} />
      </div>
      <div className="grid__item pos-17">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-4.svg)` }} />
      </div>
      <div className="grid__item pos-18">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-12.svg)` }} />
      </div>
      <div className="grid__item pos-19">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-1.svg)` }} />
      </div>
      <div className="grid__item pos-20">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-4.svg)` }} />
      </div>
      <div className="grid__item pos-21">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-5.svg)` }} />
      </div>
      <div className="grid__item pos-22">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-6.svg)` }} />
      </div>
      <div className="grid__item pos-23">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-5.svg)` }} />
      </div>
      <div className="grid__item pos-24">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-6.svg)` }} />
      </div>
      <div className="grid__item pos-25">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-7.svg)` }} />
      </div>
      <div className="grid__item pos-26">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-6.svg)` }} />
      </div>
      <div className="grid__item pos-27">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-12.svg)` }} />
      </div>
      <div className="grid__item pos-28">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-13.svg)` }} />
      </div>
      <div className="grid__item pos-29">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-10.svg)` }} />
      </div>
      <div className="grid__item pos-30">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-7.svg)` }} />
      </div>
      <div className="grid__item pos-31">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-9.svg)` }} />
      </div>
      <div className="grid__item pos-32">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-8.svg)` }} />
      </div>
      <div className="grid__item pos-33">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-4.svg)` }} />
      </div>
      <div className="grid__item pos-34">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-3.svg)` }} />
      </div>
      <div className="grid__item pos-35">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-11.svg)` }} />
      </div>
      <div className="grid__item pos-36">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-16.svg)` }} />
      </div>
      <div className="grid__item pos-37">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-8.svg)` }} />
      </div>
      <div className="grid__item pos-38">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-3.svg)` }} />
      </div>
      <div className="grid__item pos-39">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-12.svg)` }} />
      </div>
      <div className="grid__item pos-40">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-10.svg)` }} />
      </div>
      <div className="grid__item pos-41">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-8.svg)` }} />
      </div>
      <div className="grid__item pos-42">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-11.svg)` }} />
      </div>
      <div className="grid__item pos-43">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-6.svg)` }} />
      </div>
      <div className="grid__item pos-44">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-19.svg)` }} />
      </div>
      <div className="grid__item pos-45">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-7.svg)` }} />
      </div>
      <div className="grid__item pos-46">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-11.svg)` }} />
      </div>
      <div className="grid__item pos-47">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-7.svg)` }} />
      </div>
      <div className="grid__item pos-48">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-14.svg)` }} />
      </div>
      <div className="grid__item pos-49">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-3.svg)` }} />
      </div>
      <div className="grid__item pos-50">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-3.svg)` }} />
      </div>
      <div className="grid__item pos-51">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-5.svg)` }} />
      </div>
      <div className="grid__item pos-52">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-7.svg)` }} />
      </div>
      <div className="grid__item pos-53">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/insta-5.svg)` }} />
      </div>
      <div className="grid__item pos-54">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-9.svg)` }} />
      </div>
      <div className="grid__item pos-55">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-12.svg)` }} />
      </div>
      <div className="grid__item pos-56">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-4.svg)` }} />
      </div>
      <div className="grid__item pos-57">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-2.svg)` }} />
      </div>
      <div className="grid__item pos-58">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-15.svg)` }} />
      </div>
      <div className="grid__item pos-59">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-9.svg)` }} />
      </div>
      <div className="grid__item pos-60">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-7.svg)` }} />
      </div>
      <div className="grid__item pos-61">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-17.svg)` }} />
      </div>
      <div className="grid__item pos-62">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-10.svg)` }} />
      </div>
      <div className="grid__item pos-63">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-6.svg)` }} />
      </div>
      <div className="grid__item pos-64">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-6.svg)` }} />
      </div>
      <div className="grid__item pos-65">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/twtr-8.svg)` }} />
      </div>
      <div className="grid__item pos-66">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/yelp-8.svg)` }} />
      </div>
      <div className="grid__item pos-67">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-18.svg)` }} />
      </div>
      <div className="grid__item pos-68">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/google-10.svg)` }} />
      </div>
      <div className="grid__item pos-69">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/in-5.svg)` }} />
      </div>
      <div className="grid__item pos-70">
        <div className="grid__item-img" style={{ backgroundImage: `url(${config.cdnImgUrl}bg-icons/fb-9.svg)` }} />
      </div>
    </div>
    <svg className="cursor" width="80" height="80" viewBox="0 0 80 80">
      <circle className="cursor__inner" cx="40" cy="40" r="20" />
    </svg>
  </div>
);
