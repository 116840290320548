import { delay, put, takeLatest } from 'redux-saga/effects';

import { API, NODE_API, apiCall, defaultHeader } from 'utils/helpers';
import { logoutRequest } from 'actions';
import { commonApiTypes } from 'types';
import { AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE, ALERT_BOX_TYPES, API_TYPES } from 'utils/constants';
import { AI_IMAGE_GENERATION_REQUEST, AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST, AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID } from 'actions/action-types';
import {
  getAIImageGenerationImagesFailure,
  getAIImageGenerationImagesResponse,
  getAIStableDiffusionImageGenerationFailure,
  getAIStableDiffusionImageGenerationRequestById,
  getAIStableDiffusionImageGenerationResponse,
  getAIStableDiffusionImageGenerationResponseById,
  resetAIStableDiffusionImageGenerationImages
} from 'actions/modules/ai-image-generation';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

let pollingCount = 0;

function* sendGetAIImageGenerationRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const updatedPayload = { ...payload };
    if (updatedPayload?.onSuccess) {
      delete updatedPayload?.onSuccess;
    }
    const response = yield apiCall({ headers, data: updatedPayload, ...NODE_API.aiImageGeneration, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIImageGenerationImagesResponse(response.data.data));
      if (payload?.onSuccess) {
        payload.onSuccess();
      }
    } else {
      yield put(getAIImageGenerationImagesFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE);
    yield put(getAIImageGenerationImagesFailure(AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE));
  }
}

function* sendGetAIStableDiffusionImageGenerationRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = payload;
    const response = yield apiCall({ headers, data, ...API.aiStableDiffusionImageGenerations });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (response?.data?.automatic_task?.id) {
        yield put(getAIStableDiffusionImageGenerationRequestById({ Id: response.data.automatic_task.id }));
      }
      yield put(getAIStableDiffusionImageGenerationResponse(response.data));
    } else {
      yield put(getAIStableDiffusionImageGenerationFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE);
    yield put(getAIImageGenerationImagesFailure(AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE));
  }
}

function* sendGetAIStableDiffusionImageGenerationResponseById({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiPath = API.getAIStableDiffusionImageGenerations.apiPath.replace(':id', payload.Id);
    const response = yield apiCall({
      headers,
      apiPath,
      action: API.getAIStableDiffusionImageGenerations.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (response?.data?.automatic_task.id && !response?.data?.automatic_task?.photo_url) {
        if (pollingCount === 5 && response?.data?.automatic_task?.agent_scheduler_task_id === null) {
          alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE);
          yield put(getAIStableDiffusionImageGenerationFailure(response.data.message));
          pollingCount = 0;
        } else {
          yield delay(1000);
          if (pollingCount < 6) {
            pollingCount++;
          }
          yield put(getAIStableDiffusionImageGenerationRequestById({ Id: response?.data?.automatic_task?.id || payload.Id }));
        }
      } else {
        yield put(getAIStableDiffusionImageGenerationResponseById(response.data));
        yield put(resetAIStableDiffusionImageGenerationImages());
      }
    } else {
      yield put(getAIStableDiffusionImageGenerationFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE);
    yield put(getAIImageGenerationImagesFailure(AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE));
  }
}

export function* takeAIImageGenerationRequest() {
  yield takeLatest(AI_IMAGE_GENERATION_REQUEST, sendGetAIImageGenerationRequest);
  yield takeLatest(AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST, sendGetAIStableDiffusionImageGenerationRequest);
  yield takeLatest(AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID, sendGetAIStableDiffusionImageGenerationResponseById);
}
