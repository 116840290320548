import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment-timezone';

import { IStore, commonProps } from 'types';
import { useParamsDeconstructor } from '../use-params-deconstructor';
import { IReviewsLocationObj } from 'types/reviews/reviews-container';
import { ValidQueryParams } from 'analytics/utils';

export const useParamsValidator = (validQueryParams: any, validParamsObject: commonProps.ICommonObj<string[]> = {}, isValidate = true) => {
  const { queryParams } = useParamsDeconstructor();
  const navigate = useNavigate();

  const accountSelectionList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  useEffect(() => {
    if (Object.keys(queryParams).length > 0 && isValidate) {
      const queryParamsClone = { ...queryParams };
      const searObjList = Object.keys(queryParams);
      const validParmsValuesArray = Object.values(validQueryParams);

      const isDateRangeFilterValid = validParmsValuesArray.includes(ValidQueryParams.START_DATE);
      const isLocationFitlerFilterValid = validParmsValuesArray.includes(ValidQueryParams.LOCATION_LIST_ID);
      const isHubFitlerFilterValid = validParmsValuesArray.includes(ValidQueryParams.HUB_ID);
      const isLocationListFitlerFilterValid = validParmsValuesArray.includes(ValidQueryParams.LOCATION_ID);
      const isLocationDropDownValid = isLocationFitlerFilterValid || isHubFitlerFilterValid || isLocationListFitlerFilterValid;
      // const isAllHubs = validParmsValuesArray.includes('all_hubs');

      const inValidKeys = searObjList.filter((it: any) => !Object.values(validQueryParams).includes(it));
      const isValidDate = queryParams.start_date && queryParams.end_date && isDateRangeFilterValid ? moment(queryParams.end_date).isSameOrAfter(queryParams.start_date) : true;
      const hubList = searObjList.filter((it: any) => [validQueryParams.HUB_ID, validQueryParams.LOCATION_ID, validQueryParams.LOCATION_LIST_ID].includes(it)).length;

      const inValidKeyValuePair = Object.keys(validParamsObject).reduce((acc: string[], curr) => {
        const validParamsArray = validParamsObject[curr];
        if (searObjList.includes(curr) && !validParamsArray.some((it) => queryParams[curr]?.split(',').includes(it))) {
          acc = [...acc, curr];
        }
        return acc;
      }, []);

      let hubObj: IReviewsLocationObj | undefined;
      if (queryParams.hub_id && isHubFitlerFilterValid) {
        const hubList = accountSelectionList?.hubList;
        hubObj = hubList?.find((it) => it.id === +queryParams.hub_id);
      } else if (queryParams.location_list_id && isLocationFitlerFilterValid) {
        const locationList = accountSelectionList?.locationList;
        hubObj = locationList?.find((it) => it.id === +queryParams.location_list_id);
      } else if (queryParams.location_id && isLocationListFitlerFilterValid) {
        const onlyLocationList = accountSelectionList?.onlyLocationList;
        hubObj = onlyLocationList?.find((it) => it.id === +queryParams.location_id);
      }

      const isHubValid = Object.keys(accountSelectionList)?.length ? (isLocationDropDownValid && hubList === 1 ? hubObj : hubList === 0) : true;

      if (!isValidDate) {
        queryParamsClone.end_date = queryParamsClone.start_date;
      }
      if (!isHubValid) {
        delete queryParamsClone.location_list_id;
        delete queryParamsClone.hub_id;
        delete queryParamsClone.location_id;
      }
      if (inValidKeys.length) {
        inValidKeys.forEach((it) => {
          delete queryParamsClone[it];
        });
      }
      if (inValidKeyValuePair.length) {
        inValidKeyValuePair.forEach((it) => {
          delete queryParamsClone[it];
        });
      }
      if (!(isValidDate && inValidKeys.length && isHubValid && inValidKeyValuePair.length)) {
        navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` }, { state: {}, replace: true });
      }
    }
  }, [queryParams, accountSelectionList, isValidate]); // eslint-disable-line
};
