import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ModalPopup, Toastifier, DropZoneComponent, ConfirmationModal } from 'revv/components';
import { getInitials } from 'revv/utils';
import { activeFilterOptions } from 'revv/actions';
import { IStore } from 'types';
import { config } from 'config';
import { Loading } from 'components';

interface ILocationModal {
  closeModel: (value?: string) => void;
  isModalOpen: boolean;
  addUpload: (file: File) => void;
  errorMsg: string;
  successMsg: string;
  confirmClassVariant?: string;
  isFetching: boolean;
  sampleFileURL: string;
  title: string;
}

export const UploadModal = ({ closeModel, isModalOpen, addUpload, successMsg, confirmClassVariant, errorMsg, isFetching, sampleFileURL, title }: ILocationModal) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>(null);
  const activeUserDetail = useSelector((state: IStore) => state.revv.user.userDetail);

  useEffect(() => {
    return () => {
      dispatch(activeFilterOptions(''));
    };
  }, [dispatch]);

  return (
    <>
      <ModalPopup
        show={isModalOpen}
        fullView
        centered
        onHide={() => {
          closeModel('close');
        }}
        className="npsp-modal__main non-psp-location-upload__modal non-psp__modal"
        renderHeader={() => (
          <div>
            <span className="upload__title">Import {title} File</span>
            <div
              className="mod-close__icon cur-pointer"
              onClick={() => {
                closeModel('close');
                setFile(null);
              }}
            >
              <img src={`${config.cdnRevvImgUrl}/modal-close.svg`} alt="close-icon" className="non-psp-mod-close" />
            </div>
          </div>
        )}
        renderBody={() => (
          <div>
            <DropZoneComponent
              displayMsg="File"
              classVariant={`drag-drop__card d-flex align-items-center ${file ? 'custom-disabled' : ''}`}
              uploadImageList={(values: any) => {
                if (values) {
                  setFile(values[0]);
                }
              }}
            />
            <div className="upload-file__card d-flex">
              <div className="upload-sample__file loc-inner__card">
                <div className="bg-sample__file bg__file d-flex justify-content-center align-items-center">
                  <div className="text-center bg-inner__file">
                    <img src={`${config.cdnRevvImgUrl}/csv-icon.svg`} alt="csv-icon" />
                    <span className="csv__text">Download Sample Here </span>
                  </div>
                </div>
                <div className="sample-file__footer d-flex justify-content-between align-items-center">
                  <span className="upload-file__text">Sample_File.Csv</span>
                  <a href={sampleFileURL}>
                    <img src={`${config.cdnRevvImgUrl}/download.svg`} alt="download-icon" />
                  </a>
                </div>
              </div>
              {file ? (
                <div className="upload-loc__file loc-inner__card">
                  <div className="bg-loc__file bg__file d-flex justify-content-center align-items-center text-center">
                    <div className="bg-loc-inner__file">
                      {isFetching ? <Loading /> : null}
                      <div className="upload-file__text upload-browse__file mt-4" title={file?.name}>
                        {file?.name}
                      </div>
                    </div>
                  </div>
                  <div className="loc-file__footer d-flex justify-content-between align-items-center">
                    <div className="loc-file-footer__info d-flex align-items-center">
                      <span className="upload-file-info__icon">{getInitials(activeUserDetail?.firstName, activeUserDetail?.lastName)}</span>
                      <span className="upload-file__text loc-file__csv" title={file?.name}>
                        {file?.name}
                      </span>
                    </div>
                    <div
                      className="upload-close__red"
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <img src={`${config.cdnRevvImgUrl}/close-red.svg`} alt="close-icon" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        renderFooter={() => (
          <div className="upload-modal__btn d-flex justify-content-end">
            <div className="upload-cancel__btn">
              <Button
                variant="light"
                className="upload-ac__btn upload-foot__cancel"
                onClick={() => {
                  closeModel('close');
                  setFile(null);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="upload-add__btn upload-ac__btn">
              <Button
                className={`upload-ac__btn upload-foot__add ${!file ? 'custom-disabled' : ''} `}
                onClick={() => {
                  if (file) {
                    addUpload(file);
                  }
                }}
              >
                Add
              </Button>
            </div>
            <Toastifier
              isEnabled={!!errorMsg}
              notificationMsg={errorMsg}
              setToastifier={() => {
                closeModel();
              }}
              toastClr="dark"
            />
          </div>
        )}
      />
      <ConfirmationModal
        classVariant={confirmClassVariant}
        isModalEnabled={!!successMsg}
        setModalState={() => {
          closeModel();
          setFile(null);
        }}
      />
    </>
  );
};
