import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { commonProps, fbAdsApiTypes, fbAdsReducerTypes } from 'types';

// GET FB ADS TARGETING CHOICES
export const getFBAdsTargetingChoicesRequest = (payload: commonProps.IAccountSwitchedObj) => createAction(actionTypes.GET_FB_ADS_TARGETING_CHOICES_REQUEST, payload);
export const getFBAdsTargetingChoicesResponse = (data: null | fbAdsApiTypes.IFBAdsFranChoicesResponse | fbAdsApiTypes.IFBAdsAccChoicesResponse) =>
  createAction(actionTypes.GET_FB_ADS_TARGETING_CHOICES_RESPONSE, data);
export const getFBAdsTargetingChoicesFailure = (error: null | string) => createAction(actionTypes.GET_FB_ADS_TARGETING_CHOICES_FAILURE, error);

// DISCONNECT FB ADS TARGETING
export const disconnectFBAdsTargetingChoicesRequest = (payload: commonProps.IAccountSwitchedObj) => createAction(actionTypes.DISCONNECT_FB_ADS_TARGETING_CHOICES_REQUEST, payload);
export const disconnectFBAdsTargetingChoicesResponse = (data: null) => createAction(actionTypes.DISCONNECT_FB_ADS_TARGETING_CHOICES_RESPONSE, data);
export const disconnectFBAdsTargetingChoicesFailure = (error: null | string) => createAction(actionTypes.DISCONNECT_FB_ADS_TARGETING_CHOICES_FAILURE, error);

// UPDATE ACC FB ADS TARGETING DEFAULT CONFIG
export const updateAccFBDefaultConfigRequest = (payload: fbAdsApiTypes.IAccFBDefaultConfigReq) => createAction(actionTypes.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_REQUEST, payload);
export const updateAccFBDefaultConfigResponse = (data: null | string) => createAction(actionTypes.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_RESPONSE, data);
export const updateAccFBDefaultConfigFailure = (error: null | string) => createAction(actionTypes.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_FAILURE, error);

// ACCOUNTS - TARGETING INTERESTS
export const getAccTargetingInterestsListRequest = (payload: fbAdsApiTypes.IAccTargetingInterestsReq) => createAction(actionTypes.GET_ACC_TARGETING_INTERESTS_LIST_REQUEST, payload);
export const getAccTargetingInterestsListResponse = (data: fbAdsApiTypes.IAccTargetingInterestsObj[]) => createAction(actionTypes.GET_ACC_TARGETING_INTERESTS_LIST_RESPONSE, data);
export const getAccTargetingInterestsListFailure = (error: null | string) => createAction(actionTypes.GET_ACC_TARGETING_INTERESTS_LIST_FAILURE, error);

// ACCOUNTS - GET FACEBOOK AD ACCOUNT DATA
export const getAccFacebookAdAccountDataRequest = (payload: string) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_REQUEST, payload);
export const getAccFacebookAdAccountDataResponse = (data: fbAdsApiTypes.IFBAdsFacebookUserCredential) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_RESPONSE, data);
export const getAccFacebookAdAccountDataFailure = (error: null | string) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_FAILURE, error);

// ACCOUNTS - GET FACEBOOK AD ACCOUNT ID
export const getAccFacebookAdAccountIdRequest = (payload: string) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_REQUEST, payload);
export const getAccFacebookAdAccountIdResponse = (data: fbAdsApiTypes.IFBAdsFacebookAdAccount[]) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_RESPONSE, data);
export const getAccFacebookAdAccountIdFailure = (error: null | string) => createAction(actionTypes.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_FAILURE, error);

// ACCOUNTS - UPDATE FACEBOOK AD ACCOUNT ID
export const updateAccFacebookAdAccountIdRequest = (payload: { accountId: number; credentialId: string; adAccountId: string; userOwnership: string; callback?: () => void }) =>
  createAction(actionTypes.UPDATE_FACEBOOK_AD_ACCOUNT_REQUEST, payload);
export const updateAccFacebookAdAccountIdFailure = (payload: string) => createAction(actionTypes.UPDATE_FACEBOOK_AD_ACCOUNT_FAILURE, payload);
export const updateAccFacebookAdAccountIdResponse = () => createAction(actionTypes.UPDATE_FACEBOOK_AD_ACCOUNT_RESPONSE);

// ACCOUNTS - ADD FACEBOOK AD PREVIEW
export const addFacebookAdPreviewRequest = (payload: fbAdsApiTypes.IFacebookAdPreviewReq) => createAction(actionTypes.ADD_FACEBOOK_AD_PREVIEW_REQUEST, payload);
export const addFacebookAdPreviewResponse = (data: fbAdsApiTypes.IFacebookAdPreviewResponse) => createAction(actionTypes.ADD_FACEBOOK_AD_PREVIEW_RESPONSE, data);
export const addFacebookAdPreviewFailure = (error: null | string) => createAction(actionTypes.ADD_FACEBOOK_AD_PREVIEW_FAILURE, error);

// FRANCHISOR - GET AVERAGE LOCATIONS SPEND
export const getAverageLocationsSpendRequest = (payload: fbAdsApiTypes.IAverageLocationsSpendRequest) => createAction(actionTypes.GET_AVERAGE_LOCATIONS_SPEND_REQUEST, payload);
export const getAverageLocationsSpendResponse = (data: fbAdsApiTypes.IAverageLocationsSpendResponse) => createAction(actionTypes.GET_AVERAGE_LOCATIONS_SPEND_RESPONSE, data);
export const getAverageLocationsSpendFailure = (error: null | string) => createAction(actionTypes.GET_AVERAGE_LOCATIONS_SPEND_FAILURE, error);

// FRANCHISOR - SET CORPORATE POST BOOST DATA
export const setCorporatePostBoostData = (error: fbAdsReducerTypes.ICorporatePostBoostObj) => createAction(actionTypes.SET_CORPORATE_POST_BOOST_DATA, error);

// ACCOUNTS - UPDATE FB BOOST CONFIG DATA
export const updateFBBoostConfigData = (data: null | fbAdsReducerTypes.IUpdateFacebookBoostConfig) => createAction(actionTypes.UPDATE_FB_BOOST_CONFIG_DATA, data);

// ACCOUNTS - SET FB POST DETAILS
export const setFBPostDetails = (data: null | fbAdsReducerTypes.IFacebookPostDetailsObj) => createAction(actionTypes.SET_FB_POST_DETAILS, data);

// FB ADS TARGETING RESET ALL
export const fbAdsInterestListFailMsgReset = () => createAction(actionTypes.FB_ADS_INTEREST_LIST_FAIL_MSG_RESET);
export const fbAdsCreatorBoostDataReset = () => createAction(actionTypes.FB_ADS_CREATOR_BOOST_DATA_RESET);
export const fbAdsBoostDataResetAll = () => createAction(actionTypes.FB_ADS_BOOST_DATA_RESET_ALL);

const FB_ADS_TARGETING_ACTIONS = {
  getFBAdsTargetingChoicesRequest,
  getFBAdsTargetingChoicesResponse,
  getFBAdsTargetingChoicesFailure,
  disconnectFBAdsTargetingChoicesRequest,
  disconnectFBAdsTargetingChoicesResponse,
  disconnectFBAdsTargetingChoicesFailure,
  updateAccFBDefaultConfigRequest,
  updateAccFBDefaultConfigResponse,
  updateAccFBDefaultConfigFailure,
  getAccTargetingInterestsListRequest,
  getAccTargetingInterestsListResponse,
  getAccTargetingInterestsListFailure,
  getAccFacebookAdAccountDataRequest,
  getAccFacebookAdAccountDataResponse,
  getAccFacebookAdAccountDataFailure,
  getAccFacebookAdAccountIdRequest,
  getAccFacebookAdAccountIdResponse,
  getAccFacebookAdAccountIdFailure,
  updateAccFacebookAdAccountIdRequest,
  updateAccFacebookAdAccountIdFailure,
  updateAccFacebookAdAccountIdResponse,
  addFacebookAdPreviewRequest,
  addFacebookAdPreviewResponse,
  addFacebookAdPreviewFailure,
  getAverageLocationsSpendRequest,
  getAverageLocationsSpendResponse,
  getAverageLocationsSpendFailure,
  setCorporatePostBoostData,
  updateFBBoostConfigData,
  setFBPostDetails,
  fbAdsInterestListFailMsgReset,
  fbAdsCreatorBoostDataReset,
  fbAdsBoostDataResetAll
};

export type FBAdsTargetingActions = ActionsUnion<typeof FB_ADS_TARGETING_ACTIONS>;
