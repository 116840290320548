import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import {
  ALERT_BOX_TYPES,
  EXPORT_DOWNLOAD_STARTED_MSG,
  USER_OWNERSHIP,
  INITIAL_TARGETING_LOCATION_FILTER_DATA,
  LOCATION,
  BUTTON_PROPS,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  ITargettingValidQueryParams
} from 'utils/constants';
import { useParamsDeconstructor, useAccountSwitcherData, useParamsValidator } from 'utils/hooks';
import { CommonFilter, alertBoxCall } from 'components';
import { handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const TargetingFilter = React.memo(() => {
  const navigate = useNavigate();

  const { queryParams, filter } = useParamsDeconstructor(INITIAL_TARGETING_LOCATION_FILTER_DATA);
  const { id, userOwnership } = useAccountSwitcherData();
  const isDefaultFilter = Boolean(!queryParams?.hub_id && !queryParams?.location_list_id && !queryParams?.location_id);

  useParamsValidator({ ...CommonValidQueryParams, ...ITargettingValidQueryParams }, COMMON_VALID_QUERY_PARAMS_OBJ);

  const targetFilter = isDefaultFilter
    ? {
        text: queryParams?.text || '',
        locationFilterObj: INITIAL_TARGETING_LOCATION_FILTER_DATA
      }
    : {
        text: queryParams?.text || '',
        locationFilterObj: filter.locationFilterObj
      };

  const data = useSelector((state: IStore) => state.targeting.data);

  const formAndDownloadCSV = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    const configurationList = data?.franchisor_facebook_ad_account_configuration.account_configurations || [];
    const reachArr = configurationList.map((it) => (it.potential_reach ? it.potential_reach : 0));
    const rows = [['Location Name/ID', 'Location', 'Age', 'Gender', 'Radius', `Estimated Reach(${reachArr.reduce((acc, curr) => acc + curr)})`]];
    if (configurationList?.length > 0) {
      let targetingConfigList = configurationList;
      if (targetFilter?.text) {
        targetingConfigList = configurationList.filter((it) => it.account_name.toLowerCase().indexOf(targetFilter?.text.toLowerCase()) > -1);
      }
      if (targetFilter?.locationFilterObj?.type === LOCATION) {
        targetingConfigList = configurationList.filter((it) => it.account_id === targetFilter?.locationFilterObj?.id);
      }
      targetingConfigList.forEach((obj) => {
        rows.push([
          `${obj.account_name}(${obj.account_id})`,
          obj.locations?.length > 0 ? obj.locations.map((it) => it).join(',') : '---',
          obj.max_age && obj.min_age ? `${obj.min_age} - ${obj.max_age}` : obj.min_age ? `${obj.min_age} +` : obj.max_age ? `${obj.max_age} -` : '18 +',
          obj.gender === 'male' ? 'M' : obj.gender === 'female' ? 'F' : 'All',
          `${Math.round(obj.home_coordinate?.radius_in_meters || 1 * 0.000621371)} +`,
          String(obj.potential_reach || 'N / A')
        ]);
      });
    }
    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const anchor = document.createElement('a');
    anchor.href = encodedUri;
    anchor.style.display = 'none';
    anchor.download = `TargetingList.csv`;
    document.body.append(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
    if (isClear) {
      if (queryParams?.text) queryParamsClone.text = queryParams?.text || '';
    }
    if (queryParams?.text) queryParamsClone.text = queryParams?.text;
    else delete queryParamsClone.text;
    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  return (
    <CommonFilter
      filter={targetFilter}
      isDateRangeFilterEnable={false}
      handleChange={handleChange}
      handleExportCsv={formAndDownloadCSV}
      primaryButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
          isDisable: !data?.franchisor_facebook_ad_account_configuration.account_configurations.length
        },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: Boolean(!queryParams?.hub_id && !queryParams?.location_list_id && !queryParams?.location_id)
        }
      ]}
      analyticsPlatformFilter={[]}
      sectionVariant="tm-logins tm-logins__main targeting-filter"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
});
