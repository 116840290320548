import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { couponsApiTypes, couponsReducerTypes, mgApiTypes } from 'types';

// GET COUPONS LIST
export const getCouponsListRequest = (payload: couponsApiTypes.ICouponsListRequest) => createAction(actionTypes.GET_COUPONS_LIST_REQUEST, payload);
export const getCouponsListResponse = (data: couponsApiTypes.ICouponsListResponse[]) => createAction(actionTypes.GET_COUPONS_LIST_RESPONSE, data);
export const getCouponsListFailure = (error: null | string) => createAction(actionTypes.GET_COUPONS_LIST_FAILURE, error);

// ADD COUPON REQUEST
export const addOrEditCouponRequest = (payload: couponsApiTypes.IAddCouponReqPayload) => createAction(actionTypes.ADD_OR_EDIT_COUPON_REQUEST, payload);
export const addOrEditCouponResponse = (data: null | string) => createAction(actionTypes.ADD_OR_EDIT_COUPON_RESPONSE, data);
export const addOrEditCouponFailure = (error: null | string) => createAction(actionTypes.ADD_OR_EDIT_COUPON_FAILURE, error);

// GET COUPON DETAIL VIEW REUEST
export const getCouponDetailViewRequest = (payload: string) => createAction(actionTypes.GET_COUPON_DETAIL_VIEW_REQUEST, payload);
export const getCouponDetailViewResponse = (data: couponsApiTypes.IAddCouponReq) => createAction(actionTypes.GET_COUPON_DETAIL_VIEW_RESPONSE, data);
export const getCouponDetailViewFailure = (error: null | string) => createAction(actionTypes.GET_COUPON_DETAIL_VIEW_FAILURE, error);

// DELETE COUPON
export const deleteCouponRequest = (payload: string) => createAction(actionTypes.DELETE_COUPON_REQUEST, payload);
export const deleteCouponResponse = (data: null) => createAction(actionTypes.DELETE_COUPON_RESPONSE, data);
export const deleteCouponFailure = (error: null | string) => createAction(actionTypes.DELETE_COUPON_FAILURE, error);

// COUPON - UPLOAD CLOUDINARY MEDIA
export const cpUploadCloudMediaRequest = (payload: mgApiTypes.IMGUploadCloudMediaRequest) => createAction(actionTypes.CP_CLOUD_MEDIA_UPLOAD_REQUEST, payload);
export const cpUploadCloudMediaResponse = (payload: null | string) => createAction(actionTypes.CP_CLOUD_MEDIA_UPLOAD_RESPONSE, payload);
export const cpUploadCloudMediaReqFailure = (payload: null | string) => createAction(actionTypes.CP_CLOUD_MEDIA_UPLOAD_FAILURE, payload);

// COUPONS SET NEW COUPON OBJ
export const couponsSetNewCouponObj = (payload: null | couponsReducerTypes.INewCouponObj) => createAction(actionTypes.COUPONS_SET_NEW_COUPON_OBJ, payload);

// COUPONS - SET CURRENT PAGE
export const couponsSetUpdateObj = (payload: couponsReducerTypes.ICouponUpdateObj) => createAction(actionTypes.COUPONS_SET_UPDATE_OBJ, payload);

// COUPONS RESET
export const couponsDetailDataReset = () => createAction(actionTypes.COUPONS_DETAIL_DATA_RESET);
export const couponsMessagesReset = () => createAction(actionTypes.COUPONS_MESSAGES_RESET);
export const couponsResetAll = () => createAction(actionTypes.COUPONS_RESET_ALL);

const COUPONS_ACTIONS = {
  getCouponsListRequest,
  getCouponsListResponse,
  getCouponsListFailure,
  addOrEditCouponRequest,
  addOrEditCouponResponse,
  addOrEditCouponFailure,
  getCouponDetailViewRequest,
  getCouponDetailViewResponse,
  getCouponDetailViewFailure,
  deleteCouponRequest,
  deleteCouponResponse,
  deleteCouponFailure,
  cpUploadCloudMediaRequest,
  cpUploadCloudMediaResponse,
  cpUploadCloudMediaReqFailure,
  couponsSetUpdateObj,
  couponsSetNewCouponObj,
  couponsDetailDataReset,
  couponsMessagesReset,
  couponsResetAll
};

export type CouponsActions = ActionsUnion<typeof COUPONS_ACTIONS>;
