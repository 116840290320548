import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { fbAdsReducerTypes } from 'types';
import { FBAdsTargetingActions } from 'actions';

const initialState: fbAdsReducerTypes.IFBAdsTargetingReducer = {
  isFetching: true,
  isInterestsFetching: false,
  error: null,
  fbTargetingChoice: null,
  accTargetingInterests: [],
  targetSuccessMsg: null,
  isSaveConfigChanges: false,
  accountUserCredential: {
    isAdAccountFetching: false,
    isUserDataFetching: false,
    userUuid: null,
    facebookAccountDetail: null,
    adAccountList: []
  },
  adAccountFetching: true,
  adAccountUpdateError: '',
  interestListFailureMsg: null,
  facebookAdPreviewObj: {
    isPreviewFetching: false,
    adPreview: null
  },
  facebookBoostConfig: null,
  facebookPostDetails: null,
  averageLocationsSpend: {
    isFetching: false,
    data: [],
    actionType: null,
    errorMsg: null
  },
  corporatePostBoostObj: {
    isBoostEnabled: false,
    lifetimeSpend: 0,
    duration: 0,
    boostType: '',
    facebookDarkPost: false
  }
};

export const fbAdsTargetingReducer: Reducer<fbAdsReducerTypes.IFBAdsTargetingReducer, FBAdsTargetingActions> = (
  state: fbAdsReducerTypes.IFBAdsTargetingReducer = initialState,
  action: FBAdsTargetingActions
): fbAdsReducerTypes.IFBAdsTargetingReducer => {
  switch (action.type) {
    // GET FB ADS TARGETING CHOICES
    case types.GET_FB_ADS_TARGETING_CHOICES_REQUEST:
      return {
        ...state,
        isFetching: action.payload.initialLoad || false
      };
    case types.GET_FB_ADS_TARGETING_CHOICES_RESPONSE:
      return {
        ...state,
        isFetching: false,
        fbTargetingChoice: action.payload || null
      };
    case types.GET_FB_ADS_TARGETING_CHOICES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // DISCONNECT FB ADS TARGETING CHOICES
    case types.DISCONNECT_FB_ADS_TARGETING_CHOICES_REQUEST:
      return {
        ...state
      };
    case types.DISCONNECT_FB_ADS_TARGETING_CHOICES_RESPONSE:
      return {
        ...state,
        fbTargetingChoice: action.payload,
        accTargetingInterests: initialState.accTargetingInterests,
        facebookBoostConfig: initialState.facebookBoostConfig
      };
    case types.DISCONNECT_FB_ADS_TARGETING_CHOICES_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // ACCOUNT TARGETING INTERESTS
    case types.GET_ACC_TARGETING_INTERESTS_LIST_REQUEST:
      return {
        ...state,
        isInterestsFetching: true
      };
    case types.GET_ACC_TARGETING_INTERESTS_LIST_RESPONSE:
      return {
        ...state,
        isInterestsFetching: false,
        accTargetingInterests: action.payload
      };
    case types.GET_ACC_TARGETING_INTERESTS_LIST_FAILURE:
      return {
        ...state,
        isInterestsFetching: false,
        interestListFailureMsg: action.payload || 'Unexpected error'
      };
    // UPDATE ACC FB DEFAULT CONFIG
    case types.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_REQUEST:
      return {
        ...state,
        isSaveConfigChanges: true
      };
    case types.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_RESPONSE:
      return {
        ...state,
        isSaveConfigChanges: false,
        targetSuccessMsg: action.payload
      };
    case types.UPDATE_ACC_FB_TARGET_DEFAULT_CONFIG_FAILURE:
      return {
        ...state,
        isSaveConfigChanges: false,
        targetSuccessMsg: null,
        error: action.payload || 'Unexpected error'
      };
    // ACCOUNTS - GET FACEBOOK AD ACCOUNT DATA
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_REQUEST:
      return {
        ...state,
        accountUserCredential: {
          ...state.accountUserCredential,
          isUserDataFetching: true
        }
      };
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_RESPONSE:
      return {
        ...state,
        accountUserCredential: {
          ...state.accountUserCredential,
          isUserDataFetching: false,
          userUuid: null,
          facebookAccountDetail: action.payload
        }
      };
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_DATA_FAILURE:
      return {
        ...state,
        accountUserCredential: {
          ...state.accountUserCredential,
          isUserDataFetching: false,
          facebookAccountDetail: null
        },
        error: action.payload || 'Unexpected error'
      };
    // ACCOUNTS - GET FACEBOOK AD ACCOUNT ID
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_REQUEST:
      return {
        ...state
      };
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_RESPONSE:
      return {
        ...state,
        accountUserCredential: {
          ...state.accountUserCredential,
          isUserDataFetching: false,
          userUuid: null,
          adAccountList: action.payload
        }
      };
    case types.GET_ACC_FACEBOOK_AD_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        accountUserCredential: {
          ...state.accountUserCredential,
          isUserDataFetching: false,
          adAccountList: []
        },
        error: action.payload || 'Unexpected error'
      };
    case types.UPDATE_FACEBOOK_AD_ACCOUNT_REQUEST:
      return { ...state, adAccountFetching: true };
    case types.UPDATE_FACEBOOK_AD_ACCOUNT_FAILURE:
      return { ...state, adAccountUpdateError: action.payload, adAccountFetching: false };
    case types.UPDATE_FACEBOOK_AD_ACCOUNT_RESPONSE:
      return { ...state, adAccountUpdateError: '', adAccountFetching: false };
    case types.ADD_FACEBOOK_AD_PREVIEW_REQUEST:
      return {
        ...state,
        facebookAdPreviewObj: {
          isPreviewFetching: true,
          adPreview: null
        }
      };
    case types.ADD_FACEBOOK_AD_PREVIEW_RESPONSE:
      return {
        ...state,
        facebookAdPreviewObj: {
          isPreviewFetching: false,
          adPreview: {
            previewId: action.payload.id,
            cloudinaryResourceType: action.payload.scheduled_post_params.cloudinary_resource_type,
            cloudinaryVideoId: action.payload.scheduled_post_params.cloudinary_video_id || null,
            facebookCallToActionLink: action.payload.scheduled_post_params.facebook_call_to_action_link,
            facebookCallToActionType: action.payload.scheduled_post_params.facebook_call_to_action_type,
            facebookText: action.payload.scheduled_post_params.facebook_text,
            linkDescription: action.payload.scheduled_post_params.link_description,
            linkTitle: action.payload.scheduled_post_params.link_title,
            photoUrls: action.payload.scheduled_post_params.photo_urls
          }
        }
      };
    case types.ADD_FACEBOOK_AD_PREVIEW_FAILURE:
      return {
        ...state,
        facebookAdPreviewObj: initialState.facebookAdPreviewObj,
        error: action.payload || 'Unexpected error'
      };
    // FRANCHISOR - GET AVERAGE LOCATIONS SPEND
    case types.GET_AVERAGE_LOCATIONS_SPEND_REQUEST:
      return {
        ...state,
        averageLocationsSpend: {
          ...state.averageLocationsSpend,
          isFetching: true
        }
      };
    case types.GET_AVERAGE_LOCATIONS_SPEND_RESPONSE:
      return {
        ...state,
        averageLocationsSpend: {
          ...state.averageLocationsSpend,
          isFetching: false,
          data: action.payload.data.length ? action.payload.data.sort((a, b) => a.account_name.localeCompare(b.account_name)) : [],
          actionType: action.payload.actionType || null
        }
      };
    case types.GET_AVERAGE_LOCATIONS_SPEND_FAILURE:
      return {
        ...state,
        averageLocationsSpend: {
          ...state.averageLocationsSpend,
          isFetching: false,
          errorMsg: action.payload
        }
      };
    case types.SET_CORPORATE_POST_BOOST_DATA:
      return {
        ...state,
        corporatePostBoostObj: action.payload
      };
    case types.UPDATE_FB_BOOST_CONFIG_DATA:
      return {
        ...state,
        facebookBoostConfig: action.payload
      };
    case types.SET_FB_POST_DETAILS:
      return {
        ...state,
        facebookPostDetails: action.payload
      };
    // FB ADS TARGETING RESET ALL
    case types.FB_ADS_INTEREST_LIST_FAIL_MSG_RESET:
      return {
        ...state,
        interestListFailureMsg: initialState.interestListFailureMsg
      };
    case types.FB_ADS_CREATOR_BOOST_DATA_RESET:
      return {
        ...state,
        averageLocationsSpend: initialState.averageLocationsSpend,
        corporatePostBoostObj: initialState.corporatePostBoostObj,
        facebookAdPreviewObj: initialState.facebookAdPreviewObj,
        facebookBoostConfig: initialState.facebookBoostConfig
      };
    case types.FB_ADS_BOOST_DATA_RESET_ALL:
      return {
        ...state,
        facebookAdPreviewObj: initialState.facebookAdPreviewObj,
        facebookBoostConfig: initialState.facebookBoostConfig,
        facebookPostDetails: initialState.facebookPostDetails,
        accTargetingInterests: initialState.accTargetingInterests
      };
    default:
      return state;
  }
};
