import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showHideFilterButton } from 'actions';

export const useHideFilter = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line

  return useEffect(() => {
    dispatch(showHideFilterButton(false));
    return () => {
      dispatch(showHideFilterButton(true));
    };
  }, [dispatch]);
};
