import { mgApiTypes, mgReducerTypes } from 'types/media-gallery';

/** Media Gallery - Get favourite tags based on the searched key */
export const getMGFavTagsList = (tagList: mgApiTypes.IMGTagsResponseData[]) => {
  return tagList.reduce((acc: mgReducerTypes.IMediaGalleryTagsData[], curr, idx) => {
    if (curr.tag_name) {
      acc = [...acc, { id: idx + 1, name: curr.tag_name, favourite: curr.favorite }];
    }
    return acc;
  }, []);
};
