import { Reducer } from 'redux';

import * as types from 'analytics/actions/action-types';
import { ReviewAnalyticsActions } from 'analytics/actions';
import { IReviewAnalyticsReducer } from 'analytics/types';
import { REVIEW_ANALYTICS_STATS, REVIEW_ANALYTICS_PLATFORM_FILTER, PERCENT_REPLIED_IN_NUMBER } from 'analytics/utils';
import { getKeywordData, keywordCAChartDateGrouping, reviewsTableDateGrouping } from 'analytics/utils/helpers';

const initialState: IReviewAnalyticsReducer = {
  isReviewAnalyticsFetching: false,
  isError: false,
  error: null,
  data: {},
  isReviewAnalyticsSummaryFetching: false,
  keyWordPageCount: 1,
  isKeyWordDataFetching: false,
  keyWordCloudData: [],
  keyWordsData: [],
  isKeywordAscending: false,
  groupedTableData: { headers: [], data: [], summary: {}, tickInterval: undefined },
  isUniqueKeywordDataFetching: false,
  uniqueKeyWord: null,
  uniqueKeyWordData: {},
  keywordsCategoryList: [],
  keywordsCategory: null,
  isKeywordsCategoryFetching: false,
  isKeywordsCategoryListFetching: false,
  allKeywordsList: [],
  showCategoryModal: { isShowModal: false, type: '' },
  isAllKeywordsFetching: false,
  isKeywordsCategoryAnalyticsFetching: false,
  keywordsCAChartData: [],
  keywordsCategoryAnalytics: null,
  keywordsCALeaderboard: null
};
export const reviewAnalyticsReducer: Reducer<IReviewAnalyticsReducer, ReviewAnalyticsActions> = (state: IReviewAnalyticsReducer = initialState, action: ReviewAnalyticsActions) => {
  switch (action.type) {
    case types.GET_REVIEW_ANALYTICS_REQUEST:
      return {
        ...state,
        isReviewAnalyticsFetching: true,
        isError: false,
        error: null
      };
    case types.GET_REVIEW_ANALYTICS_RESPONSE:
      const groupedTableData = reviewsTableDateGrouping(action.payload?.reviews_analytics_by_date || [], REVIEW_ANALYTICS_STATS.concat(PERCENT_REPLIED_IN_NUMBER), REVIEW_ANALYTICS_PLATFORM_FILTER);
      return {
        ...state,
        isReviewAnalyticsFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...action.payload },
        groupedTableData
      };
    case types.GET_REVIEW_ANALYTICS_FAILURE:
      return {
        ...state,
        data: {},
        isReviewAnalyticsFetching: false,
        groupedTableData: { headers: [], data: [], summary: {}, tickInterval: undefined },
        isError: true,
        error: action.payload
      };
    case types.GET_REVIEW_ANALYTICS_SUMMARY_REQUEST:
      return {
        ...state,
        isReviewAnalyticsSummaryFetching: true,
        isError: false,
        error: null
      };
    case types.GET_REVIEW_ANALYTICS_SUMMARY_RESPONSE:
      return {
        ...state,
        isReviewAnalyticsSummaryFetching: false,
        isError: false,
        error: null,
        data: { ...state.data, ...action.payload }
      };
    case types.GET_REVIEW_ANALYTICS_SUMMARY_FAILURE:
      return {
        ...state,
        isReviewAnalyticsSummaryFetching: false,
        isError: true,
        error: action.payload,
        data: {}
      };
    case types.GET_KEY_WORD_DATA_REQUEST:
      return {
        ...state,
        isKeyWordDataFetching: action.payload?.fetchAllKeywords ? false : true,
        isAllKeywordsFetching: action.payload?.fetchAllKeywords ? true : false,
        isError: false,
        error: null,
        keyWordPageCount: typeof action?.payload?.page === 'number' ? action?.payload?.page || 1 : 1
      };
    case types.GET_KEY_WORD_DATA_RESPONSE:
      const totalKeyWordsData = state.keyWordPageCount > 1 ? state.keyWordsData.concat(action.payload?.review_keywords || []) : action.payload?.review_keywords || [];
      return {
        ...state,
        isKeyWordDataFetching: false,
        isError: false,
        error: null,
        keyWordsData: totalKeyWordsData,
        keyWordCloudData: getKeywordData(totalKeyWordsData.slice(0, 50)),
        isAllKeywordsFetching: false
      };
    case types.GET_KEY_WORD_DATA_FAILURE:
      return {
        ...state,
        keyWordPageCount: 1,
        keyWordCloudData: [],
        keyWordsData: [],
        isKeywordAscending: false,
        isKeyWordDataFetching: false,
        isError: true,
        error: action.payload
      };
    case types.SET_KEY_WORD_DATA:
      return {
        ...state,
        uniqueKeyWord: action.payload
      };
    case types.GET_UNIQUE_KEY_WORD_DATA_REQUEST:
      return {
        ...state,
        isUniqueKeywordDataFetching: true
      };
    case types.GET_UNIQUE_KEY_WORD_DATA_RESPONSE:
      return {
        ...state,
        isUniqueKeywordDataFetching: false,
        uniqueKeyWordData: action.payload
      };
    case types.GET_UNIQUE_KEY_WORD_DATA_FAILURE:
      return {
        ...state,
        isUniqueKeywordDataFetching: false,
        error: action.payload
      };
    case types.RESET_KEY_WORD_DATA_STATE:
      return {
        ...state,
        isKeyWordDataFetching: false,
        sortKey: 'used',
        keyWordPageCount: 1,
        keyWordCloudData: [],
        keyWordsData: [],
        isKeywordAscending: false
      };
    case types.RESET_KEY_WORD_LIST:
      return {
        ...state,
        keyWordPageCount: 1,
        keyWordCloudData: [],
        keyWordsData: []
      };
    case types.RESET_REVIEW_STATE:
      return {
        ...initialState
      };
    case types.GET_KEY_WORDS_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        isKeywordsCategoryListFetching: true,
        error: null
      };
    case types.GET_KEY_WORDS_CATEGORY_LIST_RESPONSE:
      return {
        ...state,
        isKeywordsCategoryListFetching: false,
        keywordsCategoryList: action.payload.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)),
        error: null
      };
    case types.GET_KEY_WORDS_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isKeywordsCategoryListFetching: false,
        keywordsCategoryList: [],
        error: action.payload
      };
    case types.GET_KEY_WORDS_CATEGORY_REQUEST:
      return {
        ...state,
        isKeywordsCategoryFetching: true,
        error: null
      };
    case types.GET_KEY_WORDS_CATEGORY_RESPONSE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        keywordsCategory: action.payload,
        error: null
      };
    case types.GET_KEY_WORDS_CATEGORY_FAILURE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        keywordsCategory: null,
        error: action.payload
      };
    case types.CREATE_UPDATE_KEY_WORDS_CATEGORY_REQUEST:
      return {
        ...state,
        isKeywordsCategoryFetching: true,
        error: null
      };
    case types.CREATE_UPDATE_KEY_WORDS_CATEGORY_RESPONSE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        error: null
      };
    case types.CREATE_UPDATE_KEY_WORDS_CATEGORY_FAILURE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        error: action.payload
      };
    case types.DELETE_KEY_WORDS_CATEGORY_REQUEST:
      return {
        ...state,
        isKeywordsCategoryFetching: true,
        error: null
      };
    case types.DELETE_KEY_WORDS_CATEGORY_RESPONSE:
      return {
        ...state,
        keywordsCategory: null,
        isKeywordsCategoryFetching: false,
        error: null
      };
    case types.DELETE_KEY_WORDS_CATEGORY_FAILURE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        error: action.payload
      };
    case types.GET_KEY_WORD_CATEGORY_DATA_RESPONSE:
      return {
        ...state,
        isKeyWordDataFetching: false,
        isAllKeywordsFetching: false,
        isError: false,
        error: null,
        allKeywordsList: action.payload?.available_words || []
      };
    case types.GET_KEY_WORDS_CATEGORY_ANALYTICS_REQUEST:
      return {
        ...state,
        isKeyWordDataFetching: false,
        isKeywordsCategoryAnalyticsFetching: true,
        keywordsCategoryAnalytics: null,
        keywordsCAChartData: [],
        error: null
      };
    case types.GET_KEY_WORDS_CATEGORY_ANALYTICS_RESPONSE:
      const keywordsCADataWithCumulative = keywordCAChartDateGrouping(action.payload?.time_series);
      return {
        ...state,
        error: null,
        keywordsCategoryAnalytics: action.payload,
        keywordsCALeaderboard: action.payload.leaderboard_row,
        isKeywordsCategoryAnalyticsFetching: false,
        keywordsCAChartData: keywordsCADataWithCumulative
      };
    case types.GET_KEY_WORDS_CATEGORY_ANALYTICS_FAILURE:
      return {
        ...state,
        isKeywordsCategoryFetching: false,
        error: action.payload,
        isKeywordsCategoryAnalyticsFetching: false,
        keywordsCAChartData: [],
        keywordsCategoryAnalytics: null,
        keywordsCALeaderboard: null
      };
    case types.GET_KEY_WORDS_CATEGORY_CHART_DATA:
      const chartDataWithCumulative = keywordCAChartDateGrouping(state.keywordsCategoryAnalytics ? state.keywordsCategoryAnalytics?.time_series : [], action.payload);
      return {
        ...state,
        keywordsCAChartData: chartDataWithCumulative
      };
    case types.OPEN_KEY_WORD_CATEGORY_MODEL:
      return {
        ...state,
        showCategoryModal: action.payload
      };
    case types.KEY_WORD_CATEGORY_MODEL_RESET:
      return {
        ...state,
        keywordsCategory: initialState.keywordsCategory
      };

    default:
      return state;
  }
};
