import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { CONTENT_SCHEDULER_INFO_MSG, RIPPLE_COLOR, USER_OWNERSHIP, contentSchedulerScheduleType } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { IStore } from 'types';
import { accountCreateScheduledPostRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { ScheduleCalendar } from './ScheduleCalendar';
import { Loading } from 'components';
import { SchedulerWarningModal } from './SchedulerWarningModal';

export const SchedulerDetails = () => {
  const dispatch = useDispatch();

  const { id, optionalParams } = useAccountSwitcherData();

  const accScheduleSlotList = useSelector((state: IStore) => state.accounts.accScheduleSlotList);
  const isSchedulerSlotListFetching = useSelector((state: IStore) => state.accounts.isScheduleSlotListFetching);
  const schedulerContentSupplier = useSelector((state: IStore) => state.operationsList.scheduler_content_supplier);

  const [scheduleType, setScheduleType] = useState(optionalParams?.[0] === 'account_wizard' ? contentSchedulerScheduleType.DEFAULT : contentSchedulerScheduleType.CUSTOMIZED);
  const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);

  return isSchedulerSlotListFetching ? (
    <Loading />
  ) : (
    <div className="cf-scheduler vpy-20 lpx">
      {!accScheduleSlotList?.length && scheduleType === contentSchedulerScheduleType.DEFAULT ? (
        <div className="cfs-main cfs-index">
          <h2>{CONTENT_SCHEDULER_INFO_MSG}</h2>
          <div className="cfs-cnt">
            <ImageValidation isImgValid defaultImg="scheduler" customName="scheduler" customClassname="cfsIcon" />
            <h3>Create a Custom Schedule or Use the Default</h3>
            <div className="cfs-btns">
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block`}
                  onClick={() => {
                    if (schedulerContentSupplier?.length) {
                      setScheduleType(contentSchedulerScheduleType.DEFAULT);
                      dispatch(accountCreateScheduledPostRequest({ userOwnership: USER_OWNERSHIP.ACCOUNT, id, type: contentSchedulerScheduleType.DEFAULT }));
                    } else {
                      setShowInfoModalOpen(true);
                    }
                  }}
                >
                  Use Default
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.secondary}>
                <button
                  className={`ac-btn ac-secondary ac-block`}
                  onClick={() => {
                    if (schedulerContentSupplier?.length) {
                      setScheduleType(contentSchedulerScheduleType.CUSTOMIZED);
                    } else {
                      setShowInfoModalOpen(true);
                    }
                  }}
                >
                  Customized
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="cfs-main cfs-cs">
          <h2>{CONTENT_SCHEDULER_INFO_MSG}</h2>
          <div className="cfs-cnt cf-calendar-main">
            <ScheduleCalendar />
          </div>
        </div>
      )}
      <SchedulerWarningModal isShowModal={showInfoModalOpen} onModalClose={() => setShowInfoModalOpen(false)} />
    </div>
  );
};
