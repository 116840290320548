import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { commonAccountSwitcher, IStore } from 'types';
import { ADMIN_AREA_ROUTES, SIDE_NAV_DROPDOWN_DATA } from 'utils/constants';

export const useMenuAccessibleAccountSwitcherData = () => {
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const franchisors = useSelector((state: IStore) => state.accountSwitcher.franchisors);
  const accounts = useSelector((state: IStore) => state.accountSwitcher.accounts);

  const pathArray = window.location.pathname.split('/');

  const isAdminScreenEnabled = useMemo(() => {
    return isAdmin && ADMIN_AREA_ROUTES.includes(`/${pathArray[1]}/${pathArray[2]}`);
  }, [pathArray, isAdmin]);

  const id = !isAdminScreenEnabled ? +pathArray[2] : 0;
  const userOwnership = !isAdminScreenEnabled ? pathArray[1] : '';
  const currentRoute = !isAdminScreenEnabled ? `${pathArray[3]}/${pathArray[4]}` : `${pathArray[1]}/${pathArray[2]}`;

  const dropDownData = useMemo(() => {
    const data: commonAccountSwitcher.IDropdownOptionData[] = [];
    if (franchisors.length) {
      franchisors.forEach((element) => {
        data.push({ custom: true, label: element.name, value: element.id, type: SIDE_NAV_DROPDOWN_DATA.ADMIN, name: element.name });
      });
    }
    if (accounts.length) {
      accounts.forEach((element) => {
        data.push({ label: element.name, value: element.id, type: SIDE_NAV_DROPDOWN_DATA.LOCAL });
      });
    }
    return data;
  }, [accounts, franchisors]);

  return { id, userOwnership, isAdminScreenEnabled, currentRoute, dropDownData };
};
