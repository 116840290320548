import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'analytics/actions/action-types';
import {
  getLeaderBoardAnalyticsResponse,
  getLeaderBoardAnalyticsFailure,
  getLeaderBoardAnalyticsReviewCategoryResponse,
  getLeaderBoardAnalyticsReviewCategoryFailure,
  getLeaderBoardAnalyticsReviewCategoryIdResponse
} from 'analytics/actions';
import { apiCall, defaultHeader, API, updateApiOnFail } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { exceptionHandlerRequest, logoutRequest } from 'actions';
import { LEADER_BOARD_ANALYTICS_FAILTED, LEADER_BOARD_FETCHING_LIST_FAILED } from 'analytics/utils';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getLeaderBoardAnalyticsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const params = {
      ...payload
    };
    delete params.accountId;
    delete params?.isContentSupplier;

    const headers = defaultHeader();
    const apiObj = payload?.isContentSupplier ? API.getCSLeaderBoardAnalytics : API.getLeaderBoardAnalytics;
    const path = apiObj.apiPath.replace(':id', payload.accountId);
    const response = yield apiCall({ headers, params, apiPath: path, action: apiObj.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getLeaderBoardAnalyticsResponse(response.data));
    } else {
      yield put(getLeaderBoardAnalyticsFailure(response?.data?.error || LEADER_BOARD_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LEADER_BOARD_ANALYTICS_FAILTED));
  }
}

function* getLeaderBoardAnalyticsReviewCategoryRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getReviewCategoryLeaderBoardAnalytics.apiPath.replace(':id', payload.id);
    const { id, ...params } = payload;
    const response = yield apiCall({ headers, params, apiPath: path, action: API.getReviewCategoryLeaderBoardAnalytics.action });
    const asyncResponse = yield updateApiOnFail({
      url: response.data.url,
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (asyncResponse.status === 200) {
      yield put(getLeaderBoardAnalyticsReviewCategoryResponse(asyncResponse.data));
    } else {
      yield put(getLeaderBoardAnalyticsReviewCategoryFailure(LEADER_BOARD_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LEADER_BOARD_ANALYTICS_FAILTED));
  }
}

function* getLBReviewCategoryIDRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = `${API.getReviewCategoryIdLeaderBoardAnalytics.apiPath}?franchisor_id=${payload}`;
    const response = yield apiCall({ headers, apiPath: path, action: API.getReviewCategoryIdLeaderBoardAnalytics.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getLeaderBoardAnalyticsReviewCategoryIdResponse(response.data?.review_keyword_category_ids));
    } else {
      yield put(getLeaderBoardAnalyticsReviewCategoryFailure(LEADER_BOARD_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LEADER_BOARD_ANALYTICS_FAILTED));
  }
}

export function* takeLeaderBoardRequest() {
  yield takeLatest(types.GET_LEADER_BOARD_ANALYTICS_REQUEST, getLeaderBoardAnalyticsRequest);
  yield takeLatest(types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_REQUEST, getLeaderBoardAnalyticsReviewCategoryRequest);
  yield takeLatest(types.GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_REQUEST, getLBReviewCategoryIDRequest);
}
