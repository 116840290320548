// Survey Metrics Action types
export const SURVEY_METRICS_REQUEST = 'surveys/SURVEY_METRICS_REQUEST';
export const SURVEY_METRICS_RESPONSE = 'surveys/SURVEY_METRICS_RESPONSE';
export const SURVEY_METRICS_REQUEST_FAILURE = 'surveys/SURVEY_METRICS_REQUEST_FAILURE';

// Survey List Request Action types
export const SURVEY_LIST_REQUEST = 'surveys/SURVEY_LIST_REQUEST';
export const SURVEY_LIST_RESPONSE = 'surveys/SURVEY_LIST_RESPONSE';
export const SURVEY_LIST_REQUEST_FAILURE = 'surveys/SURVEY_LIST_REQUEST_FAILURE';

export const SEND_SURVEY_REQUEST = 'surveys/SEND_SURVEY_REQUEST';
export const SEND_SURVEY_RESPONSE = 'surveys/SEND_SURVEY_RESPONSE';
export const SEND_SURVEY_FAILURE = 'surveys/SEND_SURVEY_FAILURE';

export const SEND_QUEUED_SURVEYS_REQUEST = 'surveys/SEND_QUEUED_SURVEYS_REQUEST';
export const SEND_QUEUED_SURVEYS_RESPONSE = 'surveys/SEND_QUEUED_SURVEYS_RESPONSE';
export const SEND_QUEUED_SURVEYS_FAILURE = 'surveys/SEND_QUEUED_SURVEYS_FAILURE';

export const CUSTOMER_SEND_SURVEY_REQUEST = 'surveys/CUSTOMER_SEND_SURVEY_REQUEST';
export const CUSTOMER_SEND_SURVEY_RESPONSE = 'surveys/CUSTOMER_SEND_SURVEY_RESPONSE';
export const CUSTOMER_SEND_SURVEY_FAILURE = 'surveys/CUSTOMER_SEND_SURVEY_FAILURE';

export const CUSTOMER_BASED_SURVEYS_REQUEST = 'surveys/CUSTOMER_BASED_SURVEYS_REQUEST';
export const CUSTOMER_BASED_SURVEYS_RESPONSE = 'surveys/CUSTOMER_BASED_SURVEYS_RESPONSE';
export const CUSTOMER_BASED_SURVEYS_FAILURE = 'surveys/CUSTOMER_BASED_SURVEYS_FAILURE';
