import { takeEvery, takeLatest, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  menuAccessibleRequestFailure,
  menuAccessibleResponse,
  exceptionHandlerRequest,
  getFranchisorDetailsResponse,
  getFranchisorDetailsFailure,
  accountGetProfileResponse,
  socialContentsBrandActivePlatforms,
  franchisorGetOrUpdateProfileResponse
} from 'actions';
import { apiCall, defaultHeader, API, getFranAccessibleSocialMedia } from 'utils/helpers';
import { ALERT_BOX_TYPES, MA_EXCEPTION_ERROR_MSG, MA_FETCHING_DATA_FAILED, USER_OWNERSHIP, SETTINGS_SUCCESS_MSG } from 'utils/constants';
import { commonApiTypes } from 'types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendMenuAccessiblityRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { ...(payload.editedContent && { franchisor: payload.editedContent }) };
    const urlPathObj: { apiPath: string; action: any } = payload.editedContent ? { ...API.menuAccessiblityUpdate } : { ...API.menuAccessiblityGet };
    const replacedPath: string = urlPathObj.apiPath.replace(':userOwnerShip/:id', `${payload.userOwnership}s/${payload.id}`);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, data, ...urlPathObj });
    if (response.status === 200) {
      yield put(
        menuAccessibleResponse({
          userOwnership: payload.userOwnership,
          ...response.data[payload.userOwnership]
        })
      );
      if (payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
        yield put(accountGetProfileResponse(response.data));
      } else if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        yield put(franchisorGetOrUpdateProfileResponse(response.data));
        if (payload.editedContent) {
          alertBoxCall(ALERT_BOX_TYPES.SUCCESS, SETTINGS_SUCCESS_MSG);
        }
      }
    } else {
      yield put(menuAccessibleRequestFailure(MA_FETCHING_DATA_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MA_EXCEPTION_ERROR_MSG));
  }
}

function* sendGetFranchisorDetailsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const urlPathObj: { apiPath: string; action: any } = { ...API.getFranchisorDetails };
    const replacedPath: string = urlPathObj.apiPath.replace(':id', `${payload?.topLevelFranchisorId ? payload?.topLevelFranchisorId : payload.id}`);
    urlPathObj.apiPath = replacedPath;
    const response = yield apiCall({ headers, ...urlPathObj });
    if (response.status === 200) {
      if (!payload?.topLevelFranchisorId) {
        if (payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) yield put(getFranchisorDetailsResponse(response.data.franchisor_detail));
        else yield put(getFranchisorDetailsResponse(null));
      } else yield put(socialContentsBrandActivePlatforms(getFranAccessibleSocialMedia(false, response.data.franchisor_detail.access)));
    } else {
      if (!payload?.topLevelFranchisorId) {
        yield put(getFranchisorDetailsFailure(MA_FETCHING_DATA_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MA_EXCEPTION_ERROR_MSG));
  }
}

export function* takeMenuAccessiblityRequest() {
  yield takeEvery(types.MENU_ACCESSIBLE_REQUEST, sendMenuAccessiblityRequest);
  yield takeLatest(types.GET_FRANCHISOR_DETAILS_REQUEST, sendGetFranchisorDetailsRequest);
}
