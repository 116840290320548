import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'revv/actions/action-types';
import { ILocationsMetricsResponse, IListRequestParams, ILocationsListResponse, ILocationDetailResponse, IIdParam, IUploadParams, ILoationMetrics } from 'revv/types';
import { COMMON_ACTIONS } from 'revv/actions';

export const locationsMetricsRequest = (payload: ILoationMetrics) => createAction(actionTypes.LOCATIONS_METRICS_REQUEST, payload);
export const locationsMetricsFailure = (payload: string) => createAction(actionTypes.LOCATIONS_METRICS_FAILURE, payload);
export const locationsMetricsResponse = (payload: ILocationsMetricsResponse) => createAction(actionTypes.LOCATIONS_METRICS_RESPONSE, payload);

export const locationsListRequest = (payload: IListRequestParams) => createAction(actionTypes.LOCATIONS_LIST_REQUEST, payload);
export const locationsListFailure = (payload: string) => createAction(actionTypes.LOCATIONS_LIST_FAILURE, payload);
export const locationsListResponse = (payload: ILocationsListResponse[]) => createAction(actionTypes.LOCATIONS_LIST_RESPONSE, payload);

export const locationDeleteRequest = (payload: IIdParam) => createAction(actionTypes.LOCATION_DELETE_REQUEST, payload);
export const locationDeleteFailure = (payload: string) => createAction(actionTypes.LOCATION_DELETE_FAILURE, payload);
export const locationDeleteResponse = () => createAction(actionTypes.LOCATION_DELETE_RESPONSE);

export const locationCreateRequest = (payload: ILocationDetailResponse) => createAction(actionTypes.LOCATION_CREATE_REQUEST, payload);
export const locationCreateFailure = (payload: string) => createAction(actionTypes.LOCATION_CREATE_FAILURE, payload);
export const locationCreateResponse = () => createAction(actionTypes.LOCATION_CREATE_RESPONSE);

export const locationUpdateRequest = (payload: ILocationDetailResponse) => createAction(actionTypes.LOCATION_UDPATE_REQUEST, payload);
export const locationUpdateFailure = (payload: string) => createAction(actionTypes.LOCATION_UPDATE_FAILURE, payload);
export const locationUpdateResponse = () => createAction(actionTypes.LOCATION_UPDATE_RESPONSE);

export const locationDetailRequest = (payload: IIdParam) => createAction(actionTypes.LOCATION_DETAIL_REQUEST, payload);
export const locationDetailFailure = (payload: string) => createAction(actionTypes.LOCATION_DETAIL_FAILURE, payload);
export const locationDetailResponse = (payload: ILocationDetailResponse | null) => createAction(actionTypes.LOCATION_DETAIL_RESPONSE, payload);

export const locationUploadRequest = (payload: IUploadParams) => createAction(actionTypes.LOCATION_UPLOAD_REQUEST, payload);
export const locationUploadRequestFailure = (payload: string) => createAction(actionTypes.LOCATION_UPLOAD_FAILURE, payload);
export const locationUploadResponse = (payload: string) => createAction(actionTypes.LOCATION_UPLOAD_RESPONSE, payload);

export const locationResetUploadSuccessMessage = () => createAction(actionTypes.LOCATION_RESET_UPLOAD_SUCCESS_MESSAGE);

export const updateLocationTags = (payload: string[]) => createAction(actionTypes.UPDATE_LOCATION_TAGS, payload);

export const LOCATIONS_ACTIONS = {
  ...COMMON_ACTIONS,
  locationsMetricsRequest,
  locationsMetricsFailure,
  locationsMetricsResponse,
  locationsListRequest,
  locationsListFailure,
  locationsListResponse,
  locationDeleteRequest,
  locationDeleteFailure,
  locationDeleteResponse,
  locationCreateRequest,
  locationCreateFailure,
  locationCreateResponse,
  locationUpdateRequest,
  locationUpdateFailure,
  locationUpdateResponse,
  locationDetailRequest,
  locationDetailFailure,
  locationDetailResponse,
  locationUploadRequest,
  locationUploadRequestFailure,
  locationUploadResponse,
  locationResetUploadSuccessMessage,
  updateLocationTags
};

export type LocationActions = ActionsUnion<typeof LOCATIONS_ACTIONS>;
