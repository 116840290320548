import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  accountGetProfileResponse,
  accountGetProfileFailure,
  accountUpdateProfileResponse,
  accountUpdateProfileFailure,
  settingsUpdateProfileStatus,
  accountAddProfileResponse,
  accountAddProfileFailure,
  updateSocialMediaSettingSuccessOrFailure,
  accountGetProfileRequest,
  getFranchiseePermissionResponse,
  getFranchiseePermissionFailure,
  accountActivateResponse,
  accountActivateFailure,
  settingsAIPlayBookGetUpdateResponse,
  accountCreateOrUpdateScheduleResponse,
  accountCreateOrUpdateScheduleFailure,
  accountGetScheduleListResponse,
  accountGetScheduleListFailure,
  accountDeleteScheduleResponse,
  accountDeleteScheduleFailure,
  accountCreateScheduledPostRequest,
  accountGetScheduledPostStatsResponse,
  accountGetScheduledPostStatsFailure,
  setMenuAccess,
  accountListSchedulesBlockListResponse,
  accountListSchedulesBlockListFailure
} from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes } from 'types';
import {
  ACCOUNTS_UPDATE_PROFILE_FAILED,
  ACCOUNTS_GET_PROFILE_FAILED,
  ACCOUNTS_ADD_PROFILE_FAILED,
  RALLIO_PROFILE_DETAILS_UPDATE_SUCCESS,
  RALLIO_PROFILE_DETAILS_UPDATE_FAIL,
  COMMON_SOCIAL_PROVIDER_TYPE,
  FRANCHISEE_PERMISSION_DATA_FAILURE,
  ACCOUNT_ACTIVATE_FAILURE,
  ACCOUNT_ERROR_MSG,
  ALERT_BOX_TYPES,
  ACCOUNT_SCHEDULER_CREATE_SLOT_SUCCESS_MSG,
  ACCOUNT_SCHEDULER_UPDATE_SLOT_SUCCESS_MSG,
  ACCOUNT_SCHEDULER_UPDATE_SLOT_FAILURE_MSG,
  ACCOUNT_SCHEDULER_CREATE_SLOT_FAILURE_MSG,
  ACCOUNT_SCHEDULER_DELETE_SLOT_SUCCESS_MSG,
  ACCOUNT_SCHEDULER_DELETE_SLOT_FAILURE_MSG,
  ACCOUNT_SCHEDULER_EXCEPTION_ERROR,
  USER_OWNERSHIP,
  contentSchedulerScheduleType
} from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendAccountsProfileUpdateRequest(requestDetails: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { id, ...reqPayload } = requestDetails.payload.profileData;
    const data = { account: reqPayload, ...(requestDetails.payload?.isUpdatePersonalizedFields && { refresh_account_post_texts: true }) };
    const path = API.accountsUpdateProfile.apiPath.replace(':id', requestDetails.payload.id);
    delete data.account.menu_access;
    const response = yield apiCall({ headers, data, apiPath: path, action: API.accountsUpdateProfile.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (requestDetails.payload.type === 'add_location_rallio') {
        yield put(accountAddProfileResponse(response.data));
      } else {
        yield put(accountUpdateProfileResponse(response.data.account));
        yield put(
          settingsAIPlayBookGetUpdateResponse({
            business_url: response.data?.account.url || '',
            customer_persona_text: response.data?.customer_persona_text,
            customer_persona_usage_level: response.data?.customer_persona_usage_level,
            auto_generate_post: response.data?.auto_generate_post
          })
        );
        if (!requestDetails.payload.isOnlyUpdateAccount) {
          yield put(accountGetProfileRequest({ id: requestDetails.payload.id, isDataRetain: true }));
        }
        if (!['edit_location_rallio', 'add_location_social', 'edit_location_social'].includes(requestDetails.payload.type)) {
          yield put(settingsUpdateProfileStatus({ isUpdated: true, msg: RALLIO_PROFILE_DETAILS_UPDATE_SUCCESS, resp: response.data }));
        }
      }
      if (typeof requestDetails.payload?.onNavigate === 'function') requestDetails.payload?.onNavigate(response.data.account.id, response.data.account.signup_coupon?.coupon_code);
    } else {
      if (requestDetails.payload.type === 'add_location_rallio') {
        yield put(accountAddProfileFailure(ACCOUNTS_ADD_PROFILE_FAILED));
      } else {
        yield put(accountUpdateProfileFailure(response.data.error));
        if (!['edit_location_rallio', 'edit_location_social'].includes(requestDetails.payload.type)) {
          yield put(settingsUpdateProfileStatus({ isUpdated: false, msg: RALLIO_PROFILE_DETAILS_UPDATE_FAIL }));
        }
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNTS_UPDATE_PROFILE_FAILED));
  }
}

function* sendAccountsProfileGetRequest(requestDetails: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { account: requestDetails.payload.profileData };
    const path = API.accountsGetProfile.apiPath.replace(':id', requestDetails.payload.id);
    const response = yield apiCall({ headers, data, apiPath: path, action: API.accountsGetProfile.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      // to handle get accounts social media in calendar brand view
      yield put(accountGetProfileResponse(response.data));
      if (!requestDetails.payload.isGetAccountDetailsOnly) {
        yield put(updateSocialMediaSettingSuccessOrFailure({ type: COMMON_SOCIAL_PROVIDER_TYPE.ALL }));
      }
      if (requestDetails.payload.isPayment) {
        yield put(setMenuAccess(response.data.account.menu_access));
      }
    } else {
      yield put(accountGetProfileFailure(response.data?.error || ACCOUNT_ERROR_MSG));
      yield put(updateSocialMediaSettingSuccessOrFailure({ type: COMMON_SOCIAL_PROVIDER_TYPE.ALL }));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNTS_GET_PROFILE_FAILED));
    yield put(updateSocialMediaSettingSuccessOrFailure({ type: COMMON_SOCIAL_PROVIDER_TYPE.ALL }));
  }
}

function* sendAddAccountsProfileRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { account: payload.reqPayload };
    const response = yield apiCall({ headers, data, ...API.accountsAddProfile });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountAddProfileResponse(response.data));
      yield put(accountGetProfileRequest({ id: response.data.account.id }));
      payload.onNavigate(response.data.account.id, response.data.account.signup_coupon?.coupon_code);
    } else {
      yield put(accountAddProfileFailure(ACCOUNTS_ADD_PROFILE_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNTS_ADD_PROFILE_FAILED));
  }
}

function* sendGetFranchiseePermissionRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getFranchiseePermission.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getFranchiseePermission.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getFranchiseePermissionResponse(response.data.franchisee_permission));
    } else {
      yield put(getFranchiseePermissionFailure(FRANCHISEE_PERMISSION_DATA_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(FRANCHISEE_PERMISSION_DATA_FAILURE));
  }
}

function* sendActivateAccountRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.inboxPieReload.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, apiPath: path, action: API.inboxPieReload.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountActivateResponse(response.data));
      if (typeof payload.onNavigate === 'function') payload.onNavigate(payload.id);
    } else {
      yield put(accountActivateFailure(ACCOUNT_ACTIVATE_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_ACTIVATE_FAILURE));
  }
}

function* sendAccountGetScheduleListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isFranchisor = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR;

    const apiObj = isFranchisor ? { ...API.getFranchisorSchedulerList } : { ...API.getAccountSchedulerList };
    apiObj.apiPath = isFranchisor ? apiObj.apiPath.replace(':franchisor_id', payload.id) : apiObj.apiPath.replace(':account_id', payload.id);
    const response = yield apiCall({ headers, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountGetScheduleListResponse(isFranchisor ? response.data.franchisor_account_list_schedules : response.data.account_list_schedules));
    } else {
      yield put(accountGetScheduleListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}
function* sendAccountCreateOrUpdateScheduleRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isUpdateReq = payload?.list_id;
    const isFranchisor = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR;
    const apiObj = isFranchisor
      ? isUpdateReq
        ? { ...API.updateFranchisorSchedulerList }
        : { ...API.createFranchisorSchedulerList }
      : isUpdateReq
      ? { ...API.updateAccountSchedulerList }
      : { ...API.createAccountSchedulerList };
    apiObj.apiPath = isUpdateReq
      ? apiObj.apiPath.replace(':id', payload.list_id)
      : isFranchisor
      ? apiObj.apiPath.replace(':franchisor_id', payload.id)
      : apiObj.apiPath.replace(':account_id', payload.id);

    const response = yield apiCall({ headers, data: isFranchisor ? { franchisor_account_list_schedule: payload.reqPayload } : { account_list_schedule: payload.reqPayload }, ...apiObj });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountCreateOrUpdateScheduleResponse(isFranchisor ? response.data.franchisor_account_list_schedule : response.data.account_list_schedule));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, isUpdateReq ? ACCOUNT_SCHEDULER_UPDATE_SLOT_SUCCESS_MSG : ACCOUNT_SCHEDULER_CREATE_SLOT_SUCCESS_MSG);
      yield put(accountCreateScheduledPostRequest({ userOwnership: payload.userOwnership, id: payload.id }));
    } else {
      yield put(accountCreateOrUpdateScheduleFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, isUpdateReq ? ACCOUNT_SCHEDULER_UPDATE_SLOT_FAILURE_MSG : ACCOUNT_SCHEDULER_CREATE_SLOT_FAILURE_MSG);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}
function* sendAccountDeleteScheduleRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ...API.deleteFranchisorSchedulerList } : { ...API.deleteAccountSchedulerList };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.list_id);
    const response = yield apiCall({ headers, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountDeleteScheduleResponse({ list_id: payload.list_id }));
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, ACCOUNT_SCHEDULER_DELETE_SLOT_SUCCESS_MSG);
    } else {
      yield put(accountDeleteScheduleFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, ACCOUNT_SCHEDULER_DELETE_SLOT_FAILURE_MSG);
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}

function* sendCreateScheduledPostsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isFranchisor = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR;
    const apiObj =
      payload.type === contentSchedulerScheduleType.DEFAULT ? { ...API.makeAccountDefaultsScheduler } : isFranchisor ? { ...API.createFranchisorScheduledPost } : { ...API.createAccountScheduledPost };
    apiObj.apiPath = isFranchisor ? apiObj.apiPath.replace(':franchisor_id', payload.id) : apiObj.apiPath.replace(':account_id', payload.id);
    const response = yield apiCall({ headers, params: payload?.params, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (payload.type === contentSchedulerScheduleType.DEFAULT) {
      if (response.status === 200) {
        yield put(accountGetScheduleListResponse(response.data.account_list_schedules));
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, ACCOUNT_SCHEDULER_CREATE_SLOT_SUCCESS_MSG);
      } else {
        yield put(accountGetScheduleListFailure(response.data.error));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}

function* sendGetScheduledPostsStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = { ...API.getAccountSchedulerPostStats };
    apiObj.apiPath = apiObj.apiPath.replace(':account_id', payload);
    const response = yield apiCall({ headers, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountGetScheduledPostStatsResponse(response.data.future_scheduled_posts_count));
    } else {
      yield put(accountGetScheduledPostStatsFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}

function* sendAccountListSchedulerBlockListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isFranchisor = payload.userOwnership === USER_OWNERSHIP.FRANCHISOR;

    const apiObj = { ...API.blockAccountSchedulerList };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: payload.request, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountListSchedulesBlockListResponse(isFranchisor ? response.data.franchisor_account_list_schedules : response.data.account_list_schedules));
    } else {
      yield put(accountListSchedulesBlockListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_SCHEDULER_EXCEPTION_ERROR));
  }
}

export function* takeAccountsProfileRequest() {
  yield takeEvery(types.ACCOUNTS_UPDATE_PROFILE_REQUEST, sendAccountsProfileUpdateRequest);
  yield takeEvery(types.ACCOUNTS_GET_PROFILE_REQUEST, sendAccountsProfileGetRequest);
  yield takeEvery(types.ACCOUNTS_ADD_PROFILE_REQUEST, sendAddAccountsProfileRequest);
  yield takeEvery(types.GET_FRANCHISEE_PERMISSION_REQUEST, sendGetFranchiseePermissionRequest);
  yield takeEvery(types.ACCOUNT_ACTIVATE_REQUEST, sendActivateAccountRequest);
  yield takeLatest(types.ACCOUNTS_GET_SCHEDULE_LIST_REQUEST, sendAccountGetScheduleListRequest);
  yield takeLatest(types.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_REQUEST, sendAccountCreateOrUpdateScheduleRequest);
  yield takeLatest(types.ACCOUNTS_DELETE_SCHEDULE_LIST_REQUEST, sendAccountDeleteScheduleRequest);
  yield takeLatest(types.ACCOUNTS_CREATE_SCHEDULED_POSTS_REQUEST, sendCreateScheduledPostsRequest);
  yield takeLatest(types.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_REQUEST, sendGetScheduledPostsStatsRequest);
  yield takeLatest(types.ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_REQUEST, sendAccountListSchedulerBlockListRequest);
}
