import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { advocacyLeaderBoardLocationsDownloadFormeter, dateFormatByRequestedFormat, getCurrentDate, getFormattedNumber } from 'utils/helpers';
import {
  RANKING_FILTER,
  DATE_FILTER,
  AdvocacyLeaderboardvalidQueryParams,
  BUTTON_PROPS,
  ALERT_BOX_TYPES,
  EXPORT_DOWNLOAD_STARTED_MSG,
  UTC_TIMESTAMP_FORMAT,
  ADV_LB_SORT_NAMES,
  USER_OWNERSHIP,
  EmpAdvLeaderbordRankType,
  EmpAdvLeaderbordDateType,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  DONE_SUCCESS_MSG
} from 'utils/constants';
import { CommonFilter, alertBoxCall } from 'components';
import { exportCsvhandler, handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const AdvocacyLeaderboardFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const isEmpLeaderboardValid = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity?.employee_advocacy?.advocacy_leaderboard || false);
  const filterStats = useSelector((state: IStore) => state.advLeaderboard.filterStats);
  const leaderboardData = useSelector((state: IStore) => state.advLeaderboard.leaderboardData);
  const initialFilterValue = {
    dateType: EmpAdvLeaderbordDateType.MONTH_TO_DATE,
    ranking: EmpAdvLeaderbordRankType.EMPLOYEE,
    dateRange: {
      fromDate: { selected: null, timeStamp: null },
      toDate: { selected: null, timeStamp: null }
    }
  };
  const { queryParams, filter } = useParamsDeconstructor(initialFilterValue);

  const validParams = {
    ...AdvocacyLeaderboardvalidQueryParams,
    ...(queryParams?.date_type === 'custom'
      ? {
          START_DATE: 'start_date',
          END_DATE: 'end_date'
        }
      : {})
  };

  useParamsValidator(
    { ...CommonValidQueryParams, ...validParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      ranking: RANKING_FILTER.map((it) => it.value),
      date_type: DATE_FILTER.map((it) => it.value),
      sort_by: Object.values(ADV_LB_SORT_NAMES),
      ascending: ['1']
    }
  );

  useEffect(() => {
    if (id && userOwnership && isValidId && isEmpLeaderboardValid && !Object.keys(queryParams)?.length) {
      navigate({ search: `?${new URLSearchParams({ ascending: '1' }).toString()}` }, { replace: true });
    }
  }, [id, userOwnership, isValidId, isEmpLeaderboardValid]); // eslint-disable-line

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (data.ranking || isClear) {
      delete queryParamsObj.sort_by;
      queryParamsObj.ascending = 1;
    }
    if (data.ranking === EmpAdvLeaderbordRankType.EMPLOYEE) {
      delete queryParamsObj.ranking;
    } else if (data.ranking) {
      queryParamsObj.ranking = data.ranking;
    }
    if (data.dateType === EmpAdvLeaderbordDateType.MONTH_TO_DATE) {
      delete queryParamsObj.date_type;
      delete queryParamsObj.start_data;
      delete queryParamsObj.end_date;
    } else if (data.dateType) {
      queryParamsObj.date_type = data.dateType;
      delete queryParamsObj.start_data;
      delete queryParamsObj.end_date;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleExportLeaderboardFile = () => {
    if (userOwnership && leaderboardData?.consolidate_advocacy.length) {
      alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
      const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
      exportCsvhandler(
        advocacyLeaderBoardLocationsDownloadFormeter,
        leaderboardData?.consolidate_advocacy,
        DONE_SUCCESS_MSG,
        !queryParams?.ranking ? `Employee_advocacy_leaderboard_${currentUnixTimestamp}` : `Location_advocacy_leaderboard_${currentUnixTimestamp}`
      );
    }
  };

  const queryParamKeys = Object.keys(queryParams).filter((it) => !['selected_tile', 'sort_by', 'ascending'].includes(it));

  return (
    <CommonFilter
      filter={{ ...filter, ranking: queryParams?.ranking || EmpAdvLeaderbordRankType.EMPLOYEE, dateType: queryParams.date_type || EmpAdvLeaderbordDateType.MONTH_TO_DATE }}
      handleChange={handleChange}
      handleExportCsv={handleExportLeaderboardFile}
      isDateRangeFilterEnable={queryParams?.date_type === 'custom'}
      checkBoxFilter={
        userOwnership === USER_OWNERSHIP.FRANCHISOR
          ? [
              {
                title: 'Ranking',
                filter: RANKING_FILTER.map((it, index) => ({
                  ...it,
                  label: `${it.label} (${index === 0 ? getFormattedNumber(filterStats.employeesCount || 0) : getFormattedNumber(filterStats.locationsCount || 0)})`
                })),
                value: 'ranking'
              }
            ]
          : []
      }
      secondaryCheckBoxFilter={[
        {
          title: '',
          filter: DATE_FILTER,
          value: 'dateType'
        }
      ]}
      primaryButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
          isDisable: !leaderboardData?.consolidate_advocacy?.length
        },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: !queryParamKeys?.length
        }
      ]}
      isSecondaryDateRangePlaceholder
      sectionVariant="sandbox-filter"
    />
  );
};
