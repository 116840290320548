import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { CouponsActions } from 'actions';
import { couponsReducerTypes } from 'types';
import { COUPON_UPDATES_INITIAL_OBJECT, DELETE_COUPON_FAILED, DELETE_COUPON_SUCCESS, COUPON_STATS_INITIAL_OBJECT, COUPON_MESSAGES_INIT_OBJ } from 'utils/constants';
import { getCouponStatsData } from 'utils/helpers';

const initialState: couponsReducerTypes.ICouponsReducer = {
  isFetching: true,
  couponsList: [],
  couponsStats: COUPON_STATS_INITIAL_OBJECT,
  couponDetail: null,
  error: null,
  couponUpdateobj: COUPON_UPDATES_INITIAL_OBJECT,
  newCouponObj: null,
  messages: COUPON_MESSAGES_INIT_OBJ,
  isLoadAgain: true,
  detailViewError: null
};

const couponsReducer: Reducer<couponsReducerTypes.ICouponsReducer, CouponsActions> = (state: couponsReducerTypes.ICouponsReducer = initialState, action: CouponsActions) => {
  switch (action.type) {
    // CONTENT - GET COUPONS LIST
    case types.GET_COUPONS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_COUPONS_LIST_RESPONSE:
      const statsData = getCouponStatsData(action.payload);
      return {
        ...state,
        isFetching: false,
        isLoadAgain: false,
        couponsList: action.payload,
        couponsStats: {
          active: statsData.active,
          inactive: statsData.inactive
        }
      };
    case types.GET_COUPONS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoadAgain: false,
        couponsList: initialState.couponsList,
        couponsStats: COUPON_STATS_INITIAL_OBJECT,
        error: action.payload || 'Unexpected error'
      };
    // CONTENT - GET COUPONS DETAIL VIEW
    case types.GET_COUPON_DETAIL_VIEW_REQUEST:
      return {
        ...state,
        isFetching: true,
        couponDetail: null
      };
    case types.GET_COUPON_DETAIL_VIEW_RESPONSE:
      return {
        ...state,
        isFetching: false,
        couponDetail: action.payload
      };
    case types.GET_COUPON_DETAIL_VIEW_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    // CONTENT - DELETE COUPON
    case types.DELETE_COUPON_REQUEST:
      return {
        ...state,
        couponUpdateobj: COUPON_UPDATES_INITIAL_OBJECT
      };
    case types.DELETE_COUPON_RESPONSE:
      return {
        ...state,
        isLoadAgain: true,
        couponUpdateobj: {
          isUpdated: true,
          updateSuccessMsg: DELETE_COUPON_SUCCESS,
          updateFailureMsg: null
        }
      };
    case types.DELETE_COUPON_FAILURE:
      return {
        ...state,
        couponUpdateobj: {
          isUpdated: true,
          updateSuccessMsg: null,
          updateFailureMsg: DELETE_COUPON_FAILED
        }
      };
    // CONTENT - SET CLOUDINARY MEDIA UPLOAD
    case types.CP_CLOUD_MEDIA_UPLOAD_REQUEST:
      return {
        ...state,
        newCouponObj: state.newCouponObj
          ? {
              ...state.newCouponObj,
              isMediaFetching: true
            }
          : null
      };
    case types.CP_CLOUD_MEDIA_UPLOAD_RESPONSE:
      return {
        ...state,
        newCouponObj: state.newCouponObj
          ? {
              ...state.newCouponObj,
              isMediaFetching: false,
              imageUrl: action.payload
            }
          : null
      };
    case types.CP_CLOUD_MEDIA_UPLOAD_FAILURE:
      return {
        ...state,
        newCouponObj: state.newCouponObj
          ? {
              ...state.newCouponObj,
              isMediaFetching: false,
              imageUrl: null
            }
          : null,
        error: action.payload
      };
    // CONTENT - COUPONS SET NEW COUPON OBJ
    case types.COUPONS_SET_NEW_COUPON_OBJ:
      return {
        ...state,
        newCouponObj: action.payload
      };
    case types.COUPONS_SET_UPDATE_OBJ:
      return {
        ...state,
        couponUpdateobj: action.payload
      };
    case types.ADD_OR_EDIT_COUPON_REQUEST:
      return {
        ...state,
        isFetching: true,
        messages: {
          ...state.messages,
          isFetching: true
        }
      };
    case types.ADD_OR_EDIT_COUPON_RESPONSE:
      return {
        ...state,
        isLoadAgain: true,
        messages: {
          ...state.messages,
          isFetching: false,
          couponSuccessMsg: action.payload
        }
      };
    case types.ADD_OR_EDIT_COUPON_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          isFetching: false,
          couponFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // COUPONS RESET
    case types.COUPONS_DETAIL_DATA_RESET:
      return {
        ...state,
        couponDetail: initialState.couponDetail
      };
    case types.COUPONS_MESSAGES_RESET:
      return {
        ...state,
        messages: initialState.messages
      };
    case types.COUPONS_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};

export { couponsReducer };
