import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { commonModalPopup, calendarContainerTypes } from 'types';
import { ImageValidation, Video } from 'widgets/Media';
import { convertDateBasedOnTimeZone, dateFormatByRequestedFormat, getFormatBasedOnExactDate, calendarUTCtoLocalHandler, getParsedDate, getBrowserLocalDateTime } from 'utils/helpers';
import { useAvailablePlatforms, useAccountSwitcherData } from 'utils/hooks';
import { YEAR_MONTH_DAY_WITH_TIME_FORMAT, MOMENT_TIME_FORMAT, DATE_ONLY_FORMAT, AP_FRAN_SHOW_LINE_BREAK_TYPES } from 'utils/constants';
import { ViewCalendarSelectedEventModal } from './ViewCalendarSelectedEvent';
import { CalendarIconHandler } from 'containers/Content/Calendar/SubPages/CalendarIconHandler';
import { PLATFORM_FILTER } from 'analytics/utils/constants';
import { Paragraph } from 'widgets/Text';

export const ViewCalendarShowMoreModal = (props: commonModalPopup.IViewCalendarShowMoreModal) => {
  const { isEventsEnabled, extraCalendarList, handleModalClose, handleEventSelection, isHubUser, timeZone } = props;

  const { userOwnership } = useAccountSwitcherData();

  const [selectedEvent, setSelectedEvent] = useState<null | calendarContainerTypes.ICalendarEventList>(null);
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);

  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it) => it.value !== 'yelp');

  return (
    <>
      <Modal
        className="creator-calendar-monty-modal-popup view-moresectionmodal-wrp more-cv-items cv-show-more"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={extraCalendarList.length && !selectedEvent ? true : false}
        animation={false}
      >
        <div className="mod__close--icon">
          <ImageValidation defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} onClick={handleModalClose} />
        </div>
        <div className="main-cps">
          <Modal.Body>
            <div className="head-section">
              <h3>{dateFormatByRequestedFormat(extraCalendarList[0].start, DATE_ONLY_FORMAT)}</h3>
            </div>
            {extraCalendarList.map((calendarDatum, index) => {
              const parsedDate =
                !isHubUser && timeZone
                  ? convertDateBasedOnTimeZone(timeZone, calendarDatum.scheduled_for || calendarDatum.start_datetime, YEAR_MONTH_DAY_WITH_TIME_FORMAT)
                  : calendarDatum?.post_now
                  ? getBrowserLocalDateTime(calendarDatum.scheduled_for)
                  : getParsedDate(calendarUTCtoLocalHandler(calendarDatum.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT));
              const modalImage = calendarDatum?.photo_urls?.length
                ? calendarDatum.photo_urls[0]
                : calendarDatum?.link && calendarDatum?.link?.link_preview_image_url_choices?.length
                ? calendarDatum?.link?.link_preview_image_url_choices[0]
                : calendarDatum?.link_preview_image_url_choices
                ? calendarDatum?.link_preview_image_url_choices[0]
                : null;
              return (
                <div
                  className={`creator-schedule-section-wrp ${calendarDatum.isEvent ? ` purple-bg` : calendarDatum.campaign ? ` cg-green` : calendarDatum.draft ? ` yellow-bg` : ` blue-bg`}`}
                  key={`view-cal-show-more-${index}`}
                  onClick={() => {
                    if (!isEventsEnabled) {
                      setSelectedEvent({ ...calendarDatum, start: parsedDate || '', end: parsedDate || '' });
                      setShowSelectedEvent(true);
                    } else if (handleEventSelection) {
                      handleEventSelection(null, true, calendarDatum.id);
                      handleModalClose();
                    }
                  }}
                >
                  <div className="grid-section">
                    <div className="left-section-view-more-item">
                      <CalendarIconHandler
                        isBoosted={!isHubUser ? calendarDatum.has_facebook_boost_enduser_campaign : calendarDatum.has_any_boost}
                        isAwaiting={!isHubUser ? calendarDatum.draft : false}
                        isCampaign={calendarDatum.campaign}
                      />

                      <div className="time-stamp">{getFormatBasedOnExactDate(parsedDate, MOMENT_TIME_FORMAT)}</div>
                    </div>
                    <div className="social-icon-sec">
                      {availablePlatform.map((it, index) => {
                        return (
                          it.label &&
                          it.image &&
                          calendarDatum[`use_${it?.value?.split('_')[0]}`] && (
                            <div className="gmhl-img" key={index}>
                              <ImageValidation key={`show-more-cal-event-${index}`} imgUrl={it.image} customName={it.label} />
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>

                  <div className={`post-img${!modalImage ? ` withoutmedia` : ``}`}>
                    {modalImage ? (
                      <div className="gmhl-img">
                        <ImageValidation imgUrl={modalImage} customName="Image" />
                      </div>
                    ) : calendarDatum.video_url ? (
                      <div className="video-section">
                        <Video videoUrl={calendarDatum.video_url} light={calendarDatum?.video_thumbnail_url} customClassname={'full-width'} />
                      </div>
                    ) : null}

                    <Paragraph
                      customClassname="post-content"
                      customText={calendarDatum.title ? calendarDatum.title : calendarDatum?.link_title || ''}
                      facebookTagList={calendarDatum?.use_facebook ? calendarDatum?.page_tag_details?.facebook || [] : []}
                      actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                    />
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </div>
      </Modal>
      {selectedEvent?.id && (
        <ViewCalendarSelectedEventModal
          isModalShow={showSelectedEvent}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          handleCloseModal={() => {
            setShowSelectedEvent(false);
            handleModalClose();
          }}
        />
      )}
    </>
  );
};
