import { overviewApiType, overviewContainerType } from 'types/overview';
import { OVERVIEW_ALL_VALUE } from 'utils/constants';
import { utcToLocalHandler } from 'utils/helpers';

type IOverviewAccountData = overviewApiType.IOverviewAccountData;
type IOverviewLatLongResponse = overviewApiType.IOverviewLatLong;
type IOverviewLocationDropDown = overviewContainerType.IOverviewLocationDropDown;
type IOverviewMonthlyPostsResponse = overviewApiType.IOverviewMonthlyPostsResponse;

/** Dashboard - Extract latitude and longitude from accounts data */
export const extractLatLongArray = (accounts: Array<IOverviewAccountData> | IOverviewAccountData) => {
  let locationArray: IOverviewLatLongResponse[] = [];
  if (Array.isArray(accounts) && accounts.length) {
    locationArray = accounts.reduce((acc: IOverviewLatLongResponse[], curr) => {
      if (curr.latitude && curr.longitude) {
        const locationsLatLong = {
          lat: parseFloat(curr.latitude),
          lng: parseFloat(curr.longitude)
        };
        acc = [...acc, locationsLatLong];
      }
      return acc;
    }, []);
  } else if (accounts && !Array.isArray(accounts) && Object.keys(accounts).length) {
    if (accounts.latitude && accounts.longitude) {
      const locationsLatLong = {
        lat: parseFloat(accounts.latitude),
        lng: parseFloat(accounts.longitude)
      };
      locationArray = [locationsLatLong];
    }
  }
  return locationArray;
};

/** Dashboard - Extract location dropdown list from accounts data */
export const extractLocationsDropdownList = (accountList: Array<IOverviewAccountData> | IOverviewAccountData | null, accountsCount: number) => {
  let locationsListArray: IOverviewLocationDropDown[] = [];
  if (accountList && Array.isArray(accountList) && accountList.length === 1 && !accountsCount) {
    locationsListArray = accountList.reduce((acc: IOverviewLocationDropDown[], curr) => {
      const locationsDropdownData = { id: curr.id, label: curr.name, value: curr.name };
      acc = [...acc, locationsDropdownData];
      return acc;
    }, []);
  } else if (accountList && Array.isArray(accountList)) {
    locationsListArray = [{ value: OVERVIEW_ALL_VALUE, label: OVERVIEW_ALL_VALUE, id: 0 }];
    const accountsData = accountList.reduce((acc: IOverviewLocationDropDown[], curr) => {
      const locationsDropdownData = { id: curr.id, label: curr.name, value: curr.name };
      acc = [...acc, locationsDropdownData];
      return acc;
    }, []);
    locationsListArray = [...locationsListArray, ...accountsData];
  } else if (accountList && Object.keys(accountList).length) {
    const dropdownDataForSingleLoc = { id: accountList.id, label: accountList.name, value: accountList.name };
    locationsListArray = [dropdownDataForSingleLoc];
  }
  return locationsListArray;
};

/** Dashboard - Get monthly posts metrics array based on social media */
export const getMontlyPostArray = (postsArray: IOverviewMonthlyPostsResponse[], propName: string) => {
  return postsArray.reduce((acc: any, curr: any) => {
    const utcTime = utcToLocalHandler(curr.date);
    acc = [...acc, [utcTime, curr[propName]]];
    return acc;
  }, []);
};
