import { delay, put, takeLatest } from 'redux-saga/effects';

import { NODE_API, apiCall, defaultHeader } from 'utils/helpers';
import {
  aiAssistantGenerating,
  deleteAIAssistantFailure,
  deleteAIAssistantResponse,
  getAIAssistantChatHistoryFailure,
  getAIAssistantChatHistoryResponse,
  logoutRequest,
  sendAIAssistantNewChatFailure,
  sendAIAssistantNewChatResponse,
  updateUserDetailRequest
} from 'actions';
import { commonApiTypes } from 'types';
import { AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG, AI_ASSISTANT_NEW_CHAT_FAILURE_MSG, ALERT_BOX_TYPES, API_TYPES } from 'utils/constants';
import { AI_ASSISTANT_DELETE_REQUEST, AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST, AI_ASSISTANT_SEND_NEW_CHAT_REQUEST } from 'actions/action-types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetAIAssistantChatHistoryRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, ...NODE_API.getAIAssistantChatHistory, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIAssistantChatHistoryResponse(response.data.data.chatHistory));
    } else {
      yield put(getAIAssistantChatHistoryFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(getAIAssistantChatHistoryFailure(AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}
function* sendCreateAIAssistantChatRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    yield delay(1000);
    yield put(aiAssistantGenerating());
    const response = yield apiCall({ headers, data: payload, ...NODE_API.createAIAssistant, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (!payload.assistantId && !payload.threadId) {
        yield put(updateUserDetailRequest({ userId: payload.userId, reqPayload: { ai_assistant_id: response.data.data.assistantId, ai_thread_id: response.data.data.threadId } }));
      }
      yield put(sendAIAssistantNewChatResponse(response.data.data.chatHistory[1]));
    } else {
      yield put(sendAIAssistantNewChatFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ASSISTANT_NEW_CHAT_FAILURE_MSG);
    yield put(sendAIAssistantNewChatFailure(AI_ASSISTANT_NEW_CHAT_FAILURE_MSG));
  }
}

function* deleteAIAssistantsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, data: payload, ...NODE_API.deleteAIAssistants, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(deleteAIAssistantResponse(response.data.message));
    } else {
      yield put(deleteAIAssistantFailure(response.data.message));
    }
  } catch (error: any) {
    alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG);
    yield put(getAIAssistantChatHistoryFailure(AI_ASSISTANT_CHAT_HISTORY_LIST_FAILURE_MSG));
  }
}

export function* takeAIAssistantRequest() {
  yield takeLatest(AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST, sendGetAIAssistantChatHistoryRequest);
  yield takeLatest(AI_ASSISTANT_SEND_NEW_CHAT_REQUEST, sendCreateAIAssistantChatRequest);
  yield takeLatest(AI_ASSISTANT_DELETE_REQUEST, deleteAIAssistantsRequest);
}
