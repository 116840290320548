// CAMPAIGNS LIST
export const CAMPAIGNS_LIST_REQUEST = 'campaigns/CAMPAIGNS_LIST_REQUEST';
export const CAMPAIGNS_LIST_RESPONSE = 'campaigns/CAMPAIGNS_LIST_RESPONSE';
export const CAMPAIGNS_LIST_FAILURE = 'campaigns/CAMPAIGNS_LIST_FAILURE';

// CAMPAIGNS STATS DATA
export const CAMPAIGNS_STATS_REQUEST = 'campaigns/CAMPAIGNS_STATS_REQUEST';
export const CAMPAIGNS_STATS_RESPONSE = 'campaigns/CAMPAIGNS_STATS_RESPONSE';
export const CAMPAIGNS_STATS_FAILURE = 'campaigns/CAMPAIGNS_STATS_FAILURE';

// GET CAMPAIGNS EVENT LIST
export const GET_CAMPAIGNS_EVENT_LIST_REQUEST = 'campaigns/GET_CAMPAIGNS_EVENT_LIST_REQUEST';
export const GET_CAMPAIGNS_EVENT_LIST_RESPONSE = 'campaigns/GET_CAMPAIGNS_EVENT_LIST_RESPONSE';
export const GET_CAMPAIGNS_EVENT_LIST_FAILURE = 'campaigns/GET_CAMPAIGNS_EVENT_LIST_FAILURE';

// GET CAMPAIGNS EVENT
export const GET_CAMPAIGNS_EVENT_REQUEST = 'campaigns/GET_CAMPAIGNS_EVENT_REQUEST';
export const GET_CAMPAIGNS_EVENT_RESPONSE = 'campaigns/GET_CAMPAIGNS_EVENT_RESPONSE';
export const GET_CAMPAIGNS_EVENT_FAILURE = 'campaigns/GET_CAMPAIGNS_EVENT_FAILURE';

// CREATE OR UPDATE CAMPAIGN EVENT
export const CREATE_OR_UPDATE_CAMPAIGN_EVENT_REQUEST = 'campaigns/CREATE_OR_UPDATE_CAMPAIGN_EVENT_REQUEST';
export const CREATE_OR_UPDATE_CAMPAIGN_EVENT_RESPONSE = 'campaigns/CREATE_OR_UPDATE_CAMPAIGN_EVENT_RESPONSE';
export const CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAILURE = 'campaigns/CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAILURE';

// STOP CAMPAIGN EVENT
export const STOP_CAMPAIGN_EVENT_REQUEST = 'campaigns/STOP_CAMPAIGN_EVENT_REQUEST';
export const STOP_CAMPAIGN_EVENT_RESPONSE = 'campaigns/STOP_CAMPAIGN_EVENT_RESPONSE';
export const STOP_CAMPAIGN_EVENT_FAILURE = 'campaigns/STOP_CAMPAIGN_EVENT_FAILURE';

// CAMPAIGN DELETE EVENT
export const CAMPAIGN_POSTS_DELETE_EVENT_REQUEST = 'campaigns/CAMPAIGN_POSTS_DELETE_EVENT_REQUEST';
export const CAMPAIGN_POSTS_DELETE_EVENT_RESPONSE = 'campaigns/CAMPAIGN_POSTS_DELETE_EVENT_RESPONSE';
export const CAMPAIGN_POSTS_DELETE_EVENT_FAILURE = 'campaigns/CAMPAIGN_POSTS_DELETE_EVENT_FAILURE';

// CREATE OR EDIT CAMPAIGN
export const CREATE_OR_EDIT_CAMPAIGN_REQUEST = 'campaigns/CREATE_OR_EDIT_CAMPAIGN_REQUEST';
export const CREATE_OR_EDIT_CAMPAIGN_RESPONSE = 'campaigns/CREATE_OR_EDIT_CAMPAIGN_RESPONSE';
export const CREATE_OR_EDIT_CAMPAIGN_FAILURE = 'campaigns/CREATE_OR_EDIT_CAMPAIGN_FAILURE';

// GET CAMPAIGN DETAIL
export const GET_CAMPAIGN_DETAIL_REQUEST = 'campaigns/GET_CAMPAIGN_DETAIL_REQUEST';
export const GET_CAMPAIGN_DETAIL_RESPONSE = 'campaigns/GET_CAMPAIGN_DETAIL_RESPONSE';
export const GET_CAMPAIGN_DETAIL_FAILURE = 'campaigns/GET_CAMPAIGN_DETAIL_FAILURE';

// DELETE CAMPAIGN
export const DELETE_CAMPAIGN_REQUEST = 'campaigns/DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_RESPONSE = 'campaigns/DELETE_CAMPAIGN_RESPONSE';
export const DELETE_CAMPAIGN_FAILURE = 'campaigns/DELETE_CAMPAIGN_FAILURE';

// DEACTIVATE CAMPAIGN EVENT
export const DEACTIVATE_CAMPAIGN_EVENT_REQUEST = 'campaigns/DEACTIVATE_CAMPAIGN_EVENT_REQUEST';
export const DEACTIVATE_CAMPAIGN_EVENT_RESPONSE = 'campaigns/DEACTIVATE_CAMPAIGN_EVENT_RESPONSE';
export const DEACTIVATE_CAMPAIGN_EVENT_FAILURE = 'campaigns/DEACTIVATE_CAMPAIGN_EVENT_FAILURE';

// CAMPAIGN SORT POSTS LIST
export const CAMPAIGN_SORT_POSTS_LIST_REQUEST = 'campaigns/CAMPAIGN_SORT_POSTS_LIST_REQUEST';
export const CAMPAIGN_SORT_POSTS_LIST_RESPONSE = 'campaigns/CAMPAIGN_SORT_POSTS_LIST_RESPONSE';
export const CAMPAIGN_SORT_POSTS_LIST_FAILURE = 'campaigns/CAMPAIGN_SORT_POSTS_LIST_FAILURE';

// ADD POSTS TO CAMPAIGN
export const ADD_POSTS_TO_CAMPAIGN_REQUEST = 'campaigns/ADD_POSTS_TO_CAMPAIGN_REQUEST';
export const ADD_POSTS_TO_CAMPAIGN_RESPONSE = 'campaigns/ADD_POSTS_TO_CAMPAIGN_RESPONSE';
export const ADD_POSTS_TO_CAMPAIGN_FAILURE = 'campaigns/ADD_POSTS_TO_CAMPAIGN_FAILURE';

// CAMPAIGNS DELETE POSTS
export const CAMPAIGNS_DELETE_POSTS_REQUEST = 'campaigns/CAMPAIGNS_DELETE_POSTS_REQUEST';
export const CAMPAIGNS_DELETE_POSTS_RESPONSE = 'campaigns/CAMPAIGNS_DELETE_POSTS_RESPONSE';
export const CAMPAIGNS_DELETE_POSTS_FAILURE = 'campaigns/CAMPAIGNS_DELETE_POSTS_FAILURE';

// CAMPAIGNS POST LIST
export const CAMPAIGNS_POST_LIST_REQUEST = 'campaigns/CAMPAIGNS_POST_LIST_REQUEST';
export const CAMPAIGNS_POST_LIST_RESPONSE = 'campaigns/CAMPAIGNS_POST_LIST_RESPONSE';
export const CAMPAIGNS_POST_LIST_FAILURE = 'campaigns/CAMPAIGNS_POST_LIST_FAILURE';

// CAMPAIGNS SORT POST LIST
export const CAMPAIGNS_SORT_POST_LIST = 'campaigns/CAMPAIGNS_SORT_POST_LIST';

export const SET_MULTIPLE_POSTS_TO_CAMPAIGN = 'campaigns/SET_MULTIPLE_POSTS_TO_CAMPAIGN';

export const CAMPAIGN_RESPONSE_MSG_RESET = 'campaigns/CAMPAIGN_RESPONSE_MSG_RESET';
export const CAMPAIGN_DETAIL_RESET = 'campaigns/CAMPAIGN_DETAIL_RESET';
export const CAMPAIGN_SELECTED_POSTS_RESET = 'campaigns/CAMPAIGN_SELECTED_POSTS_RESET';
export const CAMPAIGN_EVENT_DETAIL_RESET = 'campaigns/CAMPAIGN_EVENT_DETAIL_RESET';
export const CAMPAIGNS_RESET_ALL = 'campaigns/CAMPAIGNS_RESET_ALL';
