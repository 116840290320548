import { commonProps } from 'types';

export const SALES_QUERY_OBJ_FIELD = {
  NAME: 'name',
  COMPANY: 'company',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  LOCATIONS_COUNT: 'location_count',
  REGARDING: 'regarding',
  COMMENT: 'comment'
};

export const SALES_REGARDING_LIST: commonProps.IMainPageRouteData[] = [
  { name: 'rallio-platform', label: 'Rallio Platform' },
  { name: 'rallio-local', label: 'Rallio Local' },
  { name: 'revv', label: 'REVV' },
  { name: 'rallio-activate', label: 'Rallio Activate' },
  { name: 'business-listing', label: 'Business Listings' },
  { name: 'schedule-a-free-consultation', label: 'Schedule a Free Consultation' },
  { name: 'all', label: 'I want it ALL' }
];
