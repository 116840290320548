import { useState, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { ImageValidation } from 'widgets/Media';
import { IStore } from 'types';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { debounceMethod, getCampaignsReqParams } from 'utils/helpers';
import { useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';
import { CAMPAIGN_INIT_FILTER_DATA, COMMON_SOCIAL_PROVIDER_TYPE, CampaignsStatus, CampaignsValidQueryParams } from 'utils/constants';
import { CommonTotalStatsCount } from 'widgets/TotalStatsCount';
import { campaignsStatsRequest } from 'actions';

export const CampaignStats = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);

  const isStatsFetching = useSelector((state: IStore) => state.campaigns.isStatsFetching);
  const active = useSelector((state: IStore) => state.campaigns.campaignStats.active);
  const inactive = useSelector((state: IStore) => state.campaigns.campaignStats.inactive);
  const available = useSelector((state: IStore) => state.campaigns.campaignStats.available);
  const total = useSelector((state: IStore) => state.campaigns.campaignStats.total);
  const successMsg = useSelector((state: IStore) => state.campaigns.responseMsg.successMsg);

  const [searchText, setSearchText] = useState(queryParams?.text || '');

  const campaignsListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  // Get campaign stats
  useEffect(() => {
    if (id && userOwnership && isValidId) {
      handleCampaignStats();
    }
  }, [id, userOwnership, isValidId, queryParams?.source, queryParams?.text]); // eslint-disable-line

  const handleCampaignStats = () => {
    const reqParams = getCampaignsReqParams(id, userOwnership, successMsg, queryParams);
    dispatch(campaignsStatsRequest(reqParams));
  };

  const handleCampaignsFilterChange = (key: string, value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj[key];
    else if (value) queryParamsObj[key] = value;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleInputSearch = (value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value) queryParamsObj.text = value;
    else delete queryParamsObj.text;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  return (
    <>
      <div className={`stats animate__animated animate__fadeIn content-post-wrp-stats global-stats-section-wrp campaignStatsOnly lpx vpy-20 g-20`}>
        <div className="stats-item total-count cmp-item stats-level-03" onClick={() => handleCampaignsFilterChange(CampaignsValidQueryParams.STATUS, CampaignsStatus.ALL)}>
          <div className="chart-module-top">
            <span className="mod-head">Campaigns</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="mod-count">
                <ReactSpringCountUp end={total} />
              </span>
            )}
          </div>
          <div className={total ? `chart-container` : 'd-none'}>
            <CommonTotalStatsCount
              isStatsFetching={isStatsFetching}
              totalCount={total}
              statsData={[
                { label: CampaignsStatus.ACTIVE, value: active, colorCode: '#21D184' },
                { label: CampaignsStatus.IN_ACTIVE, value: inactive, colorCode: '#5B6A78' },
                { label: CampaignsStatus.AVAILABLE, value: available, colorCode: '#495AA6' }
              ]}
            />
          </div>
        </div>
        <div
          className={`stats-item fb-sc si-approved cmActive cmp-item stats-level-03${queryParams?.status === CampaignsStatus.ACTIVE ? ` active` : ``}`}
          onClick={() => handleCampaignsFilterChange(CampaignsValidQueryParams.STATUS, CampaignsStatus.ACTIVE)}
        >
          <div className="chart-module-top">
            <span className="platform-text">Active Campaigns</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="platform-count">
                <ReactSpringCountUp end={active} />
              </span>
            )}
          </div>
          <div className="platform-icon">
            <ImageValidation isImgValid defaultImg="campaign-active-green" customName={'Active Campaign'} />
          </div>
        </div>

        <div
          className={`stats-item fb-sc si-approved cmActive cmp-item stats-level-03 inactive-campaign${queryParams?.status === CampaignsStatus.IN_ACTIVE ? ` active` : ``}`}
          onClick={() => handleCampaignsFilterChange(CampaignsValidQueryParams.STATUS, CampaignsStatus.IN_ACTIVE)}
        >
          <div className="chart-module-top">
            <span className="platform-text">Inactive Campaigns</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="platform-count">
                <ReactSpringCountUp end={inactive} />
              </span>
            )}
          </div>
          <div className="platform-icon">
            <ImageValidation isImgValid defaultImg="campaign-inactive-grey" customName={'Inactive Campaign'} />
          </div>
        </div>

        <div
          className={`stats-item fb-sc cmAvailable cmp-item stats-level-03${queryParams?.status === CampaignsStatus.AVAILABLE ? ` active` : ``}`}
          onClick={() => handleCampaignsFilterChange(CampaignsValidQueryParams.STATUS, CampaignsStatus.AVAILABLE)}
        >
          <div className="chart-module-top">
            <span className="platform-text">Available Campaigns</span>
            {isStatsFetching ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <span className="platform-count">
                <ReactSpringCountUp end={available} />
              </span>
            )}
          </div>
          <div className="platform-icon">
            <ImageValidation isImgValid defaultImg="campaign-available-blue" customName={'Available Campaign'} />
          </div>
        </div>

        <div className="stats-item sicp stats-level-03" onClick={() => handleCampaignsFilterChange(CampaignsValidQueryParams.CREATE_CAMPAIGN, '1')}>
          <span className="mod-head">CREATE CAMPAIGN</span>
        </div>
      </div>
      <div className={`search-tk post-search post-list__search cp-rtSearch lpx r-mt0`}>
        <div className="react-tags">
          <div className="react-tags__search">
            <div className="react-tags__search-input">
              <input
                type="text"
                placeholder={'Search campaign'}
                autoComplete="off"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onKeyUp={() => (campaignsListApiTimeout.current = debounceMethod(searchText.trim(), campaignsListApiTimeout.current, handleInputSearch))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
