import { Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';

import { Loading, HeaderToast, ErrorBoundaryPage, Banner, CustomBanner, CSBanner } from 'components';
import { IStore, commonRouterTypes } from 'types';
import { ROUTES, USER_OWNERSHIP, NAV_BAR, APP_TITLE, IMPERSONATE_RESPONSE_USER_KEY, COMMON_ROUTES } from 'utils/constants';
import { SalesPageRoot } from './sales-page-root';
import { useAccountSwitcherData, useNavBarData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { capitalizeName } from 'utils/helpers';
import { BackToTopButton } from 'widgets/Button';

export const PrivateRoute: React.FunctionComponent<commonRouterTypes.IPrivateRoute> = ({ routeObj, isMenuAccessible }: commonRouterTypes.IPrivateRoute) => {
  const Component = routeObj.component;
  const location = useLocation();
  const navigate = useNavigate();

  const { userOwnership, id, isAdminScreenEnabled, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { mainNavPageName, subNavPageName, selectedSubNavData } = useNavBarData();

  const isAuthenticated = useSelector((state: IStore) => state.login.isAuthenticated);
  const error = useSelector((state: IStore) => state.errorBoundary.error);
  const mobileFilter = useSelector((state: IStore) => state.common.mobileFilter);
  const leftNav = useSelector((state: IStore) => state.common.leftNav);
  const isAccountFetching = useSelector((state: IStore) => state.accountSwitcher.isAccountFetching);
  const bannerList = useSelector((state: IStore) => state.accountSwitcher.bannerList);
  const isSSO = useSelector((state: IStore) => state.login.isSSO);
  const isEnvFetching = useSelector((state: IStore) => state.common.isEnvFetching);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const isLogoutClicked = useSelector((state: IStore) => state.login.isLogoutClicked);
  const accountProfilesAlerts = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0].alerts) || [];
  const accountId = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.id) || 0;

  const userOwnershipTitle = userOwnership === USER_OWNERSHIP.FRANCHISOR ? `- ${capitalizeName(userOwnership)} -` : `-`;
  const revvFilterHubLocation = useSelector((state: IStore) => state.revv.filter.hubLocation);

  document.title = `${APP_TITLE} ${userOwnershipTitle} ${
    NAV_BAR.find((datum) => datum.name === location.pathname.split('/')[1] || datum.name === location.pathname.split('/')[3])?.menu ||
    COMMON_ROUTES.find((route) => route.link === mainNavPageName)?.menu
  }`;

  const renderLoading = useCallback(
    () => (
      <section id="main-container-sec" className={`item-g container-g ${leftNav !== mobileFilter ? 'nav-on' : ''} ${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'hub-user' : 'location-user'}`}>
        <div className="content-g" id="scrollTarget">
          <Loading />
        </div>
      </section>
    ),
    [leftNav, mobileFilter, userOwnership]
  );

  const handleLogOut = () => {
    if (!isLogoutClicked && !localStorage?.getItem(IMPERSONATE_RESPONSE_USER_KEY)) {
      localStorage?.setItem('pathname', location.pathname);
      localStorage?.setItem('search', location.search);
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  const handleRedirectPage = () => {
    let route = ROUTES.CONTENT_POSTS.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', '');
    if (mainNavPageName && subNavPageName) {
      if (selectedSubNavData?.length && !selectedSubNavData.some((it) => it.name === subNavPageName)) {
        route = `/${userOwnership}/${id}/${mainNavPageName}/${selectedSubNavData[0].name}`;
      }
    }
    if (isAdminScreenEnabled) {
      route = ROUTES.ADMIN_ACCOUNTS.replace('/*', '');
    }
    navigate(route, { replace: true });
  };

  const handleRevvParentClass = (parent: string, child: string) => {
    const subPage = ['revv', 'revv_settings', 'revv_teams', 'analytics_revv', 'customers'];
    const childPage = ['revv', 'locations', 'teams'];
    return !!subPage.find((it) => it === parent || it === child) || !!childPage.find((it) => it === optionalParams[0]);
  };

  return (
    <>
      {isEnvFetching || isDataLoading ? (
        renderLoading()
      ) : isAuthenticated && isMenuAccessible ? (
        <>
          <Suspense fallback={renderLoading()}>
            {error ? (
              <ErrorBoundaryPage />
            ) : (
              <>
                <section
                  id="main-container-sec"
                  className={`item-g container-g ${leftNav !== mobileFilter ? 'nav-on' : ''} ${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'hub-user' : 'location-user'} ${
                    handleRevvParentClass(mainNavPageName, subNavPageName) && revvFilterHubLocation?.length === 0
                      ? 'revv-nonpsp-layout custom-disabled'
                      : handleRevvParentClass(mainNavPageName, subNavPageName) && revvFilterHubLocation?.length !== 0
                      ? 'revv-nonpsp-layout'
                      : ''
                  }
                  `}
                >
                  <div className="content-g" id="scrollTarget">
                    {isCSLocation ? <CSBanner /> : <Banner />}
                    {bannerList?.length
                      ? bannerList.map((it, index) => {
                          return <CustomBanner key={index} isBannerFixed={it.display_type === 'fixed'} cutomTextMessage={it.banner_text || ''} />;
                        })
                      : null}
                    {userOwnership === USER_OWNERSHIP.ACCOUNT && accountId ? <HeaderToast accountProfilesAlerts={accountProfilesAlerts} /> : null}
                    {isAccountFetching ? (
                      <Loading />
                    ) : (
                      <SalesPageRoot>
                        <Component />
                      </SalesPageRoot>
                    )}
                    <BackToTopButton isEnabled={!isDataLoading} scrollElementId={'scrollTarget'} buttonId={'backToTopBtn'} />
                  </div>
                </section>
              </>
            )}
          </Suspense>
        </>
      ) : isAuthenticated && !isMenuAccessible ? (
        handleRedirectPage()
      ) : isSSO ? (
        <Loading />
      ) : (
        handleLogOut()
      )}
    </>
  );
};
