import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { hasFranFullAccess } from 'utils/helpers';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from '../use-account-switcher-data';

export const useAllowAccess = () => {
  const { id, userOwnership, isValidId } = useAccountSwitcherData();

  const userId = useSelector((state: IStore) => state.login.activeUser?.id);
  const contentWriters = useSelector((state: IStore) => state.login.activeUser?.content_writers || []);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);

  const franchisorFullAccess = useMemo(() => {
    return userId && userOwnership === USER_OWNERSHIP.FRANCHISOR && isValidId ? hasFranFullAccess(isAdmin, id, contentWriters) : false;
  }, [userId, userOwnership, isValidId, isAdmin, id, contentWriters]);

  return { franchisorFullAccess };
};
