import { scReducerTypes } from 'types';
import { COMMON_DATE_RANGE_INIT_VALUE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';

export const SC_FILTER_INIT_VALUE: scReducerTypes.ISCFiltersValue = {
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  dateRange: COMMON_DATE_RANGE_INIT_VALUE
};

export const SC_STATS_INIT_VALUE: scReducerTypes.ISCStatsValue = {
  facebook: 0,
  instagram: 0,
  linkedin: 0,
  twitter: 0,
  tiktok: 0,
  totalStatCount: 0
};
