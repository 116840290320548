import { settingsRallioProfileContainerTypes } from 'types';
import { RALLIO_PROFILE_TAB_FILEDS, CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RIPPLE_COLOR, VALID_PHONE_NUMBER_REGEX_PROFILE } from 'utils/constants';
import { compareProfileObjects } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const AddressSection = ({
  countriesCodeList,
  countriesData,
  setCountriesData,
  timeZonesList,
  timeZonesData,
  setTimeZonesData,
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames,
  handleIndividualCancel,
  handleIndividualSave,
  isAgencyProfileSection
}: settingsRallioProfileContainerTypes.ISettingsRPAddressProps) => {
  const { optionalParams } = useAccountSwitcherData();

  return (
    <div className="settings-rallio-profile-new-section wn-address wnh wnMerge">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">{isAgencyProfileSection ? 'Contact Info' : 'Address'}</h3>
        </div>
        <div className="content-right-section-second-item form-elements-new">
          {/* // address first row */}
          {
            <div className={`form-group form-field-item m-form-align r-ml0 full-width ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.ADDRESS)}`}>
              <input
                type="text"
                name={RALLIO_PROFILE_TAB_FILEDS.ADDRESS}
                className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.ADDRESS)}
                value={String(values[RALLIO_PROFILE_TAB_FILEDS.ADDRESS]) || ''}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(RALLIO_PROFILE_TAB_FILEDS.ADDRESS, e.target.value);
                }}
              />
              <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.ADDRESS)}`}>
                Address
                {/* {actualValues.required_field === APPROVED_STATUS.YES && <span className="mandatory cursor-help">*</span>} */}
              </span>
            </div>
          }

          {/* // address second-row row */}
          <div className="add-section-second-row-item m-form-align r-ml0 full-width">
            <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.CITY)}`}>
              <input
                type="text"
                name={RALLIO_PROFILE_TAB_FILEDS.CITY}
                className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.CITY)}
                value={String(values[RALLIO_PROFILE_TAB_FILEDS.CITY] || '')}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(RALLIO_PROFILE_TAB_FILEDS.CITY, e.target.value);
                }}
              />
              <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.CITY)}`}>City</span>
            </div>

            <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE)}`}>
              <input
                type="text"
                name={RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE}
                className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE)}
                value={String(values[RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE] || '')}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE, e.target.value);
                }}
              />
              <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE)}`}>
                State / Province
                {/* {actualValues.required_field === APPROVED_STATUS.YES && <span className="mandatory cursor-help">*</span>} */}
              </span>
            </div>

            {
              <div className={`form-group form-field-item  ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE)}`}>
                <input
                  type="text"
                  name={RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE}
                  className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE)}
                  value={String(values[RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE] || '')}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE, e.target.value);
                  }}
                />
                <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE)}`}>
                  Zip / Postal Code
                  {/* <span className="mandatory cursor-help">*</span> */}
                </span>
              </div>
            }
          </div>

          {/* // Address section third row */}
          <div className="add-section-third-row-item ml-0">
            <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.COUNTRY)}`}>
              <CustomDropdown
                classNamePrefix="cs-select"
                id="dl-store-details-country-dropdown"
                placeholder={''}
                onChange={(e: any) => {
                  handleChange(RALLIO_PROFILE_TAB_FILEDS.COUNTRY, e.id);
                  setCountriesData({ id: e.id, value: e.value, label: e.label });
                }}
                value={countriesData}
                options={countriesCodeList}
              />
              <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.COUNTRY)}`}>
                Country
                <span className="mandatory cursor-help">*</span>
              </span>
            </div>

            {
              <div className={`form-group form-field-item m-align-items ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER)}`}>
                <input
                  type="text"
                  maxLength={values[RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]?.length === 16 ? 16 : undefined}
                  name={RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER}
                  className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER)}
                  value={String(values[RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER] || '')}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER, e.target.value);
                  }}
                  onKeyPress={(event) => {
                    const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX_PROFILE);
                    const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                    if (!regexData.test(enteredKey)) {
                      event.preventDefault();
                      return false;
                    }
                  }}
                />
                <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER)}`}>
                  Phone Number
                  {/* {actualValues.required_field === APPROVED_STATUS.YES && <span className="mandatory cursor-help">*</span>} */}
                </span>
              </div>
            }

            {!isAgencyProfileSection ? (
              <div className={`form-group form-field-item s fg-dropdown m-align-items cs-item cs-clean s-mb2 ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE)}`}>
                <CustomDropdown
                  classNamePrefix="cs-select"
                  id="dl-store-details-time-zone"
                  // menuIsOpen={true}  // need to remove
                  placeholder={''}
                  onChange={(e: any) => {
                    handleChange(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE, e.value);
                    setTimeZonesData({ id: e.id, value: e.value, label: e.label });
                  }}
                  value={timeZonesData}
                  options={timeZonesList}
                />
                <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.TIME_ZONE)}`}>
                  Time Zone
                  <span className="mandatory cursor-help">*</span>
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.ADDRESS, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.ADDRESS)}>
                Cancel
              </button>
            </CustomRippleButton>
            {getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.NAME) !== 'erroritem' ? (
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary size-xs`} onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.ADDRESS)}>
                  Save
                </button>
              </CustomRippleButton>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
