import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { commonProps, IStore, settingsSocialProfilContainerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, CONNECTION_TABS, RIPPLE_COLOR } from 'utils/constants';
import { handleLinkNavigation, disconnectSocialMedia } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { InstagramConnectModal } from './InstagramConnectModal';
import { useSocialMedia } from './useSocialMedia';

export const SocialProfileInstagram = ({ getFbViewPages, renderLabel }: Omit<settingsSocialProfilContainerTypes.ISPFbProps, 'setStopSpinnerOnCancel' | 'setShowPageModal'>) => {
  const navigate = useNavigate();
  const { id, userOwnership, optionalParams, isAdminScreenEnabled, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ text: '', include_hidden_accounts: '0' });
  const instagramMedia = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE);
  // const instagramPersonalMedia = useSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);

  const optionalParamsToUrl = optionalParams.join('/');
  const isShowReconnectErr = instagramMedia?.needs_reconnection; /* || instagramPersonalMedia?.needs_reconnection */

  const handleURLPathname = (route: string, search?: commonProps.ICommonObj<string>) => {
    if (isAdmin && isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
  };

  return (
    <>
      {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || accountDetails?.account_profiles[0]?.access.platform_instagram_access ? (
        <div className="csp-item ss-metafb d-flex justify-content-between cspInstagram">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="instagram" customName="social logo" />
              </div>
              <span className="sp-txt">Instagram</span>
            </div>
            <div className="sm-added-details">
              <span className="smadtxt">Business Account</span>
            </div>
          </aside>
          <>
            {
              <div className="csp-action g-15">
                {instagramMedia ? (
                  <Button
                    className="ac-btn ac-link-btn primeblueaction"
                    variant="link"
                    onClick={() => {
                      handleLinkNavigation(instagramMedia.profile_url);
                    }}
                  >
                    View page
                  </Button>
                ) : null}
                <CustomRippleButton
                  rippleClass={`ripple-unset ${isShowReconnectErr ? 'ac-danger-box' : instagramMedia ? 'ac-secondary-box' : 'ac-primary-box'} `}
                  custColor={isShowReconnectErr ? RIPPLE_COLOR.danger : instagramMedia ? RIPPLE_COLOR.whiteGrey : RIPPLE_COLOR.primary}
                >
                  <button
                    className={`ac-btn h-default r-flx r-flx-jc r-flx-ac ${isShowReconnectErr ? 'ac-danger' : instagramMedia ? 'ac-secondary-grey' : 'ac-primary'}`}
                    onClick={() => {
                      handleURLPathname(`/${currentRoute}/${optionalParamsToUrl ? optionalParamsToUrl : ''}`, { connect: COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM });

                      // handleURLPathname(`${currentRoute}/connect/instagram`)
                    }}
                  >
                    {renderLabel(
                      COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE,
                      'light',
                      isShowReconnectErr ? 'Reconnect' : instagramMedia ? 'Disconnect' : 'Connect Now'
                    )}
                  </button>
                </CustomRippleButton>
                {isShowReconnectErr ? (
                  <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                    <button
                      className={`ac-btn ac-secondary-grey h-default r-flx r-flx-jc r-flx-ac`}
                      onClick={() => {
                        handleURLPathname(`/${currentRoute}/${optionalParamsToUrl ? optionalParamsToUrl : ''}`, { connect: COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM });
                      }}
                    >
                      {renderLabel(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, 'primary', 'Disconnect')}
                    </button>
                  </CustomRippleButton>
                ) : null}
              </div>
            }
          </>
        </div>
      ) : // <div className="csp-item settings-insta sm-added__wrp">
      null}
      <InstagramConnectModal
        isShowModal={queryParams?.connect === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM}
        getFbViewPages={getFbViewPages}
        disconnectSocialMedia={disconnectSocialMedia}
        onModalClose={() => {
          delete queryParams?.connect;
          handleURLPathname(`/${currentRoute}/${optionalParamsToUrl ? optionalParamsToUrl : ''}`);
        }}
      />
    </>
  );
};
