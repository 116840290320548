import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SocialContentsAction } from 'actions';
import { scReducerTypes } from 'types';
import { DONE_SUCCESS_MSG, SC_STATS_INIT_VALUE } from 'utils/constants';
import { updateScList, updateScDelete, updateScLike, updateScReply, updateScStats } from 'utils/helpers';

type ISCReducer = scReducerTypes.ISCReducer;

const initialState: ISCReducer = {
  isStatsFetching: true,
  isListFetching: true,
  pieReloadStatus: null,
  isStatsCall: true,
  socialContentsStats: SC_STATS_INIT_VALUE,
  socialContentsList: [],
  brandActivePlatforms: [],
  savedPostId: null,
  currentPageCount: 1,
  error: null
};

const socialContentsReducer: Reducer<ISCReducer, SocialContentsAction> = (state: ISCReducer = initialState, action: SocialContentsAction): ISCReducer => {
  switch (action.type) {
    // Social contents stats
    case types.SOCIAL_CONTENTS_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        isStatsCall: false
      };
    case types.SOCIAL_CONTENTS_STATS_RESPONSE:
      return {
        ...state,
        isStatsFetching: false,
        socialContentsStats: {
          facebook: action.payload.stats_by_platform.facebook || 0,
          linkedin: action.payload.stats_by_platform.linkedin || 0,
          tiktok: action.payload.stats_by_platform.tiktok || 0,
          instagram: action.payload.stats_by_platform.instagram || action.payload.stats_by_platform.instagram_business_page || action.payload.stats_by_platform.instagram_public_content || 0,
          twitter: action.payload.stats_by_platform.twitter || 0,
          totalStatCount: action.payload.total_count || 0
        }
      };
    case types.SOCIAL_CONTENTS_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // Social contents pie reload
    case types.SOCIAL_CONTENTS_PIE_RELOAD_REQUEST:
      return {
        ...state,
        isListFetching: true,
        pieReloadStatus: null
      };
    case types.SOCIAL_CONTENTS_PIE_RELOAD_RESPONSE:
      return {
        ...state,
        isListFetching: false,
        pieReloadStatus: DONE_SUCCESS_MSG
      };
    case types.SOCIAL_CONTENTS_PIE_RELOAD_FAILURE:
      return {
        ...state,
        isListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // Social contents lists
    case types.SOCIAL_CONTENTS_LIST_REQUEST:
      return {
        ...state,
        isListFetching: true,
        currentPageCount: action.payload.page || 1
      };
    case types.SOCIAL_CONTENTS_LIST_RESPONSE:
      return {
        ...state,
        isListFetching: false,
        socialContentsList: updateScList(state.currentPageCount, state.socialContentsList, action.payload.social_contents)
      };
    case types.SOCIAL_CONTENTS_LIST_FAILURE:
      return {
        ...state,
        isListFetching: false,
        socialContentsList: [],
        error: action.payload || 'Unexpected error'
      };
    // Social contents like
    case types.SOCIAL_CONTENTS_LIKE_REQUEST:
      return {
        ...state
      };
    case types.SOCIAL_CONTENTS_LIKE_RESPONSE:
      return {
        ...state,
        socialContentsList: updateScLike(state.socialContentsList, action.payload)
      };
    case types.SOCIAL_CONTENTS_LIKE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Social contents reuse
    case types.SOCIAL_CONTENTS_REUSE_REQUEST:
      return {
        ...state
      };
    case types.SOCIAL_CONTENTS_REUSE_RESPONSE:
      return {
        ...state,
        savedPostId: action.payload
      };
    case types.SOCIAL_CONTENTS_REUSE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Social contents reply
    case types.SOCIAL_CONTENTS_REPLY_REQUEST:
      return {
        ...state
      };
    case types.SOCIAL_CONTENTS_REPLY_RESPONSE:
      return {
        ...state,
        socialContentsList: updateScReply(state.socialContentsList, action.payload)
      };
    case types.SOCIAL_CONTENTS_REPLY_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Social contents delete post
    case types.SOCIAL_CONTENTS_DELETE_REQUEST:
      return {
        ...state
      };
    case types.SOCIAL_CONTENTS_DELETE_RESPONSE:
      return {
        ...state,
        socialContentsList: updateScDelete(state.socialContentsList, action.payload.id),
        socialContentsStats: updateScStats(state.socialContentsStats, action.payload.platform)
      };
    case types.SOCIAL_CONTENTS_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // Social contents brand active platforms
    case types.SOCIAL_CONTENTS_BRAND_ACTIVE_PLATFORMS:
      return {
        ...state,
        brandActivePlatforms: action.payload
      };
    // Social contents stats call
    case types.SOCIAL_CONTENTS_IS_STATS_CALL:
      return {
        ...state,
        isStatsCall: action.payload
      };
    // Social contents reset all
    case types.SOCIAL_CONTENTS_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};

export { socialContentsReducer };
