import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ModalPopup } from 'components';
import { commonModalPopup, IStore } from 'types';
import { ACTIVE_CAMPAIGN_CONFIRM_MSG, DELETE_CAMPAIGN_CONFIRM_MSG, MODAL_BOX_TYPES, DELETE_ACTIVE_CAMPAIGN_CONFIRM_MSG } from 'utils/constants';
import { deleteCampaignRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';

export const DeleteCampaignConfirmModal = ({ campaignId, isShowModal, onModalClose }: commonModalPopup.IDeleteCampaignConfirmModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();

  const status = useSelector((state: IStore) => state.campaigns.campaignDetail?.status);

  const handleDeleteCampaign = () => {
    if (campaignId) {
      dispatch(deleteCampaignRequest({ campaignId, onNavigate: () => navigate(`/${userOwnership}/${id}/${currentRoute}`) }));
      onModalClose();
    }
  };

  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      // containerClassName={'modal-confirmation confirm-delete pureDelete'}
      containerClassName={'cnfrm__delete--modal glbl__delete--modal'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{status === 'ACTIVE' ? DELETE_ACTIVE_CAMPAIGN_CONFIRM_MSG : DELETE_CAMPAIGN_CONFIRM_MSG}</div>
          {status === 'ACTIVE' ? <div className="modal-message-wraps">{ACTIVE_CAMPAIGN_CONFIRM_MSG}</div> : null}
        </div>
      )}
      modalCloseButton
      handleModalClose={onModalClose}
      isModalFooterShow
      modalFooter={() => (
        // <div className="modal-btn-grp-wraps">
        <div className="delete__btn--wrp">
          <button className="ac-btn ac-secondary-white ac-outline ac-block" onClick={onModalClose}>
            Cancel
          </button>
          <button className="ac-btn ac-danger ac-block" onClick={handleDeleteCampaign}>
            Delete
          </button>
        </div>
      )}
    />
  );
};
