import { Col, Row } from 'react-bootstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { IListViewData } from 'revv/types';

interface IListViewProps {
  data: IListViewData[];
}

export const ListView = ({ data }: IListViewProps) => {
  return (
    <Row className="lpx">
      <Col>
        <div className="table-main__skeleton survey-tbl__wrp">
          <Table>
            <Thead>
              <Tr>
                {data[0]?.subData?.map((sd, sdIndex) => (
                  <Th key={sdIndex}>{sd?.name}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((data, sdIndex) => (
                <Tr key={sdIndex}>
                  {data?.subData?.map((sd, sdIndex) => (
                    <Td
                      key={sdIndex}
                      className={sd.dataClassName || ''}
                      onClick={() => {
                        if (sd.onClick) {
                          sd.onClick(data.id);
                          // e.stopPropagation();
                        }
                      }}
                    >
                      {sd?.value}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};
