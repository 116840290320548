import { useEffect, useState, useMemo, useContext } from 'react';
import Tab from 'react-bootstrap/esm/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';

import { CustomReactTags } from 'widgets/CustomReactTags';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import {
  TOOLTIP_PLACEMENT,
  AP_EXTRA_INFO_TAB_NAMES,
  AP_LOCK_POST_TOOLTIP,
  AP_EMPLOYEE_ADVOCATES_TOOLTIP,
  COMMON_SOCIAL_PROVIDER_TYPE
} from /* , COMMON_SOCIAL_PROVIDER_TYPE, ADD_POST_CONTENT_MAX_TEXT_LENGTH */ 'utils/constants';
import { AddTextSection } from './AddTextSection';
import { postsTagsRequest, addPostSetTags, addPostGetSelectedContentObj, addPostSetLinkData, addPostSetActiveTab } from 'actions';
import { apAccountContainerTypes, IStore } from 'types';
import { formNewInternalTag, getUpdatedLinkInContentPost, debounceMethod, getUpdatedLinkInContentPostTextEditor } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { InternalNotesSection } from './InternalNotesSection';
import { EditorStateContext } from '..';
import { regexes } from 'widgets/DraftjsHashtagsPlugin/hashtagRegex';

export const LeftCreatorPostSection = (props: apAccountContainerTypes.IAddPostLeftSectionProps) => {
  const dispatch = useDispatch();

  const { activeContentList } = props;

  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const personalProfilesFeature = useSelector((state: IStore) => state.franchisors.franchisorDetails?.personal_profiles_feature);
  const activeTab = useSelector((state: IStore) => state.addPostFranchisorAction.activeTab);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);

  let tagsApiTimeout: null | NodeJS.Timeout = null;

  const [searchValue, setSearchValue] = useState('');

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];

  const hashTagsLimit = contentObj.customText.instagram.match(regexes.validHashtag) || [];

  const handleActiveSocialMedia = (socialMedia: string) => {
    return contentObj.customText[socialMedia].length || linkData ? true : false;
  };
  //  The post can't be posted in instagram when medias are not available
  useEffect(() => {
    if (!selectedMediaList.length) {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          postStatus: {
            ...contentObj.postStatus,
            instagram: hashTagsLimit.length > 30 ? false : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
            facebook: contentObj?.facebook_story || contentObj?.facebook_reel ? false : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK),
            twitter: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
            linkedin: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN),
            google: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE),
            tiktok: false
          }
        })
      );
    }
  }, [selectedMediaList.length]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(
  //     addPostGetSelectedContentObj({
  //       ...contentObj,
  //       postStatus: {
  //         ...contentObj.postStatus,
  //         instagram: !selectedMediaList.length || hashTagsLimit.length > 30 ? false : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
  //         twitter: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
  //         linkedin: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
  //       }
  //     })
  //   );
  // }, [selectedMediaList.length]); // eslint-disable-line

  //  Add link url to social media's text as hyperlink and remove link preview if medias are available
  useEffect(() => {
    if (activePlatforms?.length && selectedMediaList.length && linkData?.url) {
      dispatch(addPostGetSelectedContentObj(getUpdatedLinkInContentPost(activePlatforms, contentObj, linkData)));
      setEditorState(getUpdatedLinkInContentPostTextEditor(activePlatforms, contentObj, linkData));
      // dispatch(addPostSetCustomTextEditorData(getUpdatedLinkInContentPostTextEditor(activePlatforms, contentObj, linkData)));
      dispatch(addPostSetLinkData(null));
    }
  }, [activePlatforms?.length, selectedMediaList.length, linkData?.url]); // eslint-disable-line

  const tagsList = useMemo(() => {
    return formNewInternalTag(internalTagsList, searchValue, isContentSupplier);
  }, [internalTagsList, searchValue, isContentSupplier]);

  const handleInputChange = (search: string) => {
    setSearchValue(search);
    if (isContentSupplier) {
      dispatch(postsTagsRequest({ searchTag: search, contentSupplierId: id }));
    } else {
      dispatch(postsTagsRequest({ searchTag: search }));
    }
  };

  const handleExtraInfoTabSelect = (selectedTab: string | null) => {
    dispatch(addPostSetActiveTab(selectedTab || AP_EXTRA_INFO_TAB_NAMES.SETTINGS));
  };

  const handleUpdateSettingsCheckbox = (socialMediaKey: string, socialMediaValue: boolean) => {
    dispatch(addPostGetSelectedContentObj({ ...contentObj, [socialMediaKey]: socialMediaValue }));
  };

  const handleAddTags = (tag: Tag) => {
    if ((isContentSupplier && internalTagsList.length) || !isContentSupplier) {
      tag.id = !internalTagsList.length ? Math.floor(Math.random() * 100) : tag.id;
      tag.name = !internalTagsList.length ? tag.name.replace('Add ', '') : isContentSupplier ? internalTagsList.find((tagName) => tagName.name === tag.name)?.name || '' : tag.name;
      if (tag.name) {
        const addedTagsArray: Tag[] = [...selectedTags, tag];
        dispatch(addPostSetTags(addedTagsArray));
        setSearchValue('');
      }
    }
  };

  const handleDeleteTag = (tagName: string) => {
    dispatch(addPostSetTags(selectedTags.filter((datum) => datum.name !== tagName)));
    setSearchValue('');
  };

  return (
    <div className="post-content">
      <div className="card-bg active">
        <div className="post-tabs">
          <AddTextSection socialMediaList={activePlatforms} addContentList={activeContentList} />

          {/* Extra Information for creating post */}
          <div className="creator-settings-wrp">
            <Tabs activeKey={activeTab} onSelect={(key) => handleExtraInfoTabSelect(key)}>
              <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.SETTINGS} title="Settings">
                {typeof personalProfilesFeature === 'boolean' && !personalProfilesFeature ? null : (
                  <div className={`form-check${contentObj?.isPostLock ? ` pointer-events-none` : ``}`}>
                    <label className="checkbox-item">
                      <span className="checkbox-hover">
                        <input
                          type="checkbox"
                          name="isPostAvailToEmployee"
                          className="option-input click-wave"
                          checked={contentObj?.isPostAvailToEmployee || false}
                          disabled={contentObj?.isPostLock || false}
                          onChange={(event) => handleUpdateSettingsCheckbox(event.target.name, event.target.checked)}
                        />
                      </span>
                      <div className="label-txt">
                        Employee Advocates
                        <CustomTooltip
                          customPlacement={TOOLTIP_PLACEMENT.TOP}
                          customClassname={'custom-tooltip-long-text'}
                          customTooltipText={AP_EMPLOYEE_ADVOCATES_TOOLTIP}
                          customInput={() => (
                            <div className="info__tool--tip">
                              <ImageValidation defaultImg="information-grey" customName={'activate'} />
                            </div>
                          )}
                        />
                      </div>
                      <span className="checkmark" />
                    </label>
                  </div>
                )}
                <div className={`form-check${contentData?.content_origins ? ` pointer-events-none` : ``}`}>
                  <label className="checkbox-item">
                    <span className="checkbox-hover">
                      <input
                        type="checkbox"
                        name="isPostLock"
                        className="option-input click-wave"
                        checked={contentObj?.isPostLock || false}
                        disabled={contentData?.content_origins || false}
                        onChange={(event) => handleUpdateSettingsCheckbox(event.target.name, event.target.checked)}
                      />
                    </span>
                    <div className="label-txt">
                      Lock Post
                      <CustomTooltip
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customClassname={'custom-tooltip-long-text'}
                        customTooltipText={AP_LOCK_POST_TOOLTIP}
                        customInput={() => (
                          <div className="info__tool--tip">
                            <ImageValidation defaultImg="information-grey" customName={'activate'} />
                          </div>
                        )}
                      />
                    </div>
                    <span className="checkmark" />
                  </label>
                </div>
              </Tab>

              <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.INTERNAL_TAGS} title="Internal Tags">
                <div className="tags">
                  <div className="wb-top">
                    <div className="creator-internaltags-wrp-section">
                      <div className="wb-fields">
                        <div className="wbf-item">
                          <div className="wbf-inputs">
                            <div className="input-fields">
                              <div className="if-main if-h">
                                <CustomReactTags
                                  key={`${id}${userOwnership}`}
                                  suggestions={tagsList}
                                  handleInputChange={(search: string) => {
                                    tagsApiTimeout = debounceMethod(search, tagsApiTimeout, handleInputChange);
                                  }}
                                  handleDelete={() => false}
                                  maxSuggestionsLength={tagsList.length}
                                  handleAddition={(tag: Tag) => handleAddTags(tag)}
                                  placeholder="Search"
                                  autofocus={false}
                                  inputAttributes={{
                                    disabled: contentObj?.isPostLock ? true : false
                                  }}
                                  allowNew
                                  minQueryLength={1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedTags.length ? (
                        <div className="tags-wrp">
                          {selectedTags.map((tagDatum, index) => {
                            return (
                              <div className="list-expanded-tag-item" key={`loc-creator-tag-${index}`}>
                                <span className="list-expanded-tag-text">{tagDatum.name}</span>
                                {contentObj?.isPostLock ? null : <span className="list-expanded-tag-remove" onClick={() => handleDeleteTag(tagDatum.name)} />}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.INTERNAL_NOTES} title="Internal Notes">
                <InternalNotesSection />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
