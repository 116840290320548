import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { ITableData, ITableHeader } from 'types/common/common-props';

interface ITableComponentProps {
  tableHeaders: ITableHeader[];
  tableData: ITableData[];
  tableClassname?: string;
  noDataFoundMessage?: string;
  onRowClick?: (identifier: string | number) => void;
  renderExtra?: () => JSX.Element;
}

export const TableComponent = ({ tableHeaders, tableData, tableClassname, noDataFoundMessage, onRowClick, renderExtra }: ITableComponentProps) => {
  return (
    <Table className={tableClassname}>
      <Thead>
        <Tr key="headers">
          {tableHeaders.map((header) => (
            <Th
              key={header.key}
              colSpan={header.colSpan}
              className={header.className}
              onClick={(e: Event) => {
                if (header.onClick) {
                  header.onClick(e, header.key);
                }
              }}
            >
              {header.renderJSX ? header.renderJSX() : header.name}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {tableData?.length > 0 ? (
          tableData.map((it, index) => (
            <Tr
              key={`tablebody${index}`}
              id={it[Object.keys(it)[0]]?.id}
              className={it[Object.keys(it)[0]]?.className}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(it[Object.keys(it)[0]]?.identifier);
                }
              }}
            >
              {tableHeaders.map((h) => (
                <Td key={`column${h}`} className={it[h.key]?.columnClass}>
                  {it[h.key]?.value}
                </Td>
              ))}
            </Tr>
          ))
        ) : (
          <Tr className="ndt-wrap">
            <Td className="no-data-text" colSpan={tableHeaders.length}>
              {noDataFoundMessage || 'No Data Found.'}
            </Td>
          </Tr>
        )}
        {renderExtra ? renderExtra() : null}
      </Tbody>
    </Table>
  );
};
