export const AI_REVIEW_RESPONSER_INITIAL_OBJ = {
  franchisor_id: null,
  account_id: null,
  enabled: false,
  enable_manual_settings: false,
  include_emojis_facebook_recommended: false,
  include_emojis_facebook_not_recommended: false,
  include_emojis_google_1_star: false,
  include_emojis_google_2_star: false,
  include_emojis_google_3_star: false,
  include_emojis_google_4_star: false,
  include_emojis_google_5_star: false,
  provide_contact_information: false,
  contact_information_phone: null,
  contact_information_email: null,
  personalize_contact_information: false,
  provide_contact_information_phone: false,
  provide_contact_information_email: false,
  provide_contact_information_facebook_recommended: false,
  provide_contact_information_facebook_not_recommended: false,
  provide_contact_information_google_1_star: false,
  provide_contact_information_google_2_star: false,
  provide_contact_information_google_3_star: false,
  provide_contact_information_google_4_star: false,
  provide_contact_information_google_5_star: false,
  ai_playbook_confirmation: false,
  respond_to_facebook_recommended: false,
  respond_to_facebook_not_recommended: false,
  respond_to_google_1_star: false,
  respond_to_google_2_star: false,
  respond_to_google_3_star: false,
  respond_to_google_4_star: false,
  respond_to_google_5_star: false,
  start_date: '',
  additional_instructions: '',
  account_list_id: null,
  account_list_name: null,
  account_list_accounts_count: null
};
