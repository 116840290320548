import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { EditorState } from 'draft-js';

import { socialContentsReplyRequest } from 'actions';
import { scContainerTypes } from 'types';
import { useNavBarData } from 'utils/hooks';
import { convertEditorStateToString, convertStringToEditorState, createEmptyEditorState, handleBeforeInput, handlePastedText, handleSubmitOnEnter } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE, SIDE_NAV_NAME, SocialContentActionType } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomTextEditor } from 'widgets/Text';

export const SCReply = ({ id: socialContentId, accountImageUrl, platform, postedFrom }: scContainerTypes.ISCReplyProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { subNavPageName } = useNavBarData();

  const [replyBtn, setReplyBtn] = useState({ replyPostId: 0, replyText: createEmptyEditorState() });

  const handleSCReply = () => {
    dispatch(
      socialContentsReplyRequest({
        pageType: subNavPageName,
        socialContentId,
        message: convertEditorStateToString(replyBtn.replyText) || '',
        dismissed: true
      })
    );
    setReplyBtn({ replyPostId: socialContentId, replyText: createEmptyEditorState() });
  };

  return (
    <div className="lv-comment">
      <div className={`lvc-top${subNavPageName === SIDE_NAV_NAME.COMMUNITY_SANDBOX ? ' inbox-comment' : ''}`}>
        <div className="round-asset brand-icon">
          <ImageValidation isImgValid imgUrl={accountImageUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={''} customName={`${subNavPageName} Asset Item`} />
        </div>
        <CustomTextEditor
          emojiKey={`${subNavPageName}-comment-${socialContentId}`}
          className="form-control g-se"
          placeholder="Write a Comment"
          editorState={replyBtn.replyPostId === socialContentId ? replyBtn.replyText : createEmptyEditorState()}
          keyBindingFn={(e: React.KeyboardEvent<{}>) =>
            handleSubmitOnEnter(e, replyBtn.replyText, () => {
              handleSCReply();
            })
          }
          onEditorChange={(e) => {
            if (replyBtn.replyPostId === socialContentId) {
              setReplyBtn({ replyPostId: socialContentId, replyText: e });
            }
          }}
          onEditorClick={() => {
            if (!replyBtn.replyText || replyBtn.replyPostId !== socialContentId) {
              const defaultReplyText = platform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? convertStringToEditorState(`@${postedFrom}`) : createEmptyEditorState();
              setReplyBtn({ replyPostId: socialContentId, replyText: EditorState.moveFocusToEnd(defaultReplyText) });
            }
          }}
          isMentionEnabled={false}
          handleBeforeInput={() => handleBeforeInput(replyBtn.replyText || createEmptyEditorState(), platform || '')}
          handlePastedText={(pastedText) => handlePastedText(pastedText, replyBtn.replyText || createEmptyEditorState(), platform || '')}
        />
      </div>
      <div className="lvc-base">
        <Button
          className={`txt-btn lv-prime${replyBtn.replyPostId === socialContentId ? '' : ' d-none'}`}
          disabled={!replyBtn.replyText.getCurrentContent().hasText()}
          onClick={handleSCReply}
          variant="link"
        >
          {SocialContentActionType.POST_COMMENT}
        </Button>
      </div>
    </div>
  );
};
