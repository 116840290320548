import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore, mgReducerTypes } from 'types';
import {
  CONTENT_ANALYTICS_FILTER_INITIAL_VALUE,
  ANALYTICS_CONTENT_PLATFORM_FILTER,
  ExportCsvFileName,
  ContentPlatformValue,
  CONTENT_ANALYTICS_TABLE_HEADERS,
  CONTENT_ANALYTICS_API_KEY,
  ContentImpression,
  ContentEngagement,
  ContentAnalyticsFilterValue,
  PLATFORM_FILTER,
  VALID_MULTIPLE_LOCATION_ROUTES,
  TABLE_TYPE
} from 'analytics/utils';
import { apiExportCsvhandler, dateFormatter, handleFilterChange, dateTimeFormatter, downloadFormatHandler, exportCsvhandler } from 'analytics/utils/helpers';
import { BUTTON_PROPS, COMMON_SOCIAL_PROVIDER_TYPE, USER_OWNERSHIP, UTC_TIMESTAMP_FORMAT, DONE_SUCCESS_MSG, AI_POSTS_FILTER_TYPE, AIPostType } from 'utils/constants';
import { API, debounceMethod } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor, useAllowAccess, useAvailablePlatforms } from 'utils/hooks';
import { setContentAnalyticsFilter } from 'analytics/actions';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { mgGetFavTagsRequest, postsTagsRequest } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { CommonFilter } from 'components';
import { IFiterHandleChangeData } from 'types/common/filter';
import { useContentAnalyticsFilterParams } from 'analytics/utils/hooks/use-content-analytics-filter-params';

export const ContentAnalyticsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, userOwnership, optionalParams, currentRoute, isValidId } = useAccountSwitcherData();
  const { franchisorFullAccess } = useAllowAccess();

  const isMultipleLocationEnabled = VALID_MULTIPLE_LOCATION_ROUTES.some((it) => it.route === optionalParams[0]) && +optionalParams[1] > 0;
  const multipleLocationId = isMultipleLocationEnabled ? +optionalParams[1] : 0;

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  const filterInitalValue = { ...CONTENT_ANALYTICS_FILTER_INITIAL_VALUE, ...{ platform: isHubUser ? ContentPlatformValue.FACEBOOK : ContentPlatformValue.ALL } };

  const { queryParams } = useParamsDeconstructor(filterInitalValue);

  // const instagramPlatform = queryParams?.analytics === 'local_post' ? [] : [PLATFORM_FILTER[2]];
  const analyticsPlatformFilter = ['reels'].includes(queryParams.type)
    ? [PLATFORM_FILTER[1], userOwnership === USER_OWNERSHIP.FRANCHISOR ? PLATFORM_FILTER[2] : ANALYTICS_CONTENT_PLATFORM_FILTER[2]]
    : queryParams.type === 'stories'
    ? [userOwnership === USER_OWNERSHIP.FRANCHISOR ? PLATFORM_FILTER[2] : ANALYTICS_CONTENT_PLATFORM_FILTER[2]]
    : userOwnership === USER_OWNERSHIP.FRANCHISOR
    ? [ANALYTICS_CONTENT_PLATFORM_FILTER[1], PLATFORM_FILTER[2], ANALYTICS_CONTENT_PLATFORM_FILTER[3]]
    : ANALYTICS_CONTENT_PLATFORM_FILTER.slice(0, 5);

  const availablePlatform = useAvailablePlatforms(analyticsPlatformFilter, userOwnership);

  let postsTagsApiTimeout: null | NodeJS.Timeout = null;

  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const favTagsList = useSelector((state: IStore) => state.mediaGallery.favTagsList);
  const isTagFetching = useSelector((state: IStore) => state.mediaGallery.isTagFetching);
  const postsTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const exportData = useSelector((state: IStore) => (state.contentAnalytics.tagsData?.length ? state.contentAnalytics.tagsData : []));
  const analyticsActualData = useSelector((state: IStore) =>
    !multipleLocationId ? state.contentAnalytics?.actualData || [] : state.contentAnalytics?.multipleLocationActualData?.post_analytics || []
  );
  // const tagsSearch = useSelector((state: IStore) => state.contentAnalytics.tagsSearch);
  // const favTagsList = useSelector((state: IStore) => state.mediaGallery.f);

  const { contentFilter, startDate, endDate, checkBoxFilter } = useContentAnalyticsFilterParams(userOwnership, optionalParams, availablePlatform, franchisorFullAccess);

  useEffect(() => {
    if (id && userOwnership && isValidId) {
      dispatch(mgGetFavTagsRequest({ id, userOwnership }));
    }
  }, [id, userOwnership, isValidId, dispatch]);

  useEffect(() => {
    if (availablePlatform.length && ['reels', 'stories'].includes(queryParams.type) && !queryParams?.platform && !multipleLocationId) {
      const params = { platform: availablePlatform[0]?.value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, userOwnership, queryParams?.type, queryParams?.ai_post, queryParams?.platform, multipleLocationId]); // eslint-disable-line

  useEffect(() => {
    const isPlatform = userOwnership === USER_OWNERSHIP.FRANCHISOR ? availablePlatform.length : availablePlatform.length === 1;
    if (isPlatform && !queryParams?.platform && !multipleLocationId) {
      const params = { platform: availablePlatform[0].value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, userOwnership, queryParams?.platform, multipleLocationId]); // eslint-disable-line

  const clearSortQueryForFilterChanges = () => {
    delete queryParams?.sort_by;
    delete queryParams?.ascending;
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (data.dateRange || data.locationFilterObj || data.analytics || data.platform || isClear || data.type || data.ai_post) {
      dispatch(setContentAnalyticsFilter({ page: 1 }));
    }
    if (data.platform) {
      clearSortQueryForFilterChanges();
    }
    if (data.analytics) {
      clearSortQueryForFilterChanges();
      if (data.analytics === ContentAnalyticsFilterValue.HUB) {
        delete queryParamsObj.analytics;
      } else {
        queryParamsObj.analytics = data.analytics;
        delete queryParams.location_list_id;
        delete queryParams.location_id;
        delete queryParams.hub_id;
      }
    }
    if (data.tags_list && Array.isArray(data.tags_list)) {
      if (data.tags_list?.length) queryParams.tags_list = data.tags_list.map((it: any) => it.name).join(',');
      else delete queryParams.tags_list;
    }
    if (data.engagement) {
      if (data.engagement === ContentEngagement.ALL) {
        delete queryParamsObj.engagement;
      } else {
        queryParamsObj.engagement = data.engagement;
      }
    }
    if (data.type) {
      clearSortQueryForFilterChanges();
      if (data.type === TABLE_TYPE[0].value) {
        delete queryParamsObj.type;
      } else {
        if (data.type === TABLE_TYPE[1].value) delete queryParamsObj.impressions;
        queryParamsObj.type = data.type;
        // if (data.type === TABLE_TYPE[1].value) queryParamsObj.platform = 'facebook';
      }
    }
    if (data.ai_post) {
      if (data.ai_post === AI_POSTS_FILTER_TYPE[0].value) {
        delete queryParamsObj.ai_post;
      } else {
        queryParamsObj.ai_post = data.ai_post;
      }
    }
    if (data.impressions) {
      if (data.impressions === ContentImpression.ALL) {
        delete queryParamsObj.impressions;
      } else {
        queryParamsObj.impressions = data.impressions;
      }
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const queryParamKeys = Object.keys(queryParams).filter((it) => !['selected_tile', 'sort_by', 'ascending'].includes(it));

  const isFilterDisabled = !availablePlatform.some((it) => it.value === 'all') ? queryParamKeys?.length === 1 && queryParams?.platform === availablePlatform[0]?.value : !queryParamKeys?.length;

  const isClearFilterDisabled = multipleLocationId > 0 ? !queryParamKeys?.length : isFilterDisabled;
  const tagsList = contentFilter.tags_list.filter((it: mgReducerTypes.IMediaGalleryTagsData) => !it.favourite);

  const handleSearchTags = (searchValue: string) => {
    dispatch(postsTagsRequest({ searchTag: searchValue }));
  };

  const handleExportCsv = () => {
    const formatterStartDate = startDate ? dateTimeFormatter(startDate, UTC_TIMESTAMP_FORMAT, 'startOfDay') : CONTENT_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.fromDate.timeStamp;
    const formatterEndDateDate = endDate ? dateTimeFormatter(endDate, UTC_TIMESTAMP_FORMAT, 'endOfDay') : CONTENT_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.toDate.timeStamp;
    if (id && queryParams?.type === 'tags') {
      exportCsvhandler(downloadFormatHandler, exportData, DONE_SUCCESS_MSG, ExportCsvFileName.CAT, currentName, id, userOwnership);
    } else if (id && !multipleLocationId) {
      const platform = queryParams?.platform || COMMON_SOCIAL_PROVIDER_TYPE.ALL;
      const isFilterHubAnalytics = queryParams?.analytics !== ContentAnalyticsFilterValue.LOCAL_POST;
      let sortOrderKey = CONTENT_ANALYTICS_TABLE_HEADERS.find((it) => it.value === queryParams?.sort_by)?.sortValue;
      if (queryParams?.sort_by === 'engagement' && queryParams.engagement) {
        sortOrderKey = CONTENT_ANALYTICS_API_KEY.engagement.find((it) => it.label === queryParams.engagement)?.value;
      } else if (queryParams?.sort_by === 'impressions' && queryParams.impressions) {
        sortOrderKey = CONTENT_ANALYTICS_API_KEY.impressions.find((it) => it.label === queryParams.impressions)?.value;
      }
      const by =
        !isHubUser || !isFilterHubAnalytics
          ? queryParams?.sort_by === 'rate'
            ? {
                'by[ratio]': [`engagement_${queryParams?.engagement || 'total'}`, `impressions_${queryParams?.impressions === 'organic' ? 'unpaid' : queryParams?.impressions || 'total'}`],
                'by[direction]': queryParams?.ascending === '1' ? 'asc' : 'desc'
              }
            : {
                'by[key]': sortOrderKey || 'posted_at',
                'by[direction]': queryParams?.ascending === '1' ? 'asc' : 'desc'
              }
          : {
              order: sortOrderKey || 'timestamp',
              reverse: queryParams?.ascending === '1' ? 0 : 1
            };
      const path = isHubUser
        ? isFilterHubAnalytics
          ? API.getHubContentAnalytics.apiPath.replace('filter', platform)
          : API.getLocalPostContentAnalytics.apiPath.replace('filter', platform)
        : API.getContentAnalytics.apiPath.replace(':id', id.toString());
      const params = {
        ...(queryParams?.type === 'reels' && { reels: 1 }),
        ...(queryParams?.type === 'stories' && { stories: 1 }),
        ...(isHubUser
          ? isFilterHubAnalytics
            ? { franchisor_id: id, parent_facebook_post_id: '' }
            : { ancestor_franchisor_id: id, content_id: '', parent_facebook_post_id: '' }
          : { account_id: id, parent_remote_post_id: '' }),
        ...(!isHubUser && { provider_name: platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? '' : platform }),
        ...by,
        ...(isHubUser && isFilterHubAnalytics
          ? {
              start_date: dateFormatter(startDate || CONTENT_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.fromDate.selected),
              end_date: dateFormatter(endDate || CONTENT_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.toDate.selected)
            }
          : { date_range: [formatterStartDate, formatterEndDateDate] }),
        ...(queryParams?.ai_post && { ai_post: queryParams?.ai_post === AIPostType.AI_ASSISTED ? 1 : 0 }),
        ...(queryParams?.type !== 'tags' && queryParams?.tags_list?.length && { tag: queryParams.tags_list })
      };
      apiExportCsvhandler(path, params, ExportCsvFileName.CA, isFilterHubAnalytics ? '' : `enduser_${platform}_post_stat_rows`, currentName, id, userOwnership);
    } else if (id && multipleLocationId) {
      const by = {
        'by[key]': queryParams?.sort_by === 'posted_at' ? 'sentCount' : queryParams?.sort_by ? `${queryParams?.sort_by}_totals` : 'timestamp',
        'by[direction]': queryParams?.ascending === '1' ? 'asc' : 'desc'
      };
      const csvResponseObj =
        optionalParams[0] === 'facebook_post_stats' ? 'enduser_facebook_post_stat_rows' : optionalParams[0] === 'instagram_post_stats' ? 'post_analytics' : 'enduser_linkedin_post_stat_rows';
      const platform = optionalParams[0] === 'facebook_post_stats' ? 'facebook' : optionalParams[0] === 'instagram_post_stats' ? 'instagram' : 'linkedin';
      let path = `${API.getHubMultipleLocationsContentAnalytics.apiPath.replace('filter', platform)}`;
      path = path.replace(':id', multipleLocationId ? multipleLocationId.toString() : id.toString());
      const params = {
        franchisor_id: id,
        ...by,
        per: 1000,
        order: queryParams?.sort_by === 'posted_at' ? 'sentCount' : queryParams?.sort_by ? `${queryParams?.sort_by}_totals` : 'timestamp',
        date_range: [formatterStartDate || '', formatterEndDateDate || ''],
        ...(queryParams?.hub_id && { ancestor_franchisor_id: queryParams?.hub_id }),
        ...(queryParams?.ai_post && { ai_post: queryParams?.ai_post === AIPostType.AI_ASSISTED ? 1 : 0 }),
        ...(queryParams?.type !== 'tags' && queryParams?.tags_list?.length && { tag: queryParams.tags_list })
      };
      apiExportCsvhandler(path, params, ExportCsvFileName.CA, csvResponseObj, currentName, id, userOwnership);
    }
  };

  const handleTagSelection = (tagObj: mgReducerTypes.IMediaGalleryTagsData) => {
    handleChange({
      tags_list:
        contentFilter.tags_list && !contentFilter.tags_list.map((it: mgReducerTypes.IMediaGalleryTagsData) => it.name).includes(tagObj.name)
          ? [...contentFilter.tags_list, tagObj]
          : contentFilter.tags_list.filter((it: mgReducerTypes.IMediaGalleryTagsData) => it.name !== tagObj.name)
    });
  };

  const handleURLPathname = (pathname: string) => {
    navigate({ pathname, search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const handleSelectAll = () => {
    const favoutiteTags = favTagsList.filter((datum) => datum.favourite);
    handleChange({
      tags_list: contentFilter.tags_list?.length === favoutiteTags?.length ? [] : favoutiteTags
    });
  };

  const isChecked =
    contentFilter.tags_list?.length && favTagsList.filter((datum) => datum.favourite)?.length && contentFilter.tags_list?.length === favTagsList.filter((datum) => datum.favourite)?.length;

  return (
    <CommonFilter
      filter={contentFilter}
      handleChange={handleChange}
      analyticsPlatformFilter={multipleLocationId > 0 ? [] : availablePlatform}
      checkBoxFilter={checkBoxFilter}
      primaryButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
          isDisable: queryParams?.type === 'tags' ? !exportData?.length : !analyticsActualData?.length
        },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled
        }
      ]}
      handleExportCsv={handleExportCsv}
      isLocation={isHubUser && queryParams?.analytics !== ContentAnalyticsFilterValue.LOCAL_POST}
      renderPrefixData={() => {
        if (multipleLocationId) {
          return <></>;
        } else {
          return (
            <>
              <div className="filter-item">
                <h3>Tags</h3>
                <div className="search-tk post-search post-list__search">
                  <CustomReactTags
                    key={`posts-tag-${id}-${userOwnership}`}
                    tags={tagsList}
                    suggestions={postsTagsList}
                    maxSuggestionsLength={postsTagsList.length}
                    handleInputChange={(search: string) => {
                      postsTagsApiTimeout = debounceMethod(search.trim(), postsTagsApiTimeout, handleSearchTags);
                    }}
                    handleDelete={(postIdx) => postIdx > -1 && handleTagSelection(tagsList[postIdx])}
                    handleAddition={(tag) => handleTagSelection(tag)}
                    placeholder={'Search tags'}
                    autofocus={false}
                  />
                </div>
              </div>
              {!isTagFetching && favTagsList.length ? (
                <div className="filter-item filter-item-tag__item status-list tagnew-margin sat-tag-options">
                  <div className="imtc ef-edit">
                    <h3>Favorite Tags</h3>
                    {!isTagFetching && userOwnership === USER_OWNERSHIP.FRANCHISOR && (
                      <>
                        <div className="edit-icon-post" onClick={() => handleURLPathname(`/${userOwnership}/${id.toString()}/${currentRoute}/favorite_tags`)}>
                          <ImageValidation isImgValid defaultImg={'create_post-edit'} customName={'Edit'} />
                          <span className="cf-values" onClick={() => handleURLPathname(`/${userOwnership}/${id.toString()}/${currentRoute}/favorite_tags`)}>
                            Edit
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  {queryParams?.type === 'tags' && (
                    <>
                      <div className={`saDatas sat-tags ${contentFilter.tags_list?.length && !isChecked ? 'some-tag-selctd' : ''} ${isChecked ? 'active' : ''}`} onClick={handleSelectAll}>
                        <label key={`checkbox`} className={isChecked ? 'checkbox-item active' : 'checkbox-item'}>
                          <span className="checkbox-hover">
                            <input value={''} type="checkbox" className={`option-input checkbox`} name={`select all`} defaultChecked={isChecked} />
                          </span>
                          <span className="labelText">Select All</span>
                        </label>
                      </div>
                    </>
                  )}
                  <div className="fts">
                    {favTagsList
                      .filter((datum) => datum.favourite)
                      .map((tagItem, index) => {
                        return (
                          <span
                            key={`posts-fav-tags-${index}`}
                            className={`fav-tags${contentFilter.tags_list.map((datum: mgReducerTypes.IMediaGalleryTagsData) => datum.favourite && datum.name).includes(tagItem.name) ? ` active` : ``}`}
                            onClick={() => handleTagSelection(tagItem)}
                          >
                            {tagItem.name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              ) : null}
            </>
          );
        }
      }}
    />
  );
};
