export const imageMotionAnimation = () => {
  window.imageMotionAnimation();
}

export const intercomBotSettings = (payload) => {
  window.intercomBotSettings(payload);
}

export const shutdownIntercomBot = () => {
  window.shutdownIntercomBot();
}

export const hideIntercomBot = (isHide) => {
  window.hideIntercomBot(isHide);
}

export const configCDNUrlToBrand = (brandConfig) => {
  window.configCDNUrlToBrand(brandConfig);
}

export const refreshCacheAndReload = () => {
  window.refreshCacheAndReload();
}
