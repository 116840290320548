import { useState, useEffect } from 'react';

import { commonWidgetTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { getPercentage } from 'utils/helpers';

export const TotalStatsCount = (props: commonWidgetTypes.ITotalStatCountProps) => {
  const { facebookStat, twitterStat, instaStat, linkedInStat, totalStatCount, platform } = props;

  const [socialStat, setSocialStat] = useState({ facebook: 0, twitter: 0, insta: 0, linkedIn: 0 });

  /** store converted stats value to local state */
  useEffect(() => {
    if (totalStatCount) {
      let facebookVal = getPercentage(totalStatCount, facebookStat) || 0;
      let twitterVal = getPercentage(totalStatCount, twitterStat) || 0;
      let instaVal = getPercentage(totalStatCount, instaStat) || 0;
      let linkedInVal = getPercentage(totalStatCount, linkedInStat) || 0;
      const totalPercent = facebookVal + twitterVal + instaVal + linkedInVal;
      if (totalPercent > 100) {
        const diff = totalPercent - 100;
        const maxStatValue = Math.max(facebookVal, twitterVal, instaVal, linkedInVal);
        if (facebookVal === maxStatValue) facebookVal = facebookVal - diff;
        else if (twitterVal === maxStatValue) twitterVal = twitterVal - diff;
        else if (instaVal === maxStatValue) instaVal = instaVal - diff;
        else if (linkedInVal === maxStatValue) linkedInVal = linkedInVal - diff;
      } else if (totalPercent < 100) {
        const diff = 100 - totalPercent;
        const minStatValue = Math.min.apply(null, [facebookVal, twitterVal, instaVal, linkedInVal].filter(Boolean));
        if (facebookVal === minStatValue) facebookVal = facebookVal + diff;
        else if (twitterVal === minStatValue) twitterVal = twitterVal + diff;
        else if (instaVal === minStatValue) instaVal = instaVal + diff;
        else if (linkedInVal === minStatValue) linkedInVal = linkedInVal + diff;
      }
      setSocialStat({ ...socialStat, facebook: facebookVal, twitter: twitterVal, insta: instaVal, linkedIn: linkedInVal });
    }
  }, [totalStatCount]); // eslint-disable-line

  const getClassName = () => {
    const className =
      platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
        ? 'cf-facebook'
        : platform === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
        ? 'cf-twitter'
        : platform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
        ? 'cf-instagram'
        : platform === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
        ? 'cf-linkedin'
        : platform === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
        ? 'cf-tiktok'
        : '';
    return className;
  };

  return (
    <div className={`chart-details ${getClassName()}`}>
      <div className="progress-vertical">
        <div className="bar bar-facebook" style={{ width: '100%', background: '#0E81EB', height: `${socialStat.facebook}%` }} />
        <div className="bar bar-twitter" style={{ width: '100%', background: '#000000', height: `${socialStat.twitter}%` }} />
        <div className="bar bar-insta" style={{ width: '100%', background: '#F00075', height: `${socialStat.insta}%` }} />
        <div className="bar bar-linkedIn" style={{ width: '100%', background: '#2867B2', height: `${socialStat.linkedIn}%` }} />
        <div className="bar bar-tiktok" style={{ width: '100%', background: '#2867B2', height: `${socialStat.linkedIn}%` }} />
      </div>
    </div>
  );
};
