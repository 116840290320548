import { put, takeLatest } from 'redux-saga/effects';

import { targetingListRequest, targetingListRequestFail, targetingListResponse, getAccountConfigDataResponse, getAccountConfigDataRequestFail, logoutRequest } from 'actions';
import { ISagaPayload } from 'types/common/api';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import * as actionTypes from '../../actions/action-types';

function* loadTargetingList(action: ISagaPayload): any {
  try {
    const params = {
      account_list_id: action.payload.account_list_id,
      ancestor_franchisor_id: action.payload.ancestor_franchisor_id
    };
    const apiURL = API.targetingList.apiPath.replace(':id', action.payload.id);
    const response = yield apiCall({
      headers: defaultHeader(),
      apiPath: apiURL,
      action: API.targetingList.action,
      params
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(targetingListResponse({ data: response.data }));
    } else {
      yield put(targetingListRequestFail({ error: 'Cannot load data.' }));
    }
  } catch (error) {
    yield put(targetingListRequestFail({ error: 'Error occured in targeting list' }));
  }
}

function* updateTargetingAccountDetails(action: ISagaPayload): any {
  try {
    const id = action.payload.id;
    const accountId = action.payload.accountId;
    const account_list_id = action.payload.account_list_id;
    const ancestor_franchisor_id = action.payload.ancestor_franchisor_id;
    action.payload.id = undefined;
    action.payload.accountId = undefined;
    action.payload.account_list_id = undefined;
    action.payload.ancestor_franchisor_id = undefined;
    const apiPath = API.updateTargetingConfiguration.apiPath.replace(':id', id).replace(':accountId', accountId);
    const response = yield apiCall({
      headers: defaultHeader(),
      apiPath,
      data: { account_configuration: action.payload },
      action: API.updateTargetingConfiguration.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        targetingListRequest({
          id,
          account_list_id,
          ancestor_franchisor_id,
          type: 'edit-list'
        })
      );
    }
  } catch (error) {
    yield put(targetingListRequestFail({ error: 'Error occured in update targeting list' }));
  }
}

function* getAccountConfigDataRequest(action: ISagaPayload): any {
  try {
    const apiURL = API.getTargetingConfiguration.apiPath.replace(':id', action.payload.franchisorId).replace(':accountId', action.payload.accountId);
    const response = yield apiCall({
      headers: defaultHeader(),
      apiPath: apiURL,
      action: API.getTargetingConfiguration.action
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (Object.keys(response.data?.account_configuration)?.length) yield put(getAccountConfigDataResponse(response.data.account_configuration));
      else yield put(getAccountConfigDataRequestFail('Cannot get account configuration data.'));
    } else {
      yield put(getAccountConfigDataRequestFail('Cannot get account configuration data.'));
    }
  } catch (error) {
    yield put(getAccountConfigDataRequestFail('Error occured in targeting list'));
  }
}

export function* takeAllTargetingRequests() {
  yield takeLatest(actionTypes.TARGETING_LIST_REQUEST, loadTargetingList);
  yield takeLatest(actionTypes.UPDATE_ACCOUNT_CONFIGURATION, updateTargetingAccountDetails);
  yield takeLatest(actionTypes.GET_ACCOUNT_CONFIG_DATA_REQUEST, getAccountConfigDataRequest);
}
