import Highcharts from 'highcharts/highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';

import { Loading } from 'components';

highchartsMore(Highcharts);
solidGauge(Highcharts);

interface IGaugeSpeedometerChartProps {
  isDataLoading?: boolean;
  startAngle: number;
  endAngle: number;
  center?: string[];
  min: number;
  max: number;
  tickPositions?: number[];
  chart?: [
    {
      type?: string;
      renderTo?: string;
      height?: number;
    }
  ];

  // pane?: [{
  //   center?: number;
  // }]
  plotBands?: {
    from: number;
    to: number;
    color: string;
    innerRadius?: string;
    outerRadius?: string;
    label?: {
      text?: string;
      verticalAlign?: string;
      x?: number;
      y?: number;
    };
  }[];
  series: {
    name: string;
    data: {
      color?: string;
      y: number;
    }[];
    dataLabels: {
      formatter: () => null | string;
    };
  }[];
  dial?: {
    backgroundColor?: string;
    borderWidth?: number;
    baseWidth?: number;
    topWidth?: number;
    baseLength?: string;
    rearLength?: string;
    radius?: string;
  };
  pivot?: {
    radius?: number;
  };
}

export const GaugeSpeedometerChart = ({ isDataLoading, startAngle, endAngle, center, min, max, tickPositions = [], plotBands, series, dial, pivot }: IGaugeSpeedometerChartProps) => {
  return (
    <div className="areachart">
      {isDataLoading ? (
        <Loading isSmall />
      ) : (
        <figure className="highcharts-figure">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'gauge',
                borderWidth: 0,
                // renderTo: '5',
                height: ''
                // width:'100%'
              },
              title: {
                text: ''
              },
              pane: {
                startAngle,
                endAngle,
                center,
                background: {
                  borderWidth: 0
                }
              },
              tooltip: {
                enabled: false
              },
              yAxis: {
                min,
                max,
                tickPositions,
                plotBands
              },
              plotOptions: {
                gauge: {
                  dial,
                  pivot
                }
              },
              series,
              credits: {
                enabled: false
              },
              accessibility: {
                enabled: false
              }
            }}
            updateArgs={[true]}
          />
        </figure>
      )}
    </div>
  );
};
