// CONFIRM USER CREDENTIALS
export const CONFIRM_USER_ACCOUNT_FETCH_EMAIL_REQUEST = 'users/CONFIRM_USER_ACCOUNT_FETCH_EMAIL_REQUEST';
export const CONFIRM_USER_ACCOUNT_FETCH_EMAIL_RESPONSE = 'users/CONFIRM_USER_ACCOUNT_FETCH_EMAIL_RESPONSE';
export const CONFIRM_USER_ACCOUNT_FETCH_EMAIL_FAILURE = 'users/CONFIRM_USER_ACCOUNT_FETCH_EMAIL_FAILURE';

// RESET PASSWORD
export const RESET_PASSWORD_REQUEST = 'users/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE = 'users/RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_FAILURE = 'users/RESET_PASSWORD_FAILURE';

// RESET PASSWORD RESET
export const RESET_PASSWORD_ERROR_MSG_RESET = 'users/RESET_PASSWORD_ERROR_MSG_RESET';
