// GET ADVOCACY ONBOARD STATS
export const ADVOCACY_ONBOARD_GET_STATS_REQUEST = 'adv_onboard/ADVOCACY_ONBOARD_GET_STATS_REQUEST';
export const ADVOCACY_ONBOARD_GET_STATS_RESPONSE = 'adv_onboard/ADVOCACY_ONBOARD_GET_STATS_RESPONSE';
export const ADVOCACY_ONBOARD_GET_STATS_FAILURE = 'adv_onboard/ADVOCACY_ONBOARD_GET_STATS_FAILURE';

// GET ADVOCACY ONBOARD LIST REQUEST
export const ADVOCACY_ONBOARD_GET_LIST_REQUEST = 'adv_onboard/ADVOCACY_ONBOARD_GET_LIST_REQUEST';
export const ADVOCACY_ONBOARD_GET_LIST_RESPONSE = 'adv_onboard/ADVOCACY_ONBOARD_GET_LIST_RESPONSE';
export const ADVOCACY_ONBOARD_GET_LIST_FAILURE = 'adv_onboard/ADVOCACY_ONBOARD_GET_LIST_FAILURE';

// GET ADVOCACY ONBOARD LIST REQUEST
export const ADVOCACY_ONBOARD_GET_EXPORT_ALL_FAILURE = 'adv_onboard/ADVOCACY_ONBOARD_GET_EXPORT_ALL_FAILURE';

// GET ADVOCACY ONBOARD SINGLE RE-INVITE REQUEST
export const ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_RESPONSE = 'adv_onboard/ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_RESPONSE';
export const ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_FAILURE = 'adv_onboard/ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_FAILURE';

// GET ADVOCACY ONBOARD MULTIPLT RE-INVITE REQUEST
export const ADVOCACY_ONBOARD_GET_MULTIPLE_RE_INVITE_REQUEST = 'adv_onboard/ADVOCACY_ONBOARD_GET_MULTIPLE_RE_INVITE_REQUEST';

// ADVOCACY ONBOARD RESET ALL
export const ADVOCACY_ONBOARD_RESET_ALL = 'adv_onboard/ADVOCACY_ONBOARD_RESET_ALL';

// ADVOCACY ONBOARD - GET ACTIVE ADVOCATES FOR REINVITATION
export const ADVOCACY_ONBOARD_GET_ACTIVE_ADVOCATES_FOR_REINVITATION = 'adv_onboard/ADVOCACY_ONBOARD_GET_ACTIVE_ADVOCATES_FOR_REINVITATION';

// ADVOCACY ONBOARD - ENABLE CREATE INVITATION TO ALL USERS
export const ADVOCACY_ONBOARD_ENABLE_CREATE_INVITATION_FOR_ALL_USERS = 'adv_onboard/ADVOCACY_ONBOARD_ENABLE_CREATE_INVITATION_FOR_ALL_USERS';

// ADVOCACY ONBOARD RESET MESSAGE
export const ADVOCACY_ONBOARD_MESSAGE_RESET = 'adv_onboard/ADVOCACY_ONBOARD_MESSAGE_RESET';

// ADVOCACY ONBOARD SET EMAIL PREVIEW MODAL POPUP
export const ADVOCACY_ONBOARD_SET_EMAIL_PREVIEW_MODAL_POPUP = 'adv_onboard/ADVOCACY_ONBOARD_SET_EMAIL_PREVIEW_MODAL_POPUP';

// ADVOCACY ONBOARD - EMAIL PREVIEW TEXT
export const ADVOCACY_ONBOARD_SET_PREVIEW_TEXT = 'adv_onboard/ADVOCACY_ONBOARD_SET_PREVIEW_TEXT';

// ADVOCACY ONBOARD - INITIAL PREVIEW TEXT
export const ADVOCACY_ONBOARD_SET_INITIAL_PREVIEW_TEXT = 'adv_onboard/ADVOCACY_ONBOARD_SET_INITIAL_PREVIEW_TEXT';

// ADVOCACY ONBOARD - SET EDIT OBJECT
export const ADVOCACY_ONBOARD_SET_EDIT_OBJECT = 'adv_onboard/ADVOCACY_ONBOARD_SET_EDIT_OBJECT';

// ADVOCACY ONBOARD  - SET STATS CALL
export const ADVOCACY_ONBOARD_SET_STATS_CALL = 'adv_onboard/ADVOCACY_ONBOARD_SET_STATS_CALL';
