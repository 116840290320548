// AI IMAGE GENERATION FIELD UPDATE
// export const AI_IMAGE_GENERATION_FIELD_UPDATE_REQUEST = 'ai_content/AI_IMAGE_GENERATION_FIELD_UPDATE_REQUEST';
export const AI_IMAGE_GENERATION_FIELD_UPDATE_RESPONSE = 'ai_content/AI_IMAGE_GENERATION_FIELD_UPDATE_RESPONSE';
// export const AI_IMAGE_GENERATION_FIELD_UPDATE_FAILURE = 'ai_content/AI_IMAGE_GENERATION_FIELD_UPDATE_FAILURE';

// AI IMAGE GENERATION RESPONSE UPDATE
export const AI_IMAGE_GENERATION_REQUEST = 'ai_content/AI_IMAGE_GENERATION_REQUEST';
export const AI_IMAGE_GENERATION_RESPONSE = 'ai_content/AI_IMAGE_GENERATION_RESPONSE';
export const AI_IMAGE_GENERATION_FAILURE = 'ai_content/AI_IMAGE_GENERATION_FAILURE';

// AI STABLE DIFFUSION IMAGE GENERATION RESPONSE
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST';
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE';
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_FAILURE';

// GET AI STABLE DIFFUSION IMAGE GENERATION RESPONSE BY ID
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID';
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE_BY_ID = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_RESPONSE_BY_ID';

// AI IMAGE GENERATE RESET
export const AI_IMAGE_GENERATION_RESET = 'ai_content/AI_IMAGE_GENERATION_RESET';

// AI STABLE DIFFUSION IMAGE GENERATE RESET
export const AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID_RESET = 'ai_content/AI_STABLE_DIFFUSION_IMAGE_GENERATION_REQUEST_BY_ID_RESET';
