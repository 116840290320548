import { useCallback, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import useOnClickOutside from 'use-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Placement } from 'react-bootstrap/esm/types';

import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { setAISubscriptionDetailsModal, setCoachMark } from 'actions';
import { IStore } from 'types';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';

export const AICoachMark = ({
  children,
  coachMarkDetails,
  isCoachMark,
  coachMarkPosition
}: {
  children: any;
  coachMarkDetails?: {
    title: string;
    value: string;
    text: string;
    videoUrl: string;
  };
  isCoachMark: boolean;
  coachMarkPosition?: Placement;
}) => {
  const coachMarkRef = useRef(null);
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const isAISubscribed = useSelector(
    (state: IStore) => state.aiContentCreator.aiSubscription?.filter((it: any) => it[userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'franchisor_id' : 'account_id'] === id)?.length > 0
  );
  const isAISusbcriptionActive = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);

  const isDonotShow = localStorage?.getItem('doNotShowAICoachMark') === 'true';

  const isModalOpen = document.body.classList.contains('modal-open');

  useOnClickOutside(coachMarkRef, () => setVisible(false));

  const [isVisible, setVisible] = useState<boolean>(true);

  const handleOverlayTrigger = useCallback(
    (payload: boolean) => {
      dispatch(setCoachMark(payload));
    },
    [dispatch]
  );

  const handleCoachMarkClose = useCallback(() => {
    setVisible(false);
    if (!isVisible) {
      handleOverlayTrigger(false);
    }
  }, [isVisible, handleOverlayTrigger]);

  const handleClick = () => {
    handleCoachMarkClose();
    if (!isAISusbcriptionActive) {
      dispatch(setAISubscriptionDetailsModal({ type: !isAISubscribed ? 'trialPeriod' : 'newSubscription', isOpen: true, isCoachMark: true, isTrialPreiod: isAISubscribed }));
    }
  };

  useEffect(() => {
    if (isModalOpen && coachMarkRef.current) {
      handleCoachMarkClose();
    }
  }, [isModalOpen, handleCoachMarkClose]);

  const popover = (
    <Popover className={`ai-cm-popup ${coachMarkDetails?.value === 'post' ? 'ai-cm-post-popup' : ''}`} id="popover-basic">
      <div className="aicoach-body" ref={coachMarkRef}>
        <div className="aicoach-cnt">
          <h3>{coachMarkDetails?.title}</h3>
          <div className="ai-coach-video">
            <div className="video-section">
              <ReactVideoPlayer videoUrl={coachMarkDetails?.videoUrl || ''} muted isPlayControl={false} customClassname={'video'} />
              <div className="ai-vs-top">
                <ImageValidation isImgValid defaultImg="play" customName={'play'} />
              </div>
            </div>
          </div>
          <p className="aicoach__cnt--txt">{coachMarkDetails?.text}</p>
          <button className="ai__pop--btn ac-btn" onClick={handleClick}>
            <span>{isAISubscribed ? 'Got it!' : 'Try it!'}</span>
          </button>
          <div className="form-check">
            <label className="checkbox-item">
              <span className="checkbox-hover">
                <input
                  onChange={() => {
                    localStorage?.setItem('doNotShowAICoachMark', 'true');
                    setVisible(false);
                    handleOverlayTrigger(false);
                  }}
                  type="checkbox"
                  name="localizePost"
                  className="option-input click-wave"
                  checked={isDonotShow}
                />
              </span>
              <div className="label-txt">
                <span className="cb-label">Don't show this again</span>
              </div>
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={Boolean(isCoachMark && isVisible && coachMarkDetails && !isDonotShow)}
      onExit={() => handleCoachMarkClose()}
      onEntered={() => {
        setTimeout(handleCoachMarkClose, 30000);
        handleOverlayTrigger(true);
      }}
      placement={coachMarkPosition || 'right'}
      overlay={popover}
    >
      {children}
    </OverlayTrigger>
  );
};
