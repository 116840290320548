import { accountApiTypes, commonProps } from 'types';
import { COMMON_SOCIAL_MEDIA_NAME, HEADER_TOAST_RECONNECT_TYPES, HEADER_TOAST_ALERT_TYPES } from 'utils/constants';

export const getActiveHeaderToastData = (accountProfilesAlerts: accountApiTypes.IAlerts[]) => {
  if (accountProfilesAlerts.length) {
    return accountProfilesAlerts.reduce((acc: commonProps.IHeaderToastData[], curr: accountApiTypes.IAlerts, index) => {
      if (Object.values(HEADER_TOAST_ALERT_TYPES).includes(curr.alertType)) {
        acc = [
          ...acc,
          {
            identifier: `header-api-toast-${index}`,
            alertType: curr.alertType,
            context: curr.message || '',
            className: `ab-api ${curr.alertType === HEADER_TOAST_ALERT_TYPES.RED_ALERT ? 'alert-red' : curr.alertType === HEADER_TOAST_ALERT_TYPES.YELLOW_ALERT ? 'alert-yellow' : 'alert-green'}`
          }
        ];
      } else if (Object.values(HEADER_TOAST_RECONNECT_TYPES).includes(curr.alertType)) {
        acc = [
          ...acc,
          {
            identifier: `header-toast-${index}`,
            alertType: curr.alertType,
            context: `${
              curr.alertType === HEADER_TOAST_RECONNECT_TYPES.FACEBOOK
                ? COMMON_SOCIAL_MEDIA_NAME.FACEBOOK
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.LINKEDIN
                ? COMMON_SOCIAL_MEDIA_NAME.LINKEDIN
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.TWITTER
                ? COMMON_SOCIAL_MEDIA_NAME.TWITTER
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.INSTAGRAM
                ? COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM
                : COMMON_SOCIAL_MEDIA_NAME.GOOGLE
            } needs to be reconnected`,
            className:
              curr.alertType === HEADER_TOAST_RECONNECT_TYPES.FACEBOOK
                ? 'alert-facebook'
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.LINKEDIN
                ? 'alert-linkedin'
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.TWITTER
                ? 'alert-twitter'
                : curr.alertType === HEADER_TOAST_RECONNECT_TYPES.INSTAGRAM
                ? 'alert-insta'
                : 'alert-google'
          }
        ];
      }
      return acc;
    }, []);
  } else return [];
};
