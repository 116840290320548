import { useEffect, useState } from 'react';

import { commonWidgetTypes } from 'types';
import { config } from 'config';

export const ImageValidation = ({ isImgValid, imgUrl, defaultImg, customClassname, customName, isNotSvgFormat, tooltip, onClick, onLoad }: commonWidgetTypes.IImageValidationProps) => {
  //
  const [imageData, setImageData] = useState({
    image: imgUrl ? imgUrl : defaultImg ? `${config.cdnImgUrl}${defaultImg}${isNotSvgFormat ? '' : '.svg'}` : '',
    isValid: isImgValid || false
  });

  useEffect(() => {
    if (imgUrl || defaultImg) {
      const imageObj = new Image();
      const imageUrl = imgUrl ? imgUrl : '';
      imageObj.src = imageUrl;
      imageObj.onload = () => {
        setImageData({ image: imageUrl, isValid: true });
      };
      imageObj.onerror = () => {
        const defaultImageObj = new Image();
        const validDefaultImg = defaultImg ? `${config.cdnImgUrl}${defaultImg}${isNotSvgFormat ? '' : '.svg'}` : '';
        defaultImageObj.src = validDefaultImg;
        defaultImageObj.onload = () => {
          setImageData({ image: validDefaultImg, isValid: true });
        };
        defaultImageObj.onerror = () => {
          setImageData({ image: validDefaultImg, isValid: false });
        };
      };
    }
    return () => {
      setImageData({ image: '', isValid: false });
    };
  }, [imgUrl, defaultImg, isNotSvgFormat]);

  return imageData.isValid && imageData.image ? <img src={imageData.image} alt={customName} className={customClassname} loading="lazy" onClick={onClick} onLoad={onLoad} title={tooltip} /> : null;
};
