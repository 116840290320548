import * as Yup from 'yup';

import { RALLIO_PROFILE_TAB_FILEDS } from 'utils/constants/settings-rallio-profile';
import { parsePhoneNumbers, isDateValid, isValidUrl } from 'utils/helpers';
import {
  DL_STORE_DETAILS_TAB_FIELDS,
  USER_LOGINS_NEW_USER_FILEDS,
  RewardType,
  Duration,
  CALENDAR_YR_MN_DATE_FORMAT,
  FULL_YEAR_FORMAT,
  DlmSourceType,
  // APPROVED_STATUS,
  CampaignDurationType,
  ICampaignAvailability,
  KEYWORDS_CATEGORY_TAB_FIELDS,
  VALID_PHONE_NUMBER_REGEX,
  COMMON_SOCIAL_PROVIDER_TYPE,
  USER_OWNERSHIP,
  GoogleCallToActionOptionsName,
  COMMUNICATION_TAB_FIELDS,
  ACCOUNT_LIST_SCHEDULE_TAB_FIELDS,
  FEED_TYPE,
  CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES
} from 'utils/constants';
import { AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS, AI_PLAYBOOK_TAB_FIELDS } from 'utils/constants/settings-ai-playbook';

/** Login validation */
export const loginValidation = Yup.object().shape({
  loginInputEmail: Yup.string().trim().email('Please enter a valid email.').required('Email required'),
  loginInputPassword: Yup.string().trim().required('Password required').min(8, 'Password should be minimum 8 characters').max(20, 'Password should not be maximum 20 characters')
});

/** Reset Password validation */
export const resetPasswordValidation = Yup.object().shape({
  resetPasswordInputNewPassword: Yup.string()
    .trim()
    .required('New password required')
    .min(8, 'New password should be minimum 8 characters')
    .max(20, 'New password should not be maximum 20 characters'),
  resetPasswordInputConfirmPassword: Yup.string()
    .trim()
    .required('Confirm required')
    .min(8, 'Confirm password should be minimum 8 characters')
    .max(20, 'Confirm password should not be maximum 20 characters')
    .oneOf([Yup.ref('resetPasswordInputNewPassword'), null], 'Passwords must match')
});

/** Verify email password validation */
export const verifyEmailPasswordValidation = Yup.object().shape({
  verifyEmailPassword: Yup.string().trim().required('Password required').min(8, 'Password should be minimum 8 characters').max(20, 'Password should not be maximum 20 characters'),
  verifyEmailConfirmPassword: Yup.string()
    .trim()
    .required('Confirm password required')
    .min(8, 'Confirm password should be minimum 8 characters')
    .max(20, 'Confirm password should not be maximum 20 characters')
    .oneOf([Yup.ref('verifyEmailPassword'), null], 'Passwords must match')
});

/** Forgot Password validation */
export const forgotPasswordValidation = Yup.object().shape({
  forgotPasswordInputEmail: Yup.string().trim().email('Please enter a valid email.').required('Email required')
});

export const rallioProfileValidation = Yup.object().shape({
  [RALLIO_PROFILE_TAB_FILEDS.NAME]: Yup.string().trim().required('Name required').min(3, 'min error')
  /* [RALLIO_PROFILE_TAB_FILEDS.ADDRESS]: Yup.string()
    .trim()
    .when('required_field', {
      is: APPROVED_STATUS.YES,
      then: Yup.string().trim().required('Address required'),
      otherwise: Yup.string().trim().notRequired()
    }), */
  // [RALLIO_PROFILE_TAB_FILEDS.STORE_CODE]: Yup.string().trim().required('Store code required'),
  // [RALLIO_PROFILE_TAB_FILEDS.CITY]: Yup.string().trim().required('City required'),
  /* [RALLIO_PROFILE_TAB_FILEDS.PHONE_NUMBER]: Yup.string()
    .trim()
    .when('required_field', {
      is: APPROVED_STATUS.YES,
      then: Yup.string()
        .trim()
        .required('Phone number required')
        .test('len', 'Phone number must be 10 digits', (val) => parsePhoneNumbers(val)?.length === 10),
      otherwise: Yup.string().trim().notRequired()
    }),
  [RALLIO_PROFILE_TAB_FILEDS.ZIP_OR_POSTAL_CODE]: Yup.string()
    .trim()
    .when('required_field', {
      is: APPROVED_STATUS.YES,
      then: Yup.string().trim().required('Zip/Postal code required'),
      otherwise: Yup.string().trim().notRequired()
    }),
  [RALLIO_PROFILE_TAB_FILEDS.STATE_OR_PROVINCE]: Yup.string()
    .trim()
    .when('required_field', {
      is: APPROVED_STATUS.YES,
      then: Yup.string().trim().required('State/Province required'),
      otherwise: Yup.string().trim().notRequired()
    }) */
});

export const couponsValidation = Yup.object().shape({
  offerCopy: Yup.string().trim().required('Description required').min(3, 'min error')
});

export const storeDetailsValidation = Yup.object().shape({
  [DL_STORE_DETAILS_TAB_FIELDS.NAME]: Yup.string().trim().required('Name required').min(2, 'min error'),
  [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: Yup.string()
    .trim()
    .when('dlm_source', {
      is: DlmSourceType.RENDER_SEO,
      then: Yup.string().trim().required('Store code required').min(1),
      otherwise: Yup.string().trim().notRequired()
    }),
  [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: Yup.mixed().test('date', 'Invalid opening date', (value) => (value ? isDateValid(value, CALENDAR_YR_MN_DATE_FORMAT, true) : true)),
  [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: Yup.mixed().test('date', 'Invalid year of incorporation', (value) => (value ? isDateValid(value, FULL_YEAR_FORMAT, true) : true)),
  [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: Yup.string()
    .trim()
    .when('dlm_source', {
      is: DlmSourceType.RENDER_SEO,
      then: Yup.string().trim().notRequired(),
      otherwise: Yup.string().trim().required('Description required').min(200)
    }),
  // [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: Yup.string().trim().required('Payment methods required'),
  [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: Yup.string().trim().required('Address required'),
  [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: Yup.string()
    .trim()
    .when('dlm_source', {
      is: DlmSourceType.RENDER_SEO,
      then: Yup.string().trim().notRequired().nullable(),
      otherwise: Yup.string().trim().required('Primary category required')
    }),
  [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: Yup.mixed().when('dlm_source', {
    is: DlmSourceType.RENDER_SEO,
    then: Yup.mixed().required('Category required'),
    otherwise: Yup.mixed().notRequired()
  }),
  [DL_STORE_DETAILS_TAB_FIELDS.CITY]: Yup.string().trim().required('City required'),

  [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: Yup.string().trim().required('State/province required'),
  [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: Yup.string().trim().required('Zip/postal required'),
  [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: Yup.string().trim().required('Country required'),
  [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: Yup.string()
    .trim()
    .required('Phone number required')
    .test('len', 'Phone Number must be 10 digits', (val) => parsePhoneNumbers(val)?.length === 10)
});

/** Add New User Login validation */
export const addNewUserValidation = Yup.object().shape({
  [USER_LOGINS_NEW_USER_FILEDS.EMAIL]: Yup.string().trim().email('Please enter a valid email').required('Email required')
});

export const cpDetailsDataValidataion = Yup.object().shape({
  name: Yup.string().trim().required('Program name required').min(2, 'Program name should be minimum 2 characters'),
  programBudget: Yup.string()
    .trim()
    .when('rewardsType', {
      is: RewardType.CASH_PRIZE,
      then: Yup.string().trim().required('Program fund required').min(1, 'Program fund should be minimum 1 characters'),
      otherwise: Yup.string().trim().notRequired()
    }),
  startDate: Yup.mixed()
    .when('rewardsType', {
      is: RewardType.CASH_PRIZE,
      then: Yup.mixed().required('Start date required'),
      otherwise: Yup.mixed().notRequired()
    })
    .when('rewardsType', {
      is: RewardType.NON_CASH_PRIZE,
      then: Yup.mixed().required('Start date required'),
      otherwise: Yup.mixed().notRequired()
    }),
  endDate: Yup.mixed()
    .when('rewardsType', {
      is: RewardType.CASH_PRIZE,
      then: Yup.mixed().required('End date required'),
      otherwise: Yup.mixed().notRequired()
    })
    .when('rewardsType', {
      is: RewardType.NON_CASH_PRIZE,
      then: Yup.mixed().required('End date required'),
      otherwise: Yup.mixed().notRequired()
    })
    .when('duration', {
      is: Duration.ONETIME,
      then: Yup.mixed().required('End date required'),
      otherwise: Yup.mixed().notRequired()
    }),
  duration: Yup.mixed().when('rewardsType', {
    is: RewardType.CASH_PRIZE,
    then: Yup.mixed().required('Program type required'),
    otherwise: Yup.mixed().notRequired()
  })
});

export const cpActionValidataionSchema = Yup.object().shape({
  actions: Yup.array().of(
    Yup.object().shape({
      rewards: Yup.mixed().when('rewardsType', {
        is: RewardType.CASH_PRIZE,
        then: Yup.mixed().required('Rewards Required'),
        otherwise: Yup.mixed().notRequired()
      }),
      nonCashRewards: Yup.mixed().when('rewardsType', {
        is: RewardType.NON_CASH_PRIZE,
        then: Yup.mixed().required('Non Cash Rewards Required'),
        otherwise: Yup.mixed().notRequired()
      }),
      actionItem: Yup.object().shape({
        program: Yup.string().trim().required('Program required'),
        operator: Yup.string().trim().required('Operator required'),
        value: Yup.string().trim().required('Value Required'),
        employeesTodo: Yup.string().trim().required('Advocates Required')
      })
    })
  )
});

export const cpEmailValidation = Yup.object().shape({
  emailInvitationMessage: Yup.string().trim().required('Message Requires').max(300),
  emailScheduleDate: Yup.mixed().when('emailNotificationStatus', (emailNotificationStatus: number) => {
    if (emailNotificationStatus === 0) {
      return Yup.date().required('Schedule Date is required').min(new Date(), 'date should be less than current date');
    } else return Yup.mixed().notRequired();
  })
});

export const changePasswordValidation = Yup.object({
  current_password: Yup.string().trim().min(1, 'Must be at least 1 characters').required('Required'),
  new_password: Yup.string().trim().min(8, 'Must be at least 8 characters').required('Required'),
  confirm_password: Yup.string()
    .trim()
    .min(8, 'Must be at least 8 characters')
    .when('new_password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('new_password')], 'Both password need to be the same')
        .required('Required')
    })
});

export const profileInfoValidation = Yup.object().shape({
  firstName: Yup.string().trim().min(1, 'First name should be minimum 1 character').required('First name required'),
  lastName: Yup.string().trim().min(1, 'Last name should be minimum 1 character').required('Last name required'),
  facebookProfileUrl: Yup.string()
    .trim()
    .matches(/(https:\/\/)(www\.)(facebook)\.(com)\/?([a-zA-Z0-9_/.^$*+-?%!@#&]+)/, 'Enter valid url'),
  instagramProfileUrl: Yup.string()
    .trim()
    .matches(/(https:\/\/)(www\.)(instagram)\.(com)\/?([a-zA-Z0-9_/.^$*+-?%!@#&]+)/, 'Enter valid url'),
  twitterProfileUrl: Yup.string()
    .trim()
    .matches(/(https:\/\/)(twitter)\.(com)\/?([a-zA-Z0-9_/.^$*+-?%!@#&]+)/, 'Enter valid url'),
  linkedinProfileUrl: Yup.string()
    .trim()
    .matches(/(https:\/\/)(www\.)(linkedin)\.(com)\/?([a-zA-Z0-9_/.^$*+-?%!@#&]+)/, 'Enter valid url')
});

export const salesDemoValidation = Yup.object().shape({
  name: Yup.string().trim().min(2, 'Name should be minimum 2 character').required('Name required'),
  email: Yup.string().trim().email('Please enter a valid email').required('Email required'),
  regarding: Yup.array().min(1, 'Regarding should be minimum 1').required('Regarding field required')
});

export const adminUserDetailsValidation = Yup.object().shape({
  name: Yup.string().trim().min(2, 'Name should be minimum 2 character').required('Name required'),
  email: Yup.string().trim().email('Please enter a valid email').required('Email required')
});

export const revvLoginValidation = Yup.object().shape({
  email: Yup.string().trim().email('Please enter a valid email.').required('Email required'),
  password: Yup.string().trim().required('Password required').min(8, 'Password should be minimum 8 characters').max(20, 'Password should not be maximum 20 characters')
});

export const postStartCampaignValidation = Yup.object().shape({
  campaign_post_per_week: Yup.number().moreThan(0).required('Campaign posts count required'),
  end_date: Yup.date().when('duration_type', {
    is: CampaignDurationType.END_DATE,
    then: Yup.date().required('End date required'),
    otherwise: Yup.date().notRequired().nullable()
  })
});

export const createOrEditCampaignValidation = Yup.object().shape({
  name: Yup.string().trim().min(1, 'Name should be minimum 1 character').required('Name required'),
  // description: Yup.string().trim().min(1, 'Description should be minimum 1 character').required('Description required'),
  expired_date: Yup.date().when('availability', {
    is: ICampaignAvailability.EXPIRES,
    then: Yup.date().required('Expired date required'),
    otherwise: Yup.date().notRequired().nullable()
  })
});

export const aiPlayBookBusinessValidation = Yup.object().shape({
  [AI_PLAYBOOK_TAB_FIELDS.NAME]: Yup.string().trim().required('Name required').min(3, 'min error')
});

export const keywordsCategoryValidation = Yup.object().shape({
  [KEYWORDS_CATEGORY_TAB_FIELDS.NAME]: Yup.string().trim().required('Name required').min(3, 'min error'),
  [KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION]: Yup.string().trim().required('Description is required'),
  [KEYWORDS_CATEGORY_TAB_FIELDS.KEYWORDS]: Yup.array().min(1).required('Keywords categories must be selected at least 1')
});

export const googleCallToActionValidation = (userOwnership: string, currentTab: string) => {
  return Yup.object().shape({
    action_type: Yup.string().trim().required('Option is required'),
    action_url: Yup.string()
      .trim()
      .when('action_type', {
        is: (val: string) =>
          val === GoogleCallToActionOptionsName.NONE || (val === GoogleCallToActionOptionsName.CALL && currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && userOwnership === USER_OWNERSHIP.ACCOUNT),
        then: (schema) => schema,
        otherwise: Yup.string()
          .trim()
          .test('test-name', 'Enter Valid Url/Email', (value) => {
            if (value) {
              // validate the string is valid url or phone
              const phoneRegex = VALID_PHONE_NUMBER_REGEX;
              const isValidURL = isValidUrl(value);
              const isValidPhone = new RegExp(phoneRegex).test(value) && parsePhoneNumbers(value)?.length === 10;
              if (!isValidURL && !isValidPhone) {
                return false;
              }
              return true;
            } else {
              return false;
            }
          })
          .required('Url/Phone is required.')
      })
  });
};

export const customizeLinkValidation = () =>
  Yup.object().shape({
    link_preview_title: Yup.string().trim().required('Title is required').min(3, 'min error'),
    link_preview_description: Yup.string().trim(),
    link_preview_image_url: Yup.string().trim().nullable().required('Image thumbnail is required')
  });

export const IAIPlaybookActivityGoalValidation = () =>
  Yup.object().shape({
    [AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS.ACTIVITY_NAME]: Yup.string().trim().required('Name is required').min(3, 'min error'),
    [AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS.FREQUENCY]: Yup.string().required('Frequency is required'),
    [AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS.GOAL_COUNT]: Yup.number().typeError('Goal count must be a number').min(1, 'Activity goal must be greater than 0').required('Goal count is required'),
    [AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS.ACTIVITY_GOAL_TYPE_ID]: Yup.number()
      .typeError('Activity goal id must be a number')

      .required('Activity goal id is required'),
    [AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS.ACCOUNT_IDS]: Yup.array().of(Yup.number()).min(1).required('Account ids are required')
  });

export const createCommunicationValidation = () =>
  Yup.object().shape({
    [COMMUNICATION_TAB_FIELDS.SUBJECT]: Yup.string().trim().required('Subject is required').min(3, 'min error'),
    [COMMUNICATION_TAB_FIELDS.BODY]: Yup.string().trim().required('Body is required')
  });

export const accSchedulerValidation = (userOwnership: string) =>
  Yup.object().shape({
    [ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.DAY_OF_WEEK]: Yup.number(),
    [ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.VERTICAL_ID]: Yup.number().nullable(),
    [ACCOUNT_LIST_SCHEDULE_TAB_FIELDS.ACCOUNT_LIST_ID]: Yup.mixed()
      .notRequired()
      .when({
        is: () => userOwnership === USER_OWNERSHIP.ACCOUNT,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required('Source required')
      }),
    start_date: Yup.date().when('duration_type', {
      is: CampaignDurationType.END_DATE,
      then: Yup.date().required('Start date required'),
      otherwise: Yup.date().notRequired().nullable()
    }),
    end_date: Yup.date().when('duration_type', {
      is: CampaignDurationType.END_DATE,
      then: Yup.date().required('End date required'),
      otherwise: Yup.date().notRequired().nullable()
    })
  });
export const contentFeedValidation = () =>
  Yup.object().shape({
    name: Yup.string().trim().required('Name required'),
    content_suppliers: Yup.array().when(['feed_type', 'content_source'], {
      is: (feedType: string, contentSource: number) => feedType === FEED_TYPE.CONTENT_SUPPLIER && contentSource === CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.CONTENT_SUPPLIER,
      then: Yup.array().min(1, 'At least one Content Supplier must be selected').required('At least one Content Supplier must be selected'),
      otherwise: Yup.array().notRequired()
    }),
    feed_type: Yup.string().required('Feed type required'),
    content_source: Yup.number().when('feed_type', {
      is: (feedType: string) => feedType === FEED_TYPE.STANDARD,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Content source required')
    }),
    tags_list: Yup.array().of(Yup.mixed()).notRequired()
  });

export const puchasedPartnerBannerValidation = () =>
  Yup.object().shape({
    // cloudinary_id: Yup.string().required('Image is required'),
    photo_url: Yup.string().required('Photo url is requried'),
    redirect_url: Yup.string()
      .required('Redirect url is requried')
      .test('redirect_url', 'Redirect url is not valid', (value) => (value ? isValidUrl(value) : false))
  });

export const aiReviewResponderValidation = () =>
  Yup.object().shape({
    contact_information_email: Yup.string().trim().email('Please enter a valid email').required('Email required'),
    contact_information_phone: Yup.string()
      .trim()
      .test('len', 'Phone Number must be 10 digits', (val) => parsePhoneNumbers(val)?.length === 10)
  });
