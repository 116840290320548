import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'analytics/actions/action-types';
import { getProfileAnalyticsFailure, getProfileAnalyticsResponse } from 'analytics/actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { exceptionHandlerRequest, logoutRequest } from 'actions';
import { PROFILE_ANALYTICS_FAILED, PROFILE_ANALYTICS_FETCHING_LIST_FAILED } from 'analytics/utils';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getProfileAnalyticsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;

    const headers = defaultHeader();
    const path = API.getProfileAnalytics.apiPath;
    const response = yield apiCall({ headers, params: payload, apiPath: path, action: API.getProfileAnalytics.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getProfileAnalyticsResponse(response.data));
    } else {
      yield put(getProfileAnalyticsFailure(response?.data?.error || PROFILE_ANALYTICS_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PROFILE_ANALYTICS_FAILED));
  }
}

export function* takeProfileAnalyticsRequest() {
  yield takeLatest(types.GET_PROFILE_ANALYTICS_REQUEST, getProfileAnalyticsRequest);
}
