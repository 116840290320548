import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { ConnectionsAction } from 'actions';
import { connectionReducerTypes } from 'types';
import { CONNECTION_TABS, CN_STATS_SUMMARY_INIT_OBJ, COMMON_PAGE_TYPE } from 'utils/constants';

const initialState: connectionReducerTypes.IConnectionsReducer = {
  isFetching: true,
  error: null,
  currentTab: CONNECTION_TABS.LANDING,
  connectionAccountList: [],
  cnStatsSummary: CN_STATS_SUMMARY_INIT_OBJ
};

const connectionsReducer: Reducer<connectionReducerTypes.IConnectionsReducer, ConnectionsAction> = (
  state: connectionReducerTypes.IConnectionsReducer = initialState,
  action: ConnectionsAction
): connectionReducerTypes.IConnectionsReducer => {
  switch (action.type) {
    // CONNECTIONS - GET LOCATION LIST
    case types.GET_CONNECTIONS_LOCATION_LIST_REQUEST:
      return {
        ...state,
        isFetching: action.payload?.type === COMMON_PAGE_TYPE.EDIT ? false : true
      };
    case types.GET_CONNECTIONS_LOCATION_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        cnStatsSummary: action.payload.summary,
        connectionAccountList: action.payload.account_connection_grid_rows
      };
    case types.GET_CONNECTIONS_LOCATION_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        cnStatsSummary: initialState.cnStatsSummary,
        connectionAccountList: initialState.connectionAccountList,
        error: action.payload || 'Unexpected error'
      };
    // CONNECTIONS RESET
    case types.CN_LOC_LIST_AND_STATS_RESET:
      return {
        ...state,
        connectionAccountList: initialState.connectionAccountList,
        cnStatsSummary: initialState.cnStatsSummary
      };
    case types.CN_RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};

export { connectionsReducer };
