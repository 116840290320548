import { CustomDropdown } from 'widgets/CustomDropdown';

export const ViewBoostButton = () => {
  return (
    <div className="white-box button-wrp">
      <div>
        <span className="title">Button</span>
      </div>
      <div className="button-option">
        <div className={`form-group form-field-item fg-dropdown m-align-items cs-item`}>
          <CustomDropdown classNamePrefix="cs-select" id="dl-store-details-country-dropdown" placeholder={''} options={undefined} />
          <span className="fltlabels">
            Learn More<span className="mandatory cursor-help">*</span>
          </span>
        </div>
        <div className={`form-group form-field-item`}>
          <input type="text" autoComplete="off" className="form-control" />
          <span className="fltlabels">
            Website URL<span className="mandatory cursor-help">*</span>
          </span>
        </div>
      </div>
    </div>
  );
};
