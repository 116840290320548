import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { ALERT_BOX_TYPES } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { alertBoxCall } from 'components';
import { useAccountSwitcherData } from 'utils/hooks';
import { IStore } from 'types';

export const ConnectSocialPlatforms = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);

  const handleNavigateToSocialProfile = () => {
    if (menuAvailablity.settings?.social_profile) {
      navigate(`/${userOwnership}/${id.toString()}/settings/social_profile`);
    } else {
      alertBoxCall(ALERT_BOX_TYPES.WARN, "You don't have access to configure social settings, Please contact your admin.");
    }
  };

  return (
    <div className="disconnect-social__wrp">
      <div className="inner__wrp">
        <ImageValidation isImgValid defaultImg="disconnect-media" customClassname="disconn-media" customName="disconnect" />
        <h2 className="message-title">Connect your social platforms</h2>
        <div className="message">“Let’s light this up! Connect at least one social platform, and the numbers will roll in!”</div>
        <button className="connect-btn" onClick={() => handleNavigateToSocialProfile()}>
          Connect Now
        </button>
      </div>
    </div>
  );
};
