import {
  IGetKeywordsCategoryRequest,
  IReviewAnalyticsRequest,
  IReviewAnalyticsResponse,
  IReviewAnalyticsSummaryResponse,
  IReviewKeywordsResponse,
  IUniqueKeywordsRequest,
  IKeywordsCategory,
  ICreateUpdateKeywordsCategoryRequest,
  IDeleteKeywordsCategoryRequest,
  IReviewAllAvailableKeywordsResponse,
  IKeywordsCategoryAnalyticsRequest,
  IKeywordsCategoryAnalyticsResponse
} from 'analytics/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

// REVIEW ANALYTICS - GET REVIEW ANALYTICS
export const getReviewAnalyticsRequest = (payload: IReviewAnalyticsRequest) => createAction(actionTypes.GET_REVIEW_ANALYTICS_REQUEST, payload);
export const getReviewAnalyticsResponse = (response: IReviewAnalyticsResponse) => createAction(actionTypes.GET_REVIEW_ANALYTICS_RESPONSE, response);
export const getReviewAnalyticsFailure = (error: null | string) => createAction(actionTypes.GET_REVIEW_ANALYTICS_FAILURE, error);

export const getReviewAnalyticsSummaryRequest = (payload: IReviewAnalyticsRequest) => createAction(actionTypes.GET_REVIEW_ANALYTICS_SUMMARY_REQUEST, payload);
export const getReviewAnalyticsSummaryResponse = (response: IReviewAnalyticsSummaryResponse) => createAction(actionTypes.GET_REVIEW_ANALYTICS_SUMMARY_RESPONSE, response);
export const getReviewAnalyticsSummaryFailure = (error: null | string) => createAction(actionTypes.GET_REVIEW_ANALYTICS_SUMMARY_FAILURE, error);

export const getKeyWordDataRequest = (payload: IReviewAnalyticsRequest) => createAction(actionTypes.GET_KEY_WORD_DATA_REQUEST, payload);
export const getAllKeyWordDataResponse = (response: IReviewKeywordsResponse) => createAction(actionTypes.GET_KEY_WORD_DATA_RESPONSE, response);
export const getKeyWordDataFailure = (error: null | string) => createAction(actionTypes.GET_KEY_WORD_DATA_FAILURE, error);

export const getUniqueKeyWordDataRequest = (payload: IUniqueKeywordsRequest) => createAction(actionTypes.GET_UNIQUE_KEY_WORD_DATA_REQUEST, payload);
export const getUniqueKeyWordDataResponse = (response: any) => createAction(actionTypes.GET_UNIQUE_KEY_WORD_DATA_RESPONSE, response);
export const getUniqueKeyWordDataFailure = (error: null | string) => createAction(actionTypes.GET_UNIQUE_KEY_WORD_DATA_FAILURE, error);

export const setKeyWordData = (keyword: string | null) => createAction(actionTypes.SET_KEY_WORD_DATA, keyword);

export const resetKeywordsState = () => createAction(actionTypes.RESET_KEY_WORD_DATA_STATE);

export const resetKeywordsList = () => createAction(actionTypes.RESET_KEY_WORD_LIST);

export const resetReviewState = () => createAction(actionTypes.RESET_REVIEW_STATE);

// get keywordsCategoryList
export const getKeyWordsCategoryListRequest = (payload: IGetKeywordsCategoryRequest) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_LIST_REQUEST, payload);
export const getKeyWordsCategoryListResponse = (response: IKeywordsCategory[]) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_LIST_RESPONSE, response);
export const getKeyWordsCategoryListFailure = (error: null | string) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_LIST_FAILURE, error);

// get keywordsCategory
export const getKeyWordsCategoryRequest = (payload: number) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_REQUEST, payload);
export const getKeyWordsCategoryResponse = (response: IKeywordsCategory) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_RESPONSE, response);
export const getKeyWordsCategoryFailure = (error: null | string) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_FAILURE, error);

// create keywordsCategory
export const createUpdateKeyWordsCategoryRequest = (payload: ICreateUpdateKeywordsCategoryRequest) => createAction(actionTypes.CREATE_UPDATE_KEY_WORDS_CATEGORY_REQUEST, payload);
export const createUpdateKeyWordsCategoryResponse = () => createAction(actionTypes.CREATE_UPDATE_KEY_WORDS_CATEGORY_RESPONSE);
export const createUpdateKeyWordsCategoryFailure = (error: null | string) => createAction(actionTypes.CREATE_UPDATE_KEY_WORDS_CATEGORY_FAILURE, error);

// delete keywordsCategory
export const deleteKeyWordsCategoryRequest = (payload: IDeleteKeywordsCategoryRequest) => createAction(actionTypes.DELETE_KEY_WORDS_CATEGORY_REQUEST, payload);
export const deleteKeyWordsCategoryResponse = () => createAction(actionTypes.DELETE_KEY_WORDS_CATEGORY_RESPONSE);
export const deleteKeyWordsCategoryFailure = (error: null | string) => createAction(actionTypes.DELETE_KEY_WORDS_CATEGORY_FAILURE, error);

// get keywords of specific category
export const getKeyWordCategoryDataResponse = (response: IReviewAllAvailableKeywordsResponse) => createAction(actionTypes.GET_KEY_WORD_CATEGORY_DATA_RESPONSE, response);

// open keywords categorymodal
export const openKeywordsCategoryModal = (payload: { isShowModal: boolean; type: string }) => createAction(actionTypes.OPEN_KEY_WORD_CATEGORY_MODEL, payload);

// reset keywords category modal
export const keywordsCategoryModalReset = () => createAction(actionTypes.KEY_WORD_CATEGORY_MODEL_RESET);

// get keywords category analytics
export const getKeyWordsCategoryAnalyticsRequest = (payload: IKeywordsCategoryAnalyticsRequest) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_ANALYTICS_REQUEST, payload);
export const getKeyWordsCategoryAnalyticsResponse = (response: IKeywordsCategoryAnalyticsResponse) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_ANALYTICS_RESPONSE, response);
export const getKeyWordsCategoryAnalyticsFailure = (error: null | string) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_ANALYTICS_FAILURE, error);

// get keywords category chartData
export const getKeyWordsCategoryChartData = (payload: number | null) => createAction(actionTypes.GET_KEY_WORDS_CATEGORY_CHART_DATA, payload);

const REVIEW_ANALYTICS = {
  getReviewAnalyticsRequest,
  getReviewAnalyticsResponse,
  getReviewAnalyticsFailure,
  getReviewAnalyticsSummaryRequest,
  getReviewAnalyticsSummaryResponse,
  getReviewAnalyticsSummaryFailure,
  getKeyWordDataRequest,
  getAllKeyWordDataResponse,
  getKeyWordDataFailure,
  resetKeywordsState,
  getUniqueKeyWordDataRequest,
  getUniqueKeyWordDataResponse,
  getUniqueKeyWordDataFailure,
  setKeyWordData,
  resetKeywordsList,
  resetReviewState,
  getKeyWordsCategoryListRequest,
  getKeyWordsCategoryListResponse,
  getKeyWordsCategoryListFailure,
  getKeyWordsCategoryRequest,
  getKeyWordsCategoryResponse,
  getKeyWordsCategoryFailure,
  createUpdateKeyWordsCategoryRequest,
  createUpdateKeyWordsCategoryResponse,
  createUpdateKeyWordsCategoryFailure,
  deleteKeyWordsCategoryRequest,
  deleteKeyWordsCategoryResponse,
  deleteKeyWordsCategoryFailure,
  getKeyWordCategoryDataResponse,
  openKeywordsCategoryModal,
  keywordsCategoryModalReset,
  getKeyWordsCategoryAnalyticsRequest,
  getKeyWordsCategoryAnalyticsResponse,
  getKeyWordsCategoryAnalyticsFailure,
  getKeyWordsCategoryChartData
};

export type ReviewAnalyticsActions = ActionsUnion<typeof REVIEW_ANALYTICS>;
