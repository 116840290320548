import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Sales } from '../../containers/Sales';
import { IStore } from 'types';
import { useAccountSwitcherData, useNavBarData, useActivePlatform } from 'utils/hooks';
import { showSalesPage, showConnectPlatformPage } from 'utils/helpers';
import { ConnectSocialPlatforms } from 'components';
import { COMMON_SOCIAL_PROVIDER_TYPE, ROUTES } from 'utils/constants';

export const SalesPageRoot = (props: { children: JSX.Element }) => {
  const { currentRoute } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  const activePlatforms = useActivePlatform();

  const salesPages = useSelector((state: IStore) => state.common.salesPages);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);
  const location = `/${currentRoute}`;

  const showSales = useMemo(() => {
    return showSalesPage(salesPages, location);
  }, [salesPages, location]);

  const showConnectPlatform = useMemo(() => {
    return showConnectPlatformPage(subNavPageName);
  }, [subNavPageName]);

  const isRevvPage = useMemo(() => {
    const revvPath = ['analytics_leaderboard'];
    return !!revvPath.find((it) => it === subNavPageName) ? !isRevvAuthenticated && !showRevv && !isRevvConnectedToCurrentFranchisor : true;
  }, [isRevvAuthenticated, showRevv, subNavPageName, isRevvConnectedToCurrentFranchisor]);

  return showSales ? (
    <Sales />
  ) : showConnectPlatform &&
    ((!activePlatforms?.length && isRevvPage) ||
      (activePlatforms.length === 1 &&
        activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.YELP) &&
        !(ROUTES.ANALYTICS_REVIEW.includes(currentRoute) || ROUTES.REPUTATION_REVIEWS.includes(currentRoute)))) ? (
    <ConnectSocialPlatforms />
  ) : (
    props.children
  );
};
