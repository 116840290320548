import { useFormik } from 'formik';
import { Button, Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, commonModalPopup, communicationContainerTypes } from 'types';
import { RIPPLE_COLOR } from 'utils/constants';
import { COMMUNICATION_TAB_FIELDS } from 'utils/constants/communications';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { TextArea } from 'widgets/Text';
import { communicationCreateUpdateRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { createCommunicationValidation } from 'utils/helpers';

export const CreateCommunications = ({ isShowModal, onModalClose, communicationData }: commonModalPopup.ICreateCommunicationProps) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();

  const isFetching = useSelector((state: IStore) => state.communications.isFetching);

  const handleModalClose = () => {
    onModalClose();
    resetForm();
  };

  const getInitialValues = () => {
    let initialValues = {
      subject: '',
      body: ''
    };
    if (communicationData?.id) {
      initialValues = {
        subject: communicationData?.subject,
        body: communicationData?.body
      };
    }
    return initialValues;
  };

  const { values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: createCommunicationValidation,
    onSubmit: (values: communicationContainerTypes.ICreateCommunicationInitialValues) => {
      dispatch(communicationCreateUpdateRequest(communicationData?.id ? { id: communicationData.id, ...values } : { franchisor_id: id, ...values }));
      handleModalClose();
    }
  });

  const getInputBoxClassName = (valueItem: keyof communicationContainerTypes.ICreateCommunicationInitialValues) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const getClassNames = (valueItem: keyof communicationContainerTypes.ICreateCommunicationInitialValues) => {
    let className = 'form-control';
    className = `${className} ${values[valueItem] && values[valueItem] !== '' && values[valueItem] !== null ? 'used' : ''} ${touched[valueItem] && errors[valueItem] ? 'err-disp' : ''}`;
    return className;
  };

  return (
    <>
      <Modal
        // className={`creator-tag-modal-wrp schedule-post-modal-hubuser glbl__modal--wrp schedule-post__main cr-sm-mdl schCorpPost ${className || ''}`}
        className={`glbl__modal--wrp cr-sm-mdl schCorpPost nmcWrap`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="esDate-bckdrp"
        show={isShowModal}
        animation={false}
      >
        <div className="mod__close--icon" onClick={handleModalClose}>
          <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" />
        </div>
        <Modal.Body>
          <h3 className="glbl__title--txt">{communicationData?.id ? 'Edit' : 'New'} Communication</h3>
          <Card className="glbl__modal--card">
            <Card.Body className="nc-fields">
              <Card.Header>Details</Card.Header>
              <div className={`form-group form-field-item ${getInputBoxClassName(COMMUNICATION_TAB_FIELDS.SUBJECT)}`}>
                <input
                  type="text"
                  name={COMMUNICATION_TAB_FIELDS.SUBJECT}
                  className={`nc-item nc-title ${getClassNames(COMMUNICATION_TAB_FIELDS.SUBJECT)}`}
                  value={values[COMMUNICATION_TAB_FIELDS.SUBJECT]}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="fltlabels">Title</span>
              </div>
              <div className={`form-group form-field-item ncTa ${getInputBoxClassName(COMMUNICATION_TAB_FIELDS.BODY)}`}>
                <TextArea
                  name={COMMUNICATION_TAB_FIELDS.BODY}
                  placeholder="Type here..."
                  id="exampleForm.ControlTextarea1"
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => setFieldValue(COMMUNICATION_TAB_FIELDS.BODY, e.target.value)}
                  onBlur={handleBlur}
                  value={values[COMMUNICATION_TAB_FIELDS.BODY]}
                  maxRows={2}
                />
                <span className="fltlabels">Description</span>
              </div>
            </Card.Body>
          </Card>
          {!communicationData || communicationData?.can_update ? (
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <Button className={`ac-btn ac-primary ${isFetching ? 'pointer-events-none' : ''}`} onClick={() => handleSubmit()}>
                <span>Save and Send Now</span>
              </Button>
            </CustomRippleButton>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};
