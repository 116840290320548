import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { downloadFormatHandler, exportCsvhandler, handleFilterChange } from 'analytics/utils/helpers';
import {
  REVIEW_ANALYTICS_PLATFORM_FILTER,
  REVIEW_ANALYTICS_FILTER_INITIAL_VALUE,
  RATING_STATUS,
  RatingStatusValue,
  ExportCsvFileName,
  REVIEW_ANALYTICS_STATS,
  ReviewAnalyticsStatsValue,
  ValidQueryParams,
  KEY_WORD_MANAGEMENT_TABLE_HEADERS
} from 'analytics/utils';
import { alertBoxCall, CommonFilter } from 'components';
import {
  BUTTON_PROPS,
  EXPORT_DOWNLOAD_STARTED_MSG,
  USER_OWNERSHIP,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  ALERT_BOX_TYPES,
  DONE_SUCCESS_MSG,
  COMMON_PAGE_TYPE
} from 'utils/constants';
import { useAvailablePlatforms, useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { IFiterHandleChangeData } from 'types/common/filter';
import { openKeywordsCategoryModal, resetKeywordsList } from 'analytics/actions';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const ReviewAnalyticsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams, filter: basicQueryFilter } = useParamsDeconstructor(REVIEW_ANALYTICS_FILTER_INITIAL_VALUE);

  const data = useSelector((state: IStore) => state.reviewAnalytics.data);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const keyWordsData = useSelector((state: IStore) => state.reviewAnalytics.keyWordsData);
  const yelpBusinessId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.yelp_business_id);
  const isReviewAnalyticsFetching = useSelector((state: IStore) => state.reviewAnalytics.isReviewAnalyticsFetching);
  const isReviewAnalyticsSummaryFetching = useSelector((state: IStore) => state.reviewAnalytics.isReviewAnalyticsSummaryFetching);
  const isKeyWordDataFetching = useSelector((state: IStore) => state.reviewAnalytics.isKeyWordDataFetching);
  const uniqueKeyWord = useSelector((state: IStore) => state.reviewAnalytics.uniqueKeyWord);
  const availablePlatform = useAvailablePlatforms(REVIEW_ANALYTICS_PLATFORM_FILTER, userOwnership, yelpBusinessId || null, false);
  const keywordsCategoryList = useSelector((state: IStore) => state.reviewAnalytics.keywordsCategoryList);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const queryParamKeys = Object.keys(queryParams).filter((it) => !['selected_tile', 'sort_by', 'ascending'].includes(it));
  const isClearFilterDisabled = availablePlatform.length === 1 ? (queryParamKeys?.length === 1 && queryParams?.platform ? true : false) : !queryParamKeys?.length;
  const exportData = queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS ? keyWordsData : data?.reviews_analytics_by_date || [];
  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const filter = {
    ...basicQueryFilter,
    ratings: queryParams?.ratings?.split(',') || [],
    category: queryParams?.category || 'all'
  };
  const isDataFetching = queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS ? isReviewAnalyticsFetching || isReviewAnalyticsSummaryFetching : isKeyWordDataFetching;

  const isValidParentId = useMemo(() => {
    return userOwnership === USER_OWNERSHIP.FRANCHISOR ? currentAccountOrFranchisor?.id === topLevelFranchisorId : false;
  }, [userOwnership, topLevelFranchisorId, currentAccountOrFranchisor]);

  const ratingStatus =
    filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      ? RATING_STATUS.filter((it) => it.value === RatingStatusValue.ALL || it.value === RatingStatusValue.RECOMMENDED || it.value === RatingStatusValue.NOT_RECOMMENDED)
      : filter.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL
      ? RATING_STATUS
      : [...RATING_STATUS].slice(0, 6);

  const isRatings = Boolean(queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS && uniqueKeyWord) || queryParams?.selected_tile === ReviewAnalyticsStatsValue.AVERAGE_RATING;

  const validQueryParamsKeys = {
    ...ValidQueryParams,
    ...(isRatings ? { RATINGS: 'ratings' } : {}),
    ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.REPLY ? { IS_NUMBER: 'is_number' } : {}),
    ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS
      ? {
          SORT_BY: 'sort_by',
          ASCENDING: 'ascending'
        }
      : {}),
    ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS ? { CATEGORY: 'category' } : {})
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS && !data?.category) {
      dispatch(resetKeywordsList());
    }
    const queryParamsClone = handleFilterChange(data, isClear, id, queryParams, availablePlatform);
    if (data.ratings && Array.isArray(data.ratings)) {
      if (data.ratings.length === 0) {
        delete queryParamsClone.ratings;
      } else {
        queryParamsClone.ratings = data.ratings.join(',');
      }
    }
    if (data.platform === 'facebook' && queryParamsClone?.ratings) {
      const ratings = queryParamsClone?.ratings?.split(',')?.filter((it: string) => ratingStatus.some((datum) => datum.value === it));
      if (ratings.length === 0) {
        delete queryParamsClone.ratings;
      } else {
        queryParamsClone.ratings = ratings.join(',');
      }
    }
    if (data.category) {
      if (data?.category === 'all') {
        delete queryParams?.category;
      } else {
        queryParams.category = data.category;
      }
    }

    navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
  };

  useEffect(() => {
    if (availablePlatform.length === 1 && !queryParams?.platform) {
      const params = { platform: availablePlatform[0].value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, queryParams?.platform]); // eslint-disable-line

  useParamsValidator(
    { ...CommonValidQueryParams, ...validQueryParamsKeys },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      selected_tile: REVIEW_ANALYTICS_STATS.map((it) => it.value),
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || ''),
      ...(isRatings ? { ratings: ratingStatus.map((it) => it.value) } : {}),
      ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.REPLY ? { is_number: ['1'] } : {}),
      ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS
        ? {
            sort_by: KEY_WORD_MANAGEMENT_TABLE_HEADERS.map((it) => it.value),
            ascending: ['1']
          }
        : {}),
      ...(queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS ? { CATEGORY: ['category'] } : {})
    }
  );

  const handleExportCsv = () => {
    alertBoxCall(ALERT_BOX_TYPES.INFO, EXPORT_DOWNLOAD_STARTED_MSG);
    exportCsvhandler(
      downloadFormatHandler,
      exportData,
      DONE_SUCCESS_MSG,
      queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS ? ExportCsvFileName.KM : ExportCsvFileName.RA,
      currentName,
      id,
      userOwnership
    );
  };

  return (
    <CommonFilter
      filter={filter}
      handleChange={handleChange}
      analyticsPlatformFilter={availablePlatform}
      isLocation={isHubUser}
      primaryButtons={[
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled || isDataFetching
        }
      ]}
      multiSelectCheckBoxFilter={
        queryParams?.selected_tile === ReviewAnalyticsStatsValue.AVERAGE_RATING
          ? [
              {
                title: 'Rating',
                filter: ratingStatus,
                value: 'ratings'
              }
            ]
          : []
      }
      handleExportCsv={handleExportCsv}
      renderSuffixData={() => (
        <div className="filter-item filter-item-tag__item status-list tagnew-margin kc-fltr">
          {queryParams?.selected_tile === ReviewAnalyticsStatsValue.KEYWORDS && userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
            <>
              <div className="imtc ef-edit">
                {keywordsCategoryList.length ? <h3>Operational Categories</h3> : null}
                {keywordsCategoryList.length ? (
                  <div
                    className="edit-icon-post"
                    onClick={
                      () => dispatch(openKeywordsCategoryModal({ isShowModal: true, type: COMMON_PAGE_TYPE.EDIT }))
                      // navigate({ pathname: `/${userOwnership}/${id}/${currentRoute}/category/edit/${queryParams?.category || ''}`, search: `?${new URLSearchParams(queryParams).toString()}` })
                    }
                  >
                    {isValidParentId && (
                      <>
                        <ImageValidation isImgValid defaultImg="create_post-edit" customName="Edit" />
                        <span className="cf-values">Edit</span>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
              {keywordsCategoryList.length ? (
                <div className="fts">
                  <span className={`fav-tags ${!queryParams.category ? 'active' : ''}`} onClick={() => handleChange({ category: 'all' })}>
                    All
                  </span>
                  {keywordsCategoryList.map((category, index) => {
                    return (
                      <span
                        key={`posts-fav-tags-${index}`}
                        className={`fav-tags ${queryParams.category === category.id.toString() ? 'active' : ''}`}
                        onClick={() => handleChange({ category: category.id.toString() })}
                      >
                        {category.name}
                      </span>
                    );
                  })}
                </div>
              ) : null}
              {isValidParentId && keywordsCategoryList.length < 5 ? (
                <CustomRippleButton>
                  <button
                    className={`ac-btn ac-primary-light ac-outline ac-block anc-btn ?'':''}`}
                    onClick={() => dispatch(openKeywordsCategoryModal({ isShowModal: true, type: COMMON_PAGE_TYPE.NEW }))}
                    // navigate({ pathname: `/${userOwnership}/${id}/analytics/analytics_reviews/category/new`, search: `?${new URLSearchParams(queryParams).toString()}` })
                  >
                    <ImageValidation isImgValid defaultImg="add-circle-blue" customName="Add New Category" />
                    <span>Add New Category</span>
                  </button>
                </CustomRippleButton>
              ) : null}
            </>
          ) : null}
        </div>
      )}
    />
  );
};
