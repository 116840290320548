import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Identifier } from 'dnd-core';
import { commonWidgetTypes } from 'types';

export const ReactDNDItem = ({ index, type, data, children, disabled, onSortData }: commonWidgetTypes.IReactDNDProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<commonWidgetTypes.IReactDNDDragItem, void, { handlerId: Identifier | null }>({
    accept: type,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    drop: (item) => {
      const dragIndex = item.index;
      const dropIndex = index;

      // If the dragged element is dropped in the same place, then do nothing
      if (dragIndex === dropIndex) {
        return;
      }

      onSortData({ dragIndex, dropIndex, draggedItem: item.data });
      item.index = dropIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => {
      return { data, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const getClassName = () => {
    return `drag-item${disabled ? ` events-none` : isDragging ? ` drag-grab` : ''}`;
  };

  drag(drop(ref));

  return (
    <div className={getClassName()} ref={ref} data-handler-id={handlerId}>
      <span className="cmpgnCount">{index + 1}</span>
      {children}
    </div>
  );
};
