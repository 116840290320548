import { apAccountReducerTypes, apFranContainerTypes, postsReducerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { dateObjBasedOnTimeZone, convertStringToEditorState, createEmptyEditorState } from 'utils/helpers';

/** Get RSS feed link for Creator page */
export const getRSSFeedLinkInCreator = (linkUrl: string, activePlatforms: string[], accountTzInfoName: string): apAccountReducerTypes.IAPSavedPostDetails => {
  return {
    current_tab: activePlatforms?.length ? activePlatforms[0] : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
    use_facebook: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? true : false,
    use_instagram: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) ? true : false,
    use_twitter: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) ? true : false,
    use_linkedin: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) ? true : false,
    use_google: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) ? true : false,
    use_tiktok: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) ? true : false,
    facebook_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? linkUrl : '',
    twitter_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) ? linkUrl : '',
    linkedin_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) ? linkUrl : '',
    instagram_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) ? linkUrl : '',
    google_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) ? linkUrl : '',
    tiktok_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) ? linkUrl : '',
    mobile_app_only_enabled: true,
    locked: false,
    link_index: 0,
    is_thumbnail_show: true,
    status_name: null,
    schedule_date_time: dateObjBasedOnTimeZone(accountTzInfoName),
    account_schedule_date_time: dateObjBasedOnTimeZone(accountTzInfoName),
    campaigns: [],
    instagram_reel: false,
    facebook_reel: false,
    google_call_to_action_type: null,
    google_call_to_action_url: null,
    facebook_call_to_action_type: null,
    facebook_call_to_action_link: null,
    personalized_facebook_call_to_action_link: '',
    link_preview_customized: false,
    video_cover_cloudinary_id: null,
    facebook_story: false,
    instagram_story: false
  };
};

/** Get edited RSS feed link for Creator page */
export const getEditedRSSFeedLinkInCreator = (linkUrl: string, activePlatforms: string[]): postsReducerTypes.IPostDetailsData => {
  return {
    account: null,
    boost_offer_id: null,
    cloudinary_resource_type: null,
    cloudinary_video_id: null,
    created_at: '',
    created_user: {
      id: 0,
      name: '',
      profile_photo_url: null
    },
    end_date: null,
    facebook_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? linkUrl : '',
    franchisor: {
      id: 0,
      name: ''
    },
    google_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) ? linkUrl : '',
    id: 0,
    instagram_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) ? linkUrl : '',
    link_preview_description: '',
    link_preview_image_url: '',
    link_preview_image_url_choices: [],
    link_preview_title: '',
    link_preview_customized: false,
    linkedin_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) ? linkUrl : '',
    locked: false,
    mobile_app_only_enabled: true,
    pad_instagram_photos: false,
    page_tag_details: {
      facebook: []
    },
    photo_url: '',
    photo_urls: [],
    instagram_photo_url: null,
    post_published_count: 0,
    preview_facebook_text: '',
    preview_long_text: '',
    preview_short_text: '',
    rejected: false,
    start_date: null,
    status_id: 0,
    syndicated_from_content_id: null,
    tags_list: '',
    trashed: false,
    twitter_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) ? linkUrl : '',
    tiktok_text: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) ? linkUrl : '',
    updated_at: '',
    url: '',
    use_facebook: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? true : false,
    use_google: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) ? true : false,
    use_instagram: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) ? true : false,
    use_linkedin: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) ? true : false,
    use_twitter: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) ? true : false,
    use_tiktok: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) ? true : false,
    use_twitter_photo: false,
    video_url: null,
    instagram_reel: false,
    facebook_reel: false,
    google_call_to_action_type: null,
    google_call_to_action_url: null,
    facebook_call_to_action_type: null,
    facebook_call_to_action_link: null,
    personalized_facebook_call_to_action_link: '',
    video_cover_cloudinary_id: null,
    facebook_story: false,
    instagram_story: false
  };
};

/** Get RSS feed link for Creator page */
export const getRSSFeedLinkTextInCreator = (linkUrl: string, activePlatforms: string[]): apFranContainerTypes.ICreatorEditorStateTextObj => {
  return {
    facebook: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? convertStringToEditorState(linkUrl) : createEmptyEditorState(),
    twitter: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) ? convertStringToEditorState(linkUrl) : createEmptyEditorState(),
    linkedin: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) ? convertStringToEditorState(linkUrl) : createEmptyEditorState(),
    instagram: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) ? convertStringToEditorState(linkUrl) : createEmptyEditorState(),
    google: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) ? convertStringToEditorState(linkUrl) : createEmptyEditorState(),
    tiktok: activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) ? convertStringToEditorState(linkUrl) : createEmptyEditorState()
  };
};
