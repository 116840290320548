import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { aiStripeCustomerRequest, setContentSupplierSelectedPlan, setAISubscriptionDetailsModal, setSubscription, setSubscriptionMainPageType, aiUpdateSubscriptionPlanRequest } from 'actions';
import { IStore } from 'types';
import {
  AI_SUBSCRIPTION_CANCEL_SUCCESS,
  ALERT_BOX_TYPES,
  CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB,
  CS_LOCATIONS_SUBSCRIPTION_PLAN,
  CS_LOCATION_SUBSCRIPTION_TYPES,
  USER_OWNERSHIP
} from 'utils/constants';
import { AISubscriptionPayment } from '../AISubscriptionModal/AISubscriptionPayment';
import { useAccountSwitcherData } from 'utils/hooks';
import { getDiscountedDaysFromToday } from 'utils/helpers';
import { AICancelSusbcription } from '../AISubscriptionModal/AICancelSusbcription';
import { alertBoxCall } from 'components/Common/AlertBox';

export const CSLocationSubscriptionModal = () => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const aiSubscriptionMainPageType = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionMainPageType);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );
  const csLocationSubscriptionModalType = useSelector((state: IStore) => state.aiContentCreator.csLocationSubscriptionModalType);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const selectedSusbcripitonActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );

  const subscriptionObj: any = accSubscriptionDetails;

  const [isCancelSubscription, setCancelSusbcription] = useState<boolean>(false);

  const contentSupplierUpgradePlans = useMemo(() => {
    const currentPlanIndex = CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.findIndex((plan) => plan.value === accSubscriptionDetails?.subscription_plan);
    const upgradePlans = CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.filter((_, index) => index > currentPlanIndex);
    return upgradePlans?.length ? upgradePlans : [CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB[CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.length - 1]];
  }, [accSubscriptionDetails?.subscription_plan]);

  const discountDays = getDiscountedDaysFromToday(accSubscriptionDetails?.created_at || '');
  const isDiscountPriceAvailable = discountDays >= 1 && discountDays <= 7;

  useEffect(() => {
    dispatch(setContentSupplierSelectedPlan(contentSupplierUpgradePlans?.[0]?.value || CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB[CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.length - 1].value));
  }, [subscriptionObj?.subscription_plan, contentSupplierUpgradePlans, dispatch]);

  // const activePlanFeatures = useMemo(() => {
  //   const features: string[] = [];
  //   const activePlanIndex = CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.findIndex((subscription) => subscription.value === contentSupplierSelectedPlan);
  //   CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB.forEach((it, index) => {
  //     if (index <= activePlanIndex) {
  //       features.push(...it?.features!);
  //     }
  //   });
  //   return features;
  // }, [contentSupplierSelectedPlan]);

  const activePlanDetails = useMemo(() => {
    const planDetails = { ...CS_LOCATIONS_SUBSCRIPTION_PLAN };
    if (isDiscountPriceAvailable) {
      planDetails.price = 7.95;
    }

    return planDetails;
  }, [isDiscountPriceAvailable]);

  useEffect(() => {
    if (activeUserEmail && aiSubscriptionDetailsModal.type !== 'modifySubscription') {
      dispatch(aiStripeCustomerRequest(activeUserEmail || null));
    }
  }, [activeUserEmail, aiSubscriptionDetailsModal, dispatch]);

  useEffect(() => {
    // dispatch(setAISubscriptionType(activePlanDetails!));
    if (accSubscriptionDetails) {
      dispatch(
        setSubscription([
          { userId: accSubscriptionDetails?.user_id, accountId: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : null, franchisorId: userOwnership === USER_OWNERSHIP.FRANCHISOR ? id : null }
        ])
      );
    }
  }, [activePlanDetails, id, userOwnership, accSubscriptionDetails, dispatch]);

  const setView = (type: 'selection' | 'payment') => {
    dispatch(setSubscriptionMainPageType(type));
  };

  const csLocationSubscriptionTypeMessage = CS_LOCATION_SUBSCRIPTION_TYPES.find((subscription) => subscription.type === csLocationSubscriptionModalType)?.message;

  const handleCancelSusbcription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId && !it?.subscription_device_type;
    });
    if (validSusbcription?.length && activeUserId) {
      const data = validSusbcription.map((it) => ({ ...it, auto_renewal: false }));
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_CANCEL_SUCCESS);
          }
        })
      );
    }
  };

  return (
    <div
      className={`prime-modal lb-filter__modal ai-sub-modal tierModal ${
        aiSubscriptionDetailsModal.type === 'susbcriptionTable' ? 'ais-cu-wrap ai__table--wrp' : aiSubscriptionDetailsModal.type === 'AICoach' ? 'activated-rai-main' : ''
      }${aiSubscriptionMainPageType === 'payment' ? 'tierPay-on' : 'tierPay-off'}`}
      // size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      // centered
      // show
      // animation={false}
    >
      <Modal.Header>
        <div className="rai-sub-top">
          <h2>Get Premium Membership</h2>
          {(aiSubscriptionDetailsModal.type !== 'modifySubscription' || aiSubscriptionMainPageType === 'payment') && <p className="ai__sub--txt">{csLocationSubscriptionTypeMessage}</p>}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="rai-sub-base">
          <div className="raisb-item raisb-left ai-lt">
            <div className="raisb-cnt">
              <div className="fabs">
                <div className="raisbc-field-group">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <h5>Premium Features</h5>
                    </div>
                  </div>
                </div>

                <ol>
                  {CS_LOCATIONS_SUBSCRIPTION_PLAN.features.map((feature, index) => (
                    <li key={`activePlanFeature-${index}`}>{feature}</li>
                  ))}
                </ol>
              </div>
              {selectedSusbcripitonActive && aiSubscriptionMainPageType !== 'payment' && selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length > 0 && (
                <button onClick={() => setCancelSusbcription(true)} className="raisb_cnt--cancel">
                  Cancel Subscription
                </button>
              )}
            </div>
          </div>
          <div className={`raisb-item raisb-right ai-payment aips`}>
            <div className="raisb-cnt">
              <div className="pay-fields">
                <div className="raisbc-field-group rais-total rais__tot--val">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big rais__lbl-tot">Monthly</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value ravs-xbold">{`$ ${activePlanDetails?.price}`}</span>
                    </div>
                  </div>
                </div>
                <div className="raisbc-field-group rais-total rais__tot--val">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big rais__lbl-tot">Total</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value ravs-xbold">{`$ ${activePlanDetails?.price}`}</span>
                    </div>
                  </div>
                </div>
                {aiSubscriptionMainPageType === 'payment' && (
                  <div className="ai__payment--card">
                    <AISubscriptionPayment />
                  </div>
                )}
              </div>
              <div className="ai-stripe-btns r-flx r-flx-ac g-20 gpm-btns">
                {aiSubscription.length > 0 && (
                  <button
                    className="ac-btn ac-secondary-white ac-outline"
                    onClick={() => {
                      setView('selection');
                      const type = aiSubscriptionDetailsModal?.isTrialPreiod ? 'trialPeriod' : 'susbcriptionTable';
                      dispatch(setAISubscriptionDetailsModal({ type, isOpen: true }));
                    }}
                  >
                    <span>Back</span>
                  </button>
                )}
                {[7].includes(+subscriptionObj?.franchisor_subscription_type?.id) && (
                  <button
                    className={`ac-btn ac-primary`}
                    onClick={() => {
                      setView('payment');
                    }}
                  >
                    <span>Make Payment</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {isCancelSubscription && <AICancelSusbcription showDeleteModal={isCancelSubscription} handleModalClose={() => setCancelSusbcription(false)} handleCancel={handleCancelSusbcription} />}
    </div>
  );
};
