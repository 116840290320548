import { loginContainerType } from 'types';

export const LOGIN_USER_INFO_KEY = 'userInfo';

export const IMPERSONATE_RESPONSE_USER_KEY = 'responseUser';

export const LOGIN_VALUES = {
  LOGIN_INPUT_EMAIL: 'loginInputEmail',
  LOGIN_INPUT_PASSWORD: 'loginInputPassword'
};

export const RESET_PASSWORD_VALUES = {
  RP_INPUT_NEW_PASSWORD: 'resetPasswordInputNewPassword',
  RP_INPUT_CONFIRM_PASSWORD: 'resetPasswordInputConfirmPassword'
};

export const FORGOT_PASSWORD_VALUE = {
  FP_INPUT_EMAIL: 'forgotPasswordInputEmail'
};

export const PROFILE_INFO_DATA_FIELDS: loginContainerType.IProfileInfoModalField = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  FACEBOOK_PROFILE_URL: 'facebookProfileUrl',
  INSTAGRAM_PROFILE_URL: 'instagramProfileUrl',
  TWITTER_PROFILE_URL: 'twitterProfileUrl',
  LINKEDIN_PROFILE_URL: 'linkedinProfileUrl'
};

export const PROFILE_INFO_TOOLTIP = {
  EDIT_USER_DETAIL: 'Edit User Detail',
  EDIT_PHOTO: 'Edit Photo',
  REMOVE_PHOTO: 'Remove Photo'
};

export const USER_NOTIFICATION_SETTINGS_FIELD_TYPE: loginContainerType.IUserNotificationFieldType = {
  DIRECT_MESSAGE: 'direct_message_notification',
  EMAIL_NOTIFICATION: 'receive_notification_emails',
  REVIEWS_NOTIFICATION: 'review_alert_ratings',
  REPORT_NOTIFICATION: 'receive_monthly_enduser_report',
  SMS_NOTIFICATION: 'rallio_local_assets_notification_enrolled_at'
};

export const NOTIFICATION_DEFAULT_DATE = '0001-01-01T00:00:00.000Z';

export const VERIFY_EMAIL_PASSWORD_VALUES = {
  VE_PASSWORD: 'verifyEmailPassword',
  VE_CONFIRM_PASSWORD: 'verifyEmailConfirmPassword'
};
