import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'config';
import { AddOrEditLocation } from 'components';
import { useAccountSwitcherData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { CONNECTION_TABS, PRIMARY_APP_SOURCE } from 'utils/constants';
import { setAccountActivate } from 'actions';
import { IStore } from 'types';

export const ActivateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const isSSO = useSelector((state: IStore) => state.login.isSSO);

  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);

  useEffect(() => {
    if (id && userOwnership && !isCSLocation && !isContentSupplierFranchisor) {
      dispatch(setAccountActivate(true));
      navigate({ pathname: `/${userOwnership}/${id.toString()}/content/posts` }, { replace: true });
    } else if ((isSSO && isCSLocation) || isContentSupplierFranchisor) {
      navigate(
        {
          pathname: `/${userOwnership}/${id.toString()}/content/posts/account_wizard/${id.toString()}/${
            PRIMARY_APP_SOURCE === 'rallio' ? CONNECTION_TABS.VIDEO_INTRODUCTION : CONNECTION_TABS.RALLIO_PROFILE
          }`
        },
        { replace: true }
      );
    }
  }, [id, userOwnership, isCSLocation, isContentSupplierFranchisor, dispatch]); // eslint-disable-line

  return (
    <>
      {/* <Modal className="rvLoginMain activateAccount" centered show={true} animation={false}> */}
      {!isSSO && !isContentSupplierFranchisor ? (
        <Modal className="activate__acc--modal" centered show={optionalParams[0] !== 'account_wizard'} animation={false}>
          <div className="revv-login">
            <div className="img-holder">
              <img src={`${config.cdnImgUrl}pause-orange.svg`} alt="Hold your horses! - Get Started" />
            </div>
            <h2>Hold your horses!</h2>
            <p>
              <span>We need to confirm your account.</span>
              <span>Click “Get Started” below to update your information.</span>
            </p>
            <div className="rl-ftr">
              <div className="rlTop">
                <span className="errorText" />
                <button
                  className="ac-btn ac-primary"
                  type="submit"
                  onClick={() => {
                    if (id && userOwnership) {
                      navigate({
                        pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/account_wizard/${id.toString()}/${CONNECTION_TABS.RALLIO_PROFILE}`
                      });
                    }
                  }}
                >
                  <span>Get Started</span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {optionalParams.length && optionalParams[0] === 'account_wizard' ? <AddOrEditLocation /> : null}
    </>
  );
};
