import { useSpring, animated } from 'react-spring';

import { numberFormatter } from 'analytics/utils';
import { commonWidgetTypes } from 'types';

export const ReactSpringCountUp = ({ end, round = true }: commonWidgetTypes.IReactSpringCountUp) => {
  const spring = useSpring({
    from: { value: 0 },
    to: { value: end },
    config: { duration: 5000 }
  });

  return <animated.div>{spring.value.to((it: number) => `${numberFormatter(round ? Math.floor(it) : it)}`)}</animated.div>;
};
