export const NAV_TOGGLE = 'common/NAV_TOGGLE';
export const LAYOUT_MODE = 'common/LAYOUT_MODE';
export const RESET_PAGE_STATE = 'common/RESET_PAGE_STATE';
export const ERROR_PAGE = 'common/ERROR_PAGE';
export const RESET_PAGE_NUMBER = 'common/RESET_PAGE_NUMBER';
export const NON_PSP_FILTER_TOGGLE = 'common/NON_PSP_FILTER_TOGGLE';
export const SET_LAYOUT_RENDERED = 'common/SET_LAYOUT_RENDERED';

export const REVV_ACCOUNT_SWITCHER_REQUEST = 'common/REVV_ACCOUNT_SWITCHER_REQUEST';
export const REVV_ACCOUNT_SWITCHER_RESPONSE = 'common/REVV_ACCOUNT_SWITCHER_RESPONSE';
export const REVV_ACCOUNT_SWITCHER_FAILURE = 'common/REVV_ACCOUNT_SWITCHER_FAILURE';
