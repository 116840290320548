import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  addPostSavePostActionResponse,
  addPostSavePostActionFailure,
  addPostSaveAsScheduledPostResponse,
  addPostSaveAsScheduledPostFailure,
  addPostCouponListResponse,
  addPostCouponListFailure,
  addPostGetFBTagsListResponse,
  addPostGetFBTagsListFailure,
  addPostUpdateSavedPostResponse,
  addPostUpdateSavedPostFailure,
  addPostGetSocialMediaPhotoPreviewResponse,
  addPostGetSocialMediaPhotoPreviewFailure,
  addPostAddBoostToScheduledPostResponse,
  addPostAddBoostToScheduledPostFailure,
  setAIPost
} from 'actions';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import {
  ADD_POST_EXCEPTION_ERROR,
  ADD_POST_SAVE_POST_NOW_FAILED,
  ADD_POST_SAVE_AS_SCHEDULED_FAILED,
  ADD_POST_COUPON_LIST_FAILED,
  ADD_POST_FB_TAG_LIST_FAILED,
  ADD_POST_UPDATE_SAVED_POST_FAILED,
  ADD_POST_GET_INSTA_PHOTO_PREVIEW_FAILURE,
  ADD_POST_ADD_BOOST_FOR_SCH_POST_FAILURE,
  ADD_POST_ADD_BOOST_FOR_SCH_POST_SUCCESS_MSG,
  COMMON_SOCIAL_PROVIDER_TYPE
} from 'utils/constants';
import { ISagaPayload } from 'types/common/api';

function* sendAddPostSavePostActionRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = { saved_post: payload.reqBody };
    const response = yield apiCall({ headers, data, ...API.saveDraftPost });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostSavePostActionResponse(response.data.saved_post_summaries));
      yield put(setAIPost(false));
    } else {
      yield put(addPostSavePostActionFailure(ADD_POST_SAVE_POST_NOW_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostSaveAsScheduledRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.addScheduledPost.apiPath.replace(':id', payload.savedPostId);
    const data = {
      scheduled_post: payload.scheduledPost
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.addScheduledPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostSaveAsScheduledPostResponse(response.data.scheduled_post.id));
    } else {
      yield put(addPostSaveAsScheduledPostFailure(ADD_POST_SAVE_AS_SCHEDULED_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostCouponListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { franchisor_id: payload };
    const response = yield apiCall({ headers, params, ...API.getCouponList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostCouponListResponse(response.data.rallio_coupons));
    } else {
      yield put(addPostCouponListFailure(ADD_POST_COUPON_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostgetFacebookPageTagListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { search: payload };
    const response = yield apiCall({ headers, params, ...API.getFacebookPageTagList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostGetFBTagsListResponse(response.data.facebook_business_pages));
    } else {
      yield put(addPostGetFBTagsListFailure(ADD_POST_FB_TAG_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddPostUpdateSavedPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.editDraftPost.apiPath.replace(':id', payload.savedPostId);
    const data = {
      saved_post: payload.reqBody
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.editDraftPost.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostUpdateSavedPostResponse(response.data.saved_post.id));
      yield put(setAIPost(false));
    } else {
      yield put(addPostUpdateSavedPostFailure(ADD_POST_UPDATE_SAVED_POST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendGetSocialMediaPhotoPreviewRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? API.getFacebookPhotoPreview : API.getInstaPhotoPreview;
    const data = {
      [`${payload.socialMedia}_photo_preview`]: payload.payload
    };
    const response = yield apiCall({ headers, data, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        addPostGetSocialMediaPhotoPreviewResponse({
          socialMedia: payload.socialMedia,
          response: {
            originalUrls: payload.payload.urls,
            previewUrls: response.data?.[`${payload.socialMedia}_photo_preview`]?.transformed_urls,
            storyUrls: response.data?.[`${payload.socialMedia}_photo_preview`]?.story_urls
          }
        })
      );
    } else {
      yield put(addPostGetSocialMediaPhotoPreviewFailure({ socialMedia: payload.socialMedia, msg: ADD_POST_GET_INSTA_PHOTO_PREVIEW_FAILURE }));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

function* sendAddBoostForScheduledPostRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      facebook_boost_enduser_campaign: payload
    };
    const response = yield apiCall({ headers, data, ...API.addBoostForScheduledPost });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addPostAddBoostToScheduledPostResponse(ADD_POST_ADD_BOOST_FOR_SCH_POST_SUCCESS_MSG));
    } else {
      yield put(addPostAddBoostToScheduledPostFailure(response.data.message || ADD_POST_ADD_BOOST_FOR_SCH_POST_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADD_POST_EXCEPTION_ERROR));
  }
}

export function* takeAddPostAccountRequest() {
  yield takeLatest(types.AP_SAVE_POST_ACTION_REQUEST, sendAddPostSavePostActionRequest);
  yield takeLatest(types.AP_SAVE_POST_AS_SCHEDULED_REQUEST, sendAddPostSaveAsScheduledRequest);
  yield takeEvery(types.AP_COUPON_LIST_REQUEST, sendAddPostCouponListRequest);
  yield takeLatest(types.AP_GET_FB_TAG_LIST_REQUEST, sendAddPostgetFacebookPageTagListRequest);
  yield takeEvery(types.AP_UPDATE_SAVED_POST_REQUEST, sendAddPostUpdateSavedPostRequest);
  yield takeEvery(types.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_REQUEST, sendGetSocialMediaPhotoPreviewRequest);
  yield takeLatest(types.AP_ADD_BOOST_FOR_SCH_POST_REQUEST, sendAddBoostForScheduledPostRequest);
}
