// GET REWARD PROGRAMS STATS REQUEST
export const RP_GET_STATS_REQUEST = 'reward_programs/RP_GET_STATS_REQUEST';
export const RP_GET_STATS_RESPONSE = 'reward_programs/RP_GET_STATS_RESPONSE';
export const RP_GET_STATS_FAILURE = 'reward_programs/RP_GET_STATS_FAILURE';

// GET REWARD PROGRAM LIST REQUEST
export const RP_GET_LIST_REQUEST = 'reward_programs/RP_GET_LIST_REQUEST';
export const RP_GET_LIST_RESPONSE = 'reward_programs/RP_GET_LIST_RESPONSE';
export const RP_GET_LIST_FAILURE = 'reward_programs/RP_GET_LIST_FAILURE';

// REWARDS PROGRAM - GET PROGRAM DATA REQUEST
export const RP_GET_PROGRAM_DETAIL_REQUEST = 'reward_programs/RP_GET_PROGRAM_DETAIL_REQUEST';
export const RP_GET_PROGRAM_DETAIL_RESPONSE = 'reward_programs/RP_GET_PROGRAM_DETAIL_RESPONSE';
export const RP_GET_PROGRAM_DETAIL_FAILURE = 'reward_programs/RP_GET_PROGRAM_DETAIL_FAILURE';

// REWARDS PROGRAM - DELETE PROGRAM DATA REQUEST
export const RP_DELETE_PROGRAM_DATA_REQUEST = 'reward_programs/RP_DELETE_PROGRAM_DATA_REQUEST';
export const RP_DELETE_PROGRAM_DATA_RESPONSE = 'reward_programs/RP_DELETE_PROGRAM_DATA_RESPONSE';
export const RP_DELETE_PROGRAM_DATA_FAILURE = 'reward_programs/RP_DELETE_PROGRAM_DATA_FAILURE';

// REWARDS PROGRAM - ACHIEVERS LIST REQUEST
export const RP_ACHIEVERS_LIST_REQUEST = 'reward_programs/RP_ACHIEVERS_LIST_REQUEST';
export const RP_ACHIEVERS_LIST_RESPONSE = 'reward_programs/RP_ACHIEVERS_LIST_RESPONSE';
export const RP_ACHIEVERS_LIST_FAILURE = 'reward_programs/RP_ACHIEVERS_LIST_FAILURE';

// REWARDS PROGRAM - EDIT PROGRAM RESEND EMAIL
export const RP_EDIT_PROGRAM_RESEND_EMAIL_REQUEST = 'reward_programs/RP_EDIT_PROGRAM_RESEND_EMAIL_REQUEST';
export const RP_EDIT_PROGRAM_RESEND_EMAIL_RESPONSE = 'reward_programs/RP_EDIT_PROGRAM_RESEND_EMAIL_RESPONSE';
export const RP_EDIT_PROGRAM_RESEND_EMAIL_FAILURE = 'reward_programs/RP_EDIT_PROGRAM_RESEND_EMAIL_FAILURE';

// REWARDS PROGRAM - CREATE OR UPDATE PROGRAM DATA
export const RP_CREATE_OR_UPDATE_PROGRAM_DATA_REQUEST = 'rewardsProgram/RP_CREATE_OR_UPDATE_PROGRAM_DATA_REQUEST';
export const RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESPONSE = 'rewardsProgram/RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESPONSE';
export const RP_CREATE_OR_UPDATE_PROGRAM_DATA_FAILURE = 'rewardsProgram/RP_CREATE_OR_UPDATE_PROGRAM_DATA_FAILURE';
export const RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESET = 'rewardsProgram/RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESET';

// REWARDS PROGRAM - LOCATION TREE DATA
export const RP_LOCATION_TREE_DATA_REQUEST = 'reward_programs/RP_LOCATION_TREE_DATA_REQUEST';
export const RP_LOCATION_TREE_DATA_RESPONSE = 'reward_programs/RP_LOCATION_TREE_DATA_RESPONSE';
export const RP_LOCATION_TREE_DATA_FAILURE = 'reward_programs/RP_LOCATION_TREE_DATA_FAILURE';

// REWARDS PROGRAM - ACTIONS PROMOTION VAULT LIST
export const RP_PROMOTION_VAULT_LIST_REQUEST = 'reward_programs/RP_PROMOTION_VAULT_LIST_REQUEST';
export const RP_PROMOTION_VAULT_LIST_RESPONSE = 'reward_programs/RP_PROMOTION_VAULT_LIST_RESPONSE';
export const RP_PROMOTION_VAULT_LIST_FAILURE = 'reward_programs/RP_PROMOTION_VAULT_LIST_FAILURE';

// RP SET CREATE PROGRAM DATA OBJ
export const RP_SET_CP_DATA_OBJ = 'reward_programs/RP_SET_CP_DATA_OBJ';

export const RP_SET_DETAIL_FETCHING = 'reward_programs/RP_SET_DETAIL_FETCHING';

// REWARDS PROGRAM RESET ALL
export const RP_RESET_ALL = 'reward_programs/RP_RESET_ALL';

// REWARDS PROGRAM - STATE UPDATES
export const RP_CURRENT_ACTION_STATUS_UPDATES = 'reward_programs/RP_CURRENT_ACTION_STATUS_UPDATES';
export const RP_SET_REWARDS_MODIFIED = 'reward_programs/RP_SET_REWARDS_MODIFIED';
export const RP_SET_RESEND_EMAIL_MODIFIED = 'reward_programs/RP_SET_RESEND_EMAIL_MODIFIED';

// RP DELETE REWARD PROGRAM DATA RESET
export const RP_DELETE_PROGRAM_DATA_RESET = 'reward_programs/RP_DELETE_PROGRAM_DATA_RESET';

export const RP_DETAIL_VIEW_ERROR = 'reward_programs/RP_DETAIL_VIEW_ERROR';
