import { useMemo, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';

import { settingsSocialProfilContainerTypes, settingsSocialProfileApiTypes } from 'types';
import { ImageValidation } from 'widgets/Media';

export const LinkedInViewPagesModalBody = (props: settingsSocialProfilContainerTypes.ILinkedInPageDetailPopupProps) => {
  const { pagesDetailData, handlePageSelect } = props;

  const profile = pagesDetailData.find((it: settingsSocialProfileApiTypes.ILinkedInViewPageDataItem) => it.type === 'User');
  const pages = pagesDetailData.filter((it: settingsSocialProfileApiTypes.ILinkedInViewPageDataItem) => it.type !== 'User');

  const [search, setSearch] = useState('');

  const filteredPages = useMemo(() => {
    if (search) {
      return pages.filter((it: any) => it.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }
    return pages;
  }, [pages, search]);

  const renderViewPageItem = (dataItem?: settingsSocialProfileApiTypes.ILinkedInViewPageDataItem) => (
    <div key={dataItem?.id} className={`aps-item`}>
      <div className="aps-content content-wrp">
        <div className="r-flx r-flx-ac">
          <div className="aps-left">
            <ImageValidation isImgValid defaultImg="linkedin-lv" customClassname="page-thumbnail" />
          </div>
          <div className="aps-right r-ml2">
            <div className="apsr-sn-details">
              <label>
                <input type="radio" className="radio-checkbox" value={String(dataItem?.id)} id={String(dataItem?.id)} name="social_medium[linkedin_page_credential_id]" />
                <label htmlFor={String(dataItem?.id)} />
              </label>
              <div className="nac-cnt">
                <div className="nac-txt">{dataItem?.name}</div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="primeblueaction"
          onClick={() => {
            if (dataItem) {
              handlePageSelect({ obj: dataItem, type: dataItem?.type || '' });
            }
          }}
        >
          Connect Now
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className="profile-section">
        <span className="linkedin-section-title">Profile</span>
        {renderViewPageItem(profile)}
      </div>
      <div className="page-section">
        <span className="linkedin-section-title">Pages</span>
        <FormControl placeholder="Search" className="s-fb-search" value={search} onChange={(e) => setSearch(e.target.value)} />
        {filteredPages.map((it: settingsSocialProfileApiTypes.ILinkedInViewPageDataItem) => renderViewPageItem(it))}
      </div>
    </>
  );
};
