import { takeEvery, put, all } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  accountSwitcherResponse,
  accountSwitcherRequestFail,
  menuAccessibleResponse,
  accountGetProfileResponse,
  getFranchisorDetailsResponse,
  exceptionHandlerRequest,
  locationSelectorRequest,
  locationSelectorResponse,
  locationSelectorFail,
  setDLStoreDetails,
  settingsUpdateRallioProfile,
  getFranchiseePermissionRequest,
  getFBAdsTargetingChoicesRequest,
  aiGetSubscriptionPlanRequest,
  franchisorGetOrUpdateProfileResponse,
  getLocationsHubListRequest
} from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { LOCATION_SELECTOR_ERROR, MSG_ACC_SWITCHER_ERROR, RALLIO_PROFILE_INITIAL_VALUE, STORE_DETAILS_INITIAL_VALUE, USER_OWNERSHIP } from 'utils/constants';
import { accountSwitcherApiTypes, commonApiTypes } from 'types';
import { aiPlaybookGetDocumentsListRequest, settingsAIPlayBookGetUpdateResponse } from 'actions/modules/settings-ai-playbook';
import { revvAccountSwitcherRequest } from 'revv/actions';
import { store } from 'store';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetAccountSwitcherRequest(requestDetails: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: accountSwitcherApiTypes.IAccountSwitcherReqParams = {};
    if (requestDetails.payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      params.franchisor_id = requestDetails.payload.id;
    } else if (requestDetails.payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
      params.account_id = requestDetails.payload.id;
    }
    const response = yield apiCall({ headers, params, ...API.accountSwitcher });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      let isRevvAuthenticated = store.getState().revv.login.isAuthenticated;
      let topLevelFranchisorId = store.getState().accountSwitcher.topLevelFranchisorId;
      store.subscribe(() => {
        topLevelFranchisorId = store.getState().accountSwitcher.topLevelFranchisorId;
        isRevvAuthenticated = store.getState().revv.login.isAuthenticated;
      });
      if (response?.data?.top_level_franchisor?.id && response?.data?.top_level_franchisor?.id !== topLevelFranchisorId && isRevvAuthenticated) {
        yield put(revvAccountSwitcherRequest({ franchisorId: response?.data?.top_level_franchisor?.id }));
      }

      yield put(accountSwitcherResponse({ ...response.data, userOwnership: requestDetails.payload.userOwnership, id: requestDetails.payload.id }));

      yield put(aiPlaybookGetDocumentsListRequest({ page: 1, ai_playbook: true, [`${requestDetails.payload.userOwnership}_id`]: requestDetails.payload.id }));
      yield put(aiPlaybookGetDocumentsListRequest({ page: 1, ai_compliance: true, franchisor_id: topLevelFranchisorId }));
      yield put(
        settingsAIPlayBookGetUpdateResponse({
          name: response.data?.top_level_franchisor?.ai_playbook_use_profile_name ? response.data?.current_account_or_franchisor?.name : response.data?.top_level_franchisor?.name,
          business_url: response.data?.current_account_or_franchisor?.business_url || '',
          vertical_id: response.data?.top_level_franchisor?.vertical_id,
          questions: response.data?.current_account_or_franchisor?.questions,
          business_description: response.data?.current_account_or_franchisor?.business_description,
          customer_persona_text: response.data?.current_account_or_franchisor?.customer_persona_text,
          customer_persona_usage_level: response.data?.current_account_or_franchisor?.customer_persona_usage_level,
          auto_generate_post: response.data?.current_account_or_franchisor?.auto_generate_post
        })
      );
      /** get ai susbcription status */
      const payload = {
        id: requestDetails.payload.id,
        userOwnership: requestDetails.payload.userOwnership,
        userId: requestDetails.payload.userId
      };
      yield put(aiGetSubscriptionPlanRequest(payload));

      /** menu accessible saga added here */
      const urlPathObj: { apiPath: string; action: any } = { ...API.menuAccessiblityGet };
      const replacedPath: string = urlPathObj.apiPath.replace(':userOwnerShip/:id', `${requestDetails.payload.userOwnership}s/${requestDetails.payload.id}`);
      urlPathObj.apiPath = replacedPath;
      const menuAccessibleRes = yield apiCall({ headers, ...urlPathObj });
      if (menuAccessibleRes.status === 200) {
        yield put(
          menuAccessibleResponse({
            userOwnership: requestDetails.payload.userOwnership,
            ...menuAccessibleRes.data[requestDetails.payload.userOwnership]
          })
        );

        /** account get profile data */
        if (requestDetails.payload.userOwnership === USER_OWNERSHIP.ACCOUNT) {
          yield put(accountGetProfileResponse(menuAccessibleRes.data));
          yield put(
            settingsAIPlayBookGetUpdateResponse({
              ai_compliance_description: menuAccessibleRes.data?.account?.ai_compliance_description
            })
          );
          // /** UPDATE RALLIO PROFILE INITIAL VALUE ONCE ACCOUNTS API CALL DONE */
          // const initialRallioProfileDetails = getInitilalRallioProfileDetails(menuAccessibleRes.data.account);
          // yield put(settingsUpdateRallioProfile(initialRallioProfileDetails));
          // const initialStoreDetails = getInitialStoreDetails(menuAccessibleRes.data.account);
          // yield put(setDLStoreDetails(initialStoreDetails));

          yield put(getFranchiseePermissionRequest(requestDetails.payload.id));
          yield put(getLocationsHubListRequest({ [`${requestDetails.payload.userOwnership}_id`]: requestDetails.payload.id }));
        } else if (requestDetails.payload.userOwnership === USER_OWNERSHIP.FRANCHISOR) {
          /** franchisor get profile data */
          yield put(franchisorGetOrUpdateProfileResponse(menuAccessibleRes.data));
          yield put(
            settingsAIPlayBookGetUpdateResponse({
              ai_compliance_description: menuAccessibleRes.data?.franchisor?.ai_compliance_description
            })
          );
          const franchisorDetailsurlPathObj: { apiPath: string; action: any } = { ...API.getFranchisorDetails };
          const replacedPath: string = franchisorDetailsurlPathObj.apiPath.replace(':id', `${requestDetails.payload.id}`);
          franchisorDetailsurlPathObj.apiPath = replacedPath;
          const franchisorDetailRes = yield apiCall({ headers, ...franchisorDetailsurlPathObj });
          if (franchisorDetailRes.status === 200) {
            yield put(getFranchisorDetailsResponse(franchisorDetailRes.data.franchisor_detail));
          }
          yield put(locationSelectorRequest(requestDetails.payload.id));
          /** UPDATE RALLIO PROFILE INITIAL VALUE ONCE ACCOUNTS API CALL DONE */
          yield put(settingsUpdateRallioProfile(RALLIO_PROFILE_INITIAL_VALUE));
          yield put(setDLStoreDetails(STORE_DETAILS_INITIAL_VALUE));
        }
        yield put(getFBAdsTargetingChoicesRequest({ id: requestDetails.payload.id, userOwnership: requestDetails.payload.userOwnership, initialLoad: true }));
      }
    } else {
      yield put(accountSwitcherRequestFail(response.data.error || response.data.message || MSG_ACC_SWITCHER_ERROR));
    }
  } catch (error) {
    yield put(accountSwitcherRequestFail(MSG_ACC_SWITCHER_ERROR));
    yield put(exceptionHandlerRequest(MSG_ACC_SWITCHER_ERROR));
  }
}

function* sendLocationFilterRequest(requestDetails: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      parent_franchisor_id: requestDetails.payload
    };
    const accountListParams = {
      ancestor_franchisor_id: requestDetails.payload
    };
    const locationListParams = {
      franchisor_id: requestDetails.payload,
      named_only: 1
    };
    const { response, accountListResponse, locationListResponse } = yield all({
      response: apiCall({ headers, params, ...API.getSocialContentFranchisors }),
      accountListResponse: apiCall({ headers, params: accountListParams, ...API.getAccSelectionList }),
      locationListResponse: apiCall({ headers, params: locationListParams, ...API.getAccountList })
    });

    if (response.status === 401 || accountListResponse.status === 401 || locationListResponse.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200 || accountListResponse.status === 200 || locationListResponse.status === 200) {
      const responseData = {
        franchisor_selections: response.data.franchisor_selections,
        account_selections: accountListResponse.data.account_selections,
        account_lists: locationListResponse.data.account_lists
      };
      yield put(locationSelectorResponse(responseData));
    } else {
      yield put(locationSelectorFail(LOCATION_SELECTOR_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(LOCATION_SELECTOR_ERROR));
  }
}

export function* takeAccountSwitcherRequest() {
  yield takeEvery(types.ACCOUNT_SWITCHER_REQUEST, sendGetAccountSwitcherRequest);
  yield takeEvery(types.LOCATION_SELECTOR_LIST_REQUEST, sendLocationFilterRequest);
}
