import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { AP_CONTENT_ACTION_TYPE, POST_NOT_AVAILABLE_MSG, RIPPLE_COLOR, TOOLTIP_PLACEMENT } from 'utils/constants';
import { commonModalPopup } from 'types';
import { useNavBarData } from 'utils/hooks';
import { CustomTooltip } from 'widgets/Tooltip';

export const CorporatePostFooter = ({
  isFetching,
  isEditEnabled,
  disableButton,
  isNotShowPost,
  isPostNowEnabled,
  setOpenPostNowModal,
  handleAddScheduleOrPostNowAction,
  handleUpdateScheduleAction,
  handleContentMassUpdate
}: commonModalPopup.ICorporatePostFooterProp) => {
  const { subNavPageName } = useNavBarData();

  return (
    <div className="footer-section">
      {subNavPageName === 'calendar' ? (
        <div className={`footer-section cal-schedule-btn ${disableButton || isFetching ? 'pointer-events-none' : isEditEnabled ? '' : 'pointer-events-none'}`}>
          <div
            className="btn schedule-btn"
            onClick={() => {
              handleUpdateScheduleAction(AP_CONTENT_ACTION_TYPE.SCHEDULE);
              handleContentMassUpdate();
            }}
          >
            Schedule
          </div>
        </div>
      ) : (
        <div className="grid-btn-section">
          <CustomRippleButton rippleClass={`ac-primary-box ${disableButton || isFetching ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.secondary}>
            <button className={`ac-btn ac-secondary ac-block ${disableButton || isFetching ? 'disabled' : ''}`} onClick={() => handleAddScheduleOrPostNowAction(AP_CONTENT_ACTION_TYPE.SCHEDULE)}>
              Schedule
            </button>
          </CustomRippleButton>
          {!isPostNowEnabled ? (
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customTooltipText={POST_NOT_AVAILABLE_MSG}
              customClassname={'custom-tooltip-long-text'}
              customInput={() => (
                // <CustomRippleButton rippleClass={`ac-primary-box button-opacity`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-primary-box ac-btn ac-primary ac-block button-opacity`}>Post Now</button>
                // </CustomRippleButton>
              )}
            />
          ) : (
            <CustomRippleButton rippleClass={`ac-primary-box ${(isNotShowPost && disableButton) || isFetching ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block ${(isNotShowPost && disableButton) || isFetching || !isPostNowEnabled ? 'disabled' : ''}`}
                onClick={() =>
                  !isNotShowPost && (localStorage?.getItem('doNotShowPostNowConfirm') === 'false' || localStorage?.getItem('doNotShowPostNowConfirm') === null)
                    ? setOpenPostNowModal(true)
                    : handleAddScheduleOrPostNowAction(AP_CONTENT_ACTION_TYPE.POST_NOW)
                }
              >
                Post Now
              </button>
            </CustomRippleButton>
          )}
        </div>
      )}
    </div>
  );
};
