import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  getInboxListResponse,
  getInboxListRequestFailure,
  inboxDismissResponse,
  inboxDismissRequestFailure,
  accountGetProfileResponse,
  inboxPieReloadRequestFailure,
  inboxReplyResponse,
  inboxReplyRequestFailure,
  inboxCommentsEngagementResponse,
  inboxCommentsEngagementRequestFailure,
  inboxPieReloadResponse,
  inboxRetweetResponse,
  inboxRetweetRequestFailure,
  getInboxStatsResponse,
  getInboxStatsReqFailure,
  inboxCommentsResponse,
  inboxCommentsRequestFailure,
  updateCommentsCountOnPost,
  updateInboxStats,
  getInboxDirectMessageThreadResponse,
  getInboxDirectMessageThreadFailure,
  getInboxDirectMessageThreadRequest
} from 'actions';
import {
  INBOX_FETCHING_LIST_FAILED,
  INBOX_EXCEPTION_ERROR,
  USER_OWNERSHIP,
  INBOX_DISMISS_FAILED,
  INBOX_PIE_RELOAD_FAILED,
  PAGINATION_PER_PAGE,
  INBOX_REPLY_FAILED,
  INBOX_COMMENTS_LIKE_FAILED,
  INBOX_RETWEET_FAILED,
  InboxReplyActionType,
  INBOX_FETCHING_STATS_FAILED,
  REVIEWS_ADD_REPLY_SUCCESS_MSG,
  SC_COMMENTS_FAILED,
  ALERT_BOX_TYPES,
  INBOX_NO_POSTS_INFO_MSG,
  DIRECT_MESSAGE_FETCHING_FAILED,
  INBOX_COMMENTS_FAVOURITE_FAILED,
  IPostEngagementType,
  INBOX_NO_SAVED_POST_COMMENTS_MSG
} from 'utils/constants';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes, inboxApiTypes } from 'types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendInboxListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: inboxApiTypes.IInboxListSagaReqParams = {
      ...(payload.feed && { feed: payload.feed }),
      page: payload.page,
      ...(payload.page && { per: PAGINATION_PER_PAGE }),
      ...(payload.search_text && { search_text: payload.search_text }),
      ...(payload.onlyLocationId
        ? { account_id: payload.onlyLocationId }
        : payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && !payload.ancestorFranchisorId
        ? { ancestor_franchisor_id: payload.id }
        : { ancestor_franchisor_id: payload.ancestorFranchisorId }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && !payload.accountId ? { account_id: payload.id } : { account_list_id: payload.accountId }),
      ...(payload.favorite ? { favorite: payload.favorite } : payload.unread ? { unread: payload.unread } : { date_range: payload.date_range }),
      ...(payload.inbox_sociable_type ? { inbox_sociable_type: payload.inbox_sociable_type } : { sociable_type: payload.sociableType }),
      platform: payload.platform,
      ads_only: payload.adsOnly
    };

    const response = yield apiCall({ headers, params, ...API.getSocialContentsList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      response.data.page = params.page;
      if (payload.countUpdate) {
        yield put(updateInboxStats(response.data));
      } else {
        yield put(getInboxListResponse(response.data));
        if (payload.sociableType !== 'Message' && payload.page === 1 && !response.data?.social_contents?.length && !response.data?.posts_with_unread_comments_count) {
          alertBoxCall(ALERT_BOX_TYPES.INFO, payload.favorite ? INBOX_NO_SAVED_POST_COMMENTS_MSG : INBOX_NO_POSTS_INFO_MSG);
        }
      }
    } else {
      yield put(getInboxListRequestFailure(INBOX_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* loadCommentStatsForPost({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const url = API.getComments.apiPath.replace(':id', payload.socialContentId);
    const response = yield apiCall({ headers, params: { page: 0 }, apiPath: url, action: API.getComments.action });
    yield put(updateCommentsCountOnPost({ contentId: payload.socialContentId, data: response.data }));
  } catch (error: any) {
    yield put(exceptionHandlerRequest(error));
  }
}

function* sendInboxStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: inboxApiTypes.IInboxListSagaReqParams = {
      feed: payload.feed,
      ...(payload.search_text && { search_text: payload.search_text }),
      ...(payload.onlyLocationId
        ? { account_id: payload.onlyLocationId }
        : payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && !payload.ancestorFranchisorId
        ? { ancestor_franchisor_id: payload.id }
        : { ancestor_franchisor_id: payload.ancestorFranchisorId }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && !payload.accountId ? { account_id: payload.id } : { account_list_id: payload.accountId }),
      ...(payload.favorite ? { favorite: payload.favorite } : payload.unread ? { unread: payload.unread } : { date_range: payload.date_range }),
      ...(payload.inbox_sociable_type ? { inbox_sociable_type: payload.inbox_sociable_type } : { sociable_type: payload.sociableType }),
      platform: payload.platform,
      ads_only: payload.adsOnly
    };

    const response = yield apiCall({ headers, params, ...API.getSocialContentsStats });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      response.data.page = params.page;
      yield put(getInboxStatsResponse(response.data));
    } else {
      yield put(getInboxStatsReqFailure(INBOX_FETCHING_STATS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxDismissRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.inboxDismiss.apiPath.replace(':id', payload.socialContentId);
    const data = {
      comment_ids: payload.commentIds
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.inboxDismiss.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(inboxDismissResponse({ socialContentId: payload.socialContentId, commentIds: payload.commentIds }));
      yield payload.callback();
    } else {
      yield put(inboxDismissRequestFailure(INBOX_DISMISS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxPieReloadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.inboxPieReload.apiPath.replace(':id', payload.id);
    const data = {
      force: payload.force
    };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.inboxPieReload.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(accountGetProfileResponse(response.data));
      yield put(inboxPieReloadResponse(false));
    } else {
      yield put(inboxPieReloadRequestFailure(INBOX_PIE_RELOAD_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxReplyRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.socialContentsReply.apiPath.replace(':id', payload.socialContentId);
    let bodyFormData;
    if (payload.actionType === InboxReplyActionType.LIST_REPLY) {
      bodyFormData = new FormData();
      bodyFormData.append('message', payload.message);
      bodyFormData.append('dismissed', payload.dismissed);
    } else {
      bodyFormData = {
        message: payload.message
      };
    }
    const response = yield apiCall({ headers, data: bodyFormData, apiPath: path, action: API.socialContentsReply.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        inboxReplyResponse({
          data: REVIEWS_ADD_REPLY_SUCCESS_MSG,
          socialContentId: payload.socialContentId,
          actionType: payload.actionType,
          message: payload.message,
          inputText: payload.inputText,
          ...(payload.contentId && { contentId: payload.contentId }),
          ...(payload.parentCommentObj && { parentCommentObj: payload.parentCommentObj })
        })
      );
      if (payload.socialMediaThreadId) {
        yield delay(1000);
        yield put(
          getInboxDirectMessageThreadRequest({
            account_id: payload.accountId,
            social_media_thread_id: payload.socialMediaThreadId,
            page: 1,
            per: 12
          })
        );
      }
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, INBOX_REPLY_FAILED);
      yield put(inboxReplyRequestFailure(INBOX_REPLY_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendinboxCommentsEngagementRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    let response;
    if (payload.type === IPostEngagementType.LIKED) {
      if (payload.liked) {
        path = API.socialContentsLike.apiPath.replace(':id', payload.socialContentId);
        response = yield apiCall({ headers, apiPath: path, action: API.socialContentsLike.action });
      } else {
        path = API.socialContentsDisLike.apiPath.replace(':id', payload.socialContentId);
        response = yield apiCall({ headers, apiPath: path, action: API.socialContentsDisLike.action });
      }
    } else {
      if (payload.liked) {
        path = API.socialContentsFavorite.apiPath.replace(':id', payload.socialContentId);
        response = yield apiCall({ headers, apiPath: path, action: API.socialContentsFavorite.action });
      } else {
        path = API.socialContentsUnFavorite.apiPath.replace(':id', payload.socialContentId);
        response = yield apiCall({ headers, apiPath: path, action: API.socialContentsUnFavorite.action });
      }
    }

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(
        inboxCommentsEngagementResponse({
          id: payload.id,
          socialContentId: payload.socialContentId,
          contentId: payload.contentId,
          liked: payload.liked,
          type: payload.type
        })
      );
    } else {
      if (payload.type === IPostEngagementType.LIKED) {
        yield put(inboxCommentsEngagementRequestFailure(INBOX_COMMENTS_LIKE_FAILED));
      } else {
        yield put(inboxCommentsEngagementRequestFailure(INBOX_COMMENTS_FAVOURITE_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxRetweetRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.inboxRetweet.apiPath.replace(':id', payload.socialContentId);
    const response = yield apiCall({ headers, apiPath: path, action: API.inboxRetweet.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(inboxRetweetResponse({ id: payload.socialContentId, retweeted: true }));
    } else {
      yield put(inboxRetweetRequestFailure(INBOX_RETWEET_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxCommentsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    const params = {
      ...(payload.page && { page: payload.page })
    };
    path = API.getComments.apiPath.replace(':id', payload.socialContentId);
    const response = yield apiCall({ headers, params, apiPath: path, action: API.getComments.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      response.data.socialContentId = payload.socialContentId;
      response.data.meta.current_page = payload.page;
      yield put(inboxCommentsResponse(response.data));
    } else {
      yield put(inboxCommentsRequestFailure(SC_COMMENTS_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

function* sendInboxDirectMessageThreadRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let path;
    path = API.getSocialContentsList.apiPath;
    const params = { ...payload };
    if (params?.isPolling) {
      delete params?.isPolling;
    }
    const response = yield apiCall({ headers, params, apiPath: path, action: API.getSocialContentsList.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getInboxDirectMessageThreadResponse({ messageData: response.data.social_contents, page: payload.page }));
    } else {
      yield put(getInboxDirectMessageThreadFailure(DIRECT_MESSAGE_FETCHING_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(INBOX_EXCEPTION_ERROR));
  }
}

export function* takeInboxRequest() {
  yield takeEvery(types.INBOX_LIST_REQUEST, sendInboxListRequest);
  yield takeLatest(types.INBOX_GET_STATS_REQUEST, sendInboxStatsRequest);
  yield takeLatest(types.INBOX_DISMISS_REQUEST, sendInboxDismissRequest);
  yield takeLatest(types.INBOX_PIE_RELOAD_REQUEST, sendInboxPieReloadRequest);
  yield takeLatest(types.INBOX_REPLY_REQUEST, sendInboxReplyRequest);
  yield takeLatest(types.INBOX_COMMENTS_ENGAGEMENT_REQUEST, sendinboxCommentsEngagementRequest);
  yield takeLatest(types.INBOX_RETWEET_REQUEST, sendInboxRetweetRequest);
  yield takeLatest(types.INBOX_COMMENTS_REQUEST, sendInboxCommentsRequest);
  yield takeLatest(types.UPDATE_POST_COMMENT_COUNT_REQUEST, loadCommentStatsForPost);
  yield takeLatest(types.INBOX_DIRECT_MESSAGE_THREAD_REQUEST, sendInboxDirectMessageThreadRequest);
}
