import { useState } from 'react';

import { ImageValidation } from 'widgets/Media';

interface IBannerProps {
  isBannerFixed?: boolean;
  cutomTextMessage?: string;
}

export const CustomBanner = ({ isBannerFixed = true, cutomTextMessage }: IBannerProps) => {
  const [openCustomBanner, setOpenCustomBanner] = useState(!!cutomTextMessage);

  return openCustomBanner ? (
    <div className="ap-alert-main mbl-head--margin">
      <div className="api-alert-box alert-facebook">
        <div className="ab-cnt">
          <p>{cutomTextMessage}</p>
        </div>
        {!isBannerFixed ? (
          <div className="alert-icon ai-x">
            <ImageValidation isImgValid defaultImg="alert-xmark" customClassname="close" customName="close" onClick={() => setOpenCustomBanner(false)} />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};
