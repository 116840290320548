import { ActionsUnion, createAction } from '../../utils';
import * as actionTypes from '../action-types';
import { COMMON_ACTIONS } from '../common';
import { IAnalyticsChartResponse, IAnalyticsRequestPayload, ISentimentTimeRespons, ISurveyResultResponse } from '../../types/analytics';

export const surveyResultRequest = (payload: IAnalyticsRequestPayload) => createAction(actionTypes.SURVEY_RESULTS_REQUEST, payload);
export const surveyResultFailure = (payload: string) => createAction(actionTypes.SURVEY_RESULTS_FAILURE, payload);
export const surveyResultResponse = (payload: ISurveyResultResponse[]) => createAction(actionTypes.SURVEY_RESULTS_RESPONSE, payload);

export const sentimentTimeRequest = (payload: IAnalyticsRequestPayload) => createAction(actionTypes.SENTIMENT_TIME_REQUEST, payload);
export const sentimentTimeFailure = (payload: string) => createAction(actionTypes.SENTIMENT_TIME_FAILURE, payload);
export const sentimentTimeResponse = (payload: ISentimentTimeRespons[]) => createAction(actionTypes.SENTIMENT_TIME_RESPONSE, payload);

export const averageRatingRequest = (payload: IAnalyticsRequestPayload) => createAction(actionTypes.AVERAGE_RATING_REQUEST, payload);
export const averageRatingFailure = (payload: string) => createAction(actionTypes.AVERAGE_RATING_FAILURE, payload);
export const averageRatingResponse = (payload: IAnalyticsChartResponse[]) => createAction(actionTypes.AVERAGE_RATING_RESPONSE, payload);

export const agreedToReviewRequest = (payload: IAnalyticsRequestPayload) => createAction(actionTypes.AGREED_TO_REVIEW_REQUEST, payload);
export const agreedToReviewFailure = (payload: string) => createAction(actionTypes.AGREED_TO_REVIEW_FAILURE, payload);
export const agreedToReviewResponse = (payload: IAnalyticsChartResponse[]) => createAction(actionTypes.AGREED_TO_REVIEW_RESPONSE, payload);

export const ANALYTICS_ACTIONS = {
  ...COMMON_ACTIONS,
  surveyResultRequest,
  surveyResultFailure,
  surveyResultResponse,
  sentimentTimeRequest,
  sentimentTimeFailure,
  sentimentTimeResponse,
  averageRatingRequest,
  averageRatingFailure,
  averageRatingResponse,
  agreedToReviewRequest,
  agreedToReviewFailure,
  agreedToReviewResponse
};

export type AnalyticsAction = ActionsUnion<typeof ANALYTICS_ACTIONS>;
