import { apFranContainerTypes } from 'types';
// import { AP_FRAN_SHOW_LINE_BREAK_TYPES } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';

export const FacebookReelPreview = ({ userName, videoUrl, description, thumbnailUrl, profilePic }: apFranContainerTypes.ISocialMediaReelPreview) => {
  return (
    <div className="tt-wraps finsWrap fb-previewWrap fb-preview-reels-wrap">
      <div className="tt-asset-wrap">
        <ReactVideoPlayer
          videoUrl={videoUrl || ''}
          customClassname={'video full-width'}
          isPlayControl={false} // playVideo.includes(socialMediaName)
          light={thumbnailUrl}
        />
      </div>
      <div className="tiktok-Overlay">
        <div className="rPrevHead">
          <div className="rp-fbReels-top">
            <div className="rp-fr-left">
              <ImageValidation isImgValid customName={'Back'} customClassname="rp-back" defaultImg="fb-prev-previous" />
              <h3>Reels</h3>
            </div>
            <div className="rp-fr-right">
              <ImageValidation isImgValid customName={'Search'} customClassname="rp-e-item" defaultImg="fb-prev-search" />
              <ImageValidation isImgValid customName={'Camera'} customClassname="rp-e-item" defaultImg="fb-prev-camera" />
              <ImageValidation isImgValid customName={'User'} customClassname="rp-e-item" defaultImg="fb-preview-user" />
            </div>
          </div>

          <div className="rPrevProfile frpBase">
            <div className="rp-fr-left">
              {/* <div className="friendsLike">
                <div className="p-avatar">
                  <ImageValidation isImgValid customName={'Location Profile Item'} defaultImg="p-avatar.png" isNotSvgFormat />
                </div>
                <div className="p-title">
                  <div className="pUserlikings">
                    <span className="prev-userLikeList">Joshuva and 21,546 others like this</span>
                  </div>
                </div>
              </div> */}
              <div className="p-header-title pfbrUser">
                <div className="p-avatar">
                  <ImageValidation isImgValid customName={'Location Profile Item'} defaultImg="user-avatar-common.png" imgUrl={profilePic} isNotSvgFormat />
                </div>
                <div className="p-title">
                  <h5>
                    <span className="rpUserName">{userName}</span>
                    <ImageValidation isImgValid customName={'Global'} customClassname="rpGlobal" defaultImg="fb-prev-globe" />
                    <div className="rpSm">
                      <span>Follow</span>
                    </div>
                  </h5>
                </div>
              </div>
              <div className="rpDescription">
                <span className="rpDText">{description}</span>
                <span className="rpDMore">more</span>
              </div>
            </div>

            <div className="r-prev-right">
              <div className="fbr-img-count">
                <ImageValidation isImgValid customName={'Like'} defaultImg="fb-prev-like" />
                <span>21K</span>
              </div>
              <div className="fbr-img-count">
                <ImageValidation isImgValid customName={'Comment'} defaultImg="fb-prev-comments" />
                <span>25</span>
              </div>
              <div className="fbr-img-count">
                <ImageValidation isImgValid customName={'Share'} defaultImg="fb-prev-share" />
                <span>149</span>
              </div>
              <div className="fbr-img-more">
                <ImageValidation isImgValid customName={'More'} defaultImg="fb-prev-more" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="play-btn">
          <ImageValidation isImgValid defaultImg={'play-tiktok'} customName={'tiktokPlay'} />
        </div> */}

        {/* <ImageValidation isImgValid defaultImg={'music-r-tiktok'} customName={'Music'} /> */}
      </div>
      <div className="rPrev-footer">
        <div className="rpSm">
          <span>Add a comment...</span>
        </div>
      </div>
    </div>
  );
};
