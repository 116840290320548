import { ModalPopup } from 'components';
import { MODAL_BOX_TYPES, POSTS_SYNDICATE_CONTENT_MSG, POSTS_SYN_CONTENT_OBJ, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { postsContainerTypes } from 'types';

export const PostSyndicateContentModal = ({ isShowModal, setSyndicateContent }: postsContainerTypes.IPostSyndicateModalProps) => {
  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.INFO}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <>
          <div className="edit-approval-post-wrp-modal">
            <div className="modal-message-wraps-text">{POSTS_SYNDICATE_CONTENT_MSG}</div>
          </div>
        </>
      )}
      modalCloseButton
      handleModalClose={() => {
        setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
      }}
      isModalFooterShow
      modalFooter={() => (
        <div className="modal-btn-grp-wraps">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button
              className="ac-btn ac-secondary-white ac-outline ac-block border-0"
              onClick={() => {
                setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
              }}
            >
              Cancel
            </button>
          </CustomRippleButton>

          <CustomRippleButton rippleClass={`ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary ac-block`}
              onClick={() => {
                setSyndicateContent({ isShowModal: false, isSyndicateConfirm: true });
              }}
            >
              OK
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
