import { menuAccessReducerTypes } from 'types';

export const MEDIA_INITIAL_MENU_VALUE: menuAccessReducerTypes.IMenuAccessInitialValue = {
  ai_assistant: {},
  analytics: {
    analytics_social: true,
    analytics_content: true,
    analytics_page: true,
    analytics_reviews: true,
    analytics_leaderboard: true,
    analytics_revv: true,
    survey_status: true,
    sentiment: true,
    review_change: true,
    average_rating: true
  },
  content: {
    creator: true,
    posts: true,
    media: true,
    feed: true,
    scheduler: true,
    campaigns: true,
    coupons: true,
    rss_feeds: true,
    calendar: true,
    profile_imagery: true
  },
  community: {
    inbox: true,
    sandbox: true
  },
  marketing: {
    prospects_clients: true,
    fb_ads_marketing: true,
    targeting: true
  },
  reputation: {
    reviews: true,
    outbox: true,
    ai_review_responder: true
  },
  revv: {
    login: true,
    dashboard: true,
    surveys: true,
    reports: true,
    revv_customers: true,
    revv_settings: true,
    locations: true,
    employees: true
  },
  employee_advocacy: {
    overview: true,
    reward_programs: true,
    advocacy_leaderboard: true
  },
  directory_listings: {
    profile: true,
    listings: true
    // rankings: true
  },
  team_management: {
    logins: true,
    connections: true,
    lists: true,
    permissions: true,
    revv_teams: true
  },
  settings: {
    reviews_settings: true,
    release_form: true,
    rallio_profile: true,
    social_profile: true,
    fb_ads_settings: true,
    ai_playbook: true,
    brand_profile: true,
    partner_banners: true
  },
  admin_area: {
    accounts: true,
    franchisors: true,
    users: true
  }
};
