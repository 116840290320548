import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';

import { config } from 'config';

interface IDropZoneComponentProps {
  classVariant?: string;
  uploadImageList?: (value: File[]) => void;
  displayMsg: string;
}

export const DropZoneComponent = ({ classVariant, uploadImageList, displayMsg }: IDropZoneComponentProps) => {
  return (
    <Dropzone
      maxFiles={1}
      onDrop={(value) => {
        if (uploadImageList && value) {
          uploadImageList(value);
        }
      }}
    >
      {({ getInputProps, getRootProps }) => {
        return (
          <div {...getRootProps({ className: `${classVariant}` })}>
            <input {...getInputProps()} />
            <div className="drag-drop__text d-flex justify-content-center align-items-center">
              <img src={`${config.cdnRevvImgUrl}/upload-blue.svg`} alt="upload-icon" className="drag-drop" />
              <span className="text-msg">Drag & Drop your {displayMsg} here to upload</span>
            </div>
            <Button className="settings-browse__btn">Browse</Button>
          </div>
        );
      }}
    </Dropzone>
  );
};
