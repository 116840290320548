import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

import { aiAssistantApiTypes, aiAssistantReducerTypes } from 'types/ai-assistant';

// AI ASSISTANT - GET CHAT HISTORY
export const getAIAssistantChatHistoryRequest = (payload: aiAssistantApiTypes.IGetChatHistoryRequest) => createAction(actionTypes.AI_ASSISTANT_GET_CHAT_HISTORY_REQUEST, payload);
export const getAIAssistantChatHistoryResponse = (payload: aiAssistantReducerTypes.IAIAssistantChatHistory[]) => createAction(actionTypes.AI_ASSISTANT_GET_CHAT_HISTORY_RESPONSE, payload);
export const getAIAssistantChatHistoryFailure = (error: null | string) => createAction(actionTypes.AI_ASSISTANT_GET_CHAT_HISTORY_FAILURE, error);

// AI ASSISTANT - CREATE CHAT HISTORY
export const sendAIAssistantNewChatRequest = (payload: aiAssistantApiTypes.ICreateAIAssistantsRequest) => createAction(actionTypes.AI_ASSISTANT_SEND_NEW_CHAT_REQUEST, payload);
export const sendAIAssistantNewChatResponse = (payload: aiAssistantReducerTypes.IAIAssistantChatHistory) => createAction(actionTypes.AI_ASSISTANT_SEND_NEW_CHAT_RESPONSE, payload);
export const sendAIAssistantNewChatFailure = (error: null | string) => createAction(actionTypes.AI_ASSISTANT_SEND_NEW_CHAT_FAILURE, error);

// AI ASSISTANT - DELETE CHAT
export const deleteAIAssistantRequest = (payload: aiAssistantApiTypes.IDeleteAIAssistants) => createAction(actionTypes.AI_ASSISTANT_DELETE_REQUEST, payload);
export const deleteAIAssistantResponse = (payload: { message: string }) => createAction(actionTypes.AI_ASSISTANT_DELETE_RESPONSE, payload);
export const deleteAIAssistantFailure = (error: null | string) => createAction(actionTypes.AI_ASSISTANT_DELETE_FAILURE, error);

// AI ASSISTANT - RESET
export const resetAIAssistant = () => createAction(actionTypes.AI_ASSISTANT_RESET);

// AI ASSISTANT GENERATING
export const aiAssistantGenerating = () => createAction(actionTypes.AI_ASSISTANT_GENERATING);

const AI_ASSISTATN_ACTIONS = {
  getAIAssistantChatHistoryRequest,
  getAIAssistantChatHistoryResponse,
  getAIAssistantChatHistoryFailure,
  sendAIAssistantNewChatRequest,
  sendAIAssistantNewChatResponse,
  sendAIAssistantNewChatFailure,
  deleteAIAssistantRequest,
  deleteAIAssistantResponse,
  deleteAIAssistantFailure,
  resetAIAssistant,
  aiAssistantGenerating
};

export type AiAssistantActions = ActionsUnion<typeof AI_ASSISTATN_ACTIONS>;
