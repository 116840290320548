import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { logoutRequest, exceptionHandlerRequest, stripeCustomerResponse, stripeCustomerResquestFail, stripeCardsListResposne, stripeCardsListResquestFail, stripeCardsListRequest } from 'actions';
import { apiCall, defaultHeader, NODE_API } from 'utils/helpers';
import { API_TYPES, MSG_STRIPE_API_EXCEPTION, MSG_STRIPE_NO_CUSTOMERS_DATA, MSG_STRIPE_NO_CARDS_DATA } from 'utils/constants';
import { commonApiTypes } from 'types';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetStripeCustomerRequest({ payload }: ISagaPayload): any {
  try {
    if (!payload) {
      yield put(exceptionHandlerRequest(MSG_STRIPE_API_EXCEPTION));
      return false;
    }
    const headers = defaultHeader();
    const params = { email: payload };
    const response = yield apiCall({ headers, params, type: API_TYPES.NODE, ...NODE_API.searchByEmail });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (response.data.data.length) {
        yield put(stripeCustomerResponse(response.data.data[0]));
      } else {
        yield put(stripeCustomerResponse(response.data.data));
      }
    } else {
      yield put(stripeCustomerResquestFail(MSG_STRIPE_NO_CUSTOMERS_DATA));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MSG_STRIPE_API_EXCEPTION));
  }
}

function* sendGetCardsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { customer_id: payload };
    const urlPathObj = { ...NODE_API.allCardsForCustomer };

    const replacedPath: string = urlPathObj.apiPath.replace(':id', payload);
    urlPathObj.apiPath = replacedPath;

    const response = yield apiCall({ headers, params, type: API_TYPES.NODE, apiPath: replacedPath, action: urlPathObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(stripeCardsListResposne(response.data?.data?.data));
    } else {
      yield put(stripeCardsListResquestFail(MSG_STRIPE_NO_CARDS_DATA));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MSG_STRIPE_API_EXCEPTION));
  }
}

function* sendAIGetStripeCustomerRequest({ payload }: ISagaPayload): any {
  try {
    if (!payload) {
      yield put(exceptionHandlerRequest(MSG_STRIPE_API_EXCEPTION));
      return false;
    }
    const headers = defaultHeader();
    const params = { email: payload };
    const response = yield apiCall({ headers, params, type: API_TYPES.NODE, ...NODE_API.searchByEmail });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (response.data.data.length) {
        yield put(stripeCustomerResponse(response.data.data[0]));
        if (response.data.data[0]?.id) yield put(stripeCardsListRequest(response.data.data[0]?.id));
      } else {
        yield put(stripeCustomerResponse(response.data.data));
      }
    } else {
      yield put(stripeCustomerResquestFail(MSG_STRIPE_NO_CUSTOMERS_DATA));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(MSG_STRIPE_API_EXCEPTION));
  }
}

export function* takeStripeRequest(): any {
  yield takeEvery(types.STRIPE_GET_CUSTOMER_REQUEST, sendGetStripeCustomerRequest);
  yield takeEvery(types.STRIPE_GET_CARDS_REQUEST, sendGetCardsListRequest);
  yield takeEvery(types.AI_STRIPE_GET_CUSTOMER_REQUEST, sendAIGetStripeCustomerRequest);
}
