import { useSelector, useDispatch } from 'react-redux';

import { setAIGeneratedOption, setCreatorAICaptions } from 'actions';
import { IStore, aiContentCreatorContainerTypes } from 'types';
import { AI_HASHTAGS_NOT_RETREIVED } from 'utils/constants';

export const HashTagsList = ({ handleEditorStateChanges, selectedTextObj }: aiContentCreatorContainerTypes.IHashTagsProps) => {
  const dispatch = useDispatch();

  const caption = useSelector((state: IStore) => state.aiContentCreator.caption);
  const createPostHashtagSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostHashtagSuggestions);
  const aiGenerated = useSelector((state: IStore) => state.aiContentCreator.aiGenerated);

  const isAllHashtags = createPostHashtagSuggestions?.length === caption.selectedHashtags?.length;

  const HashTagContent = ({ isSelected, hashtag }: aiContentCreatorContainerTypes.IHashtagResults) => {
    return (
      <div className={`ai-picks aitht-item ${isSelected ? 'active' : ''}`}>
        <div className="ait-hover">
          <button
            className="ac-btn turquoise"
            onClick={() => {
              const state = isSelected ? [...caption.selectedHashtags].filter((datum) => datum !== hashtag) : [...caption.selectedHashtags, hashtag];
              dispatch(setCreatorAICaptions({ ...caption, selectedHashtags: state }));
              dispatch(setAIGeneratedOption({ ...aiGenerated, hashtag: isSelected ? false : true }));
              handleEditorStateChanges(null, isSelected ? [...caption.selectedHashtags].filter((datum) => datum === hashtag) : [...caption.selectedHashtags, hashtag], isSelected);
            }}
          >
            <span>{isSelected ? 'Selected' : 'Select'}</span>
          </button>
        </div>
        <div className="ait-front">
          <span>{hashtag}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={createPostHashtagSuggestions.length ? 'aitc ai-tab-hashtags' : 'aitc ai-tab-hashtags ai-no-data'}>
      <div className="head-selectAll">
        <h3>Hashtags</h3>
        {createPostHashtagSuggestions?.length > 1 && (
          <div className={`saDatas ${isAllHashtags ? 'active' : ''}`}>
            <label key={`checkbox`} className={isAllHashtags ? 'checkbox-item active' : 'checkbox-item'}>
              <span className="checkbox-hover">
                <input
                  value={'all'}
                  type="checkbox"
                  className="option-input checkbox"
                  name="All"
                  checked={isAllHashtags}
                  onChange={(event) => {
                    dispatch(setAIGeneratedOption({ ...aiGenerated, hashtag: !isAllHashtags }));
                    handleEditorStateChanges(
                      selectedTextObj.index !== null || caption.index !== null ? selectedTextObj?.text : null,
                      createPostHashtagSuggestions,
                      event.target.checked ? false : true
                    );
                    dispatch(setCreatorAICaptions({ ...caption, selectedHashtags: event.target.checked ? createPostHashtagSuggestions : [] }));
                  }}
                />
              </span>
              <span className="labelText">Select All</span>
            </label>
          </div>
        )}
      </div>
      <div className="ai-hashtags">
        {createPostHashtagSuggestions?.length ? (
          createPostHashtagSuggestions.map((it, index) => {
            const isSelected = caption?.selectedHashtags?.includes(it);
            return <HashTagContent isSelected={isSelected} hashtag={it} key={`${it}_hashtag_${index}`} />;
          })
        ) : (
          <div className="ai-alert-ht">{AI_HASHTAGS_NOT_RETREIVED}</div>
        )}
      </div>
    </div>
  );
};
