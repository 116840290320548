import { ActionsUnion, createAction } from 'utils/helpers/';
import * as actionTypes from 'actions/action-types';
import { inboxApiTypes } from 'types';

// INBOX LIST
export const getInboxListRequest = (payload: inboxApiTypes.IInboxListRequest) => createAction(actionTypes.INBOX_LIST_REQUEST, payload);
export const getInboxListResponse = (data: inboxApiTypes.IInboxListResponse) => createAction(actionTypes.INBOX_LIST_RESPONSE, data);
export const getInboxListRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_LIST_REQUEST_FAILURE, error);

// INBOX STATS
export const getInboxStatsRequest = (payload: inboxApiTypes.IInboxListRequest) => createAction(actionTypes.INBOX_GET_STATS_REQUEST, payload);
export const getInboxStatsResponse = (data: inboxApiTypes.IInboxListMetaResponse) => createAction(actionTypes.INBOX_GET_STATS_RESPONSE, data);
export const getInboxStatsReqFailure = (error: null | string) => createAction(actionTypes.INBOX_GET_STATS_FAILURE, error);

// INBOX - DELETE POST
export const deleteInboxResponse = (data: inboxApiTypes.IInboxDeleteResponseData) => createAction(actionTypes.INBOX_DELETE_RESPONSE, data);
export const deleteInboxResponseFailure = (error: null | string) => createAction(actionTypes.INBOX_DELETE_FAILURE, error);

// INBOX RESET
export const inboxDeleteDataReset = () => createAction(actionTypes.INBOX_DELETE_DATA_RESET);

// INBOX - LIKE
export const inboxLikeResponse = (data: inboxApiTypes.IInboxLikeResponse) => createAction(actionTypes.INBOX_LIKE_RESPONSE, data);
export const inboxLikeRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_LIKE_FAILURE, error);

// INBOX COMMENST - LIKE
export const inboxCommentsEngagementRequest = (payload: inboxApiTypes.IInboxEngagement) => createAction(actionTypes.INBOX_COMMENTS_ENGAGEMENT_REQUEST, payload);
export const inboxCommentsEngagementResponse = (data: inboxApiTypes.IInboxEngagement) => createAction(actionTypes.INBOX_COMMENTS_ENGAGEMENT_RESPONSE, data);
export const inboxCommentsEngagementRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_COMMENTS_ENGAGEMENT_FAILURE, error);

// INBOX - REPLY
export const inboxReplyRequest = (payload: inboxApiTypes.IInboxReplyReqParams) => createAction(actionTypes.INBOX_REPLY_REQUEST, payload);
export const inboxReplyResponse = (data: inboxApiTypes.IInboxReplyResponse) => createAction(actionTypes.INBOX_REPLY_RESPONSE, data);
export const inboxReplyRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_REPLY_FAILURE, error);

// INBOX - RETWEET
export const inboxRetweetRequest = (payload: inboxApiTypes.IInboxCommentsReqParams) => createAction(actionTypes.INBOX_RETWEET_REQUEST, payload);
export const inboxRetweetResponse = (data: inboxApiTypes.IInboxRetweetResponse) => createAction(actionTypes.INBOX_RETWEET_RESPONSE, data);
export const inboxRetweetRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_RETWEET_FAILURE, error);

// INBOX - GET COMMENTS
export const inboxCommentsRequest = (payload: inboxApiTypes.IInboxCommentsReqParams) => createAction(actionTypes.INBOX_COMMENTS_REQUEST, payload);
export const updateCommentsCountOnPostRequest = (payload: inboxApiTypes.IInboxCommentsReqParams) => createAction(actionTypes.UPDATE_POST_COMMENT_COUNT_REQUEST, payload);
export const updateCommentsCountOnPost = (payload: inboxApiTypes.IInboxUpdateCommentCountResponse) => createAction(actionTypes.UPDATE_POST_COMMENT_COUNT, payload);
export const updateInboxStats = (payload: inboxApiTypes.IInboxListResponse) => createAction(actionTypes.UPDATE_INBOX_STATS, payload);
export const inboxCommentsResponse = (data: inboxApiTypes.IInboxCommentsResponse) => createAction(actionTypes.INBOX_COMMENTS_RESPONSE, data);
export const inboxCommentsRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_COMMENTS_FAILURE, error);

// INBOX - GET DISMISS
export const inboxDismissRequest = (payload: inboxApiTypes.IInboxDismissReqParams) => createAction(actionTypes.INBOX_DISMISS_REQUEST, payload);
export const inboxDismissResponse = (data: inboxApiTypes.IInboxDismissReqParams) => createAction(actionTypes.INBOX_DISMISS_RESPONSE, data);
export const inboxDismissRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_DISMISS_FAILURE, error);

// INBOX - PIE RELOAD
export const inboxPieReloadRequest = (payload: inboxApiTypes.IInboxPieReloadReqParams) => createAction(actionTypes.INBOX_PIE_RELOAD_REQUEST, payload);
export const inboxPieReloadResponse = (payload: boolean) => createAction(actionTypes.INBOX_PIE_RELOAD_RESPONSE, payload);
export const inboxPieReloadRequestFailure = (error: null | string) => createAction(actionTypes.INBOX_PIE_RELOAD_FAILURE, error);

// INBOX - SET STATSCALL
export const setInboxStatscall = (payload: boolean) => createAction(actionTypes.INBOX_SET_STATS_CALL, payload);

// INBOX - SET COMMENTS DATA
export const inboxSetActiveCommentIds = (payload: number[]) => createAction(actionTypes.INBOX_SET_ACTIVE_COMMENT_IDS, payload);

// INBOX DIRECT MESSAGE THREAD
export const getInboxDirectMessageThreadRequest = (payload: inboxApiTypes.IInboxDMThreadReqParams) => createAction(actionTypes.INBOX_DIRECT_MESSAGE_THREAD_REQUEST, payload);
export const getInboxDirectMessageThreadResponse = (data: { messageData: inboxApiTypes.IInboxListSocialContents[]; page: number }) =>
  createAction(actionTypes.INBOX_DIRECT_MESSAGE_THREAD_RESPONSE, data);
export const getInboxDirectMessageThreadFailure = (error: null | string) => createAction(actionTypes.INBOX_DIRECT_MESSAGE_THREAD_FAILURE, error);

// INBOX - RESET ALL
export const inboxReplyMsgReset = () => createAction(actionTypes.INBOX_REPLY_MSG_RESET);
export const inboxViewCommentsDataReset = () => createAction(actionTypes.INBOX_VIEW_COMMENTS_DATA_RESET);
export const inboxCommentsPageCountReset = () => createAction(actionTypes.INBOX_COMMENTS_PAGE_COUNT_RESET);
export const inboxResetAll = () => createAction(actionTypes.INBOX_RESET_ALL);

// INBOX - SET NEW THREAD STATUS
export const inboxIsSetNewThreadMsgFound = (payload: boolean) => createAction(actionTypes.INBOX_SET_IS_NEW_THREAD_MSG_FOUND, payload);

const INBOX_ACTIONS = {
  getInboxListRequest,
  getInboxListResponse,
  getInboxListRequestFailure,
  getInboxStatsRequest,
  getInboxStatsResponse,
  getInboxStatsReqFailure,
  inboxDeleteDataReset,
  deleteInboxResponse,
  deleteInboxResponseFailure,
  inboxLikeResponse,
  inboxLikeRequestFailure,
  inboxReplyRequest,
  inboxReplyResponse,
  inboxReplyRequestFailure,
  inboxCommentsRequest,
  inboxCommentsResponse,
  inboxCommentsRequestFailure,
  inboxDismissRequest,
  inboxDismissResponse,
  inboxDismissRequestFailure,
  inboxPieReloadRequest,
  inboxPieReloadResponse,
  inboxPieReloadRequestFailure,
  inboxCommentsEngagementRequest,
  inboxCommentsEngagementResponse,
  inboxCommentsEngagementRequestFailure,
  inboxRetweetRequest,
  inboxRetweetResponse,
  inboxRetweetRequestFailure,
  getInboxDirectMessageThreadRequest,
  getInboxDirectMessageThreadResponse,
  getInboxDirectMessageThreadFailure,
  inboxSetActiveCommentIds,
  inboxReplyMsgReset,
  inboxViewCommentsDataReset,
  inboxCommentsPageCountReset,
  updateCommentsCountOnPost,
  setInboxStatscall,
  updateInboxStats,
  inboxIsSetNewThreadMsgFound,
  inboxResetAll
};

export type InboxAction = ActionsUnion<typeof INBOX_ACTIONS>;
