import { changePasswordContainerTypes } from 'types';

export const CHANGE_PASSWORD_INITIAL_VALUE: changePasswordContainerTypes.IChangePasswordFieldValue = {
  current_password: '',
  new_password: '',
  confirm_password: ''
};

export const CHANGE_PASSWORD_FILEDS: changePasswordContainerTypes.IChangePasswordFields = {
  CURRENT_PASSWORD: 'current_password',
  NEW_PASSWORD: 'new_password',
  CONFIRM_PASSWORD: 'confirm_password'
};
