import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import useOnClickOutside from 'use-onclickoutside';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup, advocacyOnboardContainerTypes, IStore } from 'types';
import { advocacyOnboardSetInitialPreviewText, advocacyOnboardSetPreviewText, getAdvocacyOnboardMultipleReInviteRequest } from 'actions';
import { sanitizeHtmlContent } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { RIPPLE_COLOR } from 'utils/constants';
import { TextArea } from 'widgets/Text';

export const EmailPreviewModalPopup = ({ isModalShow, handleModalClose, containerClassName }: commonModalPopup.IEmailPreviewModalPopup) => {
  const dispatch = useDispatch();

  const { activeAdvocateIds, previewText } = useSelector((state: IStore) => state.advocacyOnboard);

  const [emailPreviewProp, setEmailPreviewProp] = useState<advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp>({ isEdit: false, isReset: true });

  const ref = useRef<any>(null);
  const emailPreviewTitleFocus = useRef<HTMLTextAreaElement>(null);

  /** Create auofocus on Editing email Preview */
  useEffect(() => {
    if (emailPreviewProp.isEdit) {
      emailPreviewTitleFocus?.current?.focus();
    }
  }, [emailPreviewProp.isEdit]); // eslint-disable-line

  useOnClickOutside(ref, handleModalClose);

  const handlePreviewModalClose = () => {
    handleModalClose();
    dispatch(advocacyOnboardSetInitialPreviewText());
    setEmailPreviewProp((state: advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp) => ({ ...state, isEdit: false, isReset: true }));
  };

  return (
    <Modal
      className={`prime-modal adv-emilpreview-modal${containerClassName ? ` ${containerClassName}` : ``}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} />
      </div>
      <Modal.Body ref={ref}>
        <div className="asp-modal">
          <div className="emailtemp-avo-header">
            <div className="modal-logo">
              <ImageValidation isImgValid defaultImg="logo" customName="email-template-logo" />
            </div>
          </div>
          <div className="adv-modal-content-wrp">
            <div
              className={`${
                emailPreviewProp.isEdit ? 'advocacy-onboarding-textarea-email-wrp tm-wa tmw-ah et-mainhead' : 'advocacy-onboarding-textarea-email-wrp tm-wa tmw-ah et-mainhead events-none'
              }`}
            >
              <TextArea
                name=""
                placeholder="Enter title"
                ref={emailPreviewTitleFocus}
                id="exampleForm.ControlTextarea1"
                className={`tm-ci-txtarea`}
                autoComplete="off"
                maxLength={100}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'title', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.title)}
                maxRows={1}
                // autoFocus={previewText.title}
              />
            </div>
            <div className={`${emailPreviewProp.isEdit ? 'advocacy-onboarding-textarea-email-wrp et-content tm-str' : 'advocacy-onboarding-textarea-email-wrp et-content tm-str events-none'}`}>
              <TextArea
                name=""
                placeholder="Enter text"
                id="exampleForm.ControlTextarea2"
                className={`tm-ci-txtarea`}
                autoComplete="off"
                maxLength={300}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'text1', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.text1)}
                maxRows={1}
              />
            </div>
            <div
              className={`${
                emailPreviewProp.isEdit ? 'advocacy-onboarding-textarea-email-wrp et-subhead tm-tr tmt-mrh' : 'advocacy-onboarding-textarea-email-wrp et-subhead tm-tr tmt-mrh events-none'
              }`}
            >
              <TextArea
                name=""
                placeholder="Enter text"
                id="exampleForm.ControlTextarea3"
                className={`tm-ci-txtarea sub-title-txt`}
                autoComplete="off"
                maxLength={300}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'text2', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.text2)}
                maxRows={1}
              />
            </div>
            <div className={`${emailPreviewProp.isEdit ? 'advocacy-onboarding-textarea-email-wrp et-content tm-str' : 'advocacy-onboarding-textarea-email-wrp et-content tm-str events-none'}`}>
              <TextArea
                name=""
                placeholder="Enter text"
                id="exampleForm.ControlTextarea4"
                className={`tm-ci-txtarea`}
                autoComplete="off"
                maxLength={300}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'text3', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.text3)}
                maxRows={1}
              />
            </div>
            <div className="btn-align-center-modal-pop">
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className="ac-btn ac-primary confirm-my-acc-btn">Confirm My Account</button>
              </CustomRippleButton>
            </div>
            <div
              className={`${
                emailPreviewProp.isEdit ? 'advocacy-onboarding-textarea-email-wrp et-subhead tm-tr tmt-mrh' : 'advocacy-onboarding-textarea-email-wrp et-subhead tm-tr tmt-mrh events-none'
              }`}
            >
              <TextArea
                name=""
                placeholder="Enter text"
                id="exampleForm.ControlTextarea5"
                className={`tm-ci-txtarea sub-title-txt`}
                autoComplete="off"
                maxLength={300}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'text4', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.text4)}
                maxRows={1}
              />
            </div>
            <div
              className={`${
                emailPreviewProp.isEdit
                  ? 'advocacy-onboarding-textarea-email-wrp et-content etc-big tm-str tm-nah'
                  : 'advocacy-onboarding-textarea-email-wrp et-content etc-big tm-str tm-nah events-none'
              }`}
            >
              <TextArea
                name=""
                placeholder="Enter text"
                id="exampleForm.ControlTextarea6"
                className={`tm-ci-txtarea`}
                autoComplete="off"
                maxLength={300}
                onChange={(e: any) => {
                  dispatch(advocacyOnboardSetPreviewText({ type: 'text5', val: e.target.value }));
                }}
                value={sanitizeHtmlContent(previewText.text5)}
                maxRows={2}
              />
            </div>
            <div className="modal-btn-grp-wraps ap-actions g-20">
              {emailPreviewProp.isEdit ? (
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button
                    className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                    onClick={() => {
                      setEmailPreviewProp((state: advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp) => ({ ...state, isEdit: false, isReset: true }));
                      dispatch(advocacyOnboardSetInitialPreviewText());
                    }}
                  >
                    {'Cancel'}
                  </button>
                </CustomRippleButton>
              ) : (
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button
                    className="ac-btn ac-secondary-white ac-outline size-xs border-0"
                    onClick={() => {
                      handlePreviewModalClose();
                    }}
                  >
                    {'Cancel'}
                  </button>
                </CustomRippleButton>
              )}
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button
                  className="ac-btn ac-secondary-white ac-outline size-xs invite__edit--btn border-0"
                  onClick={() => {
                    if (emailPreviewProp.isEdit) {
                      setEmailPreviewProp((state: advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp) => ({ ...state, isReset: true }));
                      dispatch(advocacyOnboardSetInitialPreviewText());
                    } else if (emailPreviewProp.isReset) {
                      setEmailPreviewProp((state: advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp) => ({ ...state, isEdit: true, isReset: false }));
                    }
                  }}
                >
                  {emailPreviewProp.isEdit ? 'Reset' : 'Edit'}
                </button>
              </CustomRippleButton>
              {emailPreviewProp.isEdit && (
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary size-xs`}
                    onClick={() => {
                      setEmailPreviewProp((state: advocacyOnboardContainerTypes.IAdvocacyOnboardEmailPreviewProp) => ({ ...state, isEdit: false, isReset: true }));
                    }}
                  >
                    {'Preview'}
                  </button>
                </CustomRippleButton>
              )}
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary size-xs`}
                  onClick={() => {
                    dispatch(
                      getAdvocacyOnboardMultipleReInviteRequest({
                        ids: activeAdvocateIds,
                        title: previewText.title,
                        invite_text_one: previewText.text1,
                        invite_text_two: previewText.text2,
                        invite_text_three: previewText.text3,
                        invite_text_four: previewText.text4,
                        invite_text_five: previewText.text5
                      })
                    );
                    handlePreviewModalClose();
                  }}
                >
                  {'Send'}
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
        {/* Save Cancel Buttons */}
      </Modal.Body>
    </Modal>
  );
};
