import { Toast, ToastContainer, Button } from 'react-bootstrap';

// import 'assets/stylesheets/components/toast.scss';

interface IToastFierProps {
  notificationMsg: string;
  setToastifier: (bool: boolean) => void;
  isEnabled: boolean;
  toastClr: string;
  disableAutoHide?: boolean;
  classVariant?: string;
}

export const Toastifier = ({ notificationMsg, setToastifier, isEnabled, toastClr, disableAutoHide, classVariant }: IToastFierProps) => {
  return (
    <ToastContainer className={`${classVariant} revv-npsp-toast`} position="bottom-end">
      <Toast className="p-2" onClose={() => setToastifier(false)} show={isEnabled} delay={3000} autohide={disableAutoHide ? false : true} animation bg={toastClr.toLocaleLowerCase()}>
        <Toast.Body className="d-flex align-items-center justify-content-center">
          <span className="text-white fs-secondary message">{notificationMsg}</span>
          <Button className="ms-2 text-decoration-none text-success" onClick={() => setToastifier(false)} size="sm" variant="link" type="submit">
            DISMISS
          </Button>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
