import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { reviewsApiTypes } from 'types';

// REVIEWS LIST
export const getReviewsListRequest = (payload: reviewsApiTypes.IReviewsListReqParams) => createAction(actionTypes.REVIEWS_GET_LIST_REQUEST, payload);
export const getReviewsListResponse = (data: reviewsApiTypes.IReviewsListObj[]) => createAction(actionTypes.REVIEWS_GET_LIST_RESPONSE, data);
export const getReviewsListReqFailure = (error: null | string) => createAction(actionTypes.REVIEWS_GET_LIST_FAILURE, error);

// REVIEWS STATS
export const getReviewsStatsRequest = (payload: reviewsApiTypes.IReviewsStatsReqParams) => createAction(actionTypes.REVIEWS_GET_STATS_REQUEST, payload);
export const getReviewsStatsResponse = (data: reviewsApiTypes.IReviewsStatsData) => createAction(actionTypes.REVIEWS_GET_STATS_RESPONSE, data);
export const getReviewsStatsReqFailure = (error: null | string) => createAction(actionTypes.REVIEWS_GET_STATS_FAILURE, error);

// REVIEWS - ADD REPLY TO A POST
export const getReviewsAddReplyToPostRequest = (payload: reviewsApiTypes.IReviewsAddReplyRequest) => createAction(actionTypes.REVIEWS_ADD_REPLY_TO_A_POST_REQUEST, payload);
export const getReviewsAddReplyToPostResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_ADD_REPLY_TO_A_POST_RESPONSE, data);
export const getReviewsAddReplyToPostFailure = (error: null | string) => createAction(actionTypes.REVIEWS_ADD_REPLY_TO_A_POST_FAILURE, error);

// REVIEWS - COMPLETED POST
export const getReviewsCompletePostRequest = (payload: reviewsApiTypes.IReviewsCompleteRequest) => createAction(actionTypes.REVIEWS_COMPLETE_POST_REQUEST, payload);
export const getReviewsCompletePostResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_COMPLETE_POST_RESPONSE, data);
export const getReviewsCompletePostFailure = (error: null | string) => createAction(actionTypes.REVIEWS_COMPLETE_POST_FAILURE, error);

// REVIEWS - GET INTERNAL NOTES
export const getReviewsInternalNotesRequest = (payload: reviewsApiTypes.IReviewsInternalNotesReq) => createAction(actionTypes.REVIEWS_GET_INTERNAL_NOTES_REQUEST, payload);
export const getReviewsInternalNotesResponse = (data: reviewsApiTypes.IReviewsIntNotesResp) => createAction(actionTypes.REVIEWS_GET_INTERNAL_NOTES_RESPONSE, data);
export const getReviewsInternalNotesFailure = (error: null | string) => createAction(actionTypes.REVIEWS_GET_INTERNAL_NOTES_FAILURE, error);

// REVIEWS - ADD INTERNAL NOTE
export const addReviewsInternalNoteRequest = (payload: reviewsApiTypes.IReviewsAddInternalNoteRequest) => createAction(actionTypes.REVIEWS_ADD_INTERNAL_NOTE_REQUEST, payload);
export const addReviewsInternalNoteResponse = (data: reviewsApiTypes.IReviewsInternalNotesResponse) => createAction(actionTypes.REVIEWS_ADD_INTERNAL_NOTE_RESPONSE, data);
export const addReviewsInternalNoteFailure = (error: null | string) => createAction(actionTypes.REVIEWS_ADD_INTERNAL_NOTE_FAILURE, error);

// REVIEWS - HANDLE POST
export const reviewsHandleRequest = (payload: reviewsApiTypes.IReviewsHandleRequest) => createAction(actionTypes.REVIEWS_HANDLE_POST_REQUEST, payload);
export const reviewsHandleResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_HANDLE_POST_RESPONSE, data);
export const reviewsHandleFailure = (error: null | string) => createAction(actionTypes.REVIEWS_HANDLE_POST_FAILURE, error);

// REVIEWS - SEND FOR REPLY APPROVAL
export const reviewsSendForReplyApprovalRequest = (payload: reviewsApiTypes.IReviewsAddReplyRequest) => createAction(actionTypes.REVIEWS_SEND_FOR_REPLY_APPROVAL_REQUEST, payload);
export const reviewsSendForReplyApprovalResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_SEND_FOR_REPLY_APPROVAL_RESPONSE, data);
export const reviewsSendForReplyApprovalFailure = (error: null | string) => createAction(actionTypes.REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILURE, error);

// REVIEWS - UPDATE SUBMITTED REPLY
export const reviewsUpdateSubmittedReplyRequest = (payload: reviewsApiTypes.IReviewsAddReplyRequest) => createAction(actionTypes.REVIEWS_UPDATE_SUBMITTED_REPLY_REQUEST, payload);
export const reviewsUpdateSubmittedReplyResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_UPDATE_SUBMITTED_REPLY_RESPONSE, data);
export const reviewsUpdateSubmittedReplyFailure = (error: null | string) => createAction(actionTypes.REVIEWS_UPDATE_SUBMITTED_REPLY_FAILURE, error);

// REVIEWS - APPROVE REPLY
export const reviewsApproveReplyRequest = (payload: reviewsApiTypes.IReviewsAddReplyRequest) => createAction(actionTypes.REVIEWS_APPROVE_REPLY_REQUEST, payload);
export const reviewsApproveReplyResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_APPROVE_REPLY_RESPONSE, data);
export const reviewsApproveReplyFailure = (error: null | string) => createAction(actionTypes.REVIEWS_APPROVE_REPLY_FAILURE, error);

// REVIEWS - REJECT REPLY
export const reviewsRejectReplyRequest = (payload: reviewsApiTypes.IReviewsAddReplyRequest) => createAction(actionTypes.REVIEWS_REJECT_REPLY_REQUEST, payload);
export const reviewsRejectReplyResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_REJECT_REPLY_RESPONSE, data);
export const reviewsRejectReplyFailure = (error: null | string) => createAction(actionTypes.REVIEWS_REJECT_REPLY_FAILURE, error);

// REVIEWS - DELETE REPLY FOR GOOGLE POST
export const reviewsDeleteReplyForGoogleRequest = (payload: number) => createAction(actionTypes.REVIEWS_DELETE_REPLY_FOR_GOOGLE_REQUEST, payload);
export const reviewsDeleteReplyForGoogleResponse = (data: reviewsApiTypes.IReviewsListObj) => createAction(actionTypes.REVIEWS_DELETE_REPLY_FOR_GOOGLE_RESPONSE, data);
export const reviewsDeleteReplyForGoogleFailure = (error: null | string) => createAction(actionTypes.REVIEWS_DELETE_REPLY_FOR_GOOGLE_FAILURE, error);

export const reviewsSetInternalNotesList = (data: reviewsApiTypes.IReviewsInternalNotesResponse[]) => createAction(actionTypes.REVIEWS_SET_INTERNAL_NOTES_LIST, data);

// REVIEWS RESET
export const reviewsListDataReset = () => createAction(actionTypes.REVIEWS_LIST_DATA_RESET);
export const reviewsUpdateMsgReset = () => createAction(actionTypes.REVIEWS_UPDATED_MSG_RESET);
export const reviewsResetAll = () => createAction(actionTypes.REVIEWS_RESET_ALL);

const REVIEWS_ACTION = {
  getReviewsListRequest,
  getReviewsListResponse,
  getReviewsListReqFailure,
  getReviewsStatsRequest,
  getReviewsStatsResponse,
  getReviewsStatsReqFailure,
  getReviewsAddReplyToPostRequest,
  getReviewsAddReplyToPostResponse,
  getReviewsAddReplyToPostFailure,
  getReviewsCompletePostRequest,
  getReviewsCompletePostResponse,
  getReviewsCompletePostFailure,
  getReviewsInternalNotesRequest,
  getReviewsInternalNotesResponse,
  getReviewsInternalNotesFailure,
  addReviewsInternalNoteRequest,
  addReviewsInternalNoteResponse,
  addReviewsInternalNoteFailure,
  reviewsHandleRequest,
  reviewsHandleResponse,
  reviewsHandleFailure,
  reviewsSendForReplyApprovalRequest,
  reviewsSendForReplyApprovalResponse,
  reviewsSendForReplyApprovalFailure,
  reviewsUpdateSubmittedReplyRequest,
  reviewsUpdateSubmittedReplyResponse,
  reviewsUpdateSubmittedReplyFailure,
  reviewsApproveReplyRequest,
  reviewsApproveReplyResponse,
  reviewsApproveReplyFailure,
  reviewsRejectReplyRequest,
  reviewsRejectReplyResponse,
  reviewsRejectReplyFailure,
  reviewsDeleteReplyForGoogleRequest,
  reviewsDeleteReplyForGoogleResponse,
  reviewsDeleteReplyForGoogleFailure,
  reviewsSetInternalNotesList,
  reviewsListDataReset,
  reviewsUpdateMsgReset,
  reviewsResetAll
};

export type ReviewsActions = ActionsUnion<typeof REVIEWS_ACTION>;
