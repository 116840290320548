import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { useAccountSwitcherData } from '../use-account-switcher-data';
import { SITE_RESTRICTION_OBJ_INITIAL_VALUE, USER_OWNERSHIP, siteRestrictionObj } from 'utils/constants';

export const useSiteRestriction = () => {
  const { userOwnership } = useAccountSwitcherData();
  const siteRestrictions = useSelector((state: IStore) => state.accountSwitcher.menu_access?.site_restrictions);

  const { postsRestriction, calendarRestriction, aiRestriction, creatorRestriction } = useMemo(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && siteRestrictions?.hub_user) {
      return Object.keys(siteRestrictionObj).reduce((acc, curr) => {
        siteRestrictionObj[curr].forEach((eleObj) => {
          acc[`${curr}Restriction`][eleObj] = siteRestrictions?.hub_user?.includes(`${curr}/${eleObj}`) || false;
        });
        return acc;
      }, SITE_RESTRICTION_OBJ_INITIAL_VALUE);
    } else {
      return Object.keys(siteRestrictionObj).reduce((acc, curr) => {
        siteRestrictionObj[curr].forEach((eleObj) => {
          acc[`${curr}Restriction`][eleObj] = siteRestrictions?.location_user?.includes(`${curr}/${eleObj}`) || false;
        });
        return acc;
      }, SITE_RESTRICTION_OBJ_INITIAL_VALUE);
    }
  }, [siteRestrictions, userOwnership]);

  return { postsRestriction, calendarRestriction, aiRestriction, creatorRestriction };
};
