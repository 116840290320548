import { postsApiTypes, postsReducerTypes } from 'types';

export const addOrDeleteMultiplePostsForCampaign = (totalSelectedPosts: number[], activePostId: number) => {
  return !totalSelectedPosts.includes(activePostId) ? totalSelectedPosts.concat(activePostId) : totalSelectedPosts.filter((it) => it !== activePostId);
};
/** Get campaigns sorted posts list */
export const getCampaignsSortedPostsList = (
  postsList: postsReducerTypes.IPostListData,
  dropIndex: number,
  draggedItem: postsApiTypes.IPostListContentPostSummaries | postsApiTypes.IPostListSavedPostSummaries
) => {
  const draggedPostId = draggedItem.content_id || draggedItem.saved_post_id;
  const filteredPostList = postsList.filter((it) => ![it.content_id, it.saved_post_id].includes(draggedPostId));
  filteredPostList.splice(dropIndex, 0, draggedItem);
  return filteredPostList;
};
