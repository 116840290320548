import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import { useAccountSwitcherData } from 'utils/hooks';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, COMMON_SOCIAL_PROVIDER_TYPE, MONTH_DATE_FORMAT, TIME_ONLY_FOR_EACH_12_HOURS, USER_OWNERSHIP } from 'utils/constants';
import { calendarConvertDateBasedOnTimeZone, calendarUTCtoLocalHandler, getFormatBasedOnExactDate, getPermittedImgsBasedOnPlatform, getRequestedPostList } from 'utils/helpers';
import { CalendarIconHandler } from './CalendarIconHandler';
import { IStore, calendarApiTypes, calendarContainerTypes, commonProps } from 'types';
import { ImageValidation, Video } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';
import { InfiniteScrollCardList } from 'components';

export const CalendarViewListFilter = ({
  calendarEventItemList,
  calendarFilter,
  data
}: {
  calendarEventItemList: calendarApiTypes.ICalendarItemsResponse[];
  calendarFilter: commonProps.ICommonObj<any>;
  data: calendarContainerTypes.ICalendarDataType[];
}) => {
  const { userOwnership, optionalParams } = useAccountSwitcherData();

  const accountTimeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');

  const calendarEventItem = calendarEventItemList?.length ? getRequestedPostList(calendarEventItemList, accountTimeZone) : null;
  let dateArr: (string | Date | null)[] = [''];
  const isHubUser = USER_OWNERSHIP.FRANCHISOR === userOwnership;

  const renderFilterCard = (datum: calendarApiTypes.ICalendarItemsResponse | calendarContainerTypes.ICalendarDataType, index: number) => {
    const currentDate: string | Date | null = isHubUser
      ? calendarUTCtoLocalHandler(data[index]?.scheduledTime, MONTH_DATE_FORMAT)
      : calendarConvertDateBasedOnTimeZone(accountTimeZone, data[index]?.scheduledTime, MONTH_DATE_FORMAT, true);
    const isEnabled = dateArr.includes(currentDate) ? false : true;
    if (isEnabled) dateArr.push(currentDate);
    const contentText = datum?.dataObj?.find((it: any) => it.isEnabled);
    const imageList = getPermittedImgsBasedOnPlatform(datum.activePlatform, datum.activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? datum?.instagramImageUrls || [] : datum?.imageUrls || []);
    return optionalParams[0] !== 'events' ? (
      <div className="schedule-card-section-wrp" key={`calendar-filter-posts${index}`}>
        {isEnabled ? (
          <>
            {' '}
            {index !== 0 ? (
              <span className="clander-filter-divider">
                <hr />
              </span>
            ) : null}
            <h3>
              {' '}
              <>{currentDate}</>
            </h3>{' '}
          </>
        ) : null}
        <div className={datum.campaign ? 'cg-green' : datum.draft ? 'yellow-bg' : 'blue-bg'}>
          <Card>
            <Card.Body>
              <div className="verticalLine-blue" />
              <div className="date-time brief-cal">
                <CalendarIconHandler isBoosted={datum.isBoosted} isAwaiting={!isHubUser ? datum.draft : false} isCampaign={datum.campaign} />
                <span className="cal-time">
                  <>
                    {isHubUser
                      ? calendarUTCtoLocalHandler(datum.scheduledTime, TIME_ONLY_FOR_EACH_12_HOURS)
                      : calendarConvertDateBasedOnTimeZone(accountTimeZone, datum.scheduledTime, TIME_ONLY_FOR_EACH_12_HOURS, true)}
                  </>
                </span>
              </div>
              <div className="main-section am-media-preview pmg">
                {imageList?.length ? (
                  <div className="am-media-image">
                    <ImageValidation imgUrl={imageList[0]} customClassname="social-media-post  full-width" customName="social media post" />
                  </div>
                ) : datum?.video ? (
                  <>
                    <div className="video-outer-element">
                      <Video videoUrl={datum?.video} light={datum?.video_thumbnail_url} customClassname={'full-width'} />
                    </div>
                  </>
                ) : datum.linkPreviewPhoto ? (
                  <div className="am-media-image">
                    <img className="social-media-post  full-width" src={datum?.linkPreviewPhoto} alt="social media post" />{' '}
                  </div>
                ) : null}
                <div className="description-text">
                  {contentText?.text ? (
                    <>
                      <Paragraph
                        facebookTagList={contentText?.tag ? contentText?.tag : []}
                        customText={contentText?.personalizedText ? contentText?.personalizedText?.trim() : contentText?.text?.trim() || ''}
                        customClassname="post-message"
                        actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                      />
                    </>
                  ) : (
                    <>{datum?.linkTitle}</>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    ) : (
      <div className="schedule-card-section-wrp" key={index}>
        <>
          {' '}
          {index !== 0 ? (
            <span className="clander-filter-divider">
              <hr />
            </span>
          ) : null}
          <h3>{getFormatBasedOnExactDate(datum?.start_datetime, MONTH_DATE_FORMAT)}</h3>
        </>
        <div className="purple-bg">
          <Card>
            <Card.Body>
              <div className="verticalLine-blue" />
              <div className="date-time brief-cal">{getFormatBasedOnExactDate(datum?.start_datetime, TIME_ONLY_FOR_EACH_12_HOURS)}</div>
              <div className="main-section">
                <div className="description-text">{datum?.title}</div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="filter-item">
      <InfiniteScrollCardList<calendarContainerTypes.ICalendarDataType | calendarApiTypes.ICalendarItemsResponse>
        data={data?.length ? data.filter((it) => calendarFilter?.platform === 'all' || it.activePlatform === calendarFilter?.platform) : calendarEventItem || []}
        childComp={(data, index) => renderFilterCard(data, index)}
        scrollTarget={'filterScrollTarget'}
        itemCount={20}
        next={() => (dateArr = [])}
      />
    </div>
  );
};
