// OVERVIEW - LOCATION
export const OVERVIEW_LOCATIONS_REQUEST = 'dashboard/OVERVIEW_LOCATIONS_REQUEST';
export const OVERVIEW_LOCATIONS_RESPONSE = 'dashboard/OVERVIEW_LOCATIONS_RESPONSE';
export const OVERVIEW_LOCATIONS_REQUEST_FAIL = 'dashboard/OVERVIEW_LOCATIONS_REQUEST_FAIL';

// OVERVIEW - LOCATION DETAILS
export const OVERVIEW_SET_LOC_LIST_ARRAY = 'dashboard/OVERVIEW_SET_LOC_LIST_ARRAY';

// OVERVIEW - TOTAL METRICS
export const OVERVIEW_TOTALMETRICS_REQUEST = 'dashboard/OVERVIEW_TOTALMETRICS_REQUEST';
export const OVERVIEW_TOTALMETRICS_RESPONSE = 'dashboard/OVERVIEW_TOTALMETRICS_RESPONSE';
export const OVERVIEW_TOTALMETRICS_REQUEST_FAIL = 'dashboard/OVERVIEW_TOTALMETRICS_REQUEST_FAIL';

// OVERVIEW - RECENT POSTS LIST
export const OVERVIEW_RECENTPOSTS_REQUEST = 'dashboard/OVERVIEW_RECENTPOSTS_REQUEST';
export const OVERVIEW_RECENTPOSTS_RESPONSE = 'dashboard/OVERVIEW_RECENTPOSTS_RESPONSE';
export const OVERVIEW_RECENTPOSTS_REQUEST_FAIL = 'dashboard/OVERVIEW_RECENTPOSTS_REQUEST_FAIL';

// OVERVIEW - POST METRICS
export const OVERVIEW_POSTSMETRICS_REQUEST = 'dashboard/OVERVIEW_POSTSMETRICS_REQUEST';
export const OVERVIEW_POSTSMETRICS_RESPONSE = 'dashboard/OVERVIEW_POSTSMETRICS_RESPONSE';
export const OVERVIEW_POSTSMETRICS_REQUEST_FAIL = 'dashboard/OVERVIEW_POSTSMETRICS_REQUEST_FAIL';

// OVERVIEW - MONTHLY POSTS LIST
export const OVERVIEW_MONTHLY_POSTS_REQUEST = 'dashboard/OVERVIEW_MONTHLY_POSTS_REQUEST';
export const OVERVIEW_MONTHLY_POSTS_RESPONSE = 'dashboard/OVERVIEW_MONTHLY_POSTS_RESPONSE';
export const OVERVIEW_MONTHLY_POSTS_REQUEST_FAIL = 'dashboard/OVERVIEW_MONTHLY_POSTS_REQUEST_FAIL';

// OVERVIEW RESET
export const OVERVIEW_RESET = 'dashboard/OVERVIEW_RESET';
