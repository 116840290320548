import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TableComponent } from 'components';
import { ITableData, ITableHeader } from 'types/common/common-props';

interface IPagination {
  currentPage: number;
  totalPage: number;
  activeData: ITableData[];
}

export const InfiniteScrollTable = ({
  headers,
  data,
  tableClass,
  loader = null,
  scrollTarget
}: {
  data: ITableData[];
  headers: ITableHeader[];
  tableClass?: string;
  loader?: any;
  scrollTarget?: string;
}) => {
  const [page, setPage] = useState<IPagination>({
    currentPage: 1,
    totalPage: 1,
    activeData: []
  });

  useEffect(() => {
    setPage({
      currentPage: 1,
      totalPage: data?.length ? Math.floor(data?.length / 20) + (data?.length % 20 > 0 ? 1 : 0) : 1,
      activeData: data?.slice(0, 20) || []
    });
  }, [data]);

  return (
    <div className="resuseTable">
      <InfiniteScroll
        next={() => {
          const currentPage = page.currentPage + 1;
          setPage({
            ...page,
            currentPage,
            activeData: page.activeData.concat(data.slice((currentPage - 1) * 20, (currentPage - 1) * 20 + 20))
          });
        }}
        hasMore={page.totalPage >= page.currentPage}
        dataLength={page.activeData.length}
        loader={loader}
        className="local-ini lpx vpy-20"
        scrollableTarget={scrollTarget ? scrollTarget : 'scrollTarget'}
      >
        <TableComponent tableClassname={tableClass} tableHeaders={headers} tableData={page.activeData} />
      </InfiniteScroll>
    </div>
  );
};
