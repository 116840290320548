import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'analytics/actions/action-types';
import {
  getContentAnalyticsFailure,
  getContentAnalyticsMultipleLocationsFailure,
  getContentAnalyticsMultipleLocationsResponse,
  getContentAnalyticsResponse,
  getContentAnalyticsModelIdFailure,
  getContentAnalyticsModelIdResponse,
  contentAnalyticsIndividualImageResponse
} from 'analytics/actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { exceptionHandlerRequest, logoutRequest } from 'actions';
import { CONTENT_ANALYTICS_FAILED, CONTENT_ANALYTICS_FETCHING_LIST_FAILED } from 'analytics/utils';
import { ICAModalIDResponse } from 'analytics/types';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getContentAnalyticsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;

    if (payload.analytics === 'local_post' ? payload.provider : true) {
      const params = { ...payload };
      delete params.account;
      delete params.analytics;
      delete params.provider;
      delete params.isHubUser;
      const headers = defaultHeader();
      const path = payload.isHubUser
        ? payload.analytics !== 'local_post'
          ? API.getHubContentAnalytics.apiPath.replace('filter', payload.provider)
          : API.getLocalPostContentAnalytics.apiPath.replace('filter', payload.provider)
        : API.getContentAnalytics.apiPath.replace(':id', payload.account);
      const response = yield apiCall({ headers, params, apiPath: path, action: API.getContentAnalytics.action });

      if (response.status === 401) {
        yield put(logoutRequest());
      } else if (response.status === 200) {
        const data = {
          ...response.data,
          ...(response?.data[`content_${payload.provider}_stats`] && { post_analytics: response?.data[`content_${payload.provider}_stats`] }),
          ...(response?.data[`enduser_${payload.provider}_post_stat_rows`] && { post_analytics: response?.data[`enduser_${payload.provider}_post_stat_rows`] })
        };
        const tagsResponse = {
          ...(response?.data[`content_${payload.provider}_stats`] && { tags_list: response?.data[`content_${payload.provider}_stats`] }),
          ...(response?.data[`enduser_${payload.provider}_post_stat_rows`] && { tags_list: response?.data[`enduser_${payload.provider}_post_stat_rows`] }),
          ...(response?.data.post_analytics && { tags_list: response?.data.post_analytics })
        };
        if (payload.parent_remote_post_id || payload.parent_facebook_post_id) {
          yield put(contentAnalyticsIndividualImageResponse(response.data));
        } else {
          yield put(getContentAnalyticsResponse(payload.tag_list === 1 ? tagsResponse : data));
        }
      } else {
        yield put(getContentAnalyticsFailure(response?.data?.error || CONTENT_ANALYTICS_FETCHING_LIST_FAILED));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_ANALYTICS_FAILED));
  }
}

function* getContentAnalyticsMultipleLocationsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const params = {
      franchisor_id: payload.account,
      per: 1000,
      ...payload.by,
      include_totals: 1,
      date_range: payload.dateRange,
      order: payload.by.key === 'posted_at' ? 'sentCount' : payload.by.key || 'timestamp',
      ...(payload.locationId && { ancestor_franchisor_id: payload.locationId })
    };

    const headers = defaultHeader();
    let path = `${API.getHubMultipleLocationsContentAnalytics.apiPath.replace('filter', payload.provider)}`;
    path = path.replace(':id', payload.id);
    const response = yield apiCall({ headers, params, apiPath: path, action: API.getHubMultipleLocationsContentAnalytics.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const data = {
        ...response.data,
        ...(response?.data[`enduser_${payload.provider}_post_stat_rows`] && { post_analytics: response?.data[`enduser_${payload.provider}_post_stat_rows`] }),
        ...(response?.data[`totals`] && { totals: [response?.data[`totals`]] })
      };
      yield put(getContentAnalyticsMultipleLocationsResponse(data));
    } else {
      yield put(getContentAnalyticsMultipleLocationsFailure(response.data.error || response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_ANALYTICS_FAILED));
  }
}

function* getContentAnalyticsModelIdReq(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const { id, userId, isHubUser } = payload;

    const headers = defaultHeader();
    let path = !isHubUser ? `${API.getLocalModalDataContentAnalytics.apiPath.replace('userId', userId)}` : `${API.getHubModalDataContentAnalytics.apiPath.replace(':id', id)}`;
    path = !isHubUser ? path.replace(':id', id) : path;
    const response = yield apiCall({ headers, params: '', apiPath: path, action: API.getHubMultipleLocationsContentAnalytics.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const data: ICAModalIDResponse = {
        ...(response?.data?.content && {
          // replaces the video url to medium size url in the Obj
          obj: response?.data?.videos?.length > 0 ? { ...response?.data.content, video_url: response?.data?.videos[0]?.medium_size_url } : response?.data.content,
          link: response?.data.links,
          isHubUser: true
        }),
        ...(response?.data?.post_analytic && {
          data: {
            image: [response?.data?.post_analytic?.image_url],
            post: [response?.data?.post_analytic?.post_url],
            postId: [response?.data?.post_analytic?.remote_post_id]
          }
        })
      };
      yield put(getContentAnalyticsModelIdResponse(data));
    } else {
      yield put(getContentAnalyticsModelIdFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(CONTENT_ANALYTICS_FAILED));
  }
}

export function* takeContentAnalyticsRequest() {
  yield takeLatest(types.GET_CONTENT_ANALYTICS_REQUEST, getContentAnalyticsRequest);
  yield takeLatest(types.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_REQUEST, getContentAnalyticsMultipleLocationsRequest);
  yield takeLatest(types.GET_CONTENT_ANALYTICS_MODAL_ID_REQUEST, getContentAnalyticsModelIdReq);
}
