import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { accountApiTypes } from 'types';

// UPDATE ACCOUNT PROFILE DATA
export const accountUpdateProfileRequest = (payload: accountApiTypes.IAccountUpdateProfileReq) => createAction(actionTypes.ACCOUNTS_UPDATE_PROFILE_REQUEST, payload);
export const accountUpdateProfileResponse = (response: accountApiTypes.IAccount) => createAction(actionTypes.ACCOUNTS_UPDATE_PROFILE_RESPONSE, response);
export const accountUpdateProfileFailure = (err: null | string) => createAction(actionTypes.ACCOUNTS_UPDATE_PROFILE_FAILURE, err);

// GET ACCOUNT PROFILE DATA
export const accountGetProfileRequest = (payload: accountApiTypes.IAccountGetProfileReq) => createAction(actionTypes.ACCOUNTS_GET_PROFILE_REQUEST, payload);
export const accountGetProfileResponse = (response: accountApiTypes.IAccountOrPieReload) => createAction(actionTypes.ACCOUNTS_GET_PROFILE_RESPONSE, response);
export const accountGetProfileFailure = (err: null | string) => createAction(actionTypes.ACCOUNTS_GET_PROFILE_FAILURE, err);

// ADD ACCOUNT PROFILE
export const accountAddProfileRequest = (payload: accountApiTypes.ICreateAccountProfileReq) => createAction(actionTypes.ACCOUNTS_ADD_PROFILE_REQUEST, payload);
export const accountAddProfileResponse = (response: accountApiTypes.IAccountOrPieReload) => createAction(actionTypes.ACCOUNTS_ADD_PROFILE_RESPONSE, response);
export const accountAddProfileFailure = (err: null | string) => createAction(actionTypes.ACCOUNTS_ADD_PROFILE_FAILURE, err);

// GET FRANCHISEE PERMISSION
export const getFranchiseePermissionRequest = (payload: number) => createAction(actionTypes.GET_FRANCHISEE_PERMISSION_REQUEST, payload);
export const getFranchiseePermissionResponse = (data: accountApiTypes.IFranchiseePermissionResponse) => createAction(actionTypes.GET_FRANCHISEE_PERMISSION_RESPONSE, data);
export const getFranchiseePermissionFailure = (error: null | string) => createAction(actionTypes.GET_FRANCHISEE_PERMISSION_FAILURE, error);

// ACCOUNT ACTIVATE
export const accountActivateRequest = (payload: accountApiTypes.IActivateAccountReq) => createAction(actionTypes.ACCOUNT_ACTIVATE_REQUEST, payload);
export const accountActivateResponse = (response: accountApiTypes.IAccountOrPieReload) => createAction(actionTypes.ACCOUNT_ACTIVATE_RESPONSE, response);
export const accountActivateFailure = (error: null | string) => createAction(actionTypes.ACCOUNT_ACTIVATE_FAILURE, error);

// ACCOUNTS - GET SCHEDULE LIST
export const accountGetScheduleListRequest = (payload: accountApiTypes.IGetAccListScheduleReqPayload) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULE_LIST_REQUEST, payload);
export const accountGetScheduleListResponse = (data: accountApiTypes.ICreateAccListScheduleResponse[]) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULE_LIST_RESPONSE, data);
export const accountGetScheduleListFailure = (error: null | string) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULE_LIST_FAILURE, error);

// ACCOUNTS - CREATE OR UPDATE SCHEDULE LIST
export const accountCreateOrUpdateScheduleRequest = (payload: accountApiTypes.ICreateAccListScheduleReqPayload) => createAction(actionTypes.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_REQUEST, payload);
export const accountCreateOrUpdateScheduleResponse = (data: accountApiTypes.ICreateAccListScheduleReq) => createAction(actionTypes.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_RESPONSE, data);
export const accountCreateOrUpdateScheduleFailure = (error: null | string) => createAction(actionTypes.ACCOUNTS_CREATE_OR_UPDATE_SCHEDULE_LIST_FAILURE, error);

// ACCOUNTS - DELETE SCHEDULE LIST
export const accountDeleteScheduleRequest = (payload: accountApiTypes.IDeleteAccListScheduleReqPayload) => createAction(actionTypes.ACCOUNTS_DELETE_SCHEDULE_LIST_REQUEST, payload);
export const accountDeleteScheduleResponse = (data: { list_id: number }) => createAction(actionTypes.ACCOUNTS_DELETE_SCHEDULE_LIST_RESPONSE, data);
export const accountDeleteScheduleFailure = (error: null | string) => createAction(actionTypes.ACCOUNTS_DELETE_SCHEDULE_LIST_FAILURE, error);

// ACCOUNT - SET ACCOUNT ACTIVATE
export const setAccountActivate = (data: boolean) => createAction(actionTypes.SET_ACCOUNT_ACTIVATE, data);

export const accountError = (error: null | string) => createAction(actionTypes.ACCOUNT_ERROR, error);

// RESET ACCOUNT PROFILE
export const accountProfileMessagesReset = () => createAction(actionTypes.ACCOUNT_PROFILE_MESSAGES_RESET);
export const accountProfileReset = () => createAction(actionTypes.ACCOUNTS_PROFILE_RESET);

// ACCOUNT - CREATE SCHEDULED POST
export const accountCreateScheduledPostRequest = (payload: accountApiTypes.ICreateScheduledPostReq) => createAction(actionTypes.ACCOUNTS_CREATE_SCHEDULED_POSTS_REQUEST, payload);

// ACCOUNT - GET SCHEDULED POST STATS
export const accountGetScheduledPostStatsRequest = (payload: number) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_REQUEST, payload);
export const accountGetScheduledPostStatsResponse = (response: number) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_RESPONSE, response);
export const accountGetScheduledPostStatsFailure = (error: null | string) => createAction(actionTypes.ACCOUNTS_GET_SCHEDULED_POSTS_STATS_FAILURE, error);

// ACCOUNT - BLOCK SCHEDULE LIST
export const accountListSchedulesBlockListRequest = (payload: accountApiTypes.IBlockScheduleListReq) => createAction(actionTypes.ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_REQUEST, payload);
export const accountListSchedulesBlockListResponse = (response: number) => createAction(actionTypes.ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_RESPONSE, response);
export const accountListSchedulesBlockListFailure = (error: null | string) => createAction(actionTypes.ACCOUNTS_LIST_SCHEDULES_BLOCKLIST_FAILURE, error);

const ACCOUNTS_PROFILE = {
  accountUpdateProfileRequest,
  accountUpdateProfileResponse,
  accountUpdateProfileFailure,
  accountGetProfileRequest,
  accountGetProfileResponse,
  accountGetProfileFailure,
  accountAddProfileRequest,
  accountAddProfileResponse,
  accountAddProfileFailure,
  getFranchiseePermissionRequest,
  getFranchiseePermissionResponse,
  getFranchiseePermissionFailure,
  accountActivateRequest,
  accountActivateResponse,
  accountActivateFailure,
  setAccountActivate,
  accountGetScheduleListRequest,
  accountGetScheduleListResponse,
  accountGetScheduleListFailure,
  accountCreateOrUpdateScheduleRequest,
  accountCreateOrUpdateScheduleResponse,
  accountCreateOrUpdateScheduleFailure,
  accountError,
  accountProfileMessagesReset,
  accountCreateScheduledPostRequest,
  accountProfileReset,
  accountDeleteScheduleRequest,
  accountDeleteScheduleResponse,
  accountDeleteScheduleFailure,
  accountGetScheduledPostStatsRequest,
  accountGetScheduledPostStatsResponse,
  accountGetScheduledPostStatsFailure,
  accountListSchedulesBlockListRequest,
  accountListSchedulesBlockListResponse,
  accountListSchedulesBlockListFailure
};

export type AccountsProfileActions = ActionsUnion<typeof ACCOUNTS_PROFILE>;
