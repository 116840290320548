import { Reducer } from 'redux';

import * as types from 'analytics/actions/action-types';
import { ContentAnalyticsActions } from 'analytics/actions';
import { IContentAnalyticsReducer } from 'analytics/types';
import { CONTENT_ANALYTICS_FILTER_INITIAL_VALUE, MODAL_API_DATA, CONTENT_ANALYTICS_FAILED } from 'analytics/utils';
import { getContentAnalyticsModalData } from 'analytics/utils/helpers/content-analytics/index';
import { NOT_APPLICABLE } from 'utils/constants';

const initialState: IContentAnalyticsReducer = {
  isContentAnalyticsFetching: false,
  isError: false,
  tagsData: [],
  data: null,
  contentFilter: CONTENT_ANALYTICS_FILTER_INITIAL_VALUE,
  isAppend: false,
  isScrollFecting: false,
  actualData: [],
  multipleLocationActualData: null,
  isMultipleLocationDataFetching: false,
  isMultipleLocationDataError: false,
  modalObj: MODAL_API_DATA,
  modalData: null,
  isModalDataFetching: false,
  isModalDataError: false,
  isTagsListFetching: false,
  error: null,
  detailViewError: null,
  // multiplePostIdStates: { id: null, isEnabled: true },
  individualImageRespnose: null
};

export const contenteAnalyticsReducer: Reducer<IContentAnalyticsReducer, ContentAnalyticsActions> = (state: IContentAnalyticsReducer = initialState, action: ContentAnalyticsActions) => {
  switch (action.type) {
    case types.GET_CONTENT_ANALYTICS_REQUEST:
      return {
        ...state,
        isContentAnalyticsFetching: !state.isAppend ? true : false,
        isError: false,
        isScrollFecting: state.isAppend ? true : false,
        isTagsListFetching: true
      };
    case types.GET_CONTENT_ANALYTICS_RESPONSE:
      const data = action.payload.tags_list ? state.data : action.payload;
      let tagsData = action.payload?.post_analytics ? state.tagsData : action.payload?.tags_list;
      const actualData = action.payload.tags_list ? state.actualData : state.isAppend ? [...state.actualData, ...action.payload?.post_analytics] : [...action.payload?.post_analytics];
      if (!action.payload?.post_analytics) {
        tagsData = tagsData?.map((it: any) => {
          return { ...it, rate: it.impressions_total ? Number((((it.engagement_total || 0) / it.impressions_total) * 100).toFixed(2)) : NOT_APPLICABLE };
        });
      }
      return {
        ...state,
        isContentAnalyticsFetching: false,
        isError: false,
        modalData: null,
        data,
        tagsData,
        actualData,
        isScrollFecting: false,
        isTagsListFetching: false
      };
    case types.GET_CONTENT_ANALYTICS_FAILURE:
      return {
        ...state,
        isContentAnalyticsFetching: false,
        isTagsListFetching: false,
        isScrollFecting: false,
        isError: true,
        data: null,
        actualData: [],
        tableData: []
      };
    case types.SET_CONTENT_ANALYTICS_FILTER:
      return {
        ...state,
        contentFilter: {
          ...state.contentFilter,
          ...action.payload
        },
        isAppend: action.payload?.page && action.payload?.page > 1 ? true : false
      };
    case types.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_REQUEST:
      return {
        ...state,
        isMultipleLocationDataFetching: true,
        isMultipleLocationDataError: false
      };
    case types.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_RESPONSE:
      return {
        ...state,
        modalData: null,
        isMultipleLocationDataFetching: false,
        multipleLocationActualData: action.payload,
        isMultipleLocationDataError: false
      };
    case types.GET_CONTENT_ANALYTICS_MULTILE_LOCATION_FAILURE:
      return {
        ...state,
        isMultipleLocationDataFetching: false,
        isMultipleLocationDataError: true,
        multipleLocationActualData: null,
        multipleLocationTotal: [],
        detailViewError: CONTENT_ANALYTICS_FAILED
      };
    case types.GET_CONTENT_ANALYTICS_MODAL_ID_REQUEST:
      return {
        ...state,
        isModalDataFetching: true,
        isModalDataError: false
      };
    case types.GET_CONTENT_ANALYTICS_MODAL_ID_RESPONSE:
      const modalDataResponse = action.payload.isHubUser ? getContentAnalyticsModalData(action.payload.obj, action.payload.link) : null;
      return {
        ...state,
        modalData: modalDataResponse ? modalDataResponse : action.payload?.data,
        isModalDataFetching: false,
        isModalDataError: false
      };
    case types.GET_CONTENT_ANALYTICS_MODAL_ID_FAILURE:
      return {
        ...state,
        isModalDataFetching: false,
        isModalDataError: true,
        modalData: null,
        error: action.payload,
        detailViewError: action.payload || 'No data found'
      };
    case types.CONTENT_ANALYTICS_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    // case types.CONTENT_ANALYTICS_MULTIPOST_STATE_UPDATE:
    //   return {
    //     ...state,
    //     multiplePostIdStates: action.payload
    //   }
    case types.CONTENT_ANALYTICS_INDIVIDUAL_IMAGE_RESPONSE:
      return {
        ...state,
        individualImageRespnose: action.payload,
        isContentAnalyticsFetching: false,
        isError: false,
        isScrollFecting: false,
        isTagsListFetching: false
      };
    case types.RESET_CONTENT_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
