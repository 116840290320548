import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { AdvocacyOnboardAction } from 'actions';
import { advocacyOnboardReducerTypes } from 'types';
import { ADVOCACY_ONBOARD_PREVIEW_TEXT, ADVOCACY_ONBOARD_RE_INVITE_MESSAGE, ADVOCACY_ONBOARD_STATS_DATA_INIT_OBJ } from 'utils/constants';

const initialState: advocacyOnboardReducerTypes.IAdvocacyOnboardReducer = {
  isFetching: false,
  isStatsFetching: true,
  isEmailFetching: false,
  error: null,
  advocacyOnboardStats: ADVOCACY_ONBOARD_STATS_DATA_INIT_OBJ,
  currentPageCount: 0,
  advocacyOnboardList: [],
  activeAdvocateIds: [],
  enableResendInvitation: false,
  message: null,
  previewText: ADVOCACY_ONBOARD_PREVIEW_TEXT,
  emailPreviewShowModal: false,
  isStatsCall: true,
  editObject: null
};

const advocacyOnboardReducer: Reducer<advocacyOnboardReducerTypes.IAdvocacyOnboardReducer, AdvocacyOnboardAction> = (
  state: advocacyOnboardReducerTypes.IAdvocacyOnboardReducer = initialState,
  action: AdvocacyOnboardAction
): advocacyOnboardReducerTypes.IAdvocacyOnboardReducer => {
  switch (action.type) {
    // ADVOCACY - GET ADVOCACY ONBOARD STATS REQUEST
    case types.ADVOCACY_ONBOARD_GET_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        isStatsCall: false
        // filters: { ...state.filters, isCallStats: false }
      };
    case types.ADVOCACY_ONBOARD_GET_STATS_RESPONSE:
      return {
        ...state,
        isStatsFetching: false,
        advocacyOnboardStats: action.payload
      };
    case types.ADVOCACY_ONBOARD_GET_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // ADVOCACY - GET ADVOCACY ONBOARD LIST REQUEST
    case types.ADVOCACY_ONBOARD_GET_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        currentPageCount: action.payload.page,
        advocacyOnboardList: action.payload.page === 0 ? initialState.advocacyOnboardList : state.advocacyOnboardList
      };
    case types.ADVOCACY_ONBOARD_GET_LIST_RESPONSE:
      const onboardList = state.currentPageCount > 0 ? state.advocacyOnboardList.concat(action.payload) : action.payload;
      return {
        ...state,
        isFetching: false,
        advocacyOnboardList: onboardList
      };
    case types.ADVOCACY_ONBOARD_GET_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // ADVOCACY - GET ADVOCACY ONBOARD SINGLE RE-INVITE REQUEST
    case types.ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_RESPONSE:
      return {
        ...state,
        isEmailFetching: false,
        message: ADVOCACY_ONBOARD_RE_INVITE_MESSAGE
      };
    case types.ADVOCACY_ONBOARD_GET_SINGLE_RE_INVITE_FAILURE:
      return {
        ...state,
        isEmailFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // ADVOCACY - GET ADVOCACY ONBOARD MULTIPLE RE-INVITE REQUEST
    case types.ADVOCACY_ONBOARD_GET_MULTIPLE_RE_INVITE_REQUEST:
      return {
        ...state,
        isEmailFetching: true
      };
    // ADVOCACY ONBOARD - GET ACTIVE ADVOCATES FOR REINVITATION
    case types.ADVOCACY_ONBOARD_GET_ACTIVE_ADVOCATES_FOR_REINVITATION:
      return {
        ...state,
        activeAdvocateIds: action.payload
      };
    // ADVOCACY ONBOARD - ENABLE CREATE INVITATION TO ALL USERS
    case types.ADVOCACY_ONBOARD_ENABLE_CREATE_INVITATION_FOR_ALL_USERS:
      return {
        ...state,
        enableResendInvitation: action.payload
      };
    // ADVOCACY ONBOARD - SET SEARCH KEYWORD
    case types.ADVOCACY_ONBOARD_SET_EDIT_OBJECT:
      return {
        ...state,
        editObject: action.payload
      };
    // ADVOCACY ONBOARD SET EMAIL PREVIEW MODAL POPUP
    case types.ADVOCACY_ONBOARD_SET_EMAIL_PREVIEW_MODAL_POPUP:
      return {
        ...state,
        emailPreviewShowModal: action.payload
      };
    // ADVOCACY ONBOARD RESET MESSAGE
    case types.ADVOCACY_ONBOARD_MESSAGE_RESET:
      return {
        ...state,
        message: null,
        error: null
      };
    // ADVOCACY ONBOARD - EMAIL PREVIEW TEXT
    case types.ADVOCACY_ONBOARD_SET_PREVIEW_TEXT:
      return {
        ...state,
        previewText: {
          ...state.previewText,
          [action.payload.type]: action.payload.val
        }
      };
    // ADVOCACY ONBOARD - INITIAL PREVIEW TEXT
    case types.ADVOCACY_ONBOARD_SET_INITIAL_PREVIEW_TEXT:
      return {
        ...state,
        previewText: { ...ADVOCACY_ONBOARD_PREVIEW_TEXT }
      };
    // ADVOCACY ONBOARD - SET FILTERS
    // case types.ADVOCACY_ONBOARD_SET_FILTERS:
    //   return {
    //     ...state,
    //     filters: action.payload
    //   };
    // Set Inbox statscall
    case types.ADVOCACY_ONBOARD_SET_STATS_CALL:
      return {
        ...state,
        isStatsCall: action.payload
      };

    // ADVOCACY - ADVOCACY ONBOARD RESET ALL
    case types.ADVOCACY_ONBOARD_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};

export { advocacyOnboardReducer };
