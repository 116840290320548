import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { menuAccessApiTypes, menuAccessReducerTypes } from 'types';

// MENU ACCESSIBLE
export const menuAccessibleRequest = (payload: menuAccessApiTypes.IMenuAccessibleReqParams) => createAction(actionTypes.MENU_ACCESSIBLE_REQUEST, payload);
export const menuAccessibleResponse = (response: menuAccessApiTypes.IMenuAccessibleResponse) => createAction(actionTypes.MENU_ACCESSIBLE_RESPONSE, response);
export const menuAccessibleRequestFailure = (error: null | string) => createAction(actionTypes.MENU_ACCESSIBLE_REQUEST_FAILURE, error);

export const setMenuAccessibility = (payload: menuAccessReducerTypes.IMenuAccessInitialValue) => createAction(actionTypes.SET_MENU_ACCESSIBILITY, payload);

const MENU_ACCESSIBLITY_ACTIONS = {
  menuAccessibleRequest,
  menuAccessibleResponse,
  menuAccessibleRequestFailure,
  setMenuAccessibility
};

export type MenuAccessiblityAction = ActionsUnion<typeof MENU_ACCESSIBLITY_ACTIONS>;
