import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import {
  PLATFORM_FILTER,
  LEADER_BOARD_ANALYTICS_FILTER_INITIAL_VALUE,
  ExportCsvFileName,
  LeaderboardvalidQueryParams,
  SORTING_OPTIONS,
  DateFormatType,
  LEADERBOARD_PAGE_OPTIONS,
  REVIEW_ANALYTICS_PLATFORM_FILTER
} from 'analytics/utils';
import { CommonFilter } from 'components';
import { ALL_HUBS, BUTTON_PROPS, USER_OWNERSHIP, COMMON_VALID_QUERY_PARAMS_OBJ, CommonValidQueryParams, DONE_SUCCESS_MSG } from 'utils/constants';
import { useAvailablePlatforms, useAccountSwitcherData, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { apiExportCsvhandler, dateFormatter, exportCsvhandler, handleFilterChange } from 'analytics/utils/helpers';
import { IFiterHandleChangeData } from 'types/common/filter';
import { API, analyticsLeaderBoardCSDownloadFormatter } from 'utils/helpers';

export const LeaderBoardAnalyticsFilter = () => {
  const navigate = useNavigate();
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const { queryParams, filter, startDate, endDate, selectedHubId, selectedHubKey } = useParamsDeconstructor(LEADER_BOARD_ANALYTICS_FILTER_INITIAL_VALUE);

  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName); // for export csv
  const contentSupplierData = useSelector((state: IStore) => state.leaderBoardAnalytics.actualData?.rows);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier ?? false);

  const queryParamKeys = Object.keys(queryParams).filter((it) => !['selected_tile', 'ascending_delta', 'sort_by', 'ascending'].includes(it));
  const availablePlatform = useAvailablePlatforms(
    optionalParams[0] === 'operations'
      ? REVIEW_ANALYTICS_PLATFORM_FILTER
      : isContentSupplier
      ? PLATFORM_FILTER.filter((platform) => !['yelp', 'google', 'tiktok'].includes(platform.value))
      : PLATFORM_FILTER,
    userOwnership
  );
  const isClearFilterDisabled = availablePlatform.length === 1 ? (queryParamKeys?.length === 1 && queryParams?.platform ? true : false) : !queryParamKeys?.length;
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (data?.page !== undefined && data?.page !== null) {
      navigate(`/${userOwnership}/${id}/analytics/analytics_leaderboard${data.page ? `/${data.page}` : data.page}`, { replace: true });
    } else {
      const queryParamsObj = handleFilterChange(data, isClear, id, queryParams, availablePlatform);
      if (typeof data.locationFilterObj !== 'string') {
        if (data.locationFilterObj?.value === ALL_HUBS) {
          if (queryParams.all_hubs) delete queryParams.all_hubs;
          else queryParams.all_hubs = '1';
        } else if (data.locationFilterObj?.value) {
          delete queryParams.all_hubs;
        }
      }
      navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
    }
  };

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;

  useEffect(() => {
    if (availablePlatform.length === 1 && !queryParams?.platform) {
      const params = { platform: availablePlatform[0].value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, availablePlatform, queryParams?.platform]); // eslint-disable-line

  useParamsValidator(
    { ...CommonValidQueryParams, ...LeaderboardvalidQueryParams },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      all_hubs: ['0', '1'],
      ascending: ['0', '1'],
      ascending_delta: ['0', '1'],
      sort_by: SORTING_OPTIONS.header.map((it) => it.value),
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || '')
    }
  );

  const handleExportCsv = () => {
    if (id) {
      if (optionalParams[0] !== 'operations' && !isContentSupplier) {
        const params = {
          [selectedHubKey]: selectedHubId,
          start_date: dateFormatter(startDate || LEADER_BOARD_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.fromDate.selected, DateFormatType.API),
          end_date: dateFormatter(endDate || LEADER_BOARD_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.toDate.selected, DateFormatType.API)
        };
        const path = API.getLeaderBoardAnalytics.apiPath.replace(':id', id.toString());
        apiExportCsvhandler(path, params, ExportCsvFileName.LEADER, '', currentName, id, userOwnership);
      } else if (isContentSupplier) {
        exportCsvhandler(analyticsLeaderBoardCSDownloadFormatter, contentSupplierData, DONE_SUCCESS_MSG, ExportCsvFileName.LEADER, currentName, id, userOwnership);
      }
    }
  };

  return (
    <CommonFilter
      secondaryCheckboxCustomClass="social-survey-radio__grp"
      filter={{ ...filter, page: optionalParams.join('/') }}
      handleChange={handleChange}
      analyticsPlatformFilter={availablePlatform}
      isLocation={isHubUser && !isContentSupplier}
      primaryButtons={[
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled
        }
      ]}
      checkBoxFilter={
        !isContentSupplier
          ? [
              {
                title: 'Leaderboard Type',
                filter: isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv) ? LEADERBOARD_PAGE_OPTIONS : LEADERBOARD_PAGE_OPTIONS.slice(0, 2),
                value: 'page'
              }
            ]
          : []
      }
      handleExportCsv={handleExportCsv}
      showMonthYearPicker={isContentSupplier ? true : false}
    />
  );
};
