import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { stripeReducerTypes } from 'types';

// STRIPE - GET CUSTOMER DATA
export const stripeCustomerRequest = (payload: string | null) => createAction(actionTypes.STRIPE_GET_CUSTOMER_REQUEST, payload);
export const stripeCustomerResponse = (data: stripeReducerTypes.IStripCustomerObj) => createAction(actionTypes.STRIPE_GET_CUSTOMER_RESPONSE, data);
export const stripeCustomerResquestFail = (error: null | string) => createAction(actionTypes.STRIPE_GET_CUSTOMER_REQUEST_FAIL, error);

// STRIPE - GET CARD DATA
export const stripeCardsListRequest = (payload: string) => createAction(actionTypes.STRIPE_GET_CARDS_REQUEST, payload);
export const stripeCardsListResposne = (data: stripeReducerTypes.IStripeCardItem[]) => createAction(actionTypes.STRIPE_GET_CARDS_RESPONSE, data);
export const stripeCardsListResquestFail = (error: null | string) => createAction(actionTypes.STRIPE_GET_CARDS_REQUEST_FAIL, error);

// STRIPE CARDS RESET
export const stripeCardsListReset = () => createAction(actionTypes.STRIPE_CARDS_RESET);

// AI STRIPE - GET CUSTOMER DATA
export const aiStripeCustomerRequest = (payload: string | null) => createAction(actionTypes.AI_STRIPE_GET_CUSTOMER_REQUEST, payload);

const STRIPE_ACTIONS = {
  stripeCustomerRequest,
  stripeCustomerResponse,
  stripeCustomerResquestFail,
  stripeCardsListRequest,
  stripeCardsListResposne,
  stripeCardsListResquestFail,
  stripeCardsListReset,
  aiStripeCustomerRequest
};

export type StripeAction = ActionsUnion<typeof STRIPE_ACTIONS>;
