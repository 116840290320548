import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { commonModalPopup, IStore } from 'types';
import { ICommonDateTypes, ADD_POST_ACTION_TYPE, AP_ACTION_TYPES, ADD_SCH_POST_INVALID_DATE_MSG, RIPPLE_COLOR, SIDE_NAV_NAME } from 'utils/constants';
import { utcFormatForDateValue, getApPostActionReqPayload, getAddContentList, getApUpdatePostActionReqPayload, endDateLessThanStartDate } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useNavBarData } from 'utils/hooks';
import { CustomDatePicker } from 'widgets/DatePicker';
import { addPostUpdateSavedPostDetails, addPostSavePostActionRequest, addPostUpdateSavedPostRequest, postDetailDataReset, addPostResetAll, addPostFranResetAll, addPostActionsResetAll } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const SchedulePostModal = (props: commonModalPopup.IAddPostModalPopup) => {
  const dispatch = useDispatch();

  const { isModalShow, handleModalClose } = props;

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  const activePlatforms = useActivePlatform();

  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);

  const isDateInvalid =
    savedPostDetails?.account_schedule_date_time.selected && savedPostDetails?.schedule_date_time?.selected
      ? !endDateLessThanStartDate(savedPostDetails?.account_schedule_date_time.selected, savedPostDetails?.schedule_date_time?.selected || null)
      : false;

  const handleDateChange = (date: null | Date, type: string) => {
    const selectedDateRangeObj = { selected: date, utcFormat: utcFormatForDateValue(accountDetails?.account?.tzinfo_name, date) };
    dispatch(
      addPostUpdateSavedPostDetails(
        savedPostDetails
          ? {
              ...savedPostDetails,
              [type]: selectedDateRangeObj
            }
          : null
      )
    );
  };

  const handleAddSavedPostData = (actionType: string) => {
    if (addPostAction === AP_ACTION_TYPES.ADD) {
      const apReqPayload = getApPostActionReqPayload(id, userOwnership, savedPostDetails, selectedFBTagList, selectedMediaList, selectedTags, linkObj.linkData, actionType, instaType, [], isAiPost);
      dispatch(addPostSavePostActionRequest({ actionType, reqBody: apReqPayload }));
      handleModalClose();
    } else if (addPostAction === AP_ACTION_TYPES.EDIT) {
      handleUpdateSavedPostData(actionType);
    }
  };

  const handleUpdateSavedPostData = (actionType: string) => {
    if (id && userOwnership && savedPostDetails && editSavedPost?.id) {
      const apReqPayload = getApUpdatePostActionReqPayload(
        editSavedPost,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkObj.linkData,
        actionType,
        instaType,
        reducerTextEditorState?.facebook,
        [],
        false,
        editSavedPost?.ai_post || isAiPost
      );
      dispatch(addPostUpdateSavedPostRequest({ actionType, savedPostId: editSavedPost?.id, reqBody: apReqPayload }));
      handleModalClose();
    }
  };

  const handleResetData = () => {
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS) {
      if (!optionalParams.length) dispatch(postDetailDataReset());
      dispatch(addPostResetAll());
      dispatch(addPostFranResetAll());
      dispatch(addPostActionsResetAll());
    }
    handleModalClose();
  };

  const disableSavedPostAction = () => {
    if (savedPostDetails && !getAddContentList(savedPostDetails, activePlatforms).length && !selectedMediaList.length && !linkObj.linkData) return true;
    else if (isDateInvalid) return true;
    else return false;
  };

  const savedPostStartDate = editSavedPost?.start_date ? moment(editSavedPost?.start_date).toDate() : null;
  const savedPostEndDate = editSavedPost?.end_date ? moment(editSavedPost?.end_date).toDate() : null;
  const accountScheduleDateTime = savedPostDetails?.account_schedule_date_time.selected;
  const minDate = savedPostStartDate && accountScheduleDateTime && savedPostStartDate > accountScheduleDateTime ? savedPostStartDate : accountScheduleDateTime;
  const maxDate = savedPostEndDate;

  return (
    <Modal
      className="creator-tag-modal-wrp schedule-post-modal cscdPost"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleResetData}
      animation={false}
    >
      <Modal.Body>
        <div className="react-datepicker-popper">
          <CustomDatePicker
            selectedDate={savedPostDetails?.schedule_date_time.selected || null}
            dateRangeType={ICommonDateTypes.DATE_AND_TIME}
            onChange={(date) => handleDateChange(date, 'schedule_date_time')}
            timeInputLabel={''}
            showTimeInput
            inline
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
        {isDateInvalid && <div className="error-txt-login">{ADD_SCH_POST_INVALID_DATE_MSG}</div>}

        <div className="footer-btn-section">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleResetData}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-primary-box ${disableSavedPostAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
            <button
              className={`ac-btn ac-primary ac-block ${disableSavedPostAction() ? `disabled` : ``}`}
              onClick={() => !disableSavedPostAction() && handleAddSavedPostData(ADD_POST_ACTION_TYPE.SCHEDULE_POST)}
            >
              Schedule post
            </button>
          </CustomRippleButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
