import { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AI_IOS_SUBSCRIPTION, AI_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS, AI_SUBSCRIPTION_UPGRADE_SUCCESS, ALERT_BOX_TYPES, USER_OWNERSHIP } from 'utils/constants';
import { IStore, aiContentCreatorReducerTypes } from 'types';
import {
  aiCreateSubscriptionPlanRequest,
  aiUpdateSubscriptionPlanRequest,
  setAISubscriptionDetailsModal,
  setSelectedSubscriptionTableData,
  setSubscription,
  setSubscriptionMainPageType
} from 'actions';
import { ImageValidation } from 'widgets/Media';
import { alertBoxCall } from 'components/Common/AlertBox';
import { AICancelSusbcription } from './AICancelSusbcription';
import { PageTagInfoTooltip } from 'containers/Content/Creator/CommonModal';
import { useAccountSwitcherData } from 'utils/hooks';

export const AISubscriptionSelection = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();
  const [searchText, setSearch] = useState<string>('');
  const [cancelSubsription, setCancelSubsription] = useState<any>(null);

  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const selectedSusbcripitonActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  const availableTree = useSelector((state: IStore) => [
    ...state.accountSwitcher.franchisors.map((it) => ({ ...it, type: 'hub' })),
    ...(state.accountSwitcher.accounts?.length
      ? state.accountSwitcher.accounts.map((it) => ({ ...it, type: 'account' }))
      : state.accountSwitcher.userOwnership === USER_OWNERSHIP.ACCOUNT && state.accountSwitcher.currentAccountOrFranchisor?.id
      ? [{ id: state.accountSwitcher.currentAccountOrFranchisor.id, name: state.accountSwitcher.currentAccountOrFranchisor.name, type: 'account' }]
      : [])
  ]);
  const selectedSubscription = useSelector((state: IStore) => state.aiContentCreator.selectedSubscription);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id || 0);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const isActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );

  const setSelected = (payload: any) => {
    dispatch(setSubscription(payload));
  };

  const unSelectedTree = useMemo(() => {
    return availableTree.filter((it) => !currentSubscription.some((datum) => datum.franchisor_id === it.id || datum.account_id === it.id));
  }, [availableTree, currentSubscription]);

  const handleCancelSusbcription = () => {
    if (cancelSubsription && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: [cancelSubsription]
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            const updatedSusbcription = selectedSubscriptionTableData.filter((it) => {
              return cancelSubsription.id !== it.id;
            });
            dispatch(setSelectedSubscriptionTableData({ data: [...updatedSusbcription, cancelSubsription], isActive }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS);
          }
        })
      );
    }
  };

  const handleSubmit = (trialSubscription: aiContentCreatorReducerTypes.IAISetSusbscripiton) => {
    const paymentObject: any = {
      email: activeUserEmail || '',
      amount: subscriptionType.price,
      isAiSubscription: true,
      programDuration: subscriptionType.value
    };
    if (activeUserId) {
      dispatch(
        aiCreateSubscriptionPlanRequest({
          createParams: {
            data: [
              {
                franchisor_id: trialSubscription.franchisorId || null,
                account_id: trialSubscription.accountId || null,
                user_id: trialSubscription.userId,
                autoRenewal: false,
                isTrialEnable: true,
                ...paymentObject
              }
            ]
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            if (aiSubscriptionDetailsModal.type === 'modifySubscription') {
              alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_SUCCESS);
            }
            dispatch(setSubscriptionMainPageType('selection'));
            const type = aiSubscriptionDetailsModal?.isCoachMark ? 'AICoach' : 'susbcriptionTable';
            dispatch(setAISubscriptionDetailsModal({ type, isOpen: true }));
          }
        })
      );
    }
  };

  return (
    <Card>
      {aiSubscriptionDetailsModal.type === 'newSubscription' ? (
        <div className="ai-pay-right-head">
          <span className="lca-head">Selected</span>
          {selectedSubscription.length > 0 && <span className="lca-sel-label">({selectedSubscription.length})</span>}
        </div>
      ) : (
        <div className="ai-pay-right-head">
          <span className="lca-head">Active Locations</span>
          {selectedSubscriptionTableData.length > 0 && <span className="lca-sel-label">({selectedSubscriptionTableData.length})</span>}
        </div>
      )}
      {availableTree?.length > 1 ? (
        <div className="aips-search-input">
          <input
            type="text"
            className="npsp-search-box"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      ) : null}
      <div className="aips-hl-wraps">
        <ul className="hub-list">
          {availableTree?.length > 1 && !currentSubscription?.length ? (
            <li className="aips-select-all">
              <label className={`lc-acc-cnt ${selectedSubscription?.length === unSelectedTree?.length ? 'active' : ''}`}>
                <span className="checkbox-hover">
                  <input
                    value={'all'}
                    onChange={() => {
                      const subscriptions =
                        selectedSubscription?.length === unSelectedTree?.length
                          ? []
                          : [...unSelectedTree]?.map((it) => ({
                              franchisorId: it.type === 'hub' ? it.id : null,
                              accountId: it.type !== 'hub' ? it.id : null,
                              userId: activeUserId,
                              isChecked: true
                            }));
                      setSelected(subscriptions);
                    }}
                    type="checkbox"
                    className="option-input checkbox"
                    name="All"
                    checked={selectedSubscription?.length === unSelectedTree?.length}
                  />
                </span>
                <span className="labelText">Select All</span>
              </label>
            </li>
          ) : null}
          {availableTree?.length
            ? availableTree
                .filter((it) => (searchText ? it.name.toLowerCase().trim().includes(searchText.toLowerCase().trim()) : it))
                .filter((it) =>
                  aiSubscriptionDetailsModal.type === 'modifySubscription' ? selectedSubscriptionTableData.some((datum) => datum[it.type === 'hub' ? 'franchisor_id' : 'account_id'] === it.id) : true
                )
                .map((it, index) => {
                  const currentHub = selectedSubscription.find((datum) => datum[it.type === 'hub' ? 'franchisorId' : 'accountId'] === it.id);
                  const alreadySubscribed = currentSubscription.find((datum) => datum[it.type === 'hub' ? 'franchisor_id' : 'account_id'] === it.id);
                  const isAutoRenewal = selectedSubscriptionTableData.find((datum) => datum[it.type === 'hub' ? 'franchisor_id' : 'account_id'] === it.id)?.auto_renewal;
                  const trialEnable = aiSubscription.find((item) => {
                    return item.account_id ? item.account_id === it.id : item.franchisor_id === it.id;
                  });
                  return (
                    <li key={`${it.id}${index}`} className={`aips-options`}>
                      <label className={`lc-acc-cnt ${aiSubscriptionDetailsModal.type === 'modifySubscription' || alreadySubscribed ? 'ai-active' : currentHub?.isChecked ? 'active' : ''}`}>
                        <span className={`checkbox-hover`}>
                          <input
                            type="checkbox"
                            className="option-input checkbox"
                            name="selectedLocation"
                            onChange={() => {
                              if (!alreadySubscribed) {
                                const selectedHub = selectedSubscription.find((datum) => datum[it.type === 'hub' ? 'franchisorId' : 'accountId'] === it.id);
                                if (selectedHub) {
                                  const remainingHubLocations = selectedSubscription.filter((datum) => datum[it.type === 'hub' ? 'franchisorId' : 'accountId'] !== it.id);
                                  setSelected([...remainingHubLocations]);
                                } else {
                                  setSelected([
                                    ...selectedSubscription,
                                    {
                                      franchisorId: it.type === 'hub' ? it.id : null,
                                      accountId: it.type !== 'hub' ? it.id : null,
                                      userId: activeUserId,
                                      isChecked: true
                                    }
                                  ]);
                                }
                              }
                            }}
                            checked={currentHub?.isChecked || false}
                          />
                        </span>
                        <ImageValidation isImgValid defaultImg={it.type === 'hub' ? 'hubs-active-new' : 'loc-icon-blue'} customName="location selector option" customClassname="hub" />
                        <div className={`list-lbl cur-pointer`}>
                          <div className="lsl-counts-wrap">
                            <span className="lcs-name" title={it.name}>
                              {it.name}
                            </span>
                          </div>
                        </div>
                      </label>
                      {alreadySubscribed?.subscription_device_type === 'IOS' && aiSubscriptionDetailsModal.type !== 'newSubscription' ? (
                        <PageTagInfoTooltip tooltipText={AI_IOS_SUBSCRIPTION} icon="infor-orange" />
                      ) : (
                        aiSubscriptionDetailsModal.type !== 'newSubscription' &&
                        selectedSusbcripitonActive &&
                        isAutoRenewal && (
                          <button
                            className={`ai-unsribe`}
                            onClick={() => {
                              setCancelSubsription({ ...alreadySubscribed, auto_renewal: false });
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        )
                      )}
                      {!trialEnable && (
                        <button
                          className={`ai-unsribe`}
                          onClick={() => {
                            const trialSubscription = {
                              franchisorId: it.type === 'hub' ? it.id : null,
                              accountId: it.type !== 'hub' ? it.id : null,
                              userId: activeUserId,
                              isChecked: true
                            };
                            handleSubmit(trialSubscription);
                          }}
                        >
                          <span>Start Trial</span>
                        </button>
                      )}
                    </li>
                  );
                })
            : null}
        </ul>
        {aiSubscriptionDetailsModal.type === 'modifySubscription' && aiSubscription.length > 0 && (
          <button
            // className="gnrc-btn lined-gnr"
            className="ac-btn ac-secondary-white ac-outline upg__back--btn"
            onClick={() => {
              dispatch(setSubscriptionMainPageType('selection'));
              dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            }}
          >
            <span>Back</span>
          </button>
        )}
      </div>
      {cancelSubsription && <AICancelSusbcription showDeleteModal={Boolean(cancelSubsription)} handleModalClose={() => setCancelSubsription(null)} handleCancel={handleCancelSusbcription} />}
    </Card>
  );
};
