import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { IStore } from 'types';
import { ALERT_BOX_TYPES, CampaignsScheduleStatus, CAMPAIGN_INIT_FILTER_DATA, COMMON_PAGE_TYPE, USER_OWNERSHIP } from 'utils/constants';
import { alertBoxCall, DeleteAssetModalPopup, Loading } from 'components';
import { campaignDetailReset, campaignResponseMsgReset, campaignsDeletePostsRequest, campaignsPostListRequest, getCampaignDetailRequest, getCampaignsEventListRequest } from 'actions';
import { CampaignPostsInfinityScrollList } from './CampaignPostsInfinityScrollList';
import { CampaignCard } from './CampaignCard';
import { CampaignSchedulesTable } from './CampaignSchedulesTable';
import { getRemovePostsFromActiveCampaignSecMsg, getRemovePostsFromCampaignConfirmMsg } from 'utils/helpers';
import { PostsDetailView } from 'containers/Content/Posts/SubPages';

export const CampaignDetailView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId, currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);

  const campaignDetail = useSelector((state: IStore) => state.campaigns.campaignDetail);
  const isDetailFetching = useSelector((state: IStore) => state.campaigns.isDetailFetching);
  const campaignEventsCount = useSelector((state: IStore) => state.campaigns.campaignDetail?.campaign_events_count || 0);
  const campaignFranchisorId = useSelector((state: IStore) => state.campaigns.campaignDetail?.franchisor_id || 0);
  const campaignAccountId = useSelector((state: IStore) => state.campaigns.campaignDetail?.account_id || 0);
  const successMsg = useSelector((state: IStore) => state.campaigns.responseMsg.successMsg);
  const failureMsg = useSelector((state: IStore) => state.campaigns.responseMsg.failureMsg);
  const postSuccessMsg = useSelector((state: IStore) => state.campaigns.postResponseMsg.successMsg);
  const postFailureMsg = useSelector((state: IStore) => state.campaigns.postResponseMsg.failureMsg);
  const postCount = useSelector((state: IStore) =>
    state.campaigns.campaignDetail?.content ? state.campaigns.campaignDetail?.contents_count || 0 : state.campaigns.campaignDetail?.saved_posts_count || 0
  );
  const createPostSuccessMsg = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.addPostFranchisor.updatedMessage.contentSuccessMsg : state.addPostAccount.addPostMessage
  );
  const isPostListUpdate = useSelector((state: IStore) => state.campaigns.isPostListUpdate);

  const [selectedPostIds, setSelectedPostIds] = useState<number[]>([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const validCampaignId = optionalParams[0];
  const isViewEvents = queryParams?.schedules === '1';

  useEffect(() => {
    return () => {
      dispatch(campaignDetailReset());
    };
  }, [dispatch]);

  // Get campaign detail data
  useEffect(() => {
    if (id && userOwnership && isValidId && validCampaignId) {
      handleGetCampaignDetail();
    }
  }, [id, userOwnership, isValidId, validCampaignId, dispatch]); // eslint-disable-line

  // Get campaign post list based on filter changes
  useEffect(() => {
    if (id && userOwnership && isValidId && validCampaignId && campaignDetail?.id && !isPostListUpdate) {
      handlePostListBasedOnFilters();
    }
  }, [id, userOwnership, isValidId, validCampaignId, campaignDetail?.id, isPostListUpdate]); // eslint-disable-line

  // If the campaign post has been deleted (or) campaign event has been activated or deactivated, get updated campaign detail and event list
  useEffect(() => {
    if (successMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, successMsg);
      handleGetCampaignDetail(true);
      if (isViewEvents) handleCampaignEventsList(1);
      dispatch(campaignResponseMsgReset());
    }
    if (failureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, failureMsg);
      dispatch(campaignResponseMsgReset());
    }
  }, [successMsg, failureMsg]); // eslint-disable-line

  // If the campaign posts has been added, deleted or sorted, get updated campaign detail
  useEffect(() => {
    if (postSuccessMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, postSuccessMsg);
      handleGetCampaignDetail(true);
      dispatch(campaignResponseMsgReset());
    }
    if (postFailureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, postFailureMsg);
      dispatch(campaignResponseMsgReset());
    }
  }, [postSuccessMsg, postFailureMsg]); // eslint-disable-line

  // If new post has been created, get the latest campaign detail, campaign posts list
  useEffect(() => {
    if (createPostSuccessMsg) {
      handleCloseCreatePostModal();
      handleGetCampaignDetail(true);
      if (!isViewEvents) handlePostListBasedOnFilters();
    }
  }, [createPostSuccessMsg]); // eslint-disable-line

  const handlePostListBasedOnFilters = () => {
    if (validCampaignId) {
      const activeUserOwnership = campaignFranchisorId ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
      dispatch(
        campaignsPostListRequest({
          userOwnership: activeUserOwnership,
          campaignId: +validCampaignId
        })
      );
    }
  };

  const handleGetCampaignDetail = (isDataRetain?: boolean) => {
    if (id && userOwnership && validCampaignId) {
      dispatch(
        getCampaignDetailRequest({
          campaignId: validCampaignId,
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisorId: id } : { accountId: id }),
          ...(isDataRetain && { isDataRetain: true })
        })
      );
    }
  };

  const handleCampaignEventsList = (page: number) => {
    if (id && userOwnership && validCampaignId) {
      dispatch(
        getCampaignsEventListRequest({
          id,
          userOwnership,
          campaignId: +validCampaignId,
          page,
          ...(queryParams?.schedule_status && {
            status: queryParams?.schedule_status === CampaignsScheduleStatus.ONGOING ? 'ONGOING' : 'COMPLETED'
          })
        })
      );
    }
  };

  const handleSwitchToCampaignList = () => {
    navigate({
      pathname: `/${userOwnership}/${id}/${currentRoute}`,
      search: `?${new URLSearchParams({
        ...(queryParams?.text && { text: queryParams.text }),
        ...(queryParams?.source && { source: queryParams.source }),
        ...(queryParams?.status && { status: queryParams.status })
      }).toString()}`
    });
  };

  const handleChanges = (data: { [key: string]: string }) => {
    if (data.schedules) {
      if (data.schedules === '0') {
        delete queryParams.schedules;
      } else {
        queryParams.schedules = data.schedules;
      }
    }
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const handleCloseCreatePostModal = () => {
    delete queryParams.create_post;
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  const disableSortCampaignPosts = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && campaignFranchisorId) return true;
    else return false;
  };

  return isDetailFetching ? (
    <Loading />
  ) : campaignDetail ? (
    optionalParams?.length > 1 ? (
      <PostsDetailView
        id={campaignFranchisorId ? campaignFranchisorId : campaignAccountId}
        userOwnership={campaignFranchisorId ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT}
        postId={optionalParams[1] === COMMON_PAGE_TYPE.DETAILS ? +optionalParams[2] : +optionalParams[1]}
        tabName={optionalParams[1] === COMMON_PAGE_TYPE.DETAILS ? optionalParams[3] : optionalParams[2]}
      />
    ) : (
      // <section className="coupon-expandview-wrp camp__detailed--view indvdl-campaigns ic-dtlView campaigns-res mbl-head--margin">

      <section className="camp__detailed--view indvdl-campaigns ic-dtlView campaigns-res mbl-head--margin">
        <div className="lpx pt-20">
          {/* <div className="coupon-name-alert"> */}
          <div className="blue-trans-box r-flx r-flx-ac r-flx-je">
            {/* <div className="coupon-alert-title" /> */}
            <div onClick={handleSwitchToCampaignList}>
              {/* <div className="coupon-alert-close"> */}
              <div className="blue__trans--icon cu-pointer">
                {/* <ImageValidation isImgValid defaultImg={'coupon-alert-close'} customName="Close" /> */}
                <ImageValidation isImgValid defaultImg={'xmark'} customName="Close" />
              </div>
            </div>
          </div>
        </div>
        <div className="animate__animated animate__fadeInUp hub-coupons-wrp-section lpx vpy-20 mt-0">
          <CampaignCard key={`campaign-detail-${campaignDetail.id}`} campaignItem={campaignDetail} className={'coupon-card mt-0 r-mb0'} />
        </div>
        <div className="campaign__det--list">
          <div className="lpx">
            <div className="blue-trans-box brght-btb r-flx r-flx-ac r-flx-jb">
              {disableSortCampaignPosts() ? null : (
                <div className={`rel-head-label r-flx r-flx-ac g-20${isViewEvents || postCount < 2 ? ` pointer-events-none` : ''}`}>
                  <div className="drag__hand--icon">
                    <ImageValidation isImgValid defaultImg={'move-grab'} customName="Rearrange posts" />
                  </div>
                  <div className="label-name">
                    <span>Rearrange your posts in a sequence you want to publish</span>
                  </div>
                </div>
              )}
              {selectedPostIds?.length ? (
                <div className="scl-controls cmp-crt-post ms-auto">
                  <button className="gnrc-btn blue-gnr ac-btn remove-btn" onClick={() => setShowRemoveModal(true)}>
                    <span>Remove Post{selectedPostIds.length > 1 ? 's' : ''}</span>
                  </button>
                </div>
              ) : campaignEventsCount ? (
                <div className="scl-controls cmp-crt-post ms-auto">
                  <button className="gnrc-btn ac-btn ac-secondary ac-block" onClick={() => handleChanges(!isViewEvents ? { schedules: '1' } : { schedules: '0' })}>
                    <span>{!isViewEvents ? 'View Schedules' : 'View Post'}</span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {isViewEvents ? (
            <CampaignSchedulesTable handleCampaignEventsList={handleCampaignEventsList} />
          ) : (
            <div className="cp-isl-wrapper">
              <CampaignPostsInfinityScrollList selectedPostIds={selectedPostIds} setSelectedPostIds={setSelectedPostIds} disableSortCampaignPosts={disableSortCampaignPosts} />
            </div>
          )}
        </div>
        <DeleteAssetModalPopup
          showModal={showRemoveModal}
          handleModalClose={() => setShowRemoveModal(false)}
          message={getRemovePostsFromCampaignConfirmMsg(campaignDetail?.status, selectedPostIds.length)}
          secondaryMessage={campaignDetail?.status === 'ACTIVE' ? getRemovePostsFromActiveCampaignSecMsg(selectedPostIds.length) : ''}
          handleDelete={() => {
            if (campaignDetail?.id) {
              const activeUserOwnership = campaignFranchisorId ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
              dispatch(campaignsDeletePostsRequest({ campaignId: campaignDetail.id, userOwnership: activeUserOwnership, postIds: selectedPostIds }));
              setSelectedPostIds([]);
              setShowRemoveModal(false);
            }
          }}
          okButtonText="Remove"
        />
      </section>
    )
  ) : null;
};
