import { useRef, useEffect } from 'react';

import { compileTimeEnv, config } from 'config';
import { alertBoxCall } from 'components';
import { ALERT_BOX_TYPES, INBOX_COMMENTS_DELAY_SECONDS } from 'utils/constants';
import { refreshCacheAndReload } from 'utils/helpers/external-script';

export const SourceCodeChecker = () => {
  const version = useRef<string>('');
  const latestCodeChecker = useRef<NodeJS.Timer | null>(null);
  const interval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (compileTimeEnv.REACT_APP_BUILD_TYPE !== 'LOCAL_DEV' && compileTimeEnv.REACT_APP_BUILD_TYPE !== 'DEV') {
      if (!latestCodeChecker.current) {
        latestCodeChecker.current = setInterval(async () => {
          try {
            const response = await fetch(`${config.siteUrl}manifest_stage.json`);
            if (response.body) {
              const reader = response.body.getReader();
              const data = await reader.read();
              const textDecoder = new TextDecoder();
              const decodedData = JSON.parse(textDecoder.decode(data.value));
              if (version.current === '') {
                version.current = decodedData.version;
              } else if (version.current !== decodedData.version) {
                version.current = decodedData.version;
                alertBoxCall(ALERT_BOX_TYPES.INFO, 'Updates Available, Page reload automatically to see new changes.');
                interval.current = setInterval(() => refreshCacheAndReload(), INBOX_COMMENTS_DELAY_SECONDS);
              }
            }
          } catch (error) {
            console.info('error occured on source code checker.', JSON.stringify(error));
          }
        }, config.sourceCodeCheckerTimer);
      }
    }
    return () => {
      if (latestCodeChecker.current) {
        clearInterval(latestCodeChecker.current);
      }
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  return null;
};
