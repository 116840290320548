import { accountContainerTypes } from 'types';
import { CampaignDurationType } from '../campaigns';
import { BusinessWeekDays } from '../directory-listing';

export const ANTE_MERIDIAN = 'AM';
export const POST_MERIDIAN = 'PM';

export const ACCOUNT_LIST_SCHEDULE_INITIAL_VALUES: accountContainerTypes.IAccListInitialValues = {
  account_list_id: 0,
  day_of_week: 0,
  hour: '00',
  minute: '00',
  start_date: null,
  end_date: null,
  vertical_id: null,
  duration_type: CampaignDurationType.ONGOING,
  meridian: ANTE_MERIDIAN,
  id: null,
  once_date: null
};

export const ACCOUNT_LIST_SCHEDULE_TAB_FIELDS: Record<string, keyof accountContainerTypes.IAccListInitialValues> = {
  ACCOUNT_LIST_ID: 'account_list_id',
  DAY_OF_WEEK: 'day_of_week',
  HOUR: 'hour',
  MINUTE: 'minute',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  ONCE_DATE: 'once_date',
  VERTICAL_ID: 'vertical_id',
  DURATION_TYPE: 'duration_type',
  MERIDIAN: 'meridian'
};

export const ACCOUNT_SCHEDULE_DURATION_TYPE = [
  { inputType: 'radio', value: CampaignDurationType.ONGOING, label: 'Ongoing' },
  { inputType: 'radio', value: CampaignDurationType.ONCE, label: 'Once' },
  { inputType: 'radio', value: CampaignDurationType.END_DATE, label: 'Set Start and End Date' }
];

export const SCHEDULER_DAY_SLOT_OPTIONS = [
  { label: BusinessWeekDays.SUNDAY, value: 0 },
  { label: BusinessWeekDays.MONDAY, value: 1 },
  { label: BusinessWeekDays.TUESDAY, value: 2 },
  { label: BusinessWeekDays.WEDNESDAY, value: 3 },
  { label: BusinessWeekDays.THRUSDAY, value: 4 },
  { label: BusinessWeekDays.FRIDAY, value: 5 },
  { label: BusinessWeekDays.SATURDAY, value: 6 }
];
