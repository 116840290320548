import { settingsAIPlayBookReducerTypes } from 'types';

export const settingsAIPlayBookVerticalGetList = (promotionList: settingsAIPlayBookReducerTypes.IAIPlayBookVerticals[]): settingsAIPlayBookReducerTypes.IVerticalsReducerTypes[] => {
  if (promotionList) {
    const noValueOption = { value: 'none', id: null, label: 'None' };
    const promotionVaultList = promotionList.reduce((acc: settingsAIPlayBookReducerTypes.IVerticalsReducerTypes[], curr) => {
      const vaultObj = {
        value: curr.select_label_name,
        label: curr.name,
        id: curr.id
      };
      acc = [...acc, vaultObj];
      return acc;
    }, []);
    return [noValueOption, ...promotionVaultList];
  } else return [];
};
