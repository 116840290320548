import { useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Spinner } from 'react-bootstrap';

import { FB_REQUIRED_FIELDS, FB_SCOPE, RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { IStore } from 'types';

interface IProps {
  label: string;
  handler: (fbResponse: any | null) => void;
  stop: boolean;
  isFetching: boolean;
}

export const FacebookButton = ({ label, handler, stop, isFetching }: IProps) => {
  const renderLabel = (spinnerVariant: string, label: string) => (stop ? label : isFetching ? <Spinner animation="border" variant={spinnerVariant} /> : label);

  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);

  return (
    <FacebookLogin
      appId={fbApplicationId}
      autoLoad={false}
      autoLogAppEvents={false}
      cookie={false}
      disabled
      fields={FB_REQUIRED_FIELDS}
      callback={handler}
      scope={FB_SCOPE}
      render={(renderProps: any) => (
        <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
          <button className="ac-btn ac-primary h-default r-flx r-flx-jc r-flx-ac ac-block fb__disconnect--btn" onClick={renderProps.onClick}>
            {renderLabel('light', label)}
          </button>
        </CustomRippleButton>
      )}
    />
  );
};
