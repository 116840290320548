import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { commonEnvConfigTypes, IStore } from 'types';

export let compileTimeEnv: commonEnvConfigTypes.IEnvironmentStore;
let cdnImgUrl: string | undefined;
let siteUrl: string | undefined;

export const configCDNImgUrlToBrand = (brandConfig: any) => {
  if (brandConfig?.cdn_url) config.cdnImgUrl = `${brandConfig?.cdn_url}/img/${brandConfig?.img_title}/`;
  else if (new URL(window.location as any)?.hostname === 'social.renderseo.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_RENDERSEO;
  } else if (new URL(window.location as any)?.hostname === 'reputation.avxdigital.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_ADVANTIX;
  } else if (new URL(window.location as any)?.hostname === 'app.mylocalcmo.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_MYLOCALCMO;
  } else if (new URL(window.location as any)?.hostname === 'app.painlessposting.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_PAINLESSPOSTING;
  } else if (new URL(window.location as any)?.hostname === 'reputation.digitalkairoi.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_DIGITALKAIROI;
  } else if (new URL(window.location as any)?.hostname === 'social.sparqmarketing.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_SPARQ;
  } else if (new URL(window.location as any)?.hostname === 'brightfully.app') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_BRIGHTPINKAGENCY;
  } else if (new URL(window.location as any)?.hostname === 'social.thetravelnetworkgroup.co.uk') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_SOCIALCENTRAL;
  } else if (new URL(window.location as any)?.hostname === 'social.seniornicity.com') {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL_SENIORNICITY;
  } else {
    config.cdnImgUrl = compileTimeEnv.REACT_APP_CDN_IMG_URL;
  }
};

try {
  compileTimeEnv = process.env as commonEnvConfigTypes.IEnvironmentStore;
  const cdnAppData = () => {
    const hostName = new URL(window.location as any)?.hostname;
    switch (hostName) {
      case 'social.renderseo.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_RENDERSEO, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_RENDERSEO };
      case 'reputation.avxdigital.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_ADVANTIX, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_ADVANTIX };
      case 'app.mylocalcmo.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_MYLOCALCMO, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_MYLOCALCMO };
      case 'app.painlessposting.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_PAINLESSPOSTING, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_PAINLESSPOSTING };
      case 'reputation.digitalkairoi.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_DIGITALKAIROI, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_DIGITALKAIROI };
      case 'social.sparqmarketing.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_SPARQ, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_SPARQ };
      case 'brightfully.app':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_BRIGHTPINKAGENCY, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_BRIGHTPINKAGENCY };
      case 'social.thetravelnetworkgroup.co.uk':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_SOCIALCENTRAL, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_SOCIALCENTRAL };
      case 'social.seniornicity.com':
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL_SENIORNICITY, siteUrl: compileTimeEnv.REACT_APP_SITE_URL_SOCIALCENTRAL };
      default:
        return { cdnImgUrl: compileTimeEnv.REACT_APP_CDN_IMG_URL, siteUrl: compileTimeEnv.REACT_APP_SITE_URL };
    }
  };
  const cdn = cdnAppData();
  cdnImgUrl = cdn.cdnImgUrl;
  siteUrl = cdn.siteUrl;
} catch (error) {
  compileTimeEnv = {};
  // tslint:disable-next-line no-console
  console.log('`process.env` is not defined. Compile-time environment will be empty.');
}

export const config: any = {
  // apiBaseURL: compileTimeEnv.REACT_APP_LOCAL_API_BASE_URL,
  apiBaseURL: compileTimeEnv.REACT_APP_API_BASE_URL,
  apiVersionPath: compileTimeEnv.REACT_APP_API_VERSION_PATH,
  apiNodeBaseURL: compileTimeEnv.REACT_APP_API_NODE_BASE_URL,
  apiRevvBaseURL: compileTimeEnv.REACT_APP_API_REVV_BASE_URL,
  apiRevvVersionPath: compileTimeEnv.REACT_APP_API_REVV_VERSION_PATH,
  ssoRevvToken: compileTimeEnv.REACT_APP_REVV_SSO_AUTH_TOKEN,
  revvSiteUrl: compileTimeEnv.REACT_APP_REVV_SITE_URL,
  siteUrl,
  cdnURL: compileTimeEnv.REACT_APP_CDN_SITE_URL,
  cdnImgUrl,
  cdnRevvImgUrl: compileTimeEnv.REACT_APP_CDN_REVV_IMG_URL,
  appVersion: compileTimeEnv.REACT_APP_APPVERSION,
  cryptoJs: compileTimeEnv.REACT_APP_CRYPTOJS,
  encryptionKey: compileTimeEnv.REACT_APP_ENCRYPTION_KEY,
  resetPasswordQueryStringKey: compileTimeEnv.REACT_APP_RESET_PASSWORD_QUERY_STRINGKEY,
  sourceCodeCheckerTimer: compileTimeEnv.REACT_APP_SOURCE_CODE_CHECKER_TIMER,
  mapBoxGeocoder: {
    apiBaseURL: compileTimeEnv.REACT_APP_MAP_BOX_API_BASE_URL,
    apiVersionPath: compileTimeEnv.REACT_APP_MAP_BOX_API_VERSION_PATH
  },
  mapBoxToken: compileTimeEnv.REACT_APP_MAP_BOX_TOKEN,
  dashboardSvgUrl: compileTimeEnv.REACT_APP_DASHBOARD_SVG_URL,
  environment: compileTimeEnv.REACT_APP_BUILD_TYPE,
  promotion: {
    apiUrl: compileTimeEnv.REACT_APP_PROMOTION_API_URL,
    apiVersionPath: compileTimeEnv.REACT_APP_PROMOTION_API_VERSION_PATH,
    promotionVaultToken: ''
  },
  stripe: {
    apiUrl: compileTimeEnv.REACT_APP_STRIPE_API_URL,
    apiVersionPath: compileTimeEnv.REACT_APP_STRIPE_API_VERSION_PATH,
    publishKey: '',
    secretKey: ''
  },
  googleAnalytics: {
    id: compileTimeEnv.REACT_APP_GOOGLE_ANALYTICS_ID
  },
  cloudinary: {
    baseUrl: compileTimeEnv.REACT_APP_CLOUDINARY_BASE_URL,
    name: compileTimeEnv.REACT_APP_CLOUDINARY_NAME,
    apiKey: '',
    apiSecret: ''
  },
  facebook: {
    applicationId: ''
  },
  google: {
    secretKey: '',
    clientId: ''
  },
  imgLy: {
    license: ''
  },
  aws: {
    accessKey: '',
    secretAccessKey: '',
    region: '',
    identityCredentials: ''
  },
  revvaws: {
    accessKey: '',
    secretAccessKey: '',
    region: '',
    bucketName: ''
  },
  microsoftClientId: {
    clientId: compileTimeEnv.REACT_APP_MICROSOFT_CLIENT_ID
  },
  oauthHeaders: {
    xApplicationId: compileTimeEnv.REACT_APP_X_APPLICATION_ID,
    xApplicationSecret: compileTimeEnv.REACT_APP_X_APPLICATION_SECRET,
    apiVersionPath: compileTimeEnv.REACT_APP_OUTH_API_VERSION_PATH
  },
  ssoIdentifiersForAccount: compileTimeEnv.REACT_APP_SSO_IDENTIFIERS_FOR_ACCOUNT_WIZARDS
};

export const EnvConfigs = () => {
  const envConfigs = useSelector((state: IStore) => state.common.decryptedEnvData);

  useEffect(() => {
    if (envConfigs) {
      config.cryptoJs = envConfigs.cryptojs_secret_key;
      config.mapBoxToken = envConfigs.map_box_token;
      config.stripe.publishKey = envConfigs.stripe_publish_key;
      config.facebook.applicationId = envConfigs.fb_application_id;
      config.imgLy.license = JSON.stringify(envConfigs.imgly_license);
    }
  }, [envConfigs]);

  return null;
};
