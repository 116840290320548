import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { POSTS_INIT_FILTER_DATA } from 'utils/constants';
import { debounceMethod } from 'utils/helpers';
import { useParamsDeconstructor } from 'utils/hooks';

export const RenderPublishStatusSearch = () => {
  const navigate = useNavigate();

  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  const [searchText, setSearchText] = useState(queryParams?.location || '');

  const conListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  const handleSearchChange = (search: string) => {
    if (search) queryParams.location = search;
    else delete queryParams.location;
    navigate({ search: `?${new URLSearchParams(queryParams).toString()}` });
  };

  return (
    <div className="search-tk">
      <div className="react-tags">
        <div className="react-tags__search">
          <div className="react-tags__search-input">
            <input
              type="text"
              placeholder="Search"
              autoComplete="off"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyUp={() => {
                conListApiTimeout.current = debounceMethod(searchText.trim(), conListApiTimeout.current, handleSearchChange);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
