import { Reducer } from 'redux';

import produce from 'immer';
import { IClients } from 'revv/types';
import { ClientsAction } from 'revv/actions';
import * as types from 'revv/actions/action-types';

const initialState: IClients = {
  isFetching: false,
  errorMessage: '',
  clientsDetailedViewResponse: null,
  currentPage: 1,
  response: null
};

export const clientsReducer: Reducer<IClients, ClientsAction> = (prevState: IClients = initialState, action: ClientsAction) => {
  switch (action.type) {
    case types.CLIENTS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.errorMessage = '';
        nextState.isFetching = true;
        nextState.currentPage = action.payload.pageNumber;
      });
    case types.CLIENTS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.errorMessage = action.payload;
        nextState.isFetching = false;
      });
    case types.CLIENTS_RESPONSE:
      return produce(prevState, (nextState) => {
        if (prevState.currentPage > 1) {
          if (prevState.response && prevState.response?.length > 0) {
            nextState.response = prevState.response.concat(action.payload);
          }
        } else {
          nextState.response = action.payload;
        }
        nextState.isFetching = false;
      });
    case types.CLIENTS_DETAILED_VIEW_REQUEST:
      return produce(prevState, (nextState) => {
        if (!action.payload.isLogin) {
          nextState.isFetching = true;
        }
      });
    case types.CLIENTS_DETAILED_VIEW_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.errorMessage = action.payload;
        nextState.isFetching = false;
      });
    case types.CLIENTS_DETAILED_VIEW_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.clientsDetailedViewResponse = action.payload;
        nextState.isFetching = false;
      });
    default:
      return prevState;
  }
};
