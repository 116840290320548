import { settingsAIPlayBookContainerTypes } from 'types/';

export const AI_PLAYBOOK_TAB_FIELDS: settingsAIPlayBookContainerTypes.IAIPlayBookTabFields = {
  BUSINESS_URL: 'business_url',
  NAME: 'name',
  BUSINESS_DESCRIPTION: 'business_description',
  VERTICAL_ID: 'vertical_id',
  QUESTIONS: 'questions',
  CUSTOMER_PERSONA_USAGE_LEVEL: 'customer_persona_usage_level',
  AI_COMPLIANCE_DESCRIPTION: 'ai_compliance_description',
  AI_AUTO_GENERATE_POST: 'auto_generate_post'
};

export const AI_PLAYBOOK_TAB_LABELS = {
  CUSTOMER_ALIAS: 'Hotels call them guests, doctors call them patients, ... What about you?',
  BUSINESS_SEARCH: `What would someone type when they search for you? "Best Mexican food", "Haircut for children" etc.`,
  BRAND_DESCRIPTION: 'What else can you share about your business that makes it unique?'
};

export const SETTINGS_AI_PLAYBOOK_CHECKFILEDS = {
  [AI_PLAYBOOK_TAB_FIELDS.BUSINESS_URL]: false,
  [AI_PLAYBOOK_TAB_FIELDS.NAME]: false,
  [AI_PLAYBOOK_TAB_FIELDS.VERTICAL_ID]: false,
  [AI_PLAYBOOK_TAB_FIELDS.BUSINESS_DESCRIPTION]: false,
  [AI_PLAYBOOK_TAB_FIELDS.CUSTOMER_PERSONA_USAGE_LEVEL]: false
};

export const SETTINGS_AI_PLAYBOOK_QUESTIONPARAMS = {
  CUSTOMER_ALIAS: 'customer_alias',
  BUSINESS_SEARCH: 'business_search'
};

export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};
export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
};

export const SETTINGS_CUSTOMER_PERSONA_USAGE_LEVELS = [
  { label: 'Subtle', value: 'subtle' },
  { label: 'Default', value: 'balanced' },
  { label: 'Focused', value: 'focused' }
];

export const AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY = [
  { label: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS.WEEKLY, value: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES.WEEKLY },
  { label: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_LABELS.MONTHLY, value: AI_PLAYBOOK_ACTIVITY_GOALS_FREQUENCY_VALUES.MONTHLY }
];

export const AI_PLAYBOOK_ACTIVITY_GOALS_TAB_FIELDS: Record<string, keyof settingsAIPlayBookContainerTypes.IActivityGoalsInitialValues> = {
  ACTIVITY_NAME: 'activity_name',
  FREQUENCY: 'frequency', // value should on weekly and monthly
  GOAL_COUNT: 'goal_count',
  FRANCHISOR_ID: 'franchisor_id',
  ACTIVITY_GOAL_TYPE_ID: 'activity_goal_type_id',
  ACCOUNT_IDS: 'account_ids'
};

export const AI_PLAYBOOK_DOCS_TYPE = {
  GENERAL: 'general',
  COMPLIANCE: 'compliance'
};
