import * as types from 'analytics/actions/action-types';
import { OverviewAnalyticsActions } from 'analytics/actions/modules/overview-analtyics';
import { IOverviewAnalyticsReducer } from 'analytics/types';
import { OVERVIEW_PIE_LEGEND, OVERVIEW_PLATFORM_FILTER, OVERVIEW_TABLE_HEADER, tableDateGrouping, OverviewTableValue, OverviewTableTotalValueDelta } from 'analytics/utils';

const initialState: IOverviewAnalyticsReducer = {
  isOverviewAnalyticsFetching: false,
  isOverviewAnalyticsSummaryFetching: false,
  isError: false,
  data: null,
  summary: {},
  groupedTableData: { headers: [], data: [], summary: {} },
  overallTable: []
};

export const overviewReducer = (state: IOverviewAnalyticsReducer = initialState, action: OverviewAnalyticsActions) => {
  switch (action.type) {
    case types.GET_OVERVIEW_ANALYTICS_REQUEST:
      return {
        ...state,
        isOverviewAnalyticsFetching: true,
        isError: false
      };
    case types.GET_OVERVIEW_ANALYTICS_RESPONSE:
      const groupedTableData = tableDateGrouping(action.payload?.analytics_table_by_date || [], OVERVIEW_TABLE_HEADER, OVERVIEW_PLATFORM_FILTER, false);
      return {
        ...state,
        isOverviewAnalyticsFetching: false,
        data: action.payload?.analytics_table_by_date || [],
        groupedTableData
      };
    case types.GET_OVERVIEW_ANALYTICS_FAILURE:
      return {
        ...state,
        isOverviewAnalyticsFetching: false,
        isError: true,
        groupedTableData: { headers: [], data: [], summary: {} }
      };
    case types.GET_OVERVIEW_ANALYTICS_SUMMARY_REQUEST:
      return {
        ...state,
        isOverviewAnalyticsSummaryFetching: true,
        isError: false
      };
    case types.GET_OVERVIEW_ANALYTICS_SUMMARY_RESPONSE:
      const overallTable = [...OVERVIEW_PIE_LEGEND].map((it) => {
        return {
          ...it,
          [OverviewTableValue.FOLLOWERS]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_followers`] : 0,
          [OverviewTableValue.POST_ENGAGEMENT]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_engagement`] : 0,
          [OverviewTableValue.PUBLISHED_POSTS]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_posts`] : 0,
          [OverviewTableTotalValueDelta.FOLLOWERS]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_followers_delta`] : 0,
          [OverviewTableTotalValueDelta.POST_ENGAGEMENT]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_engagement_delta`] : 0,
          [OverviewTableTotalValueDelta.PUBLISHED_POSTS]: action.payload?.analytics_summary ? action.payload?.analytics_summary[`${it.value}_posts_delta`] : 0
        };
      });
      return {
        ...state,
        isOverviewAnalyticsSummaryFetching: false,
        summary: action.payload?.analytics_summary,
        overallTable
      };
    case types.GET_OVERVIEW_ANALYTICS_SUMMARY_FAILURE:
      return {
        ...state,
        isOverviewAnalyticsSummaryFetching: false,
        isError: true,
        summary: {},
        overallTable: []
      };
    case types.RESET_OVERVIEW_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
