export const LOCATIONS_METRICS_REQUEST = 'locations/LOCATIONS_METRICS_REQUEST';
export const LOCATIONS_METRICS_FAILURE = 'locations/LOCATIONS_METRICS_FAILURE';
export const LOCATIONS_METRICS_RESPONSE = 'locations/LOCATIONS_METRICS_RESPONSE';

export const LOCATIONS_LIST_REQUEST = 'locations/LOCATIONS_LIST_REQUEST';
export const LOCATIONS_LIST_FAILURE = 'locations/LOCATIONS_LIST_FAILURE';
export const LOCATIONS_LIST_RESPONSE = 'locations/LOCATIONS_LIST_RESPONSE';

export const LOCATION_DELETE_REQUEST = 'locations/LOCATION_DELETE_REQUEST';
export const LOCATION_DELETE_FAILURE = 'locations/LOCATION_DELETE_FAILURE';
export const LOCATION_DELETE_RESPONSE = 'locations/LOCATION_DELETE_RESPONSE';

export const LOCATION_CREATE_REQUEST = 'locations/LOCATION_CREATE_REQUEST';
export const LOCATION_CREATE_FAILURE = 'locations/LOCATION_CREATE_FAILURE';
export const LOCATION_CREATE_RESPONSE = 'locations/LOCATION_CREATE_RESPONSE';

export const LOCATION_UDPATE_REQUEST = 'locations/LOCATION_UDPATE_REQUEST';
export const LOCATION_UPDATE_FAILURE = 'locations/LOCATION_UPDATE_FAILURE';
export const LOCATION_UPDATE_RESPONSE = 'locations/LOCATION_UPDATE_RESPONSE';

export const LOCATION_DETAIL_REQUEST = 'locations/LOCATION_DETAIL_REQUEST';
export const LOCATION_DETAIL_FAILURE = 'locations/LOCATION_DETAIL_FAILURE';
export const LOCATION_DETAIL_RESPONSE = 'locations/LOCATION_DETAIL_RESPONSE';

export const LOCATION_UPLOAD_REQUEST = 'locations/LOCATION_UPLOAD_REQUEST';
export const LOCATION_UPLOAD_FAILURE = 'locations/LOCATION_UPLOAD_FAILURE';
export const LOCATION_UPLOAD_RESPONSE = 'locations/LOCATION_UPLOAD_RESPONSE';

export const LOCATION_RESET_UPLOAD_SUCCESS_MESSAGE = 'locations/LOCATION_RESET_UPLOAD_SUCCESS_MESSAGE';

export const UPDATE_LOCATION_TAGS = 'locations/UPDATE_LOCATION_TAGS';
