import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { CommonFilter } from 'components';
import { activeFilterOptions, onChangeLocation, resetPageState } from 'revv/actions';
import { IFiterHandleChangeData } from 'types/common/filter';
import { BUTTON_PROPS, FeedValue, USER_OWNERSHIP } from 'utils/constants';
import { weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { handleFilterChange } from 'analytics/utils';
import { IStore } from 'types';
import { nonPspLeaderboardTableValues } from 'revv/utils';
import { handleDownload } from 'revv/utils/helpers/common';
import { IHeading, ILocationsListResponse, ISurveyMetricsResponse, IUserListResponse, IUsersMetricsResponse } from 'revv/types';

export const RevvLocationsEmployeeFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();

  const locations = useSelector((state: IStore) => state.revv.filter.hubLocation);
  const selectedLocation = useSelector((state: IStore) => state.revv.filter.selectedLocation);
  const defaultSelectedLocation = useSelector((state: IStore) => state.revv.filter.defaultSelectedLocation);
  const locationLeaderboardData = useSelector((state: IStore) => state.revv.locations.locationsListResponse);
  const locationTotalsData = useSelector((state: IStore) => state.revv.surveys.surveyMetrics);
  const isSendSurveyFetching = useSelector((state: IStore) => state.revv.surveys.sendSurveyMsg.isFetching);
  const teamsTotalsData = useSelector((state: IStore) => state.revv.user.userMetrics);
  const teamsLeaderboardData = useSelector((state: IStore) => state.revv.user.usersListResponse);

  const { queryParams, filter: basicQueryFilter } = useParamsDeconstructor({
    dateRange: weekOrMonthBeforeTodayFilter(FeedValue.INBOX)
  });

  useEffect(() => {
    return () => {
      if (selectedLocation.length) {
        dispatch(resetPageState('leaderboard'));
      }
    };
  }, [selectedLocation, dispatch]);

  const isFilterDisabled = useMemo(() => {
    if (Object.keys(queryParams).length || selectedLocation.length) {
      return false;
    } else {
      return true;
    }
  }, [queryParams, selectedLocation]);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (data.page !== null && data.page !== undefined) {
      navigate(`/${userOwnership}/${id}/analytics/analytics_leaderboard${data.page ? `/${data.page}` : data.page}`, { replace: true });
    } else {
      if (data.multipleLocationList) {
        dispatch(
          onChangeLocation({
            selectedLocations: data.multipleLocationList,
            highlightHubIdsGroup: {},
            highlightedLocations: [],
            selectedFilterMode: [],
            selectedHubGroup: {},
            validHubs: {},
            validLocations: defaultSelectedLocation
          })
        );
      }
      if (isClear) {
        if (selectedLocation.length) {
          dispatch(resetPageState('leaderboard'));
        }
      }
      const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    }
  };
  return (
    <CommonFilter
      secondaryCheckboxCustomClass="survey-radio__grp"
      sectionVariant="revv-leaderboard__filter"
      filter={{ ...basicQueryFilter, locations, selectedLocation, page: optionalParams.join('/') }}
      handleChange={handleChange}
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR && currentRoute === 'revv/locations'}
      isRevvLocation
      handleExportCsv={() => {
        if (currentRoute === 'revv/employees') {
          const tableHeading = nonPspLeaderboardTableValues.filter((it) => it.value !== 'score');
          const totalKey = tableHeading.map((it) => it.teamsTotalDataKey);
          const dataKey = tableHeading.map((it) => it.tableDataKey);
          if (teamsLeaderboardData && teamsTotalsData && teamsLeaderboardData.length > 0) {
            handleDownload<IUsersMetricsResponse, IUserListResponse, IHeading>(
              teamsTotalsData,
              teamsLeaderboardData,
              'Leaderboard_employees.csv',
              tableHeading,
              totalKey,
              dataKey,
              teamsLeaderboardData.length
            );
          }
        } else {
          const tableHeading = nonPspLeaderboardTableValues.filter((it) => it.value !== 'score' && it.value !== 'customerCount');
          const totalKey = tableHeading.map((it) => it.locationTotalDataKey);
          const dataKey = tableHeading.map((it) => it.tableDataKey);
          if (locationLeaderboardData && locationTotalsData && locationLeaderboardData.length > 0) {
            handleDownload<ISurveyMetricsResponse, ILocationsListResponse, IHeading>(
              locationTotalsData,
              locationLeaderboardData,
              'Leaderboard_locations.csv',
              tableHeading,
              totalKey,
              dataKey,
              locationLeaderboardData.length
            );
          }
        }
      }}
      primaryButtons={[
        BUTTON_PROPS.PRIMARY_EXPORT_CSV,
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isFilterDisabled
        }
      ]}
      filterActionButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_BUTTON,
          label: 'Send Survey',
          parentVariant: 'filter-item connection-report border-0',
          value: 'sendsurvey',
          onClick: () => {
            dispatch(activeFilterOptions('sendsurvey'));
          },
          isFetching: isSendSurveyFetching
        }
      ]}
    />
  );
};
