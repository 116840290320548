import { Form, Pagination } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

// import 'assets/stylesheets/components/custom-pagination.scss';

interface ICustomPagination {
  currentPage: number;
  totalPage: number;
  classVariant?: string;
  isEditable?: boolean;
  setCurrentPage: (pageNum: number) => void;
}

export const CustomPagination = ({ currentPage, totalPage, classVariant, isEditable, setCurrentPage }: ICustomPagination) => {
  return (
    <Pagination className={`justify-content-center db-pages ${classVariant}`}>
      <div className="tbl-paginations d-flex justify-content-between p-2 align-items-center">
        <ChevronLeft className={`page-icons ${currentPage <= 1 ? 'custom-disabled' : 'cur-pointer'}`} onClick={() => setCurrentPage(currentPage - 1)} />
        <Pagination.Item className="ps-3 active">
          <Form.Control
            type="text"
            value={currentPage || ''}
            className={`db-num-input ${isEditable ? '' : 'cur-default'}`}
            readOnly={!isEditable}
            pattern="[0-9]+"
            onChange={(event) => {
              const value = Number(event.target.value);
              if (value <= totalPage) {
                setCurrentPage(value);
              }
            }}
          />
        </Pagination.Item>
        <span className="px-3 lbl-of">of</span>
        <Pagination.Item disabled className="pe-3 opacity-50 total-page">
          {totalPage}
        </Pagination.Item>
        <ChevronRight className={`page-icons ${currentPage === totalPage || currentPage === 0 ? 'custom-disabled' : 'cur-pointer'}`} onClick={() => setCurrentPage(currentPage + 1)} />
      </div>
    </Pagination>
  );
};
