import { IGraphSeries, IKeywordsCAGraphicsData, INominalGraphSeries, IOverviewGroupedTableData, IOverviewHeaders } from 'analytics/types';
import { ChartType, OVERVIEW_PLATFORM_FILTER, OVERVIEW_STATS } from 'analytics/utils/constants';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

export const actualChartData = (chartData: any, isSecondaryBreakEnable: boolean = true) => {
  const keys = Object.keys(chartData);
  const isActual = Object.values(chartData).filter((it: any) => it.length > 0).length > 1;
  const minMaxObj = keys.reduce(
    (acc: any, curr: any) => {
      const value = chartData[curr]?.map((it: any) => it?.y);
      if (value && value.length) {
        const minValue = Math.min(...value);
        acc.max.push(Math.max(...value));
        if (minValue) acc.min.push(minValue);
      }
      return acc;
    },
    { max: [], min: [] }
  );
  const actualMax: number = minMaxObj?.max?.length ? Math.max(...minMaxObj.max) : 0;
  const thresholdArray = actualMax > 0 ? [...(minMaxObj?.max || [])].map((it) => it / (actualMax / 5)) : [];
  const isBreak = [...thresholdArray].some((it) => it < 0.1);
  let absoluteMax: number = 0;
  if (isBreak && isActual) {
    absoluteMax = roundOffHandler(actualMax + actualMax / 10);
    const minorBreaksMaxArray = minMaxObj?.max?.filter((it: number) => it < actualMax / 5);
    const minorBreaksMax = Math.max(...minorBreaksMaxArray);
    let multiplier = roundOffHandler(minorBreaksMax / 3);
    let max: number = (absoluteMax - multiplier * 3) / 2;

    if (absoluteMax <= 5 * multiplier || multiplier === 0) {
      return { data: chartData, tickInterval: undefined, yMax: undefined };
    }

    const secondaryBreak = minMaxObj?.max?.filter((it: number) => it < multiplier * 0.1);
    let secondaryMax = 0;
    const isSecondaryBreak = secondaryBreak.length > 0 && isSecondaryBreakEnable;
    if (isSecondaryBreak) {
      secondaryMax = roundOffHandler(Math.max(...secondaryBreak));
      multiplier = roundOffHandler(minorBreaksMax / 4);
      max = (absoluteMax - multiplier * 4) / 2;
    }
    const primaryMultiplier = isSecondaryBreak ? 4 * multiplier : 3 * multiplier;

    const data: any = {};
    keys.forEach((it: string) => {
      data[it] = chartData[it]?.map((datum: any) => {
        const yData =
          datum.y > primaryMultiplier
            ? ((datum.y - primaryMultiplier) / max) * multiplier + primaryMultiplier
            : datum.y <= secondaryMax && isSecondaryBreak
            ? datum.y * (multiplier / (secondaryMax / 2))
            : datum.y <= primaryMultiplier && datum.y > secondaryMax && isSecondaryBreak
            ? (datum.y / (4 * multiplier - 2 * multiplier)) * multiplier + 2 * multiplier
            : datum.y;
        return {
          ...datum,
          y: yData
        };
      });
    });
    return { data, tickInterval: multiplier, yMax: absoluteMax, tickAmount: secondaryBreak.length ? 7 : 6, secondaryMax };
  }
  return { data: chartData, tickInterval: undefined, yMax: undefined };
};

export const pieChartData = (tab: string, exception: string, keys: { title: string; value: string }[], summary: any) => {
  if (tab !== exception) {
    return summary
      ? keys.reduce((acc: any, curr) => {
          acc[curr.title] = summary[`${curr.value}`];
          acc[`${curr.title}_delta`] = summary[`${curr.value}_delta`];
          return acc;
        }, {})
      : {};
  }
};

export const roundOffHandler = (value: number) => {
  const precision = Math.pow(10, Math.floor(value).toString().length - 1);
  return Math.ceil(value / precision) * precision;
};

export const onLoadHandler = (it: any, path: string = '', secondaryPath: string = '') => {
  if (it.yAxis[0].plotLinesAndBands[0]?.svgElem?.d && path) {
    const d = it.yAxis[0].plotLinesAndBands[0]?.svgElem?.d;
    const dArray = d.split(' ');
    const width = `${dArray[4]}px`;
    const wpx = `${dArray[1]}px`;
    const selectedNode = it.yAxis[0]?.gridGroup?.element?.childNodes;
    const index = secondaryPath ? 2 : 3;
    const selectedPath = selectedNode[index];
    if (selectedPath) {
      selectedPath.setAttribute('class', 'v-hid');
    }
    const selectedSvgPath = it.yAxis[0].plotLinesAndBands[0]?.svgElem?.element;
    if (selectedSvgPath) {
      selectedSvgPath.setAttribute('class', 'v-hid');
    }
    const svgPath = path.replace('widthPx', width).replaceAll('wpx', wpx);
    it.yAxis[0].plotLinesAndBands[0].svgElem.element.parentElement.insertAdjacentHTML('afterend', svgPath);
  }
  if (it.yAxis[0].plotLinesAndBands[1]?.svgElem?.d && secondaryPath) {
    const d = it.yAxis[0].plotLinesAndBands[1]?.svgElem?.d;
    const dArray = d.split(' ');
    const width = `${dArray[4]}px`;
    const wpx = `${dArray[1]}px`;
    const selectedNode = it.yAxis[0]?.gridGroup?.element?.childNodes;
    const selectedPath = selectedNode[4];
    if (selectedPath) {
      selectedPath.setAttribute('class', 'v-hid');
    }
    const selectedSvgPath = it.yAxis[0].plotLinesAndBands[1]?.svgElem?.element;
    if (selectedSvgPath) {
      selectedSvgPath.setAttribute('class', 'v-hid');
    }
    const svgPath = secondaryPath.replace('widthPx', width).replaceAll('wpx', wpx);
    it.yAxis[0].plotLinesAndBands[1].svgElem.element.parentElement.insertAdjacentHTML('afterend', svgPath);
  }
};

export const seriesChartDataHandler = (
  headers: IOverviewHeaders[] = [],
  selectedPlatform = COMMON_SOCIAL_PROVIDER_TYPE.ALL,
  summary: IOverviewGroupedTableData['summary'] = {},
  stats: { [key: string]: string | number }[] = OVERVIEW_STATS
) => {
  const series: IGraphSeries = headers?.length
    ? [...stats].reduce((acc: any, curr) => {
        acc[`${curr.value}`] = [...headers]?.map((datum) => {
          const platform = selectedPlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : selectedPlatform;
          return {
            // x: utcToLocalHandler(datum.date),
            y: +summary[`${platform}_${datum.value}_${curr.value}`] || 0,
            toolTipY: +summary[`${platform}_${datum.value}_${curr.value}`] || 0,
            avg: +summary[`avg_${platform}_${datum.value}_${curr.value}`] || null,
            toolTipDeltaPerc: +summary[`${platform}_${datum.value}_${curr.value}_delta`]
          };
        });
        if (!acc[`${curr.value}`].some((it: { y: number }) => it?.y > 0)) {
          delete acc[`${curr.value}`];
        }
        return acc;
      }, {})
    : {};
  return actualChartData(series);
};

export const tabSeriesChartData = (
  headers: IOverviewHeaders[] = [],
  selectedPlatform = COMMON_SOCIAL_PROVIDER_TYPE.ALL,
  summary: IOverviewGroupedTableData['summary'] = {},
  currentTab: string = '',
  platformFilter = OVERVIEW_PLATFORM_FILTER,
  isAverage = true
) => {
  const tabSeriesPlatforms = [...platformFilter].filter((it) => (selectedPlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? it : it.value === selectedPlatform));
  const tabSeries: IGraphSeries =
    currentTab && headers?.length && summary
      ? tabSeriesPlatforms.reduce((acc: any, curr, index) => {
          const platform = curr.value === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value;
          acc[`${curr.value}`] = [...headers]?.map((datum) => {
            const average = +summary[`brand_avg_${platform}_${datum.value}_${currentTab}`] || null;
            return {
              y: +summary[`${platform}_${datum.value}_${currentTab}`] || 0,
              toolTipY: +summary[`${platform}_${datum.value}_${currentTab}`] || 0,
              avg: average,
              toolTipDeltaPerc: +summary[`${platform}_${datum.value}_${currentTab}_delta`]
            };
          });
          if (!acc[`${curr.value}`].some((it: { y: number }) => it?.y > 0)) {
            delete acc[`${curr.value}`];
          }
          if (index === tabSeriesPlatforms.length - 1 && isAverage) {
            acc.average = [...headers]?.map((datum) => {
              return {
                y: +summary[`brand_avg_${selectedPlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value}_${datum.value}_${currentTab}`] || 0,
                avg: null,
                chartType: ChartType.SPLINE,
                dashStyle: 'Dash'
                // toolTipDeltaPerc: +datum[`${curr.value === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value}_${currentTab}`]
              };
            });
            if (!acc.average.some((it: { y: number }) => it?.y > 0)) {
              delete acc.average;
            }
            // acc.hubAverage = [...headers]?.map((datum: any) => {
            //   return {
            //     y: +summary[`hub_avg_${selectedPlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL ? 'total' : curr.value}_${datum.value}_${currentTab}`] || 0,
            //     avg: null,
            //     hubAvg: null,
            //     chartType: ChartType.SPLINE,
            //     dashStyle: 'Dash',
            //   };
            // });
            // if (!acc.hubAverage.some((it: { y: number; }) => it?.y > 0)) {
            //   delete acc.hubAverage;
            // }
            // if (Object.keys(acc).includes(COMMON_SOCIAL_PROVIDER_TYPE.ALL)) {
            //   const keys = Object.keys(acc).filter((it) => it !== 'average' && it !== 'hubAverage');
            //   if (keys.length === 1) {
            //     delete acc.all;
            //   }
            // }
            if (Object.keys(acc).includes('all')) {
              const keys = Object.keys(acc).filter((it) => it !== 'average');
              if (keys.length === 2) {
                delete acc.all;
              }
            }
          }
          return acc;
        }, {})
      : {};
  return actualChartData(tabSeries);
};

export const yLabelFormatter = (
  value: string | number,
  currentTab: string,
  graphSeries: INominalGraphSeries | IKeywordsCAGraphicsData,
  tabGraphSeries: INominalGraphSeries | IKeywordsCAGraphicsData,
  yTickAmount: number = 6,
  secondaryMax: number = 0
) => {
  const exception = yTickAmount - 1;
  const multiplier = exception - 2;
  const isSecondaryBreak = yTickAmount === 7;
  const tickInterval = !currentTab && graphSeries?.tickInterval ? graphSeries?.tickInterval : currentTab && tabGraphSeries?.tickInterval ? tabGraphSeries?.tickInterval : null;
  const max = currentTab && tabGraphSeries?.yMax ? tabGraphSeries?.yMax : !currentTab && graphSeries?.yMax ? graphSeries?.yMax : null;
  if (isSecondaryBreak && secondaryMax && tickInterval && value && max && +value <= 2 * tickInterval) {
    const secondaryValue = +value <= 1 * tickInterval ? (secondaryMax / 2).toString() : secondaryMax.toString();
    return getFormattedNumber(+secondaryValue).toString();
  }
  if (isSecondaryBreak && tickInterval && secondaryMax && value && max && value === 3 * tickInterval) {
    return ((4 * tickInterval - secondaryMax) / 2 + secondaryMax).toString();
  } else if (tickInterval && max && value && +value > tickInterval * multiplier) {
    const chartvalue = value !== tickInterval * exception ? ((max - tickInterval * multiplier) / 2 + tickInterval * multiplier).toString() : max.toString();
    return getFormattedNumber(+chartvalue).toString();
  }
  return getFormattedNumber(+value).toString();
};
