import { Tr, Td } from 'react-super-responsive-table';

import { commonProps } from 'types';
import { NoDataFoundProps } from 'utils/constants';

export const NoDataFound = ({ isTable, tableRowClassname, size }: commonProps.INoDataFoundProps) =>
  isTable ? (
    <Tr className={tableRowClassname}>
      <Td
        colSpan="100%"
        className={`${
          size === NoDataFoundProps.FULL ? `no-data` : size === NoDataFoundProps.MEDIUM ? `no-data nd-text-only dshbrd-no-data d-flex` : `no-data nd-text-only dshbrd-no-data location-no-data`
        }`}
      >
        <span className="info-state">No data to show</span>
      </Td>
    </Tr>
  ) : (
    <div
      className={`${
        size === NoDataFoundProps.FULL ? `no-data` : size === NoDataFoundProps.MEDIUM ? `no-data nd-text-only dshbrd-no-data d-flex` : `no-data nd-text-only dshbrd-no-data location-no-data`
      }`}
    >
      <span className="info-state">No data to show</span>
    </div>
  );
