import { CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ } from 'utils/constants';
import { useParamsValidator, useRightToTop } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const FBAdsTargetingNotifications = () => {
  useParamsValidator(CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ);
  useRightToTop();

  return (
    <section className="item-g notification-cnt fbads__right--note">
      <div className="right-section-wrp">
        <div className="r-profile-details-notify fbads__inner--sec">
          <div className="required-content-first hc-view">
            <div className="fba-item">
              <ImageValidation defaultImg="fbAds" customName="Facebook Ads" />
            </div>
            <span className="fbads__note--txt">
              Boost your posts and run ads through Facebook by connecting your Facebook ad account below. Before connecting and running ads, you must already have your billing information set up in
              your ad account.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
