import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { exceptionHandlerRequest } from 'actions';
import { getExceptionErrorMsg } from 'utils/helpers';
import { useMenuAccessibleAccountSwitcherData } from '../use-menu-accessible-account-switcher';

export const useCheckInvalidUrl = () => {
  const dispatch = useDispatch();

  const [isShowModal, setIsShowModal] = useState(false);

  const { isAdminScreenEnabled } = useMenuAccessibleAccountSwitcherData();

  const adminAreaError = useSelector((state: IStore) => state.adminArea.detailViewError);
  const franchisorError = useSelector((state: IStore) => state.franchisors.error);
  const accountError = useSelector((state: IStore) => state.accounts.error);
  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const contentAnalyticsError = useSelector((state: IStore) => state.contentAnalytics.detailViewError);
  const postError = useSelector((state: IStore) => state.posts.detailViewError);
  const campaignError = useSelector((state: IStore) => state.campaigns.detailViewError);
  const mediaGalleryError = useSelector((state: IStore) => state.mediaGallery.detailViewError);
  const couponError = useSelector((state: IStore) => state.coupons.detailViewError);
  const calendarError = useSelector((state: IStore) => state.calendar.detailViewError);
  const targetingError = useSelector((state: IStore) => state.targeting.detailViewError);
  const rewardProgramsError = useSelector((state: IStore) => state.rewardPrograms.detailViewError);
  const operationListsError = useSelector((state: IStore) => state.operationsList.detailViewError);
  const userDetailError = useSelector((state: IStore) => state.common.userDetailObj.error);

  useEffect(() => {
    const exceptionErrorMsg = getExceptionErrorMsg(
      isAdminScreenEnabled
        ? {
            admin_area: adminAreaError,
            franchisors: franchisorError,
            accounts: accountError,
            user_detail: userDetailError
          }
        : {
            account_switcher: accountSwitcherError,
            franchisors: franchisorError,
            accounts: accountError,
            content_analytics: contentAnalyticsError,
            posts: postError,
            campaign: campaignError,
            media_gallery: mediaGalleryError,
            coupons: couponError,
            calendar: calendarError,
            targeting: targetingError,
            reward_programs: rewardProgramsError,
            operation_lists: operationListsError,
            user_detail: userDetailError
          }
    );
    if (exceptionErrorMsg) {
      setIsShowModal(true);
      dispatch(exceptionHandlerRequest(exceptionErrorMsg));
    }
  }, [
    isAdminScreenEnabled,
    adminAreaError,
    accountSwitcherError,
    franchisorError,
    accountError,
    contentAnalyticsError,
    postError,
    campaignError,
    mediaGalleryError,
    couponError,
    calendarError,
    targetingError,
    rewardProgramsError,
    operationListsError,
    userDetailError,
    dispatch
  ]);

  return { isShowModal, setIsShowModal };
};
