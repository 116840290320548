import * as actionTypes from 'revv/actions/action-types';
import { ISettingMessageRequest, ISettingRequestParams, ISettingResponse, ISettingsEmailMessageRequest } from 'revv/types';
import { ActionsUnion, createAction } from 'revv/utils';

export const settingsRequest = (payload?: ISettingRequestParams) => createAction(actionTypes.SETTINGS_REQUEST, payload);
export const settingsFailure = (payload: string) => createAction(actionTypes.SETTINGS_FAILURE, payload);
export const settingsResponse = (payload: ISettingResponse) => createAction(actionTypes.SETTINGS_RESPONSE, payload);

export const settingsMessageRequest = (payload: ISettingMessageRequest) => createAction(actionTypes.SETTINGS_MESSAGE_REQUEST, payload);
export const settingsMessageFailure = (payload: string) => createAction(actionTypes.SETTINGS_MESSAGE_FAILURE, payload);
export const settingsMessageResponse = () => createAction(actionTypes.SETTINGS_MESSAGE_RESPONSE);

export const settingsEmailMessageRequest = (payload: ISettingsEmailMessageRequest) => createAction(actionTypes.SETTINGS_EMAIL_MESSAGE_REQUEST, payload);
export const settingsEmailMessageFailure = (payload: string) => createAction(actionTypes.SETTINGS_EMAIL_MESSAGE_FAILURE, payload);
export const settingsEmailMessageResponse = () => createAction(actionTypes.SETTINGS_EMAIL_MESSAGE_RESPONSE);

export const SETTINGS_ACTION = {
  settingsRequest,
  settingsFailure,
  settingsResponse,
  settingsMessageRequest,
  settingsMessageFailure,
  settingsMessageResponse,
  settingsEmailMessageRequest,
  settingsEmailMessageFailure,
  settingsEmailMessageResponse
};

export type SettingsAction = ActionsUnion<typeof SETTINGS_ACTION>;
