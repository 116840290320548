// GET CONTENT ANALYTICS
export const GET_CONTENT_ANALYTICS_REQUEST = 'content_analytics/GET_CONTENT_ANALYTICS_REQUEST';
export const GET_CONTENT_ANALYTICS_RESPONSE = 'content_analytics/GET_CONTENT_ANALYTICS_RESPONSE';
export const GET_CONTENT_ANALYTICS_FAILURE = 'content_analytics/GET_CONTENT_ANALYTICS_FAILURE';

// CONTENT ANALYTICS FILTER
export const SET_CONTENT_ANALYTICS_FILTER = 'content_analytics/SET_CONTENT_ANALYTICS_FILTER';

// GET CONTENT ANALYTICS MULTIPLE LOCATION
export const GET_CONTENT_ANALYTICS_MULTILE_LOCATION_REQUEST = 'content_analytics/GET_CONTENT_ANALYTICS_MULTILE_LOCATION_REQUEST';
export const GET_CONTENT_ANALYTICS_MULTILE_LOCATION_RESPONSE = 'content_analytics/GET_CONTENT_ANALYTICS_MULTILE_LOCATION_RESPONSE';
export const GET_CONTENT_ANALYTICS_MULTILE_LOCATION_FAILURE = 'content_analytics/GET_CONTENT_ANALYTICS_MULTILE_LOCATION_FAILURE';

// FOR POP UP MODAL
export const GET_CONTENT_ANALYTICS_MODAL_ID_REQUEST = 'content/GET_CONTENT_ANALYTICS_MODAL_ID_REQUEST';
export const GET_CONTENT_ANALYTICS_MODAL_ID_RESPONSE = 'content_analytics/GET_CONTENT_ANALYTICS_MODAL_ID_RESPONSE';
export const GET_CONTENT_ANALYTICS_MODAL_ID_FAILURE = 'content_analytics/GET_CONTENT_ANALYTICS_MODAL_ID_FAILURE';

export const CONTENT_ANALYTICS_DETAIL_VIEW_ERROR = 'content_analytics/CONTENT_ANALYTICS_DETAIL_VIEW_ERROR';

export const CONTENT_ANALYTICS_INDIVIDUAL_IMAGE_RESPONSE = 'content_analytics/CONTENT_ANALYTICS_INDIVIDUAL_IMAGE_RESPONSE';
// RESET
export const RESET_CONTENT_STATE = 'leaderBoard_analytics/RESET_CONTENT_STATE';
