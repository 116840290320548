import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { delay } from 'redux-saga/effects';

export function* updateApiOnFail(axiosConfig: AxiosRequestConfig<any>, delayInMs: number = 1000): any {
  let apiResponse: any = null;
  while (!apiResponse) {
    try {
      apiResponse = yield axios(axiosConfig)
        .then((res: AxiosResponse<any>) => {
          return {
            ...res
          };
        })
        .catch((error: AxiosError<any>) => {
          throw new Error('error');
        });
      return apiResponse;
    } catch (err) {
      yield delay(delayInMs);
    }
  }
  throw new Error('API request failed');
}
