import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloudinaryContext } from 'cloudinary-react';
import ReactGA from 'react-ga4';

import { Loading } from 'components';
import { Router } from 'routes';
import { store, persistor } from 'store';
import { ErrorBoundary } from 'components/Common/ErrorBoundary/errorCapture';
import { config } from 'config';

const App: React.FC = () => {
  const { name } = config.cloudinary;

  const divroot = document.getElementById('root');

  const resizeWindow = () => {
    if (divroot !== null) {
      if (window.innerWidth >= 0 && window.innerWidth <= 700) {
        divroot.className = 'resizing-inner';
      } else {
        divroot.className = 'resizing-wrap';
      }
    }
  };

  useEffect(() => {
    ReactGA.initialize(config.googleAnalytics.id);
    ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Page View' });
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
    // eslint-disable-next-line
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ToastContainer hideProgressBar />
        <ErrorBoundary>
          <CloudinaryContext cloudName={name}>
            <Router />
          </CloudinaryContext>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default App;
