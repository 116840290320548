interface IPercentTileNonPspProps {
  parentClass?: string;
  imgSrc?: string;
  imageClass?: string;
  valueClass?: string;
  value: number | string;
}

export const NonPspPercentTile = ({ parentClass, imgSrc, imageClass, valueClass, value }: IPercentTileNonPspProps) => {
  return (
    <div className={`${parentClass} smiley-ratings__wrp d-flex align-items-center justify-content-center`}>
      <img src={imgSrc} alt={imageClass} className={imageClass} />
      <div className={valueClass}>{value}</div>
    </div>
  );
};
