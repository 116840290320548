import { useCallback, useEffect /* useMemo */ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore, reviewsContainerTypes } from 'types';
import { dateFormatByRequestedFormat, /* getAccReviewsPlatformList, */ getCurrentDate, getReviewsReqParamObj, weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import { useAccountSwitcherData, useAvailablePlatforms, useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import {
  INBOX_FILTER_INITIAL_VALUE,
  USER_OWNERSHIP,
  REVIEWS_INTERACTION_STATUS,
  REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  REVIEWS_STATUS_DATA,
  InboxValidQueryParams,
  YEAR_MONTH_DAY_FORMAT,
  // REVIEWS_BRAND_PLATFORMS,
  BUTTON_PROPS,
  ReviewsInteraction,
  ReviewsStatus,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  FeedValue
} from 'utils/constants';
import { CommonFilter } from 'components';
import { REVIEW_ANALYTICS_PLATFORM_FILTER, apiExportCsvhandler, handleFilterChange, RATING_STATUS, RatingStatusValue } from 'analytics/utils';
import { inboxPieReloadRequest } from 'actions';
import { IFiterHandleChangeData } from 'types/common/filter';

export const ReviewsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership } = useAccountSwitcherData();

  // const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const reviewsList = useSelector((state: IStore) => state.reviews.reviewsList);
  const yelpBusinessId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.yelp_business_id);

  const platformFilter = useAvailablePlatforms(REVIEW_ANALYTICS_PLATFORM_FILTER, userOwnership, yelpBusinessId || null, userOwnership === USER_OWNERSHIP.FRANCHISOR ? false : true);

  const reviewsDaterange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);

  const initialFilterValue = {
    ...INBOX_FILTER_INITIAL_VALUE,
    platform: 'all',
    locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
    ratings: [],
    status: ReviewsStatus.ALL_STATUS,
    interaction: ReviewsInteraction.ALL_INTERACTION,
    dateRange: reviewsDaterange
  };
  const { queryParams, filter } = useParamsDeconstructor(initialFilterValue);

  const ratingStatus =
    queryParams.platform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      ? RATING_STATUS.filter((it) => it.value === RatingStatusValue.ALL || it.value === RatingStatusValue.RECOMMENDED || it.value === RatingStatusValue.NOT_RECOMMENDED)
      : !queryParams.platform
      ? RATING_STATUS
      : [...RATING_STATUS].slice(0, 6);

  const validParams = {
    ...CommonValidQueryParams,
    ...InboxValidQueryParams,
    RATINGS: 'ratings',
    INTERACTION: 'interaction',
    STATUS: 'status',
    POSTS: null
  };

  /*  const activePlatforms = useMemo(() => {
    if (id && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      return getAccReviewsPlatformList(accountDetails?.social_media || [], accountDetails?.account_profiles[0]?.yelp_profile_url || null);
    } else {
      return REVIEWS_BRAND_PLATFORMS;
    }
  }, [id, userOwnership, accountDetails?.social_media, accountDetails?.account_profiles[0]?.yelp_profile_url]); // eslint-disable-line */

  useParamsValidator(validParams, {
    ...COMMON_VALID_QUERY_PARAMS_OBJ,
    platform: platformFilter.map((it) => it.value || ''),
    // platform: platformFilter,
    ratings: ratingStatus.filter((it) => it.value !== RATING_STATUS[0].value)?.map((it) => it.value)
  });

  const reviewsFilter = {
    ...filter,
    // dateRange: reviewsDaterange,
    interaction: queryParams.interaction || ReviewsInteraction.ALL_INTERACTION,
    status: queryParams.status || ReviewsStatus.ALL_STATUS,
    ratings: queryParams?.ratings?.split(',') || []
  };

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (id && data.reload) {
      dispatch(inboxPieReloadRequest({ id, force: true }));
    } else {
      const queryParamsClone = handleFilterChange(data, isClear, id, queryParams);
      if (data.ratings && Array.isArray(data.ratings)) {
        if (data.ratings.length === 0) {
          delete queryParamsClone.ratings;
        } else {
          queryParamsClone.ratings = data.ratings.join(',');
        }
      }
      if (data.platform) {
        delete queryParamsClone.ratings;
      }
      if (data.interaction) {
        if (data.interaction === ReviewsInteraction.ALL_INTERACTION) {
          delete queryParamsClone.interaction;
        } else {
          queryParamsClone.interaction = data.interaction;
        }
      }
      if (data.status) {
        if (data.status === ReviewsStatus.ALL_STATUS) {
          delete queryParamsClone.status;
        } else {
          queryParamsClone.status = data.status;
        }
      }
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    }
  };

  const handleReviewsExportData = () => {
    if (id && userOwnership) {
      const dateRangeParams = `date_range[]=${filter.dateRange.fromDate.timeStamp}&date_range[]=${filter.dateRange.toDate.timeStamp}&`;
      const reportUrl = `reviews.csv?${dateRangeParams}`;
      const reviewsFilterReqParams: reviewsContainerTypes.IReviewsFilterReqParams = getReviewsReqParamObj(filter);
      const param: any = {
        ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { ancestor_franchisor_id: id } : { account_id: id }),
        ...(reviewsFilterReqParams.network && { network: reviewsFilterReqParams.network }),
        ...(reviewsFilterReqParams.completed && { completed: reviewsFilterReqParams.completed }),
        ...(reviewsFilterReqParams.recommended && { recommended: reviewsFilterReqParams.recommended }),
        ...(reviewsFilterReqParams.ratings?.length && { ratings: reviewsFilterReqParams.ratings }),
        ...(reviewsFilterReqParams.has_reply && { has_reply: reviewsFilterReqParams.has_reply }),
        ...(reviewsFilterReqParams.accountId && { account_list_id: reviewsFilterReqParams.accountId }),
        ...(reviewsFilterReqParams.ancestorFranchisorId && { ancestor_franchisor_id: reviewsFilterReqParams.ancestorFranchisorId })
      };
      apiExportCsvhandler(reportUrl, param, `reviews-${dateFormatByRequestedFormat(getCurrentDate(), YEAR_MONTH_DAY_FORMAT)}`);
    }
  };

  // const platformFilter = PLATFORM_FILTER.filter((it) => activePlatforms.includes(it.value) || (activePlatforms.length > 1 ? it.value === 'all' : false));

  useEffect(() => {
    if (platformFilter.length === 1 && !queryParams?.platform) {
      const params = { platform: platformFilter[0]?.value || '', ...queryParams };
      navigate({ search: `?${new URLSearchParams(params).toString()}` }, { state: {}, replace: true });
    }
  }, [navigate, platformFilter, queryParams?.platform]); // eslint-disable-line

  const isClearFilterDisabled = platformFilter.length === 1 ? (Object.keys(queryParams)?.length === 1 && queryParams?.platform ? true : false) : !Object.keys(queryParams)?.length;

  return (
    <CommonFilter
      filter={reviewsFilter}
      handleChange={handleChange}
      handleExportCsv={handleReviewsExportData}
      analyticsPlatformFilter={platformFilter}
      checkBoxFilter={[
        {
          title: 'Interaction',
          filter: REVIEWS_INTERACTION_STATUS,
          value: 'interaction'
        },
        {
          title: 'Status',
          filter: REVIEWS_STATUS_DATA,
          value: 'status'
        }
      ]}
      primaryButtons={[
        { ...BUTTON_PROPS.PRIMARY_EXPORT_CSV, isDisable: !reviewsList.length },
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: isClearFilterDisabled
        }
      ]}
      multiSelectCheckBoxFilter={[
        {
          title: 'Rating',
          filter: ratingStatus,
          value: 'ratings'
        }
      ]}
      sectionVariant="filter animate__animated animate__fadeIn reputation-review-ftr__main"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
    />
  );
};
