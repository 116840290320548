import { advocacyOnboardReducerTypes } from 'types/advocacy-onboard';

export const ADVOCACY_ONBOARD_PREVIEW_TEXT: advocacyOnboardReducerTypes.IAdvOnboardPreviewTextReducer = {
  title: 'Welcome Aboard!',
  text1: 'We’re happy you’re here! Your account has been created and  is ready for to take the wheel.',
  text2: 'It’s time to rock-and-roll.',
  text3: 'Next, we’ll walk you through the steps to set your password and download the Rallio mobile app!',
  text4: 'Need Any Help?',
  text5:
    'We’d be delighted to help you along the way! If you need any assistance activating you Rallio account or creating a Facebook or Google profile for your business, please given us a call at (800) 399-4210 or email our support team by replying to this message.'
};

export const ADVOCACY_ONBOARD_EMAIL_TOOLTIP_STATUS = {
  OPEN: 'Opened on',
  UNOPEN: 'Unopened'
};

export const ADVOCACY_ONBOARD_APP_TOOLTIP_STATUS = {
  INSTALL: 'Installed on',
  NOT_INSTALL: 'Not Installed'
};

export const ADVOCATE_ACTIVE_CHECKBOX = {
  ALL_ADVOCATES: 'all_active_advocates',
  ADVOCATE: 'each_active_advocates'
};

export enum AdvOnboardLoginTypeValue {
  ADMIN = 'admin',
  EMPLOYEE = 'employee'
}

export enum AdvOnboardLoginTypeLabel {
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee'
}

export enum AdvOnboardEmailStatus {
  ALL = 'all',
  OPENED = 'opened',
  UNOPENED = 'unopened'
}

export enum AdvOnboardAppStatus {
  ALL = 'all',
  INSTALLED = 'installed',
  NOT_INSTALLED = 'notInstalled'
}

export const ADVOCACY_ONBOARD_FILTERS_ARRAY = {
  emailStatus: [
    { value: AdvOnboardEmailStatus.ALL, label: 'All' },
    { value: AdvOnboardEmailStatus.OPENED, label: 'Opened' },
    { value: AdvOnboardEmailStatus.UNOPENED, label: 'Unopened' }
  ],
  appStatus: [
    { value: AdvOnboardAppStatus.ALL, label: 'All' },
    { value: AdvOnboardAppStatus.INSTALLED, label: 'Installed' },
    { value: AdvOnboardAppStatus.NOT_INSTALLED, label: 'Not Installed' }
  ],
  loginType: [
    { value: AdvOnboardLoginTypeValue.ADMIN, label: AdvOnboardLoginTypeLabel.ADMIN },
    { value: AdvOnboardLoginTypeValue.EMPLOYEE, label: AdvOnboardLoginTypeLabel.EMPLOYEE }
  ],
  isFilterClick: false
};

export enum UserLoginsValidParams {
  SEARCH = 'text',
  LOGIN_TYPE = 'login_type',
  EMAIL_STATUS = 'email_status',
  APP_STATUS = 'app_status',
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id'
}
