import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { socialContentsLikeRequest } from 'actions';
import { scContainerTypes } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { scLikeImageUrl } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { useNavBarData } from 'utils/hooks';

export const SCLike = ({ platform, liked, id: socialContentId }: scContainerTypes.ISCLikeProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { subNavPageName } = useNavBarData();

  const handleSCLike = (liked: boolean) => {
    if (socialContentId) {
      dispatch(socialContentsLikeRequest({ socialContentId, liked, pageType: subNavPageName }));
    }
  };

  return [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(platform) ? null : (
    <div className="vc-item" onClick={() => handleSCLike(!liked)}>
      <ImageValidation isImgValid defaultImg={liked ? `${scLikeImageUrl(platform)}-a` : `${scLikeImageUrl(platform)}`} customName="Like" />
      <span className="vc-txt">Like</span>
    </div>
  );
};
