import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { calendarApiTypes, calendarContainerTypes, IStore } from 'types';
import { PLATFORM_FILTER } from 'analytics/utils';
import {
  USER_OWNERSHIP,
  CALENDAR_PAGE_VIEW_TYPES,
  SCHEDULE_PARAM_TYPE,
  CreatorInstaPhotoType,
  CALENDAR_FILTER_INITIAL_VALUE,
  CALENDAR_VIEW_DATE_RANGE,
  ICommonDateTypes,
  NoDataFoundProps
} from 'utils/constants';
import { CalendarView, Loading, NoDataFound } from 'components';
import { CalendarEventViewComp } from './CalendarEventView';
import {
  getEditOrSchedulePost,
  utcTimeStampConverter,
  editOrSchedulePostDispatch,
  handleEditContentPostDispatch,
  getRequestedPostList,
  getCurrentDate,
  getStartAndEndOfCurrentMonth,
  getScheduledMultiPostEventStreamHandler,
  getScheduledPostEventStreamHandler,
  getCalendarItemsListEventStreamHandler
} from 'utils/helpers';
import {
  updateScheduledPostRequest,
  getPostsRequest,
  addPostUpdateSavedPostDetails,
  accountGetProfileRequest,
  calendarEditView,
  addPostSetInstaPhotoPreviewObj,
  addPostSetCampaignData,
  calendarResetListAll,
  calendarViewDateRange
} from 'actions';
import { ICalendarDataType } from 'types/calendar/calendar-container';
import { useAccountSwitcherData, useActivePlatform, useAvailablePlatforms, useParamsDeconstructor } from 'utils/hooks';
import { SchedulePosts } from './SchedulePosts';
import { ContentCalendarListModals } from './ContentCalendarListModals';

export const ContentCalendarList = () => {
  const dispatch = useDispatch();
  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(CALENDAR_FILTER_INITIAL_VALUE);

  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const postsMediaList = useSelector((state: IStore) => state.posts.postsMediaList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const editId = useSelector((state: IStore) => state.calendar.editableData.editId);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const isPostUpdating = useSelector((state: IStore) => state.calendar.isPostUpdating);
  const calendarDateRange = useSelector((state: IStore) => state.calendar.calendarDateRange);
  const hubActualData = useSelector((state: IStore) => state.calendar.hubActualData);
  const scheduleMultiPostList = useSelector((state: IStore) => state.calendar.scheduleMultiPostList);
  const scheduledPostList = useSelector((state: IStore) => state.calendar.scheduledPostList);
  const calendarEventItem = useSelector((state: IStore) => state.calendar.calendarItemList);
  const isCalenderFetching = useSelector((state: IStore) => state.calendar.isFetching);
  const isEventPostFetching = useSelector((state: IStore) => state.calendar.isEventPostFetching);
  const locationActualData = useSelector((state: IStore) => (userOwnership === USER_OWNERSHIP.ACCOUNT ? state.calendar.locationActualData : []));

  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it: any) => it.value !== 'yelp');
  const activePlatforms = useActivePlatform();

  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const platform = queryParams?.platform || 'all';
  const displayType = optionalParams[0] || 'all';
  const dataArr: calendarContainerTypes.ICalendarDataType[] = [];
  let postData: calendarContainerTypes.ICalendarDataType[] = isHubUser ? scheduleMultiPostList : scheduledPostList.filter((it) => !it?.hidden || !it?.draft);
  if (platform !== 'all') {
    postData.filter((it) => dataArr.push({ ...it, dataObj: it.dataObj.filter((it: calendarContainerTypes.IDataObjType) => it.label === platform) }));
    postData = dataArr.filter((it) => it.dataObj.length);
  }
  const isCallApi = Boolean(availablePlatform?.length);
  const data = isHubUser ? postData : displayType === 'scheduled' ? postData.filter((it) => !it.draft) : displayType === 'awaiting_approval' ? postData.filter((it) => it.draft) : postData;
  const scheduledPosts = availablePlatform.length <= 1 ? data : data?.filter((it) => platform === 'all' || it.activePlatform === platform);
  const actualResponse = isHubUser ? hubActualData : locationActualData.filter((it: any) => !it?.hidden || !it?.draft);
  const calendarEventItemList = calendarEventItem?.length ? getRequestedPostList(calendarEventItem, timeZone || '') : [];
  const isFetching = isCalenderFetching || isEventPostFetching;
  const pageView = queryParams?.view || 'calendar';

  useEffect(() => {
    if (pageView === CALENDAR_PAGE_VIEW_TYPES.LIST) dispatch(calendarViewDateRange(CALENDAR_VIEW_DATE_RANGE));
    else {
      dispatch(
        calendarViewDateRange({
          ...CALENDAR_VIEW_DATE_RANGE,
          startDate: getStartAndEndOfCurrentMonth(getCurrentDate(), ICommonDateTypes.START_TIME),
          endDate: getStartAndEndOfCurrentMonth(getCurrentDate(), ICommonDateTypes.END_TIME)
        })
      );
    }
  }, [pageView, dispatch]);

  useEffect(() => {
    let getScheduledMultiPostEventStream: null | (() => void);
    let getScheduledPostEventStream: null | (() => void);
    let getCalendarItemsListEventStream: null | (() => void);
    if (isCallApi && isValidId && id && userOwnership && pageView === CALENDAR_PAGE_VIEW_TYPES.LIST) {
      dispatch(calendarResetListAll());
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        getScheduledMultiPostEventStream = getScheduledMultiPostEventStreamHandler(id, activePlatforms, platform, dispatch);
      } else {
        getScheduledPostEventStream = getScheduledPostEventStreamHandler(id, activePlatforms, platform, dispatch);
        getCalendarItemsListEventStream = getCalendarItemsListEventStreamHandler(id, dispatch);
      }
    }
    return () => {
      if (getScheduledMultiPostEventStream) getScheduledMultiPostEventStream();
      if (getScheduledPostEventStream) getScheduledPostEventStream();
      if (getCalendarItemsListEventStream) getCalendarItemsListEventStream();
    };
  }, [isCallApi, id, userOwnership, isValidId, pageView]); // eslint-disable-line

  // LOCATION UPDATES -> REMOVE, APPROVE, CANCEL
  const handleOnChangePostContent = (paramName: string, val: string | boolean | null, tempCurrentPostView: ICalendarDataType) => {
    const actualData: calendarApiTypes.IGetScheduledPostResponse | calendarApiTypes.IGetScheduledMultiPostResponse | undefined = [...actualResponse]?.find((it) => it.id === tempCurrentPostView.id);
    const dispatchData = { ...actualData, [`${paramName}`]: val, ...(paramName === SCHEDULE_PARAM_TYPE.HIDDEN && val && { draft: true }) };
    dispatch(
      updateScheduledPostRequest({
        activePlatforms,
        payload: dispatchData,
        isCalendarView: true,
        dateRange:
          pageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR && calendarDateRange?.startDate && calendarDateRange?.endDate
            ? [utcTimeStampConverter(calendarDateRange?.startDate), utcTimeStampConverter(calendarDateRange?.endDate)]
            : []
      })
    );
  };

  // EDIT DATA API DISPATCH USE-EFFECT
  useEffect(() => {
    if (editId && editData) {
      dispatch(getPostsRequest({ postsDataId: isHubUser ? editData.contentId : editData.savedPostId, userOwnership: userOwnership || '' }));
    }
  }, [isHubUser, editId, editData]); // eslint-disable-line

  useEffect(() => {
    if (editId && postsDetails) {
      handleEditOrSchedulePost();
    }
  }, [editId, postsDetails]); // eslint-disable-line

  const handleEditOrSchedulePost = () => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id) {
      dispatch(accountGetProfileRequest({ id: postsDetails?.account?.id, isDataRetain: false }));
    }
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && postsDetails) {
      // LOCATION DISPATCH EVENT
      dispatch(
        addPostUpdateSavedPostDetails(getEditOrSchedulePost(activePlatforms, { ...postsDetails, created_at: editData?.scheduledTime || '' }, accountDetails?.account?.tzinfo_name || '', null, true))
      );
      editOrSchedulePostDispatch(activePlatforms, postsDetails, accountDetails, dispatch);
      if (postsDetails?.use_instagram && postsDetails?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: postsDetails?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (postsDetails?.campaigns?.length) {
        dispatch(addPostSetCampaignData(postsDetails?.campaigns));
      }
      dispatch(calendarEditView({ isEditEnabled: true, editId: 0, editData }));
    } else {
      handleEditContentPost();
    }
  };

  // HUB DISPATCH EVENT
  const handleEditContentPost = () => {
    if (postsDetails) {
      handleEditContentPostDispatch(activePlatforms, postsDetails, postsMediaList, postsLinks, contentObj, dispatch);
      if (postsDetails?.campaigns?.length) {
        dispatch(addPostSetCampaignData(postsDetails?.campaigns));
      }
      dispatch(calendarEditView({ isEditEnabled: true, editId: 0, editData }));
    }
  };

  return (
    <div className="calendar-section-main-wrp calb-main mx-0">
      <div className="view-fullcalendarnew-wrp-section lpx pb-20">
        {pageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR ? (
          <CalendarView actualResponse={actualResponse} isHubUser={isHubUser} timeZone={timeZone} isFetching={isFetching} calendarEventItemList={calendarEventItemList} displayType={displayType} />
        ) : (isFetching && !calendarEventItemList?.length) || (isFetching && !scheduledPosts?.length) || ((isFetching || isPostUpdating) && pageView === CALENDAR_PAGE_VIEW_TYPES.LIST) ? (
          <Loading />
        ) : displayType === 'events' ? (
          !isFetching && calendarEventItemList.length ? (
            <CalendarEventViewComp calendarEventItemList={calendarEventItemList} />
          ) : (
            <NoDataFound size={NoDataFoundProps.MEDIUM} />
          )
        ) : (
          <SchedulePosts
            isHubUser={isHubUser}
            postData={postData}
            displayType={displayType}
            filterPlatform={platform}
            activePlatforms={activePlatforms}
            accountDetails={accountDetails}
            locationActualData={locationActualData}
            postsDetails={postsDetails}
            editId={editId}
            handleOnChangePostContent={handleOnChangePostContent}
            dispatch={dispatch}
          />
        )}
        <ContentCalendarListModals
          calendarDateRange={calendarDateRange}
          editData={editData}
          activePlatforms={activePlatforms}
          currentPageView={pageView}
          isHubUser={isHubUser}
          handleOnChangePostContent={handleOnChangePostContent}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
};
