import { Modal } from 'react-bootstrap';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup } from 'types';

export const SettingsSocialModalPopup = ({ isModalShow, handleModalClose, modalCloseButton, modalHeader, modalBody, modalFooter }: commonModalPopup.IModalPopup) => {
  return (
    <Modal
      onHide={handleModalClose}
      className={`aps-modal sp-stngs connectPopup new-cp-wrp`}
      // size='xl'

      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation
    >
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="modal-close2" customName="cancel" customClassname="mod__close--img" onClick={handleModalClose} />
      </div>
      <Modal.Header className="" closeButton={modalCloseButton}>
        {modalHeader()}
      </Modal.Header>
      <Modal.Body>
        <div className="aps-main" id="scrollTargetFbpages">
          {modalBody()}
        </div>
      </Modal.Body>
      <Modal.Footer>{modalFooter()} </Modal.Footer>
    </Modal>
  );
};
