import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { exceptionHandlerRequest, logoutRequest, getAdvLeaderboardListResponse, getAdvLeaderboardListFailure, getAdvLeaderboardStatsResponse, getAdvLeaderboardStatsFailure } from 'actions';
import { apiCall, defaultHeader, API, updateApiOnFail } from 'utils/helpers';
import { ADV_LB_EXCEPTION_ERROR, ADV_LB_LIST_FAILURE, USER_OWNERSHIP, ADV_LB_FILTER_STATS_FAILURE } from 'utils/constants';
import { ISagaPayload } from 'types/common/api';

function* sendGetAdvLeaderboardRequest({ payload }: ISagaPayload): any {
  try {
    const params = {
      date_range: payload.dateRange,
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && payload.franchisorId && { franchisor_id: payload.franchisorId }),
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && !payload.franchisorId && { franchisor_id: payload.id }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && { account_id: payload.id }),
      ...(payload.rankingTarget && { list: payload.rankingTarget })
    };
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params, ...API.advLbGetAssets });
    const asyncResponse = yield updateApiOnFail({
      url: response.data.url,
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (asyncResponse.status === 200) {
      yield put(getAdvLeaderboardListResponse(asyncResponse.data));
    } else {
      yield put(getAdvLeaderboardListFailure(ADV_LB_LIST_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADV_LB_EXCEPTION_ERROR));
  }
}

function* sendGetAdvLeaderboardStatsRequest({ payload }: ISagaPayload): any {
  try {
    const params = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR && { franchisor_id: payload.id }),
      ...(payload.userOwnership === USER_OWNERSHIP.ACCOUNT && { account_id: payload.id })
    };
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params, ...API.advLbGetFilterStats });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAdvLeaderboardStatsResponse(response.data));
    } else {
      yield put(getAdvLeaderboardStatsFailure(ADV_LB_FILTER_STATS_FAILURE));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADV_LB_EXCEPTION_ERROR));
  }
}

export function* takeAdvocacyLeaderboardRequest() {
  yield takeLatest(types.GET_ADV_LEADERBOARD_LIST_REQUEST, sendGetAdvLeaderboardRequest);
  yield takeLatest(types.GET_ADV_LEADERBOARD_STATS_REQUEST, sendGetAdvLeaderboardStatsRequest);
}
