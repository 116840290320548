import { campaignsApiTypes, commonProps, campaignsContainerTypes, postsApiTypes, franchisorApiTypes, reviewsContainerTypes } from 'types';
import {
  CampaignsStatus,
  WEEKLY_SCHEDULE_CAMPAIGN_DATA,
  FRAN_SCHEDULE_POST_TYPE,
  USER_OWNERSHIP,
  OFFSET_TIMEZONE_FORMAT,
  DATE_FORMAT,
  DAY_FORMAT,
  ICampaignSourceType,
  LOCATION_LISTS,
  FRANCHISORS_SELECTIONS
} from 'utils/constants';
import { getTodayDate, getDaysBetweenDates, getFormattedDate, getParsedDate, getIncludedAccounts, getUniqueValues, getHubOrLocationListIds } from 'utils/helpers';
import { getCurrentDate } from '../date-helper';

export const getCampaignsReqParams = (
  id: number,
  userOwnership: string,
  successMsg: null | string,
  queryParams: { [key: string]: string },
  page?: number
): campaignsApiTypes.ICampaignsDataReqParams => {
  return {
    id,
    userOwnership,
    ...(queryParams?.text && { text: queryParams?.text }),
    ...(queryParams?.source && { source: queryParams?.source === ICampaignSourceType.LOCATION ? 'local' : queryParams?.source }),
    ...(queryParams?.status && { status: queryParams?.status === CampaignsStatus.ACTIVE ? 'ACTIVE' : queryParams?.status === CampaignsStatus.IN_ACTIVE ? 'INACTIVE' : 'AVAILABLE' }),
    ...(successMsg && { isDataRetain: true }),
    page
  };
};

export const getCampaignWeeklyScheduleList = ({ endDate, weeklySchedule }: Partial<campaignsContainerTypes.ICampaignWeeklyScheduleObj>) => {
  const startDate = getCurrentDate();
  return WEEKLY_SCHEDULE_CAMPAIGN_DATA.reduce((acc: campaignsContainerTypes.ICampaignPostsWeeklySchedule[], curr) => {
    const totalDays = startDate && endDate ? getDaysBetweenDates(startDate, endDate, DATE_FORMAT) : [];
    const formattedDate = totalDays.length <= 7 ? totalDays.find((it) => getFormattedDate(getParsedDate(it), DAY_FORMAT) === curr.value) || '' : '';
    if (weeklySchedule) {
      acc = [
        ...acc,
        Object.entries(weeklySchedule).reduce((weekAcc, weekCurr) => {
          if (curr.id === weekCurr[0]) weekAcc = { ...curr, postSlot: weekCurr[1]?.map((it) => it.slice(0, 8)) || [], ...(formattedDate && { date: formattedDate }) };
          return weekAcc;
        }, curr)
      ];
    } else {
      acc = [...acc, { ...curr, ...(formattedDate && { date: formattedDate }) }];
    }
    return acc;
  }, []);
};

export const getCampaignWeeklyScheduleObj = (weeklySchedule: campaignsContainerTypes.ICampaignPostsWeeklySchedule[], timezone?: string): commonProps.ICommonObj<string[]> => {
  const offsetTimezone = timezone ? getTodayDate(timezone, OFFSET_TIMEZONE_FORMAT) : '';
  return weeklySchedule.reduce((acc, curr) => {
    acc = { ...acc, [curr.id]: curr.postSlot.map((it) => (timezone ? `${it} ${offsetTimezone}` : it)) };
    return acc;
  }, {});
};

export const getCampaignPostsLocationDetails = (
  userOwnership: string,
  locationType: string,
  accountSelectionList: reviewsContainerTypes.IReviewsLocationObj[],
  schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[],
  scheduledLocationIds: number[],
  corporateAccountId?: number
): Partial<campaignsApiTypes.ICampaignConfig> => {
  return {
    ...(userOwnership === USER_OWNERSHIP.FRANCHISOR
      ? {
          included_accounts:
            locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
              ? accountSelectionList.filter((it) => !scheduledLocationIds.includes(it.id)).map((it) => ({ id: it.id, name: it.value }))
              : locationType === FRAN_SCHEDULE_POST_TYPE.CORPORATE_ONLY
              ? !corporateAccountId
                ? []
                : accountSelectionList.reduce((acc: postsApiTypes.IPostListDetailData[], curr) => {
                    if (!scheduledLocationIds.includes(curr.id) && Number(curr.id) === Number(corporateAccountId)) acc = [...acc, { id: curr.id, name: curr.value }];
                    return acc;
                  }, [])
              : getIncludedAccounts(schLocationTree, scheduledLocationIds),
          schedule_to_account_list_ids: getUniqueValues(getHubOrLocationListIds(schLocationTree, LOCATION_LISTS)),
          schedule_to_franchisor_ids: getUniqueValues(getHubOrLocationListIds(schLocationTree, FRANCHISORS_SELECTIONS)),
          schedule_to_all_accounts: locationType === FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
        }
      : {
          location: true
        })
  };
};

export const getAddPostToCampaignSuccessMsg = (postCount: number) => {
  return `Post${postCount > 1 ? 's' : ''} added to this campaign`;
};

export const getRemovePostsFromCampaignConfirmMsg = (campaignStatus: string, postCount: number) => {
  return campaignStatus === 'ACTIVE'
    ? `You are removing ${postCount > 1 ? 'posts' : 'a post'} from active campaign, are you sure you want to remove post${postCount > 1 ? 's' : ''} from campaign?`
    : `Are you sure you want to remove post${postCount > 1 ? 's' : ''} from campaign?`;
};

export const getRemovePostsFromActiveCampaignSecMsg = (postCount: number) => {
  return `Removing ${postCount > 1 ? 'posts' : 'a post'} from active schedules might alter the post sequence.`;
};
