import { useState, useEffect, useCallback } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { ChooseHubsAndLocations } from 'components';
import { IStore, rpApiTypes } from 'types';
import { checkExpireProgram, getActiveCheckedKeysFromList } from 'utils/helpers';
import { FRANCHISORS_SELECTIONS, FRAN_SCHEDULE_POST_TYPE, LOCATION_LISTS, Stage, USER_OWNERSHIP } from 'utils/constants';
import { getDirectLocsForHubOrLocListRequest, getDirectLocsForHubOrLocListResponse, getFranScheduleTreeStats, rpSetCreateProgramDataObj, setFranchisorScheduleLocType } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';

export const ProgramLocations = () => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const { createProgramDataObj } = useSelector((state: IStore) => state.rewardPrograms);

  const locationType = useSelector((state: IStore) => state.franchisors.franScheduleTree.locationType);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const { modifiedStatus, programHuLocations, programDetails } = createProgramDataObj;
  const { franchisors, accounts, accountLists } = programHuLocations;

  const [openChooseLocModal, setOpenChooseLocModal] = useState(false);

  const isCurrAccOrFranIncluded = programHuLocations.franchisors.find((franchisor: any) => franchisor.id === currentAccountOrFranchisor?.id);
  const editLocationModalOpen = () => {
    setOpenChooseLocModal(true);
  };

  useEffect(() => {
    dispatch(
      rpSetCreateProgramDataObj({
        ...createProgramDataObj,
        modifiedStatus: {
          ...modifiedStatus,
          ...(userOwnership === USER_OWNERSHIP.ACCOUNT && {
            programHuLocations: { ...programHuLocations, accounts: [{ id: currentAccountOrFranchisor?.id, name: currentAccountOrFranchisor?.name }], selectedLocationCount: 1 }
          }),
          locations: franchisors.length || accountLists.length || accounts.length ? true : false
        }
      })
    );
  }, [franchisors, accountLists, accounts]); // eslint-disable-line

  const getSchHubLocationTree = useCallback(() => {
    let modifiedProgramHubLocations: rpApiTypes.IRPProgramLocationData =
      programDetails?.recommendedType !== Stage.RECOMMENDED_PROGRAM ||
      programHuLocations?.isAllLocation ||
      (programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM && !programHuLocations?.isAllLocation)
        ? programHuLocations
        : { franchisors: [], accountLists: [], accounts: [], selectedLocationCount: 0, isAllLocation: false };
    if (programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM) {
      const isCurrentAccOrFranExistInFranList = programHuLocations.franchisors.find((franchisor: any) => franchisor.id === currentAccountOrFranchisor?.id);
      const isCurrentAccOrFranExistInLocList = programHuLocations.accounts.find((franchisor: any) => franchisor.id === currentAccountOrFranchisor?.id);
      const accOrFranDetails = [{ id: currentAccountOrFranchisor?.id, name: currentAccountOrFranchisor?.name }];
      if (isCurrentAccOrFranExistInFranList || isCurrentAccOrFranExistInLocList) {
        modifiedProgramHubLocations = {
          ...modifiedProgramHubLocations,
          franchisors: isCurrentAccOrFranExistInFranList ? accOrFranDetails : [],
          accounts: isCurrentAccOrFranExistInLocList ? accOrFranDetails : [],
          selectedLocationCount: isCurrentAccOrFranExistInLocList ? 1 : 0
        };
      } else {
        modifiedProgramHubLocations = programHuLocations;
      }
    }
    if (modifiedProgramHubLocations?.franchisors?.length) {
      modifiedProgramHubLocations?.franchisors.forEach((franchisorObj) => {
        if (franchisorObj) {
          dispatch(getDirectLocsForHubOrLocListRequest({ id: Number(franchisorObj?.id), type: FRANCHISORS_SELECTIONS, includedAccounts: accounts }));
        }
      });
    }
    if (modifiedProgramHubLocations?.accountLists?.length) {
      modifiedProgramHubLocations?.accountLists.forEach((accountObj) => {
        if (accountObj) {
          dispatch(getDirectLocsForHubOrLocListRequest({ id: Number(accountObj?.id), type: LOCATION_LISTS, includedAccounts: accounts }));
        }
      });
    }
    if (modifiedProgramHubLocations?.accounts?.length) {
      dispatch(
        getDirectLocsForHubOrLocListResponse({
          includedAccounts: userOwnership === USER_OWNERSHIP.ACCOUNT ? [{ id: currentAccountOrFranchisor?.id, name: currentAccountOrFranchisor?.name }] : modifiedProgramHubLocations?.accounts
        })
      );
    }
    dispatch(
      getFranScheduleTreeStats({
        hubs: franchisors.length || 0,
        locationLists: accountLists.length || 0
      })
    );
  }, [franchisors, accounts, accountLists, userOwnership, currentAccountOrFranchisor, programDetails?.recommendedType, programHuLocations, dispatch]);

  useEffect(() => {
    if (openChooseLocModal && locationType !== FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS) {
      getSchHubLocationTree();
    }
  }, [openChooseLocModal]); // eslint-disable-line

  useEffect(() => {
    dispatch(
      setFranchisorScheduleLocType(
        programDetails?.recommendedType === Stage.RECOMMENDED_PROGRAM
          ? programHuLocations?.isAllLocation && !isCurrAccOrFranIncluded
            ? ''
            : programHuLocations?.isAllLocation || isCurrAccOrFranIncluded
            ? FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
            : FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS
          : programHuLocations?.isAllLocation
          ? FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS
          : Object.values(programHuLocations).some((item) => Boolean(Array.isArray(item) ? item.length : item))
          ? FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS
          : ''
      )
    );
  }, []); // eslint-disable-line

  const disableLocationTab = () => {
    return !modifiedStatus.details || !modifiedStatus.actions ? true : false;
  };

  const getSelectedLocationTags = (totalHubLocObj: rpApiTypes.IRPProgramLocationData) => {
    const updatedCheckedKeys = getActiveCheckedKeysFromList(totalHubLocObj);
    return updatedCheckedKeys.map((item, idx) => {
      return (
        <Button variant="primary" key={`rp-updated-locations-${idx}`}>
          <div className="info-icon">
            <ImageValidation isImgValid defaultImg={'loc-icon-blue'} customName="location" />
          </div>
          <span>{item}</span>
        </Button>
      );
    });
  };

  return (
    <>
      <div className={`action-rewards-wrp${disableLocationTab() ? ` opacity` : ` enabled-ac-rewards`}`}>
        <Alert variant="primary">
          <Alert.Heading>
            <div className="grid-sec-item1">
              <div className="left-section">Locations</div>
              {!franchisors.length && !accountLists.length && !accounts.length ? (
                <div className={`right-section${userOwnership === USER_OWNERSHIP.ACCOUNT ? ` pointer-events-none` : ``}`} onClick={() => setOpenChooseLocModal(true)}>
                  <div className="cursor-pointer">
                    <div className="info-icon">
                      <ImageValidation isImgValid defaultImg={'add-item'} customName="add" />
                    </div>
                    Add Location
                  </div>
                </div>
              ) : (
                <div className={`right-section${checkExpireProgram(programDetails?.endDate) ? ` pointer-events-none` : ``}`} onClick={editLocationModalOpen}>
                  <div className="cursor-pointer">
                    <div className="info-icon">
                      <ImageValidation isImgValid defaultImg={'edit-action'} customName="edit" />
                    </div>
                    Edit Location
                  </div>
                </div>
              )}
            </div>
          </Alert.Heading>
        </Alert>
        <div className={`locaion-list-section-wrp${disableLocationTab() ? ` opacity` : ``}`}>
          {programHuLocations.isAllLocation ? (
            <Button variant="primary" key={`rp-updated-locations`}>
              <div className="info-icon">
                <ImageValidation isImgValid defaultImg={'loc-icon-blue'} customName="location" />
              </div>
              <span>{`All ${franchisors[0]?.name}`}</span>
            </Button>
          ) : programDetails?.recommendedType !== Stage.RECOMMENDED_PROGRAM && userOwnership === USER_OWNERSHIP.ACCOUNT ? (
            <Button variant="primary" key={`rp-updated-locations`}>
              <div className="info-icon">
                <ImageValidation isImgValid defaultImg={'loc-icon-blue'} customName="location" />
              </div>
              <span>{currentAccountOrFranchisor?.name}</span>
            </Button>
          ) : franchisors.length || accounts.length || accountLists.length ? (
            getSelectedLocationTags(programHuLocations)
          ) : null}
        </div>
      </div>
      {openChooseLocModal && <ChooseHubsAndLocations isModalShow={openChooseLocModal} handleModalClose={() => setOpenChooseLocModal(false)} />}
    </>
  );
};
