import { couponsReducerTypes } from 'types';

export const COUPON_UPDATES_INITIAL_OBJECT = {
  isUpdated: false,
  updateSuccessMsg: null,
  updateFailureMsg: null
};

export const NEW_COUPON_INIT_OBJ: couponsReducerTypes.INewCouponObj = {
  id: null,
  couponName: null,
  quantityOfCoupons: 25,
  couponDate: {
    start: { selected: null, date: null },
    expired: { selected: null, date: null }
  },
  imageUrl: null,
  offerCopy: null,
  disclaimerCopy: null,
  isMediaFetching: false
};

export const COUPON_FILTERS_INITIAL_OBJECT: couponsReducerTypes.ICouponsFiltersVal = {
  state: 'all'
};

export const COUPON_STATS_INITIAL_OBJECT: couponsReducerTypes.ICouponsStats = {
  active: 0,
  inactive: 0
};

export const COUPON_MESSAGES_INIT_OBJ: couponsReducerTypes.IAddOrEditCouponMessage = {
  isFetching: false,
  couponSuccessMsg: null,
  couponFailureMsg: null
};
