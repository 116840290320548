import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  BUTTON_PROPS,
  CommonValidQueryParams,
  COMMON_VALID_QUERY_PARAMS_OBJ,
  CampaignsValidQueryParams,
  CAMPAIGN_INIT_FILTER_DATA,
  CAMPAIGNS_STATUS,
  CAMPAIGN_SOURCE_TYPE,
  CAMPAIGNS_SCHEDULE_STATUS,
  CommonAction,
  USER_OWNERSHIP,
  AP_ACTION_TYPES,
  ADD_POST_ACTION_TYPE,
  AP_CONTENT_ACTION_TYPE,
  PostsValidQueryParams
} from 'utils/constants';
import { useParamsDeconstructor, useParamsValidator, useAccountSwitcherData } from 'utils/hooks';
import { handleFilterChange } from 'analytics/utils/helpers';
import {
  AddPostsToCampaignModal,
  CommonFilter,
  CreateOrEditCampaignModal,
  CreateOrEditPostModal,
  DeactivateCampaignEventConfirmModal,
  DeleteCampaignConfirmModal,
  MgFavTagModalPopup,
  StartOrEditCampaignEventModal
} from 'components';
import { IFiterHandleChangeData } from 'types/common/filter';
import {
  addPostMassUpdateForScheduledPostRequest,
  addPostSavePostActionRequest,
  addPostsToCampaignRequest,
  addPostUpdateContentRequest,
  addPostUpdateLinkRequest,
  campaignSelectedPostsReset,
  franchisorScheduleTreeReset,
  mgResetAll,
  postsResetAll
} from 'actions';
import { atLeastApproved, getApPostActionReqPayload, getLinkDataReqPayload, getPreviewBrandHubCreatorActions, getPreviewSocialMediaList, getReqPayloadForUpdateContent } from 'utils/helpers';
import { IStore } from 'types';

export const CampaignsFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { queryParams, filter } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();

  const validCampaignId = optionalParams[0];

  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const instaType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const postStatus = useSelector((state: IStore) => state.addPostFranchisor.contentObj.postStatus);
  const isPostLock = useSelector((state: IStore) => state.addPostFranchisor.contentObj.isPostLock);
  const isApproveAndSaveToDBFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSaveToDBFetching);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const campaignFranchisorId = useSelector((state: IStore) => state.campaigns.campaignDetail?.franchisor_id || 0);
  const campaignPostsCount = useSelector((state: IStore) =>
    state.campaigns.campaignDetail?.content ? state.campaigns.campaignDetail?.contents_count || 0 : state.campaigns.campaignDetail?.saved_posts_count || 0
  );
  const campaignDetailStatus = useSelector((state: IStore) => state.campaigns.campaignDetail?.status);
  const allAccountIds = useSelector((state: IStore) => state.campaigns.campaignDetail?.all_account_ids || []);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0].is_franchisee || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const isTagFetching = useSelector((state: IStore) => state.mediaGallery.isTagFetching);
  const favTagsList = useSelector((state: IStore) => state.mediaGallery.favTagsList);
  const isPostListFetching = useSelector((state: IStore) => state.campaigns.isPostListFetching);
  const isDetailFetching = useSelector((state: IStore) => state.campaigns.isDetailFetching);
  const totalLocations = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList?.onlyLocationList || []);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);

  const [isDeleteCampaign, setIsDeleteCampaign] = useState(false);
  const [isStopCampaign, setIsStopCampaign] = useState(false);

  const campaignsFilter = {
    ...filter,
    search: queryParams?.text || '',
    source: queryParams?.source || 'all',
    status: queryParams?.status || 'all',
    schedule_status: queryParams?.schedule_status || 'all'
  };

  useParamsValidator(
    {
      ...CommonValidQueryParams,
      ...CampaignsValidQueryParams,
      ...(queryParams?.add_posts === '1' && { ...PostsValidQueryParams })
    },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      source: CAMPAIGN_SOURCE_TYPE.map((it) => it.value),
      status: CAMPAIGNS_STATUS.map((it) => it.value),
      add_event: ['1'],
      create_post: ['1'],
      add_posts: ['1'],
      schedules: ['1'],
      schedule_status: CAMPAIGNS_SCHEDULE_STATUS.map((it) => it.value),
      ...(queryParams?.add_posts === '1' && { favorite_tags: ['1'] })
    }
  );

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    if (data.delete_campaign) {
      setIsDeleteCampaign(true);
    } else if (data.stop_campaign) {
      setIsStopCampaign(true);
    } else {
      const queryParamsObj = handleFilterChange(data, isClear, null, queryParams);
      if (isClear) {
        if (queryParams?.text) queryParamsObj.text = queryParams?.text || '';
      }
      if (data.source) {
        if (data.source === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.source;
        else queryParamsObj.source = data.source;
      }
      if (data.status) {
        if (data.status === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.status;
        else queryParamsObj.status = data.status;
      }
      if (data.add_event) {
        if (data.add_event === '0') delete queryParamsObj.add_event;
        else queryParamsObj.add_event = '1';
      }
      if (data.edit_event) {
        if (data.edit_event === '0') delete queryParamsObj.edit_event;
      }
      if (data.create_post) {
        if (data.create_post === '0') delete queryParamsObj.create_post;
        else queryParamsObj.create_post = '1';
      }
      if (data.add_posts) {
        delete queryParamsObj.text;
        if (data.add_posts === '0') delete queryParamsObj.add_posts;
        else queryParamsObj.add_posts = '1';
      }
      if (data.favorite_tags) {
        if (data.favorite_tags === '0') delete queryParamsObj.favorite_tags;
        else queryParamsObj.favorite_tags = data.favorite_tags;
      }
      if (data.schedule_status) {
        if (data.schedule_status === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj.schedule_status;
        else queryParamsObj.schedule_status = data.schedule_status;
      }
      navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
    }
  };

  const handleCloseCreateOrEditCampaign = () => {
    const queryParamsObj = { ...queryParams };
    delete queryParamsObj.create_campaign;
    delete queryParamsObj.edit_campaign;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const disableAccountSaveAction = () => {
    if (savedPostDetails && !getPreviewSocialMediaList(savedPostDetails).length) return true;
    else return false;
  };

  const disableFranchisorSaveAction = () => {
    if (!getPreviewBrandHubCreatorActions(postStatus).length) return true;
    else if (isApproveAndSaveToDBFetching) return true;
    else if (pageType === AP_ACTION_TYPES.EDIT && isPostLock) return true;
    else return false;
  };

  const handleAddSavedPostData = () => {
    if (addPostAction === AP_ACTION_TYPES.ADD && !disableAccountSaveAction()) {
      const apReqPayload = getApPostActionReqPayload(
        id,
        userOwnership,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkData,
        ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST,
        instaType,
        campaignData,
        isAiPost
      );
      dispatch(addPostSavePostActionRequest({ actionType: ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, reqBody: apReqPayload }));
    }
  };

  const handleUpdateContentData = () => {
    handleUpdateLink();
    if (contentData?.id && activeUserId) {
      const saveDraftReqPayload = getReqPayloadForUpdateContent(
        id,
        activeUserId,
        contentData?.created_at,
        contentObj,
        selectedMediaList,
        selectedTags,
        selectedFBTagList,
        AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST,
        selectedLocData,
        linkData,
        instaType,
        campaignData,
        contentData.content_origins,
        reducerTextEditorState?.facebook,
        contentData?.ai_post || isAiPost
      );
      dispatch(addPostUpdateContentRequest({ actionType: AP_CONTENT_ACTION_TYPE.SINGLE_CAMPAIGN_POST, postId: contentData.id, reqPayload: saveDraftReqPayload }));
      handleContentMassUpdate();
    }
  };

  const handleUpdateLink = () => {
    if (linkData?.id && linkData?.link_preview_image_url_choices?.length > 1) {
      dispatch(addPostUpdateLinkRequest({ linkId: linkData.id, reqPayload: getLinkDataReqPayload(linkData, contentObj.linkIndex, contentObj.isThumbnailShow) }));
    }
  };

  const handleContentMassUpdate = () => {
    if (contentData?.id && atLeastApproved(contentData?.status_id)) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const handleCreatePostSaveButtonClassname = () => {
    return `${
      (userOwnership === USER_OWNERSHIP.FRANCHISOR && disableFranchisorSaveAction()) || (userOwnership === USER_OWNERSHIP.ACCOUNT && disableAccountSaveAction()) ? ` pointer-events-none` : ''
    }`;
  };

  const handleAddPostSaveButtonClassname = () => {
    return `gnrc-btn blue-gnr ac-btn${!selectedPosts.length ? ` pointer-events-none` : ''}`;
  };

  const filterCheckboxList =
    userOwnership === USER_OWNERSHIP.FRANCHISOR
      ? [
          {
            title: 'Status',
            filter: CAMPAIGNS_STATUS,
            value: 'status'
          }
        ]
      : [
          {
            title: 'Source',
            filter: CAMPAIGN_SOURCE_TYPE,
            value: 'source'
          },
          {
            title: 'Status',
            filter: CAMPAIGNS_STATUS,
            value: 'status'
          }
        ];

  const disableEditCampaignData = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && campaignFranchisorId) return true;
    else return false;
  };

  const handleCloseAddPosts = () => {
    dispatch(campaignSelectedPostsReset());
    dispatch(postsResetAll());
    dispatch(mgResetAll());
    handleChange({ add_posts: '0' });
  };

  const handleAddPostsToCampaign = () => {
    if (validCampaignId && userOwnership) dispatch(addPostsToCampaignRequest({ campaignId: +validCampaignId, userOwnership, selectedPostIds: selectedPosts }));
    handleCloseAddPosts();
  };

  const disableScheduleButton = () => {
    if (!campaignPostsCount) return true;
    else if (campaignDetailStatus === 'INACTIVE') return true;
    else if (campaignDetailStatus === 'ACTIVE') {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && totalLocations.every((it) => allAccountIds.includes(it.id))) return true;
      else if (userOwnership === USER_OWNERSHIP.ACCOUNT && allAccountIds.includes(id)) return true;
      return false;
    }
    return false;
  };

  const disableCreatePostButton = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && isFranchisee && overriddenCustomPostsLocked) return true;
    else if (campaignDetailStatus === 'INACTIVE') return true;
    return false;
  };

  const disableAddPostButton = () => {
    if (isPostListFetching) return true;
    else if (campaignDetailStatus === 'INACTIVE') return true;
    return false;
  };

  return (
    <>
      <CommonFilter
        filter={campaignsFilter}
        handleChange={handleChange}
        isDateRangeFilterEnable={false}
        checkBoxFilter={
          !validCampaignId
            ? filterCheckboxList
            : queryParams?.schedules === '1'
            ? [
                {
                  title: 'Schedule Status',
                  filter: CAMPAIGNS_SCHEDULE_STATUS,
                  value: 'schedule_status'
                }
              ]
            : []
        }
        primaryButtons={
          !validCampaignId
            ? [
                {
                  ...BUTTON_PROPS.CLEAR_FILTER,
                  isDisable: !queryParams.source && !queryParams.status
                }
              ]
            : isDetailFetching
            ? []
            : [
                {
                  ...BUTTON_PROPS.PRIMARY_BUTTON,
                  label: 'Schedule and Start',
                  value: 'add_event',
                  customImage: '',
                  buttonVariant: `ac-btn ac-secondary ac-icon-btn ac-block csv green-btn`,
                  isDisable: disableScheduleButton()
                },
                ...(disableEditCampaignData()
                  ? []
                  : [
                      ...(campaignDetailStatus === 'ACTIVE'
                        ? [
                            {
                              ...BUTTON_PROPS.PRIMARY_BUTTON,
                              label: 'Stop Campaign',
                              value: 'stop_campaign',
                              customImage: '',
                              buttonVariant: 'ac-btn ac-secondary ac-block ac-danger stopCmpgn'
                            }
                          ]
                        : []),
                      {
                        ...BUTTON_PROPS.PRIMARY_EXPORT_CSV,
                        label: 'Delete Campaign',
                        value: 'delete_campaign',
                        customImage: '',
                        buttonVariant: 'ac-btn ac-secondary ac-block ac-danger dc-red-btn'
                      },
                      {
                        ...BUTTON_PROPS.PRIMARY_BUTTON,
                        label: 'Create Post',
                        value: 'create_post',
                        customImage: 'add-white',
                        buttonVariant: 'ac-btn ac-primary ac-block r-flx r-flx-jc cp-blue-btn',
                        isDisable: disableCreatePostButton()
                      },
                      {
                        ...BUTTON_PROPS.SECONDARY_BUTTON,
                        label: 'Add Post',
                        value: 'add_posts',
                        isDisable: disableAddPostButton()
                      }
                    ])
              ]
        }
        sectionVariant={`post--filter post-filter__main campaign-filter${optionalParams?.length > 1 ? ` events-none pointer-events-none` : ''}`}
      />
      <CreateOrEditCampaignModal isShowModal={Boolean(queryParams.create_campaign === '1' || queryParams.edit_campaign)} onModalClose={() => handleCloseCreateOrEditCampaign()} />
      <StartOrEditCampaignEventModal
        isShowModal={Boolean(queryParams?.add_event === '1' || queryParams?.edit_event)}
        onModalClose={() => {
          handleChange({ add_event: '0', edit_event: '0' });
          dispatch(franchisorScheduleTreeReset());
        }}
      />
      <CreateOrEditPostModal
        type={CommonAction.ADD}
        isShowModal={queryParams?.create_post === '1'}
        onModalClose={() => handleChange({ create_post: '0' })}
        headerActionSection={
          <button
            className={`gnrc-btn blue-gnr ac-btn${handleCreatePostSaveButtonClassname()}`}
            onClick={() => (userOwnership === USER_OWNERSHIP.FRANCHISOR ? handleUpdateContentData() : handleAddSavedPostData())}
          >
            <span>Done</span>
          </button>
        }
      />
      <AddPostsToCampaignModal
        isShowModal={queryParams?.add_posts === '1'}
        onModalClose={handleCloseAddPosts}
        headerActionSection={
          <button className={handleAddPostSaveButtonClassname()} onClick={handleAddPostsToCampaign}>
            <span>Done</span>
          </button>
        }
        renderExtra={
          <MgFavTagModalPopup isModalShow={queryParams?.favorite_tags === '1'} isTagFetching={isTagFetching} handleModalClose={() => handleChange({ favorite_tags: '0' })} favTagsList={favTagsList} />
        }
      />
      <DeleteCampaignConfirmModal campaignId={+validCampaignId} isShowModal={isDeleteCampaign} onModalClose={() => setIsDeleteCampaign(false)} />
      <DeactivateCampaignEventConfirmModal campaignId={+validCampaignId} isShowModal={isStopCampaign} onModalClose={() => setIsStopCampaign(false)} />
    </>
  );
};
