// REVIEW ANALYTICS
export const REVIEW_ANALYTICS_FAILED = `Error occured in Review analytics`;
export const REVIEW_ANALYTICS_FETCHING_LIST_FAILED = `Fetching Review analytics list failed!`;

// PROFILE ANALYTICS
export const PROFILE_ANALYTICS_FAILED = `Error occured in Profile analytics`;
export const PROFILE_ANALYTICS_FETCHING_LIST_FAILED = `Fetching Profile analytics list failed!`;

// CONTENT ANALYTICS
export const CONTENT_ANALYTICS_FAILED = `Error occured in Content analytics`;
export const CONTENT_ANALYTICS_FETCHING_LIST_FAILED = `Fetching Content analytics list failed!`;

// LEADER BOARD ANALYTICS
export const LEADER_BOARD_ANALYTICS_FAILTED = `Error occured in Leader board analytics`;
export const LEADER_BOARD_FETCHING_LIST_FAILED = `Fetching Leader board analytics list failed!`;

// SOCIAL ANALYTICS
export const SOCIAL_ANALYTICS_FAILED = `Error occured in Social analytics`;
export const SOCIAL_ANALYTICS_FETCHING_LIST_FAILED = `Fetching Social analytics list failed!`;

// LEADER BOARD ANALYTICS
export const KEYWORDS_CATEGORY_ANALYTICS_FAILTED = `Error occured in keywords category analytics`;
export const KEYWORDS_CATEGORY_FETCHING_LIST_FAILED = `Fetching keywords category analytics list failed!`;
