import { useState } from 'react';

import { ImageValidation } from 'widgets/Media';
import { EditBoostGoal } from './EditBoostGoal';
import { FB_ADS_GOAL_LIST } from 'utils/constants';
import { commonBoost } from 'types';

export const ViewBoostGoal = ({ goalObjective, handleUpdateFBBoostConfig }: commonBoost.IViewBoostGoalProps) => {
  const [openEditGoal, setOpenEditGoal] = useState(false);

  return (
    <>
      <div className="boost__white--box goal-wrp r-mt0">
        <div className="r-flx r-flx-jb">
          <div>
            <span className="boost__box--title">Goal</span>
            <div className="boost__box--qn">What results would you like to see?</div>
          </div>
          <div className="boost__media--icon cu-pointer">
            <ImageValidation isImgValid defaultImg="edit-rd" customName="edit" customClassname="edit" onClick={() => setOpenEditGoal(true)} />
          </div>
        </div>
        {FB_ADS_GOAL_LIST.map((goalDatum, index) => {
          return (
            goalObjective === goalDatum.value && (
              <div className="boost__goal--box r-flx" key={`selected-boost-goal-${index}`}>
                <div className="boost__media--icon">
                  <ImageValidation isImgValid defaultImg={`${goalDatum.imgSrc}${goalDatum.value === goalObjective ? `-active` : ``}`} customName={goalDatum.value} customClassname="edit" />
                </div>
                <div>
                  <span className="boost__inner--title">{goalDatum.label}</span>
                  <div className="boost__desc--txt">{goalDatum.desc}</div>
                </div>
              </div>
            )
          );
        })}
      </div>
      <EditBoostGoal isModalShow={openEditGoal} handleModalClose={() => setOpenEditGoal(false)} goalObjective={goalObjective} handleUpdateFBBoostConfig={handleUpdateFBBoostConfig} />
    </>
  );
};
