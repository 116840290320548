import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  logoutRequest,
  exceptionHandlerRequest,
  getTimeZoneResponse,
  getTimeZoneFailure,
  getCountriesCodeResponse,
  getCountriesCodeFailure,
  settingsValidatePostPersonalizationResponse,
  settingsValidatePostPersonalizationFailure
} from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { GET_TIME_ZONES_FAILED, GET_COUNTRIES_CODE_FAILED, ACCOUNT_PROFILE_EXCEPTION_ERROR } from 'utils/constants';
import { ISagaPayload } from 'types/common/api';

function* sendTimeZonesGetRequest(): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getTimeZone });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getTimeZoneResponse(response.data));
    } else {
      yield put(getTimeZoneFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(GET_TIME_ZONES_FAILED));
  }
}

function* sendCountriesCodeGetRequest(): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...API.getCountriesCode });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getCountriesCodeResponse(response.data));
    } else {
      yield put(getCountriesCodeFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(GET_COUNTRIES_CODE_FAILED));
  }
}

function* sendAccValidatePostPersonalizationRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = { ...API.validatePostPersonalization };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(settingsValidatePostPersonalizationResponse(response.data));
    } else {
      yield put(settingsValidatePostPersonalizationFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ACCOUNT_PROFILE_EXCEPTION_ERROR));
  }
}

export function* takeSettingsRallioProfileRequest() {
  yield takeEvery(types.GET_TIME_ZONES_REQUEST, sendTimeZonesGetRequest);
  yield takeEvery(types.GET_COUNTRIES_CODE_REQUEST, sendCountriesCodeGetRequest);
  yield takeLatest(types.SETTINGS_VALIDATE_POST_PERSONALIZATION_REQUEST, sendAccValidatePostPersonalizationRequest);
}
