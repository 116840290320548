import { loginReducerType } from 'types';
import { FranchisorAccessType } from 'utils/constants';

export const franchisorAccessLevel = (isAdmin: boolean, franchisorId: number, contentWriters: loginReducerType.ILoginResponseData[] = []) => {
  if (isAdmin) {
    return FranchisorAccessType.ADMIN;
  } else if (contentWriters.find((it) => it.id === franchisorId)) {
    return FranchisorAccessType.CONTENT_WRITER;
  } else {
    return FranchisorAccessType.FRANCHISOR;
  }
};

export const hasFranFullAccess = (isAdmin: boolean, franchisorId: number, contentWriters: loginReducerType.ILoginResponseData[] = []) => {
  const franAccessLevel = franchisorAccessLevel(isAdmin, franchisorId, contentWriters);
  return franAccessLevel === FranchisorAccessType.FRANCHISOR || franAccessLevel === FranchisorAccessType.ADMIN;
};
