import { takeLatest, put } from 'redux-saga/effects';
import { EventSourcePolyfill } from 'event-source-polyfill';

import * as types from 'actions/action-types';
import { apiCall, defaultHeader, API, NODE_API, eventSourcePolyfillHeader } from 'utils/helpers';
import {
  accountGetProfileRequest,
  connectInstagramSuccess,
  disconnectInstagramSuccess,
  facebookPagesRequestFail,
  facebookPagesResponse,
  fbPageProvidingUrlFailure,
  fbPageProvidingUrlResponse,
  linkedInPagesResponse,
  linkedInRequestFail,
  loadGoogleBusinessAccountResponse,
  loadGoogleLocationResponse,
  saveFbUserCredentialsResponse,
  updateSocialMediaSetting,
  updateSocialMediaSettingSuccessOrFailure
} from 'actions';
import { API_TYPES } from 'utils/constants';
import { config } from 'config';
import { ISagaPayload } from 'types/common/api';
import { store } from 'store';

function* sendUpdateSocialMediaSettings(socialMediaParams: any): any {
  try {
    const headers = defaultHeader();
    const data = socialMediaParams.payload;
    let response;
    if (data.id) {
      const apiPath = `${API.updateSocialMediaSetting.apiPath.replace(':id', data.id)}`;
      response = yield apiCall({ headers, data, apiPath, action: API.updateSocialMediaSetting.action });
    } else {
      response = yield apiCall({ headers, data, ...API.saveSocialMediaSetting });
    }
    if (response.status === 200) {
      yield put(accountGetProfileRequest({ id: data.account_id, isDataRetain: true }));
      if (data.callback) {
        yield put(data.callback(socialMediaParams.payload.social_medium.oauth_token));
      }
    } else if (response.status === 400 && data.type === 'linkedin') {
      yield put(linkedInRequestFail({ error: response.data.message }));
    }
  } catch (error) {
    yield put(updateSocialMediaSettingSuccessOrFailure({ type: socialMediaParams.payload.type }));
  }
}

function* sendDeleteSocialMediaSettings(deleteParams: any): any {
  try {
    const headers = defaultHeader();
    const apiPath = `${API.deleteSocialMediaSetting.apiPath.replace(':id', deleteParams.payload.socialMediumID)}?resource_type=enduser_settings&resource_id=${deleteParams.payload.id}`;
    const response: any = yield apiCall({ headers, params: {}, apiPath, action: API.deleteSocialMediaSetting.action });
    if (response.status === 200) {
      if (deleteParams.payload.callback) {
        yield put(deleteParams.payload.callback(deleteParams.payload.callbackParams));
      } else {
        yield put(accountGetProfileRequest({ id: deleteParams.payload.id, isDataRetain: true }));
      }
    }
  } catch (error) {}
}

function* fetchLinkedInPages(linkedinParams: any): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, type: API_TYPES.NODE, params: linkedinParams.payload, apiPath: NODE_API.getLinkedInPages.apiPath, action: NODE_API.getLinkedInPages.action });
    yield put(linkedInPagesResponse({ data: response.data }));
  } catch (error) {
    yield put(linkedInRequestFail({ error }));
  }
}

function* saveFbUserCredentials(fbParams: any): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({
      headers,
      data: {
        facebook_user_credential: {
          access_token: fbParams.payload.accessToken
        }
      },
      ...API.saveUserCredentials
    });
    if (response.status === 200) {
      // yield apiCall({
      //   headers,
      //   type: API_TYPES.NODE,
      //   data: { credential_id: response.data.credential_id, access_token: fbParams.payload.accessToken, type: 'user' },
      //   ...NODE_API.updateFbAccessToken
      // });
      yield put(saveFbUserCredentialsResponse({ id: response.data.facebook_user_credential.id }));
      if (fbParams.payload.callback) {
        fbParams.payload.callback(response.data.facebook_user_credential.id, fbParams.payload.accessToken, fbParams.payload.mediaName);
      }
    }
  } catch (error) {}
}

function* loadFbPages(params: any): any {
  try {
    const headers = eventSourcePolyfillHeader();
    // const headers = defaultHeader();
    // const url = `${FACEBOOK_API.getPages.apiPath.replace(':id', params.payload.id)}?fields=id,name,link,picture,location,access_token,instagram_business_account`;
    // const response = yield apiCall({ headers, type: API_TYPES.FACEBOOK, params: { access_token: params.payload.access_token }, apiPath: url, action: FACEBOOK_API.getPages.action });
    // let response;
    if (params.payload.type === 'facebook') {
      const apiPath = API.getFBAdAccountPageList.apiPath.replace(':id', params.payload.id);
      const eventSource = new EventSourcePolyfill(`${config.apiBaseURL}${config.apiVersionPath}${apiPath}?group_size=50`, {
        headers: {
          'X-Rallio-API-Key': headers['X-Rallio-API-Key']
        }
      });
      eventSource.addEventListener('facebook_page_credentials', (event: any) => {
        store.dispatch(facebookPagesResponse({ data: [...JSON.parse(event.data || '').facebook_page_credentials] }));
      });

      eventSource.addEventListener('error', (e) => {
        eventSource.close();
        store.dispatch(facebookPagesResponse({ data: [] }));
      });
      // const url = API.getFBAdAccountPageList.apiPath.replace(':id', params.payload.id);
      // response = yield apiCall({ headers, apiPath: url, params: { refresh: params.payload.refresh }, action: API.getFBAdAccountPageList.action });
    } else {
      const apiPath = API.getInstagramPageList.apiPath.replace(':id', params.payload.id);
      const eventSource = new EventSourcePolyfill(`${config.apiBaseURL}${config.apiVersionPath}${apiPath}`, {
        headers: {
          'X-Rallio-API-Key': headers['X-Rallio-API-Key']
        }
      });
      eventSource.addEventListener('instagram_business_accounts', (event: any) => {
        store.dispatch(facebookPagesResponse({ data: [...JSON.parse(event.data || '').instagram_business_accounts] }));
      });

      eventSource.addEventListener('error', (e) => {
        eventSource.close();
        store.dispatch(facebookPagesResponse({ data: [] }));
      });
      // const url = API.getInstagramPageList.apiPath.replace(':id', params.payload.id);
      // response = yield apiCall({ headers, apiPath: url, params: { refresh: params.payload.refresh }, action: API.getInstagramPageList.action });
    }
    /* if (response.status === 200) {
      if (params.payload.type === 'facebook') {
        let fbPages: any = [];
        if (response.data) {
          response.data.split('event: facebook_page_credentials\ndata: ').forEach((it: string) => {
            if (it) {
              fbPages = fbPages.concat(JSON.parse(it).facebook_page_credentials);
            }
          });
        }
        yield put(facebookPagesResponse({ data: fbPages }));
      } else {
        let instaPages: any = [];
        if (response.data) {
          response.data.split('event: instagram_business_accounts\ndata: ').forEach((it: string) => {
            if (it) {
              instaPages = instaPages.concat(JSON.parse(it).instagram_business_accounts);
            }
          });
        }
        yield put(facebookPagesResponse({ data: instaPages }));
      }
    } */
  } catch (error) {
    yield put(facebookPagesRequestFail({ error }));
  }
}

function* saveFbPageCredentials(pageParams: any): any {
  try {
    if (pageParams.payload.isInstagram) {
      const apiPath = API.connectInstagramSocialMedia.apiPath.replace(':id', pageParams.payload.account_id);
      const response = yield apiCall({
        headers: defaultHeader(),
        apiPath,
        action: API.connectInstagramSocialMedia.action,
        data: {
          facebook_page_credential_id: pageParams.payload.credential_id
        }
      });
      if (response.status === 200) {
        yield put(accountGetProfileRequest({ id: pageParams.payload.account_id, isDataRetain: true }));
      }
    } else {
      const mediaPayload = {
        social_medium: {
          provider_name: 'facebook',
          profile_url: pageParams.url,
          oauth_token: '',
          data: pageParams.payload,
          social_media_id: pageParams.payload.facebook_id,
          social_media_type: 'Page',
          facebook_id: pageParams.payload.user_credential_id,
          facebook_page_credential_id: pageParams.payload.credential_id,
          profile_picture_url: pageParams.payload.image_url
        },
        type: 'facebook',
        account_id: pageParams.payload.account_id,
        connect_instagram_public_content: pageParams.payload.instagramAccess,
        connect_instagram_business_page: pageParams.payload.instagramAccess
      };
      yield put(updateSocialMediaSetting(mediaPayload));
    }
  } catch (error) {}
}

function* connectInstagramRequest(params: any): any {
  try {
    const url = API.connectInstagram.apiPath.replace(':id', params.payload.id);
    yield apiCall({
      headers: defaultHeader(),
      apiPath: url,
      action: API.connectInstagram.action
    });
    yield put(connectInstagramSuccess());
    yield put(accountGetProfileRequest({ id: params.payload.id, isDataRetain: true }));
  } catch (error) {
    yield put(connectInstagramSuccess());
  }
}

function* disconnectInstagramRequest(params: any): any {
  try {
    const url = API.disconnectInstagram.apiPath.replace(':id', params.payload.id);
    yield apiCall({
      headers: defaultHeader(),
      apiPath: url,
      action: API.disconnectInstagram.action
    });
    yield put(disconnectInstagramSuccess());
    yield put(accountGetProfileRequest({ id: params.payload.id, isDataRetain: true }));
  } catch (error) {
    yield put(disconnectInstagramSuccess());
  }
}

function* loadGoogleAccountsByCredentialId(action: ISagaPayload): any {
  try {
    const apiPath = API.getGoogleBusinessAccounts.apiPath.replace(':id', action.payload.credentialId);
    const response = yield apiCall({ headers: defaultHeader(), apiPath, action: API.getGoogleBusinessAccounts.action });
    yield put(loadGoogleBusinessAccountResponse({ data: response.data.google_my_business_accounts }));
  } catch (error) {
    yield put(loadGoogleBusinessAccountResponse({ data: [] }));
  }
}

function* loadGoogleLocationsByAccountId(action: ISagaPayload): any {
  try {
    const apiPath = API.getGoogleAccountLocations.apiPath.replace(':id', action.payload.credentialId).replace(':accountId', action.payload.accountId);
    const eventSource = new EventSource(`${config.apiBaseURL}${config.apiVersionPath}${apiPath}`);
    let chunkNumber = 1;
    eventSource.addEventListener('google_my_business_locations', (event) => {
      action.payload.eventCallback(event, chunkNumber++);
    });

    eventSource.addEventListener('error', (e) => {
      eventSource.close();
      store.dispatch(loadGoogleLocationResponse({ data: [] }));
    });
  } catch (error) {
    yield put(loadGoogleLocationResponse({ data: [] }));
  }
}

function* connectGoogleSocialMedia(action: ISagaPayload): any {
  try {
    const apiPath = API.connectGoogle.apiPath.replace(':id', action.payload.accountId);
    const response = yield apiCall({
      headers: defaultHeader(),
      data: {
        google_my_business_location_id: action.payload.googlePlacesId,
        google_oauth2_credential_id: action.payload.googleCredentialId
      },
      apiPath,
      action: API.connectGoogle.action
    });
    if (response.status === 200) {
      yield put(accountGetProfileRequest({ id: action.payload.accountId, isDataRetain: true }));
    }
  } catch (error) {}
}

function* getFbPageProdivingUrl(action: ISagaPayload): any {
  try {
    const apiPath = API.getFBAdAccountPageByUrl.apiPath.replace(':id', action.payload.id);
    const response = yield apiCall({
      headers: defaultHeader(),
      data: {
        page_url: action.payload.url
      },
      apiPath,
      action: API.getFBAdAccountPageByUrl.action
    });

    if (response.status === 200) {
      yield put(fbPageProvidingUrlResponse({ data: [response.data?.facebook_page_credential] }));
    } else {
      yield put(fbPageProvidingUrlFailure({ error: response.data.message }));
    }
  } catch (error) {
    yield put(fbPageProvidingUrlFailure({ error }));
  }
}

export function* takeSettingsSocialProfileRequest() {
  yield takeLatest(types.UPDATE_SOCIAL_MEDIA_SETTING, sendUpdateSocialMediaSettings);
  yield takeLatest(types.DELETE_SOCIAL_MEDIA_SETTING, sendDeleteSocialMediaSettings);
  yield takeLatest(types.LINKEDIN_PAGES_REQUEST, fetchLinkedInPages);
  yield takeLatest(types.SAVE_FACEBOOK_USER_CREDENTIAL, saveFbUserCredentials);
  yield takeLatest(types.FACEBOOK_PAGES_REQUEST, loadFbPages);
  yield takeLatest(types.FB_PAGE_PROVIDING_URL_REQUEST, getFbPageProdivingUrl);
  yield takeLatest(types.SAVE_FACEBOOK_PAGE_CREDENTIAL, saveFbPageCredentials);
  yield takeLatest(types.CONNECT_INSTAGRAM, connectInstagramRequest);
  yield takeLatest(types.DISCONNECT_INSTAGRAM, disconnectInstagramRequest);
  yield takeLatest(types.LOAD_GOOGLE_BUSINESS_ACCOUNT_REQUEST, loadGoogleAccountsByCredentialId);
  yield takeLatest(types.LOAD_GOOGLE_LOCATIONS_REQUEST, loadGoogleLocationsByAccountId);
  yield takeLatest(types.CONNECT_GOOGLE_REQUEST, connectGoogleSocialMedia);
}
