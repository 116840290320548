import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { calendarReducerTypes, IStore } from 'types';
import { USER_OWNERSHIP, CALENDER_STATS, CALENDAR_FILTER_INITIAL_VALUE, CALENDAR_PAGE_VIEW_TYPES, ROUTES } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { CalendarTotalStatsCount } from 'widgets/TotalStatsCount';
import { getRequestedPostList, checkSiteRestriction } from 'utils/helpers';
import { useParamsDeconstructor, useAccountSwitcherData, useSiteRestriction } from 'utils/hooks';
import { handleFilterChange } from 'analytics/utils';

export const CalendarStats = () => {
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();
  const { calendarRestriction } = useSiteRestriction();
  const { queryParams } = useParamsDeconstructor({ ...CALENDAR_FILTER_INITIAL_VALUE });

  const scheduleMultiPostList = useSelector((state: IStore) => state.calendar.scheduleMultiPostList);
  const scheduledPostList = useSelector((state: IStore) => state.calendar.scheduledPostList);
  const isFetching = useSelector((state: IStore) => state.calendar.isFetching || state.calendar.isEventPostFetching);
  const calendarItemList = useSelector((state: IStore) => state.calendar.calendarItemList);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const permissionCalendarStats = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions);

  const pageView = queryParams?.view || 'calendar';
  const platform = queryParams?.platform || 'all';
  const calendarEventItem = calendarItemList?.length ? getRequestedPostList(calendarItemList, timeZone || '') : null;
  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const data = isHubUser ? scheduleMultiPostList : scheduledPostList.filter((it) => !it?.hidden || !it?.draft);
  const statsValueHandler = isHubUser ? CALENDER_STATS.filter((it) => it.isHub) : CALENDER_STATS;

  let statsFiltered =
    !isHubUser && (checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/awaiting_approval') || checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/events'))
      ? statsValueHandler.filter((it) => !it.value.includes('awaiting_approval') && !it.value.includes('events'))
      : statsValueHandler;

  statsFiltered =
    permissionCalendarStats?.corporate_posts_auto_approved || permissionCalendarStats?.overridden_corporate_posts_auto_approved
      ? statsFiltered.filter((it) => !['awaiting_approval'].includes(it.value))
      : statsFiltered;

  // useMemo(() => {
  //   if (!isHubUser) {
  //     if (checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/awaiting_approval') || checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/events')) {
  //       return statsValueHandler.filter((it) => !it.value.includes('awaiting_approval') && !it.value.includes('events'));
  //     } else {
  //       return statsValueHandler;
  //     }
  //   }
  //   return statsValueHandler;
  // }, [menuAccess, isHubUser, statsValueHandler]);

  const totalStatsCount = isHubUser ? data?.length : calendarEventItem ? data?.length + calendarEventItem?.length || 0 : data?.length;
  const awaitingApprovalCount = scheduledPostList.filter((it) => it.draft).length;
  const scheduledCount = isHubUser ? data?.length : data?.length - awaitingApprovalCount;

  // this function is to route to creator tab
  const handleCreatorNavigation = () => {
    navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()));
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const statDispatchHandler = (statsType: string) => {
    if (statsType === 'all') {
      routePush(`/${userOwnership}/${id.toString()}/${currentRoute}`, queryParams);
    } else {
      routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${statsType}`, queryParams);
    }
  };

  const handleChange = (data: Partial<calendarReducerTypes.ICalendarFiltersVal>, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    if (data.view) {
      if (data.view === CALENDAR_PAGE_VIEW_TYPES.LIST) {
        queryParamsObj.view = data.view;
        delete queryParamsObj.platform;
      } else delete queryParamsObj.view;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const calendarViewHandler = (view: string) => {
    handleChange({ view });
  };

  return (
    <>
      <div className="calendar-stats-section-wrp mbl-head--margin">
        <div className={`stats global-stats-section-wrp lpx vpy-20 g-20`}>
          {!isHubUser && statsFiltered.length > 1 && (
            <div className="stats-item mx-0 total-count stats-level-02" onClick={() => statDispatchHandler('all')}>
              <div className="chart-module-top">
                <span className="mod-head">Calendar</span>
                {isFetching ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <span className="mod-count">
                    <ReactSpringCountUp
                      // sum of (no.of (scheduled,awaiting approval, event))
                      end={totalStatsCount}
                    />
                  </span>
                )}
              </div>
              <div className="chart-container">
                <CalendarTotalStatsCount
                  schedule={data?.length}
                  approval={awaitingApprovalCount}
                  events={calendarEventItem ? calendarEventItem?.length : 0}
                  totalStatCount={totalStatsCount}
                  filters={{ platform }}
                />
              </div>
            </div>
          )}
          {statsFiltered?.map((datum, index) => {
            return (
              <div
                key={`stats-section-${index}`}
                className={`stats-item mx-0 fb-sc stats-level-02 ${datum.name ? datum.name : ''} ${optionalParams[0] === datum.value ? ` active` : ``}`}
                onClick={() => statDispatchHandler(datum.value)}
              >
                {' '}
                <div className="chart-module-top">
                  <span className="platform-text">{datum.label}</span>
                  {isFetching ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <span className="platform-count">
                      <ReactSpringCountUp end={datum.value === 'scheduled' ? scheduledCount : datum.value === 'awaiting_approval' ? awaitingApprovalCount : calendarEventItem?.length || 0} />
                    </span>
                  )}
                </div>
                <div className="platform-icon">
                  <ImageValidation isImgValid defaultImg={datum.image} isNotSvgFormat customName={'statsImage'} />
                </div>
              </div>
            );
          })}

          <div
            className={`stats-item mx-0 sicp stats-level-02 ${pageView === CALENDAR_PAGE_VIEW_TYPES.LIST ? 'calendar-view-wrp-section' : 'calendar-view-wrp-section-grid'}`}
            onClick={() => {
              calendarViewHandler(pageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR ? CALENDAR_PAGE_VIEW_TYPES.LIST : CALENDAR_PAGE_VIEW_TYPES.CALENDAR);
            }}
          >
            <span className="mod-head">{pageView === CALENDAR_PAGE_VIEW_TYPES.LIST ? 'CALENDAR VIEW' : 'LIST VIEW'}</span>
          </div>
          {!calendarRestriction?.create_post ? (
            <div className="stats-item mx-0 sicp" onClick={() => handleCreatorNavigation()}>
              <span className="mod-head">CREATE POST</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
