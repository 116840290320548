import { campaignsContainerTypes } from 'types';

export const START_CAMPAIGN_POSTS_INIT_DATA: campaignsContainerTypes.IStartCampaignFieldValue = {
  campaign_id: 0,
  campaign_event_id: 0,
  campaign_post_per_week: 0,
  end_date: null,
  weekly_schedule: [],
  included_accounts: [],
  duration_type: 'start_date_and_end_date'
};

export const START_CAMPAIGN_POSTS_FILEDS: campaignsContainerTypes.IStartCampaignFields = {
  CAMPAIGN_POST_PER_WEEK: 'campaign_post_per_week',
  END_DATE: 'end_date',
  WEEKLY_SCHEDULE: 'weekly_schedule',
  INCLUDED_ACCOUNTS: 'included_accounts',
  DURATION_TYPE: 'duration_type'
};

export const WEEKLY_SCHEDULE_CAMPAIGN_DATA: campaignsContainerTypes.ICampaignPostsWeeklySchedule[] = [
  { id: '0', value: 'Sun', label: 'Su', postSlot: [] },
  { id: '1', value: 'Mon', label: 'Mo', postSlot: [] },
  { id: '2', value: 'Tue', label: 'Tu', postSlot: [] },
  { id: '3', value: 'Wed', label: 'We', postSlot: [] },
  { id: '4', value: 'Thu', label: 'Th', postSlot: [] },
  { id: '5', value: 'Fri', label: 'Fr', postSlot: [] },
  { id: '6', value: 'Sat', label: 'Sa', postSlot: [] }
];

export enum CampaignDurationType {
  ONCE = 'once',
  END_DATE = 'end_date',
  ONGOING = 'ongoing'
}

export const CAMPAIGN_POSTS_DURATION_TYPE = [
  { inputType: 'radio', value: CampaignDurationType.END_DATE, label: 'Set End Date' },
  { inputType: 'radio', value: CampaignDurationType.ONGOING, label: 'Ongoing' }
];

export const CAMPAIGN_POSTS_INIT_ACTIVE_SLOT: campaignsContainerTypes.ICampaignPostsActiveSlot = {
  slotId: '',
  slotHours: '00',
  slotMinutes: '00',
  slotMeridiem: 'AM',
  editIndex: null,
  activeTab: 'slotHours'
};

export const CAMPAIGN_INIT_FILTER_DATA: campaignsContainerTypes.ICampaignsFilter = {
  search: '',
  source: 'all',
  status: 'all'
};

export enum CampaignsStatus {
  ALL = 'all',
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive',
  AVAILABLE = 'available'
}

export const CAMPAIGNS_STATUS = [
  { label: 'All', value: CampaignsStatus.ALL },
  { label: 'Active Campaigns', value: CampaignsStatus.ACTIVE },
  { label: 'Inactive Campaigns', value: CampaignsStatus.IN_ACTIVE },
  { label: 'Available Campaigns', value: CampaignsStatus.AVAILABLE }
];

export enum CampaignsScheduleStatus {
  ALL = 'all',
  ONGOING = 'ongoing',
  COMPLETED = 'completed'
}

export const CAMPAIGNS_SCHEDULE_STATUS = [
  { label: 'All', value: CampaignsScheduleStatus.ALL },
  { label: 'Ongoing', value: CampaignsScheduleStatus.ONGOING },
  { label: 'Completed', value: CampaignsScheduleStatus.COMPLETED }
];

export enum CampaignsValidQueryParams {
  SEARCH = 'text',
  SOURCE = 'source',
  STATUS = 'status',
  CREATE_CAMPAIGN = 'create_campaign',
  EDIT_CAMPAIGN = 'edit_campaign',
  ADD_EVENT = 'add_event',
  EDIT_EVENT = 'edit_event',
  CREATE_POST = 'create_post',
  ADD_POSTS = 'add_posts',
  SCHEDULES = 'schedules',
  SCHEDULE_STATUS = 'schedule_status'
}

export const CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS: campaignsContainerTypes.ICreateOrEditCampaignModalField = {
  NAME: 'name',
  DESCRIPTION: 'description',
  AVAILABILITY: 'availability',
  EXPIRED_DATE: 'expired_date'
};

export enum ICampaignAvailability {
  EXPIRES = 'expires',
  ONGOING = 'ongoing'
}

export const CREATE_OR_EDIT_CAMPAIGN_AVAILABILITY_LIST = [
  { label: 'Expires On', value: ICampaignAvailability.EXPIRES },
  { label: 'Ongoing', value: ICampaignAvailability.ONGOING }
];

export const CAMPAIGN_SCHEDULE_TABLE_HEADER_LABEL = {
  STATUS: '',
  STARTED_BY: 'Started By',
  ENDS_ON: 'Ends On',
  NUMBER_OF_POST_PER_WEEK: 'Number of post per week',
  NUMBER_OF_LOCATION: 'Number of Location',
  ACTION: 'Action',
  EXTRA_ACTION: ''
};

export const CAMPAIGN_SCHEDULE_TABLE_HEADER_KEY = {
  STATUS: 'status',
  STARTED_BY: 'started_by',
  ENDS_ON: 'ends_on',
  NUMBER_OF_POST_PER_WEEK: 'number_of_post_per_week',
  NUMBER_OF_LOCATION: 'number_of_location',
  ACTION: 'action',
  EXTRA_ACTION: 'extra_action'
};

export enum ICampaignSourceType {
  ALL = 'all',
  BRAND = 'brand',
  LOCATION = 'location'
}

export const CAMPAIGN_SOURCE_TYPE = [
  { value: ICampaignSourceType.ALL, label: 'All' },
  { value: ICampaignSourceType.BRAND, label: 'Brand' },
  { value: ICampaignSourceType.LOCATION, label: 'Location' }
];

export const CAMPAIGN_ALREADY_SCHEDULED_LOC_TOOLTIP = 'This campaign is already scheduled for this location';
