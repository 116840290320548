import { directoryListingsReducerTypes, directoryListingsContainerTypes } from 'types';

export const DL_PROFILE_TABS: directoryListingsReducerTypes.IDLProfileTabs = {
  STORE_DETAILS: 'store_details',
  BUSINESS_HOURS: 'business_hours',
  ADD_PHOTOS: 'add_photos'
};

export const STORE_DETAILS_INITIAL_VALUE: directoryListingsContainerTypes.IStoreDetailsTabFieldValue = {
  dlm_name: null,
  tagline: null,
  dlm_store_code: null,
  payment_methods: null,
  dlm_street: null,
  synup_sub_category_name: null,
  dlm_city: null,
  synup_additional_category_ids: [],
  dlm_state: null,
  dlm_postal_code: null,
  description: null,
  dlm_country_code: null,
  dlm_url: null,
  dlm_phone: null,
  dlm_facebook_url: null,
  latitude: null,
  dlm_twitter_url: null,
  longitude: null,
  dlm_linkedin_url: null,
  dlm_appointment_link_url: null,
  dlm_hide_address: false,
  id: 0,
  owner_email: null,
  owner_name: null,
  synup_location_id: null,
  synup_sub_category_id: null,
  dlm_opening_date: null,
  year_of_incorporation: null,
  dlm_categories: [],
  dlm_source: null,
  dlm_service_area: null
};

export const UPDATE_DL_INITIAL_STATUS: directoryListingsReducerTypes.IUpdateDLDetails = {
  isUpdated: false,
  updateSuccessMsg: null,
  updateFailureMsg: null
};
