import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { IStore } from 'types';
import { FB_ADS_TARGET_GENDER_LIST, FbAdsGenderType } from 'utils/constants';
import { getNthDecimalPlace } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';

export const PostsDetailViewBoostOffer = () => {
  const boostOfferData = useSelector((state: IStore) => state.posts.boostOfferData);

  const ageMarkStyle = {
    [boostOfferData[0]?.boost_offer_configuration_template.min_age ? +boostOfferData[0]?.boost_offer_configuration_template.min_age : 18]: {
      label: <span className={'rsmark-txt'}>{`${boostOfferData[0]?.boost_offer_configuration_template.min_age || `18`}`}</span>
    },
    [boostOfferData[0]?.boost_offer_configuration_template.max_age ? +boostOfferData[0]?.boost_offer_configuration_template.max_age : 66]: {
      label: <span className={'rsmark-txt'}>{`${boostOfferData[0]?.boost_offer_configuration_template.max_age || `65+`}`}</span>
    }
  };

  return (
    <div className="st-tc-item botc-tab">
      <div className="wb-top">
        <div className="wb-label">
          <span className="wbl-head">Budget</span>
        </div>
        <div className="wb-f-main">
          <div className="wb-fields">
            <div className="wbf-item">
              <div className="wbf-label-main">
                <span className="wbf-label">Spend</span>
              </div>
              <div className="wbf-inputs">
                <div className="input-fields spend-value">
                  <div className="if-main if-h">
                    <span className="ifa">$</span>
                    <Form.Control
                      type="number"
                      placeholder=""
                      className={'plain-input'}
                      aria-describedby="inputGroupPrepend"
                      value={boostOfferData[0]?.lifetime_spend_per_ad ? getNthDecimalPlace(boostOfferData[0].lifetime_spend_per_ad || 0, 100) : 0}
                      name={'spend'}
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                  <span className="country-initials">U.S.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="wb-fields">
            <div className="wbf-item">
              <div className="wbf-label-main">
                <span className="wbf-label">Boost Offers Remaining</span>
              </div>
              <div className="wbf-inputs">
                <div className="wbfi-items">
                  <span className="wbfi-txt">{boostOfferData[0]?.max_ads_count - boostOfferData[0]?.used_count || 0}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="wb-fields">
            <div className="wbf-item">
              <div className="wbf-label-main">
                <span className="wbf-label">Duration (days)</span>
              </div>
              <div className="wbf-inputs">
                <div className="wbfi-items">
                  <span className="wbfi-txt">{`${boostOfferData[0]?.duration_per_ad || 0} day`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="wb-fields">
            <div className="wbf-item">
              <div className="wbf-label-main">
                <span className="wbf-label">Associated on</span>
              </div>
              <div className="wbf-inputs">
                <div className="wbfi-items">
                  <span className="wbfi-txt">May 24, 2021</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wb-top">
        <div className="st-tc-item">
          <div className="wbfs">
            <div className="wb-label">
              <span className="wbl-head">Target Audience</span>
            </div>
            <div className="wb-f-main">
              <div className="wb-fields">
                <div className="wbf-item">
                  <div className="wbf-label-main">
                    <span className="wbf-label">Age Range (years)</span>
                  </div>
                  <div className="wbf-inputs">
                    <Slider
                      range
                      min={18}
                      max={66}
                      value={[boostOfferData[0]?.boost_offer_configuration_template.min_age || 18, boostOfferData[0]?.boost_offer_configuration_template.max_age || 66]}
                      count={1}
                      allowCross={false}
                      marks={ageMarkStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="wb-fields">
                <div className="wbf-item">
                  <div className="wbf-label-main">
                    <span className="wbf-label">Gender</span>
                  </div>
                  <div className="wbf-inputs">
                    <div className="wbfi-items">
                      {FB_ADS_TARGET_GENDER_LIST.map((genderDatum, index) => {
                        return (
                          ((!boostOfferData[0]?.boost_offer_configuration_template.gender && FbAdsGenderType.ALL === genderDatum.name) ||
                            boostOfferData[0]?.boost_offer_configuration_template.gender === genderDatum.name) && (
                            <div className="gbx-items" key={`post-bo-gender-${index}`}>
                              <div className="rel-icons">
                                <ImageValidation isImgValid defaultImg={`gender-${genderDatum.name}-a`} customName={'Gender'} />
                              </div>
                              <span className="g-txt">{genderDatum.label}</span>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="wb-fields">
                <div className="wbf-item">
                  <div className="wbf-label-main">
                    <span className="wbf-label">Interests</span>
                  </div>
                  <div className="wbf-inputs">
                    <div className="wbfi-items">
                      <span className="wbfi-txt">
                        {!boostOfferData[0]?.boost_offer_configuration_template.interests.length
                          ? `None specified`
                          : boostOfferData[0]?.boost_offer_configuration_template.interests.map((datum) => datum.name).join()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
