import { put, takeLatest } from 'redux-saga/effects';

import { revvAccountSwitcherRequest, userRequest } from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { loginFailure, loginResponse } from 'revv/actions/login';
import { ILoginResponse, ILoginUserInfo, ISagaPayload, SagaValue, SagaYield } from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { store } from 'store';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* signInRequest(action: ISagaPayload<ILoginUserInfo>): Generator<SagaYield, void, SagaValue> {
  try {
    const reqPayload = action.payload?.token ? { sso_token: action.payload?.token } : { user: { email: action.payload?.email, password: action.payload?.password } };
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.signIn.apiPath,
        action: API_ROUTES.signIn.method,
        headers: defaultRevvHeader(),
        data: reqPayload,
        type: API_TYPES.REVV_SSO
      });
      if (response.status === 401) {
        yield put(loginFailure('Invalid credentials.'));
      } else {
        const res: ILoginResponse = {
          id: response.data.id,
          token: response.data.token,
          email: response.data.email,
          role: response.data.role
        };
        yield put(loginResponse(res));
        yield put(
          userRequest({
            id: String(res.id),
            isLogin: true
          })
        );

        let topLevelFranchisorId = store.getState().accountSwitcher.topLevelFranchisorId;
        store.subscribe(() => {
          topLevelFranchisorId = store.getState().accountSwitcher.topLevelFranchisorId;
        });
        if (topLevelFranchisorId) {
          yield put(revvAccountSwitcherRequest({ franchisorId: topLevelFranchisorId }));
        }
      }
    }
  } catch (error) {
    yield put(loginFailure('error'));
  }
}

export function* takeLoginRequests() {
  yield takeLatest(actionTypes.SIGN_IN, signInRequest);
}
