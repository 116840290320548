import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { commonWidgetTypes } from 'types';

export const ReactFullCalendar = ({
  calendarRef,
  initialView,
  events,
  viewClassNames,
  initialDate,
  eventClick,
  eventContent,
  dayMaxEvents,
  eventMaxStack,
  moreLinkClick,
  customButtons,
  allDaySlot,
  eventDrop,
  eventStartEditable,
  eventAllow,
  eventConstraint,
  showHeaderToolbar = true,
  dateClick,
  scrollTime
}: commonWidgetTypes.IFullCalendarProps) => {
  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView={initialView}
      events={events}
      viewClassNames={viewClassNames}
      scrollTime={scrollTime ?? '00:00:00'}
      headerToolbar={
        showHeaderToolbar
          ? {
              left: 'dayGridMonth,dayGridWeek,timeGridDay',
              center: 'title',
              right: 'prev,today,next'
            }
          : false
      }
      views={{
        dayGridMonth: {
          type: 'monthGrid',
          duration: { month: 1 },
          buttonText: 'Month',
          selectable: true
        },
        dayGridWeek: {
          type: 'weekGrid',
          duration: { week: 1 },
          buttonText: 'Week',
          selectable: true
        },
        timeGridDay: {
          type: 'dayGrid',
          duration: { day: 1 },
          buttonText: 'Day',
          selectable: true
        },
        timeGridWeek: {
          buttonText: 'Week',
          type: 'timeGrid',
          duration: { week: 1 },
          dayHeaderFormat: { weekday: 'short' }
        }
      }}
      initialDate={initialDate}
      eventClick={eventClick}
      eventContent={eventContent}
      dayMaxEvents={dayMaxEvents} // For dayGrid view, the max number of events within a given day
      eventMaxStack={eventMaxStack} // For timeGrid view, the maximum number of events that stacks left-to-right
      moreLinkClick={moreLinkClick}
      eventOrder={'time'}
      eventOrderStrict
      customButtons={{
        dayGridMonth: {
          click: customButtons?.dayGridMonth?.click
        },
        dayGridWeek: {
          click: customButtons?.dayGridWeek?.click
        },
        timeGridDay: {
          click: customButtons?.timeGridDay?.click
        },
        ...(customButtons?.prev?.click && {
          prev: {
            click: customButtons?.prev?.click
          },
          next: {
            click: customButtons?.next?.click
          },
          today: {
            text: customButtons?.today?.text,
            click: customButtons?.today?.click
          }
        })
      }}
      allDaySlot={allDaySlot}
      slotEventOverlap={false}
      defaultTimedEventDuration={'00:00:01'}
      eventDrop={eventDrop}
      eventStartEditable={eventStartEditable}
      eventAllow={eventAllow}
      showNonCurrentDates={false}
      eventConstraint={eventConstraint} // Disable dragging and dropping outside of established boundaries
      dateClick={dateClick}
    />
  );
};
