import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { LoginAction } from 'actions';
import { loginReducerType } from 'types/auth/login';
import { deImpersonateUser, impersonateUser, updateImpersonatedUser, loginAsImpersonatedUser } from 'utils/helpers';

const initialState: loginReducerType.ILogin = {
  isAuthenticated: false,
  isPromotionFetching: false,
  isProfileImgFetching: false,
  isUserDataUpdate: false,
  isUserDataFetching: false,
  isImpersonateRequest: false,
  error: null,
  isFetching: false,
  encodedUserInfo: null,
  responsePromotion: null,
  isSSO: false,
  prevSSOToken: null,
  userProfileObj: {
    cloudinaryId: null,
    photoUrl: null
  },
  isLogoutClicked: false,
  responseUser: null,
  activeUser: null,
  activeUserBrand: null,
  activeUserFranchisor: null,
  activeUserAccount: null,
  oAuthUserValidResponse: null,
  oAuthSignOnTokensResponse: null
};

const loginReducer: Reducer<loginReducerType.ILogin, LoginAction> = (state: loginReducerType.ILogin = initialState, action: LoginAction) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isUserDataFetching: true,
        isAuthenticated: false,
        error: null,
        responseUser: (action.payload as any).responseUser || null,
        activeUser: null,
        activeUserBrand: null,
        activeUserFranchisor: null,
        activeUserAccount: null,
        lastActiveDispatchType: null,
        isSSO: !!(action.payload as any).sso_token,
        prevSSOToken: (action.payload as any).sso_token
      };
    case types.LOGIN_RESPONSE:
      const userData = {
        ...action.payload.user,
        token: action.payload.rallio_access_token.token,
        content_writers: action.payload.user_detail?.content_writers || [],
        franchisors_managed: action.payload.user_detail?.franchisors_managed || [],
        accounts_managed: action.payload.user_detail?.accounts_managed || []
      };
      const retainedImpersonatedData = state.responseUser ? loginAsImpersonatedUser(state.responseUser, userData) : { response: userData, activeUser: userData };
      return {
        ...state,
        isFetching: false,
        isUserDataFetching: false,
        isAuthenticated: true,
        error: null,
        responseUser: retainedImpersonatedData?.response || null,
        activeUser: retainedImpersonatedData?.activeUser || null,
        activeUserBrand: action.payload.brand || null,
        activeUserFranchisor: action.payload.franchisor || null,
        activeUserAccount: action.payload.account || null,
        userProfileObj: {
          cloudinaryId: action.payload?.user?.profile_photo_cloudinary_id || null,
          photoUrl: action.payload?.user?.profile_photo_url || null
        }
      };
    case types.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        isUserDataFetching: false,
        error: action.payload,
        encodedUserInfo: null
      };
    case types.OAUTH_USER_VALIDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.OAUTH_USER_VALIDATE_RESPONSE:
      return {
        ...state,
        isFetching: false,
        oAuthUserValidResponse: action.payload
      };
    case types.OAUTH_USER_VALIDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case types.GET_OAUTH_SIGN_ON_TOKENS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_OAUTH_SIGN_ON_TOKENS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        oAuthSignOnTokensResponse: action.payload
      };
    case types.GET_OAUTH_SIGN_ON_TOKENS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case types.SET_AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case types.LOGIN_UPDATED_ENCODED_USER_INFO:
      return {
        ...state,
        encodedUserInfo: action.payload
      };
    case types.LOGIN_ERROR_RESET:
      return { ...state, error: initialState.error };
    case types.LOGOUT_REQUEST:
      return { ...state, isFetching: true, isSSO: false };
    case types.LOGOUT_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isLogoutClicked: true,
        isAuthenticated: false,
        responseUser: null,
        activeUser: null,
        encodedUserInfo: action.payload,
        oAuthUserValidResponse: null,
        oAuthSignOnTokensResponse: null
      };
    // USER - UPLOAD CLOUDINARY PROFILE IMAGE
    case types.USER_CLOUD_PROFILE_IMG_UPLOAD_REQUEST:
      return {
        ...state,
        isProfileImgFetching: true
      };
    case types.USER_CLOUD_PROFILE_IMG_UPLOAD_RESPONSE:
      return {
        ...state,
        isProfileImgFetching: false,
        userProfileObj: action.payload
      };
    case types.USER_CLOUD_PROFILE_IMG_UPLOAD_FAILURE:
      return {
        ...state,
        isProfileImgFetching: false,
        userProfileObj: initialState.userProfileObj,
        error: action.payload || 'Unexpected error'
      };
    case types.USER_SET_CLOUD_PROFILE_IMG:
      return {
        ...state,
        userProfileObj: action.payload
      };
    case types.GET_LOGIN_USER_DATA_REQUEST:
      return {
        ...state,
        isUserDataFetching: true
      };
    case types.GET_LOGIN_USER_DATA_RESPONSE:
      return {
        ...state,
        isUserDataFetching: false,
        activeUser: {
          ...action.payload,
          token: state.activeUser?.token || '',
          ...(state.activeUser?.impersonated_user_id && { impersonated_user_id: state.activeUser?.impersonated_user_id }),
          ...(state.activeUser?.impersonated_user_name && { impersonated_user_name: state.activeUser?.impersonated_user_name }),
          ...(state.activeUser?.content_writers && { content_writers: state.activeUser?.content_writers }),
          ...(state.activeUser?.franchisors_managed && { franchisors_managed: state.activeUser?.franchisors_managed }),
          ...(state.activeUser?.accounts_managed && { accounts_managed: state.activeUser?.accounts_managed })
        },
        userProfileObj: {
          cloudinaryId: action.payload?.profile_photo_cloudinary_id ? action.payload.profile_photo_cloudinary_id : state.userProfileObj.cloudinaryId,
          photoUrl: action.payload?.profile_photo_url ? action.payload.profile_photo_url : state.userProfileObj.photoUrl
        }
      };
    case types.GET_LOGIN_USER_DATA_FAILURE:
      return {
        ...state,
        isUserDataFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.IS_USER_DATA_UPDATED:
      return {
        ...state,
        isUserDataUpdate: action.payload
      };
    case types.UPDATE_USER_DETAIL_REQUEST:
      return {
        ...state,
        isUserDataUpdate: true
      };
    case types.UPDATE_USER_DETAIL_RESPONSE:
      const updatedUserDetail = {
        ...action.payload,
        token: state.activeUser?.token || '',
        ...(state.activeUser?.impersonated_user_id && { impersonated_user_id: state.activeUser?.impersonated_user_id }),
        ...(state.activeUser?.impersonated_user_name && { impersonated_user_name: state.activeUser?.impersonated_user_name }),
        ...(state.activeUser?.content_writers && { content_writers: state.activeUser?.content_writers }),
        ...(state.activeUser?.franchisors_managed && { franchisors_managed: state.activeUser?.franchisors_managed }),
        ...(state.activeUser?.accounts_managed && { accounts_managed: state.activeUser?.accounts_managed })
      };
      return {
        ...state,
        isUserDataUpdate: false,
        activeUser: updatedUserDetail,
        responseUser: state.responseUser ? updateImpersonatedUser(state.responseUser, updatedUserDetail) : null,
        userProfileObj: {
          cloudinaryId: action.payload?.profile_photo_cloudinary_id ? action.payload.profile_photo_cloudinary_id : state.userProfileObj.cloudinaryId,
          photoUrl: action.payload?.profile_photo_url ? action.payload.profile_photo_url : state.userProfileObj.photoUrl
        }
      };
    case types.UPDATE_USER_DETAIL_FAILURE:
      return {
        ...state,
        isUserDataUpdate: false,
        error: action.payload || 'Unexpected error'
      };
    case types.IMPERSONATE_USER_REQUEST:
      return {
        ...state,
        isImpersonateRequest: true
      };
    case types.IMPERSONATE_USER_RESPONSE:
      const impersonatedData = state.responseUser
        ? impersonateUser(state.responseUser, state.activeUser, {
            ...action.payload.user,
            token: action.payload.rallio_access_token.token,
            content_writers: action.payload.user_detail?.content_writers || [],
            franchisors_managed: action.payload.user_detail?.franchisors_managed || [],
            accounts_managed: action.payload.user_detail?.accounts_managed || []
          })
        : null;
      return {
        ...state,
        isImpersonateRequest: false,
        responseUser: impersonatedData?.response || null,
        activeUser: impersonatedData?.activeUser || null,
        activeUserBrand: action.payload.brand || null,
        activeUserFranchisor: action.payload.franchisor || null,
        activeUserAccount: action.payload.account || null,
        userProfileObj: {
          cloudinaryId: action.payload?.user?.profile_photo_cloudinary_id || null,
          photoUrl: action.payload?.user?.profile_photo_url || null
        }
      };
    case types.IMPERSONATE_USER_FAILURE:
      return {
        ...state,
        isImpersonateRequest: false,
        error: action.payload
      };
    case types.RESTORE_SESSION:
      const deImpersonateData = state.responseUser ? deImpersonateUser(state.responseUser, action.payload) : null;
      return {
        ...state,
        responseUser: deImpersonateData?.response || null,
        activeUser: deImpersonateData?.activeUser || null
      };
    default:
      return state;
  }
};

export { loginReducer };
