import { APP_TITLE, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, SIDE_NAV_NAME, VALID_APP_URL_TITLES, MAIN_NAV_BAR_NAME } from 'utils/constants';
import { useParamsValidator, useRightToTop } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const SettingsNotifications = ({ subNavPageName }: { subNavPageName: string | null }) => {
  useParamsValidator(CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ);
  useRightToTop();

  return (
    <>
      {subNavPageName === SIDE_NAV_NAME.TM_PERMISSIONS && (
        <section className="item-g tm-permission-notify notification-cnt">
          <div className="required-content-first">
            <div className="localized">
              <ImageValidation defaultImg="tm-permission-notify" customName="permission" />
            </div>
          </div>
        </section>
      )}
      {subNavPageName === SIDE_NAV_NAME.REVIEWS_SETTINGS && (
        <section className="item-g notification-cnt set__review--notification">
          <div className="right-section-wrp review-temp-right-sec-wrp">
            <div className="fba-item-review-temp">
              <ImageValidation defaultImg="review-tem-notify-ico" customName="Model notify" />
            </div>
            <p>You can easily respond to reviews with stock responses by specifying text that will be automatically filled in when you click the "Reply" button in {APP_TITLE}.</p>
          </div>
        </section>
      )}
      {subNavPageName === SIDE_NAV_NAME.RELEASE_FORM && (
        <section className="item-g notification-cnt set__release--notification">
          <div className="right-section-wrp model-release-notifiy-wrp">
            <div className="fba-item">
              <ImageValidation defaultImg="model-rel-noty-icon" customName="Model notify" />
            </div>
            <ul>
              <li>
                You can customize the model release form signed by users of the {VALID_APP_URL_TITLES.includes(APP_TITLE) ? '' : APP_TITLE} mobile app when uploading images to their image library.
              </li>
              <li>Please note that changing this text will not update forms that have already been signed by your users.</li>
            </ul>
          </div>
        </section>
      )}
      {(subNavPageName === SIDE_NAV_NAME.RALLIO_PROFILE || subNavPageName === SIDE_NAV_NAME.AI_PLAYBOOK) && (
        // <section className="item-g notification-cnt">
        <section className={`item-g notification-cnt set__rall--profile ai__notify--cnt`}>
          <div className="right-section-wrp">
            <div className="r-profile-details-notify">
              <div className="required-content-first notify-top">
                <div className="required">
                  <ImageValidation defaultImg="required" customName="required" />
                </div>
                <span>These fields are required</span>
              </div>
              {/* {subNavPageName === SIDE_NAV_NAME.RALLIO_PROFILE && (
                <div className="required-content-first notify-bottom">
                  <div className="localized">
                    <ImageValidation defaultImg="localized" customName="localized" />
                  </div>
                  <span>
                    Do you want your brand’s HQ to include your location’s information, like your phone number or your hashtag, in your social media posts? Fields marked with this symbol allows your
                    global content to be localized. Be sure it’s accurate!
                  </span>
                </div>
              )} */}
            </div>
          </div>
        </section>
      )}
      {subNavPageName === SIDE_NAV_NAME.SOCIAL_PROFILE && (
        <section className="item-g notification-cnt">
          <div className="right-section-wrp gn-announcement">
            <div className="fba-item">
              <ImageValidation defaultImg="goodNews" customName="Good News" />
            </div>
            <span>Social media platforms to connect with your audience to build your brand, increase sales, and drive website traffic.</span>
          </div>
        </section>
      )}
      {subNavPageName === MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS && (
        <section className="item-g notification-cnt vt-alerts">
          <div className="right-section-wrp gn-announcement">
            <div className="fba-item">
              <ImageValidation defaultImg="vt-alerts" customName="Good News" />
            </div>
            <span>
              Immerse yourself in a realm of knowledge and exploration as our experts guide you through the exciting features and capabilities of Rallio with in-depth video tutorials. Let's come
              together to learn, create, and spark inspiration.
            </span>
          </div>
        </section>
      )}
    </>
  );
};
