// PI - GET PROFILE IMAGERY LIST
export const GET_PROFILE_IMAGERY_LIST_REQUEST = 'profile_imagery/GET_PROFILE_IMAGERY_LIST_REQUEST';
export const GET_PROFILE_IMAGERY_LIST_RESPONSE = 'profile_imagery/GET_PROFILE_IMAGERY_LIST_RESPONSE';
export const GET_PROFILE_IMAGERY_LIST_FAILURE = 'profile_imagery/GET_PROFILE_IMAGERY_LIST_FAILURE';
// PI - GET FILTER PROFILE IMAGERY LIST
export const GET_FILTER_PROFILE_IMAGERY_LIST_REQUEST = 'profile_imagery/GET_FILTER_PROFILE_IMAGERY_LIST_REQUEST';
export const GET_FILTER_PROFILE_IMAGERY_RESPONSE = 'profile_imagery/GET_FILTER_PROFILE_IMAGERY_RESPONSE';
export const GET_FILTER_PROFILE_IMAGERY_FAILURE = 'profile_imagery/GET_FILTER_PROFILE_IMAGERY_FAILURE';

// PI - CHANGE ACCOUNT PICTURES
export const UPDATE_PI_ACCOUNT_PICTURE_REQUEST = 'profile_imagery/UPDATE_PI_ACCOUNT_PICTURE_REQUEST';
export const UPDATE_PI_ACCOUNT_PICTURE_RESPONSE = 'profile_imagery/UPDATE_PI_ACCOUNT_PICTURE_RESPONSE';
export const UPDATE_PI_ACCOUNT_PICTURE_FAILURE = 'profile_imagery/UPDATE_PI_ACCOUNT_PICTURE_FAILURE';

// PI - SET SELECTED ASSETS
export const SET_PI_SELECTED_ASSETS = 'profile_imagery/SET_PI_SELECTED_ASSETS';

// PI - SET UPDATE DETAILS
export const SET_PI_UPDATE_DETAILS_STAT = 'profile_imagery/SET_PI_UPDATE_DETAILS_STAT';

// PI - PROFILE IMAGERY RESET ALL
export const PROFILE_IMAGERY_RESET_ALL = 'profile_imagery/PROFILE_IMAGERY_RESET_ALL';

// PI - PROFILE IMAGERY SET SELECTED SINGLE MEDIA
export const PROFILE_IMAGERY_SET_SELECTED_MEDIA = 'profile_imagery/PROFILE_IMAGERY_SET_SELECTED_MEDIA';

// PI - PROFILE IMAGERY SET ACTIVE MEDIA TAB
export const PROFILE_IMAGERY_SET_ACTIVE_TAB = 'profile_imagery/PROFILE_IMAGERY_SET_ACTIVE_TAB';

// PI - PROFILE IMAGERY SET SHOW PROFILE CONFIRM POP-UP
export const PROFILE_IMAGERY_SET_SHOW_PROFILE_CONFIRM_POPUP = 'profile_imagery/PROFILE_IMAGERY_SET_SHOW_PROFILE_CONFIRM_POPUP';

// PI - PROFILE IMAGERY SET SHOW COVER CONFIRM POP-UP
export const PROFILE_IMAGERY_SET_SHOW_COVER_CONFIRM_POPUP = 'profile_imagery/PROFILE_IMAGERY_SET_SHOW_COVER_CONFIRM_POPUP';
