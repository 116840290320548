// ACCOUNT SWITCHER
export const ACCOUNT_SWITCHER_REQUEST = 'account_switcher/ACCOUNT_SWITCHER_REQUEST';
export const ACCOUNT_SWITCHER_RESPONSE = 'account_switcher/ACCOUNT_SWITCHER_RESPONSE';
export const ACCOUNT_SWITCHER_REQUEST_FAIL = 'account_switcher/ACCOUNT_SWITCHER_REQUEST_FAIL';

// USER OWNERSHIP DATA FROM ACCOUNT SWITCHER
export const USER_OWNERSHIP = 'account_switcher/USER_OWNERSHIP';

export const ACCOUNT_SWITCHER_DATA_RESET = 'account_switcher/ACCOUNT_SWITCHER_DATA_RESET';

// ACCOUNT SWITCHER
export const LOCATION_SELECTOR_LIST_REQUEST = 'account_switcher/LOCATION_SELECTOR_LIST_REQUEST';
export const LOCATION_SELECTOR_LIST_RESPONSE = 'account_switcher/LOCATION_SELECTOR_LIST_RESPONSE';
export const LOCATION_SELECTOR_LIST_FAILURE = 'account_switcher/LOCATION_SELECTOR_LIST_FAILURE';

// ONLY SET MENU ACCESS
export const SET_MENU_ACCESS = 'account_switcher/SET_MENU_ACCESS';

// REVV
export const UPDATE_REVV_STATE = 'account_switcher/UPDATE_REVV_STATE';
