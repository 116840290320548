import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import { getULAccountOwnersRequest, ulAddNewUserDetailReset } from 'actions';
import { RIPPLE_COLOR } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';

export const ULAddNewUserAlreadyRallioUser = ({ isModalShow, handleModalClose, containerClassName, handleMainModalClose }: commonModalPopup.IULAddedRallioUserModalPopup) => {
  const dispatch = useDispatch();

  const { currentRoute, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const { addNewUserDetails } = useSelector((state: IStore) => state.userLogins);

  const isUser = currentRoute === 'team_management/logins' ? !queryParams?.login_type : optionalParams[1] === 'users';

  const handleCancelAccessToRallioUser = () => {
    dispatch(ulAddNewUserDetailReset());
    handleModalClose();
  };

  const handleAllowAccessToRallioUser = () => {
    if (accountDetails?.account?.id) {
      delete addNewUserDetails.mobile_phone;
      dispatch(
        getULAccountOwnersRequest({
          id: accountDetails?.account?.id,
          accountOwner: {
            ...addNewUserDetails,
            employee_login: isUser ? false : true
          }
        })
      );
      handleModalClose();
      handleMainModalClose();
    }
  };

  return (
    <Modal
      className={`prime-modal miw-loc-access${containerClassName ? ` ${containerClassName}` : ``}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Header>
        <button data-dismiss="modal" className="close" type="button">
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className="modal-icon-wraps">
          <span className="modal-itm-wraps modal-danger-wraps modal-post-now-wraps" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-message-wraps">This user already has a login. Click OK to give them access to this location.</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="apmodal modal-btn-grp-wraps ap-actions">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={() => handleCancelAccessToRallioUser()}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary ac-block`} onClick={() => handleAllowAccessToRallioUser()}>
              OK
            </button>
          </CustomRippleButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
