import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { profileImageryApiTypes, profileImageryReducerTypes, apAccountReducerTypes } from 'types';

// PI - GET PROFILE IMAGERY  LOCATION LIST
export const getProfileImageryListRequest = (payload: profileImageryApiTypes.IFilterProfileImageryListRequest) => createAction(actionTypes.GET_PROFILE_IMAGERY_LIST_REQUEST, payload);
export const getProfileImageryListResponse = (data: profileImageryApiTypes.IProfileImageryListResponse) => createAction(actionTypes.GET_PROFILE_IMAGERY_LIST_RESPONSE, data);
export const getProfileImageryListFailure = (error: null | string) => createAction(actionTypes.GET_PROFILE_IMAGERY_LIST_FAILURE, error);

// PI - GET FILTER PROFILE IMAGERY  LOCATION LIST
export const getFilterProfileImageryListRequest = (payload: profileImageryApiTypes.IFilterProfileImageryListRequest) => createAction(actionTypes.GET_FILTER_PROFILE_IMAGERY_LIST_REQUEST, payload);
export const getFilterProfileImageryListResponse = (data: profileImageryApiTypes.IFilterProfileImageryListResponse) => createAction(actionTypes.GET_FILTER_PROFILE_IMAGERY_RESPONSE, data);
export const getFilterProfileImageryListFailure = (error: null | string) => createAction(actionTypes.GET_FILTER_PROFILE_IMAGERY_FAILURE, error);

// PI - CHANGE ACCOUNT PICTURES
export const updatePIAccountPictureRequest = (payload: profileImageryApiTypes.IPIAccountPictureUpdateReq) => createAction(actionTypes.UPDATE_PI_ACCOUNT_PICTURE_REQUEST, payload);
export const updatePIAccountPictureResponse = (data: null | string) => createAction(actionTypes.UPDATE_PI_ACCOUNT_PICTURE_RESPONSE, data);
export const updatePIAccountPictureFailure = (error: null | string) => createAction(actionTypes.UPDATE_PI_ACCOUNT_PICTURE_FAILURE, error);

// PI - SET SELECTED ASSETS
export const setProfileImagerySelectedAssets = (payload: profileImageryReducerTypes.ISelectedAsset) => createAction(actionTypes.SET_PI_SELECTED_ASSETS, payload);

// PI - SET UPDATE DETAILS
export const setPIUpdateDetailStat = (payload: profileImageryReducerTypes.IUpdatePIDetails) => createAction(actionTypes.SET_PI_UPDATE_DETAILS_STAT, payload);

// PI - PROFILE IMAGERY RESET ALL
export const profileImageryResetAll = () => createAction(actionTypes.PROFILE_IMAGERY_RESET_ALL);

// PI - PROFILE IMAGERY SET SELECTED SINGLE MEDIA
export const profileImagerySetSelectedMedia = (payload: null | apAccountReducerTypes.IAddPostCloudData) => createAction(actionTypes.PROFILE_IMAGERY_SET_SELECTED_MEDIA, payload);

// PI - PROFILE IMAGERY SET ACTIVE MEDIA TAB
export const profileImagerySetActiveMedia = (payload: string) => createAction(actionTypes.PROFILE_IMAGERY_SET_ACTIVE_TAB, payload);

// PI - PROFILE IMAGERY SET SHOW PROFILE CONFIRM POP-UP
export const PISetShowProfileConfirmPopup = (payload: boolean) => createAction(actionTypes.PROFILE_IMAGERY_SET_SHOW_PROFILE_CONFIRM_POPUP, payload);

// PI - PROFILE IMAGERY SET SHOW COVER CONFIRM POP-UP
export const PISetShowCoverConfirmPopup = (payload: boolean) => createAction(actionTypes.PROFILE_IMAGERY_SET_SHOW_COVER_CONFIRM_POPUP, payload);

const PROFILE_IMAGERY_ACTIONS = {
  getProfileImageryListRequest,
  getProfileImageryListResponse,
  getProfileImageryListFailure,
  getFilterProfileImageryListRequest,
  getFilterProfileImageryListResponse,
  getFilterProfileImageryListFailure,
  updatePIAccountPictureRequest,
  updatePIAccountPictureResponse,
  updatePIAccountPictureFailure,
  setProfileImagerySelectedAssets,
  setPIUpdateDetailStat,
  profileImageryResetAll,
  profileImagerySetSelectedMedia,
  profileImagerySetActiveMedia,
  PISetShowProfileConfirmPopup,
  PISetShowCoverConfirmPopup
};

export type ProfileImageryAction = ActionsUnion<typeof PROFILE_IMAGERY_ACTIONS>;
