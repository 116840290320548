import { Reducer } from 'redux';

import * as types from 'analytics/actions/action-types';
import { ProfileAnalyticsActions } from 'analytics/actions';
import { IProfileAnalyticsReducer } from 'analytics/types/profile-analytics';
import { getProfileAnalyticsData, tableDateGrouping, PROFILE_ANALYTICS_TABLE_HEADERS, PROFILE_ANALYTICS_PLATFORM_FILTER } from 'analytics/utils';

const initialState: IProfileAnalyticsReducer = {
  isProfileAnalyticsFetching: false,
  isError: false,
  data: null,
  tableData: [],
  tableTotal: {},
  groupedTableData: { headers: [], data: [], summary: {} }
};

export const profileAnalyticsReducer: Reducer<IProfileAnalyticsReducer, ProfileAnalyticsActions> = (state: IProfileAnalyticsReducer = initialState, action: ProfileAnalyticsActions) => {
  switch (action.type) {
    case types.GET_PROFILE_ANALYTICS_REQUEST:
      return {
        ...state,
        isProfileAnalyticsFetching: true,
        isError: false
      };
    case types.GET_PROFILE_ANALYTICS_RESPONSE:
      const tableDataObj = action.payload?.profile_analytics ? getProfileAnalyticsData(action.payload?.profile_analytics, action.payload?.summary) : null;
      const tableData = tableDataObj?.tableData;
      const tableTotal = tableDataObj?.tableTotal;
      const groupedTableData = action.payload?.profile_analytics ? tableDateGrouping(action.payload?.profile_analytics, PROFILE_ANALYTICS_TABLE_HEADERS, PROFILE_ANALYTICS_PLATFORM_FILTER) : null;
      return {
        ...state,
        isProfileAnalyticsFetching: false,
        isError: false,
        data: action.payload,
        ...(tableData && { tableData }),
        ...(tableTotal && { tableTotal }),
        groupedTableData
      };
    case types.GET_PROFILE_ANALYTICS_FAILURE:
      return {
        ...state,
        data: null,
        isProfileAnalyticsFetching: false,
        isError: true,
        tableData: [],
        tableTotal: {},
        groupedTableData: { headers: [], data: [], summary: {} }
      };
    case types.RESET_PROFILE_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
