import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import { COMMON_SOCIAL_PROVIDER_TYPE, ROUTES, PLATFORM_STATS } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { TotalStatsCount } from 'widgets/TotalStatsCount';
import { useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';

interface ICommonStats {
  data: { [key: string]: number | null | undefined };
  initialFilterValue?: any;
  isLoading: boolean;
  handlePlatformCallback?: () => void;
  title: string;
  activePlatforms: string[];
}

export const CommonStats = ({ data, isLoading, title, activePlatforms }: ICommonStats) => {
  const navigate = useNavigate();
  const { id, userOwnership } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor();

  const handlePlatformSelect = (value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value === COMMON_SOCIAL_PROVIDER_TYPE.ALL) {
      delete queryParamsObj.platform;
    } else {
      queryParamsObj.platform = value;
    }
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleCreatorNavigation = () => {
    navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  return (
    <div className={`stats community-stat lpx g-20 vpy-20 global-stats-section-wrp`}>
      <div className="stats-item m-0 total-count stats-level-03" onClick={() => handlePlatformSelect(COMMON_SOCIAL_PROVIDER_TYPE.ALL)}>
        <div className="chart-module-top">
          <span className="mod-head">{title}</span>
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <span className="mod-count">
              <ReactSpringCountUp end={data?.totalStatCount || 0} />
            </span>
          )}
        </div>
        <div className={data?.totalStatCount ? 'chart-container' : 'd-none'}>
          {isLoading ? null : (
            <TotalStatsCount
              facebookStat={data?.facebook || 0}
              twitterStat={data?.twitter || 0}
              instaStat={data?.instagram || 0}
              linkedInStat={data?.linkedin || 0}
              totalStatCount={data?.totalStatCount || 0}
              platform={queryParams.platform || 'all'}
            />
          )}
        </div>
      </div>
      {activePlatforms?.length
        ? PLATFORM_STATS.filter((it) => activePlatforms.includes(it.value)).map((it, index: number) => {
            return (
              <div
                key={`${it.value}-stats-${index}`}
                className={`stats-item m-0 ${it.parentVariant} stats-level-02${queryParams.platform === it.value ? ' active' : ''}`}
                onClick={() => handlePlatformSelect(it.value)}
              >
                <div className="chart-module-top">
                  <span className="platform-text">
                    <span className="ft-mainLabel">{it.label}</span> {it?.captionText ? <span className="ft-Text">{it.captionText}</span> : ''}
                  </span>

                  {isLoading ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <span className="platform-count">
                      <ReactSpringCountUp end={data[it.value] || 0} />
                    </span>
                  )}
                </div>
                <div className="platform-icon">
                  <ImageValidation isImgValid defaultImg={it.image} isNotSvgFormat customName={it.label} />
                </div>
              </div>
            );
          })
        : null}
      <div className="stats-item m-0 sicp stats-level-03" onClick={() => handleCreatorNavigation()}>
        <span className="mod-head">CREATE POST</span>
      </div>
    </div>
  );
};
