import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { scContainerTypes } from 'types';
import { socialContentsReuseRequest } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { COMMON_SOCIAL_PROVIDER_TYPE, CREATE_POST_TYPE, USER_OWNERSHIP } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';

export const SCReuse = ({ id: socialContentId, type, platform, setPostPlatform }: scContainerTypes.ISCReuseProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { userOwnership, id } = useAccountSwitcherData();

  const handleSCReuse = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && id && socialContentId) {
      dispatch(socialContentsReuseRequest({ socialContentId, accountId: id }));
      setPostPlatform(
        [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(platform)
          ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
          : platform
      );
    }
  };

  return userOwnership === USER_OWNERSHIP.ACCOUNT && type === CREATE_POST_TYPE.POST ? (
    <div className="vc-item" onClick={handleSCReuse}>
      <div className="vc-icon">
        <ImageValidation isImgValid defaultImg="reuse" customName="Reuse" />
      </div>
      <span className="vc-txt">Reuse</span>
    </div>
  ) : null;
};
