import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { IStore } from 'types';
import { NAV_BAR, SIDE_NAV_DATA, SUPER_ADMIN_NAV_BAR, USER_OWNERSHIP } from 'utils/constants';
import { getMainNavBarList, getValidSideNavBarList } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';

export const useNavBarData = () => {
  const location = useLocation();

  const { userOwnership, isAdminScreenEnabled } = useAccountSwitcherData();

  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);

  const excludedFullPages = useMemo(() => {
    const pageExclusions = menuAccess?.page_exclusions;
    let exclusions: Array<string> = [];
    if (pageExclusions) {
      if (pageExclusions.hub_user && userOwnership === USER_OWNERSHIP.FRANCHISOR && pageExclusions.hub_user?.length > 0) {
        exclusions = pageExclusions.hub_user.filter((it: string) => it.split('/')?.length === 1);
      } else if (pageExclusions.location_user && userOwnership === USER_OWNERSHIP.ACCOUNT && pageExclusions.location_user?.length > 0) {
        exclusions = pageExclusions.location_user.filter((it: string) => it.split('/')?.length === 1);
      }
    }
    if (isAdmin) {
      const adminPages = NAV_BAR.reduce((acc: string[], curr) => {
        const condition = isAdminScreenEnabled ? !SUPER_ADMIN_NAV_BAR.some((it) => it.name === curr.name) : false;
        if (condition) {
          acc.push(curr.name);
        }
        return acc;
      }, []);
      exclusions = [...exclusions, ...adminPages];
    } else {
      const adminPages = NAV_BAR.reduce((acc: string[], curr) => {
        const condition = SUPER_ADMIN_NAV_BAR.some((it) => it.name === curr.name);
        if (condition) {
          acc.push(curr.name);
        }
        return acc;
      }, []);
      exclusions = [...exclusions, ...adminPages];
    }
    return exclusions;
  }, [menuAccess, userOwnership, isAdmin, isAdminScreenEnabled]);

  const navBarData = useMemo(() => {
    const pathName = location.pathname.split('/');
    const locationIndex = isAdminScreenEnabled ? 1 : 3;
    const mainNavPageName = pathName[locationIndex];
    const validMainNavDataList = getMainNavBarList(NAV_BAR, menuAvailablity).filter((it) => excludedFullPages.indexOf(it.name) === -1);
    const validSubNavDataList = getValidSideNavBarList(SIDE_NAV_DATA[mainNavPageName], menuAvailablity[mainNavPageName]);
    const subPageNameLabel: string = SIDE_NAV_DATA[mainNavPageName]
      ? SIDE_NAV_DATA[mainNavPageName].reduce((acc: string, curr: any) => {
          if (curr.name === pathName[locationIndex + 1]) acc = curr.label;
          return acc;
        }, '')
      : '';
    return {
      mainNavPageName,
      subNavPageName: pathName[locationIndex + 1],
      activeMainPages: validMainNavDataList,
      selectedSubNavData: validSubNavDataList,
      subPageNameLabel
    };
  }, [location.pathname, excludedFullPages, menuAvailablity, isAdminScreenEnabled]);

  return navBarData;
};
