import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { advocacyLeaderboardApiTypes, advocacyLeaderboardReducerTypes } from 'types';
import { AdvocacyLeaderboardActions } from 'actions';
import { ADV_LB_FILTER_STATS_INITIAL_OBJ } from 'utils/constants';
import { getAdvLocationOrEmployeeName } from 'utils/helpers';

type IAdvocacyLeaderboardReducer = advocacyLeaderboardReducerTypes.IAdvocacyLeaderboardReducer;

const initialState: IAdvocacyLeaderboardReducer = {
  isFetching: true,
  error: null,
  leaderboardData: null,
  filterStats: ADV_LB_FILTER_STATS_INITIAL_OBJ
};

export const advocacayLeaderboardReducer: Reducer<IAdvocacyLeaderboardReducer, AdvocacyLeaderboardActions> = (
  state: IAdvocacyLeaderboardReducer = initialState,
  action: AdvocacyLeaderboardActions
) => {
  switch (action.type) {
    case types.GET_ADV_LEADERBOARD_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        leaderboardData: null
      };
    case types.GET_ADV_LEADERBOARD_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        leaderboardData: {
          ...action.payload,
          consolidate_advocacy: action.payload.consolidate_advocacy.reduce((acc: advocacyLeaderboardApiTypes.IAdvLBConsolidateAdvocacy[], curr) => {
            const locationOrEmployeeName = getAdvLocationOrEmployeeName(curr);
            acc = [...acc, { ...curr, ...(curr?.employee?.user_id && { employee_name: locationOrEmployeeName }), ...(curr?.location?.account_id && { location_name: locationOrEmployeeName }) }];
            return acc;
          }, [])
        }
      };
    case types.GET_ADV_LEADERBOARD_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.GET_ADV_LEADERBOARD_STATS_REQUEST:
      return {
        ...state,
        filterStats: ADV_LB_FILTER_STATS_INITIAL_OBJ
      };
    case types.GET_ADV_LEADERBOARD_STATS_RESPONSE:
      return {
        ...state,
        filterStats: {
          employeesCount: action.payload.employees_count || 0,
          locationsCount: action.payload.locations_count || 0
        }
      };
    case types.GET_ADV_LEADERBOARD_STATS_FAILURE:
      return {
        ...state,
        filterStats: ADV_LB_FILTER_STATS_INITIAL_OBJ,
        error: action.payload || 'Unexpected error'
      };
    default:
      return state;
  }
};
