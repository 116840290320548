import { CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ } from 'utils/constants';
import { useParamsValidator } from 'utils/hooks';

export const NoFilter = () => {
  useParamsValidator(CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ);

  return (
    <>
      <section className="item-g filter" />
    </>
  );
};
