import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { CalendarActions } from 'actions';
import { calendarReducerTypes } from 'types/calendar';
import { getRetainedScheduledPostList, getCalenderResponseData, getUpdatedCalenderList, getUpdatedScheduledPost, getUpdatedSchPostWithActivePlatform } from 'utils/helpers';
import { CALENDAR_VIEW_DATE_RANGE, UPDATE_POST_INITIAL_STATUS, UPDATE_SCHEDULE_POST_UPDATE_SUCCESS, UPDATE_SCHEDULE_POST_UPDATE_FAILED, POST_DELETE_SUCCESS_MSG } from 'utils/constants';

const initialState: calendarReducerTypes.ICalendarReducer = {
  isFetching: true,
  isPostUpdating: false,
  isEventPostFetching: false,
  error: null,
  scheduledPostList: [],
  updatePostDetails: UPDATE_POST_INITIAL_STATUS,
  scheduleMultiPostList: [],
  hubActualData: [],
  locationActualData: [],
  calendarItemList: [],
  editableData: { isEditEnabled: false, editId: 0, editData: null },
  calendarDateRange: CALENDAR_VIEW_DATE_RANGE,
  detailViewError: null,
  activePlatforms: [],
  isScheduleEnabled: false,
  selectedDataObj: null,
  datePickerEnabled: { isOpen: false, dataObj: null },
  deleteModal: { isOpenDeleteModal: false, isConfirmDelete: false },
  locationUpdates: { bool: false, data: null, type: '' },
  isStreamEnded: true,
  calendarShuffledCount: 1,
  deleteAutomatedScheduledPostOptions: { post: 1, timeSlot: 1 }
};

export const calendarReducer: Reducer<calendarReducerTypes.ICalendarReducer, CalendarActions> = (state: calendarReducerTypes.ICalendarReducer = initialState, action: CalendarActions) => {
  switch (action.type) {
    case types.CALENDAR_VIEW_DATE_RANGE:
      return {
        ...state,
        calendarDateRange: action.payload
      };
    case types.CALENDAR_EDIT_VIEW:
      return {
        ...state,
        editableData: action.payload
      };
    // CALENDAR - GET SCHEDULED POSTS--------------------------------------------------------------
    case types.GET_SCHEDULED_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        isPostUpdating: state.editableData.editData ? false : true,
        error: null,
        locationActualData: initialState.locationActualData,
        scheduledPostList: initialState.scheduledPostList
      };
    case types.GET_SCHEDULED_POST_RESPONSE:
      const scheduledPosts = state.locationActualData.concat(action.payload);
      return {
        ...state,
        isFetching: false,
        isPostUpdating: false,
        error: null,
        locationActualData: scheduledPosts,
        scheduledPostList: getCalenderResponseData(state.activePlatforms, scheduledPosts),
        isStreamEnded: !action.payload.length
      };
    // CALENDAR - UPDATE SCHEDULED POST
    case types.UPDATE_SCHEDULED_POST_REQUEST:
      const fetchingEnable = state.editableData.editData ? false : true;
      return {
        ...state,
        isPostUpdating: fetchingEnable,
        isFetching: true,
        updatePostDetails: {
          isUpdated: false,
          updateSuccessMsg: null,
          updateFailureMsg: null
        },
        error: null
      };
    case types.UPDATE_SCHEDULED_POST_RESPONSE:
      let updatedLocationScheduleActualData = [...state.locationActualData];
      if (action.payload.accountSlotDetails) {
        updatedLocationScheduleActualData = updatedLocationScheduleActualData.filter((post) => {
          return Boolean(
            !post?.account_list_schedule_details ||
              post?.account_list_schedule_details?.day_of_week !== action.payload.accountSlotDetails?.day_of_week ||
              post?.account_list_schedule_details?.hour !== action.payload.accountSlotDetails?.hour ||
              post?.account_list_schedule_details?.minute !== action.payload.accountSlotDetails?.minute
          );
        });
      } else if (action.payload.data) {
        const selectedLocationActualIndex = updatedLocationScheduleActualData.findIndex((post) => post?.id === action.payload?.data?.id);
        if (selectedLocationActualIndex >= 0) {
          updatedLocationScheduleActualData[selectedLocationActualIndex] = action.payload.data;
        }
      } else if (action.payload.postId) {
        updatedLocationScheduleActualData = updatedLocationScheduleActualData.filter((post) => post?.id !== action.payload.postId);
      }

      return {
        ...state,
        isPostUpdating: false,
        updatePostDetails: {
          ...state.updatePostDetails,
          isUpdated: true,
          updateSuccessMsg: UPDATE_SCHEDULE_POST_UPDATE_SUCCESS
        },
        isFetching: false,
        scheduledPostList: getCalenderResponseData(state.activePlatforms, updatedLocationScheduleActualData),
        locationActualData: updatedLocationScheduleActualData,
        error: null
      };
    case types.UPDATE_SCHEDULED_POST_FAILURE:
      return {
        ...state,
        isPostUpdating: false,
        isFetching: false,
        updatePostDetails: {
          ...state.updatePostDetails,
          isUpdated: false,
          updateFailureMsg: UPDATE_SCHEDULE_POST_UPDATE_FAILED
        },
        error: action.payload || 'Unexpected error'
      };
    // CALENDAR - GET REQUESTED CALENDAR ITEMS
    case types.GET_CALENDAR_ITEMS_REQUEST:
      return {
        ...state,
        isEventPostFetching: true,
        error: null,
        calendarItemList: initialState.calendarItemList
      };
    case types.GET_CALENDAR_ITEMS_RESPONSE:
      return {
        ...state,
        isEventPostFetching: false,
        error: null,
        calendarItemList: state.calendarItemList.concat(action.payload)
      };
    // CALENDAR - UPDATE REQUESTED CALENDAR ITEM
    case types.UPDATE_REQUESTED_CALENDAR_ITEM_REQUEST:
      return {
        ...state
      };
    case types.UPDATE_REQUESTED_CALENDAR_ITEM_RESPONSE:
      const updateCalenderList = getUpdatedCalenderList(state.calendarItemList, action.payload);
      return {
        ...state,
        calendarItemList: updateCalenderList
      };
    case types.UPDATE_REQUESTED_CALENDAR_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // CALENDAR - GET SCHEDULED MULTIPLE POST
    case types.GET_SCHEDULED_MULTI_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
        hubActualData: initialState.hubActualData,
        scheduleMultiPostList: initialState.scheduleMultiPostList
      };
    case types.GET_SCHEDULED_MULTI_POST_RESPONSE:
      const scheduledMultiPosts = state.hubActualData.concat(action.payload);
      return {
        ...state,
        hubActualData: scheduledMultiPosts,
        scheduleMultiPostList: getCalenderResponseData(state.activePlatforms, scheduledMultiPosts),
        isFetching: false,
        isStreamEnded: !action.payload.length
      };
    // CALENDAR - DELETE SCHEDULED POST
    case types.DELETE_SCHEDULED_MULTI_POST_REQUEST:
      return {
        ...state,
        isPostUpdating: true
      };
    case types.DELETE_SCHEDULED_MULTI_POST_RESPONSE:
      const updatedValue = getRetainedScheduledPostList(state.activePlatforms, state.hubActualData, action.payload.postId);
      return {
        ...state,
        isPostUpdating: false,
        updatePostDetails: {
          ...state.updatePostDetails,
          isUpdated: true,
          updateSuccessMsg: POST_DELETE_SUCCESS_MSG
        },
        scheduleMultiPostList: updatedValue?.updatedData,
        hubActualData: updatedValue?.response
      };
    case types.DELETE_SCHEDULED_MULTI_POST_FAILURE:
      return {
        ...state,
        isPostUpdating: false,
        updatePostDetails: {
          ...state.updatePostDetails,
          isUpdated: true,
          updateFailureMsg: action.payload || 'Unexpected error'
        }
      };
    case types.UPDATE_SCHEDULED_MULTI_POST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.UPDATE_SCHEDULED_MULTI_POST_RESPONSE:
      const updatedMultiSchduleActualData = [...state.hubActualData];
      const selectedHubActualIndex = updatedMultiSchduleActualData.findIndex((post) => post?.id === action.payload?.id);
      if (selectedHubActualIndex >= 0) {
        updatedMultiSchduleActualData[selectedHubActualIndex] = action.payload;
      }
      return {
        ...state,
        scheduleMultiPostList: getCalenderResponseData(state.activePlatforms, updatedMultiSchduleActualData),
        hubActualData: updatedMultiSchduleActualData,
        isFetching: false
      };
    case types.UPDATE_SCHEDULED_MULTI_POST_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case types.UPDATE_SCHEDULED_POST:
      return {
        ...state,
        ...(state.scheduleMultiPostList.length && { scheduleMultiPostList: getUpdatedScheduledPost(state.scheduleMultiPostList, action.payload) }),
        ...(state.scheduledPostList.length && { scheduledPostList: getUpdatedScheduledPost(state.scheduledPostList, action.payload) })
      };
    case types.GET_ACTIVE_PLATFORM_IN_SCH_POST:
      return {
        ...state,
        activePlatforms: action.payload.activePlatforms,
        ...(state.scheduleMultiPostList.length && {
          scheduleMultiPostList: getUpdatedSchPostWithActivePlatform(action.payload.activePlatforms, state.hubActualData, state.scheduleMultiPostList, action.payload.selectedPlatform)
        }),
        ...(state.scheduledPostList.length && {
          scheduledPostList: getUpdatedSchPostWithActivePlatform(action.payload.activePlatforms, state.locationActualData, state.scheduledPostList, action.payload.selectedPlatform)
        })
      };
    case types.CALENDAR_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    case types.CALENDAR_ENABLE_SCHEDULE:
      return {
        ...state,
        isScheduleEnabled: action.payload
      };
    case types.CALENDAR_ENABLE_DATE_PICKER:
      return {
        ...state,
        datePickerEnabled: action.payload
      };
    case types.CALENDAR_DELETE_MODEL_OPTION:
      return {
        ...state,
        deleteModal: action.payload
      };
    case types.CALENDAR_LOCATION_UPDATE_STATES:
      return {
        ...state,
        locationUpdates: action.payload
      };
    case types.CALENDAR_SELECT_DATA_OBJECT:
      return {
        ...state,
        selectedDataObj: action.payload
      };
    case types.CALENDAR_SHUFFLE_AUTOMATED_SCHEDULED_COUNT:
      return {
        ...state,
        calendarShuffledCount: state.calendarShuffledCount + 1
      };
    case types.CALENDAR_RESET_LIST_ALL:
      return {
        ...state,
        locationActualData: initialState.locationActualData,
        scheduledPostList: initialState.scheduledPostList,
        calendarItemList: initialState.calendarItemList,
        hubActualData: initialState.hubActualData,
        scheduleMultiPostList: initialState.scheduleMultiPostList
      };
    case types.CALENDAR_AUTOMATED_SCHEDULED_POST_DELETE_MODEL_OPTION:
      return {
        ...state,
        deleteAutomatedScheduledPostOptions: action.payload
      };
    case types.CALENDAR_RESET_ALL:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
