import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { commonWidgetTypes, IStore, campaignsContainerTypes } from 'types';
import { ImageValidation, Video } from 'widgets/Media';
import { dateFormatByRequestedFormat, isSocialMediaActive } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useParamsDeconstructor } from 'utils/hooks';
import { SocialIcons } from 'widgets/Icons';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  IReactDNDItemTypes,
  FULL_YEAR_FORMAT,
  MONTH_FORMAT,
  FULL_DATE_FORMAT,
  USER_OWNERSHIP,
  TOOLTIP_PLACEMENT,
  POSTS_TOOLTIP_NAMES,
  CAMPAIGN_INIT_FILTER_DATA,
  POSTS_DETAIL_TAB_NAMES
} from 'utils/constants';
import { Paragraph } from 'widgets/Text';
import { ReactDND } from 'widgets/DragAndDrop';
import { campaignSortPostsListRequest, campaignsSortPostList } from 'actions';
import { CustomTooltip } from 'widgets/Tooltip';

export const CampaignPostsList = ({ selectedPostIds, setSelectedPostIds, disableSortCampaignPosts }: campaignsContainerTypes.ICampaignPostsListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { optionalParams, currentRoute } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();
  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);

  const validCampaignId = optionalParams[0];

  const postsList = useSelector((state: IStore) => state.campaigns.postsList);
  const isSortList = useSelector((state: IStore) => state.campaigns.isSortList);
  const isPostListUpdate = useSelector((state: IStore) => state.campaigns.isPostListUpdate);
  const campaignFranchisorId = useSelector((state: IStore) => state.campaigns.campaignDetail?.franchisor_id || 0);
  const campaignAccountId = useSelector((state: IStore) => state.campaigns.campaignDetail?.account_id || 0);

  const handleCheckActivePlatform = (platform: string) => {
    return isSocialMediaActive(activePlatforms, platform);
  };

  const handleSortCampaign = (data: commonWidgetTypes.IReactDNDSortData) => {
    dispatch(campaignsSortPostList(data));
  };

  const sortedPostsList = useMemo(() => postsList, [postsList]);

  const handleCampaignPostsSort = () => {
    if (validCampaignId && isSortList) {
      const sortedIds = postsList.map((it) => it?.content_id || it?.saved_post_id || 0);
      dispatch(campaignSortPostsListRequest({ campaignId: validCampaignId, userOwnership: campaignFranchisorId ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT, sortedIds }));
    }
  };

  const handleSelectedPosts = (selectedId: number) => {
    if (selectedPostIds.includes(selectedId)) {
      setSelectedPostIds(selectedPostIds.filter((it) => it !== selectedId));
    } else {
      setSelectedPostIds([...selectedPostIds, selectedId]);
    }
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleNavigateToPostDetailView = (postId: number, detailTabName: string) => {
    const activeUserOwnership = campaignFranchisorId ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
    const activeId = campaignFranchisorId ? campaignFranchisorId : campaignAccountId;
    routePush(
      `/${activeUserOwnership}/${activeId}/${currentRoute}/${validCampaignId}/${
        activeUserOwnership === USER_OWNERSHIP.FRANCHISOR ? `preview/${postId.toString()}/${detailTabName}` : `${postId.toString()}/${detailTabName}`
      }`,
      queryParams
    );
  };

  return (
    <div className={`pls post-list__main lpx vpy-20 mt-0 dragCards${isPostListUpdate ? ` events-none pointer-events-none` : ''}`} onMouseLeave={handleCampaignPostsSort}>
      {sortedPostsList.map((listItem, index) => {
        const postId = listItem.saved_post_id || listItem.content_id || 0;
        return (
          <ReactDND
            key={`campaign-posts-list-dnd-${index}`}
            index={index}
            type={IReactDNDItemTypes.CARD}
            data={listItem}
            onSortData={(data) => handleSortCampaign(data)}
            disabled={disableSortCampaignPosts()}
          >
            <div className={`m-item cmpgn-mitem mi-compact${selectedPostIds.includes(postId) ? ' cmpgn-slctd' : ''}`}>
              {disableSortCampaignPosts() ? null : (
                <div className="mi-hover postlist-view-tooltipwrp post-new-list--wrp">
                  <div className="mih-expand">
                    <CustomTooltip
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customTooltipText={POSTS_TOOLTIP_NAMES.VIEW}
                      customClassname="r-post-content dv-tp"
                      customInput={() => (
                        <span>
                          <div className="post-expand-c">
                            <ImageValidation isImgValid defaultImg={'expand'} customName={'Expand'} onClick={() => postId && handleNavigateToPostDetailView(postId, POSTS_DETAIL_TAB_NAMES.DETAILS)} />
                          </div>
                        </span>
                      )}
                    />
                  </div>
                  <div className="mi-cntrls">
                    <button type="button" className="gnrc-btn blue-gnr l-blue" onClick={() => handleSelectedPosts(postId)}>
                      <span>{selectedPostIds.includes(postId) ? 'Selected' : 'Select'}</span>
                    </button>
                  </div>
                </div>
              )}
              <div className="mi-front">
                <div className="mif-head">
                  <div className="mifh-top">
                    <div className="mif-left">
                      <div className="round-asset brand-icon">
                        <ImageValidation
                          isImgValid
                          defaultImg={
                            listItem.status_id === 3
                              ? 'stats/approved'
                              : [2, 5].includes(listItem.status_id)
                              ? 'pending-filled'
                              : listItem.status_id === 1 && !listItem?.rejected
                              ? 'draft-filled'
                              : listItem.status_id === 1 && listItem.rejected
                              ? 'stats/rejected'
                              : listItem?.published
                              ? 'published'
                              : ''
                          }
                          customName={'Asset status'}
                        />
                      </div>
                      <div className="prime-sub-dtls">
                        <h3 className="crtdOn">Created on</h3>
                        <h3>{dateFormatByRequestedFormat(listItem.created_at, `${MONTH_FORMAT} ${FULL_DATE_FORMAT}`)}</h3>
                        <span className="sub-dtls">{dateFormatByRequestedFormat(listItem.created_at, FULL_YEAR_FORMAT)}</span>
                      </div>
                    </div>
                    <div className="mif-right">
                      <SocialIcons
                        useFacebook={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && listItem.has_facebook}
                        useTwitter={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && listItem.has_twitter}
                        useInstagram={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) && listItem.has_instagram}
                        useLinkedIn={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && listItem.has_linkedin}
                        useTiktok={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK) && listItem.has_tiktok}
                        useGoogle={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && `has_google_plus` in listItem ? listItem.has_google_plus : false}
                      />
                    </div>
                  </div>
                  <div className="msg-wrapper">
                    <Paragraph
                      customText={listItem?.platform_text_copy ? listItem?.platform_text_copy : listItem.copy || ''}
                      facebookTagList={handleCheckActivePlatform(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && listItem.has_facebook ? listItem?.page_tag_details?.facebook || [] : []}
                      actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                    />
                  </div>
                </div>
                {listItem.video_url || listItem.photo_preview_url || listItem?.link_image_url ? (
                  <div className="m-ast">
                    {!listItem.video_url && (listItem.photo_preview_url || listItem?.link_image_url) ? (
                      <ImageValidation imgUrl={listItem.photo_preview_url || listItem.link_image_url || ''} customClassname={'m-ast-itm m-ast-img'} customName={'Post Image'} />
                    ) : (
                      listItem.video_url && (
                        <div className="video-outer-element">
                          <Video videoUrl={listItem.video_url} light={listItem?.video_thumbnail_url} customClassname="m-ast-itm m-ast-video" />
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </ReactDND>
        );
      })}
    </div>
  );
};
