import { advocacyOnboardApiTypes } from 'types';

export const ADVOCACY_ONBOARD_STATS_DATA_INIT_OBJ: advocacyOnboardApiTypes.IAdvocacyOnboardStatsResponse = {
  installed: 0,
  notInstalled: 0,
  opened: 0,
  totalAdvocatesCount: 0,
  totalAppUsersInPercent: 0,
  unOpened: 0,
  totalAdminsCount: 0,
  totalEmployeesCount: 0
};
