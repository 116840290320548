import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { calendarApiTypes, calendarContainerTypes, commonApiTypes } from 'types';
import { ICalendarDateRange } from 'types/calendar/calendar-reducer';

// CALENDAR - GET SCHEDULED POSTS BY LOCATION
export const getScheduledPostRequest = () => createAction(actionTypes.GET_SCHEDULED_POST_REQUEST);
export const getScheduledPostResponse = (response: calendarApiTypes.IGetScheduledPostResponse[]) => createAction(actionTypes.GET_SCHEDULED_POST_RESPONSE, response);

// CALENDAR - UPDATE SCHEDULED POST BY LOCATION
export const updateScheduledPostRequest = (payload: calendarApiTypes.IGetScheduleReq) => createAction(actionTypes.UPDATE_SCHEDULED_POST_REQUEST, payload);
export const updateScheduledPostResponse = (response: calendarApiTypes.IGetScheduleRes) => createAction(actionTypes.UPDATE_SCHEDULED_POST_RESPONSE, response);
export const updateScheduledPostFailure = (err: null | string) => createAction(actionTypes.UPDATE_SCHEDULED_POST_FAILURE, err);

// CALENDAR - GET REQUESTED CALENDAR ITEMS
export const getCalendarItemsListRequest = () => createAction(actionTypes.GET_CALENDAR_ITEMS_REQUEST);
export const getCalendarItemsListResponse = (response: calendarApiTypes.ICalendarItemsResponse[]) => createAction(actionTypes.GET_CALENDAR_ITEMS_RESPONSE, response);

// CALENDAR - UPDATE REQUESTED CALENDAR ITEM
export const updateCalendarItemRequest = (payload: calendarApiTypes.ICalendarItemsResponse) => createAction(actionTypes.UPDATE_REQUESTED_CALENDAR_ITEM_REQUEST, payload);
export const updateCalendarItemResponse = (response: calendarApiTypes.ICalendarItemsResponse[]) => createAction(actionTypes.UPDATE_REQUESTED_CALENDAR_ITEM_RESPONSE, response);
export const updateCalendarItemFailure = (error: null | string) => createAction(actionTypes.UPDATE_REQUESTED_CALENDAR_ITEM_FAILURE, error);

// CALENDAR - GET SCHEDULED MULTIPLE POST
export const getScheduledMultiPostsRequest = () => createAction(actionTypes.GET_SCHEDULED_MULTI_POST_REQUEST);
export const getScheduledMultiPostsResponse = (response: calendarApiTypes.IGetScheduledMultiPostResponse[]) => createAction(actionTypes.GET_SCHEDULED_MULTI_POST_RESPONSE, response);

// CALENDAR - UPDATE SCHEDULED MULTIPLE POST
export const updateScheduledMultiPostsRequest = (payload: commonApiTypes.ICommonResponseData) => createAction(actionTypes.UPDATE_SCHEDULED_MULTI_POST_REQUEST, payload);
export const updateScheduledMultiPostsResponse = (response: calendarApiTypes.IGetScheduledMultiPostResponse) => createAction(actionTypes.UPDATE_SCHEDULED_MULTI_POST_RESPONSE, response);
export const updateScheduledMultiPostsFailure = (err: null | string) => createAction(actionTypes.UPDATE_SCHEDULED_MULTI_POST_FAILURE, err);

// CALENDAR - DELETE SCHEDULED MULTIPLE POST
export const deleteScheduledMultiPostRequest = (payload: calendarApiTypes.IDeleteBrandSchPostReqData) => createAction(actionTypes.DELETE_SCHEDULED_MULTI_POST_REQUEST, payload);
export const deleteScheduledMultiPostResponse = (payload: calendarApiTypes.IDeleteBrandSchPostReqData) => createAction(actionTypes.DELETE_SCHEDULED_MULTI_POST_RESPONSE, payload);
export const deleteScheduledMultiPostFailure = (payload: null | string) => createAction(actionTypes.DELETE_SCHEDULED_MULTI_POST_FAILURE, payload);

// CALENDAR - UPDATE SCHEDULED POST
export const updateScheduledPost = (response: calendarContainerTypes.ICalendarDataType) => createAction(actionTypes.UPDATE_SCHEDULED_POST, response);
export const getActivePlatormInScheduledPost = (data: calendarApiTypes.ICalendarSelectedPlatform) => createAction(actionTypes.GET_ACTIVE_PLATFORM_IN_SCH_POST, data);

export const calendarDetailViewError = (error: null | string) => createAction(actionTypes.CALENDAR_DETAIL_VIEW_ERROR, error);

// CALENDAR_VIEW_DATE_RANGE
export const calendarViewDateRange = (payload: ICalendarDateRange) => createAction(actionTypes.CALENDAR_VIEW_DATE_RANGE, payload);

// CALENDAR _ UPDATE EDIT VIEW
export const calendarEditView = (payload: calendarApiTypes.ICalendarUpdateCurrentActionPage) => createAction(actionTypes.CALENDAR_EDIT_VIEW, payload);

// CALENDAR ENABLE SCHEDULE
export const calendarEnableSchedule = (payload: boolean) => createAction(actionTypes.CALENDAR_ENABLE_SCHEDULE, payload);
export const calendarEnableDatePicker = (payload: { isOpen: boolean; dataObj: calendarContainerTypes.ICalendarDataType | null }) => createAction(actionTypes.CALENDAR_ENABLE_DATE_PICKER, payload);

// CALENDAR SELECT DATA OBJECT
export const calendarSelectDataObject = (payload: calendarContainerTypes.ICalendarDataType | null) => createAction(actionTypes.CALENDAR_SELECT_DATA_OBJECT, payload);

// CALENDAR DELETE MODEL OPTION
export const calendarDeleteModalOption = (payload: { isOpenDeleteModal: boolean; isConfirmDelete: boolean }) => createAction(actionTypes.CALENDAR_DELETE_MODEL_OPTION, payload);

// CALENDAR DELETE MODEL OPTION
export const calendarLocationUpdates = (payload: { bool: boolean; data: calendarContainerTypes.ICalendarDataType | null; type: string }) =>
  createAction(actionTypes.CALENDAR_LOCATION_UPDATE_STATES, payload);

// CALENDAR SHUFFLE AUTOMATED POSTS
export const calendarShuffleAutomatedCount = () => createAction(actionTypes.CALENDAR_SHUFFLE_AUTOMATED_SCHEDULED_COUNT);

// CALENDAR AUTOMATED POST DELETE MODEL OPTION
export const calendarAutomatedScheduledPostDeleteModalOption = (payload: { post: number; timeSlot: number }) =>
  createAction(actionTypes.CALENDAR_AUTOMATED_SCHEDULED_POST_DELETE_MODEL_OPTION, payload);

// CALENDAR RESET
export const calendarResetAll = () => createAction(actionTypes.CALENDAR_RESET_ALL);
export const calendarResetListAll = () => createAction(actionTypes.CALENDAR_RESET_LIST_ALL);

const CALENDAR_ACTIONS = {
  getScheduledPostRequest,
  getScheduledPostResponse,
  updateScheduledPostRequest,
  updateScheduledPostResponse,
  updateScheduledPostFailure,
  getCalendarItemsListRequest,
  getCalendarItemsListResponse,
  updateCalendarItemRequest,
  updateCalendarItemResponse,
  updateCalendarItemFailure,
  getScheduledMultiPostsRequest,
  getScheduledMultiPostsResponse,
  updateScheduledMultiPostsRequest,
  updateScheduledMultiPostsResponse,
  updateScheduledMultiPostsFailure,
  deleteScheduledMultiPostRequest,
  deleteScheduledMultiPostResponse,
  deleteScheduledMultiPostFailure,
  updateScheduledPost,
  getActivePlatormInScheduledPost,
  calendarDetailViewError,
  calendarViewDateRange,
  calendarEditView,
  calendarResetAll,
  calendarEnableSchedule,
  calendarSelectDataObject,
  calendarEnableDatePicker,
  calendarDeleteModalOption,
  calendarLocationUpdates,
  calendarShuffleAutomatedCount,
  calendarAutomatedScheduledPostDeleteModalOption,
  calendarResetListAll
};

export type CalendarActions = ActionsUnion<typeof CALENDAR_ACTIONS>;
