import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { campaignsContainerTypes, campaignsApiTypes } from 'types';
import { SHORT_MONTH_DATE_YEAR_FORMAT, AP_FRAN_SHOW_LINE_BREAK_TYPES, CAMPAIGN_INIT_FILTER_DATA, USER_OWNERSHIP } from 'utils/constants';
import { dateFormatByRequestedFormat, getFormattedDate, numberPadding } from 'utils/helpers';
import { useParamsDeconstructor, useAccountSwitcherData } from 'utils/hooks';
import { ImageValidation, Video } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';

export const CampaignCard = ({ campaignItem, className, onDetailView }: campaignsContainerTypes.ICampaignCardProps) => {
  const navigate = useNavigate();

  const { userOwnership } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);

  const handleEditCampaign = (campaignId: number) => {
    navigate({ search: `?${new URLSearchParams({ ...queryParams, edit_campaign: String(campaignId) }).toString()}` });
  };

  const disableEditCampaign = () => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && campaignItem.franchisor_id) return true;
    else return false;
  };

  const CampaignStatus = ({ status }: { status: campaignsApiTypes.ICampaignStatusValue }) => (
    <div className={`coupon-status ${status === 'ACTIVE' ? 'campaign-active' : status === 'INACTIVE' ? 'campaign-inactive' : 'campaign-available'}`}>
      <span className="status__card--txt">{status}</span>
    </div>
  );

  const CampaignLeftSection = ({ id, name, createdAt, postCount, postData, status, description, userId }: campaignsContainerTypes.ICampaignCardLeftProps) => {
    const photoUrl = postData && postData?.photo_preview_url ? postData?.photo_preview_url : postData && 'link_image_url' in postData ? postData?.link_image_url : null;
    return (
      <div className={`coupan-card-left ${postData?.id && (photoUrl || postData?.video_url) ? 'coupon-withThumb' : 'coupon-withoutThumb'}`}>
        <CampaignStatus status={status} />
        <div className="coupon-content ">
          <div className={`coupon__inner--content ${photoUrl ? '' : 'no-thumbnail'}`}>
            {postData?.id && (photoUrl || postData?.video_url) ? (
              <div className="coupon-thumb">
                <div className="m-ast">
                  {photoUrl ? (
                    <ImageValidation isImgValid imgUrl={photoUrl} customClassname={'ct-asset'} customName={'Post Image'} />
                  ) : postData?.video_url ? (
                    <div className="video-outer-element">
                      <Video videoUrl={postData?.video_url} light={postData?.video_thumbnail_url || ''} customClassname={'coupon-video-thumb'} />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="coupon-details">
              <div className="coupon__details--head">
                <span className="ccd-head" title={name}>
                  {name}
                </span>
                {postCount ? (
                  <span className="ccd-count">
                    <span>{numberPadding(postCount)}</span>
                  </span>
                ) : null}
              </div>
              <div className="camp-date">{dateFormatByRequestedFormat(createdAt, SHORT_MONTH_DATE_YEAR_FORMAT)}</div>
              <Paragraph customText={description || ''} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
            </div>
          </div>
          <div className="cpnItem">
            {disableEditCampaign() ? null : (
              <Button
                variant="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditCampaign(id);
                }}
              >
                <span>Edit</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CouponRightSection = ({ usesCount, endDate }: campaignsContainerTypes.ICampaignCardRightProps) => (
    <div className="coupan-card-right">
      <div className="info">
        <div className="ci-usage">
          <span className="ci-label">Uses:</span> <span className="ci-value">{usesCount}</span>
        </div>
        <div className="ci-avail">
          <span className="ci-label">Availability</span>
          <span className="ci-value">{endDate ? getFormattedDate(endDate, SHORT_MONTH_DATE_YEAR_FORMAT) : 'Ongoing'}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`card-bg${className ? ` ${className}` : ''}`} onClick={onDetailView}>
      <CampaignLeftSection
        id={campaignItem.id}
        name={campaignItem.name}
        createdAt={campaignItem.created_at}
        postCount={campaignItem.content ? campaignItem?.contents_count || 0 : campaignItem?.saved_posts_count || 0}
        postData={campaignItem.content ? campaignItem.content : campaignItem.saved_post}
        status={campaignItem.status}
        description={campaignItem.description}
        userId={campaignItem.created_user?.id || 0}
      />
      <CouponRightSection usesCount={campaignItem.campaign_events_count} endDate={campaignItem.end_date} />
    </div>
  );
};
