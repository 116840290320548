import { Modal } from 'react-bootstrap';

import { commonModalPopup } from 'types';
import { MODAL_BOX_TYPES } from 'utils/constants';

export const ModalPopup = (props: commonModalPopup.IModalPopup) => {
  const {
    isModalFooterShow = true,
    isModalShow,
    handleModalClose,
    handleModalOpen = () => null,
    modalBody,
    modalCloseButton,
    modalHeaderType,
    modalFooter = () => null,
    modalHeaderClassName = '',
    containerClassName = '',
    modalSize = 'sm'
  } = props;

  return (
    <Modal
      className={`prime-modal${containerClassName ? ` ${containerClassName}` : ``}`}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onShow={handleModalOpen}
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Header className={modalHeaderClassName} closeButton={modalCloseButton}>
        <button data-dismiss="modal" className="close" type="button" onClick={handleModalClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        {modalHeaderType === MODAL_BOX_TYPES.DANGER && (
          <div className="modal-icon-wraps">
            <span className="modal-itm-wraps modal-danger-wraps" />
          </div>
        )}
        {modalHeaderType === MODAL_BOX_TYPES.INFO && (
          <div className="modal-icon-wraps">
            <span className="modal-itm-wraps modal-info-wraps" />
          </div>
        )}
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      {isModalFooterShow ? <Modal.Footer>{modalFooter()} </Modal.Footer> : null}
    </Modal>
  );
};
