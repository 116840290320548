import { useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';

import { ImageValidation } from 'widgets/Media';
import { IStore, commonWidgetTypes, couponsReducerTypes } from 'types';
import { couponsSetNewCouponObj, cloudSignRequest, cpUploadCloudMediaRequest, addOrEditCouponRequest, couponsDetailDataReset, addPostSavedPostReset, getCouponDetailViewRequest } from 'actions';
import {
  NEW_COUPON_INIT_OBJ,
  DATEPICKER_DATE_ONLY_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  COUPONS_DATE_RANGE_NAME,
  UTC_TIMESTAMP_FORMAT,
  RIPPLE_COLOR,
  ROUTES,
  COMMON_PAGE_TYPE,
  MgMediaType,
  IMAGE_SUPPORTED_FORMAT
} from 'utils/constants';
import { getCouponDateRange, dateFormatByRequestedFormat, getCurrentDate, getParsedDate, getNewCouponReqPayload, getUpdateCouponReqPayload, couponsValidation } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomDatePicker } from 'widgets/DatePicker';
import { Loading } from 'components';
import { CouponBrowseImagesList } from './CouponBrowseImagesList';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const CreateCouponsModalPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();

  const newCouponObj = useSelector((state: IStore) => state.coupons.newCouponObj);
  const couponDetail = useSelector((state: IStore) => state.coupons.couponDetail);
  const isFetching = useSelector((state: IStore) => state.coupons.messages.isFetching);

  const isPhotosModalOpen =
    (optionalParams[0] === COMMON_PAGE_TYPE.NEW && optionalParams[1] === MgMediaType.PHOTOS) || (optionalParams[1] === COMMON_PAGE_TYPE.EDIT && optionalParams[2] === MgMediaType.PHOTOS);

  const { values, touched, errors, handleSubmit } = useFormik({
    initialValues: { ...newCouponObj },
    validationSchema: couponsValidation,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (val: any) => {
      if (id && newCouponObj && !newCouponObj?.id) {
        const couponReqPayload = getNewCouponReqPayload(id, newCouponObj);
        dispatch(addOrEditCouponRequest(couponReqPayload));
      } else if (id && newCouponObj && newCouponObj?.id && couponDetail) {
        const updateCouponReqPayload = getUpdateCouponReqPayload(id, newCouponObj, couponDetail);
        dispatch(addOrEditCouponRequest(updateCouponReqPayload));
      }
      navigate(ROUTES.CONTENT_COUPONS.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
    }
  });

  useEffect(() => {
    if (!couponDetail && optionalParams[1] === COMMON_PAGE_TYPE.EDIT) {
      dispatch(getCouponDetailViewRequest(optionalParams[0]));
    }
  }, [couponDetail, optionalParams[1]]); // eslint-disable-line

  useEffect(() => {
    if (couponDetail?.id) {
      dispatch(
        couponsSetNewCouponObj({
          id: couponDetail.id,
          couponName: couponDetail.coupon_name,
          quantityOfCoupons: couponDetail.quantity,
          couponDate: {
            start: { selected: getParsedDate(couponDetail.start_date), date: couponDetail.start_date },
            expired: { selected: getParsedDate(couponDetail.expiration), date: couponDetail.expiration }
          },
          imageUrl: couponDetail.coupon_image_url,
          offerCopy: couponDetail.description.replace('<p>', '').replace('</p>', ''),
          disclaimerCopy: couponDetail.disclaimer.replace('<p>', '').replace('</p>', ''),
          isMediaFetching: false
        })
      );
    }
  }, [couponDetail?.id]); // eslint-disable-line

  useEffect(() => {
    if (optionalParams[0] === COMMON_PAGE_TYPE.NEW) {
      dispatch(couponsSetNewCouponObj({ ...NEW_COUPON_INIT_OBJ, couponDate: getCouponDateRange() }));
    }
  }, [optionalParams[0]]); // eslint-disable-line

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const currentUnixTimestamp = dateFormatByRequestedFormat(getCurrentDate(), UTC_TIMESTAMP_FORMAT);
      if (currentUnixTimestamp) {
        dispatch(
          cloudSignRequest({
            unixTimestamp: +currentUnixTimestamp,
            onSignatureCallback: (cloudSign) => {
              if (cloudSign.timestamp && cloudSign.apikey && cloudSign.signature && cloudSign.eager.length) {
                acceptedFiles.forEach(async (selectedFile) => {
                  dispatch(
                    cpUploadCloudMediaRequest({
                      timestamp: cloudSign.timestamp,
                      apiKey: cloudSign.apikey,
                      signature: cloudSign.signature,
                      eager: cloudSign.eager.join(),
                      file: selectedFile,
                      fileName: selectedFile.name
                    })
                  );
                });
              }
            }
          })
        );
      }
    },
    [dispatch]
  );

  const getInputBoxClassName = (valueItem: keyof couponsReducerTypes.INewCouponObj) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'erroritem' : ''}`;
    return className;
  };

  const getInputTitleClassName = (valueItem: keyof couponsReducerTypes.INewCouponObj) => {
    let className = '';
    className = `${touched[valueItem] && errors[valueItem] ? 'fltlabels-error-text' : ''}`;
    return className;
  };

  const handleCouponField = (key: string, value: null | string | number) => {
    dispatch(couponsSetNewCouponObj(newCouponObj ? { ...newCouponObj, [key]: value } : null));
  };

  const handleCouponChange = (dateKey: string, dateValue: commonWidgetTypes.IDateValueType) => {
    dispatch(
      couponsSetNewCouponObj(
        newCouponObj
          ? {
              ...newCouponObj,
              couponDate: {
                ...newCouponObj.couponDate,
                [dateKey]: {
                  selected: dateValue,
                  date: dateFormatByRequestedFormat(dateValue, YEAR_MONTH_DAY_FORMAT)
                }
              }
            }
          : null
      )
    );
  };

  const handleCancelCouponData = () => {
    dispatch(couponsSetNewCouponObj(null));
    if (newCouponObj?.id) {
      dispatch(couponsDetailDataReset());
    }
    navigate(ROUTES.CONTENT_COUPONS.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  /* const handleSaveCoupon = () => {
    if (id && newCouponObj && !newCouponObj?.id) {
      const couponReqPayload = getNewCouponReqPayload(id, newCouponObj);
      dispatch(addOrEditCouponRequest(couponReqPayload));
      navigate(ROUTES.CONTENT_COUPONS.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
    } else if (id && newCouponObj && newCouponObj?.id && couponDetail) {
      const updateCouponReqPayload = getUpdateCouponReqPayload(id, newCouponObj, couponDetail);
      dispatch(addOrEditCouponRequest(updateCouponReqPayload));
    }
  }; */

  const { getRootProps, getInputProps } = useDropzone({
    accept: IMAGE_SUPPORTED_FORMAT,
    maxFiles: 1,
    noClick: false,
    onDrop
  });

  const routePush = (path: string) => {
    navigate({ pathname: path });
  };

  return (
    <>
      <Modal
        className="add-assets edit-coupon__list coupons-tab"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        show={optionalParams[0] === COMMON_PAGE_TYPE.NEW || optionalParams[1] === COMMON_PAGE_TYPE.EDIT}
        animation={false}
      >
        <Modal.Body>
          <div className="hubuser-coupons-modal-wrp">
            <div className="card-bg ac-modal">
              <div className="ac-title-holder">
                <h5>{!values?.id ? `New Coupon` : `Edit Coupon`}</h5>
                <div className="close" onClick={() => handleCancelCouponData()}>
                  <ImageValidation isImgValid defaultImg="close" customName="close" />
                </div>
              </div>
              <div className="ac-form-holder">
                <div className="ac-form-row">
                  <div className="form-group form-field-item">
                    <input
                      type="text"
                      name="couponName"
                      value={newCouponObj?.couponName || ''}
                      className={`form-control${newCouponObj?.couponName ? ` used` : ``}`}
                      onChange={(event) => handleCouponField(event.target.name, event.target.value || null)}
                    />
                    <span className="fltlabels">Coupon Name</span>
                  </div>
                  <div className="form-group form-field-item">
                    <input
                      type="text"
                      name="quantityOfCoupons"
                      value={newCouponObj?.quantityOfCoupons || ''}
                      disabled={newCouponObj?.id ? true : false}
                      className={`form-control${newCouponObj?.quantityOfCoupons ? ` used` : ``}`}
                      onChange={(event) => handleCouponField(event.target.name, +event.target.value || 0)}
                    />
                    <span className="fltlabels">Quantity of Coupons</span>
                  </div>
                </div>
                <div className="ac-form-row">
                  <div className="form-group deatefilter-sec-wrp">
                    <div className="calendar-icon">
                      <ImageValidation isImgValid defaultImg="calendar-icon" customName="calendar-icon" />
                    </div>
                    <CustomDatePicker
                      selectedDate={newCouponObj?.couponDate.start.selected || null}
                      dateRangeType={COUPONS_DATE_RANGE_NAME.START}
                      onChange={(date: commonWidgetTypes.IDateValueType) => handleCouponChange(COUPONS_DATE_RANGE_NAME.START, date)}
                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                      customClassname="form-control"
                      placeholderText="Date"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode={'select'}
                      maxDate={newCouponObj?.couponDate?.expired?.selected}
                    />
                    <span className="fltlabels">Start Date</span>
                  </div>

                  <div className="form-group deatefilter-sec-wrp">
                    <div className="calendar-icon">
                      <ImageValidation isImgValid defaultImg="calendar-icon" customName="calendar-icon" />
                    </div>
                    <CustomDatePicker
                      selectedDate={newCouponObj?.couponDate.expired.selected || null}
                      dateRangeType={COUPONS_DATE_RANGE_NAME.EXPIRED}
                      onChange={(date: commonWidgetTypes.IDateValueType) => handleCouponChange(COUPONS_DATE_RANGE_NAME.EXPIRED, date)}
                      dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                      customClassname="form-control"
                      placeholderText="Date"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode={'select'}
                      minDate={newCouponObj?.couponDate?.start?.selected}
                    />
                    <span className="fltlabels">Expiration Date</span>
                  </div>
                </div>
                {!newCouponObj?.isMediaFetching && !newCouponObj?.imageUrl ? (
                  <div className="ac-upload-image">
                    <div className="ac-upload-link">
                      <input {...getInputProps()} />
                      <a
                        href="none"
                        {...getRootProps({
                          onClick: (event) => event.preventDefault()
                        })}
                      >
                        <div className="upload">
                          <ImageValidation isImgValid defaultImg="upload" customName="upload" />
                        </div>
                        <span>My Devices</span>
                      </a>
                    </div>
                    <div className="ac-upload-or">
                      <span>or</span>
                    </div>
                    <div className="ac-upload-link">
                      <a
                        href="none"
                        onClick={(event) => {
                          routePush(
                            optionalParams[0] === COMMON_PAGE_TYPE.NEW
                              ? `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}/${MgMediaType.PHOTOS}`
                              : `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}/${optionalParams[1]}/${MgMediaType.PHOTOS}`
                          );
                          event.preventDefault();
                        }}
                      >
                        Choose an image from your library.
                      </a>
                    </div>
                  </div>
                ) : newCouponObj?.isMediaFetching && !newCouponObj?.imageUrl ? (
                  <div className="mg-upload">
                    <div className="masonry-grid">
                      <div className="m-item mi-skeleton">
                        <div className="m-ast">
                          <div className="m-ast-itm m-ast-img">
                            <div className="mast-green">
                              <div className="mast-loader">
                                <Loading />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mg-upload">
                    <div className="masonry-grid">
                      <div className="m-item">
                        <div className="m-ast">
                          {newCouponObj?.imageUrl && (
                            <div className="m-ast-itm m-ast-img">
                              <ImageValidation imgUrl={newCouponObj?.imageUrl || ''} customName="coupon-asset" />
                            </div>
                          )}
                          <div className="lb-btn lb-close" onClick={() => handleCouponField('imageUrl', null)}>
                            <ImageValidation isImgValid defaultImg="remove-white" customName="remove" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="ac-form-row offers-copy-row">
                  <div className={`form-group form-field-item w-100 ${getInputBoxClassName('offerCopy')}`}>
                    {/* <div className="form-group form-field-item w-100"> */}
                    <input
                      type="text"
                      name="offerCopy"
                      value={newCouponObj?.offerCopy || ''}
                      className={`form-control${newCouponObj?.offerCopy ? ` used` : ``}`}
                      onChange={(event) => handleCouponField(event.target.name, event.target.value || null)}
                    />
                    <span className={`fltlabels ${getInputTitleClassName('offerCopy')}`}>Offer Copy</span>
                  </div>
                </div>
                <div className="ac-form-row  offers-copy-row">
                  <div className="form-group form-field-item w-100">
                    <input
                      type="text"
                      name="disclaimerCopy"
                      value={newCouponObj?.disclaimerCopy || ''}
                      className={`form-control${newCouponObj?.disclaimerCopy ? ` used` : ``}`}
                      onChange={(event) => handleCouponField(event.target.name, event.target.value || null)}
                    />
                    <span className="fltlabels">Disclaimer Copy</span>
                  </div>
                </div>
              </div>
              <div className="ac-footer">
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className="ac-btn ac-secondary-white ac-outline size-xs" onClick={() => handleCancelCouponData()}>
                    <span>Go Back</span>
                  </button>
                </CustomRippleButton>
                <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!newCouponObj?.couponName || isFetching ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                  <button
                    className={`ac-btn ac-primary ac-outline size-xs ${!newCouponObj?.couponName || isFetching ? `disabled` : ``}`}
                    onClick={() => newCouponObj?.couponName && !isFetching && handleSubmit()}
                  >
                    <span>Save</span>
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CouponBrowseImagesList
        isModalShow={isPhotosModalOpen}
        handleModalClose={() => {
          routePush(
            optionalParams[0] === COMMON_PAGE_TYPE.NEW
              ? `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}`
              : `/${userOwnership}/${id.toString()}/${currentRoute}/${optionalParams[0]}/${optionalParams[1]}`
          );
          dispatch(addPostSavedPostReset());
        }}
      />
    </>
  );
};
