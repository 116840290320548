import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import { ALERT_BOX_TYPES, AP_CONTENT_ACTION_TYPE, RIPPLE_COLOR } from 'utils/constants';
import { addPostGetSelectedContentObj, getAverageLocationsSpendFailure } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { alertBoxCall } from 'components';

export const FranPostNowModal = (props: commonModalPopup.IAddPostFranPostNowModalPopup) => {
  const { isModalShow, handleModalClose, disablePostButton, handlePostData, setOpenPostNowModal } = props;

  const dispatch = useDispatch();
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const errorMsg = useSelector((state: IStore) => state.fbAdsTargeting.averageLocationsSpend.errorMsg);

  useEffect(() => {
    if (errorMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, errorMsg);
      setOpenPostNowModal(false);
      dispatch(getAverageLocationsSpendFailure(null));
    }
  }, [errorMsg, setOpenPostNowModal, dispatch]);

  return (
    <Modal
      className="prime-modal modal-confirmation confirm-delete post-now__modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Body>
        <div className="cretor-postnow-modal-wrp hubuser-creator-postnow-modal-wrp">
          <div className="m-b-2re">
            <ImageValidation isImgValid defaultImg={'post-now-icon-creator'} customName={'post now'} />
          </div>
          <div className="modal-message-wraps">Are you sure you want to post now?</div>
          <div className="message-sub-text">Posting now will override all the user permissions and post immediately on all the account selected</div>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0">
        <div className="cretor-postnow-modal-wrp hubuser-creator-postnow-modal-wrp">
          <div className="modal-btn-grp-wraps">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box ${disablePostButton ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary ac-block ${disablePostButton ? `disabled` : ``}`} onClick={() => handlePostData(AP_CONTENT_ACTION_TYPE.POST_NOW)}>
                Post Now
              </button>
            </CustomRippleButton>
          </div>
          <div className="postnow-form">
            <div className="form-check">
              <label className="checkbox-item">
                <input
                  type="checkbox"
                  name="isNotShowPost"
                  checked={contentObj.isNotShowPost}
                  onChange={(event) => {
                    dispatch(
                      addPostGetSelectedContentObj({
                        ...contentObj,
                        [event.target.name]: !contentObj.isNotShowPost
                      })
                    );
                    localStorage?.setItem('doNotShowPostNowConfirm', `${!contentObj.isNotShowPost}`);
                  }}
                />
                <div className="label-txt">Do not show me this again</div>
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
