import { IContentAnalyticsFilter, IModalApiDataType, IContentAnalyticsHeader, ICATableData, IContentModalData } from 'analytics/types';
import { NOT_APPLICABLE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';
import { convertMsTimeFormat, dateHelperForMonth } from '../../helpers';
import { NO_DATA_FOUND, PLATFORM_FILTER } from '../common';

export enum ContentAnalyticsHeaderValue {
  POSTED_AT = 'posted_at',
  STATS = 'stats',
  IMPRESSIONS = 'impressions',
  ENGAGEMENT = 'engagement',
  RATE = 'rate',
  SPEND = 'spend'
}

export enum ContentAnalyticsHeaderLabel {
  DATE_POSTED = 'Date Posted',
  PUBLISHED = 'Published',
  IMPRESSIONS = 'Impressions',
  ENGAGEMENT = 'Engagement',
  RATE = 'Rate',
  SPEND = 'Spend',
  PROMOTE = 'Promote',
  VIDEO_AVERAGE_TIME_WATCHED = 'Average Time Watched',
  VIDEO_TOTAL_TIME_WATCHED = 'Total Time Watched',
  REEL_PLAY_COUNT = 'Play Count',
  STORY_EXITS = 'Exits',
  STORY_TAPS_FORWARD = 'Taps Forward',
  STORY_TAPS_BACK = 'Taps Backward'
}

export const CONTENT_ANALYTICS_TABLE_HEADERS: IContentAnalyticsHeader[] = [
  {
    id: 1,
    label: ContentAnalyticsHeaderLabel.DATE_POSTED,
    sortValue: 'posted_at',
    value: ContentAnalyticsHeaderValue.POSTED_AT,
    tableValue: 'postedAt'
  },
  {
    id: 2,
    label: ContentAnalyticsHeaderLabel.PUBLISHED,
    sortValue: 'stats_count',
    tooltipContent: 'The number of times a post was published to pages.',
    value: ContentAnalyticsHeaderValue.STATS,
    tableValue: 'published'
  },
  {
    id: 3,
    label: ContentAnalyticsHeaderLabel.IMPRESSIONS,
    sortValue: 'impressions_total',
    tooltipContent: `The number of times a post has been displayed in a person's feed.`,
    value: ContentAnalyticsHeaderValue.IMPRESSIONS,
    tableValue: 'impression'
  },
  {
    id: 4,
    label: ContentAnalyticsHeaderLabel.ENGAGEMENT,
    sortValue: 'engagement_total',
    tooltipContent: 'The number of comments, likes, shares and other interactions on posts published during the selected date range.',
    value: ContentAnalyticsHeaderValue.ENGAGEMENT,
    tableValue: 'engagement'
  },
  {
    id: 5,
    label: ContentAnalyticsHeaderLabel.RATE,
    sortValue: 'rate',
    tooltipContent: `The engagement rate percentage is a measure of engagement divided by impressions.`,
    value: ContentAnalyticsHeaderValue.RATE,
    tableValue: 'rate'
  },
  {
    id: 6,
    label: ContentAnalyticsHeaderLabel.SPEND,
    sortValue: 'boost',
    tooltipContent: `The amount spent in this platform to promote a post via boosting.`,
    value: ContentAnalyticsHeaderValue.SPEND,
    tableValue: 'spent'
  },
  {
    id: 7,
    label: ContentAnalyticsHeaderLabel.PROMOTE,
    tableValue: 'promote'
  },
  {
    id: 8,
    label: ContentAnalyticsHeaderLabel.VIDEO_AVERAGE_TIME_WATCHED,
    sortValue: 'video_average_time_watched',
    value: 'video_average_time_watched',
    tableValue: 'avgTime'
  },
  {
    id: 9,
    label: ContentAnalyticsHeaderLabel.VIDEO_TOTAL_TIME_WATCHED,
    sortValue: 'video_total_time_watched',
    value: 'video_total_time_watched',
    tableValue: 'totalTime'
  },
  {
    id: 10,
    label: ContentAnalyticsHeaderLabel.REEL_PLAY_COUNT,
    sortValue: 'reel_play_count',
    value: 'reel_play_count',
    tableValue: 'playCount'
  },
  {
    id: 11,
    label: ContentAnalyticsHeaderLabel.STORY_EXITS,
    sortValue: 'story_exits',
    value: 'story_exits',
    tableValue: 'exits'
  },
  {
    id: 12,
    label: ContentAnalyticsHeaderLabel.STORY_TAPS_FORWARD,
    sortValue: 'story_taps_forward',
    value: 'story_taps_forward',
    tableValue: 'tapsForward'
  },
  {
    id: 13,
    label: ContentAnalyticsHeaderLabel.STORY_TAPS_BACK,
    sortValue: 'story_taps_backward',
    value: 'story_taps_backward',
    tableValue: 'tapsBackward'
  }
];

export const CONTENT_ANALYTICS_API_KEY = {
  impressions: [
    { label: 'all', value: 'impressions_total' },
    { label: 'organic', value: 'impressions_unpaid' },
    { label: 'paid', value: 'impressions_paid' }
  ],
  engagement: [
    { label: 'all', value: 'engagement_total' },
    { label: 'likes', value: 'engagement_likes' },
    { label: 'comments', value: 'engagement_comments' },
    { label: 'shares', value: 'engagement_shares' },
    { label: 'clicks', value: 'engagement_clicks' }
  ]
};

export enum ContentAnalyticsTitle {
  IMPRESSIONS = 'Impressions',
  ENGAGEMENT = 'Engagement',
  POST_SOURCE = 'Post Source',
  POST_CLICKS = 'Post Clicks',
  NEGATIVE_FEEDBACK = 'Negative Feedback',
  REELS_INSIGHTS = 'Reels Insights'
}

export enum ContentPlatformValue {
  ALL = 'all',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  GOOGLE = 'google',
  INSTAGRAM_BUSINESS_PAGE = 'instagram_business_page',
  INSTAGRAM = 'instagram'
}

export const CONTENT_PLATFORM_FILTERS = [
  { value: ContentPlatformValue.ALL, label: 'All' },
  { value: ContentPlatformValue.FACEBOOK, label: 'Facebook' },
  { value: ContentPlatformValue.LINKEDIN, label: 'Linkedin' },
  { value: ContentPlatformValue.TWITTER, label: 'X (Formerly Twitter)' },
  { value: ContentPlatformValue.GOOGLE, label: 'Google' },
  { value: ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE, label: 'Instagram' }
];

export enum ContentImpression {
  ALL = 'all',
  ORGANIC = 'organic',
  PAID = 'paid'
}

// types ICAImpressionEngagementType
export const CONTENT_IMPRESSION_FILTER = [
  { value: ContentImpression.ALL, label: 'All', title: ContentAnalyticsTitle.IMPRESSIONS },
  { value: ContentImpression.ORGANIC, label: 'Organic' },
  { value: ContentImpression.PAID, label: 'Paid' }
];

export enum ImpressionsUnique {
  UNIQUE = 'unique'
}

export const IMPRESSIONS_UNIQUE_FILTER = [{ value: ImpressionsUnique.UNIQUE, label: 'Unique Impression', title: ContentAnalyticsTitle.IMPRESSIONS }];

export enum ReelsInsights {
  VIDEO_AVERAGE_TIME_WATCHED = 'Average Time Watched',
  VIDEO_TOTAL_TIME_WATCHED = 'Total Time Watched',
  REEL_PLAY_COUNT = 'Play Count'
}

export const REELS_INSIGHTS_FILTER = [
  { value: ReelsInsights.VIDEO_AVERAGE_TIME_WATCHED, label: 'Average Time Watched', title: ContentAnalyticsTitle.REELS_INSIGHTS },
  { value: ReelsInsights.VIDEO_TOTAL_TIME_WATCHED, label: 'Total Time Watched' },
  { value: ReelsInsights.REEL_PLAY_COUNT, label: 'Play Count' }
];

export enum ContentEngagement {
  ALL = 'all',
  LIKES = 'likes',
  COMMENTS = 'comments',
  SHARES = 'shares',
  CLICKS = 'clicks'
}

// types ICAImpressionEngagementType
export const CONTENT_ENGAGEMENT_FILTER = [
  { value: ContentEngagement.ALL, label: 'All', title: ContentAnalyticsTitle.ENGAGEMENT },
  { value: ContentEngagement.LIKES, label: 'Likes' },
  { value: ContentEngagement.COMMENTS, label: 'Comments' },
  { value: ContentEngagement.SHARES, label: 'Shares' },
  { value: ContentEngagement.CLICKS, label: 'Clicks' }
];

export enum ContentAnalyticsFilterValue {
  HUB = 'hub',
  LOCAL_POST = 'local_post'
}

// types ICAImpressionEngagementType
export const CONTENT_ANALYTICS_FILTER = [
  // { value: 'all', label: 'All', title: 'Analtyics' },
  { value: ContentAnalyticsFilterValue.HUB, label: 'Hub', title: ContentAnalyticsTitle.POST_SOURCE },
  { value: ContentAnalyticsFilterValue.LOCAL_POST, label: 'Local' }
];

export const POST_CLICKS = [
  { value: 'all', label: 'All', title: ContentAnalyticsTitle.POST_CLICKS },
  { value: 'image_views', label: 'Image Views' },
  { value: 'link_cliks', label: 'Link Clicks' },
  { value: 'other_clicks', label: 'Other Clicks' }
];

export const NEGATIVE_FEEDBACK = [
  { value: 'all', label: 'All', title: ContentAnalyticsTitle.NEGATIVE_FEEDBACK },
  { value: 'hide_post', label: 'Hide Post' },
  { value: 'hide_all post', label: 'Hide All Post' },
  { value: 'unlike_page', label: 'Unlike Page' },
  { value: 'Report_as_Spam', label: 'Report as Spam' }
];

export const POST_LINK = [
  { label: 'linkein', link: 'https://www.linkedin.com/embed/feed/update/' },
  { label: 'instagram', link: 'embed' },
  { label: 'facebook', link: 'https://www.facebook.com/plugins/post.php?href=' }
];

export const TABLE_TYPE = [
  { value: 'posts', label: 'All Posts', title: 'type' },
  { value: 'reels', label: 'Reels Only' },
  { value: 'stories', label: 'Stories' },
  { value: 'tags', label: 'Tags' }
];

export const CONTENT_ANALYTICS_FILTER_INITIAL_VALUE: IContentAnalyticsFilter = {
  id: 0,
  dateRange: dateHelperForMonth(),
  platform: ContentPlatformValue.FACEBOOK,
  impressions: ContentImpression.ALL,
  engagement: ContentEngagement.ALL,
  page: 1,
  by: {
    key: 'posted_at',
    isAscending: false
  },
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  analytics: ContentAnalyticsFilterValue.HUB
};

export const VALID_MULTIPLE_LOCATION_ROUTES = [
  { route: 'facebook_post_stats', platform: 'facebook' },
  { route: 'linkedin_post_stats', platform: 'linkedin' },
  { route: 'instagram_post_stats', platform: 'instagram' }
];

export const MODAL_API_DATA: IModalApiDataType = { id: 0, userId: 0, isHubUser: false };

export const ANALYTICS_CONTENT_PLATFORM_FILTER = [
  ...PLATFORM_FILTER.slice(0, 2),
  {
    ...PLATFORM_FILTER[2],
    value: ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE
  },
  // PLATFORM_FILTER[3],
  PLATFORM_FILTER[4],
  PLATFORM_FILTER[6]
];

export enum ContetnAnalyticsValidQueryParams {
  PLATFORM = 'platform',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  ASCENDING = 'ascending',
  SORT_BY = 'sort_by',
  ENGAGEMENT = 'engagement',
  IMPRESSIONS = 'impressions',
  AI_POST = 'ai_post'
}

export const TAGS_CONTENT_ANALYTICS_TABLE_HEADERS = [
  { id: 1, label: 'Tags', sortValue: 'tag_name', value: 'tag_name' },
  { id: 2, label: ContentAnalyticsHeaderLabel.PUBLISHED, sortValue: 'stats_count', value: 'stats_count' },
  {
    id: 3,
    label: ContentAnalyticsHeaderLabel.IMPRESSIONS,
    sortValue: 'impressions_total',
    value: 'impressions_total'
  },
  {
    id: 4,
    label: ContentAnalyticsHeaderLabel.ENGAGEMENT,
    sortValue: 'engagement_total',
    value: 'engagement_total'
  },
  {
    id: 5,
    label: ContentAnalyticsHeaderLabel.RATE,
    sortValue: 'rate',
    value: 'rate'
  }
];

export const TAGS_CHART_TYPES = [
  { label: ContentAnalyticsHeaderLabel.PUBLISHED, value: 'stats_count', color: '#A9EBE0', fadeColor: '#01D4A8', image: 'screen-b', customname: 'Published' },
  { label: ContentAnalyticsHeaderLabel.ENGAGEMENT, value: ContentAnalyticsHeaderValue.ENGAGEMENT, color: '#C0C8EE', fadeColor: '#6e84f7', image: 'like-b', customname: 'Engagement' },
  { label: ContentAnalyticsHeaderLabel.IMPRESSIONS, value: ContentAnalyticsHeaderValue.IMPRESSIONS, color: '#E6C0F4', fadeColor: '#bb6bd9', image: 'star-b', customname: 'Impressions' },
  { label: ContentAnalyticsHeaderLabel.RATE, value: ContentAnalyticsHeaderValue.RATE, color: '#A1DBF4', fadeColor: '#67C0E6', image: 'rate-b', customname: 'Rate' }
];

export const TAG_CHART_OPTIONS: any = {
  title: {
    text: ''
  },
  yAxis: {
    min: 0,
    title: {
      text: null,
      align: 'high'
    },
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    crosshair: {
      color: 'rgba(56, 112, 255, 0.1)',
      width: 41
    },
    tickmarkPlacement: 'on',
    labels: {
      overflow: 'justify'
    }
  },
  tooltip: {
    formatter(this: any) {
      const tooltipXData = this?.x as any;
      const tooltipYData = this?.y as any;
      const tooltipYPerc = this?.percentage as any;
      return `${tooltipXData}:${tooltipYPerc ? tooltipYPerc?.toLocaleString() : tooltipYData?.toLocaleString()}${tooltipYPerc ? '%' : ''}`;
    }
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
        formatter(this: any) {
          const tooltipYData = this?.y as any;
          const tooltipYPerc = this?.percentage as any;
          return `${tooltipYPerc ? tooltipYPerc?.toLocaleString() : tooltipYData?.toLocaleString()}${tooltipYPerc ? '%' : ''}`;
        }
      }
    },
    series: {
      pointWidth: 20
    }
  },
  legend: {
    enabled: false
  },
  lang: {
    noData: NO_DATA_FOUND
  },
  credits: {
    enabled: false
  }
};

export const VALID_CONTENT_ANALYTICS_TABLE_HEADERS: any = {
  hub: { facebook: [1, 2, 3, 4, 5, 6], instagram: [1, 2, 3, 4, 5], linkedin: [1, 2, 3, 4, 5] },
  local: { facebook: [1, 3, 4, 5, 6], instagram: [1, 3, 4, 5], linkedin: [1, 3, 4, 5] },
  hubReels: { facebook: [1, 2, 3, 4, 8, 9, 10], instagram: [1, 2, 3, 4, 10] },
  hubStories: { facebook: [1, 2, 3, 4, 11, 12, 13], instagram: [1, 2, 3, 4, 11, 12, 13] },
  localReels: { facebook: [1, 3, 4, 8, 9, 10], instagram: [1, 3, 4, 10] },
  localStories: { facebook: [1, 3, 4, 11, 12, 13], instagram: [1, 3, 4, 11, 12, 13] },
  location: {
    all: [1, 3, 4, 5, 7],
    facebook: [1, 3, 4, 5, 6, 7],
    instagram_business_page: [1, 3, 4, 5, 7],
    linkedin: [1, 3, 4, 5, 7]
  },
  locationReels: { facebook: [1, 3, 4, 8, 9, 10], instagram_business_page: [1, 3, 4, 10] },
  locationStories: { facebook: [1, 3, 4, 11, 12, 13], instagram_business_page: [1, 3, 4, 11, 12, 13] },
  multipleLocations: { facebook_post_stats: [1, 3, 4, 5, 6], instagram_post_stats: [1, 3, 4, 5], linkedin_post_stats: [1, 3, 4, 5] },
  promote: {
    all: [1, 3, 4, 5, 7],
    facebook: [1, 3, 4, 5, 6, 7],
    instagram_business_page: [1, 3, 4, 5],
    linkedin: [1, 3, 4, 5]
  }
};

export const platformInstagram: string[] = [ContentPlatformValue.INSTAGRAM, ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE];

export const contentModelDataArr = (datum: ICATableData, platform: string, type: string) => {
  let contentModelData: IContentModalData[] = [
    {
      header: 'Impressions',
      value: 'impressions',
      count: datum?.impressionTotal,
      stats:
        platform === ContentPlatformValue.LINKEDIN
          ? [{ label: 'Impressions', value: 'impressions', count: datum?.impressionTotal }]
          : type === 'reels'
          ? [{ label: 'Unique Impression', value: 'unique', count: datum?.impressionUnique }]
          : [
              { label: 'Organic', value: 'organic', count: datum?.impressionUnpaid },
              { label: 'Paid', value: 'paid', count: datum?.impressionPaid }
            ]
    },
    {
      header: 'Engagement',
      value: 'engagement',
      count: datum?.engagementModalTotal,
      stats:
        platform === ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE
          ? [
              { label: 'Likes', value: 'likes', count: datum?.engagementLikes },
              { label: 'Comments', value: 'comments', count: datum?.engagementComment }
            ]
          : [
              { label: 'Likes', value: 'likes', count: datum?.engagementLikes },
              { label: 'Comments', value: 'comments', count: datum?.engagementComment },
              { label: 'Shares', value: 'shares', count: datum?.engagementShares || datum?.shares }
            ]
    },
    {
      header: 'Post Clicks',
      value: 'post_clicks',
      count: datum?.postCliksTotal,
      stats:
        platform === ContentPlatformValue.LINKEDIN
          ? [{ label: 'Post Clicks', value: 'post_clicks', count: datum?.postCliksTotal }]
          : [
              { label: 'Image Views', value: 'image_views', count: datum?.postClicksPhoto },
              { label: 'Link Clicks', value: 'link_clicks', count: datum?.postClicksLink },
              { label: 'Other Clicks', value: 'other_clicks', count: datum?.postClicksOthers }
            ]
    }
  ];

  if (type === 'reels' || platform === ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE) {
    contentModelData = contentModelData.filter((data) => data.value !== 'post_clicks');
  }
  if (type === 'reels' && ['facebook', 'instagram', 'instagram_business_page'].includes(platform)) {
    const reelsInsights: IContentModalData = {
      header: 'Reels Insights',
      value: 'reels_insights',
      stats: [
        ...(['facebook'].includes(platform)
          ? [
              {
                label: 'Average Time Watched',
                value: 'average_time_watched',
                count: Number.isInteger(datum?.videoAverageTimeWatched) ? convertMsTimeFormat(datum?.videoAverageTimeWatched, true, true) : NOT_APPLICABLE
              }
            ]
          : []),
        ...(['facebook'].includes(platform)
          ? [
              {
                label: 'Total Time Watched',
                value: 'total_time_watched',
                count: Number.isInteger(datum?.videoTotalTimeWatched) ? convertMsTimeFormat(datum?.videoTotalTimeWatched, true, true) : NOT_APPLICABLE
              }
            ]
          : []),
        { label: 'Play Count', value: 'play_count', count: datum?.reelPlayCount }
      ]
    };
    contentModelData.push(reelsInsights);
  }
  if (type === 'stories' && ['facebook', 'instagram', 'instagram_business_page'].includes(platform)) {
    const storiesInteraction: IContentModalData = {
      header: 'Stories Insights',
      value: 'stories_insights',
      stats: [
        {
          label: 'Exits',
          value: 'story_exits',
          count: datum?.storyExits
        },
        {
          label: 'Taps Forward',
          value: 'story_taps_forward',
          count: datum?.storyTapsForward
        },
        { label: 'Taps Backward', value: 'story_taps_backward', count: datum?.storyTapsBack }
      ]
    };
    contentModelData.push(storiesInteraction);
  }
  if (!['reels', 'stories'].includes(type) && platform !== ContentPlatformValue.LINKEDIN && platform !== ContentPlatformValue.INSTAGRAM_BUSINESS_PAGE) {
    const negativeFeedback: IContentModalData = {
      header: 'Negative Feedback',
      value: 'negative_feedback',
      count: datum?.negativeFeedbackTotal,
      stats: [
        { label: 'Hide Post', value: 'hide_post', count: datum?.negativeFeedHide },
        { label: 'Hide All Post', value: 'hide_all_post', count: datum?.negativeFeedHideAll },
        { label: 'Unlike Page', value: 'unlike_page', count: datum?.negativeFeedUnlike },
        { label: 'Report as Spam', value: 'report_as_spam', count: datum?.negativeFeedSpam }
      ]
    };
    contentModelData.push(negativeFeedback);
  }
  return contentModelData;
};
export const CONTENT_ANALYTICS_TIME_AND_ZONE_FORMAT = 'h:mm A z';
export const CONTENT_ANALYTICS_TIME_FORMAT = 'h:mm A';
