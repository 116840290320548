import { put, takeEvery } from 'redux-saga/effects';

import {
  overviewLocationsResponse,
  overviewLocationsRequestFail,
  overviewTotalMetricsResponse,
  overviewTotalMetricsRequestFail,
  overviewRecentPostsResponse,
  overviewRecentPostsRequestFail,
  overviewPostsMetricsResponse,
  overviewPostsMetricsRequestFail,
  overviewMonthlyPostsResponse,
  overviewMonthlyPostsRequestFail,
  exceptionHandlerRequest,
  overviewSetLocListArray
} from 'actions';
import * as types from 'actions/action-types';
import { apiCall, defaultHeader, API /* , locationGeocoderResponse */ } from 'utils/helpers';
import {
  OVERVIEW_EXCEPTION_ERROR,
  USER_OWNERSHIP,
  OVERVIEW_LOCATION_LIST_FAILURE,
  OVERVIEW_POST_METRICS_FAILURE,
  OVERVIEW_TOTAL_METRICS_FAILURE,
  OVERVIEW_RECENT_POSTS_LIST_FAILURE,
  OVERVIEW_MONTHLY_POST_METRICS_FAILURE
} from 'utils/constants';
import { commonApiTypes, overviewApiType } from 'types';

type IOverviewParamsReq = overviewApiType.IOverviewPostMetricsReq;
type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendOverviewLocationsRequest({ payload }: ISagaPayload): any {
  try {
    if (payload.id !== null && payload.userType !== null) {
      const headers = defaultHeader();
      const params: IOverviewParamsReq = {};
      if (payload.userType === USER_OWNERSHIP.ACCOUNT) params.account_id = payload.id;
      else {
        params.franchisor_id = payload.id;
        params.list = payload.list;
      }
      const urlPathObj = { ...API.overviewLocations };
      const replacedPath = urlPathObj.apiPath.replace(':metric', payload.rankingMetric);
      urlPathObj.apiPath = replacedPath;
      const response = yield apiCall({ headers, params, ...urlPathObj });
      if (response.status === 200) {
        yield put(overviewLocationsResponse(response.data));
        const accountsArray: Array<overviewApiType.IOverviewAccountData> =
          Array.isArray(response.data.accounts) && response.data.accounts.length ? response.data.accounts : response.data.account ? [response.data.account] : [];
        // const updatedLocationListPayload: overviewApiType.IOverviewAccountData[] = yield locationGeocoderResponse(accountsArray);
        yield put(overviewSetLocListArray(accountsArray));
      } else {
        yield put(overviewLocationsRequestFail(OVERVIEW_LOCATION_LIST_FAILURE));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${OVERVIEW_EXCEPTION_ERROR} Location list`));
  }
}

function* sendOverviewTotalMetricsRequest({ payload }: ISagaPayload): any {
  try {
    if (payload.id !== null && payload.userType !== null) {
      const headers = defaultHeader();
      const params: IOverviewParamsReq = {};
      if (payload.userType === USER_OWNERSHIP.ACCOUNT) {
        params.account_id = payload.id;
        params.date_range = payload.dateRange;
      } else {
        params.franchisor_id = payload.id;
        // params.list = payload.list;
        params.date_range = payload.dateRange;
      }
      const urlPathObj = { ...API.overviewTotalMetrics };
      const replacedPath = urlPathObj.apiPath.replace(':metric', payload.rankingMetric);
      urlPathObj.apiPath = replacedPath;
      const response = yield apiCall({ headers, params, ...urlPathObj });
      if (response.status === 200) {
        yield put(overviewTotalMetricsResponse(response.data));
      } else {
        yield put(overviewTotalMetricsRequestFail(OVERVIEW_TOTAL_METRICS_FAILURE));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${OVERVIEW_EXCEPTION_ERROR} Total metrics`));
  }
}

function* sendOverviewRecentPostsRequest({ payload }: ISagaPayload): any {
  try {
    if (payload.id !== null && payload.userType !== null) {
      const headers = defaultHeader();
      const params: IOverviewParamsReq = {};
      if (payload.userType === USER_OWNERSHIP.ACCOUNT) {
        params.account_id = payload.id;
        params.date_range = payload.dateRange;
      } else {
        params.ancestor_franchisor_id = payload.id;
        // params.list = payload.list;
        params.date_range = payload.dateRange;
      }
      const urlPathObj = { ...API.overviewRecentPosts };
      const replacedPath = urlPathObj.apiPath.replace(':metric', payload.rankingMetric);
      urlPathObj.apiPath = replacedPath;
      const response = yield apiCall({ headers, params, ...urlPathObj });
      if (response.status === 200) {
        yield put(overviewRecentPostsResponse(response.data.recent_posts));
      } else {
        yield put(overviewRecentPostsRequestFail(OVERVIEW_RECENT_POSTS_LIST_FAILURE));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${OVERVIEW_EXCEPTION_ERROR} Recent posts list`));
  }
}

function* sendOverviewPostsMetricsRequest({ payload }: ISagaPayload): any {
  try {
    if (payload.id !== null && payload.userType !== null) {
      const headers = defaultHeader();
      const params: IOverviewParamsReq = {};
      if (payload.userType === USER_OWNERSHIP.ACCOUNT) {
        params.account_id = payload.id;
        params.date_range = payload.dateRange;
      } else {
        params.franchisor_id = payload.id;
        // params.list = payload.list;
        params.date_range = payload.dateRange;
      }
      const urlPathObj = { ...API.overviewPostsMetrics };
      const replacedPath = urlPathObj.apiPath.replace(':metric', payload.rankingMetric);
      urlPathObj.apiPath = replacedPath;
      const response = yield apiCall({ headers, params, ...urlPathObj });
      if (response.status === 200) {
        yield put(overviewPostsMetricsResponse(response.data.posts_metrics));
      } else {
        yield put(overviewPostsMetricsRequestFail(OVERVIEW_POST_METRICS_FAILURE));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${OVERVIEW_EXCEPTION_ERROR} Post metrics`));
  }
}

function* sendOverviewMonthlyPostsRequst({ payload }: ISagaPayload): any {
  try {
    if (payload.id !== null && payload.userType !== null) {
      const headers = defaultHeader();
      const params: IOverviewParamsReq = {
        ...(payload.userType === USER_OWNERSHIP.ACCOUNT ? { account_id: payload.id } : { franchisor_id: payload.id }),
        start_date: payload.startDate,
        end_date: payload.endDate
      };
      const urlPathObj = { ...API.overviewMonthlyPostsMetrics };
      const replacedPath = urlPathObj.apiPath.replace(':metric', payload.rankingMetric);
      urlPathObj.apiPath = replacedPath;
      const response = yield apiCall({ headers, params, ...urlPathObj });
      if (response.status === 200) {
        yield put(overviewMonthlyPostsResponse(response.data.date_wise_post_metrics));
      } else {
        yield put(overviewMonthlyPostsRequestFail(OVERVIEW_MONTHLY_POST_METRICS_FAILURE));
      }
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(`${OVERVIEW_EXCEPTION_ERROR} Monthly post metrics`));
  }
}

export function* takeOverviewRequest(): any {
  yield takeEvery(types.OVERVIEW_LOCATIONS_REQUEST, sendOverviewLocationsRequest);
  yield takeEvery(types.OVERVIEW_TOTALMETRICS_REQUEST, sendOverviewTotalMetricsRequest);
  yield takeEvery(types.OVERVIEW_RECENTPOSTS_REQUEST, sendOverviewRecentPostsRequest);
  yield takeEvery(types.OVERVIEW_POSTSMETRICS_REQUEST, sendOverviewPostsMetricsRequest);
  yield takeEvery(types.OVERVIEW_MONTHLY_POSTS_REQUEST, sendOverviewMonthlyPostsRequst);
}
