import moment, { DurationInputArg2, MomentInput } from 'moment-timezone';

import { accountApiTypes, commonProps, commonWidgetTypes, couponsReducerTypes, directoryListingsContainerTypes } from 'types';
import {
  DATE_TIME_FORMAT,
  MONTH_DATE_FORMAT,
  UTC_TIMESTAMP_FORMAT,
  DATE_FORMAT,
  EMAIL_DATE_FORMAT,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  ICommonDateTypes,
  MOMENT_DATE_FORMAT,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  PAGINATION_PER_PAGE_SMALL_GRID,
  TIME_ONLY_FOR_EACH_12_HOURS,
  MOMENT_TIME_FORMAT,
  ADV_LB_DATE_RANGE_FILTER_TYPE,
  SIDE_NAV_NAME,
  CALENDAR_YR_MN_DATE_FORMAT,
  DATE_FORMAT_HYPEN
} from 'utils/constants';

export const startEndDate = (date: Date | number, type: moment.unitOfTime.StartOf) => {
  const startDate = moment(date).startOf(type).toDate();
  const endDate = moment(date).endOf(type).toDate();
  return { startDate, endDate };
};

export const utcTimeStampConverter = (date: Date) => {
  return moment(date).format(UTC_TIMESTAMP_FORMAT);
};

export const dateFormatterMmDDYYToLocal = (dateString: string | null | Date) => {
  if (dateString === null) {
    return null;
  }
  const date = moment.utc(dateString);
  if (!date.isValid) return null;
  return moment(date).local().format(DATE_TIME_FORMAT);
};

export const dateTimeObjectFormatter = (dateString: string | null | Date) => {
  let dateTimeObj: any;
  if (dateString) {
    const dateTimeformat = dateFormatterMmDDYYToLocal(dateString);
    dateTimeObj = {
      dateFormat: dateTimeformat && dateTimeformat !== null ? dateTimeformat?.split(' ')[0] : '',
      timeFormat: dateTimeformat && dateTimeformat !== null ? dateTimeformat.split(' ').splice(1).join(' ') : ''
    };
  }
  return dateTimeObj;
};

export const dateLessThanDay = (dateString: any) => {
  const responseDateObj: any = {
    ended: false,
    endSoon: false,
    endingText: ''
  };
  if (dateString === null) {
    return null;
  }
  const date = moment.utc(dateString).local();
  if (!date.isValid) return null;
  const futureDate = moment().add(1, 'days').endOf('day');
  responseDateObj.ended = moment(date).isBefore(moment());
  responseDateObj.endSoon = moment(date).isSameOrBefore(futureDate);
  if (responseDateObj.endSoon && !responseDateObj.ended) {
    const diffDays = date.diff(futureDate, 'days') + 1;
    responseDateObj.endingText = diffDays === 1 ? 'Ending Tomorrow' : diffDays === 0 ? 'Ending Today' : '';
  }
  return responseDateObj;
};

export const dateToTimeStampToLocal = (dateString: Date | null) => {
  if (!dateString) return null;
  const date = moment.utc(dateString);
  if (!date.isValid) return null;
  return moment(dateString, moment.ISO_8601).local().format(UTC_TIMESTAMP_FORMAT);
};

export const getCurrentDate = (): Date => {
  return new Date();
};

export const getTimezone = (): any => {
  return moment.tz.guess();
};

export const getBrowserLocalDateTime = (dateString: string | Date | null) => {
  const localTimeZone = getTimezone();
  return moment.tz(dateString, localTimeZone).local().toDate();
};

export const getTimezoneAbbreviation = (timezone: string) => {
  return moment().tz(timezone).zoneAbbr();
};

export const getDatePickerDateToLocal = (dateString: string | null | Date, type?: string) => {
  if (dateString === null) {
    return null;
  }
  const date = moment.utc(dateString);
  if (!date?.isValid) return null;
  return moment(date).local().toDate();
};

export const compareCurrentDate = (dateString: string | null | Date) => {
  if (dateString === null) return null;
  const compareCurrentDate = moment(dateString, moment.ISO_8601).isSame(moment(), 'day');
  return compareCurrentDate;
};

export const formatEditDate = (editedDate: string) => {
  const date = moment(editedDate, DATE_TIME_FORMAT).local().toDate();
  return date;
};

export const checkValidDate = (date: any) => {
  return moment(date, moment.ISO_8601).isValid();
};

export const getStartOfDate = (dateString: string | null | Date) => {
  if (dateString === null) return null;
  const startDate = moment(dateString).startOf('day').toDate();
  // if (!startDate.isValid()) return null;
  return startDate;
};

export const getEndOfDate = (dateString: string | null | Date) => {
  if (dateString === null) return null;
  const endDate = moment(dateString).endOf('day').toDate();
  // if (!startDate.isValid()) return null;
  return endDate;
};

export const formatDate = (date: null | Date | string, type: string, value: number, formatType: string) => {
  switch (type) {
    case 'addDay': {
      return moment(date)
        .add(value || 0, 'days')
        .startOf('day')
        .format(formatType);
    }
    case 'addWeek': {
      return moment(date)
        .add(value || 0, 'weeks')
        .startOf('day')
        .format(formatType);
    }
    case 'addMonth': {
      return moment(date)
        .add(value || 0, 'months')
        .startOf('day')
        .format(formatType);
    }
    default:
      return '';
  }
};

export const getEndDateAsUnix = (date: string | null | Date | undefined) => {
  if (date === null) {
    return null;
  }
  const endDate = moment.utc(date).local();
  if (!endDate.isValid) return null;
  return moment(endDate).unix();
};

/* Add Post - Get date formatter for media list */
export const dateFormatterForAddPost = (dateString: string | null | Date) => {
  if (!dateString) return null;
  const date = moment.utc(dateString);
  if (!date.isValid) return null;
  return moment(date, moment.ISO_8601).local().format(MOMENT_DATE_FORMAT);
};

/** Add Post - Get Date value and UTC format based on the time info name  */
export const dateObjBasedOnTimeZone = (tzInfoName: null | string, date?: string | Date | null) => {
  if (!tzInfoName) {
    return {
      selected: null,
      utcFormat: null
    };
  } else {
    const momentValueForTZName = date ? moment.tz(date, tzInfoName) : moment.tz(tzInfoName);
    return {
      selected: new Date(momentValueForTZName.seconds(0).format(YEAR_MONTH_DAY_WITH_TIME_FORMAT)),
      utcFormat: moment(momentValueForTZName).seconds(0).format()
    };
    // const momentValueForTZName = moment.tz(tzInfoName);
    // const remainder = PAGINATION_PER_PAGE_SMALL_GRID - (momentValueForTZName.minute() % PAGINATION_PER_PAGE_SMALL_GRID);
    // return {
    //   selected: new Date(moment.tz(tzInfoName).add(remainder, 'minutes').seconds(0).format(YEAR_MONTH_DAY_WITH_TIME_FORMAT)),
    //   utcFormat: moment(momentValueForTZName).add(remainder, 'minutes').seconds(0).format()
    // };
  }
};

/** Calendar - Get utc date based on the time info name  */
export const getUtcDateObjBasedOnTimezone = (tzInfoName: null | string, date: Date | null) => {
  if (!tzInfoName && !date) return null;
  else if (tzInfoName && date) {
    const momentValueForTZName = moment(date).tz(tzInfoName, true).format();
    return momentValueForTZName;
  } else if (!tzInfoName && date) return moment(date).format();
  else return null;
};

/** Add Post - Get UTC value for the selected date based on time info name */
export const utcFormatForDateValue = (tzInfoName: string | undefined, selectedDate: commonWidgetTypes.IDateValueType | string) => {
  if (!selectedDate || !tzInfoName) return null;
  return moment(selectedDate).tz(tzInfoName, true).format();
};

export const getParsedDate = (date: string | null): Date | null => {
  if (!date) return null;
  else return new Date(date);
};

export const convertLocationTimezone = (dateString: Date | string, locationTimezoneName: string, format: string = LOCATION_TIME_ZONE_DATE_TIME_FORMAT) => {
  const formatedDate = moment(dateString, moment.ISO_8601)
    .tz(locationTimezoneName === '' ? moment.tz.guess() : locationTimezoneName)
    .format(format);
  return formatedDate;
};

export const convertLocalTimezone = (dateString: string | null | Date) => {
  if (dateString === null) {
    return null;
  }
  const date = moment.utc(dateString);
  if (!date.isValid) return null;
  return moment(date).local().format(LOCATION_TIME_ZONE_DATE_TIME_FORMAT);
};

export const canReplyDateTime = (originallyPostedAt: Date) => {
  return moment(originallyPostedAt).isBefore(moment().subtract(1, 'day'));
};

/** Outbox, Sandbox, Inbox - Get week or month before today filter data */
export const weekOrMonthBeforeTodayFilter = (pageType?: string): commonProps.ICommonDateRange => {
  let weekOrMonthBeforeToday;
  if (pageType === SIDE_NAV_NAME.COMMUNITY_INBOX) {
    weekOrMonthBeforeToday = moment().subtract(1, 'month').startOf('day');
  } else {
    weekOrMonthBeforeToday = moment().subtract(1, 'week').startOf('day');
  }
  return {
    [ICommonDateTypes.FROM_DATE]: {
      selected: new Date(moment(weekOrMonthBeforeToday).format()),
      timeStamp: weekOrMonthBeforeToday.format(UTC_TIMESTAMP_FORMAT)
    },
    [ICommonDateTypes.TO_DATE]: {
      selected: new Date(moment().format()),
      timeStamp: moment().format(UTC_TIMESTAMP_FORMAT)
    }
  };
};

/** Post detail - Get date formatter */
export const dateFormatterForPostDetail = (dateString: string | null | Date) => {
  if (!dateString) return null;
  const date = moment.utc(dateString);
  if (!date.isValid) return null;
  return moment(date, moment.ISO_8601).local().format(MONTH_DATE_FORMAT);
};

export const dateFormatByRequestedFormat = (dateString: null | Date | string, dateFormat?: string) => {
  if (!dateString) return null;
  const date = moment.utc(dateString, moment.ISO_8601);
  if (!date.isValid) return null;
  return moment(date).local().format(dateFormat);
};

/** Get valid requested post based on the timezone name */
export const validDateBasedOnTimeZone = (currentDate: null | string, tzInfoName: null | string) => {
  if (!tzInfoName) return null;
  const date = new Date(moment.tz(tzInfoName).format(YEAR_MONTH_DAY_WITH_TIME_FORMAT));
  if (moment(currentDate).isBefore(date)) return false;
  else return true;
};

export const calenderDateHelper = (date: null | string | Date, timeZone: string, formatStr: string) => {
  return moment(date).tz(timeZone).format(formatStr);
};

export const calendarUTCtoLocalHandler = (date: string | Date | null, formatStr: string) => {
  return moment.utc(date).format(formatStr);
};

export const getDiffInDate = (date: string) => {
  return moment().diff(utcToLocalHandler(date));
};

/** Get the converted date based on time zone */
export const calendarConvertDateBasedOnTimeZone = (timeZone: string, dateString: null | string, type?: string, isCalendarView: boolean = false) => {
  if (!timeZone || !dateString) return null;
  return isCalendarView ? moment(dateString, moment.ISO_8601).tz(timeZone).format(type) : new Date(moment(dateString, moment.ISO_8601).tz(timeZone).format(type));
};

/** Get the converted date based on time zone */
export const convertDateBasedOnTimeZone = (timeZone: null | string, dateString: null | string, type?: string) => {
  if (!timeZone || !dateString) return null;
  return new Date(moment(dateString).tz(timeZone).format(type));
};

export const exactTimeBasedOnTimeZone = (timeZone: string = '', isHubUser: boolean = false) => {
  if (isHubUser) {
    return new Date(moment.utc().format(EMAIL_DATE_FORMAT));
  } else {
    return timeZone ? new Date(moment().tz(timeZone).format(EMAIL_DATE_FORMAT)) : null;
  }
};

/** Get the date / time format based on exact date */
export const getFormatBasedOnExactDate = (dateString: null | Date | string, type?: string) => {
  if (!dateString) return null;
  return moment(dateString, moment.ISO_8601).format(type);
};

/** Get the date object format based on date string */
export const getFormatBasedOnDateString = (dateValue: null | string | Date, type?: string) => {
  if (!dateValue) return null;
  return moment.utc(dateValue).format(type);
};

/** Get date in given format */
export const getDateBasedOnGivenFormat = (dateValue: null | string | Date, type: string) => {
  if (!dateValue) return null;
  return moment(dateValue).format(type);
};

/** Get moment object for next day */
export const getDefaultDateObjForNextDay = () => {
  return moment().add(1, 'day').set({ hour: 8, minute: 0, second: 0 });
};

/** Get moment object for prvious day */
export const getDefaultDateObjForPreviousDay = () => {
  return moment().subtract(1, 'day').set({ hour: 8, minute: 0, second: 0 });
};

/** Convert moment object to date */
export const convertMomentObjToDate = (defaultDate: moment.Moment | any) => {
  return new Date(`${defaultDate}`);
};

/** Get UTC date and time for selected date */
export const getUTCDateAndTimeForSelectedData = (selectedDate?: null | Date) => {
  if (!selectedDate) return moment().format();
  else return moment(selectedDate, moment.ISO_8601).format();
};

export const getDateTimeAfterAYear = () => {
  return moment().add(1, 'years');
};

export const getDateTimeBeforeAHalfYear = () => {
  return moment().subtract(6, 'months').format(UTC_TIMESTAMP_FORMAT);
};

export const getDateTimeAfterAWeek = (formatString?: string) => {
  const day = moment().add(7, 'days');
  return formatString ? day.format(formatString) : day;
};

export const getDefaultFromAndToDateForOneYear = (): commonProps.ICommonDateAndUTCFormatRange => {
  const fromDateValue = convertMomentObjToDate(getDefaultDateObjForPreviousDay());
  const toDateValue = convertMomentObjToDate(getDateTimeAfterAYear());
  return {
    fromDate: { selected: fromDateValue, utcFormat: getDefaultDateObjForPreviousDay().format() },
    toDate: { selected: toDateValue, utcFormat: getDateTimeAfterAYear().format() }
  };
};

/** Get initial time slot based on static hour input */
export const getInitialTimeSlot = (hoursInput: number) => {
  return {
    selectedDate: new Date(moment().set({ hour: hoursInput, minute: 0, second: 0 }).format()),
    utcTime: moment().set({ hour: hoursInput, minute: 0, second: 0 }).format(TIME_ONLY_FOR_EACH_12_HOURS)
  };
};

export const getNextDayDate = (currentDate: string | Date, reqFormat: string) => {
  return moment(currentDate).add(1, 'day').format(reqFormat);
};

/** Check whether given date is less than current date */
export const checkCurrentDateGreater = (currentDate: string | Date, isFormat: boolean = true) => {
  if (!currentDate) return null;
  else if (isFormat) return moment(moment().format(DATE_FORMAT)).isSameOrAfter(moment(currentDate).format(DATE_FORMAT));
  else return moment().isAfter(moment(currentDate));
};
/** Check whether given date is greater than current date */
export const checkCurrentDateLessThan = (endDate: string | Date) => {
  if (endDate) return moment(moment().format(DATE_FORMAT_HYPEN)).isSameOrBefore(moment(endDate).format(DATE_FORMAT_HYPEN));
};

export const endDateChecked = (startDate: string | Date, endDate: string | Date) => {
  return moment(endDate).isAfter(startDate);
};

export const subscriptionTableEndDateChecker = (endDate: string | Date) => {
  return moment(new Date()).isSameOrBefore(endDate);
};

export const checkCouponIsActive = (startDate: string, expireDate: string) => {
  if (!startDate || !expireDate) return false;

  const currentDate = moment();
  const start = moment(startDate).startOf('day');
  const expiration = moment(expireDate).endOf('day');
  return start.isBefore(currentDate) && currentDate.isBefore(expiration);
};

/** Get valid time format */
export const getValidTimeFormat = (time: string) => {
  const trimmedTime = time.trim().replaceAll(' ', '');
  const hour = trimmedTime.substring(0, trimmedTime.indexOf(':'));
  const minute = trimmedTime.substring(trimmedTime.indexOf(':') + 1, trimmedTime.indexOf(':') + 3);
  const meridiem = trimmedTime.substring(trimmedTime.indexOf(':') + 3, trimmedTime.length).toUpperCase();
  return `${hour}:${minute} ${meridiem}`;
};

/** Get initial time slot based on day's data */
export const getInitialTimeSlotBasedOnDay = (time: string) => {
  const validTime = getValidTimeFormat(time);
  const todayDate = moment(new Date(), moment.ISO_8601).format(YEAR_MONTH_DAY_FORMAT);
  const dateAndTime = moment(new Date(`${todayDate} ${validTime}`), moment.ISO_8601).format();
  return {
    selectedDate: new Date(dateAndTime),
    utcTime: `${validTime}`
  };
};

/** Get initial time slot based on day's data */
export const getTimeBasedOnDay = (time: string, date: string | Date) => {
  const todayDate = moment(new Date(date), moment.ISO_8601).format(YEAR_MONTH_DAY_FORMAT);
  const dateAndTime = moment(new Date(`${todayDate} ${time}`), moment.ISO_8601).format();
  return new Date(dateAndTime);
};

/** Validate whether end date is same as/less than start date or not */
export const endDateLessThanStartDate = (startDate: null | Date, endDate: null | Date) => {
  if (!startDate || !endDate) return false;
  return moment(startDate, moment.ISO_8601).isSameOrBefore(endDate);
};

/** Validate whether start date is same as/greater than start date or not */
export const startDateMoreThanEndDate = (startDate: null | Date | string, endDate: null | Date | string) => {
  if (!startDate || !endDate) return false;
  return moment(endDate, moment.ISO_8601).isSameOrAfter(startDate);
};

/* Advocacy OnboardList - Date Formatter for Advocates list */
export const getDateFormatterForAdvList = (date: null | string) => {
  const formattedDate = moment.utc(date, moment.ISO_8601).local();
  if (!formattedDate.isValid()) return null;
  else return moment(formattedDate).format(MOMENT_DATE_FORMAT);
};
/* Advocacy OnboardList - Time Formatter Advocates list */
export const getTimeFormatterForAdvList = (date: null | string) => {
  const formattedDate = moment.utc(date, moment.ISO_8601).local();
  if (!formattedDate.isValid()) return null;
  else return moment(formattedDate).format(MOMENT_TIME_FORMAT);
};

// check reward program expire date
export const checkExpireProgram = (end: string | null | Date | undefined) => {
  if (end === null) {
    return null;
  }
  const endDate = moment.utc(end).local();
  if (!endDate.isValid) return null;
  return endDate.isBefore(moment().utc());
};

/** Get the start and end unix timestamp based on date type */
export const getAdvLeaderboardDateRange = (type: string) => {
  let fromTimestamp: null | number = null;
  let toTimestamp: null | number = null;
  switch (type) {
    case ADV_LB_DATE_RANGE_FILTER_TYPE.MONTH_TO_DATE:
      fromTimestamp = moment().startOf('month').unix();
      toTimestamp = moment().unix();
      return { fromDate: fromTimestamp, toDate: toTimestamp };

    case ADV_LB_DATE_RANGE_FILTER_TYPE.LAST_MONTH:
      fromTimestamp = moment().subtract(1, 'months').startOf('month').unix();
      toTimestamp = moment().subtract(1, 'months').endOf('month').unix();
      return { fromDate: fromTimestamp, toDate: toTimestamp };

    case ADV_LB_DATE_RANGE_FILTER_TYPE.YEAR_TO_DATE:
      fromTimestamp = moment().startOf('year').unix();
      toTimestamp = moment().unix();
      return { fromDate: fromTimestamp, toDate: toTimestamp };

    default:
      return { fromDate: 0, toDate: moment().unix() };
  }
};

export const getUTCDateForPosts = (date: null | Date, hours: number, minutes: number, seconds: number) => {
  return {
    selectedDate: new Date(moment(date).set({ hour: hours, minute: minutes, second: seconds }).format()),
    utcTime: moment(date).set({ hour: hours, minute: minutes, second: seconds }).format()
  };
};

export const getUTCForCurrentTime = () => {
  return moment().utc().format();
};

export const getDateForBusinessHours = (slotData: accountApiTypes.ISlot[]) => {
  const todayDate = getDateBasedOnGivenFormat(new Date(), DATE_FORMAT);
  return slotData.reduce((acc: directoryListingsContainerTypes.IDLBusinessHoursTimeSlotObj[], curr) => {
    acc = [
      ...acc,
      {
        start: {
          selectedDate: new Date(`${todayDate} ${curr.start}`),
          utcTime: curr.start
        },
        end: {
          selectedDate: new Date(`${todayDate} ${curr.end}`),
          utcTime: curr.end
        }
      }
    ];
    return acc;
  }, []);
};

export const utcToLocalHandler = (data: string) => {
  return moment.utc(data, moment.ISO_8601).local().valueOf();
};

export const getCouponDateRange = (): couponsReducerTypes.ICouponDateRange => {
  const todayDate = new Date();
  const tenDaysFromToday = moment(todayDate).add(10, 'days').format();
  return {
    start: { selected: getStartOfDate(todayDate), date: dateFormatByRequestedFormat(todayDate, YEAR_MONTH_DAY_FORMAT) },
    expired: { selected: getStartOfDate(tenDaysFromToday), date: dateFormatByRequestedFormat(tenDaysFromToday, YEAR_MONTH_DAY_FORMAT) }
  };
};

/** Validate whether today's date is less than start date or not */
export const currentDateLessThanSelectedDate = (date: null | Date) => {
  const startOfDate = date
    ? moment(date)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        .toDate()
    : null;
  const currentDate = getCurrentDate()
    ? moment(getCurrentDate())
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        .toDate()
    : null;
  if (!startOfDate) return null;
  else return moment(startOfDate, moment.ISO_8601).isBefore(currentDate);
};

export const dateHelperTimeZone = (date: number | Date | null, formatString: string) => {
  const timeZone: string = moment.tz.guess();
  return timeZone !== 'Asia/Calcutta' ? moment(date).utc().format(formatString) : moment(date).local().format(formatString);
};

// visualise date in human readable form
export const dateHumanizer = (date: number) => {
  return moment.duration(date).humanize();
};
/** Get moment value for particular date */
export const getMomentForDate = (date: string | number | Date | null) => {
  return moment(date);
};
/** Get nearest 30th minute based on current timezone */
export const getNearest30thMinute = () => {
  const momentValueForTZName = moment.tz(getTimezone());
  const remainder = PAGINATION_PER_PAGE_SMALL_GRID - (momentValueForTZName.minute() % PAGINATION_PER_PAGE_SMALL_GRID);
  return new Date(moment.tz(getTimezone()).add(remainder, 'minutes').seconds(0).milliseconds(0).format(YEAR_MONTH_DAY_WITH_TIME_FORMAT));
};
/** Set hour, minute and second based on type */
export const setHourMinuteSecondBasedOnType = (date: null | Date, type: string, value: number) => {
  if (!date) return null;
  return new Date(
    moment(date)
      .set({ [type]: value })
      .format()
  );
};

export const getStartAndEndOfCurrentMonth = (date: null | Date, type: string) => {
  if (!date) return null;
  if (type === ICommonDateTypes.START_TIME) return moment(date).startOf('month').toDate();
  else return moment(date, moment.ISO_8601).endOf('month').toDate();
};

export const getStartAndEndOfUpcomingWeek = (date: null | Date, type: string) => {
  if (!date) return null;
  if (type === ICommonDateTypes.START_TIME) return moment(date, moment.ISO_8601).add(1, 'day').startOf('day').toDate();
  else return moment(date, moment.ISO_8601).add(7, 'days').endOf('day').toDate();
};

/** Get the converted date based on time zone in Calendar page */
export const calendarInitScheduledTime = (currentTimeZone: null | string, dateString: null | string) => {
  if (!currentTimeZone || !dateString) {
    return {
      selected: null,
      utcFormat: null
    };
  }
  const validDate = moment(dateString).tz(currentTimeZone).format(YEAR_MONTH_DAY_WITH_TIME_FORMAT);
  return {
    selected: new Date(validDate),
    utcFormat: dateString
  };
};

export const convertDateTimeToMs = (date: number | null | Date | string) => {
  if (!date) return 0;
  return moment(date).valueOf();
};

export const checkIsToday = (date: number | null | Date | string) => {
  return moment(date).isSame(moment(), 'date');
};

export const getRevvReviewAnalyticsDateFormat = () => {
  return {
    fromDate: moment().subtract(1, 'month').startOf('day').format(UTC_TIMESTAMP_FORMAT),
    toDate: moment().endOf('day').format(UTC_TIMESTAMP_FORMAT)
  };
};

export const getHumanReadableTime = (currentDate: Date, selectedDate: null | Date) => {
  if (!selectedDate) return '';
  return moment.duration(moment(currentDate).diff(moment(selectedDate))).humanize();
};

export const isDateValid = (dateString: string, format: string, strict?: boolean) => {
  if (!dateString) return false;
  return moment(dateString, format, strict).isValid();
};

export const getMomentBasedOnTimezone = (timezone: string, date?: Date) => {
  return !date ? moment().tz(timezone) : moment(date).tz(timezone);
};

export const getTodayDate = (timezone: string, format: string) => {
  const today = getMomentBasedOnTimezone(timezone);
  return today.format(format);
};

export const convertMomentToDate = (date: moment.Moment) => {
  return date.toDate();
};

export const getFormattedDate = (date: null | string | Date, format?: string) => {
  if (!date) return '';
  return moment(date).format(format);
};

export const convertStringToDate = (date: null | string) => {
  if (!date) return null;
  return moment(date).toDate();
};

export const getDayOfWeek = (date: Date) => {
  return moment(date).day();
};

export const getDaysBetweenDates = (startDate: null | Date, endDate: null | Date, format: string) => {
  const formatedDate: string[] = [];

  if (!startDate || !endDate) return [];

  const initDate = moment(startDate).clone();
  const lastDate = moment(endDate);

  while (initDate.isSameOrBefore(lastDate)) {
    formatedDate.push(initDate.format(format));
    initDate.add(1, 'days');
  }

  return formatedDate;
};

// Check if date is inbetween and equal between two dates -> [ indicates inclusion of a value. ( indicates exclusion of a value.
export const isDateInBetweenTwoDates = (startDate: MomentInput, endDate: MomentInput, compareDate: MomentInput, inclusivity?: '()' | '[)' | '(]' | '[]') => {
  if (!startDate || !endDate || !compareDate) return false;
  return moment(compareDate).isBetween(startDate, endDate, null, inclusivity || '[]');
};

export const couponIsExpired = (expiresAt: null | string) => {
  if (!expiresAt) return false;

  const currentDate = moment();
  const expiredDate = moment(expiresAt);

  return expiredDate && expiredDate.isBefore(currentDate);
};

export const getPreviousMonthFormattedDate = (date: null | Date, format?: string, amount?: number, unit?: DurationInputArg2) => {
  const validDate = date || getCurrentDate();
  return amount && unit ? moment(validDate).subtract(amount, unit).startOf('day').format(format) : moment(validDate).format(format);
};

export const getChatFormattedDate = (date: Date | string, accountTimeZone: string, isLastSeen?: boolean) => {
  const dateDifferenceInTime = new Date().getTime() - new Date(date).getTime();
  const dateDifferenceInDays = dateDifferenceInTime / (1000 * 60 * 60 * 24);
  const timeZone = moment(date, moment.ISO_8601).tz(accountTimeZone);

  if (dateDifferenceInDays < 1) {
    return isLastSeen
      ? `Active ${timeZone.fromNow()}`
      : timeZone.calendar(null, {
          lastDay: '[Yesterday]',
          sameDay: '[Today]'
        });
  } else if (dateDifferenceInDays < 2) {
    return isLastSeen
      ? `Last seen yesterday at ${timeZone.format('LT')}`
      : timeZone.calendar(null, {
          sameDay: '[Yesterday]'
        }) === 'Yesterday'
      ? 'Yesterday'
      : timeZone.format('dddd');
  } else if (dateDifferenceInDays <= 7) {
    return isLastSeen ? `Last seen ${timeZone.format('ddd h:mm A')}` : timeZone.format('dddd');
  } else if (dateDifferenceInDays <= 365) {
    return isLastSeen ? `Last seen ${timeZone.format('lll')}` : timeZone.format('LL');
  } else {
    return isLastSeen ? `Last seen ${timeZone.format('ll')}` : timeZone.format('LL');
  }
};

export const getRemainingDaysFromToday = (dateString: string) => {
  if (!dateString) {
    return 0;
  }
  const date = moment.utc(dateString).local();
  if (!date.isValid) return 0;
  const futureDate = moment().add(1, 'days');
  const diffDays = date.diff(futureDate, 'days') + 1;
  return diffDays;
};

export const getDiscountedDaysFromToday = (dateString: string) => {
  if (!dateString) {
    return 0;
  }
  const date = moment.utc(dateString).local().add(8, 'days');
  if (!date.isValid) return 0;
  const futureDate = moment();
  const diffDays = date.diff(futureDate, 'days');
  return diffDays;
};

export const getDateTimeByDayOfWeek = (dayOfWeek: number) => {
  return moment().day(dayOfWeek).format(CALENDAR_YR_MN_DATE_FORMAT);
};
export const getDateTimeByHourAndMin = (date: Date | string, hour: number, min: number) => {
  const dateTime = moment(date);

  dateTime.hour(hour);
  dateTime.minute(min).second(0).millisecond(0);

  return dateTime.format();
};

export const getCurrentDateWithoutMS = () => {
  return moment().second(0).millisecond(0).format();
};

export const getCurrentWeekDates = (dayOfWeek: number) => {
  const startOfWeek = moment().startOf('week'); // Sunday
  const endOfWeek = moment().endOf('week'); // Saturday

  const dates = [];
  let currentDate = startOfWeek;

  while (currentDate <= endOfWeek) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'days');
  }
  return dates[dayOfWeek];
};
