import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';

export const useSocialMedia = (provider: string) => {
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  return useMemo(() => {
    return accountDetails ? accountDetails.social_media.find((x: any) => x.provider_name === provider) : null;
  }, [accountDetails, provider]);
};
