import { FormikErrors, FormikTouched } from 'formik';

import { accountApiTypes, directoryListingsApiTypes, directoryListingsContainerTypes, settingsRallioProfileReducerTypes } from 'types';
import {
  SPECIAL_HOUR_FORMAT,
  TIME_ONLY_FOR_EACH_12_HOURS,
  STORE_DETAILS_OBJ_TYPE,
  DL_STORE_DETAILS_TAB_FIELDS,
  ALLOW_ONLY_NUMBERS_REGEX,
  DATE_FORMAT,
  DlmSourceType,
  DlBusinessHoursType,
  DlAddPhotoTab,
  DL_STORE_DETAILS_CHECKFILEDS,
  STORE_DETAILS_INITIAL_VALUE
} from 'utils/constants';
import { compareTwoObjects, formatPhoneNumber, getDateBasedOnGivenFormat, getInitialTimeSlot, getValidTimeFormat, touchFieldObjectCreator } from 'utils/helpers';

/** Get only id's of selected sub catogories tag */
export const getSelectedCategoryTagId = (tagsArray: directoryListingsApiTypes.IDLCategoriesResponse[]) => {
  return tagsArray.reduce((acc: string[], curr) => {
    acc = [...acc, `${curr.databaseId}`];
    return acc;
  }, []);
};

/** Get updated business photo list */
export const getUpdatedBusinessPhotoList = (businessPhotos: accountApiTypes.IBusinessPhoto[], photoKey: string, cloudinaryId: string, secureUrl: string) => {
  const updatedBusinessPhoto = [...businessPhotos, { synup_id: cloudinaryId, type: photoKey, photo: secureUrl }];
  const onlyLogoPhotos = updatedBusinessPhoto.filter((datum) => datum.type === DlAddPhotoTab.LOGO);
  const logoPhoto = onlyLogoPhotos[onlyLogoPhotos.length - 1];
  const onlyCoverPhotos = updatedBusinessPhoto.filter((datum) => datum.type === DlAddPhotoTab.COVER);
  const coverPhoto = onlyCoverPhotos[onlyCoverPhotos.length - 1];
  const additionalPhotos = updatedBusinessPhoto.filter((datum) => datum.type === DlAddPhotoTab.ADDITIONAL);
  return [{ ...logoPhoto }, { ...coverPhoto }, ...additionalPhotos].filter((datum) => datum.type);
};

/** Get updated business hours in directory listing */
export const getUpdatedBusinessHours = (
  filteredValidBusinessTime: directoryListingsContainerTypes.IDLBusinessHoursTimeSlot,
  businessHoursList: accountApiTypes.IBusinessHour[],
  currentDayName: string
) => {
  return businessHoursList.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    if (curr.day === currentDayName && curr.type === DlBusinessHoursType.OPEN) {
      acc = [
        ...acc,
        {
          ...curr,
          slots: Object.values(filteredValidBusinessTime)[0].reduce((slotAcc: accountApiTypes.ISlot[], slotCurr) => {
            if (slotCurr.end.utcTime && slotCurr.start.utcTime) {
              slotAcc = [...slotAcc, { end: slotCurr.end.utcTime, start: slotCurr.start.utcTime }];
            }
            return slotAcc;
          }, [])
        }
      ];
    } else acc = [...acc, curr];
    return acc;
  }, []);
};

export const getSelectedBusinessHoursBasedOnType = (businessHoursList: accountApiTypes.IBusinessHour[], selectedDay: string, selectedType: string, isRenderSeo: boolean) => {
  return businessHoursList.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    if (curr.day === selectedDay && selectedType === DlBusinessHoursType.OPEN) acc = [...acc, { ...curr, type: selectedType }];
    else if (curr.day === selectedDay) {
      acc = [...acc, { ...curr, type: selectedType, slots: isRenderSeo && selectedType === DlBusinessHoursType.OPEN_FULL_DAY ? [{ end: '00:00', start: '00:00' }] : [] }];
    } else acc = [...acc, curr];
    return acc;
  }, []);
};

/** Get updated special hours based on date changes */
export const getUpdatedSpecialHoursDateChange = (specialHours: accountApiTypes.IBusinessHour[], selectedDay: string, date: null | Date) => {
  const updatedSpecialHours = specialHours.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    const tempHour: any = { ...curr };
    if (curr.day === selectedDay) {
      const formatDay = getDateBasedOnGivenFormat(date, DATE_FORMAT);
      const formatName = getDateBasedOnGivenFormat(date, SPECIAL_HOUR_FORMAT);
      tempHour.day = formatDay;
      tempHour.name = formatName;
    }
    acc = [...acc, tempHour];
    return acc;
  }, []);
  return updatedSpecialHours;
};

/** Get updated special hours based on Time changes */
export const getUpdatedSpecialHoursTimeChange = (specialHours: accountApiTypes.IBusinessHour[], selectedDay: string, date: Date | null, dateType: string) => {
  const updatedSpecialHours = specialHours.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    const tempHour: any = { ...curr };
    if (curr.day === selectedDay) {
      const formatDate = getDateBasedOnGivenFormat(date, TIME_ONLY_FOR_EACH_12_HOURS);
      tempHour.slots[0][dateType] = formatDate;
    }
    acc = [...acc, tempHour];
    return acc;
  }, []);
  return updatedSpecialHours;
};

/** Get updated special hours based on hours changes */
export const getUpdatedSpecialHoursType = (specialHours: accountApiTypes.IBusinessHour[], selectedDay: string, selectedType: string, isRenderSeo: boolean) => {
  const updatedSpecialHours = specialHours.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    const tempHour = { ...curr };
    const startDate = getInitialTimeSlot(8);
    const endDate = getInitialTimeSlot(12);
    if (curr.day === selectedDay) tempHour.type = selectedType;
    if (curr.day === selectedDay && selectedType === DlBusinessHoursType.OPEN) tempHour.slots = [{ end: endDate.utcTime, start: startDate.utcTime }];
    else if (curr.day === selectedDay) tempHour.slots = isRenderSeo && selectedType === DlBusinessHoursType.OPEN_FULL_DAY ? [{ end: '00:00', start: '00:00' }] : [];
    acc = [...acc, tempHour];
    return acc;
  }, []);
  return updatedSpecialHours;
};

export const compareStoreDetailsObjects = (
  objType: string,
  values: directoryListingsContainerTypes.IStoreDetailsTabFieldValue,
  storeDetails: directoryListingsContainerTypes.IStoreDetailsTabFieldValue
) => {
  if (objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO) {
    let isSame = false;
    const existingVal: any = {
      [DL_STORE_DETAILS_TAB_FIELDS.NAME]: storeDetails.dlm_name,
      [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: storeDetails.dlm_store_code,
      ...(storeDetails[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? { [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: storeDetails.dlm_opening_date }
        : { [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: storeDetails.year_of_incorporation }),
      [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: storeDetails.description
    };
    const currentValObj = {
      [DL_STORE_DETAILS_TAB_FIELDS.NAME]: values.dlm_name,
      [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: values.dlm_store_code,
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? { [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: values.dlm_opening_date }
        : { [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: values.year_of_incorporation }),
      [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: values.description
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else if (objType === STORE_DETAILS_OBJ_TYPE.ADDRESS) {
    let isSame = false;
    const existingVal: any = {
      [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: storeDetails.dlm_street,
      [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: storeDetails.dlm_hide_address,
      [DL_STORE_DETAILS_TAB_FIELDS.CITY]: storeDetails.dlm_city,
      [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: storeDetails.dlm_state,
      [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: storeDetails.dlm_postal_code,
      [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: storeDetails.dlm_country_code,
      [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: storeDetails.dlm_phone ? storeDetails.dlm_phone?.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : storeDetails.dlm_phone,
      [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: storeDetails.latitude,
      [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: storeDetails.longitude,
      [DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA]: storeDetails.dlm_service_area
    };
    const currentValObj = {
      [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: values.dlm_street,
      [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: values.dlm_hide_address,
      [DL_STORE_DETAILS_TAB_FIELDS.CITY]: values.dlm_city,
      [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: values.dlm_state,
      [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: values.dlm_postal_code,
      [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: values.dlm_country_code,
      [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: values.dlm_phone ? values.dlm_phone?.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : values.dlm_phone,
      [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: values.latitude,
      [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: values.longitude,
      [DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA]: values.dlm_service_area
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else if (objType === STORE_DETAILS_OBJ_TYPE.CATEGORY) {
    let isSame = false;
    const existingVal: any = {
      ...(storeDetails[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? {
            [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: storeDetails.dlm_categories
          }
        : {
            [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: storeDetails.synup_sub_category_name,
            [DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY]: storeDetails.synup_additional_category_ids,
            [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: storeDetails.tagline
          })
    };
    const currentValObj = {
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? {
            [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: values.dlm_categories
          }
        : {
            [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: values.synup_sub_category_name,
            [DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY]: values.synup_additional_category_ids,
            [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: values.tagline
          })
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else if (objType === STORE_DETAILS_OBJ_TYPE.PAYMENT_METHODS) {
    return compareTwoObjects(
      {
        [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: storeDetails.payment_methods
      },
      {
        [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: values.payment_methods
      }
    );
  } else if (objType === STORE_DETAILS_OBJ_TYPE.URL) {
    let isSame = false;
    const existingVal: any = {
      [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: storeDetails.dlm_url,
      [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: storeDetails.dlm_facebook_url,
      [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: storeDetails.dlm_twitter_url,
      [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: storeDetails.dlm_linkedin_url,
      [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: storeDetails.dlm_appointment_link_url
    };
    const currentValObj = {
      [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: values.dlm_url,
      [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: values.dlm_facebook_url,
      [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: values.dlm_twitter_url,
      [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: values.dlm_linkedin_url,
      [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: values.dlm_appointment_link_url
    };
    isSame = compareTwoObjects(existingVal, currentValObj);
    return isSame;
  } else return false;
};

export const getStoreDetailsUpdatedValues = (objType: string, values: directoryListingsContainerTypes.IStoreDetailsTabFieldValue) => {
  let val: any = { ...values };
  if (objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO) {
    val = {
      [DL_STORE_DETAILS_TAB_FIELDS.NAME]: values.dlm_name,
      [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: values.dlm_store_code,
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? { [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: values.dlm_opening_date }
        : { [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: values.year_of_incorporation }),
      [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: values.description
    };
  } else if (objType === STORE_DETAILS_OBJ_TYPE.ADDRESS) {
    val = {
      [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: values.dlm_street,
      [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: values.dlm_hide_address,
      [DL_STORE_DETAILS_TAB_FIELDS.CITY]: values.dlm_city,
      [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: values.dlm_state,
      [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: values.dlm_postal_code,
      [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: values.dlm_country_code,
      [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: values.dlm_phone,
      [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: values.latitude,
      [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: values.longitude,
      [DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA]: values.dlm_service_area
    };
  } else if (objType === STORE_DETAILS_OBJ_TYPE.CATEGORY) {
    val = {
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? {
            [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: values.dlm_categories
          }
        : {
            [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: values.synup_sub_category_name,
            [DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY]: values.synup_additional_category_ids,
            [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: values.tagline
          })
    };
  } else if (objType === STORE_DETAILS_OBJ_TYPE.PAYMENT_METHODS) {
    val = {
      [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: values.payment_methods
    };
  } else if (objType === STORE_DETAILS_OBJ_TYPE.URL) {
    val = {
      [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: values.dlm_url,
      [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: values.dlm_facebook_url,
      [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: values.dlm_twitter_url,
      [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: values.dlm_linkedin_url,
      [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: values.dlm_appointment_link_url
    };
  }
  return val;
};

export const getStoreSaveStatus = (objType: string, errors: FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>, sourceType: null | string) => {
  if (objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO) {
    return errors.dlm_name || errors.dlm_store_code || errors.dlm_opening_date || errors.year_of_incorporation || errors.description ? true : false;
  } else if (objType === STORE_DETAILS_OBJ_TYPE.ADDRESS) {
    return errors.dlm_street || errors.dlm_city || errors.dlm_state || errors.dlm_postal_code || errors.dlm_country_code || errors.dlm_phone ? true : false;
  } else if (objType === STORE_DETAILS_OBJ_TYPE.CATEGORY) {
    return sourceType === DlmSourceType.RENDER_SEO ? errors.dlm_categories : errors.synup_sub_category_name ? true : false;
  } else return false;
};

export const getCountriesCodeForDropdown = (countriesCode: settingsRallioProfileReducerTypes.ICountriesCodeResponse[]): directoryListingsContainerTypes.ICountriesCodeObj[] => {
  return countriesCode.reduce((acc: directoryListingsContainerTypes.ICountriesCodeObj[], curr) => {
    const countriesObj = {
      id: curr.code,
      value: curr.name,
      label: curr.name
    };
    acc = [...acc, countriesObj];
    return acc;
  }, []);
};

export const getSubcategoriesForDropdown = (subcategories: directoryListingsApiTypes.IDLCategoriesData[]): directoryListingsContainerTypes.ISubcategoriesObj[] => {
  return subcategories.reduce((acc: directoryListingsContainerTypes.ISubcategoriesObj[], curr) => {
    if ('primary' in curr && curr.primary) {
      const subcategoriesObj = {
        id: curr.id,
        value: curr.name,
        label: curr.name
      };
      acc = [...acc, subcategoriesObj];
    }
    if ('category_name' in curr && curr.category_name) {
      const subcategoriesObj = {
        id: curr.id,
        value: curr.category_name,
        label: curr.category_name
      };
      acc = [...acc, subcategoriesObj];
    }
    return acc;
  }, []);
};

/** Get valid time slot format for operational hours */
export const getOperationalHoursData = (operationalHours: accountApiTypes.IBusinessHour[]) => {
  return operationalHours.reduce((acc: accountApiTypes.IBusinessHour[], curr) => {
    if (curr?.slots?.length) {
      const validSlots = curr?.slots?.reduce((slotAcc: accountApiTypes.ISlot[], slotCurr) => {
        slotAcc = [
          ...slotAcc,
          {
            end: getValidTimeFormat(slotCurr.end),
            start: getValidTimeFormat(slotCurr.start)
          }
        ];
        return slotAcc;
      }, []);
      acc = [...acc, { ...curr, slots: validSlots }];
    } else acc = [...acc, curr];
    return acc;
  }, []);
};

export const getInitialStoreDetails = (accountData: null | accountApiTypes.IAccount): directoryListingsContainerTypes.IStoreDetailsTabFieldValue => {
  if (accountData) {
    return {
      dlm_name: accountData[DL_STORE_DETAILS_TAB_FIELDS.NAME],
      tagline: accountData[DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE],
      dlm_store_code: accountData[DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE],
      payment_methods: accountData[DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS],
      dlm_street: accountData[DL_STORE_DETAILS_TAB_FIELDS.ADDRESS],
      synup_sub_category_name: accountData[DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY],
      dlm_city: accountData[DL_STORE_DETAILS_TAB_FIELDS.CITY],
      synup_additional_category_ids: accountData[DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY],
      dlm_state: accountData[DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE],
      dlm_postal_code: accountData[DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL],
      description: accountData[DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION],
      dlm_country_code: accountData[DL_STORE_DETAILS_TAB_FIELDS.COUNTRY],
      dlm_url: accountData[DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL],
      dlm_phone: accountData[DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER],
      dlm_facebook_url: accountData[DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL],
      latitude: accountData[DL_STORE_DETAILS_TAB_FIELDS.LATITUDE],
      dlm_twitter_url: accountData[DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL],
      longitude: accountData[DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE],
      dlm_linkedin_url: accountData[DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL],
      dlm_hide_address: accountData[DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS],
      id: accountData[DL_STORE_DETAILS_TAB_FIELDS.ID],
      owner_email: accountData[DL_STORE_DETAILS_TAB_FIELDS.OWNER_EMAIL],
      owner_name: accountData[DL_STORE_DETAILS_TAB_FIELDS.OWNER_NAME],
      synup_location_id: accountData[DL_STORE_DETAILS_TAB_FIELDS.SYNUP_SUB_CATEGORY_ID],
      synup_sub_category_id: accountData[DL_STORE_DETAILS_TAB_FIELDS.SYNUP_SUB_CATEGORY_ID],
      dlm_opening_date: accountData[DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE],
      year_of_incorporation: accountData[DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION],
      dlm_categories: accountData[DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES],
      dlm_source: accountData[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE],
      dlm_service_area: accountData[DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA],
      dlm_appointment_link_url: accountData[DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]
    };
  }
  return STORE_DETAILS_INITIAL_VALUE;
};

export const getInitialDLStoreDetailsFieldTouched = (
  setTouched: (
    touched: FormikTouched<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>> | Promise<void>,
  isRenderSeo: boolean
) => {
  setTouched({
    ...DL_STORE_DETAILS_CHECKFILEDS[STORE_DETAILS_OBJ_TYPE.STORE_INFO],
    ...DL_STORE_DETAILS_CHECKFILEDS[STORE_DETAILS_OBJ_TYPE.ADDRESS],
    ...DL_STORE_DETAILS_CHECKFILEDS[STORE_DETAILS_OBJ_TYPE.CATEGORY],
    ...DL_STORE_DETAILS_CHECKFILEDS[STORE_DETAILS_OBJ_TYPE.URL],
    ...(isRenderSeo
      ? {
          [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: false,
          [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: false
        }
      : {
          [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: false,
          [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: false,
          [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: false
        })
  });
};

export const compareDLStoreDetailsObj = (accountData: null | accountApiTypes.IAccount, values: directoryListingsContainerTypes.IStoreDetailsTabFieldValue) => {
  if (accountData && values) {
    const existingVal = {
      [DL_STORE_DETAILS_TAB_FIELDS.NAME]: accountData.dlm_name,
      [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: accountData.dlm_store_code,
      ...(accountData[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? { [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: accountData.dlm_opening_date }
        : { [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: accountData.year_of_incorporation }),
      [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: accountData.description,
      [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: accountData.dlm_street,
      [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: accountData.dlm_hide_address,
      [DL_STORE_DETAILS_TAB_FIELDS.CITY]: accountData.dlm_city,
      [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: accountData.dlm_state,
      [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: accountData.dlm_postal_code,
      [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: accountData.dlm_country_code,
      [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: accountData.dlm_phone ? accountData.dlm_phone?.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : accountData.dlm_phone,
      [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: accountData.latitude,
      [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: accountData.longitude,
      [DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA]: accountData.dlm_service_area,
      ...(accountData[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? {
            [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: accountData.dlm_categories
          }
        : {
            [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: accountData.synup_sub_category_name,
            [DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY]: accountData.synup_additional_category_ids,
            [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: accountData.tagline
          }),
      [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: accountData.payment_methods,
      [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: accountData.dlm_url,
      [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: accountData.dlm_facebook_url,
      [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: accountData.dlm_twitter_url,
      [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: accountData.dlm_linkedin_url,
      [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: accountData.dlm_appointment_link_url
    };
    const currentValObj = {
      [DL_STORE_DETAILS_TAB_FIELDS.NAME]: values.dlm_name,
      [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: values.dlm_store_code,
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? { [DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE]: values.dlm_opening_date }
        : { [DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: values.year_of_incorporation }),
      [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: values.description,
      [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: values.dlm_street,
      [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: values.dlm_hide_address,
      [DL_STORE_DETAILS_TAB_FIELDS.CITY]: values.dlm_city,
      [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: values.dlm_state,
      [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: values.dlm_postal_code,
      [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: values.dlm_country_code,
      [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: values.dlm_phone ? values.dlm_phone?.replace(ALLOW_ONLY_NUMBERS_REGEX, '') : values.dlm_phone,
      [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: values.latitude,
      [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: values.longitude,
      [DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA]: values.dlm_service_area,
      ...(values[DL_STORE_DETAILS_TAB_FIELDS.SOURCE_TYPE] === DlmSourceType.RENDER_SEO
        ? {
            [DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES]: values.dlm_categories
          }
        : {
            [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: values.synup_sub_category_name,
            [DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY]: values.synup_additional_category_ids,
            [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: values.tagline
          }),
      [DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS]: values.payment_methods,
      [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: values.dlm_url,
      [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: values.dlm_facebook_url,
      [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: values.dlm_twitter_url,
      [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: values.dlm_linkedin_url,
      [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: values.dlm_appointment_link_url
    };
    return compareTwoObjects(existingVal, currentValObj);
  }
  return false;
};

export const compareDLBusinessHoursObj = (
  existingHours: accountApiTypes.IBusinessHour[],
  editedBusinessHours: accountApiTypes.IBusinessHour[],
  editedSpecialHours: accountApiTypes.IBusinessHour[]
) => {
  let overallEditedHours: accountApiTypes.IBusinessHour[] = [...editedBusinessHours, ...editedSpecialHours];
  if (!existingHours.length && !editedSpecialHours.length && editedBusinessHours.length) {
    overallEditedHours = editedBusinessHours.filter((it) => it.type === DlBusinessHoursType.OPEN || it.type === DlBusinessHoursType.OPEN_FULL_DAY);
  }
  return compareTwoObjects({ business_hours: existingHours }, { business_hours: overallEditedHours });
};

export const compareDLPhotosObj = (existingPhotos: accountApiTypes.IBusinessPhoto[], editedPhotos: accountApiTypes.IBusinessPhoto[]) => {
  return compareTwoObjects({ business_photos: existingPhotos }, { business_photos: editedPhotos });
};

export const handleDLStoreDetailsCheckTouchedFields = (
  objType: string,
  setTouched: (
    touched: FormikTouched<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>> | Promise<void>,
  setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>> | Promise<void>,
  isRenderSeo: boolean
) => {
  const storeDetailsCheckFields = touchFieldObjectCreator(DL_STORE_DETAILS_CHECKFILEDS, true);
  if (objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO) {
    setTouched({
      ...storeDetailsCheckFields[STORE_DETAILS_OBJ_TYPE.STORE_INFO],
      [isRenderSeo ? DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE : DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: true
    });
  } else if (objType === STORE_DETAILS_OBJ_TYPE.ADDRESS) {
    setTouched(storeDetailsCheckFields[STORE_DETAILS_OBJ_TYPE.ADDRESS]);
  } else if (objType === STORE_DETAILS_OBJ_TYPE.CATEGORY) {
    if (isRenderSeo) {
      setFieldTouched(DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES, true);
    } else {
      setTouched(storeDetailsCheckFields[STORE_DETAILS_OBJ_TYPE.CATEGORY]);
    }
  } else if (objType === STORE_DETAILS_OBJ_TYPE.PAYMENT_METHODS) {
    setFieldTouched(DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS, true);
  } else if (objType === STORE_DETAILS_OBJ_TYPE.URL) {
    setTouched(storeDetailsCheckFields[STORE_DETAILS_OBJ_TYPE.URL]);
  }
};

export const handleDLStoreDetailsIndividualCancel = (
  objType: string,
  storeDetails: directoryListingsContainerTypes.IStoreDetailsTabFieldValue,
  touched: FormikTouched<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>,
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>>,
  setTouched: (
    touched: FormikTouched<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>> | Promise<void>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<directoryListingsContainerTypes.IStoreDetailsTabFieldValue>>,
  isRenderSeo: boolean
) => {
  if (DL_STORE_DETAILS_CHECKFILEDS[objType]) {
    setTouched({
      ...touched,
      ...DL_STORE_DETAILS_CHECKFILEDS[objType],
      [isRenderSeo && objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO ? DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE : DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION]: false
    });
  }
  if (objType === STORE_DETAILS_OBJ_TYPE.STORE_INFO) {
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.NAME, storeDetails.dlm_name);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE, storeDetails.dlm_store_code);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION, storeDetails.description);
    if (isRenderSeo) {
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.OPENING_DATE, storeDetails.dlm_opening_date);
    } else {
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.YEAR_OF_INCORPORATION, storeDetails.year_of_incorporation);
    }
  } else if (objType === STORE_DETAILS_OBJ_TYPE.ADDRESS) {
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.ADDRESS, storeDetails.dlm_street);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS, storeDetails.dlm_hide_address);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.CITY, storeDetails.dlm_city);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE, storeDetails.dlm_state);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL, storeDetails.dlm_postal_code);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.COUNTRY, storeDetails.dlm_country_code);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER, storeDetails.dlm_phone ? formatPhoneNumber(storeDetails.dlm_phone || '') : storeDetails.dlm_phone);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.LATITUDE, storeDetails.latitude);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE, storeDetails.longitude);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.SERVICE_AREA, storeDetails.dlm_service_area);
  } else if (objType === STORE_DETAILS_OBJ_TYPE.CATEGORY) {
    if (isRenderSeo) {
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES, storeDetails.dlm_categories);
      setFieldTouched(DL_STORE_DETAILS_TAB_FIELDS.CATEGORIES, false);
    } else {
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY, storeDetails.synup_sub_category_name);
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.ADDITIONAL_CATEGORY, storeDetails.synup_additional_category_ids);
      setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE, storeDetails.tagline);
    }
  } else if (objType === STORE_DETAILS_OBJ_TYPE.PAYMENT_METHODS) {
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS, storeDetails.payment_methods);
    setFieldTouched(DL_STORE_DETAILS_TAB_FIELDS.PAYMENT_METHODS, false);
  } else if (objType === STORE_DETAILS_OBJ_TYPE.URL) {
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL, storeDetails.dlm_url);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL, storeDetails.dlm_facebook_url);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL, storeDetails.dlm_twitter_url);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL, storeDetails.dlm_linkedin_url);
    setFieldValue(DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK, storeDetails.dlm_appointment_link_url);
  }
};
