import { useEffect } from 'react';

export const useRightToTop = () => {
  return useEffect(() => {
    const appMainContainer = document.getElementById('app-main-container');
    if (appMainContainer) appMainContainer.classList.add('right-section__direction--layout');
    return () => {
      if (appMainContainer) appMainContainer.classList.remove('right-section__direction--layout');
    };
  }, []);
};
