import { calendarApiTypes, calendarContainerTypes } from 'types';
import { isSocialMediaActive, validDateBasedOnTimeZone } from 'utils/helpers';
import { COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';

type ICalendarList = (calendarApiTypes.IGetScheduledPostResponse | calendarApiTypes.IGetScheduledMultiPostResponse)[];

export const getCalenderResponseData = (activePlatforms: string[], data: ICalendarList): calendarContainerTypes.ICalendarDataType[] => {
  const isFacebook = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isTwitter = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
  const isLinkedin = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
  const isInstagram = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
  const isTiktok = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
  const isGoogle = isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);

  return data.map((it) => ({
    id: it?.id || 0,
    contentId: 'content_id' in it && it?.content_id ? it?.content_id : 0,
    franchisorId: it?.franchisor_id || 0,
    savedPostId: 'saved_post_id' in it && it?.saved_post_id ? it?.saved_post_id : 0,
    hidden: 'hidden' in it && it?.hidden ? it?.hidden : false,
    userName: 'user' in it ? `${it?.user?.first_name || ''} ${it?.user?.last_name || ''}` : '',
    userId: 'user' in it && it?.user?.id ? it?.user?.id : 0,
    avatarImg: 'user' in it && it?.user?.profile_photo_url ? it?.user?.profile_photo_url : '',
    accountName: it?.account?.name || '',
    scheduledTime: 'scheduled_for' in it && it?.scheduled_for ? it?.scheduled_for : '',
    timeZone: 'time_zone' in it && it?.time_zone ? it?.time_zone : '',
    timeZoneAbbrivation: 'time_zone_abbreviation' in it && it?.time_zone_abbreviation ? it?.time_zone_abbreviation : '',
    draft: 'draft' in it && it?.draft ? it?.draft : false,
    dataObj: [
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
        text: isFacebook && 'facebook_text' in it && it?.facebook_text ? it?.facebook_text : '',
        isEnabled: Boolean(isFacebook && it.use_facebook),
        personalizedText: isFacebook && 'personalized_facebook_text' in it && it?.personalized_facebook_text ? it?.personalized_facebook_text : '',
        tag: isFacebook ? it?.page_tag_details?.facebook || [] : []
      },
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM,
        text: isInstagram && 'instagram_text' in it && it?.instagram_text ? it?.instagram_text : '',
        personalizedText: isInstagram && 'personalized_instagram_text' in it && it?.personalized_instagram_text ? it?.personalized_instagram_text : '',
        isEnabled: Boolean(isInstagram && it.use_instagram)
      },
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
        text: isTwitter && 'twitter_text' in it && it?.twitter_text ? it?.twitter_text : '',
        personalizedText: isTwitter && 'personalized_twitter_text' in it && it?.personalized_twitter_text ? it?.personalized_twitter_text : '',
        isEnabled: Boolean(isTwitter && it.use_twitter)
      },
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
        text: isLinkedin && 'linkedin_text' in it && it?.linkedin_text ? it?.linkedin_text : '',
        personalizedText: isLinkedin && 'personalized_linkedin_text' in it && it?.personalized_linkedin_text ? it?.personalized_linkedin_text : '',
        isEnabled: Boolean(isLinkedin && it.use_linkedin)
      },
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        text: isTiktok && 'tiktok_text' in it && it?.tiktok_text ? it?.tiktok_text : '',
        personalizedText: isTiktok && 'personalized_tiktok_text' in it && it?.personalized_tiktok_text ? it?.personalized_tiktok_text : '',
        isEnabled: Boolean(isTiktok && it.use_tiktok)
      },
      {
        label: COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE,
        text: isGoogle && 'google_text' in it && it?.google_text ? it?.google_text : '',
        personalizedText: isGoogle && 'personalized_google_text' in it && it?.personalized_google_text ? it?.personalized_google_text : '',
        isEnabled: Boolean(isGoogle && it.use_google)
      }
    ],
    video: it?.video_url || '',
    video_cover_cloudinary_id: it?.video_cover_cloudinary_id || '',
    video_thumbnail_url: it?.video_thumbnail_url || '',
    facebook_dark_post: it?.facebook_dark_post || false,
    isBoosted:
      'has_facebook_boost_enduser_campaign' in it && it?.has_facebook_boost_enduser_campaign
        ? it?.has_facebook_boost_enduser_campaign
        : 'has_any_boost' in it && it?.has_any_boost
        ? it?.has_any_boost
        : false,
    permission: 'force_approved' in it && it?.force_approved ? it?.force_approved : false,
    post_now: it?.post_now || false,
    linkTitle: 'link' in it ? it?.link?.link_preview_title || '' : 'link_title' in it ? it?.link_title || '' : '',
    linkUrl: 'link' in it ? it?.link?.url || '' : 'link_url' in it ? it?.link_url || '' : '',
    linkDescription: 'link' in it ? it?.link?.link_preview_description || '' : 'link_description' in it ? it?.link_description || '' : '',
    linkPhotos: 'link' in it && it?.link?.link_preview_image_url_choices ? it?.link?.link_preview_image_url_choices : it?.link_preview_image_url_choices || [],
    linkPreviewPhoto: 'link' in it && it?.link?.link_preview_image_url ? it?.link?.link_preview_image_url : it?.link_preview_image_url || null,
    cloudinaryVideoId: it?.cloudinary_video_id || null,
    isCorporatePost: 'corporate_post' in it && it?.corporate_post ? it?.corporate_post : false,
    activePlatform: Boolean(isFacebook && it.use_facebook)
      ? COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
      : Boolean(isInstagram && it.use_instagram)
      ? COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      : Boolean(isTwitter && it.use_twitter)
      ? COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      : Boolean(isLinkedin && it.use_linkedin)
      ? COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      : Boolean(isTiktok && it.use_tiktok)
      ? COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
      : Boolean(isGoogle && it.use_google)
      ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      : '',
    imageUrls: it?.photo_urls || [],
    instagramImageUrls: it?.instagram_photo_urls || [],
    isLocked: it?.locked || false,
    campaign: it?.campaign || false,
    includedAccounts: 'included_accounts' in it && it?.included_accounts ? it?.included_accounts : [],
    includedAccountsCount: 'included_accounts_count' in it && it?.included_accounts_count ? it?.included_accounts_count : 0,
    accountListIds: 'schedule_to_account_list_ids' in it && it?.schedule_to_account_list_ids ? it?.schedule_to_account_list_ids || [] : [],
    franchisorIds: 'schedule_to_franchisor_ids' in it && it?.schedule_to_franchisor_ids ? it?.schedule_to_franchisor_ids || [] : [],
    facebook_call_to_action_type: 'facebook_call_to_action_type' in it && it?.facebook_call_to_action_type ? it?.facebook_call_to_action_type : null,
    facebook_call_to_action_link: 'facebook_call_to_action_link' in it && it?.facebook_call_to_action_link ? it?.facebook_call_to_action_link : null,
    google_call_to_action_type: 'google_call_to_action_type' && it?.google_call_to_action_type ? it?.google_call_to_action_type : null,
    google_call_to_action_url: 'google_call_to_action_url' && it?.google_call_to_action_url ? it?.google_call_to_action_url : null,
    personalized_facebook_call_to_action_link: 'personalized_facebook_call_to_action_link' && it?.personalized_facebook_call_to_action_link ? it?.personalized_facebook_call_to_action_link : '',
    future_scheduled_posts_count: 'future_scheduled_posts_count' in it ? it?.future_scheduled_posts_count : null,
    past_scheduled_posts_count: 'past_scheduled_posts_count' in it ? it?.past_scheduled_posts_count : null,
    account_list_schedule_details: 'account_list_schedule_details' in it ? it?.account_list_schedule_details : null,
    abilities: 'abilities' in it ? it?.abilities : null,
    start_date: it?.start_date || null,
    end_date: it?.end_date || null
  }));
};

export const getUpdatedScheduledPost = (actualData: calendarContainerTypes.ICalendarDataType[], currentData: calendarContainerTypes.ICalendarDataType) => {
  return actualData.map((datum) => (datum.id === currentData?.id ? currentData : datum));
};

export const getUpdatedSchPostWithActivePlatform = (activePlatforms: string[], actualData: ICalendarList, scheduledData: calendarContainerTypes.ICalendarDataType[], activePlatform: string) => {
  return activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL
    ? getCalenderResponseData(activePlatforms, actualData)
    : scheduledData.reduce((acc: calendarContainerTypes.ICalendarDataType[], curr) => {
        if (curr.dataObj.find((it) => it.label === activePlatform && it.isEnabled)) acc = [...acc, { ...curr, activePlatform }];
        else acc = [...acc, curr];
        return acc;
      }, []);
};

export const getUpdatedCalenderList = (actualData: calendarApiTypes.ICalendarItemsResponse[], response: calendarApiTypes.ICalendarItemsResponse[]) => {
  return actualData.map((datum) => (datum.id === response[0]?.id ? response[0] : datum));
};

/** Get requested post list */
export const getRequestedPostList = (scheduledPost: calendarApiTypes.ICalendarItemsResponse[], timeZone: null | string) => {
  return scheduledPost.reduce((acc: calendarApiTypes.ICalendarItemsResponse[], curr) => {
    if (validDateBasedOnTimeZone(curr.start_datetime, timeZone)) {
      acc = [...acc, curr];
    }
    return acc;
  }, []);
};

/** Get Existing Brand Schedule post list */
export const getRetainedScheduledPostList = (
  activePlatforms: string[],
  scheduledPostList: calendarApiTypes.IGetScheduledMultiPostResponse[],
  deletedPostId: number
): {
  updatedData: calendarContainerTypes.ICalendarDataType[];
  response: calendarApiTypes.IGetScheduledMultiPostResponse[];
} => {
  if (!deletedPostId) {
    return { updatedData: getCalenderResponseData(activePlatforms, scheduledPostList), response: scheduledPostList };
  } else {
    const data = scheduledPostList.filter((datum) => datum.id !== deletedPostId);
    return { updatedData: getCalenderResponseData(activePlatforms, data), response: data };
  }
};
