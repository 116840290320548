// REVIEW ANALYTICS - GET REVIEW ANALYTICS
export const GET_REVIEW_ANALYTICS_REQUEST = 'review_analytics/GET_REVIEW_ANALYTICS_REQUEST';
export const GET_REVIEW_ANALYTICS_RESPONSE = 'review_analytics/GET_REVIEW_ANALYTICS_RESPONSE';
export const GET_REVIEW_ANALYTICS_FAILURE = 'review_analytics/GET_REVIEW_ANALYTICS_FAILURE';

// REVIEW ANALYTICS - GET REVIEW ANALYTICS
export const GET_REVIEW_ANALYTICS_SUMMARY_REQUEST = 'review_analytics/GET_REVIEW_ANALYTICS_SUMMARY_REQUEST';
export const GET_REVIEW_ANALYTICS_SUMMARY_RESPONSE = 'review_analytics/GET_REVIEW_ANALYTICS_SUMMARY_RESPONSE';
export const GET_REVIEW_ANALYTICS_SUMMARY_FAILURE = 'review_analytics/GET_REVIEW_ANALYTICS_SUMMARY_FAILURE';

// KEY WORD MANAGEMENT
export const GET_KEY_WORD_DATA_REQUEST = 'review_analytics/GET_KEY_WORD_DATA_REQUEST';
export const GET_KEY_WORD_DATA_RESPONSE = 'review_analytics/GET_KEY_WORD_DATA_RESPONSE';
export const GET_KEY_WORD_DATA_FAILURE = 'review_analytics/GET_KEY_WORD_DATA_FAILURE';

// KEY WORD MANAGEMENT
export const GET_UNIQUE_KEY_WORD_DATA_REQUEST = 'review_analytics/GET_UNIQUE_KEY_WORD_DATA_REQUEST';
export const GET_UNIQUE_KEY_WORD_DATA_RESPONSE = 'review_analytics/GET_UNIQUE_KEY_WORD_DATA_RESPONSE';
export const GET_UNIQUE_KEY_WORD_DATA_FAILURE = 'review_analytics/GET_UNIQUE_KEY_WORD_DATA_FAILURE';

// RESET KEY_WORD
export const SET_KEY_WORD_DATA = 'review_analytics/SET_KEY_WORD_DATA';

// RESET KEY_WORD LIST
export const RESET_KEY_WORD_LIST = 'review_analytics/RESET_KEY_WORD_LIST';

// RESET KEY_WORD
export const RESET_KEY_WORD_DATA_STATE = 'review_analytics/RESET_KEY_WORD_DATA_STATE';

// RESET REDUCER
export const RESET_REVIEW_STATE = 'review_analytics/RESET_REVIEW_STATE';

// GET KEYWORDS CATEGORY LIST
export const GET_KEY_WORDS_CATEGORY_LIST_REQUEST = 'review_analytics/GET_KEY_WORDS_CATEGORY_LIST_REQUEST';
export const GET_KEY_WORDS_CATEGORY_LIST_RESPONSE = 'review_analytics/GET_KEY_WORDS_CATEGORY_LIST_RESPONSE';
export const GET_KEY_WORDS_CATEGORY_LIST_FAILURE = 'review_analytics/GET_KEY_WORDS_CATEGORY_LIST_FAILURE';

// GET KEYWORDS CATEGORY LIST
export const GET_KEY_WORDS_CATEGORY_REQUEST = 'review_analytics/GET_KEY_WORDS_CATEGORY_REQUEST';
export const GET_KEY_WORDS_CATEGORY_RESPONSE = 'review_analytics/GET_KEY_WORDS_CATEGORY_RESPONSE';
export const GET_KEY_WORDS_CATEGORY_FAILURE = 'review_analytics/GET_KEY_WORDS_CATEGORY_FAILURE';

// CREATE KEYWORDS CATEGORY LIST
export const CREATE_UPDATE_KEY_WORDS_CATEGORY_REQUEST = 'review_analytics/CREATE_UPDATE_KEY_WORDS_CATEGORY_REQUEST';
export const CREATE_UPDATE_KEY_WORDS_CATEGORY_RESPONSE = 'review_analytics/CREATE_UPDATE_KEY_WORDS_CATEGORY_RESPONSE';
export const CREATE_UPDATE_KEY_WORDS_CATEGORY_FAILURE = 'review_analytics/CREATE_UPDATE_KEY_WORDS_CATEGORY_FAILURE';

// DELETE KEYWORDS CATEGORY LIST
export const DELETE_KEY_WORDS_CATEGORY_REQUEST = 'review_analytics/DELETE_KEY_WORDS_CATEGORY_REQUEST';
export const DELETE_KEY_WORDS_CATEGORY_RESPONSE = 'review_analytics/DELETE_KEY_WORDS_CATEGORY_RESPONSE';
export const DELETE_KEY_WORDS_CATEGORY_FAILURE = 'review_analytics/DELETE_KEY_WORDS_CATEGORY_FAILURE';

// KEY CATEGORY MANAGEMENT
export const GET_KEY_WORD_CATEGORY_DATA_RESPONSE = 'review_analytics/GET_KEY_WORD_CATEGORY_DATA_RESPONSE';

// KEYWORD CATEGORY MODEL
export const OPEN_KEY_WORD_CATEGORY_MODEL = 'review_analytics/OPEN_KEY_WORD_CATEGORY_MODEL';

// KEYWORD CATEGORY MODEL RESET
export const KEY_WORD_CATEGORY_MODEL_RESET = 'review_analytics/KEY_WORD_CATEGORY_MODEL_RESET';

// KEYWORDS CATEGORY ANALYTICS
export const GET_KEY_WORDS_CATEGORY_ANALYTICS_REQUEST = 'review_analytics/GET_KEY_WORDS_CATEGORY_ANALYTICS_REQUEST';
export const GET_KEY_WORDS_CATEGORY_ANALYTICS_RESPONSE = 'review_analytics/GET_KEY_WORDS_CATEGORY_ANALYTICS_RESPONSE';
export const GET_KEY_WORDS_CATEGORY_ANALYTICS_FAILURE = 'review_analytics/GET_KEY_WORDS_CATEGORY_ANALYTICS_FAILURE';

// KEYWORDS CATEGORY CHART DATA
export const GET_KEY_WORDS_CATEGORY_CHART_DATA = 'review_analytics/GET_KEY_WORDS_CATEGORY_CHART_DATA';
