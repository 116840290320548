import { Fragment } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

import { NoDataToShow } from 'revv/containers/dashboard/comman';
import { INonPspTableHeader } from 'revv/types';

interface ITableProps<ITableValue> {
  headers: INonPspTableHeader[];
  data: ITableValue[];
  renderColum: (headers: INonPspTableHeader[], data: ITableValue, index: number) => React.ReactNode;
  tableClass: string;
  totalRow?: ITableValue;
  renderTotalsRow?: (headers: INonPspTableHeader[], totalsRow: ITableValue) => React.ReactNode;
  noDataMsg?: string;
}

export const NonPspTableView = <ITableValue extends { [key: string]: any }>({ headers, data, tableClass, renderColum, totalRow, renderTotalsRow, noDataMsg }: ITableProps<ITableValue>) => {
  return (
    <Table className={tableClass}>
      <Thead>
        <Tr>
          {headers.map((hdata, index) => {
            return (
              <Th key={`teams-${index}`} className={hdata.classStr || ''}>
                {hdata?.label}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {totalRow && renderTotalsRow ? renderTotalsRow(headers, totalRow) : null}
        {data?.length > 0 ? (
          data?.map((it, index) => {
            return <Fragment key={index}>{renderColum(headers, it, index)}</Fragment>;
          })
        ) : (
          <Tr>
            <Td colspan={headers.length}>
              <NoDataToShow message={noDataMsg || 'No data found.'} />
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
