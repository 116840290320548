import { rpReducerTypes, rpApiTypes, commonLocationTree } from 'types';
import { getDatePickerDateToLocal, getLocationDetails, getAllLocationTreeKeys } from 'utils/helpers';
import { PRIMARY_LOGO_URL, TREE_NODE_TYPE, RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE, RP_PRGRM_ACTION_PRGRM } from 'utils/constants';

/** Get promotion vault list for create/edit program */
export const getCPPromotionVaultList = (promotionList: rpApiTypes.ICPPromotionVaultData[]): rpReducerTypes.ICPActionPromotionObj[] => {
  if (promotionList) {
    const promotionVaultList = promotionList.reduce(
      (acc: rpReducerTypes.ICPActionPromotionObj[], curr) => {
        const vaultObj = {
          value: curr.promotion_id,
          label: curr.display_name,
          giftCost: curr.gift_cost
        };
        acc = [...acc, vaultObj];
        return acc;
      },
      [{ value: '', label: 'Select', giftCost: '' }]
    );
    return promotionVaultList;
  } else return [];
};

/** Get modified Reward program Detail object */
export const getModifiedRPDetailsObj = (currentProgramDetail: rpApiTypes.IRPProgramDetailData) => {
  const modifiedDetailObj = {
    ...currentProgramDetail,
    startDate: getDatePickerDateToLocal(currentProgramDetail.startDate),
    endDate: getDatePickerDateToLocal(currentProgramDetail.endDate),
    previousprogramBudget: currentProgramDetail.programBudget,
    rewardEmailScheduleDate: getDatePickerDateToLocal(currentProgramDetail.rewardEmailScheduleDate)
  };
  return modifiedDetailObj;
};

/** Get modified Reward program email object */
export const getModifiedRPEmailObj = (currentProgramDetail: rpApiTypes.IRPProgramDetailData) => {
  const modifiedEmailObj: rpReducerTypes.ICPEmailTemplateObj = {
    emailNotificationStatus: currentProgramDetail.emailNotificationStatus === null || currentProgramDetail.emailNotificationStatus === undefined ? 2 : currentProgramDetail.emailNotificationStatus,
    emailScheduleDate: currentProgramDetail.rewardEmailScheduleDate,
    emailInvitationMessage: currentProgramDetail.emailInvitationMessage ? currentProgramDetail.emailInvitationMessage : '',
    rewardEmailLogoUrl: currentProgramDetail.rewardEmailLogoUrl ? currentProgramDetail.rewardEmailLogoUrl : PRIMARY_LOGO_URL
  };
  return modifiedEmailObj;
};

/** Get modified Reward program action object */
export const getModifiedActionObj = (currentActionObj: rpApiTypes.IRPProgramActionData[]) => {
  return currentActionObj.reduce((acc: rpApiTypes.IRPProgramActionData[], curr: rpApiTypes.IRPProgramActionData) => {
    acc = [
      ...acc,
      {
        rewards: curr.rewards || '',
        nonCashRewards: curr.nonCashRewards || '',
        actionItem: {
          action: curr.action || 0,
          condition: curr.condition || '',
          employeesTodo: curr.employeesTodo || '',
          giftCost: curr.giftCost || 0,
          operator: curr.operator || '',
          program: curr.program || '',
          rewardsProgramDetailsId: curr.rewardsProgramDetailsId || 0,
          value: curr.value ? +curr.value : 0,
          programMeasure: curr.program === RP_PRGRM_ACTION_PRGRM.ASSETS_SUBMITTED ? curr.programMeasure || RP_PRGRM_ACTION_PROGRAM_MEASURE_TYPE.NOT_APPROVAL : ''
        }
      }
    ];
    return acc;
  }, []);
};

/** Get all checked keys based on selected franchisors */
const getAllCheckedKeysBasedOnFran = (locationTreeArr: commonLocationTree.ILocationTree[], brandKey: string[]) => {
  return locationTreeArr.reduce<string[]>((acc, curr) => {
    if (brandKey.includes(curr.key)) {
      acc = [...acc, curr.key, ...getAllLocationTreeKeys(curr.children || [])];
    } else {
      acc = [...acc, ...getAllCheckedKeysBasedOnFran(curr.children || [], brandKey)];
    }
    return acc;
  }, []);
};

/** Get individual franchisor/location keys from program response */
const getIndividualKeysFromResponse = (locationTree: commonLocationTree.ILocationTree[], userType: string, selectedLocData: any[]) => {
  const getKeysBasedOnUserType = getAllLocationTreeKeys(locationTree).filter((filterDatum) => getLocationDetails(filterDatum).userType === userType);
  const responseDataIds = selectedLocData.map((datum) => datum.id);
  return getKeysBasedOnUserType.filter((datum) => responseDataIds.includes(+getLocationDetails(datum).id));
};

/** Get valid checked keys for edit program */
export const getValidCheckedKeysForEdit = (locationTree: commonLocationTree.ILocationTree[], responseHubAccObj: rpApiTypes.IRPProgramLocationData): string[] => {
  let franchisorKeys: string[] = [];
  let locationKeys: string[] = [];
  if (responseHubAccObj.franchisors.length) {
    const brandkeys = getIndividualKeysFromResponse(locationTree, TREE_NODE_TYPE.FRANCHISORS, responseHubAccObj.franchisors);
    franchisorKeys = getAllCheckedKeysBasedOnFran(locationTree, brandkeys);
  }
  if (responseHubAccObj.accounts.length) {
    locationKeys = getIndividualKeysFromResponse(locationTree, TREE_NODE_TYPE.ACCOUNTS, responseHubAccObj.accounts);
  }
  return [...franchisorKeys, ...locationKeys];
};
