import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { OverviewAction } from 'actions';
import { overviewReducerType, overviewApiType } from 'types/overview';
import { extractLocationsDropdownList, extractLatLongArray, getMontlyPostArray } from 'utils/helpers';
import { OVERVIEW_POST_API_PROPS, OVERVIEW_INIT_LOCATION_DATA, OVERVIEW_INIT_ASSETS_STATS, OVERVIEW_INIT_POST_METRICS, OVERVIEW_INIT_MONTHLY_POST_METRICS } from 'utils/constants';

type IOverviewReducer = overviewReducerType.IOverviewReducer;
type IOverviewAccountData = overviewApiType.IOverviewAccountData;
type IOverviewMonthlyPostsResponse = overviewApiType.IOverviewMonthlyPostsResponse;

const initialState: IOverviewReducer = {
  isDataFetching: false,
  isTotalMetricsFetching: false,
  error: null,
  locationListArray: [],
  locationsLatLng: [],
  locationsData: OVERVIEW_INIT_LOCATION_DATA,
  assetsStats: OVERVIEW_INIT_ASSETS_STATS,
  recentPosts: [],
  postsMetrics: OVERVIEW_INIT_POST_METRICS,
  monthlyPostsMetrics: OVERVIEW_INIT_MONTHLY_POST_METRICS
};

const overviewReducer: Reducer<IOverviewReducer, OverviewAction> = (state: IOverviewReducer = initialState, action: OverviewAction): IOverviewReducer => {
  switch (action.type) {
    case types.OVERVIEW_LOCATIONS_REQUEST:
      return {
        ...state,
        isDataFetching: true,
        locationsData: OVERVIEW_INIT_LOCATION_DATA
      };
    case types.OVERVIEW_LOCATIONS_RESPONSE:
      const locationData: IOverviewAccountData[] | IOverviewAccountData =
        Array.isArray(action.payload.accounts) && action.payload.accounts.length ? action.payload.accounts : action.payload.account ? action.payload.account : [];
      const accountCount = action.payload.accounts_count ? action.payload.accounts_count : action.payload.account ? 1 : 0;
      return {
        ...state,
        isDataFetching: false,
        locationsData: {
          accounts_count: accountCount,
          accounts: extractLocationsDropdownList(locationData, accountCount)
        },
        locationListArray: locationData,
        locationsLatLng: extractLatLongArray(locationData)
      };
    case types.OVERVIEW_LOCATIONS_REQUEST_FAIL:
      return {
        ...state,
        isDataFetching: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.OVERVIEW_SET_LOC_LIST_ARRAY:
      return {
        ...state,
        locationListArray: action.payload,
        locationsLatLng: extractLatLongArray(action.payload)
      };
    case types.OVERVIEW_TOTALMETRICS_REQUEST:
      return {
        ...state,
        isTotalMetricsFetching: true,
        assetsStats: { ...OVERVIEW_INIT_ASSETS_STATS, isAssetsStatsFetching: true }
      };
    case types.OVERVIEW_TOTALMETRICS_RESPONSE:
      const data: overviewApiType.IOverviewTotalMetricsResponse = action.payload;
      return {
        ...state,
        isTotalMetricsFetching: false,
        assetsStats: {
          isAssetsStatsFetching: false,
          totalAssetsCount: data.assets_stats.total_assets_count,
          usedAssetsCount: data.assets_stats.used_assets_count,
          recentMediaAssetsCount: data.assets_stats.recent_media_assets_count,
          recentMediaAssetDate: data.assets_stats.recent_media_assets_submitted_date,
          usersCount: data.users_count,
          totalPhotosCount: data.assets_stats.total_photos_count,
          totalVideosCount: data.assets_stats.total_videos_count,
          unusedPhotosCount: data.assets_stats.unused_photos_count,
          unusedVideosCount: data.assets_stats.unused_videos_count,
          usedPhotosCount: data.assets_stats.used_photos_count,
          usedVideosCount: data.assets_stats.used_videos_count,
          activeUsersCount: data.users_stats.active_users_count,
          activeUsersDelta: data.users_stats.active_users_delta,
          activeUsersDeltaPercentage: data.users_stats.active_users_delta_percentage,
          inactiveUsersCount: data.users_stats.inactive_users_count
        }
      };
    case types.OVERVIEW_TOTALMETRICS_REQUEST_FAIL:
      return {
        ...state,
        isTotalMetricsFetching: false,
        assetsStats: { ...state.assetsStats, isAssetsStatsFetching: false },
        error: action.payload || 'Unexpected Error'
      };
    case types.OVERVIEW_RECENTPOSTS_REQUEST:
      return {
        ...state,
        isDataFetching: true
      };
    case types.OVERVIEW_RECENTPOSTS_RESPONSE:
      return {
        ...state,
        isDataFetching: false,
        recentPosts: action.payload.length ? action.payload : []
      };
    case types.OVERVIEW_RECENTPOSTS_REQUEST_FAIL:
      return {
        ...state,
        isDataFetching: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.OVERVIEW_POSTSMETRICS_REQUEST:
      return {
        ...state,
        isDataFetching: true,
        postsMetrics: {
          ...OVERVIEW_INIT_POST_METRICS,
          isPostMetricsFetching: true
        }
      };
    case types.OVERVIEW_POSTSMETRICS_RESPONSE:
      return {
        ...state,
        isDataFetching: false,
        postsMetrics: {
          isPostMetricsFetching: false,
          postsCount: action.payload.posts_count || 0,
          publishedPosts: action.payload.published_posts || 0,
          usedPosts: action.payload.used_posts || 0
        }
      };
    case types.OVERVIEW_POSTSMETRICS_REQUEST_FAIL:
      return {
        ...state,
        isDataFetching: false,
        postsMetrics: {
          ...state.postsMetrics,
          isPostMetricsFetching: false
        },
        error: action.payload || 'Unexpected Error'
      };
    case types.OVERVIEW_MONTHLY_POSTS_REQUEST:
      return {
        ...state,
        isDataFetching: true,
        monthlyPostsMetrics: {
          ...OVERVIEW_INIT_MONTHLY_POST_METRICS,
          isMonthlyPostMetricsFetching: true
        }
      };
    case types.OVERVIEW_MONTHLY_POSTS_RESPONSE:
      const totalPostsList: IOverviewMonthlyPostsResponse[] = action.payload.total_posts_result || [];
      const publishedPostsList: IOverviewMonthlyPostsResponse[] = action.payload.published_posts_result || [];
      const usedPostsList: IOverviewMonthlyPostsResponse[] = action.payload.used_posts_result || [];
      return {
        ...state,
        isDataFetching: false,
        monthlyPostsMetrics: {
          isMonthlyPostMetricsFetching: false,
          totalPostsResult: {
            facebookPostsCount: getMontlyPostArray(totalPostsList, OVERVIEW_POST_API_PROPS.FB),
            twitterPostsCount: getMontlyPostArray(totalPostsList, OVERVIEW_POST_API_PROPS.TWIT),
            instagramPostsCount: getMontlyPostArray(totalPostsList, OVERVIEW_POST_API_PROPS.INSTA),
            linkedinPostsCount: getMontlyPostArray(totalPostsList, OVERVIEW_POST_API_PROPS.LINKIN)
          },
          publishedPostsResult: {
            facebookPostsCount: getMontlyPostArray(publishedPostsList, OVERVIEW_POST_API_PROPS.FB),
            twitterPostsCount: getMontlyPostArray(publishedPostsList, OVERVIEW_POST_API_PROPS.TWIT),
            instagramPostsCount: getMontlyPostArray(publishedPostsList, OVERVIEW_POST_API_PROPS.INSTA),
            linkedinPostsCount: getMontlyPostArray(publishedPostsList, OVERVIEW_POST_API_PROPS.LINKIN)
          },
          usedPostsResult: {
            facebookPostsCount: getMontlyPostArray(usedPostsList, OVERVIEW_POST_API_PROPS.FB),
            twitterPostsCount: getMontlyPostArray(usedPostsList, OVERVIEW_POST_API_PROPS.TWIT),
            instagramPostsCount: getMontlyPostArray(usedPostsList, OVERVIEW_POST_API_PROPS.INSTA),
            linkedinPostsCount: getMontlyPostArray(usedPostsList, OVERVIEW_POST_API_PROPS.LINKIN)
          }
        }
      };
    case types.OVERVIEW_MONTHLY_POSTS_REQUEST_FAIL:
      return {
        ...state,
        isDataFetching: false,
        monthlyPostsMetrics: {
          ...state.monthlyPostsMetrics,
          isMonthlyPostMetricsFetching: false
        },
        error: action.payload || 'Unexpected Error'
      };

    case types.OVERVIEW_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
export { overviewReducer };
