import { takeLatest, put, all } from 'redux-saga/effects';

import * as types from 'analytics/actions/action-types';
import {
  getReviewAnalyticsResponse,
  getReviewAnalyticsFailure,
  getReviewAnalyticsSummaryResponse,
  getReviewAnalyticsSummaryFailure,
  getAllKeyWordDataResponse,
  getKeyWordDataFailure,
  getKeyWordsCategoryListResponse,
  getKeyWordsCategoryListFailure,
  getKeyWordsCategoryResponse,
  getKeyWordsCategoryFailure,
  createUpdateKeyWordsCategoryFailure,
  deleteKeyWordsCategoryFailure,
  deleteKeyWordsCategoryResponse,
  createUpdateKeyWordsCategoryResponse,
  getKeyWordCategoryDataResponse,
  getKeyWordsCategoryAnalyticsResponse,
  getKeyWordsCategoryAnalyticsFailure
} from 'analytics/actions';
import { apiCall, defaultHeader, API, getMomentForDate, updateApiOnFail } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { exceptionHandlerRequest, logoutRequest } from 'actions';
import {
  dateEqualHandler,
  dateFormatter,
  KEYWORDS_CATEGORY_ANALYTICS_FAILTED,
  KEYWORDS_CATEGORY_FETCHING_LIST_FAILED,
  REVIEW_ANALYTICS_FAILED,
  REVIEW_ANALYTICS_FETCHING_LIST_FAILED,
  subtractDayHandler
} from 'analytics/utils';
import { ALERT_BOX_TYPES, KEYWORDS_ADD_CATEGORY_SUCCESS_MSG, KEYWORDS_DELETE_CATEGORY_FAILURE_MSG, KEYWORDS_DELETE_CATEGORY_SUCCESS_MSG, KEYWORDS_UPDATE_CATEGORY_SUCCESS_MSG } from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getReviewAnalyticsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;

    const params = {
      ...payload,
      isReviewRequest: undefined,
      segment: 'reviews'
    };

    const repliedParams = {
      ...payload,
      isReviewRequest: undefined,
      segment: 'replied'
    };

    const headers = defaultHeader();
    const path = API.getReviewAnalytics.apiPath;

    const { reviewsResponse, repliedResponse } = yield all({
      reviewsResponse: apiCall({ headers, params, apiPath: path, action: API.getReviewAnalytics.action }),
      ...(!payload.isReviewRequest
        ? {
            repliedResponse: apiCall({ headers, params: repliedParams, apiPath: path, action: API.getReviewAnalytics.action })
          }
        : {})
    });

    if (reviewsResponse?.status === 401 || repliedResponse?.status === 401) {
      yield put(logoutRequest());
    } else if (reviewsResponse?.status === 200 || repliedResponse?.status === 200) {
      const timeZoneOffset = new Date().getTimezoneOffset();
      const day = Math.abs(timeZoneOffset) > 1440 ? 2 : 1;
      const reviewsData: any[] = reviewsResponse?.data?.reviews_analytics_by_date || [];
      const repliedData: any[] = repliedResponse?.data?.reviews_analytics_by_date || [];
      const concatedData = reviewsData.reduce((acc, curr, index) => {
        const replied = repliedData.find((it) => it?.date === curr?.date) || {};
        acc.push({ ...curr, ...replied });
        if (index === reviewsData.length - 1) {
          const excessRepliedData = repliedData.find((it) => !reviewsData.some((datum) => datum?.date === it?.date)) || [];
          acc = [...acc, ...excessRepliedData];
        }
        return acc;
      }, []);
      const data = concatedData
        .map((it: any) => {
          return {
            ...it,
            date: timeZoneOffset === 0 ? it.date : subtractDayHandler(it.date, timeZoneOffset < 0 ? -1 * day : day, 'YYYY-MM-DD')
          };
        })
        .filter((it: any) => {
          const startDate = dateFormatter(+payload.date_range[0] * 1000, 'YYYY-MM-DD');
          return dateEqualHandler(it.date, startDate, 'YYYY-MM-DD', 'after') && dateEqualHandler(it.date, getMomentForDate(payload.date_range[1] * 1000), 'YYYY-MM-DD', 'before');
        });
      yield put(
        getReviewAnalyticsResponse({
          reviews_analytics_by_date: data || []
        })
      );
    } else {
      yield put(getReviewAnalyticsFailure(reviewsResponse?.data?.error || repliedResponse?.data?.error || REVIEW_ANALYTICS_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* getReviewAnalyticsSummaryRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;

    const headers = defaultHeader();
    const summaryPath = API.getReviewAnalyticsSummary.apiPath;
    const summaryResponse = yield apiCall({ headers, params: payload, apiPath: summaryPath, action: API.getReviewAnalyticsSummary.action });

    if (summaryResponse.status === 401) {
      yield put(logoutRequest());
    } else if (summaryResponse.status === 200) {
      yield put(getReviewAnalyticsSummaryResponse(summaryResponse.data));
    } else {
      yield put(getReviewAnalyticsSummaryFailure(summaryResponse?.data?.error || REVIEW_ANALYTICS_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* getKeyWordDataRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const params = {
      ...payload,
      page: payload.page,
      per: 450,
      key: payload?.sortKey,
      direction: payload?.isKeywordAscending ? 'asc' : 'desc',
      ...(payload?.review_keyword_category_id && { review_keyword_category_id: payload.review_keyword_category_id })
    };
    if (params?.fetchAllKeywords) {
      delete params?.fetchAllKeywords;
    }
    const headers = defaultHeader();
    let response;
    if (payload?.fetchAllKeywords) {
      const params = { franchisor_id: payload.ancestor_franchisor_id };
      response = yield apiCall({ headers, params, apiPath: API.getAllAvailableReviewKeyWord.apiPath, action: API.getAllAvailableReviewKeyWord.action });
    } else {
      response = yield apiCall({ headers, params, apiPath: API.getReviewKeyWord.apiPath, action: API.getReviewKeyWord.action });
    }

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload?.fetchAllKeywords) {
        // yield put(getKeyWordCategoryDataResponse({ review_keywords: response.data.available_words}));
        yield put(getKeyWordCategoryDataResponse(response.data));
      } else {
        yield put(getAllKeyWordDataResponse(response.data));
      }
    } else {
      yield put(getKeyWordDataFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* getKeyWordsCategoryListRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const headers = defaultHeader();
    const path = `${API.getKeywordsCategoryList.apiPath}?${payload.userOwnership}_id=${payload.id}`;
    const response = yield apiCall({ headers, apiPath: path, action: API.getKeywordsCategoryList.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getKeyWordsCategoryListResponse(response.data?.review_keyword_categories));
    } else {
      yield put(getKeyWordsCategoryListFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* getKeyWordsCategoryRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const headers = defaultHeader();
    const path = API.getKeywordsCategory.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getKeywordsCategory.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getKeyWordsCategoryResponse(response.data?.review_keyword_category));
    } else {
      yield put(getKeyWordsCategoryFailure(response.data.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* createUpdateKeyWordsCategoryRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const headers = defaultHeader();
    const actionType = payload.id ? API.updateKeywordsCategory : API.createKeywordsCategory;
    const path = payload.id ? actionType.apiPath.replace(':id', payload.id) : actionType.apiPath;
    const response = yield apiCall({ headers, apiPath: path, action: actionType.action, data: { review_keyword_category: { ...payload } } });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(createUpdateKeyWordsCategoryResponse());
      if (payload.id) {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, KEYWORDS_UPDATE_CATEGORY_SUCCESS_MSG);
      } else {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, KEYWORDS_ADD_CATEGORY_SUCCESS_MSG);
      }
    } else {
      yield put(createUpdateKeyWordsCategoryFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error);
    }
    payload.navigate();
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* deleteKeyWordsCategoryRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const headers = defaultHeader();
    const path = API.deleteKeywordsCategory.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteKeywordsCategory.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, KEYWORDS_DELETE_CATEGORY_SUCCESS_MSG);
      yield put(deleteKeyWordsCategoryResponse());
    } else {
      yield put(deleteKeyWordsCategoryFailure(response.data.error));
      alertBoxCall(ALERT_BOX_TYPES.ERROR, KEYWORDS_DELETE_CATEGORY_FAILURE_MSG);
    }
    payload.navigate();
  } catch (error) {
    yield put(exceptionHandlerRequest(REVIEW_ANALYTICS_FAILED));
  }
}

function* getKeywordsCategoryAnalyticsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getKeywordCategoriesAnalytics.apiPath.replace(':account_id', payload.account_id);
    const response = yield apiCall({ headers, params: payload, apiPath: path, action: API.getKeywordCategoriesAnalytics.action });
    const asyncResponse = yield updateApiOnFail({
      url: response.data.url,
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (asyncResponse.status === 200) {
      yield put(getKeyWordsCategoryAnalyticsResponse(asyncResponse.data));
    } else {
      yield put(getKeyWordsCategoryAnalyticsFailure(KEYWORDS_CATEGORY_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(KEYWORDS_CATEGORY_ANALYTICS_FAILTED));
  }
}

export function* takeKeyWordManagementRequest() {
  yield takeLatest(types.GET_KEY_WORD_DATA_REQUEST, getKeyWordDataRequest);
  yield takeLatest(types.GET_KEY_WORDS_CATEGORY_LIST_REQUEST, getKeyWordsCategoryListRequest);
  yield takeLatest(types.GET_KEY_WORDS_CATEGORY_REQUEST, getKeyWordsCategoryRequest);
  yield takeLatest(types.CREATE_UPDATE_KEY_WORDS_CATEGORY_REQUEST, createUpdateKeyWordsCategoryRequest);
  yield takeLatest(types.DELETE_KEY_WORDS_CATEGORY_REQUEST, deleteKeyWordsCategoryRequest);
  yield takeLatest(types.GET_KEY_WORDS_CATEGORY_ANALYTICS_REQUEST, getKeywordsCategoryAnalyticsRequest);
}

export function* takeReviewAnalyticsSummaryRequest() {
  yield takeLatest(types.GET_REVIEW_ANALYTICS_SUMMARY_REQUEST, getReviewAnalyticsSummaryRequest);
}

export function* takeReviewAnalyticsRequest() {
  yield takeLatest(types.GET_REVIEW_ANALYTICS_REQUEST, getReviewAnalyticsRequest);
}
