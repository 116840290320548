import { Form } from 'react-bootstrap';

import { commonBoost } from 'types';
import { AP_ADD_BOOST_DURATION_DROPDOWN, TOOLTIP_PLACEMENT } from 'utils/constants';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CustomDatePicker } from 'widgets/DatePicker';
import { CustomTooltip } from 'widgets/Tooltip';
import { ImageValidation } from 'widgets/Media';

export const BoostDuration = ({ lifetimeSpend, duration, facebookDarkPost, handleUpdateFBBoostConfig }: commonBoost.IBoostBudget) => {
  const handleBoostDailySpend = (key: string, value: number | string | boolean) => {
    handleUpdateFBBoostConfig(key, value);
  };

  return (
    <div className="creator-add-boost-spend-wrp">
      <div className="boost__white--box white-box duration--wrp r-mt2">
        {/* <div><span className='title'>Duration</span></div> */}
        <div className="r-flx r-flx-ac g-20 r-mb4">
          <div className="left-section">
            <span className="boost__box--title">Total Budget</span>
          </div>
          <div className="right-section">
            <div className="wbf-inputs">
              <div className="input-fields g-20">
                <div className="if-main if-h">
                  <span className="ifa">$</span>
                  <Form.Control
                    type="number"
                    placeholder=""
                    className={'plain-input'}
                    aria-describedby="inputGroupPrepend"
                    value={lifetimeSpend || ''}
                    name={'lifetimeSpend'}
                    onChange={(event) => handleBoostDailySpend(event.target.name, event.target.value)}
                    step="0.5"
                    autoComplete="off"
                  />
                </div>
                <div className="hint-wrp r-flx r-flx-ac">
                  <span className="country-initials r-ml0 r-mt0">U.S.</span>
                  {lifetimeSpend / (duration || 1) < 150 && (
                    <CustomTooltip
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customClassname={'custom-tooltip-long-text hint-tool highzIndex'}
                      customTooltipText={'Minimum spend must be at least $1.50 per day. '}
                      customInput={() => (
                        <div className="info__tool--tip">
                          <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="r-flx r-flx-ac g-20 r-mb4">
          <div className="left-section">
            <div className="boost__box--title">Duration</div>
          </div>
          <div className="right-section duration__drop--down">
            <CustomDropdown
              classNamePrefix=""
              id="boost-duration-dropdown"
              placeholder={'Select a day'}
              options={AP_ADD_BOOST_DURATION_DROPDOWN}
              value={AP_ADD_BOOST_DURATION_DROPDOWN.find((datum) => datum.id === duration)}
              onChange={(eventObj: any) => handleBoostDailySpend('duration', +eventObj.id)}
            />
          </div>
        </div>

        <div className="r-flx r-flx-ac wi-drkPost g-20 r-mb4">
          <div className="left-section">
            <div className="boost__box--title">Dark Post</div>
          </div>
          <div className="right-section">
            <div className="form-check">
              <label className="checkbox-item">
                <span className="checkbox-hover">
                  <input
                    type="checkbox"
                    name="facebookDarkPost"
                    className="option-input click-wave"
                    checked={facebookDarkPost}
                    onChange={(event) => handleBoostDailySpend(event.target.name, event.target.checked)}
                  />
                </span>
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </div>

        {false && (
          <>
            <div className={`filter-item date-picker`}>
              <div className={`dp-item dp-from`}>
                <CustomDatePicker selectedDate={new Date()} dateRangeType="" dateRangeValue={undefined} selectsStart onChange={() => null} placeholderText="date" />
                <span className="fltlabels start">Start Date</span>
              </div>
              <div className={`dp-item dp-to`}>
                <CustomDatePicker selectedDate={new Date()} dateRangeType="" dateRangeValue={undefined} selectsStart onChange={() => null} placeholderText="date" />
                <span className="fltlabels end">End Date</span>
              </div>
            </div>
            <span className="total-budget">Total Budget</span>
            <div className="duration-option">Place a chart here...</div>
          </>
        )}
      </div>
    </div>
  );
};
