import { mgReducerTypes } from 'types/media-gallery';
import { CONTENT_SUPPLIERS_DROPDOWN_OBJ } from '../posts';

export const MG_STATS_INITIAL_VALUE: mgReducerTypes.IMgStatsVal = {
  totalAssets: 0,
  totalPhotos: 0,
  totalVideos: 0
};

export const MG_FILTER_INITIAL_VALUE: mgReducerTypes.IMgFiltersVal = {
  type: 'photos',
  source: '',
  media_release: 'all',
  post_used: 'all',
  search: '',
  contentSupplierFilterObj: CONTENT_SUPPLIERS_DROPDOWN_OBJ
};

export const IMAGE_CROP_PROPS: mgReducerTypes.IMGMediaCropperProps = {
  showCropper: false,
  image: null,
  crop: { x: 0, y: 0 },
  croppedAreaPixels: {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    rotate: 0,
    scaleX: 1,
    scaleY: 1
  },
  zoom: 0,
  aspect: 6 / 5,
  shape: 'rect',
  rotate: 0,
  enhance: false,
  watermark: false,
  rotateEnabled: false
};

export const MG_FILTER_STATS_INIT_OBJ: mgReducerTypes.IMgMediaFilterStatsObj = {
  allMediaCount: 0,
  brandMediaCount: 0,
  locationMediaCount: 0,
  contentSupplierCount: 0,
  newlyAddedMediaCount: 0,
  unusedMediaCount: 0,
  usedMediaCount: 0
};

export const MG_UPLOAD_MEDIA_IN_CLOUD_INIT_OBJ: mgReducerTypes.IMgUploadMediaInCloud = {
  addedAssetsCount: 0,
  response: [],
  cloudinaryData: null
};

export const MG_ADD_ASSETS_INIT_OBJ: mgReducerTypes.IMediaGalleryAddAsset = {
  assetsList: [],
  assetsSuccessMessage: null,
  assetsErrorMessage: null
};

export const MG_UPLOAD_PROGRESS_INFO_INIT_OBJ: mgReducerTypes.IMgMediaUploadProgressInfo = {
  percentage: 0,
  remainingTime: '0'
};
