import { franchisorReducerTypes } from 'types';

export const FRAN_SCHEDULE_INIT_TREE_DATA: franchisorReducerTypes.IFranScheduleTreeData = {
  isLocFetching: false,
  locationType: 'all_locations',
  schLocationTree: [],
  hubOrLocStats: {
    hubs: 0,
    locationLists: 0
  }
};

export const FRAN_ADS_BANNER_INIT_RES: franchisorReducerTypes.IFranPartnerBanners = { partnerBannersList: [], randomPartnerBanners: [] };
