import { Tag } from 'react-tag-autocomplete';

import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { apAccountApiTypes, apAccountContainerTypes, postsReducerTypes, apAccountReducerTypes, postsApiTypes, calendarApiTypes } from 'types';

// ADD POST - POST ACTION
export const addPostSavePostActionRequest = (payload: apAccountApiTypes.IAPPostActionRequest) => createAction(actionTypes.AP_SAVE_POST_ACTION_REQUEST, payload);
export const addPostSavePostActionResponse = (data: postsApiTypes.IPostListSavedPostSummaries[]) => createAction(actionTypes.AP_SAVE_POST_ACTION_RESPONSE, data);
export const addPostSavePostActionFailure = (error: null | string) => createAction(actionTypes.AP_SAVE_POST_ACTION_REQ_FAILURE, error);

// ADD POST - SAVE AS SCHEDULED POST
export const addPostSaveAsScheduledPostRequest = (payload: apAccountContainerTypes.IAPSaveAsScheduledPostReq) => createAction(actionTypes.AP_SAVE_POST_AS_SCHEDULED_REQUEST, payload);
export const addPostSaveAsScheduledPostResponse = (data: number) => createAction(actionTypes.AP_SAVE_POST_AS_SCHEDULED_RESPONSE, data);
export const addPostSaveAsScheduledPostFailure = (error: null | string) => createAction(actionTypes.AP_SAVE_POST_AS_SCHEDULED_FAILURE, error);

// ADD POST - COUPON LIST
export const addPostCouponListRequest = (payload: number) => createAction(actionTypes.AP_COUPON_LIST_REQUEST, payload);
export const addPostCouponListResponse = (data: apAccountApiTypes.IAddPostCouponObj[]) => createAction(actionTypes.AP_COUPON_LIST_RESPONSE, data);
export const addPostCouponListFailure = (error: null | string) => createAction(actionTypes.AP_COUPON_LIST_FAILURE, error);

// ADD POST - GET FACEBOOK TAGS
export const addPostGetFBTagsListRequest = (payload: string) => createAction(actionTypes.AP_GET_FB_TAG_LIST_REQUEST, payload);
export const addPostGetFBTagsListResponse = (data: apAccountApiTypes.IAddPostFBTagListResponse[]) => createAction(actionTypes.AP_GET_FB_TAG_LIST_RESPONSE, data);
export const addPostGetFBTagsListFailure = (error: null | string) => createAction(actionTypes.AP_GET_FB_TAG_LIST_FAILURE, error);

// ADD POST - UPDATE SAVED POST
export const addPostUpdateSavedPostRequest = (payload: apAccountApiTypes.IAPUpdateSavedPostRequest) => createAction(actionTypes.AP_UPDATE_SAVED_POST_REQUEST, payload);
export const addPostUpdateSavedPostResponse = (data: number) => createAction(actionTypes.AP_UPDATE_SAVED_POST_RESPONSE, data);
export const addPostUpdateSavedPostFailure = (error: null | string) => createAction(actionTypes.AP_UPDATE_SAVED_POST_REQ_FAILURE, error);

// ADD POST - GET INSTAGRAM PHOTO PREVIEW
export const addPostGetSocialMediaPhotoPreviewRequest = (payload: { socialMedia: string; payload: apAccountApiTypes.IAPInstaPhotoPreviewReqPayload }) =>
  createAction(actionTypes.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_REQUEST, payload);
export const addPostGetSocialMediaPhotoPreviewResponse = (data: { socialMedia: string; response: apAccountApiTypes.IAPInstaPhotoPreviewResponse }) =>
  createAction(actionTypes.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_RESPONSE, data);
export const addPostGetSocialMediaPhotoPreviewFailure = (error: { socialMedia: string; msg: string }) => createAction(actionTypes.AP_GET_SOCIAL_MEDIA_PHOTO_PREVIEW_FAILURE, error);

// ADD POST - ADD BOOST FOR SCHEDULED POST
export const addPostAddBoostToScheduledPostRequest = (payload: apAccountApiTypes.IAPAddBoostForSchPostReqPayload) => createAction(actionTypes.AP_ADD_BOOST_FOR_SCH_POST_REQUEST, payload);
export const addPostAddBoostToScheduledPostResponse = (data: string) => createAction(actionTypes.AP_ADD_BOOST_FOR_SCH_POST_RESPONSE, data);
export const addPostAddBoostToScheduledPostFailure = (error: null | string) => createAction(actionTypes.AP_ADD_BOOST_FOR_SCH_POST_FAILURE, error);

// ADD POST - GET INTERNAL NOTES
export const addPostGetInternalNotesResponse = (response: calendarApiTypes.IGetSavedPostCommentsResponse[]) => createAction(actionTypes.AP_GET_INTERNAL_NOTES_RESPONSE, response);

// ADD POSTS - SAVE INTERNAL NOTE
export const addPostAddInternalNotesResponse = (response: number) => createAction(actionTypes.AP_ADD_INTERNAL_NOTES_RESPONSE, response);
export const addPostAddInternalNotesFailure = (error: null | string) => createAction(actionTypes.AP_ADD_INTERNAL_NOTES_FAILURE, error);

// ADD POST - SET TAGS
export const addPostSetTags = (payload: Tag[]) => createAction(actionTypes.AP_SET_TAGS, payload);

// ADD POST - GET ACTION TYPE FOR POST
export const addPostGetActionTypeForPost = (payload: string) => createAction(actionTypes.AP_GET_ACTION_TYPE_FOR_POST, payload);

// ADD POST - UPDATE SAVED POST DETAILS
export const addPostUpdateSavedPostDetails = (payload: null | apAccountReducerTypes.IAPSavedPostDetails) => createAction(actionTypes.AP_UPDATE_SAVED_POST_DETAILS, payload);

// ADD POST - GET EDIT SAVED POST OBJ
export const addPostGetEditSavedPostObj = (payload: postsReducerTypes.IPostDetailsData) => createAction(actionTypes.AP_GET_EDIT_SAVED_POST_OBJ, payload);

// ADD POST - SET INSTAGRAM PHOTO PREVIEW OBJ
export const addPostSetInstaPhotoPreviewObj = (payload: apAccountReducerTypes.IAddPostInstaPreviewData) => createAction(actionTypes.AP_SET_INSTA_PHOTO_PREVIEW_OBJ, payload);

// ADD POST - SET EDIT CONFIRM FOR APPROVED POST
export const addPostSetEditConfirmForApprovedPost = (payload: apAccountReducerTypes.IAddPostEditConfirmObj) => createAction(actionTypes.AP_SET_EDIT_CONFIRM_FOR_APPROVED_POST, payload);

export const addPostEnablePostNow = (payload: boolean) => createAction(actionTypes.AP_ENABLE_POST_NOW, payload);

// ADD POST RESET ALL
export const addPostSaveMessageReset = () => createAction(actionTypes.AP_SAVE_POST_MESSAGE_RESET);
export const addPostSavedPostReset = () => createAction(actionTypes.AP_SAVED_POST_RESET);
export const addPostFBTagsListReset = () => createAction(actionTypes.AP_FB_TAGS_LIST_RESET);
export const addPostResetAll = () => createAction(actionTypes.AP_RESET_ALL);

const ADD_POST_ACCOUNT = {
  addPostSavePostActionRequest,
  addPostSavePostActionResponse,
  addPostSavePostActionFailure,
  addPostSaveAsScheduledPostRequest,
  addPostSaveAsScheduledPostResponse,
  addPostSaveAsScheduledPostFailure,
  addPostCouponListRequest,
  addPostCouponListResponse,
  addPostCouponListFailure,
  addPostGetFBTagsListRequest,
  addPostGetFBTagsListResponse,
  addPostGetFBTagsListFailure,
  addPostUpdateSavedPostRequest,
  addPostUpdateSavedPostResponse,
  addPostUpdateSavedPostFailure,
  addPostGetInternalNotesResponse,
  addPostAddInternalNotesResponse,
  addPostAddInternalNotesFailure,
  addPostGetSocialMediaPhotoPreviewRequest,
  addPostGetSocialMediaPhotoPreviewResponse,
  addPostGetSocialMediaPhotoPreviewFailure,
  addPostAddBoostToScheduledPostRequest,
  addPostAddBoostToScheduledPostResponse,
  addPostAddBoostToScheduledPostFailure,
  addPostSetTags,
  addPostGetActionTypeForPost,
  addPostUpdateSavedPostDetails,
  addPostGetEditSavedPostObj,
  addPostSetInstaPhotoPreviewObj,
  addPostSetEditConfirmForApprovedPost,
  addPostEnablePostNow,
  addPostSaveMessageReset,
  addPostSavedPostReset,
  addPostFBTagsListReset,
  addPostResetAll
};

export type AddPostAccountActions = ActionsUnion<typeof ADD_POST_ACCOUNT>;
