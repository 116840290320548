import { connectionContainerTypes, commonProps } from 'types';
import { REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants/reviews';

export const CONNECTION_TABS: connectionContainerTypes.IConnectionStatsTabs = {
  LANDING: 'landing',
  MANAGE_LOGINS: 'manage_logins',
  RALLIO_PROFILE: 'rallio_profile',
  SOCIAL_PROFILES: 'social_profiles',
  UPGRADE_PACKAGE: 'upgrade_package',
  FEED: 'feed',
  SCHEDULER: 'scheduler',
  PROFILE: 'profile',
  VIDEO_INTRODUCTION: 'video_introduction',
  VIDEO_CONCLUSION: 'video_conclusion'
};

export enum ConnSocialMediaStatusName {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
  PROBLEM = 'problem'
}

export enum ConnSocialMediaStatusLabel {
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not Connected',
  PROBLEM = 'Needs Attention'
}

export const CN_SOCIAL_MEDIA_STATUS: commonProps.IMainPageRouteData[] = [
  { name: ConnSocialMediaStatusName.CONNECTED, label: ConnSocialMediaStatusLabel.CONNECTED },
  { name: ConnSocialMediaStatusName.NOT_CONNECTED, label: ConnSocialMediaStatusLabel.NOT_CONNECTED },
  { name: ConnSocialMediaStatusName.PROBLEM, label: ConnSocialMediaStatusLabel.PROBLEM }
];

export const CONNECTION_SORT_TYPES: connectionContainerTypes.IConnectionSortType = {
  LOCATION: 'location',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  TIKTOK: 'tiktok',
  GOOGLE: 'google',
  INSTAGRAM_BUSINESS: 'instagram_business_page',
  YELP: 'yelp'
};

export enum ConnExportTabName {
  CONNECTIONS_REPORT = 'connections_report',
  USERS_REPORT = 'users_report',
  LOCATION_ACTIVITY_REPORT = 'location_activity_report'
}

export enum ConnExportTabLabel {
  CONNECTIONS_REPORT = 'Connections Report',
  USERS_REPORT = 'Users Report',
  LOCATION_ACTIVITY_REPORT = 'Location Activity Report'
}

export enum ConnectionsValidQueryParams {
  SEARCH = 'text',
  SORT_BY = 'sort_by',
  ASCENDING = 'ascending',
  LOCATION_LIST_ID = 'location_list_id',
  HUB_ID = 'hub_id'
}

export const CONNECTION_FILTER_INIT_OBJ = {
  text: '',
  sort_by: '',
  ascending: '0',
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ
};

export enum IConUpgradePackageOption {
  RALLIO_PACKAGE = 'rallio-package',
  DO_IT_YOURSELF = 'do-it-yourself'
}
