import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { rpApiTypes, rpReducerTypes, commonProps, commonLocationTree } from 'types';

// GET REWARD PROGRAMS STATS REQUEST
export const rpGetStatsRequest = (payload: rpApiTypes.IRPListDataRequest) => createAction(actionTypes.RP_GET_STATS_REQUEST, payload);
export const rpGetStatsResponse = (data: rpApiTypes.IRPStatsResponse) => createAction(actionTypes.RP_GET_STATS_RESPONSE, data);
export const rpGetStatsFailure = (error: null | string) => createAction(actionTypes.RP_GET_STATS_FAILURE, error);

// GET REWARD PROGRAMS LIST REQUEST
export const rpGetListRequest = (payload: rpApiTypes.IRPListDataRequest) => createAction(actionTypes.RP_GET_LIST_REQUEST, payload);
export const rpGetListResponse = (data: rpApiTypes.IRPListDataResponse) => createAction(actionTypes.RP_GET_LIST_RESPONSE, data);
export const rpGetListFailure = (error: null | string) => createAction(actionTypes.RP_GET_LIST_FAILURE, error);

// GET REWARD PROGRAM DETAIL DATA
export const rpGetProgramDetailRequest = (payload: number) => createAction(actionTypes.RP_GET_PROGRAM_DETAIL_REQUEST, payload);
export const rpGetProgramDetailResponse = (data: rpApiTypes.IRPProgramDetails) => createAction(actionTypes.RP_GET_PROGRAM_DETAIL_RESPONSE, data);
export const rpGetProgramDetailFailure = (error: null | string) => createAction(actionTypes.RP_GET_PROGRAM_DETAIL_FAILURE, error);

// DELETE REWARD PROGRAM DATA
export const rpDeleteProgramRequest = (payload: rpApiTypes.IRPDeleteProgramRequest) => createAction(actionTypes.RP_DELETE_PROGRAM_DATA_REQUEST, payload);
export const rpDeleteProgramResponse = (data: rpApiTypes.IRPDeleteProgramResponse) => createAction(actionTypes.RP_DELETE_PROGRAM_DATA_RESPONSE, data);
export const rpDeleteProgramFailure = (error: { message: null | string }) => createAction(actionTypes.RP_DELETE_PROGRAM_DATA_FAILURE, error);

// GET REWARDS PROGRAM  ACHIEVERS LIST REQUEST
export const rpGetAchieversListRequest = (payload: number) => createAction(actionTypes.RP_ACHIEVERS_LIST_REQUEST, payload);
export const rpGetAchieversListResponse = (data: rpApiTypes.IRPAchieversList) => createAction(actionTypes.RP_ACHIEVERS_LIST_RESPONSE, data);
export const rpGetAchieversListFailure = (error: null | string) => createAction(actionTypes.RP_ACHIEVERS_LIST_FAILURE, error);

// REWARDS PROGRAM - EDIT PROGRAM RESEND EMAIL
export const rpEditProgramResendEmailRequest = (payload: rpApiTypes.IRPResendEmailRequest) => createAction(actionTypes.RP_EDIT_PROGRAM_RESEND_EMAIL_REQUEST, payload);
export const rpEditProgramResendEmailResponse = () => createAction(actionTypes.RP_EDIT_PROGRAM_RESEND_EMAIL_RESPONSE);
export const rpEditProgramResendEmailFailure = () => createAction(actionTypes.RP_EDIT_PROGRAM_RESEND_EMAIL_FAILURE);

// REWARDS PROGRAM - CREATE OR UPDATE PROGRAM DATA
export const rpCreateOrUpdateProgramRequest = (payload: rpApiTypes.IRpAddProgramRequest) => createAction(actionTypes.RP_CREATE_OR_UPDATE_PROGRAM_DATA_REQUEST, payload);
export const rpCreateOrUpdateProgramResponse = (data: rpApiTypes.IRPAddProgramResponse) => createAction(actionTypes.RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESPONSE, data);
export const rpCreateOrUpdateProgramFailure = (error: rpApiTypes.IRPAddProgramFailure) => createAction(actionTypes.RP_CREATE_OR_UPDATE_PROGRAM_DATA_FAILURE, error);
export const rpCreateOrUpdateProgramReset = (reset: string) => createAction(actionTypes.RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESET, reset);

// REWARDS PROGRAM - LOCATION TREE DATA
export const rpTreeDataRequest = (payload: commonProps.IAccountSwitchedObj) => createAction(actionTypes.RP_LOCATION_TREE_DATA_REQUEST, payload);
export const rpTreeDataResponse = (data: { brand_hubs_locations: commonLocationTree.ILocationTreeResponseObj }) => createAction(actionTypes.RP_LOCATION_TREE_DATA_RESPONSE, data);
export const rpTreeDataFailure = (errorData: null | string) => createAction(actionTypes.RP_LOCATION_TREE_DATA_FAILURE, errorData);

// REWARDS PROGRAM - ACTIONS REWARDS LIST
export const rpPromotionVaultListRequest = (payload: number | string | null) => createAction(actionTypes.RP_PROMOTION_VAULT_LIST_REQUEST, payload);
export const rpPromotionVaultListResponse = (data: rpApiTypes.ICPPromotionVaultResponse) => createAction(actionTypes.RP_PROMOTION_VAULT_LIST_RESPONSE, data);
export const rpPromotionVaultListFailure = (error: null | string) => createAction(actionTypes.RP_PROMOTION_VAULT_LIST_FAILURE, error);

// RP SET CREATE PROGRAM DATA OBJ
export const rpSetCreateProgramDataObj = (data: rpReducerTypes.ICreateProgramDataObj) => createAction(actionTypes.RP_SET_CP_DATA_OBJ, data);

// REWARDS PROGRAM - STATE UPDATES
export const rpCurrentActionStatusUpdates = (payload: string | null) => createAction(actionTypes.RP_CURRENT_ACTION_STATUS_UPDATES, payload);
export const rpRewardsModified = (payload: boolean) => createAction(actionTypes.RP_SET_REWARDS_MODIFIED, payload);
export const rpSetResendEmailModified = (payload: boolean) => createAction(actionTypes.RP_SET_RESEND_EMAIL_MODIFIED, payload);

export const rpSetDetailFetching = (data: boolean) => createAction(actionTypes.RP_SET_DETAIL_FETCHING, data);

export const rpDetailViewError = (error: null | string) => createAction(actionTypes.RP_DETAIL_VIEW_ERROR, error);

// REWARD PROGRAM RESET
export const rpDeleteProgramReset = () => createAction(actionTypes.RP_DELETE_PROGRAM_DATA_RESET);
export const rpResetAll = () => createAction(actionTypes.RP_RESET_ALL);

const RP_ACTIONS = {
  rpGetStatsRequest,
  rpGetStatsResponse,
  rpGetStatsFailure,
  rpGetListRequest,
  rpGetListResponse,
  rpGetListFailure,
  rpGetProgramDetailRequest,
  rpGetProgramDetailResponse,
  rpGetProgramDetailFailure,
  rpDeleteProgramRequest,
  rpDeleteProgramResponse,
  rpDeleteProgramFailure,
  rpGetAchieversListRequest,
  rpGetAchieversListResponse,
  rpGetAchieversListFailure,
  rpEditProgramResendEmailRequest,
  rpEditProgramResendEmailResponse,
  rpEditProgramResendEmailFailure,
  rpCreateOrUpdateProgramRequest,
  rpCreateOrUpdateProgramResponse,
  rpCreateOrUpdateProgramFailure,
  rpCreateOrUpdateProgramReset,
  rpTreeDataRequest,
  rpTreeDataResponse,
  rpTreeDataFailure,
  rpPromotionVaultListRequest,
  rpPromotionVaultListResponse,
  rpPromotionVaultListFailure,
  rpSetCreateProgramDataObj,
  rpCurrentActionStatusUpdates,
  rpRewardsModified,
  rpSetResendEmailModified,
  rpSetDetailFetching,
  rpDetailViewError,
  rpDeleteProgramReset,
  rpResetAll
};

export type RewardProgramsAction = ActionsUnion<typeof RP_ACTIONS>;
