import { useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ImageValidation } from 'widgets/Media';
import { IStore, postsContainerTypes } from 'types';
import { ReactSpringCountUp } from 'widgets/ReactSpring';
import { useAccountSwitcherData, useParamsDeconstructor, useSiteRestriction } from 'utils/hooks';
import { checkSiteRestriction, debounceMethod } from 'utils/helpers';
import { USER_OWNERSHIP, POSTS_STATUS_TYPE, ROUTES, POSTS_INIT_FILTER_DATA, COMMON_SOCIAL_PROVIDER_TYPE, PostsValidQueryParams, SIDE_NAV_NAME } from 'utils/constants';
import { TotalStatsCountPosts } from 'widgets/TotalStatsCount';

export const PostStats = ({ type }: postsContainerTypes.IPostsListProps) => {
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);
  const { postsRestriction } = useSiteRestriction();

  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isPostsStatsFetching = useSelector((state: IStore) => state.posts.isPostsStatsFetching);
  const postsStats = useSelector((state: IStore) => state.posts.postsStats);
  const permissions = useSelector((state: IStore) => state.accounts.permissions);
  const franchisorsPermissionList = useSelector((state: IStore) => state.franchisors.franchisorsPermissionList);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);

  const overriddenCustomPostsLocked = permissions?.franchiseePermissions?.overridden_custom_posts_locked;
  const copiedParentContentsAutoApproved = franchisorsPermissionList?.copied_parent_contents_auto_approved;

  const [searchText, setSearchText] = useState(queryParams?.text || '');

  const postsListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  const franCustomPostsLocked = !franchisorsPermissionList
    ? false
    : Boolean(franchisorsPermissionList?.custom_posts_locked)
    ? franchisorsPermissionList?.custom_posts_locked
    : franchisorsPermissionList?.franchisee_permissions.some((datum) => datum.overridden_custom_posts_locked || datum.custom_posts_locked);

  const isHidePending = checkSiteRestriction(userOwnership, menuAccess, userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'posts/awaiting_approval' : 'posts/pending');

  const handlePostsStatusFilterChange = (key: string, value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value === COMMON_SOCIAL_PROVIDER_TYPE.ALL) delete queryParamsObj[key];
    else if (value) queryParamsObj[key] = value;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  const handleCreatorNavigation = () => {
    navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  const handleInputSearch = (value: string) => {
    const queryParamsObj = { ...queryParams };
    if (value) queryParamsObj.text = value;
    else delete queryParamsObj.text;
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  return (
    <>
      {type === SIDE_NAV_NAME.CONTENT_CAMPAIGNS ? null : (
        <div className={`stats animate__animated animate__fadeIn content-post-wrp-stats pStats post-stats-main global-stats-section-wrp lpx vpy-20 g-20`}>
          <div className="stats-item mptotal total-count stats-level-03" onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.ALL)}>
            <div className="chart-module-top">
              <span className="mod-head">POSTS</span>
              {isPostsStatsFetching ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <span className="mod-count">
                  <ReactSpringCountUp end={postsStats?.totalPosts} />
                </span>
              )}
            </div>
            <div className={postsStats?.totalPosts ? `chart-container` : 'd-none'}>{isPostsStatsFetching ? null : <TotalStatsCountPosts />}</div>
          </div>
          <div
            className={`stats-item fb-sc si-approved stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.APPROVED ? ` active` : ``}`}
            onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.APPROVED)}
          >
            <div className="chart-module-top">
              <span className="platform-text">Ready</span>
              {isPostsStatsFetching ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <span className="platform-count">
                  <ReactSpringCountUp end={postsStats?.approvedPosts} />
                </span>
              )}
            </div>
            <div className="platform-icon">
              <ImageValidation isImgValid defaultImg="stats/approved" customName={'Ready'} />
            </div>
          </div>
          {isHidePending ? null : userOwnership === USER_OWNERSHIP.ACCOUNT ? (
            overriddenCustomPostsLocked && (
              <div
                className={`stats-item fb-sc si-pending stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.AWAITING_APPROVAL ? ` active` : ``}`}
                onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.AWAITING_APPROVAL)}
              >
                <div className="chart-module-top">
                  <span className="platform-text">Pending</span>
                  {isPostsStatsFetching ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <span className="platform-count">
                      <ReactSpringCountUp end={postsStats?.pendingPosts} />
                    </span>
                  )}
                </div>
                <div className="platform-icon">
                  <ImageValidation isImgValid defaultImg="stats/pending" customName={'Pending'} />
                </div>
              </div>
            )
          ) : (
            <>
              {!copiedParentContentsAutoApproved && !isContentSupplier && (
                <div className={`stats-item fb-sc si-pending p-awaiting stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.AA_MY_CONTENT ? ` active` : ``}`}>
                  <div className="chart-module-top">
                    <span className="platform-text">Approval</span>
                  </div>
                  <div className="r-flx r-flx-gap1">
                    <div className={'pending-item my-content'} onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.AA_MY_CONTENT)}>
                      <div className="count-wrp awt-cw-main">
                        {isPostsStatsFetching ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <span className="platform-count awt-pca">
                            <ReactSpringCountUp end={postsStats?.pendingPosts} />
                          </span>
                        )}
                        <div className="awt-txt-dtls">
                          <span className="awt-item-top">Awaiting</span>
                          <span className="lbl" title={'My Content'}>
                            My Content
                          </span>
                        </div>
                      </div>
                      <div className="platform-icon">
                        <ImageValidation isImgValid defaultImg="stats/pending" customName={'Pending'} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {franCustomPostsLocked && (
                <div className={`stats-item fb-sc si-pending p-awaiting stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.AA_LOCATION_CONTENT ? ` active` : ``}`}>
                  <div className="chart-module-top">
                    <span className="platform-text">Approval</span>
                  </div>
                  <div className="r-flx r-flx-gap1">
                    <div className={'pending-item loc-content'} onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.AA_LOCATION_CONTENT)}>
                      <div className="count-wrp awt-cw-main">
                        {isPostsStatsFetching ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <span className="platform-count awt-pca">
                            <ReactSpringCountUp end={postsStats?.pendingPostsLocationContent} />
                          </span>
                        )}
                        <div className="awt-txt-dtls">
                          <span className="awt-item-top">Awaiting</span>
                          <span className="lbl" title={'Location Content'}>
                            Location Content
                          </span>
                        </div>
                      </div>
                      <div className="platform-icon">
                        <ImageValidation isImgValid defaultImg="stats/pending" customName={'Pending'} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {isAISubscribed ? (
            <div className={`stats-item fb-sc si-pending p-awaiting stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.AI_GENERATED ? ` active` : ``}`}>
              <div className="chart-module-top">
                <span className="platform-text">Approval</span>
              </div>
              <div className="r-flx r-flx-gap1">
                <div className={'pending-item loc-content'} onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.AI_GENERATED)}>
                  <div className="count-wrp awt-cw-main">
                    {isPostsStatsFetching ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <span className="platform-count awt-pca">
                        <ReactSpringCountUp end={postsStats?.aiDraftPosts} />
                      </span>
                    )}
                    <div className="awt-txt-dtls">
                      <span className="awt-item-top">Awaiting</span>
                      <span className="lbl" title={'AI Generated'}>
                        AI Generated
                      </span>
                    </div>
                  </div>
                  <div className="platform-icon">
                    <ImageValidation isImgValid defaultImg="stats/pending" customName={'AI Generated'} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={`stats-item fb-sc si-draft stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.DRAFT ? ` active` : ``}`}
            onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.DRAFT)}
          >
            <div className="chart-module-top">
              <span className="platform-text">Draft</span>
              {isPostsStatsFetching ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <span className="platform-count">
                  <ReactSpringCountUp end={postsStats?.draftPosts} />
                </span>
              )}
            </div>
            <div className="platform-icon">
              <ImageValidation isImgValid defaultImg="stats/draft" customName={'Draft'} />
            </div>
          </div>
          {overriddenCustomPostsLocked && postsStats?.rejectedPosts !== undefined && (
            <div
              className={`stats-item fb-sc si-rejected stats-level-02${queryParams?.status === POSTS_STATUS_TYPE.REJECTED ? ` active` : ``}`}
              onClick={() => handlePostsStatusFilterChange(PostsValidQueryParams.STATUS, POSTS_STATUS_TYPE.REJECTED)}
            >
              <div className="chart-module-top">
                <span className="platform-text">Rejected</span>
                {isPostsStatsFetching ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <span className="platform-count">
                    <ReactSpringCountUp end={postsStats?.rejectedPosts} />
                  </span>
                )}
              </div>
              <div className="platform-icon">
                <ImageValidation isImgValid defaultImg="stats/rejected" customName={'Rejected'} />
              </div>
            </div>
          )}
          {/* {
          userOwnership === USER_OWNERSHIP.FRANCHISOR && id === topLevelFranchisorId && postsStats?.boostedPosts !== undefined &&
          <div className={`stats-item fb-sc si-boostedpost${isAvailBoostedPosts ? ` active` : ``}`}
            onClick={() => dispatch(setAvailBoostedPosts(!isAvailBoostedPosts))}>
            <div className='chart-module-top'>
              <span className='platform-text'>Boosted Post</span>
              <span className='platform-count'>
                <ReactSpringCountUp
                  end={postsStats?.boostedPosts}
                />
              </span>
            </div>
            <div className='platform-icon'>
              <ImageValidation isImgValid defaultImg="stats/boosted-post" customName={'Boosted Post'} />
            </div>
          </div>
        } */}

          {!postsRestriction?.create_post ? (
            <div className="stats-item sicp stats-level-03" onClick={() => handleCreatorNavigation()}>
              <span className="mod-head">CREATE POST</span>
            </div>
          ) : null}
        </div>
      )}
      <div className={`search-tk post-search post-list__search lpx r-mt0`}>
        <div className="react-tags">
          <div className="react-tags__search">
            <div className="react-tags__search-input">
              <input
                type="text"
                placeholder={'Search text'}
                autoComplete="off"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onKeyUp={() => (postsListApiTimeout.current = debounceMethod(searchText.trim(), postsListApiTimeout.current, handleInputSearch))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
