import { useMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { ROUTES } from 'utils/constants';
import { IStore } from 'types';
import { salesIsDemoModalOpen } from 'actions';

export const SalesDirectoryListing = () => {
  const dispatch = useDispatch();
  const directoryListingsRoute = useMatch(`${ROUTES.DIRECTORY_LISTINGS_PROFILE}/store_details`);

  const brandName = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);

  return (
    //  directorylisting  start here
    directoryListingsRoute?.pathname ? (
      <div className="rallioplatform directorylisting rallioactivate">
        <div className="content-main-section">
          <div className="left-section-main">
            <h3>
              [{brandName}] + <span>Directory Listing</span>
            </h3>
            <h4>Update your online information instantly.</h4>
            <p className="main-para-text main-para-title">Closing early? New phone number? No problem. Update instantly across the web right in your Rallio app.</p>
            <p className="main-para-text main__para--subtxt">
              With Rallio’s Directory Listings service, you can instantly update your business hours, address, phone number and other important information across the web.
            </p>
            <p className="main-para-text main__para--subtxt">
              Reach your customers around the world, across search engines, social media, voice search and mobile apps — right at the moment they’re searching for you.
            </p>
            <div className="quotation-text">
              <span className="quoto-icon">&#8220;</span>
              It just made sense for us to have our listings managed in the same suite as our social media and online review profiles. Now instead of locations reaching out to me to update their hours
              they simply open their Rallio app and make the changes themselves. We love this feature! ”
              <p className="para__author--txt">
                <span> Kristi Waite </span> Corporate Social Media Coordinator @ La Vida Massage
              </p>
              <div className="schedule-section">
                <Button onClick={() => dispatch(salesIsDemoModalOpen(true))} className="csv-btn">
                  <span>Schedule a demo</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
    //  directorylisting  end here
  );
};
