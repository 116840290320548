import { useState, useRef, useMemo } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  CORPORATE_POST_SCHEDULE_TYPE,
  FRANCHISORS_SELECTIONS,
  LOCATION_LISTS,
  LOCATIONS,
  FRAN_SCHEDULE_POST_TYPE,
  LOCATION,
  TOOLTIP_PLACEMENT,
  CAMPAIGN_ALREADY_SCHEDULED_LOC_TOOLTIP,
  USER_OWNERSHIP,
  Stage,
  REWARD_PROGRAM_LIST_TYPE,
  RECOMMEND_PROGRAM_LIST_TYPES
} from 'utils/constants';
import { IStore, commonModalPopup, franchisorApiTypes, rpApiTypes } from 'types';
import { CustomDropdownWithSearch } from 'widgets/CustomDropdown';
import { deleteItemInLocationTree, getDirectLocsForHubOrLocListRequest, getDirectLocsForHubOrLocListResponse, getFranScheduleTreeStats, setFranchisorScheduleLocType } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { Loading } from './Loading';
import { CustomTooltip } from 'widgets/Tooltip';
import { useAccountSwitcherData } from 'utils/hooks';

export const ScheduleLocationSection = ({
  isEditEnabled = true,
  selectedLocationsCount,
  scheduledLocationIds,
  isRewardProgram = false,
  setBrandHubLocData,
  showOnlyLocations
}: commonModalPopup.ICommonScheduleLocationModalProps) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const corporateAccountId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.corporate_account_id || 0);
  const dropdownList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  const locationType = useSelector((state: IStore) => state.franchisors.franScheduleTree.locationType);
  const isLocFetching = useSelector((state: IStore) => state.franchisors.franScheduleTree.isLocFetching);
  const schLocationTree = useSelector((state: IStore) => state.franchisors.franScheduleTree.schLocationTree);
  const hubOrLocStats = useSelector((state: IStore) => state.franchisors.franScheduleTree.hubOrLocStats);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const recommendedType = useSelector((state: IStore) => state.rewardPrograms.createProgramDataObj.programDetails.recommendedType || false);

  const [showAll, setShowAll] = useState<boolean>(true);

  const scheduleOptions =
    corporateAccountId && !isRewardProgram && !showOnlyLocations
      ? CORPORATE_POST_SCHEDULE_TYPE
      : userOwnership === USER_OWNERSHIP.ACCOUNT
      ? isRewardProgram
        ? RECOMMEND_PROGRAM_LIST_TYPES.filter((it) => it.name === FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS)
        : CORPORATE_POST_SCHEDULE_TYPE.filter((it) => it.name === FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS)
      : recommendedType === Stage.RECOMMENDED_PROGRAM
      ? RECOMMEND_PROGRAM_LIST_TYPES
      : showOnlyLocations
      ? RECOMMEND_PROGRAM_LIST_TYPES.filter((it) => it.name === FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS)
      : REWARD_PROGRAM_LIST_TYPE;

  const dropdownRef = useRef(null);

  const getSelectedLocationsCount = (locationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[]) => {
    const selectedHubAndLocation: rpApiTypes.IRPProgramLocationData = { franchisors: [], accounts: [], accountLists: [], selectedLocationCount: 0, isAllLocation: false };
    locationTree.forEach((data) => {
      const isAllLocationsSelected = !data.locations?.some((child) => !child.active);
      if (data.type === FRANCHISORS_SELECTIONS) {
        if (isAllLocationsSelected) {
          selectedHubAndLocation.franchisors = [...selectedHubAndLocation.franchisors, data];
          selectedHubAndLocation.selectedLocationCount = selectedHubAndLocation.selectedLocationCount += data?.locations?.length || 0;
        } else {
          const accountObjs = data.locations?.filter((location) => location.active) || [];
          selectedHubAndLocation.accounts = [...selectedHubAndLocation.accounts, ...accountObjs];
          selectedHubAndLocation.selectedLocationCount = selectedHubAndLocation.selectedLocationCount += accountObjs.length || 0;
        }
      } else if (data.type === LOCATION_LISTS) {
        if (isAllLocationsSelected) {
          selectedHubAndLocation.accountLists = [...selectedHubAndLocation.accountLists, data];
          selectedHubAndLocation.selectedLocationCount = selectedHubAndLocation.selectedLocationCount += data?.locations?.length || 0;
        } else {
          const accountObjs = data.locations?.filter((location) => location.active) || [];
          selectedHubAndLocation.accounts = [...selectedHubAndLocation.accounts, ...accountObjs];
          selectedHubAndLocation.selectedLocationCount = selectedHubAndLocation.selectedLocationCount += accountObjs.length || 0;
        }
      } else {
        selectedHubAndLocation.accounts = [...selectedHubAndLocation.accounts, data];
        selectedHubAndLocation.selectedLocationCount = selectedHubAndLocation.selectedLocationCount += 1;
      }
    });

    return selectedHubAndLocation;
  };

  const dropdownOptions = useMemo(() => {
    return isRewardProgram && userOwnership === USER_OWNERSHIP.ACCOUNT
      ? [
          {
            label: FRANCHISORS_SELECTIONS,
            options: []
          },
          {
            label: LOCATION_LISTS,
            options: []
          },
          {
            label: LOCATIONS,
            options:
              isRewardProgram && userOwnership === USER_OWNERSHIP.ACCOUNT
                ? [{ id: currentAccountOrFranchisor?.id, value: currentAccountOrFranchisor?.name, label: currentAccountOrFranchisor?.name, type: 'Location' }]
                : []
          }
        ]
      : recommendedType === Stage.RECOMMENDED_PROGRAM
      ? [
          {
            label: FRANCHISORS_SELECTIONS,
            options: dropdownList.hubList || []
          },
          {
            label: LOCATIONS,
            options: dropdownList.onlyLocationList || []
          }
        ]
      : showOnlyLocations
      ? [
          {
            label: LOCATIONS,
            options: dropdownList.onlyLocationList || []
          }
        ]
      : [
          {
            label: FRANCHISORS_SELECTIONS,
            options: dropdownList.hubList || []
          },
          {
            label: LOCATION_LISTS,
            options: dropdownList.locationList || []
          },
          {
            label: LOCATIONS,
            options: dropdownList.onlyLocationList || []
          }
        ];
  }, [dropdownList, currentAccountOrFranchisor, isRewardProgram, userOwnership, recommendedType, showOnlyLocations]);

  const totalDropdownListCount = useMemo(() => {
    return Object.values(dropdownList).reduce((acc, curr) => {
      if (curr.length) acc += curr?.length || 0;
      return acc;
    }, 0);
  }, [dropdownList]);

  const handleLocationTabSelection = (selectedKey: null | string) => {
    dispatch(setFranchisorScheduleLocType(selectedKey));
    if (setBrandHubLocData) {
      setBrandHubLocData({ franchisors: [], accounts: [], accountLists: [], selectedLocationCount: 0, isAllLocation: false });
    }
  };

  const handleAddOrUpdateFranDirectLocsObj = (updatedData: franchisorApiTypes.IDirectLocsFromHubOrListData) => {
    dispatch(getDirectLocsForHubOrLocListResponse({ hubOrListData: updatedData }));
    if (setBrandHubLocData) {
      setBrandHubLocData(getSelectedLocationsCount(schLocationTree));
    }
  };

  const handleSelectLocations = (item: franchisorApiTypes.IDirectLocsFromHubOrListData, idx: number, currentLocActive?: boolean) => {
    (item?.locations || [])[idx].active = !currentLocActive;
    handleAddOrUpdateFranDirectLocsObj(item);
  };

  const handleDeleteItem = (deleteData: franchisorApiTypes.IDeleteItemInLocationTree) => {
    dispatch(deleteItemInLocationTree(deleteData));
    const filteredLocationTree = schLocationTree.filter((location) => location.id !== deleteData.deleteId);
    if (setBrandHubLocData) {
      setBrandHubLocData(getSelectedLocationsCount(filteredLocationTree));
    }
  };

  const isLocationAlreadyScheduled = (locationId: number) => {
    return scheduledLocationIds?.includes(locationId);
  };

  const renderHubOrListLocation = (parentItem: franchisorApiTypes.IDirectLocsFromHubOrListData, locItem: franchisorApiTypes.IDirectLocsObj, type: string, idx: number) => {
    const isDisabled = isLocationAlreadyScheduled(locItem.id);
    return (
      <li className={`${locItem?.active ? 'active' : ''}${isDisabled ? ` button-opacity` : ''}`} key={`${type}-${idx}`}>
        <div className="scv-sub-items" onClick={() => (isDisabled ? false : handleSelectLocations(parentItem, idx, locItem.active))}>
          <span className="checkbox-n" />
          {isDisabled ? (
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.RIGHT}
              customClassname={'custom-tooltip-long-text'}
              customTooltipText={CAMPAIGN_ALREADY_SCHEDULED_LOC_TOOLTIP}
              customInput={() => (
                <div className="blh-type">
                  <ImageValidation isImgValid defaultImg="location-sub-dark-grey" customName={'selected-type'} customClassname={'blh-asset'} />
                  <span className="blh-head-txt">{locItem.name}</span>
                </div>
              )}
            />
          ) : (
            <div className="blh-type">
              <ImageValidation isImgValid defaultImg="location-sub-dark-grey" customName={'selected-type'} customClassname={'blh-asset'} />
              <span className="blh-head-txt">{locItem.name}</span>
            </div>
          )}
        </div>
      </li>
    );
  };

  const renderIndividualLocations = (item: franchisorApiTypes.IDirectLocsFromHubOrListData, index: number) => {
    const isDisabled = isLocationAlreadyScheduled(item.id);
    return (
      <div className="blh-item scv-hub" key={`post-loc-item-${index}`}>
        <div className="blh-head">
          {isDisabled ? (
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.RIGHT}
              customClassname={'custom-tooltip-long-text'}
              customTooltipText={CAMPAIGN_ALREADY_SCHEDULED_LOC_TOOLTIP}
              customInput={() => (
                <div className="blh-left button-opacity">
                  <div className="blh-type">
                    <ImageValidation isImgValid defaultImg="location-mild-grey" customName={'selected-type'} customClassname={'blh-asset'} />
                    <span className="blh-head-txt">{item.name}</span>
                  </div>
                </div>
              )}
            />
          ) : (
            <div className="blh-left">
              <div className="blh-type">
                <ImageValidation isImgValid defaultImg="location-mild-grey" customName={'selected-type'} customClassname={'blh-asset'} />
                <span className="blh-head-txt">{item.name}</span>
              </div>
            </div>
          )}
          <div className="glbl__round--delete">
            <ImageValidation isImgValid defaultImg="remove-red" customName={'remove-type'} customClassname={'remove-sp'} onClick={() => handleDeleteItem({ deleteId: item.id })} />
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedHubs = () => {
    return schLocationTree.map((item, index) => {
      return item?.type === FRANCHISORS_SELECTIONS ? (
        <div className={`blh-item scv-brand${item.open ? ` open` : ''}`} key={`post-hub-item-${index}`}>
          <div className="blh-head">
            <div className="blh-left">
              <span className="tog-arw" onClick={() => handleAddOrUpdateFranDirectLocsObj({ ...item, open: !item.open })} />
              <div className="blh-type">
                <ImageValidation isImgValid defaultImg="rc-hub" customName={'selected-type'} customClassname={'blh-asset'} />
                <span className="blh-head-txt">{item.name}</span>
              </div>
            </div>
            <div className="glbl__round--delete">
              <ImageValidation isImgValid defaultImg="remove-red" customName={'remove-type'} customClassname={'remove-sp'} onClick={() => handleDeleteItem({ deleteId: item.id, type: item.type })} />
            </div>
          </div>
          <div className="blh-body">
            <ul>
              {item?.locations?.map((locItem, idx) => {
                return renderHubOrListLocation(item, locItem, 'hub-loc', idx);
              })}
            </ul>
          </div>
        </div>
      ) : null;
    });
  };

  const renderSelectedLocationLists = () => {
    return schLocationTree.map((item, index) => {
      return item?.type === LOCATION_LISTS ? (
        <div className={`blh-item scv-location${item.open ? ` open` : ''}`} key={`post-acc-list-item-${index}`}>
          <div className="blh-head">
            <div className="blh-left">
              <span className="tog-arw" onClick={() => handleAddOrUpdateFranDirectLocsObj({ ...item, open: !item.open })} />
              <div className="blh-type">
                <ImageValidation isImgValid defaultImg="location-que-trans" customName={'selected-type'} customClassname={'blh-asset'} />
                <span className="blh-head-txt">{item.name}</span>
              </div>
            </div>
            <div className="glbl__round--delete">
              <ImageValidation isImgValid defaultImg="remove-red" customName={'remove-type'} customClassname={'remove-sp'} onClick={() => handleDeleteItem({ deleteId: item.id, type: item.type })} />
            </div>
          </div>
          <div className="blh-body">
            <ul>
              {item?.locations?.map((locItem, idx) => {
                return renderHubOrListLocation(item, locItem, 'acc-list-loc', idx);
              })}
            </ul>
          </div>
        </div>
      ) : null;
    });
  };

  const renderSelectedIndividualLocations = () => {
    return schLocationTree.map((item, index) => {
      return !item?.type ? renderIndividualLocations(item, index) : null;
    });
  };

  return (
    <Tabs activeKey={locationType} onSelect={(selectedKey) => locationType !== selectedKey && handleLocationTabSelection(selectedKey)} className={isEditEnabled ? '' : 'pointer-events-none'}>
      {scheduleOptions.map((optionItem, index) => {
        return (
          <Tab
            key={`schedule-option${index}`}
            eventKey={optionItem.name}
            title={
              <div>
                <Form.Group className="form-group" controlId={`formBasicCheckbox${index + 1}`}>
                  <Form.Check type="radio" label={optionItem.label} checked={locationType === optionItem.name} readOnly />
                </Form.Group>
              </div>
            }
          >
            {[FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS, FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCATIONS].includes(locationType) ? (
              <div className="spn-wrap">
                <div className={`form-group form-field-item ${isEditEnabled ? '' : 'pointer-events-none'}`}>
                  <CustomDropdownWithSearch
                    dropdownRef={dropdownRef}
                    classNamePrefix="rs-drp"
                    id="select-locations-dropdown"
                    placeholder="Select Locations"
                    onChange={(event: any) => {
                      if (event.id && event.type === LOCATION) {
                        dispatch(getDirectLocsForHubOrLocListResponse({ includedAccounts: [{ id: Number(event.id), name: event.label }] }));
                      } else {
                        dispatch(
                          getFranScheduleTreeStats({
                            hubs: event.type === FRANCHISORS_SELECTIONS ? hubOrLocStats.hubs + 1 : hubOrLocStats.hubs || 0,
                            locationLists: event.type === LOCATION_LISTS ? hubOrLocStats?.locationLists + 1 : hubOrLocStats.locationLists || 0
                          })
                        );
                        dispatch(getDirectLocsForHubOrLocListRequest({ id: Number(event.id), type: event.type, scheduledLocationIds }));
                      }
                      if (setBrandHubLocData) {
                        const locType = event.type === FRANCHISORS_SELECTIONS ? 'franchisors' : event.type === LOCATION_LISTS ? 'accountLists' : 'accounts';
                        setBrandHubLocData((prev) => {
                          const modifiedLocaitons = [...new Set([...prev[locType], { id: event.id, name: event.label }].map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
                          return {
                            ...prev,
                            [locType]: modifiedLocaitons,
                            selectedLocationCount: (prev.selectedLocationCount += prev[locType].map((location) => location.id).includes(event?.id)
                              ? 0
                              : event.type === LOCATION
                              ? 1
                              : event?.locationsCount)
                          };
                        });
                      }
                    }}
                    value={{}}
                    options={dropdownOptions}
                    isSearchable={totalDropdownListCount > 1}
                    menuIsOpen
                  />
                </div>
                {schLocationTree?.length ? (
                  <div className="sc-viewwraps">
                    {isLocFetching ? <Loading isSmall /> : null}
                    <div className="scv-top">
                      <div className="svt-left g-10">
                        <h3 className="glbl__title--txt">Selected</h3>
                        <span>
                          ({selectedLocationsCount()} Location{selectedLocationsCount() > 1 ? 's' : ''})
                        </span>
                      </div>
                      <button type="button" className="gnrc-btn transparent">
                        <span onClick={() => setShowAll(!showAll)}>{!showAll ? 'Show All' : 'Hide All'}</span>
                      </button>
                    </div>
                    <div className={`scv-base${isLocFetching || !isEditEnabled ? ` pointer-events-none` : ''}${!showAll ? ` hide-all` : ``}`}>
                      <div className="blh-group">
                        {renderSelectedHubs()}
                        {renderSelectedLocationLists()}
                        {renderSelectedIndividualLocations()}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </Tab>
        );
      })}
    </Tabs>
  );
};
