import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { ALERT_BOX_TYPES, ALERT_BOX_TYPES_INIT_SINGLE_TOAST } from 'utils/constants';
import { commonAlertBox } from 'types/common';

const AlertBox = ({ message }: commonAlertBox.IAlertBox) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <span className="success-mess-txt">{message}</span>
      </Col>
    </Row>
  );
};

export const alertBoxCall = (type: string, message: string | null, singleToastObj: Partial<commonAlertBox.IAlertBoxSingleToastObj> = ALERT_BOX_TYPES_INIT_SINGLE_TOAST) => {
  switch (type) {
    case ALERT_BOX_TYPES.SUCCESS:
      toast.success(<AlertBox type={type} message={message} />, {
        ...(singleToastObj?.success && { toastId: ALERT_BOX_TYPES.SUCCESS }),
        position: toast.POSITION.TOP_CENTER,
        icon: false,
        closeButton: false
      });
      break;
    case ALERT_BOX_TYPES.INFO:
      toast.info(<AlertBox type={type} message={message} />, {
        ...(singleToastObj?.info && { toastId: ALERT_BOX_TYPES.INFO }),
        position: toast.POSITION.TOP_CENTER,
        icon: false,
        closeButton: false
      });
      break;
    case ALERT_BOX_TYPES.WARN:
      toast.warn(<AlertBox type={type} message={message} />, {
        ...(singleToastObj?.warn && { toastId: ALERT_BOX_TYPES.WARN }),
        position: toast.POSITION.TOP_CENTER,
        icon: false,
        closeButton: false
      });
      break;
    case ALERT_BOX_TYPES.ERROR:
      toast.error(<AlertBox type={type} message={message} />, {
        ...(singleToastObj?.error && { toastId: ALERT_BOX_TYPES.ERROR }),
        position: toast.POSITION.TOP_CENTER,
        icon: false,
        closeButton: false
      });
      break;
    case ALERT_BOX_TYPES.DISMISS:
      toast.dismiss();
      break;
    default:
      break;
  }
};
