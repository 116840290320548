import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { DirectoryListingsAction } from 'actions';
import { directoryListingsReducerTypes } from 'types';
import {
  DL_UPDATED_SUCCESSFULLY,
  DL_UPDATED_FAILED,
  STORE_DETAILS_INITIAL_VALUE,
  UPDATE_DL_INITIAL_STATUS,
  DL_BUSINESS_HOUR_INIT_TIME_SLOT_OBJ,
  DL_BUSINESS_HOUR_WARNINGS,
  DL_SPECIAL_HOUR_WARNING
} from 'utils/constants';
import { getUpdatedBusinessPhotoList, getInitialStoreDetails } from 'utils/helpers';

const initialState: directoryListingsReducerTypes.IDirectoryListingsReducer = {
  error: null,
  isSynupFetching: false,
  isAddPhotosFetching: false,
  categories: [],
  synupListings: [],
  storeDetails: STORE_DETAILS_INITIAL_VALUE,
  dlUpdateDetail: UPDATE_DL_INITIAL_STATUS,
  businessPhotos: [],
  businessHours: [],
  specialHours: [],
  businessTimeSlots: DL_BUSINESS_HOUR_INIT_TIME_SLOT_OBJ,
  businessHoursWarning: DL_BUSINESS_HOUR_WARNINGS,
  specialHoursWarning: DL_SPECIAL_HOUR_WARNING
};

const directoryListingsReducer: Reducer<directoryListingsReducerTypes.IDirectoryListingsReducer, DirectoryListingsAction> = (
  state: directoryListingsReducerTypes.IDirectoryListingsReducer = initialState,
  action: DirectoryListingsAction
): directoryListingsReducerTypes.IDirectoryListingsReducer => {
  switch (action.type) {
    // GET DL CATEGORIES
    case types.GET_DL_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null
      };
    case types.GET_DL_CATEGORIES_RESPONSE:
      return {
        ...state,
        categories: action.payload,
        error: null
      };
    case types.GET_DL_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        categories: []
      };
    // GET DL SYNUP SUB CATEGORIES LIST
    case types.DL_SYNUP_LISTING_REQUEST:
      return {
        ...state,
        error: null,
        isSynupFetching: true
      };
    case types.DL_SYNUP_LISTING_RESPONSE:
      return {
        ...state,
        error: null,
        isSynupFetching: false,
        synupListings: action.payload
      };
    case types.DL_SYNUP_LISTING_FAILURE:
      return {
        ...state,
        error: action.payload,
        isSynupFetching: false,
        synupListings: []
      };
    // DL - UPDATE DIRECTORY LISTING
    case types.DL_UPDATE_REQUEST:
      return {
        ...state,
        dlUpdateDetail: {
          isUpdated: true,
          updateSuccessMsg: UPDATE_DL_INITIAL_STATUS.updateSuccessMsg,
          updateFailureMsg: UPDATE_DL_INITIAL_STATUS.updateFailureMsg
        }
      };
    case types.DL_UPDATE_RESPONSE:
      return {
        ...state,
        storeDetails: getInitialStoreDetails(action.payload),
        dlUpdateDetail: {
          ...state.dlUpdateDetail,
          isUpdated: true,
          updateSuccessMsg: DL_UPDATED_SUCCESSFULLY
        }
      };
    case types.DL_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        dlUpdateDetail: {
          ...state.dlUpdateDetail,
          isUpdated: false,
          updateFailureMsg: DL_UPDATED_FAILED
        }
      };
    // DL - ADD BUSINESS PHOTO IN CLOUDINARY
    case types.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_REQUEST:
      return {
        ...state,
        isAddPhotosFetching: true
      };
    case types.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_RESPONSE:
      return {
        ...state,
        isAddPhotosFetching: false,
        businessPhotos: getUpdatedBusinessPhotoList(state.businessPhotos, action.payload.imageKey, action.payload.cloudinaryId, action.payload.secureUrl)
      };
    case types.DL_ADD_BUSINESS_PHOTO_IN_CLOUD_FAILURE:
      return {
        ...state,
        isAddPhotosFetching: false,
        error: action.payload
      };
    // DL - SET DL-BUSINESS PHOTO
    case types.DL_SET_BUSINESS_PHOTO:
      return {
        ...state,
        businessPhotos: action.payload
      };
    // DL - SET DL-BUSINESS HOURS
    case types.DL_SET_BUSINESS_HOURS:
      return {
        ...state,
        businessHours: action.payload
      };
    // DL - SET DL-BUSINESS SPECIAL HOURS
    case types.DL_SET_BUSINESS_SPECIAL_HOURS:
      return {
        ...state,
        specialHours: action.payload
      };
    // DL - SET DL SPECIAL HOURS LOGIN
    case types.DL_SET_SPECIAL_HOURS_WARNINGS:
      return {
        ...state,
        specialHoursWarning: action.payload
      };
    // DL - SET DL-BUSINESS HOURS TIME SLOT
    case types.DL_SET_BUSINESS_HOURS_TIME_SLOT:
      return {
        ...state,
        businessTimeSlots: action.payload
      };
    // DL - SET STORES DETAIL STATES
    case types.DL_SET_STORE_DETAILS:
      return {
        ...state,
        storeDetails: action.payload
      };
    // DL - SET DL-UPDATE STATES
    case types.DL_SET_UPDATE_STATUS:
      return {
        ...state,
        dlUpdateDetail: action.payload
      };
    // DL - SET DL BUSINESS HOURS LOGIN
    case types.DL_SET_BUSINESS_HOURS_WARNINGS:
      return {
        ...state,
        businessHoursWarning: action.payload
      };
    // DL RESET ALL
    case types.DL_RESET_ALL:
      return { ...initialState };

    default:
      return state;
  }
};

export { directoryListingsReducer };
