import { IProfileAnalyticsData, IProfileAnalyticsRequest } from 'analytics/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

// REVIEW ANALYTICS - GET REVIEW ANALYTICS
export const getProfileAnalyticsRequest = (payload: IProfileAnalyticsRequest) => createAction(actionTypes.GET_PROFILE_ANALYTICS_REQUEST, payload);
export const getProfileAnalyticsResponse = (response: IProfileAnalyticsData) => createAction(actionTypes.GET_PROFILE_ANALYTICS_RESPONSE, response);
export const getProfileAnalyticsFailure = (error: null | string) => createAction(actionTypes.GET_PROFILE_ANALYTICS_FAILURE, error);

export const resetProfileState = () => createAction(actionTypes.RESET_PROFILE_STATE);

const PROFILE_ANALYTICS = {
  getProfileAnalyticsRequest,
  getProfileAnalyticsResponse,
  getProfileAnalyticsFailure,
  resetProfileState
};

export type ProfileAnalyticsActions = ActionsUnion<typeof PROFILE_ANALYTICS>;
