import { useMemo, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';

import { IGoogleBusinessAccount } from 'types/settings-social-profile/settings-social-profile-reducer';
import { getInitials } from 'utils/helpers';

export const GoogleAccountPopup = (props: {
  accountDetailsData: IGoogleBusinessAccount[];
  handleGoogleAccountSelect: (pageId: string) => void;
  locationsList: Array<any>;
  handleConnect: (locationObject: any) => void;
  selectedObj: IGoogleBusinessAccount | null;
  setSelectedObj: (obj: IGoogleBusinessAccount | null) => void;
}) => {
  const { accountDetailsData, handleGoogleAccountSelect, locationsList, handleConnect, selectedObj, setSelectedObj } = props;
  const [search, setSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');

  const filteredAccountDetails = useMemo(() => {
    if (search) {
      return accountDetailsData?.filter((it: any) => it.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    }
    return accountDetailsData;
  }, [accountDetailsData, search]);

  const filteredLocationDetails = useMemo(() => {
    if (locationsList.length && locationSearch) {
      return locationsList.filter(
        (it: any) =>
          it.name.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1 ||
          it.address.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1 ||
          it.location_id.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1 ||
          (it?.store_code && it?.store_code.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1)
      );
    }
    return locationsList;
  }, [locationsList, locationSearch]);

  const handleSelect = (accountObj: IGoogleBusinessAccount) => {
    setSelectedObj(accountObj);
    handleGoogleAccountSelect(accountObj.id);
  };

  const AccountCard = ({ dataItem, idx }: { dataItem: IGoogleBusinessAccount; idx: number }) => {
    const fullName = dataItem.name;
    return (
      <div className={`aps-item gmb-item`}>
        <div className="aps-content content-wrp ">
          <div className="r-flx r-flx-ac">
            <div className="aps-left">{getInitials(fullName)}</div>
            <div className="aps-right r-ml2">
              <div className="apsr-sn-details">
                <label>
                  <input type="radio" className="radio-checkbox" value={dataItem.id} id={dataItem.id} name="social_medium[facebook_page_credential_id]" defaultChecked={idx === 0 ? true : false} />
                  <label htmlFor={dataItem.id} />
                </label>
                <div className="nac-cnt">
                  <div className="nac-txt">{fullName}</div>
                </div>
              </div>
            </div>
          </div>
          {selectedObj ? (
            <button className="change" onClick={() => setSelectedObj(null)}>
              Change
            </button>
          ) : (
            <Button className="primeblueaction" onClick={() => handleSelect(dataItem)}>
              {' '}
              Select
            </Button>
          )}
        </div>
      </div>
    );
  };

  return !selectedObj ? (
    <>
      <FormControl placeholder="Search Accounts" value={search} onChange={(e) => setSearch(e.target.value)} className="s-fb-search" />
      {filteredAccountDetails && filteredAccountDetails?.length > 0
        ? filteredAccountDetails?.map((dataItem: IGoogleBusinessAccount, idx: number) => <AccountCard dataItem={dataItem} idx={idx} key={idx} />)
        : 'Accounts not found.'}
    </>
  ) : (
    <>
      <AccountCard dataItem={selectedObj} idx={0} />
      <FormControl placeholder="Search Locations" value={locationSearch} onChange={(e) => setLocationSearch(e.target.value)} className="s-fb-search" />
      {filteredLocationDetails && filteredLocationDetails?.length > 0
        ? filteredLocationDetails.map((dataItem: any, index: number) => (
            <div key={index} className={`aps-item gmb-selected-locations`}>
              <div className="aps-content content-wrp">
                <div className="r-flx r-flx-ac">
                  <div className="aps-right">
                    <div className="apsr-sn-details">
                      <label>
                        <input
                          type="radio"
                          className="radio-checkbox"
                          value={dataItem.id}
                          id={dataItem.id}
                          name="social_medium[facebook_page_credential_id]"
                          defaultChecked={index === 0 ? true : false}
                        />
                        <label htmlFor={dataItem.id} />
                      </label>
                      <div className="r-flx">
                        <div className="left-sec">
                          <span className="store-id">{dataItem.store_code}</span>
                        </div>
                        <div className="right-sec">
                          <div className="r-field-g">
                            <div className="lbl">Location Name:</div>
                            <div className="value loc-name">{dataItem.name}</div>
                          </div>
                          <div className="r-field-g">
                            <div className="lbl">Address:</div>
                            <div className="value">{dataItem.address}</div>
                          </div>
                          <div className="r-field-g">
                            <div className="lbl">Location ID#:</div>
                            <div className="value">{dataItem.location_id}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button className="primeblueaction" onClick={() => handleConnect(dataItem)}>
                  Connect Now
                </Button>
              </div>
            </div>
          ))
        : 'Sorry: This Google My Business Account contains no locations.'}
    </>
  );
};
