import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';

import { commonWidgetTypes } from 'types';
import { BREAK_POINT_PROFILE_IMAGERY } from 'utils/constants';

export const InfiniteScrollCardList = <T,>({ data, children, loader = null, itemCount = 20, useMasonaryGrid = false, childComp, scrollTarget, next }: commonWidgetTypes.IInfinityCardListProps<T>) => {
  const [page, setPage] = useState<commonWidgetTypes.ICardListPagination<T>>({
    currentPage: 1,
    totalPage: Math.ceil(data.length / itemCount),
    activeData: data.slice(0, itemCount)
  });

  useEffect(() => {
    setPage({
      currentPage: 1,
      totalPage: Math.ceil(data.length / itemCount),
      activeData: data.slice(0, itemCount)
    });
    if (next) {
      next();
    }
  }, [data, itemCount, next]);

  return (
    <InfiniteScroll
      next={() => {
        if (next) {
          next();
        }
        const currentPage = page.currentPage + 1;
        setPage({
          ...page,
          currentPage,
          activeData: page.activeData.concat(data.slice((currentPage - 1) * itemCount, (currentPage - 1) * itemCount + itemCount))
        });
      }}
      hasMore={page.totalPage >= page.currentPage}
      dataLength={page.activeData.length}
      loader={loader}
      className="local-ini"
      scrollableTarget={scrollTarget || 'scrollTarget'}
    >
      {childComp ? (
        useMasonaryGrid ? (
          <Masonry breakpointCols={BREAK_POINT_PROFILE_IMAGERY} className="masonry-grid media-grid" columnClassName="masonry-grid_column">
            {page.activeData.map((data, index) => childComp(data, index))}
          </Masonry>
        ) : (
          <div>{page.activeData.map((data, index) => childComp(data, index))}</div>
        )
      ) : (
        children
      )}
    </InfiniteScroll>
  );
};
