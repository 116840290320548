import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { APP_TITLE, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, VALID_APP_URL_TITLES } from 'utils/constants';
import { useParamsValidator, useRightToTop } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const DLProfileNotification = () => {
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  useParamsValidator(CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ);
  useRightToTop();

  return (
    <section className="item-g notification-cnt dl-filter-notify">
      <div className="dl-info-card">
        <ImageValidation isImgValid defaultImg={'info-card-banner'} customClassname={'banner-img'} customName={'Banner Image'} />
        <p className="banner-text">
          People are searching for you all across the internet. They want to know where to find you, how to get in touch with you, or when you're open. With Directory Listings, you can update all of
          that information and more with the click of a button!
        </p>
        {!VALID_APP_URL_TITLES.includes(APP_TITLE) && (
          <p className="banner-text">
            <strong>Have questions?</strong>
            <br />
            Reach out to {accountDetails?.account?.directory_listings_feature_enabled ? <a href="mailto: support@rallio.com">support@rallio.com</a> : 'support@rallio.com.'}
          </p>
        )}
      </div>
    </section>
  );
};
