import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { IStore } from 'types';
import { IPlatformFilter } from 'types/common/filter';
import { AI_POSTS_FILTER_TYPE, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, USER_OWNERSHIP, COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { getPostFavTagsFromQueryParams } from 'utils/helpers';
import {
  ContentAnalyticsFilterValue,
  ContentPlatformValue,
  CONTENT_ANALYTICS_FILTER,
  CONTENT_ANALYTICS_FILTER_INITIAL_VALUE,
  CONTENT_ANALYTICS_TABLE_HEADERS,
  CONTENT_ENGAGEMENT_FILTER,
  CONTENT_IMPRESSION_FILTER,
  ContetnAnalyticsValidQueryParams,
  TABLE_TYPE,
  TAGS_CONTENT_ANALYTICS_TABLE_HEADERS,
  VALID_MULTIPLE_LOCATION_ROUTES
} from '../../constants';

export const useContentAnalyticsFilterParams = (userOwnership: string, optionalParams: string[], availablePlatform: IPlatformFilter[], franchisorFullAccess: boolean) => {
  const isHubUser = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  const isMultipleLocationEnabled = VALID_MULTIPLE_LOCATION_ROUTES.some((it) => it.route === optionalParams[0]) && +optionalParams[1] > 0;
  const multipleLocationId = isMultipleLocationEnabled ? +optionalParams[1] : 0;

  const favTagsList = useSelector((state: IStore) => state.mediaGallery.favTagsList);

  const filterInitalValue = { ...CONTENT_ANALYTICS_FILTER_INITIAL_VALUE, ...{ platform: isHubUser ? ContentPlatformValue.FACEBOOK : ContentPlatformValue.ALL } };

  const { queryParams, startDate, endDate, filter } = useParamsDeconstructor(filterInitalValue);

  const isReelsEnabled =
    availablePlatform.find((platform) =>
      [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(
        platform.value || ''
      )
    ) ?? false;

  const isStoriesEnabled =
    availablePlatform.find((platform) =>
      [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(platform.value || '')
    ) ?? false;

  const filteredTableType = useMemo(() => {
    if (!isReelsEnabled) return TABLE_TYPE.filter((type) => !['reels', 'stories'].includes(type.value));
    else if (!isStoriesEnabled) return TABLE_TYPE.filter((type) => !['stories'].includes(type.value));
    else return TABLE_TYPE;
  }, [isReelsEnabled, isStoriesEnabled]);

  const contentFilter = {
    ...filter,
    engagement: queryParams?.engagement || 'all',
    impressions: queryParams?.impressions || 'all',
    analytics: queryParams?.analytics || ContentAnalyticsFilterValue.HUB,
    type: queryParams?.type || TABLE_TYPE[0].value,
    ai_post: queryParams?.ai_post || AI_POSTS_FILTER_TYPE[0].value,
    tags_list: queryParams?.tags_list ? getPostFavTagsFromQueryParams(favTagsList, queryParams?.tags_list || '') : []
  };

  const tagsHeaderConst =
    queryParams?.analytics === 'local_post' || !isHubUser
      ? TAGS_CONTENT_ANALYTICS_TABLE_HEADERS.map((it) => {
          if (it.id === 2) {
            return {
              ...it,
              value: 'count',
              sortValue: 'count'
            };
          } else return it;
        })
      : TAGS_CONTENT_ANALYTICS_TABLE_HEADERS;

  const validQueryParamsKeys = {
    ...ContetnAnalyticsValidQueryParams,
    ...(queryParams?.analytics !== ContentAnalyticsFilterValue.LOCAL_POST ? { HUB_ID: 'hub_id', LOCATION_LIST_ID: 'location_list_id', LOCATION_ID: 'location_id' } : {}),
    ...(multipleLocationId ? { HUB_ID: 'hub_id' } : {}),
    ...(!multipleLocationId ? { ANALYTICS: 'analytics', TABLE_TYPE: 'type' } : {}),
    ...(!multipleLocationId ? { TAGS_LIST: 'tags_list' } : {})
  };

  const sortByParams = () => {
    /*  if (userOwnership === USER_OWNERSHIP.ACCOUNT && queryParams?.type !== 'tags') {
      return CONTENT_ANALYTICS_TABLE_HEADERS.map((it) => it.value || '');
    } else  */
    if (queryParams.type === 'tags') {
      return tagsHeaderConst.map((it) => it.sortValue || '');
    }
    /* else if (!queryParams.analytics) {
      return CONTENT_ANALYTICS_TABLE_HEADERS.filter((it) => it?.sortValue !== 'rate' && it?.sortValue !== 'boost')
        .map((it) => it.value || '')
        .concat('boost');
    } else if (multipleLocationId && !queryParams.analytics) {
      return CONTENT_ANALYTICS_TABLE_HEADERS.filter((it) => it?.sortValue !== 'boost' && it?.sortValue !== 'rate').map((it) => it.value || '');
    } */
    return CONTENT_ANALYTICS_TABLE_HEADERS.map((it) => it.value || '');
  };

  useParamsValidator(
    { ...CommonValidQueryParams, ...validQueryParamsKeys },
    {
      ...COMMON_VALID_QUERY_PARAMS_OBJ,
      platform: availablePlatform.filter((it) => it?.value !== 'all').map((it) => it?.value || ''),
      ...(!isHubUser ? {} : { analytics: franchisorFullAccess ? [ContentAnalyticsFilterValue.LOCAL_POST] : [] }),
      engagement: CONTENT_ENGAGEMENT_FILTER.map((it) => it.value),
      impressions: CONTENT_IMPRESSION_FILTER.map((it) => it.value),
      sort_by: sortByParams(),
      ascending: ['1'],
      type: ['tags', 'reels', 'stories']
    }
  );

  const checkBoxFilter = [
    ...(isHubUser && !multipleLocationId
      ? [
          {
            title: 'Post Source',
            filter: franchisorFullAccess ? CONTENT_ANALYTICS_FILTER : CONTENT_ANALYTICS_FILTER.filter((it) => it.value === ContentAnalyticsFilterValue.HUB),
            value: 'analytics'
          }
        ]
      : []),
    ...(!multipleLocationId ? [{ title: 'Type', filter: filteredTableType, value: 'type' }] : []),
    /*  ...[
      {
        title: 'AI Type',
        filter: AI_POSTS_FILTER_TYPE.map((it) => ({
          value: it.value,
          label: it.label
        })),
        value: 'ai_post'
      }
    ], */
    {
      title: 'Engagement Type',
      filter: CONTENT_ENGAGEMENT_FILTER,
      value: 'engagement'
    },
    ...(!['reels', 'stories'].includes(queryParams.type)
      ? [
          {
            title: 'Impressions',
            filter: CONTENT_IMPRESSION_FILTER,
            value: 'impressions'
          }
        ]
      : [])
  ];

  return { contentFilter, startDate, endDate, checkBoxFilter };
};
