import React, { useEffect, useRef } from 'react';
import { Picker } from 'emoji-mart';

const EmojiPicker = (props: any) => {
  const ref = useRef<any>(null);
  const instance = useRef<any>(null);

  if (instance.current) {
    instance.current.update(props);
  }

  useEffect(() => {
    instance.current = new Picker({ ...props, ref });

    return () => {
      instance.current = null;
    };
  }, []); // eslint-disable-line

  return React.createElement('div', { ref });
};

export default EmojiPicker;
