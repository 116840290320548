import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { settingsPartnerBannersApiTypes } from 'types';

// SETTINGS PARTNER BANNER - GET PARTNER BANNERS LIST
export const settingsGetPartnerBannersCSListRequest = (payload: settingsPartnerBannersApiTypes.IGetPartnerBannerCsListReqPayload) =>
  createAction(actionTypes.GET_PARTNER_BANNERS_CS_LIST_REQUEST, payload);
export const settingsGetPartnerBannersCSListResponse = (payload: settingsPartnerBannersApiTypes.IPartnerBannerRes) => createAction(actionTypes.GET_PARTNER_BANNERS_CS_LIST_RESPONSE, payload);
export const settingsGetPartnerBannersCSListFailure = (payload: string | null) => createAction(actionTypes.GET_PARTNER_BANNERS_CS_LIST_FAILURE, payload);

// SETTINGS PARTNER BANNER - GET PARTNER BANNER FRANCHSIOR
export const settingsGetPartnerBannerFranchisorsRequest = (payload: settingsPartnerBannersApiTypes.IGetPartnerBannerCsListReqPayload) =>
  createAction(actionTypes.GET_PARTNER_BANNER_FRANCHISORS_REQUEST, payload);
export const settingsGetPartnerBannerFranchisorsResponse = (payload: settingsPartnerBannersApiTypes.IPartnerBannerFranchisorRes) =>
  createAction(actionTypes.GET_PARTNER_BANNER_FRANCHISORS_RESPONSE, payload);
export const settingsGetPartnerBannerFranchisorsFailure = (payload: string | null) => createAction(actionTypes.GET_PARTNER_BANNER_FRANCHISORS_FAILURE, payload);

// SETTINGS PARTNER BANNER - ENROLL PARTNER BANNER
export const settingsEnrollPartnerBannersRequest = (payload: settingsPartnerBannersApiTypes.IUpdatePartnerBannerSyndicationReq) => createAction(actionTypes.ENROLL_PARTNER_BANNERS_REQUEST, payload);
export const settingsEnrollPartnerBannersResponse = (payload: settingsPartnerBannersApiTypes.IUpdatePartnerBannerSyndicationReq) => createAction(actionTypes.ENROLL_PARTNER_BANNERS_RESPONSE, payload);
export const settingsEnrollPartnerBannersFailure = (payload: string | null) => createAction(actionTypes.ENROLL_PARTNER_BANNERS_FAILURE, payload);

// SETTINGS PARTNER BANNER - UPDATE PURCHASED BRANDS
export const createOrUpdatePartnerBannerRequest = (payload: settingsPartnerBannersApiTypes.ICreateOrUpdatePartnerBanner) => createAction(actionTypes.CREATE_OR_UPDATE_PARTNER_BANNER_REQUEST, payload);
export const createOrUpdatePartnerBannerResponse = (payload: settingsPartnerBannersApiTypes.IPartnerBannerAd) => createAction(actionTypes.CREATE_OR_UPDATE_PARTNER_BANNER_RESPONSE, payload);
export const createOrUpdatePartnerBannerFailure = (payload: string | null) => createAction(actionTypes.CREATE_OR_UPDATE_PARTNER_BANNER_FAILURE, payload);

// SETTINGS PARTNER BANNER - RESET ALL
export const partnerBannerResetAll = () => createAction(actionTypes.PARTNER_BANNERS_RESET_ALL);

const SETTINGS_PARTNER_BANNERS = {
  settingsGetPartnerBannersCSListRequest,
  settingsGetPartnerBannersCSListResponse,
  settingsGetPartnerBannersCSListFailure,
  settingsGetPartnerBannerFranchisorsRequest,
  settingsGetPartnerBannerFranchisorsResponse,
  settingsGetPartnerBannerFranchisorsFailure,
  settingsEnrollPartnerBannersRequest,
  settingsEnrollPartnerBannersResponse,
  settingsEnrollPartnerBannersFailure,
  createOrUpdatePartnerBannerRequest,
  createOrUpdatePartnerBannerResponse,
  createOrUpdatePartnerBannerFailure,
  partnerBannerResetAll
};

export type SettingsPartnerBannersAction = ActionsUnion<typeof SETTINGS_PARTNER_BANNERS>;
