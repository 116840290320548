import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { COMMON_SOCIAL_PROVIDER_TYPE, MG_MEDIA_TYPE, AP_INSTA_PHOTO_PREVIEW_LIST, USER_OWNERSHIP, TOOLTIP_PLACEMENT, IReactDNDItemTypes, POST_IMAGES_LIMITATION } from 'utils/constants';
import { apFranContainerTypes, commonWidgetTypes, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { addPostSetInstaPhotoPreviewObj, addPostSetEditConfirmForApprovedPost, addPostSetSelectedMediaList, selectedMediaListSort, addPostGetSocialMediaPhotoPreviewRequest } from 'actions';
import { handleLinkNavigation } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { Loading } from 'components';
import { CustomTooltip } from 'widgets/Tooltip';
import { ReactDND } from 'widgets/DragAndDrop';

export const CreatorInstagramPhotos = ({ socialMedia, isActionEnabled = true, handleAddImages, isOpacityEnabled, disabled, currentTab }: apFranContainerTypes.ICreatorInstagramPhotosProps) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const photoUrl = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.photoUrl);
  const actionType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isInstaPhotosFetching = useSelector((state: IStore) => state.addPostAccount.isInstaPhotosFetching);

  useEffect(() => {
    if (selectedMediaList.length && currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) {
      const updatedMediaList = selectedMediaList.slice(0, POST_IMAGES_LIMITATION.INSTAGRAM);
      [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].forEach((tab) => {
        dispatch(
          addPostGetSocialMediaPhotoPreviewRequest({
            socialMedia: currentTab,
            payload: {
              mode: actionType,
              urls: updatedMediaList.map((it) => it.url)
            }
          })
        );
      });
    }
  }, [selectedMediaList, actionType, currentTab, dispatch]);

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const handleDeleteUploadedMedia = (originalUrl: string, previewUrl?: string) => {
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && isPostEditable) {
      handleEditConfirmation();
    } else if (originalUrl) {
      if (previewUrl) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType,
            photoUrl: {
              originalUrls: photoUrl.originalUrls.filter((it) => it !== originalUrl),
              previewUrls: photoUrl?.previewUrls?.filter((it) => it !== previewUrl) || []
            }
          })
        );
      }
      const totalMediaUrls = selectedMediaList.filter((it) => it.url !== originalUrl);
      dispatch(addPostSetSelectedMediaList(totalMediaUrls));
    }
  };

  const renderImage = (instaPhotoItem: string) => {
    return (
      <div className="img-thumb">
        <ImageValidation imgUrl={instaPhotoItem} customName={'instagram-preview-image'} onClick={() => handleLinkNavigation(instaPhotoItem)} />
      </div>
    );
  };

  const handleSelectedMedialistPosition = (data: commonWidgetTypes.IReactDNDSortData) => {
    dispatch(selectedMediaListSort(data));
  };

  return socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && selectedMediaList.length && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? (
    <div className={`rcpm-insta-assets`}>
      {photoUrl?.previewUrls?.length ? <p className="d-block  mb-3">Drag and drop to rearrange the order</p> : null}

      <div className={`post-inst-grid-sec g-10${isInstaPhotosFetching ? ` pointer-events-none` : ``}`}>
        {isInstaPhotosFetching ? <Loading isSmall /> : null}
        {photoUrl?.previewUrls?.length
          ? photoUrl?.previewUrls.map((instaPhotoItem, idx) => {
              const mediaData = selectedMediaList[idx];
              return (
                <ReactDND key={`insta-media-list-dnd-${idx}`} index={idx} type={IReactDNDItemTypes.CARD} data={mediaData} onSortData={(data) => handleSelectedMedialistPosition(data)}>
                  <div className="photo" key={`insta-photo-preview-${idx}`}>
                    <div className="image-roll-holder">
                      <div className={`ctvm ct-img${isOpacityEnabled ? ` button-opacity` : ``}${disabled ? ` pointer-events-none` : ''}`}>
                        {mediaData?.comments ? (
                          <CustomTooltip
                            customPlacement={TOOLTIP_PLACEMENT.TOP}
                            customTooltipText={mediaData.comments}
                            customClassname={'custom-tooltip-long-text'}
                            customInput={() => renderImage(instaPhotoItem)}
                          />
                        ) : (
                          renderImage(instaPhotoItem)
                        )}

                        {isActionEnabled ? (
                          <div className="ctm-controls">
                            <div className="ctmc-item ctmc-delete">
                              <ImageValidation
                                isImgValid
                                defaultImg={'delete-bright-red'}
                                customName={'Delete'}
                                onClick={() => handleDeleteUploadedMedia(photoUrl.originalUrls[idx], instaPhotoItem)}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </ReactDND>
              );
            })
          : null}
        {selectedMediaList.length < 10 && isActionEnabled && (
          <div
            className={`add-img-btn${disabled ? ` pointer-events-none` : ''}`}
            onClick={() => {
              if (userOwnership === USER_OWNERSHIP.ACCOUNT && isPostEditable) {
                handleEditConfirmation();
              } else {
                handleAddImages();
              }
            }}
          >
            <div>
              <ImageValidation isImgValid defaultImg={'add-images'} customName={'add-img-btn'} />
            </div>
            <span>Add Images</span>
          </div>
        )}
      </div>
      <div className="radio-section vpt-20">
        <div className="filter-item">
          {AP_INSTA_PHOTO_PREVIEW_LIST.map((previewItem, index) => {
            return (
              <div className="form-group m-b-2rem" key={`instagram-preview-type-${index}`}>
                <label className="">
                  <input
                    type="radio"
                    name={previewItem.name}
                    className="option-input radio"
                    checked={actionType === previewItem.name}
                    onChange={(event) => {
                      dispatch(
                        addPostSetInstaPhotoPreviewObj({
                          actionType: event.target.name,
                          photoUrl: { originalUrls: [], previewUrls: [] }
                        })
                      );
                    }}
                  />
                  <span className={'labelText'}>{previewItem.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};
