import Typist from 'react-typist';

import { AI_ICON_BG_IMG } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';

export const AILoading = ({ aiIconBgColorType, context }: { aiIconBgColorType?: string; context?: string }) => {
  return (
    <div className="spin-txt-wrap ai-loader">
      <ImageValidation isImgValid defaultImg={aiIconBgColorType || AI_ICON_BG_IMG.WHITE} isNotSvgFormat customName="AI Content Generated" />
      {context ? (
        <div className="spin-txt">
          <Typist
            avgTypingDelay={10}
            startDelay={2000}
            // cursor={{ hideWhenDone: true }}
          >
            {/*   <span className="typist-head">Hold your horses!</span>
            <br />
            <br /> */}
            {/* <Typist.Delay ms={1500} /> */}
            <span className="typist-body-txt">{context}</span>
          </Typist>
        </div>
      ) : null}
    </div>
  );
};
