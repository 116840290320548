import { RefObject, useCallback, useEffect, useRef } from 'react';

interface INavSliderProps {
  activeIndex: number;
  noOfItems: number;
  sectionRef: RefObject<HTMLTableSectionElement>;
  navRef: RefObject<HTMLDivElement>;
}

export const NavSlider = ({ activeIndex, navRef, sectionRef, noOfItems }: INavSliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const computeAnimationFactors = useCallback(() => {
    const sectionRect = sectionRef.current?.getBoundingClientRect();
    const navRect = navRef.current?.getBoundingClientRect();
    if (sectionRect && navRect) {
      return {
        aFactor: sectionRect.width / 2 - navRect.width / 2,
        xFactor: navRect.width / noOfItems
      };
    }
    return null;
  }, [noOfItems, navRef, sectionRef]);

  useEffect(() => {
    const loadTab = () => {
      const animationFactors = computeAnimationFactors();
      const activeElement = navRef.current?.children.item(activeIndex);
      const activeRect = activeElement?.getBoundingClientRect();
      if (activeRect && animationFactors) {
        const eFactor = activeIndex * animationFactors.xFactor;
        if (sliderRef.current) {
          sliderRef.current.style.transform = `translateX(${animationFactors?.aFactor + eFactor}px)`;
        }

        setTimeout(() => {
          if (sliderRef.current) {
            sliderRef.current.classList.remove('d-none');
          }
        }, 200);
      }
    };
    window.addEventListener('resize', () => {
      if (sliderRef.current) sliderRef.current.classList.add('d-none');
      loadTab();
    });
    loadTab();
  }, [activeIndex, navRef, noOfItems, computeAnimationFactors]);

  return (
    <div className="nav__slider" id="transition-slider">
      <div className="nav__slider-rect d-none" ref={sliderRef} />
    </div>
  );
};
