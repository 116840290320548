import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore } from 'types';
import { useParamsDeconstructor, useParamsValidator } from 'utils/hooks';
import { weekOrMonthBeforeTodayFilter } from 'utils/helpers';
import { USER_OWNERSHIP, REVIEWS_LOCATIONS_DROPDOWN_OBJ, OverviewValidQueryParams, BUTTON_PROPS, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ, FeedValue } from 'utils/constants';
import { CommonFilter } from 'components';
import { handleFilterChange } from 'analytics/utils';
import { IFiterHandleChangeData } from 'types/common/filter';

export const AdvocacyOverviewFilter = () => {
  const navigate = useNavigate();

  const { id, userOwnership } = useSelector((state: IStore) => state.accountSwitcher);
  const overviewDaterange = weekOrMonthBeforeTodayFilter(FeedValue.INBOX);

  useParamsValidator({ ...CommonValidQueryParams, ...OverviewValidQueryParams }, COMMON_VALID_QUERY_PARAMS_OBJ);

  const initialFilterValue = {
    locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
    dateRange: overviewDaterange
  };
  const { queryParams, filter } = useParamsDeconstructor(initialFilterValue);

  const handleChange = (data: IFiterHandleChangeData, isClear = false) => {
    const queryParamsObj = handleFilterChange(data, isClear, id, queryParams);
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  return (
    <CommonFilter
      filter={filter}
      handleChange={handleChange}
      sectionVariant="adv-ov-filter"
      isLocation={userOwnership === USER_OWNERSHIP.FRANCHISOR}
      primaryButtons={[
        {
          ...BUTTON_PROPS.CLEAR_FILTER,
          isDisable: !Object.keys(queryParams)?.length
        }
      ]}
    />
  );
};
