import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalPopup } from 'components';
import { IStore, postsContainerTypes } from 'types';
import { MG_DELETE_ASSET_CONFIRM_MSG, MODAL_BOX_TYPES, AP_DELETE_CONTENT_ITEM, USER_OWNERSHIP, RIPPLE_COLOR, FranCreatorDeleteType } from 'utils/constants';
import { addPostDeleteContentRequest, addPostMassDeleteContentRequest, addPostDeleteContentObj, deletePostsRequest } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useAllowAccess } from 'utils/hooks';

export const PostsDeleteModal = ({ isModalShow, handleModalClose }: postsContainerTypes.IPostsDeleteModalPopup) => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { franchisorFullAccess } = useAllowAccess();

  const deleteCheckBox = useSelector((state: IStore) => state.addPostFranchisorAction.deleteCheckBox);
  const postsDetailID = useSelector((state: IStore) => state.posts.postsDetails?.id);
  const postsDetailAccountID = useSelector((state: IStore) => state.posts.postsDetails?.account?.id || null);
  const deletePostsResponse = useSelector((state: IStore) => state.posts.deletePostsResponse);
  const isAccActionFetching = useSelector((state: IStore) => state.posts.isActionFetching);
  const isFranActionFetching = useSelector((state: IStore) => state.addPostFranchisorAction.deletedObj.isFetching);
  const deleteMessage = useSelector((state: IStore) => state.addPostFranchisorAction.deletedObj.deleteMessage);

  const postUserOwnership = userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetailAccountID ? USER_OWNERSHIP.ACCOUNT : userOwnership;

  const deleteOptions = useMemo(() => {
    if (franchisorFullAccess) return AP_DELETE_CONTENT_ITEM;
    else return [AP_DELETE_CONTENT_ITEM[0]];
  }, [franchisorFullAccess]);

  useEffect(() => {
    if (deleteMessage || deletePostsResponse) {
      handleModalClose();
    }
  }, [deleteMessage, deletePostsResponse]); // eslint-disable-line

  const handleDeleteContentPost = () => {
    if (postsDetailID && id && postUserOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(
        deletePostsRequest({
          postsId: postsDetailID,
          id,
          userOwnership: USER_OWNERSHIP.ACCOUNT
        })
      );
    } else if (postsDetailID && postUserOwnership === USER_OWNERSHIP.FRANCHISOR) {
      if (deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_DB)) {
        dispatch(addPostDeleteContentRequest(postsDetailID));
      }
      if (deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_HUB_AND_LOC) || deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_SOCIAL_MEDIA)) {
        dispatch(
          addPostMassDeleteContentRequest({
            postId: postsDetailID,
            isShowAlert: !deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_DB),
            reqPayload: {
              delete_descendants: deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_HUB_AND_LOC) ? `${1}` : `${0}`,
              delete_remote_posts: deleteCheckBox.includes(FranCreatorDeleteType.REMOVE_FROM_SOCIAL_MEDIA) ? `${1}` : `${0}`
            }
          })
        );
      }
    }
  };

  const handleDeleteContentCheckbox = (contentName: string) => {
    if (!deleteCheckBox.includes(contentName)) {
      dispatch(addPostDeleteContentObj([...deleteCheckBox, contentName]));
    } else {
      const filteredContentCheckbox = deleteCheckBox.filter((datum) => datum !== contentName);
      dispatch(addPostDeleteContentObj([...filteredContentCheckbox]));
    }
  };

  const handleDisableDeleteAction = () => {
    return postUserOwnership === USER_OWNERSHIP.FRANCHISOR ? isFranActionFetching || !deleteCheckBox.length : isAccActionFetching;
  };

  return (
    <ModalPopup
      isModalShow={isModalShow}
      modalHeaderType={MODAL_BOX_TYPES.DANGER}
      // containerClassName={`modal-confirmation confirm-delete cnfrm__delete--modal${postUserOwnership === USER_OWNERSHIP.FRANCHISOR ? ` hub-post-del` : ``}`}
      containerClassName={`cnfrm__delete--modal glbl__delete--modal package__delete--modal ${postUserOwnership === USER_OWNERSHIP.FRANCHISOR ? ` hub-post-del` : ``}`}
      modalBody={() => (
        <div>
          {postUserOwnership === USER_OWNERSHIP.ACCOUNT ? (
            <div className="modal-message-wraps">{MG_DELETE_ASSET_CONFIRM_MSG}</div>
          ) : (
            <>
              <div className="modal-message-wraps">Are you sure you want to permanently delete this package?</div>
              <div className="createpost-wrp-new-section">
                {deleteOptions.map((contentDatum, index) => {
                  return (
                    <div className="form-check" key={`content-delete-item-${index}`}>
                      <label className={`checkbox-item${deleteCheckBox.includes(contentDatum.name) ? ` active` : ``}`}>
                        <span className="checkbox-hover">
                          <input
                            type="checkbox"
                            className="option-input click-wave"
                            name={contentDatum.name}
                            onChange={() => handleDeleteContentCheckbox(contentDatum.name)}
                            checked={deleteCheckBox.includes(contentDatum.name)}
                          />
                        </span>
                        <div className="label-txt">{contentDatum.label}</div>
                        <span className={'checkmark'} />
                      </label>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      modalCloseButton
      handleModalClose={() => handleModalClose()}
      isModalFooterShow
      modalFooter={() => (
        // <div className="modal-btn-grp-wraps">
        <div className="delete__btn--wrp">
          <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
            <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={handleModalClose}>
              Cancel
            </button>
          </CustomRippleButton>
          <CustomRippleButton rippleClass={`ac-danger-box ${handleDisableDeleteAction() ? `pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.danger}>
            <button className={`ac-btn ac-danger ac-block ${handleDisableDeleteAction() ? `disabled` : ``}`} disabled={handleDisableDeleteAction()} onClick={() => handleDeleteContentPost()}>
              Delete
            </button>
          </CustomRippleButton>
        </div>
      )}
    />
  );
};
