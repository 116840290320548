import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  getCouponsListResponse,
  getCouponsListFailure,
  getCouponsListRequest,
  logoutRequest,
  getCouponDetailViewResponse,
  getCouponDetailViewFailure,
  deleteCouponResponse,
  deleteCouponFailure,
  exceptionHandlerRequest,
  cpUploadCloudMediaResponse,
  cpUploadCloudMediaReqFailure,
  addOrEditCouponResponse,
  addOrEditCouponFailure
} from 'actions';
import { commonApiTypes, couponsApiTypes } from 'types';
import { apiCall, defaultHeader, API, HttpMethods, cloudinaryHeader, CLOUDINARY_API } from 'utils/helpers';
import {
  COUPONS_LIST_FAILED,
  API_TYPES,
  COUPON_DETAIL_VIEW_FAILED,
  DELETE_COUPON_FAILED,
  COUPON_EXCEPTION_ERROR,
  CP_CLOUD_UPLOAD_MEDIA_FAILED,
  ADD_COUPON_FAILED,
  EDIT_COUPON_FAILED,
  DL_UPDATED_SUCCESSFULLY,
  ALERT_BOX_TYPES
} from 'utils/constants';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetCouponsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params: couponsApiTypes.ICouponsListRequest = payload;
    const response = yield apiCall({ headers, params, ...API.getCouponsList });
    if (response.status === 200) {
      yield put(getCouponsListResponse(response.data.rallio_coupons));
    } else {
      yield put(getCouponsListFailure(COUPONS_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(COUPON_EXCEPTION_ERROR));
  }
}

function* sendGetCouponDetailViewRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getCouponDetail.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getCouponDetail.action });
    if (response.status === 200) {
      yield put(getCouponDetailViewResponse(response.data.rallio_coupon));
    } else {
      yield put(getCouponDetailViewFailure(COUPON_DETAIL_VIEW_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(COUPON_EXCEPTION_ERROR));
  }
}

function* sendAddOrEditCouponRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    let data;
    let response;
    let path: any;
    if (payload.id) {
      // For update coupons
      const { id, ...newPayload } = payload;
      data = { rallio_coupon: newPayload };
      const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.updateCoupon };
      path = urlPathObj;
      path.apiPath = path.apiPath.replace(':id', payload.id);
      response = yield apiCall({ headers, data, ...path, action: API.updateCoupon.action });
    } else {
      // For add coupons
      data = { rallio_coupon: payload };
      const urlPathObj: { apiPath: string; action: HttpMethods } = { ...API.addCoupon };
      path = urlPathObj;
      response = yield apiCall({ headers, data, ...path, action: API.addCoupon.action });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if ([200, 201].includes(response.status)) {
      yield put(addOrEditCouponResponse(DL_UPDATED_SUCCESSFULLY));
      yield put(getCouponsListRequest({ franchisor_id: +payload.franchisor_id }));
    } else {
      yield put(addOrEditCouponFailure(payload.id ? EDIT_COUPON_FAILED : ADD_COUPON_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(COUPON_EXCEPTION_ERROR));
  }
}

function* sendDeleteCouponRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.deleteCoupon.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.deleteCoupon.action });
    if (response.status === 200) {
      yield put(deleteCouponResponse(null));
    } else {
      yield put(deleteCouponFailure(DELETE_COUPON_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(COUPON_EXCEPTION_ERROR));
  }
}

function* sendCpCloudMediaUploadRequest({ payload }: ISagaPayload): any {
  const { file, signature, apiKey, timestamp, eager } = payload;
  try {
    const headers = cloudinaryHeader();
    const bodyFormData = new FormData();
    bodyFormData.append('api_key', apiKey);
    bodyFormData.append('file', file);
    bodyFormData.append('signature', signature);
    bodyFormData.append('timestamp', timestamp);
    bodyFormData.append('eager', eager);

    const response = yield apiCall({
      headers,
      data: bodyFormData,
      ...CLOUDINARY_API.cloudinaryImageUpload,
      type: API_TYPES.CLOUDINARY
    });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(cpUploadCloudMediaResponse(response.data.secure_url));
    } else {
      if (response.data?.error?.message) alertBoxCall(ALERT_BOX_TYPES.ERROR, response.data.error.message);
      else alertBoxCall(ALERT_BOX_TYPES.ERROR, CP_CLOUD_UPLOAD_MEDIA_FAILED);
      yield put(cpUploadCloudMediaReqFailure(null));
    }
  } catch (error) {
    yield put(cpUploadCloudMediaReqFailure(CP_CLOUD_UPLOAD_MEDIA_FAILED));
    yield put(exceptionHandlerRequest(COUPON_EXCEPTION_ERROR));
  }
}

export function* takeCouponsRequest() {
  yield takeEvery(types.GET_COUPONS_LIST_REQUEST, sendGetCouponsRequest);
  yield takeLatest(types.ADD_OR_EDIT_COUPON_REQUEST, sendAddOrEditCouponRequest);
  yield takeEvery(types.GET_COUPON_DETAIL_VIEW_REQUEST, sendGetCouponDetailViewRequest);
  yield takeEvery(types.DELETE_COUPON_REQUEST, sendDeleteCouponRequest);
  yield takeLatest(types.CP_CLOUD_MEDIA_UPLOAD_REQUEST, sendCpCloudMediaUploadRequest);
}
