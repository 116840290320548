import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { communicationApiTypes, communicationReducerTypes } from 'types';

// COMMUNICATIONS - GET LOCATION LIST
export const communicationsListGetRequest = (payload: communicationApiTypes.ICommunicationListReq) => createAction(actionTypes.COMMUNICATION_GET_LIST_REQUEST, payload);
export const communicationsListGetResponse = (data: communicationReducerTypes.ICommunicationList[]) => createAction(actionTypes.COMMUNICATION_GET_LIST_RESPONSE, data);
export const communicationsListGetFailure = (error: null | string) => createAction(actionTypes.COMMUNICATION_GET_LIST_FAILURE, error);

// COMMUNICATIONS - GET LOCATION LIST
export const communicationCreateUpdateRequest = (payload: communicationApiTypes.ICommunicationCreateUpdateReq) => createAction(actionTypes.COMMUNICATION_CREATE_UPDATE_REQUEST, payload);
export const communicationCreateUpdateResponse = (data: { communication: communicationReducerTypes.ICommunicationList[]; message: string }) =>
  createAction(actionTypes.COMMUNICATION_CREATE_UPDATE_RESPONSE, data);
export const communicationCreateUpdateFailure = (error: null | string) => createAction(actionTypes.COMMUNICATION_CREATE_UPDATE_FAILURE, error);

// COMMUNICATIONS - GET LOCATION LIST
export const communicationDeleteRequest = (payload: number) => createAction(actionTypes.COMMUNICATION_DELETE_REQUEST, payload);
export const communicationDeleteResponse = (data: string) => createAction(actionTypes.COMMUNICATION_DELETE_RESPONSE, data);
export const communicationDeleteFailure = (error: null | string) => createAction(actionTypes.COMMUNICATION_DELETE_FAILURE, error);

// COMMUNICATIONS - MARK AS SEEN
export const communicationMarkAsSeenRequest = (payload: number) => createAction(actionTypes.COMMUNICATION_MARK_AS_SEEN_REQUEST, payload);
export const communicationMarkAsSeenResponse = (data: communicationReducerTypes.ICommunicationList) => createAction(actionTypes.COMMUNICATION_MARK_AS_SEEN_RESPONSE, data);
export const communicationMarkAsSeenFailure = (error: null | string) => createAction(actionTypes.COMMUNICATION_MARK_AS_SEEN_FAILURE, error);

const COMMUNICATIONS_ACTIONS = {
  communicationsListGetRequest,
  communicationsListGetResponse,
  communicationsListGetFailure,
  communicationCreateUpdateRequest,
  communicationCreateUpdateResponse,
  communicationCreateUpdateFailure,
  communicationDeleteRequest,
  communicationDeleteResponse,
  communicationDeleteFailure,
  communicationMarkAsSeenRequest,
  communicationMarkAsSeenResponse,
  communicationMarkAsSeenFailure
};

export type CommunicationsAction = ActionsUnion<typeof COMMUNICATIONS_ACTIONS>;
