import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { CampaignsAction } from 'actions';
import { campaignsReducerTypes } from 'types';
import { CAMPAIGN_POSTS_INIT_STATS_DATA, CAMPAIGN_POSTS_INIT_RESPONSE_MSG, DONE_SUCCESS_MSG } from 'utils/constants';
import { addOrDeleteMultiplePostsForCampaign, getCampaignsSortedPostsList } from 'utils/helpers';

type ICampaignsReducer = campaignsReducerTypes.ICampaignsReducer;

const initialState: ICampaignsReducer = {
  isListFetching: true,
  isStatsFetching: true,
  isCampaignUpdate: false,
  isCreateOrEditCampaign: false,
  isDetailFetching: true,
  isPostListUpdate: false,
  isEventListFetching: false,
  isEventFetching: false,
  isPostListFetching: true,
  currentPage: 1,
  error: null,
  campaignsList: [],
  campaignStats: CAMPAIGN_POSTS_INIT_STATS_DATA,
  campaignDetail: null,
  detailViewError: null,
  responseMsg: CAMPAIGN_POSTS_INIT_RESPONSE_MSG,
  postResponseMsg: CAMPAIGN_POSTS_INIT_RESPONSE_MSG,
  eventsCurrentPage: 1,
  campaignEventsList: [],
  postsList: [],
  selectedPosts: [],
  campaignEvent: null,
  isSortList: false
};

export const campaignsReducer: Reducer<ICampaignsReducer, CampaignsAction> = (state = initialState, action: CampaignsAction): ICampaignsReducer => {
  switch (action.type) {
    case types.CAMPAIGNS_LIST_REQUEST:
      return {
        ...state,
        isListFetching: action.payload?.isDataRetain ? false : true,
        currentPage: action.payload.page || 1
      };
    case types.CAMPAIGNS_LIST_RESPONSE:
      return {
        ...state,
        isListFetching: false,
        isCreateOrEditCampaign: initialState.isCreateOrEditCampaign,
        campaignsList: state.currentPage === 1 ? action.payload : state.campaignsList.concat(action.payload)
      };
    case types.CAMPAIGNS_LIST_FAILURE:
      return {
        ...state,
        isListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGNS_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: action.payload?.isDataRetain ? false : true
      };
    case types.CAMPAIGNS_STATS_RESPONSE:
      return {
        ...state,
        isStatsFetching: false,
        campaignStats: {
          active: action.payload.active || 0,
          inactive: action.payload.in_active || 0,
          available: action.payload.available || 0,
          total: action.payload.total_count || 0
        }
      };
    case types.CAMPAIGNS_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.GET_CAMPAIGNS_EVENT_LIST_REQUEST:
      return {
        ...state,
        isEventListFetching: true,
        eventsCurrentPage: action.payload.page,
        campaignEventsList: action.payload.page === 1 ? initialState.campaignEventsList : state.campaignEventsList
      };
    case types.GET_CAMPAIGNS_EVENT_LIST_RESPONSE:
      return {
        ...state,
        isEventListFetching: false,
        campaignEventsList: state.eventsCurrentPage === 1 ? action.payload : state.campaignEventsList.concat(action.payload)
      };
    case types.GET_CAMPAIGNS_EVENT_LIST_FAILURE:
      return {
        ...state,
        isEventListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.GET_CAMPAIGNS_EVENT_REQUEST:
      return {
        ...state,
        isEventFetching: true
      };
    case types.GET_CAMPAIGNS_EVENT_RESPONSE:
      return {
        ...state,
        isEventFetching: false,
        campaignEvent: action.payload
      };
    case types.GET_CAMPAIGNS_EVENT_FAILURE:
      return {
        ...state,
        isEventFetching: false,
        campaignEvent: initialState.campaignEvent,
        error: action.payload || 'Unexpected error'
      };
    case types.CREATE_OR_UPDATE_CAMPAIGN_EVENT_REQUEST:
      return {
        ...state,
        isCampaignUpdate: true
      };
    case types.CREATE_OR_UPDATE_CAMPAIGN_EVENT_RESPONSE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          successMsg: DONE_SUCCESS_MSG
        }
      };
    case types.CREATE_OR_UPDATE_CAMPAIGN_EVENT_FAILURE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.STOP_CAMPAIGN_EVENT_REQUEST:
      return {
        ...state,
        isCampaignUpdate: true
      };
    case types.STOP_CAMPAIGN_EVENT_RESPONSE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          successMsg: DONE_SUCCESS_MSG
        }
      };
    case types.STOP_CAMPAIGN_EVENT_FAILURE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGN_POSTS_DELETE_EVENT_REQUEST:
      return {
        ...state,
        isCampaignUpdate: true
      };
    case types.CAMPAIGN_POSTS_DELETE_EVENT_RESPONSE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          successMsg: !action.payload ? DONE_SUCCESS_MSG : state.responseMsg.successMsg
        }
      };
    case types.CAMPAIGN_POSTS_DELETE_EVENT_FAILURE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.CREATE_OR_EDIT_CAMPAIGN_REQUEST:
      return {
        ...state,
        isCreateOrEditCampaign: true
      };
    case types.CREATE_OR_EDIT_CAMPAIGN_RESPONSE:
      return {
        ...state,
        responseMsg: {
          ...state.responseMsg,
          successMsg: action.payload
        }
      };
    case types.CREATE_OR_EDIT_CAMPAIGN_FAILURE:
      return {
        ...state,
        isCreateOrEditCampaign: false,
        responseMsg: {
          ...state.responseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.GET_CAMPAIGN_DETAIL_REQUEST:
      return {
        ...state,
        isDetailFetching: action.payload?.isDataRetain ? false : true
      };
    case types.GET_CAMPAIGN_DETAIL_RESPONSE:
      return {
        ...state,
        isDetailFetching: false,
        campaignDetail: action.payload
      };
    case types.GET_CAMPAIGN_DETAIL_FAILURE:
      return {
        ...state,
        isDetailFetching: false,
        campaignDetail: null,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    case types.DELETE_CAMPAIGN_REQUEST:
      return {
        ...state
      };
    case types.DELETE_CAMPAIGN_RESPONSE:
      return {
        ...state,
        campaignDetail: initialState.campaignDetail,
        postsList: initialState.postsList,
        eventsCurrentPage: initialState.eventsCurrentPage,
        campaignEventsList: initialState.campaignEventsList
      };
    case types.DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    case types.DEACTIVATE_CAMPAIGN_EVENT_REQUEST:
      return {
        ...state,
        isCampaignUpdate: true
      };
    case types.DEACTIVATE_CAMPAIGN_EVENT_RESPONSE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          successMsg: DONE_SUCCESS_MSG
        }
      };
    case types.DEACTIVATE_CAMPAIGN_EVENT_FAILURE:
      return {
        ...state,
        isCampaignUpdate: false,
        responseMsg: {
          ...state.responseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGN_SORT_POSTS_LIST_REQUEST:
      return {
        ...state,
        isPostListUpdate: true
      };
    case types.CAMPAIGN_SORT_POSTS_LIST_RESPONSE:
      return {
        ...state,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          successMsg: action.payload
        }
      };
    case types.CAMPAIGN_SORT_POSTS_LIST_FAILURE:
      return {
        ...state,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.ADD_POSTS_TO_CAMPAIGN_REQUEST:
      return {
        ...state,
        isPostListUpdate: true
      };
    case types.ADD_POSTS_TO_CAMPAIGN_RESPONSE:
      return {
        ...state,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          successMsg: action.payload
        }
      };
    case types.ADD_POSTS_TO_CAMPAIGN_FAILURE:
      return {
        ...state,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGNS_DELETE_POSTS_REQUEST:
      return {
        ...state,
        isPostListUpdate: true
      };
    case types.CAMPAIGNS_DELETE_POSTS_RESPONSE:
      return {
        ...state,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          successMsg: action.payload
        }
      };
    case types.CAMPAIGNS_DELETE_POSTS_FAILURE:
      return {
        ...state,
        isCampaignUpdate: false,
        isPostListUpdate: false,
        postResponseMsg: {
          ...state.postResponseMsg,
          failureMsg: action.payload
        },
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGNS_POST_LIST_REQUEST:
      return {
        ...state,
        isPostListFetching: true
      };
    case types.CAMPAIGNS_POST_LIST_RESPONSE:
      return {
        ...state,
        isPostListFetching: false,
        postsList: action.payload,
        isSortList: initialState.isSortList
      };
    case types.CAMPAIGNS_POST_LIST_FAILURE:
      return {
        ...state,
        isPostListFetching: initialState.isPostListFetching,
        postsList: initialState.postsList,
        isSortList: initialState.isSortList,
        error: action.payload || 'Unexpected error'
      };
    case types.CAMPAIGNS_SORT_POST_LIST:
      return {
        ...state,
        isSortList: true,
        postsList: getCampaignsSortedPostsList(state.postsList, action.payload.dropIndex, action.payload.draggedItem)
      };
    case types.SET_MULTIPLE_POSTS_TO_CAMPAIGN:
      return {
        ...state,
        selectedPosts: addOrDeleteMultiplePostsForCampaign(state.selectedPosts, action.payload)
      };
    case types.CAMPAIGN_RESPONSE_MSG_RESET:
      return {
        ...state,
        responseMsg: initialState.responseMsg,
        postResponseMsg: initialState.postResponseMsg,
        error: initialState.error
      };
    case types.CAMPAIGN_DETAIL_RESET:
      return {
        ...state,
        campaignDetail: initialState.campaignDetail,
        postsList: initialState.postsList,
        eventsCurrentPage: initialState.eventsCurrentPage,
        campaignEventsList: initialState.campaignEventsList
      };
    case types.CAMPAIGN_SELECTED_POSTS_RESET:
      return {
        ...state,
        selectedPosts: initialState.selectedPosts
      };
    case types.CAMPAIGN_EVENT_DETAIL_RESET:
      return {
        ...state,
        campaignEvent: initialState.campaignEvent
      };
    case types.CAMPAIGNS_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
