// USER LOGINS - GET ACCOUNT OWNERS
export const UL_GET_ACCOUNT_OWNERS_REQUEST = 'user_logins/UL_GET_ACCOUNT_OWNERS_REQUEST';
export const UL_GET_ACCOUNT_OWNERS_RESPONSE = 'user_logins/UL_GET_ACCOUNT_OWNERS_RESPONSE';
export const UL_GET_ACCOUNT_OWNERS_FAILURE = 'user_logins/UL_GET_ACCOUNT_OWNERS_FAILURE';

// USER LOGINS - ADD ACCOUNT OWNERS
export const UL_ADD_ACCOUNT_OWNERS_RESPONSE = 'user_logins/UL_ADD_ACCOUNT_OWNERS_RESPONSE';
export const UL_ADD_ACCOUNT_OWNERS_FAILURE = 'user_logins/UL_ADD_ACCOUNT_OWNERS_FAILURE';

// USER LOGINS - UPDATE ACCOUNT OWNERS
export const UL_UPDATE_ACCOUNT_OWNERS_RESPONSE = 'user_logins/UL_UPDATE_ACCOUNT_OWNERS_RESPONSE';
export const UL_UPDATE_ACCOUNT_OWNERS_FAILURE = 'user_logins/UL_UPDATE_ACCOUNT_OWNERS_FAILURE';

// CHECK EMAIL FOR ACCOUNT OWNERS
export const ACCOUNT_OWNERS_CHECK_EMAIL_REQUEST = 'user_logins/ACCOUNT_OWNERS_CHECK_EMAIL_REQUEST';
export const ACCOUNT_OWNERS_CHECK_EMAIL_RESPONSE = 'user_logins/ACCOUNT_OWNERS_CHECK_EMAIL_RESPONSE';
export const ACCOUNT_OWNERS_CHECK_EMAIL_FAILURE = 'user_logins/ACCOUNT_OWNERS_CHECK_EMAIL_FAILURE';

// USER LOGINS - DELETE ACCOUNT OWNERS
export const UL_DELETE_ACCOUNT_OWNERS_REQUEST = 'user_logins/UL_DELETE_ACCOUNT_OWNERS_REQUEST';
export const UL_DELETE_ACCOUNT_OWNERS_RESPONSE = 'user_logins/UL_DELETE_ACCOUNT_OWNERS_RESPONSE';
export const UL_DELETE_ACCOUNT_OWNERS_FAILURE = 'user_logins/UL_DELETE_ACCOUNT_OWNERS_FAILURE';

// USER LOGINS - RESEND INVITE FOR USER
export const UL_RESEND_INVITE_FOR_USER_REQUEST = 'user_logins/UL_RESEND_INVITE_FOR_USER_REQUEST';
export const UL_RESEND_INVITE_FOR_USER_RESPONSE = 'user_logins/UL_RESEND_INVITE_FOR_USER_RESPONSE';
export const UL_RESEND_INVITE_FOR_USER_FAILURE = 'user_logins/UL_RESEND_INVITE_FOR_USER_FAILURE';

// CHECK EMAIL SET VALIDATION
export const ACCOUNT_OWNERS_CHECK_EMAIL_SET_VALIDATION = 'user_logins/ACCOUNT_OWNERS_CHECK_EMAIL_SET_VALIDATION';

// USER LOGINS - SET ADD NEW USER
export const UL_SET_IS_ADD_NEW_USER = 'user_logins/UL_SET_IS_ADD_NEW_USER';

// USER LOGINS - RESET ADD NEW USER
export const UL_RESET_ADD_NEW_USER = 'user_logins/UL_RESET_ADD_NEW_USER';

// USER LOGINS RESET
export const UL_ADD_NEW_USER_DETAIL_RESET = 'user_logins/UL_ADD_NEW_USER_DETAIL_RESET';
export const UL_DELETE_DATA_RESET = 'user_logins/UL_DELETE_DATA_RESET';
export const UL_RESEND_INVITE_DATA_RESET = 'user_logins/UL_RESEND_INVITE_DATA_RESET';
export const UL_MESSAGES_RESET = 'user_logins/UL_MESSAGES_RESET';
export const UL_RESET_ALL = 'user_logins/UL_RESET_ALL';
