import { Table } from 'react-bootstrap';

import { IIndexable, ITableProps } from 'types';

export const NormalTableMode = <T extends IIndexable, E extends {}>({ data, headers, onHeaderClick, onRowClick, onColumnClick, tableClassName, noDataMesg, colState }: ITableProps<T, E>) => {
  return (
    <Table className={tableClassName}>
      <thead>
        <tr>
          {headers.map((it, index) => (
            <th
              className={it.headerClassName}
              key={`tableHeader${index}`}
              onClick={() => {
                if (onHeaderClick) {
                  onHeaderClick(it);
                }
              }}
            >
              {it.renderHeader ? it.renderHeader(it) : it.label}
            </th>
          ))}
        </tr>
      </thead>
      {data.map((body, bodyIndex) => (
        <tbody key={`normalTableBody${bodyIndex}`}>
          {body.length > 0 ? (
            body.map((row, rowIndex) => (
              <tr
                className={`${row.rowClassName || ''} ${onRowClick ? 'cur-pointer' : ''}`}
                key={`tableRow${bodyIndex}${rowIndex}`}
                onClick={(e) => {
                  if (onRowClick) {
                    e.preventDefault();
                    onRowClick(row, rowIndex);
                  }
                }}
              >
                {headers.map((col, colIndex) => (
                  <td
                    className={`${col.addColClass ? col.addColClass(row) : col.colClassName || ''} ${onColumnClick ? 'cur-pointer' : ''}`}
                    key={`tableRowColomn${bodyIndex}${rowIndex}${colIndex}`}
                    onClick={(e) => {
                      if (onColumnClick) {
                        e.stopPropagation();
                        onColumnClick(row, col.identifier, rowIndex);
                      }
                    }}
                    title={typeof row[col.identifier] === 'string' ? row[col.identifier] : undefined}
                  >
                    {col.renderColumn ? col.renderColumn(row, col, rowIndex, colState) : row[col.identifier]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={headers.length}>
                {noDataMesg || ' No Data to show'}
              </td>
            </tr>
          )}
        </tbody>
      ))}
    </Table>
  );
};
