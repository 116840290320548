import { useSelector } from 'react-redux';

import { NoDataFoundProps } from 'utils/constants';
import { campaignsContainerTypes, IStore, postsApiTypes } from 'types';
import { InfiniteScrollCardList, Loading, NoDataFound } from 'components';
import { CampaignPostsList } from './CampaignPostsList';

export const CampaignPostsInfinityScrollList = ({ selectedPostIds, setSelectedPostIds, disableSortCampaignPosts }: campaignsContainerTypes.ICampaignPostsListProps) => {
  const isPostListFetching = useSelector((state: IStore) => state.campaigns.isPostListFetching);
  const postsList = useSelector((state: IStore) => state.campaigns.postsList);

  return isPostListFetching ? (
    <Loading />
  ) : postsList.length ? (
    <div className="infinite-list post-grid-wrp" id="campaign-post-list-ifs">
      <InfiniteScrollCardList<postsApiTypes.IPostListSavedPostSummaries | postsApiTypes.IPostListContentPostSummaries> data={postsList}>
        <CampaignPostsList selectedPostIds={selectedPostIds} setSelectedPostIds={setSelectedPostIds} disableSortCampaignPosts={disableSortCampaignPosts} />
      </InfiniteScrollCardList>
    </div>
  ) : !isPostListFetching && !postsList.length ? (
    <NoDataFound size={NoDataFoundProps.FULL} />
  ) : (
    <Loading />
  );
};
