import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ModalPopup } from 'components';
import { IStore, commonModalPopup } from 'types';
import { BRAND_USER_FEED_NOT_AVALIABLE_MSG, CONNECTION_TABS, MODAL_BOX_TYPES, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData } from 'utils/hooks';

export const SchedulerWarningModal = ({ isShowModal, onModalClose }: commonModalPopup.ICommonModalProps) => {
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams, currentRoute } = useAccountSwitcherData();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const locationsHubList = useSelector((state: IStore) => state.operationsList.locationsGroupList);

  const isFranchisor = userOwnership === USER_OWNERSHIP.FRANCHISOR;
  return (
    <ModalPopup
      isModalShow={isShowModal}
      modalHeaderType={MODAL_BOX_TYPES.INFO}
      containerClassName={'modal-confirmation confirm-delete'}
      modalBody={() => (
        <div>
          <div className="modal-message-wraps">{BRAND_USER_FEED_NOT_AVALIABLE_MSG}</div>
        </div>
      )}
      modalCloseButton
      handleModalClose={() => {
        onModalClose();
      }}
      isModalFooterShow
      modalFooter={() => (
        <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
          <button
            className={`ac-btn ac-primary ac-block kc-save`}
            type="submit"
            onClick={() => {
              if (isFranchisor) {
                navigate(`/${userOwnership}/${id}/team_management/lists`);
              } else if (locationsHubList?.length) {
                navigate(
                  optionalParams[2] === CONNECTION_TABS.SCHEDULER
                    ? `/${userOwnership}/${id}/${currentRoute}/account_wizard/${accountDetails?.account?.id?.toString()}/${CONNECTION_TABS.FEED}`
                    : `/${userOwnership}/${id}/content/feed`
                );
              }
              onModalClose();
            }}
          >
            {isFranchisor ? 'Go to List & Feeds' : locationsHubList?.length ? 'Go to Feed' : 'Ok'}
          </button>
        </CustomRippleButton>
      )}
    />
  );
};
