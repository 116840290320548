import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { ChangePasswordActions } from 'actions';
import { changePasswordReducerTypes } from 'types';
import { SETTINGS_SUCCESS_MSG } from 'utils/constants';

const initialState: changePasswordReducerTypes.IChangePasswordReducer = {
  isFetching: false,
  changePasswordResponse: null,
  changePasswordSuccessMsg: null,
  changePasswordFailMsg: null
};

const changePasswordReducer: Reducer<changePasswordReducerTypes.IChangePasswordReducer, ChangePasswordActions> = (
  state: changePasswordReducerTypes.IChangePasswordReducer = initialState,
  action: ChangePasswordActions
) => {
  switch (action.type) {
    // CHANGE PASSWORD REQUEST
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        isFetching: false,
        changePasswordSuccessMsg: SETTINGS_SUCCESS_MSG,
        changePasswordFailMsg: null,
        changePasswordResponse: action.payload
      };
    case types.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isFetching: false,
        changePasswordFailMsg: action.payload
      };
    case types.CHANGE_PASSWORD_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};

export { changePasswordReducer };
