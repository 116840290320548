import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { CommunicationsAction } from 'actions';
import { communicationReducerTypes } from 'types';

const initialState: communicationReducerTypes.ICommunicationReducer = {
  isFetching: false,
  communicationList: [],
  message: '',
  error: null,
  remainingSeenCount: 0
};

const communicationsReducer: Reducer<communicationReducerTypes.ICommunicationReducer, CommunicationsAction> = (
  state: communicationReducerTypes.ICommunicationReducer = initialState,
  action: CommunicationsAction
) => {
  switch (action.type) {
    // CHANGE PASSWORD REQUEST
    case types.COMMUNICATION_GET_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: ''
      };
    case types.COMMUNICATION_GET_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        communicationList: action.payload,
        remainingSeenCount: action.payload.filter((item) => !item.seen).length || 0
      };
    case types.COMMUNICATION_GET_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case types.COMMUNICATION_CREATE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: '',
        message: ''
      };
    case types.COMMUNICATION_CREATE_UPDATE_RESPONSE:
      return {
        ...state,
        isFetching: false,
        communicationList: action.payload.communication,
        message: action.payload.message
      };
    case types.COMMUNICATION_CREATE_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        message: ''
      };
    case types.COMMUNICATION_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: '',
        message: ''
      };
    case types.COMMUNICATION_DELETE_RESPONSE:
      return {
        ...state,
        isFetching: false,
        message: action.payload,
        remainingSeenCount: state.remainingSeenCount - 1
      };
    case types.COMMUNICATION_MARK_AS_SEEN_RESPONSE:
      const markAsSeenArr = state.communicationList.map((it) => (it.id === action.payload.id ? { ...it, seen: action.payload.seen } : it));
      return {
        ...state,
        remainingSeenCount: state.remainingSeenCount - 1,
        communicationList: markAsSeenArr
      };
    case types.COMMUNICATION_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        message: ''
      };
    default:
      return state;
  }
};

export { communicationsReducer };
