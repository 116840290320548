import { accountApiTypes, commonProps, directoryListingsContainerTypes, directoryListingsReducerTypes } from 'types';
import { Month } from 'utils/constants';

export const DL_STORE_DETAILS_TAB_FIELDS: directoryListingsContainerTypes.IStoreDetailsTabFields = {
  NAME: 'dlm_name',
  TAG_LINE: 'tagline',
  STORE_CODE: 'dlm_store_code',
  PAYMENT_METHODS: 'payment_methods',
  ADDRESS: 'dlm_street',
  PRIMARY_CATEGORY: 'synup_sub_category_name',
  CITY: 'dlm_city',
  ADDITIONAL_CATEGORY: 'synup_additional_category_ids',
  STATE_OR_PROVINCE: 'dlm_state',
  ZIP_OR_POSTAL: 'dlm_postal_code',
  DESCRIPTION: 'description',
  COUNTRY: 'dlm_country_code',
  WEBSITE_URL: 'dlm_url',
  PHONE_NUMBER: 'dlm_phone',
  FACEBOOK_URL: 'dlm_facebook_url',
  LATITUDE: 'latitude',
  TWITTER_URL: 'dlm_twitter_url',
  LONGITUDE: 'longitude',
  LINKED_IN_URL: 'dlm_linkedin_url',
  HIDE_ADDRESS: 'dlm_hide_address',
  ID: 'id',
  OWNER_EMAIL: 'owner_email',
  OWNER_NAME: 'owner_name',
  SYNUP_LOCATION_ID: 'synup_location_id',
  SYNUP_SUB_CATEGORY_ID: 'synup_sub_category_id',
  OPENING_DATE: 'dlm_opening_date',
  YEAR_OF_INCORPORATION: 'year_of_incorporation',
  CATEGORIES: 'dlm_categories',
  SOURCE_TYPE: 'dlm_source',
  SERVICE_AREA: 'dlm_service_area',
  APPOINTMENT_LINK: 'dlm_appointment_link_url'
};

export const COUNTRIES_CODE_DROPDOWN_OBJ = { id: '', value: '', label: '' };

export const STORE_DETAILS_OBJ_TYPE = {
  STORE_INFO: 'storeInfo',
  ADDRESS: 'address',
  CATEGORY: 'category',
  PAYMENT_METHODS: 'paymemtMethods',
  URL: 'urls',
  APPOINTMENT_LINK: 'appointmentLink'
};

export const CONNECTION_STATUS: directoryListingsContainerTypes.IDLFilterType = {
  ALL: 'All',
  REQUIRE_CONNECTION: 'Require Action',
  PENDING_CONNECTION: 'Connection pending',
  CONNECTED: 'Connected'
};

export const CONNECTION_STATUS_VALUE: directoryListingsContainerTypes.IDLFilterType = {
  ALL: 'all',
  REQUIRE_CONNECTION: 'require_action',
  PENDING_CONNECTION: 'connection_pending',
  CONNECTED: 'connected'
};

export const CONNECTION_STATUS_ARRAY = [
  { value: CONNECTION_STATUS_VALUE.ALL, label: CONNECTION_STATUS.ALL },
  { value: CONNECTION_STATUS_VALUE.CONNECTED, label: CONNECTION_STATUS.CONNECTED },
  { value: CONNECTION_STATUS_VALUE.PENDING_CONNECTION, label: CONNECTION_STATUS.PENDING_CONNECTION },
  { value: CONNECTION_STATUS_VALUE.REQUIRE_CONNECTION, label: CONNECTION_STATUS.REQUIRE_CONNECTION }
];

export enum DlAddPhotoTab {
  LOGO = 'LOGO',
  COVER = 'COVER',
  ADDITIONAL = 'ADDITIONAL'
}

export const DL_ADD_PHOTO_TAB_LIST: commonProps.IMainPageRouteData[] = [
  { name: DlAddPhotoTab.LOGO, label: 'Logo Photo' },
  { name: DlAddPhotoTab.COVER, label: 'Cover Photo' },
  { name: DlAddPhotoTab.ADDITIONAL, label: 'Additional Photo' }
];

export enum DlBusinessHoursType {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  OPEN_FULL_DAY = 'OPEN_24x7'
}

export const DL_BUSINESS_HOURS_DD_LIST: commonProps.IMainPageRouteData[] = [
  { name: DlBusinessHoursType.CLOSED, label: 'Closed' },
  { name: DlBusinessHoursType.OPEN, label: 'Open' },
  { name: DlBusinessHoursType.OPEN_FULL_DAY, label: 'Open 24 Hours' }
];

export enum BusinessWeekDays {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THRUSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export const DL_BUSINESS_HOUR_INIT_LIST: accountApiTypes.IBusinessHour[] = Object.values(BusinessWeekDays).map((it) => {
  return { day: it, slots: [], type: DlBusinessHoursType.CLOSED };
});

export const DL_BUSINESS_HOURS_PER_DAY: directoryListingsContainerTypes.IDLBusinessHoursTimeSlotObj[] = [
  {
    start: { selectedDate: null, utcTime: null },
    end: { selectedDate: null, utcTime: null }
  },
  {
    start: { selectedDate: null, utcTime: null },
    end: { selectedDate: null, utcTime: null }
  }
];

export const DL_BUSINESS_HOUR_INIT_TIME_SLOT_OBJ: directoryListingsContainerTypes.IDLBusinessHoursTimeSlot = {
  [BusinessWeekDays.MONDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.TUESDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.WEDNESDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.THRUSDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.FRIDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.SATURDAY]: DL_BUSINESS_HOURS_PER_DAY,
  [BusinessWeekDays.SUNDAY]: DL_BUSINESS_HOURS_PER_DAY
};

export const DL_BUSINESS_HOUR_WARNINGS: directoryListingsReducerTypes.IDLBusinessHoursWarnings = {
  endTimeWarn: null,
  startTimeWarn: null,
  secondSlotWarn: null
};

export const DL_SPECIAL_HOUR_WARNING: directoryListingsReducerTypes.IDLSpecialHoursWarnings = {
  startTimeWarn: null,
  endTimeWarn: null,
  dateWarn: null,
  sameDateWarn: null
};

export const VIEW_MY_LISTINGS_CONNECTION_PENDING = `Connection in progress no action required`;

export const VIEW_MY_LISTINGS_REQUIRE_ACTION = `Platform not connected please contact support@rallio.com`;

export const VIEW_MY_LISTINGS_CONNECTED = `Platform successfully connected`;

export enum DlmSourceType {
  RENDER_SEO = 'renderseo'
}

export const DL_SPECIAL_HOUR_MONTH_DATA: commonProps.IMainPageRouteData[] = Object.values(Month).map((it, index) => {
  const value = index + 1;
  return { name: value < 10 ? `0${value}` : `${value}`, label: it };
});

export const DL_RENDER_SEO_SOURCE_PAYMENT_METHOD_LIST: directoryListingsContainerTypes.IDlPaymentMethod[] = [
  { value: 'cash', label: 'Cash', imgSrc: 'cash' },
  { value: 'check', label: 'Checks', imgSrc: 'cheque' },
  { value: 'creditcard', label: 'Credit Cards', imgSrc: 'creditcard' },
  { value: 'amex', label: 'Amex', imgSrc: 'amex' },
  { value: 'chinaunionpay', label: 'China Union Pay', imgSrc: 'chinaunionpay' },
  { value: 'dinersclub', label: 'Diners Club', imgSrc: 'dinersclub' },
  { value: 'discover', label: 'Discover', imgSrc: 'discover' },
  { value: 'jcb', label: 'JCB', imgSrc: 'jcb' },
  { value: 'mastercard', label: 'Mastercard', imgSrc: 'master-card' },
  { value: 'visa', label: 'Visa', imgSrc: 'visa' },
  { value: 'debitcard', label: 'Debit Cards', imgSrc: 'debitcard' },
  { value: 'mealcoupon', label: 'Meal Coupon', imgSrc: 'mealcoupon' },
  { value: 'nfc', label: 'NFC Mobile Payments', imgSrc: 'nfc' },
  { value: 'gpay', label: 'Google Pay (Tez)', imgSrc: 'gpay' }
];

export const DL_COMMON_PAYMENT_METHOD_LIST: directoryListingsContainerTypes.IDlPaymentMethod[] = [
  { value: 'mastercard', label: 'Mastercard', imgSrc: 'master-card' },
  { value: 'visa', label: 'Visa', imgSrc: 'visa' },
  { value: 'amex', label: 'Amex', imgSrc: 'amex' },
  { value: 'cash', label: 'Cash', imgSrc: 'cash' },
  { value: 'check', label: 'Cheque', imgSrc: 'cheque' },
  { value: 'crypto', label: 'Crypto', imgSrc: 'crypto' }
];

export enum DlmCategoryType {
  SYNUP_SUB_CATEGORY = 'synup_sub_category',
  BUSINESS_PROFILE_CATEGORY = 'business_profile_category'
}

export const DL_STORE_DETAILS_CHECKFILEDS = {
  [STORE_DETAILS_OBJ_TYPE.STORE_INFO]: {
    [DL_STORE_DETAILS_TAB_FIELDS.NAME]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.STORE_CODE]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.DESCRIPTION]: false
  },
  [STORE_DETAILS_OBJ_TYPE.ADDRESS]: {
    [DL_STORE_DETAILS_TAB_FIELDS.ADDRESS]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.HIDE_ADDRESS]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.CITY]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.STATE_OR_PROVINCE]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.ZIP_OR_POSTAL]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.COUNTRY]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.PHONE_NUMBER]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.LATITUDE]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.LONGITUDE]: false
  },
  [STORE_DETAILS_OBJ_TYPE.URL]: {
    [DL_STORE_DETAILS_TAB_FIELDS.WEBSITE_URL]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.FACEBOOK_URL]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.TWITTER_URL]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.LINKED_IN_URL]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.APPOINTMENT_LINK]: false
  },
  [STORE_DETAILS_OBJ_TYPE.CATEGORY]: {
    [DL_STORE_DETAILS_TAB_FIELDS.PRIMARY_CATEGORY]: false,
    [DL_STORE_DETAILS_TAB_FIELDS.TAG_LINE]: false
  }
};
