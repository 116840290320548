import { useId } from 'react';
import Switch from 'react-switch';

export const ToggleSwitch = (props: any) => {
  const { id, handleOnChange, checkedStatus, height = 17.5, width = 35, disabled } = props;
  return (
    <Switch
      onChange={() => handleOnChange()}
      checked={checkedStatus}
      onColor="#1877F2"
      onHandleColor="#2693e6"
      handleDiameter={9}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 0px 2px 3px rgba(0, 0, 0, 0.2)"
      activeBoxShadow="0px 0px 2px 3px rgba(0, 0, 0, 0.2)"
      height={height}
      width={width}
      borderRadius={10}
      className={`react-switch-section ${checkedStatus ? 'active' : ''}`}
      id={`${id} ${useId()}`}
      disabled={disabled}
    />
  );
};
