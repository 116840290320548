import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { commonWidgetTypes } from 'types';
import { ImageValidation } from './ImageValidation';

export const CarousalImages = ({ customClassname, imagesList, showArrows, showThumbs, handleImageUrl, carouselWrapperClassName }: commonWidgetTypes.ICarouselImagesProps) => {
  return imagesList.length ? (
    <Carousel infiniteLoop swipeable autoPlay showStatus={false} showArrows={showArrows || false} showThumbs={showThumbs} showIndicators={imagesList.length <= 1 ? false : true} selectedItem={0}>
      {imagesList.map((photoItem, index: number) => {
        return (
          <div className={`gma-item all-fade-in ${carouselWrapperClassName || ''}`} key={`carousal-images-${index}`} {...(handleImageUrl && { onClick: () => handleImageUrl(photoItem) })}>
            <ImageValidation imgUrl={`${photoItem}#t=1`} customName={`Carousal Images ${index + 1}`} customClassname={customClassname} />
          </div>
        );
      })}
    </Carousel>
  ) : null;
};
