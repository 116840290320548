import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { commonWidgetTypes } from 'types';
import { DATE_PICKER_MIN_YEAR } from 'utils/constants';

export const CustomDatePicker = ({
  open,
  selectedDate,
  onChange,
  minDate,
  maxDate,
  minTime,
  maxTime,
  isShowTimeSelect,
  isShowTimeSelectOnly,
  timeInterval,
  dateFormat,
  customClassname,
  placeholderText,
  disabled,
  readOnly,
  timeCaption,
  name,
  onFocus,
  onBlur,
  onInputClick,
  onSelect,
  autoComplete,
  inline,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  timeInputLabel,
  showTimeInput,
  showMonthDropdown,
  showYearDropdown,
  showMonthYearPicker,
  dropdownMode,
  onCalendarOpen,
  onCalendarClose
}: commonWidgetTypes.ICustomDatePickerProps) => (
  <DatePicker
    open={open}
    selected={selectedDate}
    onChange={onChange}
    onChangeRaw={(event) => event.preventDefault()}
    minDate={minDate || DATE_PICKER_MIN_YEAR}
    maxDate={maxDate}
    minTime={minTime}
    maxTime={maxTime}
    isClearable
    showTimeSelect={isShowTimeSelect}
    showTimeSelectOnly={isShowTimeSelectOnly}
    timeIntervals={timeInterval}
    dateFormat={dateFormat}
    className={customClassname}
    placeholderText={placeholderText}
    disabled={disabled}
    readOnly={readOnly}
    timeCaption={timeCaption}
    name={name}
    onFocus={onFocus}
    onBlur={onBlur}
    onInputClick={onInputClick}
    onSelect={onSelect}
    autoComplete={autoComplete}
    inline={inline}
    selectsStart={selectsStart}
    selectsEnd={selectsEnd}
    startDate={startDate}
    endDate={endDate}
    timeInputLabel={timeInputLabel}
    showTimeInput={showTimeInput}
    showMonthDropdown={showMonthDropdown}
    showYearDropdown={showYearDropdown}
    dropdownMode={dropdownMode}
    popperPlacement={'bottom-start'}
    onCalendarOpen={onCalendarOpen}
    onCalendarClose={onCalendarClose}
    showMonthYearPicker={showMonthYearPicker}
  />
);
