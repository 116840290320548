import * as actionTypes from 'actions/action-types';
import { ActionsUnion, createAction } from 'utils/helpers';
import { resetPasswordContainerType } from 'types';

//  CONFIRM USER CREDENTIALS
export const confirmUserAccountFetchEmailRequest = (payload: resetPasswordContainerType.IUserAccountConfirmationEmail) => createAction(actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_REQUEST, payload);
export const confirmUserAccountFetchEmailResponse = (data: string) => createAction(actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_RESPONSE, data);
export const confirmUserAccountFetchEmailFailure = (error: string) => createAction(actionTypes.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_FAILURE, error);

// RESSET PASSWORD
export const resetPasswordRequest = (payload: resetPasswordContainerType.IUserResetPasswordReqPayload) => createAction(actionTypes.RESET_PASSWORD_REQUEST, payload);
export const resetPasswordResponse = (data: string) => createAction(actionTypes.RESET_PASSWORD_RESPONSE, data);
export const resetPasswordFailure = (error: string) => createAction(actionTypes.RESET_PASSWORD_FAILURE, error);

// RESSET PASSWORD RESET
export const resetPasswordErrorMsgReset = () => createAction(actionTypes.RESET_PASSWORD_ERROR_MSG_RESET);

const RESET_PASSWORD_ACTIONS = {
  confirmUserAccountFetchEmailRequest,
  confirmUserAccountFetchEmailResponse,
  confirmUserAccountFetchEmailFailure,
  resetPasswordRequest,
  resetPasswordResponse,
  resetPasswordFailure,
  resetPasswordErrorMsgReset
};

export type ResetPasswordActions = ActionsUnion<typeof RESET_PASSWORD_ACTIONS>;
