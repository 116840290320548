import { adminAreaContainerTypes } from 'types';
import { MAIN_NAV_BAR_NAME, ROUTE_TABS } from '../app-routes';

export const ACCOUNTS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'Created', value: 'created' },
  { title: 'Dashboard', value: 'dashboard' },
  { title: 'Setup', value: 'setup' }
];

export const FRANCHISORS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'Created', value: 'created' },
  { title: 'Stats', value: 'stats' },
  { title: 'Report', value: 'report' },
  { title: 'Dash', value: 'dash' },
  { title: 'Franchisor Content', value: 'franchisorContent' },
  { title: 'New Location', value: 'newLocation' }
];

export const USERS_TABLE_HEADER = [
  { title: 'Name', value: 'name' },
  { title: 'User', value: 'user' },
  { title: 'Company', value: 'company' },
  { title: 'Admin?', value: 'admin' },
  { title: 'Become', value: 'become' }
];

export const FRAN_STATS_TABLE_HEADER = [
  { title: 'Franchisee Name', value: 'franchiseeName' },
  { title: 'Next unapproved post', value: 'noPost' },
  { title: '# Posts', value: 'posts' },
  { title: '# Approved Posts', value: 'approvedPosts' }
];

export const USER_DETAILS_FILEDS: adminAreaContainerTypes.IUserDetailsField = {
  NAME: 'name',
  EMAIL: 'email'
};

export const ADMIN_AREA_ROUTES = ['/admin_area/accounts', '/admin_area/franchisors', '/admin_area/users'];

export const COMMON_ROUTES = [
  { menu: ROUTE_TABS.VIDEO_TUTORIALS, link: MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS },
  { menu: ROUTE_TABS.AI_ASSISTANT, link: MAIN_NAV_BAR_NAME.AI_ASSISTANT }
];

export enum AdminAreaAccountsValidQueryParams {
  SEARCH = 'text',
  INCLUDE_HIDDEN_ACCOUNTS = 'include_hidden_accounts'
}

export enum AdminAreaValidQueryParams {
  SEARCH = 'text'
}
