import { profileImageryApiTypes } from 'types';

/** Get profile imagery list based on query params */
export const getFilteredProfileImgList = (profileImageryList: profileImageryApiTypes.IPlatformsProfileImagery[], ancestorFranchisorId: number, locationListId: number, locationId: number) => {
  let filteredProfileImageryList: profileImageryApiTypes.IPlatformsProfileImagery[] = profileImageryList || [];
  if (ancestorFranchisorId || locationListId || locationId) {
    filteredProfileImageryList = profileImageryList.filter((filterItem) => {
      return filterItem.ancestor_franchisor_id === ancestorFranchisorId || filterItem.account_list_ids.includes(locationListId) || filterItem.account_id === locationId;
    });
  }
  return filteredProfileImageryList;
};
