import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { loginApiType, loginContainerType, loginReducerType, mgApiTypes } from 'types';

// APP LOGIN
export const loginRequest = (payload: loginContainerType.ILoginRequestPayload | { sso_token: string }) => createAction(actionTypes.LOGIN_REQUEST, payload);
export const loginResponse = (data: loginReducerType.ILoginResponseObj) => createAction(actionTypes.LOGIN_RESPONSE, data);
export const loginRequestFailure = (error: null | string) => createAction(actionTypes.LOGIN_REQUEST_FAILURE, error);

// APP OAUTH USER VALIDATE
export const oauthUserValidateRequest = (payload: string) => createAction(actionTypes.OAUTH_USER_VALIDATE_REQUEST, payload);
export const oauthUserValidateResponse = (data: loginApiType.IOauthUserValidResponse) => createAction(actionTypes.OAUTH_USER_VALIDATE_RESPONSE, data);
export const oauthUserValidateFailure = (error: null | string) => createAction(actionTypes.OAUTH_USER_VALIDATE_FAILURE, error);

// GET OAUTH SIGN ON TOKENS
export const getOauthSignOnTokensRequest = (payload: number) => createAction(actionTypes.GET_OAUTH_SIGN_ON_TOKENS_REQUEST, payload);
export const getOauthSignOnTokensResponse = (data: loginApiType.IOauthSignOnTokens) => createAction(actionTypes.GET_OAUTH_SIGN_ON_TOKENS_RESPONSE, data);
export const getOauthSignOnTokensFailure = (error: null | string) => createAction(actionTypes.GET_OAUTH_SIGN_ON_TOKENS_FAILURE, error);

// APP LOGIN ENCODED USER INFO FOR REMEMBER ME SELECTION
export const loginUpdateEncodedUserInfo = (payload: null | string) => createAction(actionTypes.LOGIN_UPDATED_ENCODED_USER_INFO, payload);

// LOGIN RESET
export const loginErrorReset = () => createAction(actionTypes.LOGIN_ERROR_RESET);

// APP LOGOUT
export const logoutRequest = () => createAction(actionTypes.LOGOUT_REQUEST);
export const logoutResponse = (data: string | null) => createAction(actionTypes.LOGOUT_RESPONSE, data);

// SET ACCOUNT NAME
export const setAccountName = (payload: string) => createAction(actionTypes.SET_ACCOUNT_NAME, payload);

// USER - UPLOAD CLOUDINARY PROFILE IMAGE
export const userUploadCloudProfileImgRequest = (payload: mgApiTypes.IMGUploadCloudMediaRequest) => createAction(actionTypes.USER_CLOUD_PROFILE_IMG_UPLOAD_REQUEST, payload);
export const userUploadCloudProfileImgResponse = (data: loginApiType.IUserUploadProfileImgResp) => createAction(actionTypes.USER_CLOUD_PROFILE_IMG_UPLOAD_RESPONSE, data);
export const userUploadCloudProfileImgFailure = (error: null | string) => createAction(actionTypes.USER_CLOUD_PROFILE_IMG_UPLOAD_FAILURE, error);

export const userSetCloudProfileImg = (data: loginApiType.IUserUploadProfileImgResp) => createAction(actionTypes.USER_SET_CLOUD_PROFILE_IMG, data);

// USER - UPDATE USER
export const updateUserDetailRequest = (payload: loginApiType.IUpdateUserDetailRequest) => createAction(actionTypes.UPDATE_USER_DETAIL_REQUEST, payload);
export const updateUserDetailResponse = (data: loginReducerType.ILoginResponseUser) => createAction(actionTypes.UPDATE_USER_DETAIL_RESPONSE, data);
export const updateUserDetailFailure = (error: null | string) => createAction(actionTypes.UPDATE_USER_DETAIL_FAILURE, error);

export const isUserDataUpdated = (payload: boolean) => createAction(actionTypes.IS_USER_DATA_UPDATED, payload);

// GET LOG-IN USER DATA
export const getLoginUserDataRequest = (payload: number) => createAction(actionTypes.GET_LOGIN_USER_DATA_REQUEST, payload);
export const getLoginUserDataResponse = (data: loginReducerType.ILoginResponseUser) => createAction(actionTypes.GET_LOGIN_USER_DATA_RESPONSE, data);
export const getLoginUserDataFailure = (error: null | string) => createAction(actionTypes.GET_LOGIN_USER_DATA_FAILURE, error);

// IMPERSONATE USER
export const impersonateUserRequest = (payload: loginApiType.IImpersonateUserRequest) => createAction(actionTypes.IMPERSONATE_USER_REQUEST, payload);
export const impersonateUserResponse = (payload: loginReducerType.ILoginResponseObj) => createAction(actionTypes.IMPERSONATE_USER_RESPONSE, payload);
export const impersonateUserFailure = (payload: null | string) => createAction(actionTypes.IMPERSONATE_USER_FAILURE, payload);

export const restoreSession = (activeUser: number) => createAction(actionTypes.RESTORE_SESSION, activeUser);

export const setAuthenticate = (data: boolean) => createAction(actionTypes.SET_AUTHENTICATE, data);

const LOGIN_ACTIONS = {
  loginRequest,
  loginResponse,
  loginRequestFailure,
  oauthUserValidateRequest,
  oauthUserValidateResponse,
  oauthUserValidateFailure,
  getOauthSignOnTokensRequest,
  getOauthSignOnTokensResponse,
  getOauthSignOnTokensFailure,
  loginUpdateEncodedUserInfo,
  loginErrorReset,
  logoutRequest,
  logoutResponse,
  setAccountName,
  userUploadCloudProfileImgRequest,
  userUploadCloudProfileImgResponse,
  userUploadCloudProfileImgFailure,
  userSetCloudProfileImg,
  updateUserDetailRequest,
  updateUserDetailResponse,
  updateUserDetailFailure,
  getLoginUserDataRequest,
  getLoginUserDataResponse,
  getLoginUserDataFailure,
  impersonateUserRequest,
  impersonateUserResponse,
  impersonateUserFailure,
  restoreSession,
  setAuthenticate,
  isUserDataUpdated
};

export type LoginAction = ActionsUnion<typeof LOGIN_ACTIONS>;
