import { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/esm/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete';

import { CustomReactTags } from 'widgets/CustomReactTags';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import {
  TOOLTIP_PLACEMENT,
  AP_SAVED_POST_INIT_DETAILS,
  AP_EXTRA_INFO_TAB_NAMES,
  AP_ACTION_TYPES,
  AP_LOCK_POST_TOOLTIP,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CREATOR_EDIT_INIT_OBJ,
  MODAL_BOX_TYPES,
  EDIT_POST_CONFIRM_MSG
  // ADD_POST_CONTENT_MAX_TEXT_LENGTH
} from 'utils/constants';
import { AddTextSection } from './AddTextSection';
import { addPostUpdateSavedPostDetails, addPostSetLinkData, postsTagsRequest, addPostSetTags, addPostGetEditSavedPostObj, addPostSetEditConfirmForApprovedPost, addPostSetActiveTab } from 'actions';
import { apAccountContainerTypes, IStore } from 'types';
import { formNewInternalTag, dateObjBasedOnTimeZone, getUpdatedLinkInSavedPost, debounceMethod, getUpdatedLinkTextInSavedPost, getAccValidSocialMediaForCreator } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { InternalNotesSection } from './InternalNotesSection';
import { ModalPopup } from 'components';
import { EditorStateContext } from '..';
// import { regexes } from 'widgets/DraftjsHashtagsPlugin/hashtagRegex';

export const LeftCreatorPostSection = (props: apAccountContainerTypes.IAddPostCommonProps) => {
  const dispatch = useDispatch();

  const { activeContentList } = props;
  const { id } = useAccountSwitcherData();

  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const internalTagsList = useSelector((state: IStore) => state.posts.postsTagsList);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isShowModal = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isShowModal);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const gmbFeatureEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account.gmb_feature_enabled || false);
  const accountProfiles = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles || []);
  const activeTab = useSelector((state: IStore) => state.addPostFranchisorAction.activeTab);

  let tagsApiTimeout: null | NodeJS.Timeout = null;

  const [searchValue, setSearchValue] = useState<null | string>(null);

  const activeSocialMedia = getAccValidSocialMediaForCreator(accountProfiles, gmbFeatureEnabled);

  // const hashTagsLimit = savedPostDetails?.[`${COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM}_text`].match(regexes.validHashtag) || [];

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];

  /* const handleActiveSocialMedia = (socialMedia: string) => {
    return savedPostDetails?.[`${socialMedia}_text`].length && savedPostDetails?.[`${socialMedia}_text`].length <= ADD_POST_CONTENT_MAX_TEXT_LENGTH[socialMedia.toUpperCase()] ? true : false;
  }; */
  //  The post can't be posted in instagram when medias are not available
  // useEffect(() => {
  //   if (savedPostDetails) {
  //     dispatch(
  //       addPostUpdateSavedPostDetails({
  //         ...savedPostDetails,
  //         use_instagram: !selectedMediaList.length || hashTagsLimit.length > 30 ? false : savedPostDetails.use_instagram
  //       })
  //     );
  //   }
  // }, [selectedMediaList.length]); // eslint-disable-line

  // useEffect(() => {
  //   if (savedPostDetails) {
  //     dispatch(
  //       addPostUpdateSavedPostDetails({
  //         ...savedPostDetails,
  //         use_instagram: !selectedMediaList.length || hashTagsLimit.length > 30 ? false : handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM),
  //         use_twitter: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER),
  //         use_linkedin: handleActiveSocialMedia(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
  //       })
  //     );
  //   }
  // }, [selectedMediaList.length]); // eslint-disable-line

  useEffect(() => {
    if (addPostAction === AP_ACTION_TYPES.ADD && accountDetails?.account?.tzinfo_name && !savedPostDetails) {
      dispatch(
        addPostUpdateSavedPostDetails({
          ...AP_SAVED_POST_INIT_DETAILS,
          current_tab: activeSocialMedia.length ? activeSocialMedia[0] : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
          schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || ''),
          account_schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || '')
        })
      );
      dispatch(addPostGetEditSavedPostObj(CREATOR_EDIT_INIT_OBJ));
    }
  }, [addPostAction, accountDetails?.account?.tzinfo_name, savedPostDetails]); // eslint-disable-line

  //  Add link url to social media's text as hyperlink and remove link preview if medias are available
  useEffect(() => {
    if (savedPostDetails && activeSocialMedia.length && selectedMediaList.length && linkData?.url) {
      dispatch(addPostUpdateSavedPostDetails(getUpdatedLinkInSavedPost(activeSocialMedia, savedPostDetails, linkData)));
      setEditorState(getUpdatedLinkTextInSavedPost(activeSocialMedia, savedPostDetails, linkData));
      // dispatch(addPostSetCustomTextEditorData(getUpdatedLinkTextInSavedPost(activeSocialMedia, savedPostDetails, linkData)));
      dispatch(addPostSetLinkData(null));
    }
  }, [savedPostDetails, activeSocialMedia.length, selectedMediaList.length, linkData?.url]); // eslint-disable-line

  const tagsList = formNewInternalTag(internalTagsList, searchValue);

  const handleInputChange = (search: string) => {
    setSearchValue(search || null);
    dispatch(postsTagsRequest({ searchTag: search }));
  };

  const handleExtraInfoTabSelect = (selectedTab: string | null) => {
    dispatch(addPostSetActiveTab(selectedTab || AP_EXTRA_INFO_TAB_NAMES.SETTINGS));
  };

  const handleUpdateSettingsCheckbox = (socialMediaKey: string, socialMediaValue: boolean) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      dispatch(
        addPostUpdateSavedPostDetails(
          savedPostDetails
            ? {
                ...savedPostDetails,
                [socialMediaKey]: socialMediaValue
              }
            : null
        )
      );
    }
  };

  const handleAddTags = (tag: Tag) => {
    tag.id = !internalTagsList.length ? Math.floor(Math.random() * 100) : tag.id;
    tag.name = !internalTagsList.length ? tag.name.replace('Add ', '') : tag.name;
    const addedTagsArray: Tag[] = [...selectedTags, tag];
    dispatch(addPostSetTags(addedTagsArray));
    setSearchValue(null);
  };

  const handleDeleteTag = (tagName: string) => {
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      dispatch(addPostSetTags(selectedTags.filter((datum) => datum.name !== tagName)));
      setSearchValue(null);
    }
  };

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  return (
    <>
      <div className="post-content">
        <div className="card-bg active">
          <div className="post-tabs">
            <AddTextSection socialMediaList={activeSocialMedia} addContentList={activeContentList} />

            {/* Extra Information for creating post */}
            <div className={`creator-settings-wrp`}>
              <Tabs activeKey={activeTab} onSelect={(key) => handleExtraInfoTabSelect(key)}>
                <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.SETTINGS} title="Settings">
                  <div className={`form-check${savedPostDetails?.locked ? ` pointer-events-none` : ``}`}>
                    <label className="checkbox-item">
                      <span className="checkbox-hover">
                        <input
                          type="checkbox"
                          name="mobile_app_only_enabled"
                          className="option-input click-wave"
                          disabled={savedPostDetails?.locked || false}
                          checked={savedPostDetails?.mobile_app_only_enabled || false}
                          onChange={(event) => handleUpdateSettingsCheckbox(event.target.name, event.target.checked)}
                        />
                      </span>
                      <div className="label-txt">
                        Employee Advocates
                        <CustomTooltip
                          customPlacement={TOOLTIP_PLACEMENT.TOP}
                          customClassname={'custom-tooltip-long-text'}
                          customTooltipText="Checking this option provides your Employee Advocates the ability to post
                          this content to their personal profiles."
                          customInput={() => (
                            <div className="info__tool--tip">
                              <ImageValidation defaultImg="information-grey" customName={'activate'} />
                            </div>
                          )}
                        />
                      </div>
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className={`form-check${editSavedPost?.syndicated_from_content_id ? ` pointer-events-none` : ``}`}>
                    <label className="checkbox-item">
                      <span className="checkbox-hover">
                        <input
                          type="checkbox"
                          name="locked"
                          className="option-input click-wave"
                          checked={savedPostDetails?.locked || false}
                          disabled={editSavedPost?.syndicated_from_content_id || false}
                          onChange={(event) => handleUpdateSettingsCheckbox(event.target.name, event.target.checked)}
                        />
                      </span>
                      <div className="label-txt">
                        Lock Post
                        <CustomTooltip
                          customPlacement={TOOLTIP_PLACEMENT.TOP}
                          customClassname={'custom-tooltip-long-text'}
                          customTooltipText={AP_LOCK_POST_TOOLTIP}
                          customInput={() => (
                            <div className="info__tool--tip">
                              <ImageValidation defaultImg="information-grey" customName={'activate'} />
                            </div>
                          )}
                        />
                      </div>
                      <span className="checkmark" />
                    </label>
                  </div>
                </Tab>

                <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.INTERNAL_TAGS} title="Internal Tags">
                  <div className="tags">
                    <div className="wb-top">
                      <div className="creator-internaltags-wrp-section">
                        <div className="wb-fields">
                          <div className="wbf-item">
                            <div className="wbf-inputs">
                              <div className="input-fields">
                                <div className="if-main if-h">
                                  <CustomReactTags
                                    key={`${id}-${creatorCompName}`}
                                    suggestions={tagsList}
                                    handleInputChange={(search: string) => {
                                      tagsApiTimeout = debounceMethod(search, tagsApiTimeout, handleInputChange);
                                    }}
                                    handleDelete={() => false}
                                    maxSuggestionsLength={tagsList.length}
                                    handleAddition={(tag: Tag) => handleAddTags(tag)}
                                    placeholder="Search"
                                    autofocus={false}
                                    inputAttributes={{
                                      disabled: savedPostDetails?.locked || false,
                                      onClick: () => {
                                        if (isPostEditable) {
                                          handleEditConfirmation();
                                        }
                                      }
                                    }}
                                    allowNew
                                    minQueryLength={1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {selectedTags.length ? (
                          <div className="tags-wrp">
                            {selectedTags.map((tagDatum, index) => {
                              return (
                                <div className="list-expanded-tag-item" key={`loc-creator-tag-${index}`}>
                                  <span className="list-expanded-tag-text">{tagDatum.name}</span>
                                  {!savedPostDetails?.locked && <span className="list-expanded-tag-remove" onClick={() => handleDeleteTag(tagDatum.name)} />}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey={AP_EXTRA_INFO_TAB_NAMES.INTERNAL_NOTES} title="Internal Notes">
                  <InternalNotesSection />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <ModalPopup
        isModalShow={isShowModal}
        modalHeaderType={MODAL_BOX_TYPES.INFO}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <>
            <div className="edit-approval-post-wrp-modal">
              <div className="modal-message-wraps-text">{EDIT_POST_CONFIRM_MSG}</div>
            </div>
          </>
        )}
        modalCloseButton
        handleModalClose={() => {
          dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: false, isEditConfirmed: false }));
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button
              className="modal-btn-action-itm modal-cancel-btn"
              onClick={() => {
                dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: false, isEditConfirmed: false }));
              }}
            >
              Cancel
            </button>
            <button
              className="modal-btn-action-itm modal-ok-btn"
              onClick={() => {
                dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: false, isEditConfirmed: true }));
              }}
            >
              OK
            </button>
          </div>
        )}
      />
    </>
  );
};
