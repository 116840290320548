import { calendarReducerTypes, calendarContainerTypes } from 'types/calendar';
import { CalendarViewTypes } from 'utils/constants';

export const UPDATE_POST_INITIAL_STATUS: calendarReducerTypes.IUpdatePostDetails = {
  isUpdated: false,
  updateSuccessMsg: null,
  updateFailureMsg: null
};

export const SCHEDULE_PARAM_TYPE = {
  SCHEDULE_FOR: 'scheduled_for',
  BIG_TEXT: 'bigText',
  REFRESH: 'refresh',
  HIDDEN: 'hidden'
};

export enum CalendarStatsType {
  SCHEDULED = 'scheduled',
  AWAITING_APPROVAL = 'awaiting_approval',
  EVENTS = 'events'
}

export const CALENDER_STATS: calendarContainerTypes.ICalendarStats[] = [
  { value: CalendarStatsType.SCHEDULED, label: 'Scheduled', isHub: true, image: 'cal-schedule-icon.svg', name: 'scheduledsection-wrp' },
  { value: CalendarStatsType.AWAITING_APPROVAL, label: 'Awaiting Approval', isHub: false, image: 'cal-waiting-icon.svg', name: 'approval-wrp' }
  // { value: CalendarStatsType.EVENTS, label: 'Events', isHub: false, image: 'cal-events-icon.svg', name: 'events-wrp' }
];

export const CALENDAR_EVENT_CHECK_BOX = [
  { label: 'Event', response: 'request_type_event_created' },
  { label: 'Post', response: 'request_type_post_created' },
  { label: 'Promotion', response: 'request_type_promotion_created' }
];

export const CALENDAR_VIEW_DATE_RANGE = {
  startDate: new Date(),
  endDate: null,
  calendarView: CalendarViewTypes.MONTH_VIEW
};

export const CALENDAR_PAGE_VIEW_TYPES = {
  LIST: 'list',
  CALENDAR: 'calendar'
};

export const CALENDAR_FILTER_INITIAL_VALUE = {
  platform: 'all',
  view: 'list'
};

export enum CalendarValidQueryParams {
  PLATFORM = 'platform',
  VIEW = 'view'
}

export const AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS = {
  NEVER_SCHEDULE: 'never_schedule',
  LATER_USE: 'later_use',
  REPLACE_POST: 'replace_post',
  EMPTY_SLOT: 'empty_slot',
  REMOVE_ALL_POST: 'remove_all_post'
};

export const AUTOMATED_SCHEDULED_POSTS_DELETE_OPTIONS = [
  { name: 'posts', key: AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.NEVER_SCHEDULE, value: 1, label: 'Delete and never schedule again' },
  { name: 'posts', key: AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.LATER_USE, value: 2, label: 'Delete and use at a later date' }
];

export const AUTOMATED_POSTS_DELETE_TIME_SLOT_OPTIONS = [
  { name: 'timeSlot', key: AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.REPLACE_POST, value: 1, label: 'Replace this post with another unused post' },
  { name: 'timeSlot', key: AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.EMPTY_SLOT, value: 2, label: 'Leave this (:time-slot) slot empty' }, // eslint-disable-line
  {
    name: 'timeSlot',
    key: AUTOMATED_SCHEDULED_POSTS_SLOT_DELETE_OPTIONS.REMOVE_ALL_POST,
    value: 3,
    label: 'Leave this slot empty, remove all scheduled :time-slot posts, and no longer schedule them at this time'
  }
];
