import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { getFormatBasedOnExactDate } from 'utils/helpers';
import { IStore, calendarApiTypes } from 'types';
import { updateCalendarItemRequest } from 'actions';
import { LOCATION_TIME_ZONE_DATE_TIME_FORMAT, CALENDAR_EVENT_CHECK_BOX, CALENDAR_EVENT_DATE_FORMAT } from 'utils/constants';
import { TextArea } from 'widgets/Text';
interface ICalendarEventViewCompType {
  calendarEventItemList: calendarApiTypes.ICalendarItemsResponse[];
  // accountName: string;
}

export const CalendarEventViewComp = ({ calendarEventItemList }: ICalendarEventViewCompType) => {
  const dispatch = useDispatch();

  const accountName = useSelector((state: IStore) => state.accountSwitcher.currentName);

  const calenderEventUpdateHandler = (dataObj: calendarApiTypes.ICalendarItemsResponse, type: string) => {
    const payload = { ...dataObj, [`${type}`]: !dataObj[type] };
    dispatch(updateCalendarItemRequest(payload));
  };

  return (
    <>
      {calendarEventItemList?.map((datum, index: number) => {
        const userName = `${datum?.modified_by_user?.first_name || ''} ${datum?.modified_by_user?.last_name || ''}`;
        return (
          <div className="li-view-main" key={`calendar-events-${index}`}>
            <div className="liv-left li-top">
              <ImageValidation isImgValid imgUrl={datum?.modified_by_user?.profile_photo_url || ''} defaultImg="user-avatar-common.png" isNotSvgFormat customName="Event user profile img" />
              <div className="lvt-details">
                <h3 title={userName}>{userName}</h3>
                <div className="lvt-brief">
                  <span className="lvt-txt">Requested to</span>
                </div>
              </div>
            </div>

            <div className="liv-right list-item">
              <div className="header-section">
                <div className="left-section">
                  <div className="p-header">
                    <div className="p-header-title">
                      <div className="p-avatar">
                        <ImageValidation
                          isImgValid
                          imgUrl={datum?.modified_by_user?.avatar_url}
                          defaultImg="user-avatar-common.png"
                          isNotSvgFormat
                          customClassname="events-thumb"
                          customName="User profile"
                        />
                      </div>
                      <div className="p-title">
                        <h5>{accountName}</h5>
                        <h6>{`@${getFormatBasedOnExactDate(datum?.start_datetime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT)}`}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc-title">{datum?.title || ''}</div>
              <div className="desc-text">{datum?.description || ''}</div>
              <div className="cal-event-notes-wrap">
                <div className="event-section-main-wrp">
                  <div className="left-section">
                    <div className="calendr-notify-section">
                      <div className="first-itm">
                        <div>Frequency</div>
                        <div>Start Time</div>
                        <div>End Time</div>
                        <div>Location</div>
                      </div>
                      <div className="last-itm">
                        <div className="last-item-text">{datum?.recurrence_type}</div>
                        <div className="last-item-text">{getFormatBasedOnExactDate(datum?.start_datetime, CALENDAR_EVENT_DATE_FORMAT)} </div>
                        <div className="last-item-text">{getFormatBasedOnExactDate(datum?.end_datetime, CALENDAR_EVENT_DATE_FORMAT)}</div>
                        <div className="last-item-text">{datum.address}</div>
                      </div>
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="p-avatar">
                      <ImageValidation isImgValid defaultImg="event-calendar-notify-img" customName="event-calendar" />
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <div className="intrnal-notes-section-wrp">
                  <div className="desc-title">Internal Notes</div>
                  <div className="p-header">
                    <div className="p-header-title">
                      <div className="p-avatar">
                        <ImageValidation
                          isImgValid
                          imgUrl={datum?.modified_by_user?.avatar_url}
                          defaultImg="user-avatar-common.png"
                          isNotSvgFormat
                          customClassname="events-thumb"
                          customName="User profile"
                        />
                      </div>
                      <div className="p-title">
                        <h5>{accountName}</h5>
                        <h6>{`@${getFormatBasedOnExactDate(datum?.start_datetime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT)}`}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="add-internal-notes">
                    <div className="aintrn-main" />
                    <div className="ap-small-textarea">
                      <TextArea className="form-control" placeholder="Internal Notes" disabled readOnly defaultValue={datum.notes || ''} maxRows={2} />
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <div className="calendar-flr-btn-wrp internal-notes-btn-wrp">
                  {CALENDAR_EVENT_CHECK_BOX.map((it, index) => {
                    return (
                      <div key={index} className="btn cancel-btn">
                        <div className="form-check">
                          <label className="checkbox-item">
                            <input type="checkbox" name={it.response} checked={datum[it.response]} onChange={(event) => calenderEventUpdateHandler(datum, event.target.name)} />
                            {it.label}
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
