import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tab, Tabs } from 'react-bootstrap';

import { currentUserDetailsReset, getCurrentUserDetailsRequest, setCurrentUserActiveTab } from 'actions';
import { commonModalPopup, IStore } from 'types';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { USER_DETAIL_MODAL_TAB_KEY, USER_DETAIL_MODAL_TABS } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { EditUserDetail } from './EditUserDetail';
import { UserMediaList } from './UserMediaList';
import { UserPostsList } from './UserPostList';
import { UserRewardsList } from './UserRewardList';

export const UserDetailModal = ({ isShowModal, onModalClose, updateUserDetails }: commonModalPopup.IUserDetailModalProps) => {
  const dispatch = useDispatch();

  const { isAdminScreenEnabled } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const isEditUser = useSelector((state: IStore) => state.common.userDetailObj.isEditUser);
  const activeTab = useSelector((state: IStore) => state.common.userDetailObj.activeTab);
  const error = useSelector((state: IStore) => state.common.userDetailObj.error);

  const userId = queryParams?.user_id || queryParams?.active_user_id;

  useEffect(() => {
    if (userId) {
      dispatch(getCurrentUserDetailsRequest(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (error) {
      handleCloseUserDetailModal();
    }
  }, [error]); // eslint-disable-line

  const handleCloseUserDetailModal = () => {
    if (onModalClose) {
      onModalClose();
      dispatch(currentUserDetailsReset());
    }
  };

  const renderTabData = (tabName: string) => {
    return tabName === activeTab ? (
      [USER_DETAIL_MODAL_TAB_KEY.PHOTOS, USER_DETAIL_MODAL_TAB_KEY.VIDEOS, USER_DETAIL_MODAL_TAB_KEY.DOCUMENTS].includes(tabName) ? (
        <UserMediaList />
      ) : tabName === USER_DETAIL_MODAL_TAB_KEY.POSTS ? (
        <UserPostsList />
      ) : tabName === USER_DETAIL_MODAL_TAB_KEY.REWARDS ? (
        <UserRewardsList />
      ) : null
    ) : null;
  };

  return (
    <Modal
      className={`profileinfomodal-section-wrp upm-mains ${isEditUser ? 'up-edit-active' : 'up-no-edit'}${isAdminScreenEnabled ? ' userProfile-top-only' : ''}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      show={isShowModal}
      animation={false}
    >
      <Modal.Body>
        <div className={`upm-wrap`}>
          <div className={`profile__modal--content gwhite-bg`}>
            <div className="mod__close--icon">
              <ImageValidation isImgValid defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} onClick={handleCloseUserDetailModal} />
            </div>
            <EditUserDetail updateUserDetails={updateUserDetails} />
          </div>
          {!isAdminScreenEnabled && (
            <div className="addTabs gwhite-bg">
              <div className="sub-nav-tabs">
                <Tabs id="add-tabs" activeKey={activeTab} onSelect={(key: null | string) => key && key !== activeTab && dispatch(setCurrentUserActiveTab(key))}>
                  {USER_DETAIL_MODAL_TABS.map((tab, index) => (
                    <Tab eventKey={tab.key} title={tab.title} key={`user-tab-${index}`}>
                      {renderTabData(tab.key)}
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
