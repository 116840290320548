import { Reducer } from 'redux';

import produce from 'immer';
import * as actionTypes from '../../actions/action-types';
import { IDownload } from 'revv/types';
import { DownloadActions } from 'revv/actions';

const initialState: IDownload = {
  isFetching: false,
  isExportInProgress: false,
  exportRequest: null,
  dashboardReportResponse: null,
  dashboardComparisonReportResponse: null,
  error: '',
  isPollRequest: false,
  reportName: ''
};

export const downloadReducer: Reducer<IDownload, DownloadActions> = (prevState: IDownload = initialState, action: DownloadActions) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_SURVEYS_FILTERED_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.DOWNLOAD_SURVEYS_FILTERED_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.isPollRequest = false;
        if (action.payload) {
          nextState.isExportInProgress = true;
        } else {
          nextState.isExportInProgress = false;
        }
        nextState.exportRequest = action.payload;
      });
    case actionTypes.DOWNLOAD_SURVEYS_FILTERED_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.DOWNLOAD_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.error = '';
      });
    case actionTypes.DOWNLOAD_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.error = action.payload;
      });
    case actionTypes.DOWNLOAD_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isFetching = false;
        nextState.isPollRequest = false;
        if (action.payload) {
          nextState.isExportInProgress = true;
        } else {
          nextState.isExportInProgress = false;
        }
        nextState.exportRequest = action.payload;
      });
    case actionTypes.POLL_EXPORT_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isPollRequest = true;
      });
    case actionTypes.POLL_EXPORT_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.error = action.payload;
        nextState.isPollRequest = false;
      });
    default:
      return prevState;
  }
};
