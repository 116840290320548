import { Reducer } from 'redux';

import produce from 'immer';
import { ISurveys } from 'revv/types';
import * as actionTypes from 'revv/actions/action-types';
import { CommonActions, SurveyActions } from 'revv/actions';

const initialState: ISurveys = {
  isMetricsFetching: true,
  successMsg: '',
  errorMsg: '',
  surveyMetrics: null,
  isFetching: false,
  currentPage: 1,
  surveyList: null,
  sendQueuedSurveyMsg: {
    isFetching: false,
    successMsg: null,
    errorMsg: null
  },
  sendSurveyMsg: {
    isFetching: false,
    successMsg: null,
    errorMsg: null
  },
  customerBasedSurveyList: [],
  isCustomerBasedSurveyListFetching: false,
  errorMsgCustomerBasedSurvey: '',
  customerBasedSurveyPageNum: 1
};

export const surveysReducer: Reducer<ISurveys, SurveyActions> = (prevState: ISurveys = initialState, action: SurveyActions | CommonActions) => {
  switch (action.type) {
    case actionTypes.SURVEY_METRICS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = true;
        nextState.errorMsg = '';
      });
    case actionTypes.SURVEY_METRICS_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.errorMsg = action.payload;
      });
    case actionTypes.SURVEY_METRICS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isMetricsFetching = false;
        nextState.errorMsg = '';
        nextState.surveyMetrics = action.payload;
      });
    case actionTypes.SURVEY_LIST_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.errorMsg = '';
        nextState.currentPage = action.payload.pageNumber;
      });
    case actionTypes.SURVEY_LIST_REQUEST_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.errorMsg = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.SURVEY_LIST_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.errorMsg = '';
        nextState.isFetching = false;
        if (prevState.currentPage > 1) {
          if (prevState.surveyList && prevState.surveyList?.length > 0) {
            nextState.surveyList = prevState.surveyList.concat(action.payload || []);
          }
        } else {
          nextState.surveyList = action.payload;
        }
      });
    case actionTypes.CUSTOMER_SEND_SURVEY_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isFetching = true;
        nextState.errorMsg = '';
        nextState.successMsg = '';
      });
    case actionTypes.CUSTOMER_SEND_SURVEY_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.errorMsg = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.CUSTOMER_SEND_SURVEY_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.successMsg = action.payload;
        nextState.isFetching = false;
      });
    case actionTypes.SEND_QUEUED_SURVEYS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.sendQueuedSurveyMsg.successMsg = '';
        nextState.sendQueuedSurveyMsg.errorMsg = '';
        nextState.sendQueuedSurveyMsg.isFetching = true;
      });
    case actionTypes.SEND_QUEUED_SURVEYS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.sendQueuedSurveyMsg.errorMsg = action.payload;
        nextState.sendQueuedSurveyMsg.isFetching = false;
      });
    case actionTypes.SEND_QUEUED_SURVEYS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.sendQueuedSurveyMsg.successMsg = action.payload;
        nextState.sendQueuedSurveyMsg.isFetching = false;
      });
    case actionTypes.SEND_SURVEY_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.sendSurveyMsg.isFetching = true;
        nextState.sendSurveyMsg.successMsg = '';
        nextState.sendSurveyMsg.errorMsg = '';
      });
    case actionTypes.SEND_SURVEY_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.sendSurveyMsg.isFetching = false;
        nextState.sendSurveyMsg.errorMsg = action.payload;
      });
    case actionTypes.SEND_SURVEY_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.sendSurveyMsg.isFetching = false;
        nextState.sendSurveyMsg.successMsg = action.payload;
      });
    case actionTypes.CUSTOMER_BASED_SURVEYS_REQUEST:
      return produce(prevState, (nextState) => {
        nextState.isCustomerBasedSurveyListFetching = true;
        nextState.customerBasedSurveyList = prevState.customerBasedSurveyList;
        nextState.errorMsgCustomerBasedSurvey = '';
        nextState.customerBasedSurveyPageNum = action.payload.pageNum || prevState.customerBasedSurveyPageNum;
      });
    case actionTypes.CUSTOMER_BASED_SURVEYS_RESPONSE:
      return produce(prevState, (nextState) => {
        nextState.isCustomerBasedSurveyListFetching = false;
        if (prevState.customerBasedSurveyPageNum > 1 || !action.payload.isDataReset) {
          nextState.customerBasedSurveyList = [...prevState.customerBasedSurveyList, ...action.payload.data];
        } else {
          nextState.customerBasedSurveyList = action.payload.data;
        }
        nextState.errorMsgCustomerBasedSurvey = '';
      });
    case actionTypes.CUSTOMER_BASED_SURVEYS_FAILURE:
      return produce(prevState, (nextState) => {
        nextState.isCustomerBasedSurveyListFetching = false;
        nextState.customerBasedSurveyList = prevState.customerBasedSurveyList;
        nextState.errorMsgCustomerBasedSurvey = action.payload;
      });

    case actionTypes.RESET_PAGE_STATE:
      return produce(prevState, (nextState) => {
        if (action.payload === 'revv_surveys') {
          nextState.isMetricsFetching = initialState.isMetricsFetching;
          nextState.successMsg = initialState.successMsg;
          nextState.errorMsg = initialState.errorMsg;
          nextState.surveyMetrics = initialState.surveyMetrics;
          nextState.isFetching = initialState.isFetching;
          nextState.currentPage = initialState.currentPage;
          nextState.surveyList = initialState.surveyList;
          nextState.sendQueuedSurveyMsg.errorMsg = initialState.sendQueuedSurveyMsg.errorMsg;
          nextState.sendQueuedSurveyMsg.isFetching = initialState.sendQueuedSurveyMsg.isFetching;
          nextState.sendQueuedSurveyMsg.successMsg = initialState.sendQueuedSurveyMsg.successMsg;
          nextState.sendSurveyMsg.errorMsg = initialState.sendSurveyMsg.errorMsg;
          nextState.sendSurveyMsg.isFetching = initialState.sendSurveyMsg.isFetching;
          nextState.sendSurveyMsg.successMsg = initialState.sendSurveyMsg.successMsg;
        }
      });
    default:
      return prevState;
  }
};
