import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { CREATOR_USER_OWNERSHIP, CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ } from 'utils/constants';
import { useParamsValidator } from 'utils/hooks';
import { AccountCreatePostFilter } from './AccountCreatePostFilter';
import { FranchisorCreatePostFilter } from './FranchisorCreatePostFilter';

export const ContentCreatorFilter = () => {
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);

  useParamsValidator(CommonValidQueryParams, COMMON_VALID_QUERY_PARAMS_OBJ);

  return creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR ? (
    <FranchisorCreatePostFilter />
  ) : creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) ? (
    <AccountCreatePostFilter />
  ) : null;
};
