import { IFilterActionPayload, IHubListResponse, IHubLocationList, IHubLocationRequestParams, IHubTypeResponse, ILocationFilterPayload } from '../../types/filter';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';
import { COMMON_ACTIONS } from '../common';

export const onChangeDateRange = (payload: IFilterActionPayload) => createAction(actionTypes.ON_CHANGE_DATE_RANGE, payload);
export const enableOrDisableFilter = (payload: boolean) => createAction(actionTypes.ENABLE_OR_DISABLE_FILTER, payload);

export const loadHubsListRequest = (payload: string) => createAction(actionTypes.LOAD_HUBS_LIST_REQUEST, payload);
export const loadHubsListFailure = (payload: string) => createAction(actionTypes.LOAD_HUBS_LIST_FAILURE, payload);
export const loadHubsListResponse = (payload: IHubListResponse) => createAction(actionTypes.LOAD_HUBS_LIST_RESPONSE, payload);

export const loadHubTypeRequest = (payload: string) => createAction(actionTypes.LOAD_HUB_TYPE_REQUEST, payload);
export const loadHubTypeFailure = (payload: string) => createAction(actionTypes.LOAD_HUB_TYPE_FAILURE, payload);
export const loadHubTypeResponse = (payload: IHubTypeResponse[]) => createAction(actionTypes.LOAD_HUB_TYPE_RESPONSE, payload);

export const loadHubLocationRequest = (payload: IHubLocationRequestParams) => createAction(actionTypes.LOAD_HUB_LOCATION_REQUEST, payload);
export const loadHubLocationFailure = (payload: string) => createAction(actionTypes.LOAD_HUB_LOCATION_FAILURE, payload);
export const loadHubLocationResponse = (payload: IHubLocationList[]) => createAction(actionTypes.LOAD_HUB_LOCATION_RESPONSE, payload);

export const updateHubLocationFlag = (payload: boolean) => createAction(actionTypes.UPDATE_HUB_LOCATION_FLAG, payload);

export const onChangeLocation = (payload: ILocationFilterPayload) => createAction(actionTypes.ON_CHANGE_LOCATION, payload);

export const activeFilterOptions = (payload: string) => createAction(actionTypes.ACTIVE_FILTER_OPTIONS, payload);
export const FILTER_ACTIONS = {
  ...COMMON_ACTIONS,
  onChangeDateRange,
  enableOrDisableFilter,
  loadHubsListRequest,
  loadHubsListFailure,
  loadHubsListResponse,
  loadHubTypeRequest,
  loadHubTypeFailure,
  loadHubTypeResponse,
  loadHubLocationRequest,
  loadHubLocationFailure,
  loadHubLocationResponse,
  onChangeLocation,
  activeFilterOptions,
  updateHubLocationFlag
};

export type FilterAction = ActionsUnion<typeof FILTER_ACTIONS>;
