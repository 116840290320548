import { settingsRallioProfileContainerTypes } from 'types';
import { RALLIO_PROFILE_TAB_FILEDS, CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RIPPLE_COLOR } from 'utils/constants';
import { compareProfileObjects } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const CustomFieldsSection = ({
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  handleIndividualCancel,
  handleIndividualSave
}: settingsRallioProfileContainerTypes.ISettingsCommonCompProps) => {
  const { optionalParams } = useAccountSwitcherData();

  return accountId ? (
    <div className="settings-rallio-profile-new-section wn-customFields wnh">
      <div className="wn-top-cnt">
        <h3>
          <span>Custom fields Content Creators May Request You to Use</span>
        </h3>
      </div>
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Custom Fields</h3>
        </div>

        <div className="content-right-section-second-item form-elements-new">
          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_1`}
              className="form-control"
              value={values?.custom_fields?.custom_field_1 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_1`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 1</span>
          </div>

          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_2`}
              className="form-control"
              value={values?.custom_fields?.custom_field_2 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_2`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 2</span>
          </div>

          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_3`}
              className="form-control"
              value={values?.custom_fields?.custom_field_3 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_3`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 3</span>
          </div>

          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_4`}
              className="form-control"
              value={values?.custom_fields?.custom_field_4 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_4`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 4</span>
          </div>
          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_5`}
              className="form-control"
              value={values?.custom_fields?.custom_field_5 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_5`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 5</span>
          </div>
          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_6`}
              className="form-control"
              value={values?.custom_fields?.custom_field_6 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_6`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 6</span>
          </div>
          <div className={`form-group form-field-item custom-fields-st m-form-align full-width`}>
            <input
              type="text"
              name={`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_7`}
              className="form-control"
              value={values?.custom_fields?.custom_field_7 || ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(`${RALLIO_PROFILE_TAB_FILEDS.CUSTOM_FIELDS}.custom_field_7`, e.target.value);
              }}
            />
            <span className={`fltlabels`}>Custom Field 7</span>
          </div>
        </div>
      </div>
      {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS)}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-xs" onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.CUSTOM_FIELDS)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
