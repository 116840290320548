export enum BrandPartnerTabKeys {
  AVAILABLE_BRANDS = 'available_brands',
  PURCHASED_BRANDS = 'purchased_brands'
}

export const BRAND_PARTNERS_LIST_TABLE_HEADER_KEY = {
  AVAILABLE_PARTNER: 'available_partner',
  BRAND_PARTNER: 'brand_partner',
  CONTENT_SUPPLIER: 'content_supplier'
};

export const BRAND_PARTNERS_LIST_TABLE_HEADER = [
  { label: 'Available Partners', key: BRAND_PARTNERS_LIST_TABLE_HEADER_KEY.AVAILABLE_PARTNER },
  { label: 'Banner Partner', key: BRAND_PARTNERS_LIST_TABLE_HEADER_KEY.BRAND_PARTNER },
  { label: 'Content Supplier', key: BRAND_PARTNERS_LIST_TABLE_HEADER_KEY.CONTENT_SUPPLIER }
];

export const BANNER_PARTNER_FRANCHISOR_TABLE_HEADER_KEY = {
  AVAILABLE_BRANDS: 'available_brands',
  PRICE: 'price'
};

export const ENROLLED_PARTNER_BANNER_FRANCHISORS_TABLE_HEADER = [
  { label: 'List of Available Brands', key: BANNER_PARTNER_FRANCHISOR_TABLE_HEADER_KEY.AVAILABLE_BRANDS },
  { label: 'Banner Display Price', key: BANNER_PARTNER_FRANCHISOR_TABLE_HEADER_KEY.PRICE }
];
