import { couponsApiTypes, couponsReducerTypes } from 'types';
import { checkCouponIsActive } from 'utils/helpers';

/** Get coupon stats data */
export const getCouponStatsData = (couponList: couponsApiTypes.ICouponsListResponse[]) => {
  return couponList.reduce(
    (acc: couponsReducerTypes.ICouponsStats, curr) => {
      if (checkCouponIsActive(curr.start_date, curr.expiration)) {
        acc.active += 1;
      } else {
        acc.inactive += 1;
      }
      return acc;
    },
    { active: 0, inactive: 0 }
  );
};
