import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { ADMIN_AREA_ROUTES, RELOAD, ROUTES, SOMETHING_WENT_WRONG, USER_OWNERSHIP } from 'utils/constants';
import { franchisorError, accountError, exceptionHandlerRequest, accountSwitcherRequest, isShowPageNotFound, accountSwitcherRequestFail } from 'actions';
import { DeleteAssetModalPopup } from 'components';
import { useMenuAccessibleAccountSwitcherData, useCheckInvalidUrl } from 'utils/hooks';

export const ErrorRouteModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const activeUserFranchisor = useSelector((state: IStore) => state.login.activeUserFranchisor);
  const activeUserAccount = useSelector((state: IStore) => state.login.activeUserAccount);
  const franchisorErrorMsg = useSelector((state: IStore) => state.franchisors.error);
  const accountErrorMsg = useSelector((state: IStore) => state.accounts.error);

  const { isShowModal, setIsShowModal } = useCheckInvalidUrl();
  const { currentRoute, dropDownData } = useMenuAccessibleAccountSwitcherData();

  const isAdminScreenEnabledFunc = () => {
    const pathArray = window.location.pathname.split('/');
    return isAdmin && ADMIN_AREA_ROUTES.includes(`/${pathArray[1]}/${pathArray[2]}`);
  };

  const isAdminScreenEnabled = isAdminScreenEnabledFunc();

  const exceptionHandlerErrorReset = () => {
    dispatch(exceptionHandlerRequest(null));
  };

  const handleReloadToValidPage = () => {
    if (franchisorErrorMsg) dispatch(franchisorError(null));
    if (accountErrorMsg) dispatch(accountError(null));
    if (isAdminScreenEnabled) {
      navigate(`/${currentRoute}`);
      exceptionHandlerErrorReset();
    } else {
      if (isAdmin) {
        navigate(ROUTES.ADMIN_ACCOUNTS.replace('/*', ''));
        exceptionHandlerErrorReset();
        dispatch(accountSwitcherRequestFail(null));
      } else if (dropDownData.length) {
        const ownerShip = dropDownData[0]?.type === 'admin' ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
        navigate(ROUTES.CONTENT_POSTS.replace(':type', ownerShip).replace(':id', dropDownData[0].value.toString()).replace('/*', ''));
        exceptionHandlerErrorReset();
      } else if (activeUserFranchisor) {
        navigate(ROUTES.CONTENT_POSTS.replace(':type', USER_OWNERSHIP.FRANCHISOR).replace(':id', activeUserFranchisor.id.toString()).replace('/*', ''));
        if (accountSwitcherError) {
          dispatch(accountSwitcherRequest({ label: '', value: activeUserFranchisor.id.toString(), userOwnership: USER_OWNERSHIP.FRANCHISOR, id: activeUserFranchisor.id, userId: activeUserId }));
        }
        exceptionHandlerErrorReset();
      } else if (activeUserAccount) {
        navigate(ROUTES.CONTENT_POSTS.replace(':type', USER_OWNERSHIP.ACCOUNT).replace(':id', activeUserAccount.id.toString()).replace('/*', ''));
        if (accountSwitcherError) {
          dispatch(accountSwitcherRequest({ label: '', value: activeUserAccount.id.toString(), userOwnership: USER_OWNERSHIP.ACCOUNT, id: activeUserAccount.id, userId: activeUserId }));
        }
        exceptionHandlerErrorReset();
      } else {
        dispatch(isShowPageNotFound(true));
      }
    }
    setIsShowModal(false);
  };

  return (
    <DeleteAssetModalPopup
      showModal={isShowModal}
      message={SOMETHING_WENT_WRONG}
      okButtonText="Ok"
      secondaryMessage={RELOAD}
      handleModalClose={() => {
        setIsShowModal(false);
      }}
      handleDelete={handleReloadToValidPage}
    />
  );
};
