import { put, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';

import {
  NODE_API,
  apiCall,
  defaultHeader,
  API,
  getBrandMediaCloudinaryIdsForAI,
  getLocMediaCloudinaryIdsForAI,
  getBrandMediaTypeForAI,
  getLocMediaTypeForAI,
  splitTextFromHashAndEmoji,
  isDateInBetweenTwoDates,
  splitTextFromHash
} from 'utils/helpers';
import { logoutRequest, exceptionHandlerRequest } from 'actions';
import { commonApiTypes, overviewApiType } from 'types';
import {
  AI_CONTENT_CREATOR,
  AI_SUBSCRIPTION_PLAN_FAILED,
  AI_DRAFT_CREATION_FAILED,
  ALERT_BOX_TYPES,
  API_TYPES,
  USER_OWNERSHIP,
  AI_TRIAL_SUBSCRIPTION_PLAN_SUCCESS,
  NO_COMPLIANCE_SUGGESTION_MSG,
  ICSSubscriptionPlans
} from 'utils/constants';
import {
  getAIContentCreatorFailure,
  getAIContentCreatorResponse,
  getAIReplyResponseFailure,
  getAIReplyResponseResponse,
  getAIToneResponse,
  getAIToneFailure,
  aiCreateSimilarPostFailure,
  getAITagsListResponse,
  getAITagsListFailure,
  aiGetSubscriptionPlanResponse,
  aiGetSubscriptionPlanFailure,
  aiCreateSubscriptionPlanResponse,
  aiCreateSubscriptionPlanFailure,
  aiGetSubscriptionPlanRequest,
  aiUpdateSubscriptionPlanResponse,
  aiModelReviewsLearningResponse,
  aiCreateCaptionResponse,
  aiGetCaptionsListResponse,
  aiValidateCaptionResponse,
  aiValidateCaptionFailure,
  aiUpdateLimitationResponse,
  aiUpdateLimitationFailure
} from 'actions/modules/ai-content-creator';
import {
  AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST,
  AI_CONTENT_CREATOR_FAILURE,
  AI_CONTENT_CREATOR_REQUEST,
  AI_CREATE_SIMILAR_POST_REQUEST,
  AI_CREATE_SUBSCRIPTION_PLAN_REQUEST,
  AI_GET_SUBSCRIPTION_PLAN_REQUEST,
  AI_REPLY_RESPONSE_FAILURE,
  AI_REPLY_RESPONSE_REQUEST,
  AI_TONE_REQUEST,
  GET_AI_TAGS_LIST_REQUEST,
  AI_MODEL_REVIEWS_LEARNING_REQUEST,
  AI_MODEL_REVIEWS_LEARNING_FAILURE,
  AI_CREATE_CAPTION_REQUEST,
  AI_CREATE_CAPTION_FAILURE,
  AI_GET_CAPTIONS_LIST_REQUEST,
  AI_GET_CAPTIONS_LIST_FAILURE,
  AI_CAPTION_VALIDATION_REQUEST,
  AI_UPDATE_LIMITATION_REQUEST
} from 'actions/action-types';
import { alertBoxCall } from 'components';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendGetAIContentCreatorRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const data = {
      ...(payload.userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: payload.id } : { account_id: payload.id }),
      tone_id: payload.toneId,
      text: payload.text,
      response_count: payload?.ai_type === 'media' ? 1 : 5,
      user_name: payload.userName,
      brand_name: payload.brandName,
      // type: payload.type,
      phone_number: payload.phoneNumber,
      email: payload.email,
      sentiment: payload.sentiment,
      customer_alias: payload.customerAlias,
      business_search: payload.businessSearch,
      brand_category: payload.brandCategory,
      document_urls: payload.documentUrls,
      compliance_document_urls: payload.compliance_document_urls,
      ai_compliance_description: payload.ai_compliance_description,
      is_hashtags: payload.isHashtags,
      is_emojis: payload.isEmojis,
      website_url: payload.websiteUrl,
      location: payload.location,
      customer_persona_description: payload.customer_persona_description,
      customer_persona_name: payload.customer_persona_name,
      customer_persona_occupation: payload.customer_persona_occupation,
      customer_persona_usage_level: payload.customer_persona_usage_level,
      ai_type: payload.ai_type,
      include_emojis: payload.isEmoji
    };
    let response;
    if (payload.type === 'reviewsReply') {
      response = yield apiCall({ headers, data, ...NODE_API.aiReviewCreator, type: API_TYPES.NODE });
    } else {
      response = yield apiCall({ headers, data, ...NODE_API.aiCaptionCreator, type: API_TYPES.NODE });
    }
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      if (payload?.onAICaptionSuccessCallback) {
        const updatedRes = splitTextFromHash(response.data.data.choices);
        payload.onAICaptionSuccessCallback(`${updatedRes.text[0]} ${updatedRes.hash.join(' ')}`);
      }
      const responseCallbackFunc = payload.type === 'reviewsReply' ? getAIReplyResponseResponse : getAIContentCreatorResponse;
      yield put(responseCallbackFunc(response.data.data));
    } else {
      const failureCallbackFunc = payload.type === 'reviewsReply' ? getAIReplyResponseFailure : getAIContentCreatorFailure;
      const failureCallbackText = payload.type === 'reviewsReply' ? AI_REPLY_RESPONSE_FAILURE : AI_CONTENT_CREATOR_FAILURE;
      yield put(failureCallbackFunc(failureCallbackText));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}

function* sendGetAIToneRequest(): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, ...NODE_API.aiTone, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAIToneResponse(response.data.data));
    } else {
      yield put(getAIToneFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}

function* sendGetAISubscritionPlanRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { id: payload.id, userOwnership: payload.userOwnership, userId: payload.userId };
    const response = yield apiCall({ headers, ...NODE_API.aiSubsciptionPlan, params, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const data = response.data.data;
      const activeSubscription = data.find((subscription: any) => subscription.account_id === payload.id);
      const subscription = data.reduce(
        (acc: any, curr: any) => {
          if (isDateInBetweenTwoDates(moment(curr?.start_date), moment(curr?.end_date), moment().utc())) {
            acc.currentSubscription = [...acc.currentSubscription, curr];
          } else if (payload.type === 'contentSupplierSubscription') {
            acc.currentSubscription = [...acc.currentSubscription, curr];
          }
          if (curr.created_at && payload.userId === curr.user_id) {
            acc.total += curr.subscription_period === 'month' ? 29 : 288;
            if (acc.subscriptionTableData[curr.created_at]) {
              acc.subscriptionTableData[curr.created_at] = [...acc.subscriptionTableData[curr?.created_at], curr];
            } else {
              acc.subscriptionTableData[curr.created_at] = [curr];
            }
          }
          return acc;
        },
        { currentSubscription: [], subscriptionTableData: {}, total: 0 }
      );

      if (activeSubscription?.account_id && +activeSubscription.franchisor_subscription_type_id === 7 && activeSubscription.subscription_plan === ICSSubscriptionPlans.FREE) {
        yield put(aiUpdateLimitationResponse(activeSubscription.ai_limitations));
      }

      yield put(
        aiGetSubscriptionPlanResponse({
          aiSubscription: data,
          isAISubscribed:
            subscription.currentSubscription.filter((it: any) => {
              return (it.franchisor_id === payload.id || it.account_id === payload.id) && isDateInBetweenTwoDates(moment(it?.start_date), moment(it?.end_date), moment().utc());
            })?.length > 0,
          currentSubscription: subscription.currentSubscription,
          subscriptionTableData: subscription.subscriptionTableData
        })
      );
    } else {
      yield put(aiGetSubscriptionPlanFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_SUBSCRIPTION_PLAN_FAILED));
  }
}

function* sendAICreatePlanRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();

    const response = yield apiCall({ headers, ...NODE_API.createAISubsciptionPlan, data: payload.createParams, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiCreateSubscriptionPlanResponse(response.message));
      yield put(aiGetSubscriptionPlanRequest(payload.getParams));
      if (payload.closeModal) {
        payload.closeModal();
      }
      payload?.createParams?.data[0]?.isTrialEnable
        ? alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_TRIAL_SUBSCRIPTION_PLAN_SUCCESS || '')
        : alertBoxCall(ALERT_BOX_TYPES.SUCCESS, response.data.message || '');
    } else {
      alertBoxCall(ALERT_BOX_TYPES.WARN, response.data.message || '');
      yield put(aiCreateSubscriptionPlanFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_SUBSCRIPTION_PLAN_FAILED));
  }
}

function* sendAICancelPlanRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();

    const response = yield apiCall({ headers, ...NODE_API.updateAISubsciptionPlan, data: payload.createParams, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiUpdateSubscriptionPlanResponse(response.message));
      yield put(aiGetSubscriptionPlanRequest(payload.getParams));
      if (payload.closeModal) {
        payload.closeModal();
      }
      // alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_CANCEL);
    } else {
      alertBoxCall(ALERT_BOX_TYPES.WARN, response.data.message || '');
      yield put(aiCreateSubscriptionPlanFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_SUBSCRIPTION_PLAN_FAILED));
  }
}

// function* sendGetGPT3AIModelRequest({ payload }: ISagaPayload): any {
//   try {
//     const headers = defaultHeader();
//     const params = {
//       franchisor_id: payload.franchisorId,
//       tone_id: payload.toneId
//     };
//     const response = yield apiCall({ headers, params, ...NODE_API.aiModel, type: API_TYPES.NODE });
//     if (response.status === 401) {
//       yield put(logoutRequest());
//     } else if (response.status === 200) {
//       const modelResponse = response.data.data.map((it: aiContentCreatorApiTypes.IGPT3AIModelResponse) => ({ input: it.input, output: it.output }));
//       const text = modelResponse ? modalParserFromData(modelResponse, payload.editedContent) : payload.editedContent;
//       yield put(getAIContentCreatorRequest({ ...payload, text }));
//     } else {
//       alertBoxCall(ALERT_BOX_TYPES.ERROR, AI_GPT3_MODEL_SUGGEST_FAILED);
//     }
//   } catch (error) {
//     yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
//   }
// }

function* sendAICreateSimilarPost({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const isAccountUser = payload.userOwnership === USER_OWNERSHIP.ACCOUNT;
    const path = isAccountUser ? API.getPostsDetail.apiPath.replace(':id', `${payload.postsDataId}`) : API.getContentPostDetail.apiPath.replace(':id', `${payload.postsDataId}`);
    const postDetailsResponse = yield apiCall({ headers, params: {}, apiPath: path, action: API.getSavedPosts.action });
    if (postDetailsResponse.status === 401) {
      yield put(logoutRequest());
    } else if (postDetailsResponse.status === 200) {
      const dataObj = isAccountUser ? postDetailsResponse?.data?.saved_post : postDetailsResponse?.data?.content;
      const locationMediaObj = { imageList: postDetailsResponse?.data?.saved_post?.photo_urls || [], videoUrl: postDetailsResponse?.data?.saved_post?.video_url || null };
      const brandMediaList = postDetailsResponse?.data?.videos?.length ? postDetailsResponse?.data?.videos || [] : postDetailsResponse?.data?.photos || [];
      const cloudinaryId = isAccountUser ? getLocMediaCloudinaryIdsForAI(locationMediaObj) : getBrandMediaCloudinaryIdsForAI(brandMediaList);
      let tagsList: string[] = [];
      if (cloudinaryId?.length) {
        const params = {
          cloudinary_id: cloudinaryId,
          type: isAccountUser ? getLocMediaTypeForAI(locationMediaObj) : getBrandMediaTypeForAI(brandMediaList)
        };
        const tagsListResponse = yield apiCall({ headers, params, ...NODE_API.aiTagsList, type: API_TYPES.NODE });
        if (tagsListResponse.status === 401) {
          yield put(logoutRequest());
        } else if (tagsListResponse.status === 200) {
          tagsList = tagsListResponse.data.data.map((it: overviewApiType.IOverviewBrandData) => it.name);
          yield put(getAITagsListResponse(tagsList));
        } else {
          yield put(getAITagsListFailure(tagsListResponse.data.message));
        }
      }
      const data = {
        aiPostObj: {
          cloudinary_resource_type: isAccountUser ? dataObj.cloudinary_resource_type || 'image' : dataObj.video_id ? 'video' : 'image',
          cloudinary_id: cloudinaryId,
          use_facebook: dataObj.use_facebook,
          use_twitter: dataObj.use_twitter,
          use_linkedin: dataObj.use_linkedin,
          use_google: dataObj.use_google,
          use_instagram: dataObj.use_instagram,
          use_tiktok: dataObj.use_tiktok,
          facebook_text: splitTextFromHashAndEmoji(dataObj.facebook_text),
          twitter_text: splitTextFromHashAndEmoji(dataObj.twitter_text),
          linkedin_text: splitTextFromHashAndEmoji(dataObj.linkedin_text),
          google_text: splitTextFromHashAndEmoji(dataObj.google_text),
          instagram_text: splitTextFromHashAndEmoji(dataObj.instagram_text),
          tiktok_text: splitTextFromHashAndEmoji(dataObj.tiktok_text)
        },
        tag_list: tagsList,
        top_level_franchisor_id: payload.top_level_franchisor_id,
        brand_name: payload.brand_name,
        franchisor_id: !isAccountUser ? payload.id : null,
        account_id: isAccountUser ? payload.id : null,
        brand_category: payload.brandCategory,
        customer_persona_description: payload.customer_persona_description,
        customer_persona_name: payload.customer_persona_name,
        customer_persona_occupation: payload.customer_persona_occupation,
        customer_persona_usage_level: payload.customer_persona_usage_level,
        customer_alias: payload.customerAlias,
        business_search: payload.businessSearch,
        document_urls: payload.documentUrls,
        compliance_document_urls: payload.compliance_document_urls,
        ai_compliance_description: payload.ai_compliance_description,
        is_hashtags: payload.isHashtags,
        is_emojis: payload.isEmojis,
        website_url: payload.websiteUrl,
        location: payload.location,
        ai_type: payload.ai_type || '',
        tone_id: payload.toneId,
        response_count: 1,
        user_name: payload.userName,
        type: payload.type,
        phone_number: payload.phoneNumber,
        email: payload.email,
        sentiment: payload.sentiment
      };
      const response = yield apiCall({ headers, data, ...NODE_API.aiPost, type: API_TYPES.NODE });
      if (response.status === 401) {
        yield put(logoutRequest());
      } else if (response.status === 422) {
        alertBoxCall(ALERT_BOX_TYPES.WARN, AI_DRAFT_CREATION_FAILED);
        yield put(aiCreateSimilarPostFailure(AI_REPLY_RESPONSE_FAILURE));
      } else {
        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, response.data.message);
        payload.onNavigateToCreatorPage(response.data.data);
      }
    } else {
      alertBoxCall(ALERT_BOX_TYPES.WARN, AI_DRAFT_CREATION_FAILED);
      yield put(aiCreateSimilarPostFailure(AI_REPLY_RESPONSE_FAILURE));
    }
  } catch (error) {
    yield put(aiCreateSimilarPostFailure(AI_REPLY_RESPONSE_FAILURE));
    // yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}

function* sendGetAITagsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      cloudinary_id: payload.cloudinaryId,
      type: payload.type
    };
    const response = yield apiCall({ headers, params, ...NODE_API.aiTagsList, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAITagsListResponse(response.data.data.map((it: overviewApiType.IOverviewBrandData) => it.name)));
    } else {
      yield put(getAITagsListFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}

function* sendAIModelReviewsLearningRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = NODE_API.aiModelLearning.apiPath;
    const response = yield apiCall({ headers, data: payload, apiPath: path, action: NODE_API.aiModelLearning.action, type: API_TYPES.NODE });
    if (response === 200) {
      yield put(aiModelReviewsLearningResponse());
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_MODEL_REVIEWS_LEARNING_FAILURE));
  }
}

function* sendAICreateCaptionsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const { caption_id, ...reqPayload } = payload;
    const response = yield apiCall({
      headers,
      data: reqPayload,
      apiPath: payload.caption_id ? API.updateAICaptionsList.apiPath.replace(':id', `${payload.caption_id}`) : API.createAICaptionsList.apiPath,
      action: payload.caption_id ? API.updateAICaptionsList.action : API.createAICaptionsList.action
    });
    if (response.status === 201) {
      yield put(aiCreateCaptionResponse(response.data.caption));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_CREATE_CAPTION_FAILURE));
  }
}

function* sendAIGetCaptionsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, params: payload, apiPath: API.getAICaptionsList.apiPath, action: API.getAICaptionsList.action });
    if (response.status === 200) {
      yield put(aiGetCaptionsListResponse(response.data.captions));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(AI_GET_CAPTIONS_LIST_FAILURE));
  }
}

function* sendaiValidateCaptionRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, data: payload, ...NODE_API.validateAICaption, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      const complianceWarningRes = NO_COMPLIANCE_SUGGESTION_MSG.some((msg) => response.data.data.choices?.[0]?.text.toLowerCase().includes(msg)) ? '' : response.data.data.choices?.[0]?.text;
      if (payload?.onSuccess) {
        payload.onSuccess(complianceWarningRes);
      }
      yield put(aiValidateCaptionResponse(complianceWarningRes));
    } else {
      yield put(aiValidateCaptionFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}
function* sendUpdateAILimitationRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const response = yield apiCall({ headers, data: payload, ...NODE_API.updateAILimitation, type: API_TYPES.NODE });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(aiUpdateLimitationResponse(response.data.data));
    } else {
      yield put(aiUpdateLimitationFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(exceptionHandlerRequest(AI_CONTENT_CREATOR));
  }
}

export function* takeAIContenCreatorRequest() {
  yield takeLatest(AI_CONTENT_CREATOR_REQUEST, sendGetAIContentCreatorRequest);
  yield takeLatest(AI_REPLY_RESPONSE_REQUEST, sendGetAIContentCreatorRequest);
  yield takeLatest(AI_TONE_REQUEST, sendGetAIToneRequest);
  yield takeLatest(AI_CREATE_SIMILAR_POST_REQUEST, sendAICreateSimilarPost);
  yield takeLatest(GET_AI_TAGS_LIST_REQUEST, sendGetAITagsListRequest);
  yield takeLatest(AI_GET_SUBSCRIPTION_PLAN_REQUEST, sendGetAISubscritionPlanRequest);
  yield takeLatest(AI_CREATE_SUBSCRIPTION_PLAN_REQUEST, sendAICreatePlanRequest);
  yield takeLatest(AI_UPDATE_SUBSCRIPTION_PLAN_REQUEST, sendAICancelPlanRequest);
  yield takeLatest(AI_MODEL_REVIEWS_LEARNING_REQUEST, sendAIModelReviewsLearningRequest);
  yield takeLatest(AI_CREATE_CAPTION_REQUEST, sendAICreateCaptionsListRequest);
  yield takeLatest(AI_GET_CAPTIONS_LIST_REQUEST, sendAIGetCaptionsListRequest);
  yield takeLatest(AI_CAPTION_VALIDATION_REQUEST, sendaiValidateCaptionRequest);
  yield takeLatest(AI_UPDATE_LIMITATION_REQUEST, sendUpdateAILimitationRequest);
}
