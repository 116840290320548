// OVERVIEW ANALYTICS - GET OVERVIEW ANALYTICS
export const GET_OVERVIEW_ANALYTICS_REQUEST = 'overview_analytics/GET_OVERVIEW_ANALYTICS_REQUEST';
export const GET_OVERVIEW_ANALYTICS_RESPONSE = 'overview_analytics/GET_OVERVIEW_ANALYTICS_RESPONSE';
export const GET_OVERVIEW_ANALYTICS_FAILURE = 'overview_analytics/GET_OVERVIEW_ANALYTICS_FAILURE';

// OVERVIEW ANALYTICS - GET OVERVIEW ANALYTICS SUMMARY
export const GET_OVERVIEW_ANALYTICS_SUMMARY_REQUEST = 'overview_analytics/GET_OVERVIEW_ANALYTICS_SUMMARY_REQUEST';
export const GET_OVERVIEW_ANALYTICS_SUMMARY_RESPONSE = 'overview_analytics/GET_OVERVIEW_ANALYTICS_SUMMARY_RESPONSE';
export const GET_OVERVIEW_ANALYTICS_SUMMARY_FAILURE = 'overview_analytics/GET_OVERVIEW_ANALYTICS_SUMMARY_FAILURE';

// RESET REDUCER ON COMPONENT UNMOUNT
export const RESET_OVERVIEW_STATE = 'overview_analytics/RESET_OVERVIEW_STATE';
