import { put, takeLatest } from 'redux-saga/effects';

import { activeClientResponse, clientsDetailedViewFailure, clientsDetailedViewResponse, clientsFailure, clientsResponse } from 'revv/actions';
import * as actionTypes from 'revv/actions/action-types';
import { IClientDetailedViewResponse, IClientListResponse, IClientRequestParams, IClientsResponse, IDashboardConfig, ISagaPayload, SagaValue, SagaYield } from 'revv/types';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';

function* clientRequest(action: ISagaPayload<IClientRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.clients.apiPath,
      action: API_ROUTES.clients.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        'filter[keyword]': action.payload?.searchKeyword || '',
        'page[size]': 10,
        'page[number]': action.payload?.pageNumber || 1
      }
    });
    if (response.status === 401) {
      yield put(clientsFailure(response.data.error));
    } else {
      const res: IClientsResponse[] =
        response.data?.data && response.data?.data.length > 0
          ? response.data.data.map((it: IClientListResponse) => {
              return {
                id: it.id,
                name: it?.attributes.name,
                locationsCount: it?.attributes.locations_count,
                surveysCreatedCount: it?.attributes.surveys_created_count
              };
            })
          : [];
      yield put(clientsResponse(res));
    }
  } catch (error: any) {
    yield put(clientsFailure(error));
  }
}

function* clientDetailedViewRequest(action: ISagaPayload<{ id: string; isLogin?: boolean }>): Generator<SagaYield, void, SagaValue> {
  try {
    if (action.payload) {
      const response = yield apiCall({
        apiPath: API_ROUTES.clientDetail.apiPath.replace(':id', `${action.payload.id}`),
        action: API_ROUTES.clientDetail.method,
        headers: defaultRevvHeader(),
        type: API_TYPES.REVV
      });

      if (response.data?.data) {
        const clientAddOnService = response.data.included.find((it: any) => it.type === 'client_addon_services');
        const clientConfig = response.data.included.find((it: any) => it.type === 'client_configs');
        const dashboardConfigs: IDashboardConfig = {
          reputationTile: clientConfig?.attributes?.dashboard_configs?.reputation_tile,
          showTransTypeFilter: clientConfig?.attributes?.dashboard_configs?.show_trans_type_filter
        };
        const responseData: IClientDetailedViewResponse = {
          id: response.data.data?.id,
          rallioBrandId: response.data.data?.attributes?.rallio_brand_id || '',
          name: response.data.data?.attributes?.name || '',
          locationCount: response.data.data?.attributes?.locations_count,
          maxLocationCount: response.data.data?.attributes?.max_locations_allowed,
          surveyCount: response.data.data?.attributes?.surveys_created_count,
          address: response.data.data?.attributes?.address,
          city: response.data.data?.attributes?.city,
          state: response.data.data?.attributes?.region,
          country: response.data.data?.attributes?.country_code,
          timeZone: response.data.data?.attributes?.time_zone,
          businessPh: response.data.data?.attributes?.phone_number,
          firstName: response.data.data?.attributes?.contact_first_name,
          lastName: response.data.data?.attributes?.contact_last_name,
          email: response.data.data?.attributes?.email,
          contactEmail: response.data.data?.attributes?.contact_email,
          storeEmail: response.data.data?.attributes?.store_email,
          contactPh: response.data.data?.attributes?.contact_phone_number,
          postCode: response.data.data?.attributes?.postal_code,
          faceBookUrl: response.data.data?.attributes?.facebook_page_url,
          googleUrl: response.data.data?.attributes?.google_page_url,
          yelpUrl: response.data.data?.attributes?.yelp_page_url,
          notificationHours: response.data.data?.attributes?.notification_hours,
          smsPhoneNumber: response.data.data?.attributes?.sms_phone_number,
          coupons: clientAddOnService?.attributes?.coupons || false,
          npsSurvey: clientAddOnService?.attributes?.nps_surveys || false,
          fiscalCalendar: response.data.data?.attributes?.frg_date_range,
          abcFinancial: false,
          vault: false,
          perkville: false,
          perkvilleVaultId: '',
          perkvilleId: '',
          perkvilleGiftId: '',
          perkvilleUserName: '',
          perkvillePassword: '',
          configuration: {
            id: clientConfig?.id,
            allFirstPriorityPlatform: clientConfig?.attributes?.all_first_priority_platform,
            allSecondPriorityPlatform: clientConfig?.attributes?.all_second_priority_platform,
            allThirdPriorityPlatform: clientConfig?.attributes?.all_third_priority_platform,
            companyEmailLogoUrl: clientConfig?.attributes?.company_email_logo_url,
            couponEmailImageUrl: clientConfig?.attributes?.coupon_email_image_url,
            couponEmailMessage: clientConfig?.attributes?.coupon_email_message,
            couponEmailSubject: clientConfig?.attributes?.coupon_email_subject,
            couponEmailTitle: clientConfig?.attributes?.coupon_email_title,
            couponMessage: clientConfig?.attributes?.coupon_message,
            couponService: clientConfig?.attributes?.coupon_service,
            couponUrl: clientConfig?.attributes?.coupon_url,
            customerOptIn: clientConfig?.attributes?.customer_opt_in,
            dashboard: clientConfig?.attributes?.dashboard,
            dashboardConfigs,
            fromEmail: clientConfig?.attributes?.from_email,
            optInMessage: clientConfig?.attributes?.opt_in_message,
            reviewSites: clientConfig?.attributes?.review_sites,
            showAllReviewSites: clientConfig?.attributes?.show_all_review_sites,
            smsMessage: clientConfig?.attributes?.sms_message,
            surveyBlackoutPeriod: clientConfig?.attributes?.survey_blackout_period,
            surveyDeliveryDelay: clientConfig?.attributes?.survey_delivery_delay,
            surveyDeliveryMethod: clientConfig?.attributes?.survey_delivery_method,
            surveyEmailMessage: clientConfig?.attributes?.survey_email_message,
            surveyEmailSubject: clientConfig?.attributes?.survey_email_subject,
            surveyRatingReview: clientConfig?.attributes?.survey_rating_review,
            surveyTitle: clientConfig?.attributes?.survey_title,
            surveyType: clientConfig?.attributes?.survey_type
          }
        };
        if (action.payload.isLogin) {
          yield put(activeClientResponse(responseData));
        } else {
          yield put(clientsDetailedViewResponse(responseData));
        }
      }
    }
  } catch (error: any) {
    yield put(clientsDetailedViewFailure(error));
  }
}

export function* takeClientsRequests() {
  yield takeLatest(actionTypes.CLIENTS_REQUEST, clientRequest);
  yield takeLatest(actionTypes.CLIENTS_DETAILED_VIEW_REQUEST, clientDetailedViewRequest);
}
