import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { commonWidgetTypes } from 'types';
import { ReactDNDItem } from './ReactDNDItem';

export const ReactDND = ({ index, type, data, children, disabled, onSortData }: commonWidgetTypes.IReactDNDProps) => {
  const isTouchDevice = () => {
    if ('ontouchstart' in window) {
      return true;
    }
    return false;
  };

  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

  return (
    <DndProvider backend={backendForDND}>
      <ReactDNDItem index={index} type={type} data={data} onSortData={onSortData} disabled={disabled} children={children} />
    </DndProvider>
  );
};
