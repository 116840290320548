import { directoryListingsContainerTypes, reviewsContainerTypes } from 'types';

export const getLocationListForDropdown = (locationListData: reviewsContainerTypes.IReviewsLocationObj[]): directoryListingsContainerTypes.ISubcategoriesObj[] => {
  return locationListData.reduce((acc: directoryListingsContainerTypes.ISubcategoriesObj[], curr) => {
    const locationListObj = {
      id: curr.id,
      value: curr.value,
      label: curr.label
    };
    acc = [...acc, locationListObj];
    return acc;
  }, []);
};
