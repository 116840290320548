import { useDispatch } from 'react-redux';

import { ReactVideoPlayer } from 'widgets/Media';
import { setAISubscriptionDetailsModal } from 'actions';

export const AICoach = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
  };

  return (
    <div className="activated-rai-wrap">
      <h3>Congratulations! You have now activated Rallio AI and your free trial begins now!</h3>
      <p>Here's a quick explanation on how to get the most out of your new, super smart, always ready to help, friendly AI companion. Enjoy!</p>
      <div className="ai-train-video">
        <div className="video-section">
          <ReactVideoPlayer videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/v1665738930/AI%20Coach%20Mark/rallio_ai_training.mp4'} muted isPlayControl={false} customClassname={'video'} />
        </div>
      </div>
      <button onClick={handleClose}>
        <span>Got it!</span>
      </button>
    </div>
  );
};
