import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { resetPasswordResponse, resetPasswordFailure, exceptionHandlerRequest, confirmUserAccountFetchEmailResponse, confirmUserAccountFetchEmailFailure } from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { FE_ERROR_MSG, RSP_EXCEPTION_ERROR_MSG } from 'utils/constants';
import { commonApiTypes } from 'types';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendConfirmUserAccountRequest(action: ISagaPayload): any {
  const headers = defaultHeader();
  const data = action.payload.token;
  const params = action.payload;
  const response = yield apiCall({ headers, data, params, ...API.accountConfirmation });
  try {
    if (response.status === 200) {
      yield put(confirmUserAccountFetchEmailResponse(response.data.user_email));
    } else {
      yield put(confirmUserAccountFetchEmailFailure(response.data ? response.data.errors : response.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(response.data && response.data.errors ? response.data.errors : FE_ERROR_MSG));
  }
}

function* sendResetPasswordRequest(authDetails: ISagaPayload): any {
  const headers = defaultHeader();
  const data = authDetails.payload;
  const response = yield apiCall({ headers, data, ...API.resetPassword });
  try {
    if (response.status === 200) {
      yield put(resetPasswordResponse(response.data.message));
    } else {
      yield put(resetPasswordFailure(response.data ? response.data.errors : response.error));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(response.data && response.data.errors ? response.data.errors : RSP_EXCEPTION_ERROR_MSG));
  }
}

export function* takeResetPasswordRequest() {
  yield takeLatest(types.CONFIRM_USER_ACCOUNT_FETCH_EMAIL_REQUEST, sendConfirmUserAccountRequest);
  yield takeLatest(types.RESET_PASSWORD_REQUEST, sendResetPasswordRequest);
}
