import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'revv/actions/action-types';
import { ICustomerDeleteRequestParams, ICustomerDetailResponse, ICustomerListResponse, ICustomerMetrics, ICustomerUpdateRequestParams, IIdParam, IListRequestParams, IUploadParams } from 'revv/types';
import { COMMON_ACTIONS } from 'revv/actions';

export const customerMetricsRequest = (payload: IListRequestParams) => createAction(actionTypes.CUSTOMER_METRICS_REQUEST, payload);
export const customerMetricsRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_METRICS_REQUEST_FAILURE, payload);
export const customerMetricsResponse = (payload: ICustomerMetrics) => createAction(actionTypes.CUSTOMER_METRICS_RESPONSE, payload);

export const customerListRequest = (payload: IListRequestParams) => createAction(actionTypes.CUSTOMER_LIST_REQUEST, payload);
export const customerListRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_LIST_REQUEST_FAILURE, payload);
export const customerListResponse = (payload: ICustomerListResponse[] | null) => createAction(actionTypes.CUSTOMER_LIST_RESPONSE, payload);

export const customerDetailRequest = (payload: IIdParam) => createAction(actionTypes.CUSTOMER_DETAIL_REQUEST, payload);
export const customerDetailResponse = (payload: ICustomerDetailResponse | null) => createAction(actionTypes.CUSTOMER_DETAIL_RESPONSE, payload);
export const customerDetailRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_DETAIL_FAILURE, payload);

export const customerDeleteRequest = (payload: ICustomerDeleteRequestParams) => createAction(actionTypes.CUSTOMER_DELETE_REQUEST, payload);
export const customerDeleteFailure = (payload: string) => createAction(actionTypes.CUSTOMER_DELETE_FAILURE, payload);
export const customerDeleteResponse = () => createAction(actionTypes.CUSTOMER_DELETE_RESPONSE);

export const customerCreateRequest = (payload: ICustomerUpdateRequestParams) => createAction(actionTypes.CUSTOMER_CREATE_REQUEST, payload);
export const customerCreateFailure = (payload: string) => createAction(actionTypes.CUSTOMER_CREATE_FAILURE, payload);
export const customerCreateResponse = () => createAction(actionTypes.CUSTOMER_CREATE_RESPONSE);

export const customerUpdateRequest = (payload: ICustomerUpdateRequestParams) => createAction(actionTypes.CUSTOMER_DETAILS_UPDATE_REQUEST, payload);
export const customerUpdateFailure = (payload: string) => createAction(actionTypes.CUSTOMER_DETAILS_UPDATE_FAILURE, payload);
export const customerUpdateResponse = (payload: string) => createAction(actionTypes.CUSTOMER_DETAILS_UPDATE_RESPONSE, payload);

export const customerUploadRequest = (payload: IUploadParams) => createAction(actionTypes.CUSTOMER_UPLOAD_REQUEST, payload);
export const customerUploadRequestFailure = (payload: string) => createAction(actionTypes.CUSTOMER_UPLOAD_REQUEST_FAILURE, payload);
export const customerUplodadResponse = (payload: string) => createAction(actionTypes.CUSTOMER_UPLOAD_RESPONSE, payload);

export const customerResetUploadSuccessMessage = () => createAction(actionTypes.CUSTOMER_RESET_UPLOAD_SUCCESS_MESSAGE);

export const CUSTOMER_ACTIONS = {
  ...COMMON_ACTIONS,
  customerMetricsRequest,
  customerMetricsRequestFailure,
  customerMetricsResponse,
  customerListRequest,
  customerListRequestFailure,
  customerListResponse,
  customerDetailRequest,
  customerDetailResponse,
  customerDetailRequestFailure,
  customerDeleteRequest,
  customerDeleteFailure,
  customerDeleteResponse,
  customerCreateRequest,
  customerCreateFailure,
  customerCreateResponse,
  customerUpdateRequest,
  customerUpdateFailure,
  customerUpdateResponse,
  customerUploadRequest,
  customerUploadRequestFailure,
  customerUplodadResponse,
  customerResetUploadSuccessMessage
};

export type CustomerActions = ActionsUnion<typeof CUSTOMER_ACTIONS>;
