import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { ImageValidation } from 'widgets/Media';
import { CustomReactTags } from 'widgets/CustomReactTags';
import { commonModalPopup, IStore, mgReducerTypes } from 'types';
import { getCurrentFranchisorId, getUpdatedMGFavTagsList } from 'utils/helpers';
import { mgSetTagsList, mgUpdateFavTagsRequest } from 'actions';
import { NoDataFoundProps } from 'utils/constants';
import { Loading, NoDataFound } from 'components';

export const MgFavTagModalPopup = (additionalPops: commonModalPopup.IMGFavouriteTagsModalPopup) => {
  const dispatch = useDispatch();
  const props = useOutletContext<commonModalPopup.IMGFavouriteTagsModalPopup>() || additionalPops;

  const { isModalShow, handleModalClose, containerClassName, favTagsList, isTagFetching } = props;
  const { id, userOwnership, topLevelFranchisorId } = useSelector((state: IStore) => state.accountSwitcher);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isModalShow) setSearchValue('');
  }, [isModalShow]);

  const mgFavTagsList = favTagsList.filter((datum) => datum.name.indexOf(searchValue) > -1);

  const handleEditFavTagsList = (favTagItem: mgReducerTypes.IMediaGalleryTagsData) => {
    const tempTagItem = { ...favTagItem, favourite: !favTagItem.favourite };
    const updatedFavTagsList = getUpdatedMGFavTagsList(favTagsList, favTagItem.name);
    dispatch(mgSetTagsList(updatedFavTagsList));
    const currentFranchisorId = getCurrentFranchisorId(id, userOwnership, topLevelFranchisorId);
    if (currentFranchisorId) {
      dispatch(mgUpdateFavTagsRequest({ id: currentFranchisorId, tagItem: tempTagItem }));
    }
  };

  return (
    <Modal
      className={`prime-modal eft-modal edit__fav--modal${containerClassName ? ` ${containerClassName}` : ``}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      animation={false}
    >
      <Modal.Body>
        {isTagFetching ? (
          <Loading isSmall />
        ) : (
          <div className="eftMain">
            <div className="eft-top r-flx r-flx-ac r-flx-jb">
              <div className="glbl__title--txt">Edit Favorite Tags</div>
              {/* <div className="scl-controls">
                <Button className="plain-btn" variant="link">
                  <div className="rel-icons pb-icon" onClick={handleModalClose}>
                    <ImageValidation isImgValid defaultImg="xmark" customName="close" />
                  </div>
                </Button>
              </div> */}
              <div className="eft__close--icon" onClick={handleModalClose}>
                <ImageValidation isImgValid defaultImg="xmark" customName="close" />
              </div>
            </div>
            <p className="glbl__sub--title">
              These are the tags you have used for your images &amp; videos. You may click on the star beside each one to Star or Unstar the tag. Starred tags will appear in the "Quick Search" section
              in your locations’ Media Gallery.
            </p>
            <div className="at-input">
              <CustomReactTags
                key={`${id}${userOwnership}`}
                handleInputChange={(search: string) => setSearchValue(search)}
                handleAddition={() => false}
                handleDelete={() => false}
                placeholder={'Search'}
                autofocus={false}
              />
            </div>
            <div className="eft-listings">
              {mgFavTagsList.length ? (
                mgFavTagsList.map((tagItem, tagIndex) => {
                  return (
                    <div className="eft-items" key={`mg-fav-tags${tagIndex}`}>
                      <div className="fltr-control">
                        <label className={`checkbox-item${tagItem.favourite ? ` active` : ``}`}>
                          <span className="label-txt" onClick={(event) => event.preventDefault()} style={{ cursor: 'default' }}>
                            {tagItem.name}
                          </span>
                          <input type="checkbox" name="edit-favorite-tags" onChange={() => handleEditFavTagsList(tagItem)} />
                          <span className={`checkmark${tagItem.favourite ? ` fullSelectCheck` : ``}`} />
                        </label>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoDataFound size={NoDataFoundProps.FULL} />
              )}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
