import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { RewardProgramsAction } from 'actions';
import { rpApiTypes, rpReducerTypes } from 'types';
import { CREATE_PROGRAM_INITIAL_DATA_OBJ, ON_GOING_ACHIEVERS_LIST_INITIAL_DATA_OBJ, RP_ADD_PROGRAM_INIT_OBJ, RP_PROGRAM_RESET_TYPES, RP_TREE_INIT_OBJ } from 'utils/constants';
import {
  // locationTreeGenerator,
  // locationChildTreeGenerator,
  getCPPromotionVaultList,
  // getExpandedTreeArray,
  // getTopLevelFranchisorKeys,
  getModifiedRPEmailObj,
  getModifiedRPDetailsObj,
  getModifiedActionObj,
  getValidCheckedKeysForEdit
} from 'utils/helpers';

type IRewardProgramsReducer = rpReducerTypes.IRewardProgramsReducer;

const initialState: IRewardProgramsReducer = {
  isFetching: true,
  isStatsFetching: true,
  isAchieversFetching: true,
  isDetailFetching: true,
  error: null,
  currentAction: null,
  createProgramDataObj: CREATE_PROGRAM_INITIAL_DATA_OBJ,
  rpListData: [],
  totalPageCount: 0,
  programDetailObj: null,
  pageStats: null,
  achieversList: [],
  ongoingAchieversList: ON_GOING_ACHIEVERS_LIST_INITIAL_DATA_OBJ,
  programId: 0,
  promotionVaultList: [],
  deleteRpError: null,
  deleteRpResponse: null,
  postProgram: RP_ADD_PROGRAM_INIT_OBJ,
  isRewardsModified: false,
  detailViewError: null,
  currentPage: 0,
  isResendEmailModified: false
};

const rewardProgramsReducer: Reducer<IRewardProgramsReducer, RewardProgramsAction> = (state: IRewardProgramsReducer = initialState, action: RewardProgramsAction): IRewardProgramsReducer => {
  switch (action.type) {
    // GET REWARD PROGRAMS LIST REQUEST
    case types.RP_GET_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        currentPage: action.payload.page,
        rpListData: action.payload.page === 0 ? initialState.rpListData : state.rpListData
      };
    case types.RP_GET_LIST_RESPONSE:
      const listData = action.payload.page > 0 ? [...state.rpListData, ...action.payload.programDetails] : [...action.payload.programDetails];
      return {
        ...state,
        isFetching: false,
        error: null,
        rpListData: listData
      };
    case types.RP_GET_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // GET REWARD STATS REQUEST
    case types.RP_GET_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        error: null
      };
    case types.RP_GET_STATS_RESPONSE:
      return {
        ...state,
        isStatsFetching: false,
        totalPageCount: action.payload.totalPageCount,
        pageStats: action.payload.pageStats
      };

    case types.RP_GET_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // GET REWARD PROGRAM DETAIL DATA
    case types.RP_GET_PROGRAM_DETAIL_REQUEST:
      return {
        ...state,
        isDetailFetching: true,
        programDetailObj: null,
        error: null
      };
    case types.RP_GET_PROGRAM_DETAIL_RESPONSE:
      return {
        ...state,
        isDetailFetching: false,
        programDetailObj: action.payload,
        createProgramDataObj: {
          ...state.createProgramDataObj,
          programAction: getModifiedActionObj(action.payload.programAction),
          programDetails: getModifiedRPDetailsObj(action.payload.programDetails),
          programHuLocations: action.payload.programHuLocations,
          emailObj: getModifiedRPEmailObj(action.payload.programDetails),
          locationsObj: {
            ...state.createProgramDataObj.locationsObj,
            checkedKeysList: getValidCheckedKeysForEdit(state.createProgramDataObj.locationsObj.rpTreeList, action.payload.programHuLocations)
          }
        }
      };

    case types.RP_GET_PROGRAM_DETAIL_FAILURE:
      return {
        ...state,
        isDetailFetching: false,
        error: action.payload || 'Unexpected error',
        detailViewError: action.payload || 'No data found'
      };
    case types.RP_SET_DETAIL_FETCHING:
      return {
        ...state,
        isDetailFetching: action.payload
      };
    // RP EDIT PROGRAM RESEND EMAIL
    case types.RP_EDIT_PROGRAM_RESEND_EMAIL_REQUEST:
      return {
        ...state
      };
    case types.RP_EDIT_PROGRAM_RESEND_EMAIL_RESPONSE:
      return {
        ...state,
        isResendEmailModified: initialState.isResendEmailModified
      };
    case types.RP_EDIT_PROGRAM_RESEND_EMAIL_FAILURE:
      return {
        ...state
      };
    // GET REWARDS PROGRAM  ACHIEVERS LIST REQUEST
    case types.RP_ACHIEVERS_LIST_REQUEST:
      return {
        ...state,
        isAchieversFetching: true,
        programId: action.payload
      };
    case types.RP_ACHIEVERS_LIST_RESPONSE:
      return {
        ...state,
        isAchieversFetching: false,
        achieversList: action.payload.achieversList ? action.payload.achieversList : [],
        ongoingAchieversList: action.payload.ongoingAchieversList ? action.payload.ongoingAchieversList : ON_GOING_ACHIEVERS_LIST_INITIAL_DATA_OBJ
      };
    case types.RP_ACHIEVERS_LIST_FAILURE:
      return {
        ...state,
        isAchieversFetching: false,
        error: action.payload || 'Unexpected error'
      };
    // RP PROMOTION VAULT LIST
    case types.RP_PROMOTION_VAULT_LIST_REQUEST:
      return {
        ...state,
        promotionVaultList: []
      };
    case types.RP_PROMOTION_VAULT_LIST_RESPONSE:
      return {
        ...state,
        promotionVaultList: getCPPromotionVaultList(action.payload.data)
      };
    case types.RP_PROMOTION_VAULT_LIST_FAILURE:
      return {
        ...state,
        promotionVaultList: [],
        error: action.payload || 'Unexpected error'
      };
    // RP LOCATION TREE
    case types.RP_LOCATION_TREE_DATA_REQUEST:
      return {
        ...state
      };
    case types.RP_LOCATION_TREE_DATA_RESPONSE:
      // const locationDataObj: { dataArray: commonLocationTree.ILocationTree[] } = {
      //   dataArray: []
      // };
      const brandHubLocation = action.payload.brand_hubs_locations ? action.payload.brand_hubs_locations : null;
      // if (brandHubLocation) {
      //   const franchisors: commonLocationTree.ILocationTreeFranchisor[] = brandHubLocation.franchisors && Array.isArray(brandHubLocation.franchisors) ? brandHubLocation.franchisors : [];
      //   const account: commonLocationTree.ILocationTreeAccount[] = brandHubLocation.account && Array.isArray(brandHubLocation.account) ? brandHubLocation.account : [];
      //   locationDataObj.dataArray = franchisors.length ? locationTreeGenerator(franchisors).dataArray : locationChildTreeGenerator(account, 0);
      // }
      return {
        ...state,
        createProgramDataObj: {
          ...state.createProgramDataObj,
          locationsObj: {
            ...state.createProgramDataObj.locationsObj,
            brandHubLocResponse: brandHubLocation,
            // rpTreeList: locationDataObj.dataArray,
            // expandedKeysList: getExpandedTreeArray(locationDataObj.dataArray),
            // topLevelFranchisor: getTopLevelFranchisorKeys(locationDataObj.dataArray),
            checkedKeysList: []
          }
        }
      };
    case types.RP_LOCATION_TREE_DATA_FAILURE:
      return {
        ...state,
        createProgramDataObj: {
          ...state.createProgramDataObj,
          locationsObj: RP_TREE_INIT_OBJ
        },
        error: action.payload || 'Unexpected error'
      };
    // RP DELETE REWARD PROGRAM DATA
    case types.RP_DELETE_PROGRAM_DATA_REQUEST:
      return {
        ...state,
        deleteRpError: null,
        deleteRpResponse: null,
        isFetching: true
      };
    case types.RP_DELETE_PROGRAM_DATA_RESPONSE:
      let tempData = state.rpListData;
      if (action.payload.deleteId) {
        tempData = tempData.filter((item: rpApiTypes.IRPListDetails) => item.programId !== action.payload.deleteId);
      }
      return {
        ...state,
        rpListData: tempData,
        deleteRpResponse: action.payload.message,
        isFetching: false
      };
    case types.RP_DELETE_PROGRAM_DATA_FAILURE:
      return {
        ...state,
        deleteRpError: action.payload.message,
        isFetching: false
      };
    // RP DELETE REWARD PROGRAM DATA RESET
    case types.RP_DELETE_PROGRAM_DATA_RESET:
      return {
        ...state,
        deleteRpError: null,
        deleteRpResponse: null
      };
    // RP SET CREATE PROGRAM DATA OBJ
    case types.RP_SET_CP_DATA_OBJ:
      return {
        ...state,
        createProgramDataObj: action.payload
      };
    // REWARDS PROGRAM - CREATE OR UPDATE PROGRAM
    case types.RP_CREATE_OR_UPDATE_PROGRAM_DATA_REQUEST:
      return {
        ...state,
        isRewardsModified: false,
        isFetching: true,
        postProgram: {
          message: null,
          error: null
        }
      };
    case types.RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESPONSE:
      return {
        ...state,
        postProgram: {
          message: action.payload.message,
          error: null
        }
      };
    case types.RP_CREATE_OR_UPDATE_PROGRAM_DATA_FAILURE:
      return {
        ...state,
        postProgram: {
          message: null,
          error: action.payload.message
        }
      };
    case types.RP_CREATE_OR_UPDATE_PROGRAM_DATA_RESET:
      if (action.payload === RP_PROGRAM_RESET_TYPES.SUBMIT) {
        return {
          ...state,
          currentAction: initialState.currentAction,
          postProgram: {
            message: null,
            error: null
          },
          createProgramDataObj: {
            ...initialState.createProgramDataObj,
            locationsObj: {
              ...initialState.createProgramDataObj.locationsObj,
              checkedKeysList: [],
              brandHubLocResponse: state.createProgramDataObj.locationsObj.brandHubLocResponse,
              rpTreeList: state.createProgramDataObj.locationsObj.rpTreeList,
              topLevelFranchisor: state.createProgramDataObj.locationsObj.topLevelFranchisor
            }
          },
          isDetailFetching: false,
          programDetailObj: initialState.programDetailObj,
          programId: initialState.programId
        };
      } else if (action.payload === RP_PROGRAM_RESET_TYPES.BACK) {
        return {
          ...state,
          postProgram: {
            message: null,
            error: null
          }
        };
      } else return { ...state };
    case types.RP_CURRENT_ACTION_STATUS_UPDATES:
      return {
        ...state,
        currentAction: action.payload
      };
    case types.RP_SET_REWARDS_MODIFIED:
      return {
        ...state,
        isRewardsModified: action.payload
      };
    case types.RP_SET_RESEND_EMAIL_MODIFIED:
      return {
        ...state,
        isResendEmailModified: action.payload
      };
    case types.RP_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    // REWARD PROGRAM RESET ALL
    case types.RP_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
export { rewardProgramsReducer };
