import { useCallback, useState, useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { IStore, commonProps } from 'types';
import {
  SIDE_NAV_DATA,
  SIDE_NAV_NAME,
  ROUTES,
  USER_OWNERSHIP,
  CREATOR_SAVE_CHANGES_IN_NAV_OBJ,
  SUPER_ADMIN_NAV_BAR,
  SUPER_ADMIN_MENU,
  CHILD_ROUTES,
  RALLIO_PROFILE_TEXT,
  MAIN_NAV_BAR_NAME,
  COMMON_ROUTES,
  helpNavBars,
  ROUTE_TABS
} from 'utils/constants';
import { handleLinkNavigation, getValidSideNavBarList, getNeedHelpURL, TrackGoogleAnalyticsEvent } from 'utils/helpers';
import { toggleNav, updateAALastViewedScreen, setIsDataLoading } from 'actions';
import { CommonSaveChangesConfirmModal } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useNavBarData, useParamsDeconstructor, useSSOIdentifiersForAccount } from 'utils/hooks';
import { INeedHelpUrlTypes } from 'types/common/account-switcher';

export const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const location = useLocation();

  const { mainNavPageName, subNavPageName, selectedSubNavData, activeMainPages } = useNavBarData();
  const { id, userOwnership, isAdminScreenEnabled, isValidId } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { showNotificationSettings } = useParamsDeconstructor();

  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const leftNav = useSelector((state: IStore) => state.common.leftNav);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const contentModified = useSelector((state: IStore) => state.common.contentModified);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const activeUser = useSelector((state: IStore) => state.login.activeUser);
  const lastVisitedScreen = useSelector((state: IStore) => state.adminArea.lastVisitedScreen);
  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const isAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const accountSwitcherData = useSelector((state: IStore) => state.accountSwitcher);

  const [saveChangesObj, setSaveChangesObj] = useState(CREATOR_SAVE_CHANGES_IN_NAV_OBJ);

  const siteRestrictions = useMemo(() => {
    const restrictions = {
      isShowNeedHelp: false,
      isShowVideoTutorials: false,
      isShowFeatureUpdates: false,
      isShowTabProfileName: false
    };
    const siteRestrictions = menuAccess?.site_restrictions;
    if (id && userOwnership && isValidId) {
      const userType = userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'hub_user' : 'location_user';

      restrictions.isShowNeedHelp = Boolean(siteRestrictions?.[userType]?.includes('need_help'));
      restrictions.isShowVideoTutorials = Boolean(siteRestrictions?.[userType]?.includes('video_tutorials'));
      restrictions.isShowFeatureUpdates = Boolean(siteRestrictions?.[userType]?.includes('feature_updates'));
      restrictions.isShowTabProfileName = Boolean(siteRestrictions?.[userType]?.includes('profile'));
    }
    return restrictions;
  }, [menuAccess, id, userOwnership, isValidId]);

  const handleActiveNavBarState = (mainPageName: string, subPageName: string) => {
    if (!isDataLoading) {
      let route = '';
      const isAdminMenu = mainPageName in SUPER_ADMIN_MENU;
      if (selectedSubNavData?.length > 0 || COMMON_ROUTES.find((route) => route.link === mainNavPageName)) {
        route = isAdminMenu
          ? `/${mainPageName}/${!menuAvailablity[mainPageName][subPageName] ? selectedSubNavData[0]?.name : subPageName}`
          : `/${userOwnership}/${id}/${mainPageName}/${!menuAvailablity[mainPageName][subPageName] ? selectedSubNavData[0]?.name : subPageName}`;
      } else {
        route = `/${userOwnership}/${id}/${mainPageName}/${subPageName}`;
      }
      navigate(route);
    }
  };

  const handleContentTabNav = (subTabName: null | string) => {
    if (subTabName === MAIN_NAV_BAR_NAME.REVV && isAuthenticated && isRevvConnectedToCurrentFranchisor) {
      navigate(`/${userOwnership}/${id}/${MAIN_NAV_BAR_NAME.REVV}/dashboard`);
    }
  };

  const handleSideNavBar = useCallback(
    (navNameItem: string, subNavIndex: number = 0) => {
      const validSubNavDataList = getValidSideNavBarList(SIDE_NAV_DATA[navNameItem], menuAvailablity[navNameItem]);
      const selectedSubPageName = validSubNavDataList[subNavIndex]?.name;
      let route = '';
      if (navNameItem === MAIN_NAV_BAR_NAME.CONTENT && selectedSubPageName === SIDE_NAV_NAME.CONTENT_MEDIA) {
        route = `/${userOwnership}/${id}/content/media/photos`;
      } else if (navNameItem === MAIN_NAV_BAR_NAME.CONTENT && selectedSubPageName === SIDE_NAV_NAME.CONTENT_CALENDAR) {
        route = `/${userOwnership}/${id}/${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'content/calendar/scheduled' : 'content/calendar'}`;
      } else if (navNameItem === MAIN_NAV_BAR_NAME.SETTINGS && selectedSubPageName === SIDE_NAV_NAME.REVV_SETTINGS) {
        route = `/${userOwnership}/${id}/revv/revv_settings/global`;
      } else if (navNameItem === MAIN_NAV_BAR_NAME.DIRECTORY_LISTINGS && selectedSubPageName === SIDE_NAV_NAME.DL_PROFILE) {
        route = `/${userOwnership}/${id}/directory_listings/profile/store_details`;
      } else {
        const isAdminMenu = navNameItem in SUPER_ADMIN_MENU;
        route = isAdminMenu
          ? `/${navNameItem}/${selectedSubPageName !== subNavPageName ? selectedSubPageName : subNavPageName}`
          : `/${userOwnership}/${id}/${navNameItem}/${selectedSubPageName !== subNavPageName ? selectedSubPageName : subNavPageName}`;
      }
      navigate(route);
    },
    [navigate, menuAvailablity, subNavPageName, id, userOwnership]
  );

  const handleNavBar = useCallback(() => {
    return activeMainPages.map((navNameItem, index: number) => {
      let validSubNavDataList: commonProps.ISubPageRouteData[] = [];
      if (mainNavPageName === navNameItem.name) {
        validSubNavDataList = getValidSideNavBarList(SIDE_NAV_DATA[navNameItem.name], menuAvailablity[navNameItem.name]);
      }
      return (
        navNameItem.isShow && (
          <div key={index}>
            <CustomRippleButton>
              <li
                className={`nav-item animate__animated animate__fadeInLeft${
                  navNameItem.name === MAIN_NAV_BAR_NAME.AI_ASSISTANT
                    ? ` ai-assist ${mainNavPageName === MAIN_NAV_BAR_NAME.AI_ASSISTANT ? 'active' : ''}`
                    : navNameItem.name === mainNavPageName
                    ? ` active`
                    : ``
                }`}
                onClick={() => {
                  TrackGoogleAnalyticsEvent('main_menu', navNameItem.name, `${window.location.pathname}${window.location.search}`, {
                    menu_name: navNameItem.name,
                    account_id: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0,
                    franchisor_id: accountSwitcherData.topLevelFranchisorObj?.id,
                    user_id: activeUser?.id,
                    user_ids: activeUser?.id,
                    user_name: `${activeUser?.first_name} ${activeUser?.last_name}`,
                    franchisor_name: accountSwitcherData.currentName,
                    account_name: userOwnership === USER_OWNERSHIP.ACCOUNT ? accountSwitcherData.currentName : '',
                    platform: 'Web'
                  });
                  const subPageName = SIDE_NAV_DATA?.[navNameItem.name]?.[0]?.name;
                  if (!subPageName) {
                    navigate(`/${userOwnership}/${id}/${navNameItem.name}`);
                  } else if (contentModified && subNavPageName !== subPageName) {
                    setSaveChangesObj({ isShow: true, mainPageName: navNameItem.name, subPageIndex: 0 });
                  } else if (navNameItem.name === SUPER_ADMIN_NAV_BAR[0].name && (isAdmin ? !isAdminScreenEnabled : false)) {
                    handleActiveNavBarState(navNameItem.name, lastVisitedScreen || 'accounts');
                    if (lastVisitedScreen) dispatch(updateAALastViewedScreen(''));
                    dispatch(setIsDataLoading(true));
                  } else if (subPageName && subPageName === 'revv' && isAuthenticated && isRevvConnectedToCurrentFranchisor) {
                    handleContentTabNav(subPageName);
                  } else {
                    handleSideNavBar(navNameItem.name);
                  }
                  dispatch(toggleNav());
                }}
              >
                <div className="nav-item-asset">
                  <div className="nav-item-icon">
                    <ImageValidation
                      defaultImg={navNameItem.icon}
                      customClassname={navNameItem.icon}
                      customName={navNameItem.menu}
                      isNotSvgFormat={navNameItem.name === MAIN_NAV_BAR_NAME.AI_ASSISTANT ? true : false}
                    />
                  </div>
                  <span className="nav-item-txt">{navNameItem.menu}</span>
                </div>
              </li>
            </CustomRippleButton>
            {mainNavPageName === navNameItem.name ? (
              <ul className="mobilr-nav-menu" style={{ display: 'none' }}>
                {validSubNavDataList &&
                  validSubNavDataList.map((it, sIndex: number) => (
                    <li
                      className={subNavPageName === it.name ? 'active' : ''}
                      key={it.name}
                      onClick={() => {
                        TrackGoogleAnalyticsEvent('main_menu', navNameItem.name, `${window.location.pathname}${window.location.search}`, {
                          menu_name: navNameItem.name,
                          account_id: userOwnership === USER_OWNERSHIP.ACCOUNT ? id : 0,
                          franchisor_id: accountSwitcherData.topLevelFranchisorObj?.id,
                          user_id: activeUser?.id,
                          user_ids: activeUser?.id,
                          user_name: `${activeUser?.first_name} ${activeUser?.last_name}`,
                          franchisor_name: accountSwitcherData.currentName,
                          account_name: accountSwitcherData.currentName,
                          platform: 'Web'
                        });
                        // Check whether the content changes to be discarded
                        if (contentModified && subNavPageName !== it.name) {
                          setSaveChangesObj({ isShow: true, mainPageName: navNameItem.name, subPageIndex: sIndex });
                        } else if (navNameItem.name === SUPER_ADMIN_NAV_BAR[0].name && (isAdmin ? !isAdminScreenEnabled : false)) {
                          handleActiveNavBarState(navNameItem.name, lastVisitedScreen || 'accounts');
                          if (lastVisitedScreen) dispatch(updateAALastViewedScreen(''));
                          dispatch(setIsDataLoading(true));
                        } else {
                          handleSideNavBar(navNameItem.name, sIndex);
                        }
                        dispatch(toggleNav());
                      }}
                    >
                      {it.label === RALLIO_PROFILE_TEXT ? (!siteRestrictions.isShowTabProfileName ? it.label : 'Profile') : it.label}
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        )
      );
    });
  }, [mainNavPageName, contentModified, activeMainPages, handleSideNavBar, handleContentTabNav, lastVisitedScreen]); // eslint-disable-line

  const isValidRoute = useMemo(() => {
    const routeMatch = (path: string) => matchPath(path, location.pathname)?.pathname;
    const routesArray = Object.values(ROUTES).slice(7);
    const childRouteKeys = Object.keys(CHILD_ROUTES);
    const routes: any = ROUTES;
    const childRoute: any = CHILD_ROUTES;

    const childRoutes = childRouteKeys.reduce((acc: string[], curr) => {
      const parentRoute: any = routes[curr];
      const childRouteObj = childRoute[curr];
      const routesArr = Object.values(childRouteObj).map((it) => `${parentRoute}/${it}`);
      acc = acc.concat(routesArr);
      return acc;
    }, []);
    return routesArray.concat(childRoutes).filter((it) => routeMatch(it))?.length > 0;
  }, [location.pathname]);

  const helpNavbarsArr = helpNavBars(mainNavPageName, siteRestrictions?.isShowNeedHelp, siteRestrictions?.isShowVideoTutorials, siteRestrictions?.isShowFeatureUpdates);

  return (
    <>
      {' '}
      {!accountSwitcherError && isValidRoute ? (
        <>
          <section className={`item-g nav-g ${leftNav ? 'nav-open' : ''}`}>
            <nav>
              <ul>{handleNavBar()}</ul>
            </nav>
            {helpNavbarsArr.map((it, index) => {
              return (
                it.isShow && (
                  <aside
                    className={`need-help${
                      it.label === ROUTE_TABS.VIDEO_TUTORIALS && mainNavPageName === MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS
                        ? ' video-tutorials active'
                        : it.label === ROUTE_TABS.FEATURE_UPDATES
                        ? ' cb-wrap'
                        : it.label === ROUTE_TABS.NEED_HELP
                        ? ' nh-wrap'
                        : ''
                    } animate__animated animate__fadeInLeft`}
                    onClick={(event) => {
                      if (it.label === ROUTE_TABS.VIDEO_TUTORIALS) {
                        navigate(`/${userOwnership}/${id}/video_tutorials`);
                      } else if (it.label === ROUTE_TABS.FEATURE_UPDATES) {
                        handleLinkNavigation('http://features.rallio.com');
                      } else {
                        event.preventDefault();
                        handleLinkNavigation(
                          subNavPageName || COMMON_ROUTES.find((route) => route.link === mainNavPageName)
                            ? getNeedHelpURL((isCSLocation ? 'csLocation' : isContentSupplier ? 'contentSupplier' : userOwnership) as INeedHelpUrlTypes, subNavPageName, showNotificationSettings)
                            : ''
                        );
                      }
                    }}
                    key={`common-route-${index}`}
                  >
                    <div className="nh-icon">
                      <ImageValidation defaultImg={it.image} customName={it.label.replace('?', '')} />
                    </div>
                    <div className="nh-txt">
                      <h4>{it.label}</h4>
                      {it.title && <span>{it.title}</span>}
                    </div>
                  </aside>
                )
              );
            })}
          </section>
          <CommonSaveChangesConfirmModal
            isModalShow={saveChangesObj.isShow}
            handleModalClose={() => setSaveChangesObj(CREATOR_SAVE_CHANGES_IN_NAV_OBJ)}
            handleNavigation={() => {
              handleSideNavBar(saveChangesObj.mainPageName, saveChangesObj.subPageIndex);
              dispatch(toggleNav());
            }}
          />{' '}
        </>
      ) : null}
    </>
  );
};
