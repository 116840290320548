import { useSelector } from 'react-redux';
import { EventApi } from '@fullcalendar/core';
import { useState } from 'react';
import { DateClickArg } from '@fullcalendar/interaction';

import { IStore, accountContainerTypes, calendarContainerTypes } from 'types';
import {
  ALERT_BOX_TYPES,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  CalendarViewTypes,
  LOCATION_USER_TIME_SLOT_DISABLE_ALERT_MSG,
  TOOLTIP_PLACEMENT,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT_HYPHEN
} from 'utils/constants';
import { checkCurrentDateLessThan, convertScheduleSlotIntoEventObj, getCurrentDate, formatDate, getParsedDate } from 'utils/helpers';
import { ReactFullCalendar } from 'widgets/Calendar';
import { CreateEditSlotDetailModal } from './CreateEditSlotDetailModal';
import { SchedulerWarningModal } from './SchedulerWarningModal';
import { CustomTooltip } from 'widgets/Tooltip';
import { Paragraph } from 'widgets/Text';
import { alertBoxCall } from 'components/Common';

export const ScheduleCalendar = () => {
  const accScheduleSlotList = useSelector((state: IStore) => state.accounts.accScheduleSlotList);
  const schedulerContentSupplier = useSelector((state: IStore) => state.operationsList.scheduler_content_supplier);

  const [selectedTimeSlot, setSelectedTimeSLot] = useState<accountContainerTypes.IScheduleCalendarSlotRes | null>(null);
  const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);
  const [slotModalOpen, setSlotModalOpen] = useState(false);

  const scheduleSlotList = convertScheduleSlotIntoEventObj(accScheduleSlotList);
  const sortedList = accScheduleSlotList?.length ? Object.values(accScheduleSlotList).sort((a, b) => a.hour - b.hour) : null;
  const scrollTimeHour = sortedList && sortedList?.length > 0 ? sortedList[0].hour : 8;

  const renderCustomEvent = (props: { event: calendarContainerTypes.ICalendarEventList }) => {
    const isFirstHalf = props.event?.extendedProps?.hour < 12;
    const hour = isFirstHalf ? props.event?.extendedProps?.hour : props.event?.extendedProps?.hour - 12;
    const slotHours = !hour ? '12' : hour;
    const slotMin = props.event?.extendedProps?.minute < 10 ? `0${props.event?.extendedProps?.minute}` : props.event?.extendedProps?.minute;
    const isSlotDisabled = props.event?.extendedProps?.franchisor_account_list_schedule_id;

    return (
      <div className={`creator-schedule-section-wrp blue-bg ${isSlotDisabled ? 'disable-opacity' : ''}`}>
        <CustomTooltip
          customPlacement={TOOLTIP_PLACEMENT.TOP}
          customTooltipText={props.event.title}
          customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
          customInput={() => (
            <>
              <Paragraph
                customClassname="post-content cals-title"
                customText={props.event._def.title === 'Any' ? 'All enrolled feeds' : props.event._def.title || ''}
                actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
              />
              <div className="time-stamp" title={`${slotHours}:${slotMin} ${isFirstHalf ? 'AM' : 'PM'}`}>{`${slotHours}:${slotMin} ${isFirstHalf ? 'AM' : 'PM'}`}</div>
            </>
          )}
        />
      </div>
    );
  };

  const handleEventSelection = (calObj: EventApi | any) => {
    const isSlotDisabled = Boolean(calObj.event._def?.extendedProps?.franchisor_account_list_schedule_id);
    if (isSlotDisabled) {
      alertBoxCall(ALERT_BOX_TYPES.INFO, LOCATION_USER_TIME_SLOT_DISABLE_ALERT_MSG);
    } else {
      const selectedSlot = scheduleSlotList.find((slot) => +slot.id! === +calObj.event._def.publicId);
      setSelectedTimeSLot(selectedSlot ?? null);
      if (!selectedSlot?.franchisor_account_list_schedule_id) {
        setSlotModalOpen(true);
      }
    }
  };

  const handleTimeSlotSelection = (arg: DateClickArg) => {
    const isSameOrGreaterDate = checkCurrentDateLessThan(arg.date);
    if (!schedulerContentSupplier?.length) {
      setShowInfoModalOpen(true);
    } else {
      const obj = {
        day_of_week: new Date(arg.date).getDay(),
        hour: new Date(arg.date).getHours(),
        minute: new Date(arg.date).getMinutes(),
        start_date: null,
        end_date: null,
        vertical_id: null,
        title: '',
        allDay: false,
        account_list_id: null,
        account_list_name: null,
        franchisor_account_list_schedule_id: null,
        vertical_name: null,
        once_date: !isSameOrGreaterDate ? getParsedDate(formatDate(arg.date, 'addWeek', 1, YEAR_MONTH_DAY_WITH_TIME_FORMAT_HYPHEN)) : arg.date
      };
      setSelectedTimeSLot(obj);
      setSlotModalOpen(true);
    }
  };

  const handleSlotModalClose = () => {
    setSlotModalOpen(false);
    setSelectedTimeSLot(null);
  };

  return (
    <>
      <div className="calendar-section-main-wrp calb-main mx-0">
        <div className="view-fullcalendarnew-wrp-section">
          <div className="cal-lc-views calendar-calview creator-view-calendar-wrp-modal">
            <ReactFullCalendar
              events={scheduleSlotList.length ? scheduleSlotList : []}
              initialView={CalendarViewTypes.TIME_GRID_WEEK}
              viewClassNames={'weeklycalendarwrapper'}
              showHeaderToolbar={false}
              allDaySlot={false}
              eventContent={renderCustomEvent}
              initialDate={getCurrentDate()}
              eventClick={handleEventSelection}
              dateClick={(arg) => {
                handleTimeSlotSelection(arg);
              }}
              scrollTime={`${scrollTimeHour}:00:00`}
            />
          </div>
        </div>
        <CreateEditSlotDetailModal isShowModal={slotModalOpen} onModalClose={handleSlotModalClose} slotDetail={selectedTimeSlot} />
        <SchedulerWarningModal isShowModal={showInfoModalOpen} onModalClose={() => setShowInfoModalOpen(false)} />
      </div>
    </>
  );
};
