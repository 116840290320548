import { useRef, useEffect } from 'react';

interface IOutsideClickDetectorProps {
  children: any;
  handler: (e: any) => void;
  excludeTargets?: Array<any>;
  renderWrapper: any;
  active: boolean;
}

const useOutsideClickDetector = (ref: any, handler: (e: any) => void, excludeTargets: any, active: boolean) => {
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      let isDisableDetect = false;
      isDisableDetect = excludeTargets.some((it: any) => {
        return it && it.contains(event.target);
      });

      if (ref.current && !ref.current.contains(event.target) && !isDisableDetect) {
        handler(event);
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, handler, excludeTargets, active]);
};

export const OutsideClickDetector = ({ children, handler, excludeTargets, renderWrapper, active }: IOutsideClickDetectorProps) => {
  const wrapperRef = useRef(null);

  useOutsideClickDetector(wrapperRef, handler, excludeTargets || [], active);

  return renderWrapper(wrapperRef, children);
};
