import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';

import { ImageValidation } from 'widgets/Media';
import { campaignsContainerTypes, commonModalPopup, IStore } from 'types';
import {
  CAMPAIGN_INIT_FILTER_DATA,
  DATEPICKER_DATE_ONLY_FORMAT,
  ICommonDateTypes,
  USER_OWNERSHIP,
  CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS,
  ICampaignAvailability,
  CREATE_OR_EDIT_CAMPAIGN_AVAILABILITY_LIST,
  DATE_FORMAT,
  CALENDAR_YR_MN_DATE_FORMAT,
  CREATOR_USER_OWNERSHIP
} from 'utils/constants';
import { useParamsDeconstructor, useAccountSwitcherData, useNavBarData } from 'utils/hooks';
import { campaignDetailReset, createOrEditCampaignRequest, getCampaignDetailRequest } from 'actions';
import { CustomDatePicker } from 'widgets/DatePicker';
import { createOrEditCampaignValidation, dateFormatByRequestedFormat, getCurrentDate, getEndOfDate, getParsedDate, getFormattedDate } from 'utils/helpers';
import { TextArea } from 'widgets/Text';
import { Loading } from 'components';

export const CreateOrEditCampaignModal = ({ isShowModal, onModalClose, campaignName, className }: commonModalPopup.ICreateOrEditCampaignModal) => {
  const dispatch = useDispatch();

  const { subNavPageName } = useNavBarData();
  const { id, userOwnership, isValidId, optionalParams } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(CAMPAIGN_INIT_FILTER_DATA);
  const existingCampaignId = queryParams?.edit_campaign;

  const isDetailFetching = useSelector((state: IStore) => state.campaigns.isDetailFetching);
  const campaignDetail = useSelector((state: IStore) => state.campaigns.campaignDetail);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);

  // Get campaign detail data
  useEffect(() => {
    if (isShowModal && id && userOwnership && isValidId && existingCampaignId && !campaignDetail) {
      dispatch(
        getCampaignDetailRequest({
          campaignId: existingCampaignId,
          ...(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisorId: id } : { accountId: id })
        })
      );
    }
  }, [isShowModal, id, userOwnership, isValidId, existingCampaignId, campaignDetail, dispatch]);

  const initialCampaignData = useMemo(() => {
    return campaignDetail
      ? {
          name: campaignDetail.name || '',
          description: campaignDetail.description || '',
          availability: campaignDetail.end_date ? ICampaignAvailability.EXPIRES : ICampaignAvailability.ONGOING,
          expired_date: getParsedDate(getFormattedDate(campaignDetail.end_date))
        }
      : {
          name: campaignName || '',
          description: '',
          availability: ICampaignAvailability.EXPIRES,
          expired_date: null
        };
  }, [campaignName, campaignDetail]);

  const { values, touched, errors, setFieldValue, handleSubmit, handleReset } = useFormik({
    initialValues: initialCampaignData,
    validationSchema: createOrEditCampaignValidation,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (data: campaignsContainerTypes.ICreateOrEditCampaignModalFieldValue) => {
      const activeId = creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT && accountId ? accountId : id;
      const activeUserOwnership = creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? USER_OWNERSHIP.ACCOUNT : userOwnership;
      if (activeId && activeUserOwnership) {
        dispatch(
          createOrEditCampaignRequest({
            campaignId: +existingCampaignId,
            pageName: subNavPageName,
            data: {
              name: data.name,
              description: data.description,
              end_date: data.availability === ICampaignAvailability.EXPIRES ? dateFormatByRequestedFormat(data.expired_date, CALENDAR_YR_MN_DATE_FORMAT) : null,
              ...(activeUserOwnership === USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: activeId } : { account_id: activeId })
            }
          })
        );
        handleResetCampaignData();
      }
    }
  });

  const handleChange = (field: string, value: null | string | Date) => setFieldValue(field, value);

  const handleResetCampaignData = () => {
    handleReset(values);
    onModalClose();
    if (!optionalParams.length && campaignDetail?.id) dispatch(campaignDetailReset());
  };

  const getInputErrorClassname = (valueItem: keyof campaignsContainerTypes.ICreateOrEditCampaignModalFieldValue) => {
    return touched[valueItem] && errors[valueItem] ? ` erroritem` : '';
  };

  const isInputFieldsEnabled = existingCampaignId ? !isDetailFetching : true;

  const disableSaveAction = () => {
    if (campaignDetail?.id) {
      if (
        initialCampaignData.name === values.name &&
        initialCampaignData.description === values.description &&
        dateFormatByRequestedFormat(initialCampaignData.expired_date, CALENDAR_YR_MN_DATE_FORMAT) === dateFormatByRequestedFormat(values.expired_date, CALENDAR_YR_MN_DATE_FORMAT)
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Modal className={`prime-modal campaign-cnc-ec-main${className ? ` ${className}` : ``}`} size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={isShowModal} animation={false}>
      <div className="mod__close--icon">
        <ImageValidation isImgValid defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} onClick={handleResetCampaignData} />
      </div>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="cce-body">
          <h2 className="glbl__title--txt">{existingCampaignId ? 'Edit Campaign' : 'Create New Campaign'}</h2>
          <div className="cceb-top">
            <div className="edit__camp--header">
              <span>Campaign Settings</span>
            </div>
            {!isInputFieldsEnabled ? <Loading /> : null}
            <div className={`cce-fields${!isInputFieldsEnabled ? ` pointer-events-none` : ''}`}>
              <div className="cce-row">
                <div className="cceb-label">
                  <span>Name</span>
                </div>
                <div className={`cceb-field${getInputErrorClassname(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.NAME)}`}>
                  <div className="form-group">
                    <input
                      type="text"
                      name={CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.NAME}
                      className="form-control"
                      autoComplete="off"
                      value={values.name || ''}
                      onChange={(e) => handleChange(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.NAME, e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="cce-row">
                <div className="cceb-label">
                  <span>Description</span>
                </div>
                <div className={`cceb-field cceb__desc--field${getInputErrorClassname(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.DESCRIPTION)}`}>
                  <div className="form-group">
                    <TextArea
                      id="campaign-description"
                      name={CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.DESCRIPTION}
                      className="form-control"
                      autoComplete="off"
                      value={values.description || ''}
                      onChange={(e) => handleChange(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.DESCRIPTION, e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="cce-row">
                <div className="cceb-label">
                  <span>Availability</span>
                </div>
                <div className="cceb-field">
                  <div className="ccea-top">
                    <div className="form-group">
                      {CREATE_OR_EDIT_CAMPAIGN_AVAILABILITY_LIST.map((it, index) => {
                        return (
                          <label key={`campaign-avail-item-${index}`} className={values.availability === it.value ? 'active' : ''}>
                            <input
                              type="radio"
                              className="option-input radio"
                              name={CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.AVAILABILITY}
                              checked={values.availability === it.value}
                              onChange={() => {
                                handleChange(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.AVAILABILITY, it.value);
                                handleChange(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.EXPIRED_DATE, null);
                              }}
                            />
                            <span className="label-text">{it.label}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  {isInputFieldsEnabled && values.availability === ICampaignAvailability.EXPIRES && (
                    <div className={`ccea-datePicker${getInputErrorClassname(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.EXPIRED_DATE)}`}>
                      <CustomDatePicker
                        selectedDate={values.expired_date}
                        dateRangeType={ICommonDateTypes.DATE_ONLY}
                        onChange={(date) => handleChange(CREATE_OR_EDIT_CAMPAIGN_DATA_FIELDS.EXPIRED_DATE, getEndOfDate(date))}
                        minDate={getCurrentDate()}
                        dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                        customClassname="fltr-date-item fltr-from-date"
                        placeholderText={DATE_FORMAT}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isInputFieldsEnabled && (
            <div className="flex-action-btns r-flx r-flx-ac g-20">
              {/* <button className="gnrc-btn lined-gnr ac-btn" type="button" onClick={handleResetCampaignData}> */}
              <button className="ac-secondary-white ac-outline ac-btn" type="button" onClick={handleResetCampaignData}>
                <span>Cancel</span>
              </button>
              <button className={`ac-btn ac-primary${disableSaveAction() ? ` events-none pointer-events-none` : ''}`} type="submit">
                {/* <button className={`gnrc-btn blue-gnr ac-btn${disableSaveAction() ? ` events-none pointer-events-none` : ''}`} type="submit"> */}
                <span>Save</span>
              </button>
            </div>
          )}
        </Modal.Body>
      </form>
    </Modal>
  );
};
