import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { commonAccountSwitcher, IStore } from 'types';
import { ADMIN_AREA_ROUTES, COMMON_ROUTES, SIDE_NAV_DROPDOWN_DATA, USER_OWNERSHIP } from 'utils/constants';
import { isValueHasExponentialTerm } from 'utils/helpers';

export const useAccountSwitcherData = () => {
  const location = useLocation();
  const franchisors = useSelector((state: IStore) => state.accountSwitcher.franchisors);
  const accounts = useSelector((state: IStore) => state.accountSwitcher.accounts);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const pathArray = location.pathname.split('/');

  const isAdminScreenEnabled = useMemo(() => {
    return isAdmin && ADMIN_AREA_ROUTES.includes(`/${pathArray[1]}/${pathArray[2]}`);
  }, [pathArray, isAdmin]);

  const id = !isAdminScreenEnabled && !isValueHasExponentialTerm(+pathArray[2]) ? +pathArray[2] : 0;
  const userOwnership = !isAdminScreenEnabled ? pathArray[1] : '';
  const currentRoute = !isAdminScreenEnabled ? (COMMON_ROUTES.find((route) => route.link === pathArray[3]) ? `${pathArray[3]}` : `${pathArray[3]}/${pathArray[4]}`) : `${pathArray[1]}/${pathArray[2]}`;
  const optionalParams = !isAdminScreenEnabled ? pathArray.slice(5).filter((it) => it !== '') : pathArray.slice(3).filter((it) => it !== '');

  const dropDownData = useMemo(() => {
    const data: commonAccountSwitcher.IDropdownOptionData[] = [];
    if (franchisors.length) {
      franchisors.forEach((element) => {
        data.push({ custom: true, label: element.name, value: element.id, type: SIDE_NAV_DROPDOWN_DATA.ADMIN, name: element.name });
      });
    }
    if (accounts.length) {
      accounts.forEach((element) => {
        data.push({ label: element.name, value: element.id, type: SIDE_NAV_DROPDOWN_DATA.LOCAL });
      });
    }
    return data;
  }, [accounts, franchisors]);

  const accountswitcherData = useMemo(() => {
    const selectedDropdownOption = dropDownData.find(
      (it) =>
        it.value === +id &&
        (userOwnership === USER_OWNERSHIP.FRANCHISOR ? it.type === SIDE_NAV_DROPDOWN_DATA.ADMIN : userOwnership === USER_OWNERSHIP.ACCOUNT ? it.type === SIDE_NAV_DROPDOWN_DATA.LOCAL : false)
    );
    const selectedOption = selectedDropdownOption || {
      label: currentName || SIDE_NAV_DROPDOWN_DATA.LOCATION,
      value: +id || 1,
      type: userOwnership === USER_OWNERSHIP.FRANCHISOR ? SIDE_NAV_DROPDOWN_DATA.ADMIN : SIDE_NAV_DROPDOWN_DATA.LOCAL || ''
    };
    return {
      id,
      userOwnership,
      selectedOption
    };
  }, [dropDownData, id, userOwnership]); // eslint-disable-line

  const isValidId = useMemo(() => currentAccountOrFranchisor?.id === +id, [id, currentAccountOrFranchisor]);

  return { ...accountswitcherData, isValidId, dropDownData, currentRoute, optionalParams, isAdminScreenEnabled };
};
