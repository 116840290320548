import { useSelector } from 'react-redux';

import { fbAdsApiTypes, IStore } from 'types';

export const FBAdsFacebookViewPagesModalBody = (props: fbAdsApiTypes.IFBAdsAccountListPopupProps) => {
  const { handleFbPageSelect, selectedFBPageId } = props;

  const { accountUserCredential } = useSelector((state: IStore) => state.fbAdsTargeting);
  const { adAccountList } = accountUserCredential;

  return (
    <>
      {adAccountList.length
        ? adAccountList.map((accountItem, index) => {
            return (
              <div key={`fb-ad-account-list-${index}`} className={`aps-item fb-ad-mod${selectedFBPageId === accountItem.id ? ' active' : ''}`} onClick={() => handleFbPageSelect(accountItem.id || '')}>
                <div className="aps-content">
                  <div className="nac-cnt">
                    <div className="lbl-wrp">
                      <span className="lbl">Account Name:</span> <span className="value">{accountItem.name}</span>
                    </div>
                    <div className="lbl-wrp acc-id-lbl">
                      <span className="lbl">Account ID#:</span>
                      <span className="value">{accountItem.id}</span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : 'Pages not found.'}
    </>
  );
};
