import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { commonModalPopup } from 'types';
import { ulResendInviteForUserRequest } from 'actions';

export const ULResendInviteToUser = ({ isModalShow, handleModalClose, containerClassName, userId }: commonModalPopup.IULResendInviteToUserModalPopup) => {
  const dispatch = useDispatch();

  const handleResendInviteToUser = () => {
    if (userId) {
      dispatch(ulResendInviteForUserRequest(userId));
      handleModalClose();
    }
  };

  return (
    <Modal
      className={`prime-modal${containerClassName ? ` ${containerClassName}` : ``}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalShow}
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Header>
        <button data-dismiss="modal" className="close" type="button">
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className="modal-icon-wraps">
          <span className="modal-itm-wraps modal-danger-wraps modal-post-now-wraps" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="modal-message-wraps">Click OK to resend the email invitation to this user.</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="apmodal modal-btn-grp-wraps ap-actions">
          <button className="modal-btn-action-itm modal-cancel-btn" onClick={handleModalClose}>
            Cancel
          </button>
          <button className="modal-btn-action-itm modal-ok-btn" onClick={() => handleResendInviteToUser()}>
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
