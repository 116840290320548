import { useState } from 'react';
import Select, { components, CSSObjectWithLabel } from 'react-select';
import Button from '@atlaskit/button';

import { commonAccountSwitcher, commonWidgetTypes } from 'types';

export const CustomDropdownWithSearch = ({
  dropdownRef,
  classNamePrefix,
  id,
  value,
  placeholder,
  onChange,
  options,
  isSearchable,
  menuIsOpen,
  autoFocus
}: commonWidgetTypes.ICustomDropdownWithSearchProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  /** SVG FOR SEARCH ICON IN SEARCH BAR */
  const Svg = ({ children, style }: commonAccountSwitcher.ISvgProps) => <svg width="27" height="27" viewBox="0 0 27 27" focusable="false" role="presentation" children={children} style={style} />;

  /** CUSTOM STYLE PROVIDED TO SEARCH & DROPDOWN */
  const selectStyles = {
    control: (provided: CSSObjectWithLabel) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
  };

  /** DROPDOWN OPTIONS WITH CUSTOMIZED STYLE */
  const Menu = ({ children }: commonAccountSwitcher.IMenuProps) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <div
        className="ds-menu"
        css={{
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: 'absolute',
          zIndex: 2
        }}
        children={children}
      />
    );
  };

  /** PLACE FIXED POSITION OF DROPDOWN ON SCREEN FIT */
  const Blanket = ({ onClick }: commonAccountSwitcher.IBlanketProps) => (
    <div
      className="ds-blanket"
      css={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1
      }}
      onClick={onClick}
    />
  );

  /** PARENT DROPDOWN COMPONENT */
  const Dropdown = ({ children, isOpen, target, onClose }: commonWidgetTypes.ICustomDropdownParent) => (
    <div className="ds-dropdown" css={{ position: 'relative' }} title={value.label} ref={dropdownRef}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );

  /** SEARCH ICON IN SEARCH BAR */
  const DropdownIndicator = () => (
    <div className="ds-drp-indicator" css={{ color: '#F08080', height: 20, width: 20 }}>
      <Svg>
        <path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fillRule="evenodd" />
      </Svg>
    </div>
  );

  /** DROPDOWN ICON SVG */
  const ChevronDown = () => (
    <Svg style={{ marginRight: -6 }}>
      <path
        d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  );

  const Option = (props: commonAccountSwitcher.IDropdownOptionData | any) => {
    const { data } = props;
    return (
      <div title={data.label}>
        <components.Option {...props} />
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      target={
        <Button className="h-btn" iconAfter={<ChevronDown />} onClick={() => setIsDropdownOpen(!isDropdownOpen)} isSelected={isDropdownOpen}>
          {value.id ? value.label : placeholder}
        </Button>
      }
    >
      <Select
        classNamePrefix={classNamePrefix}
        id={id}
        placeholder={placeholder}
        onChange={(data) => {
          if (onChange) {
            onChange(data);
            setIsDropdownOpen(false);
          }
        }}
        value={value}
        options={options}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
        autoFocus={autoFocus}
        styles={selectStyles}
      />
    </Dropdown>
  );
};
