import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EventApi } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';

import { ReactFullCalendar } from 'widgets/Calendar';
import { accountContainerTypes, calendarApiTypes, calendarContainerTypes, IStore } from 'types';
import {
  MOMENT_TIME_FORMAT,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  CalendarViewTypes,
  CalendarStatsType,
  USER_OWNERSHIP,
  CALENDAR_FILTER_INITIAL_VALUE,
  CALENDAR_YR_MN_DATE_FORMAT,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  CALENDAR_EXCEPTION_ERROR,
  SCHEDULE_PARAM_TYPE,
  FEED_TYPE,
  CAMPAIGN_WEEKLY_SCHEDULE_FROM,
  CALENDAR_SELECT_FUTURE_DATE_WARNING,
  MODAL_BOX_TYPES,
  RIPPLE_COLOR
} from 'utils/constants';
import { ImageValidation, Video } from 'widgets/Media';
import {
  convertDateBasedOnTimeZone,
  getCalendarAPScheduledPostList,
  getFormatBasedOnExactDate,
  calendarUTCtoLocalHandler,
  getShowMoreSchPostsList,
  getUTCDateAndTimeForSelectedData,
  getUtcDateObjBasedOnTimezone,
  exactTimeBasedOnTimeZone,
  getAPScheduledPostEventListForEvent,
  utcTimeStampConverter,
  startDateMoreThanEndDate,
  getUTCDateForPosts,
  getDateBasedOnGivenFormat,
  getNextDayDate,
  getParsedDate,
  startEndDate,
  getCurrentDate,
  apiEventSourcePolyfillHandler,
  API,
  getBrowserLocalDateTime,
  checkCurrentDateLessThan
  // getFormattedDate
} from 'utils/helpers';
import {
  accountGetScheduleListRequest,
  calendarResetListAll,
  calendarViewDateRange,
  getCalendarItemsListRequest,
  getCalendarItemsListResponse,
  getLocationsHubListRequest,
  getScheduledMultiPostsRequest,
  getScheduledMultiPostsResponse,
  getScheduledPostRequest,
  getScheduledPostResponse,
  updateScheduledMultiPostsRequest,
  updateScheduledPostRequest
} from 'actions';
import { ViewCalendarShowMoreModal } from 'containers/Content/Creator/AccountSubPages';
import { PLATFORM_FILTER } from 'analytics/utils';
import { Loading, ModalPopup } from 'components';
import { CalendarIconHandler } from 'containers/Content/Calendar/SubPages/CalendarIconHandler';
import { useParamsDeconstructor, useAvailablePlatforms, useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { Paragraph } from 'widgets/Text';
import { CreateEditSlotDetailModal, SchedulerWarningModal } from 'containers/Content/Scheduler/SubPages';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

interface ICalendarViewTypes {
  actualResponse: any;
  defaultDate?: null | Date;
  isHubUser?: boolean;
  timeZone: string;
  isFetching: boolean;
  calendarEventItemList?: calendarApiTypes.ICalendarItemsResponse[];
  displayType?: string | undefined;
  isEventsEnabled?: boolean;
  setShowSelectedEvent?: (eneabled: boolean) => void;
  setSelectedEvent?: (data: calendarContainerTypes.ICalendarEventList) => void;
}

export const CalendarView = ({
  actualResponse,
  defaultDate,
  isHubUser,
  timeZone,
  isFetching,
  calendarEventItemList,
  displayType,
  isEventsEnabled = true,
  setShowSelectedEvent,
  setSelectedEvent
}: ICalendarViewTypes) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarRef = useRef<any>();

  const { id, userOwnership, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor(CALENDAR_FILTER_INITIAL_VALUE);
  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it) => it.value !== 'yelp');
  const activePlatforms = useActivePlatform();

  const calendarView = useSelector((state: IStore) => state.calendar.calendarDateRange.calendarView);
  const startDate = useSelector((state: IStore) => state.calendar.calendarDateRange.startDate);
  const endDate = useSelector((state: IStore) => state.calendar.calendarDateRange.endDate);
  const calendarShuffledCount = useSelector((state: IStore) => state.calendar.calendarShuffledCount);
  // const accScheduleSlotList = useSelector((state: IStore) => state.accounts.accScheduleSlotList);
  const schedulerContentSupplier = useSelector((state: IStore) => state.operationsList.scheduler_content_supplier);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher.content_supplier_franchisor);
  const isSchedulerFeature = useSelector((state: IStore) => state.franchisors.franchisorDetails?.scheduler_feature || state.accounts?.accountDetails?.account?.features?.scheduler_feature || false);

  const [showMoreData, setShowMoreData] = useState<calendarContainerTypes.ICalendarEventList[]>([]);
  const [calendarEventList, setCalendarEventList] = useState<calendarContainerTypes.ICalendarEventList[]>([]);
  // const [calendarView, setCalendarView] = useState<string>(CalendarViewTypes.MONTH_VIEW);
  const [eventViewData, setEventViewData] = useState([]);
  const [slotModalOpen, setSlotModalOpen] = useState(false);
  const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSLot] = useState<accountContainerTypes.IScheduleCalendarSlotRes | null>(null);
  const [showWarningModalOpen, setShowWarningModalOpen] = useState<boolean>(false);

  // const scheduleSlotList = convertScheduleSlotIntoEventObj(accScheduleSlotList);

  useEffect(() => {
    if ((actualResponse?.length || calendarEventItemList?.length) && !isFetching) {
      setCalendarEventList(getCalendarAPScheduledPostList(actualResponse, calendarView, timeZone || ''));
      if (!isHubUser && calendarEventItemList && calendarEventItemList?.length > 0) {
        setEventViewData(getAPScheduledPostEventListForEvent(calendarEventItemList, calendarView, timeZone));
      }
    } else {
      setCalendarEventList([]);
      if (!isHubUser) {
        setEventViewData([]);
      }
    }
  }, [calendarView, actualResponse, calendarEventItemList]); // eslint-disable-line

  useEffect(() => {
    // if (isContentSupplierFranchisor) {
    dispatch(accountGetScheduleListRequest({ userOwnership, id }));
    dispatch(
      getLocationsHubListRequest({
        [`${userOwnership}_id`]: id,
        from: CAMPAIGN_WEEKLY_SCHEDULE_FROM.SCHEDULER,
        ...(userOwnership === USER_OWNERSHIP.ACCOUNT && { account_included: true }),
        ...(isContentSupplierFranchisor && { feed_type: FEED_TYPE.CONTENT_SUPPLIER })
      })
    );
    // }
  }, [dispatch, isContentSupplierFranchisor, id]); // eslint-disable-line

  useEffect(() => {
    let getScheduledMultiPostEventStream: null | (() => void);
    let getScheduledPostEventStream: null | (() => void);
    let getCalendarItemsListEventStream: null | (() => void);
    if (id && userOwnership && startDate && endDate) {
      dispatch(calendarResetListAll());
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        getScheduledMultiPostEventStream = apiEventSourcePolyfillHandler(
          API.getScheduledMultiPost.apiPath,
          { franchisor_id: id, brand_date_range: [utcTimeStampConverter(startDate), utcTimeStampConverter(endDate)], trashed: 0 },
          'scheduled_multiposts',
          () => dispatch(getScheduledMultiPostsRequest()),
          (data) => {
            dispatch(getScheduledMultiPostsResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
      }
      if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
        getScheduledPostEventStream = apiEventSourcePolyfillHandler(
          API.getScheduledPost.apiPath,
          {
            hidden: 0,
            order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
            account_id: id,
            date_range: [utcTimeStampConverter(startDate), utcTimeStampConverter(endDate)]
          },
          'scheduled_posts',
          () => dispatch(getScheduledPostRequest()),
          (data) => {
            dispatch(getScheduledPostResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
        if (isEventsEnabled) {
          getCalendarItemsListEventStream = apiEventSourcePolyfillHandler(
            API.getRequestedCalendarItem.apiPath,
            {
              account_id: id,
              dateRange: {
                start_date: getDateBasedOnGivenFormat(startDate, CALENDAR_YR_MN_DATE_FORMAT) || '',
                end_date: getNextDayDate(endDate, CALENDAR_YR_MN_DATE_FORMAT) || ''
              }
            },
            'calendar_items',
            () => getCalendarItemsListRequest(),
            (data) => {
              dispatch(getCalendarItemsListResponse(data));
            },
            CALENDAR_EXCEPTION_ERROR
          );
        }
      }
    }
    return () => {
      if (getScheduledMultiPostEventStream) {
        getScheduledMultiPostEventStream();
      }
      if (getScheduledPostEventStream) {
        getScheduledPostEventStream();
      }
      if (getCalendarItemsListEventStream) {
        getCalendarItemsListEventStream();
      }
    };
  }, [id, userOwnership, startDate, endDate, calendarShuffledCount, dispatch]); // eslint-disable-line

  const renderCustomEvent = (props: { event: calendarContainerTypes.ICalendarEventList }) => {
    const parsedDate = isHubUser
      ? props.event._def.extendedProps?.post_now
        ? getBrowserLocalDateTime(props.event._def.extendedProps.scheduled_for)
        : getParsedDate(calendarUTCtoLocalHandler(props.event._def.extendedProps.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT))
      : props.event._def.extendedProps.start_datetime
      ? getParsedDate(getFormatBasedOnExactDate(props.event._def.extendedProps.start_datetime, YEAR_MONTH_DAY_WITH_TIME_FORMAT))
      : convertDateBasedOnTimeZone(timeZone, props.event._def.extendedProps.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT);

    const calendarImg = props.event._def.extendedProps.photo_urls?.length
      ? props.event._def.extendedProps.photo_urls[0]
      : props.event._def.extendedProps.link && props.event._def.extendedProps.link?.link_preview_image_url_choices?.length
      ? props.event._def.extendedProps?.link?.link_preview_image_url_choices[0]
      : props.event._def.extendedProps?.link_preview_image_url_choices
      ? props.event._def.extendedProps?.link_preview_image_url_choices[0]
      : null;
    return (
      <div
        className={`creator-schedule-section-wrp ${
          props.event._def.extendedProps.isEvent ? 'purple-bg' : props.event._def.extendedProps.campaign ? 'cg-green' : props.event._def.extendedProps.draft ? 'yellow-bg' : 'blue-bg'
        } ${calendarImg || props.event._def.extendedProps.video_url ? '' : 'onlyText'}`}
      >
        <div className="creator-timestamp-icon-wrp">
          <div className="left-section">
            <CalendarIconHandler
              isBoosted={
                userOwnership === USER_OWNERSHIP.FRANCHISOR
                  ? props.event._def.extendedProps?.has_any_boost || false
                  : userOwnership === USER_OWNERSHIP.ACCOUNT
                  ? props.event._def.extendedProps?.has_facebook_boost_enduser_campaign || false
                  : false
              }
              isAwaiting={!isHubUser ? props.event._def.extendedProps.draft : false}
              isCampaign={props.event._def.extendedProps.campaign}
            />

            <div className="time-stamp">{getFormatBasedOnExactDate(parsedDate, MOMENT_TIME_FORMAT)}</div>
          </div>
          <div className="right-section">
            <div className="social-icon-sec-new">
              {availablePlatform.map((it, index) => {
                return (
                  it.label &&
                  it.image &&
                  props.event._def.extendedProps[`use_${it?.value?.split('_')[0]}`] && (
                    <div className="gmhl-img" key={index}>
                      <ImageValidation isImgValid defaultImg={it.image} isNotSvgFormat customName={it.label} />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>

        <div className={`grid-section`}>
          {calendarImg ? (
            <div className="post-img">
              {/* <img alt='calendar image' src={props.event._def.extendedProps.photo_urls[0]} className='gmhl-img'/> */}
              <div className="gmhl-img">
                <ImageValidation imgUrl={calendarImg} customName={'vc-schedule-post-img'} />
              </div>
            </div>
          ) : props.event._def.extendedProps.video_url ? (
            <div className="post-video">
              <Video videoUrl={props.event._def.extendedProps.video_url} light={props.event._def.extendedProps.video_thumbnail_url} customClassname={'full-width'} />
            </div>
          ) : null}
          <Paragraph
            customClassname="post-content"
            customText={props.event._def.title ? props.event._def.title : props.event?.extendedProps?.link_title || ''}
            facebookTagList={props.event?.extendedProps?.use_facebook ? props.event?.extendedProps?.page_tag_details?.facebook || [] : []}
            actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
          />
        </div>
      </div>
    );
  };

  const handleEventSelection = (event: EventApi | any, isFromMoreClick: boolean = false, postId: number = 0) => {
    if (!isEventsEnabled && event && setShowSelectedEvent && setSelectedEvent) {
      const parsedDate = convertDateBasedOnTimeZone(timeZone, event._def.extendedProps.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT);
      setSelectedEvent({
        ...event._def.extendedProps,
        id: +event._def.publicId,
        title: event._def.title,
        start: parsedDate || '',
        end: parsedDate || ''
      });
      setShowSelectedEvent(true);
    } else {
      const selectedPostId = isFromMoreClick ? postId : Number(event?._def.publicId) || 0;
      navigate({
        pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${displayType !== 'all' ? `/${displayType}` : ''}/${selectedPostId.toString()}`,
        search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
      });
    }
  };

  const handleTimeSlotSelection = (arg: DateClickArg) => {
    if (!schedulerContentSupplier?.length) {
      setShowInfoModalOpen(true);
    } else {
      const obj = {
        day_of_week: new Date(arg.date).getDay(),
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
        start_date: null,
        end_date: null,
        vertical_id: null,
        title: '',
        allDay: false,
        account_list_id: null,
        account_list_name: null,
        franchisor_account_list_schedule_id: null,
        vertical_name: null,
        once_date: arg.dateStr
      };
      setSelectedTimeSLot(obj);
      setSlotModalOpen(true);
    }
  };

  const handleSlotModalClose = () => {
    setSlotModalOpen(false);
    setSelectedTimeSLot(null);
  };

  // DRAG AND DROP HANDLER - HUB USER
  const calendarDragDropHandler = (dataObj: any, changedDate: Date | null, dataId: number) => {
    /** Update the calendar event local state for avoiding fluctuation of event that are dragged & dropped */
    const hours = Number(dataObj.scheduled_for.substring(11, 13));
    const minutes = Number(dataObj.scheduled_for.substring(14, 16));
    const seconds = Number(dataObj.scheduled_for.substring(17, 19));
    const updatedDate = getUTCDateForPosts(changedDate, hours, minutes, seconds).selectedDate;
    const validDate = calendarView === CalendarViewTypes.DAY_VIEW ? changedDate : updatedDate;
    const modifiedDateAndTime = isHubUser ? getUTCDateAndTimeForSelectedData(validDate) : getUtcDateObjBasedOnTimezone(timeZone, validDate);
    const handleChangeDateRange: { [key: string]: Date | null | undefined } | undefined = { startDate, endDate };
    if (isHubUser && handleChangeDateRange.startDate && handleChangeDateRange.endDate) {
      const scheduledData = { ...dataObj, scheduled_for: modifiedDateAndTime };
      dispatch(
        updateScheduledMultiPostsRequest({
          schedulePayload: scheduledData,
          id: dataId,
          franchisorId: scheduledData.franchisor_id,
          dateRange: { startDate: utcTimeStampConverter(handleChangeDateRange.startDate), endDate: utcTimeStampConverter(handleChangeDateRange.endDate) },
          refetch: true
        })
      );
    } else {
      if (handleChangeDateRange?.startDate && handleChangeDateRange?.endDate) {
        dispatch(
          updateScheduledPostRequest({
            activePlatforms,
            payload: { ...dataObj, scheduled_for: modifiedDateAndTime, id: dataId },
            isCalendarView: true,
            dateRange: [utcTimeStampConverter(handleChangeDateRange?.startDate), utcTimeStampConverter(handleChangeDateRange?.endDate)],
            refetch: true
          })
        );
      }
    }
  };

  const utcDate =
    isHubUser && actualResponse.length && !isFetching
      ? exactTimeBasedOnTimeZone('', isHubUser)
      : !isHubUser && actualResponse.length && !isFetching
      ? exactTimeBasedOnTimeZone(timeZone || '', isHubUser)
      : undefined;

  const calendarEventsBasedOnStat = useMemo(() => {
    if (displayType === CalendarStatsType.SCHEDULED) {
      return calendarEventList.filter((it) => !it?.draft);
    } else if (displayType === CalendarStatsType.AWAITING_APPROVAL) {
      return calendarEventList.filter((it) => it?.draft);
    } else if (displayType === CalendarStatsType.EVENTS) {
      return eventViewData;
    } else {
      return calendarEventList;
    }
  }, [calendarEventList, eventViewData, displayType]);

  const calendarDate = (type?: string) => {
    const calendarApi = calendarRef?.current?.getApi();
    const date = calendarApi.getDate();
    const value = type || calendarView;
    const momentType = value === CalendarViewTypes.MONTH_VIEW ? 'month' : value === CalendarViewTypes.WEEK_VIEW ? 'week' : 'day';
    const monthDate = startEndDate(date, momentType);
    if (type) {
      calendarApi.changeView(type);
    }
    if (monthDate) {
      const params = {
        startDate: monthDate.startDate,
        endDate: monthDate.endDate,
        calendarView: type || calendarView
      };
      dispatch(calendarViewDateRange(params));
    }
  };

  const chartData = !isHubUser ? (!isEventsEnabled ? calendarEventList : calendarEventsBasedOnStat) : calendarEventList;

  return (
    <>
      {isFetching ? <Loading /> : null}
      <div className="cal-lc-views calendar-calview creator-view-calendar-wrp-modal" style={{ opacity: isFetching ? 0.5 : 1 }}>
        <ReactFullCalendar
          calendarRef={calendarRef}
          // key={Math.random()}
          events={chartData.length ? chartData : []}
          // initialView={calendarView}
          viewClassNames={calendarView === CalendarViewTypes.MONTH_VIEW ? `monthlycalendarwrapper` : calendarView === CalendarViewTypes.WEEK_VIEW ? `weeklycalendarwrapper` : `dailycalendarwrpper`}
          eventContent={renderCustomEvent}
          eventClick={(props: { event: EventApi }) => handleEventSelection(props.event)}
          dateClick={(arg) => {
            const isSameOrGreaterDate = checkCurrentDateLessThan(arg.date);
            if (isContentSupplierFranchisor || isSchedulerFeature) {
              if (isSameOrGreaterDate) {
                handleTimeSlotSelection(arg);
              } else {
                setShowWarningModalOpen(true);
              }
            }
          }}
          dayMaxEvents={calendarView === CalendarViewTypes.MONTH_VIEW ? 1 : false}
          eventMaxStack={calendarView === CalendarViewTypes.DAY_VIEW ? 5 : 0}
          moreLinkClick={(event) => setShowMoreData(getShowMoreSchPostsList(event.hiddenSegs, timeZone))}
          allDaySlot={false}
          initialDate={defaultDate || getCurrentDate()}
          customButtons={{
            dayGridMonth: {
              click: () => !isFetching && calendarDate(CalendarViewTypes.MONTH_VIEW)
            },
            dayGridWeek: {
              click: () => !isFetching && calendarDate(CalendarViewTypes.WEEK_VIEW)
            },
            timeGridDay: {
              click: () => !isFetching && calendarDate(CalendarViewTypes.DAY_VIEW)
            },
            prev: {
              click: () => {
                if (!isFetching) {
                  const calendarApi = calendarRef?.current?.getApi();
                  calendarApi.prev();
                  calendarDate();
                }
              }
            },
            next: {
              click: () => {
                if (!isFetching) {
                  const calendarApi = calendarRef?.current?.getApi();
                  calendarApi.next();
                  calendarDate();
                }
              }
            },
            today: {
              text: calendarView === CalendarViewTypes.MONTH_VIEW ? 'Month' : calendarView === CalendarViewTypes.WEEK_VIEW ? 'Week' : 'Today',
              click: () => {
                if (!isFetching) {
                  const calendarApi = calendarRef?.current?.getApi();
                  calendarApi.today();
                  calendarDate();
                }
              }
            }
          }}
          eventStartEditable={!isFetching ? true : false}
          eventDrop={(data) => {
            calendarDragDropHandler(data.event._def.extendedProps, data.event.start, Number(data.event._def.publicId));
          }}
          eventConstraint={{ start: utcDate ? utcDate : '' }}
          eventAllow={(_1, draggedEvent) => {
            if (startDateMoreThanEndDate(utcDate ? utcDate : null, draggedEvent?._def.extendedProps.scheduled_for)) {
              if (isHubUser) {
                return true;
              } else {
                if (calendarEventItemList?.find((it) => Number(draggedEvent?._def.publicId) === it?.id)) {
                  return false;
                } else {
                  return true;
                }
              }
            } else {
              return false;
            }
          }}
        />
      </div>
      {showMoreData.length ? (
        <ViewCalendarShowMoreModal
          showSelectedEvent={false}
          setShowSelectedEvent={() => false}
          extraCalendarList={showMoreData}
          isEventsEnabled={isEventsEnabled}
          handleModalClose={() => setShowMoreData([])}
          handleEventSelection={handleEventSelection}
          isHubUser={isHubUser}
          timeZone={timeZone}
        />
      ) : null}
      {slotModalOpen && <CreateEditSlotDetailModal isShowModal={slotModalOpen} onModalClose={handleSlotModalClose} slotDetail={selectedTimeSlot} isFromCalendar />}
      {showInfoModalOpen && <SchedulerWarningModal isShowModal={showInfoModalOpen} onModalClose={() => setShowInfoModalOpen(false)} />}
      {showWarningModalOpen && (
        <ModalPopup
          isModalShow={showWarningModalOpen}
          modalHeaderType={MODAL_BOX_TYPES.INFO}
          containerClassName={'modal-confirmation confirm-delete'}
          modalBody={() => (
            <div>
              <div className="modal-message-wraps">{CALENDAR_SELECT_FUTURE_DATE_WARNING}</div>
            </div>
          )}
          modalCloseButton
          handleModalClose={() => {
            setShowWarningModalOpen(false);
          }}
          isModalFooterShow
          modalFooter={() => (
            <div className="modal-btn-grp-wraps">
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className="ac-btn ac-primary ac-block" onClick={() => setShowWarningModalOpen(false)}>
                  Ok
                </button>
              </CustomRippleButton>
            </div>
          )}
        />
      )}
    </>
  );
};
