import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { config } from 'config';
import { store } from 'store';
import { commonApiTypes, commonEnvConfigTypes } from 'types';
import { API_TYPES, FB_GRAPH_URL } from 'utils/constants';
import { dateFormatterMmDDYYToLocal } from './date-helper';

export const apiCall = async (apiConfig: commonApiTypes.ApiConfig, promisePayload?: any): Promise<any> => {
  try {
    let isRevvAuthenticated = store.getState().revv.login?.isAuthenticated;
    store.subscribe(() => {
      isRevvAuthenticated = store.getState().revv.login?.isAuthenticated;
    });
    const resourceType = '/auto';
    let url = `${config.apiBaseURL}${config.apiVersionPath}${apiConfig.apiPath}`;

    if (apiConfig.type === API_TYPES.NODE) {
      url = `${config.apiNodeBaseURL}${config.apiVersionPath}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.OAUTH_API) {
      url = `${config.apiBaseURL}${config.oauthHeaders.apiVersionPath}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.PROMOTION) {
      url = `${config.promotion.apiUrl}${config.promotion.apiVersionPath}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.STRIPE) {
      url = `${config.stripe.apiUrl}${config.stripe.apiVersionPath}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.MAP_BOX_GEOCODER) {
      url = `${config.mapBoxGeocoder.apiBaseURL}${config.mapBoxGeocoder.apiVersionPath}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.CLOUDINARY) {
      url = `${config.cloudinary.baseUrl}${config.cloudinary.name}${resourceType}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.FACEBOOK) {
      url = `${FB_GRAPH_URL}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.REVV_SSO) {
      url = `${config.apiRevvBaseURL}${apiConfig.apiPath}`;
    }
    if (apiConfig.type === API_TYPES.REVV) {
      url = `${config.apiRevvBaseURL}${isRevvAuthenticated ? config.apiRevvVersionPath : ''}${apiConfig.apiPath}`;
    }
    const requestConfig: AxiosRequestConfig | any = {
      url,
      method: apiConfig.action,
      data: apiConfig.data,
      headers: apiConfig.headers,
      params: apiConfig.params,
      timeout: 0,
      onUploadProgress: apiConfig.onUploadProgress,
      validateStatus(status: number) {
        return status >= 200 && status <= 500;
      }
    };

    const response: any = await axios(requestConfig)
      .then((res: AxiosResponse<any>) => {
        return {
          ...res,
          ...(promisePayload && { promisePayload })
        };
      })
      .catch((error: AxiosError<any>) => handleErrors(error));

    return response;
  } catch (ex) {
    return false;
  }
};

const handleErrors = (error: any) => {
  // console.log("handleErrors", error)

  // Error
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    //  console.log(error.response.data);
    //  console.log(error.response.status);
    //  console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error", error.message);
  }

  if (error.status === undefined) {
    error.error = 'Failed to load resource';
  }

  switch (error.status) {
    case 401: // unauthorised
    case 403: // forbidden
    case 400: // bad request
      break;
    default:
      return error;
  }
  return error;
};

export const defaultRevvHeader = () => {
  const headers: IDefaultHeaders | any = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const revvLogin = store.getState().revv.login;
  let isAuthenticated = revvLogin.isAuthenticated;
  store.subscribe(() => {
    isAuthenticated = store.getState().revv.login.isAuthenticated;
  });
  if (isAuthenticated && store.getState().revv.login?.activeUser) {
    let token = store.getState().revv.login?.activeUser?.token;
    store.subscribe(() => {
      token = store.getState().revv.login?.activeUser?.token;
    });
    let email = store.getState().revv.login?.activeUser?.email;
    store.subscribe(() => {
      email = store.getState().revv.login?.activeUser?.email;
    });
    headers.Authorization = `Token ${token}, email="${email}"`;
  } else {
    headers.Authorization = `Token token="${config.ssoRevvToken}"`;
  }
  return headers;
};

export const defaultHeader = () => {
  let token: string | null = store.getState().login.activeUser?.token || null;
  store.subscribe(() => {
    token = store.getState().login.activeUser?.token || null;
  });
  const headers: IDefaultHeaders | any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    requestStartTime: dateFormatterMmDDYYToLocal(new Date())
  };
  if (token) {
    headers['X-Rallio-API-Key'] = token;
  } else indexedDB.deleteDatabase('localforage');
  return headers;
};

export const defaultOAuthHeader = () => {
  const headers: IDefaultHeaders | any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Application-ID': config.oauthHeaders.xApplicationId,
    'X-Application-Secret': config.oauthHeaders.xApplicationSecret
  };
  return headers;
};

export const promotionHeader = () => {
  const promotionHeaders: IPromotionHeaders | any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${config.promotion.promotionVaultToken}`
  };
  return promotionHeaders;
};

export const stripeHeader = () => {
  const stripeHeaders: IStripeHeaders | any = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${config.stripe.secretKey}`
  };
  return stripeHeaders;
};

export const cloudinaryHeader = () => {
  const cloudinaryHeaders: commonEnvConfigTypes.ICloudinaryHeaders | any = {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data'
  };
  return cloudinaryHeaders;
};

export const awsHeader = () => {
  let token: string | null = store.getState().login.activeUser?.token || null;
  store.subscribe(() => {
    token = store.getState().login.activeUser?.token || null;
  });
  const awsHeaders: commonEnvConfigTypes.IAWSHeaders | any = {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data'
  };
  if (token) {
    awsHeaders['X-Rallio-API-Key'] = token;
  } else indexedDB.deleteDatabase('localforage');
  return awsHeaders;
};

export const eventSourcePolyfillHeader = () => {
  let token: string | null = store.getState().login.activeUser?.token || null;
  store.subscribe(() => {
    token = store.getState().login.activeUser?.token || null;
  });
  const eventSourceHeaders: IEventSourceHeaders | any = {
    'X-Rallio-API-Key': token
  };
  return eventSourceHeaders;
};

interface IDefaultHeaders {
  Accept: string;
  'Content-Type': string;
  requestStartTime: Date;
  'X-Rallio-API-Key'?: any;
}

interface IPromotionHeaders {
  Accept: string;
  'Content-Type': string;
  Authorization?: any;
}

interface IStripeHeaders {
  Accept: string;
  'Content-Type': string;
  Authorization: any;
}

interface IEventSourceHeaders {
  'X-Rallio-API-Key'?: any;
}
