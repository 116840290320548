import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import {
  getAAAccountsListResponse,
  getAAAccountsListFailed,
  getAAFranchisorsListResponse,
  getAAFranchisorsListFailed,
  getAAUsersListResponse,
  getAAUsersListFailed,
  logoutRequest,
  getAAFranchisorStatsResponse,
  getAAFranchisorStatsFailure,
  updateAAFranchisorResponse,
  updateAAFranchisorFailure,
  exceptionHandlerRequest,
  updateAAUserDetailsResponse,
  updateAAUserDetailsFailure,
  addAAUserInviteMailResponse,
  addAAUserInviteMailFailure,
  getAAUserDetailsResponse,
  getAAUserDetailsFailure
} from 'actions';
import { commonApiTypes } from 'types';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import {
  ADMIN_AREA_ACCOUNT_LIST_FAILED,
  ADMIN_AREA_FRANCHISORS_LIST_FAILED,
  ADMIN_AREA_USERS_LIST_FAILED,
  ADMIN_AREA_EXCEPTION_ERROR,
  ADMIN_AREA_GET_USERS_DETAIL_FAILED,
  ADMIN_AREA_UPDATE_USERS_DETAIL_FAILED,
  DONE_SUCCESS_MSG,
  ADMIN_AREA_ADD_USER_INVITE_EMAIL_FAILED,
  ADMIN_AREA_EXIST_EMAIL_FAIL_MSG
} from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getAAAccountsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      order: `id`,
      limit: 10,
      page: payload.page,
      ...(payload?.searchString && { search: payload?.searchString }),
      ...(!payload?.isIncludeHiddenAccount && { hide_from_admins: 0 }),
      reverse: 1
    };
    const response = yield apiCall({ headers, params, ...API.getAccountSummaries });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAAAccountsListResponse(response.data.account_summaries));
    } else {
      yield put(getAAAccountsListFailed(ADMIN_AREA_ACCOUNT_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendAAFranchisorsListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      hide_from_admins: 0,
      limit: 10,
      order: `id`,
      page: payload.page,
      reverse: true,
      admin_area: 1,
      ...(payload?.searchString && { search: payload?.searchString })
    };
    const response = yield apiCall({ headers, params, ...API.getFranchisorsList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAAFranchisorsListResponse(response.data.franchisors));
    } else {
      yield put(getAAFranchisorsListFailed(ADMIN_AREA_FRANCHISORS_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendAAUsersListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {
      hide_from_admins: 0,
      limit: 10,
      order: `id`,
      page: payload.page,
      admin_area: 1,
      ...(payload?.searchString && { search: payload?.searchString })
    };
    const response = yield apiCall({ headers, params, ...API.getUsersList });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAAUsersListResponse(response.data.users));
    } else {
      yield put(getAAUsersListFailed(ADMIN_AREA_USERS_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendAAFranchisorStatsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = { franchisor_id: payload };
    const response = yield apiCall({ headers, params, ...API.getExpirtStats });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAAFranchisorStatsResponse(response.data.expiry_stats));
    } else {
      yield put(getAAFranchisorStatsFailure(response));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendAAUpdateFranchisorRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const params = {};
    const path = API.updateFranchisor.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: { franchisor: payload }, params, apiPath: path, action: API.updateFranchisor.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(updateAAFranchisorResponse(response.data.franchisor || {}));
    } else {
      yield put(updateAAFranchisorFailure(response));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendGetUserDetailsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.getUserDetail.apiPath.replace(':id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.getUserDetail.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getAAUserDetailsResponse(response.data.user));
    } else {
      yield put(getAAUserDetailsFailure(ADMIN_AREA_GET_USERS_DETAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendUpdateUserDetailsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.saveNotificationSettings.apiPath.replace(':id', payload.id);
    delete payload.id;
    const data = { user: payload };
    const response = yield apiCall({ headers, data, apiPath: path, action: API.saveNotificationSettings.action });
    if (response.status === 200) {
      yield put(updateAAUserDetailsResponse(response.data.user));
    } else {
      yield put(updateAAUserDetailsFailure(response.data.errors.email[0] === 'has already been taken' ? ADMIN_AREA_EXIST_EMAIL_FAIL_MSG : ADMIN_AREA_UPDATE_USERS_DETAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

function* sendAddUserInviteEmailRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const path = API.tmReinviteForEachUser.apiPath.replace(':user_id', payload);
    const response = yield apiCall({ headers, apiPath: path, action: API.tmReinviteForEachUser.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(addAAUserInviteMailResponse(DONE_SUCCESS_MSG));
    } else {
      yield put(addAAUserInviteMailFailure(ADMIN_AREA_ADD_USER_INVITE_EMAIL_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(ADMIN_AREA_EXCEPTION_ERROR));
  }
}

export function* takeAdminAreaRequest() {
  yield takeLatest(types.GET_AA_ACCOUNTS_LIST_REQUEST, getAAAccountsListRequest);
  yield takeLatest(types.GET_AA_FRANCHISORS_LIST_REQUEST, sendAAFranchisorsListRequest);
  yield takeLatest(types.GET_AA_USERS_LIST_REQUEST, sendAAUsersListRequest);
  yield takeLatest(types.GET_AA_FRANCHISOR_STATS_REQUEST, sendAAFranchisorStatsRequest);
  yield takeLatest(types.UPDATE_AA_FRANCHISOR_REQUEST, sendAAUpdateFranchisorRequest);
  yield takeLatest(types.GET_AA_USER_DETAILS_REQUEST, sendGetUserDetailsRequest);
  yield takeLatest(types.UPDATE_AA_USER_DETAILS_REQUEST, sendUpdateUserDetailsRequest);
  yield takeLatest(types.ADD_AA_USER_INVITE_EMAIL_REQUEST, sendAddUserInviteEmailRequest);
}
