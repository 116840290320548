import { userLoginsContainerTypes } from 'types';

export const USER_LOGINS_NEW_USER_INITIAL_VALUE: userLoginsContainerTypes.IULAddNewUserFieldValue = {
  email: '',
  first_name: '',
  last_name: '',
  mobile_phone: '',
  employee_login: false
  // type: ''
};

export const USER_LOGINS_NEW_USER_FILEDS: userLoginsContainerTypes.IULAddNewUserFields = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  MOBILE_PHONE: 'mobile_phone'
  // TYPE: 'type',
};

export const USER_LOGIN_ACTIVATE_COL_TEXT = 'The account has been activated by the user by clicking the link in the invitation email.';
