import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { settingsRallioProfileReducerTypes } from 'types';
import { ICommonResponseData } from 'types/common/api';

// UPDATE RALLIO PROFILE STATES
export const settingsUpdateRallioProfile = (payload: settingsRallioProfileReducerTypes.IRallioProfileValue) => createAction(actionTypes.SETTINGS_UPDATE_RALLIO_PROFILE, payload);

export const settingsUpdateProfileStatus = (payload: ICommonResponseData) => createAction(actionTypes.SETTINGS_UPDATE_PROFILE_STATUS, payload);

export const settingsResetprofileStatus = () => createAction(actionTypes.SETTINGS_RESET_PROFILE_STATUS);

// GET TIME ZONES
export const getTimeZoneRequest = () => createAction(actionTypes.GET_TIME_ZONES_REQUEST);

export const getTimeZoneResponse = (response: settingsRallioProfileReducerTypes.ITimeZoneResponse[]) => createAction(actionTypes.GET_TIME_ZONES_RESPONSE, response);

export const getTimeZoneFailure = (err: ICommonResponseData) => createAction(actionTypes.GET_TIME_ZONES_FAILURE, err);

// GET COUNTRIES CODE
export const getCountriesCodeRequest = () => createAction(actionTypes.GET_COUNTRIES_CODE_REQUEST);

export const getCountriesCodeResponse = (response: settingsRallioProfileReducerTypes.ICountriesCodeResponse[]) => createAction(actionTypes.GET_COUNTRIES_CODE_RESPONSE, response);

export const getCountriesCodeFailure = (err: ICommonResponseData) => createAction(actionTypes.GET_COUNTRIES_CODE_FAILURE, err);

// SETTINGS - VALIDATE POST PERSONALIZATION
export const settingsValidatePostPersonalizationRequest = (payload: number) => createAction(actionTypes.SETTINGS_VALIDATE_POST_PERSONALIZATION_REQUEST, payload);
export const settingsValidatePostPersonalizationResponse = (response: settingsRallioProfileReducerTypes.IPostsNeedingUpdate) =>
  createAction(actionTypes.SETTINGS_VALIDATE_POST_PERSONALIZATION_RESPONSE, response);
export const settingsValidatePostPersonalizationFailure = (error: null | string) => createAction(actionTypes.SETTINGS_VALIDATE_POST_PERSONALIZATION_FAILURE, error);

export const rallioProfileResetAll = () => createAction(actionTypes.RALLIO_PROFILE_RESET_ALL);

const SETTINGS_RALLIO_PROFILE = {
  settingsUpdateRallioProfile,
  settingsUpdateProfileStatus,
  settingsResetprofileStatus,
  getTimeZoneRequest,
  getTimeZoneResponse,
  getTimeZoneFailure,
  getCountriesCodeRequest,
  getCountriesCodeResponse,
  getCountriesCodeFailure,
  settingsValidatePostPersonalizationRequest,
  settingsValidatePostPersonalizationResponse,
  settingsValidatePostPersonalizationFailure,
  rallioProfileResetAll
};

export type SettingsRallioProfileActions = ActionsUnion<typeof SETTINGS_RALLIO_PROFILE>;
