import { useHideFilter } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const ComingSoon = () => {
  useHideFilter();

  return (
    <div className="frameModule comingsoon">
      <div className="cs-main">
        <div className="cs-left">
          <div className="cs-main-pic">
            <ImageValidation defaultImg="cs.png" isNotSvgFormat customName={'Coming Soon'} />
          </div>
        </div>
        <div className="cs-text">
          <h2>COMING SOON!</h2>
          <h4>We promise we're worth the wait</h4>
        </div>
        <div className="cs-right">
          <div className="cs-bg-pic">
            <ImageValidation defaultImg="cs-r" customName={'Coming Soon'} />
          </div>
        </div>
      </div>
    </div>
  );
};
