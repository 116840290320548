// LEADER BOARD - GET LEADER BOARD ANALYTICS
export const GET_LEADER_BOARD_ANALYTICS_REQUEST = 'leaderBoard_analytics/GET_LEADER_BOARD_REQUEST';
export const GET_LEADER_BOARD_ANALYTICS_RESPONSE = 'leaderBoard_analytics/GET_LEADER_BOARD_RESPONSE';
export const GET_LEADER_BOARD_ANALYTICS_FAILURE = 'leaderBoard_analytics/GET_LEADER_BOARD_FAILURE';

// LEADER BOARD - GET LEADER BOARD REVIW CATEGORY
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_REQUEST = 'leaderBoard_analytics/GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_REQUEST';
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_RESPONSE = 'leaderBoard_analytics/ GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_RESPONSE';
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_FAILURE = 'leaderBoard_analytics/ GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_FAILURE';

// LEADER BOARD - GET LEADER BOARD REVIW CATEGORY ID
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_REQUEST = 'leaderBoard_analytics/GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_REQUEST';
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_RESPONSE = 'leaderBoard_analytics/ GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_RESPONSE';
export const GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_FAILURE = 'leaderBoard_analytics/ GET_LEADER_BOARD_ANALYTICS_REVIEW_CATEGORY_ID_FAILURE';

// RESET REDUCER ON COMPONENT UNMOUNT
export const RESET_LEADER_BOARD_ANALYTICS_STATE = 'leaderBoard_analytics/RESET_LEADERBOARD_STATE';
