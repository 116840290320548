import { useState } from 'react';
// import { useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { commonBoost /* , IStore */ } from 'types';
import { capitalizeName } from 'utils/helpers';
import { EditAudienceModal } from './EditAudienceModal';
// import { FB_ADS_TARGET_CONNECTIONS_LIST } from 'utils/constants';

export const BoostAudience = ({ boostConfig, handleUpdateFBBoostConfig, handleUpdateAllFBBoostConfig }: commonBoost.IViewBoostAudienceProps) => {
  // const facebookBoostConfig = useSelector((state: IStore) => state.fbAdsTargeting.facebookBoostConfig);

  const [openEditAudience, setOpenEditAudience] = useState(false);

  return (
    <>
      <div className="boost__white--box audience-wrp r-mt2">
        <div>
          <span className="boost__box--title">Audience</span>
          <div className="boost__box--qn">Who should see your ad?</div>
        </div>
        <div className="aud-radio__wrp audience-option vbModal">
          <div className="r-flxs">
            {/* <label className="pt-head"> */}
            <span className="boost__inner--title">People you choose through targeting</span>
            {/* </label> */}

            {/* <div className="r-ml2"> */}
            <div className="boost__desc--txt r-mt1">Select the location, age, gender and interest of people you want to reach with your ad.</div>
            {/* </div> */}
          </div>

          <div className="audience-details">
            <div className="r-flx r-flx-jb">
              <span className="boost__inner--title">Audience Details</span>
              <div className="boost__media--icon cu-pointer">
                <ImageValidation isImgValid defaultImg="edit-rd" customName="edit" customClassname="edit" onClick={() => setOpenEditAudience(true)} />
              </div>
            </div>
            <div className="ad-llive">
              <div className="boost__desc--txt">
                Location:{' '}
                <span className="sec-lbl">
                  {boostConfig?.coordinates
                    ?.map((it) =>
                      capitalizeName(
                        `${Math.round(it.radius_in_meters * 0.00062)} mile${Math.round(it.radius_in_meters * 0.00062) > 1 ? 's' : ''} around ${it.human_readable_address.replace(',', ' ')}`
                      )
                    )
                    .join(', or ')}
                  <br />
                  <span>{boostConfig?.locations?.join(', ')}</span>
                </span>
              </div>
              <div className="boost__desc--txt r-mt1">
                Age: <span className="sec-lbl">{`${boostConfig?.minAge} - ${boostConfig?.maxAge || 65}`}</span>
                {!boostConfig?.maxAge ? '+' : ''}
              </div>
            </div>
          </div>
          {/* <div className='aud-radio__wrp'>
            <label>
              <span className='label'>Connections</span>
            </label>
            {
              FB_ADS_TARGET_CONNECTIONS_LIST.map((connectionDatum, index) => {
                return (
                  <label key={`view-boost-conn-${index}`}>
                    <input type='radio' className='option-input radio' name='connections'
                      checked={boostConfig?.connections === connectionDatum.name}
                      value={connectionDatum.name}
                      onChange={(event) => handleUpdateFBBoostConfig(event.target.name, event.target.value)} />
                    <span className='radio-label'>{connectionDatum.label}</span>
                  </label>
                );
              })
            }
          </div> */}
        </div>
      </div>
      <EditAudienceModal
        isModalShow={openEditAudience}
        handleModalClose={() => setOpenEditAudience(false)}
        boostConfig={boostConfig}
        handleUpdateFBBoostConfig={handleUpdateFBBoostConfig}
        handleUpdateAllFBBoostConfig={handleUpdateAllFBBoostConfig}
      />
    </>
  );
};
