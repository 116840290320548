import { useDispatch, useSelector } from 'react-redux';

import { setAISubscriptionDetailsModal, setAISubscriptionType } from 'actions';
import { AI_SUBSCRIPTION_TAB } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { IStore } from 'types';

export const AISubscriptionTrialPage = ({ isAlreadySubscribed }: { isAlreadySubscribed: boolean }) => {
  const dispatch = useDispatch();

  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);

  return (
    <div className="ai-sub-home">
      <div className="ai-promo-video">
        <div className="video-section">
          <ReactVideoPlayer videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/q_auto,c_limit,h_1024,w_1024/uflyanvylewjw41fr3sy.mp4'} isPlayControl customClassname={'video'} />
          <div className="ai-vs-top">
            <ImageValidation isImgValid defaultImg="ai-play" customName={'play'} />
          </div>
        </div>
      </div>
      <div className="ral-aip-main">
        <h2>Rallio AI Pricing &amp; Plans</h2>
        <p className="ai__pricing--txt">
          Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio AI! Try it for free for a week and get ready to fall in love! Want to save 17%? Click the
          yearly option.
        </p>

        <div className={`raip-item-group ${isAlreadySubscribed ? 'ai-as-options as-active' : 'ai-as-options as-inactive'}`}>
          {AI_SUBSCRIPTION_TAB.map((it) => (
            <div className={it.class} key={it.value}>
              <div className="raip-top">
                <h4>{it.label}</h4>
                <p>Per location</p>
              </div>
              <div className="raip-mid">
                <div className="raipm-price-main">
                  <span className="raipm-dollar">$</span>
                  <span className="raipm-price">{`${it.trialPrice}`}</span>
                  <span className="raipm-monthly">/mo</span>
                </div>
                {it.value === 'year' && (
                  <span className="raipm-monthly">
                    Billed Annually
                    <span className="raipm__monthly--bold">$288</span>
                  </span>
                )}
              </div>
              {!isAlreadySubscribed && (
                <div className="raip-ft">
                  <span className="raipft-txt">7 days Free Trial</span>
                </div>
              )}
              <button
                // className="gnrc-btn blue-gnr"
                className="ac-btn ac-primary"
                onClick={() => {
                  dispatch(setAISubscriptionDetailsModal({ type: 'newSubscription', isOpen: true, isTrialPreiod: true, isCoachMark: aiSubscriptionDetailsModal?.isCoachMark || false }));
                  dispatch(setAISubscriptionType(it));
                }}
              >
                <span>{isAlreadySubscribed ? 'Subscribe Now' : 'Try for free now'}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
