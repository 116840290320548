export const downloadFormatHandlerLogins = (data: any) => {
  return data?.map((it: any) => ({
    ID: it.advocateId,
    'First Name': it.firstName,
    'Last Name': it?.lastName,
    Email: it?.email,
    'Phone Number': it?.contact,
    'Invite Status': it?.emailStatus,
    'Invite Sent': it?.inviteEmailDate,
    'App Status': it?.appStatus,
    'Recent Activity': it?.recentActivityDate,
    'Location(s)': it?.accountNames.join(','),
    Activated: it?.emailStatus ? 'Yes' : 'No'
  }));
};
