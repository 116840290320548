import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { exceptionHandlerRequest } from 'actions';
import { ROUTES } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { IStore } from 'types';

export const PageNotFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, userOwnership } = useAccountSwitcherData();

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);

  const handleRefreshClick = () => {
    dispatch(exceptionHandlerRequest(null));
    const route = isAdmin ? ROUTES.ADMIN_ACCOUNTS.replace('/*', '') : `/${userOwnership}/${id}${ROUTES.DEFAULT}`;
    navigate(route);
  };

  return (
    <div className="page-not-found-wraps">
      <div className="pnf-bg">
        <div className="pnf-content no-page-exist d-none">
          <span className="error-id">404</span>
          <span className="oops-txt">Oops! Page is Not Found</span>
        </div>

        <div className="pnf-content">
          <span className="oops-txt">Oops! Something went wrong</span>
          <button className="btn go-home" onClick={() => handleRefreshClick()}>
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};
