// TEAM_MANAGEMENT - GET HUB LISTS
export const SET_VIEW_MODE = 'lists/SET_VIEW_MODE';

export const GET_LOCATIONS_HUBS_LIST_REQUEST = 'lists/GET_LOCATIONS_HUBS_LIST_REQUEST';
export const GET_LOCATIONS_HUBS_LIST_RESPONSE = 'lists/GET_LOCATIONS_HUBS_LIST_RESPONSE';
export const GET_LOCATIONS_HUBS_LIST_FAILURE = 'lists/GET_LOCATIONS_HUBS_LIST_FAILURE';

export const UPDATE_LOCATIONS_HUBS_LIST_REQUEST = 'lists/UPDATE_LOCATIONS_HUBS_LIST_REQUEST';
export const UPDATE_LOCATIONS_HUBS_LIST_RESPONSE = 'lists/UPDATE_LOCATIONS_HUBS_LIST_RESPONSE';
export const UPDATE_LOCATIONS_HUBS_LIST_FAILURE = 'lists/UPDATE_LOCATIONS_HUBS_LIST_FAILURE';

export const SUBSCRIPTIONS_LOCATION_TO_LIST_REQUEST = 'lists/SUBSCRIPTIONS_LOCATION_TO_LIST_REQUEST';
export const SUBSCRIPTIONS_LOCATION_TO_LIST_RESPONSE = 'lists/SUBSCRIPTIONS_LOCATION_TO_LIST_RESPONSE';
export const SUBSCRIPTIONS_LOCATION_TO_LIST_FAILURE = 'lists/SUBSCRIPTIONS_LOCATION_TO_LIST_FAILURE';

export const RELOAD_LIST = 'lists/RELOAD_LIST';

export const GET_LOCATIONS_TREE_REQUEST = 'lists/GET_LOCATIONS_TREE_REQUEST';
export const GET_LOCATIONS_TREE_RESPONSE = 'lists/GET_LOCATIONS_TREE_RESPONSE';
export const GET_LOCATIONS_TREE_FAILURE = 'lists/GET_LOCATIONS_TREE_FAILURE';

export const GET_LOCATION_HUB_DETAIL_REQUEST = 'list/GET_LOCATION_HUB_DETAIL_REQUEST';
export const GET_LOCATION_HUB_DETAIL_RESPONSE = 'list/GET_LOCATION_HUB_DETAIL_RESPONSE';
export const GET_LOCATION_HUB_DETAIL_FAILURE = 'list/GET_LOCATION_HUB_DETAIL_FAILURE';

export const ON_CHANGE_LIST = 'lists/ON_CHANGE_LIST';

export const SAVE_LOCATION_LIST_REQUEST = 'lists/SAVE_LOCATION_LIST_REQUEST';
export const SAVE_LOCATION_LIST_RESPONSE = 'lists/SAVE_LOCATION_LIST_RESPONSE';
export const SAVE_LOCATION_LIST_FAILURE = 'lists/SAVE_LOCATION_LIST_FAILURE';

export const DELETE_LOCATION_LIST_REQUEST = 'lists/DELETE_LOCATION_LIST_REQUEST';
export const DELETE_LOCATION_LIST_RESPONSE = 'lists/DELETE_LOCATION_LIST_RESPONSE';
export const DELETE_LOCATION_LIST_FAILURE = 'lists/DELETE_LOCATION_LIST_FAILURE';

export const OPERATION_LISTS_DETAIL_VIEW_ERROR = 'lists/OPERATION_LISTS_DETAIL_VIEW_ERROR';

export const OPERATION_LISTS_RESET_ALL = 'lists/OPERATION_LISTS_RESET_ALL';
export const OPERATION_LISTS_RESET_MODIFIED_OBJ = 'lists/OPERATION_LISTS_RESET_MODIFIED_OBJ';

// CONTENT SUPPLIER - GET FRANCHISOR LIST
export const CONTENT_FEED_GET_CS_FRANCHISORS_LIST_REQUEST = 'content-feed/CONTENT_FEED_GET_CS_FRANCHISORS_LIST_REQUEST';
export const CONTENT_FEED_GET_CS_FRANCHISORS_LIST_RESPONSE = 'content-feed/CONTENT_FEED_GET_CS_FRANCHISORS_LIST_RESPONSE';
export const CONTENT_FEED_GET_CS_FRANCHISORS_LIST_FAILURE = 'content-feed/CONTENT_FEED_GET_CS_FRANCHISORS_LIST_FAILURE';

// CONTENT FEED - GET CONTENT FEED LOCATIONS
export const CONTENT_FEED_GET_HUB_LIST_RESPONSE = 'content-feed/CONTENT_FEED_GET_HUB_LIST_RESPONSE';

// CONTENT FEED - TAGS LIST
export const CONTENT_FEED_ADD_TAGS = 'content-feed/CONTENT_FEED_ADD_TAGS';
export const CONTENT_FEED_RESET_TAGS = 'content-feed/CONTENT_FEED_RESET_TAGS';

// CONTENT SUPPLIER - GET ELIGIBLE CONTENTS COUNT

export const GET_ELIGIBLE_CONTENT_COUNT_REQUEST = 'lists/GET_ELIGIBLE_CONTENT_COUNT_REQUEST';
export const GET_ELIGIBLE_CONTENT_COUNT_RESPONSE = 'lists/GET_ELIGIBLE_CONTENT_COUNT_RESPONSE';
export const GET_ELIGIBLE_CONTENT_COUNT_FAILURE = 'lists/GET_ELIGIBLE_CONTENT_COUNT_FAILURE';

export const MODIFY_LOCATIONS_HUBS_LIST_RESPONSE = 'lists/MODIFY_LOCATIONS_HUBS_LIST_RESPONSE';
