import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { OperationsListsAction } from 'actions';
import { operationsListsReducerTypes } from 'types';
import { CONTENT_SUPPLIER_CONTENT_SOURCE_OPTIONS, CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES, INITIAL_ELIGIBLE_CONTENT_COUNT } from 'utils/constants';

const initialState: operationsListsReducerTypes.IOperationsListsReducer = {
  error: null,
  viewMode: 'list',
  locationsGroupList: [],
  isListFetching: false,
  isListUpdate: false,
  isSubscriptionsRequest: false,
  isListDetailFetching: true,
  isTreeFetching: false,
  loadListAgain: true,
  treeData: [],
  locationGroupDetail: null,
  isSaveRequest: false,
  modifiedObj: null,
  isDeleteRequest: false,
  detailViewError: null,
  cs_franchisors: [],
  scheduler_content_supplier: [],
  eligible_contents_counts: INITIAL_ELIGIBLE_CONTENT_COUNT,
  modifiedLocationGroupList: [],
  tags: []
};

const operationsListsReducer: Reducer<operationsListsReducerTypes.IOperationsListsReducer, OperationsListsAction> = (
  state: operationsListsReducerTypes.IOperationsListsReducer = initialState,
  action: OperationsListsAction
): operationsListsReducerTypes.IOperationsListsReducer => {
  switch (action.type) {
    case types.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.viewMode,
        locationGroupDetail: null,
        modifiedObj: null
      };
    case types.GET_LOCATIONS_HUBS_LIST_REQUEST:
      return {
        ...state,
        isListFetching: true
      };
    case types.GET_LOCATIONS_HUBS_LIST_RESPONSE:
      const isFeedListSubscribed = action.payload.data.some((location) => location.account_included);
      const updatedPayload = action.payload.isDefaultEnroll && !isFeedListSubscribed ? action.payload.data.map((location) => ({ ...location, account_included: true })) : action.payload.data;
      return {
        ...state,
        isListFetching: false,
        loadListAgain: false,
        modifiedLocationGroupList: action.payload.isDefaultEnroll && !isFeedListSubscribed ? updatedPayload : [],
        ...(!action.payload?.account_included && { locationsGroupList: updatedPayload })
      };
    case types.GET_LOCATIONS_HUBS_LIST_FAILURE:
      return {
        ...state,
        isListFetching: false,
        loadListAgain: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.UPDATE_LOCATIONS_HUBS_LIST_REQUEST:
      return {
        ...state,
        isListUpdate: true
      };
    case types.UPDATE_LOCATIONS_HUBS_LIST_RESPONSE:
      return {
        ...state,
        locationsGroupList: state.locationsGroupList.map((it) => (it.id === action.payload.id ? { ...it, ...action.payload } : it)),
        isListUpdate: false
      };
    case types.UPDATE_LOCATIONS_HUBS_LIST_FAILURE:
      return {
        ...state,
        isListUpdate: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.SUBSCRIPTIONS_LOCATION_TO_LIST_REQUEST:
      return {
        ...state,
        isSubscriptionsRequest: true
      };
    case types.SUBSCRIPTIONS_LOCATION_TO_LIST_RESPONSE:
      return {
        ...state,
        locationsGroupList: state.locationsGroupList.map((it) => (it.id === action.payload.id ? { ...it, ...action.payload } : it)),
        isSubscriptionsRequest: false
      };
    case types.SUBSCRIPTIONS_LOCATION_TO_LIST_FAILURE:
      return {
        ...state,
        isSubscriptionsRequest: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.RELOAD_LIST:
      return {
        ...state,
        loadListAgain: true
      };
    case types.GET_LOCATIONS_TREE_REQUEST:
      return {
        ...state,
        isTreeFetching: true
      };
    case types.GET_LOCATIONS_TREE_RESPONSE:
      return {
        ...state,
        treeData: action.payload.data,
        isTreeFetching: false
      };
    case types.GET_LOCATIONS_TREE_FAILURE:
      return {
        ...state,
        isTreeFetching: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.GET_LOCATION_HUB_DETAIL_REQUEST:
      return {
        ...state,
        isListDetailFetching: true
      };
    case types.GET_LOCATION_HUB_DETAIL_RESPONSE:
      return {
        ...state,
        isListDetailFetching: false,
        locationGroupDetail: action.payload.data,
        modifiedObj: {
          ...action.payload.data,
          content_suppliers: action.payload?.data?.content_suppliers?.length ? action.payload?.data?.content_suppliers.map((contentSupplier) => contentSupplier.id) : [],
          tags_list: action.payload?.data?.tags_list ? action.payload?.data?.tags_list.split(',') : [],
          content_source: CONTENT_SUPPLIER_CONTENT_SOURCE_OPTIONS.find((source) => source.identifier === action.payload?.data?.content_source)?.value || CONTENT_SUPPLIER_CONTENT_SOURCE_VALUES.ALL
        }
      };
    case types.GET_LOCATION_HUB_DETAIL_FAILURE:
      return {
        ...state,
        isListDetailFetching: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.ON_CHANGE_LIST:
      return {
        ...state,
        modifiedObj: { ...state.modifiedObj, [action.payload.key]: action.payload.value }
      };
    case types.SAVE_LOCATION_LIST_REQUEST:
      return {
        ...state,
        isSaveRequest: true
      };
    case types.SAVE_LOCATION_LIST_RESPONSE:
      return {
        ...state,
        isSaveRequest: false,
        loadListAgain: true,
        isListFetching: true,
        viewMode: 'list'
      };
    case types.SAVE_LOCATION_LIST_FAILURE:
      return {
        ...state,
        isSaveRequest: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.DELETE_LOCATION_LIST_REQUEST:
      return {
        ...state,
        isDeleteRequest: true
      };
    case types.DELETE_LOCATION_LIST_RESPONSE:
      return {
        ...state,
        isDeleteRequest: false,
        loadListAgain: true,
        isListFetching: true,
        viewMode: 'list'
      };
    case types.DELETE_LOCATION_LIST_FAILURE:
      return {
        ...state,
        isDeleteRequest: false,
        error: action.payload || 'Unexpected Error'
      };
    case types.OPERATION_LISTS_DETAIL_VIEW_ERROR:
      return {
        ...state,
        detailViewError: action.payload
      };
    case types.OPERATION_LISTS_RESET_ALL:
      return { ...initialState };
    case types.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_REQUEST:
      return {
        ...state,
        cs_franchisors: []
      };
    case types.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_RESPONSE:
      return {
        ...state,
        cs_franchisors: action.payload
      };
    case types.CONTENT_FEED_GET_CS_FRANCHISORS_LIST_FAILURE:
      return {
        ...state,
        cs_franchisors: [],
        error: action.payload
      };
    case types.CONTENT_FEED_GET_HUB_LIST_RESPONSE:
      return {
        ...state,
        scheduler_content_supplier: action.payload
      };
    case types.CONTENT_FEED_ADD_TAGS:
      return {
        ...state,
        tags: Array.from(new Map(action.payload.map((dataItem) => [dataItem, dataItem])).values())
      };
    case types.GET_ELIGIBLE_CONTENT_COUNT_RESPONSE:
      return {
        ...state,
        eligible_contents_counts: action.payload
      };
    case types.CONTENT_FEED_RESET_TAGS:
      return {
        ...state,
        tags: []
      };

    case types.MODIFY_LOCATIONS_HUBS_LIST_RESPONSE:
      return {
        ...state,
        modifiedLocationGroupList: action.payload
      };

    case types.OPERATION_LISTS_RESET_MODIFIED_OBJ:
      return { ...state, modifiedObj: initialState.modifiedObj };
    default:
      return state;
  }
};

export { operationsListsReducer };
