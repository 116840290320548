import { ITargetingReducer } from 'types/targeting';
import { TargetingAction } from 'actions';
import * as actionTypes from 'actions/action-types';

const initialState: ITargetingReducer = {
  isFetching: true,
  isAccountConfigFetching: true,
  data: null,
  error: null,
  selectedObj: null,
  detailViewError: null
};

export const targetingReducer = (prevState: ITargetingReducer = initialState, action: TargetingAction) => {
  switch (action.type) {
    case actionTypes.TARGETING_LIST_REQUEST:
      return {
        ...prevState,
        isFetching: action.payload.type === 'edit-list' ? false : true
      };
    case actionTypes.TARGETING_LIST_REQUEST_FAIL:
      return {
        ...prevState,
        error: action.payload.error,
        isFetching: false
      };
    case actionTypes.TARGETING_LIST_RESPONSE:
      return {
        ...prevState,
        data: action.payload.data,
        isFetching: false
      };
    case actionTypes.ON_CHANGE_CONFIGURATION:
      return {
        ...prevState,
        selectedObj: action.payload
      };
    case actionTypes.GET_ACCOUNT_CONFIG_DATA_REQUEST:
      return {
        ...prevState,
        isAccountConfigFetching: true
      };
    case actionTypes.GET_ACCOUNT_CONFIG_DATA_RESPONSE:
      return {
        ...prevState,
        isAccountConfigFetching: false,
        selectedObj: action.payload
      };
    case actionTypes.GET_ACCOUNT_CONFIG_DATA_FAILURE:
      return {
        ...prevState,
        isAccountConfigFetching: false,
        selectedObj: null,
        detailViewError: action.payload || 'No data found'
      };
    case actionTypes.MARKETING_TARGETING_RESET_ALL:
      return { ...initialState };
    default:
      return prevState;
  }
};
