// COMMUNICATIONS - GET BRAND LEVEL LIST
export const COMMUNICATION_GET_LIST_REQUEST = 'communications/COMMUNICATION_GET_LIST_REQUEST';
export const COMMUNICATION_GET_LIST_RESPONSE = 'communications/COMMUNICATION_GET_LIST_RESPONSE';
export const COMMUNICATION_GET_LIST_FAILURE = 'communications/COMMUNICATION_GET_LIST_FAILURE';

// COMMUNICATIONS - GET LOCATION LEVEL LIST
export const COMMUNICATION_CREATE_UPDATE_REQUEST = 'communications/COMMUNICATION_CREATE_UPDATE_REQUEST';
export const COMMUNICATION_CREATE_UPDATE_RESPONSE = 'communications/COMMUNICATION_CREATE_UPDATE_RESPONSE';
export const COMMUNICATION_CREATE_UPDATE_FAILURE = 'communications/COMMUNICATION_CREATE_UPDATE_FAILURE';

// COMMUNICATIONS - GET LOCATION LEVEL LIST
export const COMMUNICATION_DELETE_REQUEST = 'communications/COMMUNICATION_DELETE_REQUEST';
export const COMMUNICATION_DELETE_RESPONSE = 'communications/COMMUNICATION_DELETE_RESPONSE';
export const COMMUNICATION_DELETE_FAILURE = 'communications/COMMUNICATION_DELETE_FAILURE';

// COMMUNICATIONS - GET LOCATION LEVEL LIST
export const COMMUNICATION_MARK_AS_SEEN_REQUEST = 'communications/COMMUNICATION_MARK_AS_SEEN_REQUEST';
export const COMMUNICATION_MARK_AS_SEEN_RESPONSE = 'communications/COMMUNICATION_MARK_AS_SEEN_RESPONSE';
export const COMMUNICATION_MARK_AS_SEEN_FAILURE = 'communications/COMMUNICATION_MARK_AS_SEEN_FAILURE';
