import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { NOTIFICATION_DEFAULT_DATE } from 'utils/constants';

export const useShowDefaultNotificationSettings = () => {
  const rlcAccount = useSelector((state: IStore) => state.login.activeUser?.rlc_account || false);
  const rallioLocalAssetsNotificationEnrolledAt = useSelector((state: IStore) => state.login.activeUser?.rallio_local_assets_notification_enrolled_at || null);

  const showDefaultNotificationSettings = useMemo(() => {
    if (rlcAccount) {
      if (rallioLocalAssetsNotificationEnrolledAt === null) {
        return true;
      } else if (rallioLocalAssetsNotificationEnrolledAt === NOTIFICATION_DEFAULT_DATE) {
        return false;
      }
      return false;
    }
    return false;
  }, [rlcAccount, rallioLocalAssetsNotificationEnrolledAt]);

  return showDefaultNotificationSettings;
};
