export const SURVEY_RESULTS_REQUEST = 'analytics/SURVEY_RESULTS_REQUEST';
export const SURVEY_RESULTS_FAILURE = 'analytics/SURVEY_RESULTS_FAILURE';
export const SURVEY_RESULTS_RESPONSE = 'analytics/SURVEY_RESULTS_RESPONSE';

export const SENTIMENT_TIME_REQUEST = 'analytics/SENTIMENT_TIME_REQUEST';
export const SENTIMENT_TIME_FAILURE = 'analytics/SENTIMENT_TIME_FAILURE';
export const SENTIMENT_TIME_RESPONSE = 'analytics/SENTIMENT_TIME_RESPONSE';

export const AVERAGE_RATING_REQUEST = 'analytics/AVERAGE_RATING_REQUEST';
export const AVERAGE_RATING_FAILURE = 'analytics/AVERAGE_RATING_FAILURE';
export const AVERAGE_RATING_RESPONSE = 'analytics/AVERAGE_RATING_RESPONSE';

export const AGREED_TO_REVIEW_REQUEST = 'analytics/AGREED_TO_REVIEW_REQUEST';
export const AGREED_TO_REVIEW_FAILURE = 'analytics/AGREED_TO_REVIEW_FAILURE';
export const AGREED_TO_REVIEW_RESPONSE = 'analytics/AGREED_TO_REVIEW_RESPONSE';
