import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { accountSwitcherRequest, exceptionHandlerRequest, accountSwitcherRequestFail, isShowPageNotFound } from 'actions';
import { ROUTES, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { IStore } from 'types';

export const ErrorBoundaryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentRoute, dropDownData, isAdminScreenEnabled } = useAccountSwitcherData();

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const activeUserFranchisor = useSelector((state: IStore) => state.login.activeUserFranchisor);
  const activeUserAccount = useSelector((state: IStore) => state.login.activeUserAccount);
  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const showPageNotFound = useSelector((state: IStore) => state.common.showPageNotFound);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);

  const reloadWindow = () => {
    window.location.reload();
  };

  useEffect(() => {
    return () => {
      dispatch(exceptionHandlerRequest(null));
    };
    // eslint-disable-next-line
  }, []);

  const handleRefreshClick = () => {
    if (isAdminScreenEnabled) {
      navigate(`/${currentRoute}`);
      reloadWindow();
    } else {
      if (dropDownData.length) {
        const ownerShip = dropDownData[0]?.type === 'admin' ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
        navigate(ROUTES.CONTENT_POSTS.replace(':type', ownerShip).replace(':id', dropDownData[0].value.toString()).replace('/*', ''));
        reloadWindow();
      } else if (activeUserFranchisor) {
        navigate(ROUTES.CONTENT_POSTS.replace(':type', USER_OWNERSHIP.FRANCHISOR).replace(':id', activeUserFranchisor.id.toString()).replace('/*', ''));
        if (accountSwitcherError) {
          dispatch(accountSwitcherRequest({ label: '', value: activeUserFranchisor.id.toString(), userOwnership: USER_OWNERSHIP.FRANCHISOR, id: activeUserFranchisor.id, userId: activeUserId }));
        }
        reloadWindow();
      } else if (activeUserAccount) {
        navigate(ROUTES.CONTENT_POSTS.replace(':type', USER_OWNERSHIP.ACCOUNT).replace(':id', activeUserAccount.id.toString()).replace('/*', ''));
        if (accountSwitcherError) {
          dispatch(accountSwitcherRequest({ label: '', value: activeUserAccount.id.toString(), userOwnership: USER_OWNERSHIP.ACCOUNT, id: activeUserAccount.id, userId: activeUserId }));
        }
        reloadWindow();
      } else if (isAdmin) {
        navigate(ROUTES.ADMIN_ACCOUNTS.replace('/*', ''));
        dispatch(accountSwitcherRequestFail(null));
        reloadWindow();
      } else {
        dispatch(isShowPageNotFound(true));
      }
    }
  };

  return (
    <div className="page-not-found-wraps">
      <div className="pnf-bg">
        {showPageNotFound ? (
          <div className="pnf-content no-page-exist">
            <span className="error-id">404</span>
            <span className="oops-txt">Page not found</span>
          </div>
        ) : (
          <div className="pnf-content">
            <span className="oops-txt">Oops! Something went wrong</span>
            <button
              className="btn go-home"
              onClick={() => {
                handleRefreshClick();
              }}
            >
              {' '}
              Refresh Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
