export const PI_PICTURE_TYPE = {
  PROFILE: 'profile',
  COVER: 'cover'
};

export enum ProfileImgValidQueryParams {
  PLATFORM = 'platform',
  HUB_ID = 'hub_id',
  LOCATION_LIST_ID = 'location_list_id',
  LOCATION_ID = 'location_id'
}
