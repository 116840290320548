import {
  IDashboardSurveyStatsRequestParams,
  IDashboardSurveyStatsResponse,
  IDashboardSurveyRecentCommentsRequestParams,
  IDashboardSurveyRecentComments,
  IDashboardQuestionReqParams,
  IDashboardQuestListResponse,
  IDashboardSurveyAnswerParams,
  ISurveyAnswerResponse,
  IDashboardSurveySendMessageRequestParams,
  IDashboardSurveySendMessageResponse,
  IDashboardSurveyNotificationsRequestParams,
  IDashboardSurveyNotificationsResponse,
  IDashboardSurveyResolvedNotificationsResponse,
  IDashboardSurveyNotificationListResponse,
  IDashboardReviewStatsRequestParams
} from 'revv/types';
import { ActionsUnion, createAction } from 'revv/utils';
import { IReviewsStatsData } from 'types/reviews/reviews-api';
import * as actionTypes from '../action-types';

export const dashboardSurveyStatsRequest = (payload: IDashboardSurveyStatsRequestParams) => createAction(actionTypes.DASHBOARD_SURVEY_STATS_REQUEST, payload);
export const dashboardSurveyStatsFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_STATS_FAILURE, payload);
export const dashboardSurveyStatsResponse = (payload: IDashboardSurveyStatsResponse) => createAction(actionTypes.DASHBOARD_SURVEY_STATS_RESPONSE, payload);

export const dashboardReviewStatsRequest = (payload: IDashboardReviewStatsRequestParams) => createAction(actionTypes.DASHBOARD_REVIEW_STATS_REQUEST, payload);
export const dashboardReviewStatsRequestFailure = (payload: string) => createAction(actionTypes.DASHBOARD_REVIEW_STATS_REQUEST_FAILURE, payload);
export const dashboardReviewStatsResponse = (payload: IReviewsStatsData) => createAction(actionTypes.DASHBOARD_REVIEW_STATS_RESPONES, payload);

export const dashboardSurveyRecentCommentsRequest = (payload: IDashboardSurveyRecentCommentsRequestParams) => createAction(actionTypes.DASHBOARD_SURVEY_RECENT_COMMENTS_REQUEST, payload);
export const dashboardSurveyRecentCommentsFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_RECENT_COMMENTS_FAILURE, payload);
export const dashboardSurveyRecentCommentsResponse = (payload: IDashboardSurveyRecentComments[]) => createAction(actionTypes.DASHBOARD_SURVEY_RECENT_COMMENTS_RESPONSE, payload);

export const dashboardSurveySendMessageRequest = (payload: IDashboardSurveySendMessageRequestParams) => createAction(actionTypes.DASHBOARD_SURVEY_SEND_MESSAGE_REQUEST, payload);
export const dashboardSurveySendMessageFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_SEND_MESSAGE_FAILURE, payload);
export const dashboardSurveySendMessageResponse = (payload: IDashboardSurveySendMessageResponse) => createAction(actionTypes.DASHBOARD_SURVEY_SEND_MESSAGE_RESPONSE, payload);

export const dashboardSurveyQuestionRequest = (payload: IDashboardQuestionReqParams) => createAction(actionTypes.DASHBOARD_SURVEY_QUESTION_REQUEST, payload);
export const dashboardSurveyQuestionFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_QUESTION_FAILURE, payload);
export const dashboardSurveyQuestionResponse = (payload: IDashboardQuestListResponse[]) => createAction(actionTypes.DASHBOARD_SURVEY_QUESTION_RESPONSE, payload);

export const dashboardSurveyAnswerRequest = (payload: IDashboardSurveyAnswerParams) => createAction(actionTypes.DASHBOARD_SURVEY_ANSWER_REQUEST, payload);
export const dashboardSurveyAnswerFailure = (payload: { [key: string]: string }) => createAction(actionTypes.DASHBOARD_SURVEY_ANSWER_FAILURE, payload);
export const dashboardSurveyAnswerResponse = (payload: ISurveyAnswerResponse) => createAction(actionTypes.DASHBOARD_SURVEY_ANSWER_RESPONSE, payload);

export const dashboardSurveyDeliveryMethod = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_DELIVERY_METHOD, payload);

export const dashboardFocusAreaPageNum = (payload: number) => createAction(actionTypes.DASHBOARD_FOCUS_AREA_PAGE_NUM, payload);

export const dashboardSurveyNotificationRequest = (payload: IDashboardSurveyNotificationsRequestParams) => createAction(actionTypes.DASHBOARD_SURVEY_NOTIFICATION_REQUEST, payload);
export const dashboardSurveyNotificationFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_NOTIFICATION_FAILURE, payload);
export const dashboardSurveyNotificationResponse = (payload: IDashboardSurveyNotificationsResponse) => createAction(actionTypes.DASHBOARD_SURVEY_NOTIFICATION_RESPONSE, payload);

export const dashboardSurveyResolvedNotificationRequest = (payload: IDashboardSurveyNotificationsRequestParams) => createAction(actionTypes.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_REQUEST, payload);
export const dashboardSurveyResolvedNotificationFailure = (payload: string) => createAction(actionTypes.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_FAILURE, payload);
export const dashboardSurveyResolvedNotificationResponse = (payload: IDashboardSurveyResolvedNotificationsResponse) =>
  createAction(actionTypes.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_RESPONSE, payload);

export const dashboardResolveNotificationRequest = (payload: IDashboardSurveyNotificationsRequestParams) => createAction(actionTypes.DASHBOARD_RESOLVE_NOTIFICATION_REQUEST, payload);
export const dashboardResolveNotificationFailure = (payload: string) => createAction(actionTypes.DASHBOARD_RESOLVE_NOTIFICATION_FAILURE, payload);
export const dashboardResolveNotificationResponse = () => createAction(actionTypes.DASHBOARD_RESOLVE_NOTIFICATION_RESPONSE);

export const dashboardLocationDetailedViewRequest = (payload: number) => createAction(actionTypes.DASHBOARD_LOCATION_DETAILED_VIEW_REQUEST, payload);
export const dashboardLocationDetailedViewFailure = (payload: string) => createAction(actionTypes.DASHBOARD_LOCATION_DETAILED_VIEW_FAILURE, payload);
export const dashboardLocationDetailedViewResponse = (payload: IDashboardSurveyNotificationListResponse) => createAction(actionTypes.DASHBOARD_LOCATION_DETAILED_VIEW_RESPONSE, payload);

export const DASHBOARD_ACTIONS = {
  dashboardSurveyStatsRequest,
  dashboardSurveyStatsFailure,
  dashboardSurveyStatsResponse,
  dashboardReviewStatsRequest,
  dashboardReviewStatsRequestFailure,
  dashboardReviewStatsResponse,
  dashboardSurveyRecentCommentsRequest,
  dashboardSurveyRecentCommentsFailure,
  dashboardSurveyRecentCommentsResponse,
  dashboardSurveyQuestionRequest,
  dashboardSurveyQuestionFailure,
  dashboardSurveyQuestionResponse,
  dashboardSurveyAnswerRequest,
  dashboardSurveyAnswerFailure,
  dashboardSurveyAnswerResponse,
  dashboardSurveyDeliveryMethod,
  dashboardFocusAreaPageNum,
  dashboardSurveyNotificationRequest,
  dashboardSurveyNotificationFailure,
  dashboardSurveyNotificationResponse,
  dashboardSurveyResolvedNotificationRequest,
  dashboardSurveyResolvedNotificationFailure,
  dashboardSurveyResolvedNotificationResponse,
  dashboardLocationDetailedViewRequest,
  dashboardLocationDetailedViewFailure,
  dashboardLocationDetailedViewResponse
};

export type DashboardAction = ActionsUnion<typeof DASHBOARD_ACTIONS>;
