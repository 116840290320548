import { useCallback, useEffect, useState } from 'react';

import { ICarouselTableProps, IIndexable } from 'types';
import { NormalTableMode } from './NormalTableMode';
import { ResponsiveTableMode } from './ResponsiveTableMode';

export const CarouselTable = <T extends IIndexable, E extends {} = {}>({
  data,
  headers,
  isFetching,
  noDataMesg,
  ipadBoundary,
  ipadColCount,
  mobileBoundary,
  mobileColCount,
  mdDeviceBoundary,
  mdDeviceColCount,
  pinnedColumns,
  pinColumnsRight,
  responsiveNavigation,
  onHeaderClick,
  onRowClick,
  onColumnClick,
  onCustomHeaderDropDownClick,
  responsiveNavigationNextIcon,
  responsiveNavigationPreviousIcon,
  renderResponsiveNavNextIcon,
  renderResponsiveNavPrevIcon,
  tableClassName,
  colState
}: ICarouselTableProps<T, E>) => {
  const [isResponsive, setIsResponsive] = useState(false);

  const enableOrDisableResponsive = useCallback(() => {
    const boundary = mdDeviceBoundary || ipadBoundary || mobileBoundary || 567;
    if (window.innerWidth <= boundary) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [mdDeviceBoundary, ipadBoundary, mobileBoundary, setIsResponsive]);

  useEffect(() => {
    enableOrDisableResponsive();
    window.addEventListener('resize', enableOrDisableResponsive);
    return () => {
      window.removeEventListener('resize', enableOrDisableResponsive);
    };
  }, [enableOrDisableResponsive]);

  return isFetching && data.length === 0 ? null : isResponsive ? (
    <ResponsiveTableMode<T, E>
      data={data}
      headers={headers}
      tableClassName={tableClassName}
      mobileColCount={mobileColCount || 1}
      mobileBoundary={mobileBoundary || 575}
      ipadBoundary={ipadBoundary}
      ipadColCount={ipadColCount}
      mdDeviceBoundary={mdDeviceBoundary}
      mdDeviceColCount={mdDeviceColCount}
      pinnedColumns={pinnedColumns || [0]}
      pinColumnsRight={pinColumnsRight}
      onHeaderClick={onHeaderClick}
      onRowClick={onRowClick}
      onColumnClick={onColumnClick}
      responsiveNavigation={responsiveNavigation || 'dropdown'}
      onCustomHeaderDropDownClick={onCustomHeaderDropDownClick}
      responsiveNavigationNextIcon={responsiveNavigationNextIcon}
      responsiveNavigationPreviousIcon={responsiveNavigationPreviousIcon}
      renderResponsiveNavNextIcon={renderResponsiveNavNextIcon}
      renderResponsiveNavPrevIcon={renderResponsiveNavPrevIcon}
      noDataMesg={noDataMesg}
      colState={colState}
    />
  ) : (
    <NormalTableMode<T, E>
      data={data}
      tableClassName={tableClassName}
      headers={headers}
      onHeaderClick={onHeaderClick}
      onRowClick={onRowClick}
      onColumnClick={onColumnClick}
      noDataMesg={noDataMesg}
      colState={colState}
    />
  );
};
