import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import moment from 'moment-timezone';

import { subscriptionTableEndDateChecker, dateFormatByRequestedFormat, isDateInBetweenTwoDates, getDiscountedDaysFromToday } from 'utils/helpers';
import { MONTH_DATE_FORMAT, SCROLL_THRESHOLD, AI_SUBSCRIPTION_TAB, AI_SUBSCRIPTION_TABLE_HEADERS, CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB, ICSSubscriptionPlans } from 'utils/constants';
import { ICustomHeaderDropDownOption, IStore, ITableHeader } from 'types';
import { setAISubscriptionDetailsModal, setAISubscriptionType, setCsLocationSubscriptionModalType, setSelectedSubscriptionTableData } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { VirtualTable } from 'components/Common/CarouselTable';
import { useAccountSwitcherData } from 'utils/hooks';

type ISortingOptions = 'ascending' | 'descending';

export const AISubscriptionTable = () => {
  const dispatch = useDispatch();
  const { id } = useAccountSwitcherData();

  const subscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.subscriptionTableData);
  const isFetching = useSelector((state: IStore) => state.stripe.isFetching);
  const isAiSubscriptionFetching = useSelector((state: IStore) => state.aiContentCreator.isAiSubscriptionFetching);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);

  const subscriptionObj: any = currentSubscription.find((datum) => datum.franchisor_id === id || datum.account_id === id);

  const [isAscending, setAscending] = useState<boolean>(false);

  const renderHeader = useCallback(
    (headerObj: ITableHeader<any, {}>, isResponsiveMode?: boolean, setShowDropdown?: React.Dispatch<React.SetStateAction<string>>, pinned?: boolean) => {
      return (
        <div
          onClick={() => {
            if (isResponsiveMode && !pinned && setShowDropdown) {
              setShowDropdown((prevState) => (headerObj.identifier === prevState ? '' : headerObj.identifier));
            }
          }}
        >
          <span>{headerObj.label}</span>
          {headerObj.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? (
            <ImageValidation isImgValid defaultImg="sort" customName="sort" customClassname={`sort-cus ${isAscending ? 'sort-icon__rotate' : ''}`} />
          ) : null}
        </div>
      );
    },
    [isAscending]
  );

  const renderColumn = useCallback((rowObj: any, header: ITableHeader<any, {}>, rowIndex: number, colState?: {}) => {
    return (
      <>
        {!header.identifier ? (
          <span className={`adsi-sign ${rowObj[header.identifier] ? 'active' : ''}`} />
        ) : header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[1].value ? (
          <div className="ad-label">
            <span className="adl-top ">Rallio AI</span>
          </div>
        ) : header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[6].value ? (
          <span className="ad-cnt adc-bold">{rowObj[header.identifier]}</span>
        ) : (
          <span className="ad-cnt">{rowObj[header.identifier]}</span>
        )}
      </>
    );
  }, []);

  const tableComponentHeader = AI_SUBSCRIPTION_TABLE_HEADERS.map((it): ITableHeader<string> => {
    const responsiveSortingOptions: ICustomHeaderDropDownOption[] = [
      {
        identifier: 'ascending' as ISortingOptions,
        text: 'Low to High',
        type: 'sort'
      },
      {
        identifier: 'descending' as ISortingOptions,
        text: 'High to Low',
        type: 'sort'
      }
    ];
    return {
      identifier: it.value,
      headerClassName: it.className,
      colClassName: !it.value ? 'ads-item' : it.value === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? 'sort-active' : '',
      label: it.title,
      labelString: it.title,
      headerDropdownOptions: it.value === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? responsiveSortingOptions : [],
      renderColumn,
      renderHeader
    };
  });

  const handleClick = (it: any) => {
    if (it?.data[0]?.franchisor_subscription_type_id) {
      if ([7, 8].includes(+it?.data[0]?.franchisor_subscription_type_id)) {
        dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
        dispatch(setCsLocationSubscriptionModalType('ai_community'));
        dispatch(setSelectedSubscriptionTableData(it));
      } else if (![7, 8].includes(+it?.data[0]?.franchisor_subscription_type_id)) {
        dispatch(setSelectedSubscriptionTableData(it));
        dispatch(setAISubscriptionType(CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB[it.data[0]?.subscription_plan === ICSSubscriptionPlans.TIER2 ? 0 : 1]));
        dispatch(setAISubscriptionDetailsModal({ type: 'contentSupplierSubscription', isOpen: true }));
      }
    } else {
      dispatch(setSelectedSubscriptionTableData(it));
      dispatch(setAISubscriptionType(AI_SUBSCRIPTION_TAB[it.data[0]?.subscription_period === 'month' ? 0 : 1]));
      dispatch(setAISubscriptionDetailsModal({ type: 'modifySubscription', isOpen: true }));
    }
  };

  const subscriptionKeys = Object.keys(subscriptionTableData);
  const tableValue = useMemo(() => {
    return subscriptionKeys
      .reduce((acc: any[], curr: any, index: number) => {
        const currentDataArr = subscriptionTableData[curr];
        const monthlyData = currentDataArr?.filter((it) => it.franchisor_subscription_type_id === null && it.subscription_period === 'month');
        const yearlyData = currentDataArr?.filter((it) => it.franchisor_subscription_type_id === null && it.subscription_period === 'year');
        const contentSupplierData = currentDataArr?.filter(
          (it) =>
            it.subscription_plan === ICSSubscriptionPlans.FREE ||
            it.subscription_plan === ICSSubscriptionPlans.TIER2 ||
            it.subscription_plan === ICSSubscriptionPlans.TIER2POINT5 ||
            it.subscription_plan === ICSSubscriptionPlans.CS_PREMIUM
        );

        if (monthlyData?.length) {
          let dataObj = {};
          const endDate = dateFormatByRequestedFormat(monthlyData[0]?.end_date, MONTH_DATE_FORMAT);
          const currentValue: any = {
            subscritpionPeriod: endDate,
            plan: monthlyData[0]?.trial_period ? 'Free trial' : 'Monthly',
            purchaseDate: dateFormatByRequestedFormat(curr, MONTH_DATE_FORMAT),
            dueDate: endDate,
            locations: monthlyData.length,
            auto_renewal: monthlyData[0]?.auto_renewal,
            amount: `$${monthlyData[0]?.trial_period ? 0 : monthlyData?.length * 29}`
          };
          const isActive = isDateInBetweenTwoDates(moment(monthlyData[0]?.start_date), moment(monthlyData[0]?.end_date), moment());
          AI_SUBSCRIPTION_TABLE_HEADERS.forEach((it) => {
            const value = it?.value
              ? it.value === AI_SUBSCRIPTION_TABLE_HEADERS[4].value
                ? currentValue.auto_renewal
                  ? `Renews On ${currentValue[it?.value]}`
                  : `${monthlyData[0]?.end_date && subscriptionTableEndDateChecker(monthlyData[0]?.end_date) ? 'Ends' : 'Ended'} On ${currentValue[it?.value]}`
                : currentValue[it?.value]
              : isActive;
            dataObj = {
              ...dataObj,
              [it.value]: value,
              identifier: { isActive, data: monthlyData }
            };
          });
          acc.push(dataObj);
        }
        if (yearlyData?.length) {
          let dataObj = {};
          const endDate = dateFormatByRequestedFormat(yearlyData[0]?.end_date, MONTH_DATE_FORMAT);
          const currentValue: any = {
            subscritpionPeriod: endDate,
            plan: yearlyData[0]?.trial_period ? 'Free trial' : 'Yearly',
            purchaseDate: dateFormatByRequestedFormat(curr, MONTH_DATE_FORMAT),
            dueDate: endDate,
            locations: yearlyData.length,
            auto_renewal: yearlyData[0]?.auto_renewal,
            amount: `$${yearlyData[0]?.trial_period ? 0 : yearlyData?.length * 288}`
          };
          const isActive = isDateInBetweenTwoDates(moment(yearlyData[0]?.start_date), moment(yearlyData[0]?.end_date), moment());
          AI_SUBSCRIPTION_TABLE_HEADERS.forEach((it) => {
            const value = it?.value
              ? it.value === AI_SUBSCRIPTION_TABLE_HEADERS[4].value
                ? currentValue.auto_renewal
                  ? `Renews On ${currentValue[it?.value]}`
                  : `${yearlyData[0]?.end_date && subscriptionTableEndDateChecker(yearlyData[0]?.end_date) ? 'Ends' : 'Ended'} On ${currentValue[it?.value]}`
                : currentValue[it?.value]
              : isActive;
            dataObj = {
              ...dataObj,
              [it.value]: value,
              identifier: { isActive, data: yearlyData }
            };
          });
          acc.push(dataObj);
        }
        if (contentSupplierData?.length) {
          let dataObj = {};
          const endDate = dateFormatByRequestedFormat(contentSupplierData[0]?.end_date, MONTH_DATE_FORMAT);
          const discountDays = getDiscountedDaysFromToday(contentSupplierData[0]?.created_at || '');
          const isDiscountPriceAvailable = discountDays >= 1 && discountDays <= 7;
          const currentValue: any = {
            subscritpionPeriod: endDate,
            plan:
              contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.FREE
                ? contentSupplierData[0]?.franchisor_subscription_type_id === 1
                  ? 'Tier 1'
                  : 'Free'
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2
                ? 'Tier 2'
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2POINT5
                ? 'Tier 2.5'
                : 'Premium',
            purchaseDate: dateFormatByRequestedFormat(curr, MONTH_DATE_FORMAT),
            dueDate: endDate,
            locations: contentSupplierData.length,
            auto_renewal: contentSupplierData[0]?.auto_renewal,
            amount: `$${
              contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.FREE
                ? 0
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2
                ? 10
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2POINT5
                ? 25
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.CS_PREMIUM
                ? isDiscountPriceAvailable
                  ? 7.95
                  : 9.95
                : 0
            }`
          };
          const isActive = isDateInBetweenTwoDates(moment(contentSupplierData[0]?.start_date), moment(contentSupplierData[0]?.end_date), moment());
          AI_SUBSCRIPTION_TABLE_HEADERS.forEach((it) => {
            const value = it?.value
              ? it.value === AI_SUBSCRIPTION_TABLE_HEADERS[4].value
                ? currentValue.auto_renewal
                  ? `Renews On ${currentValue[it?.value]}`
                  : contentSupplierData[0]?.subscription_plan !== ICSSubscriptionPlans.FREE
                  ? `${contentSupplierData[0]?.end_date && subscriptionTableEndDateChecker(contentSupplierData[0]?.end_date) ? 'Ends' : 'Ended'} On ${currentValue[it?.value]}`
                  : ''
                : currentValue[it?.value]
              : isActive;
            dataObj = {
              ...dataObj,
              [it.value]: value,
              identifier: { isActive, data: contentSupplierData }
            };
          });
          acc.push(dataObj);
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => {
        return isAscending
          ? new Date(a.identifier?.data[0]?.created_at).valueOf() - new Date(b.identifier?.data[0].created_at).valueOf()
          : new Date(b.identifier?.data[0]?.created_at).valueOf() - new Date(a.identifier?.data[0].created_at).valueOf();
      });
  }, [isAscending, subscriptionKeys, subscriptionTableData]);

  return (
    <>
      <Modal.Header>
        <div className="rai-sub-top">
          <h2>Rallio Subscriptions</h2>
          {!subscriptionObj?.franchisor_subscription_type_id && !isAiSubscriptionFetching ? (
            <div className="ai-stripe-btns csuy-btns ans-btn">
              <button
                // className="gnrc-btn blue-gnr add-new-sub-btn"
                className="ac-btn ai__sub--btn"
                onClick={() => {
                  if (subscriptionType.value === AI_SUBSCRIPTION_TAB[1].value) {
                    dispatch(setAISubscriptionType(AI_SUBSCRIPTION_TAB[0]));
                  }
                  dispatch(setAISubscriptionDetailsModal({ type: 'newSubscription', isOpen: true }));
                }}
              >
                <span>Add New Subscriptions</span>
              </button>
            </div>
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="resuseTable adptTable">
          <div className={`adpt-height ${tableValue.length === 0 ? 'adpt__nodata--height' : ''}`} id="subscriptionTable">
            <VirtualTable<any>
              data={[tableValue]}
              headers={tableComponentHeader}
              ipadBoundary={768}
              ipadColCount={2}
              mobileBoundary={575}
              mobileColCount={2}
              mdDeviceBoundary={992}
              mdDeviceColCount={3}
              pinnedColumns={[0, 1]}
              isFetching={false}
              noDataMesg={isFetching || isAiSubscriptionFetching ? 'Loading...' : undefined}
              scrollableTargetString="subscriptionTable"
              scrollThreshold={SCROLL_THRESHOLD}
              tableClassName="responsiveTable"
              onRowClick={(row) => {
                handleClick(row.identifier);
              }}
              onHeaderClick={(header) => {
                if (header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value) {
                  setAscending(!isAscending);
                }
              }}
              onCustomHeaderDropDownClick={(header, dropdownItem) => {
                if (header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value) {
                  if (dropdownItem.identifier === ('ascending' as ISortingOptions)) {
                    setAscending(true);
                  } else {
                    setAscending(false);
                  }
                }
              }}
            />
          </div>
          <p className="adp-base">Your payments will be charged against the credit card on file. You will be charged for the upcoming period unless you manually cancel the subscription.</p>
        </div>
      </Modal.Body>
    </>
  );
};
