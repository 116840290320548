export const useBackToTopButton = (scrollElementId: string, btnElementId: string) => {
  const scrollElement = document.getElementById(scrollElementId);
  const buttonDiv = document.getElementById(btnElementId);

  if (scrollElement) {
    scrollElement.onscroll = () => scrollFunction();
  }

  const scrollFunction = () => {
    if (buttonDiv) {
      if (scrollElement && scrollElement.scrollTop > 100) {
        buttonDiv.className = 'scrollTop scrolled-d';
      } else {
        buttonDiv.className = 'd-none';
      }
    }
  };
};
