export const REVIEW_TEMPLATE_FIELDS = {
  REVIEW_REPLY_TEMPLATE_1_STAR: 'review_reply_template_1_star',
  REVIEW_REPLY_TEMPLATE_2_STAR: 'review_reply_template_2_star',
  REVIEW_REPLY_TEMPLATE_3_STAR: 'review_reply_template_3_star',
  REVIEW_REPLY_TEMPLATE_4_STAR: 'review_reply_template_4_star',
  REVIEW_REPLY_TEMPLATE_5_STAR: 'review_reply_template_5_star',
  FB_RECOMMEND_TEXT: 'review_reply_template_facebook_recommend',
  FB_NOT_RECOMMEND_TEXT: 'review_reply_template_facebook_not_recommend'
};

export const reviewTemplateListData = Object.values(REVIEW_TEMPLATE_FIELDS).map((listKey) => {
  const obj = listKey.includes('facebook_recommend')
    ? { title: 'Facebook Recommended', key: listKey, className: 'fb-rec-icon' }
    : listKey.includes('facebook_not_recommend')
    ? { title: 'Facebook Not Recommended', key: listKey, className: 'fb-non-rec-icon' }
    : { title: 'Star Rating', key: listKey, className: 'star-filled' };
  return obj;
});
