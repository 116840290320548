// CALENDAR - GET SCHEDULED POSTS BY LOCATION
export const GET_SCHEDULED_POST_REQUEST = 'calendar/GET_SCHEDULED_POST_REQUEST';
export const GET_SCHEDULED_POST_RESPONSE = 'calendar/GET_SCHEDULED_POST_RESPONSE';

// CALENDAR - UPDATE SCHEDULED POST BY LOCATION
export const UPDATE_SCHEDULED_POST_REQUEST = 'calendar/UPDATE_SCHEDULED_POST_REQUEST';
export const UPDATE_SCHEDULED_POST_RESPONSE = 'calendar/UPDATE_SCHEDULED_POST_RESPONSE';
export const UPDATE_SCHEDULED_POST_FAILURE = 'calendar/UPDATE_SCHEDULED_POST_FAILURE';

// CALENDAR - GET REQUESTED CALENDAR ITEMS
export const GET_CALENDAR_ITEMS_REQUEST = 'calendar/GET_CALENDAR_ITEMS_REQUEST';
export const GET_CALENDAR_ITEMS_RESPONSE = 'calendar/GET_CALENDAR_ITEMS_RESPONSE';

// CALENDAR - UPDATE REQUESTED CALENDAR ITEM
export const UPDATE_REQUESTED_CALENDAR_ITEM_REQUEST = 'calendar/UPDATE_REQUESTED_CALENDAR_ITEM_REQUEST';
export const UPDATE_REQUESTED_CALENDAR_ITEM_RESPONSE = 'calendar/UPDATE_REQUESTED_CALENDAR_ITEM_RESPONSE';
export const UPDATE_REQUESTED_CALENDAR_ITEM_FAILURE = 'calendar/UPDATE_REQUESTED_CALENDAR_ITEM_FAILURE';

// CALENDAR - GET SCHEDULED MULTIPLE POST
export const GET_SCHEDULED_MULTI_POST_REQUEST = 'calendar/GET_SCHEDULED_MULTI_POST_REQUEST';
export const GET_SCHEDULED_MULTI_POST_RESPONSE = 'calendar/GET_SCHEDULED_MULTI_POST_RESPONSE';

// CALENDAR - DELETE SCHEDULED MULTIPLE POST
export const DELETE_SCHEDULED_MULTI_POST_REQUEST = 'calendar/DELETE_SCHEDULED_MULTI_POST_REQUEST';
export const DELETE_SCHEDULED_MULTI_POST_RESPONSE = 'calendar/DELETE_SCHEDULED_MULTI_POST_RESPONSE';
export const DELETE_SCHEDULED_MULTI_POST_FAILURE = 'calendar/DELETE_SCHEDULED_MULTI_POST_FAILURE';

// CALENDAR - UPDATE SCHEDULED MULTIPLE POST
export const UPDATE_SCHEDULED_MULTI_POST_REQUEST = 'calendar/UPDATE_SCHEDULED_MULTI_POST_REQUEST';
export const UPDATE_SCHEDULED_MULTI_POST_RESPONSE = 'calendar/UPDATE_SCHEDULED_MULTI_POST_RESPONSE';
export const UPDATE_SCHEDULED_MULTI_POST_FAILURE = 'calendar/UPDATE_SCHEDULED_MULTI_POST_FAILURE';

// CALENDAR - UPDATE SCHEDULED POST
export const UPDATE_SCHEDULED_POST = 'calendar/UPDATE_SCHEDULED_POST';
export const GET_ACTIVE_PLATFORM_IN_SCH_POST = 'calendar/GET_ACTIVE_PLATFORM_IN_SCH_POST';

export const CALENDAR_DETAIL_VIEW_ERROR = 'calendar/CALENDAR_DETAIL_VIEW_ERROR';

// SET CALENDAR FILTER MODAL
export const SET_CALENDAR_FILTER_MODAL_ID = 'calendar/SET_CALENDAR_FILTER_MODAL_ID';

// CALENDAR UPDATE EDIT VIEW
export const CALENDAR_EDIT_VIEW = 'calendar/CALENDAR_EDIT_VIEW';

// CALENDAR_VIEW_DATE_RANGE
export const CALENDAR_VIEW_DATE_RANGE = 'calendar/CALENDAR_VIEW_DATE_RANGE';

// CALENDAR ENABLE SCHEDULE
export const CALENDAR_ENABLE_SCHEDULE = 'calendar/CALENDAR_ENABLE_SCHEDULE';

// CALENDAR ENABLE DATE_PICKER
export const CALENDAR_ENABLE_DATE_PICKER = 'calendar/CALENDAR_ENABLE_DATE_PICKER';

// CALENDAR DELETE MODEL OPTION
export const CALENDAR_DELETE_MODEL_OPTION = 'calendar/CALENDAR_DELETE_MODEL_OPTION';

// CALENDAR LOCATION UPDATE STATES
export const CALENDAR_LOCATION_UPDATE_STATES = 'calendar/CALENDAR_LOCATION_UPDATE_STATES';

// CALENDAR SELECT DATA OBJECT
export const CALENDAR_SELECT_DATA_OBJECT = 'calendar/CALENDAR_SELECT_DATA_OBJECT';

// CALENDAR RESET
export const CALENDAR_RESET_ALL = 'calendar/CALENDAR_RESET_ALL';
export const CALENDAR_RESET_LIST_ALL = 'calendar/CALENDAR_RESET_LIST_ALL';

// CALENDAR SHUFFLE POST
export const CALENDAR_SHUFFLE_AUTOMATED_SCHEDULED_COUNT = 'calendar/CALENDAR_SHUFFLE_AUTOMATED_SCHEDULED_COUNT';

// CALENDAR AUTOMATED POST DELETE MODEL OPTIONS
export const CALENDAR_AUTOMATED_SCHEDULED_POST_DELETE_MODEL_OPTION = 'calendar/CALENDAR_AUTOMATED_SCHEDULED_POST_DELETE_MODEL_OPTION';
