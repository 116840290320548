// UPDATE SALES
export const SALES_UPDATE_QUERY_FIELDS = 'sales/SALES_UPDATE_QUERY_FIELDS';

// MODAL OPEN
export const SALES_IS_DEMO_MODAL_OPEN = 'sales/SALES_IS_DEMO_MODAL_OPEN';

// SALES SCHEDULE DEMO CONTACT EMAIL
export const SCHEDULE_DEMO_CONTACT_EMAIL_REQUEST = 'sales/SCHEDULE_DEMO_CONTACT_EMAIL_REQUEST';
export const SCHEDULE_DEMO_CONTACT_EMAIL_RESPONSE = 'sales/SCHEDULE_DEMO_CONTACT_EMAIL_RESPONSE';
export const SCHEDULE_DEMO_CONTACT_EMAIL_FAILURE = 'sales/SCHEDULE_DEMO_CONTACT_EMAIL_FAILURE';

// REVV LOGIN REQUEST
export const REVV_LOGIN_REQUEST = 'sales/REVV_LOGIN_REQUEST';
export const REVV_LOGIN_RESPONSE = 'sales/REVV_LOGIN_RESPONSE';
export const REVV_LOGIN_FAILURE = 'sales/REVV_LOGIN_FAILURE';

// SCHEDULE_DEMO_CONTACT_EMAIL RESET MESSAGE
export const SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE_RESET = 'sales/SCHEDULE_DEMO_CONTACT_EMAIL_MESSAGE_RESET';
