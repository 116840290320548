import { settingsRallioProfileContainerTypes } from 'types';
import { RALLIO_PROFILE_TAB_FILEDS, /* CONNECTION_TABS, RALLIO_PROFILE_OBJ_TYPE, RIPPLE_COLOR, */ APP_TITLE, VALID_APP_URL_TITLES } from 'utils/constants';
// import { compareProfileObjects } from 'utils/helpers';
import { /* useAccountSwitcherData, */ useNavBarData } from 'utils/hooks';
// import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const AgencyInfoSection = ({
  hideLogo,
  selectedFranchisorId,
  accountId,
  values,
  actualValues,
  handleBlur,
  handleChange,
  getInputTitleClassName,
  getInputBoxClassName,
  getClassNames,
  handleIndividualCancel,
  handleIndividualSave
}: settingsRallioProfileContainerTypes.ISettingsRPProfileInfoProps) => {
  // const { optionalParams } = useAccountSwitcherData();

  const { subNavPageName } = useNavBarData();

  // const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);

  /* const isProfileNameEditDisabled = useMemo(() => {
    if (id && userOwnership && menuAccess) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('profile_name');
      }
    }
    return false;
  }, [id, userOwnership, menuAccess]); */

  return (
    <div className="settings-rallio-profile-new-section wn-agencyInfo wnh">
      <div className="content-full-wrp settins-content--wrp">
        <div className="content-left-section">
          <h3 className="global__title--txt">Agency Info</h3>
        </div>
        <div className="content-right-section form-elements-new">
          {/* ${isProfileNameEditDisabled ? 'events-none pointer-events-none' : ''} */}
          <div className={`form-group form-field-item ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.NAME)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.NAME}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.NAME)}
              value={String(values[RALLIO_PROFILE_TAB_FILEDS.NAME] || '')}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.NAME, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.NAME)}`}>
              {subNavPageName === 'connections' || selectedFranchisorId ? 'Location Name' : `${hideLogo || VALID_APP_URL_TITLES.includes(APP_TITLE) ? '' : 'Agency'} Name`}
              <span className="mandatory cursor-help">*</span>
            </span>
          </div>

          <div className={`form-group form-field-item  ${getInputBoxClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>
            <input
              type="text"
              name={RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL}
              className={getClassNames(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}
              value={values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL] ? String(values[RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL]) : ''}
              autoComplete="off"
              onBlur={handleBlur}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL, e.target.value);
              }}
            />
            <span className={`fltlabels ${getInputTitleClassName(RALLIO_PROFILE_TAB_FILEDS.WEBSITE_URL)}`}>Website URL</span>
          </div>
        </div>
      </div>

      {/* {optionalParams[2] !== CONNECTION_TABS.RALLIO_PROFILE && !compareProfileObjects(RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO, values, actualValues) && (
        <div className="indivual-button-section">
          <div className="stg-item form-configure">
            <div className="fc-actions g-20">
              <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline size-xs border-0" onClick={() => handleIndividualCancel(RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO)}>
                  Cancel
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary size-xs`} onClick={() => handleIndividualSave(RALLIO_PROFILE_OBJ_TYPE.PROFILE_INFO)}>
                  Save
                </button>
              </CustomRippleButton>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};
