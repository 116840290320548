import InfiniteScroll from 'react-infinite-scroll-component';

import { Loading } from 'components';
import { NonPspTableView } from 'revv/components/table-view';
import { INonPspTableHeader } from 'revv/types';

interface ITableViewProps<ITableValue> {
  isFetching: boolean;
  tableHeader: INonPspTableHeader[];
  tableValue: ITableValue[];
  noDataMsg?: string;
  onScroll: () => void;
  tableClass: string;
  renderColum: (headers: INonPspTableHeader[], data: ITableValue, index: number) => React.ReactNode;
  sortString?: string;
  totalRow?: ITableValue;
  renderTotalsRow?: (headers: INonPspTableHeader[], totalsRow: ITableValue) => React.ReactNode;
}

export const NonPspScrollableListView = <ITableValue extends { [key: string]: any }>({
  isFetching,
  tableHeader,
  tableValue,
  noDataMsg,
  tableClass,
  renderColum,
  onScroll,
  renderTotalsRow,
  totalRow
}: ITableViewProps<ITableValue>) => {
  return (
    <InfiniteScroll scrollableTarget="scrollTarget" hasMore dataLength={tableValue.length || 0} next={onScroll} loader={isFetching ? <Loading /> : null}>
      <NonPspTableView<ITableValue>
        headers={tableHeader}
        data={tableValue}
        renderColum={renderColum}
        tableClass={tableClass}
        totalRow={totalRow}
        renderTotalsRow={renderTotalsRow}
        noDataMsg={noDataMsg}
      />
    </InfiniteScroll>
  );
};
