export const YELP_DETAILS_INITIAL_VALUES = {
  inputBusinessUrl: null,
  business_id: null,
  viewPagesUrl: null
};

export const LINKEDIN_PAGES_INITIAL_VALUE = {
  isFetching: false,
  error: '',
  data: []
};

export const FACEBOOK_PAGES_INITIAL_VALUE = {
  isFetching: false,
  error: '',
  data: []
};
