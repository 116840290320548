import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import { IStore, commonModalPopup } from 'types';
import { ImageValidation, Video } from 'widgets/Media';
import { AP_ADD_TO_POST_TAB_NAMES, MediaDropPages } from 'utils/constants';
import { AddMediaDragAndDrop } from './AddMediaDragAndDrop';
import { Loading } from 'components';

export const AddVideoThumbnails = ({ isShowModalVideoThumbnail, selectedCloudinary }: commonModalPopup.IVideoThumbnails) => {
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const uploadProgressInfo = useSelector((state: IStore) => state.mediaGallery.uploadProgressInfo);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const videoThumbnail = useSelector((state: IStore) => state.mediaGallery.videoThumbnailRes);
  const isVideoThumbnailUploading = useSelector((state: IStore) => state.mediaGallery.isVideoThumbnailUploading);

  const selectedMediaThumbnail = selectedCloudinary?.cloudinaryId
    ? videoThumbnail.filter((thumbnail) => thumbnail?.videoCloudinaryId === selectedCloudinary?.cloudinaryId || !thumbnail?.cloudinaryId)
    : videoThumbnail.filter((thumb) => !thumb.videoCloudinaryId);

  return (
    <>
      {isShowModalVideoThumbnail && (
        <div className="avcWrap msi-avt">
          <h3 className="gm__title">Add Video Thumbnail</h3>
          <Card className="glbl__modal--card">
            <Card.Body>
              <div className="alc-item">
                <h6>
                  Thumbnail <span className="mandateField">*</span>
                </h6>
                <div className="masonry-grid">
                  {selectedMediaThumbnail?.length ? (
                    selectedMediaThumbnail.map((cloudResponse, index) => {
                      return !cloudResponse.cloudinaryId ? (
                        <div className="m-item mi-skeleton" key={`ap-cloudinary-without-data-${index}`}>
                          <div className="m-ast">
                            <div className="m-ast-itm m-ast-img">
                              <div className="mast-green">
                                <div className="mast-loader">
                                  <Loading />
                                </div>
                                <div className="mast-skltn-cnt mg-upload-progress-bar">
                                  <span className="msc-fileName">{cloudResponse.fileName}</span>
                                  <span className="msc-count-percntge-load">
                                    {uploadProgressInfo.percentage}% - {uploadProgressInfo.remainingTime} Sec. remaining
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="m-item" key={`ap-cloudinary-with-data-${index}`}>
                          <div className="m-ast">
                            <ImageValidation imgUrl={cloudResponse?.secureUrl} customName={'Media Asset'} />
                          </div>
                        </div>
                      );
                    })
                  ) : selectedMediaList?.[0]?.thumbnail_url ? (
                    <div className="alc-upload">
                      <div className="m-ast-itm m-ast-img">
                        <ImageValidation imgUrl={selectedMediaList[0]?.thumbnail_url} customName={'Media Asset'} />
                      </div>
                    </div>
                  ) : selectedCloudinary?.secureUrl ? (
                    <div className="m-item">
                      <div className="m-ast">
                        <div className="m-ast-itm m-ast-img">
                          <Video videoUrl={selectedCloudinary?.secureUrl} customClassname="m-ast-itm m-ast-img" />
                        </div>
                      </div>
                    </div>
                  ) : response?.length && response?.[0]?.secureUrl ? (
                    <div className="m-item">
                      <div className="m-ast">
                        <div className="m-ast-itm m-ast-img">
                          <Video videoUrl={response?.[0]?.secureUrl} customClassname="m-ast-itm m-ast-img" />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className={`media-dnd m-item ${isVideoThumbnailUploading ? 'pointer-events-none' : ''}`}>
                    <AddMediaDragAndDrop
                      mediaName={AP_ADD_TO_POST_TAB_NAMES.PHOTOS}
                      from={MediaDropPages.CUSTOMIZE_THUMBNAIL}
                      title={'Click here to Replace New Thumbnail'}
                      videoCloudinaryId={selectedCloudinary?.cloudinaryId}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};
