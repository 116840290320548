import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { EditorState, Modifier } from 'draft-js';
import { BaseEmoji } from 'emoji-mart';
import '@draft-js-plugins/hashtag/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import { Button } from 'react-bootstrap';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';

import { ImageValidation } from 'widgets/Media';
import { commonWidgetTypes } from 'types';
import Picker from 'widgets/ReactEmojiPicker';
import { createHashtagPlugin } from 'widgets/DraftjsHashtagsPlugin';
import { IAiIconBgColorType, AI_ICON_BG_IMG, AI_COACHMARK } from 'utils/constants';
import { AICoachMark } from 'components/Common/Modal/AICoachMark';
import { useSiteRestriction } from 'utils/hooks';

export const CustomTextEditor = ({
  emojiKey,
  onEditorClick,
  onEmojiClick,
  editorState,
  onEditorChange,
  keyBindingFn,
  isMentionEnabled = false,
  suggestionList,
  onAddSuggestionTag,
  onSuggestionSearchChange,
  className,
  spellCheck = true,
  disableEditor,
  disableEmoji,
  placeholder,
  handleBeforeInput,
  handlePastedText,
  renderExtra,
  isEmoji = true,
  isEmojiListOpen,
  isAddToYourPostEmoji,
  isShowAiButton = false,
  isAiButtonDisable = false,
  isSecondaryAiButton = true,
  handleAiClick,
  aiIconBgColorType,
  isCoachMark = true,
  coachMarkType = 'caption',
  coachMarkPosition = 'right',
  isShowAiText = true
}: commonWidgetTypes.ITextEditorProps) => {
  const { aiRestriction } = useSiteRestriction();

  const [suggestionOpen, setSuggestionOpen] = useState(isMentionEnabled);
  const [isEmojiOpen, setEmojiOpen] = useState(false);

  const editorRef = useRef<Editor>(null);

  // Update the emoji list state
  useEffect(() => {
    if (typeof isEmojiListOpen === 'boolean') {
      setEmojiOpen(isEmojiListOpen);
    }
  }, [isEmojiListOpen]);

  useEffect(() => {
    return () => {
      if (isEmojiOpen) setEmojiOpen(false);
    };
  }, []); // eslint-disable-line

  const onSuggestionOpenChange = useCallback(
    (open: boolean) => {
      if (isMentionEnabled) setSuggestionOpen(open);
    },
    [isMentionEnabled]
  ); // eslint-disable-line

  const { MentionSuggestions, hashtagPlugin, mentionPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const hashtagPlugin = createHashtagPlugin();

    return { mentionPlugin, hashtagPlugin, MentionSuggestions };
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      if (isMentionEnabled && onSuggestionSearchChange) onSuggestionSearchChange(value);
    },
    [isMentionEnabled] // eslint-disable-line
  );

  const onEditorFocus = () => {
    editorRef.current!.focus();
    if (onEditorClick) onEditorClick();
  };

  const CustomSuggestion = useCallback((props: EntryComponentProps) => {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;
    return (
      <div {...parentProps}>
        <div className="d-flex align-items-center">
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img src={mention.avatar} className={theme?.mentionSuggestionsEntryAvatar} role="presentation" alt={mention.name} />
          </div>
          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>{mention.label}</div>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <div className={`${className}${disableEditor ? ` pointer-events-none` : ''} editor flex-g`}>
        <div className="at-mention editor" onClick={onEditorFocus}>
          <Editor
            editorKey={`editor-${emojiKey}`}
            ref={editorRef}
            editorState={editorState}
            onChange={onEditorChange}
            keyBindingFn={keyBindingFn}
            plugins={[mentionPlugin, hashtagPlugin]}
            readOnly={disableEditor}
            placeholder={placeholder}
            spellCheck={spellCheck}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
          />
          <div className="ral-mSuggestions">
            <MentionSuggestions
              entryComponent={CustomSuggestion}
              open={suggestionOpen}
              onOpenChange={onSuggestionOpenChange}
              suggestions={suggestionList || []}
              onSearchChange={onSearchChange}
              onAddMention={onAddSuggestionTag}
            />
          </div>
        </div>
        <div className={`eai-wrap ${isShowAiButton ? 'ai-enabled' : 'd-ai'}`}>
          {isEmoji && (
            <div className="ap-emoji cp-emoji">
              {!isAddToYourPostEmoji && ( // RAS-4013 - For Emoji button changes in creator page
                <Button
                  className="roundedbtn ap-emoji-btn"
                  variant="link"
                  disabled={disableEmoji}
                  onClick={() => {
                    if (typeof onEmojiClick === 'function') onEmojiClick();
                    else setEmojiOpen(!isEmojiOpen);
                    onEditorFocus();
                  }}
                />
              )}
              {!isAddToYourPostEmoji && isEmojiOpen && (
                <Picker
                  previewPosition="none"
                  onEmojiSelect={(emo: BaseEmoji) => {
                    let contentState = editorState.getCurrentContent();
                    const currentSelection = editorState.getSelection();

                    contentState = contentState.createEntity('EMOJI', 'IMMUTABLE', `${emo.native}\u200A`);
                    const emoEntityKey = contentState.getLastCreatedEntityKey();

                    const newContentState = Modifier.insertText(contentState, currentSelection, `${emo.native}\u200A`, undefined, emoEntityKey);
                    let newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

                    newEditorState = EditorState.moveFocusToEnd(newEditorState);
                    newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());

                    onEditorChange(newEditorState);
                  }}
                />
              )}
            </div>
          )}
          {!isAddToYourPostEmoji && isShowAiButton && handleAiClick && !aiRestriction.button && (
            <AICoachMark coachMarkPosition={coachMarkPosition} coachMarkDetails={AI_COACHMARK.find((it) => it.value === coachMarkType)} isCoachMark={isCoachMark}>
              <div className={`ai-btn ai-creator-btn ai-anim-btn ${isAiButtonDisable ? 'ai-events-none' : ''} ${isCoachMark ? 'cm-enable' : ''}`} onClick={handleAiClick}>
                {isShowAiText && <span className="ai-icon-txt">AI Assistant</span>}
                <ImageValidation
                  isImgValid
                  defaultImg={
                    aiIconBgColorType === IAiIconBgColorType.GREY
                      ? AI_ICON_BG_IMG.GREY
                      : aiIconBgColorType === IAiIconBgColorType.LIGHT_BLUE
                      ? AI_ICON_BG_IMG.LIGHT_BLUE
                      : aiIconBgColorType === IAiIconBgColorType.DARK_BLUE
                      ? AI_ICON_BG_IMG.DARK_BLUE
                      : AI_ICON_BG_IMG.WHITE
                  }
                  isNotSvgFormat
                  customName="AI"
                  customClassname="aib"
                />
              </div>
            </AICoachMark>
          )}
        </div>
        {renderExtra ? renderExtra() : null}
      </div>
    </>
  );
};
