import { Button, Card } from 'react-bootstrap';

import { ModalPopup } from 'revv/components';
import { config } from 'config';

interface IConfirmationModalProps {
  isModalEnabled: boolean;
  classVariant?: string;
  setModalState: () => void;
}

export const ConfirmationModal = ({ isModalEnabled, classVariant, setModalState }: IConfirmationModalProps) => {
  return (
    <ModalPopup
      show={isModalEnabled}
      fullView
      centered
      className={`${classVariant || ''} npsp-modal__main confirm-modal__container`}
      onHide={setModalState}
      renderBody={() => (
        <Card className="confirm-modal__card">
          <img src={`${config.cdnRevvImgUrl}/confirmation.svg`} alt="confirm" />
          <section className="confirm-message">You Will Recieve An Email Once Your Request Has Been Processed</section>
          <Button className="dismiss-btn" onClick={setModalState}>
            Dismiss
          </Button>
        </Card>
      )}
    />
  );
};
