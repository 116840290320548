import { fbAdsApiTypes } from 'types';

/** Get interest tag dropdown label */
export const handleAddInterestTagSymbol = (tagOptions: fbAdsApiTypes.IAccTargetingInterestsObj[]) => {
  return tagOptions.map((listItem) => {
    return {
      ...listItem,
      name: listItem.path.reduce((acc: string, curr, index) => {
        if (index !== listItem.path.length - 1) acc = `${acc} ${curr} > `;
        else acc = `${acc}${curr}`;
        return acc;
      }, '')
    };
  });
};
