import { Reducer } from 'redux';

import * as actionTypes from 'actions/action-types';
import { ForgotPasswordActions } from 'actions';
import { forgotPasswordReducerType } from 'types/auth/forgot-password';
import { capitalizeName } from 'utils/helpers';
import { FGP_ERROR_MSG } from 'utils/constants';

const initialState: forgotPasswordReducerType.IForgotPassword = {
  isFetching: false,
  message: null,
  error: null
};

export const forgotPasswordReducer: Reducer<forgotPasswordReducerType.IForgotPassword, ForgotPasswordActions> = (
  state: forgotPasswordReducerType.IForgotPassword = initialState,
  action: ForgotPasswordActions
) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        error: null
      };
    case actionTypes.FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        isFetching: false,
        message: capitalizeName(action.payload),
        error: null
      };
    case actionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        error: action.payload ? capitalizeName(action.payload) : FGP_ERROR_MSG
      };

    case actionTypes.FORGOT_PASSWORD_ERROR_MSG_RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
