import { commonWidgetTypes } from 'types';

export const Video = (props: commonWidgetTypes.IVideoProps) => (
  <video
    onClick={props.onClick}
    className={`loader-td all-fade-in ${props.customClassname}`}
    autoPlay={props.autoPlay}
    muted={props.muted}
    loop={props.loop}
    preload={props?.light ? 'none' : 'auto'}
    poster={props?.light}
  >
    <source src={`${props.videoUrl}#t=1`} type="video/mp4" />
  </video>
);
