import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { IStore, commonModalPopup } from 'types';
import { setCorporatePostBoostData, getAverageLocationsSpendResponse, getAverageLocationsSpendFailure, calendarEditView } from 'actions';
import { NOT_APPLICABLE, CORPORATE_BOOST_POST_TYPE, TOOLTIP_PLACEMENT, ALERT_BOX_TYPES } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { AverageLocSpendTable, alertBoxCall } from 'components';

export const BrandBoostSection = ({ isEditEnabled, handleGetAverageLocSpendData }: commonModalPopup.IBrandBoostSectionProps) => {
  const dispatch = useDispatch();

  const averageLocationsSpend = useSelector((state: IStore) => state.fbAdsTargeting.averageLocationsSpend);
  const { isFetching, data, errorMsg, actionType } = averageLocationsSpend;
  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const corporatePostBoostObj = useSelector((state: IStore) => state.fbAdsTargeting.corporatePostBoostObj);
  const { isBoostEnabled, lifetimeSpend, duration, boostType, facebookDarkPost } = corporatePostBoostObj;

  const [openLocSpendTable, setOpenLocSpendTable] = useState(false);

  useEffect(() => {
    if (errorMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, errorMsg);
      dispatch(getAverageLocationsSpendFailure(null));
    }
  }, [errorMsg, dispatch]);

  useEffect(() => {
    if (data.length && !actionType) {
      setOpenLocSpendTable(true);
    }
  }, [data.length, actionType]);

  const dailySpendValue = lifetimeSpend && duration ? (lifetimeSpend / duration).toFixed(2) : NOT_APPLICABLE;

  const handleAddOrDeleteCorporateBoost = (isAddBoost: boolean) => {
    dispatch(
      setCorporatePostBoostData(
        isAddBoost
          ? { isBoostEnabled: isAddBoost, lifetimeSpend: 10, duration: 1, boostType: 'flat', facebookDarkPost: false }
          : { isBoostEnabled: isAddBoost, lifetimeSpend: 0, duration: 0, boostType: '', facebookDarkPost: false }
      )
    );
  };

  const handleUpdateCorporateBoost = (key: string, value: string | number | boolean) => {
    if (key === 'facebookDarkPost') {
      dispatch(
        calendarEditView({
          isEditEnabled: true,
          editId: 0,
          editData: {
            ...editData,
            facebook_dark_post: value
          }
        })
      );
    }
    dispatch(setCorporatePostBoostData({ ...corporatePostBoostObj, [key]: value }));
  };

  return (
    <>
      <div
        className={`add-boost-accordian-section corp-boost${isBoostEnabled ? ` cb-on` : ''}${isEditEnabled ? '' : ` pointer-events-none`}`}
        onClick={() => !isBoostEnabled && handleAddOrDeleteCorporateBoost(true)}
      >
        <Card className="add__boost--card">
          <Card.Header>
            <div className="addboost-header-sec">
              <div className="left-column">
                <div className="fb-icon">
                  <ImageValidation isImgValid defaultImg={'add-fb-icon'} customName={'fb-icon'} />
                </div>
                <span className="ab-heading">Add Boost</span>
              </div>
              {isBoostEnabled ? (
                <div className="right-column">
                  <div className="schedule__del--btn" onClick={() => handleAddOrDeleteCorporateBoost(false)}>
                    <ImageValidation isImgValid defaultImg={'delete-white'} customName={'delete'} />
                  </div>
                </div>
              ) : null}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="add-boost-inner-content">
              <div className="section-01">
                <div className="form-group form-field-item">
                  <span className="corp-dollar">$</span>
                  <input type="number" name="lifetimeSpend" value={lifetimeSpend || ''} onChange={(event) => handleUpdateCorporateBoost(event.target.name, Number(event.target.value))} />
                  <span className="fltlabels">Lifetime Spend</span>
                </div>
              </div>
              <div className="section-02">+</div>

              <div className="section-03">
                <div className="form-group form-field-item">
                  <input type="number" name="duration" value={duration || ''} onChange={(event) => handleUpdateCorporateBoost(event.target.name, Number(event.target.value))} />
                  <span className="fltlabels">Daily</span>
                </div>
              </div>

              <div className="section-04">=</div>
              <div className="section-05">
                <div className="daily-spend-text">Daily Spend</div>
                <div className="amount">
                  {dailySpendValue !== NOT_APPLICABLE && <span className="corp-dollar">$</span>}
                  <span className="corpdValue">{dailySpendValue}</span>
                </div>
              </div>
            </div>

            <div className="selection-section-wrp">
              {CORPORATE_BOOST_POST_TYPE.map((datum) => {
                return (
                  <div className="tip-section-wrp" key={`boost-type-${datum.id}`}>
                    <div className="form-check">
                      <label className="checkbox-item">
                        <input
                          type="radio"
                          name="boostType"
                          value={datum.value}
                          checked={boostType === datum.value}
                          onChange={(event) => handleUpdateCorporateBoost(event.target.name, event.target.value)}
                        />
                        <div className="label-txt">
                          {' '}
                          {datum.label}
                          <CustomTooltip
                            customPlacement={TOOLTIP_PLACEMENT.RIGHT}
                            customClassname="custom-tooltip-long-text cb-tooltip"
                            customTooltipText={datum.tooltip}
                            customInput={() => (
                              <div className="info__tool--tip">
                                <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                              </div>
                            )}
                          />
                        </div>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                );
              })}
              <div className="r-flx r-flx-ac wi-drkPost hdpWrap" onClick={() => handleUpdateCorporateBoost('facebookDarkPost', !facebookDarkPost)}>
                <label className={`checkbox-item${facebookDarkPost || editData?.facebook_dark_post ? ` active` : ``}`}>
                  <span className="checkbox-hover">
                    <input
                      type="checkbox"
                      name="facebookDarkPost"
                      className="option-input click-wave"
                      checked={facebookDarkPost || editData?.facebook_dark_post}
                      onChange={(event) => handleUpdateCorporateBoost(event.target.name, event.target.checked)}
                    />
                  </span>
                  <span className="checkmark" />
                </label>
                <span className="boost__box--title">Dark Post</span>
              </div>
              <div className={`avg-spend-loc${isFetching ? ` pointer-events-none` : ''}`} onClick={() => handleGetAverageLocSpendData()}>
                View Average spend locations
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <AverageLocSpendTable
        isModalShow={openLocSpendTable}
        handleModalClose={() => {
          dispatch(getAverageLocationsSpendResponse({ data: [], actionType: null }));
          setOpenLocSpendTable(false);
        }}
        data={data}
      />
    </>
  );
};
