import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { IStore } from 'types';
import { accountUpdateProfileRequest, settingsUpdateYelpDetails } from 'actions';
import { CONNECTION_TABS, RIPPLE_COLOR, TOOLTIP_PLACEMENT, USER_OWNERSHIP, YELP_BASE_URL, YELP_DETAILS_INITIAL_VALUES, YELP_PROFILE_REGEX, YELP_UPDATE_TOOLTIP_TEXT } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { handleLinkNavigation } from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';

export const SocialProfileYelp = () => {
  const dispatch = useDispatch();
  const { id, userOwnership, optionalParams } = useAccountSwitcherData();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const { yelpDetails } = useSelector((state: IStore) => state.socialProfile);

  const [showModal, setShowModal] = useState(false);

  const accountId = id && userOwnership === USER_OWNERSHIP.ACCOUNT ? id : accountDetails?.account?.id || 0;

  const onChangeYelpBusinessInput = (yelpInputUrl: string) => {
    const tempYelpDetails = {
      ...yelpDetails,
      inputBusinessUrl: yelpInputUrl.match(YELP_PROFILE_REGEX) ? yelpInputUrl : null,
      business_id: yelpInputUrl.match(YELP_PROFILE_REGEX) != null ? yelpInputUrl.split('/biz/')[1] : null
    };
    dispatch(settingsUpdateYelpDetails(tempYelpDetails));
  };

  const saveYelpOnConnectDisconnect = (businessId: string | null) => {
    if (accountDetails?.account) {
      const tempAccounts = { ...accountDetails.account, yelp_business_id: businessId };
      dispatch(accountUpdateProfileRequest({ id: accountId, profileData: tempAccounts, type: 'edit_location_social' }));
    }
  };

  const connectYelp = () => {
    const setURL = `${YELP_BASE_URL}${yelpDetails.business_id} ?sort_by=date_desc`;
    const tempYelpDetails = { ...yelpDetails };
    tempYelpDetails.viewPagesUrl = setURL;
    dispatch(settingsUpdateYelpDetails(tempYelpDetails));
    saveYelpOnConnectDisconnect(yelpDetails.business_id);
  };

  return (
    <>
      {optionalParams[2] === CONNECTION_TABS.SOCIAL_PROFILES || accountDetails?.account_profiles[0]?.access.platform_yelp_access ? (
        <div className="csp-item smd-yelp d-flex justify-content-between flex-wrap cspYelp">
          <aside className="d-flex align-items-center social-media__wrp">
            <div className="smn">
              <div className="sp-img">
                <ImageValidation isImgValid defaultImg="yelp-Iv" customName="social logo" />
              </div>
              <span className="sp-txt">Yelp</span>
            </div>
            <div className="sm-added-details">
              <span className="smadtxt">Business ID</span>
              <div className={`form-group smaInput ${accountDetails && accountDetails.account_profiles[0].yelp_profile_url ? 'min-w__unset' : ''}`}>
                {accountDetails && accountDetails.account_profiles[0].yelp_profile_url ? (
                  <label
                    className="bussiness-id__lbl text-dotted d-block"
                    title={accountDetails.account_profiles[0].yelp_profile_url.replace('https://www.yelp.com/biz/', '').replace('www.yelp.com/biz/', '')}
                  >
                    {accountDetails.account_profiles[0].yelp_profile_url.replace('https://www.yelp.com/biz/', '').replace('www.yelp.com/biz/', '')}
                  </label>
                ) : (
                  <input
                    type="text"
                    name="addedDetails"
                    placeholder="Enter Yelp Profile URL"
                    className={'form-control'}
                    autoComplete="off"
                    onChange={(e) => onChangeYelpBusinessInput(e.target.value)}
                  />
                )}
              </div>
            </div>
          </aside>
          {
            <div className="csp-action">
              {accountDetails && accountDetails?.account_profiles[0].yelp_profile_url ? (
                <>
                  {showModal ? (
                    <div className="disconnect-notification-wrp">
                      <div className="left-item">
                        <div>
                          <ImageValidation isImgValid defaultImg="connection-social-profile" customName="more" />
                        </div>
                        <span className="are-u-sure-text">Are you sure?</span>
                      </div>
                      <div className="right-item">
                        <div className="csp-action">
                          <CustomRippleButton rippleClass="ripple-unset ac-secondary-box clr" custColor={RIPPLE_COLOR.whiteGrey}>
                            <button className="ac-btn ac-secondary-white size-xs cl" onClick={() => setShowModal(false)}>
                              Cancel
                            </button>
                          </CustomRippleButton>
                          <CustomRippleButton rippleClass={`ripple-unset ac-danger-box`} custColor={RIPPLE_COLOR.danger}>
                            <button
                              className={`ac-btn ac-danger `}
                              onClick={() => {
                                setShowModal(false);
                                dispatch(settingsUpdateYelpDetails(YELP_DETAILS_INITIAL_VALUES));
                                saveYelpOnConnectDisconnect(null);
                              }}
                            >
                              Disconnect Now
                            </button>
                          </CustomRippleButton>
                        </div>
                      </div>
                    </div>
                  ) : accountDetails?.account?.synup_location_id && accountDetails?.account?.use_synup ? (
                    <CustomTooltip
                      customPlacement={TOOLTIP_PLACEMENT.TOP}
                      customTooltipText={YELP_UPDATE_TOOLTIP_TEXT}
                      customClassname={'custom-tooltip-long-text'}
                      customInput={() => (
                        <div className="csp-action g-15">
                          <Button
                            className="ac-btn ac-link-btn primeblueaction"
                            variant="link"
                            onClick={() => {
                              handleLinkNavigation(accountDetails.account_profiles[0].yelp_profile_url);
                            }}
                          >
                            View page
                          </Button>
                        </div>
                      )}
                    />
                  ) : (
                    <div className="csp-action g-15">
                      <Button
                        className="ac-btn ac-link-btn primeblueaction"
                        variant="link"
                        onClick={() => {
                          handleLinkNavigation(accountDetails.account_profiles[0].yelp_profile_url);
                        }}
                      >
                        View page
                      </Button>
                      <CustomRippleButton rippleClass="ripple-unset ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                        <button
                          className="ac-btn ac-secondary-grey"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Disconnect
                        </button>
                      </CustomRippleButton>
                    </div>
                  )}
                </>
              ) : (
                <div className="csp-action">
                  <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!yelpDetails.inputBusinessUrl ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                    <button className={`ac-btn ac-primary ${!yelpDetails.inputBusinessUrl ? 'disabled' : ''}`} onClick={() => connectYelp()}>
                      Connect Now
                    </button>
                  </CustomRippleButton>
                </div>
              )}
            </div>
          }
        </div>
      ) : null}
    </>
  );
};
