import { franchisorApiTypes, franchisorReducerTypes, menuAccessApiTypes, postsApiTypes } from 'types';
import { FRANCHISORS_SELECTIONS, LOCATION_LISTS } from 'utils/constants';

/** Get unique data */
export const getUniqueData = (locationData: postsApiTypes.IPostListDetailData[]) => {
  return Array.from(new Map(locationData.map((dataItem) => [dataItem.id, dataItem])).values());
};

export const getHubsOrLocListsLocationIds = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[]) => {
  return schLocationTree.reduce((acc: number[], curr) => {
    if (curr.type && curr.locations?.length) {
      acc = [...acc, ...(curr.locations || []).map((it) => it.id)];
    }
    return acc;
  }, []);
};

export const getCurrentHubOrLocListStats = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[]) => {
  return schLocationTree.reduce(
    (acc: franchisorReducerTypes.IFranScheduleTreeStats, curr) => {
      acc = {
        ...(curr.type === FRANCHISORS_SELECTIONS ? { hubs: acc.hubs + 1 } : { hubs: acc?.hubs || 0 }),
        ...(curr.type === LOCATION_LISTS ? { locationLists: acc.locationLists + 1 } : { locationLists: acc?.locationLists || 0 })
      };
      return acc;
    },
    { hubs: 0, locationLists: 0 }
  );
};

export const isHubOrLocListsUpdated = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[], hubOrLocStats: franchisorReducerTypes.IFranScheduleTreeStats) => {
  const locationTreeStats = getCurrentHubOrLocListStats(schLocationTree);
  return hubOrLocStats.hubs === locationTreeStats.hubs && hubOrLocStats.locationLists === locationTreeStats.locationLists;
};

export const getDirectLocsForHubsOrListsUpdatedData = (
  schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[],
  currentData: franchisorApiTypes.IDirectLocsFromHubOrListDataResp,
  hubOrLocStats: franchisorReducerTypes.IFranScheduleTreeStats
) => {
  const validLocationTree = schLocationTree;
  if (currentData?.hubOrListData?.id) {
    const index = validLocationTree.findIndex(({ name, id }) => id === currentData.hubOrListData?.id && name === currentData.hubOrListData?.name);
    if (index === -1) {
      validLocationTree.push(currentData.hubOrListData);
    } else {
      validLocationTree[index] = currentData.hubOrListData;
    }
  }

  if (currentData.includedAccounts?.length) {
    if (isHubOrLocListsUpdated(validLocationTree, hubOrLocStats)) {
      validLocationTree.push(
        ...currentData.includedAccounts.reduce((acc: franchisorApiTypes.IDirectLocsFromHubOrListData[], curr) => {
          if (!getHubsOrLocListsLocationIds(validLocationTree).includes(curr.id)) acc.push(curr);
          return acc;
        }, [])
      );
    }
  }
  return Array.from(new Map(validLocationTree.map((dataItem) => [dataItem.id && dataItem.name, dataItem])).values());
};

export const deleteItemInLocationTree = (schLocationTree: franchisorApiTypes.IDirectLocsFromHubOrListData[], deleteData: franchisorApiTypes.IDeleteItemInLocationTree) => {
  return schLocationTree.reduce((acc: franchisorApiTypes.IDirectLocsFromHubOrListData[], curr) => {
    if (curr?.type === deleteData?.type && curr.id !== deleteData.deleteId) acc = acc.concat(curr);
    else if (curr.id !== deleteData.deleteId) acc = acc.concat(curr);
    return acc;
  }, []);
};

export const updateFranchisorSyndicateWithLabel = (data: { id: number; name: string }[]) => {
  if (data) {
    const promotionVaultList = data.reduce((acc: menuAccessApiTypes.IFranchisorSyndication[], curr) => {
      const vauleObj = {
        value: curr.name,
        label: curr.name,
        id: curr.id,
        name: curr.name
      };
      acc = [...acc, vauleObj];
      return acc;
    }, []);
    return promotionVaultList;
  } else return [];
};
