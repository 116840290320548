import { useDispatch, useSelector } from 'react-redux';

import { CommonFilter } from 'components';
import { activeFilterOptions } from 'revv/actions';
import { BUTTON_PROPS } from 'utils/constants';
import { IStore } from 'types';

export const TeamsFilter = () => {
  const isSendSurveyFetching = useSelector((state: IStore) => state.revv.surveys.sendSurveyMsg.isFetching);

  const dispatch = useDispatch();

  return (
    <CommonFilter
      sectionVariant="revv-team__filter"
      filterActionButtons={[
        {
          ...BUTTON_PROPS.PRIMARY_BUTTON,
          label: 'Send Survey',
          parentVariant: 'filter-item connection-report border-0',
          value: 'sendsurvey',
          onClick: () => {
            dispatch(activeFilterOptions('sendsurvey'));
          },
          isFetching: isSendSurveyFetching
        },
        {
          ...BUTTON_PROPS.PRIMARY_BUTTON,
          label: 'New Teammate',
          parentVariant: 'connection-report border-0',
          value: 'newteammate',
          onClick: () => {
            dispatch(activeFilterOptions('newteammate'));
          }
        },
        {
          ...BUTTON_PROPS.SECONDARY_BUTTON,
          label: 'Upload',
          customImage: 'upload-white.svg',
          parentVariant: 'connection-report border-0',
          buttonVariant: 'ac-btn ac-icon-btn ac-secondary ac-block csv',
          onClick: () => {
            dispatch(activeFilterOptions('teamUpload'));
          }
        }
      ]}
      filter={{}}
      handleChange={() => false}
      isDateRangeFilterEnable={false}
    />
  );
};
