import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateCalendarItemRequest } from 'actions';
import { IStore, calendarApiTypes, calendarContainerTypes } from 'types';
import { getFormatBasedOnExactDate } from 'utils/helpers';
import { CALENDAR_EVENT_CHECK_BOX, CALENDAR_EVENT_DATE_FORMAT, CALENDAR_FILTER_INITIAL_VALUE, LOCATION_TIME_ZONE_DATE_TIME_FORMAT } from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const CalendarViewEventFilter = ({ calendarEventData }: { calendarEventData: calendarContainerTypes.ICalendarDataType | calendarApiTypes.ICalendarItemsResponse | null | undefined }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userOwnership, id, optionalParams, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor({ ...CALENDAR_FILTER_INITIAL_VALUE });

  const accountName = useSelector((state: IStore) => state.accountSwitcher.currentName);

  const calenderEventUpdateHandler = (dataObj: calendarApiTypes.ICalendarItemsResponse | any, type: string) => {
    const payload = { ...dataObj, [`${type}`]: !dataObj[type] };
    dispatch(updateCalendarItemRequest(payload));
  };
  return (
    <div className="filter-item cal-filter-first-section">
      <div className="filter-item nf-events">
        <div className="cal-social-preview-sec">
          <div className="card-bg">
            <div className="overlay">
              <div className="p-avatar">
                <ImageValidation
                  defaultImg="expand"
                  customName="expand"
                  onClick={() => {
                    navigate({
                      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}${optionalParams[0] ? `/${optionalParams[0]}` : ''}/${calendarEventData?.id?.toString()}`,
                      search: queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ``
                    });
                  }}
                />
              </div>
            </div>
            <div className="p-header">
              <div className="p-header-title">
                <div className="p-avatar">
                  <ImageValidation
                    imgUrl={calendarEventData?.modified_by_user?.avatar_url}
                    defaultImg="user-avatar-common.png"
                    isNotSvgFormat
                    customClassname="p-avatar"
                    customName="Calendar profile user image"
                  />
                </div>
                <div className="p-title">
                  <h5>{accountName}</h5>
                  <h6>{`@${getFormatBasedOnExactDate(calendarEventData?.start_datetime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT)}`}</h6>
                </div>
              </div>
            </div>

            <div className="p-body">
              <div className="calendar-section-main-wrp">
                <h5 className="desc-title">{calendarEventData?.title || ''}</h5>
                <p className="desc-text">{calendarEventData?.description || ''}</p>
                <div className="event-section-main-wrp">
                  <div className="left-section">
                    <div className="calendr-notify-section">
                      <div className="first-itm">
                        <div className="nf-head">Frequency</div>
                        <div className="nf-head">Start Time</div>
                        <div className="nf-head">End Time</div>
                        <div className="nf-head">Location</div>
                      </div>
                      <div className="last-itm">
                        <div className="last-item-text">{calendarEventData?.recurrence_type}</div>
                        <div className="last-item-text">{getFormatBasedOnExactDate(calendarEventData?.start_datetime, CALENDAR_EVENT_DATE_FORMAT)}</div>
                        <div className="last-item-text">{getFormatBasedOnExactDate(calendarEventData?.end_datetime, CALENDAR_EVENT_DATE_FORMAT)}</div>
                        <div className="last-item-text">{calendarEventData?.address}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-item calendar-flr-btn-wrp internal-notes-btn-wrp">
        {CALENDAR_EVENT_CHECK_BOX.map((it, index) => {
          return (
            <div key={`calendar-selected-event-${index}`} className="btn cancel-btn">
              <div className="form-check">
                <label className="checkbox-item">
                  <input type="checkbox" name={it.response} checked={calendarEventData?.[it.response]} onChange={(event) => calenderEventUpdateHandler(calendarEventData, event.target.name)} />
                  <span className="labelTxt">{it.label}</span>
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
