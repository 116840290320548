import { CustomDropdown } from 'widgets/CustomDropdown';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { aiImageGenertaionContainerTypes } from 'types';
import { TextArea } from 'widgets/Text';
import { AI_IMAGE_MODIFY_CREATIVITY_LEVEL, TOOLTIP_PLACEMENT, AI_IMAGE_MODIFY_DETAIL_LEVEL } from 'utils/constants';

export const AIFilter = ({ isAIFilterEnabled, values, setFieldValue, handleBlur }: aiImageGenertaionContainerTypes.IAIFilterProps) => {
  return (
    <div className={`aiImageFilterSec ${!isAIFilterEnabled ? 'd-none' : ''}`}>
      <div className="aif-item">
        <h3>
          <span>Modify level of details</span>
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={'High values will result in an image with gritty details, while low values will produce a smooth, airbrushed look'}
            customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
            customInput={() => (
              <div className="infoIcon-divWrap">
                <ImageValidation isImgValid defaultImg="info-blue" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
              </div>
            )}
          />
        </h3>
        <div className="iar-wrap">
          <CustomDropdown
            name="add_detail"
            classNamePrefix="iar"
            id="ai-image-modify-level"
            options={AI_IMAGE_MODIFY_DETAIL_LEVEL}
            value={AI_IMAGE_MODIFY_DETAIL_LEVEL.find((detail) => detail.value === values.add_detail)}
            onChange={(eventObj: any) => setFieldValue('add_detail', eventObj.value)}
          />
        </div>
      </div>
      <div className="aif-item">
        <h3>
          <span>Modify AI Creativity</span>
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={
              'Low Creativity will force the AI Assistant to adhere to your prompt as much as it can at the expense of producing unrealistic elements, while high Creativity will allow the AI Assistant to treat your prompt as more of a suggestion that it may ignore in order to produce a more coherent image.'
            }
            customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
            customInput={() => (
              <div className="infoIcon-divWrap">
                <ImageValidation isImgValid defaultImg="info-blue" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
              </div>
            )}
          />
        </h3>

        <div className="iar-wrap">
          <CustomDropdown
            name="cfg_scale"
            classNamePrefix="iar"
            id="ai-image-modify-level"
            placeholder={'Select a day'}
            options={AI_IMAGE_MODIFY_CREATIVITY_LEVEL}
            value={AI_IMAGE_MODIFY_CREATIVITY_LEVEL.find((detail) => detail.value === values.cfg_scale)}
            onChange={(eventObj: any) => setFieldValue('cfg_scale', eventObj.value)}
          />
        </div>
      </div>
      <div className="aif-item">
        <h3>
          <span>Name an item you want to remove from the image</span>
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={
              'If you are seeing an unwanted object or composition in your image, you can specify it here to try it in the output. For example, if an image is generated of person holding an umbrella, which you don’t want in the image, you can enter ‘umbrella’ here.'
            }
            customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
            customInput={() => (
              <div className="infoIcon-divWrap">
                <ImageValidation isImgValid defaultImg="info-blue" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
              </div>
            )}
          />
        </h3>
        <div className="txtarea-icon">
          <TextArea
            name={'negative_prompt'}
            className="form-control"
            placeholder="Type here"
            onBlur={handleBlur}
            value={values.negative_prompt}
            onChange={(e) => setFieldValue('negative_prompt', e.target.value)}
            maxRows={3}
          />
        </div>
      </div>
      <div className="aif-item">
        <div className="aif-checkbox-item">
          <div className="aifc-left">
            <input type="checkbox" checked={values?.detailer_faces} name="detailer_faces" onChange={() => setFieldValue('detailer_faces', !values.detailer_faces)} />
            <span>Fix deformed faces?</span>
          </div>
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={
              'If a person in your image has a face with deformed features, select this option to attempt fixing it automatically. Only select this option when needed, as it may produce unwanted image artifacts in some cases.'
            }
            customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
            customInput={() => (
              <div className="infoIcon-divWrap">
                <ImageValidation isImgValid defaultImg="info-blue" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
              </div>
            )}
          />
        </div>
        <div className="aif-checkbox-item">
          <div className="aifc-left">
            <input type="checkbox" name="detailer_hands" checked={values?.detailer_hands} onChange={() => setFieldValue('detailer_hands', !values.detailer_hands)} />
            <span>Fix deformed hands?</span>
          </div>
          <CustomTooltip
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={
              'If a person in your image has a hand with deformed features, select this option to attempt fixing it automatically. Only select this option when needed, as it may produce unwanted image artifacts in some cases.'
            }
            customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
            customInput={() => (
              <div className="infoIcon-divWrap">
                <ImageValidation isImgValid defaultImg="info-blue" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
