import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { rssFeedsReducerTypes } from 'types';
import { RssFeedsAction } from 'actions';

type IRssFeedsReducer = rssFeedsReducerTypes.IRssFeedsReducer;

const initialState: IRssFeedsReducer = {
  isFetching: true,
  newsRssFeedUrls: []
};

export const rssFeedsReducer: Reducer<IRssFeedsReducer, RssFeedsAction> = (state: IRssFeedsReducer = initialState, action: RssFeedsAction): IRssFeedsReducer => {
  switch (action.type) {
    // RSS_FEEDS LIST REQUEST
    case types.RSS_FEEDS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.RSS_FEEDS_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        newsRssFeedUrls: action.payload
      };
    case types.RSS_FEEDS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    // RSS FEEDS RESET ALL
    case types.RSS_FEEDS_RESET_ALL:
      return { ...initialState };
    default:
      return state;
  }
};
