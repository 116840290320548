export const ON_CHANGE_DATE_RANGE = 'filter/ON_CHANGE_DATE_RANGE';
export const ENABLE_OR_DISABLE_FILTER = 'filter/ENABLE_OR_DISABLE_FILTER';

// hubs list
export const LOAD_HUBS_LIST_REQUEST = 'filter/LOAD_HUBS_LIST_REQUEST';
export const LOAD_HUBS_LIST_FAILURE = 'filter/LOAD_HUBS_LIST_FAILURE';
export const LOAD_HUBS_LIST_RESPONSE = 'filter/LOAD_HUBS_LIST_RESPONSE';

// hub types
export const LOAD_HUB_TYPE_REQUEST = 'filter/LOAD_HUB_TYPE_REQUEST';
export const LOAD_HUB_TYPE_FAILURE = 'filter/LOAD_HUB_TYPE_FAILURE';
export const LOAD_HUB_TYPE_RESPONSE = 'filter/LOAD_HUB_TYPE_RESPONSE';

// hub locations
export const LOAD_HUB_LOCATION_REQUEST = 'filter/LOAD_HUB_LOCATION_REQUEST';
export const LOAD_HUB_LOCATION_FAILURE = 'filter/LOAD_HUB_LOCATION_FAILURE';
export const LOAD_HUB_LOCATION_RESPONSE = 'filter/LOAD_HUB_LOCATION_RESPONSE';

// location selection
export const ON_CHANGE_LOCATION = 'filter/ON_CHANGE_LOCATION';

export const ACTIVE_FILTER_OPTIONS = 'filter/ACTIVE_FILTER_OPTIONS';

export const UPDATE_HUB_LOCATION_FLAG = 'filter/UPDATE_HUB_LOCATION_FLAG';
