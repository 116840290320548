import { Loading } from 'components';
import { OutsideClickDetector } from './OutsideClickDetector';
import { CustomTooltip } from 'widgets/Tooltip';
import { TOOLTIP_PLACEMENT } from 'utils/constants';

export const CustomDropdownMenu = ({
  targetRef,
  showMenu,
  setActive,
  options,
  isLoading,
  onSelect,
  showTooltip,
  renderTooltipJSX,
  placeholder,
  selectedOptionsVariant = '',
  isActive = false
}: any) => {
  return (
    <div className="custom-menu-container">
      <OutsideClickDetector
        handler={() => setActive(false)}
        excludeTargets={Array.isArray(targetRef.current) ? targetRef.current : [targetRef.current]}
        renderWrapper={(wrapperRef: any, children: any) => (
          <nav className={`custom-dropdown-menu ${showMenu ? 'active' : 'inactive'}`} ref={wrapperRef}>
            {children}
          </nav>
        )}
        active={showMenu}
      >
        <ul>
          {isLoading ? (
            <Loading />
          ) : options?.length === 0 && placeholder ? (
            <li>{placeholder}</li>
          ) : (
            options.map((it: any) =>
              showTooltip ? (
                <CustomTooltip
                  key={it.name || it.label}
                  customPlacement={TOOLTIP_PLACEMENT.LEFT}
                  customClassname={'man-tooltip-inactive ma-image-tooltip-assets'}
                  customTooltipText={renderTooltipJSX(it.value)}
                  customInput={() => (
                    <li className={`${isActive && selectedOptionsVariant === it.value ? 'active' : ''}`} key={it.value} onClick={() => onSelect(it.value)}>
                      {it.name || it.label}
                    </li>
                  )}
                />
              ) : (
                <li className={`${isActive && selectedOptionsVariant === it.value ? 'active' : ''}`} key={it.value} onClick={() => onSelect(it.value)}>
                  {it.name || it.label}
                </li>
              )
            )
          )}
        </ul>
      </OutsideClickDetector>
    </div>
  );
};
