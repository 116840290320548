import { commonWidgetTypes } from 'types';
import { TOOLTIP_PLACEMENT, BACK_TO_TOP_TOOLTIP } from 'utils/constants';
import { scrollToTop } from 'utils/helpers';
import { CustomTooltip } from 'widgets/Tooltip';

export const BackToTopButton = ({ scrollElementId, buttonId, isEnabled = true, tooltipVariant = '' }: commonWidgetTypes.IBackToTopButtonProps) => {
  return isEnabled ? (
    <CustomTooltip
      customPlacement={TOOLTIP_PLACEMENT.TOP}
      customClassname={`custom-tooltip-long-text ${tooltipVariant}`}
      customTooltipText={BACK_TO_TOP_TOOLTIP}
      customInput={() => <div id={buttonId} onClick={() => scrollToTop(scrollElementId)} />}
    />
  ) : null;
};
