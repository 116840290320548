// MEDIA GALLERY STATS
export const MG_STATS_RESPONSE = 'media_gallery/MG_STATS_RESPONSE';
export const MG_STATS_FAILURE = 'media_gallery/MG_STATS_FAILURE';

// MEDIA GALLERY FILTER STATS
export const MG_FILTER_STATS_REQUEST = 'media_gallery/MG_FILTER_STATS_REQUEST';
export const MG_FILTER_STATS_RESPONSE = 'media_gallery/MG_FILTER_STATS_RESPONSE';
export const MG_FILTER_STATS_FAILURE = 'media_gallery/MG_FILTER_STATS_FAILURE';

// MEDIA GALLERY LIST
export const MG_LIST_REQUEST = 'media_gallery/MG_LIST_REQUEST';
export const MG_LIST_RESPONSE = 'media_gallery/MG_LIST_RESPONSE';
export const MG_LIST_FAILURE = 'media_gallery/MG_LIST_FAILURE';

// MEDIA GALLERY - UPLOAD CLOUDINARY MEDIA
export const MG_CLOUD_MEDIA_UPLOAD_REQUEST = 'media_gallery/MG_CLOUD_MEDIA_UPLOAD_REQUEST';
export const MG_CLOUD_MEDIA_UPLOAD_RESPONSE = 'media_gallery/MG_CLOUD_MEDIA_UPLOAD_RESPONSE';
export const MG_CLOUD_MEDIA_UPLOAD_FAILURE = 'media_gallery/MG_CLOUD_MEDIA_UPLOAD_FAILURE';

export const MG_VALID_CLOUD_MEDIA_UPLOAD_LIST = 'media_gallery/MG_VALID_CLOUD_MEDIA_UPLOAD_LIST';

// MEDIA GALLERY - ADD MEDIA ASSET
export const MG_ADD_MEDIA_ASSET_REQUEST = 'media_gallery/MG_ADD_MEDIA_ASSET_REQUEST';
export const MG_ADD_MEDIA_ASSET_RESPONSE = 'media_gallery/MG_ADD_MEDIA_ASSET_RESPONSE';
export const MG_ADD_MEDIA_ASSET_FAILURE = 'media_gallery/MG_ADD_MEDIA_ASSET_FAILURE';

// MEDIA GALLERY - DELETE ASSET
export const MG_DELETE_ASSET_REQUEST = 'media_gallery/MG_DELETE_ASSET_REQUEST';
export const MG_DELETE_ASSET_RESPONSE = 'media_gallery/MG_DELETE_ASSET_RESPONSE';
export const MG_DELETE_ASSET_FAILURE = 'media_gallery/MG_DELETE_ASSET_FAILURE';

// MEDIA GALLERY - GET ADDED ASSETS COUNT
export const MG_GET_ADDED_ASSETS_COUNT = 'media_gallery/MG_GET_ADDED_ASSETS_COUNT';

// MEDIA GALLERY - GET CLOUD RESPONSE LIST
export const MG_GET_CLOUD_RESPONSE_LIST = 'media_gallery/MG_GET_CLOUD_RESPONSE_LIST';

// MEDIA GALLERY - GET TAGS BASED ON SEARCH
export const MG_TAGS_REQUEST = 'media_gallery/MG_TAGS_REQUEST';
export const MG_TAGS_RESPONSE = 'media_gallery/MG_TAGS_RESPONSE';
export const MG_TAGS_FAILURE = 'media_gallery/MG_TAGS_FAILURE';

// MEDIA GALLERY - GET ALL FAV TAGS FROM DB
export const MG_GET_FAV_TAGS_REQUEST = 'media_gallery/MG_GET_FAV_TAGS_REQUEST';
export const MG_GET_FAV_TAGS_RESPONSE = 'media_gallery/MG_GET_FAV_TAGS_RESPONSE';
export const MG_GET_FAV_TAGS_FAILURE = 'media_gallery/MG_GET_FAV_TAGS_FAILURE';

// MEDIA GALLERY - RESET EDITED FAV TAGS TO DB
export const MG_UPDATE_FAV_TAGS_REQUEST = 'media_gallery/MG_UPDATE_FAV_TAGS_REQUEST';
export const MG_UPDATE_FAV_TAGS_RESPONSE = 'media_gallery/MG_UPDATE_FAV_TAGS_RESPONSE';
export const MG_UPDATE_FAV_TAGS_FAILURE = 'media_gallery/MG_UPDATE_FAV_TAGS_FAILURE';

// MEDIA GALLERY - UPDATE TAG LISTS TO REDUCER STATE
export const MG_SET_TAGS_LIST = 'media_gallery/MG_SET_TAGS_LIST';

// MEDIA GALLERY MEDIA_RELEASE
export const MG_MEDIA_RELEASE_REQUEST = 'media_gallery/MG_MEDIA_RELEASE_REQUEST';
export const MG_MEDIA_RELEASE_RESPONSE = 'media_gallery/MG_MEDIA_RELEASE_RESPONSE';
export const MG_MEDIA_RELEASE_FAILURE = 'media_gallery/MG_MEDIA_RELEASE_FAILURE';

// MEDIA GALLERY LIKE
export const MG_LIKE_REQUEST = 'media_gallery/MG_LIKE_REQUEST';
export const MG_LIKE_RESPONSE = 'media_gallery/MG_LIKE_RESPONSE';
export const MG_LIKE_FAILURE = 'media_gallery/MG_LIKE_FAILURE';

// MEDIA GALLERY DISLIKE
export const MG_DISLIKE_REQUEST = 'media_gallery/MG_DISLIKE_REQUEST';
export const MG_DISLIKE_RESPONSE = 'media_gallery/MG_DISLIKE_RESPONSE';
export const MG_DISLIKE_FAILURE = 'media_gallery/MG_DISLIKE_FAILURE';

// MEDIA GALLERY GET DETAIL DATA
export const MG_DETAIL_DATA_REQUEST = 'media_gallery/MG_DETAIL_DATA_REQUEST';
export const MG_DETAIL_DATA_RESPONSE = 'media_gallery/MG_DETAIL_DATA_RESPONSE';
export const MG_DETAIL_DATA_FAILURE = 'media_gallery/MG_DETAIL_DATA_FAILURE';

// MEDIA GALLERY UPDATE DETAIL DATA
export const MG_UPDATE_DETAIL_DATA_REQUEST = 'media_gallery/MG_UPDATE_DETAIL_DATA_REQUEST';
export const MG_UPDATE_DETAIL_DATA_RESPONSE = 'media_gallery/MG_UPDATE_DETAIL_DATA_RESPONSE';
export const MG_UPDATE_DETAIL_DATA_FAILURE = 'media_gallery/MG_UPDATE_DETAIL_DATA_FAILURE';

// MEDIA GALLERY CLONE ASSET
export const MG_CLONE_ASSET_REQUEST = 'media_gallery/MG_CLONE_ASSET_REQUEST';
export const MG_CLONE_ASSET_RESPONSE = 'media_gallery/MG_CLONE_ASSET_RESPONSE';
export const MG_CLONE_ASSET_FAILURE = 'media_gallery/MG_CLONE_ASSET_FAILURE';

// MEDIA GALLERY - SET ASSET UPLOADED ID
export const SET_EDIT_MG_DETAILS = 'media_gallery/SET_EDIT_MG_DETAILS';

// MEDIA GALLERY RESET
export const MG_ADDED_ASSET_DETAIL_RESET = 'media_gallery/MG_ADDED_ASSET_DETAIL_RESET';
export const MG_DELETE_ASSET_DATA_RESET = 'media_gallery/MG_DELETE_ASSET_DATA_RESET';
export const MG_UPDATE_ASSET_DATA_RESET = 'media_gallery/MG_DELETE_UPDATE_DATA_RESET';
export const MG_DETAIL_VIEW_RESET = 'media_gallery/MG_DETAIL_VIEW_RESET';
export const MG_RESET_ALL = 'media_gallery/MG_RESET_ALL';

// MEDIA GALLERY - UPLOAD PROGRESS INFO
export const MG_SET_UPLOAD_PROGRESS_INFO = 'media_gallery/MG_SET_UPLOAD_PROGRESS_INFO';

export const MEDIA_DETAIL_VIEW_ERROR = 'media_gallery/MEDIA_DETAIL_VIEW_ERROR';

export const MG_AI_IMAGE_UPLOADING_REQUEST = 'media_gallery/MG_AI_IMAGE_UPLOADING_REQUEST';

// WATERMARK POPUP OPTIONS.
export const SHOW_HIDE_WATERMARK_MODAL = 'media_gallery/SHOW_HIDE_WATERMARK_MODAL';
export const UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST = 'media_gallery/UPLOAD_WATERMARK_IMAGE_CLOUDINARY_REQUEST';
export const CANCEL_UPLOAD = 'media_gallery/CANCEL_UPLOAD';
export const GET_WATERMARK_IMAGE = 'media_gallery/GET_WATERMARK_IMAGE';
export const GET_WATERMARK_IMAGE_RESPONSE = 'media_gallery/GET_WATERMARK_IMAGE_RESPONSE';
export const UPLOAD_WATERMARK_IMAGE_CLOUDINARY_RESPONSE = 'media_gallery/UPLOAD_WATERMARK_IMAGE_CLOUDINARY_RESPONSE';
export const SAVE_WATERMARK_IMAGE_REQUEST = 'media_gallery/SAVE_WATERMARK_IMAGE_REQUEST';
export const SAVE_WATERMARK_IMAGE_RESPONSE = 'media_gallery/SAVE_WATERMARK_IMAGE_RESPONSE';

export const MG_S3_CLOUD_MEDIA_UPLOAD_REQUEST = 'media_gallery/MG_S3_CLOUD_MEDIA_UPLOAD_REQUEST';

// MEDIA GALLERY - CUSTOMIZE VIDEO THUMBNAIL URL
export const MG_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST = 'media_gallery/MG_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST';
export const MG_ADD_CUSTOM_VIDEO_THUMBNAIL_RESPONSE = 'media_gallery/MG_ADD_CUSTOM_VIDEO_THUMBNAIL_RESPONSE';
export const MG_RESET_CUSTOM_VIDEO_THUMBNAIL_RESPONSE = 'media_gallery/MG_RESET_CUSTOM_VIDEO_THUMBNAIL_RESPONSE';
export const MG_REMOVE_CUSTOM_VIDEO_THUMBNAIL_RESPONSE = 'media_gallery/MG_REMOVE_CUSTOM_VIDEO_THUMBNAIL_RESPONSE';

// MEDIA GALLERY - ADD MEDIA RELEASE
export const MG_ADD_MEDIA_RELEASE_REQUEST = 'media_gallery/MG_ADD_MEDIA_RELEASE_REQUEST';
export const MG_ADD_MEDIA_RELEASE_RESPONSE = 'media_gallery/MG_ADD_MEDIA_RELEASE_RESPONSE';
export const MG_ADD_MEDIA_RELEASE_FAILURE = 'media_gallery/MG_ADD_MEDIA_RELEASE_REQUEST';

// MEDIA GALLERY - DELETE MEDIA RELEASE
export const MG_DELETE_MEDIA_RELEASE_REQUEST = 'media_gallery/MG_DELETE_MEDIA_RELEASE_REQUEST';

// MEDIA GALLERY - RESET MEDIA RELEASE
export const MG_RESET_MEDIA_RELEASE = 'media_gallery/MG_RESET_MEDIA_RELEASE';

// MEDIA GALLERY - UPDATE CLOUD MEDIA RESPONSE
export const MG_UPDATE_CLOUD_MEDIA_RESPONSE = 'media_gallery/MG_UPDATE_CLOUD_MEDIA_RESPONSE';

// MEDIA GALLERY - UPDATE MEDIA GALLERY SETTINGS
export const MG_UPDATE_MEDIA_EXPIRATION = 'media_gallery/MG_UPDATE_MEDIA_EXPIRATION';
export const MG_RESET_MEDIA_EXPIRATION = 'media_gallery/MG_RESET_MEDIA_EXPIRATION';
export const MEDIA_GALLERY_UPDATE_SETTINGS = 'media_gallery/MEDIA_GALLERY_UPDATE_SETTINGS';
export const MEDIA_GALLERY_RESET_SETTINGS = 'media_gallery/MEDIA_GALLERY_RESET_SETTINGS';
