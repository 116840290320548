import * as actionTypes from 'revv/actions/action-types';
import { IClientDetailedViewResponse, IClientRequestParams, IClientsResponse } from 'revv/types';
import { ActionsUnion, createAction } from 'revv/utils';

export const clientsDetailedViewRequest = (payload: { id: string; isLogin?: boolean }) => createAction(actionTypes.CLIENTS_DETAILED_VIEW_REQUEST, payload);
export const clientsDetailedViewFailure = (payload: string) => createAction(actionTypes.CLIENTS_DETAILED_VIEW_FAILURE, payload);
export const clientsDetailedViewResponse = (payload: IClientDetailedViewResponse | null) => createAction(actionTypes.CLIENTS_DETAILED_VIEW_RESPONSE, payload);

export const clientsRequest = (payload: IClientRequestParams) => createAction(actionTypes.CLIENTS_REQUEST, payload);
export const clientsFailure = (payload: string) => createAction(actionTypes.CLIENTS_FAILURE, payload);
export const clientsResponse = (payload: IClientsResponse[]) => createAction(actionTypes.CLIENTS_RESPONSE, payload);

export const CLIENTS_ACTIONS = {
  clientsDetailedViewRequest,
  clientsDetailedViewFailure,
  clientsDetailedViewResponse,
  clientsRequest,
  clientsFailure,
  clientsResponse
};

export type ClientsAction = ActionsUnion<typeof CLIENTS_ACTIONS>;
