import { useCallback, useEffect } from 'react';
import { Alert, ButtonGroup, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { IStore, rpApiTypes } from 'types';
import {
  CP_DETAILS_PAGE_FIELDS,
  RewardType,
  Duration,
  RecommendedType,
  ICommonDateTypes,
  RP_DATEPICKER_DATE_TIME_FORMAT,
  RP_ACTIONS_TYPE,
  Stage,
  USER_OWNERSHIP,
  RP_REPLACE_SPECIAL_CHAR,
  ALERT_BOX_TYPES,
  RP_DETAIL_BUDGET_VALIDATE_FAIL_MSG,
  VALID_ONLY_NUMBERS_REGEX,
  COMMA_SPLITTER,
  COMMON_PAGE_TYPE
} from 'utils/constants';
import {
  cpDetailsDataValidataion,
  getDatePickerDateToLocal,
  compareCurrentDate,
  getEndOfDate,
  getCurrentDate,
  getStartOfDate,
  formatEditDate,
  checkValidDate,
  getFormattedNumber,
  validateBudget
} from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { rpSetCreateProgramDataObj, rpRewardsModified } from 'actions';
import { CustomDatePicker } from 'widgets/DatePicker';
import { alertBoxCall } from 'components';

interface IProgramDetailsModalProps {
  modalType: string;
}

export const ProgramDetails = ({ modalType }: IProgramDetailsModalProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { userOwnership } = useAccountSwitcherData();

  // const userOwnership = useSelector((state: IStore) => state.accountSwitcher.userOwnership);
  const { createProgramDataObj, currentAction, isRewardsModified, promotionVaultList } = useSelector((state: IStore) => state.rewardPrograms);
  const { programDetails, modifiedStatus, programAction } = createProgramDataObj;

  const previousprogramBudget = programDetails.previousprogramBudget ? programDetails.previousprogramBudget.replace(RP_REPLACE_SPECIAL_CHAR.SPECIAL_CHAR, '') : '';

  const { values, handleBlur, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      ...programDetails,
      programBudget: programDetails.programBudget ? `${getFormattedNumber(Number(programDetails.programBudget)) || ''}` : ''
    },
    validationSchema: cpDetailsDataValidataion,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (val: rpApiTypes.IRPProgramDetailData) => {
      if (val) return;
    }
  });

  const budgetValue = values?.programBudget?.replaceAll(COMMA_SPLITTER, '');

  useEffect(() => {
    if (currentAction === RP_ACTIONS_TYPE.EDIT && budgetValue && parseFloat(previousprogramBudget || `${0}`) > parseFloat(budgetValue || `${0}`)) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, RP_DETAIL_BUDGET_VALIDATE_FAIL_MSG);
    }
    if (values) {
      const detailsModifiedStatus = getProgramDetailsValidation(values);
      dispatch(
        rpSetCreateProgramDataObj({
          ...createProgramDataObj,
          programDetails: { ...values, programBudget: budgetValue || null },
          modifiedStatus: { ...modifiedStatus, details: detailsModifiedStatus }
        })
      );
    }
  }, [JSON.stringify(values)]); // eslint-disable-line

  /** Get program details validation */
  const getProgramDetailsValidation = (data: rpApiTypes.IRPProgramDetailData) => {
    const isBudgetValid = validateBudget(programAction, promotionVaultList, budgetValue, data.rewardsType);
    if (!data.name) return false;
    else if (data.name && data.rewardsType === RewardType.CASH_PRIZE && !budgetValue) return false;
    else if (data.name && data.rewardsType === RewardType.CASH_PRIZE && data.duration === Duration.ONETIME && (!data.startDate || !data.endDate)) return false;
    else if (data.name && data.rewardsType === RewardType.CASH_PRIZE && data.duration === Duration.RECURRING && !data.startDate) return false;
    else if (data.name && data.rewardsType === RewardType.NON_CASH_PRIZE && data.duration === Duration.ONETIME && (!data.startDate || !data.endDate)) return false;
    else if (data.name && data.rewardsType === RewardType.NON_CASH_PRIZE && data.duration === Duration.RECURRING && !data.startDate) return false;
    else if (programAction.length && !isBudgetValid) return false;
    else if (currentAction === RP_ACTIONS_TYPE.EDIT && budgetValue && parseFloat(previousprogramBudget || `${0}`) > parseFloat(budgetValue || `${0}`)) return false;
    else return true;
  };

  /** Set values when changes happen in forms */
  const handleChange = (field: string, value: null | string | number | Date) => {
    if (field === CP_DETAILS_PAGE_FIELDS.REWARDS_TYPE && value === RewardType.NON_CASH_PRIZE) setFieldValue(CP_DETAILS_PAGE_FIELDS.PROGRAM_BUDGET, ``);
    if (field === CP_DETAILS_PAGE_FIELDS.DURATION && value === Duration.ONETIME) {
      setFieldValue(CP_DETAILS_PAGE_FIELDS.START_DATE, null);
      setFieldValue(CP_DETAILS_PAGE_FIELDS.END_DATE, null);
    }
    if (field === CP_DETAILS_PAGE_FIELDS.DURATION && value === Duration.RECURRING) {
      setFieldValue(CP_DETAILS_PAGE_FIELDS.START_DATE, values.startDate || getCurrentDate());
      setFieldValue(CP_DETAILS_PAGE_FIELDS.END_DATE, null);
    }
    setFieldValue(field, value);
    if (currentAction === RP_ACTIONS_TYPE.EDIT && !isRewardsModified) {
      dispatch(rpRewardsModified(true));
    }
  };

  return (
    <div className="program-details-section-wrp">
      <Alert variant="primary">
        <Alert.Heading>Program Details</Alert.Heading>
      </Alert>

      <div className="main-section">
        <div className="item-section">
          <div className="left-section">
            <span>Program Name </span>
          </div>
          <div className="right-section">
            <div
              className={`form-group form-field-item lz-active${touched.name && errors.name ? ` error-disp` : ``}${
                currentAction === RP_ACTIONS_TYPE.EDIT && values.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate ? ` events-none pointer-events-none` : ``
              }`}
            >
              <input
                type="text"
                name={CP_DETAILS_PAGE_FIELDS.NAME}
                className={`form-control${values.name ? ` used` : ``}`}
                placeholder="Enter Program Name"
                autoComplete="off"
                onBlur={handleBlur}
                value={values.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(CP_DETAILS_PAGE_FIELDS.NAME, e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="item-section">
          <div className="left-section">
            <span>Recommended Program </span>
          </div>
          <div
            className={`right-section${
              (userOwnership === USER_OWNERSHIP.ACCOUNT && currentAction === RP_ACTIONS_TYPE.CREATE) ||
              (currentAction === RP_ACTIONS_TYPE.EDIT && values?.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate)
                ? ` events-none pointer-events-none`
                : ``
            }`}
          >
            <Form.Check
              type="switch"
              id="custom-switch"
              disabled={
                (userOwnership === USER_OWNERSHIP.ACCOUNT && currentAction === RP_ACTIONS_TYPE.CREATE) ||
                (currentAction === RP_ACTIONS_TYPE.EDIT && values?.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate) ||
                modalType === COMMON_PAGE_TYPE.DETAIL_VIEW
                  ? true
                  : false
              }
              onChange={() => handleChange(CP_DETAILS_PAGE_FIELDS.RECOMMENDED_TYPE, values.recommendedType === RecommendedType.DEFAULT ? RecommendedType.RECOMMENDED_TYPE : RecommendedType.DEFAULT)}
              checked={values.recommendedType === RecommendedType.RECOMMENDED_TYPE ? true : false}
            />
          </div>
        </div>

        <div className="item-section">
          <div className="left-section">
            <span>Type </span>
          </div>
          <div
            className={`right-section${
              currentAction === RP_ACTIONS_TYPE.EDIT && values.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate ? ` events-none pointer-events-none` : ``
            }`}
          >
            <div className="group-btn-wrp">
              <ButtonGroup className="rew-btn-group">
                <Button className={`rew-btn${values.rewardsType === RewardType.CASH_PRIZE ? ` active` : ``}`} onClick={() => handleChange(CP_DETAILS_PAGE_FIELDS.REWARDS_TYPE, RewardType.CASH_PRIZE)}>
                  <div className="avathar-icon">
                    <ImageValidation isImgValid defaultImg={values.rewardsType === RewardType.CASH_PRIZE ? 'c-prize-active' : 'c-prize'} customName="avatar" />
                  </div>
                  Cash Prize
                </Button>
                <Button
                  className={`rew-btn${values.rewardsType === RewardType.NON_CASH_PRIZE ? ` active` : ``}`}
                  onClick={() => handleChange(CP_DETAILS_PAGE_FIELDS.REWARDS_TYPE, RewardType.NON_CASH_PRIZE)}
                >
                  <div className="avathar-icon">
                    <ImageValidation isImgValid defaultImg={values.rewardsType === RewardType.NON_CASH_PRIZE ? 'nc-prize-active' : 'nc-prize'} customName="avatar" />
                  </div>
                  Non-Cash Prize
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="item-section">
          <div className="left-section">
            <span>Duration</span>
          </div>
          <div
            className={`right-section${
              currentAction === RP_ACTIONS_TYPE.EDIT && values.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate ? ` events-none pointer-events-none` : ``
            }`}
          >
            <div className="duration-wrp">
              <label>
                <input
                  type="radio"
                  className="option-input radio"
                  name={CP_DETAILS_PAGE_FIELDS.DURATION}
                  onClick={() => handleChange(CP_DETAILS_PAGE_FIELDS.DURATION, Duration.ONETIME)}
                  checked={values.duration === Duration.ONETIME}
                  readOnly
                />
                <span className="labelText">Set a Start and End Date</span>
              </label>

              <label>
                <input
                  type="radio"
                  className="option-input radio"
                  name={CP_DETAILS_PAGE_FIELDS.DURATION}
                  onClick={() => handleChange(CP_DETAILS_PAGE_FIELDS.DURATION, Duration.RECURRING)}
                  checked={values.duration === Duration.RECURRING}
                  readOnly
                />
                <span className="labelText">Ongoing</span>
              </label>
            </div>

            <div
              className={`action-start-date-time-wrapper dp-unset ${(touched.startDate && errors.startDate) || (touched.endDate && errors.endDate) ? ` error-disp` : ``}${
                currentAction === RP_ACTIONS_TYPE.EDIT && values.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate ? ` events-none pointer-events-none` : ``
              }`}
            >
              <div className="left-section">
                <div className="calendar-icon">
                  <ImageValidation isImgValid defaultImg={'calendar-icon'} customName="calendar-icon" />
                </div>
                <CustomDatePicker
                  name={CP_DETAILS_PAGE_FIELDS.START_DATE}
                  dateRangeType={ICommonDateTypes.FROM_DATE}
                  selectedDate={values.startDate ? getDatePickerDateToLocal(values.startDate) : null}
                  onChange={(date: null | Date) => {
                    if (date) {
                      const sameDay = compareCurrentDate(date.toDateString());
                      const dateString = sameDay ? getCurrentDate() : getStartOfDate(date);
                      handleChange(CP_DETAILS_PAGE_FIELDS.START_DATE, dateString);
                    }
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    const formatedDate = formatEditDate(e.target.value);
                    const editedDateIsValid = checkValidDate(formatedDate);
                    if (editedDateIsValid) {
                      handleChange(CP_DETAILS_PAGE_FIELDS.START_DATE, formatedDate);
                    }
                  }}
                  dateFormat={RP_DATEPICKER_DATE_TIME_FORMAT}
                  minDate={getCurrentDate()}
                  maxDate={null}
                  autoComplete="off"
                  placeholderText={`Start date & time`}
                  customClassname={`form-control${touched.startDate && errors.startDate ? ` erroritem` : ``}`}
                  disabled={
                    (currentAction === RP_ACTIONS_TYPE.EDIT && values?.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate) ||
                    modalType === COMMON_PAGE_TYPE.DETAIL_VIEW
                      ? true
                      : false
                  }
                />
              </div>

              {values.duration === Duration.ONETIME && <div className="center-section">to</div>}

              {values.duration === Duration.ONETIME && (
                <div className="right-section">
                  <CustomDatePicker
                    dateRangeType={ICommonDateTypes.TO_DATE}
                    selectedDate={values.endDate ? getDatePickerDateToLocal(values.endDate) : null}
                    onChange={(date: null | Date) => {
                      handleChange(CP_DETAILS_PAGE_FIELDS.END_DATE, getDatePickerDateToLocal(getEndOfDate(date)));
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      const formatedDate = formatEditDate(e.target.value);
                      const editedDateIsValid = checkValidDate(formatedDate);
                      if (editedDateIsValid) {
                        handleChange(CP_DETAILS_PAGE_FIELDS.END_DATE, formatedDate);
                      }
                    }}
                    dateFormat={RP_DATEPICKER_DATE_TIME_FORMAT}
                    minDate={!values.startDate && typeof values.startDate !== 'string' ? getCurrentDate() : values.startDate && typeof values.startDate !== 'string' ? values.startDate : null}
                    autoComplete="off"
                    placeholderText={`End date & time`}
                    customClassname={`form-control${touched.endDate && errors.endDate ? ` erroritem` : ``}`}
                    disabled={
                      (currentAction === RP_ACTIONS_TYPE.EDIT && values?.stage === Stage.PAYMENT_COMPLETED && values.startDate && getCurrentDate() > values.startDate) ||
                      modalType === COMMON_PAGE_TYPE.DETAIL_VIEW
                        ? true
                        : false
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {values.rewardsType === RewardType.CASH_PRIZE && (
          <div className="item-section">
            <div className="left-section">
              <span>Program Budget</span>
            </div>
            <div className="right-section">
              <div className="wb-top">
                <div className="wb-f-main">
                  <div className="wb-fields">
                    <div className="wbf-item">
                      <div className="wbf-inputs">
                        <div className="input-fields">
                          <div className="if-main if-h">
                            <p className="ifa">$</p>
                            <Form.Control
                              type="text"
                              placeholder={`${0}`}
                              minLength={1}
                              maxLength={20}
                              className={`plain-input${touched.programBudget && errors.programBudget ? ` error-disp` : ``}`}
                              name={CP_DETAILS_PAGE_FIELDS.PROGRAM_BUDGET}
                              value={values.programBudget || ''}
                              onKeyPress={(event: React.KeyboardEventHandler<HTMLInputElement> | any) => {
                                const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                if (!regexData.test(enteredKey)) {
                                  event.preventDefault();
                                  return false;
                                }
                              }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(CP_DETAILS_PAGE_FIELDS.PROGRAM_BUDGET, e.target.value);
                              }}
                              autoComplete="off"
                            />
                          </div>
                          <span className="country-initials">U.S.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
