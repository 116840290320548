import { useSelector } from 'react-redux';

import { enableAICaptionValidation, setAIGeneratedOption, setCreatorAICaptions, setCreatorAITabName } from 'actions';
import { IStore, aiContentCreatorContainerTypes } from 'types';
import { IAIResultsTab, IAISugesstionTabs } from 'utils/constants';

export const CaptionsList = ({ handleEditorStateChanges, handleSelect, selectedTab, NoResult, dispatch }: aiContentCreatorContainerTypes.IPostContentProps) => {
  const caption = useSelector((state: IStore) => state.aiContentCreator.caption);
  const createPostSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostSuggestions);
  const aiGenerated = useSelector((state: IStore) => state.aiContentCreator.aiGenerated);

  const PostContent = ({ content, index }: aiContentCreatorContainerTypes.IContentResults) => {
    return (
      <div className={`ai-picks aitc-item ${caption.index === index ? 'active' : ''} `}>
        <div className="ait-hover">
          <button
            className="ac-btn turquoise"
            onClick={() => {
              const state = caption.index === index ? { index: null, text: '' } : { index, text: content };
              dispatch(setAIGeneratedOption({ ...aiGenerated, caption: caption.index === index ? false : true }));
              handleEditorStateChanges(state.index !== null ? state?.text : null);
              dispatch(setCreatorAICaptions({ ...caption, index: state.index }));
              dispatch(setCreatorAITabName({ parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS }));
              handleSelect();
              dispatch(enableAICaptionValidation(false));
            }}
          >
            <span>{caption.index === index ? 'Selected' : 'Select'}</span>
          </button>
        </div>
        <div className="ait-front">
          <span className="aitc-count">{index + 1}</span>
          <p>{content}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="aitc ai-tab-contents">
      <h3>Captions</h3>
      {createPostSuggestions?.length ? createPostSuggestions.map((it, index) => <PostContent content={it} index={index} key={`${it}_post_${index}`} />) : <NoResult parent={selectedTab.parent} />}
    </div>
  );
};
