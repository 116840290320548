import { IReviewAnalyticsFilter, IReviewsAnalyticsStats } from 'analytics/types/review-analytics';
import { KeywordCAGraphFadeColors, KeywordCAGraphFillColors, KeywordsCAGraphColors } from 'analytics/utils/theme/rallio';
import { COMMON_SOCIAL_PROVIDER_TYPE, REVIEWS_LOCATIONS_DROPDOWN_OBJ } from 'utils/constants';
import { dateHelperForMonth } from '../../helpers/common';
import { ANALYTICS_PLATFORM_CHART_OPTIONS, PLATFORM_FILTER, ChartColors, ChartType, NO_DATA_FOUND, FontStyle, RatingStatusValue } from '../common';

export const REVIEW_ANALYTICS_PLATFORM_FILTER = [PLATFORM_FILTER[0], PLATFORM_FILTER[1], PLATFORM_FILTER[6], PLATFORM_FILTER[7]];

export enum ReviewsPlatformColors {
  FACEBOOK = '#3870FF',
  GOOGLE = '#477AB7',
  YELP = '#D32323'
}

export enum KeywordManagementTableValue {
  KEYWORD = 'keyword',
  USED = 'used',
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
  USED_PERCENTAGE = 'used_percentage'
}

export const KEY_WORD_MANAGEMENT_TABLE_HEADERS = [
  { title: 'Keywords', value: KeywordManagementTableValue.KEYWORD },
  { title: 'Used', value: KeywordManagementTableValue.USED },
  { title: 'Positive', value: KeywordManagementTableValue.POSITIVE },
  { title: 'Neutral', value: KeywordManagementTableValue.NEUTRAL },
  { title: 'Negative', value: KeywordManagementTableValue.NEGATIVE },
  { title: '% of Reviews', value: KeywordManagementTableValue.USED_PERCENTAGE }
];

export const REVIEW_ANALYTICS_FILTER_INITIAL_VALUE: IReviewAnalyticsFilter = {
  dateRange: dateHelperForMonth(true),
  platform: 'all',
  locationId: null,
  locationListId: null,
  ancestorFranchisorId: null,
  locationFilterObj: REVIEWS_LOCATIONS_DROPDOWN_OBJ,
  ratings: []
};

export const REDUCE_OBJ = ['pos', 'neg', 'neut'];
export const KEYWORDS_COLOR = ['#21D184', '#FF5C5C', '#00000066'];

export const REVIEW_ANALYTICS_PIE_LEGEND = [
  { image: 'fb-lv.svg', class: 'fb-txt', name: 'Facebook', value: 'facebook' },
  { image: 'google-lv.svg', class: 'google-txt', name: 'Google', value: 'google' },
  { image: 'yelp-lv.svg', class: 'yelp-txt', name: 'Yelp', value: 'yelp' }
];

export const pieStarColors = ['#D17E00', '#F2A102', '#FFC700', '#FFDF70', '#FFEBA3'];

export enum ReviewsAnalyticsStarLegendName {
  ONE_STAR = '1 Star',
  TWO_STAR = '2 Star',
  THREE_STAR = '3 Star',
  FOUR_STAR = '4 Star',
  FIVE_STAR = '5 Star'
}

export const REVIEW_ANALYTICS_STAR_PIE_LEGEND = [
  { name: ReviewsAnalyticsStarLegendName.ONE_STAR, value: 'one_star', ratings: '1' },
  { name: ReviewsAnalyticsStarLegendName.TWO_STAR, value: 'two_star', ratings: '2' },
  { name: ReviewsAnalyticsStarLegendName.THREE_STAR, value: 'three_star', ratings: '3' },
  { name: ReviewsAnalyticsStarLegendName.FOUR_STAR, value: 'four_star', ratings: '4' },
  { name: ReviewsAnalyticsStarLegendName.FIVE_STAR, value: 'five_star', ratings: '5' }
];

export const fbVariantColors = ['#F0665E', '#C4C4C4'];

export enum ReviewsAnalyticsFBVariantLegendName {
  RECOMMENDED = 'Recommended',
  NOT_RECOMMENDED = 'Not Recommended'
}

export const REVIEW_ANALYTICS_FB_VARIEANT_PIE_LEGEND = [
  { name: ReviewsAnalyticsFBVariantLegendName.RECOMMENDED, value: 'recommended', ratings: '5' },
  { name: ReviewsAnalyticsFBVariantLegendName.NOT_RECOMMENDED, value: 'not_recommended', ratings: '1' }
];

export enum ReviewAnalyticsStatsValue {
  REVIEWS = 'reviews',
  AVERAGE_RATING = 'avg_rating',
  REPLY = 'reply',
  RESPONSE_TIME = 'response_time',
  KEYWORDS = 'keywords'
}

export const REVIEW_ANALYTICS_STATS: IReviewsAnalyticsStats[] = [
  {
    id: 0,
    title: 'New Reviews',
    color: '#3870FF',
    fillColor: 'rgba(56, 112, 255, 0.23)',
    fadeColor: 'rgba(196, 196, 196, 0)',
    value: ReviewAnalyticsStatsValue.REVIEWS,
    totalValue: 'total_reviews_count',
    chartTitle: 'Number of New Reviews',
    pieChartTitle: 'New Reviews',
    delta: 'total_reviews_delta',
    brandAvg: 'brand_avg_reviews_count',
    hubAvg: 'hub_avg_reviews_count',
    chartValue: 'reviews_count'
  },
  {
    id: 1,
    title: 'Average Rating',
    color: '#1F527C',
    fillColor: '#1F527C',
    fadeColor: 'rgba(31, 82, 124, 0)',
    value: ReviewAnalyticsStatsValue.AVERAGE_RATING,
    totalValue: 'avg_rating',
    chartTitle: 'Ratings from New Reviews',
    pieChartTitle: 'Average Rating from New Reviews',
    delta: 'avg_rating_delta',
    brandAvg: 'brand_avg_rating',
    hubAvg: 'hub_avg_rating',
    chartValue: 'avg_rating',
    piChartChartTitle: 'Percentage of each Review Rating'
  },
  {
    id: 2,
    title: 'Percent Replied',
    color: ' #27AE60',
    fillColor: 'rgba(105, 214, 151, 0.22)',
    fadeColor: 'rgba(240, 255, 246, 0)',
    value: ReviewAnalyticsStatsValue.REPLY,
    totalValue: 'total_replied_percentage',
    chartTitle: 'Number of Reviews Replies',
    chartTitlePerc: 'New Reviews Reply Percentage',
    pieChartTitle: 'Percent Replied of New Reviews',
    delta: 'total_replied_percentage_delta',
    brandAvg: 'brand_avg_replied_percentage',
    hubAvg: 'hub_avg_replied_percentage',
    chartValue: 'replied_percentage',
    piChartChartTitle: 'Percentage of Total Review Replies by Platform'
  },
  {
    id: 3,
    title: 'Response Time',
    color: '#1F527C',
    fillColor: 'rgba(31, 82, 124, 0.15)',
    fadeColor: 'rgba(31, 82, 124, 0)',
    value: ReviewAnalyticsStatsValue.RESPONSE_TIME,
    totalValue: 'avg_response_time',
    chartTitle: 'Average Response Time',
    pieChartTitle: 'Average Response Time',
    pieChartSubTitle: 'min:sec',
    delta: 'avg_response_time_delta',
    brandAvg: 'brand_avg_response_time',
    hubAvg: 'hub_avg_response_time',
    chartValue: 'avg_response_time'
  },
  {
    id: 4,
    title: 'Keywords',
    color: '#2CCBA7',
    fillColor: 'rgba(44, 203, 167, 0.1)',
    fadeColor: 'rgba(44, 203, 167, 0)',
    value: ReviewAnalyticsStatsValue.KEYWORDS,
    totalValue: 'total_keywords_count',
    chartTitle: 'Keyword Management',
    pieChartTitle: 'Keyword Management',
    delta: 'total_reviews_delta',
    chartValue: 'reviews_count'
  }
];

export const PERCENT_REPLIED_IN_NUMBER = {
  id: 5,
  title: 'Reviews Replied',
  color: ' #27AE60',
  fillColor: 'rgba(105, 214, 151, 0.22)',
  fadeColor: 'rgba(240, 255, 246, 0)',
  value: 'replied_count',
  totalValue: 'total_replied_percentage',
  chartTitle: 'Number of Reviews Replies',
  pieChartTitle: 'Total Review Replies',
  delta: 'replied_delta',
  brandAvg: 'brand_avg_replied_percentage',
  hubAvg: 'hub_avg_replied_percentage',
  chartValue: 'replied_count'
};

export const TOOLT_TIP_COLOR = [
  {
    label: 'Average Rating',
    class: 'ra-avg-text',
    color: '#FFC259'
  },
  {
    label: 'Facebook',
    class: 'ra-fb-text',
    color: '#1877F2'
  },
  {
    label: 'Yelp',
    class: 'ra-yelp-text',
    color: '#D32323'
  },
  {
    label: 'Google Business',
    class: 'ra-google-text',
    color: '#4989F5'
  }
];

export const REVIEW_PLATFORM_CHART_OPTIONS = [ANALYTICS_PLATFORM_CHART_OPTIONS[0], ANALYTICS_PLATFORM_CHART_OPTIONS[1], ANALYTICS_PLATFORM_CHART_OPTIONS[5], ANALYTICS_PLATFORM_CHART_OPTIONS[6]];

export enum ViewTypes {
  NUMBER = 'View in Number',
  PERCENTAGE = 'View in Percentage'
}

export const REVIEW_ANALYTICS_KEYWORD_STATS = [
  {
    title: 'Positive',
    variant: 'positive diff',
    value: 'pos'
  },
  {
    title: 'Neutral',
    variant: 'neutral diff',
    value: 'neut'
  },
  {
    title: 'Negative',
    variant: 'negative diff',
    value: 'neg'
  }
];

export const KEYWORDS_FONT_VARIANT = ['large', 'meadium', 'small'];

export const TAB_GRAPH_SERIES = [
  {
    key: 'percReplied',
    value: ReviewAnalyticsStatsValue.REPLY
  },
  {
    key: 'percRepliedCount',
    value: 'replied_count'
  },
  {
    key: 'responseTime',
    value: ReviewAnalyticsStatsValue.RESPONSE_TIME
  }
];

export const REVIEW_ANALYTICS_WORD_CLOUD_OPTIONS: Highcharts.Options = {
  chart: {
    renderTo: 'kwrds'
  },
  series: [
    {
      type: ChartType.WORDCLOUD,
      minFontSize: 12,
      maxFontSize: 25,
      style: {
        fontFamily: 'Mulish'
      },
      animation: {
        duration: 1500,
        easing: 'easeOutBounce'
      },
      rotation: {
        from: 0,
        to: 0,
        orientations: 1
      }
    }
  ],
  tooltip: {
    enabled: false
  },
  lang: {
    noData: NO_DATA_FOUND
  },
  title: {
    useHTML: true,
    align: 'left',
    text: 'Keywords',
    style: {
      fontSize: FontStyle.SIZE,
      fontWeight: FontStyle.WEIGHT,
      color: ChartColors.BLACK
    }
  },
  credits: {
    enabled: false
  },
  accessibility: {
    enabled: false
  }
};

export const REVIEW_ANALYTICS_BAR_CHART_OPTIONS: Highcharts.Options = {
  chart: {
    spacingLeft: 0,
    spacingRight: 0,
    spacingTop: 10,
    spacingBottom: 10
  },
  title: {
    text: ''
  },
  legend: {
    enabled: false
  },
  lang: {
    noData: NO_DATA_FOUND
  },
  credits: {
    enabled: false
  },
  xAxis: {
    visible: false,
    margin: 0,
    min: 0,
    maxPadding: 0,
    minPadding: 0
  },
  tooltip: {
    enabled: false
  },
  yAxis: {
    min: 0,
    visible: false,
    max: 100
  },
  plotOptions: {
    bar: {
      pointWidth: 20
    }
  }
};

export const VALID_ON_CLICK_HEADERS = [
  KEY_WORD_MANAGEMENT_TABLE_HEADERS[0].value,
  KEY_WORD_MANAGEMENT_TABLE_HEADERS[2].value,
  KEY_WORD_MANAGEMENT_TABLE_HEADERS[3].value,
  KEY_WORD_MANAGEMENT_TABLE_HEADERS[4].value
];

export const REVIEW_ANALYTICS_SENTIMENTS = {
  [COMMON_SOCIAL_PROVIDER_TYPE.ALL]: {
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[2].value]: [RatingStatusValue.FIVE, RatingStatusValue.RECOMMENDED],
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[4].value]: [RatingStatusValue.ONE, RatingStatusValue.TWO, RatingStatusValue.NOT_RECOMMENDED],
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[3].value]: [RatingStatusValue.THREE, RatingStatusValue.FOUR]
  },
  [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK]: {
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[2].value]: [RatingStatusValue.RECOMMENDED],
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[4].value]: [RatingStatusValue.NOT_RECOMMENDED]
  },
  [COMMON_SOCIAL_PROVIDER_TYPE.YELP]: {
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[2].value]: [RatingStatusValue.FIVE],
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[4].value]: [RatingStatusValue.ONE, RatingStatusValue.TWO],
    [KEY_WORD_MANAGEMENT_TABLE_HEADERS[3].value]: [RatingStatusValue.THREE, RatingStatusValue.FOUR]
  }
};

export enum KeywordCAValue {
  LOCATION_SCORE = 'location_score'
}

export enum KeywordCATitle {
  LOCATION_SCORE = 'Location Score'
}

export const KEYWORDS_CATEGORY_CHART_DATA = [
  {
    title: KeywordCATitle.LOCATION_SCORE,
    value: KeywordCAValue.LOCATION_SCORE,
    header: 'Location Score',
    class: 'published-p-txt',
    colorKey: 'LOCATION_SCORE',
    color: KeywordsCAGraphColors.LOCATION_SCORE,
    fillColor: KeywordCAGraphFillColors.LOCATION_SCORE,
    fadeColor: KeywordCAGraphFadeColors.LOCATION_SCORE
  }
];
