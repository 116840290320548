import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentUserSearch } from 'actions';
import { commonModalPopup } from 'types';
import { debounceMethod } from 'utils/helpers';
import { INPUT_TYPE } from 'utils/constants';

export const UserDetailSearch = ({ searchValue, handleInputSearch }: commonModalPopup.IUserDetailSearchProps) => {
  const dispatch = useDispatch();

  const postsListApiTimeout = useRef<null | NodeJS.Timeout>(null);

  return (
    <div className={`search-tk post-search post-list__search`}>
      <div className="react-tags">
        <div className="react-tags__search">
          <div className="react-tags__search-input">
            <input
              type={INPUT_TYPE.TEXT}
              placeholder={'Search'}
              autoComplete="off"
              value={searchValue}
              onChange={(e) => {
                dispatch(setCurrentUserSearch(e.target.value));
              }}
              onKeyUp={() => {
                postsListApiTimeout.current = debounceMethod(searchValue.trim(), postsListApiTimeout.current, handleInputSearch);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
