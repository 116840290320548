import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'types';
import { getRemainingDaysFromToday } from 'utils/helpers';
import { BANNER_CONTEXT, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ImageValidation } from 'widgets/Media';
import { aiGetSubscriptionPlanRequest, setAISubscriptionDetailsModal } from 'actions';

export const Banner = () => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();

  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );

  const isFreeTier = ['Tier 1 - Free'].includes(accSubscriptionDetails?.franchisor_subscription_type?.plan_name);
  const remainingTrailDays = getRemainingDaysFromToday(accSubscriptionDetails?.end_date || '');
  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);

  const [openBanner, setOpenBanner] = useState(false);

  const showBanner = useMemo(() => {
    if (id && userOwnership && menuAccess) {
      const restrictions = menuAccess?.site_restrictions;
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR && restrictions?.hub_user) {
        return restrictions.hub_user.includes('show_banner');
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT && restrictions?.location_user) {
        return restrictions.location_user.includes('show_banner') || isFreeTier;
      }
    }
    return false;
  }, [id, userOwnership, menuAccess, isFreeTier]);

  useEffect(() => {
    setOpenBanner(showBanner);
  }, [showBanner]);

  return openBanner ? (
    <div className={`ap-alert-main ${!isFreeTier ? '' : 'freeTier-alert-on'}`}>
      <div className="api-alert-box alert-facebook">
        {isFreeTier ? (
          <div className="w-100 bg-white d-flex justify-end align-items-center">
            {remainingTrailDays > 0 ? (
              <p>
                Trial Ends in{' '}
                <strong>
                  {remainingTrailDays} {remainingTrailDays === 1 ? 'day.' : 'days.'}.
                </strong>
              </p>
            ) : (
              <p>Trial Ended.</p>
            )}

            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block kc-save`}
                type="submit"
                onClick={() => {
                  dispatch(setAISubscriptionDetailsModal({ type: 'contentSupplierSubscription', isOpen: true }));
                  if (!currentSubscription.length && activeUserId) {
                    dispatch(
                      aiGetSubscriptionPlanRequest({
                        id,
                        userOwnership,
                        userId: activeUserId,
                        type: 'contentSupplierSubscription'
                      })
                    );
                  }
                }}
              >
                Upgrade
              </button>
            </CustomRippleButton>
          </div>
        ) : (
          <div className="ab-cnt">
            <p>{BANNER_CONTEXT}</p>
          </div>
        )}
        {!isFreeTier && (
          <div className="alert-icon ai-x">
            <ImageValidation isImgValid defaultImg="alert-xmark" customClassname="close" customName="close" onClick={() => setOpenBanner(false)} />
          </div>
        )}
      </div>
    </div>
  ) : null;
};
