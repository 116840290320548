import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IStore } from 'types';
import { POSTS_STATUS_TYPE, USER_OWNERSHIP, POSTS_INIT_FILTER_DATA } from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { getPercentage } from 'utils/helpers';

export const TotalStatsCountPosts = () => {
  const { id, userOwnership } = useAccountSwitcherData();

  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);

  const postsStats = useSelector((state: IStore) => state.posts.postsStats);
  const isAvailBoostedPosts = useSelector((state: IStore) => state.posts.isAvailBoostedPosts);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);

  const totalStatCount = postsStats.totalPosts;
  const approved = postsStats.approvedPosts;
  const pending = userOwnership === USER_OWNERSHIP.FRANCHISOR ? postsStats.pendingPosts + postsStats.pendingPostsLocationContent : postsStats.pendingPosts;
  const draft = postsStats.draftPosts;
  const rejected = postsStats?.rejectedPosts !== undefined ? postsStats.rejectedPosts : 0;
  const boostedPost = userOwnership === USER_OWNERSHIP.FRANCHISOR && id === topLevelFranchisorId && postsStats.boostedPosts !== undefined ? postsStats.boostedPosts : 0;

  const [socialStat, setSocialStat] = useState({ approved: 0, pending: 0, draft: 0, rejected: 0, boostedPost: 0 });

  /** store converted stats value to local state */
  useEffect(() => {
    if (totalStatCount) {
      let approvedVal = getPercentage(totalStatCount, approved) || 0;
      let pendingVal = getPercentage(totalStatCount, pending) || 0;
      let draftVal = getPercentage(totalStatCount, draft) || 0;
      let rejectedVal = getPercentage(totalStatCount, rejected) || 0;
      let boostedPostVal = getPercentage(totalStatCount, boostedPost) || 0;
      const totalPercent = approvedVal + pendingVal + draftVal + rejectedVal + boostedPostVal;
      if (totalPercent > 100) {
        const diff = totalPercent - 100;
        const maxStatValue = Math.max(approvedVal, pendingVal, draftVal, rejectedVal, boostedPostVal);
        if (approvedVal === maxStatValue) approvedVal = approvedVal - diff;
        else if (pendingVal === maxStatValue) pendingVal = pendingVal - diff;
        else if (draftVal === maxStatValue) draftVal = draftVal - diff;
        else if (rejectedVal === maxStatValue) rejectedVal = rejectedVal - diff;
        else if (boostedPostVal === maxStatValue) boostedPostVal = boostedPostVal - diff;
      } else if (totalPercent < 100) {
        const diff = 100 - totalPercent;
        const minStatValue = Math.min.apply(null, [approvedVal, pendingVal, draftVal, rejectedVal, boostedPostVal].filter(Boolean));
        if (approvedVal === minStatValue) approvedVal = approvedVal + diff;
        else if (pendingVal === minStatValue) pendingVal = pendingVal + diff;
        else if (draftVal === minStatValue) draftVal = draftVal + diff;
        else if (rejectedVal === minStatValue) rejectedVal = rejectedVal + diff;
        else if (boostedPostVal === minStatValue) boostedPostVal = boostedPostVal + diff;
      }
      setSocialStat({ ...socialStat, approved: approvedVal, pending: pendingVal, draft: draftVal, rejected: rejectedVal, boostedPost: boostedPostVal });
    }
  }, [totalStatCount]); // eslint-disable-line

  const getClassName = () => {
    const className =
      queryParams?.status === POSTS_STATUS_TYPE.APPROVED
        ? 'cf-approved'
        : queryParams?.status === POSTS_STATUS_TYPE.AWAITING_APPROVAL
        ? 'cf-pending'
        : queryParams?.status === POSTS_STATUS_TYPE.DRAFT
        ? 'cf-draft'
        : queryParams?.status === POSTS_STATUS_TYPE.REJECTED
        ? 'cf-rejected'
        : isAvailBoostedPosts
        ? 'cf-boostedPost'
        : '';
    return className;
  };

  return (
    <div className={`chart-details ${getClassName()}`}>
      <div className="progress-vertical">
        <div className="bar bar-approved" style={{ width: '100%', background: '#21D184', height: `${socialStat.approved}%` }} />
        <div className="bar bar-pending" style={{ width: '100%', background: '#FFC96C', height: `${socialStat.pending}%` }} />
        <div className="bar bar-draft" style={{ width: '100%', background: '#495AA6', height: `${socialStat.draft}%` }} />
        <div className="bar bar-rejected" style={{ width: '100%', background: '#FF5C5C', height: `${socialStat.rejected}%` }} />
        <div className="bar bar-boostedpost" style={{ width: '100%', background: '#1877F2', height: `${socialStat.boostedPost}%` }} />
      </div>
    </div>
  );
};
