import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { campaignsContainerTypes } from 'types';
import {
  CAMPAIGN_POSTS_INIT_ACTIVE_SLOT,
  RP_TIME_PICKER_MERIDIAN,
  START_CAMPAIGN_POSTS_FILEDS,
  RIPPLE_COLOR,
  ALERT_BOX_TYPES,
  TIME_SLOT_ALREADY_EXISTED_ERROR_MSG,
  DAY_FORMAT,
  WEEKLY_SCHEDULE_CAMPAIGN_DATA
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { alertBoxCall } from 'components';
import { getDaysBetweenDates, isDateInBetweenTwoDates, getParsedDate } from 'utils/helpers';

export const CampaignWeeklyScheduleSection = ({ disabled, startDate, endDate, weeklySchedule, setFieldValue, activeSlot, setActiveSlot }: campaignsContainerTypes.ICampaignWeeklyScheduleProps) => {
  const handleAddDefaultSlot = (slotId: string) => {
    setActiveSlot((prevState) => ({ ...prevState, slotId }));
  };

  const handleDeleteActiveSlot = () => {
    setActiveSlot(CAMPAIGN_POSTS_INIT_ACTIVE_SLOT);
  };

  const handleTimePicker = (type: string, value: string) => {
    const validValue = value?.length === 2 ? String(value) : `0${value}`;
    setActiveSlot((prevState) => ({ ...prevState, ...(['slotHours', 'slotMinutes'].includes(type) && { activeTab: type }), [type]: validValue }));
  };

  const getValidTimeFormat = () => {
    const anteMeridiemHours = activeSlot.slotHours === '12' ? '00' : activeSlot.slotHours;
    const postMeridiemHours = activeSlot.slotHours === '12' ? activeSlot.slotHours : String(+activeSlot.slotHours + 12);
    const hours = activeSlot.slotMeridiem === 'AM' ? anteMeridiemHours : postMeridiemHours;
    return `${hours}:${activeSlot.slotMinutes}:00`;
  };

  const handleAddOrEditTimeForPreferredDate = () => {
    const validTime = getValidTimeFormat();
    if (weeklySchedule.find((it) => it.id === activeSlot.slotId && it.postSlot.includes(validTime))?.value) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, TIME_SLOT_ALREADY_EXISTED_ERROR_MSG);
    } else {
      const updateWeeklySchedule = weeklySchedule.reduce((acc: campaignsContainerTypes.ICampaignPostsWeeklySchedule[], curr) => {
        if (curr.id === activeSlot.slotId) {
          if (activeSlot.editIndex === null) {
            acc = [...acc, { ...curr, postSlot: [...curr.postSlot, validTime] }];
          } else {
            curr.postSlot.splice(activeSlot.editIndex, 1, validTime);
            acc = [...acc, { ...curr, postSlot: curr.postSlot }];
          }
        } else acc = [...acc, curr];
        return acc;
      }, []);
      setFieldValue(START_CAMPAIGN_POSTS_FILEDS.WEEKLY_SCHEDULE, updateWeeklySchedule);
      setActiveSlot(CAMPAIGN_POSTS_INIT_ACTIVE_SLOT);
    }
  };

  const handleDeleteTimeSlot = (slotId: string, timeSlot: string) => {
    const updateWeeklySchedule = weeklySchedule.reduce((acc: campaignsContainerTypes.ICampaignPostsWeeklySchedule[], curr) => {
      if (curr.id === slotId) acc = [...acc, { ...curr, postSlot: curr.postSlot.filter((it) => it !== timeSlot) }];
      else acc = [...acc, curr];
      return acc;
    }, []);
    setFieldValue(START_CAMPAIGN_POSTS_FILEDS.WEEKLY_SCHEDULE, updateWeeklySchedule);
  };

  const getCampaignSlotLabel = (slotItem: string) => {
    const splitTime = slotItem.split(':');
    if (splitTime.length) {
      const isFirstHalf = +splitTime[0] < 12;
      const anteMeridiemHoursLabel = splitTime[0] === '00' ? '12' : splitTime[0];
      const postMeridiemHoursLabel = splitTime[0] === '12' ? '12' : String(+splitTime[0] - 12)?.length === 2 ? `${+splitTime[0] - 12}` : `0${+splitTime[0] - 12}`;
      const slotHours = isFirstHalf ? anteMeridiemHoursLabel : postMeridiemHoursLabel;

      return `${slotHours}:${splitTime[1]} ${isFirstHalf ? 'AM' : 'PM'}`;
    }
    return '';
  };

  const handleEditTimeSlot = (slotId: string, timeSlot: string, editIndex: number) => {
    const slotDetail = getCampaignSlotLabel(timeSlot);
    setActiveSlot({ slotId, slotHours: slotDetail.slice(0, 2), slotMinutes: slotDetail.slice(3, 5), slotMeridiem: slotDetail.slice(6, 8), editIndex, activeTab: 'slotHours' });
  };

  const isSlotInvalid = (formattedDate: string) => {
    return formattedDate ? !isDateInBetweenTwoDates(startDate, endDate, getParsedDate(`${formattedDate} ${getValidTimeFormat()}`)) : false;
  };

  const disableAddOrEditSlotButton = (formattedDate: string) => {
    if (activeSlot.slotHours === '00') return true;
    else if (activeSlot.editIndex !== null) {
      return weeklySchedule.find((it) => it.id === activeSlot.slotId && it.postSlot?.length)?.postSlot.includes(getValidTimeFormat()) || false;
    } else if (startDate && endDate && isSlotInvalid(formattedDate)) return true;
    return false;
  };

  const enableAddOrDeleteSlotIcon = (dayValue: string) => {
    return startDate && endDate ? getDaysBetweenDates(startDate, endDate, DAY_FORMAT).slice(0, 7).includes(dayValue) : WEEKLY_SCHEDULE_CAMPAIGN_DATA.map((it) => it.value);
  };

  return (
    <div className="when-fields">
      <div className="wf-left-labels">
        <span>Schedule</span>
      </div>
      <div className={`wf-right-options${disabled ? ` events-none` : ''}`}>
        <div className="wf-schedule">
          {weeklySchedule.map((it, index) => {
            return (
              <div className={`wfs-row${it.id === activeSlot.slotId ? ` whs-open` : ''}`} key={`campaign-modal-ws-${index}`}>
                <div className="wfs-left">
                  <span>{it.value}</span>
                </div>
                <div className="wfs-right">
                  <div className="wf-sep" />
                  <div className="wfs-time">
                    <div className="tgroups">
                      {it.postSlot.map((slotItem, idx) => {
                        const slotData = getCampaignSlotLabel(slotItem);
                        return (
                          <div className="tg-item" key={`campaign-modal-ws-slot-${idx}`}>
                            <span className="tg-time" onClick={() => handleEditTimeSlot(it.id, slotItem, idx)}>
                              {slotData}
                            </span>
                            <span className="tg-remove" onClick={() => handleDeleteTimeSlot(it.id, slotItem)} />
                          </div>
                        );
                      })}
                    </div>

                    {enableAddOrDeleteSlotIcon(it.value) ? (
                      activeSlot.slotId === it.id ? (
                        <button className="plain-btn wfs-add" type="button" onClick={() => handleDeleteActiveSlot()}>
                          <div className="af-add-btn">
                            <ImageValidation isImgValid defaultImg={'remove-red'} customName="Delete" />
                          </div>
                        </button>
                      ) : (
                        <button className="plain-btn wfs-add" type="button" onClick={() => handleAddDefaultSlot(it.id)}>
                          <div className="af-add-btn">
                            <ImageValidation isImgValid defaultImg={'add-circle-green'} customName="Add" />
                          </div>
                        </button>
                      )
                    ) : null}
                  </div>
                  {it.id === activeSlot.slotId && (
                    <div className="sdt-options">
                      <div className="cr-timePicker">
                        <ButtonGroup className="crt-item crt-top btn-group-toggle">
                          <div className="tgb-item">
                            <ToggleButton id={`campaign-ws-hour`} type="radio" variant="secondary" name="hours" value={'slotHours'} checked={activeSlot.activeTab === 'slotHours'}>
                              <input
                                type="number"
                                className="iText"
                                placeholder={'00'}
                                step={1}
                                min={1}
                                max={12}
                                value={activeSlot.slotHours}
                                autoComplete="off"
                                onChange={(event) => handleTimePicker('slotHours', String(+event.target.value > 12 ? 12 : +event.target.value))}
                              />
                            </ToggleButton>
                          </div>

                          <div className="ctSep">
                            <ImageValidation isImgValid defaultImg={`ctSep`} customName="ctSep" />
                          </div>

                          <div className="tgb-item">
                            <ToggleButton id={`campaign-ws-minute`} type="radio" variant="secondary" name="minutes" value={'slotMinutes'} checked={activeSlot.activeTab === 'slotMinutes'}>
                              <input
                                type="number"
                                className="iText"
                                placeholder={'00'}
                                step={1}
                                min={0}
                                max={59}
                                value={activeSlot.slotMinutes}
                                autoComplete="off"
                                onChange={(event) => handleTimePicker('slotMinutes', String(+event.target.value > 59 ? 59 : +event.target.value))}
                              />
                            </ToggleButton>
                          </div>
                          <div className="crt-item crt-base">
                            <div className="switch6">
                              <label className="switch6-light">
                                <input type="checkbox" />
                                <span>
                                  {RP_TIME_PICKER_MERIDIAN.map((timeItem, timeIdx) => {
                                    return (
                                      <span
                                        key={`campaign-time-meridian-${timeIdx}`}
                                        className={timeItem === activeSlot.slotMeridiem ? 'active' : ''}
                                        onClick={() => handleTimePicker('slotMeridiem', timeItem)}
                                      >
                                        {timeItem}
                                      </span>
                                    );
                                  })}
                                </span>
                                <button type="button" className="btn btn-primary" />
                              </label>
                            </div>
                          </div>
                        </ButtonGroup>
                      </div>

                      <div className="ac-footer r-flx r-flx-ac g-20">
                        <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                          <button className={`ac-btn ac-secondary-white ac-outline size-xs border-0`} type="button" onClick={() => setActiveSlot(CAMPAIGN_POSTS_INIT_ACTIVE_SLOT)}>
                            <span>Cancel</span>
                          </button>
                        </CustomRippleButton>
                        <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                          <button
                            className={`ac-btn ac-primary ac-outline size-xs${disableAddOrEditSlotButton(it.date || '') ? ` pointer-events-none` : ``}`}
                            type="button"
                            onClick={() => handleAddOrEditTimeForPreferredDate()}
                          >
                            <span>{activeSlot.editIndex === null ? 'Add' : 'Edit'}</span>
                          </button>
                        </CustomRippleButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
