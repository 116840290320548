import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { ImageGrid } from 'react-fb-image-video-grid';

import { CustomSwiper, Image, ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { apAccountContainerTypes, IStore } from 'types';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  MG_MEDIA_TYPE,
  CREATOR_FB_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS,
  CREATOR_TWITTER_PREVIEW_FOOTER_ICONS,
  CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS,
  APP_TITLE,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO
} from 'utils/constants';
import { Paragraph } from 'widgets/Text';
import { getValidInstaProviderName, getPermittedImgsBasedOnPlatform, getValidFBTagPreview, convertURLAsLink } from 'utils/helpers';
import { FacebookReelPreview, FacebookStoryPreview, InstagramStoryPreview, SocialMediaLinkPreview, TiktokPreview } from 'widgets/Preview';
import { PLATFORM_FILTER } from 'analytics/utils';
import { SocialMediaPreviewFooterIcons } from '../CommonModal';
import { useActivePlatform } from 'utils/hooks';

export const RightCreatorPreviewSection = ({ accessibleSocialMedia }: apAccountContainerTypes.IAddPostSocialPlatformProps) => {
  const activePlatforms = useActivePlatform();

  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const validInstaName = getValidInstaProviderName(accountDetails?.social_media || []);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const instagramPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.photoUrl);
  const facebookPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.fbPhotoPreviewObj.photoUrl);
  const [playVideo, setPlayVideo] = useState<string[]>([]);

  useEffect(() => {
    if (!accessibleSocialMedia.length) setPlayVideo([]);
  }, [accessibleSocialMedia.length]); // eslint-disable-line

  const ImageGridCol = useCallback(
    ({ imageUrls }: { imageUrls: string[] }) => {
      return (
        <ImageGrid>
          {imageUrls.map((image, index) => (
            <img alt="ig" src={image} key={`selected-image-${index}`} />
          ))}
        </ImageGrid>
      );
    },
    [selectedMediaList] // eslint-disable-line
  );

  return (
    <div className="addpost-preview-section-wrp fbInMain">
      <div className="post-preview">
        {!accessibleSocialMedia.length ? (
          <div className="p-loader">
            <div className="p-mock-header heading">
              <div>
                <div className="p-mock-avatar" />
              </div>
              <div className="p-mock-title">
                <div className="p-mock-line" />
              </div>
            </div>
            <div className="card-bg">
              <div className="p-mock-header">
                <div>
                  <div className="p-mock-avatar" />
                </div>
                <div className="p-mock-title">
                  <div className="p-mock-line" />
                  <div className="p-mock-line" />
                </div>
              </div>

              <div className="p-mock-content">
                <div className="p-mock-line" />
              </div>
            </div>
          </div>
        ) : (
          accessibleSocialMedia
            .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b))
            .map((socialMediaName, index) => {
              const imageUrls =
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? savedPostDetails?.facebook_story
                    ? facebookPhotoPreviewUrl.storyUrls || []
                    : selectedMediaList.map((datum) => datum.medium_size_url || '')
                  : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? savedPostDetails?.instagram_story
                    ? instagramPhotoPreviewUrl.storyUrls || []
                    : instagramPhotoPreviewUrl.previewUrls || []
                  : selectedMediaList.map((datum) => datum.medium_size_url || '');
              const imagesLimitBasedOnPlatform = getPermittedImgsBasedOnPlatform(socialMediaName, imageUrls);
              const facebookTags = savedPostDetails?.use_facebook ? selectedFBTagList : [];
              const socialProfile = accountDetails?.social_media?.find((socialMedia) =>
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? socialMedia.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE
                  : socialMedia.provider_name === socialMediaName
              );

              return savedPostDetails && activePlatforms.includes(socialMediaName) && (savedPostDetails[`${socialMediaName}_text`] || selectedMediaList.length || linkData?.link_preview_title) ? (
                <div className={`p-content ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? 'smType-google' : ''}`} key={`account-creator-preview-${index}`}>
                  <div className="p-content-header">
                    <div className={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'p-avatar-box' : 'p-avatar-box-twitter'}>
                      {/* <div> */}
                      <Image
                        isImageExists={false}
                        customImgName={SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.logo || ''}
                        customName={'Social media'}
                      />
                      {/* </div> */}
                    </div>
                    <h4>{SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.label || ''}</h4>
                  </div>
                  <div
                    className={`card-bg ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? 'ig-assets' : ''}
                  ${selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? 'cb-tiktok' : ''} 
                  ${
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_story) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_reel) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
                      ? 'fb-in-active'
                      : ''
                  } `}
                  >
                    {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && (
                      <TiktokPreview
                        userName={topLevelFranchisor}
                        videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                        thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                        description={savedPostDetails?.[`${socialMediaName}_text`] || ''}
                      />
                    )}

                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                      savedPostDetails?.facebook_story ? (
                        <FacebookStoryPreview
                          userName={socialProfile?.profile_name || APP_TITLE}
                          media={selectedMediaList?.[0]}
                          imgStoryUrl={imageUrls[0]}
                          profilePic={socialProfile?.profile_picture_url || ''}
                        />
                      ) : savedPostDetails?.facebook_reel ? (
                        <FacebookReelPreview
                          userName={socialProfile?.profile_name || APP_TITLE}
                          description={savedPostDetails?.facebook_text || ''}
                          videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                          thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                          profilePic={socialProfile?.profile_picture_url || ''}
                        />
                      ) : null
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story ? (
                      <InstagramStoryPreview
                        userName={socialProfile?.profile_name || APP_TITLE}
                        media={selectedMediaList?.[0]}
                        imgStoryUrl={imageUrls[0]}
                        profilePic={socialProfile?.profile_picture_url || ''}
                      />
                    ) : null}
                    {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK &&
                      !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) &&
                      !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story) &&
                      accountDetails?.social_media?.map((socialMediaDatum, index) => {
                        const mediaName = validInstaName.includes(socialMediaName) ? validInstaName : socialMediaName;
                        return (
                          mediaName === socialMediaDatum.provider_name && (
                            <div className="p-header" key={`creator-profile-${index}`}>
                              <div className="p-header-title">
                                <div className="p-avatar">
                                  <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={socialMediaDatum?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
                                </div>
                                <div className="p-title">
                                  <h5>{socialMediaDatum.profile_name || APP_TITLE}</h5>

                                  {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && <Paragraph customText={'Just now'} />}
                                </div>
                              </div>
                              {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (
                                <div className="p-header-menu">
                                  <div className="insta-dots-rotate">
                                    <ImageValidation isImgValid defaultImg="dot-menu" customName="p-avatar" />
                                  </div>
                                </div>
                              )}
                              {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                                <div className="p-header-menu">
                                  <div className="insta-dots-rotate">
                                    <ImageValidation isImgValid defaultImg="share-dots" customName="shareDots" />
                                    <ImageValidation isImgValid defaultImg="more-dots-v" customName="moreDots" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        );
                      })}

                    {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story) ? (
                      <div className="p-body previewtLinks p-0">
                        {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                          <div className="am-media-preview pmg">
                            <div>
                              <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0] || linkData?.link_preview_image_url} customName={'Image asset'} />
                            </div>
                          </div>
                        )}
                        <div className="msg-wrapper">
                          {savedPostDetails && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                            <Paragraph facebookTagList={facebookTags} customText={getValidFBTagPreview(reducerTextEditorState?.facebook)} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
                          ) : ![COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(socialMediaName) ? (
                            <Paragraph customClassname="gm-text" customText={savedPostDetails[`${socialMediaName}_text`] || ''} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
                          ) : null}
                        </div>
                        <div className="am-media-preview pmg">
                          {selectedMediaList.length >= 1 && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO && socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                            imagesLimitBasedOnPlatform.length > 1 ? (
                              [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(socialMediaName) ? (
                                <ImageGridCol imageUrls={imagesLimitBasedOnPlatform} />
                              ) : (
                                <CustomSwiper imagesList={imagesLimitBasedOnPlatform} isVirtualSlider />
                              )
                            ) : (
                              <div>
                                <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
                              </div>
                            )
                          ) : null}
                          {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                            <div className="video-outer-element">
                              <ReactVideoPlayer
                                videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                                customClassname={'video'}
                                isPlayControl={playVideo.includes(socialMediaName)}
                                // playIcon={true}
                                // isPlayControl={false}
                                onClick={() => {
                                  if (savedPostDetails && savedPostDetails[`use_${socialMediaName}`]) {
                                    setPlayVideo([...playVideo, socialMediaName]);
                                  }
                                }}
                                light={selectedMediaList[0]?.thumbnail_url}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {linkData?.id ? (
                      <SocialMediaLinkPreview
                        imageUrl={linkData?.link_preview_image_url || null}
                        linkPreviewTitle={linkData?.link_preview_title || ''}
                        linkUrl={linkData?.url || ''}
                        isShowThumbnail={savedPostDetails?.is_thumbnail_show || false}
                        linkPreviewDescription={linkData?.link_preview_description || ''}
                        isLinkCustomized={savedPostDetails?.link_preview_customized || false}
                        actionType={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? savedPostDetails?.facebook_call_to_action_type : savedPostDetails?.google_call_to_action_type || null}
                        socialMedia={socialMediaName}
                      />
                    ) : null}

                    {socialMediaName &&
                    [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(socialMediaName) &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) ? (
                      <SocialMediaPreviewFooterIcons
                        socialMedia={socialMediaName}
                        footerIconsList={
                          socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                            ? CREATOR_FB_PREVIEW_FOOTER_ICONS
                            : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                            ? CREATOR_TWITTER_PREVIEW_FOOTER_ICONS
                            : CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS
                        }
                      />
                    ) : null}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && !savedPostDetails?.instagram_story && (
                      <>
                        <div className="p-footer" />
                        <div className="insta-social-sec-wrp">
                          <div className="lefticon">
                            <SocialMediaPreviewFooterIcons socialMedia={`${socialMediaName}-left`} footerIconsList={CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS} />
                          </div>

                          <div className="righticon">
                            <SocialMediaPreviewFooterIcons socialMedia={`${socialMediaName}-right`} footerIconsList={CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS} />
                          </div>
                        </div>
                        <div className="msg-wrapper">{savedPostDetails && <Paragraph customText={savedPostDetails[`${socialMediaName}_text`] || ''} customClassname={'insta-text-main'} />}</div>
                        <Paragraph customText={'Just now'} customClassname={'insta-text-sub'} />
                      </>
                    )}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && savedPostDetails.google_call_to_action_url && savedPostDetails.google_call_to_action_type && (
                      <>
                        <div className="linkWrap">
                          <span>
                            {parse(
                              convertURLAsLink(
                                savedPostDetails.google_call_to_action_url,
                                GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === savedPostDetails.google_call_to_action_type)?.label || '',
                                'add'
                              )
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : null;
            })
        )}
      </div>
    </div>
  );
};
