import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { CustomTooltip } from 'widgets/Tooltip';
import { AILoading, Loading } from 'components';
import { addPostGetSelectedContentObj, addPostUpdateSavedPostDetails, getAIStableDiffusionImageGenerationRequest, mgAIImageUploadingRequest, setAIGeneratedOption, setAITextContent } from 'actions';
import { IStore, aiContentCreatorContainerTypes, aiImageGenertaionReducerTypes } from 'types';
import {
  AI_ICON_BG_IMG,
  AI_IMAGE_NO_RESULTS_FOUND,
  TOOLTIP_PLACEMENT,
  AI_LOADING_IMAGE_GENERATION_CONTEXT_MSG,
  RIPPLE_COLOR,
  SIDE_NAV_NAME,
  THINK_LITTLE_HARDER_TOOLTIP_TEXT,
  USER_OWNERSHIP,
  AI_TEXT_CONTENT_INITIAL_VALUE
} from 'utils/constants';
import { addAIGeneratedImagesToCloudinary, handleAddOrDeleteMedia, splitTextFromHashAndEmoji } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { AIFilter } from './AIFilter';

const AIMediaList = ({ userOwnership, selectedTab, NoResult, isAIFilterEnabled, setEnableAIFilter, dispatch }: aiContentCreatorContainerTypes.IAIMediaListProps) => {
  const { currentRoute } = useAccountSwitcherData();

  const aiGeneratedImages = useSelector((state: IStore) => state.aiImageGeneration.aiGeneratedImages);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const addedAssetsCount = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.addedAssetsCount);
  const isAIImageUpdating = useSelector((state: IStore) => state.mediaGallery.isAIImageUpdating);
  const isAIImageFetching = useSelector((state: IStore) => state.aiImageGeneration.isAIImageFetching);
  const aiImageGenerationError = useSelector((state: IStore) => state.aiImageGeneration.error);
  const assetsList = useSelector((state: IStore) => state.mediaGallery.assetsList);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const aiGenerated = useSelector((state: IStore) => state.aiContentCreator.aiGenerated);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const aiStableDiffusionImage = useSelector((state: IStore) => state.aiImageGeneration.aiStableDiffusionImage);

  const isMediaScreen = currentRoute.split('/')[1] === SIDE_NAV_NAME.CONTENT_MEDIA;
  const steps = aiStableDiffusionImage?.automatic_task?.steps || 13;

  const isSaveButtonDisabled = useMemo(() => {
    if (aiGeneratedImages.length) {
      const imageName = `AI Generated-${aiGeneratedImages[0].id}`;
      return assetsList.find((it) => it.name === imageName) || selectedMediaList.find((it) => it.name === imageName);
    }
    return false;
  }, [assetsList, aiGeneratedImages, selectedMediaList]);

  const { values, setFieldValue, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      prompt: aiTextContent.image,
      add_detail: aiStableDiffusionImage?.automatic_task?.add_detail || 1,
      cfg_scale: aiStableDiffusionImage?.automatic_task?.cfg_scale || 5.0,
      steps,
      detailer_faces: aiStableDiffusionImage?.automatic_task?.detailer_faces || false,
      detailer_hands: aiStableDiffusionImage?.automatic_task?.detailer_hands || false,
      negative_prompt: aiStableDiffusionImage?.automatic_task?.negative_prompt || '',
      seed: aiStableDiffusionImage?.automatic_task?.seed!,
      aspect_ratio: aiStableDiffusionImage?.automatic_task?.aspect_ratio
    },
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (values: aiImageGenertaionReducerTypes.IAIImageRequestPayload) => {
      const payload = {
        ...values,
        prompt: splitTextFromHashAndEmoji(values.prompt)
      };
      dispatch(getAIStableDiffusionImageGenerationRequest(payload));
      setEnableAIFilter(false);
    }
  });

  const handleSaveImages = async () => {
    const imageName = `AI Generated-${aiGeneratedImages[0].id}`;
    const uploadedImage = assetsList.find((asset: any) => asset.name === imageName);
    dispatch(setAIGeneratedOption({ ...aiGenerated, image: true }));
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(
        addPostGetSelectedContentObj({
          ...contentObj,
          ...(contentObj?.customText?.[contentObj.currentTab] && {
            postStatus: {
              ...contentObj.postStatus,
              [contentObj.currentTab]: true
            }
          })
        })
      );
    } else {
      dispatch(
        addPostUpdateSavedPostDetails({
          ...savedPostDetails,
          ...(savedPostDetails?.[`${savedPostDetails.current_tab}_text`] && {
            [`use_${savedPostDetails.current_tab}`]: true
          })
        })
      );
    }
    if (!uploadedImage) {
      dispatch(mgAIImageUploadingRequest(true));
      await addAIGeneratedImagesToCloudinary([aiGeneratedImages[0]], response, addedAssetsCount, true, 'AI Generated', dispatch);
    } else {
      handleAddOrDeleteMedia(false, uploadedImage, selectedMediaList, dispatch);
    }
  };

  const handleCloudinaryUpload = async () => {
    if (aiGeneratedImages?.length) {
      await addAIGeneratedImagesToCloudinary([aiGeneratedImages[0]], response, addedAssetsCount, true, 'AI Generated', dispatch);
      dispatch(setAITextContent(AI_TEXT_CONTENT_INITIAL_VALUE));
    }
  };

  const handleThinkLittleHarder = () => {
    const payload = {
      ...values,
      steps: steps < 23 ? steps + 2 : steps,
      prompt: splitTextFromHashAndEmoji(values.prompt)
    };
    dispatch(getAIStableDiffusionImageGenerationRequest(payload));
  };

  const AIMediaContent = useCallback(
    ({ image }: { image: aiImageGenertaionReducerTypes.IAIGeneratedImages }) => {
      return (
        <div className={`ai-picks aitht-item`}>
          <div className="ait-front">
            <div className="ai-img-asset">
              <ImageValidation imgUrl={image.b64_json} customClassname={'m-ast-itm m-ast-img'} customName={'img-media'} />
            </div>
          </div>
        </div>
      );
    },
    [] // eslint-disable-line
  );
  return (
    <div
      className={`aitc ai-tab-contents ai-tab-img-wrap aiMedia ${isAIFilterEnabled ? 'imgGen-Filter-on' : 'imgGen-Filter-off'} ${
        !isAIImageFetching && !aiGeneratedImages.length && aiImageGenerationError ? '' : 'no-result'
      }`}
    >
      <div className="genHeadImgWrap">
        <h2 className="lightBlueHead">Images</h2>
        {aiGeneratedImages[0]?.b64_json && isShowAiImageTab && <h3>AI Images</h3>}
        {aiGeneratedImages.length && aiGeneratedImages[0]?.b64_json ? (
          <div className="d-flex ai-img-gen-wrap">
            {/* <div className={`infinite-scroll-component ${isAIFilterEnabled ? 'd-none' : ''}`}> */}
            <div className={`infinite-scroll-component`}>
              {aiGeneratedImages.map((it, index) => (
                <AIMediaContent image={it} key={`${it}_media_${index}`} />
              ))}
              <div className="use-fn-loader">{isAIImageUpdating ? <Loading /> : null}</div>
            </div>
            <AIFilter values={values} isAIFilterEnabled={isAIFilterEnabled} setFieldValue={setFieldValue} handleBlur={handleBlur} handleSubmit={handleSubmit} />
          </div>
        ) : !isAIImageFetching && !aiGeneratedImages.length && aiImageGenerationError ? (
          <span className="ai-no-results">{AI_IMAGE_NO_RESULTS_FOUND}</span>
        ) : isAIImageFetching && isShowAiImageTab ? (
          <AILoading aiIconBgColorType={AI_ICON_BG_IMG.WHITE} context={AI_LOADING_IMAGE_GENERATION_CONTEXT_MSG} />
        ) : (
          <NoResult isDataLoading={isAIImageFetching} parent={selectedTab.parent} />
        )}
      </div>
      {aiGeneratedImages.length > 0 ? (
        <div className="ai-sub-btns sai-use mediaAiActions">
          {!isAIFilterEnabled ? (
            <>
              {false && (
                <div className="mai-top">
                  <CustomRippleButton rippleClass={`ac-primary d-blue-bg${isAIImageUpdating || isSaveButtonDisabled || steps === 23 ? ' pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                    <button className={`ac-btn ac-primary-light ac-outline ac-block`} onClick={handleThinkLittleHarder}>
                      <span>Think a Little Harder</span>
                      <CustomTooltip
                        customPlacement={TOOLTIP_PLACEMENT.TOP}
                        customTooltipText={THINK_LITTLE_HARDER_TOOLTIP_TEXT}
                        customClassname={'custom-tooltip-long-text r-tbl-header__tip contetn-an analytics-content-pg'}
                        customInput={() => (
                          <div className="infoIcon-divWrap">
                            <ImageValidation isImgValid defaultImg="info-white-trans" customClassname={`info-grey`} customName={'Best Results From AI Assistant'} />
                          </div>
                        )}
                      />
                    </button>
                  </CustomRippleButton>

                  <CustomRippleButton rippleClass={`ac-primary${isAIImageUpdating || isSaveButtonDisabled ? ' pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
                    <button className={`ac-btn ac-primary-light ac-outline ac-block`} onClick={() => setEnableAIFilter(true)}>
                      <span>Advanced AI Editing</span>
                    </button>
                  </CustomRippleButton>
                </div>
              )}
              <div className="mai-base">
                <CustomRippleButton rippleClass={`ac-primary${isAIImageUpdating || isSaveButtonDisabled ? ' pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.skyBlue}>
                  <button className={`gnrc-btn skyblue`} onClick={isMediaScreen ? handleCloudinaryUpload : handleSaveImages}>
                    <span>{isMediaScreen ? 'Perfect! Save this Image' : 'Perfect! Use this Image'}</span>
                  </button>
                </CustomRippleButton>
              </div>
            </>
          ) : /* !isMediaScreen ? (
            <CustomRippleButton rippleClass={`ac-primary${isAIImageUpdating || isSaveButtonDisabled ? ' pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.skyBlue}>
              <button className={`gnrc-btn skyblue`} onClick={isMediaScreen ? handleCloudinaryUpload : handleSaveImages}>
                <span>{isMediaScreen ? 'Save' : 'Use'}</span>
              </button>
            </CustomRippleButton>
          ) : */ null}
          {isAIFilterEnabled ? (
            <div className="mai-base gen-filter">
              <CustomRippleButton rippleClass={`ac-primary`} custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="gnrc-btn white-gnr" onClick={() => setEnableAIFilter(false)}>
                  <span>Back</span>
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ac-primary`} custColor={RIPPLE_COLOR.skyBlue}>
                <button className="gnrc-btn skyblue" onClick={() => handleSubmit()}>
                  <span>Re-Generate</span>
                </button>
              </CustomRippleButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const AIMediaListMemo = React.memo(AIMediaList);
