import * as actionTypes from 'actions/action-types';
import { ActionsUnion, createAction } from 'utils/helpers';
import { forgotPasswordContainerType } from 'types';

// FORGOT PASSWORD
export const forgotPasswordRequest = (payload: forgotPasswordContainerType.IUserForgotPasswordInfo) => createAction(actionTypes.FORGOT_PASSWORD_REQUEST, payload);
export const forgotPasswordResponse = (data: string) => createAction(actionTypes.FORGOT_PASSWORD_RESPONSE, data);
export const forgotPasswordFailure = (error: string) => createAction(actionTypes.FORGOT_PASSWORD_FAILURE, error);

// FORGOT PASSWORD RESET
export const forgotPasswordErrorMessageReset = () => createAction(actionTypes.FORGOT_PASSWORD_ERROR_MSG_RESET);

const FORGOT_PASSWORD_ACTIONS = {
  forgotPasswordRequest,
  forgotPasswordResponse,
  forgotPasswordFailure,
  forgotPasswordErrorMessageReset
};

export type ForgotPasswordActions = ActionsUnion<typeof FORGOT_PASSWORD_ACTIONS>;
