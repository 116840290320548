import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { ReviewsActions } from 'actions';
import { reviewsReducerTypes } from 'types';
import {
  REVIEWS_INITIAL_STATS_VALUE,
  REVIEWS_INT_NOTES_OBJ,
  NOT_APPLICABLE,
  REVIEWS_ADD_REPLY_SUCCESS_MSG,
  REVIEWS_ADD_INT_NOTES_SUCCESS_MSG,
  CommonAction,
  REVIEWS_REJECT_REPLY_SUCCESS_MSG,
  REVIEWS_SEND_FOR_REPLY_APPROVAL_SUCCESS_MSG
} from 'utils/constants';
import {
  getUpdatedReviewsList,
  getUniqueReviewsInternalNotes,
  getReviewStatsByDate,
  getReviewRepliedPercentage,
  getRoundNumberTo2Decimal,
  getReviewsThatAreReplied,
  getReviewsIntNotesCount
} from 'utils/helpers';
import { reviewsTableDateGrouping } from 'analytics/utils/helpers';
import { REVIEW_ANALYTICS_PLATFORM_FILTER, REVIEW_ANALYTICS_STATS } from 'analytics/utils/constants';

type IReviewsReducer = reviewsReducerTypes.IReviewsReducer;

const initialState: IReviewsReducer = {
  isListFetching: true,
  isStatsFetching: true,
  isReplyReviewFetching: false,
  isSendReplyApprovalFetching: false,
  isEditReplyFetching: false,
  isApproveReplyFetching: false,
  error: null,
  currentPageCount: 1,
  reviewsList: [],
  reviewStats: REVIEWS_INITIAL_STATS_VALUE,
  messages: {
    reviewsSuccessMsg: null,
    reviewsFailureMsg: null
  },
  reviewsInternalNotes: REVIEWS_INT_NOTES_OBJ
};

export const reviewsReducer: Reducer<IReviewsReducer, ReviewsActions> = (state = initialState, action: ReviewsActions) => {
  switch (action.type) {
    case types.REVIEWS_GET_LIST_REQUEST:
      return {
        ...state,
        isListFetching: true,
        currentPageCount: action.payload.page
      };
    case types.REVIEWS_GET_LIST_RESPONSE:
      return {
        ...state,
        isListFetching: false,
        reviewsList: state.currentPageCount > 1 ? state.reviewsList.concat(action.payload) : action.payload
      };
    case types.REVIEWS_GET_LIST_FAILURE:
      return {
        ...state,
        isListFetching: false,
        error: action.payload || 'Unexpected error'
      };
    case types.REVIEWS_GET_STATS_REQUEST:
      return {
        ...state,
        isStatsFetching: true,
        reviewStats: REVIEWS_INITIAL_STATS_VALUE
      };
    case types.REVIEWS_GET_STATS_RESPONSE:
      const reviewStatsData = reviewsTableDateGrouping(action.payload.stats_by_date, REVIEW_ANALYTICS_STATS, REVIEW_ANALYTICS_PLATFORM_FILTER);
      return {
        ...state,
        isStatsFetching: false,
        reviewStats: {
          averageRating: getRoundNumberTo2Decimal(action.payload.avg_rating ? action.payload.avg_rating : 0) || 0,
          reviewsCount: action.payload.total_reviews_count || 0,
          repliedPercentage: action.payload.replied_percentage ? getReviewRepliedPercentage(action.payload.replied_percentage) : NOT_APPLICABLE,
          platformStats: {
            facebook: action.payload.stats_by_platform?.facebook || 0,
            yelp: action.payload.stats_by_platform?.yelp || 0,
            google: action.payload.stats_by_platform?.google_places || 0
          },
          statsByDate: getReviewStatsByDate(reviewStatsData.data)
        }
      };
    case types.REVIEWS_GET_STATS_FAILURE:
      return {
        ...state,
        isStatsFetching: false,
        reviewStats: REVIEWS_INITIAL_STATS_VALUE,
        error: action.payload || 'Unexpected error'
      };
    // REVIEWS - ADD REPLY TO A POST
    case types.REVIEWS_ADD_REPLY_TO_A_POST_REQUEST:
      return {
        ...state,
        isReplyReviewFetching: true
      };
    case types.REVIEWS_ADD_REPLY_TO_A_POST_RESPONSE:
      const updatedReviewsList = getUpdatedReviewsList(state.reviewsList, action.payload);
      return {
        ...state,
        isReplyReviewFetching: false,
        reviewsList: updatedReviewsList,
        messages: {
          ...state.messages,
          reviewsSuccessMsg: REVIEWS_ADD_REPLY_SUCCESS_MSG
        }
      };
    case types.REVIEWS_ADD_REPLY_TO_A_POST_FAILURE:
      return {
        ...state,
        isReplyReviewFetching: false,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // REVIEWS - COMPLETE POST
    case types.REVIEWS_COMPLETE_POST_REQUEST:
      return {
        ...state
      };
    case types.REVIEWS_COMPLETE_POST_RESPONSE:
      const completedReviewsList = getUpdatedReviewsList(state.reviewsList, action.payload);
      return {
        ...state,
        reviewsList: completedReviewsList
      };
    case types.REVIEWS_COMPLETE_POST_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // REVIEWS - GET INTERNAL NOTES
    case types.REVIEWS_GET_INTERNAL_NOTES_REQUEST:
      return {
        ...state,
        reviewsInternalNotes: {
          ...state.reviewsInternalNotes,
          isNotesFetching: action.payload.actionType === CommonAction.GET ? true : false,
          currentReviewId: action.payload.id
        }
      };
    case types.REVIEWS_GET_INTERNAL_NOTES_RESPONSE:
      const internalNotes = getUniqueReviewsInternalNotes([...state.reviewsInternalNotes.reviewsInternalNotesList, ...action.payload.notes]);
      return {
        ...state,
        reviewsList: action.payload.actionType === CommonAction.ADD ? getReviewsIntNotesCount(state.reviewsList, action.payload) : state.reviewsList,
        reviewsInternalNotes: {
          isNotesFetching: false,
          currentReviewId: 0,
          reviewsInternalNotesList: internalNotes
        }
      };
    case types.REVIEWS_GET_INTERNAL_NOTES_FAILURE:
      return {
        ...state,
        reviewsInternalNotes: initialState.reviewsInternalNotes,
        error: action.payload || 'Unexpected error'
      };
    // REVIEWS - ADD INTERNAL NOTE
    case types.REVIEWS_ADD_INTERNAL_NOTE_REQUEST:
      return {
        ...state
      };
    case types.REVIEWS_ADD_INTERNAL_NOTE_RESPONSE:
      return {
        ...state,
        messages: {
          ...state.messages,
          reviewsSuccessMsg: action.payload.id ? REVIEWS_ADD_INT_NOTES_SUCCESS_MSG : null
        }
      };
    case types.REVIEWS_ADD_INTERNAL_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    case types.REVIEWS_HANDLE_POST_REQUEST:
      return {
        ...state
      };
    case types.REVIEWS_HANDLE_POST_RESPONSE:
      return {
        ...state,
        reviewsList: getReviewsThatAreReplied(state.reviewsList, action.payload)
      };
    case types.REVIEWS_HANDLE_POST_FAILURE:
      return {
        ...state,
        error: action.payload || 'Unexpected error'
      };
    // REVIEWS - SEND FOR REPLY APPROVAL
    case types.REVIEWS_SEND_FOR_REPLY_APPROVAL_REQUEST:
      return {
        ...state,
        isSendReplyApprovalFetching: true
      };
    case types.REVIEWS_SEND_FOR_REPLY_APPROVAL_RESPONSE:
      return {
        ...state,
        isSendReplyApprovalFetching: false,
        reviewsList: getUpdatedReviewsList(state.reviewsList, action.payload),
        messages: {
          ...state.messages,
          reviewsSuccessMsg: action.payload.id ? REVIEWS_SEND_FOR_REPLY_APPROVAL_SUCCESS_MSG : null
        }
      };
    case types.REVIEWS_SEND_FOR_REPLY_APPROVAL_FAILURE:
      return {
        ...state,
        isSendReplyApprovalFetching: false,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // REVIEWS - UPDATE SUBMITTED REPLY
    case types.REVIEWS_UPDATE_SUBMITTED_REPLY_REQUEST:
      return {
        ...state,
        isEditReplyFetching: true
      };
    case types.REVIEWS_UPDATE_SUBMITTED_REPLY_RESPONSE:
      return {
        ...state,
        isEditReplyFetching: false,
        reviewsList: getUpdatedReviewsList(state.reviewsList, action.payload)
      };
    case types.REVIEWS_UPDATE_SUBMITTED_REPLY_FAILURE:
      return {
        ...state,
        isEditReplyFetching: false,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // REVIEWS - APPROVE REPLY
    case types.REVIEWS_APPROVE_REPLY_REQUEST:
      return {
        ...state,
        isApproveReplyFetching: true
      };
    case types.REVIEWS_APPROVE_REPLY_RESPONSE:
      return {
        ...state,
        isApproveReplyFetching: false,
        reviewsList: getUpdatedReviewsList(state.reviewsList, action.payload),
        messages: {
          ...state.messages,
          reviewsSuccessMsg: action.payload.id ? REVIEWS_ADD_REPLY_SUCCESS_MSG : null
        }
      };
    case types.REVIEWS_APPROVE_REPLY_FAILURE:
      return {
        ...state,
        isApproveReplyFetching: false,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // REVIEWS - REJECT REPLY
    case types.REVIEWS_REJECT_REPLY_REQUEST:
      return {
        ...state
      };
    case types.REVIEWS_REJECT_REPLY_RESPONSE:
      return {
        ...state,
        reviewsList: getUpdatedReviewsList(state.reviewsList, action.payload),
        messages: {
          ...state.messages,
          reviewsSuccessMsg: action.payload.id ? REVIEWS_REJECT_REPLY_SUCCESS_MSG : null
        }
      };
    case types.REVIEWS_REJECT_REPLY_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    // REVIEWS - DELETE REPLY FOR GOOGLE
    case types.REVIEWS_DELETE_REPLY_FOR_GOOGLE_REQUEST:
      return {
        ...state
      };
    case types.REVIEWS_DELETE_REPLY_FOR_GOOGLE_RESPONSE:
      return {
        ...state,
        reviewsList: getUpdatedReviewsList(state.reviewsList, action.payload)
      };
    case types.REVIEWS_DELETE_REPLY_FOR_GOOGLE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          reviewsFailureMsg: action.payload || 'Unexpected error'
        }
      };
    case types.REVIEWS_SET_INTERNAL_NOTES_LIST:
      return {
        ...state,
        reviewsInternalNotes: {
          ...state.reviewsInternalNotes,
          reviewsInternalNotesList: action.payload
        }
      };
    case types.REVIEWS_LIST_DATA_RESET:
      return {
        ...state,
        reviewsList: initialState.reviewsList,
        reviewStats: REVIEWS_INITIAL_STATS_VALUE
      };
    case types.REVIEWS_UPDATED_MSG_RESET:
      return {
        ...state,
        messages: {
          reviewsSuccessMsg: initialState.messages.reviewsSuccessMsg,
          reviewsFailureMsg: initialState.messages.reviewsFailureMsg
        }
      };
    case types.REVIEWS_RESET_ALL:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
