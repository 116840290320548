import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { commonErrorBoundary } from 'types';

const initialState: commonErrorBoundary.IErrorBoundary = {
  error: null
};

export const errorBoundaryReducer: Reducer<commonErrorBoundary.IErrorBoundary> = (state = initialState, action: any) => {
  switch (action.type) {
    case types.EXCEPTION_HANDLER_REQUEST:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};
