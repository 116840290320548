import moment from 'moment-timezone';
// import { SuggestionDataItem } from 'react-mentions';
import { MentionData } from '@draft-js-plugins/mention';
import { Tag } from 'react-tag-autocomplete';
import { EventSegment } from '@fullcalendar/core';
import { EditorState } from 'draft-js';

import {
  accountApiTypes,
  apAccountApiTypes,
  apAccountContainerTypes,
  apAccountReducerTypes,
  calendarApiTypes,
  calendarContainerTypes,
  fbAdsApiTypes,
  fbAdsReducerTypes,
  mgApiTypes,
  postsReducerTypes,
  overviewContainerType,
  apFranContainerTypes,
  postsApiTypes,
  accountContainerTypes
} from 'types';
import {
  ADD_POST_ACTION_TYPE,
  COMMON_SOCIAL_PROVIDER_TYPE,
  MG_CLOUD_MEDIA_TYPE,
  CreatorInstaPhotoType,
  USER_OWNERSHIP,
  DATE_FORMAT,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  CalendarViewTypes,
  COMMON_SOCIAL_MEDIA_NAME,
  OVERVIEW_MON_CHRT_LABLES,
  MG_MEDIA_TYPE,
  FbAdsConnectionsType,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  FB_CALL_TO_ACTION_SELECT_OPTIONS,
  socialMediaCallToActionCallOptions
} from 'utils/constants';
import {
  convertDateBasedOnTimeZone,
  calendarUTCtoLocalHandler,
  capitalizeName,
  createEmptyEditorState,
  convertStringToEditorState,
  getParsedDate,
  getCurrentDate,
  comparyTwoArrays,
  getContentTextWithMaximumLimit,
  getDateTimeByDayOfWeek,
  getBrowserLocalDateTime,
  canApproveScheduledPost,
  getValidFBTagPreview
} from 'utils/helpers';
// import { PLATFORM_FILTER } from 'analytics/utils';

/** Get valid custom text by matching tag name and replacing with their ID */
export const formattedCustomText = (postText: string, selectedTagList: MentionData[]) => {
  return selectedTagList.length
    ? selectedTagList.reduce((acc, curr) => {
        if (acc.includes(curr.name)) acc = acc.replace(curr.name, `@[${curr.id}]`);
        return acc;
      }, postText)
    : postText;
};

/** Get only ID and Name attributes for FB Tag list */
export const getFacebookPageTagList = (tagList: MentionData[]) => {
  return tagList.reduce((acc: MentionData[], curr) => {
    acc = [...acc, { id: curr.id, name: curr.name, link: curr.link }];
    return acc;
  }, []);
};

/** Add Post - Get multiple media cloudinary response */
export const getAPMediaUploadedInCloud = (currentResponse: mgApiTypes.IMGUploadCloudMediaResponse, previousCloudData: apAccountReducerTypes.IAddPostCloudData[]) => {
  let cloudResponseArray: apAccountReducerTypes.IAddPostCloudData[] = previousCloudData[0].cloudinaryId ? [...previousCloudData] : [];
  const cloudinaryData = {
    cloudinaryId: currentResponse.cloudinaryResponse.public_id,
    fileName: currentResponse.fileName,
    secureUrl: currentResponse.cloudinaryResponse.secure_url,
    resourceType: currentResponse.cloudinaryResponse.resource_type,
    tags: currentResponse.cloudinaryResponse.tags,
    eTag: currentResponse.cloudinaryResponse.etag,
    comments: null,
    mediumSizeUrl: currentResponse.cloudinaryResponse.resource_type === MG_MEDIA_TYPE.VIDEO ? currentResponse.cloudinaryResponse.secure_url || null : null
  };
  cloudResponseArray = [...cloudResponseArray, cloudinaryData];
  return cloudResponseArray.filter((filterDatum) => filterDatum.cloudinaryId);
};

export const getDataForFBTagList = (tagList: apAccountApiTypes.IAddPostFBTagListResponse[]) => {
  return tagList.reduce((acc: MentionData[], curr) => {
    const eachTagObj = {
      id: curr.id,
      name: curr.name,
      label: curr.name_with_location || curr.name,
      avatar: `https://graph.facebook.com/${curr.id}/picture?type=square`,
      link: curr.link
    };
    acc = [...acc, eachTagObj];
    return acc;
  }, []);
};

/** Add Post - Remove the tag list based on clearing custom text */
export const removeTagListBasedOnDataAvail = (selectedTagList: MentionData[], customText: null | string) => {
  return selectedTagList.reduce((acc: MentionData[], curr) => {
    if (customText?.includes(curr.name)) acc = [...acc, curr];
    return acc;
  }, []);
};

/** Add Post - Get preview social media list */
export const getPreviewSocialMediaList = (savedPostDetails: apAccountReducerTypes.IAPSavedPostDetails) => {
  return Object.entries(savedPostDetails)
    .map(([key, value]) => {
      if (value && key.includes('use_')) return key.replace('use_', '');
      else if (!value && key.includes('use_')) return '';
      else return '';
    })
    .filter((datum) => datum);
};

/** Add Post - Get accessible social media list */
export const getAccessibleSocialMediaList = (
  googleFeature: boolean,
  socialMediaList: accountApiTypes.ISocialMedia[],
  enabledPlatforms: accountApiTypes.IAccountProfileAccess | undefined,
  isYelpConnected: boolean
) => {
  const accountSocialMediaAccessObj = {
    platform_facebook_access: enabledPlatforms?.platform_facebook_access || false,
    platform_twitter_access: enabledPlatforms?.platform_twitter_access || false,
    platform_linkedin_access: enabledPlatforms?.platform_linkedin_access || false,
    platform_instagram_access: enabledPlatforms?.platform_instagram_access || false,
    platform_tiktok_access: enabledPlatforms?.platform_tiktok_access || false,
    platform_yelp_access: enabledPlatforms?.platform_yelp_access || false,
    gmb_feature: true
  };
  const availablePlatform = Object.entries(accountSocialMediaAccessObj).reduce((acc: string[], [key, value]) => {
    if (value) key.includes('platform') ? acc.push(key.replace(/platform_|_access/gi, '')) : acc.push(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT);
    return acc;
  }, []);
  // .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b));

  const filterPlatforms = availablePlatform
    .filter((platform) =>
      socialMediaList.some((socialMedia) =>
        ['instagram_business_page', 'instagram_public_content'].includes(socialMedia.provider_name)
          ? platform === 'instagram'
          : socialMedia.provider_name === 'instagram'
          ? false
          : socialMedia.provider_name === platform
      )
    )
    .reduce((acc: string[], curr) => {
      if (curr === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT) acc.push(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
      else acc.push(curr);
      return acc;
    }, []);
  return filterPlatforms;
};

/** Add Post - Get add content list */
export const getAddContentList = (savedPostDetails: apAccountReducerTypes.IAPSavedPostDetails, activePlatforms: string[]) => {
  return Object.entries(savedPostDetails)
    .map(([key, value]) => {
      if (value && key.includes('_text')) return key.replace('_text', '');
      else if (!value && key.includes('_text')) return '';
      else return '';
    })
    .filter((datum) => activePlatforms.includes(datum));
};

/** Add Post - Get valid instagram provider name */
export const getValidInstaProviderName = (socialMediaList: accountApiTypes.ISocialMedia[]) => {
  return socialMediaList.reduce((acc: string, curr) => {
    if (curr.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE) acc = `${curr.provider_name}`;
    else if (curr.provider_name !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE && curr.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM) acc = `${curr.provider_name}`;
    return acc;
  }, '');
};

/** Add post - Get calendar scheduled post list */
export const getCalendarAPScheduledPostList = (
  scheduledPostList: (calendarApiTypes.IGetScheduledPostResponse | calendarApiTypes.IGetScheduledMultiPostResponse)[],
  calendarView: string,
  timeZone: string
) => {
  return scheduledPostList.reduce((acc: calendarContainerTypes.ICalendarEventList[], curr) => {
    const dateFormatForSchPost: Date = timeZone
      ? convertDateBasedOnTimeZone(timeZone, curr.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT) || getCurrentDate()
      : curr?.post_now
      ? getBrowserLocalDateTime(curr.scheduled_for)
      : getParsedDate(calendarUTCtoLocalHandler(curr.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT)) || getCurrentDate();
    const postEventObj = {
      title: curr.use_facebook
        ? curr.personalized_facebook_text || curr.facebook_text
        : curr.use_twitter
        ? curr.personalized_twitter_text || curr.twitter_text
        : curr.use_instagram
        ? curr.personalized_instagram_text || curr.instagram_text
        : curr.use_linkedin
        ? curr.personalized_linkedin_text || curr.linkedin_text
        : curr.use_google
        ? curr.personalized_google_text || curr.google_text
        : curr.use_tiktok
        ? curr.personalized_tiktok_text || curr.tiktok_text
        : '',
      start: dateFormatForSchPost,
      end: dateFormatForSchPost,
      allDay: calendarView === CalendarViewTypes.DAY_VIEW ? false : true,
      ...curr
    };
    acc = [...acc, postEventObj];
    return acc;
  }, []);
};

/** Add post - Get Calendar Event List Array for Scheduled post -> location level */
export const getAPScheduledPostEventListForEvent = (scheduledPostList: any, calendarView: string, timeZone: string = '') => {
  if (scheduledPostList?.length) {
    return scheduledPostList.reduce((acc: calendarContainerTypes.ICalendarEventList[], curr: any) => {
      const dateFormatForSchPost: Date = convertDateBasedOnTimeZone(timeZone, curr.start_datetime, YEAR_MONTH_DAY_WITH_TIME_FORMAT) || new Date();
      const postEventObj = {
        title: curr.title,
        start: dateFormatForSchPost,
        end: dateFormatForSchPost,
        allDay: calendarView === CalendarViewTypes.DAY_VIEW ? false : true,
        isEvent: true,
        ...curr
      };
      acc = [...acc, postEventObj];
      return acc;
    }, []);
  } else {
    return [];
  }
};

/** Add post - Get initial upcoming days  */
export const ApInitialUpcomingDaysList = () => {
  const nextDay = moment().add(1, 'day');
  const endOfWeek = moment().add(1, 'week');

  const upcomingDays: apAccountContainerTypes.IAddPostUpcomingDays[] = [];
  let day = nextDay;

  while (day <= endOfWeek) {
    upcomingDays.push({
      day: day.startOf('day').format('dd'),
      date: day.startOf('day').format(DATE_FORMAT),
      dayNumber: 0,
      postCount: 0
    });
    day = day.add(1, 'day');
  }
  return upcomingDays;
};

/** Add post - Get upcoming days based on scheduled posts  */
export const ApUpcomingDaysSchPostList = (
  upcomingDays: apAccountContainerTypes.IAddPostUpcomingDays[],
  scheduledPostList: calendarApiTypes.IGetScheduledPostResponse[],
  tzInfoName: string,
  isHubUser: boolean,
  isFranchisee: boolean,
  overriddenCorporatePostsAutoApproved: boolean,
  overriddenCustomPostsLocked: boolean
) => {
  const scheduledPostDates = scheduledPostList.map((datum) => moment(datum.scheduled_for).tz(tzInfoName)?.startOf('day').format(DATE_FORMAT));
  return upcomingDays.reduce((acc: apAccountContainerTypes.IAddPostUpcomingDays[], curr, index) => {
    if (scheduledPostDates.includes(curr.date)) {
      const schPostsListOnCurrDate = scheduledPostList.filter((datum) => moment(datum.scheduled_for).tz(tzInfoName)?.startOf('day').format(DATE_FORMAT) === curr.date);
      const approvalPostsCount =
        schPostsListOnCurrDate.filter((post) => {
          return !isHubUser && post?.id ? canApproveScheduledPost(post.draft, post.corporate_post, isFranchisee, overriddenCorporatePostsAutoApproved, overriddenCustomPostsLocked) : false;
        })?.length || 0;
      acc = [
        ...acc,
        {
          ...curr,
          dayNumber: index + 1,
          postCount: schPostsListOnCurrDate.length,
          approvalPostsCount
        }
      ];
    } else {
      acc = [...acc, { ...curr, dayNumber: index + 1, postCount: 0 }];
    }
    return acc;
  }, []);
};

/** Add Post - Get post action request payload */
export const getApPostActionReqPayload = (
  currentAccountId: null | number,
  userOwnership: null | string,
  savedPostDetails: null | apAccountReducerTypes.IAPSavedPostDetails,
  fbTagsList: MentionData[],
  mediaList: mgApiTypes.IMGListResponseObj[],
  selectedTags: Tag[],
  linkData: null | calendarApiTypes.ICouponLinks,
  actionType: string,
  instaType: string,
  campaignData?: postsApiTypes.IPostListDetailData[],
  isAiPost?: boolean
): apAccountContainerTypes.IAPSavePostActionReqParams => {
  const multipleImagesList = mediaList.reduce((acc: string[], curr) => {
    if (curr.url && curr.media_type === MG_MEDIA_TYPE.PHOTO) acc = [...acc, curr.url];
    return acc;
  }, []);
  return {
    account_id: userOwnership === USER_OWNERSHIP.ACCOUNT ? `${currentAccountId}` : null,
    boost_offer_id: null,
    ...([ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(actionType) && campaignData?.length && { campaign_ids: campaignData.map((it) => +it.id) }),
    cloudinary_resource_type: mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? MG_CLOUD_MEDIA_TYPE.IMAGE : MG_CLOUD_MEDIA_TYPE.VIDEO,
    cloudinary_video_id: (mediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && mediaList[0]?.cloudinary_id) || null,
    created_at: null,
    end_date: null,
    link_preview_description: linkData?.link_preview_description ? linkData?.link_preview_description : '',
    link_preview_image_url: savedPostDetails?.is_thumbnail_show && linkData?.link_preview_image_url ? linkData?.link_preview_image_url : '',
    link_preview_image_url_choices: linkData?.link_preview_image_url_choices.length ? linkData?.link_preview_image_url_choices : [],
    link_preview_title: linkData?.link_preview_title ? linkData?.link_preview_title : '',
    link_preview_customized: savedPostDetails?.link_preview_customized || false,
    locked: savedPostDetails?.locked || false,
    mobile_app_only_enabled: savedPostDetails?.mobile_app_only_enabled || false,
    page_tag_details: {
      ...(savedPostDetails?.use_facebook && fbTagsList.length && { facebook: getFacebookPageTagList(fbTagsList || []) })
    },
    photo_urls: mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? multipleImagesList : [],
    pad_instagram_photos: instaType === CreatorInstaPhotoType.PAD ? true : false,
    rejected: false,
    start_date: null,
    status_id:
      actionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
        ? `${3}`
        : actionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
        ? `${2}`
        : actionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
        ? `${1}`
        : null,
    syndicated_from_content_id: null,
    tags_list: selectedTags.map((dataItem) => dataItem.name).join(),
    trashed: false,
    updated_at: null,
    url: linkData?.url || '',
    facebook_text: savedPostDetails?.use_facebook ? formattedCustomText(savedPostDetails?.facebook_text || '', fbTagsList || []) || '' : '',
    google_text: savedPostDetails?.use_google ? savedPostDetails?.google_text || '' : '',
    instagram_text: savedPostDetails?.use_instagram ? savedPostDetails?.instagram_text || '' : '',
    linkedin_text: savedPostDetails?.use_linkedin ? savedPostDetails?.linkedin_text || '' : '',
    twitter_text: savedPostDetails?.use_twitter
      ? getContentTextWithMaximumLimit(`${savedPostDetails?.twitter_text}${linkData?.url ? ` ${linkData?.url}` : ''}` || '', COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
      : '',
    tiktok_text: savedPostDetails?.use_tiktok ? savedPostDetails?.tiktok_text || '' : '',

    use_facebook: savedPostDetails?.use_facebook || false,
    use_google: savedPostDetails?.use_google || false,
    use_instagram: savedPostDetails?.use_instagram || false,
    use_linkedin: savedPostDetails?.use_linkedin || false,
    use_twitter: savedPostDetails?.use_twitter || false,
    use_tiktok: savedPostDetails?.use_tiktok || false,
    user_id: null,
    instagram_reel: savedPostDetails?.instagram_reel || false,
    facebook_reel: savedPostDetails?.facebook_reel || false,
    ai_post: isAiPost ? true : false,
    video_cover_cloudinary_id: mediaList?.[0]?.cover_cloudinary_id || savedPostDetails?.video_cover_cloudinary_id || null,
    ...(savedPostDetails?.use_google && {
      google_call_to_action_type: GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === savedPostDetails.google_call_to_action_type)?.value || null,
      google_call_to_action_url: savedPostDetails.google_call_to_action_url || null
    }),
    ...(savedPostDetails?.use_facebook && {
      facebook_call_to_action_type: FB_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === savedPostDetails.facebook_call_to_action_type)?.value || null,
      facebook_call_to_action_link: savedPostDetails.facebook_call_to_action_type
        ? socialMediaCallToActionCallOptions.includes(savedPostDetails.facebook_call_to_action_type)
          ? `tel:${savedPostDetails.facebook_call_to_action_link}`
          : savedPostDetails.facebook_call_to_action_link
        : null
    }),
    facebook_story: savedPostDetails?.facebook_story || false,
    instagram_story: savedPostDetails?.instagram_story || false
  };
};

/** Add Post - Update post action request payload */
export const getApUpdatePostActionReqPayload = (
  editSavedPost: postsReducerTypes.IPostDetailsData,
  savedPostDetails: null | apAccountReducerTypes.IAPSavedPostDetails,
  fbTagsList: MentionData[],
  mediaList: mgApiTypes.IMGListResponseObj[],
  selectedTags: Tag[],
  linkData: null | calendarApiTypes.ICouponLinks,
  actionType: string,
  instaType: string,
  fbEditorState: EditorState,
  campaignData?: postsApiTypes.IPostListDetailData[],
  isPostEditedForApproval?: boolean,
  isAiPost?: boolean
): apAccountContainerTypes.IAPSavePostActionReqParams => {
  const multipleImagesList = mediaList.reduce((acc: string[], curr) => {
    if (curr.url && curr.media_type === MG_MEDIA_TYPE.PHOTO) acc = [...acc, curr.url];
    return acc;
  }, []);
  return {
    account_id: editSavedPost?.account?.id ? `${editSavedPost?.account?.id}` : null,
    boost_offer_id: null,
    ...([ADD_POST_ACTION_TYPE.SINGLE_CAMPAIGN_POST, ADD_POST_ACTION_TYPE.MULTIPLE_CAMPAIGN_POSTS].includes(actionType) && campaignData?.length && { campaign_ids: campaignData.map((it) => +it.id) }),
    cloudinary_resource_type: mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? MG_CLOUD_MEDIA_TYPE.IMAGE : MG_CLOUD_MEDIA_TYPE.VIDEO,
    cloudinary_video_id: (mediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && mediaList[0]?.cloudinary_id) || null,
    created_at: editSavedPost?.created_at || null,
    end_date: null,
    link_preview_description: linkData?.link_preview_description ? linkData?.link_preview_description : '',
    link_preview_image_url: savedPostDetails?.is_thumbnail_show && linkData?.link_preview_image_url ? linkData?.link_preview_image_url : '',
    link_preview_image_url_choices: linkData?.link_preview_image_url_choices.length ? linkData?.link_preview_image_url_choices : [],
    link_preview_title: linkData?.link_preview_title ? linkData?.link_preview_title : '',
    link_preview_customized: savedPostDetails?.link_preview_customized || false,
    locked: savedPostDetails?.locked || false,
    mobile_app_only_enabled: savedPostDetails?.mobile_app_only_enabled || false,
    page_tag_details: {
      ...(savedPostDetails?.use_facebook && fbTagsList.length && { facebook: getFacebookPageTagList(fbTagsList || []) })
    },
    photo_urls: mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? multipleImagesList : [],
    pad_instagram_photos: instaType === CreatorInstaPhotoType.PAD ? true : false,
    rejected: actionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST ? (isPostEditedForApproval ? false : editSavedPost?.rejected || false) : false,
    start_date: null,
    status_id:
      actionType === ADD_POST_ACTION_TYPE.UPDATE_SAVED_POST && editSavedPost
        ? `${editSavedPost.status_id}`
        : actionType === ADD_POST_ACTION_TYPE.POST_NOW
        ? `${3}`
        : actionType === ADD_POST_ACTION_TYPE.SAVE_TO_DATABASE
        ? `${3}`
        : actionType === ADD_POST_ACTION_TYPE.SEND_FOR_APPROVAL
        ? `${2}`
        : actionType === ADD_POST_ACTION_TYPE.SAVE_AS_DRAFT
        ? `${1}`
        : isPostEditedForApproval
        ? `${2}`
        : null,
    syndicated_from_content_id: editSavedPost?.syndicated_from_content_id || null,
    tags_list: selectedTags.map((dataItem) => dataItem.name).join(),
    trashed: false,
    updated_at: editSavedPost?.updated_at || null,
    url: linkData?.url || '',
    facebook_text: savedPostDetails?.use_facebook ? getValidFBTagPreview(fbEditorState) || '' : '',
    google_text: savedPostDetails?.use_google ? savedPostDetails?.google_text || '' : '',
    instagram_text: savedPostDetails?.use_instagram ? savedPostDetails?.instagram_text || '' : '',
    linkedin_text: savedPostDetails?.use_linkedin ? savedPostDetails?.linkedin_text || '' : '',
    twitter_text: savedPostDetails?.use_twitter
      ? getContentTextWithMaximumLimit(`${savedPostDetails?.twitter_text}${linkData?.url ? ` ${linkData?.url}` : ''}` || '', COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
      : '',
    tiktok_text: savedPostDetails?.use_tiktok ? savedPostDetails?.tiktok_text || '' : '',
    use_facebook: savedPostDetails?.use_facebook || false,
    use_google: savedPostDetails?.use_google || false,
    use_instagram: savedPostDetails?.use_instagram || false,
    use_linkedin: savedPostDetails?.use_linkedin || false,
    use_twitter: savedPostDetails?.use_twitter || false,
    use_tiktok: savedPostDetails?.use_tiktok || false,
    user_id: editSavedPost?.user_id || null,
    instagram_reel: savedPostDetails?.instagram_reel || false,
    facebook_reel: savedPostDetails?.facebook_reel || false,
    ai_post: isAiPost ? true : false,
    video_cover_cloudinary_id: mediaList?.[0]?.cover_cloudinary_id || savedPostDetails?.video_cover_cloudinary_id || null,
    ...(savedPostDetails?.use_facebook && {
      facebook_call_to_action_type: FB_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === savedPostDetails?.facebook_call_to_action_type)?.value || null,
      facebook_call_to_action_link: savedPostDetails.facebook_call_to_action_type
        ? socialMediaCallToActionCallOptions.includes(savedPostDetails.facebook_call_to_action_type)
          ? `tel:${savedPostDetails.facebook_call_to_action_link}`
          : savedPostDetails.facebook_call_to_action_link
        : null
    }),
    ...(savedPostDetails?.use_google && {
      google_call_to_action_type: GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((selectOption) => selectOption.value === savedPostDetails?.google_call_to_action_type)?.value || null,
      google_call_to_action_url: savedPostDetails?.google_call_to_action_url || null,
      facebook_story: savedPostDetails?.facebook_story || null
    }),
    facebook_story: savedPostDetails?.facebook_story || false,
    instagram_story: savedPostDetails?.instagram_story || false
  };
};

/** View Calendar - Get show more scheduled posts */
export const getShowMoreSchPostsList = (scheduledPosts: EventSegment[] | any, timeZone: string) => {
  return scheduledPosts.reduce((acc: calendarContainerTypes.ICalendarEventList[], curr: any) => {
    const parsedDate = timeZone
      ? convertDateBasedOnTimeZone(timeZone, curr.event._def.extendedProps.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT)
      : new Date(calendarUTCtoLocalHandler(curr.event._def.extendedProps.scheduled_for, YEAR_MONTH_DAY_WITH_TIME_FORMAT)) || new Date();
    acc = [
      ...acc,
      {
        ...curr.event._def.extendedProps,
        id: +curr.event._def.publicId,
        title: curr.event._def.title,
        start: parsedDate || '',
        end: parsedDate || ''
      }
    ];
    return acc;
  }, []);
};

/** Add post - Get facebook boost config data initially */
export const getFacebookBoostConfigData = (
  facebooKTargetChoice: null | fbAdsApiTypes.IFBAdsFranChoicesResponse | fbAdsApiTypes.IFBAdsAccChoicesResponse
): null | fbAdsReducerTypes.IUpdateFacebookBoostConfig => {
  const defaultConfiguration = facebooKTargetChoice && `default_configuration` in facebooKTargetChoice ? facebooKTargetChoice.default_configuration : null;
  return defaultConfiguration
    ? {
        isAddBoost: false,
        connections: defaultConfiguration.connections,
        coordinates: defaultConfiguration.coordinates,
        duration: defaultConfiguration.duration,
        gender: defaultConfiguration.gender,
        homeCoordinate: defaultConfiguration.home_coordinate,
        interests: defaultConfiguration.interests,
        lifetimeSpend: defaultConfiguration.lifetime_spend / 100,
        locations: defaultConfiguration.locations,
        maxAge: defaultConfiguration.max_age,
        minAge: defaultConfiguration.min_age,
        objective: defaultConfiguration.objective,
        potentialReach: 0,
        facebookDarkPost: false
      }
    : null;
};

/** Add post - Get boost data for scheduled post request payload */
export const getAddBoostForScheduledPostReqPayload = (
  scheduledPostId: number,
  facebookBoostConfig: fbAdsReducerTypes.IUpdateFacebookBoostConfig
): apAccountApiTypes.IAPAddBoostForSchPostReqPayload => {
  return {
    scheduled_post_id: `${scheduledPostId}`,
    facebook_boost_configuration: {
      // connections: facebookBoostConfig.connections,
      connections: FbAdsConnectionsType.ALL_CONNECTIONS,
      coordinates: facebookBoostConfig.coordinates,
      duration: facebookBoostConfig.duration,
      gender: facebookBoostConfig.gender,
      home_coordinate: facebookBoostConfig.homeCoordinate,
      interests: facebookBoostConfig.interests,
      lifetime_spend: facebookBoostConfig.lifetimeSpend,
      locations: facebookBoostConfig.locations,
      max_age: facebookBoostConfig.maxAge,
      min_age: facebookBoostConfig.minAge,
      objective: facebookBoostConfig.objective
    }
  };
};

/** Get modified status for saved post */
export const getModifiedStatusForSavedPost = (
  savedPostDetails: apAccountReducerTypes.IAPSavedPostDetails,
  editedSavedPost: postsReducerTypes.IPostDetailsData,
  mediaList: mgApiTypes.IMGListResponseObj[],
  linkData: null | calendarApiTypes.ICouponLinks,
  selectedTags: Tag[],
  campaignData: postsApiTypes.IPostListDetailData[]
): boolean => {
  if (
    savedPostDetails.facebook_text !== editedSavedPost?.facebook_text ||
    savedPostDetails.instagram_text !== editedSavedPost?.instagram_text ||
    savedPostDetails.twitter_text !== editedSavedPost?.twitter_text ||
    savedPostDetails.linkedin_text !== editedSavedPost?.linkedin_text ||
    savedPostDetails.google_text !== editedSavedPost?.google_text
  ) {
    return true;
  } else if (
    savedPostDetails.use_facebook !== editedSavedPost?.use_facebook ||
    savedPostDetails.use_instagram !== editedSavedPost?.use_instagram ||
    savedPostDetails.use_twitter !== editedSavedPost?.use_twitter ||
    savedPostDetails.use_linkedin !== editedSavedPost?.use_linkedin ||
    savedPostDetails.use_google !== editedSavedPost?.use_google ||
    savedPostDetails.use_tiktok !== editedSavedPost?.use_tiktok
  ) {
    return true;
  } else if (mediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO && editedSavedPost?.photo_urls?.length !== mediaList.length) return true;
  else if (mediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && editedSavedPost?.video_url !== mediaList[0]?.url) return true;
  else if (!mediaList.length && (editedSavedPost?.photo_urls?.length || editedSavedPost?.video_url)) return true;
  else if (editedSavedPost?.url !== (linkData?.url || '')) return true;
  else if (savedPostDetails.mobile_app_only_enabled !== editedSavedPost?.mobile_app_only_enabled) return true;
  else if (savedPostDetails.locked !== editedSavedPost?.locked) return true;
  else if (selectedTags.map((dataItem) => dataItem.name).join() !== editedSavedPost?.tags_list) return true;
  else if (
    comparyTwoArrays(
      savedPostDetails?.campaigns?.map((it) => +it.id) || [],
      campaignData.map((it) => +it.id)
    )
  ) {
    return true;
  } else if (savedPostDetails.instagram_reel !== editedSavedPost?.instagram_reel) return true;
  else if (savedPostDetails.facebook_reel !== editedSavedPost?.facebook_reel) return true;
  else if (savedPostDetails.facebook_story !== editedSavedPost?.facebook_story) return true;
  else if (savedPostDetails.instagram_story !== editedSavedPost?.instagram_story) return true;
  return false;
};

/** Get updated link as hyperlink in saved post */
export const getUpdatedLinkInSavedPost = (activeSocialMedia: string[], savedPostDetails: null | apAccountReducerTypes.IAPSavedPostDetails, linkData: calendarApiTypes.ICouponLinks) => {
  return savedPostDetails
    ? {
        ...savedPostDetails,
        ...(linkData?.url && {
          ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) && {
            facebook_text: savedPostDetails?.facebook_text ? `${savedPostDetails.facebook_text} ${linkData?.url}` : `${linkData?.url}`
          }),
          ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER) && {
            twitter_text: savedPostDetails?.twitter_text ? `${savedPostDetails.twitter_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER) : `${linkData?.url}`
          }),
          ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN) && {
            linkedin_text: savedPostDetails?.linkedin_text ? `${savedPostDetails.linkedin_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN) : `${linkData?.url}`
          }),
          ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE) && {
            google_text: savedPostDetails?.google_text ? `${savedPostDetails.google_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE) : `${linkData?.url}`
          })
        }),
        facebook_call_to_action_type: null
      }
    : null;
};

/** Get updated link as hyperlink in content post text editor */
export const getUpdatedLinkTextInSavedPost = (
  activeSocialMedia: string[],
  savedPostDetails: null | apAccountReducerTypes.IAPSavedPostDetails,
  linkData: calendarApiTypes.ICouponLinks
): apFranContainerTypes.ICreatorEditorStateTextObj => {
  return {
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)
      ? {
          facebook: savedPostDetails?.facebook_text ? convertStringToEditorState(`${savedPostDetails?.facebook_text} ${linkData?.url}`) : convertStringToEditorState(linkData?.url)
        }
      : { facebook: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM)
      ? {
          instagram: savedPostDetails?.instagram_text
            ? convertStringToEditorState(`${savedPostDetails?.instagram_text}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM))
            : createEmptyEditorState()
        }
      : { instagram: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER)
      ? {
          twitter: savedPostDetails?.twitter_text
            ? convertStringToEditorState(`${savedPostDetails?.twitter_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER))
            : convertStringToEditorState(linkData?.url)
        }
      : { twitter: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN)
      ? {
          linkedin: savedPostDetails?.linkedin_text
            ? convertStringToEditorState(`${savedPostDetails?.linkedin_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN))
            : convertStringToEditorState(linkData?.url)
        }
      : { linkedin: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)
      ? {
          google: savedPostDetails?.google_text
            ? convertStringToEditorState(`${savedPostDetails?.google_text} ${linkData?.url}`.slice(0, ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE))
            : convertStringToEditorState(linkData?.url)
        }
      : { google: createEmptyEditorState() }),
    ...(activeSocialMedia.includes(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK)
      ? {
          tiktok: savedPostDetails?.tiktok_text ? convertStringToEditorState(`${savedPostDetails?.tiktok_text} ${linkData?.url}`) : convertStringToEditorState(linkData?.url)
        }
      : { tiktok: createEmptyEditorState() })
  };
};

/** Get add content list for dropdown */
export const getContentListForDropdown = (addContentList: string[]) => {
  return addContentList.reduce((acc: overviewContainerType.IOverviewLocationDropDown[], curr, index) => {
    acc = [
      ...acc,
      {
        id: index + 1,
        value: curr,
        label:
          curr === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
            ? OVERVIEW_MON_CHRT_LABLES.LINKEDIN
            : curr === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
            ? COMMON_SOCIAL_MEDIA_NAME.GOOGLE_MY_BUSINESS
            : curr === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
            ? COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM_BUSINESS
            : curr === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
            ? COMMON_SOCIAL_MEDIA_NAME.X
            : capitalizeName(curr)
      }
    ];
    return acc;
  }, []);
};

export const creatorPhotosCount = (mediaList: mgApiTypes.IMGListResponseObj[]) => {
  return mediaList.filter((it) => it.media_type === MG_MEDIA_TYPE.PHOTO).length;
};

export const creatorHasVideo = (mediaList: mgApiTypes.IMGListResponseObj[]) => {
  return Boolean(mediaList.find((it) => it.media_type === MG_MEDIA_TYPE.VIDEO)?.cloudinary_id || '');
};

export const getAccValidSocialMediaForCreator = (accountProfiles: accountApiTypes.IAccountProfile[], gmbFeatureEnabled: boolean) => {
  const socialMediaList: string[] = [];
  if (accountProfiles?.length) {
    const accountProfileData = accountProfiles[0];
    const access = accountProfiles[0]?.access;
    if (accountProfileData?.has_facebook_medium && access?.platform_facebook_access) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
    }
    if (
      // (accountProfileData?.has_instagram_medium || accountProfileData?.has_instagram_business_page_medium || accountProfileData?.has_instagram_hootsuite_medium) &&
      (accountProfileData?.has_instagram_business_page_medium || accountProfileData?.has_instagram_hootsuite_medium) &&
      access?.platform_instagram_access
    ) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM);
    }
    if (accountProfileData?.has_twitter_medium && access?.platform_twitter_access) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.TWITTER);
    }
    if (accountProfileData?.has_linkedin_medium && access?.platform_linkedin_access) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN);
    }
    if (accountProfileData?.has_tiktok_medium && access?.platform_tiktok_access) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK);
    }

    if (accountProfileData?.has_google_plus_medium && gmbFeatureEnabled) {
      socialMediaList.push(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE);
    }
  }
  return socialMediaList;
};

// scheduler time slot conversion for calendar
export const convertScheduleSlotIntoEventObj = (scheduleSlotList: accountApiTypes.ICreateAccListScheduleReq[]) => {
  return scheduleSlotList.reduce((acc: accountContainerTypes.IScheduleCalendarSlotRes[], curr) => {
    const slotMin = curr.minute < 10 ? `0${curr.minute}` : curr.minute;
    const title = `${curr?.account_list_name ? curr?.account_list_name : 'Any'}`;

    const startDateFormat = new Date(`${getDateTimeByDayOfWeek(curr.day_of_week)} ${curr.hour}:${slotMin}:00`);
    const endDateFormat = new Date(`${getDateTimeByDayOfWeek(curr.day_of_week)} ${curr.hour + 1}:${slotMin}:00`);

    const postEventObj: any = {
      ...curr,
      id: curr?.id,
      title,
      start: startDateFormat,
      end: endDateFormat,
      allDay: false,
      start_date: curr?.start_date ? getBrowserLocalDateTime(curr?.start_date) : null,
      end_date: curr?.end_date ? getBrowserLocalDateTime(curr?.end_date) : null
    };
    acc = [...acc, postEventObj];
    return acc;
  }, []);
};
