import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { commonAccountSwitcher } from 'types/common';
import { IAPGetAccountListResponse } from 'types/add-post-franchisor/ap-franchisor-api';

// ACCOUNT SWITCHER
export const accountSwitcherRequest = (data: commonAccountSwitcher.IAccountSwitcherRequest) => createAction(actionTypes.ACCOUNT_SWITCHER_REQUEST, data);
export const accountSwitcherResponse = (data: commonAccountSwitcher.IAccountSwitcherResponseData) => createAction(actionTypes.ACCOUNT_SWITCHER_RESPONSE, data);
export const accountSwitcherRequestFail = (error: string | null) => createAction(actionTypes.ACCOUNT_SWITCHER_REQUEST_FAIL, error);

// USER OWNERSHIP DATA FROM ACCOUNT SWITCHER
export const userOwnerShipResponse = (data: commonAccountSwitcher.IAccountSwitcherRequest) => createAction(actionTypes.USER_OWNERSHIP, data);

export const accountSwitcherDataReset = () => createAction(actionTypes.ACCOUNT_SWITCHER_DATA_RESET);

// GET LOCATION FILTER DATA
export const locationSelectorRequest = (data: number) => createAction(actionTypes.LOCATION_SELECTOR_LIST_REQUEST, data);
export const locationSelectorResponse = (data: IAPGetAccountListResponse) => createAction(actionTypes.LOCATION_SELECTOR_LIST_RESPONSE, data);
export const locationSelectorFail = (error: string | null) => createAction(actionTypes.LOCATION_SELECTOR_LIST_FAILURE, error);

// ONLY SET MENU ACCESS
export const setMenuAccess = (data: commonAccountSwitcher.IAccountSwitcherMenuAccess) => createAction(actionTypes.SET_MENU_ACCESS, data);

// REVV
export const updateRevvState = (data: boolean) => createAction(actionTypes.UPDATE_REVV_STATE, data);

const ACCOUNT_SWITCHER_ACTIONS = {
  accountSwitcherRequest,
  accountSwitcherResponse,
  accountSwitcherRequestFail,
  userOwnerShipResponse,
  accountSwitcherDataReset,
  locationSelectorRequest,
  locationSelectorResponse,
  locationSelectorFail,
  setMenuAccess,
  updateRevvState
};

export type AccountSwitcherActions = ActionsUnion<typeof ACCOUNT_SWITCHER_ACTIONS>;
