import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { SchedulerDetails } from './SubPages';
import {
  accountActivateRequest,
  accountGetScheduleListRequest,
  accountProfileReset,
  getLocationsHubListRequest,
  rallioProfileResetAll,
  reloadList,
  setFranchisorIds,
  socialProfileResetAll
} from 'actions';
import { IStore, commonProps } from 'types';
import { CAMPAIGN_WEEKLY_SCHEDULE_FROM, CONNECTION_TABS, FEED_TYPE, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { Loading } from 'components';

export const Scheduler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams, isAdminScreenEnabled } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id || null);
  const activateAccount = useSelector((state: IStore) => state.accounts.activateAccount);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin);
  const isAccountActivateFetching = useSelector((state: IStore) => state.accounts.isAccountActivateFetching);
  const selectedFranchisorId = useSelector((state: IStore) => state.adminArea.selectedFranchisorId);
  const isSchedulerSlotListFetching = useSelector((state: IStore) => state.accounts.isScheduleSlotListFetching);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher.content_supplier_franchisor);

  useEffect(() => {
    if (optionalParams[2] === CONNECTION_TABS.SCHEDULER && accountId) {
      dispatch(accountGetScheduleListRequest({ userOwnership, id: accountId }));
      dispatch(
        getLocationsHubListRequest({ [`${USER_OWNERSHIP.ACCOUNT}_id`]: accountId, feed_type: FEED_TYPE.CONTENT_SUPPLIER, from: CAMPAIGN_WEEKLY_SCHEDULE_FROM.SCHEDULER, account_included: true })
      );
    } else {
      dispatch(accountGetScheduleListRequest({ userOwnership, id }));
      dispatch(
        getLocationsHubListRequest({
          [`${userOwnership}_id`]: id,
          from: CAMPAIGN_WEEKLY_SCHEDULE_FROM.SCHEDULER,
          ...(userOwnership === USER_OWNERSHIP.ACCOUNT && { account_included: true }),
          ...(isContentSupplierFranchisor && { feed_type: FEED_TYPE.CONTENT_SUPPLIER })
        })
      );
    }
  }, [dispatch, accountId, isContentSupplierFranchisor, id]); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(reloadList());
    };
  }, [dispatch, accountId]);

  const handleURLPathname = (route: string, search?: commonProps.ICommonObj<string>) => {
    if (isAdmin && isAdminScreenEnabled) navigate({ pathname: route, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
    else navigate({ pathname: `/${userOwnership}/${id.toString()}${route}`, search: `?${new URLSearchParams(search ? { ...queryParams, ...search } : queryParams).toString()}` });
  };

  const handleCancel = () => {
    dispatch(rallioProfileResetAll());
    if (isAdminScreenEnabled) {
      if (selectedFranchisorId) dispatch(setFranchisorIds(null));
      dispatch(accountProfileReset());
      dispatch(socialProfileResetAll());
    }
    handleURLPathname(`/${currentRoute}`, { ...queryParams });
  };

  const handleNavigateToCalendarPage = () => {
    if (accountId) {
      dispatch(
        accountActivateRequest({
          id: accountId
          /* onNavigate: (currentAccountId) =>
            navigate({
              pathname: `/${USER_OWNERSHIP.ACCOUNT}/${currentAccountId.toString()}/content/calendar`,
              search: `?${new URLSearchParams({ ...queryParams, ...{ view: 'calendar' } }).toString()}`
            }) */
        })
      );
      navigate({ pathname: `/${userOwnership}/${id.toString()}/content/calendar` }, { replace: true });
    }
  };

  return isSchedulerSlotListFetching ? (
    <Loading />
  ) : (
    <>
      <SchedulerDetails />
      {optionalParams[2] === CONNECTION_TABS.SCHEDULER && (
        <div className="stg-item form-configure r-mr0">
          <div className="modal-btn-grp-wraps csp-action ap-actions cn-social-pr">
            {activateAccount ? null : (
              <CustomRippleButton rippleClass={`ripple-unset ac-secondary-box edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline size-sm border-0" disabled={isAccountActivateFetching} onClick={handleCancel}>
                  Cancel
                </button>
              </CustomRippleButton>
            )}
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button
                className="ac-btn ac-primary-white ac-outline size-sm"
                disabled={isAccountActivateFetching}
                onClick={() => {
                  handleURLPathname(`/${currentRoute}/account_wizard/${accountId?.toString()}/${CONNECTION_TABS.FEED}`);
                }}
              >
                Back
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ripple-unset ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
              <button className="ac-btn ac-primary size-sm" disabled={isAccountActivateFetching} onClick={handleNavigateToCalendarPage}>
                {isAccountActivateFetching ? <Spinner animation="border" variant="light" /> : 'Finish'}
              </button>
            </CustomRippleButton>
          </div>
        </div>
      )}
    </>
  );
};
