import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

export const navToggle = () => createAction(actionTypes.NAV_TOGGLE);

export const resetPageState = (stateName: string) => createAction(actionTypes.RESET_PAGE_STATE, stateName);

export const resetPageNumber = (stateName: string) => createAction(actionTypes.RESET_PAGE_NUMBER, stateName);

export const errorPage = (payload: boolean) => createAction(actionTypes.ERROR_PAGE, payload);

export const setLayoutMode = (payload: boolean) => createAction(actionTypes.LAYOUT_MODE, payload);

export const setNonPSPFilterState = () => createAction(actionTypes.NON_PSP_FILTER_TOGGLE);

export const setLayoutRendered = (payload: boolean) => createAction(actionTypes.SET_LAYOUT_RENDERED, payload);

export const revvAccountSwitcherRequest = (payload: { franchisorId: number }) => createAction(actionTypes.REVV_ACCOUNT_SWITCHER_REQUEST, payload);
export const revvAccountSwitcherResponse = (payload: boolean) => createAction(actionTypes.REVV_ACCOUNT_SWITCHER_RESPONSE, payload);
export const revvAccountSwitcherFailure = (payload: string) => createAction(actionTypes.REVV_ACCOUNT_SWITCHER_FAILURE, payload);

export const COMMON_ACTIONS = {
  navToggle,
  resetPageState,
  errorPage,
  resetPageNumber,
  setLayoutMode,
  setNonPSPFilterState,
  setLayoutRendered,
  revvAccountSwitcherRequest,
  revvAccountSwitcherResponse,
  revvAccountSwitcherFailure
};

export type CommonActions = ActionsUnion<typeof COMMON_ACTIONS>;
