import { commonProps } from 'types';

export const COUPONS_DATE_RANGE_NAME = {
  START: 'start',
  EXPIRED: 'expired'
};

export enum CouponsStateName {
  ALL = 'all',
  ACTIVE = '1',
  INACTIVE = '0'
}

export enum CouponsStateLabel {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export const COUPONS_STATE_DATA: commonProps.IMainPageRouteData[] = [
  { name: CouponsStateName.ALL, label: CouponsStateLabel.ALL },
  { name: CouponsStateName.ACTIVE, label: CouponsStateLabel.ACTIVE },
  { name: CouponsStateName.INACTIVE, label: CouponsStateLabel.INACTIVE }
];

export const COUPON_TABLE_HEADERS = [
  {
    name: 'Location',
    key: 'location'
  },
  {
    name: 'Spend',
    key: 'spend'
  },
  {
    name: 'Views',
    key: 'views'
  },
  {
    name: 'Claimed',
    key: 'claimed'
  },
  {
    name: 'Redeemed',
    key: 'redeemed'
  },
  {
    name: 'Revenue',
    key: 'revenue'
  },
  {
    name: 'ROI',
    key: 'roi'
  }
];
