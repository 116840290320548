import { takeLatest, put } from 'redux-saga/effects';

import * as types from 'analytics/actions/action-types';
import { getOverviewAnalyticsFailure, getOverviewAnalyticsResponse, getOverviewAnalyticsSummaryResponse, getOverviewAnalyticsSummaryFailure } from 'analytics/actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { commonApiTypes } from 'types';
import { exceptionHandlerRequest, logoutRequest } from 'actions';
import { SOCIAL_ANALYTICS_FAILED, SOCIAL_ANALYTICS_FETCHING_LIST_FAILED } from 'analytics/utils';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* getOverviewAnalyticsRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;

    const headers = defaultHeader();
    const path = API.getSocialAnalytics.apiPath;
    const response = yield apiCall({ headers, params: payload, apiPath: path, action: API.getSocialAnalytics.action });

    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(getOverviewAnalyticsResponse(response.data));
    } else {
      yield put(getOverviewAnalyticsFailure(response?.data?.error || SOCIAL_ANALYTICS_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SOCIAL_ANALYTICS_FAILED));
  }
}

function* getOverviewAnalyticsSummaryRequest(requestDetails: ISagaPayload): any {
  try {
    const { payload } = requestDetails;
    const params = { ...payload };
    if (params?.id) delete params?.id;
    const headers = defaultHeader();
    const apiObj = payload?.id ? { ...API.getCSSocialAnalyticsSummary } : { ...API.getSocialAnalyticsSummary };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const summaryResponse = yield apiCall({ headers, params, ...apiObj });

    if (summaryResponse.status === 401) {
      yield put(logoutRequest());
    } else if (summaryResponse.status === 200) {
      yield put(getOverviewAnalyticsSummaryResponse(summaryResponse.data));
      if (payload?.id) yield put(getOverviewAnalyticsResponse(summaryResponse.data));
    } else {
      yield put(getOverviewAnalyticsSummaryFailure(summaryResponse?.data?.error || SOCIAL_ANALYTICS_FETCHING_LIST_FAILED));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SOCIAL_ANALYTICS_FAILED));
  }
}

export function* takeOverviewAnalyticsRequest() {
  yield takeLatest(types.GET_OVERVIEW_ANALYTICS_REQUEST, getOverviewAnalyticsRequest);
}

export function* takeOverviewAnalyticsSummaryRequest() {
  yield takeLatest(types.GET_OVERVIEW_ANALYTICS_SUMMARY_REQUEST, getOverviewAnalyticsSummaryRequest);
}
