// Dashboard Survey stats request action types
export const DASHBOARD_SURVEY_STATS_REQUEST = 'dashboard/DASHBOARD_SURVEY_STATS_REQUEST';
export const DASHBOARD_SURVEY_STATS_FAILURE = 'dashboard/DASHBOARD_SURVEY_STATS_FAILURE';
export const DASHBOARD_SURVEY_STATS_RESPONSE = 'dashboard/DASHBOARD_SURVEY_STATS_RESPONSE';

// Dashboard Reviews stats action types
export const DASHBOARD_REVIEW_STATS_REQUEST = 'dashboard/DASHBOARD_REVIEW_STATS_REQUEST';
export const DASHBOARD_REVIEW_STATS_REQUEST_FAILURE = 'dashboard/DASHBOARD_REVIEW_STATS_REQUEST_FAILURE';
export const DASHBOARD_REVIEW_STATS_RESPONES = 'dashboard/DASHBOARD_REVIEW_STATS_RESPONSE';

// Dashboard Survey Recent Comments action types
export const DASHBOARD_SURVEY_RECENT_COMMENTS_REQUEST = 'dashboard/DASHBOARD_SURVEY_RECENT_COMMENTS_REQUEST';
export const DASHBOARD_SURVEY_RECENT_COMMENTS_FAILURE = 'dashboard/DASHBOARD_SURVEY_RECENT_COMMENTS_FAILURE';
export const DASHBOARD_SURVEY_RECENT_COMMENTS_RESPONSE = 'dashboard/DASHBOARD_SURVEY_RECENT_COMMENTS_RESPONSE';

export const DASHBOARD_SURVEY_SEND_MESSAGE_REQUEST = 'dashboard/DASHBOARD_SURVEY_SEND_MESSAGE_REQUEST';
export const DASHBOARD_SURVEY_SEND_MESSAGE_FAILURE = 'dashboard/DASHBOARD_SURVEY_SEND_MESSAGE_FAILURE';
export const DASHBOARD_SURVEY_SEND_MESSAGE_RESPONSE = 'dashboard/DASHBOARD_SURVEY_SEND_MESSAGE_RESPONSE';

// Dashboard Survey Question action types
export const DASHBOARD_SURVEY_QUESTION_REQUEST = 'dashboard/DASHBOARD_SURVEY_QUESTION_REQUEST';
export const DASHBOARD_SURVEY_QUESTION_FAILURE = 'dashboard/DASHBOARD_SURVEY_QUESTION_FAILURE';
export const DASHBOARD_SURVEY_QUESTION_RESPONSE = 'dashboard/DASHBOARD_SURVEY_QUESTION_RESPONSE';

// Dashboard Survey Anser Request action types
export const DASHBOARD_SURVEY_ANSWER_REQUEST = 'dashboard/DASHBOARD_SURVEY_ANSWER_REQUEST';
export const DASHBOARD_SURVEY_ANSWER_FAILURE = 'dashboard/DASHBOARD_SURVEY_ANSWER_FAILURE';
export const DASHBOARD_SURVEY_ANSWER_RESPONSE = 'dashboard/DASHBOARD_SURVEY_ANSWER_RESPONSE';

export const DASHBOARD_SURVEY_DELIVERY_METHOD = 'dashboard/DASHBOARD_SURVEY_DELIVERY_METHOD';

export const DASHBOARD_FOCUS_AREA_PAGE_NUM = 'dashboard/DASHBOARD_FOCUS_AREA_PAGE_NUM';

// Dashboard Customer Contact Request action types
export const DASHBOARD_SURVEY_NOTIFICATION_REQUEST = 'dashboard/DASHBOARD_SURVEY_NOTIFICATION_REQUEST';
export const DASHBOARD_SURVEY_NOTIFICATION_FAILURE = 'dashboard/DASHBOARD_SURVEY_NOTIFICATION_FAILURE';
export const DASHBOARD_SURVEY_NOTIFICATION_RESPONSE = 'dashboard/DASHBOARD_SURVEY_NOTIFICATION_RESPONSE';

export const DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_REQUEST = 'dashboard/DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_REQUEST';
export const DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_FAILURE = 'dashboard/DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_FAILURE';
export const DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_RESPONSE = 'dashboard/DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_RESPONSE';

export const DASHBOARD_RESOLVE_NOTIFICATION_REQUEST = 'dashboard/DASHBOARD_RESOLVE_NOTIFICATION_REQUEST';
export const DASHBOARD_RESOLVE_NOTIFICATION_FAILURE = 'dashboard/DASHBOARD_RESOLVE_NOTIFICATION_FAILURE';
export const DASHBOARD_RESOLVE_NOTIFICATION_RESPONSE = 'dashboard/DASHBOARD_RESOLVE_NOTIFICATION_RESPONSE';

export const DASHBOARD_LOCATION_DETAILED_VIEW_REQUEST = 'dashboard/DASHBOARD_LOCATION_DETAILED_VIEW_REQUEST';
export const DASHBOARD_LOCATION_DETAILED_VIEW_FAILURE = 'dashboard/DASHBOARD_LOCATION_DETAILED_VIEW_FAILURE';
export const DASHBOARD_LOCATION_DETAILED_VIEW_RESPONSE = 'dashboard/DASHBOARD_LOCATION_DETAILED_VIEW_RESPONSE';
