import { Modal } from 'react-bootstrap';
import React from 'react';

// import 'assets/stylesheets/components/modal.scss';

interface IModalPopupProps {
  centered?: boolean;
  show: boolean;
  className?: string;
  headerClass?: string;
  bodyClass?: string;
  footerClass?: string;
  backdropClasses?: string;
  fullView?: boolean;
  renderHeader?: () => React.ReactNode;
  renderBody?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderContent?: () => React.ReactNode;
  onHide?: () => void;
}

export const ModalPopup = ({
  centered,
  show,
  className,
  headerClass,
  bodyClass,
  footerClass,
  backdropClasses,
  fullView,
  renderContent,
  renderHeader,
  renderBody,
  renderFooter,
  onHide
}: IModalPopupProps) => {
  return (
    <Modal
      className={className}
      show={show}
      fullscreen={fullView || undefined}
      centered={centered}
      backdropClassName={backdropClasses}
      onHide={() => {
        if (onHide) {
          onHide();
        }
      }}
      animation={false}
    >
      <div className="inner-modal__wrp">
        {renderContent ? renderContent() : null}
        {renderHeader ? <Modal.Header className={headerClass}>{renderHeader()}</Modal.Header> : null}

        {renderBody ? <Modal.Body className={bodyClass}>{renderBody()}</Modal.Body> : null}

        {renderFooter ? <Modal.Footer className={footerClass}>{renderFooter()}</Modal.Footer> : null}
      </div>
    </Modal>
  );
};
