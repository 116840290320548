import { Dispatch } from 'redux';

import { deleteSocialMediaSetting } from 'actions';

export const disconnectSocialMedia = (provider: string, socialMediumID: number, type: string, accountId: number, dispatch: Dispatch): boolean | void => {
  if (accountId && provider && socialMediumID) {
    dispatch(deleteSocialMediaSetting({ socialMediumID, id: accountId, type }));
  } else {
    return false;
  }
};
