import { IDashboardReportDownloadParams, IDashboardReportResponse, IDownloadParams, IDownloadSurveysFilteredParams, IExportRequest, IIdParam } from 'revv/types';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';
import { COMMON_ACTIONS } from '../common';

export const downloadSurveysFilteredRequest = (payload: IDownloadSurveysFilteredParams) => createAction(actionTypes.DOWNLOAD_SURVEYS_FILTERED_REQUEST, payload);
export const downloadSurveysFilteredRequestFailure = (payload: string) => createAction(actionTypes.DOWNLOAD_SURVEYS_FILTERED_REQUEST_FAILURE, payload);
export const downloadSurveysFilteredResponse = (payload: IExportRequest | null) => createAction(actionTypes.DOWNLOAD_SURVEYS_FILTERED_RESPONSE, payload);

export const downloadRequest = (payload: IDownloadParams) => createAction(actionTypes.DOWNLOAD_REQUEST, payload);
export const downloadRequestFailure = (payload: string) => createAction(actionTypes.DOWNLOAD_REQUEST_FAILURE, payload);
export const downloadResponse = (payload: IExportRequest | null) => createAction(actionTypes.DOWNLOAD_RESPONSE, payload);

export const pollExportRequest = (payload: IIdParam) => createAction(actionTypes.POLL_EXPORT_REQUEST, payload);
export const pollExportRequestFailure = (payload: string) => createAction(actionTypes.POLL_EXPORT_REQUEST_FAILURE, payload);

export const dashboardReportDownloadRequest = (payload: IDashboardReportDownloadParams) => createAction(actionTypes.DASHBOARD_REPORT_DOWNLOAD_REQUEST, payload);
export const dashboardReportDownloadRequestFailure = (payload: string) => createAction(actionTypes.DASHBOARD_REPORT_DOWNLOAD_REQUEST_FAILURE, payload);
export const dashboardReportDownloadResponse = (payload: IDashboardReportResponse | null) => createAction(actionTypes.DASHBOARD_REPORT_DOWNLOAD_RESPONSE, payload);

export const DOWNLOAD_ACTIONS = {
  ...COMMON_ACTIONS,
  downloadSurveysFilteredRequest,
  downloadSurveysFilteredRequestFailure,
  downloadSurveysFilteredResponse,
  downloadRequest,
  downloadRequestFailure,
  downloadResponse,
  dashboardReportDownloadRequest,
  dashboardReportDownloadRequestFailure,
  dashboardReportDownloadResponse,
  pollExportRequest,
  pollExportRequestFailure
};

export type DownloadActions = ActionsUnion<typeof DOWNLOAD_ACTIONS>;
