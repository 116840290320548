import { config } from 'config';
import { dateHelperTimeZone } from '../../helpers/date-helper';

const getTooltipClassName = (currentPlatform: string) => {
  if (currentPlatform === 'Facebook') return 'fb';
  else if (currentPlatform === 'Twitter') return 'twit';
  else if (currentPlatform === 'Instagram') return 'insta';
  else if (currentPlatform === 'LinkedIn') return 'linkedIn';
};

export const HIGHCHARTS_OBJ = {
  CHART: 'chart'
};

export const OVERVIEW_POSTS_GAUGE: Highcharts.Options = {
  chart: {
    type: 'solidgauge',
    events: {
      load() {
        this.renderer.image(`${config.cdnImgUrl}chart-globe.svg`, (this.chartHeight / 2 || 0) - 35, (this.chartHeight / 2 || 0) - 35, 70, 70).add();
      },
      render() {
        const self: any = this;
        if (self.series && self.series.length && !self.series[0].icon) {
          self.series[0].icon = self.renderer
            .circle(0, 0, 6)
            .attr({
              stroke: 'rgba(0, 0, 0, 0.12)',
              fill: 'rgba(0, 0, 0, 0.12)',
              'stroke-linecap': 'round',
              'stroke-linejoin': 'round',
              'stroke-width': 0,
              zIndex: 10
            })
            .add(self.series[2].group);
        }
        self.series[0].icon.translate(
          self.chartWidth / 2 - 10,
          self.plotHeight / 2 - self.series[0].points[0].shapeArgs.innerR - (self.series[0].points[0].shapeArgs.r - self.series[0].points[0].shapeArgs.innerR) / 2
        );

        if (self.series && self.series.length && !self.series[1].icon) {
          self.series[1].icon = self.renderer
            .circle(0, 0, 6)
            .attr({
              stroke: 'rgba(0, 0, 0, 0.12)',
              fill: 'rgba(0, 0, 0, 0.12)',
              'stroke-linecap': 'round',
              'stroke-linejoin': 'round',
              'stroke-width': 0,
              zIndex: 10
            })
            .add(self.series[2].group);
        }
        self.series[1].icon.translate(
          self.chartWidth / 2 - 10,
          self.plotHeight / 2 - self.series[1].points[0].shapeArgs.innerR - (self.series[1].points[0].shapeArgs.r - self.series[1].points[0].shapeArgs.innerR) / 2
        );

        if (self.series && self.series.length && !self.series[2].icon) {
          self.series[2].icon = self.renderer
            .circle(0, 0, 6)
            .attr({
              stroke: 'rgba(0, 0, 0, 0.12)',
              fill: 'rgba(0, 0, 0, 0.12)',
              'stroke-linecap': 'round',
              'stroke-linejoin': 'round',
              'stroke-width': 0,
              zIndex: 10
            })
            .add(self.series[2].group);
        }

        self.series[2].icon.translate(
          self.chartWidth / 2 - 10,
          self.plotHeight / 2 - self.series[2].points[0].shapeArgs.innerR - (self.series[2].points[0].shapeArgs.r - self.series[2].points[0].shapeArgs.innerR) / 2
        );
        return self;
      }
    },
    width: 250,
    height: 250
  },
  title: {
    text: ''
  },

  tooltip: {
    followPointer: true,
    formatter() {
      const self: any = this;
      return self.point.options.tooltip || '';
    },
    borderRadius: 10
  },

  pane: {
    startAngle: 0,
    endAngle: 270,
    background: [
      {
        // total Post
        outerRadius: '112%',
        innerRadius: '100%',
        backgroundColor: '#013B71',
        borderWidth: 0,
        shape: 'arc'
      },
      {
        // used Post
        outerRadius: '88%',
        innerRadius: '76%',
        backgroundColor: '#FFC259',
        borderWidth: 0,
        shape: 'arc'
      },
      {
        // publications
        outerRadius: '64%',
        innerRadius: '52%',
        backgroundColor: '#29AB87',
        borderWidth: 0,
        shape: 'arc'
      }
    ]
  },

  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: []
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: true
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: true,
    itemDistance: 10,
    itemMarginBottom: 2,
    itemStyle: {
      color: '#787878',
      cursor: 'pointer',
      fontSize: '9.5px'
    },
    symbolHeight: 9,
    symbolPadding: 4,
    symbolRadius: 6,
    // width: "350",
    x: 20
  },
  accessibility: {
    enabled: false
  }
};

// ACTIVITY GAUGE CHART
export const OVERVIEW_POSTS_CHART_DATALABELS = {
  borderWidth: 0,
  align: 'right',
  style: {
    fontWeight: '500',
    fontFamily: 'Mulish',
    fontSize: '11px',
    color: '#000',
    opacity: '1'
  }
};

export const OVERVIEW_POSTS_CHART_SERIES = [
  {
    name: 'Total Posts',
    data: [
      {
        color: '#013B71',
        radius: '112%',
        innerRadius: '100%',
        y: 0
      }
    ],
    marker: {
      fillColor: '#013B71'
    },
    dataLabels: {
      ...OVERVIEW_POSTS_CHART_DATALABELS,
      useHTML: true,
      enabled: true,
      format: '<span style="font-size:1.5em; font-weight: bold; ">{Total Posts-y}</span>',
      x: -35,
      y: -150
    }
  },
  {
    name: 'Used Posts',
    data: [
      {
        color: '#FFC259',
        radius: '88%',
        innerRadius: '76%',
        y: 0
      }
    ],
    marker: {
      fillColor: '#FFC259'
    },
    dataLabels: {
      ...OVERVIEW_POSTS_CHART_DATALABELS,
      useHTML: true,
      enabled: true,
      x: -35,
      y: -94
    }
  },
  {
    name: 'Publications',
    data: [
      {
        color: '#29AB87',
        radius: '64%',
        innerRadius: '52%',
        y: 0
      }
    ],
    marker: {
      fillColor: '#29AB87'
    },
    dataLabels: {
      ...OVERVIEW_POSTS_CHART_DATALABELS,
      useHTML: true,
      enabled: true,
      x: -35,
      y: -70
    }
  }
];
/* Donut design */

export const OVERVIEW_MONTHLY_POSTS_YAXIS_SERIES = 10;

// MONTHLY POST METRIC CHART
export const OVERVIEW_MON_CHRT_LABLES = {
  FACEBOOK: 'Facebook',
  TWITTER: 'X (Formerly Twitter)',
  LINKEDIN: 'LinkedIn',
  INSTAGRAM: 'Instagram'
};

export const OVERVIEW_MON_CHRT_SERIS_OPT_OBJ = {
  type: 'areaspline',
  data: [],
  marker: {
    enabled: false,
    symbol: 'circle'
  },
  fillColor: {
    linearGradient: {
      x1: 1,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: []
  },
  color: ''
};

export const OVERVIEW_MON_CHRT: any = {
  chart: {
    type: 'areaspline'
    // width: 250,
  },
  title: '',
  plotOptions: {
    areaspline: {
      fillOpacity: 0.2
    },
    series: {
      lineWidth: 0.25,
      animation: false,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 3.5,
        states: {
          hover: {
            radius: 3.5
          }
        }
      },
      states: {
        hover: {
          halo: {
            size: 6.5
          }
        }
      }
    }
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    crosshair: true,
    visible: [1, 2, 3, 4].length > 0,
    labels: {
      align: 'center',
      style: { color: '#5B6A78', fontWeight: 'bold', fontSize: '14px' },
      formatter() {
        const self: any = this;
        return dateHelperTimeZone(self.value, 'DD ddd');
        // return getDateBasedOnGivenFormat(self.value, 'DD ddd');
        // return moment(self.value).format('DD ddd');
      }
    }
  },
  yAxis: {
    lineWidth: 1,
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    title: '',
    min: 0,
    max: 50,
    labels: {
      style: { color: '#5B6A78', fontWeight: 'bold', fontSize: '14px' }
    }
  },
  tooltip: {
    shared: true,
    split: false,
    backgroundColor: '#ffffff',
    useHTML: true,
    shape: 'square',
    className: 'chart-tooltip-wrp',
    borderWidth: 0,
    borderRadius: 6,
    style: {
      border: 'none',
      boxSizing: 'border-box',
      boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1)'
    },
    formatter() {
      const self: any = this;
      let tooltip = `<span class='ra-tooltip-date'>${dateHelperTimeZone(self.x, 'dddd, MMMM D, YYYY')}</span>`;
      self.points.forEach((item: any) => {
        tooltip += `<div class="tooltip-tile-wrp"><div class="follwers-wrp"><span class='ra-${getTooltipClassName(item.series.name)}-text'>${item.series.name}</span></div>
        <div class="ra-tooltip-ratings-wrp"><span class='single-value'>${item.y}</span></div>
        </div>`;
      });
      return tooltip;
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    squareSymbol: false,
    enabled: true,
    symbolHeight: 3,
    symbolRadius: 1,
    symbolWidth: 15,
    useHTML: true,
    itemStyle: {
      fontFamily: 'Mulish !important',
      fontSize: '0.8333vw',
      fontWeight: 'normal'
    },
    ry: 10,
    labelFormatter() {
      const self: any = this;
      if (self.name === 'Facebook') {
        return `<div class='legend-img ${self.visible ? '' : 'r-op-5'}'><img src='${config.cdnImgUrl}legend-fb.svg'/></div>`;
      } else if (self.name === 'Instagram') {
        return `<div class='legend-img ${self.visible ? '' : 'r-op-5'}'><img src='${config.cdnImgUrl}legend-insta.svg'/></div>`;
      } else if (self.name === 'X (Formerly Twitter)') {
        return `<div class='legend-img ${self.visible ? '' : 'r-op-5'}'><img src='${config.cdnImgUrl}legend-twitter.svg'/></div>`;
      } else if (self.name === 'LinkedIn') {
        return `<div class='legend-img ${self.visible ? '' : 'r-op-5'}'><img src='${config.cdnImgUrl}legend-linkedin.svg'/></div>`;
      }
    }
  },
  series: [],
  accessibility: {
    enabled: false
  }
};

export const OVERVIEW_MON_CHRT_METRIC_OBJ = {
  FB_POST_COUNT: 'facebookPostsCount',
  TWIT_POST_COUNT: 'twitterPostsCount',
  INSTA_POST_COUNT: 'instagramPostsCount',
  LINKIN_POST_COUNT: 'linkedinPostsCount'
};

export const OVERVIEW_MON_CHRT_METRIC_TYPES = {
  TOTAL_POST: 'totalPostsResult',
  PUBLISHED_POST: 'publishedPostsResult',
  USED_POST: 'usedPostsResult'
};

const OVERVIEW_MON_CHRT_GRADINET = {
  linearGradient: {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1
  }
};

export const OVERVIEW_MON_CHRT_GRADINET_COLOR = {
  FB: {
    linearGradient: OVERVIEW_MON_CHRT_GRADINET.linearGradient,
    stops: [
      [0, 'rgba(24,119,242,0.7)'],
      [1, 'rgba(255,255,255,0.1)']
    ]
  },
  TWITTER: {
    linearGradient: OVERVIEW_MON_CHRT_GRADINET.linearGradient,
    stops: [
      [0, 'rgba(29,161,242,0.7)'],
      [1, 'rgba(255,255,255,0.1)']
    ]
  },
  INSTA: {
    linearGradient: OVERVIEW_MON_CHRT_GRADINET.linearGradient,
    stops: [
      [0, 'rgba(240,0,117,1)'],
      [1, 'rgba(255,255,255,0.1)']
    ]
  },
  LINKIN: {
    linearGradient: OVERVIEW_MON_CHRT_GRADINET.linearGradient,
    stops: [
      [0, 'rgba(40,103,178,0.7)'],
      [1, 'rgba(255,255,255,0.1)']
    ]
  }
};

export const OVERVIEW_MON_CHRT_SERIES_COLORS = {
  facebookPostsCount: '#1877F2',
  twitterPostsCount: '#000000',
  instagramPostsCount: '#F00075',
  linkedinPostsCount: '#2867B2'
};

export enum OverviewValidQueryParams {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  LOCATION_ID = 'location_id'
}

export enum OverviewChartTooltip {
  LOC_TOTAL_POSTS = 'Total posts created for this location by all users',
  USED_POSTS = 'Scheduled posts, yet to be published',
  LOC_PUBLISHED_POST = 'Total published posts for this location',
  HUB_TOTAL_POSTS = 'Total posts created by all users',
  HUB_PUBLISHED_POST = 'Total published posts'
}

export const OVERVIEW_MONTHLY_POSTS_TAB = [
  {
    value: OVERVIEW_MON_CHRT_METRIC_TYPES.TOTAL_POST,
    label: 'Total Posts'
  },
  {
    value: OVERVIEW_MON_CHRT_METRIC_TYPES.USED_POST,
    label: 'Used Posts'
  },
  {
    value: OVERVIEW_MON_CHRT_METRIC_TYPES.PUBLISHED_POST,
    label: 'Published Posts'
  }
];
