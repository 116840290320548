export const MAP_INIT_VIEW_PORT = {
  width: '100%',
  height: '100%',
  latitude: 37.7577,
  longitude: -122.4376,
  zoom: 3
};

export const MAP_DEFAULT_PROPS = {
  coordsList: [],
  centerCoords: {
    lat: MAP_INIT_VIEW_PORT.latitude,
    lng: MAP_INIT_VIEW_PORT.longitude
  },
  zoom: MAP_INIT_VIEW_PORT.zoom,
  width: MAP_INIT_VIEW_PORT.width,
  height: MAP_INIT_VIEW_PORT.height
};
